import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { BookingForm } from './interfaces/BookingForm';
import { CustomDatepicker } from '../../../../components/CustomDatepicker';
import { useGetQuery } from '../../../../customhooks/useGetQuery';
import { Port } from './interfaces/Port';
import { FormikProps } from 'formik';
import CustomTextField from '../../../../components/CustomTextInput';

export interface TransportFormProps {
    formData: BookingForm;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
    formik: FormikProps<BookingForm>;
}

const moveTypesList = [
    'Port, Ramp, or CY to Port, Ramp, or CY',
    'Door to Port, Ramp, or CY',
    'Door to Door',
    'Port, Ramp, or CY to Door'
];

export const TransportForm = ({
    formData,
    setFormData,
    formik
}: TransportFormProps) => {
    const { isLoading: isLoadingPorts, entries: Ports } = useGetQuery<Port[]>({
        url: '/ports',
        cacheKey: 'ports',
        params: {
            search: 'Nigeria'
        }
    });

    return (
        <div className='flex flex-col gap-4 pb-4 lg:pb-0 lg:grid lg:grid-cols-3 lg:mb-8'>
            <div className='w-full'>
                <Autocomplete
                    options={moveTypesList.map((label) => ({ label }))}
                    value={
                        formData.transport.moveType
                            ? {
                                  label: formData.transport.moveType
                              }
                            : null
                    }
                    onChange={(_, value) => {
                        if (value) {
                            setFormData((prev) => {
                                const update = {
                                    ...prev,
                                    transport: {
                                        ...prev.transport,
                                        moveType: value.label
                                    }
                                };

                                formik.setValues(update);

                                return update;
                            });
                        }
                    }}
                    renderInput={(params) => (
                        <CustomTextField
                            params={params}
                            label='Move Type'
                            required
                            error={!!formik.errors.transport?.moveType}
                            helperText={formik.errors.transport?.moveType}
                        />
                    )}
                />
            </div>

            <div className='w-full'>
                <CustomDatepicker
                    required={true}
                    value={formData.transport.earliestDepatureDate}
                    label='Earliest Departure Date'
                    error={!!formik.errors.transport?.earliestDepatureDate}
                    helperText={formik.errors.transport?.earliestDepatureDate}
                    onHandleChange={(value) => {
                        if (value) {
                            setFormData((prev) => {
                                const update = {
                                    ...prev,
                                    transport: {
                                        ...prev.transport,
                                        earliestDepatureDate: new Date(
                                            value
                                        ).toISOString()
                                    }
                                };

                                formik.setValues(update);

                                return update;
                            });
                        }
                    }}
                />
            </div>

            <div className='w-full'>
                <CustomDatepicker
                    required={true}
                    value={formData.transport.latestDeliveryDate}
                    error={!!formik.errors.transport?.latestDeliveryDate}
                    helperText={formik.errors.transport?.latestDeliveryDate}
                    label='Latest Delivery Date'
                    onHandleChange={(value) => {
                        if (value) {
                            setFormData((prev) => {
                                const update = {
                                    ...prev,
                                    transport: {
                                        ...prev.transport,
                                        latestDeliveryDate: new Date(
                                            value
                                        ).toISOString()
                                    }
                                };

                                formik.setValues(update);

                                return update;
                            });
                        }
                    }}
                />
            </div>

            <div className='w-full'>
                <Autocomplete
                    options={Ports}
                    onChange={(_, value) => {
                        if (value) {
                            setFormData((prev) => {
                                const update = {
                                    ...prev,
                                    transport: {
                                        ...prev.transport,
                                        placeOfReceiptId: value.id
                                    }
                                };

                                formik.setValues(update);

                                return update;
                            });
                        }
                    }}
                    renderInput={(params) => (
                        <CustomTextField
                            params={params}
                            label='Place of Carrier Receipt'
                            required
                            error={!!formik.errors.transport?.placeOfReceiptId}
                            helperText={
                                formik.errors.transport?.placeOfReceiptId
                            }
                        />
                    )}
                    getOptionLabel={(option) => option.name}
                    loading={isLoadingPorts}
                    renderOption={(props, { name, countryName }, state) => (
                        <Box component='li' {...props} key={state.index}>
                            {name}, {countryName}
                        </Box>
                    )}
                />
            </div>

            <div className='w-full'>
                <Autocomplete
                    options={Ports}
                    onChange={(_, value) => {
                        if (value) {
                            setFormData((prev) => {
                                const update = {
                                    ...prev,
                                    transport: {
                                        ...prev.transport,
                                        placeOfDeliveryId: value.id
                                    }
                                };

                                formik.setValues(update);

                                return update;
                            });
                        }
                    }}
                    renderInput={(params) => (
                        <CustomTextField
                            params={params}
                            label='Place of Carrier Delivery'
                            required
                            error={!!formik.errors.transport?.placeOfDeliveryId}
                            helperText={
                                formik.errors.transport?.placeOfDeliveryId
                            }
                        />
                    )}
                    getOptionLabel={(option) => option.name}
                    loading={isLoadingPorts}
                    renderOption={(props, { name, countryName }, state) => (
                        <Box component='li' {...props} key={state.index}>
                            {name}, {countryName}
                        </Box>
                    )}
                />
            </div>
        </div>
    );
};
