import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { Box, Button, Chip, Grid, LinearProgress } from '@mui/material';
import { Avatar, Table } from 'antd';
import { useState } from 'react';
import { Mapping } from '../../../bookings/mapping/mapping';
import AnchorLocation from './anchor-location';
import moment from 'moment';
import { SearchResult } from '../../interfaces/SearchResults';

interface TileProps {
    data: SearchResult | null;
    from: string;
    to: string;
    modal: boolean;
    handleBookButtonClick: (data: SearchResult) => void;
    isProfileExistsFn: (id: string) => boolean;
}

const Tile = ({
    data,
    from,
    to,
    modal,
    handleBookButtonClick,
    isProfileExistsFn
}: TileProps) => {
    const [showExpanded, setShowExpanded] = useState<boolean>(modal);

    return (
        <Grid
            container
            sx={{
                backgroundColor: 'white',
                borderRadius: 2,
                mb: 2,
                p: 1.5
            }}
        >
            {data && (
                <>
                    <Grid container justifyContent='space-between'>
                        <Grid item xs={3} md={1.5}>
                            <Box display='flex' alignItems='center' gap='1rem'>
                                <Avatar
                                    style={{
                                        backgroundColor: '#0088b2',
                                        verticalAlign: 'middle',
                                        fontSize: '20px',
                                        fontWeight: 500
                                    }}
                                    size='large'
                                >
                                    {data?.carrier[0]}
                                </Avatar>
                                <Box
                                    component='p'
                                    sx={{
                                        display: {
                                            xs: 'none',
                                            sm: 'block',
                                            fontSize: '18px',
                                            fontWeight: 500
                                        }
                                    }}
                                >
                                    {data?.carrier}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={4} justifyItems='flex-end'>
                            <Box
                                display='flex'
                                height='100%'
                                alignItems='center'
                                justifyContent='flex-end'
                            >
                                <Chip
                                    label={`${data?.transit} days`}
                                    size='small'
                                    sx={{
                                        p: 0.5,
                                        mr: 1
                                    }}
                                />
                                <Chip
                                    icon={
                                        <CalendarTodayOutlinedIcon
                                            sx={{ height: 1 }}
                                        />
                                    }
                                    label={`${moment
                                        .unix(Number(data?.departs))
                                        .format('ll')} - ${moment
                                        .unix(Number(data?.arrives))
                                        .format('ll')}`}
                                    size='small'
                                    sx={{
                                        px: 0.5,
                                        display: { xs: 'none', md: 'block' }
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justifyContent='space-between'
                        sx={{ my: 1 }}
                    >
                        <Grid item xs={4} md={3} alignItems='center'>
                            <AnchorLocation location={from} />
                        </Grid>
                        <Grid item xs={4} md={3} alignItems='baseline'>
                            <Box display='flex' justifyContent='flex-end'>
                                <AnchorLocation location={to} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ my: 2 }}>
                        <LinearProgress variant='determinate' value={100} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            my: 2
                        }}
                    >
                        {showExpanded && (
                            <div>
                                <div className='relative map-container h-[25rem]'>
                                    <Mapping paths={[]} />
                                </div>
                                <div>
                                    <Table
                                        columns={[
                                            {
                                                title: 'Route',
                                                key: 'route',
                                                width: '50%',
                                                render: () => (
                                                    <>
                                                        <Box
                                                            display='flex'
                                                            justifyContent='space-between'
                                                        >
                                                            <AnchorLocation
                                                                location={from}
                                                                date={String(
                                                                    data?.departs
                                                                )}
                                                            />
                                                            <ArrowForwardIcon
                                                                sx={{
                                                                    color: 'black'
                                                                }}
                                                            />
                                                            <AnchorLocation
                                                                location={to}
                                                                date={String(
                                                                    data?.arrives
                                                                )}
                                                            />
                                                        </Box>
                                                    </>
                                                )
                                            },
                                            {
                                                title: 'Transport',
                                                key: 'vessel',
                                                dataIndex: 'vessel',
                                                width: '25%'
                                            },
                                            {
                                                title: 'Service',
                                                key: 'service',
                                                dataIndex: 'service',
                                                width: '25%'
                                            }
                                        ]}
                                        dataSource={[data]}
                                        pagination={false}
                                    />
                                </div>
                            </div>
                        )}
                    </Grid>
                    <Grid container justifyContent='end'>
                        <Grid item xs={6} md={3}>
                            <Box display='flex' justifyContent='flex-end'>
                                <Button
                                    variant='contained'
                                    sx={{ mr: 2 }}
                                    onClick={() => handleBookButtonClick(data)}
                                >
                                    {isProfileExistsFn(data.carrierId)
                                        ? 'Book Now'
                                        : 'Connect Now'}
                                </Button>
                                {!modal && (
                                    <Button
                                        variant='text'
                                        className='action-btn'
                                        sx={{
                                            '&:hover': {
                                                background: 'unset',
                                                textDecoration: 'underline'
                                            }
                                        }}
                                        onClick={() => {
                                            setShowExpanded(!showExpanded);
                                        }}
                                    >
                                        {showExpanded ? 'Hide' : 'Show'} Details
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

Tile.defaultProps = {
    modal: false
};

export default Tile;
