import { Grid, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import PortsAutocomplete from '../../../../autocompletewithapi/PortsAutocomplete';
import Customdatepicker from '../../../../customdatepicker/customdatepicker';
import { PackingListBasicInterface } from '../../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/parkinglistTypes';

const PortDetails = ({
    errors,
    payload,
    updateField,
    setShowAddPartner
}: PackingListBasicInterface) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-4'>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: '0px'
                        }}
                    >
                        <Grid item xs={12} md={12} lg={6}>
                            <PortsAutocomplete
                                errorCondition={
                                    errors?.portOfLoadingId.length > 0
                                }
                                helperText={
                                    errors?.portOfLoadingId.length > 0
                                        ? errors?.portOfLoadingId
                                        : undefined
                                }
                                id='quotation-portOfLoading'
                                label='Port of Loading'
                                name='portOfLoadingId'
                                updateField={updateField}
                                nameValue='portOfLoadingValue'
                                value={payload.portOfLoadingValue}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <PortsAutocomplete
                                errorCondition={
                                    errors?.portOfDischargeId.length > 0
                                }
                                helperText={
                                    errors?.portOfDischargeId.length > 0
                                        ? errors?.portOfDischargeId
                                        : undefined
                                }
                                id='quotation-portOfDischarge'
                                label='Port of Discharge'
                                name='portOfDischargeId'
                                updateField={updateField}
                                nameValue='portOfDischargeValue'
                                value={payload.portOfDischargeValue}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: '0px'
                        }}
                    >
                        <Grid item xs={12} md={12} lg={6}>
                            <div className='pt-4'>
                                <Customdatepicker
                                    value={ payload?.dateOfDeparture || null }
                                    id='quotation-issuedDate'
                                    label='Date of Departure'
                                    name='dateOfDeparture'
                                    updateField={updateField}
                                    error={errors?.dateOfDeparture.length > 0}
                                    helperText={
                                        errors?.dateOfDeparture.length > 0
                                            ? errors?.dateOfDeparture
                                            : undefined
                                    }
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                error={errors?.finalDestination.length > 0}
                                helperText={
                                    errors?.finalDestination.length > 0
                                        ? errors?.finalDestination
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='commercialinvoice-finalDestination'
                                label='Final Destination'
                                name='finalDestination'
                                value={payload?.finalDestination}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'finalDestination',
                                        e.target.value
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export default PortDetails;
