import { FormControlLabel } from '@mui/material';
import { Tooltip } from 'antd';
import { BookingForm } from './interfaces/BookingForm';
import CustomTextField from '../../../../components/CustomTextInput';
import CustomCheckBox from '../../../../components/CustomCheckBox';
import { InfoCircleIcon } from '../../../../components/icons/InfoCircleIcon';

interface AdditionalProps {
    formData: BookingForm;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
}

export const GeneralAdditionalDetailsForm = ({
    formData,
    setFormData
}: AdditionalProps) => {
    return (
        <div className='flex flex-col pb-4 lg:pb-0 lg:flex-row gap-4'>
            <CustomTextField
                name='bookingNumber'
                value={formData.general.carrierBookingNumber || ''}
                label='Carrier Booking Number'
                onChange={(e) => {
                    setFormData((prev) => ({
                        ...prev,
                        general: {
                            ...prev.general,
                            carrierBookingNumber: e.target.value
                        }
                    }));
                }}
            />

            <CustomTextField
                name='filer'
                disabled={!formData.general.fileWithCustoms}
                label='Filer ID/SCAC'
                focused={!!formData.general.fileWithCustoms}
                value={formData.general.filerId || ''}
                onChange={(e) => {
                    setFormData((prev) => ({
                        ...prev,
                        general: {
                            ...prev.general,
                            filerId: e.target.value
                        }
                    }));
                }}
            />

            <div className='w-full'>
                <FormControlLabel
                    value={formData.general.fileWithCustoms}
                    control={
                        <CustomCheckBox
                            checked={formData.general.fileWithCustoms}
                            onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    general: {
                                        ...prev.general,
                                        fileWithCustoms: e.target.checked
                                    }
                                }));
                            }}
                        />
                    }
                    label={
                        <div className='flex items-center gap-2'>
                            <span>Carrier Does NOT File</span>{' '}
                            <span>
                                <Tooltip
                                    arrow
                                    title='Check this box if the carrier will NOT be filling the cargo manifest with customs'
                                    placement='right'
                                >
                                    <InfoCircleIcon />
                                </Tooltip>
                            </span>
                        </div>
                    }
                />

                <FormControlLabel
                    checked={formData.general.releaseNumberPerContainer}
                    control={
                        <CustomCheckBox
                            value={formData.general.releaseNumberPerContainer}
                            onChange={(e) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    general: {
                                        ...prev.general,
                                        releaseNumberPerContainer:
                                            e.target.checked
                                    }
                                }));
                            }}
                        />
                    }
                    label='Distinct Release Number Per Container'
                />
            </div>
        </div>
    );
};
