import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Custombutton from '../../../../components/custombutton/custombutton';

export interface savetradedocumentProps {
    updateField: (name: string, value: any) => void;
    onSubmit: () => Promise<void>;
    loading: boolean;
    data?: any;
}

const Savetradedocument = ({
    updateField,
    onSubmit,
    loading,
    data
}: savetradedocumentProps) => {
    // const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({
        documentName: ''
    });
    const [payload, setPayload] = useState<any>({
        documentName: data?.documentName || ''
    });

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    // const updateField = (name: string, value: any) => {
    //   setPayload((prevState: any) => ({
    //     ...prevState,
    //     [name]: value
    //   }))
    // }

    /**
     * Submit form
     * @param e Object
     */
    const onSubmitting = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit();
    };

    return (
        <div>
            <form autoComplete='off' onSubmit={onSubmitting} noValidate>
                <TextField
                    error={errors?.documentName.length > 0}
                    helperText={
                        errors?.documentName.length > 0
                            ? errors?.documentName
                            : undefined
                    }
                    margin='normal'
                    required
                    value={payload?.documentName}
                    fullWidth
                    id='savedocument-documentName'
                    label='Name Tag'
                    name='documentName'
                    onChange={(e: any) => {
                        setPayload((prevState: any) => ({
                            ...prevState,

                            ['documentName']: e.target.value
                        }));
                        updateField('documentName', e.target.value);
                    }}
                />

                <Custombutton
                    isLoadingButton
                    loading={loading}
                    type='submit'
                    fullWidth
                    variant='contained'
                    styles={{
                        padding: '0.75rem 0.62rem 1rem 0.93rem'
                    }}
                    disabled={
                        Object.values(payload).some(
                            (item: any) => item === undefined
                        ) ||
                        Object.values(errors).some((item) => item.length > 0) ||
                        loading
                    }
                    buttonText='Save as'
                />
            </form>
        </div>
    );
};

export default Savetradedocument;
