import { Grid, MenuItem, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { methodsOfPayments } from '../../../../../utils/appconstants';
import PortsAutocomplete from '../../../../autocompletewithapi/PortsAutocomplete';
import { CommercialFormBasicInterface } from '../../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/CommercialTypes';

const PortsDetails = ({
    payload,
    updateField,
    errors
}: CommercialFormBasicInterface) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: '0px'
                        }}
                    >
                        <Grid item xs={12} md={12} lg={6}>
                            <PortsAutocomplete
                                errorCondition={
                                    errors?.portOfLoadingId.length > 0
                                }
                                helperText={
                                    errors?.portOfLoadingId.length > 0
                                        ? errors?.portOfLoadingId
                                        : undefined
                                }
                                id='quotation-portOfLoadingId'
                                label='Port of Loading'
                                name='portOfLoadingId'
                                updateField={updateField}
                                nameValue='portOfLoadingValue'
                                value={payload?.portOfLoadingValue || ''}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <PortsAutocomplete
                                errorCondition={
                                    errors?.portOfDischargeId.length > 0
                                }
                                helperText={
                                    errors?.portOfDischargeId.length > 0
                                        ? errors?.portOfDischargeId
                                        : undefined
                                }
                                id='quotation-portOfDischargeId'
                                label='Port of Discharge'
                                name='portOfDischargeId'
                                updateField={updateField}
                                nameValue='portOfDischargeValue'
                                value={payload?.portOfDischargeValue || ''}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: '0px'
                        }}
                    >
                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                select
                                error={errors?.termsOfPayment.length > 0}
                                helperText={
                                    errors?.termsOfPayment.length > 0
                                        ? errors?.termsOfPayment
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='commercialinvoice-termsOfPayment'
                                label='Terms / Method of Payment'
                                name='termsOfPayment'
                                value={payload?.termsOfPayment || ''}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'termsOfPayment',
                                        e.target.value
                                    );
                                    updateField(
                                        'methodOfPayment',
                                        e.target.value
                                    );
                                }}
                            >
                                {methodsOfPayments.map((item, index) => (
                                    <MenuItem
                                        value={item || ''}
                                        key={`methodsOfPayments-${item}-${index}`}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                error={errors?.placeOfOrigin.length > 0}
                                helperText={
                                    errors?.placeOfOrigin.length > 0
                                        ? errors?.placeOfOrigin
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='commercialinvoice-placeOfOrigin'
                                label='Place of Origin'
                                value={payload?.placeOfOrigin || ''}
                                name='placeOfOrigin'
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'placeOfOrigin',
                                        e.target.value
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export default PortsDetails;
