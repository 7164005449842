import { Dispatch, SetStateAction } from 'react';
import CustomModal from '../../../components/CustomModal';
import { MessageTextIcon } from '../../../components/icons';

const FinanceInfoModal = ({
    view,
    setView
}: {
    view: boolean;
    setView: Dispatch<SetStateAction<boolean>>;
}) => {
    const closeModal = () => {
        setView(false);
    };
    return (
        <CustomModal maxWidth='lg' open={view} onClose={closeModal} title=''>
            <div className='w-full p-[40px]'>
                <div className='w-[52px] h-[52px] rounded-[50%] flex justify-center items-center bg-[#F2FBFF]'>
                    <div className='w-[44px] h-[44px] rounded-[50%] flex justify-center items-center bg-[#44ADCD]'>
                        <MessageTextIcon />
                    </div>
                </div>
                <h5 className='text-base leading-7 text-ebony font-semibold mt-[24px]'>
                    How to apply
                </h5>
                <p className='text-ebony my-[16px]'>
                    To apply for financing, please reach out to us at{' '}
                    <span className='font-bold'>sales@frontedge.io</span>
                </p>
            </div>
        </CustomModal>
    );
};

export default FinanceInfoModal;
