import React from 'react';
import { AccountFeatureIcon } from '../../../../components/icons/AccountFeatureIcon';
import { Button, Stack, TextField } from '@mui/material';
import { mutateConfig } from '../../../../api/queryBase';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { LoadingButton } from '@mui/lab';
import { getErrorMessage, openNotification } from '../../../../utils/helper';
import {
    ApproveAccountDialogProps,
    ApproveRequestValues
} from './interfaces/DedicatedAccounts';

const ApproveAccountDialog = ({
    currentAccount,
    setShowApproveAccountModal
}: ApproveAccountDialogProps) => {
    const initialValues: ApproveRequestValues = {
        clientId: '',
        clientKey: '',
        approved: true
    };

    const mutation = mutateConfig();
    const { mutate, isLoading: isLoadingApproval } = mutation({
        url: `/administrator/corporates/${currentAccount}/offshore/account/approve`
    });

    const handleSubmit = async (
        values: ApproveRequestValues,
        { resetForm }: FormikHelpers<ApproveRequestValues>
    ) => {
        if (!currentAccount) {
            return;
        }
        mutate(values, {
            onSettled: (_, error) => {
                if (error) {
                    openNotification(
                        'error',
                        getErrorMessage(error) ||
                            'Approve Account, Something went wrong'
                    );
                    return;
                }

                resetForm();
                setShowApproveAccountModal(false);
            }
        });
    };

    return (
        <div className='w-[400px] p-[10px]'>
            <AccountFeatureIcon />
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {({ errors, isValid }) => (
                    <Form autoComplete='off'>
                        <Stack mt='20px' gap='15px'>
                            <h3 className=' text-[#101828] font-medium text-[20px]'>
                                API Credentials
                            </h3>
                            <div>
                                <p className='text-[#101828] text-[16px]'>
                                    Client ID
                                </p>
                                <Field
                                    as={TextField}
                                    error={!!errors.clientId}
                                    helperText={
                                        <ErrorMessage name='clientId' />
                                    }
                                    id='clientId'
                                    name='clientId'
                                    fullWidth
                                    required
                                    sx={{
                                        borderRadius: '8px',
                                        background: '#F9FAFB',
                                        color: '#667085'
                                    }}
                                />
                            </div>

                            <div>
                                <p className='text-[#101828] text-[16px]'>
                                    Client Key
                                </p>
                                <Field
                                    as={TextField}
                                    error={!!errors.clientKey}
                                    helperText={
                                        <ErrorMessage name='clientKey' />
                                    }
                                    id='clientKey'
                                    required
                                    name='clientKey'
                                    fullWidth
                                    type='password'
                                    sx={{
                                        borderRadius: '8px',
                                        background: '#F9FAFB',
                                        color: '#667085'
                                    }}
                                />
                            </div>
                        </Stack>

                        <Stack
                            direction='row'
                            mt='20px'
                            gap='20px'
                            width='100%'
                        >
                            <Button
                                style={{
                                    color: 'black',
                                    flex: '1',
                                    border: '1px solid #D0D5DD',
                                    background: 'white',
                                    boxShadow:
                                        '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                    padding: '10px',
                                    borderRadius: '5px'
                                }}
                                onClick={() =>
                                    setShowApproveAccountModal(false)
                                }
                            >
                                Cancel
                            </Button>

                            <LoadingButton
                                loading={isLoadingApproval}
                                style={{
                                    color: 'white',
                                    flex: '1',
                                    background: '#0088B2',
                                    boxShadow:
                                        '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                    padding: '10px',
                                    borderRadius: '5px'
                                }}
                                type='submit'
                                disabled={!isValid || isLoadingApproval}
                            >
                                Approve
                            </LoadingButton>
                        </Stack>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ApproveAccountDialog;
