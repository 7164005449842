import { useParams } from 'react-router-dom';
import { useGetQuery } from '../../../customhooks/useGetQuery';
import LogisticsInvoiceDetailsSummary from './LogisticsInvoiceDetailsSummary';
import LogisticsInvoicePaymentDetails from './LogisticsInvoicePaymentDetails';
import { InvoicePayment } from './interfaces/InvoicePayment';
import { SalesDocument } from './interfaces/SalesDocuments';
import LogisticsNoPaymentDetails from './LogisticsNoPaymentDetails';
import LogisticsInvoiceDetailsMobileView from './LogisticsInvoiceDetailsMobileView';

const LogisticsInvoiceDetails = () => {
    const { reference } = useParams();

    const { isLoading: isPaymentLoading, entries: paymentDetails } =
        useGetQuery<InvoicePayment[]>({
            url: `/invoice/payment/${reference}`,
            cacheKey: `invoice-payment-details-${reference}`,
            enabled: Boolean(reference)
        });

    const { isLoading: isDetailsLoading, entries: invoiceDetails } =
        useGetQuery<SalesDocument>({
            url: `/sales/documents/${reference}`,
            cacheKey: `invoice-details-${reference}`,
            enabled: Boolean(reference)
        });
    return (
        <>
            <div className='min-h-screen hidden lg:block'>
                <LogisticsInvoiceDetailsSummary
                    {...{ isPaymentLoading, isDetailsLoading, invoiceDetails }}
                />

                {paymentDetails?.length > 0 ? (
                    <LogisticsInvoicePaymentDetails {...{ paymentDetails }} />
                ) : (
                    <LogisticsNoPaymentDetails
                        {...{ invoiceDetails, isDetailsLoading }}
                    />
                )}
            </div>
            <LogisticsInvoiceDetailsMobileView
                {...{
                    paymentDetails,
                    invoiceDetails,
                    isDetailsLoading,
                    isPaymentLoading
                }}
            />
        </>
    );
};

export default LogisticsInvoiceDetails;
