import React, { Dispatch, SetStateAction, useState } from 'react';
import { filter } from '../contracts/FilterContract';
import { Stack, Tooltip } from '@mui/material';
import {
    Button,
    DatePicker,
    DatePickerProps,
    Divider,
    Dropdown,
    Input,
    MenuProps,
    Select,
    Space
} from 'antd';
import {
    contentStyle,
    headingStyle,
    menuStyle,
    subStyle
} from '../../tradeFilter/filterStyle';
import { InvoiceParams } from '../../../../pages/dashboard/payments/interfaces/Invoice';
import { CorporatePartner } from '../../../../interfaces/CorporatePartner';
import { SuitUIButton } from '../../../SuitUIButton';
import CustomModal from '../../../CustomModal';

interface IProps {
    setFilterOptions?: Dispatch<SetStateAction<InvoiceParams>>;
    filterOptions?: InvoiceParams;
    corporatePartners?: CorporatePartner[];
}

const FilterInvoice = ({
    setFilterOptions,
    filterOptions,
    corporatePartners
}: IProps) => {
    const [tempFilterOptions, setTempFilterOptions] = useState<InvoiceParams>(
        filterOptions ?? {}
    );

    const onChangeFrom: DatePickerProps['onChange'] = (date) => {
        setTempFilterOptions({
            ...tempFilterOptions,
            dueDateStart: date?.format('YYYY-MM-DD HH:mm:ss')
        });
    };
    const onChangeTo: DatePickerProps['onChange'] = (date) => {
        setTempFilterOptions({
            ...tempFilterOptions,
            dueDateEnd: date?.format('YYYY-MM-DD HH:mm:ss')
        });
    };
    const onOk = (value: DatePickerProps['value']) => {
        console.log('onOk:');
    };

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const handleMenuClick = () => {
        setFilterOptions && setFilterOptions(tempFilterOptions);
        setDropdownVisible(false);
    };

    const handleButtonClick = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const items = [
        {
            key: '1',
            label: (
                <div>
                    <h2>Exporter</h2>

                    <Select
                        style={{
                            marginTop: '.5rem',
                            width: '100%'
                        }}
                        value={tempFilterOptions?.exporterId}
                        onChange={(value: string) =>
                            setTempFilterOptions({
                                ...tempFilterOptions,
                                exporterId: value
                            })
                        }
                        options={corporatePartners?.map(
                            (item: CorporatePartner) => ({
                                value: item.id,
                                label: item.name
                            })
                        )}
                    />
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '2',
            label: (
                <div>
                    <h2>Consignee</h2>

                    <Select
                        style={{
                            marginTop: '.5rem',
                            width: '100%'
                        }}
                        value={tempFilterOptions?.consigneeId}
                        onChange={(value: string) =>
                            setTempFilterOptions({
                                ...tempFilterOptions,
                                consigneeId: value
                            })
                        }
                        options={corporatePartners?.map(
                            (item: CorporatePartner) => ({
                                value: item.id,
                                label: item.name
                            })
                        )}
                    />
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '3',
            label: (
                <div>
                    <h2>BL Number</h2>
                    <Input
                        type='text'
                        value={tempFilterOptions?.billOfLadingNumber}
                        style={{
                            marginTop: '.5rem'
                        }}
                        onChange={(e) =>
                            setTempFilterOptions({
                                ...tempFilterOptions,
                                billOfLadingNumber: e.target.value
                            })
                        }
                    />
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '4',
            label: (
                <div>
                    <h2>Invoice No</h2>
                    <Input
                        type='text'
                        value={tempFilterOptions?.invoiceNumber}
                        style={{
                            marginTop: '.5rem'
                        }}
                        onChange={(e) =>
                            setTempFilterOptions({
                                ...tempFilterOptions,
                                invoiceNumber: e.target.value
                            })
                        }
                    />
                </div>
            )
        },
        {
            type: 'divider'
        },
        // {
        //   key: '5',
        //   label: (
        //     <div>
        //       <h2>Reference</h2>
        //       <Input
        //         type="text"
        //         value={filterOptions?.reference}
        //         style={{
        //           marginTop: '.5rem',
        //         }}
        //         onChange={(e) =>
        //           setFilterOptions({
        //             ...filterOptions,
        //             reference: e.target.value,
        //           })
        //         }
        //       />
        //     </div>
        //   ),
        // },
        // {
        //   type: 'divider',
        // },
        {
            key: '6',
            label: (
                <div>
                    <h2>Sales Contract No</h2>
                    <Input
                        type='text'
                        value={tempFilterOptions?.salesContractNumber}
                        style={{
                            marginTop: '.5rem'
                        }}
                        onChange={(e) =>
                            setTempFilterOptions({
                                ...tempFilterOptions,
                                salesContractNumber: e.target.value
                            })
                        }
                    />
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '7',
            label: (
                <div>
                    <h2>Due Date Range</h2>
                    <Space
                        style={{
                            padding: 0,
                            marginTop: '.5rem',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <DatePicker
                            placeholder='Start Date'
                            onChange={onChangeFrom}
                            showTime={{ format: 'HH:mm' }}
                            format='YYYY-MM-DD'
                            onOk={onOk}
                        />

                        <DatePicker
                            showTime={{ format: 'HH:mm' }}
                            format='YYYY-MM-DD'
                            placeholder='End Date'
                            onChange={onChangeTo}
                            onOk={onOk}
                        />
                    </Space>
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '5',
            label: (
                <div>
                    <h2>Status</h2>

                    <Select
                        style={{
                            marginTop: '.5rem',
                            width: '100%'
                        }}
                        value={tempFilterOptions?.status}
                        onChange={(value: string) =>
                            setTempFilterOptions({
                                ...tempFilterOptions,
                                status: value
                            })
                        }
                        options={[
                            { value: 0, label: 'Canceled' },
                            { value: 1, label: 'Completed' },
                            { value: 2, label: 'Draft' },
                            { value: 3, label: 'Issued' },
                            { value: 4, label: 'Approved' },
                            { value: 5, label: 'Booking Placed' },
                            { value: 6, label: 'Expired' }
                        ]}
                    />
                </div>
            )
        }
    ];
    const modalItems = items as MenuProps['items'];

    return (
        <>
            <div className='hidden lg:block'>
                <Dropdown
                    menu={{ items: modalItems }}
                    trigger={['click']}
                    visible={dropdownVisible}
                    overlayStyle={{
                        pointerEvents: 'auto'
                    }}
                    dropdownRender={(menu) => (
                        <div style={contentStyle}>
                            <h2 style={headingStyle}>Filters</h2>
                            <p style={subStyle}>
                                Filters will be added to the table.
                            </p>
                            {React.cloneElement(menu as React.ReactElement, {
                                style: menuStyle
                            })}
                            <Divider style={{ margin: 0 }} />
                            <Space
                                style={{
                                    padding: '1rem',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}
                            >
                                <Button
                                    className='reset-btn'
                                    onClick={() => {
                                        setFilterOptions &&
                                            setFilterOptions({});
                                        setTempFilterOptions({});
                                        setDropdownVisible(false);
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button
                                    className='bg-appcolorprimarydeep text-appcolorwhite border-0 hover:bg-appcolorwhite hover:border-appcolorprimarydeep hover:border  hover:text-appcolorprimary'
                                    onClick={handleMenuClick}
                                >
                                    Apply Filter
                                </Button>
                            </Space>
                        </div>
                    )}
                >
                    <Tooltip title='Apply filters' arrow>
                        <div
                            className='tab-buttons'
                            onClick={handleButtonClick}
                        >
                            {filter}
                            <p style={{ paddingRight: '5px' }}>Filter</p>
                        </div>
                    </Tooltip>
                </Dropdown>
            </div>

            <div className='lg:hidden '>
                <CustomModal
                    maxWidth='lg'
                    open={modalVisible}
                    onClose={() => setModalVisible(false)}
                    hideCloseIcon
                >
                    <div className='w-full px-[16px] pt-[40px]'>
                        <h2 className='text-ebony mb-[16px]'>Filters</h2>

                        <Stack gap={2} direction='column'>
                            {items?.map((item) => item?.label)}
                        </Stack>
                        <div className='flex justify-between pt-[40px] pb-[80px]'>
                            <SuitUIButton
                                text='Cancel'
                                onClick={() => setModalVisible(false)}
                                invert
                                style='h-[40px] border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                            />
                            <div className='flex gap-[24px]'>
                                <SuitUIButton
                                    text='Reset'
                                    onClick={() => {
                                        setFilterOptions &&
                                            setFilterOptions({});
                                        setTempFilterOptions({});
                                        setModalVisible(false);
                                    }}
                                    variant='secondary'
                                />
                                <SuitUIButton
                                    text='Apply Filter'
                                    onClick={() => {
                                        handleMenuClick();
                                        setModalVisible(false);
                                    }}
                                    style='h-[40px]'
                                />
                            </div>
                        </div>
                    </div>
                </CustomModal>

                <Tooltip title='Apply filters' arrow>
                    <div
                        className='tab-buttons flex'
                        onClick={() => setModalVisible(true)}
                    >
                        {filter}
                        <p style={{ paddingLeft: '8px' }}>Filter</p>
                    </div>
                </Tooltip>
            </div>
        </>
    );
};

export default FilterInvoice;
