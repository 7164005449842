import { Dispatch, SetStateAction } from 'react';
import { Currency } from '../../payments/interfaces/Currency';
import { CorporatePartner } from '../../../../interfaces/CorporatePartner';
import { Country } from '../../../../interfaces/Country';

export interface CommercialPayload {
    countryOfFinalDestinationName: string | undefined;
    countryOfOriginName: string | undefined;
    logoUrl: string | undefined;
    currencyCodeValue: string | undefined;
    currencyCode?: string | undefined;
    portOfLoadingValue: string | undefined;
    portOfDischargeValue: undefined;
    buyerAddress: string | undefined;
    consigneeAddress: undefined;
    invoiceDate: string | number | null | undefined;
    exporterAddress: string | undefined;
    exporterId: string | number | undefined;
    invoiceNumber: string | undefined;
    consigneeId: string | undefined;
    billOfLadingNumber: string | undefined;
    buyerReference: string | undefined;
    buyerId: string | undefined;
    methodOfDispatch: string | undefined;
    typeOfShipment: string | undefined;
    countryOfOrigin: string | undefined;
    transport: string | undefined;
    voyageNumber: string | undefined;
    countryOfFinalDestinationId: string | undefined;
    portOfLoadingId: string | undefined;
    portOfDischargeId: string | undefined;
    methodOfPayment: string | undefined;
    dateOfDeparture: string | number | null | undefined;
    countryOfFinalDestination: string | undefined;
    marineCoverPolicyNo: string | undefined;
    letterOfCreditNo: string | undefined;
    additionalInformation: string | undefined;
    bankDetails: string | undefined;
    placeOfIssue: string | undefined;
    signatoryCompany: string | undefined;
    nameOfAuthorisedSignatory: string | undefined;
    signatureUrl: string | undefined;
    placeOfOrigin: string | undefined;
    finalDestination: string | undefined;
    termsOfPayment: string | undefined;
    documentName: string | undefined;
    dueDate: string | number | null | undefined;
    buyer?: string;
    exporterName: string | undefined;
    exporterBankName: string | undefined;
    exporterAccountNumber: string | undefined;
    exporterCountry: string | undefined;
    consigneeAccountNumber: string | undefined;
    consigneeName: string | undefined;
    consigneeBankName: string | undefined;
    consigneeCountry: string | undefined;
    salesContractNumber: string | undefined;
    stamp: string | undefined;
    countryOfOriginId?: string | undefined;
}

export interface ParamsInterface {
    edit: number | undefined;
}

export interface OriginalItems {
    id: number;
    productCode: string;
    descriptionOfGoods: string;
    quantity: number;
    unitType: string;
    unitPrice: number;
}

export interface TransformedItems {
    id: number | string;
    productCode: string;
    desc: string | undefined;
    qty: number;
    type: string | undefined;
    price: number;
}

export interface CommercialError {
    countryOfFinalDestinationName: string;
    countryOfOriginName: string;
    portOfLoadingValue: string;
    portOfDischargeValue: string;
    buyerAddress: string;
    consigneeAddress: string;
    invoiceDate: string;
    exporterAddress: string;
    exporterId: string;
    invoiceNumber: string;
    consigneeId: string;
    billOfLadingNumber: string;
    buyerReference: string;
    buyerId: string;
    methodOfDispatch: string;
    typeOfShipment: string;
    countryOfOrigin: string;
    transport: string;
    voyageNumber: string;
    countryOfFinalDestinationId: string;
    portOfLoadingId: string;
    portOfDischargeId: string;
    methodOfPayment: string;
    dateOfDeparture: string;
    countryOfFinalDestination: string;
    marineCoverPolicyNo: string;
    letterOfCreditNo: string;
    additionalInformation: string;
    bankDetails: string;
    placeOfIssue: string;
    signatoryCompany: string;
    nameOfAuthorisedSignatory: string;
    signatureUrl: string;
    placeOfOrigin: string;
    finalDestination: string;
    termsOfPayment: string;
    documentName: string;
    salesContractNumber: string;
    dueDate: string;
    buyer: string;
    logoUrl: string;
    exporterName: string;
    consigneeName: string;
    stamp: string;
    documentUrl: string;
}

export interface CommercialFormBasicInterface {
    errors: CommercialError;
    payload: CommercialPayload;
    updateField: (name: string, value: unknown) => void;
    setShowAddPartner: Dispatch<SetStateAction<boolean>>;
}
export interface CommercialFormInterface extends CommercialFormBasicInterface {
    error: string | null;
    corporatePartners: CorporatePartner[];
    loadingCorporatepartners: boolean;
    quoteFields: TransformedItems[];
    updateQuoteField: (
        index: number,
        attributeName: keyof TransformedItems,
        attributeValue: TransformedItems[keyof TransformedItems]
    ) => void;
    removeQuoteField: (index: number) => void;
    addQuoteField: () => void;
    isCountriesLoading: boolean;
    countries: Country[];
    setSignatureUrlBase64: (value: string) => void;
    signatureUrlBase64: string;
    currencies: Currency[];
    totalAmount?: number;
    loadingCurrencies: boolean;
    setPartnerId: Dispatch<SetStateAction<number | string | undefined>>;
}
export const generateInitialErrors = (): CommercialError => {
    return {
        countryOfFinalDestinationName: '',
        countryOfOriginName: '',
        portOfLoadingValue: '',
        portOfDischargeValue: '',
        buyerAddress: '',
        consigneeAddress: '',
        invoiceDate: '',
        exporterAddress: '',
        exporterId: '',
        invoiceNumber: '',
        consigneeId: '',
        billOfLadingNumber: '',
        buyerReference: '',
        buyerId: '',
        methodOfDispatch: '',
        typeOfShipment: '',
        countryOfOrigin: '',
        transport: '',
        voyageNumber: '',
        countryOfFinalDestinationId: '',
        portOfLoadingId: '',
        portOfDischargeId: '',
        methodOfPayment: '',
        dateOfDeparture: '',
        countryOfFinalDestination: '',
        marineCoverPolicyNo: '',
        letterOfCreditNo: '',
        additionalInformation: '',
        bankDetails: '',
        placeOfIssue: '',
        signatoryCompany: '',
        nameOfAuthorisedSignatory: '',
        signatureUrl: '',
        placeOfOrigin: '',
        finalDestination: '',
        termsOfPayment: '',
        documentName: '',
        salesContractNumber: '',
        documentUrl: '',
        dueDate: '',
        buyer: '',
        logoUrl: '',
        exporterName: '',
        consigneeName: '',
        stamp: ''
    };
};

export interface CommercialSignatureInterface
    extends CommercialFormBasicInterface {
    setSignatureUrlBase64: CommercialFormInterface['setSignatureUrlBase64'];
    signatureUrlBase64: CommercialFormInterface['signatureUrlBase64'];
    setPartnerId: CommercialFormInterface['setPartnerId'];
}

export interface CommercialTransportInterface
    extends CommercialFormBasicInterface {
    isCountriesLoading: boolean;
    countries: Country[];
}

export interface PartnerDetailsInterface extends CommercialFormBasicInterface {
    corporatePartners: CommercialFormInterface['corporatePartners'];
    loadingCorporatepartners: CommercialFormInterface['loadingCorporatepartners'];
}
