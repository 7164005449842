import React, { ReactNode } from 'react';
import Custombutton from '../../../../components/custombutton/custombutton';
import { SuitUIButton } from '../../../../components/SuitUIButton';

export interface financingcardsProps {
    title: ReactNode;
    subTitle: ReactNode;
    getStarted: () => void;
    hasTop?: boolean;
    topText: string;
    imgIcon: string;
    buttonText: string;
    otherColorBg?: string;
    icon: ReactNode;
}

const Financingcards = ({
    title,
    subTitle,
    getStarted,
    hasTop,
    topText,
    imgIcon,
    buttonText,
    otherColorBg,
    icon
}: financingcardsProps) => {
    return (
        <>
            {hasTop ? (
                <div className='hidden lg:flex items-center justify-between'>
                    <div className='flex'>
                        <div
                            className={`bg-appcolorwhite trapezoid pt-[0.8rem] pb-[0.7rem] pl-[1.6rem] w-fit pr-[5.6rem] cursor-pointer text-[17px] leading-[24px] rounded-l-md rounded-b-none ${
                                otherColorBg !== ''
                                    ? 'bg-appcolordeepdarkpurple text-appcolorwhite'
                                    : ''
                            }`}
                        >
                            {topText}
                        </div>
                    </div>
                </div>
            ) : null}

            <div
                className={`p-4 border border-lightGray lg:border-none lg:py-[0.5rem]  lg:px-[1.3rem] rounded-[8px] lg:rounded-[0px] lg:rounded-b-md lg:rounded-r-md bg-appcolorwhite lg:pb-[2.4rem] mb-4 ${otherColorBg} pt-4 max-h-[250px]`}
            >
                <div className='lg:flex items-center justify-between gap-6'>
                    <div className='hidden lg:block flex-1'>
                        <img src={imgIcon} alt='dashicon' className='' />
                    </div>

                    <div className='mb-[16px] lg:hidden'>{icon}</div>

                    <div className='flex-1'>
                        <div className='text-base mb-[4px] lg:mb-4 lg:text-[18px] lg:leading-[100%] text-ebony lg:text-thinGrey'>
                            {title}
                        </div>

                        <div
                            className={`text-sm mb-4 text-paleSky lg:text-thinGrey `}
                        >
                            {subTitle}
                        </div>

                        <div className='hidden lg:block'>
                            <Custombutton
                                variant='contained'
                                styles={{
                                    padding: '0.5rem 0.75rem .5rem 0.75rem'
                                }}
                                disabled={false}
                                buttonText={buttonText}
                                size='small'
                                onClickAction={() => {
                                    getStarted();
                                }}
                            />
                        </div>

                        <SuitUIButton
                            text='Apply now'
                            variant='secondary'
                            onClick={getStarted}
                            style='lg:hidden'
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

Financingcards.defaultProps = {
    otherColorBg: ''
};

export default Financingcards;
