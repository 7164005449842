export const AccountFeatureIcon = () => {
    return (
        <svg
            width='56'
            height='56'
            viewBox='0 0 56 56'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                x='4'
                y='4'
                width='48'
                height='48'
                rx='24'
                fill='#0088B2'
                fillOpacity='0.1'
            />
            <rect
                x='4'
                y='4'
                width='48'
                height='48'
                rx='24'
                stroke='#0088B2'
                strokeOpacity='0.1'
                strokeWidth='8'
            />
            <path
                d='M23.2 30.8C23.2 29.6954 24.0954 28.8 25.2 28.8H30.8C31.9045 28.8 32.8 29.6954 32.8 30.8V36H23.2V30.8Z'
                stroke='#0088B2'
                strokeWidth='1.5'
            />
            <path
                d='M24.8 20V23C24.8 23.5523 25.2478 24 25.8 24H30.2C30.7523 24 31.2 23.5523 31.2 23V20'
                stroke='#0088B2'
                strokeWidth='1.5'
            />
            <path
                d='M20 22C20 20.8954 20.8954 20 22 20H28H30.3716C30.902 20 31.4107 20.2107 31.7858 20.5858L35.4142 24.2142C35.7893 24.5893 36 25.098 36 25.6284V28V34C36 35.1046 35.1046 36 34 36H22C20.8954 36 20 35.1046 20 34V22Z'
                stroke='#3C3C3C'
                strokeWidth='1.5'
            />
        </svg>
    );
};
