import axios from 'axios';
import { REACT_APP_BASE_URL } from '../config';

export const http = axios.create({
    baseURL: REACT_APP_BASE_URL
});

export const httpNoAuth = axios.create({
    baseURL: REACT_APP_BASE_URL
});

export const setAuthToken = (token: string | null) => {
    if (token !== null) {
        http.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        http.defaults.headers.common['x-access-token']= `${token}`;
    } else {
        delete http.defaults.headers.common['Authorization'];
    }
};

http.interceptors.response.use(
    (response) => response,
    (error) => {
        if (
            !error.response ||
            (error.response && error.response.status === 401)
        ) {
            window.location.replace('/login');
            ['refreshToken', 'exp', 'token'].forEach((item) =>
                localStorage.removeItem(item)
            );
            return;
        }

        return Promise.reject(error);
    }
);
