import { useEffect, useState } from 'react';
import { useGetQuery } from '../useGetQuery';

export interface Industry {
    id: number;
    name: string;
}

const useIndustries = () => {
    const { isLoading: isIndustriesLoading, entries } = useGetQuery<Industry[]>(
        {
            url: '/business/industry',
            cacheKey: 'industries'
        }
    );
    const [industries, setIndustries] = useState<Industry[]>([] as Industry[]);

    useEffect(() => {
        setIndustries(entries || []);
    }, [entries]);

    return { industries, isIndustriesLoading };
};

export default useIndustries;
