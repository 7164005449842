export const ProfileCircleIcon = () => (
    <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M8.0799 8.51999C8.03323 8.51332 7.97323 8.51332 7.9199 8.51999C6.74657 8.47999 5.81323 7.51999 5.81323 6.33999C5.81323 5.13332 6.78657 4.15332 7.9999 4.15332C9.20657 4.15332 10.1866 5.13332 10.1866 6.33999C10.1799 7.51999 9.25323 8.47999 8.0799 8.51999Z'
            stroke='#667085'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M12.4933 12.92C11.3066 14.0067 9.73326 14.6667 7.99993 14.6667C6.26659 14.6667 4.69326 14.0067 3.50659 12.92C3.57326 12.2934 3.97326 11.68 4.68659 11.2C6.51326 9.98671 9.49992 9.98671 11.3133 11.2C12.0266 11.68 12.4266 12.2934 12.4933 12.92Z'
            stroke='#667085'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.99992 14.6666C11.6818 14.6666 14.6666 11.6818 14.6666 7.99992C14.6666 4.31802 11.6818 1.33325 7.99992 1.33325C4.31802 1.33325 1.33325 4.31802 1.33325 7.99992C1.33325 11.6818 4.31802 14.6666 7.99992 14.6666Z'
            stroke='#667085'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
