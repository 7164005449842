import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import Alert from '@mui/material/Alert';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import Authouterpage from '../../components/authouterpage/authouterpage';
import Passwordinput from '../../components/passwordinput/passwordinput';
import { getErrorMessage, validateEmail } from '../../utils/helper';
import { loginUser, logoutUser } from '../../store/userSlice/userSlice';
import EnablingFeature from '../../components/EnablingFeature';
import CustomTextField from '../../components/CustomTextInput';
import { SuitUIButton } from '../../components/SuitUIButton';
import { mutateConfig } from '../../api/queryBase';

const Signin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const mutation = mutateConfig();
    const { mutate } = mutation({
        url: '/login'
    });

    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState<any>({
        username: undefined,
        userSecret: undefined
    });
    const [errors, setErrors] = useState({
        username: '',
        userSecret: ''
    });
    const [error, setError] = useState<string | any>(null);

    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        setErrors((prevState: any) => ({
            ...prevState,
            username: '',
            userSecret: ''
        }));

        mutate(payload, {
            onSettled: (res, error: AxiosError | undefined | any) => {
                setLoading(false);
                if (error) {
                    setError(
                        getErrorMessage(error) ||
                            'An error has occured. Please try again.'
                    );
                } else {
                    const { access_token, refresh_token } = res?.payload as {
                        access_token: string;
                        refresh_token: string;
                    };

                    const user: any = jwt_decode(access_token);

                    localStorage.setItem('token', access_token);
                    localStorage.setItem('refreshToken', refresh_token);
                    localStorage.setItem('exp', user.exp);

                    dispatch(
                        loginUser({
                            token: access_token,
                            user,
                            refresh: refresh_token
                        })
                    );
                    navigate('/dashboard');
                }
            }
        });
    };

    useEffect(() => {
        if (payload !== null) {
            setErrors((prevState) => ({
                ...prevState,
                username:
                    payload?.username === undefined
                        ? ''
                        : validateEmail(payload?.username)
                        ? ''
                        : 'Invalid email address',
                userSecret:
                    payload?.userSecret === undefined
                        ? ''
                        : payload?.userSecret.length > 0
                        ? ''
                        : 'Password is required'
            }));
        }
    }, [payload]);

    useEffect(() => {
        dispatch(logoutUser());
    }, []);

    return (
        <div>
            <Authouterpage
                title='SIGN IN'
                info='Lets get you back'
                infoText='Create a free account or sign in to get started using FrontEdge'
            >
                <div>
                    <div className='bg-appcolorwhite py-[2.7rem] lg:py-[3.5rem] lg:px-[2.6rem] px-[1.6rem] mb-[1.75rem] rounded-[3px]'>
                        {error !== null && (
                            <Alert severity='error'>{error}</Alert>
                        )}

                        <form autoComplete='off' onSubmit={onSubmit} noValidate>
                            <div className='flex flex-col gap-4 lg:gap-[24px]'>
                                <CustomTextField
                                    error={errors?.username.length > 0}
                                    helperText={
                                        errors?.username.length > 0
                                            ? errors?.username
                                            : undefined
                                    }
                                    required
                                    id='signin-email'
                                    label='Email'
                                    name='username'
                                    type='email'
                                    onChange={(e: any) => {
                                        updateField('username', e.target.value);
                                    }}
                                />

                                <Passwordinput
                                    id='signin-password'
                                    label='Password'
                                    name='userSecret'
                                    updateField={updateField}
                                    error={errors?.userSecret.length > 0}
                                    helperText={
                                        errors?.userSecret.length > 0
                                            ? errors?.userSecret
                                            : undefined
                                    }
                                />
                            </div>

                            <p className='mt-4 text-[13px] text-appcolorprimary leading-[16px]'>
                                <span
                                    className='cursor-pointer'
                                    onClick={() => {
                                        navigate('/password/forgot');
                                    }}
                                >
                                    Forgot Password?
                                </span>
                            </p>

                            <div className='mt-4'>
                                <SuitUIButton
                                    isLoading={loading}
                                    type='submit'
                                    fullWidth
                                    disabled={
                                        Object.values(payload).some(
                                            (item: any) => item === undefined
                                        ) ||
                                        Object.values(errors).some(
                                            (item) => item.length > 0
                                        ) ||
                                        loading
                                    }
                                    text='Continue'
                                />
                            </div>
                        </form>

                        <EnablingFeature flag='DirectSignUp'>
                            <p className='mt-4 text-sm text-appcolorfaint leading-[18px]'>
                                Dont't have an account?{' '}
                                <span
                                    className='underline cursor-pointer text-appcolorprimary'
                                    onClick={() => {
                                        navigate('/signup');
                                    }}
                                >
                                    Sign Up
                                </span>
                            </p>
                        </EnablingFeature>
                    </div>
                </div>
            </Authouterpage>
        </div>
    );
};

export default Signin;
