import { cloneElement, ReactElement, useRef, useState } from 'react';
import { Carousel } from 'antd';
import { CarouselRef } from '../interfaces/SliderInterfaces';
import { RoundedArrowIcon } from './icons';
import { SuitUIButton } from './SuitUIButton';
import { WalletBalancesSkeleton } from './walletbalances/WalletBalancesSkeleton';

interface CustomCarouselProps {
    data: { [key: string]: string | ReactElement | number }[];
    isLoading?: boolean;
    component?: ReactElement;
    actions?: {
        title: string;
        icon?: ReactElement;
        onHandleClick: () => void;
    }[];
}

const CustomCarousel = ({
    data,
    isLoading,
    component,
    actions
}: CustomCarouselProps) => {
    const slider = useRef<CarouselRef>(null);

    const [slideIndex, setSlideIndex] = useState(0);

    return (
        <div className='lg:hidden max-w-[calc(100vw-32px)]'>
            <Carousel ref={slider} autoplay afterChange={setSlideIndex}>
                {isLoading ? (
                    <div className='bg-white  p-5 rounded-lg'>
                        <WalletBalancesSkeleton length={1} />
                    </div>
                ) : (
                    data.map((item, index) => (
                        <>
                            {component ? (
                                cloneElement(component, {
                                    key: index,
                                    item
                                })
                            ) : (
                                <div className='flex grow h-[107px] justify-between items-center bg-white py-[17px] px-[14px] border-lightGray border rounded-[5px] lg:p-0 lg:border-none'>
                                    <div>
                                        <p className='text-sm text-appcolorfaintgreyblue'>
                                            {item?.label}
                                        </p>
                                        <h3 className='text-2xl text-charcoal font-medium'>
                                            {item?.value}
                                        </h3>
                                    </div>

                                    {item?.icon}
                                </div>
                            )}
                        </>
                    ))
                )}
            </Carousel>

            <div className='flex justify-between items-center mt-[16px]'>
                <div className='flex gap-[8px] items-center'>
                    {data?.map((item, index) => (
                        <div
                            key={index}
                            className={`w-[8px] h-[8px] rounded-[50%] ${
                                slideIndex !== index
                                    ? 'bg-lavender'
                                    : 'bg-appcolorprimary'
                            }`}
                        />
                    ))}
                </div>
                <div className='flex gap-[32px] items-center'>
                    <button
                        onClick={() => slider.current?.prev()}
                        className='rotate-180'
                    >
                        <RoundedArrowIcon />
                    </button>
                    <button onClick={() => slider.current?.next()}>
                        <RoundedArrowIcon />
                    </button>
                </div>
            </div>

            {actions?.length ? (
                <>
                    {actions?.length === 3 ? (
                        <div className='my-[1rem] lg:hidden justify-between '>
                            <SuitUIButton
                                text={actions?.[0]?.title}
                                onClick={actions?.[0]?.onHandleClick}
                                invert
                                fullWidth
                                iconLeft={actions?.[0]?.icon}
                                style='border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                            />
                            <div className='flex gap-[8px] mt-3'>
                                {actions
                                    .slice(1)
                                    .map(
                                        (
                                            { title, icon, onHandleClick },
                                            index
                                        ) => (
                                            <SuitUIButton
                                                key={index}
                                                text={title}
                                                onClick={onHandleClick}
                                                invert
                                                fullWidth
                                                iconLeft={icon}
                                                style='border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                                            />
                                        )
                                    )}
                            </div>
                        </div>
                    ) : (
                        <div className='mt-[24px] grid grid-cols-2 gap-[8px] lg:hidden'>
                            {actions?.map(({ title, icon, onHandleClick }) => (
                                <SuitUIButton
                                    key={title}
                                    text={title}
                                    onClick={onHandleClick}
                                    invert
                                    fullWidth
                                    iconLeft={icon}
                                    style='border border-gray300 text-steelBlue font-medium text-sm leading-[20px] px-0'
                                />
                            ))}
                        </div>
                    )}
                </>
            ) : null}
        </div>
    );
};

export default CustomCarousel;
