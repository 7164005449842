import { Box } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

export const UserProfileTabs = ({
    tabs,
    selected,
    setSelected
}: {
    tabs: { key: string; label: string; allowView: boolean }[];
    selected: string;
    setSelected: Dispatch<SetStateAction<string>>;
}) => {
    return (
        <Box className='flex lg:space-x-10 space-x-6 px-4 lg:px-0 lg:items-end items-center'>
            {tabs
                .filter(({ allowView }) => allowView)
                .map(({ label, key }) => (
                    <Box
                        key={label}
                        onClick={() => setSelected(key)}
                        className={`lg:pb-3 cursor-pointer lg:text-base text-[14px] font-semibold lg:font-normal ${
                            key === selected
                                ? 'lg:border-b-2 lg:border-b-black lg:rounded-none font-medium lg:text-black lg:bg-transparent bg-appcolorprimary text-white p-2 rounded-md'
                                : 'text-[#697386] '
                        }`}
                    >
                        <span className='capitalize'>{label}</span>
                    </Box>
                ))}
        </Box>
    );
};
