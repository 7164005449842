import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Outletouterpage from '../../../../components/dashboard/outletouterpage/outletouterpage';
import { Dropdown, Progress, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import type { MenuProps } from 'antd';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { commaNumber } from '../../../../utils/helper';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

const dataSource = [
    {
        id: 'FEFINANCE123',
        createdDate: '',
        debtorName: '',
        totalRecievables: '500,000',
        fundaAvailable: 0,
        fundsInUse: 0,
        noOfInvoices: 0,
        status: 1
    },
    {
        id: 'FEFINANCE124',
        createdDate: 'March 17, 2023 4:04 PM',
        debtorName: 'Nestle',
        totalRecievables: '1,500,009',
        fundaAvailable: '300,000',
        fundsInUse: ' 254,423.434',
        noOfInvoices: 6,
        status: 1
    }
];

const Financingoverview = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [currentData, setCurrentData] = useState<any>('');

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        navigate(`/financing/application/${currentData?.id}`);
                    }}
                >
                    <img src='/img/vectors/eyesicon.svg' alt='eyesicon' />
                    <span className='ml-2'>Review Application</span>
                </div>
            )
        }
    ];

    const columns: ColumnsType<any> = [
        {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (_, { createdDate }) => (
                <span>
                    {createdDate === '' ? (
                        <>{moment(moment.now()).format('ll')}</>
                    ) : (
                        <>{createdDate}</>
                    )}
                </span>
            )
        },
        {
            title: 'Debtor Name',
            dataIndex: 'debtorName',
            key: 'debtorName',
            render: (text) => (
                <span className=' text-appcolorprimary'>{text}</span>
            )
        },
        {
            title: 'Total Receivables',
            dataIndex: 'totalRecievables',
            key: 'totalRecievables',
            render: (text) => <span>${commaNumber(text)}</span>
        },
        {
            title: 'Fund Available',
            dataIndex: 'fundaAvailable',
            key: 'fundaAvailable',
            render: (text) => <span>${commaNumber(text)}</span>
        },
        {
            title: 'Funds in Use',
            dataIndex: 'fundsInUse',
            key: 'fundsInUse',
            render: (text) => <span>${commaNumber(text)}</span>
        },
        {
            title: 'No of Invoices',
            dataIndex: 'noOfInvoices',
            key: 'noOfInvoices',
            render: (text) => (
                <div className='flex items-center'>
                    <span className='mr-2 text-appcolorprimary'>
                        {commaNumber(text)}
                    </span>{' '}
                    <img
                        src='/img/vectors/featuredicon.svg'
                        alt='featuredicon'
                    />
                </div>
            )
        },
        {
            title: 'Status',
            render: (_, data) => (
                <div
                    className='cursor-pointer'
                    onClick={() => {
                        setCurrentData(data);
                    }}
                >
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <img src='/img/vectors/more.svg' alt='more' />
                    </Dropdown>
                </div>
            )
        }
    ];

    return (
        <div>
            <Outletouterpage
                breadcrumbs={[
                    {
                        title: 'Home',
                        link: '/dashboard'
                    },
                    {
                        title: 'Financing',
                        link: '/financing'
                    },
                    {
                        title: 'Overview',
                        link: '/financing/overview'
                    }
                ]}
            >
                <div className='flex justify-between'>
                    <h1 className='text-[32px] leading-[23px] text-appcolorblacktwo'>
                        Financing
                    </h1>

                    <p
                        className='w-fit flex items-center cursor-pointer bg-appcolorprimary rounded-[10px] py-[0.7rem] px-[0.9rem] mr-2'
                        onClick={() => {
                            navigate('/financing/export-finance/request');
                        }}
                    >
                        <img
                            src='/img/vectors/brokenplus.svg'
                            alt='plaindownload'
                            className='h-[1.1rem]'
                        />
                    </p>
                </div>

                <div className='mt-8'>
                    {location.state !== null &&
                        location.state?.newFinanceRequestAlert && (
                            <Alert
                                icon={
                                    <img
                                        src='/img/vectors/infosquare.svg'
                                        alt='infosquare'
                                    />
                                }
                                severity='info'
                                sx={{
                                    background: 'rgba(0, 136, 178, 0.06)',
                                    borderRadius: '3px',
                                    border: '1px solid #0088B2',
                                    color: theme.palette.primary.main
                                }}
                            >
                                Your export finance application has been
                                successfully submitted. Our credit unit will
                                review your financing request and will be in
                                contact in the next 24-48 hours
                            </Alert>
                        )}

                    <div className='mt-8'>
                        <div className='bg-appcolorwhite py-[2.5rem] px-[3.1rem] rounded-[3px] shadow-[0px 14px 24px rgba(51, 51, 51, 0.01)] mb-8'>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} lg={6}>
                                    <div>
                                        <p className='text-appcolorfaintsix text-[14px] leading-[18px] mb-3'>
                                            Credit Line
                                        </p>
                                        <p className='font-bold text-[22px] leading-[90%] text-appcolorprimary'>
                                            $554,423.434
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12} lg={2}>
                                    <div>
                                        <p className='text-appcolorfaintsix text-[14px] leading-[18px] mb-3'>
                                            In Use
                                        </p>
                                        <p className='font-bold text-[22px] leading-[90%]'>
                                            $254,423.434
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12} lg={2}>
                                    <div>
                                        <p className='text-appcolorfaintsix text-[14px] leading-[18px] mb-3'>
                                            Available
                                        </p>
                                        <p className='font-bold text-[22px] leading-[90%]'>
                                            $300,000
                                        </p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={12} lg={2}>
                                    <div>
                                        <p className='text-appcolorfaintsix text-[14px] leading-[18px] mb-3'>
                                            Amount Requested
                                        </p>
                                        <p className='font-bold text-[22px] leading-[90%]'>
                                            $1,000,000
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        <div className='bg-appcolorwhite py-[1.5rem] px-[3.1rem] rounded-[3px] mb-8'>
                            <Grid container alignItems='center'>
                                <Grid item xs={12} md={12} lg={2}>
                                    <Progress
                                        strokeColor='#0088B2'
                                        type='dashboard'
                                        percent={80}
                                        format={(percent) => {
                                            return (
                                                <p>
                                                    <span className='text-appcolorprimary text-[30px] leading-[38px] font-bold block'>
                                                        {percent}%
                                                    </span>
                                                    <span className='text-xs'>
                                                        Processing
                                                    </span>
                                                </p>
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={3}>
                                    <div className='flex flex-col '>
                                        <p className='text-[13px] leading-[16px] text-appcolorfaintsix mb-1'>
                                            Product
                                        </p>
                                        <p className='flex items-center w-fit'>
                                            <img
                                                src='/img/vectors/going.svg'
                                                alt='going'
                                                className='mr-3'
                                            />
                                            <span className='text-[14px] leading-[18px] text-appcolorfaintsix'>
                                                Factoring
                                            </span>
                                        </p>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={12} lg={2}>
                                    <div className='flex flex-col '>
                                        <p className='text-[13px] leading-[16px] text-appcolorfaintsix mb-1'>
                                            Account Active
                                        </p>
                                        <p className='flex items-center w-fit'>
                                            <img
                                                src='/img/vectors/going.svg'
                                                alt='going'
                                                className='mr-3'
                                            />
                                            <span className='text-[14px] leading-[18px] text-appcolorfaintsix'>
                                                Pending approval
                                            </span>
                                        </p>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={12} lg={3}>
                                    <div className='flex flex-col pl-16'>
                                        <p className='text-[13px] leading-[16px] text-appcolorfaintsix mb-1'>
                                            Date Submitted
                                        </p>
                                        <p className='flex items-center w-fit'>
                                            <img
                                                src='/img/vectors/going.svg'
                                                alt='going'
                                                className='mr-3'
                                            />
                                            <span className='text-[14px] leading-[18px] text-appcolorfaintsix'>
                                                {moment(moment.now()).format(
                                                    'll'
                                                )}
                                            </span>
                                        </p>
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={12} lg={2}>
                                    <div className='flex justify-end'>
                                        <p className='rounded-[3px] shadow-[0px 1px 2px rgba(16, 24, 40, 0.05)] flex justify-center bg-appcolorfaintyellow w-fit px-[0.6rem] py-[0.4rem] items-center'>
                                            <span className='w-2 h-2 mr-3 bg-appcoloryellow rounded-[50%]'></span>
                                            <span className='text-[14px] leading-[20px] font-bold text-appcoloryellow'>
                                                Pending
                                            </span>
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                        <div className='relative mt-8'>
                            <div className='bg-appcolorwhite rounded-[3px] pb-0 px-4 absolute right-0 top-[-0.5rem]'>
                                <div className=''>
                                    <InputBase
                                        fullWidth
                                        placeholder='Search Application'
                                        startAdornment={
                                            <InputAdornment position='start'>
                                                <SearchIcon />
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                            </div>

                            <div>
                                <div className='flex items-center justify-between'>
                                    <div className='flex justify-between'>
                                        <div
                                            className={`bg-appcolorwhite trapezoid pt-[0.8rem] pb-[0.7rem] pl-[1.6rem] w-fit pr-[5.6rem] cursor-pointer text-[17px] leading-[24px] flex`}
                                        >
                                            <p className='rounded-[3px] shadow-[0px 1px 2px rgba(16, 24, 40, 0.05)] flex justify-center hover:bg-appcolorprimaryfainttwo w-fit px-[0.5rem] py-[0.3rem] items-center mr-4'>
                                                <span className='w-2 h-2 mr-3 bg-appcolorprimary rounded-[50%]'></span>
                                                <span className='text-[14px] leading-[20px] font-bold text-appcolorblackfour'>
                                                    Recent
                                                </span>
                                            </p>

                                            <p className='rounded-[3px] shadow-[0px 1px 2px rgba(16, 24, 40, 0.05)] flex justify-center hover:bg-appcolorgreenfaint w-fit px-[0.5rem] py-[0.3rem] items-center mr-4'>
                                                <span className='w-2 h-2 mr-3 bg-appcolorgreen rounded-[50%]'></span>
                                                <span className='text-[14px] leading-[20px] font-bold text-appcolorblackfour'>
                                                    Accepted
                                                </span>
                                            </p>

                                            <p className='rounded-[3px]  shadow-[0px 1px 2px rgba(16, 24, 40, 0.05)] flex justify-center hover:bg-appcolorfaintyellow w-fit px-[0.5rem] py-[0.3rem] items-center'>
                                                <span className='w-2 h-2 mr-3 bg-appcoloryellow rounded-[50%]'></span>
                                                <span className='text-[14px] leading-[20px] font-bold text-appcolorblackfour'>
                                                    Pending
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='py-[0.5rem] px-[1.3rem] bg-appcolorwhite'>
                                    <Table
                                        // pagination={false}
                                        loading={loading}
                                        columns={columns}
                                        dataSource={dataSource}
                                        rowKey={'id'}
                                        locale={{
                                            emptyText: () => {
                                                return (
                                                    <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                                                        <div className='flex justify-center mb-4'>
                                                            <img
                                                                src='/img/vectors/nodatacross.svg'
                                                                alt='nodata'
                                                            />
                                                        </div>
                                                        <p className='text-center'>
                                                            No data found
                                                        </p>
                                                    </div>
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Outletouterpage>
        </div>
    );
};

export default Financingoverview;
