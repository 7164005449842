import {
    Autocomplete,
    CircularProgress,
    debounce,
    MenuItem
} from '@mui/material';
import React, { ReactNode, useEffect } from 'react';
import { useState } from 'react';
import CustomTextField from '../CustomTextInput';

export interface GenericAutocompleteProps {
    apiDataKey: string;
    apiFunction: (q: string) => Promise<any>;
    displayFunction: (item: any) => object;
    error: boolean;
    helperText?: string;
    iconPrefix?: ReactNode;
    id: string;
    initialSearch?: string;
    isRequired?: boolean;
    isSnug?: boolean;
    label: string;
    labelFunction: (e: any) => string;
    name: string;
    nameValue?: string;
    updateField: (name: string, value: any) => void;
    updateFieldValue: (name: string, value: any) => void;
    value?: any;
}

const GenericAutocomplete = (props: GenericAutocompleteProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [options, setOptions] = useState<any[]>([]);

    const apiCall = async (
        endpointFunction: (query: string) => Promise<any>,
        value: string,
        preSelect?: boolean
    ): Promise<any[]> => {
        setLoading(true);
        const response = await endpointFunction(value);
        const { content } = response.data.data;
        const displayOptions = content.map((item: any) =>
            props.displayFunction(item)
        );
        setOptions(displayOptions.reverse());
        setLoading(false);
        return displayOptions;
    };

    const getOptions = debounce((value: string) => {
        if (value.trim() !== '') {
            apiCall(props.apiFunction, value, false);
        }
    }, 300);

    useEffect(() => {
        apiCall(props.apiFunction, props.initialSearch ?? 'Nigeria', true);
    }, []);

    return (
        <>
            <Autocomplete
                disableClearable
                id={props.id}
                options={options}
                placeholder={props.label}
                value={props.value}
                renderInput={(params) => (
                    <CustomTextField
                        name=''
                        params={params}
                        required={props.isRequired}
                        label={`${props.label}`}
                        placeholder={'Search'}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: props.iconPrefix,
                            endAdornment: loading && (
                                <React.Fragment>
                                    <CircularProgress size={20} />
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                        onChange={(e: any) => {
                            getOptions(e.target.value);
                        }}
                    />
                )}
                noOptionsText={'No data available'}
                renderOption={(props, option: any) => (
                    <MenuItem
                        {...props}
                        value={option.toDisplay}
                        key={`ports-${option.id}`}
                    >
                        {option.toDisplay}
                    </MenuItem>
                )}
                getOptionLabel={props.labelFunction}
                onChange={(event: any, value: any) => {
                    if (value !== null) {
                        props.updateField(props.name, value.toDisplay);
                        if (
                            props.updateFieldValue !== undefined &&
                            props.nameValue !== undefined
                        ) {
                            props.updateFieldValue(props.nameValue, value);
                        }
                    }
                }}
            />
        </>
    );
};

export default GenericAutocomplete;

GenericAutocomplete.defaultProps = {
    apiDataKey: 'content'
};
