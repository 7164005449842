import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Custombutton from '../../../../components/custombutton/custombutton';
import { shippingLinesInterfaceArray } from '../../../../customhooks/shippinglines/userShippinglines';
import { openNotification } from '../../../../utils/helper';
import { createBookingExisting } from '../../../../api/booking/booking';
import NewCustomSelect from '../../../../components/newcustomselect/newcustomselect';
import { useTheme, useMediaQuery } from '@mui/material';

export interface existingbookingdrawerProps {
    closeDrawer: (refreshList?: boolean) => void;
    showDrawer: boolean;
    isLoadingShippingLines: boolean;
    shippingLines: shippingLinesInterfaceArray;
}

const shippingLines: any[] = [];
const isLoadingShippingLines = false;

const Existingbookingdrawer = ({
    closeDrawer,
    showDrawer,
    shippingLines,
    isLoadingShippingLines
}: existingbookingdrawerProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [error, setError] = useState<string | any>(null);
    const [loading, setLoading] = useState(false);

    const [payload, setPayload] = useState<any>({
        billOfLadingNumber: undefined,
        shippingLineId: undefined,
        salesContractNumber: undefined,
        agent: undefined,
        trackingType: 'BL'
    });
    const [errors, setErrors] = useState<any>({
        billOfLadingNumber: '',
        shippingLineId: '',
        salesContractNumber: '',
        agent: '',
        trackingType: ''
    });

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrors((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));
    };

    /**
     * Submit form
     * @param e Object
     */
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        setErrors((prevState: any) => ({
            ...prevState,
            billOfLadingNumber: '',
            shippingLineId: '',
            salesContractNumber: '',
            agent: ''
        }));

        try {
            const res = await createBookingExisting(payload);

            const { data } = res.data;

            setLoading(false);

            openNotification(
                'success',
                'Existing shipment created successfully'
            );

            closeDrawer(true);
        } catch (error) {
            setLoading(false);
            if (error?.response) {
                if (error?.response?.data?.error) {
                    let hasFieldNameAttribute = false;

                    error?.response?.data?.error.map((item: any) => {
                        return Object.keys(item).map((itemInside: string) => {
                            if (itemInside === 'fieldName') {
                                hasFieldNameAttribute = true;

                                return;
                            }
                        });
                    });

                    if (hasFieldNameAttribute) {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>
                                    {element.messageError}
                                </li>
                            );
                        });
                        setError(errorList);
                    } else {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>{element}</li>
                            );
                        });
                        setError(errorList);
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    return (
        <div>
            <div
                className='slide-in-right active bg-appcolorwhite rounded-[50%] top-[1.2rem] right-[28.4rem] fixed p-4 z-[2000] cursor-pointer'
                onClick={() => {
                    closeDrawer();
                }}
            >
                <img src='/img/vectors/cross.svg' alt='cross' />
            </div>

            <Drawer
                anchor={isMobile ? 'bottom' : 'right'}
                open={showDrawer}
                onClose={() => {
                    closeDrawer();
                }}
                sx={{
                    '.MuiDrawer-paperAnchorRight': {
                        width: {
                            xs: '95%',
                            lg: '30rem'
                        }
                    }
                }}
            >
                <div className='px-[3.2rem] py-[2.1rem]'>
                    <h1 className='text-[24px] leading-[23px] text-appcolorblacktwo font-semibold'>
                        Add an existing booking
                    </h1>

                    {error !== null && (
                        <div className='mt-10'>
                            <Alert severity='error'>{error}</Alert>
                        </div>
                    )}

                    <div className='my-4'>
                        <form autoComplete='off' onSubmit={onSubmit} noValidate>
                            <div className='mt-4'>
                                <NewCustomSelect
                                    selectValue={payload?.trackingType}
                                    required
                                    selectOptions={[
                                        {
                                            name: 'Bill of lading',
                                            value: 'BL'
                                        },
                                        {
                                            name: 'Booking Number',
                                            value: 'BK'
                                        }
                                    ]}
                                    name={'trackingType'}
                                    updateField={(_, value) => {
                                        updateField('trackingType', value);
                                    }}
                                    label={'Tracking Type'}
                                    alternativeValue='value'
                                    selectOptionLabel='name'
                                    loading={false}
                                />
                            </div>

                            <TextField
                                value={payload?.billOfLadingNumber}
                                error={errors?.billOfLadingNumber.length > 0}
                                helperText={
                                    errors?.billOfLadingNumber.length > 0
                                        ? errors?.billOfLadingNumber
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='existingbooking-billOfLadingNumber'
                                label={
                                    payload?.trackingType === 'BL'
                                        ? 'Bill of lading'
                                        : 'Booking Number'
                                }
                                name='billOfLadingNumber'
                                onChange={(e: any) => {
                                    updateField(
                                        'billOfLadingNumber',
                                        e.target.value
                                    );
                                }}
                            />

                            <Autocomplete
                                disableClearable
                                // value={payload?.countryId}
                                // loading={isCountriesLoading}
                                id='signup-shippinglineid'
                                options={shippingLines}
                                sx={{
                                    marginTop: '1rem',
                                    '.MuiInputBase-input': {
                                        height: '0.3rem'
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Shipping Line'
                                        required={true}
                                        error={
                                            errors?.shippingLineId.length > 0
                                        }
                                        helperText={
                                            errors?.shippingLineId.length > 0
                                                ? errors?.shippingLineId
                                                : undefined
                                        }
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment:
                                                isLoadingShippingLines && (
                                                    <React.Fragment>
                                                        <CircularProgress
                                                            size={20}
                                                        />
                                                        {
                                                            params.InputProps
                                                                .endAdornment
                                                        }
                                                    </React.Fragment>
                                                )
                                        }}
                                    />
                                )}
                                noOptionsText={'No data available'}
                                renderOption={(props, option: any) => (
                                    <MenuItem
                                        {...props}
                                        value={option.id}
                                        key={`shippingline-${option.id}`}
                                    >
                                        <div className='flex items-center'>
                                            <span className='ml-2'>
                                                {option.name}
                                            </span>
                                        </div>
                                    </MenuItem>
                                )}
                                getOptionLabel={(option) => option.name}
                                onChange={(e: any, value: any) => {
                                    if (value !== null) {
                                        updateField(
                                            'shippingLineId',
                                            value.id.toString()
                                        );
                                    }
                                }}
                            />

                            <TextField
                                value={payload?.salesContractNumber}
                                error={errors?.salesContractNumber.length > 0}
                                helperText={
                                    errors?.salesContractNumber.length > 0
                                        ? errors?.salesContractNumber
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='existingbooking-salesContractNumber'
                                label='Sales Contract Number'
                                name='salesContractNumber'
                                onChange={(e: any) => {
                                    updateField(
                                        'salesContractNumber',
                                        e.target.value
                                    );
                                }}
                            />

                            <TextField
                                value={payload?.agent}
                                error={errors?.agent.length > 0}
                                helperText={
                                    errors?.agent.length > 0
                                        ? errors?.agent
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='existingbooking-agent'
                                label='Agent'
                                name='agent'
                                onChange={(e: any) => {
                                    updateField('agent', e.target.value);
                                }}
                            />

                            <div className='mt-4'>
                                <Custombutton
                                    isLoadingButton
                                    loading={loading}
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    styles={{
                                        padding:
                                            '0.75rem 0.62rem 0.9rem 0.93rem'
                                    }}
                                    disabled={
                                        Object.values(payload).some(
                                            (item: any) => item === undefined
                                        ) || loading
                                    }
                                    buttonText={
                                        <span>
                                            <span className='ml-3'>
                                                Add and track booking
                                            </span>
                                        </span>
                                    }
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default Existingbookingdrawer;
