export const ChevronDownIcon = () => (
    <svg
        width='12'
        height='8'
        viewBox='0 0 12 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M1 1.58496L6 6.58496L11 1.58496'
            stroke='#667085'
            strokeWidth='1.66667'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
