import { useEffect, useState } from 'react';
import { queryConfig } from '../api/queryBase';
import { Transactions } from '../pages/dashboard/payments/interfaces/Transactions';

export const useTransactionDetails = (
    transactionReference?: string,
    corporateId?: number
) => {
    const [entries, setEntries] = useState<Transactions>();
    const query = queryConfig();

    const { isLoading, data, isFetching } = query<Transactions>({
        url: `/wallet/transaction/${transactionReference}/corporate/${corporateId}`,
        cacheKey: `transaction-details-${transactionReference}`
    });

    useEffect(() => {
        setEntries(data?.payload);
    }, [isFetching, isLoading]);


    return {
        isLoading,
        entries,
        isFetching
    };
};
