export const EditIcon = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
        >
            <g clip-path='url(#clip0_1953_324)'>
                <path
                    d='M11.3333 2.00016C11.5084 1.82506 11.7163 1.68617 11.9451 1.59141C12.1738 1.49665 12.419 1.44788 12.6667 1.44788C12.9143 1.44788 13.1595 1.49665 13.3883 1.59141C13.617 1.68617 13.8249 1.82506 14 2.00016C14.1751 2.17526 14.314 2.38313 14.4087 2.6119C14.5035 2.84067 14.5523 3.08587 14.5523 3.33349C14.5523 3.58112 14.5035 3.82632 14.4087 4.05509C14.314 4.28386 14.1751 4.49173 14 4.66683L5 13.6668L1.33333 14.6668L2.33333 11.0002L11.3333 2.00016Z'
                    stroke='#1D2939'
                    strokeWidth='1.33333'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_1953_324'>
                    <rect width='16' height='16' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};
