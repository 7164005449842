import { Beneficiary } from "./Beneficiary";

export interface Invoice {
    id: number;
    corporateDocumentId: number;
    issueDate: string;
    dueDate: string;
    fromCurrencyCode: string;
    amount: number;
    invoiceNumber: string;
    carrierName: string;
    status: string;
    createdDate: string;
    consigneeName: string;
    totalAmount: number;
    totalPaid: number;
    currencyCode: string;
    termsOfPayment: string;
}

export interface ServiceableInvoice {
    id: number;
    corporateDocumentId: number;
    billOfLadingNumber: string;
    issuedDate: string;
    dueDate: string;
    currencyCode: string;
    customerNumber?: string;
    customerName: string;
    totalAmount: number;
    invoiceNumber: string;
    carrierName?: string;
    beneficiaryId?: number;
    status: number;
    buyerReference?: string;
    notifyParties?: string;
    totalToPay?: number;
    transactionSource?: number;
    transactionCurrency?: string | undefined;
    documentUrl: string;
    beneficiary:Beneficiary
    walletBalance?:number|undefined
    beneficiaryName?:string
}

export const InvoiceStates = {
    PARTIALLY_PAID: 'partially paid',
    OVERDUE: 'overdue',
    CANCELLED: 'cancelled',
    PAID: 'paid',
    DRAFT: 'draft',
    WRITE_OFF: 'write off',
    ISSUED: 'issued'
};


export interface ServiceableInvoiceParams {
    dueDateEnd?: string
    dueDateStart?: string
    issuedDateEnd?: string
    issuedDateStart?: string
    status?: string
    carrierName?: string
    amountRangeStart?: number
    amountRangeEnd?: number
    currencyCode?: string
    invoiceNumber?: string
}

export interface InvoiceSummaryDetails {
    totalPaid: number;
    totalAmount: number;
    totalDue: number;
    totalOverdue: number;
    currencyCode: string;
}

export interface InvoiceParams {
    exporterId?: string;
    consigneeId?: string;
    billOfLadingNumber?: string;
    invoiceNumber?: string;
    salesContractNumber?: string;
    status?: string;
    dueDateEnd?: string;
    dueDateStart?: string;
}
