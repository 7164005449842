import { Stack } from '@mui/material';
import { Table } from 'antd';
import moment from 'moment';
import React, { FC } from 'react';
import { formatNumber } from '../../../../utils/helper';
import { REACT_APP_BASE_URL } from '../../../../config';
import { getColumns } from './tableData/tableData';
import DocumentViewer from '../../../DocumentViewer/DocumentViewer';

interface IProps {
    data: any;
}
const DraftBillOfLadenView: FC<IProps> = ({ data }) => {
    const columns = getColumns('DRAFT_BILL_OF_LADING', data?.currencyCode);

    return (
        <>
            <Stack
                direction='row'
                alignItems='start'
                className='top-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='font-semibold text-appcolorprimary'>
                        Consigner/Shipper{' '}
                    </p>
                    <p className='font-medium'>{data?.shipper?.name || '--'}</p>
                    <p>{data?.shipper?.address}</p>
                    <p>{data?.shipper?.bankCountry}</p>

                    <div className='mt-[15px] w-full'>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Bank Name</p>
                            <p className='value'>
                                {data?.shipper?.bankName || '--'}
                            </p>
                        </Stack>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Account No.</p>
                            <p className='value'>
                                {data?.shipper?.bankAccountNumber || '--'}
                            </p>
                        </Stack>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Swift Code</p>
                            <p className='value'>
                                {data?.shipper?.swiftCode || '--'}
                            </p>
                        </Stack>
                    </div>
                </div>

                <div className='view-two'>
                    <p className='font-semibold text-appcolorprimary'>
                        Consignee
                    </p>
                    <p className='font-medium'>
                        {data?.consignee?.name || '--'}
                    </p>
                    <p>{data?.consignee?.address}</p>
                    <p>{data?.consignee?.bankCountry}</p>

                    <div className='mt-[15px] w-full'>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Bank Name</p>
                            <p className='value'>
                                {data?.consignee?.bankName || '--'}
                            </p>
                        </Stack>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Account No.</p>
                            <p className='value'>
                                {data?.consignee?.bankAccountNumber || '--'}
                            </p>
                        </Stack>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Swift Code</p>
                            <p className='value'>
                                {data?.consignee?.swiftCode || '--'}
                            </p>
                        </Stack>
                    </div>
                </div>

                <div className='view-three'>
                    <div></div>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>MTD No.</p>
                        <p className='value'>{data?.mtdNumber || '--'}</p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Date</p>
                        <p className='value'>
                            {moment(data?.issuedDate).format('ll') || '--'}
                        </p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Place of Receipt</p>
                        <p className='value'>{data?.placeOfReceipt || '--'}</p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Shipment Ref No.</p>
                        <p className='value'>
                            {data?.shipmentReferenceNumber || '--'}
                        </p>
                    </Stack>
                </div>
            </Stack>
            <Stack
                direction='row'
                alignItems='start'
                className='mid-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='title'>Notify Party</p>
                    <p className='value'>{data?.notifyParty.name || '--'}</p>

                    <p className='title'>Port of Loading</p>
                    <p className='value'>{data?.portOfLoading?.name || '--'}</p>

                    <p className='title'>Vessel & Voyage</p>
                    <p className='value'>{data?.vesselAndVoyage || '--'}</p>

                    <p className='title'>Port of Departure</p>
                    <p className='value'>{data?.portOfDischarge?.name}</p>
                </div>

                <div className='view-one'>
                    <p className='title'>Place of Delivery</p>
                    <p className='value'>{data?.placeOfDelivery || '--'}</p>

                    <p className='title'>End of Delivery Date</p>
                    <p className='value'>
                        {' '}
                        {moment(data?.endDateOfDelivery).format('ll')}
                    </p>

                    <p className='title'>Transport</p>
                    <p className='value'>{data?.transport || '--'}</p>

                    <p className='title'>Route/ Place of Transhipment</p>
                    <p className='value'>{data?.placeOfTranshipment || '--'}</p>
                </div>
            </Stack>
            <div className='table-view'>
                <Table
                    rowKey={'id'}
                    columns={columns}
                    dataSource={data?.packing}
                    pagination={false}
                />
            </div>

            <Stack direction='row' justifyContent='flex-end'>
                <div className='preview-total'>
                    Total Weight :{' '}
                    <span className=''>
                        {formatNumber(data?.totalGrossWeight || 0)} Kg
                    </span>
                </div>
            </Stack>

            <Stack
                direction='row'
                alignItems='start'
                className='bottom-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='titl'>Delivery Agent</p>
                    <p>{data?.deliveryAgent || '--'}</p>

                    <p className='titl'>Number of Originals</p>
                    <p>{data?.noOfOriginals || '--'}</p>
                </div>

                <div className='view-two'>
                    <p className='titl'>Freight Amount</p>
                    <p className=''>{data?.freightAmount || '--'}</p>
                    <p className='titl'>Freight Payable At</p>
                    <p>{data?.freightPayableAt || '--'}</p>
                </div>

                <div className='view-three'>
                    <p className='titl'>Place of Issue</p>
                    <p>{data?.placeOfIssue || '--'}</p>
                    <p className='titl'>Signature</p>
                    <div className='h-[50px] w-[100px] img'>
                        <DocumentViewer
                            fileUrl={`${REACT_APP_BASE_URL}/${data?.signatureUrl.split(
                                '/v1/'
                            )[1]}`}
                        />
                    </div>
                </div>
            </Stack>
        </>
    );
};

export default DraftBillOfLadenView;
