import { RouteItem } from "../interfaces/RouteItem";

export const menuList: RouteItem[] = [
    {
        headingId: 'headingid-1',
        icon: '/img/vectors/home.svg',
        iconActive: '/img/vectors/activehome.svg',
        title: 'Home',
        active: false,
        childId: 'childId-1',
        route: '/dashboard',
        isNew: false,
        level: [1, 5],
        feature: 'Accessible'
    },
    {
        headingId: 'headingid-2',
        icon: '/img/vectors/sales.svg',
        iconActive: '/img/vectors/sales.svg',
        title: 'Sales',
        active: false,
        childId: 'childId-21',
        route: '#',
        isNew: false,
        level: [1],
        feature: 'Sales',
        permissionTitle:"sales",
        child: [
            {
                headingId: 'headingid-222',
                icon: '/img/vectors/docadd.svg',
                iconActive: '/img/vectors/docadd.svg',
                title: 'Contracts',
                active: false,
                childId: 'childId-221',
                route: '/sales/contracts',
                isNew: false,
                level: [1],
                feature: 'Sales',
                permissionTitle:"sales",
            },
            {
                headingId: 'headingid-222',
                icon: '/img/vectors/docadd.svg',
                iconActive: '/img/vectors/docadd.svg',
                title: 'Invoices',
                active: false,
                childId: 'childId-221',
                route: '/sales/invoices',
                isNew: false,
                level: [1],
                feature: 'Invoicing',
                permissionTitle:"sales",
            }
        ]
    },
    {
        icon: '/img/vectors/chart2.svg',
        iconActive: '/img/vectors/chart2.svg',
        headingId: 'headingid-3',
        title: 'Stock Management',
        active: false,
        childId: 'childId-31',
        route: '/stock',
        isNew: false,
        level: [1],
        feature: 'Stocks',
        permissionTitle:"stocks",
    },

    {
        icon: '/img/vectors/chart2.svg',
        iconActive: '/img/vectors/chart2.svg',
        headingId: 'headingid-3',
        title: 'Payments',
        active: false,
        level: [1, 5],
        feature: 'Payments',
        permissionTitle:"payments",
        route: 'payments',
        child: [
            {
                icon: '/img/vectors/chart2.svg',
                iconActive: '/img/vectors/chart2.svg',
                title: 'Transactions',
                headingId: 'headingId-31',
                childId: 'childId-311',
                route: '/payments/overview',
                level: [1, 5],
                feature: 'Accessible',
                permissionTitle:"payments",

            },
            {
                icon: '',
                title: 'Currencies',
                headingId: 'headingId-31',
                childId: 'childId-312',
                route: '/payments/currency-administration',
                level: [5],
                feature: 'Accessible',
                permissionTitle:"payments",

            },
            {
                icon: '',
                title: 'Dedicated Accounts',
                route: '/payments/dedicated-accounts',
                level: [5],
                feature: 'Accessible',
                permissionTitle:"payments",

            },
            {
                icon: '',
                title: 'Logistics Payments',
                route: '/payments/logistics',
                level: [1],
                feature: 'LogisticsPayments',
                permissionTitle:"payments",

            }
        ]
    },
    {
        icon: '/img/vectors/share.svg',
        iconActive: '/img/vectors/share.svg',
        headingId: 'headingid-4',
        title: 'Logistics',
        level: [1],
        feature: 'BookingInttra',
        permissionTitle:"logistics",
        route: "bookings",
        child: [
            {
                headingId: 'headingid-69',
                icon: '/img/vectors/docadd.svg',
                iconActive: '/img/vectors/docadd.svg',
                title: 'Bookings',
                active: false,
                childId: 'childId-891',
                route: '/bookings',
                isNew: false,
                level: [1],
                feature: 'BookingInttra',
                permissionTitle:"logistics",
            },
            {
                headingId: 'headingid-699',
                icon: '/img/vectors/docadd.svg',
                iconActive: '/img/vectors/docadd.svg',
                title: 'Tracking',
                active: false,
                childId: 'childId-891',
                route: '/bookings/track-shipments',
                isNew: false,
                level: [1],
                feature: 'BookingInttra',
                permissionTitle:"logistics",

            },
            {
                headingId: 'headingid-700',
                icon: '/img/vectors/docadd.svg',
                iconActive: '/img/vectors/docadd.svg',
                title: 'Ocean Schedules',
                active: false,
                childId: 'childId-894',
                route: '/ocean-schedules',
                isNew: false,
                level: [1],
                feature: 'OceanSchedules',
                permissionTitle:"logistics",

            }
        ]
    },
    {
        headingId: 'headingid-6',
        icon: '/img/vectors/chart.svg',
        iconActive: '/img/vectors/chart.svg',
        title: 'Financing',
        active: false,
        childId: 'childId-1',
        route: '/financing',
        isNew: false,
        level: [1],
        feature: 'Financing',
        permissionTitle:"financing",

    },
    {
        icon: '/img/vectors/documentAdd.svg',
        iconActive: '/img/vectors/documentAdd.svg',
        title: 'Insurance',
        active: false,
        childId: 'childId-1',
        route: '/insurance',
        isNew: false,
        level: [1],
        feature: 'Insurance',
        permissionTitle:"insurance",

    },
    {
        headingId: 'headingid-7',
        icon: '/img/vectors/scale.svg',
        iconActive: '/img/vectors/activescale.svg',
        title: 'Trade Document',
        active: false,
        childId: 'childId-71',
        route: '/trade-documents',
        isNew: false,
        level: [1],
        feature: 'TradeDocuments',
        permissionTitle:"tradeDocuments",
    },
    {
        headingId: 'headingid-29',
        icon: '/img/vectors/sales.svg',
        iconActive: '/img/vectors/sales.svg',
        title: 'Corporates',
        active: false,
        childId: 'childId-219',
        route: '/corporates',
        isNew: false,
        level: [5],
        feature: 'Accessible'
    },
    {
        headingId: 'headingid-63',
        icon: '/img/vectors/docadd.svg',
        iconActive: '/img/vectors/docadd.svg',
        title: 'Bookings',
        active: false,
        childId: 'childId-891',
        route: '/bookings/overview',
        isNew: false,
        level: [5],
        feature: 'Accessible'
    },
    {
        headingId: 'headingid-7',
        icon: '/img/vectors/settings.svg',
        iconActive: '/img/vectors/settings.svg',
        title: 'Settings',
        active: false,
        childId: 'childId-71',
        route: '/settings/profile',
        isNew: false,
        level: [1],
        feature: 'Accessible'
    },
];
