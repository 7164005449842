export const InfoSquareIcon = () => {
    return (
        <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                x='1'
                y='1'
                width='12'
                height='12'
                rx='3'
                stroke='#0088B2'
                strokeWidth='0.7'
            />
            <path
                d='M7.30005 9.99999L7.30005 6.39999'
                stroke='#0088B2'
                strokeWidth='0.7'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.10005 6.39999L7.30005 6.39999'
                stroke='#0088B2'
                strokeWidth='0.7'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.30005 4.6L7.30005 4'
                stroke='#0088B2'
                strokeWidth='0.7'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
