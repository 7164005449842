import { Table } from 'antd'
import moment from 'moment'
import React from 'react'
interface IProps {
  data?: any
}
const DetailsReference = ({ data }: IProps) => {
  const refColumns = [
    {
      title: 'Shipping Ref No',
      dataIndex: 'shipperReferences',
      key: 'shipperReferences',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Forwarders Ref No',
      dataIndex: 'forwarderReferences',
      key: 'forwarderReferences',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Purchase Order No',
      dataIndex: 'purchaseOrderNumbers',
      key: 'purchaseOrderNumbers',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Tariff Ref No',
      dataIndex: 'tariffNumber',
      key: 'tariffNumber',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Contract Party Ref No',
      dataIndex: 'contractPartyReferences',
      key: 'contractPartyReferences',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Consignee Ref No',
      dataIndex: 'consigneeReferences',
      key: 'consigneeReferences',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'BL Ref No',
      dataIndex: 'billOfLadingReferences',
      key: 'billOfLadingReferences',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Customer Shipment ID',
      dataIndex: 'customerShipmentId',
      key: 'customerShipmentId',
      render: (text: any) => <span>{text || '--'}</span>,
    },
  ]

  const transColumns = [
    {
      title: 'Move Type',
      dataIndex: 'moveType',
      key: 'moveType',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Place of Carrier Reciept',
      dataIndex: 'placeOfReceipt',
      key: 'placeOfReceipt',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Earliest Dept. Date',
      dataIndex: 'earliestDepatureDate',
      key: 'earliestDepatureDate',
      render: (text: any) => <span>{moment(text).format('ll') || '--'}</span>,
    },
    {
      title: 'Place of Carrier Delivery',
      dataIndex: 'placeOfDelivery',
      key: 'placeOfDelivery',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Latest Delivery Date',
      dataIndex: 'latestDeliveryDate',
      key: 'latestDeliveryDate',
      render: (text: any) => <span>{moment(text).format('ll') || '--'}</span>,
    },
  ]

  const preColumns = [
    {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'ETD',
      dataIndex: 'etd',
      key: 'etd',
      render: (text: any) => <span>{moment(text).format('ll') || '--'}</span>,
    },
    {
      title: 'Mode',
      dataIndex: 'mode',
      key: 'mode',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'End',
      dataIndex: 'end',
      key: 'end',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'ETA',
      dataIndex: 'eta',
      key: 'eta',
      render: (text: any) => <span>{moment(text).format('ll') || '--'}</span>,
    },
  ]

  const mainColumns = [
    {
      title: 'Port of Loading',
      dataIndex: 'portOfLoad',
      key: 'portOfLoad',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'ETD',
      dataIndex: 'etd',
      key: 'etd',
      render: (text: any) => <span>{moment(text).format('ll') || '--'}</span>,
    },
    {
      title: 'Vessel',
      dataIndex: 'vessel',
      key: 'vessel',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Port of Discharge',
      dataIndex: 'portOfDischarge',
      key: 'portOfDischarge',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'ETA',
      dataIndex: 'eta',
      key: 'eta',
      render: (text: any) => <span>{moment(text).format('ll') || '--'}</span>,
    },
  ]

  return (
    <>
      <div className="details-content">
        <h3>Reference</h3>
        <Table
          dataSource={[{ ...data?.creationDetails?.references }]}
          columns={refColumns}
          pagination={false}
        />
      </div>

      <div className="details-content">
        <h3>Transport</h3>

        <Table
          dataSource={[{ ...data?.creationDetails?.transport }]}
          columns={transColumns}
          pagination={false}
        />
      </div>

      <div className="details-content">
        <h3>Pre-Carriage</h3>
        <Table
          dataSource={data?.creationDetails?.transport?.preCarriage.filter(
            (item: any) => item.type === 1,
          )}
          columns={preColumns}
          pagination={false}
        />
      </div>

      <div className="details-content">
        <h3>Main Carriage</h3>
        <Table
          dataSource={data?.creationDetails?.transport?.main}
          columns={mainColumns}
          pagination={false}
        />
      </div>

      <div className="details-content">
        <h3>On Carriage</h3>
        <Table
          dataSource={data?.creationDetails?.transport?.onCarriage.filter(
            (item: any) => item.type === 0,
          )}
          columns={preColumns}
          pagination={false}
        />
      </div>
    </>
  )
}

export default DetailsReference
