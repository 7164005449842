import { Dropdown } from 'antd';
import { DeleteIcon } from '../../../components/icons/DeleteIcon';
import { EditIcon } from '../../../components/icons/EditIcon';
import { MoreIcon } from '../../../components/icons/MoreIcon';
import { getTransactionStatus } from '../../../utils/helper';
import StatusIndicator from '../../StatusIndicator';

interface WarehouseTableMobileCardProps {
    item?: any;
    onClick?: () => void;
}

const WarehouseTableMobileCard = ({
    item,
    onClick = () => {}
}: WarehouseTableMobileCardProps) => {
    return (
        <div
            className='bg-white py-4 border-b border-appcolorfaintnine mx-4 flex justify-between'
            onClick={onClick}
        >
            <div className='flex justify-between mb-[16px]'>
                <div className='flex gap-4'>
                    <StatusIndicator
                        status={
                            'COMPLETED' || getTransactionStatus(item?.status)
                        }
                    />
                    <div>
                        <p className='text-sm font-semibold leading-5 text-ebony mb-[8px]'>
                            Warehouse west {item?.warehouseName}
                        </p>
                        <div className='flex items-center gap-x-4 gap-y-1 flex-wrap mb-[4px]'>
                            <p className='flex gap-[4px]  text-xs font-normal leading-[18px] text-paleSky'>
                                Location:
                                <span className='font-semibold'>
                                    Apapa
                                    {item?.location}
                                </span>
                            </p>
                            <p className='flex gap-[4px] text-xs font-normal leading-[18px] text-paleSky'>
                                Size:
                                <span className='font-semibold'>
                                    {' '}
                                    3sqft {item?.size}
                                </span>
                            </p>
                        </div>
                        <p className='text-xs font-normal leading-[18px] text-paleSky'>
                            Status:
                            <span className='font-semibold'>&nbsp;Active</span>
                        </p>
                    </div>
                </div>
            </div>

            <Dropdown
                menu={{
                    items: [
                        {
                            key: '1',
                            label: (
                                <button
                                    className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base'
                                    onClick={() => {}}
                                >
                                    <EditIcon />
                                    <span>Edit</span>
                                </button>
                            )
                        },
                        {
                            key: '2',
                            label: (
                                <button
                                    className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base'
                                    onClick={() => {}}
                                >
                                    <DeleteIcon />
                                    <span>Delete</span>
                                </button>
                            )
                        }
                    ]
                }}
                trigger={['click']}
            >
                <div
                    style={{
                        cursor: 'pointer'
                    }}
                >
                    <MoreIcon />
                </div>
            </Dropdown>
        </div>
    );
};

export default WarehouseTableMobileCard;
