import React from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import { unitTypes } from '../../../../../utils/appconstants';
import { formatDecimalNumber } from '../../../../../utils/helper';
import { NumberInput } from '../../../../../components/paymentinput/paymentinput';
import { TransformedItems } from '../../tradeDocumentTypes/CommercialTypes';
import CustomTextField from '../../../../../components/CustomTextInput';

export interface quotefieldProps {
    id: string;
    updateQuoteField: (
        index: number,
        attributeName: keyof TransformedItems,
        attributeValue: TransformedItems[keyof TransformedItems]
    ) => void;
    qty: number;
    price: number;
    index: number;
    removeQuoteField: (index: number) => void;
    fromUpload?: boolean;
    item?: any;
}

const Quotefield = ({
    id,
    updateQuoteField,
    qty,
    price,
    index,
    removeQuoteField,
    fromUpload,
    item
}: quotefieldProps) => {
    return (
        <div
            className={`relative custom-quote-field ${
                fromUpload ? 'px-[0rem]' : 'px-[0rem]'
            } `}
        >
            <Grid container>
                <Grid item xs={12} md={12} lg={6}>
                    <div className='lg:border border-appcolorgreyfaint lg:px-[1rem] lg:pt-[1rem] pb-2'>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid item xs={12} md={12} lg={4} className='mt-2'>
                                <CustomTextField
                                    id={`quote-productCode-${id}`}
                                    label='Product Code'
                                    name='productCode'
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'productCode',
                                            e.target.value
                                        );
                                    }}
                                    value={item?.productCode}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={8}
                                className='mt-2'
                                mt={{ lg: 0, sm: 1 }}
                            >
                                <CustomTextField
                                    value={item?.desc}
                                    required
                                    id={`quote-desc-${id}`}
                                    label='Description of Goods'
                                    name='desc'
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'desc',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12} md={12} lg={3}>
                    <div className='lg:border border-appcolorgreyfaint lg:px-[1rem] lg:pt-[1rem] pb-2'>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid item xs={12} md={12} lg={6}>
                                <CustomTextField
                                    value={
                                        isNaN(parseFloat(item?.qty))
                                            ? undefined
                                            : item?.qty
                                    }
                                    required
                                    id={`quote-qty-${id}`}
                                    label='Unit Qty'
                                    name='qty'
                                    numericInput
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'qty',
                                            e.target.value
                                        );
                                    }}
                                    InputProps={{
                                        inputComponent: NumberInput,
                                        inputProps: {
                                            min: '0'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={6}
                                mt={{ lg: 0, sm: 1 }}
                            >
                                <CustomTextField
                                    select
                                    value={item?.type}
                                    required
                                    id={`quote-type-${id}`}
                                    label='Unit Type'
                                    name='type'
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'type',
                                            e.target.value
                                        );
                                    }}
                                >
                                    {unitTypes.map((item) => (
                                        <MenuItem
                                            value={item}
                                            key={`${id}-${item}`}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12} md={12} lg={3}>
                    <div className='lg:border border-appcolorgreyfaint lg:px-[1rem] lg:pt-[1rem] pb-2'>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={6}
                                className='mt-2 lg:mt-0'
                            >
                                <CustomTextField
                                    value={item?.price}
                                    id={`quote-price-${id}`}
                                    label='Unit Price'
                                    name='price'
                                    InputProps={{
                                        inputComponent: NumberInput,
                                        inputProps: {
                                            min: '0'
                                        }
                                    }}
                                    required
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'price',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={6}
                                mt={{ lg: 0, sm: 1 }}
                            >
                                <CustomTextField
                                    disabled
                                    id={`quote-amount-${id}`}
                                    label='Total Price'
                                    name='qty'
                                    value={formatDecimalNumber(
                                        (isNaN(price) ? 0 : price) *
                                            (isNaN(qty) ? 0 : qty)
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {index !== 0 && (
                <div
                    className='absolute top-[1.5rem] cursor-pointer right-[0.8rem] custom-quote-field-delete w-fit transition-all delay-75'
                    onClick={() => {
                        removeQuoteField(index);
                    }}
                >
                    <DeleteIcon />
                </div>
            )}
        </div>
    );
};

export default Quotefield;
