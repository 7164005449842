import { Checkbox, Dropdown, Pagination, Table } from 'antd';
import dayjs from 'dayjs';
import {
    formatCurrency,
    getErrorMessage,
    getInvoiceStatus,
    openNotification
} from '../../../utils/helper';
import { Box, Button } from '@mui/material';
import { MoreIcon } from '../../../components/icons/MoreIcon';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    InvoiceStates,
    ServiceableInvoice,
    ServiceableInvoiceParams
} from './interfaces/Invoice';
import { useInvoices } from '../../../customhooks/useInvoices';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useNavigate } from 'react-router-dom';
import { FlagIcon } from '../../../components/icons/FlagIcon';
import { deleteMutate } from '../../../api/queryBase';
import { useQueryClient } from 'react-query';
import { NoDataCross } from '../../../components/icons/NoDataCross';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';
import LogisticsInvoicesMobileCards from './LogisticsInvoicesMobileCards';

export const LogisticsInvoicesListTable = ({
    filterParams,
    setFilterParams,
    selected,
    setSelected,
    raiseEmpty,
    setRaiseEmpty,
    refetchInvoices
}: {
    filterParams: ServiceableInvoiceParams;
    setFilterParams: Dispatch<SetStateAction<ServiceableInvoiceParams>>;
    selected: (number | string)[];
    setSelected: Dispatch<React.SetStateAction<(number | string)[]>>;
    raiseEmpty?: boolean;
    setRaiseEmpty: Dispatch<React.SetStateAction<boolean>>;
    refetchInvoices: () => void;
}) => {
    const { mutate, isLoading: isDeleteLoading } = deleteMutate({
        url: `/invoice/freight/delete`
    });
    const queryClient = useQueryClient();
    const { permissions } = useUserProfile();

    const navigate = useNavigate();
    const { user } = useSelector((state: RootState) => state.user);
    const {
        isLoading,
        entries = [],
        isFetching,
        paging
    } = useInvoices<ServiceableInvoice>({
        corporateId: user?.corporate_id,
        params: { serviceable: true, size: 10, ...filterParams }
    });
    const [invoices, setInvoices] = useState<ServiceableInvoice[]>(entries);
    const [viewExport, setViewExport] = useState(false);

    useEffect(() => {
        setInvoices(entries);
    }, [entries.length]);

    useEffect(() => {
        if (selected.length) {
            setRaiseEmpty(false);
        }
    });

    const handleDelete = async (ids: number[]) => {
        const params = `${ids
            .map((id) => `?invoiceIds=${encodeURIComponent(id)}`)
            .join('&')}`;
        if (
            permissions &&
            hasPermission({
                userPermissions: permissions,
                module: 'payments',
                permissionList: ['write', '*']
            })
        ) {
            mutate(params, {
                onSettled: (_, error) => {
                    queryClient.invalidateQueries('invoices');
                    if (error) {
                        openNotification(
                            'error',
                            getErrorMessage(error) ||
                                'Delete Invoice, Something went wrong'
                        );
                        return;
                    }
                    openNotification('success', 'Delete Invoice completed!');
                }
            });
        } else {
            openNotification(
                'error',
                'You do not have access to perform this action'
            );
        }
    };

    const getMenuItems = (docId: number, id: number, status: number) =>
        [
            <Box
                onClick={() => {
                    navigate(`/payments/logistics/details/${docId}`);
                }}
            >
                Invoice Details
            </Box>,
            !['CANCELLED', 'PAID'].includes(
                getInvoiceStatus(status).toUpperCase()
            ) && (
                <Box
                    onClick={() => {
                        if (
                            permissions &&
                            hasPermission({
                                userPermissions: permissions,
                                module: 'payments',
                                permissionList: ['write', '*']
                            })
                        ) {
                            navigate(
                                `/payments/logistics/pay?invoices=${docId}`
                            );
                        } else {
                            openNotification(
                                'error',
                                'You do not have access to perform this action'
                            );
                        }
                    }}
                    className={` ${
                        !hasPermission({
                            userPermissions: permissions,
                            module: 'payments',
                            permissionList: ['write', '*']
                        }) && ` opacity-30 cursor-not-allowed`
                    }`}
                >
                    Make Payment
                </Box>
            ),
            <Box
                onClick={() => {
                    handleDelete([id]);
                }}
                className={` ${
                    !hasPermission({
                        userPermissions: permissions,
                        module: 'payments',
                        permissionList: ['write', '*']
                    }) && ` opacity-30 cursor-not-allowed`
                }`}
            >
                Delete Invoice
            </Box>
        ].map((comp, key) => ({
            key,
            label: comp
        }));

    const EmptyTable = () => (
        <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
            <div className='flex justify-center mb-4'>
                <NoDataCross />
            </div>

            <p className='text-center text-appcolorblackthree'>
                You have no invoices. <br />
                {permissions &&
                    hasPermission({
                        userPermissions: permissions,
                        module: 'payments',
                        permissionList: ['write', '*']
                    }) && (
                        <>
                            <span
                                className='cursor-pointer text-appcolorprimary'
                                onClick={() => {
                                    navigate('/payments/logistics/create');
                                }}
                            >
                                Create an invoice
                            </span>
                            to pay your logistics providers in any currency of
                            choice.
                        </>
                    )}
            </p>
        </div>
    );
    return (
        <>
            {raiseEmpty && (
                <Box className='flex justify-center space-x-2 text-center py-2 bg-provincialPink mb-2 text-alizarinCrimson rounded-lg'>
                    <Box className='w-[24px] h-[24px] inline-block'>
                        <FlagIcon />
                    </Box>
                    <Box className='inline-block font-semibold'>
                        Please select an invoice
                    </Box>
                </Box>
            )}
            <Table
                loading={isLoading || isFetching || isDeleteLoading}
                dataSource={invoices}
                pagination={false}
                locale={{
                    emptyText: () => {
                        return <EmptyTable />;
                    }
                }}
                columns={[
                    {
                        title: (
                            <Checkbox
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelected(
                                            invoices
                                                .filter(
                                                    ({ status }) =>
                                                        ![
                                                            'CANCELLED',
                                                            'PAID'
                                                        ].includes(
                                                            getInvoiceStatus(
                                                                status
                                                            ).toUpperCase()
                                                        )
                                                )
                                                .map(
                                                    ({ corporateDocumentId }) =>
                                                        corporateDocumentId
                                                )
                                        );

                                        return;
                                    }

                                    setSelected([]);
                                }}
                                checked={
                                    !!selected.length &&
                                    selected.length ===
                                        invoices.filter(({ status }) =>
                                            ['CANCELLED', 'PAID'].includes(
                                                getInvoiceStatus(
                                                    status
                                                ).toUpperCase()
                                            )
                                        ).length
                                }
                            />
                        ),
                        render: (_, invoice) => {
                            const { status, corporateDocumentId } = invoice;

                            return (
                                <Checkbox
                                    disabled={['CANCELLED', 'PAID'].includes(
                                        getInvoiceStatus(status).toUpperCase()
                                    )}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelected((prev) => [
                                                ...prev,
                                                corporateDocumentId
                                            ]);

                                            return;
                                        }

                                        setSelected(
                                            selected
                                                .filter(
                                                    (ref) =>
                                                        ref !==
                                                        corporateDocumentId
                                                )
                                                .map(
                                                    (corporateDocumentId) =>
                                                        corporateDocumentId
                                                )
                                        );
                                    }}
                                    checked={
                                        selected.includes(
                                            corporateDocumentId
                                        ) &&
                                        !['CANCELLED', 'PAID'].includes(
                                            getInvoiceStatus(
                                                status
                                            ).toUpperCase()
                                        )
                                    }
                                />
                            );
                        }
                    },
                    {
                        title: 'Issue Date',
                        key: 'issuedDate',
                        render: (_, { issuedDate }) => (
                            <span>
                                {dayjs(issuedDate).format('DD MMM, YYYY')}
                            </span>
                        )
                    },
                    {
                        title: 'Due Date',
                        key: 'dueDate',
                        render: (_, { dueDate }) => (
                            <span>{dayjs(dueDate).format('DD MMM, YYYY')}</span>
                        )
                    },
                    {
                        title: 'Invoice No.',
                        dataIndex: 'invoiceNumber',
                        key: 'invoiceNumber'
                    },
                    {
                        title: 'Carrier Name',
                        dataIndex: 'beneficiaryName',
                        key: 'beneficiaryName'
                    },
                    {
                        title: 'Invoice Amount',
                        key: 'totalAmount',
                        render: (_, { currencyCode, totalAmount }) => (
                            <span>
                                {formatCurrency(totalAmount, currencyCode)}
                            </span>
                        )
                    },
                    {
                        title: 'Status',
                        key: 'status',
                        render: (_, { status }) => (
                            <span className='capitalize'>
                                {
                                    InvoiceStates[
                                        getInvoiceStatus(
                                            status
                                        ).toUpperCase() as keyof typeof InvoiceStates
                                    ]
                                }
                            </span>
                        )
                    },
                    {
                        title: '',
                        key: 'id',
                        render: (_, { corporateDocumentId, id, status }) => (
                            <Dropdown
                                menu={{
                                    items: getMenuItems(
                                        corporateDocumentId as number,
                                        id,
                                        status
                                    )
                                }}
                                trigger={['click']}
                            >
                                <Box className='rotate-90 flex flex-row justify-center'>
                                    <MoreIcon />
                                </Box>
                            </Dropdown>
                        )
                    }
                ]}
                className='hidden lg:block'
            />
            <div className='lg:hidden'>
                <LogisticsInvoicesMobileCards
                    loading={isLoading || isFetching || isDeleteLoading}
                    emptyView={<EmptyTable />}
                    {...{
                        invoices,
                        selected,
                        setSelected,
                        getMenuItems,
                        filterParams,
                        viewExport,
                        setViewExport,
                        setFilterParams,
                        refetchInvoices
                    }}
                />
            </div>
            <Box className='flex lg:justify-between bg-white py-5 flex-col lg:flex-row gap-4 justify-center items-center pr-2'>
                <Box className='grow text-center space-x-3'>
                    {Boolean(selected.length) && (
                        <>
                            <span className='text-[12px]'>
                                {selected.length} item(s) selected
                            </span>
                            {permissions &&
                                hasPermission({
                                    userPermissions: permissions,
                                    module: 'payments',
                                    permissionList: ['write', '*']
                                }) && (
                                    <Button
                                        variant='contained'
                                        onClick={() =>
                                            navigate(
                                                `/payments/logistics/pay?invoices=${selected}`
                                            )
                                        }
                                    >
                                        Make Payment
                                    </Button>
                                )}
                            <Button
                                variant='contained'
                                color='error'
                                sx={{
                                    '.MuiButton-startIcon': {
                                        '.MuiSvgIcon-root': {
                                            color: 'white'
                                        }
                                    }
                                }}
                                onClick={() => setSelected([])}
                            >
                                Clear
                            </Button>
                        </>
                    )}
                </Box>
                <Pagination
                    total={paging.total}
                    defaultCurrent={1}
                    defaultPageSize={10}
                    current={paging.page}
                    onChange={(page) => {
                        setFilterParams((props) => ({
                            ...props,
                            page
                        }));
                    }}
                />
            </Box>
        </>
    );
};
