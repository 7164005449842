import React, { useState } from 'react';
import { Dropdown, MenuProps, Table } from 'antd';
import moment from 'moment';
import CustomModal from '../../CustomModal';
import ApprovalModalContent from './ApprovalModalContent';
import { openNotification } from '../../../utils/helper';
import { approveBookingProfile } from '../../../api/booking/booking';
import { Alert, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BookingProfileProps, CurrentDataType } from './BookingPrfileTypes';

const BookingProfileTable = ({
    loadingData,
    data,
    fetch
}: BookingProfileProps) => {
    type ErrorObject = Record<string, string>;
    const [currentData, setCurrentData] = useState<CurrentDataType>();
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState<
        string | JSX.Element[] | JSX.Element | null | string[]
    >(null);
    const [errors, setErrors] = useState<Record<string, string>>({});

    const navigate = useNavigate();
    const [inttraCompanyId, setInttraCompanyId] = useState('');

    const approveFunction = async () => {
        setError(null);
        if (!inttraCompanyId) {
            setError('Enter Intra Company ID');
        } else {
            setLoading(true);
            try {
                const res = await approveBookingProfile(
                    currentData?.corporateId || '',
                    {
                        inttraCompanyId
                    }
                );
                setLoading(false);
                setShowApproveModal(false);
                openNotification(
                    'success',
                    'Booking Profile was approved successfully'
                );
            } catch (error) {
                setLoading(false);
                openNotification(
                    'error',
                    'Booking Profile approval, Something went wrong'
                );
                fetch();
                const otherErrors: string[] = [];

                if (error?.response) {
                    if (error?.response?.data?.error) {
                        const resError = error?.response?.data?.error;

                        if (typeof resError === 'string') {
                            const errorList = (
                                <li key={`error-list-${1}`}>{resError}</li>
                            );
                            setError(errorList);
                        } else {
                            Object.entries(resError).forEach(([key, value]) => {
                                if (Object.keys(errors).includes(key)) {
                                    setErrors((prevState) => ({
                                        ...prevState,
                                        [key]: value as string
                                    }));
                                } else {
                                    otherErrors.push(value as string);
                                }
                            });

                            if (otherErrors.length > 0) {
                                const errorList = otherErrors.map(
                                    (element, index) => {
                                        return (
                                            <li key={`error-list-${index}`}>
                                                {element}
                                            </li>
                                        );
                                    }
                                );
                                setError(errorList);
                            }
                        }
                    } else {
                        setError(
                            error?.response?.data?.message ||
                                'Something went wrong'
                        );
                    }
                } else {
                    setError('Something went wrong');
                }
            }
        }
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        navigate(
                            `/bookings/booking-profile/${currentData?.corporateId}/details`
                        );
                    }}
                >
                    <span className='ml-2'>Review Profile Details</span>
                </div>
            )
        },
        {
            key: '2',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        setShowApproveModal(true);
                    }}
                >
                    <span className='ml-2'>Approve Booking Profile</span>
                </div>
            )
        }
    ];

    return (
        <>
            <Table
                loading={loadingData}
                pagination={{ pageSize: 20 }}
                dataSource={data?.content}
                columns={[
                    {
                        title: 'Date',
                        dataIndex: 'createdDate',
                        key: 'createdDate',
                        render: (_, { createdDate }) => (
                            <span>
                                {createdDate
                                    ? moment(createdDate).format('ll')
                                    : '-'}
                            </span>
                        )
                    },
                    {
                        title: 'Business Name',
                        dataIndex: 'name',
                        key: 'name',
                        render: (text) => <span>{text}</span>
                    },
                    {
                        title: 'Email',
                        dataIndex: 'emailAddress',
                        key: 'emailAddress'
                    },
                    {
                        title: 'Country',
                        dataIndex: 'country',
                        key: 'country'
                    },
                    {
                        title: 'Phone Number',
                        dataIndex: 'phoneNumber',
                        key: 'phoneNumber'
                    },
                    {
                        title: 'Company Role',
                        dataIndex: 'companyRole',
                        key: 'companyRole'
                    },
                    {
                        title: '',
                        render: (_, data) => (
                            <div
                                className='cursor-pointer'
                                onClick={() => {
                                    setCurrentData(data);
                                }}
                            >
                                <Dropdown
                                    menu={{ items }}
                                    trigger={['click']}
                                    placement='bottomRight'
                                >
                                    <img
                                        src='/img/vectors/more.svg'
                                        alt='more'
                                    />
                                </Dropdown>
                            </div>
                        )
                    }
                ]}
            />
            {showApproveModal && (
                <CustomModal
                    maxWidth='md'
                    open={showApproveModal}
                    onClose={() => setShowApproveModal(false)}
                    title=''
                >
                    <ApprovalModalContent
                        type='Booking Profile'
                        {...{ currentData }}
                        approveFunction={approveFunction}
                        cancelFunction={() => {
                            setShowApproveModal(false);
                        }}
                        loading={loading}
                    >
                        {error && (
                            <div className='mt-4'>
                                <Alert severity='error'>{error}</Alert>
                            </div>
                        )}
                        <TextField
                            value={inttraCompanyId}
                            margin='normal'
                            required
                            fullWidth
                            id='inttraCompanyId'
                            label='Intra Company Id'
                            name='inttraCompanyId'
                            onChange={(e) => {
                                setInttraCompanyId(e.target.value);
                            }}
                        />
                    </ApprovalModalContent>
                </CustomModal>
            )}
        </>
    );
};

export default BookingProfileTable;
