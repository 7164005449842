import React, { useState } from 'react';
import { resendVerification } from '../../api/base/base';
import { openNotification } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { ResendConfirmationPayload } from './SignUp';

const SignUpConfirmation = ({
    resentConfirmationPayload
}: {
    resentConfirmationPayload: ResendConfirmationPayload;
}) => {
    const [resending, setResending] = useState(false);

    const navigate = useNavigate();

    const resendConfirmation = async () => {
        if (!resending) {
            setResending(true);
            try {
                await resendVerification({
                    userId: resentConfirmationPayload?.userId,
                    corporateId: resentConfirmationPayload?.corporateId
                });
                setResending(false);
                openNotification(
                    'success',
                    'Resend Confirmation, verification sent'
                );
            } catch (error) {
                setResending(false);
                openNotification(
                    'error',
                    'Resend Confirmation, Something went wrong'
                );
            }
        }
    };
    return (
        <div className='flex flex-col items-center bg-appcolorwhite pb-[2.4rem] pt-[3rem] px-4'>
            <div>
                <img
                    src='/img/vectors/mailbox.png'
                    alt='mailbox'
                    className='h-[74.06px] mb-[0.5rem]'
                />
            </div>

            <p className='text-base mb-[0.6rem]'>
                A confirmation has been sent to{' '}
                <span className='text-appcolorprimary'>
                    {resentConfirmationPayload?.email}
                </span>
            </p>
            <p className='text-base mb-[1.5rem]'>
                Follow the link to complete your onboarding,
            </p>

            <hr className='border-dotted border-appcolorblack w-[80%] mb-[1.2rem] h-2' />

            <p className='text-base mb-[0.6rem]'>Can’t find the email?</p>
            <p
                className='text-base mb-[2.8rem] w-fit cursor-pointer underline text-appcolorprimary'
                onClick={() => {
                    resendConfirmation();
                }}
            >
                Resend Confirmation Email
            </p>

            <div
                className='flex items-center cursor-pointer w-fit'
                onClick={() => {
                    navigate('/signin');
                }}
            >
                <p className='text-base text-appcolorprimary'>Login</p>
                <div className='ml-1'>
                    <img
                        src='/img/vectors/arrowright.svg'
                        alt='arrow-right'
                        className='w-4'
                    />
                </div>
            </div>
        </div>
    );
};

export default SignUpConfirmation;
