import React, { useState } from 'react';
import Authouterpage from '../../components/authouterpage/authouterpage';
import SignUpConfirmation from './SignUpConfirmation';
import SignUpForm from './SignUpForm';

export interface RegisterValues {
    emailAddress?: string;
    phoneNumber?: string;
    cipherCode?: string;
    rcNumber?: string;
    businessName?: string;
    countryId?: string;
    firstName?: string;
    lastName?: string;
    corporateIndustryId?: string;
    businessTypes?: string[];
}

export interface ResendConfirmationPayload {
    userId: number;
    corporateId: number;
    email: string;
}
const SignUp = () => {
    const [resentConfirmationPayload, setResentConfirmationPayload] =
        useState<ResendConfirmationPayload>({} as ResendConfirmationPayload);

    const [showConfirmation, setShowConfirmation] = useState(false);

    return (
        <div>
            <Authouterpage
                title='SIGN UP'
                info={
                    showConfirmation
                        ? 'Confirm your Email'
                        : 'Open A Free Account'
                }
                infoText='Signing up for FrontEdge is fast and free.No commitments or long-term contracts.'
            >
                <div>
                    {showConfirmation ? (
                        <SignUpConfirmation
                            {...{ resentConfirmationPayload }}
                        />
                    ) : (
                        <SignUpForm
                            {...{
                                setShowConfirmation,
                                setResentConfirmationPayload
                            }}
                        />
                    )}
                </div>
            </Authouterpage>
        </div>
    );
};

export default SignUp;
