import React from "react";

interface IProps {
  addCommodityField: any;
}

const SalesAddCommodity = ({ addCommodityField }: IProps): JSX.Element => {
  return (
    <p
      onClick={() => addCommodityField()}
      className="cursor-pointer text-appcolorprimary"
    >
      + Add Commodity
    </p>
  );
};

export default SalesAddCommodity;
