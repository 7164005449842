import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Drawer from '@mui/material/Drawer';
import Custombutton from '../../../../components/custombutton/custombutton';
import { openNotification } from '../../../../utils/helper';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import { CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import useCountries from '../../../../customhooks/useCountries';
import NewCustomSelect from '../../../../components/newcustomselect/newcustomselect';
import {
    createPartner,
    updatePartner
} from '../../../../api/documents/documents';
import useCurrencies from '../../../../customhooks/useCurrencies';
import CustomMapInput from '../../../../components/CustomMapInput';
import useCorporatePartners from '../../../../customhooks/useCorporatePartners';
import { getCities, getStates } from '../../../../api/base/base';

export interface newcarrierDrawerProps {
    closeDrawer: (refreshList?: boolean) => void;
    showDrawer: boolean;
    partnerId?: number;
    setPartnerId?: any;
}

const Addpartnerdrawer = ({
    closeDrawer,
    showDrawer,
    partnerId,
    setPartnerId
}: newcarrierDrawerProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { countries, isCountriesLoading } = useCountries();
    const { isLoading: isLoadingCurrencies, currencies } = useCurrencies();

    const [error, setError] = useState<string | any>(null);
    const [loading, setLoading] = useState(false);
    const [loadingCities, setLoadingCities] = useState(false);
    const [cities, setCities] = useState([]);
    const [loadingState, setLoadingState] = useState(false);
    const [states, setStates] = useState([]);

    const [errors, setErrors] = useState<any>({
        address: '',
        bankAccountNumber: '',
        bankCountry: '',
        bankName: '',
        currencyCode: '',
        name: '',
        phoneNumber: '',
        swiftCode: '',
        cityId: '',
        city: '',
        country: ''
    });

    const fetchStates = async () => {
        try {
            setLoadingState(true);
            const res = await getStates(payload?.countryId || '');
            const { data } = res.data;

            setStates(data);

            setLoadingState(false);
        } catch (error) {
            setLoadingState(false);
        }
    };

    const { corporatePartners: partnerDetails } = useCorporatePartners({
        page: 1
    });

    const [payload, setPayload] = useState<any>({
        address: partnerDetails[0]?.address || '',
        bankAccountNumber: partnerDetails[0]?.bankAccountNumber || '',
        bankCountry: partnerDetails[0]?.bankCountry || '',
        bankName: partnerDetails[0]?.bankName || '',
        currencyCode: partnerDetails[0]?.currencyCode || '',
        name: partnerDetails[0]?.name || undefined,
        phoneNumber: partnerDetails[0]?.phoneNumber || undefined,
        swiftCode: partnerDetails[0]?.swiftCode || '',
        cityId: partnerDetails[0]?.cityId || '',
        city: partnerDetails[0]?.city || '',
        country: partnerDetails[0]?.countryId || '',
        countryId: partnerDetails[0]?.countryId || ''
    });

    useEffect(() => {
        if (partnerDetails && partnerDetails?.length === 1) {
            setPayload({
                address: partnerDetails[0]?.address || '',
                bankAccountNumber: partnerDetails[0]?.bankAccountNumber || '',
                bankCountry: partnerDetails[0]?.bankCountry || '',
                bankName: partnerDetails[0]?.bankName || '',
                currencyCode: partnerDetails[0]?.currencyCode || '',
                name: partnerDetails[0]?.name || '',
                phoneNumber: partnerDetails[0]?.phoneNumber || '',
                swiftCode: partnerDetails[0]?.swiftCode || '',
                cityId: partnerDetails[0]?.cityId || '',
                city: partnerDetails[0]?.city || '',
                country: partnerDetails[0]?.countryId || '',
                countryId: partnerDetails[0]?.countryId || ''
            });
        }
    }, [partnerDetails]);

    useEffect(() => {
        if (payload.countryId) {
            fetchStates();
        }
    }, [payload.countryId]);

    const fetchCities = async () => {
        try {
            setLoadingCities(true);
            const res = await getCities(payload?.stateId || '');
            const { data } = res.data;

            setCities(data);

            setLoadingCities(false);
        } catch (error) {
            setLoadingCities(false);
        }
    };

    useEffect(() => {
        if (payload.stateId) {
            fetchCities();
        }
    }, [payload.stateId]);

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrors((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));
    };

    /**
     * Submit form
     * @param e Object
     */
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        setErrors((prevState: any) => ({
            ...prevState,
            address: '',
            bankAccountNumber: '',
            bankCountry: '',
            bankName: '',
            currencyCode: '',
            name: '',
            phoneNumber: '',
            place: '',
            swiftCode: '',
            cityId: '',
            city: '',
            country: ''
        }));

        try {
            const res = partnerId
                ? await updatePartner(payload, partnerId)
                : await createPartner(payload);

            const { data } = res.data;

            setLoading(false);

            openNotification(
                'success',
                partnerId
                    ? 'Corporate Partner edited successfully'
                    : 'Corporate Partner created successfully'
            );

            closeDrawer(true);
        } catch (error) {
            setLoading(false);
            if (error?.response) {
                if (error?.response?.data?.error) {
                    let hasFieldNameAttribute = false;

                    error?.response?.data?.error.map((item: any) => {
                        return Object.keys(item).map((itemInside: string) => {
                            if (itemInside === 'fieldName') {
                                hasFieldNameAttribute = true;

                                return;
                            }
                        });
                    });

                    if (hasFieldNameAttribute) {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>
                                    {element.messageError}
                                </li>
                            );
                        });
                        setError(errorList);
                    } else {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>{element}</li>
                            );
                        });
                        setError(errorList);
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    return (
        <Drawer
            anchor={isMobile ? 'bottom' : 'right'}
            open={showDrawer}
            onClose={() => {
                closeDrawer();
            }}
            sx={{
                '.MuiDrawer-paperAnchorRight': {
                    width: {
                        xs: '95%',
                        lg: '30rem'
                    }
                }
            }}
        >
            <div className='px-[2.5rem] py-[2.5rem]'>
                <h1 className='text-[24px] leading-[23px] text-appcolorblacktwo font-semibold'>
                    {partnerId ? `Edit` : `Create`} Partner
                </h1>

                {error !== null && (
                    <div className='mt-10'>
                        <Alert severity='error'>{error}</Alert>
                    </div>
                )}

                <div className='my-4'>
                    <form autoComplete='off' onSubmit={onSubmit} noValidate>
                        <TextField
                            value={payload?.name}
                            error={errors?.name.length > 0}
                            helperText={
                                errors?.name.length > 0
                                    ? errors?.phoneNumber
                                    : undefined
                            }
                            margin='normal'
                            required
                            fullWidth
                            id='createnewcarrier-name'
                            label='Name'
                            name='name'
                            onChange={(e: any) => {
                                updateField('name', e.target.value);
                            }}
                        />

                        <TextField
                            value={payload?.phoneNumber}
                            error={errors?.phoneNumber.length > 0}
                            helperText={
                                errors?.phoneNumber.length > 0
                                    ? errors?.phoneNumber
                                    : undefined
                            }
                            margin='normal'
                            required
                            fullWidth
                            id='createnewcarrier-phoneNumber'
                            label='Phone Number'
                            name='phoneNumber'
                            onChange={(e: any) => {
                                updateField('phoneNumber', e.target.value);
                            }}
                        />
                        <div className='mt-2'>
                            {partnerId ? (
                                <TextField
                                    fullWidth
                                    name='address'
                                    value={payload?.address}
                                    onChange={(e: any) => {
                                        updateField('address', e.target.value);
                                    }}
                                    label='Address'
                                />
                            ) : (
                                <CustomMapInput
                                    name='address'
                                    updateField={(_, value) => {
                                        updateField('address', value);
                                    }}
                                    label='Address'
                                />
                            )}
                        </div>

                        <div className='mt-4'>
                            <NewCustomSelect
                                selectValue={payload?.countryId}
                                required
                                selectOptions={
                                    countries as unknown as Record<
                                        string,
                                        unknown
                                    >[]
                                }
                                name={'countryId'}
                                updateField={(_, value) => {
                                    updateField('countryId', value);
                                }}
                                label={'Country'}
                                selectOptionLabel='name'
                                loading={isCountriesLoading}
                            />
                        </div>

                        <div className='mt-4'>
                            <NewCustomSelect
                                selectValue={payload?.stateId}
                                required
                                selectOptions={states}
                                name={'stateId'}
                                updateField={(_, value) => {
                                    updateField('stateId', value);
                                }}
                                label={'State'}
                                selectOptionLabel='name'
                                loading={loadingState}
                            />
                        </div>

                        <div className='mt-4'>
                            <NewCustomSelect
                                selectValue={payload?.cityId}
                                required
                                selectOptions={cities}
                                name={'cityId'}
                                updateField={(_, value) => {
                                    updateField('cityId', value);
                                }}
                                label={'City'}
                                selectOptionLabel='name'
                                loading={loadingCities}
                            />
                        </div>

                        <div className='mt-[1.5rem] mb-[.5rem] pb-2 border-b-[#D0D5DD] border-b text-[13px] text-black'>
                            <p>Bank Details</p>
                        </div>

                        <TextField
                            value={payload?.bankName}
                            error={errors?.bankName.length > 0}
                            helperText={
                                errors?.bankName.length > 0
                                    ? errors?.bankName
                                    : undefined
                            }
                            margin='normal'
                            // required
                            fullWidth
                            id='bankName'
                            label='Name of Bank (Optional)'
                            name='bankName'
                            onChange={(e: any) => {
                                updateField('bankName', e.target.value);
                            }}
                        />

                        <div className='mt-4'>
                            <NewCustomSelect
                                selectOptions={
                                    countries as unknown as Record<
                                        string,
                                        unknown
                                    >[]
                                }
                                name={'bankCountry'}
                                updateField={(_, value) => {
                                    updateField('bankCountry', value);
                                }}
                                label={'Country of Bank (Optional)'}
                                selectOptionLabel='name'
                                loading={false}
                                alternativeValue='name'
                                selectValue={payload?.bankCountry}
                            />
                        </div>

                        <Autocomplete
                            inputValue={payload.currencyCode}
                            placeholder='Currency'
                            disableClearable
                            id='send-currency'
                            sx={{
                                marginTop: '1rem',
                                '.MuiInputBase-input': {
                                    height: '0.3rem'
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    value={payload.currencyCode}
                                    label='Select Currency (Optional)'
                                    placeholder='Currency'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: isLoadingCurrencies && (
                                            <React.Fragment>
                                                <CircularProgress size={20} />
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            )}
                            noOptionsText={'No data available'}
                            renderOption={(props, option) => (
                                <MenuItem
                                    {...props}
                                    value={option.nameAndFlag}
                                    key={`countries-${option.id}`}
                                >
                                    {option.nameAndFlag}
                                </MenuItem>
                            )}
                            getOptionLabel={(option) => option.nameAndFlag}
                            onChange={(event: any, value: any) => {
                                if (value !== null) {
                                    updateField(
                                        'currencyCode',
                                        value.currencyCode
                                    );
                                    updateField('currencyCodeValue', value);
                                }
                            }}
                            options={currencies}
                        />

                        <TextField
                            value={payload?.swiftCode}
                            error={errors?.swiftCode.length > 0}
                            helperText={
                                errors?.swiftCode.length > 0
                                    ? errors?.swiftCode
                                    : undefined
                            }
                            margin='normal'
                            fullWidth
                            id=' swiftCode'
                            label='Swift Code / Sort Code (Optional)'
                            name='swiftCode'
                            onChange={(e: any) => {
                                updateField('swiftCode', e.target.value);
                            }}
                        />
                        <TextField
                            value={payload?.bankAccountNumber}
                            error={errors?.bankAccountNumber.length > 0}
                            helperText={
                                errors?.bankAccountNumber.length > 0
                                    ? errors?.bankAccountNumber
                                    : undefined
                            }
                            margin='normal'
                            fullWidth
                            id='bankAccountNumber'
                            label='IBAN/Account Number (Optional)'
                            name='bankAccountNumber'
                            onChange={(e: any) => {
                                updateField(
                                    'bankAccountNumber',
                                    e.target.value
                                );
                            }}
                        />

                        <div className='mt-6'>
                            <Custombutton
                                isLoadingButton
                                loading={loading}
                                type='submit'
                                fullWidth
                                variant='contained'
                                styles={{
                                    padding: '0.75rem 0.62rem 0.9rem 0.93rem'
                                }}
                                disabled={
                                    Object.values(payload).some(
                                        (item: any) => item === undefined
                                    ) || loading
                                }
                                buttonText='Submit'
                            />
                        </div>
                    </form>
                </div>
            </div>
        </Drawer>
    );
};

export default Addpartnerdrawer;
