import React, { Dispatch, FC, SetStateAction } from 'react';
import { Divider, Stack } from '@mui/material';
import { TabContainerNew } from '../../../styles/TradeDocStyles';
import FilterTradeDocuments from '../tradeFilter/FilterTradeDocuments';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { hasPermission } from '../dashboardpage/utils';

interface IProps {
    setShowAddModal: Dispatch<SetStateAction<boolean>>;
    setFilterOptions: any;
    filterOptions: any;
    fetchDocuments: any;
}

const DocumentPageHeader: FC<IProps> = ({
    setFilterOptions,
    filterOptions,
    setShowAddModal,
    fetchDocuments
}: IProps): JSX.Element => {
    const { permissions } = useUserProfile();

    return (
        <div className='page-header'>
            <h1 className='text-appcolorblacktwo'>Documents</h1>
            <TabContainerNew>
                <Stack direction='row' spacing={'10px'}>
                    <FilterTradeDocuments
                        {...{ setFilterOptions, filterOptions, fetchDocuments }}
                    />
                    {permissions &&
                        hasPermission({
                            userPermissions: permissions,
                            module: 'tradeDocuments',
                            permissionList: ['*', 'write']
                        }) && (
                            <>
                                {' '}
                                <Divider orientation='vertical' flexItem />
                                <div
                                    className='tab-buttons actif'
                                    onClick={() => {
                                        setShowAddModal(true);
                                    }}
                                >
                                    <img
                                        src='/img/vectors/brokenplusBlack.svg'
                                        alt='plaindownload'
                                        className='h-[1.1rem]'
                                    />
                                    <p style={{ paddingLeft: '5px' }}>
                                        Create Document
                                    </p>
                                </div>
                            </>
                        )}
                </Stack>
            </TabContainerNew>
        </div>
    );
};

export default DocumentPageHeader;
