import { Box } from '@mui/material';
import { useRef } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import EnablingFeature from '../../../components/EnablingFeature';

export const LogisticsInvoiceContainer = () => {
    const backRef = useRef<HTMLDivElement>();
    const actionsRef = useRef<HTMLDivElement>();
    const { reference } = useParams();
    const location = useLocation();

    return (
        <EnablingFeature flag='LogisticsPayments'>
            <Box className='mt-5  lg:px-0'>
                <Box className='lg:text-[28px] lg:font-semibold text-[20px] flex justify-between flex-col lg:flex-row gap-3 mb-4 lg:mb-0 px-[1rem]'>
                    <Box>
                        <Box>
                            <Box ref={backRef}></Box>
                        </Box>
                        {!reference &&
                            !location.pathname.includes('logistics/pay') && (
                                <Box>Logistics Payments</Box>
                            )}
                    </Box>

                    <Box className='lg:block hidden'>
                        <Box ref={actionsRef}></Box>
                    </Box>
                </Box>
                <Outlet context={{ backRef, actionsRef }} />
            </Box>
        </EnablingFeature>
    );
};
