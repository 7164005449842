import { Box, Divider } from '@mui/material';

export const LogisticsInvoicePayDetailsSections = ({
    section
}: {
    section: number;
}) => {
    return (
        <>
            <Box className='hidden lg:block p-5 bg-white rounded-lg h-40 w-[250px] space-y-5'>
                {['Invoice Details', 'Summary'].map((label, index) => (
                    <Box key={index} className='space-x-3'>
                        <Box
                            className={`inline-block h-[36px] w-[36px] rounded-full border-2 text-center leading-[33px] ${
                                section === index + 1
                                    ? 'border-appcolorprimary'
                                    : 'border-gray'
                            }`}
                        >
                            {index + 1}
                        </Box>
                        <Box
                            className={`inline-block text-[17px] ${
                                section === index + 1 ? 'font-semibold' : ''
                            }`}
                        >
                            {label}
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box className='lg:hidden mb-6 gap-1 space-y-2'>
                <p className=' text-[18px] font-medium'>Pay for invoice</p>
                <div className='flex items-center gap-2'>
                    {['Invoice Details', 'Summary'].map((label, index) => (
                        <Box
                            key={index}
                            className={`flex items-center justify-center text-[12px] rounded-full text-center font-bold text-white ${
                                section >= index + 1
                                    ? 'bg-appcolorprimary text-white h-[20px] w-[20px]'
                                    : ' bg-lavender w-[10px] h-[10px]'
                            }`}
                        >
                            {section >= index + 1 ? index + 1 : ''}
                        </Box>
                    ))}
                </div>
                <p>
                    {section === 1
                        ? 'Invoice Details'
                        : 'Review details of your transfer'}
                </p>
                <Divider />
            </Box>
        </>
    );
};
