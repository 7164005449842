export const medthodsOfDispatch = ['Container', 'Wagon', 'Truck']

export const statusSales = ['Active', 'Inactive']

export const containerTypesSales = ['20 Feet', '40 Feet']

export const contractTypes = ['Original', 'Dummy']

export const jobLevels = [
  'Associate',
  'C-level Executive',
  'Director',
  'EVP/SVP/Vice President',
  'Manager',
  'Other',
]

export const jobFunctions = [
  'Consultant ',
  'Demand Planning',
  'Export Compliance',
  'Export & Import Compliance',
  'Export Logistics',
  'Finance',
  'FTA/FTZ Management',
  'General Management',
  'Import Compliance',
  'Import Logistics',
  'IT',
  'Legal',
  'Operations - Air',
  'Operations - Truck',
  'Operations - Rail',
  'Operations - Ocean',
  'Operations - Warehouse',
  'Operations - Other',
  'Other',
  'Procurement/Purchasing',
  'Risk & Quality Management',
  'Sales/Marketing',
  'Supply Chain',
]

export const languages = ['English']

export const shipperRole = [
  'Shipper',
  'Freight Forwarder',
  'Preferred Partner',
  'Consignee',
  'Custom House Broker',
  'Notify Party',
]

export const typesOfShipment = [
  'FCL',
  'LCL',
  'Bulk',
  'FTL',
  'LTL',
  'Rail',
  'Air',
]

export const transports = ['Vessel', 'Truck', 'Wagon']

export const methodsOfPayments = [
  '100% prepayment',
  'Partial Prepayment',
  'LC',
  'CAD',
  'Deferral',
]

export const unitTypes = [
  'units',
  'boxes',
  'packages',
  'barrels',
  'sacks',
  'big bags',
  'tons',
  'm3',
  'kg',
  'lbs',
  'ft3',
  'litres',
  'gallons',
  'KG',
]

export const NGNTradeFXLimit = 200000

export const USDTradeFXLimit = 100
