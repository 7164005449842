import React, { Dispatch, SetStateAction, useState } from 'react';
import { Dropdown, MenuProps, Pagination, Table } from 'antd';
import { DocumentTableContainer } from '../../../../styles/TradeDocStyles';
import dayjs from 'dayjs';
import {
    formatCurrency,
    getInvoiceStatus,
    openNotification
} from '../../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../CustomModal';
import UpdateStatus from '../UpdateStatus';
import { updateInvoiceStatus } from '../../../../api/sales/sales';
import UpdatePayment from './UpdatePayment';
import { useUserProfile } from '../../../../customhooks/useUserProfile';
import { hasPermission } from '../../dashboardpage/utils';
import { Box } from '@mui/material';
import {
    Invoice,
    InvoiceParams
} from '../../../../pages/dashboard/payments/interfaces/Invoice';
import { CorporatePartner } from '../../../../interfaces/CorporatePartner';
import { NoDataCross } from '../../../icons/NoDataCross';
import { MoreIcon } from '../../../icons/MoreIcon';
import InvoiceTableMobileCards from './InvoiceTableMobileCards';

const InvoiceTable = ({
    loadingData,
    data,
    setCurrentData,
    currentData,
    refetchInvoice,
    paging,
    filterInvoiceOptions,
    setFilterInvoiceOptions,
    corporatePartners
}: {
    loadingData: boolean;
    data: Invoice[];
    setCurrentData: Dispatch<SetStateAction<Invoice>>;
    currentData: Invoice;
    refetchInvoice: () => void;
    paging: { page: number; total: number };
    filterInvoiceOptions: InvoiceParams;
    setFilterInvoiceOptions: Dispatch<SetStateAction<InvoiceParams>>;
    corporatePartners: CorporatePartner[];
}) => {
    const navigate = useNavigate();
    const { permissions } = useUserProfile();
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [error, setError] = useState<string | any>(null);
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false);
    const updateOptions = [
        { value: 'PARTIALLY_PAID', label: 'Partially Paid', color: '#6DA75F' },
        { value: 'OVERDUE', label: 'Overdue', color: '#D6DA1C' },
        { value: 'CANCELED', label: 'Canceled', color: '#CB444A' },
        { value: 'PAID', label: 'Paid', color: '#12B76A' },
        { value: 'DRAFT', label: 'Draft', color: '#A0A0A0' },
        { value: 'WRITE_OFF', label: 'Write Off', color: '#000' },
        { value: 'ISSUED', label: 'Issued', color: '#42A5F5' }
    ];

    const updateFunction = async (val: any) => {
        setLoading(true);
        setError(null);

        try {
            const res = await updateInvoiceStatus(
                currentData?.corporateDocumentId,
                val
            );

            setLoading(false);
            openNotification('success', 'Status updated successfully');
            setShowUpdateModal(false);
            refetchInvoice();
        } catch (error) {
            setLoading(false);
            if (error?.response) {
                if (error?.response?.data?.error) {
                    let hasFieldNameAttribute = false;

                    error?.response?.data?.error.map((item: any) => {
                        return Object.keys(item).map((itemInside: string) => {
                            if (itemInside === 'fieldName') {
                                hasFieldNameAttribute = true;

                                return;
                            }
                        });
                    });

                    if (hasFieldNameAttribute) {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>
                                    {element.messageError}
                                </li>
                            );
                        });
                        setError(errorList);
                    } else {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>{element}</li>
                            );
                        });
                        setError(errorList);
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className='flex items-center menu-otps'
                    onClick={() =>
                        navigate(
                            `/sales/invoices/details/${currentData?.corporateDocumentId}`
                        )
                    }
                >
                    <span className='ml-2'>Invoice Details</span>
                </div>
            )
        },
        {
            key: '2',
            label: (
                <div
                    onClick={() => {
                        if (
                            permissions &&
                            hasPermission({
                                userPermissions: permissions,
                                module: 'sales',
                                permissionList: ['write', '*']
                            })
                        ) {
                            navigate(
                                `/trade-documents/commercial-invoice?edit=${currentData?.corporateDocumentId}`
                            );
                        } else {
                            openNotification(
                                'error',
                                'You do not have access to perform this action'
                            );
                        }
                    }}
                    className={`flex items-center menu-otps ${
                        permissions &&
                        !hasPermission({
                            userPermissions: permissions,
                            module: 'sales',
                            permissionList: ['write', '*']
                        }) &&
                        ` opacity-30 cursor-not-allowed`
                    }`}
                >
                    <span className='ml-2'>Edit Invoice</span>
                </div>
            )
        },

        {
            key: '4',
            label: (
                <div
                    onClick={() => {
                        if (
                            permissions &&
                            hasPermission({
                                userPermissions: permissions,
                                module: 'sales',
                                permissionList: ['write', '*']
                            })
                        ) {
                            setShowUpdateModal(true);
                        } else {
                            openNotification(
                                'error',
                                'You do not have access to perform this action'
                            );
                        }
                    }}
                    className={`flex items-center menu-otps ${
                        permissions &&
                        !hasPermission({
                            userPermissions: permissions,
                            module: 'sales',
                            permissionList: ['write', '*']
                        }) &&
                        ` opacity-30 cursor-not-allowed`
                    }`}
                >
                    <span className='ml-2'>Update Status</span>
                </div>
            )
        },

        {
            key: '5',
            label: (
                <div
                    onClick={() => {
                        if (
                            permissions &&
                            hasPermission({
                                userPermissions: permissions,
                                module: 'sales',
                                permissionList: ['write', '*']
                            })
                        ) {
                            setShowUpdatePaymentModal(true);
                        } else {
                            openNotification(
                                'error',
                                'You do not have access to perform this action'
                            );
                        }
                    }}
                    className={`flex items-center menu-otps ${
                        permissions &&
                        !hasPermission({
                            userPermissions: permissions,
                            module: 'sales',
                            permissionList: ['write', '*']
                        }) &&
                        ` opacity-30 cursor-not-allowed`
                    }`}
                >
                    <span className='ml-2'>Update Payment</span>
                </div>
            )
        },
        {
            key: '6',
            label: (
                <div
                    className={`flex items-center menu-otps ${
                        permissions &&
                        !hasPermission({
                            userPermissions: permissions,
                            module: 'sales',
                            permissionList: ['write', '*']
                        }) &&
                        ` opacity-30 cursor-not-allowed`
                    }`}
                >
                    <span className='ml-2'>Delete Invoice</span>
                </div>
            )
        }
    ];

    const EmptyTable = () => (
        <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
            <div className='flex justify-center mb-4'>
                <NoDataCross />
            </div>

            <p className='text-center text-appcolorblackthree'>
                You have no Invoices.
                <br />
            </p>
        </div>
    );
    return (
        <>
            <DocumentTableContainer className='bg-appcolorwhite rounded-none lg:rounded-[8px]'>
                <Table
                    loading={loadingData}
                    pagination={false}
                    locale={{
                        emptyText: () => {
                            return <EmptyTable />;
                        }
                    }}
                    rowKey='id'
                    dataSource={data ?? []}
                    columns={[
                        {
                            title: 'Date Created',
                            dataIndex: 'createdDate',
                            key: 'createdDate',
                            render: (_, record) => (
                                <span>
                                    {dayjs(record?.createdDate).format('ll')}
                                </span>
                            )
                        },
                        {
                            title: 'Due Date',
                            dataIndex: 'dueDate',
                            key: 'dueDate',
                            render: (_, record) => (
                                <span>
                                    {dayjs(record?.dueDate).format('ll')}
                                </span>
                            )
                        },

                        {
                            title: 'Invoice #',
                            dataIndex: 'invoiceNumber',
                            key: 'invoiceNumber',
                            render: (text) => (
                                <span className=''>{text ?? '-'}</span>
                            )
                        },
                        {
                            title: 'Customer Name',
                            dataIndex: 'consigneeName',
                            key: 'consigneeName',
                            render: (text) => (
                                <span className=''>{text ?? '-'}</span>
                            )
                        },
                        {
                            title: 'Total Amount',
                            dataIndex: 'totalAmount',
                            key: 'totalAmount',
                            render: (_, record) => (
                                <span>
                                    {formatCurrency(
                                        record?.totalAmount || 0,
                                        record?.currencyCode || 'NGN'
                                    )}
                                </span>
                            )
                        },
                        {
                            title: 'Balances Due',
                            dataIndex: 'totalPaid',
                            key: 'totalPaid',
                            render: (_, record) => (
                                <span>
                                    {formatCurrency(
                                        record?.totalAmount -
                                            record?.totalPaid || 0,
                                        record?.currencyCode || 'NGN'
                                    )}
                                </span>
                            )
                        },
                        {
                            title: 'Payment Terms',
                            dataIndex: 'termsOfPayment',
                            key: 'termsOfPayment'
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                            render: (text) => (
                                <span className=''>
                                    {getInvoiceStatus(text) ?? '-'}
                                </span>
                            )
                        },
                        {
                            title: '',
                            render: (_, data) => (
                                <div
                                    className='cursor-pointer'
                                    onClick={() => {
                                        setCurrentData(data);
                                    }}
                                >
                                    <Dropdown
                                        menu={{ items }}
                                        trigger={['click']}
                                        placement='bottomRight'
                                    >
                                        <Box className=' flex flex-row justify-center'>
                                            <MoreIcon />
                                        </Box>
                                    </Dropdown>
                                </div>
                            )
                        }
                    ]}
                    className='hidden lg:block'
                />
                <div className='lg:hidden'>
                    <InvoiceTableMobileCards
                        loading={loadingData}
                        invoices={data}
                        fetchInvoices={refetchInvoice}
                        filterOptions={filterInvoiceOptions}
                        setFilterOptions={setFilterInvoiceOptions}
                        setCurrentData={setCurrentData}
                        items={items}
                        corporatePartners={corporatePartners}
                        paging={paging}
                    />
                </div>
                {data?.length > 0 && (
                    <Box className='lg:flex hidden lg:justify-end bg-white lg:py-5 pt-2 pb-10 flex-col lg:flex-row gap-4 justify-center items-center pr-2'>
                        <Pagination
                            total={paging.total}
                            defaultCurrent={1}
                            defaultPageSize={20}
                            current={paging.page}
                            onChange={(page) => {
                                setFilterInvoiceOptions((props) => ({
                                    ...props,
                                    page
                                }));
                            }}
                        />
                    </Box>
                )}

                {showUpdateModal && (
                    <CustomModal
                        maxWidth='md'
                        open={showUpdateModal}
                        onClose={() => setShowUpdateModal(false)}
                        title=''
                    >
                        <UpdateStatus
                            {...{
                                updateOptions,
                                updateFunction,
                                loading,
                                error,
                                setSelectedStatus,
                                selectedStatus
                            }}
                        />
                    </CustomModal>
                )}

                {showUpdatePaymentModal && (
                    <CustomModal
                        maxWidth='md'
                        open={showUpdatePaymentModal}
                        onClose={() => setShowUpdatePaymentModal(false)}
                        title=''
                    >
                        <UpdatePayment
                            fetchContracts={refetchInvoice}
                            currentData={currentData}
                            {...{ setShowUpdatePaymentModal }}
                        />
                    </CustomModal>
                )}
            </DocumentTableContainer>
        </>
    );
};

export default InvoiceTable;
