import React from 'react';
import { Wallet } from './interfaces/Wallet';
import { CopyText } from '../../../components/CopyText';

export const LocalBankAccount = ({
    selectedWallet
}: {
    selectedWallet: Wallet;
}) => {
    return (
        <>
            <h3 className='font-bold text-[14px] mb-[.5rem]'>
                {selectedWallet.currencyCode} Account (Local)
            </h3>
            <p>
                Account Number:
                <span className='font-semibold'>
                    &nbsp; {selectedWallet.localAccountNumber}
                </span>
                &nbsp;
                <CopyText text={selectedWallet?.localAccountNumber || ''} />
            </p>
            <p className='text-paleSky text-sm mb-[8px]'>
                Account Name:
                <span className='font-semibold'>
                    &nbsp; {selectedWallet.localAccountName}
                </span>
            </p>
            <p className='text-paleSky text-sm mb-[8px]'>
                Sort Code:
                <span className='font-semibold'>
                    &nbsp; {selectedWallet.localSortCode}
                </span>
            </p>
            <p className='text-paleSky text-sm mb-[8px]'>
                Bank Name:
                <span className='font-semibold'>
                    &nbsp; {selectedWallet.localBankName}
                </span>
            </p>
            <p className='text-paleSky text-sm mb-8'>
                Bank Address:
                <span className='font-semibold'>
                    &nbsp; {selectedWallet.localBankAddress}
                </span>
            </p>
        </>
    );
};
