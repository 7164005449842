import { Wallet } from './interfaces/Wallet';
import { LocalBankAccount } from './LocalBankAccount';
import { GlobalBankAccount } from './GlobalBankAccount';
import { NoneModeBankAccount } from './NoneModeBankAccount';

export const BankAccounts = ({
    selectedWallet
}: {
    selectedWallet: Wallet;
}) => {
    return (
        <>
            <p className='text-sm text-steelBlue mb-[16px] font-medium'>
                Transfer an amount you would like to top up to
            </p>
            <div className='rounded-[5px] border border-gray300 py-[24px] px-[16px] flex flex-col p-6 mb-[10px]'>
                {(selectedWallet?.accountMode === 'none' ||
                    !selectedWallet?.accountMode) && (
                    <NoneModeBankAccount selectedWallet={selectedWallet} />
                )}

                {(selectedWallet?.accountMode === 'local' ||
                    selectedWallet?.accountMode === 'multi') && (
                    <LocalBankAccount selectedWallet={selectedWallet} />
                )}

                {(selectedWallet?.accountMode === 'global' ||
                    selectedWallet?.accountMode === 'multi') && (
                    <GlobalBankAccount selectedWallet={selectedWallet} />
                )}
            </div>
            <p className='text-paleSky text-sm mb-[8px]'>
                Use this account for this transaction only
            </p>
        </>
    );
};
