import React, { ReactNode } from 'react';

const UploadLogisticsInvoice = ({ children }: { children?: ReactNode }) => {
    return (
        <div className='p-[10px]'>
            <h3 className='text-[#101828] text-[18px] font-[600]'>
                Upload and attach files
            </h3>
            <p className='text-[#667085] text-[14px] mt-[8px]'>
                Upload Invoice here
            </p>
            <div className='lg:w-[450px] w-[90vw] h-[25rem] lg:h-max mt-[20px] '>
                {children}
            </div>
        </div>
    );
};

export default UploadLogisticsInvoice;
