import React, { useEffect, useState } from 'react';
import { getUploadedLogo } from '../../api/documents/documents';
import { openNotification } from '../../utils/helper';
import { REACT_APP_BASE_URL } from '../../config';
import styled from 'styled-components';
import DocumentViewer from '../DocumentViewer/DocumentViewer';

export interface customModalProps {
    selected: string;
    setSelected?: any;
}

const LogoOptions = ({ setSelected, selected }: customModalProps) => {
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState<any>(null);

    const fetchLogos = async () => {
        setLoadingData(true);
        try {
            const res = await getUploadedLogo({ page: 1, size: 6 });
            const { data } = res.data;
            setData(data);
            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            if (error?.response) {
                openNotification('error', `Something went wrong`);
            } else {
                openNotification('error', `Something went wrong`);
            }
        }
    };

    useEffect(() => {
        fetchLogos();
    }, []);

    return (
        <LogoOptionsContainer>
            {!loadingData &&
                data &&
                data?.content?.map((item: any) => (
                    <div onClick={() => setSelected(item.logoUrl)}>
                        <div
                            className={
                                selected === item.logoUrl
                                    ? 'preview-cont active-c'
                                    : 'preview-cont'
                            }
                        >
                            <DocumentViewer
                                fileUrl={`${REACT_APP_BASE_URL}/${item?.logoUrl.split(
                                    '/v1/'
                                )[1]}`}
                            />
                        </div>
                    </div>
                ))}
        </LogoOptionsContainer>
    );
};

export default LogoOptions;

const LogoOptionsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 400px;
    justify-content: space-between;
    cursor: pointer;
    .preview-cont {
        width: 180px;
        height: 40px;
        margin-bottom: 20px;
        border: 1px solid #0000002a;
    }
    .active-c {
        border: 2px solid #0088b2;
    }
    #image-renderer {
        background-image: unset !important;
    }
`;
