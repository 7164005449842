import { Grid, MenuItem, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { transports } from '../../../../../utils/appconstants';
import { CommercialTransportInterface } from '../../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/CommercialTypes';
import CountrySelector from '../../common/CountrySelector';

const TransportDetails = ({
    payload,
    updateField,
    errors,
    countries,
    isCountriesLoading
}: CommercialTransportInterface) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: '0'
                        }}
                    >
                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                select
                                error={errors?.transport.length > 0}
                                helperText={
                                    errors?.transport.length > 0
                                        ? errors?.transport
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='commercialinvoice-transport'
                                label='Transport'
                                name='transport'
                                value={payload?.transport || ''}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField('transport', e.target.value);
                                }}
                            >
                                {transports.map((item, index) => (
                                    <MenuItem
                                        value={item || ''}
                                        key={`transports-${item}-${index}`}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                error={errors?.voyageNumber.length > 0}
                                helperText={
                                    errors?.voyageNumber.length > 0
                                        ? errors?.voyageNumber
                                        : undefined
                                }
                                margin='normal'
                                // required
                                fullWidth
                                id='proformainvoice-voyageNumber'
                                label='Voyage Number (Optional)'
                                name='voyageNumber'
                                value={payload?.voyageNumber || ''}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField('voyageNumber', e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: 0
                        }}
                    >
                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                placeholder='Place'
                                error={errors?.finalDestination.length > 0}
                                helperText={
                                    errors?.finalDestination.length > 0
                                        ? errors?.finalDestination
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='proformainvoice-finalDestination'
                                label='Final Destination'
                                name='finalDestination'
                                value={payload?.finalDestination || ''}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'finalDestination',
                                        e.target.value
                                    );
                                }}
                            />
                        </Grid>

                        <CountrySelector
                            error={
                                errors?.countryOfFinalDestinationId.length > 0
                            }
                            helperText={
                                errors?.countryOfFinalDestinationId.length > 0
                                    ? errors?.countryOfFinalDestinationId
                                    : undefined
                            }
                            changeFunction={(
                                e: ChangeEvent<HTMLInputElement>
                            ) => {
                                updateField(
                                    'countryOfFinalDestinationId',
                                    e.target.value
                                );
                                updateField(
                                    'countryOfFinalDestinationName',
                                    countries.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === e.target.value
                                    )[0]?.name
                                );
                            }}
                            value={payload?.countryOfFinalDestinationId || ''}
                            id='commercialinvoice-countryOfFinalDestinationId'
                            label='Country Of Final Destination'
                            name='countryOfFinalDestinationId'
                            {...{ isCountriesLoading, countries }}
                        />
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export default TransportDetails;
