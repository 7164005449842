export const TagIcon = () => {
    return (
        <svg
            width='29'
            height='28'
            viewBox='0 0 29 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                x='0.5'
                width='28'
                height='28'
                rx='10.1818'
                fill='#0088B2'
                fillOpacity='0.05'
            />
            <path
                d='M22.8333 8.99996V11.0166C22.8333 12.3333 21.9999 13.1666 20.6833 13.1666H17.8333V7.34163C17.8333 6.41663 18.5916 5.66663 19.5166 5.66663C20.4249 5.67496 21.2583 6.04163 21.8583 6.64163C22.4583 7.24996 22.8333 8.08329 22.8333 8.99996Z'
                fill='#0088B2'
            />
            <path
                opacity='0.4'
                d='M6.16675 9.83329V21.5C6.16675 22.1916 6.95008 22.5833 7.50008 22.1666L8.92508 21.1C9.25841 20.85 9.72508 20.8833 10.0251 21.1833L11.4084 22.575C11.7334 22.9 12.2667 22.9 12.5917 22.575L13.9917 21.175C14.2834 20.8833 14.7501 20.85 15.0751 21.1L16.5001 22.1666C17.0501 22.575 17.8334 22.1833 17.8334 21.5V7.33329C17.8334 6.41663 18.5834 5.66663 19.5001 5.66663H10.3334H9.50008C7.00008 5.66663 6.16675 7.15829 6.16675 8.99996V9.83329Z'
                fill='#0088B2'
            />
            <path
                d='M14.2916 12.9584H9.70825C9.36659 12.9584 9.08325 12.675 9.08325 12.3334C9.08325 11.9917 9.36659 11.7084 9.70825 11.7084H14.2916C14.6333 11.7084 14.9166 11.9917 14.9166 12.3334C14.9166 12.675 14.6333 12.9584 14.2916 12.9584Z'
                fill='#0088B2'
            />
        </svg>
    );
};
