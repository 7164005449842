export const PermissionLockIcon = () => {
    return (
        <svg
            width='56'
            height='56'
            viewBox='0 0 56 56'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect x='4' y='4' width='48' height='48' rx='24' fill='#F2F4F7' />
            <rect
                x='4'
                y='4'
                width='48'
                height='48'
                rx='24'
                stroke='#F9FAFB'
                strokeWidth='8'
            />
            <path
                d='M22 26V24C22 20.69 23 18 28 18C33 18 34 20.69 34 24V26'
                stroke='#292D32'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M28 34.5C29.3807 34.5 30.5 33.3807 30.5 32C30.5 30.6193 29.3807 29.5 28 29.5C26.6193 29.5 25.5 30.6193 25.5 32C25.5 33.3807 26.6193 34.5 28 34.5Z'
                stroke='#292D32'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M33 38H23C19 38 18 37 18 33V31C18 27 19 26 23 26H33C37 26 38 27 38 31V33C38 37 37 38 33 38Z'
                stroke='#292D32'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
