import { z } from 'zod';

export const ContainerReeferType = z.object({
    temperature: z.number(),
    temperatureUnit: z.string().min(1),
    humidity: z.number(),
    ventSettings: z.string().min(1),
    airflow: z.number(),
    airFlowUnit: z.string().min(1),
    oxygenLevel: z.number(),
    carbonDioxideLevel: z.number(),
    nitrogenLevel: z.number(),
    genSetRequested: z.boolean(),
    temperatureControlInstructions: z.string().optional()
});

export type ContainerReefer = z.infer<typeof ContainerReeferType>;
