import { useState } from 'react';
import { ModalProps } from '../interfaces/ModalProps';
import { ContainerReefer } from '../interfaces/ContainerReefer';
import { CustomModalActions } from '../../../../../components/CustomModalActions';
import { ReeferActive } from './ReeferActive';
import { ReeferNonOperating } from './ReeferNonOperating';
import { Box, FormControlLabel, Switch } from '@mui/material';

export const ReeferModal = (props: ModalProps<ContainerReefer>) => {
    const { payload, onHandleClose } = props;
    const [reefer, setReefer] = useState(payload);
    const [activeReefer, setActiveReefer] = useState(true);
    const [isValidated, setIsValidated] = useState(false);

    return (
        <Box>
            <Box>
                <FormControlLabel
                    control={
                        <Switch
                            checked={activeReefer}
                            onChange={(_, value) => {
                                setReefer({} as ContainerReefer);
                                setActiveReefer(value);
                            }}
                        />
                    }
                    label={
                        activeReefer ? 'Active Reefer' : 'Non-Operating Reefer'
                    }
                />
            </Box>

            {activeReefer ? (
                <ReeferActive {...{ reefer, setReefer, setIsValidated }} />
            ) : (
                <ReeferNonOperating />
            )}

            <CustomModalActions
                onHandleSave={() => {
                    onHandleClose();
                }}
                onHandleClear={onHandleClose}
                disabled={activeReefer && !isValidated}
            />
        </Box>
    );
};
