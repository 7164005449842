import React, { useRef, memo } from 'react';
import styled from 'styled-components';
import { Box, CircularProgress } from '@mui/material';
import { queryConfig } from '../../api/queryBase';

const DocumentViewer = ({
    fileUrl,
    loader
}: {
    fileUrl: string;
    loader?: boolean;
}) => {
    const iframeRef = useRef(null);

    const query = queryConfig({ isBlob: true });
    const { isLoading, data } = query<Blob>({
        url: fileUrl,
        cacheKey: fileUrl
    });

    const newFileUrl = data?.payload ? URL.createObjectURL(data.payload) : null;

    const ext: string = fileUrl?.split('?size=')[0]?.split('.')[
        fileUrl?.split('?size=')[0]?.split('.').length - 1
    ];

    return (
        <>
            {!isLoading && newFileUrl && (
                <FrameContainer>
                    {ext === 'pdf' ? (
                        <iframe
                            title='PDF File Viewer'
                            ref={iframeRef}
                            src={newFileUrl}
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                        />
                    ) : (
                        <img src={newFileUrl} alt={ext} />
                    )}
                </FrameContainer>
            )}
            {isLoading && (
                <Box
                    width='100%'
                    height={loader ? '500px' : 'auto'}
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                >
                    <CircularProgress size={loader ? '3rem' : '1.5rem'} />
                </Box>
            )}
        </>
    );
};

export default memo(DocumentViewer);

export const FrameContainer = styled.div`
    width: '100%' !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    img {
        width: 100%;
        object-fit: scale-down;
        height: 100%;
    }

    iframe {
        width: 100%;
        height: 100%;
        border: none;
        margin: auto;
        background: unset;
    }
`;

export const ContentBody = styled.div`
    padding: 20px;
    max-height: 90vh !important;
    max-width: 80vw !important;
    width: ${({ extension }: { extension?: string }) =>
        extension === 'pdf' ? '100%' : 'max-content'} !important;
    height: ${({ extension }: { extension?: string }) =>
        extension === 'pdf' ? '100%' : 'max-content'} !important;
`;
