import React from 'react';
import { useOffshoreAccountCredentials } from '../../../../customhooks/useOffshoreAccountCredentials';
import { PlainFileIcon } from '../../../../components/icons/PlainFileIcon';
import { Backdrop, Skeleton, Stack, TextField } from '@mui/material';
import { ApproveRequestValues } from './interfaces/DedicatedAccounts';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { mutateConfig } from '../../../../api/queryBase';
import { getErrorMessage, openNotification } from '../../../../utils/helper';
import { LoadingButton } from '@mui/lab';
import { LoadingCircles } from '../../../../components/icons/LoadingCircles';

const DedicatedAccountCredentials = ({ id }: { id?: string }) => {
    const {
        isLoading,
        entries: credentials,
        isFetching
    } = useOffshoreAccountCredentials(id ?? '');

    const initialValues: ApproveRequestValues = {
        clientId: credentials?.clientId ?? '',
        clientKey: credentials?.clientKey ?? ''
    };

    const mutation = mutateConfig({ method: 'put' });
    const { mutate, isLoading: isLoadingUpdate } = mutation({
        url: `/administrator/corporates/${id}/offshore/account`
    });

    const handleSubmit = async (values: ApproveRequestValues) => {
        mutate(values, {
            onSettled: (_, error) => {
                if (error) {
                    openNotification(
                        'error',
                        getErrorMessage(error) ||
                            'Update Credentials, Something went wrong'
                    );
                    return;
                }
                openNotification('success', 'Update Credentials completed!');
            }
        });
    };

    return (
        <div>
            {isLoading || isFetching || !credentials ? (
                <div className=''>
                    <Skeleton variant='text' sx={{ fontSize: '25rem' }} />
                </div>
            ) : (
                <div className='my-[20px]'>
                    <div className='flex gap-[5px] items-center py-[10px] px-[14px] leading-[22px] text-[15px] bg-white w-max'>
                        <PlainFileIcon />
                        API Credentials
                    </div>

                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, errors, isValid }) => (
                            <Form className='p-[30px] bg-white rounded-[5px] rounded-tl-[0]'>
                                <Backdrop
                                    sx={{
                                        color: '#fff',
                                        zIndex: (theme) =>
                                            theme.zIndex.drawer + 1
                                    }}
                                    open={isLoadingUpdate}
                                >
                                    <div className='animate-spin'>
                                        <LoadingCircles />
                                    </div>
                                </Backdrop>
                                <Stack
                                    direction='row'
                                    gap='20px'
                                    width='100%'
                                    alignItems='end'
                                >
                                    <div className=' w-[25%]'>
                                        <p className='text-[#101828] text-[16px] pb-[5px] opacity-60'>
                                            Client ID
                                        </p>
                                        <Field
                                            as={TextField}
                                            error={!!errors.clientId}
                                            helperText={
                                                <ErrorMessage name='clientId' />
                                            }
                                            id='clientId'
                                            name='clientId'
                                            required
                                            fullWidth
                                            sx={{
                                                borderRadius: '8px',
                                                background: '#F9FAFB',
                                                color: '#667085'
                                            }}
                                        />
                                    </div>
                                    <div className=' w-[45%]'>
                                        <p className='text-[#101828] text-[16px] pb-[5px] opacity-60'>
                                            Client Key
                                        </p>
                                        <Field
                                            as={TextField}
                                            error={!!errors.clientKey}
                                            helperText={
                                                <ErrorMessage name='clientKey' />
                                            }
                                            id='clientKey'
                                            name='clientKey'
                                            required
                                            fullWidth
                                            sx={{
                                                borderRadius: '8px',
                                                background: '#F9FAFB',
                                                color: '#667085'
                                            }}
                                        />
                                    </div>
                                    <div className='w-[25%] p-[3px]'>
                                        <LoadingButton
                                            loading={isSubmitting}
                                            style={{
                                                color: 'white',
                                                background: '#0088B2',
                                                width: '100%',
                                                height: '50px',
                                                boxShadow:
                                                    '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                                padding: '10px',
                                                borderRadius: '5px'
                                            }}
                                            type='submit'
                                            disabled={
                                                !isValid || isLoadingUpdate
                                            }
                                        >
                                            Update
                                        </LoadingButton>
                                    </div>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        </div>
    );
};

export default DedicatedAccountCredentials;
