import { useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { REACT_APP_GOOGLEAPIKEY } from '../config';
import {
    ClickAwayListener,
    List,
    ListItem,
    ListItemButton
} from '@mui/material';
import CustomTextField from './CustomTextInput';

export interface CustomMapInputProps {
    value?: string;
    name: string;
    updateField: (name: string, value: string) => void;
    label: string;
    updatePlaceIDField?: (name: string, value: string) => void;
}

const CustomMapInput = ({
    value,
    name,
    updateField,
    label,
    updatePlaceIDField
}: CustomMapInputProps) => {
    const { placesService, placePredictions, getPlacePredictions } =
        usePlacesService({
            apiKey: REACT_APP_GOOGLEAPIKEY
        });

    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (placePredictions.length)
            placesService?.getDetails(
                {
                    placeId: placePredictions[0].place_id
                },
                () => {}
            );
    }, [placePredictions]);

    useEffect(() => {
        if (value) {
            setInputValue(value);
        }
    }, [value]);

    return (
        <div className='relative w-full'>
            <ClickAwayListener
                onClickAway={() => {
                    setOpen(false);
                }}
            >
                <div className='relative'>
                    <CustomTextField
                        name='name'
                        autoComplete='off'
                        value={inputValue}
                        label={label}
                        placeholder=''
                        onChange={(e) => {
                            setInputValue(e.target.value);
                            getPlacePredictions({ input: e.target.value });
                        }}
                        onFocus={() => {
                            setOpen(true);
                        }}
                    />
                    {open &&
                    inputValue !== undefined &&
                    inputValue?.trim().length > 0 ? (
                        <div
                            className='absolute w-full bg-appcolorwhite left-[0] overflow-hidden
       outline-0 z-[5050]
       '
                            style={{
                                boxShadow:
                                    '15px 30px 40px rgba(64, 25, 109, 0.08)',
                                maxHeight: '200px',
                                overflowY: 'scroll',
                                border: '1px solid rgba(0, 0, 0, 0.1)',
                                marginTop: '0.5rem',
                                borderRadius: '5px'
                            }}
                        >
                            <List>
                                {placePredictions.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        onClick={() => {
                                            updateField(name, item.description);
                                            if (
                                                updatePlaceIDField !== undefined
                                            ) {
                                                updatePlaceIDField(
                                                    name,
                                                    item.place_id
                                                );
                                            }
                                            setOpen(false);
                                            setInputValue(item.description);
                                        }}
                                        sx={{
                                            padding: '0'
                                        }}
                                    >
                                        <ListItemButton>
                                            {item.description}
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    ) : null}
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default CustomMapInput;
