import React, { useState } from 'react';
import { Dropdown, MenuProps, Table } from 'antd';
import moment from 'moment';
import CustomModal from '../../CustomModal';
import ApprovalModalContent from './ApprovalModalContent';
import { openNotification } from '../../../utils/helper';
import { approveShippingProfile } from '../../../api/booking/booking';
import { Alert, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CurrentDataType, ShippingProfileProps } from './ShippingProfileProps';

const ShippingProfileTable = ({
    loadingData,
    fetch,
    data
}: ShippingProfileProps) => {
    const [currentData, setCurrentData] = useState<CurrentDataType>();
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [contractNumber, setContractNumber] = useState('');
    const [inttraAlias, setInttraAlias] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<
        string | JSX.Element[] | JSX.Element | null | string[]
    >(null);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const navigate = useNavigate();

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        navigate(
                            `/bookings/shipping-profile/${currentData?.id}/details`
                        );
                    }}
                >
                    <span className='ml-2'>Review Profile Details</span>
                </div>
            )
        },
        {
            key: '2',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        setShowApproveModal(true);
                    }}
                >
                    <span className='ml-2'>Approve Shipping Profile</span>
                </div>
            )
        }
    ];

    const approveFunction = async () => {
        setError(null);
        if (!contractNumber || !inttraAlias) {
            setError('Booking Number & Intra Alias are required');
        } else {
            setLoading(true);
            try {
                await approveShippingProfile(currentData?.id || '', {
                    contractNumber,
                    inttraAlias
                });
                setLoading(false);
                setShowApproveModal(false);
                openNotification(
                    'success',
                    'Shipping Profile was approved successfully'
                );
                fetch();
            } catch (error) {
                setLoading(false);
                openNotification(
                    'error',
                    'Shipping Profile approval, Something went wrong'
                );
                const otherErrors: string[] = [];

                if (error?.response) {
                    if (error?.response?.data?.error) {
                        const resError = error?.response?.data?.error;

                        if (typeof resError === 'string') {
                            const errorList = (
                                <li key={`error-list-${1}`}>{resError}</li>
                            );
                            setError(errorList);
                        } else {
                            Object.entries(resError).forEach(([key, value]) => {
                                if (Object.keys(errors).includes(key)) {
                                    setErrors((prevState) => ({
                                        ...prevState,
                                        [key]: value as string
                                    }));
                                } else {
                                    otherErrors.push(value as string);
                                }
                            });

                            if (otherErrors.length > 0) {
                                const errorList = otherErrors.map(
                                    (element, index) => {
                                        return (
                                            <li key={`error-list-${index}`}>
                                                {element}
                                            </li>
                                        );
                                    }
                                );
                                setError(errorList);
                            }
                        }
                    } else {
                        setError(
                            error?.response?.data?.message ||
                                'Something went wrong'
                        );
                    }
                } else {
                    setError('Something went wrong');
                }
            }
        }
    };
    return (
        <>
            <Table
                loading={loadingData}
                pagination={{ pageSize: 20 }}
                dataSource={data?.content}
                columns={[
                    {
                        title: 'Date',
                        dataIndex: 'createdDate',
                        key: 'createdDate',
                        render: (_, { createdDate }) => (
                            <span>
                                {createdDate
                                    ? moment(createdDate).format('ll')
                                    : '-'}
                            </span>
                        )
                    },
                    {
                        title: 'Business Name',
                        dataIndex: 'businessName',
                        key: 'businessName',
                        render: (text) => <span>{text}</span>
                    },
                    {
                        title: 'Requester Name',
                        dataIndex: 'firstName',
                        key: 'firstName',
                        render: (_, { firstName, lastName }) => (
                            <span>{`${firstName ?? '-'} ${
                                lastName ?? '-'
                            }`}</span>
                        )
                    },
                    {
                        title: 'Shipping Line',
                        dataIndex: 'shippingLineName',
                        key: 'shippingLineName'
                    },
                    {
                        title: 'BL Number',
                        dataIndex: 'blNumber',
                        key: 'blNumber',
                        render: (text) => (
                            <span className='text-appcolorprimary'>{text}</span>
                        )
                    },
                    {
                        title: 'Requester Email',
                        dataIndex: 'requesterContactEmail',
                        key: 'requesterContactEmail'
                    },
                    {
                        title: '',
                        render: (_, data) => (
                            <div
                                className='cursor-pointer'
                                onClick={() => {
                                    setCurrentData(data);
                                }}
                            >
                                <Dropdown
                                    menu={{ items }}
                                    trigger={['click']}
                                    placement='bottomRight'
                                >
                                    <img
                                        src='/img/vectors/more.svg'
                                        alt='more'
                                    />
                                </Dropdown>
                            </div>
                        )
                    }
                ]}
            />

            {showApproveModal && (
                <CustomModal
                    maxWidth='md'
                    open={showApproveModal}
                    onClose={() => setShowApproveModal(false)}
                    title=''
                >
                    <ApprovalModalContent
                        type='Shipping Profile'
                        {...{ currentData }}
                        approveFunction={approveFunction}
                        cancelFunction={() => {
                            setContractNumber('');
                            setShowApproveModal(false);
                        }}
                        loading={loading}
                    >
                        {error && (
                            <div className='mt-4'>
                                <Alert severity='error'>{error}</Alert>
                            </div>
                        )}
                        <TextField
                            value={contractNumber}
                            margin='normal'
                            required
                            fullWidth
                            id='contractNumber'
                            label='Contract Number'
                            name='contractNumber'
                            onChange={(e) => {
                                setContractNumber(e.target.value);
                            }}
                        />
                        <TextField
                            value={inttraAlias}
                            margin='normal'
                            required
                            fullWidth
                            id='inttraAlias'
                            label='Intra Alias'
                            name='inttraAlias'
                            onChange={(e) => {
                                setInttraAlias(e.target.value);
                            }}
                        />
                    </ApprovalModalContent>
                </CustomModal>
            )}
        </>
    );
};

export default ShippingProfileTable;
