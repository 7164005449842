import React, { useState, useRef, ChangeEvent, FocusEvent } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Custombutton from '../../../../../components/custombutton/custombutton';
import AddBeneficiaryDrawer from '../../AddBeneficiaryDrawer';
import { useTheme } from '@mui/material/styles';
import { PaymentPurposesInterface } from '../../../../../customhooks/paymentpurpose/usePaymentpurposes';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Upload } from 'antd';
import { REACT_APP_BASE_URL } from '../../../../../config';
import useWallets from '../../../../../customhooks/wallets/useWallets';
import Skeleton from '@mui/material/Skeleton';
import { Beneficiary } from '../../interfaces/Beneficiary';
import { Chip, useMediaQuery } from '@mui/material';
import { PaymentRequest } from '../../interfaces/PaymentRequest';
import { validateEmail } from '../../../../../utils/helper';
import UploadItem from '../../../../../components/UploadItem';
import AutoCompleteSelect from '../../../../../components/AutoCompleteSelect';
import CustomTextField from '../../../../../components/CustomTextInput';
import { SuitUIButton } from '../../../../../components/SuitUIButton';
import { PlusIcon } from '../../../../../components/icons/PlusIcon';

export interface selectbeneficiaryProps {
    activeStep: number;
    updateStep: (activeStep: number) => void;
    conversionType: number;
    requestObject: PaymentRequest;
    updateRequestObject: (value: PaymentRequest) => void;
    updateRecipientChoosen: (value: any) => void;
    updateConversionType: (value: number) => void;
    beneficiaries: Beneficiary[];
    isBeneficiariesLoading: boolean;
    fetchBeneficiaries: () => void;
    paymentPurposes: PaymentPurposesInterface[];
    loadingPaymentPurposes: boolean;
}

interface FormErrors {
    recipientId: string;
    purposeId: string;
    reference: string;
    transactionDocumentsUrl: string;
    notifyParties: string;
}

const Selectbeneficiary = ({
    updateStep,
    activeStep,
    requestObject,
    updateRequestObject,
    updateRecipientChoosen,
    updateConversionType,
    beneficiaries,
    isBeneficiariesLoading,
    fetchBeneficiaries,
    paymentPurposes,
    loadingPaymentPurposes
}: selectbeneficiaryProps) => {
    const { wallets, isLoading: isWalletsLoading } = useWallets();

    const theme = useTheme();

    const uploadRef = useRef<any>(null);

    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('md'));

    const [errors, setErrors] = useState<FormErrors>({} as FormErrors);

    const [showAddBeneficiary, setShowAddBeneficiary] = useState(false);
    const [beneficiaryType, setBeneficiaryType] = useState(
        'beneficiary-account'
    );

    const updateField = (name: string, value: any) => {
        setErrors((prevState) => ({
            ...prevState,
            [name]: ''
        }));

        const newRequest = { ...requestObject };

        updateRequestObject({
            ...requestObject,
            ...newRequest,
            [name]: value
        });
    };

    /**
     * Validates the inputs and move to the next step
     */
    const validateToNextStep = () => {
        const newErrors = {
            ...errors,
            recipientId: '',
            purposeId: '',
            transactionDocumentsUrl: '',
            notifyParties: ''
        };

        newErrors['recipientId'] = '';
        newErrors['purposeId'] = '';
        newErrors['transactionDocumentsUrl'] = '';
        newErrors['notifyParties'] = '';

        if (beneficiaryType === 'beneficiary-account') {
            if (requestObject.recipientId === undefined) {
                newErrors.recipientId = 'Recipient is required';
            }

            if (requestObject.purposeId === undefined) {
                newErrors.purposeId = 'Purpose is required';
            }
        }
        if (requestObject.transactionDocumentsUrl === undefined) {
            newErrors.transactionDocumentsUrl = 'Document is required';
        }

        if (
            requestObject?.notifyParties?.length &&
            !requestObject?.notifyParties?.every((item) => validateEmail(item))
        ) {
            newErrors.notifyParties =
                'All parties should be a valid email address';
        }

        setErrors((prevState) => ({
            ...prevState,
            ...newErrors
        }));

        if (Object.values(newErrors).every((item) => item === '')) {
            updateStep(activeStep + 1);
        }
    };

    const closeAddBeneficiaryDrawer = () => {
        setShowAddBeneficiary(false);
    };

    const filterBeneficiary = (beneficiaries: Beneficiary[]) => {
        const filteredData = beneficiaries?.filter(
            (item: Beneficiary) =>
                item.currencyCode === requestObject.receiveCurrency
        );
        return filteredData?.map((item) => {
            return {
                label: `${item.beneficiaryCompanyName} - ${item.accountNumber}`,
                value: String(item.id)
            };
        });
    };

    const formattedPurposes = paymentPurposes.map((item) => {
        return {
            label: item.title,
            value: String(item.id)
        };
    });
    const handleChangeBeneficiaryType = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const valueChoosen = (event.target as HTMLInputElement).value;
        setBeneficiaryType(valueChoosen);
        if (valueChoosen === 'own-account') {
            updateConversionType(2);

            const checkCurrency = wallets.filter(
                (item) => item.currencyCode === requestObject.receiveCurrency
            );
            updateRecipientChoosen(checkCurrency[0]);
        } else {
            updateConversionType(1);
            updateRecipientChoosen(null);
        }
    };

    const userHasReceiveWallet = () => {
        const checkCurrency = wallets.filter(
            (item) => item.currencyCode === requestObject.receiveCurrency
        );
        if (checkCurrency.length > 0) {
            return true;
        }
        return false;
    };

    const validateReference = (reference: string): boolean => {
        if (reference.trim() === '') {
            return true;
        }

        if (/^[a-zA-Z0-9]+$/.test(reference) && reference.length <= 32) {
            return true;
        } else {
            setErrors((errors) => ({
                ...errors,
                reference:
                    'Must be 32 characters or less and contain only alphanumeric characters'
            }));
            return false;
        }
    };

    return (
        <div>
            <p className='hidden lg:block text-[19px] leading-6 pt-6'>
                Who are you sending money to?
            </p>

            {requestObject.convertCurrency !==
                requestObject.receiveCurrency && (
                <div className='mt-4 px-[16px] lg:px-0'>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby='beneficiary-select-type'
                            name='beneficiary-type'
                            value={beneficiaryType}
                            onChange={handleChangeBeneficiaryType}
                        >
                            <FormControlLabel
                                value='beneficiary-account'
                                control={<Radio />}
                                label='A beneficairy account'
                            />

                            {isWalletsLoading ? (
                                <>
                                    <div className='w-[10rem]'>
                                        <Skeleton
                                            variant='text'
                                            sx={{ fontSize: '2em' }}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    {userHasReceiveWallet() ? (
                                        <FormControlLabel
                                            value='own-account'
                                            control={<Radio />}
                                            label={`My ${requestObject.receiveCurrency} account`}
                                        />
                                    ) : null}
                                </>
                            )}
                        </RadioGroup>
                    </FormControl>
                </div>
            )}

            <div className='relative mt-4 lg:mt-8 px-[16px] lg:px-0'>
                <Grid
                    container
                    spacing={4}
                    sx={{
                        marginTop: '0px'
                    }}
                >
                    <Grid item xs={12} md={12} lg={9}>
                        <div>
                            <AutoCompleteSelect
                                id='beneficiary-purpose'
                                label='Purpose'
                                required={
                                    beneficiaryType === 'beneficiary-account'
                                        ? true
                                        : false
                                }
                                options={formattedPurposes}
                                value={formattedPurposes?.find(
                                    (item) =>
                                        item.value === requestObject.purposeId
                                )}
                                isLoading={loadingPaymentPurposes}
                                onChange={({
                                    value
                                }: {
                                    label: string;
                                    value: string;
                                }) => {
                                    if (value !== null) {
                                        updateField('purposeId', value);
                                    }
                                }}
                                name='purposeId'
                                error={!!errors?.purposeId}
                                helperText={errors?.purposeId}
                            />
                        </div>

                        <div>
                            <Upload
                                action={`${REACT_APP_BASE_URL}/files/upload`}
                                accept='.png, .jpg, .jpeg, .pdf'
                                name='file'
                                maxCount={1}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        'token'
                                    )}`
                                }}
                                defaultFileList={
                                    requestObject.transactionDocumentsUrl
                                        ? [
                                              {
                                                  uid: '1',
                                                  name:
                                                      requestObject.transactionDocumentsName ||
                                                      '',
                                                  status: 'done',
                                                  url: requestObject.transactionDocumentsUrl
                                              }
                                          ]
                                        : []
                                }
                                onChange={({ file }) => {
                                    if (file.status !== 'uploading') {
                                        updateRequestObject({
                                            ...requestObject,
                                            transactionDocumentsUrl:
                                                file.response.data.fileUri,
                                            transactionDocumentsName: file.name
                                        });
                                    }
                                }}
                                method={'post'}
                                data={{
                                    fileType: 'PAYMENT'
                                }}
                            >
                                <span ref={uploadRef} className='opacity-0' />
                            </Upload>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={3}>
                        <div className='hidden lg:block'>
                            <Custombutton
                                fullWidth
                                variant='contained'
                                styles={{
                                    borderColor: errors.transactionDocumentsUrl
                                        ? 'red'
                                        : theme.palette.primary.main,
                                    borderWidth: '1px',
                                    borderStyle: 'solid',
                                    padding: '0.75rem 0.92rem 0.9rem 0.93rem',
                                    backgroundColor: 'transparent',
                                    color: errors.transactionDocumentsUrl
                                        ? 'red'
                                        : theme.palette.primary.main,
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                        color: theme.palette.primary.main
                                    }
                                }}
                                disabled={false}
                                buttonText='+ Upload'
                                size='small'
                                onClickAction={() => {
                                    if (uploadRef?.current) {
                                        uploadRef?.current.click();
                                    }
                                }}
                            />
                        </div>

                        <div className='lg:hidden'>
                            <UploadItem
                                actionText='Click to upload a supporting document'
                                action={`${REACT_APP_BASE_URL}/files/upload`}
                                accept='.png, .jpg, .jpeg, .pdf'
                                name='file'
                                maxCount={1}
                                error={errors.transactionDocumentsUrl}
                                defaultFileList={
                                    requestObject.transactionDocumentsUrl
                                        ? [
                                              {
                                                  uid: '1',
                                                  name:
                                                      requestObject.transactionDocumentsName ||
                                                      '',
                                                  status: 'done',
                                                  url: requestObject.transactionDocumentsUrl
                                              }
                                          ]
                                        : []
                                }
                                onChange={(file) => {
                                    if (file.status !== 'uploading') {
                                        updateRequestObject({
                                            ...requestObject,
                                            transactionDocumentsUrl:
                                                file.response.data.fileUri,
                                            transactionDocumentsName: file.name
                                        });
                                    }
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>

                <p
                    className='hidden lg:block text-[13px] font-bold leading-[16px] caveat-text top-[-1.5rem] right-[-0.5rem] absolute'
                    style={{ color: errors.transactionDocumentsUrl && 'red' }}
                >
                    upload a supporting document
                </p>
            </div>

            <div className='mt-4 lg:mt-0 px-[16px] lg:px-0'>
                <CustomTextField
                    error={!!errors?.reference}
                    helperText={errors?.reference}
                    id='beneficiary-reference'
                    label='Add reference (optional)'
                    name='reference'
                    value={requestObject.reference}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        updateField('reference', e.target.value);
                    }}
                    onBlur={(e: FocusEvent<HTMLInputElement>) => {
                        validateReference(e.target.value);
                    }}
                />
            </div>

            {beneficiaryType === 'beneficiary-account' && (
                <>
                    <div className='mt-4 lg:mt-8 px-[16px] lg:px-0'>
                        <AutoCompleteSelect
                            id='beneficiary-beneficiaries'
                            label={`Beneficiary (${requestObject.receiveCurrency})`}
                            required={
                                beneficiaryType === 'beneficiary-account'
                                    ? true
                                    : false
                            }
                            options={filterBeneficiary(beneficiaries)}
                            value={filterBeneficiary(beneficiaries)?.find(
                                (item) =>
                                    item.value === requestObject.recipientId
                            )}
                            isLoading={isBeneficiariesLoading}
                            onChange={({
                                value
                            }: {
                                label: string;
                                value: string;
                            }) => {
                                if (value !== null) {
                                    const recipientChoosen =
                                        beneficiaries?.find(
                                            (item: Beneficiary) =>
                                                String(item.id) === value
                                        );

                                    updateRecipientChoosen(recipientChoosen);
                                    updateField('recipientId', value);
                                    updateConversionType(1);
                                }
                            }}
                            name='recipientId'
                            error={!!errors?.recipientId}
                            helperText={errors?.recipientId}
                        />
                    </div>

                    <div className='flex justify-end mt-1 hidden lg:block'>
                        <span
                            className='text-appcolorprimary text-[13px] leading-[150%] cursor-pointer'
                            onClick={() => {
                                setShowAddBeneficiary(true);
                            }}
                        >
                            + Add Beneficiary
                        </span>
                    </div>

                    <div className='lg:hidden px-[16px] lg:px-0'>
                        <p className='my-4 text-paleSky font-medium text-sm'>
                            or
                        </p>
                        <SuitUIButton
                            text='Add beneficiary'
                            onClick={() => {
                                setShowAddBeneficiary(true);
                            }}
                            invert
                            fullWidth
                            iconLeft={<PlusIcon />}
                            style='border border-gray300 text-steelBlue font-medium text-base leading-[24px]'
                        />
                    </div>
                </>
            )}

            <div className='mt-4 px-[16px] lg:px-0'>
                <Autocomplete
                    clearOnBlur
                    freeSolo
                    multiple
                    disableClearable
                    options={[]}
                    value={requestObject.notifyParties}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                variant='outlined'
                                label={option}
                                {...getTagProps({
                                    index
                                })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <CustomTextField
                            params={params}
                            name='notify'
                            label='Notify Parties'
                            required={false}
                            error={!!errors?.notifyParties}
                            helperText={errors?.notifyParties}
                            multiline={isMobile}
                            rows={3}
                            height={isMobile ? 146 : 48}
                        />
                    )}
                    onChange={(_, value) => {
                        if (value) {
                            updateRequestObject({
                                ...requestObject,
                                notifyParties: value
                            });
                        }
                    }}
                />
                <div className='text-midnightHaze text-sm lg:text-appcolorprimary lg:text-[13px] lg:text-right mt-1'>
                    This input supports multiple email entries, press enter when
                    done to start a new entry.
                </div>
            </div>

            <div className='relative mt-4 px-[16px] lg:px-0'>
                <div className='flex gap-[16px] items-center justify-end mt-8 mb-[80px] lg:mb-0'>
                    <SuitUIButton
                        text='Back'
                        onClick={() => {
                            updateStep(activeStep - 1);
                            updateConversionType(1);
                        }}
                        invert
                        fullWidth
                        style='px-[0.92rem] border border-gray300 text-steelBlue font-medium text-sm leading-[20px] lg:border-none lg:text-appcolorprimary'
                    />

                    <SuitUIButton
                        text='Continue'
                        onClick={() => validateToNextStep()}
                        fullWidth
                        style='px-[0.92rem]'
                    />
                </div>
            </div>

            {
                <AddBeneficiaryDrawer
                    closeDrawer={closeAddBeneficiaryDrawer}
                    showDrawer={showAddBeneficiary}
                    reRunGetBeneficiaries={fetchBeneficiaries}
                    setBeneficiaryId={() => console.log()}
                />
            }
        </div>
    );
};

export default Selectbeneficiary;
