import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Custombutton from '../../../../../components/custombutton/custombutton';
import { commaNumber, getErrorMessage } from '../../../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import CustomModal from '../../../../../components/CustomModal';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { mutateConfig } from '../../../../../api/queryBase';
import { toast } from 'react-toastify';
import { Beneficiary } from '../../interfaces/Beneficiary';
import { Wallet } from '../../interfaces/Wallet';
import { PaymentRequest } from '../../interfaces/PaymentRequest';
import { SuitUIButton } from '../../../../../components/SuitUIButton';

interface ConfirmationProps {
    updateStep: (activeStep: number) => void;
    conversionType: number;
    requestObject: PaymentRequest;
    rate: number;
    recipientChoosen: Beneficiary & {
        bankName?: string;
        accountNumber?: string;
    };
    sourceWallet?: Wallet;
}

const Confirmation = ({
    updateStep,
    conversionType,
    requestObject,
    rate,
    recipientChoosen,
    sourceWallet
}: ConfirmationProps) => {
    const navigate = useNavigate();
    const theme = useTheme();

    const [error, setError] = useState<string | any>(null);
    const [rateChange, setRateChange] = useState(false);
    const [isMakingPayment, setIsMakingPayment] = useState(false);
    const [expectedNewRate, setexpectedNewRate] = useState(null);
    const mutation = mutateConfig({});
    const { mutate, isLoading } = mutation({
        url: '/wallet/transaction/create'
    });

    const onHandleSubmit = () => {
        setIsMakingPayment(true);
        mutate(
            {
                destinationCurrencyCode: requestObject.receiveCurrency,
                expectedDestinationAmount:
                    requestObject.convertAmount *
                    (expectedNewRate === null ? rate : expectedNewRate),
                expectedRate: expectedNewRate === null ? rate : expectedNewRate,
                paymentPurposeId: requestObject.purposeId || '',
                paymentReference: requestObject.reference ?? undefined,
                notifyParties: requestObject.notifyParties || [],
                sourceAmount: requestObject.convertAmount,
                sourceCurrencyCode: requestObject.convertCurrency,
                transactionDestination: recipientChoosen?.id,
                transactionSource: sourceWallet?.id,
                transactionDocumentsUrl: [
                    requestObject.transactionDocumentsUrl
                ],
                transactionType:
                    conversionType === 2
                        ? 1
                        : requestObject.convertCurrency ===
                          requestObject.receiveCurrency
                        ? 2
                        : 3
            },
            {
                onSettled: (_, error) => {
                    if (error) {
                        setError(
                            getErrorMessage(error) || 'Something went wrong'
                        );
                        setIsMakingPayment(false);
                        return;
                    }

                    navigate('/payments/overview', {
                        state: {
                            newTransactionAlert: true
                        }
                    });

                    setIsMakingPayment(false);

                    toast.info('Transaction successful');
                }
            }
        );
    };

    const youGetString = (who: string, convType: number) => {
        if (convType === 1) {
            return `${who} gets exactly`;
        } else {
            return 'You get exactly';
        }
    };

    return (
        <div>
            <div className='lg:mt-[2.1rem] lg:border lg:border-appcolorgreyfaint px-[16px] lg:px-[3rem] pt-[40px] lg:py-[2.5rem] rounded-[3px]'>
                {error !== null && (
                    <Alert
                        variant='outlined'
                        severity='error'
                        sx={{ marginBottom: '16px' }}
                    >
                        {error}
                    </Alert>
                )}

                <p className='hidden lg:block text-[19px] leading-6 pt-6'>
                    Review details of your transfer
                </p>

                <div className='lg:pt-[2.1rem] border-b border-appcolorgrey pb-[0.8rem]'>
                    <div className='flex justify-between border border-0 border-b border-appcolorfaintnine lg:border-none mb-4'>
                        <p className='font-medium text-base text-ebony'>
                            Transfer details
                        </p>
                        <button
                            className='cursor-pointer text-appcolorprimary text-sm  font-semibold  w-fit'
                            onClick={() => {
                                updateStep(0);
                            }}
                        >
                            Edit
                        </button>
                    </div>

                    <div className='flex justify-between border border-0 border-b border-appcolorfaintnine lg:border-none mb-4'>
                        <p className='text-sm leading-6'>You send</p>
                        <p className='text-ebony text-sm leading-6 font-bold'>
                            {commaNumber(requestObject.convertAmount)}
                            {requestObject.convertCurrency}
                        </p>
                    </div>

                    <div className='flex justify-between border border-0 border-b border-appcolorfaintnine lg:border-none mb-4'>
                        <p className='text-sm leading-6'>
                            Total Fees (included)
                        </p>
                        <p className='text-ebony text-sm leading-6'>
                            0.00 {requestObject.convertCurrency}
                        </p>
                    </div>

                    <div className='flex justify-between border border-0 border-b border-appcolorfaintnine lg:border-none mb-4'>
                        <p className='text-sm leading-6'>
                            Guaranteed rate (30secs)
                        </p>
                        <p className=' text-ebony text-sm leading-6'>
                            1 &nbsp;
                            {requestObject.convertCurrency}
                            &nbsp; = {Number(rate).toFixed(7)}
                            &nbsp;
                            {requestObject.receiveCurrency}
                        </p>
                    </div>

                    <div className='flex justify-between border border-0 border-b border-appcolorfaintnine lg:border-none lg:mb-4'>
                        <p className='text-sm leading-6'>
                            {conversionType === 1
                                ? recipientChoosen?.beneficiaryCompanyName
                                : 'You'}
                            &nbsp;
                            {conversionType === 1 ? `gets` : 'get'}
                            &nbsp; exactly
                        </p>
                        <p className='text-ebony text-sm leading-6 font-bold'>
                            {commaNumber(requestObject.receiveAmount)}{' '}
                            {requestObject.receiveCurrency}
                        </p>
                    </div>
                </div>

                <div className='lg:hidden flex justify-center my-[16px] h-[24px] items-center'>
                    <p className='w-[56px] border border-0 border-b border-silver'></p>
                </div>

                {conversionType === 1 && (
                    <>
                        <div className='lg:pt-[1.1rem] pb-[0.8rem]'>
                            <div className='flex justify-between border border-0 border-b border-appcolorfaintnine lg:border-none mb-4'>
                                <p className='font-medium text-base text-ebony'>
                                    Recipients details
                                </p>
                                <button
                                    className='cursor-pointer text-appcolorprimary text-sm  font-semibold  w-fit'
                                    onClick={() => {
                                        updateStep(1);
                                    }}
                                >
                                    Change
                                </button>
                            </div>

                            <div className='flex justify-between border border-0 border-b border-appcolorfaintnine lg:border-none mb-4'>
                                <p className='text-sm leading-6'>Name</p>
                                <p className='text-ebony text-sm leading-6'>
                                    {recipientChoosen?.beneficiaryCompanyName}
                                </p>
                            </div>

                            <div className='flex justify-between border border-0 border-b border-appcolorfaintnine lg:border-none mb-4'>
                                <p className='text-sm leading-6'>Bank Name</p>
                                <p className='text-ebony text-sm leading-6'>
                                    {recipientChoosen?.bankName}
                                </p>
                            </div>

                            <div className='flex justify-between border border-0 border-b border-appcolorfaintnine lg:border-none lg:mb-4'>
                                <p className='text-sm leading-6'>Acc Number</p>
                                <p className='text-ebony text-sm leading-6'>
                                    {recipientChoosen?.accountNumber}
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <div className='lg:hidden flex justify-center my-[16px] h-[24px] items-center'>
                <p className='w-[56px] border border-0 border-b border-silver'></p>
            </div>

            {conversionType === 1 && (
                <>
                    <div className='px-[16px] lg:mt-[1.1rem]  rounded-[3px] lg:py-[1.3rem] lg:px-[3.1rem]  lg:border lg:border-appcolorgreyfaint'>
                        <div className='flex justify-between border border-0 border-b border-appcolorfaintnine lg:border-none mb-2'>
                            <p className='text-sm leading-6'>Reference</p>
                            <button
                                className='cursor-pointer text-appcolorprimary text-sm  font-semibold  w-fit'
                                onClick={() => {
                                    updateStep(1);
                                }}
                            >
                                Edit
                            </button>
                        </div>

                        <div className='flex justify-between border border-0 border-b border-appcolorfaintnine lg:hidden mb-2'>
                            <p className='text-sm leading-6'>
                                {!requestObject?.reference
                                    ? 'No Reference Provided'
                                    : requestObject?.reference}
                            </p>
                        </div>

                        <form className='hidden lg:block'>
                            <TextField
                                margin='normal'
                                fullWidth
                                id='confimarion-reference'
                                label='Reference'
                                name='reference'
                                disabled
                                value={
                                    requestObject?.reference === undefined
                                        ? 'No Reference Provided'
                                        : requestObject?.reference
                                }
                            />
                        </form>
                    </div>
                </>
            )}

            <div className='mt-8 px-[16px] lg:px-0 mb-20 lg:mb-0'>
                <SuitUIButton
                    text='Confirm and send'
                    onClick={onHandleSubmit}
                    fullWidth
                    isLoading={isMakingPayment}
                    type='submit'
                    style='px-[0.92rem]'
                />
            </div>

            {rateChange && expectedNewRate !== null && (
                <CustomModal
                    maxWidth='xs'
                    open={rateChange}
                    onClose={() => setRateChange(false)}
                    title='Rate Change'
                    subTitle={
                        <span>
                            Rate changed from &nbsp;
                            <span className='text-appcolorprimary'>
                                1 &nbsp;
                                {requestObject.convertCurrency}
                                &nbsp; = {Number(rate).toFixed(7)}
                                &nbsp;
                                {requestObject.receiveCurrency}
                            </span>{' '}
                            &nbsp; to &nbsp;
                            <span className='text-appcolorprimary'>
                                1 &nbsp;
                                {requestObject.convertCurrency}
                                &nbsp; = {Number(expectedNewRate).toFixed(7)}
                                &nbsp;
                                {requestObject.receiveCurrency}
                            </span>
                            , would you like to continue with the new rate?
                        </span>
                    }
                    styles={{
                        overflowX: 'hidden'
                    }}
                >
                    <div className=''>
                        <Grid
                            container
                            spacing={4}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid item xs={6} md={6} lg={6}>
                                <Custombutton
                                    type='button'
                                    fullWidth
                                    variant='contained'
                                    buttonText='Cancel'
                                    styles={{
                                        borderColor: theme.palette.primary.main,
                                        borderWidth: '1px',
                                        borderStyle: 'solid',
                                        padding:
                                            '0.65rem 0.92rem 0.6rem 0.93rem',
                                        backgroundColor: 'transparent',
                                        color: theme.palette.primary.main,
                                        '&:hover': {
                                            backgroundColor: 'transparent',
                                            color: theme.palette.primary.main
                                        }
                                    }}
                                    onClickAction={() => {
                                        setRateChange(false);
                                        setexpectedNewRate(null);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={6} md={6} lg={6}>
                                <Custombutton
                                    isLoadingButton
                                    type='button'
                                    fullWidth
                                    variant='contained'
                                    buttonText='Proceed'
                                    styles={{
                                        padding:
                                            '0.65rem 0.92rem 0.6rem 0.93rem'
                                    }}
                                    onClickAction={onHandleSubmit}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </CustomModal>
            )}
        </div>
    );
};

export default Confirmation;
