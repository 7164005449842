import { Accordion, Box, Typography } from '@mui/material';
export const CargoInsuranceHome = () => {
    return (
        <>
            <Box
                className='text-[#425466]'
                sx={{ mb: '26px', fontWeight: 400, fontSize: '18px' }}
            >
                Insure the entirety of international cargo trips online at 100%.
                We offer three types of Cargo insurance:
            </Box>
            <Box sx={{ mb: '37px' }}>
                <Accordion sx={{ mb: 1, boxShadow: 'none', p: 2 }}>
                    <Typography>Marine Insurance (Export)</Typography>
                </Accordion>
                <Accordion sx={{ mb: 1, boxShadow: 'none', p: 2 }}>
                    <Typography>Marine Insurance (Import)</Typography>
                </Accordion>
                <Accordion sx={{ mb: 1, boxShadow: 'none', p: 2 }}>
                    <Typography>
                        Marine Insurance (Import Comprehensive)
                    </Typography>
                </Accordion>
            </Box>
        </>
    );
};
