import React, {
    Dispatch,
    MutableRefObject,
    SetStateAction,
    useEffect,
    useState
} from 'react';
import CustomModal from '../../CustomModal';
import {
    PreviewDocumentContainer,
    TabContainerNew
} from '../../../styles/TradeDocStyles';
import { getSingleDocuments } from '../../../api/documents/documents';
import { openNotification } from '../../../utils/helper';
import { REACT_APP_BASE_URL } from '../../../config';

import QuotationTop from './previewComponents/QuotationTop';
import ProformaView from './previewComponents/ProformaView';
import CommercialView from './previewComponents/CommercialView';
import PackingListView from './previewComponents/PackingListView';
import CertificateOfOriginView from './previewComponents/CertificateOfOriginView';
import DraftBillOfLadenView from './previewComponents/DraftBillOfLadenView';
import SalesView from './previewComponents/SalesView';
import { CircularProgress, Stack } from '@mui/material';
import DocumentViewer from '../../DocumentViewer/DocumentViewer';

interface IProps {
    currentData: {
        documentType: string;
        id: number;
    };
    visibleDocument: boolean;
    closeCurrentDocModal: () => void;
    myDiv?: MutableRefObject<HTMLDivElement | null>;
    setDownloadLoading: Dispatch<SetStateAction<boolean>>;
    downloadLoading: boolean;
    handlePrint: () => void;
}

const CreatedDocumentPreview = ({
    visibleDocument,
    closeCurrentDocModal,
    currentData,
    myDiv,
    setDownloadLoading,
    downloadLoading,
    handlePrint
}: IProps) => {
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState<any>(null);
    const [previewId, setPreviewId] = useState<any>(null);

    const fetchDocuments = async () => {
        setLoadingData(true);
        try {
            const res = await getSingleDocuments({
                corporateDocumentId: currentData?.id
            });

            const { data } = res.data;
            setData(data?.documentData);
            setPreviewId(data?.id);
            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            if (error?.response) {
                openNotification('error', `Documents, Something went wrong`);
            } else {
                openNotification('error', `Documents, Something went wrong`);
            }
        }
    };

    useEffect(() => {
        fetchDocuments();
    }, [currentData]);

    return (
        <CustomModal
            maxWidth='xl'
            open={visibleDocument}
            onClose={closeCurrentDocModal}
            styles={{
                overflowX: 'hidden'
            }}
        >
            <Stack width='100%' direction='row' mb='1rem'>
                <TabContainerNew>
                    <div className='tab-buttons' onClick={handlePrint}>
                        {downloadLoading ? (
                            <CircularProgress
                                size={15}
                                sx={{
                                    marginRight: '0.3rem'
                                }}
                            />
                        ) : (
                            <img
                                src='/img/vectors/plaindownload.svg'
                                alt='plaindownload'
                                className='h-[1.1rem] mr-2'
                            />
                        )}
                        <p style={{ paddingRight: '5px' }}>Download</p>
                    </div>
                </TabContainerNew>
            </Stack>
            <div ref={myDiv} className='px-4'>
                <PreviewDocumentContainer>
                    <div className='text-[14px]'>
                        <div className='flex items-center justify-between mb-4 w-full'>
                            {data?.logoUrl ? (
                                <div className='w-[350px]'>
                                    <div className='preview-container'>
                                        <DocumentViewer
                                            fileUrl={`${REACT_APP_BASE_URL}/${data?.logoUrl?.split(
                                                '/v1/'
                                            )[1]}`}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div>
                                        <img
                                            src='/img/vectors/powered.svg'
                                            alt=''
                                            className='h-[2.5rem]'
                                        />
                                    </div>
                                </>
                            )}

                            <div>
                                <h1 className='text-appcolorprimary text-[26px] leading-[27px] font-bold capitalize'>
                                    {currentData?.documentType
                                        ?.split('_')
                                        .filter((x) => x.length > 0)
                                        .map(
                                            (x) =>
                                                x.charAt(0).toUpperCase() +
                                                x.slice(1)
                                        )
                                        .join(' ')
                                        .toLowerCase()}
                                </h1>
                                <p className='text-right text-sm'>
                                    Doc#: {data?.corporateDocumentId}
                                </p>
                            </div>
                        </div>
                    </div>
                    {(currentData?.documentType === 'QUOTATION' ||
                        currentData?.documentType === 'PURCHASE_ORDER' ||
                        currentData?.documentType ===
                            'DRAFT_GROSS_MASS_DECLARATION') &&
                        data && (
                            <QuotationTop
                                data={data}
                                docType={currentData?.documentType}
                            />
                        )}
                    {currentData?.documentType === 'PROFORMA_INVOICE' &&
                        data && <ProformaView data={data} />}
                    {currentData?.documentType === 'COMMERCIAL_INVOICE' &&
                        data && <CommercialView data={data} />}
                    {currentData?.documentType === 'PACKING_LIST' && data && (
                        <PackingListView data={data} />
                    )}
                    {currentData?.documentType ===
                        'DRAFT_CERTIFICATE_OF_ORIGIN' &&
                        data && <CertificateOfOriginView data={data} />}

                    {currentData?.documentType === 'DRAFT_BILL_OF_LADING' &&
                        data && <DraftBillOfLadenView data={data} />}

                    {currentData?.documentType === 'SALES_CONTRACT' && data && (
                        <SalesView
                            data={data}
                            docType={currentData?.documentType}
                        />
                    )}
                </PreviewDocumentContainer>
            </div>
        </CustomModal>
    );
};

export default CreatedDocumentPreview;
