import React from 'react';
import { SalesSummaryContainer } from '../../../../styles/SalesStyles';
import { Avatar, Stack } from '@mui/material';
import { formatCurrency, getInvoiceStatus } from '../../../../utils/helper';
import { getInvoiceContractStatusIcon } from '../../../icons/InvoiceStatusIcons';
import dayjs from 'dayjs';
import { SalesDocumentData } from '../../../../pages/dashboard/payments/interfaces/SalesDocuments';

const InvoiceDetailsSummary = ({ details }: { details: SalesDocumentData }) => {
    function stringAvatar(name: string) {
        return {
            sx: {
                bgcolor: '#E5F3F7',
                color: '#0088B29a',
                width: '54px',
                height: '54px',
                fontSize: '18px',
                fontWeight: '600'
            },
            children: name
        };
    }

    return (
        <>
            <SalesSummaryContainer className='hidden lg:flex'>
                <Stack direction='row' gap='12px' className='first-details'>
                    <Avatar {...stringAvatar('#')} />
                    <div>
                        <p>Invoice Number</p>
                        <h4>{details?.invoiceNumber || ''}</h4>
                    </div>
                </Stack>
                <div className='other-details'>
                    <p>Customer</p>
                    <h4>{details?.buyer?.name || ''}</h4>
                </div>
                <div className='other-details'>
                    <p>Sales Contract</p>
                    <h4>{details?.salesContractNumber || ''}</h4>
                </div>
                <div className='other-details'>
                    <p>Due Date</p>
                    <h4>{dayjs(details?.dueDate).format('ll')}</h4>
                </div>
                <div className='other-details'>
                    <p>Total invoice Amount</p>
                    <h4>
                        {formatCurrency(
                            details?.totalAmount || 0,
                            details?.currencyCode
                        )}
                    </h4>
                </div>

                <div className='other-details'>
                    <p>Balance Due</p>
                    <h4>
                        {' '}
                        {formatCurrency(
                            details?.totalAmount - details?.totalPaid ?? 0,
                            details?.currencyCode
                        )}
                    </h4>
                </div>
                <div className='other-details'>
                    <p>Payment Terms</p>
                    <h4>{details?.termsOfPayment || ''}</h4>
                </div>
            </SalesSummaryContainer>
            <div className='flex lg:hidden items-center justify-center p-[2rem] flex-col gap-1 text-paleSky'>
                <div>
                    {getInvoiceContractStatusIcon(
                        getInvoiceStatus(details?.status).toUpperCase()
                    )}
                </div>

                <p className='text-[14px]'>
                    Invoice No: <b>{details?.invoiceNumber ?? ''}</b>
                </p>
                <h4 className='text-ebony text-[18px] font-bold'>
                    {details?.buyer?.name || ''}
                </h4>
                <p className='text-[14px]'>
                    Due date:
                    <b>{dayjs(details?.dueDate).format('ll')}</b>
                </p>
            </div>
        </>
    );
};

export default InvoiceDetailsSummary;
