import { object, string, number, array, InferType } from 'yup';

export const GeneralDetailsSchema = object({
    salesContractNumber: string().required('Contract number is required'),
    general: object({
        contractNumber: string().required('Booking Agent is required')
    }),
    parties: object({
        forwarder: number(),
        shipper: number()
    }).test(
        'xor',
        'One of forwarder or shipper is required, but not both',
        (data) => {
            const { forwarder, shipper } = data;
            return !((!forwarder && !shipper) || (forwarder && shipper));
        }
    ),
})

export const ReferencesSchema = object({
    transport: object({
        moveType: string().required('Transport move type is required'),
        placeOfReceiptId: string().required(
            'Place of Carrier Receipt is required'
        ),
        placeOfDeliveryId: string().required(
            'Place of Carrier Delivery is required'
        ),
        earliestDepatureDate: string().required(
            'Transport earliest departure date is required'
        ),
        latestDeliveryDate: string().required(
            'Transport latest delivery date is required'
        ),})
})

export const TransportSchema = object({
    transport: object({
        moveType: string().required('Transport move type is required'),
        placeOfReceiptId: string().required(
            'Place of Carrier Receipt is required'
        ),
        placeOfDeliveryId: string().required(
            'Place of Carrier Delivery is required'
        ),
        earliestDepatureDate: string().required(
            'Transport earliest departure date is required'
        ),
        latestDeliveryDate: string().required(
            'Transport latest delivery date is required'
        ),
        main: array(
            object({
                etd: string().required(
                    'Transport Estimated Time of Departure is required'
                ),
                eta: string().required(
                    'Transport Estimated Time of Arrival is required'
                ),
                portOfLoadId: string().required(
                    'Main Carriage Start is required'
                ),
                portOfDischargeId: string().required(
                    'Main Carriage End is required'
                )
            })
        ),
        onCarriage: array(
            object({
                etd: string().required(
                    'Transport Estimated Time of Departure is required'
                ),
                eta: string().required(
                    'Transport Estimated Time of Arrival is required'
                ),
                portOfLoadId: string().required(
                    'OnCarriage Start is required'
                ),
                portOfDischargeId: string().required(
                    'OnCarriage End is required'
                )
            })
        ).optional(),
        preCarriage: array(
            object({
                etd: string().required(
                    'Transport Estimated Time of Departure is required'
                ),
                eta: string().required(
                    'Transport Estimated Time of Arrival is required'
                ),
                portOfLoadId: string().required(
                    'Pre Carriage Start is required'
                ),
                portOfDischargeId: string().required(
                    'Pre Carriage End is required'
                )
            })
        ).optional()
    }),
    container: array(
        object({
            containerTypeId: number().required('Container Type is required')
        })
    ),
})

export const BookingFormValidationSchema = object({
    cargo: array(
        object({
            cargoWeight: string().required('Cargo weight is required'),
            volumeUnit: string().required('Cargo volume unit is required'),
            weightUnit: string().required('Cargo weight unit is required'),
            description: string().required('Cargo description is required'),
            hsCode: string().required('Cargo HS code is required'),
            commodityId: number().required('Cargo commodity is required')
        })
    )
}).concat(GeneralDetailsSchema).concat(TransportSchema);

export type BookingFormError = InferType<typeof BookingFormValidationSchema>;
