import { Box } from '@mui/material';
import styled from 'styled-components';
import { FrontEdgeSmallLogo } from './icons/FrontedgeSmallLogo';

const SuiteUIPageLoader = () => {
    return (
        <SuiteLoaderContainer>
            <Box>
                <div className='test'>
                    <div className='spinner-circle'>
                        <div className='spinner'></div>
                        <div className='icon '>
                            <FrontEdgeSmallLogo />
                        </div>
                    </div>
                </div>
            </Box>
        </SuiteLoaderContainer>
    );
};

export default SuiteUIPageLoader;

const SuiteLoaderContainer = styled.section`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .test {
        display: inline-block;
        padding: 20px;
    }
    .spinner-circle {
        width: 100px;
        height: 100px;
        position: relative;
        margin: 20px;
    }
    .spinner {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        border: 5px solid rgba(0, 0, 0, 0.127);
        border-right: 5px solid #0088b2;
        animation: rotate--spinner 1.6s linear infinite;
        box-sizing: border-box;
    }
    .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    @keyframes rotate--spinner {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;
