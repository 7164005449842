export const ArrowLeftIcon = () => (
    <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M12.8334 7.08504H1.16675M1.16675 7.08504L7.00008 12.9184M1.16675 7.08504L7.00008 1.25171'
            stroke='#344054'
            strokeWidth='1.66667'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
