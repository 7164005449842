import { Alert, AlertColor, useTheme } from '@mui/material';

interface AlertProps {
    severity: AlertColor;
    message: string;
    actionComponent?: JSX.Element;
}

export const SuiteUIAlert = (props: AlertProps) => {
    const { severity, message, actionComponent } = props;
    const theme = useTheme();

    return (
        <Alert
            severity={severity}
            sx={{
                background: 'rgba(0, 136, 178, 0.06)',
                borderRadius: '3px',
                border: '1px solid #0088B2',
                color: theme.palette.primary.main,
                '.MuiAlert-message': {
                    width: '100%'
                },
                '.MuiAlert-icon': {
                    paddingTop: '12px'
                }
            }}
        >
            <div className='flex items-center justify-between w-full'>
                <span>{message}</span>

                {actionComponent}
            </div>
        </Alert>
    );
};
