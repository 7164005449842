import { Autocomplete, Chip, FormControlLabel } from '@mui/material';
import CustomCheckBox from '../../../../components/CustomCheckBox';
import CustomTextField from '../../../../components/CustomTextInput';
import { BookingForm } from './interfaces/BookingForm';

interface CommentFormProps {
    formData: BookingForm;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
}

export const CommentForm = (props: CommentFormProps) => {
    const { formData, setFormData } = props;

    return (
        <>
            <div className='mt-4 lg:mt-8 flex flex-col gap-4 pb-4 lg:pb-0 lg:grid lg:grid-cols-2'>
                <div className='w-full'>
                    <CustomTextField
                        rows={5}
                        multiline
                        height={150}
                        label='Customer Comments'
                        value={formData.general.comments}
                        onChange={(e) => {
                            setFormData((prev) => ({
                                ...prev,
                                general: {
                                    ...prev.general,
                                    comments: e.target.value
                                }
                            }));
                        }}
                    />
                </div>

                <div className='w-full'>
                    <div>
                        <Autocomplete
                            freeSolo
                            multiple
                            disableClearable
                            options={[]}
                            value={formData.general.notificationParties?.split(
                                ','
                            )}
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip
                                        variant='outlined'
                                        label={option}
                                        {...getTagProps({
                                            index
                                        })}
                                    />
                                ))
                            }
                            renderInput={(params) => (
                                <CustomTextField
                                    params={params}
                                    label='Partner Email Notifications'
                                    required={false}
                                    InputProps={{
                                        ...params.InputProps
                                    }}
                                />
                            )}
                            onChange={(_, value) => {
                                if (value) {
                                    setFormData((prev) => ({
                                        ...prev,
                                        general: {
                                            ...prev.general,
                                            notificationParties: value.join(',')
                                        }
                                    }));
                                }
                            }}
                        />
                        <div className='text-appcolorprimary mt-2'>
                            This input supports multiple email entries, press
                            enter when done to start a new entry.
                        </div>
                    </div>

                    <div className='mt-6'>
                        <FormControlLabel
                            value={formData.general.notify}
                            control={
                                <CustomCheckBox
                                    onChange={(event) => {
                                        setFormData((prev) => ({
                                            ...prev,
                                            general: {
                                                ...prev.general,
                                                notify: event.target.checked
                                            }
                                        }));
                                    }}
                                />
                            }
                            label='Notify me regarding the status and update of this booking.'
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
