import React from 'react';
import { PaymentReceiptContainer } from '../../../../styles/PaymentStyles';
import dayjs from 'dayjs';
import { Stack } from '@mui/material';
import { formatCurrency, getTransactionStatus } from '../../../../utils/helper';
import { TransactionExtra, Transactions } from '../interfaces/Transactions';
import { FrontEdgeLogo } from '../../../../components/icons/FrontEdgeLogo';

const PaymentReceipt = ({ data }: { data: Transactions }) => {
    const findExtraByKey = (key: string): TransactionExtra | undefined => {
        return data?.extra?.find((extra) => extra?.key === key);
    };

    return (
        <PaymentReceiptContainer>
            <div>
                <FrontEdgeLogo />
            </div>
            <div className=' bg-appcolorprimary bg-opacity-30 p-3 mt-2 mb-[1rem]'>
                <p className=' text-appcolorprimary font-semibold text-center text-[15px]'>
                    This payment was executed by FrontEdge Holdings at{' '}
                    {dayjs(data.transactionEndDate).format('hh:mm A')} on{' '}
                    {dayjs(data.transactionEndDate).format('DD/MM/YYYY')} for{' '}
                    {data.businessName}
                </p>
            </div>

            <Stack gap='20px' className='payment-info'>
                <Stack
                    direction={'row'}
                    width='100%'
                    justifyContent='space-between'
                >
                    <p>Transaction Reference</p>
                    <p>{data.transactionReference ?? '--'}</p>
                </Stack>

                <Stack
                    direction={'row'}
                    width='100%'
                    justifyContent='space-between'
                >
                    <p>Transaction State</p>
                    <p>{getTransactionStatus(data.status)}</p>
                </Stack>

                <Stack
                    direction={'row'}
                    width='100%'
                    justifyContent='space-between'
                >
                    <p>Amount From</p>
                    <p>
                        {formatCurrency(
                            data?.amountFrom,
                            data.fromCurrencyCode
                        )}
                    </p>
                </Stack>

                <Stack
                    direction={'row'}
                    width='100%'
                    justifyContent='space-between'
                >
                    <p>Rate</p>
                    <p>
                        1 &nbsp;
                        {data?.fromCurrencyCode}
                        &nbsp; = {Number(data?.rateValue)}
                        &nbsp;
                        {data?.toCurrencyCode}
                    </p>{' '}
                </Stack>

                <Stack
                    direction={'row'}
                    width='100%'
                    justifyContent='space-between'
                >
                    <p>Amount To</p>
                    <p>{formatCurrency(data?.amountTo, data.toCurrencyCode)}</p>
                </Stack>

                <Stack
                    direction={'row'}
                    width='100%'
                    justifyContent='space-between'
                >
                    <p>Sender</p>
                    <p> {data.businessName ?? '--'}</p>
                </Stack>

                <Stack
                    direction={'row'}
                    width='100%'
                    justifyContent='space-between'
                >
                    <p>Beneficiary</p>
                    <p className=' text-right w-[60%]'>
                        {findExtraByKey('destination')?.value}
                    </p>
                </Stack>

                <Stack
                    direction={'row'}
                    width='100%'
                    justifyContent='space-between'
                >
                    <p>Beneficiary Account</p>
                    <p>
                        {findExtraByKey('accountNumber')?.value ??
                            findExtraByKey('destinationAccount')?.value ??
                            '--'}
                    </p>
                </Stack>

                {findExtraByKey('bankName')?.value && (
                    <Stack
                        direction={'row'}
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p>Beneficiary Bank</p>
                        <p>{findExtraByKey('bankName')?.value ?? '--'}</p>
                    </Stack>
                )}
                <Stack
                    direction={'row'}
                    width='100%'
                    justifyContent='space-between'
                >
                    <p>Beneficiary Address</p>
                    <p className=' w-[60%] text-right'>
                        {data.corporateBeneficiaryName ?? '--'}
                    </p>
                </Stack>

                <Stack
                    direction={'row'}
                    width='100%'
                    justifyContent='space-between'
                >
                    <p>Transaction Date</p>
                    <p>
                        {dayjs(data.transactionEndDate).format(
                            'dddd D, MMMM, YYYY'
                        )}
                    </p>
                </Stack>

                <Stack
                    direction={'row'}
                    width='100%'
                    justifyContent='space-between'
                >
                    <p>Narration</p>
                    <p className=' w-[60%] text-right'>{data?.narration}</p>
                </Stack>
            </Stack>

            <div className='foot-note'>
                <p>
                    Powered By <b>FrontEdge Holdings</b>
                </p>
                <p>38E 50th Street, New York, NY 10022</p>
            </div>
        </PaymentReceiptContainer>
    );
};

export default PaymentReceipt;
