import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import { NumberInput } from '../../../../../components/paymentinput/paymentinput';

export interface housebillofladingfieldProps {
    id: string;
    updateQuoteField: (
        index: number,
        attributeName: string,
        attributeValue?: any
    ) => void;
    qty: number;
    kindOfPackage?: string;
    qtyOfPackage?: number;
    weightOfPackageKG?: string;
    grossWeightOfPackageKG?: string;
    measurementOfPackage?: string;
    index: number;
    removeQuoteField: (index: number) => void;
    item: any;
}

const Housebillofladingfield = ({
    id,
    updateQuoteField,
    qty,
    kindOfPackage,
    qtyOfPackage,
    weightOfPackageKG,
    grossWeightOfPackageKG,
    measurementOfPackage,
    index,
    removeQuoteField,
    item
}: housebillofladingfieldProps) => {
    return (
        <div className='relative custom-quote-field'>
            <Grid container>
                <Grid item xs={12} md={12} lg={6}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    margin='normal'
                                    fullWidth
                                    id={`quote-productCode-${id}`}
                                    label='Product Code'
                                    name='productCode'
                                    onChange={(e) => {
                                        updateQuoteField(
                                            index,
                                            'productCode',
                                            e.target.value
                                        );
                                    }}
                                    value={item?.productCode}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    id={`quote-desc-${id}`}
                                    label='Description of Goods'
                                    name='desc'
                                    value={item?.desc}
                                    onChange={(e) => {
                                        updateQuoteField(
                                            index,
                                            'desc',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    value={item?.kindOfPackage}
                                    margin='normal'
                                    fullWidth
                                    id={`quote-kindOfPackage-${id}`}
                                    label='Package Kind'
                                    name='kindOfPackage'
                                    onChange={(e) => {
                                        updateQuoteField(
                                            index,
                                            'kindOfPackage',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    margin='normal'
                                    fullWidth
                                    id={`quote-qtyOfPackage-${id}`}
                                    label='Package Qty'
                                    name='qtyOfPackage'
                                    value={item?.qtyOfPackage}
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                    InputProps={{
                                        inputComponent: NumberInput,
                                        inputProps: {
                                            min: '0'
                                        }
                                    }}
                                    onChange={(e) => {
                                        updateQuoteField(
                                            index,
                                            'qtyOfPackage',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={12}>
                                <TextField
                                    margin='normal'
                                    fullWidth
                                    id={`quote-chargeable-${id}`}
                                    label='Chargeable'
                                    name='chargeable'
                                    value={item?.chargeable}
                                    onChange={(e) => {
                                        updateQuoteField(
                                            index,
                                            'chargeable',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {index !== 0 && (
                <div
                    className='absolute top-[1rem] cursor-pointer right-[-0.8rem] custom-quote-field-delete w-fit transition-all delay-75'
                    onClick={() => {
                        removeQuoteField(index);
                    }}
                >
                    <DeleteIcon />
                </div>
            )}
        </div>
    );
};

export default Housebillofladingfield;
