export const FlagTwoIcon = () => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M6.44995 22C6.03995 22 5.69995 21.66 5.69995 21.25V2.75C5.69995 2.34 6.03995 2 6.44995 2C6.85995 2 7.19995 2.34 7.19995 2.75V21.25C7.19995 21.66 6.85995 22 6.44995 22Z'
            fill='#0088B2'
        />
        <path
            opacity='0.4'
            d='M15.2001 7.16053L7.10008 3.66053C6.98008 3.60053 6.85008 3.62053 6.74008 3.69053C6.64008 3.76053 6.58008 3.87053 6.58008 4.00053V17.0005C6.58008 17.1305 6.65008 17.2505 6.76008 17.3205C6.82008 17.3605 6.89008 17.3805 6.96008 17.3805C7.02008 17.3805 7.07008 17.3705 7.13008 17.3405L15.4301 13.2405C15.4301 13.2405 15.4301 13.2405 15.4401 13.2405C17.1001 12.3805 17.9901 11.2705 17.9401 10.1005C17.8801 8.92053 16.9101 7.88053 15.2001 7.16053Z'
            fill='#0088B2'
        />
    </svg>
);
