import Grid from '@mui/material/Grid';
import { NumericFormat } from 'react-number-format';
import CustomTextField from '../CustomTextInput';
import NewCustomSelect from '../newcustomselect/newcustomselect';

export interface paymentinputProps {
    currencies: any;
    label: string;
    loadingCurrencies: boolean;
    name: string;
    error: boolean;
    helperText?: string;
    currency: string;
    setCurrency: (value: string) => void;
    amount: number;
    setAmount: (value: number) => void;
}

export const NumberInput = (props: any) => {
    const { inputRef, onChange, value, ...other } = props;

    return (
        <NumericFormat
            {...other}
            value={value || ''}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator=','
            thousandsGroupStyle='thousand'
            valueIsNumericString
            decimalScale={2}
            fixedDecimalScale={false}
            allowNegative={false}
        />
    );
};

const Paymentinput = ({
    currencies,
    label,
    loadingCurrencies,
    name,
    error,
    helperText,
    currency,
    setCurrency,
    amount,
    setAmount
}: paymentinputProps) => {
    return (
        <div>
            <Grid
                container
                spacing={3}
                sx={{
                    marginTop: '0px'
                }}
                justifyContent='baseline'
                alignItems='center'
            >
                <Grid item xs={8} md={8} lg={8}>
                    <CustomTextField
                        id={`${name}-payment-input`}
                        name={name}
                        required
                        error={error}
                        helperText={helperText}
                        label={label}
                        placeholder='0.00'
                        value={amount}
                        onChange={(e: any) => setAmount(e.target.value)}
                        InputProps={{
                            inputComponent: NumberInput,
                            inputProps: {
                                min: '0'
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={4} md={4} lg={4}>
                    <NewCustomSelect
                        label='Currency'
                        selectValue={currency}
                        loading={loadingCurrencies}
                        showSearch={true}
                        name={'select'}
                        updateField={(_, value) => setCurrency(value)}
                        selectOptions={currencies}
                        selectOptionLabel='nameAndFlag'
                        alternativeValue='currencyCode'
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default Paymentinput;
