import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useMediaQuery, useTheme } from '@mui/material';
import { FormikProps } from 'formik';
import { BookingEntryDivider } from './BookingEntryDivider';
import { BookingForm } from './interfaces/BookingForm';
import { Container } from './interfaces/Container';
import { useGetContainerTypes } from './hooks/useGetContainerTypes';
import CustomModal from '../../../../components/CustomModal';
import { FumigationModal } from './modals/FumigationModal';
import { HaulageModal } from './modals/HaulageModal';
import { ReeferModal } from './modals/ReeferModal';
import { DimensionsModal } from './modals/DimensionsModal';
import { ContainerType } from './interfaces/ContainerType';
import CustomTextField from '../../../../components/CustomTextInput';
import CustomCheckBox from '../../../../components/CustomCheckBox';
import { SuitUIButton } from '../../../../components/SuitUIButton';

interface ContainerFormProps {
    content: Container;
    index: number;
    isRemoveShown?: boolean;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
    formik: FormikProps<BookingForm>;
}

interface ContainerOptions {
    haulage: boolean;
    fumigation: boolean;
    reefer: boolean;
    dimensions: boolean;
}

const containerOptions = [
    {
        key: 'haulage',
        label: '+ Add Haulage Details',
        categories: ['hybrid', 'not controlled', 'reefer', '2', '3', '0'],
        oog: 1
    },
    {
        key: 'reefer',
        label: '+ Add Reefer Settings',
        categories: ['hybrid', 'reefer']
    },
    {
        key: 'fumigation',
        label: '+ Add Fumigation Details',
        categories: ['hybrid', 'not controlled', 'reefer', '2', '3', '0'],
        oog: 1
    },
    {
        key: 'dimensions',
        label: '+ Add Out of Guage',
        categories: ['not controlled', '3'],
        oog: 1
    }
];

export const ContainerForm = (props: ContainerFormProps) => {
    const { content, setFormData, index, isRemoveShown, formik } = props;
    const { containerTypes } = useGetContainerTypes();
    const [openContainerOption, setContainerOption] = useState(
        {} as ContainerOptions
    );
    const [container, setContainer] = useState<ContainerType>();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const onHandleRemoveItem = () => {
        setFormData((prev) => {
            prev.container.splice(index, 1);

            return {
                ...prev,
                container: prev.container
            };
        });
    };

    return (
        <>
            <BookingEntryDivider
                index={index}
                isRemoveShown={isRemoveShown}
                onHandleRemoveItem={onHandleRemoveItem}
            />
            <div className='pb-[12px] border-b border-dashed border-gray300 lg:border-none flex flex-col gap-4 lg:pb-0 lg:grid lg:grid-cols-3'>
                <div className='w-full'>
                    <Autocomplete
                        options={containerTypes}
                        getOptionLabel={(option) => option.shortDescription}
                        renderInput={(params) => (
                            <CustomTextField
                                params={params}
                                label='Container Type'
                                required
                                error={!!formik.errors.container}
                                helperText={
                                    formik.errors?.container
                                        ? 'Container type is required'
                                        : ''
                                }
                            />
                        )}
                        onChange={(_, value) => {
                            if (value) {
                                setContainer(value);
                                const entry = {
                                    ...content,
                                    containerTypeId: value.id
                                };
                                setFormData((prev) => {
                                    prev.container.splice(index, 1, entry);

                                    const update = {
                                        ...prev,
                                        container: prev.container
                                    };

                                    formik.setValues(update);

                                    return update;
                                });

                                return;
                            }

                            setContainer(undefined);
                        }}
                    />
                </div>

                <div className='w-full'>
                    <CustomTextField
                        label='Comment'
                        rows={5}
                        multiline={isMobile}
                        height={isMobile ? 150 : 48}
                        onChange={(e) => {
                            const entry = {
                                ...content,
                                comments: e.target.value
                            };
                            setFormData((prev) => {
                                prev.container.splice(index, 1, entry);

                                return {
                                    ...prev,
                                    container: prev.container
                                };
                            });
                        }}
                    />
                </div>

                <div className='mt-2 lg:mt-0 w-full ml-6'>
                    <FormControlLabel
                        sx={{
                            '.MuiButtonBase-root': {
                                padding: 0,
                                paddingRight: '0.5rem'
                            }
                        }}
                        value={content.shipperOwned}
                        control={
                            <CustomCheckBox
                                onChange={(_, value) => {
                                    const entry = {
                                        ...content,
                                        shipperOwned: value
                                    };
                                    setFormData((prev) => {
                                        prev.container.splice(index, 1, entry);

                                        return {
                                            ...prev,
                                            container: prev.container
                                        };
                                    });
                                }}
                            />
                        }
                        label='Shipper Owned'
                    />
                </div>
            </div>
            <div className='flex flex-col bg-red items-left lg:flex-row gap-4 lg:gap-6 lg:mt-4 mt-6'>
                {containerOptions
                    .filter(({ categories, oog }) => {
                        const { typeCategory, oogFlag } = (container ||
                            {}) as ContainerType;
                        const isVisible = categories.includes(
                            typeCategory ? typeCategory.toLowerCase() : '0'
                        );
                        return isVisible && (oogFlag ? oogFlag === oog : true);
                    })
                    .map(({ key, label }) => (
                        <div key={key}>
                            <SuitUIButton
                                text={label}
                                variant='secondary'
                                onClick={() =>
                                    setContainerOption((prev) => ({
                                        ...prev,
                                        [key]: true
                                    }))
                                }
                            />
                        </div>
                    ))}
            </div>

            <CustomModal
                title={`Container ${index + 1} Haulage`}
                open={openContainerOption.haulage}
                onClose={() =>
                    setContainerOption((prev) => ({ ...prev, haulage: false }))
                }
                maxWidth={'lg'}
            >
                <HaulageModal
                    payload={content.haulage}
                    setPaylod={() => {}}
                    onHandleClose={() =>
                        setContainerOption((prev) => ({
                            ...prev,
                            haulage: false
                        }))
                    }
                />
            </CustomModal>

            <CustomModal
                title={`Container ${index + 1} Reefer`}
                open={openContainerOption.reefer}
                onClose={() =>
                    setContainerOption((prev) => ({ ...prev, reefer: false }))
                }
                maxWidth={'xs'}
                styles={{
                    '& .MuiDialog-container': {
                        alignItems: 'baseline'
                    }
                }}
            >
                <ReeferModal
                    payload={content.reefer}
                    setPaylod={() => {}}
                    onHandleClose={() =>
                        setContainerOption((prev) => ({
                            ...prev,
                            reefer: false
                        }))
                    }
                />
            </CustomModal>

            <CustomModal
                title={`Container ${index + 1} Dimensions`}
                open={openContainerOption.dimensions}
                onClose={() =>
                    setContainerOption((prev) => ({
                        ...prev,
                        dimensions: false
                    }))
                }
                maxWidth={'xs'}
                styles={{
                    '& .MuiDialog-container': {
                        alignItems: 'baseline'
                    }
                }}
            >
                <DimensionsModal
                    payload={content.dimensions}
                    setPaylod={() => {}}
                    onHandleClose={() =>
                        setContainerOption((prev) => ({
                            ...prev,
                            dimensions: false
                        }))
                    }
                />
            </CustomModal>

            <CustomModal
                title={`Container ${index + 1} Fumigation`}
                open={openContainerOption.fumigation}
                onClose={() =>
                    setContainerOption((prev) => ({
                        ...prev,
                        fumigation: false
                    }))
                }
                maxWidth={'xs'}
            >
                <FumigationModal
                    payload={content.fumigation}
                    setPaylod={() => {}}
                    onHandleClose={() =>
                        setContainerOption((prev) => ({
                            ...prev,
                            fumigation: false
                        }))
                    }
                />
            </CustomModal>
        </>
    );
};
