import { useRef } from 'react';
import { formatCurrency } from '../../../utils/helper';
import DedicatedAccountDetails from './DedicatedAccountDetails';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { useReactToPrint } from 'react-to-print';
import { Wallet } from './interfaces/Wallet';
import { Cross } from '../../../components/icons/Cross';
import { SuitUIButton } from '../../../components/SuitUIButton';

export interface ViewAccountsProps {
    wallets: Wallet[];
    closeDrawer: () => void;
    showDrawer: boolean;
}

const ViewAccounts = ({
    wallets,
    closeDrawer,
    showDrawer
}: ViewAccountsProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const isDedicatedAccount: boolean = wallets.some(
        (wallet) => wallet.accountMode !== undefined
    );

    const myDiv = useRef<HTMLDivElement | null>(null);

    const handlePrint = useReactToPrint({
        content: () => myDiv.current
    });

    return (
        <div className=''>
            {showDrawer ? (
                <div
                    className='slide-in-right active bg-appcolorwhite rounded-[50%] top-[1.2rem] right-[34rem] fixed p-4 z-[4000] cursor-pointer'
                    onClick={() => {
                        closeDrawer();
                    }}
                >
                    <Cross />
                </div>
            ) : null}

            <Drawer
                anchor={isMobile ? 'bottom' : 'right'}
                open={showDrawer}
                onClose={() => {
                    closeDrawer();
                }}
                sx={{
                    '.MuiDrawer-paperAnchorRight': {
                        width: {
                            xs: '95%',
                            lg: '33rem'
                        }
                    }
                }}
            >
                <div className='px-[16px] lg:px-[3.2rem] py-[2.1rem]'>
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                    >
                        <h1 className='text-base text-ebony lg:text-[24px] lg:leading-[23px] lg:text-appcolorblacktwo lg:font-semibold'>
                            All Accounts
                        </h1>
                        {isDedicatedAccount && (
                            <SuitUIButton
                                onClick={() => {
                                    handlePrint();
                                    closeDrawer();
                                }}
                                text='Download Account details'
                            />
                        )}
                    </Stack>
                    <div className='my-4 mt-10'>
                        {wallets.map((item) => (
                            <div
                                className={`pr-4 mb-3 transition-all delay-75 px-[1rem] py-[0.7rem] hover:text-appcolorprimary hover:bg-appcolorprimaryfainttwo hover:rounded-[5px]`}
                                key={`user-wallet-${item?.id}`}
                            >
                                <p className='text-[11px] leading-[14px] mb-2'>
                                    {item?.currencyCode}
                                </p>

                                {item?.balance !== undefined && (
                                    <p className='text-[22px] leading-[28px] font-medium text-appcolorblacktwo mb-2'>
                                        {formatCurrency(
                                            item.balance,
                                            item.currencyCode
                                        )}
                                    </p>
                                )}

                                {item?.walletBalance !== undefined && (
                                    <p className='text-[22px] leading-[28px] font-medium text-appcolorblacktwo mb-2'>
                                        {formatCurrency(
                                            item.walletBalance,
                                            item.currencyCode
                                        )}
                                    </p>
                                )}

                                {item?.ledgerBalance !== undefined && (
                                    <p className='text-[10px] leading-[13px]'>
                                        <span className='text-appcolorblacktwo opacity-[0.45]'>
                                            LEDGER BALANCE:{' '}
                                        </span>{' '}
                                        {formatCurrency(
                                            item.ledgerBalance,
                                            item.currencyCode
                                        )}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </Drawer>
            <DedicatedAccountDetails {...{ wallets, myDiv }} />
        </div>
    );
};

export default ViewAccounts;
