import React, { useEffect, useRef, useState } from 'react';
import { openNotification } from '../../../../utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import useCorporatePartners from '../../../../customhooks/useCorporatePartners';
import {
    createGross,
    getSingleDocuments
} from '../../../../api/documents/documents';
import {
    TradeContainer,
    TradeContent
} from '../../../../styles/TradeDocStyles';
import FormHeader from '../../../../components/dashboard/tradeDocuments/common/FormHeader';
import Addpartnerdrawer from '../addpartnerdrawer/addpartnerdrawer';
import CustomModal from '../../../../components/CustomModal';
import Savetradedocument from '../savetradedocument/savetradedocument';
import VerifiedGrossForm from '../../../../components/dashboard/tradeDocuments/formComponents/VerifiedGrossForm';
import QuotationPreview from '../../../../components/dashboard/tradeDocuments/previewComponents/QuotationPreview';
import { Backdrop } from '@mui/material';

const VerifiedGrossMassDeclarationNew = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [params, setParams] = useState<any>();
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const param: any = Object.fromEntries(searchParams.entries());
        setParams(param);
    }, [location.search]);

    useEffect(() => {
        if (params?.edit) {
            fetchDocuments();
        }
    }, [params]);

    const fetchDocuments = async () => {
        setLoadingData(true);
        try {
            const res = await getSingleDocuments({
                corporateDocumentId: params?.edit
            });

            const { data } = res.data;
            setData(data);

            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            if (error?.response) {
                openNotification('error', `Documents, Something went wrong`);
            } else {
                openNotification('error', `Documents, Something went wrong`);
            }
        }
    };

    const { corporatePartners, isLoading } = useCorporatePartners({ page: 1 });

    const pageTopRef = useRef<any>(null);
    const myDiv = useRef<HTMLDivElement | null>(null);

    const [showPreview, setShowPreview] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [fileId, setFileId] = useState(undefined);
    const [signatureUrlBase64, setSignatureUrlBase64] = useState('');
    const [saveOnly, setSaveOnly] = useState(true);
    const [methodOne, setMethodOne] = useState(false);
    const [methodTwo, setMethodTwo] = useState(false);
    const [error, setError] = useState<string | any>(null);
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState<any>({
        logoUrl: '',
        shipperId: undefined,
        shipperAddress: undefined,
        shipperReference: undefined,
        certificateNumber: undefined,
        containerNumber: undefined,
        containerTare: undefined,
        cargoWeight: undefined,
        grossWeight: undefined,
        additionalInformation: undefined,
        placeOfIssue: undefined,
        issuedDate: Date.now(),
        nameOfAuthorisedSignatory: undefined,
        signatureUrl: undefined,
        documentName: '',
        method: undefined,
        signatoryCompany: undefined,
        shipperBank: '',
        shipperSwiftCode: '',
        billOfLadingNumber: ''
    });
    const [errors, setErrors] = useState({
        logoUrl: '',
        shipperId: '',
        shipperAddress: '',
        shipperReference: '',
        certificateNumber: '',
        containerNumber: '',
        containerTare: '',
        cargoWeight: '',
        grossWeight: '',
        additionalInformation: '',
        placeOfIssue: '',
        issuedDate: '',
        nameOfAuthorisedSignatory: '',
        signatureUrl: '',
        documentName: '',
        method: '',
        signatoryCompany: '',
        billOfLadingNumber: ''
    });

    useEffect(() => {
        if (data) {
            setFileId(data?.id);
            setPayload({
                logoUrl: data?.documentData?.logoUrl || '',
                shipperId: data?.documentData?.shipperId || '',
                shipperAddress: data?.documentData?.shipperAddress || '',
                shipperName: data?.documentData?.shipper.name || '',
                shipperReference: data?.documentData?.shipperReference || '',
                certificateNumber: data?.documentData?.certificateNumber || '',
                placeOfIssue: data?.documentData?.placeOfIssue || '',
                issuedDate: data?.documentData?.issuedDate || Date.now(),
                documentName: data?.documentName || '',
                method: data?.documentData?.method || '',
                shipperBank: '',
                shipperSwiftCode: '',
                billOfLadingNumber:
                    data?.documentData?.billOfLadingNumber || '',
                signatoryCompany: data?.documentData?.signatoryCompany || '',
                additionalInformation:
                    data?.documentData?.additionalInformation || '',
                nameOfAuthorisedSignatory:
                    data?.documentData?.nameOfAuthorisedSignatory || '',
                signatureUrl: data?.documentData?.signatureUrl || '',
                containerNumber: data?.documentData.containerNumber || 0,
                containerTare: data.documentData?.containerTare || 0,
                cargoWeight: data.documentData?.cargoWeight || 0,
                grossWeight: data.documentData?.grossWeight || 0
            });

            setQuoteFields([
                {
                    containerNumber: data?.documentData.containerNumber || 0,
                    containerTare: data.documentData?.containerTare || 0,
                    cargoWeight: data.documentData?.cargoWeight || 0,
                    grossWeight: data.documentData?.grossWeight || 0
                }
            ]);
        }
    }, [data]);

    const [showAddPartner, setShowAddPartner] = useState(false);

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangeOne = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMethodOne(event.target.checked);
        setMethodTwo(!event.target.checked);
        if (event.target.checked) {
            updateField('method', 1);
        }
    };

    const handleChangeTwo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setMethodOne(!event.target.checked);
        setMethodTwo(event.target.checked);
        if (event.target.checked) {
            updateField('method', 2);
        }
    };

    /**
     * Close save document
     */
    const [showSaveModal, setShowSaveModal] = useState(false);
    const closeSaveModal = () => {
        setShowSaveModal(false);
    };

    const onSubmit = async () => {
        setLoading(true);
        setError(null);

        setErrors((prevState: any) => ({
            ...prevState,
            shipperAddress: '',
            shipperReference: '',
            certificateNumber: '',
            containerNumber: '',
            containerTare: '',
            cargoWeight: '',
            grossWeight: '',
            additionalInformation: '',
            placeOfIssue: '',
            issuedDate: '',
            signatoryCompany: '',
            nameOfAuthorisedSignatory: '',
            signatureUrl: '',
            documentName: ''
        }));

        try {
            const {
                logoUrl,
                shipperId,
                shipperAddress,
                shipperReference,
                certificateNumber,
                containerNumber,
                containerTare,
                cargoWeight,
                grossWeight,
                additionalInformation,
                placeOfIssue,
                issuedDate,
                nameOfAuthorisedSignatory,
                signatureUrl,
                documentName,
                method,
                signatoryCompany,
                billOfLadingNumber
            } = payload;

            const res = await createGross({
                createdType: 'CREATED',
                documentData: {
                    logoUrl,
                    shipperId,
                    shipperAddress,
                    shipperReference,
                    certificateNumber,
                    containerNumber,
                    containerTare,
                    cargoWeight,
                    grossWeight,
                    additionalInformation,
                    placeOfIssue,
                    issuedDate,
                    nameOfAuthorisedSignatory,
                    signatureUrl,
                    documentName,
                    method,
                    signatoryCompany,
                    billOfLadingNumber
                },
                documentType: 'DRAFT_GROSS_MASS_DECLARATION',
                documentName,
                id: fileId
            });

            const { id } = res.data.data;

            setFileId(id);

            openNotification(
                'success',
                'Draft Gross Mass Declaration document created successfully'
            );

            setShowSaveModal(false);
            setLoading(false);

            if (saveOnly) {
                navigate('/trade-documents');
            }
        } catch (error) {
            window.scroll({
                top: pageTopRef?.current?.offsetTop,
                left: 0,
                behavior: 'smooth'
            });

            setShowSaveModal(false);
            setLoading(false);
            const otherErrors: any[] = [];

            if (error?.response) {
                if (error?.response?.data?.error) {
                    const resError = error?.response?.data?.error;

                    Object.entries(resError).forEach(([key, value]) => {
                        if (Object.keys(errors).includes(key)) {
                            setErrors((prevState) => ({
                                ...prevState,
                                [key]: value
                            }));
                        } else {
                            otherErrors.push(value);
                        }
                    });

                    if (otherErrors.length > 0) {
                        const errorList = otherErrors.map(
                            (element: any, index) => {
                                return (
                                    <li key={`error-list-${index}`}>
                                        {element}
                                    </li>
                                );
                            }
                        );
                        setError(errorList);
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    const [quoteFields, setQuoteFields] = useState([
        {
            containerNumber: payload.containerNumber || 0,
            containerTare: payload.containerTare || 0,
            cargoWeight: payload.cargoWeight || 0,
            grossWeight: payload.grossWeight || 0
        }
    ]);

    // TODO(Timi): Fix function
    const updateQuoteField = (
        index: number,
        attributeName: string,
        attributeValue?: any
    ) => {
        const newContainers = [...quoteFields];
        (newContainers[index] as any)[attributeName] = attributeValue;
        setQuoteFields(newContainers);
    };

    const breadcrumbs = [
        {
            title: 'Home',
            link: '/',
            active: false
        },
        {
            title: 'Documents',
            link: '/trade-documents',
            active: false
        },
        {
            title: 'Draft Gross Mass Declaration',
            link: '/trade-documents/verified-gross-mass-declaration',
            active: true
        }
    ];

    return (
        <TradeContainer>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loadingData}
            >
                <img
                    src='/img/vectors/loadingcircles.svg'
                    alt='loading'
                    className='animate-spin'
                />
            </Backdrop>
            <FormHeader
                breadcrumbs={breadcrumbs}
                docTitle='Draft Gross Mass Declaration'
                {...{
                    showPreview,
                    setShowPreview,
                    myDiv,
                    downloadLoading,
                    setDownloadLoading
                }}
                onSaveOnlyAction={() => {
                    setSaveOnly(true);
                    setShowSaveModal(true);
                }}
                onSaveAction={() => {
                    setSaveOnly(false);
                    setShowSaveModal(true);
                }}
                disabled={
                    Object.values(payload).some(
                        (item: any) => item === undefined
                    ) || loading
                }
            />

            <TradeContent>
                {showPreview ? (
                    <div ref={myDiv}>
                        <QuotationPreview
                            data={payload}
                            docTitle='Draft Gross Mass Declaration'
                            quoteFields={quoteFields}
                            signatureUrlBase64={signatureUrlBase64}
                        />
                    </div>
                ) : (
                    <VerifiedGrossForm
                        {...{
                            error,
                            updateField,
                            payload,
                            corporatePartners,
                            loadingCorporatepartners: isLoading,
                            setShowAddPartner,
                            errors,
                            quoteFields,
                            updateQuoteField,
                            loading,
                            handleChangeTwo,
                            handleChangeOne,
                            setSignatureUrlBase64,
                            signatureUrlBase64,
                            setSaveOnly,
                            setShowSaveModal,
                            methodOne,
                            methodTwo
                        }}
                    />
                )}
            </TradeContent>
            {showAddPartner ? (
                <Addpartnerdrawer
                    closeDrawer={() => setShowAddPartner(false)}
                    showDrawer={showAddPartner}
                />
            ) : null}

            {showSaveModal && (
                <CustomModal
                    open={showSaveModal}
                    onClose={closeSaveModal}
                    title='Saving document'
                    maxWidth='xs'
                    styles={{
                        overflowX: 'hidden',
                        '.MuiPaper-root': {
                            width: '100%'
                        }
                    }}
                >
                    <Savetradedocument
                        updateField={updateField}
                        onSubmit={onSubmit}
                        loading={loading}
                        data={payload}
                    />
                </CustomModal>
            )}
        </TradeContainer>
    );
};

export default VerifiedGrossMassDeclarationNew;
