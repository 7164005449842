import { useEffect, useState } from 'react';
import { AccessPermissions, CurrentUserProfile } from '../interfaces/User';
import { useGetQuery } from './useGetQuery';

export const useUserProfile = () => {
    const [permissions, setPermissions] = useState<AccessPermissions>(
        {} as AccessPermissions
    );

    const {
        isLoading,
        isFetching,
        entries: userDetails,
        refetch
    } = useGetQuery<CurrentUserProfile>({
        url: '/me',
        cacheKey: 'users-profile'
    });

    useEffect(() => {
        setPermissions(userDetails?.accessPermissions);
    }, [isFetching, isLoading, userDetails]);

    return { isLoading, userDetails, refetch, permissions, isFetching };
};