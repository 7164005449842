import { http } from '..'
import { paginatedData } from '../../utils/helper-interface'

export interface getSalesContractPayload extends paginatedData {
  corporateId?: string
}

export const getSalesContract = (
  corporateId: any,
  data: getSalesContractPayload,
) => {
  return http.get(`/sales/contract/${corporateId}`, {
    params: {
      ...data,
    },
  })
}

export const getSalesContractDetails = (id: number) => {
  return http.get(`/sales/contract/booking/${id}`)
}

export const deleteSalesContract = (id: number) => {
  return http.delete(`/sales/contract/${id}`)
}

export const getSalesInvoicesSummary = (
  corporateId: any,
  data: getSalesContractPayload,
) => {
  return http.get(`/invoice/summary/${corporateId}`, {
    params: {
      ...data,
    },
  })
}

export const updateContractStatus = (id: number, status: any) => {
  return http.put(`/sales/contract/status/${id}?status=${status}`, {
    params: {
      ...status,
    },
  })
}

export const updateInvoiceStatus = (id: number, status: any) => {
  return http.put(`/invoice/status/${id}?status=${status}`, {
    params: {
      ...status,
    },
  })
}

export const updateInvoicePayment = (id: number, data: any) => {
  return http.post(`/invoice/paynent/${id}`, data)
}

export const getSalesContractSummary = (
  corporateId: any,
  data: getSalesContractPayload,
) => {
  return http.get(`/sales/contract/summary/${corporateId}`, {
    params: {
      ...data,
    },
  })
}



