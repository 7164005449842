import { Grid, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import NewCustomSelect from '../../../../newcustomselect/newcustomselect';
import { PartnerDetailsInterface } from '../../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/CommercialTypes';

const ConsigneeDetails = ({
    errors,
    payload,
    updateField,
    corporatePartners,
    setShowAddPartner,
    loadingCorporatepartners
}: PartnerDetailsInterface) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                    <div className='mt-4'>
                        <NewCustomSelect
                            hasError={errors?.consigneeId.length > 0}
                            helperText={
                                errors?.consigneeId.length > 0
                                    ? errors?.consigneeId
                                    : undefined
                            }
                            selectValue={payload.consigneeId}
                            required
                            selectOptions={
                                corporatePartners as unknown as Record<
                                    string,
                                    unknown
                                >[]
                            }
                            name={'consigneeId'}
                            updateField={(_, value) => {
                                updateField('consigneeId', value);
                                updateField(
                                    'consigneeAddress',
                                    corporatePartners.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === value
                                    )[0]?.address
                                );
                                updateField(
                                    'consigneeName',
                                    corporatePartners.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === value
                                    )[0]?.name
                                );
                                updateField(
                                    'consigneeBankName',
                                    corporatePartners.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === value
                                    )[0]?.bankName
                                );
                                updateField(
                                    'consigneeAccountNumber',
                                    corporatePartners.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === value
                                    )[0]?.bankAccountNumber
                                );
                                updateField(
                                    'consigneeCountry',
                                    corporatePartners.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === value
                                    )[0]?.bankCountry
                                );
                                updateField(
                                    'consigneeSwiftCode',
                                    corporatePartners.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === value
                                    )[0]?.swiftCode
                                );
                            }}
                            label={'Consignee'}
                            selectOptionLabel='name'
                            loading={loadingCorporatepartners}
                            dropdownRender={() => {
                                return (
                                    <div className='px-4 border-t border-appcolorgreyfaint'>
                                        <p
                                            className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                            onClick={() => {
                                                setShowAddPartner(true);
                                            }}
                                        >
                                            + Add a New Partner
                                        </p>
                                    </div>
                                );
                            }}
                        />
                    </div>

                    <TextField
                        InputLabelProps={{
                            shrink: !!payload.consigneeAddress
                        }}
                        error={errors?.consigneeAddress.length > 0}
                        helperText={
                            errors?.consigneeAddress.length > 0
                                ? errors?.consigneeAddress
                                : undefined
                        }
                        value={payload?.consigneeAddress}
                        margin='normal'
                        required
                        fullWidth
                        id='quotation-documentName'
                        label='Consignee Address'
                        name='consigneeAddress'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            updateField('consigneeAddress', e.target.value);
                        }}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: '0px'
                        }}
                    >
                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                error={errors?.billOfLadingNumber.length > 0}
                                helperText={
                                    errors?.billOfLadingNumber.length > 0
                                        ? errors?.billOfLadingNumber
                                        : undefined
                                }
                                margin='normal'
                                value={payload?.billOfLadingNumber || ''}
                                required
                                fullWidth
                                id='proformainvoice-billOfLadingNumber'
                                label='Bill of Lading Number'
                                name='billOfLadingNumber'
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'billOfLadingNumber',
                                        e.target.value
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                error={errors?.salesContractNumber.length > 0}
                                helperText={
                                    errors?.salesContractNumber.length > 0
                                        ? errors?.salesContractNumber
                                        : undefined
                                }
                                margin='normal'
                                value={payload?.salesContractNumber || ''}
                                required
                                fullWidth
                                id='proformainvoice-salesContractNumber'
                                label='Sales Contract Number'
                                name='salesContractNumber'
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'salesContractNumber',
                                        e.target.value
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: '0px'
                        }}
                    >
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                error={errors?.buyerReference.length > 0}
                                helperText={
                                    errors?.buyerReference.length > 0
                                        ? errors?.buyerReference
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='commercialinvoice-buyerReference'
                                label='Buyer Reference'
                                name='buyerReference'
                                value={payload?.buyerReference || ''}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'buyerReference',
                                        e.target.value
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>

                    <div className='mt-4'>
                        <NewCustomSelect
                            hasError={errors?.buyerId.length > 0}
                            helperText={
                                errors?.buyerId.length > 0
                                    ? errors?.buyerId
                                    : undefined
                            }
                            selectValue={payload.buyerId}
                            selectOptions={
                                corporatePartners as unknown as Record<
                                    string,
                                    unknown
                                >[]
                            }
                            name={'buyerId'}
                            updateField={(_, value) => {
                                updateField('buyerId', value);
                                updateField(
                                    'buyerAddress',
                                    corporatePartners.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === value
                                    )[0]?.address
                                );
                                updateField(
                                    'buyer',
                                    corporatePartners.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === value
                                    )[0]?.name
                                );
                            }}
                            label={'Buyer (if not Consignee)'}
                            selectOptionLabel='name'
                            loading={loadingCorporatepartners}
                            dropdownRender={() => {
                                return (
                                    <div className='px-4 border-t border-appcolorgreyfaint'>
                                        <p
                                            className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                            onClick={() => {
                                                setShowAddPartner(true);
                                            }}
                                        >
                                            + Add a New Partner
                                        </p>
                                    </div>
                                );
                            }}
                        />
                    </div>

                    <TextField
                        InputLabelProps={{
                            shrink: !!payload.buyerAddress
                        }}
                        error={errors?.buyerAddress.length > 0}
                        helperText={
                            errors?.buyerAddress.length > 0
                                ? errors?.buyerAddress
                                : undefined
                        }
                        value={payload?.buyerAddress || ''}
                        margin='normal'
                        fullWidth
                        id='quotation-buyerAddress'
                        label='Buyer Address (if not Consignee)'
                        name='buyerAddress'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            updateField('buyerAddress', e.target.value);
                        }}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default ConsigneeDetails;
