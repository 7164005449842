import React from 'react';
import { REACT_APP_BASE_URL } from '../../../config';
import DocumentViewer from '../../../components/DocumentViewer/DocumentViewer';
import { PlainFileIcon } from '../../../components/icons/PlainFileIcon';
import { Divider, Grid, Skeleton } from '@mui/material';
import ViewTransactionDocument from './shared/ViewTransactionDocument';
import dayjs from 'dayjs';
import { SalesDocument } from './interfaces/SalesDocuments';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';

const LogisticsNoPaymentDetails = ({
    invoiceDetails,
    isDetailsLoading
}: {
    invoiceDetails: SalesDocument;
    isDetailsLoading: boolean;
}) => {
    const { permissions } = useUserProfile();

    return (
        <div>
            <div className='mt-[20px]'>
                <div className='flex gap-[5px] items-center py-[10px] px-[14px] leading-[22px] text-[15px] bg-white w-max'>
                    <PlainFileIcon />
                    Details
                </div>
            </div>
            {isDetailsLoading ? (
                <Skeleton variant='rectangular' height={'35rem'} />
            ) : (
                <Grid
                    className='p-[20px] bg-white rounded-[5px] gap-[40px] rounded-tl-[0]'
                    justifyContent='space-between'
                    direction='row'
                    alignItems='flex-start'
                    container
                >
                    <Grid container item lg={6}>
                        <Grid item xs={12} md={12} lg={12}>
                            <div className=' flex justify-between py-[15px] border-b-[1px] border-[#eaecf0]'>
                                <p className='text-appcolorblacktwo opacity-[0.6]'>
                                    Invoice Number
                                </p>

                                <p className='font-medium'>
                                    {
                                        invoiceDetails?.documentData
                                            ?.invoiceNumber
                                    }
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div className=' flex justify-between py-[15px] border-b-[1px] border-[#eaecf0]'>
                                <p className='text-appcolorblacktwo opacity-[0.6]'>
                                    Bill of Lading Number
                                </p>

                                <p className='font-medium'>
                                    {
                                        invoiceDetails?.documentData
                                            ?.billOfLadingNumber
                                    }
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div className=' flex justify-between py-[15px] border-b-[1px] border-[#eaecf0]'>
                                <p className='text-appcolorblacktwo opacity-[0.6]'>
                                    Customer Name
                                </p>

                                <p className='font-medium'>
                                    {invoiceDetails?.documentData?.customerName}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div className=' flex justify-between py-[15px] border-b-[1px] border-[#eaecf0]'>
                                <p className='text-appcolorblacktwo opacity-[0.6]'>
                                    Carrier Name
                                </p>

                                <p className='font-medium'>
                                    {
                                        invoiceDetails?.documentData
                                            ?.beneficiary.beneficiaryCompanyName
                                    }
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div className=' flex justify-between py-[15px] border-b-[1px] border-[#eaecf0]'>
                                <p className='text-appcolorblacktwo opacity-[0.6]'>
                                    Issued Date
                                </p>

                                <p className='font-medium'>
                                    {dayjs(
                                        invoiceDetails?.documentData?.issuedDate
                                    ).format('DD MMM, YYYY')}
                                </p>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div className=' flex justify-between py-[15px] border-b-[1px] border-[#eaecf0]'>
                                <p className='text-appcolorblacktwo opacity-[0.6]'>
                                    Due Date{' '}
                                </p>

                                <p className='font-medium'>
                                    {dayjs(
                                        invoiceDetails?.documentData?.dueDate
                                    ).format('DD MMM, YYYY')}
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider orientation='vertical' flexItem />

                    <Grid
                        item
                        xs={12}
                        md={12}
                        lg={5}
                        className='max-w-[100%] max-h-[100%] h-[100%]'
                    >
                        {invoiceDetails?.documentUrl && (
                            <div className='w-[100%] min-h-[500px] h-[20px]'>
                                <DocumentViewer
                                    fileUrl={
                                        REACT_APP_BASE_URL +
                                        invoiceDetails?.documentUrl?.replace(
                                            'v1/',
                                            ''
                                        )
                                    }
                                    loader={true}
                                />
                            </div>
                        )}
                        {permissions &&
                            hasPermission({
                                userPermissions: permissions,
                                module: 'payments',
                                permissionList: ['write', '*', 'read']
                            }) && (
                                <div className=' w-full flex border-t justify-end '>
                                    <ViewTransactionDocument
                                        downloadOnly
                                        fileUrl={
                                            REACT_APP_BASE_URL +
                                            invoiceDetails?.documentUrl?.replace(
                                                'v1/',
                                                ''
                                            )
                                        }
                                    />
                                </div>
                            )}
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default LogisticsNoPaymentDetails;
