import { Table } from 'antd';
import React from 'react';
import { formatCurrency, formatNumber } from '../../../../utils/helper';
import { Box } from '@mui/material';
import { NoDataCross } from '../../../icons/NoDataCross';
import Customtable from '../../../customtable/customtable';
interface IProps {
    loadingData?: boolean;
    data?: any;
}

const MobileItemsCards = ({ item }: { item?: any }) => {
    return (
        <>
            <Box key={item?.id} borderBottom='1px solid#EAECF0' p={3}>
                <Box width='full' justifyContent='space-between' display='flex'>
                    <Box display='flex' gap={1}>
                        <Box>
                            <h4 className=' text-ebony text-[14px] font-semibold'>
                                Product Code: {item?.productCode ?? '--'}
                            </h4>
                            <p className=' text-[12px] text-paleSky whitespace-pre-line max-w-[320px] overflow-hidden'>
                                Description:
                                <span className=' pl-1 pr-3 font-extrabold'>
                                    {item?.descriptionOfGoods ?? '--'}
                                </span>
                            </p>
                            <p className=' text-[12px] text-paleSky'>
                                Qty:
                                <span className=' pl-1 pr-3 font-extrabold'>
                                    {formatNumber(item?.quantity ?? 0)}
                                </span>
                                Unit Type:
                                <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                    {item?.unitType ?? 0}
                                </span>
                                Unit Price:
                                <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                    {formatCurrency(
                                        item?.unitPrice ?? 0,
                                        item.currencyCode
                                    )}
                                </span>
                            </p>
                            <p className=' text-[12px] text-paleSky'>
                                Total Amount:
                                <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                    {formatCurrency(
                                        item?.unitPrice * item?.quantity || 0,
                                        item?.currencyCode || 'NGN'
                                    )}
                                </span>
                            </p>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const InvoiceItemsTable = ({ loadingData, data }: IProps) => {
    return (
        <>
            <Table
                loading={loadingData}
                pagination={{ pageSize: 20 }}
                dataSource={data?.items || []}
                locale={{
                    emptyText: () => {
                        return (
                            <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                                <div className='flex justify-center mb-4'>
                                    <NoDataCross />
                                </div>

                                <p className='text-center text-appcolorblackthree'>
                                    You have no Items on this Invoice.
                                    <br />
                                </p>
                            </div>
                        );
                    }
                }}
                columns={[
                    {
                        title: 'Product Code',
                        dataIndex: 'productCode',
                        key: 'productCode',
                        render: (text) => <span>{text}</span>
                    },
                    {
                        title: 'Description',
                        dataIndex: 'descriptionOfGoods',
                        key: 'descriptionOfGoods',
                        render: (text) => <span>{text}</span>
                    },
                    {
                        title: 'Quantity',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        render: (text) => <span>{formatNumber(text || 0)}</span>
                    },
                    {
                        title: 'Unit Type',
                        dataIndex: 'unitType',
                        key: 'unitType'
                    },
                    {
                        title: 'Unit Price',
                        dataIndex: 'unitPrice',
                        key: 'unitPrice',
                        render: (text) => (
                            <span>
                                {formatCurrency(text || 0, data.currencyCode)}
                            </span>
                        )
                    },

                    {
                        title: 'Total Amount',
                        dataIndex: 'amount',
                        key: 'amount',
                        render: (_, record: any) => (
                            <span>
                                {formatCurrency(
                                    record?.unitPrice * record?.quantity || 0,
                                    data?.currencyCode || 'NGN'
                                )}
                            </span>
                        )
                    }
                ]}
                className='hidden lg:block'
            />

            <div className='lg:hidden'>
                <Customtable
                    data={data?.items ?? []}
                    loadingData={loadingData}
                    multiTableTiles={[]}
                    emptyText='You have no Items on this Invoice.'
                    mobileComponent={<MobileItemsCards />}
                />
            </div>
        </>
    );
};

export default InvoiceItemsTable;
