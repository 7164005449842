import { useState } from 'react';
import Customtable from '../../../components/customtable/customtable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { commaNumber, getTransactionStatus } from '../../../utils/helper';
import useWallets from '../../../customhooks/wallets/useWallets';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import TopupAccount from '../payments/TopupAccount';
import Dashboardcards from './dashboardcards/dashboardcards';
import EnablingFeature from '../../../components/EnablingFeature';
import { useTransactions } from '../../../customhooks/useTransactions';
import { WalletBalances } from '../../../components/walletbalances/WalletBalances';
import { SuitUIButton } from '../../../components/SuitUIButton';
import { MoneySendIcon } from '../../../components/icons';
import { PlusIcon } from '../../../components/icons/PlusIcon';
import DashboardTableMobileCard from './dashboardTableMobileCard';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { LoadingCircles } from '../../../components/icons/LoadingCircles';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';

const Dashboardmain = () => {
    const { wallets, isLoading: isLoadingWallets } = useWallets();
    const { isLoading: isLoadingUserProfile, permissions } = useUserProfile();

    const navigate = useNavigate();

    const state = useSelector((state: RootState) => ({
        user: state.user.user
    }));

    const { user } = state;

    const [showTopUp, setShowTopUp] = useState(false);

    const {
        isLoading: isTransactionsLoading,
        transactionsList,
        invalidateTransactionsCache: fetchTransactions
    } = useTransactions();

    const closeShowTopUp = () => {
        setShowTopUp(false);
    };

    return (
        <div className='pt-[32px]'>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={isLoadingUserProfile}
            >
                <div className='animate-spin'>
                    <LoadingCircles />
                </div>{' '}
            </Backdrop>

            <div className='mb-[1.3rem] px-[16px] lg:px-[1rem] relative'>
                <div className=' flex items-center justify-between lg:pb-4'>
                    <h1 className='text-[24px] font-medium  lg:text-[32px] lg:leading-[23px] text-ebony'>
                        Welcome
                        {user?.corporate_name && ` ${user?.corporate_name}.`}
                    </h1>

                    {user?.user_level !== 5 &&
                        permissions &&
                        hasPermission({
                            userPermissions: permissions,
                            module: 'payments',
                            permissionList: ['write', '*']
                        }) && (
                            <div className='hidden lg:flex'>
                                <p
                                    className='flex items-center cursor-pointer bg-appcolorwhite rounded-[3px] py-[0.8rem] px-[1.5rem]'
                                    onClick={() => {
                                        navigate('/payments/make-payment');
                                    }}
                                >
                                    <img
                                        src='/img/vectors/upload.svg'
                                        alt='send'
                                    />
                                    <span className='pl-[0.6rem] text-appcolorblackthree tracking-[0.01rem] text-[12px] leading-[15px] font-semibold'>
                                        Send
                                    </span>
                                </p>

                                <p
                                    className='flex items-center cursor-pointer bg-appcolorwhite rounded-[3px] py-[0.8rem] px-[1.5rem] ml-[1.1rem]'
                                    onClick={() => {
                                        setShowTopUp(true);
                                    }}
                                >
                                    <img
                                        src='/img/vectors/download.svg'
                                        alt='add-funds'
                                    />
                                    <span className='pl-[0.6rem] text-appcolorblackthree tracking-[0.01rem] text-[12px] leading-[15px] font-semibold'>
                                        Add Funds
                                    </span>
                                </p>
                            </div>
                        )}
                </div>

                <div>
                    <p className='text-[18px] max-w-[290px] font-normal lg:text-[20px] lg:max-w-full leading-[23px] text-paleSky'>
                        We're here to help you grow your business.
                    </p>
                </div>
            </div>

            {user?.user_level !== 5 && (
                <div className='px-[16px] mb-[24px] lg:[px-1rem] mt-[16px] lg:mt-[3rem] lg:mb-[2.8rem]'>
                    <WalletBalances
                        wallets={[...wallets].splice(0, 3)}
                        isLoading={isLoadingWallets}
                    />
                </div>
            )}

            {user?.user_level !== 5 &&
                permissions &&
                hasPermission({
                    userPermissions: permissions,
                    module: 'payments',
                    permissionList: ['write', '*']
                }) && (
                    <div className='px-[16px] lg:hidden flex justify-between gap-[8px]'>
                        <SuitUIButton
                            text='Add funds'
                            onClick={() => {
                                setShowTopUp(true);
                            }}
                            invert
                            fullWidth
                            iconLeft={<PlusIcon color='#0088B2' />}
                            style='border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                        />

                        <SuitUIButton
                            text='Make payment'
                            onClick={() => {
                                navigate('/payments/make-payment');
                            }}
                            invert
                            fullWidth
                            iconLeft={<MoneySendIcon />}
                            style='border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                        />
                    </div>
                )}

            {user?.user_level !== 5 ? (
                <div>
                    <h5 className='px-[16px] text-base font-normal leading-[23px] mt-[24px] mb-[12px] lg:hidden'>
                        Steps to get you started!
                    </h5>

                    <div className='px-[1rem] flex items-center justify-between hidden lg:block'>
                        <div className='flex'>
                            <div
                                className={`bg-appcolorwhite trapezoid pt-[0.8rem] pb-[0.7rem] pl-[1.6rem] w-fit pr-[5.6rem] cursor-pointer text-[17px] leading-[24px]`}
                            >
                                Steps to get you started!
                            </div>
                        </div>
                    </div>

                    <div className='pl-[16px] lg:px-[1rem] flex overflow-x-scroll max-w-[calc(100vw)] lg:block lg:grid lg:grid-cols-2 lg:gap-[16px] lg:col-auto'>
                        <EnablingFeature flag='Payments'>
                            <Dashboardcards
                                imgIcon='/img/vectors/g4.svg'
                                title={
                                    <p>
                                        Exchange currencies and
                                        <br />
                                        send payments instantly
                                    </p>
                                }
                                subTitle={
                                    <span>
                                        You can now send and receive payments
                                        <br />
                                        in any currency of your choice. See how
                                        <br />
                                        easy it is to send money in any currency
                                    </span>
                                }
                                getStarted={() => {
                                    navigate('/dashboard');
                                }}
                            />
                        </EnablingFeature>
                        <EnablingFeature flag='Financing'>
                            <Dashboardcards
                                imgIcon='/img/vectors/g1.svg'
                                title={
                                    <p>
                                        Finance your <br /> business
                                    </p>
                                }
                                subTitle={
                                    <span>
                                        Get paid upfront on all your
                                        <br />
                                        shipments. Ship your goods and pay
                                        <br />
                                        us later.
                                    </span>
                                }
                                getStarted={() => {
                                    navigate('/dashboard');
                                }}
                            />
                        </EnablingFeature>
                        <EnablingFeature flag='BookingInttra'>
                            <Dashboardcards
                                imgIcon='/img/vectors/g3.svg'
                                title={
                                    <p>
                                        Book and track all your
                                        <br />
                                        shipments in one place
                                    </p>
                                }
                                subTitle={
                                    <span>
                                        You can book your shipments directly
                                        with
                                        <br />
                                        any carrier of your choice in 3 clicks.
                                    </span>
                                }
                                getStarted={() => {
                                    navigate('/dashboard');
                                }}
                            />
                        </EnablingFeature>
                        <EnablingFeature flag='TradeDocuments'>
                            <Dashboardcards
                                imgIcon='/img/vectors/g2.svg'
                                title={
                                    <p>
                                        Create and manage all
                                        <br />
                                        trade documents in one place
                                    </p>
                                }
                                subTitle={
                                    <span>
                                        You can now create, save and send
                                        <br />
                                        branded documents you require for all
                                        <br />
                                        your shipments
                                    </span>
                                }
                                getStarted={() => {
                                    navigate('/dashboard');
                                }}
                            />
                        </EnablingFeature>
                    </div>
                </div>
            ) : null}

            <div className='mt-8 lg:px-[1rem]'>
                <div className='bg-white px-[16px] pt-[24px] pb-[8px] lg:hidden'>
                    <h5 className='text-2xl text-black'>Transactions</h5>
                </div>
                <Customtable
                    isDashboardHomeTable
                    data={transactionsList}
                    loadingData={isTransactionsLoading}
                    multiTableTiles={[
                        {
                            title: 'Transactions',
                            data: [],
                            noDataText: () =>
                                user?.user_level === 5 ? null : (
                                    <p className='text-center text-appcolorblackthree'>
                                        You have no transactions done.
                                        <br />
                                        <span
                                            className='cursor-pointer text-appcolorprimary'
                                            onClick={() => {
                                                navigate(
                                                    '/payments/make-payment'
                                                );
                                            }}
                                        >
                                            Make a payment
                                        </span>{' '}
                                        to get started.
                                    </p>
                                ),
                            columns:
                                user?.user_level === 5
                                    ? [
                                          {
                                              title: 'Date',
                                              dataIndex: 'createdDate',
                                              key: 'createdDate',
                                              render: (_, { createdDate }) => (
                                                  <span>
                                                      {moment(
                                                          createdDate
                                                      ).format('ll')}
                                                  </span>
                                              )
                                          },
                                          {
                                              title: 'Corporate',
                                              dataIndex: 'businessName',
                                              key: 'businessName'
                                          },
                                          {
                                              title: 'Tranx ID',
                                              dataIndex: 'transactionReference',
                                              key: 'transactionReference',
                                              render: (
                                                  text,
                                                  { transactionReference }
                                              ) => (
                                                  <span
                                                      className='cursor-pointer text-appcolorprimary'
                                                      onClick={() => {
                                                          navigate(
                                                              `/payments/transactions/${transactionReference}`
                                                          );
                                                      }}
                                                  >
                                                      {text}
                                                  </span>
                                              )
                                          },
                                          {
                                              title: 'From',
                                              dataIndex: 'amountFrom',
                                              key: 'amountFrom',
                                              render: (
                                                  text,
                                                  { fromCurrencyCode }
                                              ) => (
                                                  <span>
                                                      {fromCurrencyCode}{' '}
                                                      {commaNumber(
                                                          parseFloat(text)
                                                      )}
                                                  </span>
                                              )
                                          },
                                          {
                                              title: 'To',
                                              dataIndex: 'amountTo',
                                              key: 'amountTo',
                                              render: (
                                                  text,
                                                  { toCurrencyCode }
                                              ) => (
                                                  <span>
                                                      {toCurrencyCode}{' '}
                                                      {commaNumber(
                                                          parseFloat(text)
                                                      )}
                                                  </span>
                                              )
                                          },
                                          {
                                              title: 'Rate',
                                              dataIndex: 'rateValue',
                                              key: 'rateValue',
                                              render: (text) => (
                                                  <span>
                                                      {commaNumber(
                                                          parseFloat(
                                                              text
                                                          ).toFixed(5)
                                                      )}
                                                  </span>
                                              )
                                          },
                                          {
                                              title: 'Type',
                                              dataIndex: 'transactionAction',
                                              key: 'transactionAction'
                                          },
                                          {
                                              title: 'Status',
                                              dataIndex: 'status',
                                              key: 'status',
                                              render: (_, { status }) => (
                                                  <span>
                                                      {getTransactionStatus(
                                                          status
                                                      )}
                                                  </span>
                                              )
                                          }
                                      ]
                                    : [
                                          {
                                              title: 'Date',
                                              dataIndex: 'createdDate',
                                              key: 'createdDate',
                                              render: (_, { createdDate }) => (
                                                  <span>
                                                      {moment(
                                                          createdDate
                                                      ).format('ll')}
                                                  </span>
                                              )
                                          },
                                          {
                                              title: 'Tranx ID',
                                              dataIndex: 'transactionReference',
                                              key: 'transactionReference',
                                              render: (
                                                  text,
                                                  { transactionReference }
                                              ) => (
                                                  <span
                                                      className='cursor-pointer text-appcolorprimary'
                                                      onClick={() => {
                                                          navigate(
                                                              `/payments/transactions/${transactionReference}`
                                                          );
                                                      }}
                                                  >
                                                      {text}
                                                  </span>
                                              )
                                          },
                                          {
                                              title: 'From',
                                              dataIndex: 'amountFrom',
                                              key: 'amountFrom',
                                              render: (
                                                  text,
                                                  { fromCurrencyCode }
                                              ) => (
                                                  <span>
                                                      {fromCurrencyCode}{' '}
                                                      {commaNumber(
                                                          parseFloat(text)
                                                      )}
                                                  </span>
                                              )
                                          },
                                          {
                                              title: 'To',
                                              dataIndex: 'amountTo',
                                              key: 'amountTo',
                                              render: (
                                                  text,
                                                  { toCurrencyCode }
                                              ) => (
                                                  <span>
                                                      {toCurrencyCode}{' '}
                                                      {commaNumber(
                                                          parseFloat(text)
                                                      )}
                                                  </span>
                                              )
                                          },
                                          {
                                              title: 'Rate',
                                              dataIndex: 'rateValue',
                                              key: 'rateValue',
                                              render: (text) => (
                                                  <span>
                                                      {commaNumber(
                                                          parseFloat(
                                                              text
                                                          ).toFixed(5)
                                                      )}
                                                  </span>
                                              )
                                          },
                                          {
                                              title: 'Type',
                                              dataIndex: 'transactionAction',
                                              key: 'transactionAction'
                                          },
                                          {
                                              title: 'Status',
                                              dataIndex: 'status',
                                              key: 'status',
                                              render: (_, { status }) => (
                                                  <span>
                                                      {getTransactionStatus(
                                                          status
                                                      )}
                                                  </span>
                                              )
                                          }
                                      ],
                            rowKey: 'id',
                            actionText: () => (
                                <p
                                    className='px-3 leading-6 cursor-pointer text-appcolorprimary w-fit'
                                    onClick={() => {
                                        if (!isTransactionsLoading) {
                                            navigate('/payments/overview');
                                        }
                                    }}
                                >
                                    View All
                                </p>
                            ),
                            getData: () => {
                                fetchTransactions();
                            }
                        }
                    ]}
                    mobileComponent={<DashboardTableMobileCard />}
                    viewMoreAction={() => {
                        if (!isTransactionsLoading) {
                            navigate('/payments/overview');
                        }
                    }}
                    viewMoreText='View all transactions'
                />
            </div>

            {
                <TopupAccount
                    wallets={wallets}
                    closeDrawer={closeShowTopUp}
                    showDrawer={showTopUp}
                    loadingWallets={isLoadingWallets}
                />
            }
        </div>
    );
};

export default Dashboardmain;
