import React, { useEffect, useState } from 'react';
import { download } from '../../../components/customIcons';
import CustomModal from '../../../components/CustomModal';
import { ExportIcon } from '../../../components/icons/ExportIcon';
import MessageEnvelope from '../../../components/icons/MessageEnvelope';
import {
    Alert,
    CircularProgress,
    InputAdornment,
    Stack,
    TextField
} from '@mui/material';
import { Button, Radio, RadioChangeEvent } from 'antd';
import { ExportContainer } from '../../../components/dashboard/sales/contracts/ContractExport';
import { GetTransactionsParams, Transactions } from './interfaces/Transactions';
import { queryConfig } from '../../../api/queryBase';
import { ServiceableInvoiceParams } from './interfaces/Invoice';
import { openNotification } from '../../../utils/helper';
import { DeleteIcon } from '../../../components/icons/DeleteIcon';

export const ExportTransactions = ({
    params,
    serviceable = false,
    viewExport,
    setViewExport
}: {
    params: GetTransactionsParams | ServiceableInvoiceParams;
    serviceable?: boolean;
    viewExport: boolean;
    setViewExport: (val: boolean) => void;
}) => {
    const [exportType, setExportType] = useState('CSV');
    const [emailList, setEmailList] = useState([{ email: '' }]);
    const [emailErrors, setEmailErrors] = useState<string[]>([]);

    const exportOptions = ['CSV', 'Send Via Email'];
    const query = queryConfig();
    const downloadQuery = queryConfig({ isBlob: true });

    const onChangeExportType = ({ target: { value } }: RadioChangeEvent) => {
        setExportType(value);
    };

    const addEmail = () => {
        setEmailList([...emailList, { email: '' }]);
    };

    const removeEmail = (index: number) => {
        setEmailList(emailList.filter((_, i) => i !== index));
        setEmailErrors(emailErrors.filter((_, i) => i !== index));
    };

    const {
        isLoading: isDownloadTransactionsLoading,
        isFetching: isDownloadTransactionsFetching,
        refetch: downloadTransaction,
        isError: isDownloadError,
        isSuccess: isDownloadSuccess
    } = downloadQuery<Blob>({
        url: '/wallet/transaction/download',
        cacheKey: `transactions-download`,
        params: { ...params, serviceable },
        enabled: false
    });

    const {
        isLoading: isExportTransactionsLoading,
        isFetching: isExportTransactionsFetching,
        refetch: exportTransaction,
        isError: isExportError,
        isSuccess: isExportSuccess
    } = query<{
        content: Transactions;
    }>({
        url: '/wallet/transaction/notify',
        cacheKey: `transactions-notify`,
        params: {
            ...params,
            exportEmails: emailList.map((item) => item.email).join(', '),
            serviceable
        },
        enabled: false
    });

    const isValidEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const updateEmailList = (index: number, value: string) => {
        const newArray = [...emailList];
        const newErrors = [...emailErrors];

        if (isValidEmail(value)) {
            newErrors[index] = '';
        } else {
            newErrors[index] = 'Invalid email';
        }

        newArray[index] = { email: value };
        setEmailList(newArray);
        setEmailErrors(newErrors);
    };

    useEffect(() => {
        if (isExportError || isDownloadError) {
            openNotification('error', 'Export, Something went wrong');
        }
        if (isExportSuccess || isDownloadSuccess) {
            openNotification('info', 'Export, completed Successfully');
            setEmailList([{ email: '' }]);
            setViewExport(false);
            setExportType('CSV');
        }
    }, [isExportError, isDownloadError, isExportSuccess, isDownloadSuccess]);

    const handleDownload = async () => {
        try {
            const { data } = await downloadTransaction();

            if (data) {
                const newFileUrl = window.URL.createObjectURL(data.payload);
                const link = document.createElement('a');
                link.href = newFileUrl;
                link.setAttribute('download', 'Transactions');
                document.body.appendChild(link);
                link.click();
            }
        } catch (error) {
            openNotification('error', 'Export, Something went wrong');
        }
    };

    return (
        <div>
            <div
                className='tab-buttons mr-2 flex'
                onClick={() => setViewExport(true)}
            >
                {download}
                <p
                    style={{
                        paddingRight: '5px',
                        paddingLeft: '5px'
                    }}
                >
                    Export
                </p>
            </div>
            {viewExport && (
                <CustomModal
                    maxWidth='lg'
                    open={viewExport}
                    onClose={() => setViewExport(false)}
                >
                    <ExportContainer>
                        <ExportIcon />
                        <h2>Export File</h2>

                        <Radio.Group
                            options={exportOptions}
                            onChange={onChangeExportType}
                            value={exportType}
                            optionType='button'
                            size='large'
                        />
                        {exportType !== 'CSV' && (
                            <>
                                <p className='mb-4'>
                                    Please enter emails of users to which you
                                    would like to export as a CSV
                                </p>

                                {emailErrors.some((error) => error !== '') && (
                                    <Alert
                                        variant='standard'
                                        severity='error'
                                        className='mb-4'
                                    >
                                        Invalid value in email list
                                    </Alert>
                                )}
                                <p className='lbl'>Email Address</p>

                                <Stack>
                                    {emailList?.map(
                                        (
                                            item: { email: string },
                                            index: number
                                        ) => (
                                            <TextField
                                                key={index}
                                                type='email'
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position='start'>
                                                            <MessageEnvelope />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position='end'>
                                                            {emailList.length >
                                                                1 && (
                                                                <div
                                                                    className=' w-4 cursor-pointer '
                                                                    onClick={() =>
                                                                        removeEmail(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    <DeleteIcon />
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    )
                                                }}
                                                error={!!emailErrors[index]}
                                                value={item?.email}
                                                margin='dense'
                                                fullWidth
                                                placeholder='you@untitledui.com'
                                                onChange={(e) => {
                                                    updateEmailList(
                                                        index,
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        )
                                    )}

                                    <div
                                        className='addAnother'
                                        onClick={addEmail}
                                    >
                                        + Add another
                                    </div>
                                </Stack>
                            </>
                        )}
                        <Stack
                            direction='row'
                            width='100%'
                            gap='12px'
                            className='submitBtns'
                        >
                            <Button
                                onClick={() => {
                                    setEmailList([{ email: '' }]);
                                    setViewExport(false);
                                    setExportType('CSV');
                                }}
                            >
                                Cancel
                            </Button>
                            {exportType === 'CSV' ? (
                                <Button onClick={() => handleDownload()}>
                                    {isDownloadTransactionsFetching ||
                                    isDownloadTransactionsLoading ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        'Download CSV'
                                    )}
                                </Button>
                            ) : (
                                <Button
                                    onClick={() => {
                                        exportTransaction();
                                    }}
                                    disabled={
                                        emailErrors.some(
                                            (error) => error !== ''
                                        ) ||
                                        isExportTransactionsLoading ||
                                        isExportTransactionsFetching
                                    }
                                >
                                    {isExportTransactionsLoading ||
                                    isExportTransactionsFetching ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        'Send CSV'
                                    )}
                                </Button>
                            )}
                        </Stack>
                    </ExportContainer>
                </CustomModal>
            )}
        </div>
    );
};
