import { BeneficiaryShippingLines } from '../interfaces/User';
import { useGetQuery } from './useGetQuery';

const useBeneficiaryShippingLines = () => {
    const { isLoading, entries: beneficiaryShippingLines } = useGetQuery<
        BeneficiaryShippingLines[]
    >({
        url: 'booking/shipping/lines',
        cacheKey: 'beneficiaryShippingLines',
        params: { page: 1, size: 0, beneficiary: true }
    });

    return { isLoading, beneficiaryShippingLines };
};
export default useBeneficiaryShippingLines;
