import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import {
    Box,
    Button,
    Grid,
    IconButton,
    FormControl,
    FormControlLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { getOceanSchedulesPayload } from '../../../../api/booking/ocean-schedules';
import PortsAutocomplete from '../../../../components/autocompletewithapi/PortsAutocomplete';
import { SearchProps } from '../ocean-schedules';
import useShippingLines from '../../../../customhooks/shippinglines/userShippinglines';
import NewCustomSelect from '../../../../components/newcustomselect/newcustomselect';
import React from 'react';
import { DatePicker, DatePickerProps, Dropdown, MenuProps, Spin } from 'antd';
import moment from 'moment';
import {
    buttonStyle,
    contentStyle,
    menuStyle
} from '../../../../components/dashboard/tradeFilter/filterStyle';
import { AxiosResponse } from 'axios';
import { FullResponseData } from '../interfaces/SearchResults';
import { useUserProfile } from '../../../../customhooks/useUserProfile';
import { hasPermission } from '../../../../components/dashboard/dashboardpage/utils';
import { openNotification } from '../../../../utils/helper';

const IDENTIFIER = 'by-point';

interface PointsSearchProps extends SearchProps {
    setFrom: Dispatch<SetStateAction<string>>;
    setTo: Dispatch<SetStateAction<string>>;
    setFromDisplay: Dispatch<SetStateAction<string>>;
    setToDisplay: Dispatch<SetStateAction<string>>;
    setTheResponseError: Dispatch<SetStateAction<string>>;
}

const PointsSearch = (props: PointsSearchProps) => {
    const [theFormData, setTheFormData] =
        useState<Partial<getOceanSchedulesPayload> | null>({
            direction: 'D',
            searchWindow: '2',
            departureDate: undefined,
            portFrom: undefined,
            portTo: undefined,
            vessel: undefined,
            carrierId: ''
        });
    const { permissions } = useUserProfile();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (
            permissions &&
            hasPermission({
                userPermissions: permissions,
                module: 'logistics',
                permissionList: ['write', '*', 'read']
            })
        ) {
            if (theFormData !== null) {
                props.indicateLoading(true);
                const searchResults = await props.fetcher(
                    IDENTIFIER,
                    theFormData
                );
                if (!searchResults) {
                    props.indicateLoading(false);
                    return;
                }

                props.setFrom(theFormData.portFrom ?? '');
                props.setTo(theFormData.portTo ?? '');
                props.setFromDisplay(theFormData.portFromName ?? '');
                props.setToDisplay(theFormData.portToName ?? '');
                const vessel: string = theFormData.vessel ?? '';
                const response: AxiosResponse<FullResponseData> =
                    searchResults as AxiosResponse<FullResponseData>;
                if (response?.data?.data?.succeeded) {
                    props.indicateLoading(false);
                    props.onSearchComplete(
                        IDENTIFIER,
                        searchResults,
                        vessel,
                        theFormData.carrierId
                    );
                } else {
                    props.indicateLoading(false);
                    props.setTheResponseError(
                        response?.data?.data?.errorMessages[0] || ''
                    );
                }
            }
        } else {
            openNotification(
                'error',
                'You do not have access to perform this action'
            );
        }
    };

    const { shippingLines, isLoadingShippingLines } = useShippingLines({});

    const onChangeTo: DatePickerProps['onChange'] = (date) => {
        setTheFormData({
            ...theFormData,
            departureDate: date?.format('YYYY-MM-DD')
        });
    };

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleButtonClick = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const hasUndefinedValue = (obj: getOceanSchedulesPayload): boolean =>
        Object.values(obj).some((value) => value === undefined);

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Box display='grid'>
                    <b>Weeks Ahead</b>
                    <FormControl fullWidth>
                        <Select
                            defaultValue={theFormData?.searchWindow}
                            onChange={(e: SelectChangeEvent) => {
                                setTheFormData({
                                    ...theFormData,
                                    searchWindow: e.target.value
                                });
                            }}
                        >
                            <MenuItem value={'2'}>2 weeks</MenuItem>
                            <MenuItem value={'3'}>3 weeks</MenuItem>
                            <MenuItem value={'4'}>4 weeks</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '2',
            label: (
                <Box display='grid'>
                    <b>Search on</b>
                    <FormControl fullWidth>
                        <RadioGroup
                            row
                            value={theFormData?.direction}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setTheFormData({
                                    ...theFormData,
                                    direction: e.target.value
                                });
                            }}
                        >
                            <FormControlLabel
                                value='D'
                                control={<Radio />}
                                label='Departure'
                            />
                            <FormControlLabel
                                value='A'
                                control={<Radio />}
                                label='Arrival'
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '3',
            label: (
                <Box display='grid'>
                    <b>Shipping Line</b>
                    <FormControl fullWidth>
                        <NewCustomSelect
                            selectValue={theFormData?.vessel}
                            selectOptions={
                                shippingLines as unknown as Record<
                                    string,
                                    unknown
                                >[]
                            }
                            name={'shippingLineId'}
                            updateField={(_, value) => {
                                const shippingLine = shippingLines.filter(
                                    (i: { id: number }) =>
                                        i?.id === Number(value)
                                )[0];
                                setTheFormData({
                                    ...theFormData,
                                    vessel: `${shippingLine?.code}`,
                                    carrierId: `${shippingLine?.id}`
                                });
                            }}
                            selectOptionLabel='name'
                            loading={isLoadingShippingLines}
                        />
                    </FormControl>
                </Box>
            )
        }
    ];

    return (
        <form onSubmit={handleSubmit}>
            <Box
                role={props.role}
                sx={{
                    p: 2,
                    backgroundColor: 'white',
                    borderRadius: 2,
                    pb: 1
                }}
            >
                <Grid
                    container
                    alignItems='center'
                    spacing={0.5}
                    gap={1}
                    justifyContent='space-between'
                >
                    <Grid item xs={5} md={2.5} role='from-address'>
                        <PortsAutocomplete
                            errorCondition={false}
                            helperText=''
                            id='osc--from-address'
                            isSnug={true}
                            label='From'
                            name='from-address'
                            nameValue='from-address'
                            iconPrefix={<LocationOnOutlinedIcon />}
                            updateField={(_, value) => {
                                const { toDisplay, portCode } = value;
                                setTheFormData({
                                    ...theFormData,
                                    portFrom: portCode,
                                    portFromName: toDisplay
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} md={1} lg={1} role='location-reverser'>
                        <Box textAlign='center'>
                            <IconButton>
                                <SwapHorizOutlinedIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                    <Grid item xs={5} md={2.5} role='to-address'>
                        <PortsAutocomplete
                            errorCondition={false}
                            helperText=''
                            id='osc--to-address'
                            isSnug={true}
                            label='To'
                            name='to-address'
                            nameValue='to-address'
                            iconPrefix={<LocationOnOutlinedIcon />}
                            updateField={(_, value) => {
                                const { toDisplay, portCode } = value;
                                setTheFormData({
                                    ...theFormData,
                                    portTo: portCode,
                                    portToName: toDisplay
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} md={2} role='date-selector'>
                        <DatePicker
                            format='DD MMM, YYYY'
                            placeholder='DD/MM/YYYY'
                            onChange={onChangeTo}
                            style={{
                                border: 'none',
                                width: '100%',
                                height: '45px',
                                borderRadius: '3px'
                            }}
                            disabledDate={(current) =>
                                current && current < moment()
                            }
                        />
                    </Grid>
                    <Grid item xs={5} md={2}>
                        <Dropdown
                            menu={{ items }}
                            trigger={['click']}
                            visible={dropdownVisible}
                            overlayStyle={{
                                pointerEvents: 'auto'
                            }}
                            dropdownRender={(menu) => (
                                <div style={contentStyle}>
                                    {React.cloneElement(
                                        menu as React.ReactElement,
                                        { style: menuStyle }
                                    )}
                                </div>
                            )}
                        >
                            <div
                                className='tab-buttons'
                                style={buttonStyle}
                                onClick={handleButtonClick}
                            >
                                <p>Other Parameters</p>
                                <ExpandMoreTwoToneIcon />
                            </div>
                        </Dropdown>
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        <Box textAlign='center'>
                            <Button
                                variant='contained'
                                sx={{
                                    width: '45px',
                                    height: '50px'
                                }}
                                disabled={
                                    theFormData
                                        ? hasUndefinedValue(theFormData)
                                        : true
                                }
                                type='submit'
                            >
                                {props.loadingData ? (
                                    <Spin />
                                ) : (
                                    <SearchOutlinedIcon
                                        sx={{ color: 'white' }}
                                    />
                                )}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

export default PointsSearch;
