import { Stack } from '@mui/material';
import { Table } from 'antd';
import moment from 'moment';
import React, { FC } from 'react';
import { formatNumber } from '../../../../utils/helper';
import { REACT_APP_BASE_URL } from '../../../../config';
import DocumentViewer from '../../../DocumentViewer/DocumentViewer';

interface IProps {
    data: any;
}

const CertificateOfOriginView: FC<IProps> = ({ data }: IProps) => {
    return (
        <>
            <Stack
                direction='row'
                alignItems='start'
                className='top-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='font-semibold text-appcolorprimary'>
                        Exporter
                    </p>
                    <p className='font-medium'>
                        {data?.exporter?.name || '--'}
                    </p>
                    <p>{data?.exporter?.address}</p>

                    <div className='mt-[15px] w-full'>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Bank Name</p>
                            <p className='value'>
                                {data?.exporter?.bankName || '--'}
                            </p>
                        </Stack>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Account No.</p>
                            <p className='value'>
                                {data?.exporter?.bankAccountNumber || '--'}
                            </p>
                        </Stack>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Swift Code</p>
                            <p className='value'>
                                {data?.exporter?.swiftCode || '--'}
                            </p>
                        </Stack>
                    </div>
                </div>
                <div className='view-two'>
                    <p className='font-semibold text-appcolorprimary'>
                        Consignee
                    </p>
                    <p className='font-medium'>{data?.consignee?.name}</p>
                    <p>{data?.consignee?.address}</p>
                    <p>{data?.consignee?.bankCountry}</p>
                    <div className='mt-[15px] w-full'>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Bank Name</p>
                            <p className='value'>
                                {data?.consignee?.bankName || '--'}
                            </p>
                        </Stack>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Account No.</p>
                            <p className='value'>
                                {data?.consignee?.bankAccountNumber || '--'}
                            </p>
                        </Stack>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Swift Code</p>
                            <p className='value'>
                                {data?.consignee.swiftCode || '--'}
                            </p>
                        </Stack>
                    </div>
                </div>

                <div className='view-three'>
                    <div></div>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Invoice No.</p>
                        <p className='value'>
                            {data?.exportInvoiceNumber || '--'}
                        </p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Exporter Invoice Date</p>
                        <p className='value'>
                            {moment(data?.exportInvoiceDate).format('ll') ||
                                '--'}
                        </p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Letter of Credit Number</p>
                        <p className='value'>
                            {data?.letterOfCreditNumber || '--'}
                        </p>
                    </Stack>
                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Buyer Name</p>
                        <p className='value'>{data?.buyer?.name} </p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Buyer Address</p>
                        <p className='value'>{data?.buyer?.address || '--'}</p>
                    </Stack>
                </div>
            </Stack>

            <Stack
                direction='row'
                alignItems='start'
                className='mid-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='title'>Method of Dispatch</p>
                    <p className='value'>{data?.methodOfDispatch || '--'}</p>

                    <p className='title'>Type of Shipment</p>
                    <p className='value'>{data?.typeOfShipment || '--'}</p>

                    <p className='title'>Port of Loading</p>
                    <p className='value'>{data?.portOfLoading?.name || '--'}</p>

                    <p className='title'>Date of Departure</p>
                    <p className='value'>
                        {moment(data?.dateOfDeparture).format('ll')}
                    </p>
                </div>

                <div className='view-one'>
                    <p className='title'>Transport</p>
                    <p className='value'>{data?.transport || '--'}</p>

                    <p className='title'>Voyage Number</p>
                    <p className='value'>{data?.voyageNumber || '--'}</p>

                    <p className='title'>Method of Discharge</p>
                    <p className='value'>
                        {data?.portOfDischarge?.name || '--'}
                    </p>

                    <p className='title'>Final Destination</p>
                    <p className='value'>{data?.finalDestination || '--'}</p>
                </div>

                <div className='view-one'>
                    <p className='title'>Parking Details</p>
                    <p className='value'>{data?.packingDetails || '--'}</p>
                </div>
            </Stack>

            <div className='table-view'>
                <Table
                    rowKey={'id'}
                    columns={[
                        {
                            title: 'Marks & Numbers*',
                            dataIndex: 'itemMarker',
                            key: 'itemMarker'
                        },
                        {
                            title: 'Description',
                            dataIndex: 'descriptionOfGoods',
                            key: 'descriptionOfGoods'
                        },
                        {
                            title: 'Package Kind',
                            dataIndex: 'kindAndNumber',
                            key: 'kindAndNumber'
                        },
                        {
                            title: 'Package Qty',
                            dataIndex: 'unitQuantity',
                            key: 'unitQuantity'
                        },
                        {
                            title: 'Tariff Code',
                            dataIndex: 'tariffCode',
                            key: 'tariffCode'
                        },
                        {
                            title: 'Gross Weight',
                            dataIndex: 'grossWeight',
                            key: 'grossWeight',
                            render: (text) => (
                                <span>{formatNumber(text)} Kg</span>
                            )
                        }
                    ]}
                    dataSource={data?.packing}
                    pagination={false}
                />
            </div>

            <Stack direction='row' justifyContent='flex-end'>
                <div className='preview-total'>
                    Total Weight :{' '}
                    <span className=''>
                        {formatNumber(data?.totalGrossWeight)} Kg
                    </span>
                </div>
            </Stack>

            <Stack
                direction='row'
                alignItems='start'
                className='bottom-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='titl'>Chamber Issue Date</p>
                    <p>
                        {moment(data?.chamberIssuedDate).format('ll') || '--'}
                    </p>
                    <p className='titl'>Chamber Name Of Authorized Signatory</p>
                    <p>{data?.chamberNameOfAuthorisedSignatory || '--'}</p>
                    <p className='titl'>Chamber Signature</p>
                    <div className='h-[50px] w-[100px] img'>
                        <DocumentViewer
                            fileUrl={`${REACT_APP_BASE_URL}/${data?.chamberSignatureUrl.split(
                                '/v1/'
                            )[1]}`}
                        />
                    </div>
                </div>

                <div className='view-two'>
                    <p className='titl'>Chamber Place of Issue</p>
                    <p>{data?.chamberPlaceOfIssue || '--'}</p>

                    <p className='titl'>Place of Issue</p>
                    <p className=''>{data?.exporterPlaceOfIssue || '--'}</p>
                </div>

                <div className='view-three'>
                    <p className='titl'>Issued Date</p>
                    <p className=''>
                        {moment(data?.exporterIssuedDate).format('ll') || '--'}
                    </p>
                    <p className='titl'>Name of Authorized Signatory</p>
                    <p>{data?.exporterNameOfAuthorisedSignatory || '--'}</p>
                    <p className='titl'>Signature</p>
                    <div className='h-[50px] w-[100px] img'>
                        <DocumentViewer
                            fileUrl={`${REACT_APP_BASE_URL}/${data?.exporterSignatureUrl.split(
                                '/v1/'
                            )[1]}`}
                        />
                    </div>
                </div>
            </Stack>
        </>
    );
};

export default CertificateOfOriginView;
