import React from 'react'
// import { customButtonProps } from './custombutton-interface'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton';

import { SxProps } from '@mui/material/styles';

export interface customButtonProps {
  startIcon?: React.ReactNode,
  endIcon?: React.ReactNode,
  buttonText: React.ReactNode,
  isLoadingButton?: boolean,
  loadingPosition?: "center" | "end" | "start" | undefined,
  type?: "button" | "submit" | "reset" | undefined,
  fullWidth?: boolean,
  loading?: boolean,
  variant?: "text" | "outlined" | "contained" | undefined,
  disabled?: boolean,
  styles?: SxProps,
  onClickAction?: () => void,
  size?: "small" | "medium" | "large" | undefined
}

/**
 * Button component
 * @param props customButtonProps
 * @returns tsx
 */
const Custombutton = ({ buttonText, isLoadingButton, loadingPosition, type, fullWidth, loading, variant, disabled, styles, onClickAction, size, startIcon, endIcon }: customButtonProps) => {
  return (
      <>
          {isLoadingButton ? (
              <LoadingButton
                  startIcon={startIcon}
                  endIcon={endIcon}
                  size={size}
                  sx={styles}
                  loadingPosition={loadingPosition}
                  loading={loading}
                  type={type}
                  fullWidth={fullWidth}
                  variant={variant}
                  disabled={disabled}
                  onClick={() => {
                      if (onClickAction !== undefined) {
                          onClickAction();
                      }
                  }}
              >
                  {buttonText}
              </LoadingButton>
          ) : (
              <Button
                  startIcon={startIcon}
                  endIcon={endIcon}
                  size={size}
                  sx={styles}
                  type={type}
                  fullWidth={fullWidth}
                  variant={variant}
                  disabled={disabled}
                  onClick={() => {
                      if (onClickAction !== undefined) {
                          onClickAction();
                      }
                  }}
              >
                  {buttonText}
              </Button>
          )}
      </>
  );
}

export default Custombutton