import { Stack } from '@mui/material';
import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { formatNumber } from '../../../../utils/helper';
import { REACT_APP_BASE_URL } from '../../../../config';
import DocumentViewer from '../../../DocumentViewer/DocumentViewer';

interface IProps {
    data: any;
}
const PackingListView = ({ data }: IProps) => {
    return (
        <>
            {' '}
            <Stack
                direction='row'
                alignItems='start'
                className='top-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='font-semibold text-appcolorprimary'>
                        Exporter
                    </p>
                    <p className='font-medium'>
                        {data?.exporter?.name || '--'}
                    </p>
                    <p>{data?.exporter?.address}</p>
                </div>
                <div className='view-two'>
                    <p className='font-semibold text-appcolorprimary'>
                        Consignee
                    </p>
                    <p className='font-medium'>{data?.consignee?.name}</p>
                    <p>{data?.consignee?.address}</p>
                </div>

                <div className='view-three'>
                    <div></div>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Invoice No.</p>
                        <p className='value'>
                            {data?.exportInvoiceNumber || '--'}
                        </p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Date</p>
                        <p className='value'>
                            {moment(data?.issuedDate).format('ll') || '--'}
                        </p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Reference</p>
                        <p className='value'>{data?.reference || '--'}</p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Buyer Name</p>
                        <p className='value'>{data?.buyer?.name || '--'}</p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Buyer Address</p>
                        <p className='value'>{data?.buyer?.address || '--'}</p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Buyer Reference</p>
                        <p className='value'>{data?.buyerReference || '--'}</p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Bill of Lading Number</p>
                        <p className='value'>
                            {data?.billOfLadingNumber || '--'}
                        </p>
                    </Stack>
                </div>
            </Stack>
            <Stack
                direction='row'
                alignItems='start'
                className='mid-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='title'>Method of Dispatch</p>
                    <p className='value'>{data?.methodOfDispatch || '--'}</p>

                    <p className='title'>Type of Shipment</p>
                    <p className='value'>{data?.typeOfShipment || '--'}</p>

                    <p className='title'>Country Of Origin of Goods</p>
                    <p className='value'>
                        {data?.countryOfOrigin?.name || '--'}
                    </p>
                </div>

                <div className='view-one'>
                    <p className='title'>Transport</p>
                    <p className='value'>{data?.transport || '--'}</p>

                    <p className='title'>Voyage Number</p>
                    <p className='value'>{data?.voyageNumber || '--'}</p>

                    <p className='title'>Country of Final Destination</p>
                    <p className='value'>
                        {data?.countryOfFinalDestination?.name || '--'}
                    </p>
                </div>

                <div className='view-one'>
                    <p className='title'>Place of Origin</p>
                    <p className='value'>{data?.placeOfOrigin || '--'}</p>

                    <p className='title'>Method of Discharge</p>
                    <p className='value'>
                        {data?.portOfDischarge?.name || '--'}
                    </p>

                    <p className='title'>Final Destination</p>
                    <p className='value'>{data?.finalDestination || '--'}</p>
                </div>

                <div className='view-one'>
                    <p className='title'>Port of Loading</p>
                    <p className='value'>{data?.portOfLoading?.name || '--'}</p>

                    <p className='title'>Date of Departure</p>
                    <p className='value'>
                        {moment(data?.dateOfDeparture).format('ll')}
                    </p>
                </div>
            </Stack>
            <Stack
                direction='row'
                alignItems='start'
                className='bottom-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='titl'>Bank Details</p>
                    <p>{data?.bankDetails || '--'}</p>

                    <p className='titl'>Additional Information</p>
                    <p>{data?.additionalInformation}</p>
                </div>

                <div className='view-two'>
                    <p className='titl'>Place of Issue</p>
                    <p className=''>{data?.placeOfIssue || '--'}</p>
                    <p className='titl'>Signatory Company</p>
                    <p>{data?.signatoryCompany || '--'}</p>
                </div>

                <div className='view-three'>
                    <p className='titl'>Due Date</p>
                    <p className=''>
                        {moment(data?.dueDate).format('ll') || '--'}
                    </p>
                    <p className='titl'>Name of Authorized Signatory</p>
                    <p>{data?.nameOfAuthorisedSignatory || '--'}</p>
                    <p className='titl'>Signature</p>
                    <div className='h-[50px] w-[100px] img'>
                        <DocumentViewer
                            fileUrl={`${REACT_APP_BASE_URL}/${data?.signatureUrl.split(
                                '/v1/'
                            )[1]}`}
                        />
                    </div>
                </div>
            </Stack>
            <div className='table-view'>
                <Table
                    rowKey={'id'}
                    columns={[
                        {
                            title: 'Product Code',
                            dataIndex: 'itemMarker',
                            key: 'itemMarker'
                        },
                        {
                            title: 'Description',
                            dataIndex: 'descriptionOfGoods',
                            key: 'descriptionOfGoods'
                        },
                        {
                            title: 'Qty',
                            dataIndex: 'unitQuantity',
                            key: 'unitQuantity',
                            render: (text) => <span>{formatNumber(text)}</span>
                        },
                        {
                            title: 'Package Kind',
                            dataIndex: 'kindAndNumber',
                            key: 'kindAndNumber'
                        },
                        {
                            title: 'Package Qty',
                            dataIndex: 'kindQuantity',
                            key: 'kindQuantity'
                        },
                        {
                            title: 'Net Weight of Package (Kg)',
                            dataIndex: 'netWeight',
                            key: 'netWeight',
                            render: (text) => <span>{formatNumber(text)}</span>
                        },
                        {
                            title: 'Gross Weight of Package (Kg)',
                            dataIndex: 'grossWeight',
                            key: 'grossWeight',
                            render: (text) => <span>{formatNumber(text)}</span>
                        },
                        {
                            title: 'Measurements of Package (m3)',
                            dataIndex: 'volume',
                            key: 'volume',
                            render: (text) => <span>{formatNumber(text)}</span>
                        }
                    ]}
                    dataSource={data?.packing}
                    pagination={false}
                />
            </div>
            <div className='flex justify-end mt-2 '>
                <Stack direction='row' width='100%'>
                    <div
                        className='preview-total flex items-center flex-1'
                        style={{
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            marginTop: '0'
                        }}
                    >
                        Total Unit Qty:{' '}
                        <span>{formatNumber(data?.totalUnitQuantity)}</span>
                    </div>

                    <div
                        className='preview-total flex items-center flex-1'
                        style={{
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            marginTop: '0'
                        }}
                    >
                        Total net weight (kg):{' '}
                        <span>{formatNumber(data?.totalNetWeight)}</span>
                    </div>

                    <div
                        className='preview-total flex items-center flex-1'
                        style={{
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            marginTop: '0'
                        }}
                    >
                        Total gross weight (kg):{' '}
                        <span>{formatNumber(data?.totalGrossWeight)}</span>
                    </div>

                    <div
                        className='preview-total flex items-center flex-1'
                        style={{
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            marginTop: '0'
                        }}
                    >
                        Total volume (m³):{' '}
                        <span>{formatNumber(data?.totalVolume)}</span>
                    </div>
                </Stack>
            </div>
        </>
    );
};

export default PackingListView;
