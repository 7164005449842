import { useEffect, useState } from 'react';
import { queryConfig } from '../api/queryBase';
import { Wallet } from '../pages/dashboard/payments/interfaces/Wallet';
import { SuitUITabContainer } from './SuiteUITabContainer';
import { WalletBalances } from './walletbalances/WalletBalances';

export const AdminWalletBalances = () => {
    const query = queryConfig({});
    const [type, setType] = useState('PROVIDER');
    const { isLoading, data, refetch } = query<Wallet[]>({
        url: '/administrator/balance',
        params: { type },
        cacheKey: 'admin-balance',
        enabled: false
    });
    const tabs = [
        {
            label: 'VertoFX Balance',
            onHandleClick: () => setType('PROVIDER'),
            view: true
        },
        {
            label: 'FrontEdge Balance',
            onHandleClick: () => setType('INTERNAL'),
            view: true
        },
        {
            label: 'Pool Balance',
            onHandleClick: () => setType('POOL'),
            view: true
        }
    ];

    useEffect(() => {
        refetch();
    }, [type]);

    return (
        <>
            <SuitUITabContainer tabs={tabs} />
            <WalletBalances
                wallets={
                    data?.payload.sort(
                        (a, b) => (b.balance || 0) - (a.balance || 0)
                    ) || []
                }
                isLoading={isLoading}
            />
        </>
    );
};
