import { Box, useMediaQuery, useTheme } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Drawer from '@mui/material/Drawer';
import { useState } from 'react';
import { Wallet } from './interfaces/Wallet';
import { Cross } from '../../../components/icons/Cross';
import CustomTextField from '../../../components/CustomTextInput';
import { BankAccounts } from './BankAccounts';

export interface TopupAccountProps {
    wallets: Wallet[];
    closeDrawer: () => void;
    showDrawer: boolean;
    loadingWallets?: boolean;
}

const TopupAccount = ({
    wallets,
    closeDrawer,
    showDrawer
}: TopupAccountProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [selectedWallet, setSelectedWallet] = useState<Wallet>();

    return (
        <div>
            {showDrawer ? (
                <div
                    className='slide-in-right active bg-appcolorwhite rounded-[50%] right-[32rem] top-[1.2rem] fixed p-4 z-[4000] cursor-pointer'
                    onClick={() => {
                        closeDrawer();
                        setSelectedWallet(undefined);
                    }}
                >
                    <Cross />
                </div>
            ) : null}

            <Drawer
                anchor={isMobile ? 'bottom' : 'right'}
                open={showDrawer}
                onClose={() => {
                    closeDrawer();
                    setSelectedWallet(undefined);
                }}
                sx={{
                    '.MuiDrawer-paperAnchorRight': {
                        width: {
                            xs: '95%',
                            lg: '30rem'
                        }
                    }
                }}
            >
                <div className='py-[24px] lg:py-[2.1rem]'>
                    <div className='px-[16px] lg:px-[3.2rem] lg:py-[2.1rem]'>
                        <h1 className='text-base text-ebony lg:text-[24px] lg:leading-[23px] lg:text-appcolorblacktwo lg:font-semibold'>
                            Add Funds
                        </h1>

                        <div className='my-4 mt-10'>
                            <Autocomplete
                                disableClearable
                                disablePortal
                                renderInput={(params) => (
                                    <CustomTextField
                                        params={params}
                                        name='currencies'
                                        helperText={
                                            selectedWallet?.currencyCode ===
                                            'NGN'
                                                ? `Please note a top-up fee of ₦150`
                                                : undefined
                                        }
                                        label='Choose an Account'
                                        placeholder='Select account'
                                    />
                                )}
                                noOptionsText={'No data available'}
                                renderOption={(
                                    props,
                                    { nameAndFlag },
                                    state
                                ) => (
                                    <Box key={state.index}>
                                        <Box component='li' {...props}>
                                            {nameAndFlag}
                                        </Box>
                                    </Box>
                                )}
                                getOptionLabel={(option) =>
                                    option.nameAndFlag ?? ''
                                }
                                onChange={(_, value) => {
                                    if (value !== null) {
                                        setSelectedWallet(value);
                                    }
                                }}
                                options={wallets}
                            />
                            {selectedWallet !== null && (
                                <div className='mt-10'>
                                    {selectedWallet?.currencyCode && (
                                        <BankAccounts
                                            selectedWallet={selectedWallet}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default TopupAccount;
