export const textValue = `
  <h2 style="font-family: serif; font-size: 16px; margin-bottom: 10px;">THE FOLLOWING DOCUMENTS WILL BE PROVIDED BY SELLER:</h2>
  <ul style="margin-top: 0; padding-left: 20px; font-size: 13px;">
    <li style="list-style-type: decimal;">3/3 Full set of original Bill of Lading</li>
    <li style="list-style-type: decimal;">Signed Commercial Invoices</li>
    <li style="list-style-type: decimal;">Packing List</li>
  </ul>
  <br/>
  <h2 style="font-family: serif; font-size: 16px; margin-bottom: 5px;">INSPECTION</h2>

  <p style="margin: 0 0 20px; padding-left: 20px;">Quality, weight &quality at load port as per bills of lading is final binding between both the parties and no claim to be entertained after that.</p>
  <br/>

  <h2 style="font-family: Arial, sans-serif; font-size: 16px; margin-bottom: 5px;">OTHER TERMS AND CONDITIONS:</h2>
  <ul style="margin-top: 0; padding-left: 20px; font-size: 13px;">
    <li>Any amendments and additions to the Contract as well as to the specifications (s) to be valid only if made in written and signed by both parties.</li>
    <li>Third party documents should be acceptable except Invoice and Packing List.</li>
    <li>All bank charges other than sellers bank to buyer's account.</li>
    <li>Duties/ Levies, if any, in the Importing country shall be to the Buyer's account. This shall also include Anti-Dumping, CVD and/ or Safeguard Duties, if any, levied in the importing country.</li>
  </ul>
  <br/>

  <h2 style="font-family: serif; font-size: 16px; margin-bottom: 5px;">CONDITION PRECEDENT:</h2>
  <p style="margin: 0 0 10px; padding-left: 20px; font-size: 13px;">Seller and/or Shipper of the goods and/or carrier of the cargo reserve the right to call upon Buyer and/or Receiver of the cargo to take direct delivery from the performing vessel upon carrier's readiness to discharge directly onto Buyer's and/or Receivers trucks, which shall be positioned alongside the vessel if required by the Seller/shipper/carrier and accept the cargo as fast as the vessel is able to discharge. This would become necessary if and, in the event, it is the custom of the Port, and/or by reason of cargo port congestions preventing discharge of cargo on the quay on the vessel's arrival at the port, and/or as might be directed by the Port Authorities.</p>
  <br/>

  <p style="margin: 0 0 10px; padding-left: 20px; font-size: 13px;">Buyer and/or Receiver shall at all time act in good faith ensuring vessel detention and/or demurrage is avoided in its own interests. Should the performing vessel not be allowed to berth by reason of Buyer's/Receiver's failure to be ready in all respects to receive direct discharge as fast as the vessel is able to discharge, vessel detention charges and/or demurrage shall be for the Buyers account at the market rate for the vessel for the period of any failure to take direct discharge of the cargo in accordance with this clause.</p>
  <br/>

  <h2 style="font-family: serif; font-size: 16px; margin-bottom: 5px;">FORCE MAIEURE</h2>
  <p style="margin: 0 0 10px; padding-left: 20px; font-size: 13px;">The seller shall not be liable for delay or non-performance in whole or in part of their contractual obligation in consequence of war, blockade, revolution, insurrection, civil commotion, riots mobilization, act of Government, act of god, plague or other epidemic, fire, flood, sabotage, quarantine, restriction, explosion or embargo, non-availability of trucks, including any change /modification in commercial laws and regulations by the Government etc.</p>
  <br/>

  <h2 style="font-family: serif; font-size: 16px; margin-bottom: 5px;">ARBITRATION</h2>
  <p style="margin: 0 0 10px; padding-left: 20px; font-size: 13px;">Any dispute arising out of or in connection with this contract, including any question regarding its existence, validity or termination, shall be referred to and finally resolved by arbitration in Lagos under Nigerian Laws and in accordance with the Arbitration Rules of the Nigeria.</p>
  <br/>

  <b style="margin: 0 0 10px; padding-top: 20px; font-size:13px">Kindly sign and return one copy of the above duly stamped as a token of acceptance.</b>
`;
