import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface AppState {
  showMfaModal: boolean;
  mfaAction: (val: string) => void;
  loading?: boolean
}

const initialState: AppState = {
  showMfaModal: false,
  mfaAction: () => {},
  loading: false
}


export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setShowMfaModal: (state, action: PayloadAction<AppState>) => {
      state.showMfaModal = action.payload.showMfaModal,
      state.mfaAction = action.payload.mfaAction
    },
    setMfaLoading: (state, action: PayloadAction<boolean> ) => {
      state.loading = action.payload
    }
  }
})

export const { setShowMfaModal, setMfaLoading } = appSlice.actions

const appReducer = appSlice.reducer

export default appReducer