import React, { useState } from 'react';
import {
    formatDecimalNumber,
    openNotification
} from '../../../../utils/helper';
import {
    Alert,
    Autocomplete,
    CircularProgress,
    Grid,
    MenuItem
} from '@mui/material';
import Custombutton from '../../../custombutton/custombutton';
import Quotefield from '../../../../pages/dashboard/tradedocuments/quotation/quotefield/quotefield';
import Documentupload from '../../../documentupload/documentupload';
import Customdatepicker from '../../../customdatepicker/customdatepicker';
import NewCustomSelect from '../../../newcustomselect/newcustomselect';
import { createCommercialInvoice } from '../../../../api/documents/documents';
import CustomTextField from '../../../CustomTextInput';

interface IProps {
    filterInvoiceOptions?: boolean;
    currencies?: any;
    loadingCurrencies?: boolean;
    updateField?: any;
    errorsCom?: any;
    error?: any;
    setError?: any;
    payloadCom?: any;
    quoteFields?: any;
    updateQuoteField?: unknown | any;
    addQuoteField?: unknown | any;
    corporatePartners?: any;
    removeQuoteField?: unknown | any;
    totalAmount?: any;
    closeVisibleModal?: any;
    loadingCorporatepartners?: boolean;
    setShowAddPartner?: any;
}

const UploadInvoice = ({
    corporatePartners,
    totalAmount,
    closeVisibleModal,
    currencies,
    loadingCurrencies,
    updateField,
    errorsCom,
    error,
    setError,
    payloadCom,
    quoteFields,
    updateQuoteField,
    addQuoteField,
    removeQuoteField,
    loadingCorporatepartners,
    setShowAddPartner
}: IProps) => {
    const [loading, setLoading] = useState(false);

    const generalSubmit = async () => {
        try {
            setLoading(true);
            setError(null);

            const { dueDate, billOfLadingNumber, consigneeName, ...rest } =
                payloadCom;

            const items: any[] = [];

            quoteFields.forEach((item: unknown | any) => {
                items.push({
                    amount: item.price,
                    descriptionOfGoods: item.desc,
                    productCode: '',
                    quantity: item.qty,
                    unitPrice: item.price,
                    unitType: item.type
                });
            });

            await createCommercialInvoice({
                createdType: 'UPLOADED',
                documentData: {
                    items,
                    consigneeName,
                    billOfLadingNumber,
                    totalAmount,
                    dueDate,
                    salesContractNumber: rest.salesContractNumber,
                    invoiceNumber: rest.invoiceNumber,
                    consigneeId: rest.consigneeId,
                    currencyCode: rest.currencyCode
                },
                documentType: 'COMMERCIAL_INVOICE',
                documentName: rest.documentName,
                documentUrl: rest.documentUrl
            });

            openNotification('success', 'Document created successfully');

            closeVisibleModal();
        } catch (error) {
            setLoading(false);
            if (error?.response) {
                if (error?.response?.data?.error) {
                    let hasFieldNameAttribute = false;

                    error?.response?.data?.error.map((item: any) => {
                        return Object.keys(item).map((itemInside: string) => {
                            if (itemInside === 'fieldName') {
                                hasFieldNameAttribute = true;

                                return;
                            }
                        });
                    });

                    if (hasFieldNameAttribute) {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>
                                    {element.messageError}
                                </li>
                            );
                        });
                        setError(errorList);
                        console.log(errorList);
                    } else {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>{element}</li>
                            );
                        });
                        setError(errorList);
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    return (
        <div className='mt-4'>
            {error !== null && (
                <div className='mt-10'>
                    <Alert severity='error'>{error}</Alert>
                </div>
            )}
            <div className=' w-[95%] mx-auto lg:w-full'>
                <CustomTextField
                    value={payloadCom?.documentName}
                    error={errorsCom?.documentName.length > 0}
                    helperText={
                        errorsCom?.documentName.length > 0
                            ? errorsCom?.documentName
                            : undefined
                    }
                    required
                    id='existingbooking-documentName'
                    label='Name Tag'
                    name='documentName'
                    onChange={(e: any) => {
                        updateField('documentName', e.target.value);
                    }}
                />
            </div>

            <div className='lg:mt-4 mt-2 w-[95%] mx-auto lg:w-full'>
                <CustomTextField
                    value={payloadCom?.salesContractNumber}
                    error={errorsCom?.salesContractNumber.length > 0}
                    helperText={
                        errorsCom?.salesContractNumber.length > 0
                            ? errorsCom?.salesContractNumber
                            : undefined
                    }
                    id='existingbooking-salesContractNumber'
                    label={'Sales Contract Number (Optional)'}
                    name='salesContractNumber'
                    onChange={(e: any) => {
                        updateField('salesContractNumber', e.target.value);
                    }}
                />
            </div>
            <div className='lg:mt-4 mt-2 w-[95%] mx-auto lg:w-full'>
                <CustomTextField
                    value={payloadCom?.billOfLadingNumber}
                    error={errorsCom?.billOfLadingNumber.length > 0}
                    helperText={
                        errorsCom?.billOfLadingNumber.length > 0
                            ? errorsCom?.billOfLadingNumber
                            : undefined
                    }
                    id='existingbooking-salesContractNumber'
                    label='BL Number (Optional)'
                    name='billOfLadingNumber'
                    onChange={(e: any) => {
                        updateField('billOfLadingNumber', e.target.value);
                    }}
                />
            </div>
            <div className='mt-4 lg:w-full w-[95%] mx-auto'>
                <Documentupload
                    useAuthorization
                    returnURLOnly
                    title={'Document'}
                    afterUploadUpdate={(
                        itemId: number,
                        documentUrl: string
                    ) => {
                        updateField('documentUrl', documentUrl);
                    }}
                    full={true}
                />
            </div>
            <div className=' w-[95%] mx-auto lg:w-full lg:border'>
                <Grid
                    container
                    spacing={1}
                    sx={{
                        marginTop: 0,
                        marginBottom: '1rem'
                    }}
                    className=' lg:px-[1rem]'
                >
                    <Grid item xs={12} md={12} lg={4} mt={{ lg: 0, sm: 1 }}>
                        <div className='mt-2'>
                            <Customdatepicker
                                value={payloadCom?.dueDate || null}
                                id='proformainvoice-date'
                                label='Due Date'
                                name='dueDate'
                                required
                                updateField={updateField}
                                error={errorsCom?.dueDate.length > 0}
                                helperText={
                                    errorsCom?.dueDate.length > 0
                                        ? errorsCom?.dueDate
                                        : undefined
                                }
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12} lg={4}>
                        <div className='mt-4'>
                            <NewCustomSelect
                                hasError={errorsCom?.consigneeId.length > 0}
                                helperText={
                                    errorsCom?.consigneeId.length > 0
                                        ? errorsCom?.consigneeId
                                        : undefined
                                }
                                selectValue={payloadCom.consigneeId}
                                required
                                selectOptions={corporatePartners}
                                name={'consigneeId'}
                                updateField={(_, value) => {
                                    updateField('consigneeId', value);
                                    updateField(
                                        'consigneeName',
                                        corporatePartners.filter(
                                            (item: any) => item?.id === value
                                        )[0]?.name
                                    );
                                }}
                                label={'Customer'}
                                selectOptionLabel='name'
                                loading={loadingCorporatepartners}
                                dropdownRender={() => {
                                    return (
                                        <div className='px-4 border-t border-appcolorgreyfaint'>
                                            <p
                                                className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                onClick={() => {
                                                    setShowAddPartner(true);
                                                }}
                                            >
                                                + Add a New Partner
                                            </p>
                                        </div>
                                    );
                                }}
                            />
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12} lg={4} mt={2}>
                        <CustomTextField
                            error={errorsCom?.invoiceNumber?.length > 0}
                            helperText={
                                errorsCom?.invoiceNumber?.length > 0
                                    ? errorsCom?.invoiceNumber
                                    : undefined
                            }
                            required
                            id='proformainvoice-invoiceNumber'
                            label='Invoice Number'
                            name='invoiceNumber'
                            onChange={(e: any) => {
                                updateField('invoiceNumber', e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>

                {quoteFields.map((item: any, index: number) => (
                    <Quotefield
                        fromUpload
                        key={item.id}
                        index={index}
                        id={item.id}
                        updateQuoteField={updateQuoteField}
                        price={item.price}
                        qty={item.qty}
                        removeQuoteField={removeQuoteField}
                    />
                ))}

                <div className='lg:border border-appcolorgreyfaint px-[1.5rem] pt-4 pb-4'>
                    <div
                        className='flex items-center cursor-pointer w-fit'
                        onClick={() => {
                            addQuoteField();
                        }}
                    >
                        <div>
                            <img
                                src='/img/vectors/primarydownload.svg'
                                alt='primarydownload'
                                className='w-6'
                            />
                        </div>
                        <span className='pl-2 text-appcolorprimary'>
                            Add Field
                        </span>
                    </div>
                </div>

                <div className='lg:border border-t border-appcolorgreyfaint lg:px-[1.5rem] lg:pt-[0.1rem] pb-4 flex justify-end gap-2 items-end'>
                    <div className='lg:w-[20%] w-[40%]'>
                        <Autocomplete
                            value={payloadCom.currencyCodeValue}
                            placeholder='Currency'
                            disableClearable
                            id='send-currency'
                            sx={{
                                marginTop: '1rem',
                                '.MuiInputBase-input': {
                                    height: '0.3rem'
                                }
                            }}
                            renderInput={(params: any) => (
                                <CustomTextField
                                    {...params}
                                    value={payloadCom.currencyCode}
                                    label='Select Currency'
                                    placeholder='Currency'
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: loadingCurrencies && (
                                            <React.Fragment>
                                                <CircularProgress size={20} />
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            )}
                            noOptionsText={'No data available'}
                            renderOption={(props, option) => (
                                <MenuItem
                                    {...props}
                                    value={option.nameAndFlag}
                                    key={`countries-${option.id}`}
                                >
                                    {option.nameAndFlag}
                                </MenuItem>
                            )}
                            getOptionLabel={(option) => option.nameAndFlag}
                            onChange={(event: any, value: any) => {
                                if (value !== null) {
                                    updateField(
                                        'currencyCode',
                                        value.currencyCode
                                    );
                                    updateField('currencyCodeValue', value);
                                }
                            }}
                            options={currencies}
                        />
                    </div>
                    <div className='lg:w-[20%] w-[40%]'>
                        <CustomTextField
                            disabled
                            id='commercialinvoice-totalAmount'
                            label='Total Amount'
                            name='totalAmount'
                            value={formatDecimalNumber(totalAmount)}
                        />
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <Custombutton
                    onClickAction={generalSubmit}
                    isLoadingButton
                    loading={loading}
                    type='submit'
                    fullWidth
                    variant='contained'
                    styles={{
                        padding: '0.75rem 0.62rem 0.9rem 0.93rem'
                    }}
                    disabled={
                        Object.values(payloadCom).some(
                            (item: any) => item === undefined
                        ) ||
                        Object.values(errorsCom).some(
                            (item: any) => item.length > 0
                        ) ||
                        loading
                    }
                    buttonText='Submit Invoice'
                />
            </div>
        </div>
    );
};

export default UploadInvoice;
