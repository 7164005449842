import { DedicatedAccountCredential } from '../pages/dashboard/payments/dedicatedAccounts/interfaces/DedicatedAccounts';
import { useGetQuery } from './useGetQuery';

export const useOffshoreAccountCredentials = (corporateId?: string) => {
    const { isLoading, entries, isFetching } = useGetQuery<
        DedicatedAccountCredential
    >({
        url: `/administrator/corporates/${corporateId}/offshore/account`,
        cacheKey: `wallet-credentials-${corporateId}`
    });

    return {
        isLoading,
        entries,
        isFetching
    };
};
