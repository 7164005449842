import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import { useNavigate, useParams } from 'react-router-dom';
import { verifyEmail } from '../../api/base/base';
import Authouterpage from '../../components/authouterpage/authouterpage';
import Custombutton from '../../components/custombutton/custombutton';
import { openNotification } from '../../utils/helper';

const Verifyemail = () => {
    const navigate = useNavigate();
    const { verificationCode } = useParams();
    const [loading, setLoading] = useState(false);

    /**
     * Veriifies the user account
     */
    const runVerification = async () => {
        setLoading(true);
        try {
            await verifyEmail({
                verificationCode: verificationCode || ''
            });
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            if (error?.response?.data) {
                const errors = error?.response?.data?.error;
                openNotification(
                    'error',
                    `${errors || 'Something went wrong.'}`
                );
            } else {
                openNotification('error', 'Something went wrong.');
            }
            navigate('/');
        }
    };

    useEffect(() => {
        runVerification();
    }, []);

    return (
        <div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <img
                    src='/img/vectors/loadingcircles.svg'
                    alt='loading'
                    className='animate-spin'
                />
            </Backdrop>

            <Authouterpage
                title='Verify Email'
                info='Lets verify your email'
                infoText='Verify email'
            >
                <div>
                    <div className='bg-appcolorwhite py-[2.7rem] lg:py-[3.5rem] lg:px-[2.6rem] px-[1.6rem] mb-[1.75rem] rounded-[3px]'>
                        <div className='flex justify-center text-center'>
                            <img
                                src='/img/vectors/mailbox.png'
                                alt='mailbox'
                                className='h-[74.06px] mb-[0.5rem]'
                            />
                        </div>

                        {loading ? (
                            <>
                                <p className='text-base mb-[0.6rem] text-center'>
                                    Email verification in progress...
                                </p>
                            </>
                        ) : (
                            <>
                                <p className='text-base mb-[0.6rem] text-center text-appcolorprimary'>
                                    Your email verification process was
                                    successful.
                                </p>

                                <Custombutton
                                    onClickAction={() => {
                                        navigate('/login');
                                    }}
                                    loading={false}
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    styles={{
                                        padding: '0.75rem 0.62rem 1rem 0.93rem'
                                    }}
                                    disabled={false}
                                    buttonText='Log in to your account'
                                />
                            </>
                        )}
                    </div>
                </div>
            </Authouterpage>
        </div>
    );
};

export default Verifyemail;
