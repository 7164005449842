import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SalesContainer } from '../../../../styles/SalesStyles';
import { Backdrop, Stack } from '@mui/material';
import { backBtn } from '../contracts/ContractDetails';
import { formatCurrency, getInvoiceStatus } from '../../../../utils/helper';
import InvoiceDetailsSummary from '../../../../components/dashboard/sales/invoice/InvoiceDetailsSummary';
import InvoiceDetailsTable from '../../../../components/dashboard/sales/invoice/InvoiceDetailsTable';
import { useGetQuery } from '../../../../customhooks/useGetQuery';
import { Invoice } from '../../payments/interfaces/Invoice';
import { Booking } from '../../bookings/interfaces/Booking';
import { SalesDocument } from '../../payments/interfaces/SalesDocuments';

const InvoiceDetails = () => {
    const { reference } = useParams();
    const [activeView, setActiveView] = useState('Invoice Line Items');
    const navigate = useNavigate();
    const { entries: paymentData } = useGetQuery<Invoice>({
        url: `/invoice/payment/${reference}`,
        cacheKey: 'invoice-payments',
        enabled: Boolean(reference)
    });

    const { entries: bookingData } = useGetQuery<{ content: Booking[] }>({
        url: `/invoice/booking/${reference}`,
        cacheKey: 'invoice-booking',
        enabled: Boolean(reference)
    });

    const {
        isLoading: isDetailsLoading,
        entries: data,
        isError: isDetailsError
    } = useGetQuery<SalesDocument>({
        url: `/sales/documents/${Number(reference)}`,
        cacheKey: `sales-document-details-${reference}`,
        enabled: Boolean(reference)
    });

    return (
        <>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={isDetailsLoading}
            />
            <div className='min-h-screen bg-white lg:bg-transparent'>
                <SalesContainer>
                    <div className='page-header hidden lg:flex'>
                        <p className='back-btn' onClick={() => navigate(-1)}>
                            {backBtn} Back to Invoices
                        </p>
                    </div>
                    <div className='px-4 mt-4 lg:hidden'>
                        <p
                            className='back-btn flex gap-3 text-[20px] items-center'
                            onClick={() => navigate(-1)}
                        >
                            {backBtn} Invoices
                        </p>
                    </div>

                    {data && (
                        <InvoiceDetailsSummary details={data.documentData} />
                    )}

                    <div className='w-full lg:hidden'>
                        <div className='px-4 pt-8 pb-10 bg-appcolorgrey'>
                            <h4 className=' text-[16px] font-bold border-b-[1px] border-appcolorfaintnine pb-2'>
                                Invoice Details
                            </h4>

                            <Stack
                                width='100%'
                                direction='row'
                                className='flex-row flex justify-between py-[10px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                            >
                                <p className='text-appcolorblacktwo'>
                                    Sales Contract{' '}
                                </p>

                                <p className='font-medium'>
                                    {data?.documentData?.salesContractNumber ||
                                        '--'}
                                </p>
                            </Stack>

                            <Stack
                                width='100%'
                                direction='row'
                                className='flex-row flex justify-between py-[10px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                            >
                                <p className='text-appcolorblacktwo'>
                                    Total invoice amount{' '}
                                </p>

                                <p className='font-medium'>
                                    {formatCurrency(
                                        data?.documentData?.totalAmount || 0,
                                        data.documentData?.currencyCode ?? 'NGN'
                                    )}
                                </p>
                            </Stack>

                            <Stack
                                width='100%'
                                direction='row'
                                className='flex-row flex justify-between py-[10px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                            >
                                <p className='text-appcolorblacktwo'>
                                    Balance Due{' '}
                                </p>

                                <p className='font-medium'>
                                    {formatCurrency(
                                        data?.documentData?.totalAmount -
                                            data?.documentData?.totalPaid || 0,
                                        data?.documentData?.currencyCode ??
                                            'NGN'
                                    )}{' '}
                                </p>
                            </Stack>

                            <Stack
                                width='100%'
                                direction='row'
                                className='flex-row flex justify-between py-[10px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                            >
                                <p className='text-appcolorblacktwo'>
                                    Payment Terms{' '}
                                </p>

                                <p className='font-medium'>
                                    {data?.documentData?.termsOfPayment || '--'}
                                </p>
                            </Stack>

                            <Stack
                                width='100%'
                                direction='row'
                                className='flex-row flex justify-between pt-[10px] text-[14px]'
                            >
                                <p className='text-appcolorblacktwo'>Status </p>

                                <p className='font-medium'>
                                    {getInvoiceStatus(
                                        data?.documentData?.status
                                    ) || '--'}
                                </p>
                            </Stack>
                        </div>
                    </div>
                    <InvoiceDetailsTable
                        {...{
                            setActiveView,
                            activeView,
                            loadingData: isDetailsLoading,
                            bookingData,
                            paymentData
                        }}
                        data={data.documentData}
                    />
                </SalesContainer>
            </div>
        </>
    );
};

export default InvoiceDetails;
