import { Stack } from '@mui/material';
import { Table } from 'antd';
import moment from 'moment';
import React, { FC } from 'react';
import { formatCurrency, formatNumber } from '../../../../utils/helper';
import { REACT_APP_BASE_URL } from '../../../../config';
import { getColumns } from './tableData/tableData';
import DocumentViewer from '../../../DocumentViewer/DocumentViewer';

interface CustomTableProps {
    data: any;
    docType: string;
    tableData?: any;
}

const QuotationTop: FC<CustomTableProps> = ({ data, docType }) => {
    const getTotal = () => {
        let totalNumber = 0;

        data?.items?.forEach((item: any) => {
            totalNumber +=
                (isNaN(item?.unitPrice) ? 0 : Number(item?.unitPrice)) *
                (isNaN(item?.quantity) ? 0 : Number(item?.quantity));
        });
        return totalNumber;
    };

    const dataSource = data?.items || [
        {
            key: '1',
            containerNumber: data?.containerNumber,
            containerTare: data?.containerTare,
            cargoWeight: data?.cargoWeight,
            grossWeight: data?.grossWeight
        }
    ];

    const columns = getColumns(docType, data?.currencyCode);

    return (
        <>
            {docType === 'DRAFT_GROSS_MASS_DECLARATION' ? (
                <Stack
                    direction='row'
                    alignItems='start'
                    className='top-preview-content'
                    width='full'
                    justifyContent={'space-between'}
                >
                    <div className='view-one'>
                        <p className='font-semibold text-appcolorprimary'>
                            Shipper
                        </p>
                        <p className='font-medium'>{data?.shipper?.name}</p>
                        <p>{data?.shipper?.address}</p>
                        <p>{data?.shipper?.country}</p>
                    </div>
                    <div className='view-three'>
                        <div></div>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                            alignItems='flex-end'
                        >
                            <p className='title'>Shipper Reference.</p>
                            <p className='value'>
                                {data?.shipperReference || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                            alignItems='flex-end'
                        >
                            <p className='title'>Shipper Address.</p>
                            <p className='value'>
                                {data?.shipper?.address || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                            alignItems='flex-end'
                        >
                            <p className='title'>Certificate Number.</p>
                            <p className='value'>
                                {data?.certificateNumber || '--'}
                            </p>
                        </Stack>
                    </div>
                </Stack>
            ) : (
                <Stack
                    direction='row'
                    alignItems='start'
                    className='top-preview-content'
                    width='full'
                    justifyContent={'space-between'}
                >
                    <div className='view-one'>
                        <p className='font-semibold text-appcolorprimary'>
                            Invoice From
                        </p>
                        <p className='font-medium'>{data?.seller?.name}</p>
                        <p>{data?.seller?.address}</p>
                    </div>

                    <div className='view-two'>
                        <p className='font-semibold text-appcolorprimary'>
                            Invoice To
                        </p>
                        <p className='font-medium'>{data?.receiver?.name}</p>
                        <p>{data?.receiver?.address}</p>
                    </div>

                    {docType === 'QUOTATION' && (
                        <div className='view-three'>
                            <div></div>
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Quote No.</p>
                                <p className='value'>
                                    {data?.quoteNumber || '--'}
                                </p>
                            </Stack>
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Issued Date</p>
                                <p className='value'>
                                    {moment(data?.issuedDate).format('ll') ||
                                        '--'}
                                </p>
                            </Stack>
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Port of Loading</p>
                                <p className='value'>
                                    {data?.portOfLoading?.name || '--'}
                                </p>
                            </Stack>

                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Method of Dispatch</p>
                                <p className='value'>
                                    {data?.methodOfDispatch || '--'}
                                </p>
                            </Stack>

                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Port of Discharge</p>
                                <p className='value'>
                                    {data?.portOfDischarge?.name || '--'}
                                </p>
                            </Stack>

                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Type of Shipment</p>
                                <p className='value'>
                                    {data?.typeOfShipment || '--'}
                                </p>
                            </Stack>
                        </div>
                    )}

                    {docType === 'PURCHASE_ORDER' && (
                        <div className='view-three'>
                            <div></div>
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Purchase Order No.</p>
                                <p className='value'>
                                    {data?.purchaseOrderNumber || '--'}
                                </p>{' '}
                            </Stack>
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Issued Date</p>
                                <p className='value'>
                                    {moment(data?.issuedDate).format('ll') ||
                                        '--'}
                                </p>
                            </Stack>
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Supplier Reference</p>
                                <p className='value'>
                                    {data?.supplierReference || '--'}
                                </p>
                            </Stack>

                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Shipper Reference</p>
                                <p className='value'>
                                    {data?.shipperReference || '--'}
                                </p>
                            </Stack>

                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Method of Dispatch</p>
                                <p className='value'>
                                    {data?.methodOfDispatch || '--'}
                                </p>
                            </Stack>

                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Type of Shipment</p>
                                <p className='value'>
                                    {data?.typeOfShipment || '--'}
                                </p>
                            </Stack>

                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Port of Loading</p>
                                <p className='value'>
                                    {data?.portOfLoading?.name || '--'}
                                </p>
                            </Stack>

                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Port of Discharge</p>
                                <p className='value'>
                                    {data?.portOfDischarge?.name || '--'}
                                </p>
                            </Stack>
                        </div>
                    )}
                </Stack>
            )}
            <div className='table-view'>
                <Table
                    rowKey='id'
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                />
            </div>

            <Stack direction='row' justifyContent='flex-end'>
                {docType === 'DRAFT_GROSS_MASS_DECLARATION' ? (
                    <div className='preview-total'>
                        Total Weight :{' '}
                        <span className=''>
                            {formatNumber(data?.grossWeight || 0)} Kg
                        </span>
                    </div>
                ) : (
                    <div className='preview-total'>
                        Total amount :{' '}
                        <span className=''>
                            {formatCurrency(getTotal(), data?.currencyCode)}
                        </span>
                    </div>
                )}
            </Stack>

            <Stack
                direction='row'
                alignItems='start'
                className='bottom-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='titl'>Additional Information</p>
                    <p>{data?.additionalInformation}</p>
                </div>

                <div className='view-two'>
                    <p className='titl'>Place of Issue</p>
                    <p className=''>{data?.placeOfIssue || '--'}</p>
                    <p className='titl'>Signatory Company</p>
                    <p>{data?.signatoryCompany || '--'}</p>
                </div>

                <div className='view-three'>
                    <p className='titl'>Date of Issue</p>
                    <p className=''>
                        {moment(data?.dateOfIssue).format('ll') || '--'}
                    </p>
                    <p className='titl'>Name of Authorized Signatory</p>
                    <p>{data?.nameOfAuthorisedSignatory || '--'}</p>
                    <p className='titl'>Signature</p>

                    <div className='h-[50px] w-[100px] img'>
                        <DocumentViewer
                            fileUrl={`${REACT_APP_BASE_URL}/${data?.signatureUrl.split(
                                '/v1/'
                            )[1]}`}
                        />
                    </div>
                </div>
            </Stack>
        </>
    );
};

export default QuotationTop;
