export const EmptyWalletIcon = ({ color }: { color?: string }) => {
    return (
        <svg
            width='16'
            height='17'
            viewBox='0 0 16 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12.1687 9.42497C11.8987 9.68855 11.7444 10.0678 11.783 10.4728C11.8409 11.1671 12.4773 11.675 13.1716 11.675H14.393V12.44C14.393 13.7707 13.3066 14.8571 11.9759 14.8571H4.59585C3.26514 14.8571 2.17871 13.7707 2.17871 12.44V8.11356C2.17871 6.78285 3.26514 5.69641 4.59585 5.69641H11.9759C13.3066 5.69641 14.393 6.78285 14.393 8.11356V9.03928H13.0944C12.7344 9.03928 12.4066 9.18069 12.1687 9.42497Z'
                stroke='#344054'
                strokeWidth='1.28571'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M2.17871 8.69212V5.7543C2.17871 4.9893 2.648 4.30784 3.36157 4.03784L8.46585 2.10927C9.263 1.80713 10.118 2.39858 10.118 3.25358V5.69643'
                stroke='#344054'
                strokeWidth='1.28571'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15.0737 9.69499V11.0193C15.0737 11.3729 14.7908 11.6621 14.4308 11.675H13.1708C12.4765 11.675 11.8401 11.1672 11.7822 10.4729C11.7437 10.0679 11.8979 9.68858 12.1679 9.42501C12.4058 9.18072 12.7337 9.03931 13.0937 9.03931H14.4308C14.7908 9.05216 15.0737 9.34142 15.0737 9.69499Z'
                stroke='#344054'
                strokeWidth='1.28571'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M5.07153 8.42847H9.57153'
                stroke='#344054'
                strokeWidth='1.28571'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
