import React, { useRef, useState } from 'react';
import { DocumentTableContainer } from '../../../styles/TradeDocStyles';
import { Dropdown, Table } from 'antd';
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import { getAnythingElseStatus } from '../../../utils/helper';
import CreatedDocumentPreview from '../tradeDocuments/CreatedDocumentPreview';
import { useReactToPrint } from 'react-to-print';
import {
    AssociatedDocumentProps,
    ColumnsDocumentTypes,
    SingleBookingColumnsTypes
} from './DocumentTypes';
import Customtable from '../../customtable/customtable';

const AssociatedDocumentTable = ({
    setCurrentData,
    items,
    showContainers,
    loading,
    containers,
    documents,
    docItems,
    visibleDocument,
    setCurrentDocData,
    setVisibleDocument,
    currentDocData
}: AssociatedDocumentProps) => {
    const columnsDocuments: ColumnsType<ColumnsDocumentTypes> = [
        {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (_, { createdDate }) => (
                <span>{moment(parseInt(createdDate)).format('ll')}</span>
            )
        },
        {
            title: 'Document Name',
            dataIndex: 'documentName',
            key: 'documentName',
            render: (text) => (
                <span className='text-appcolorprimary'>{text}</span>
            )
        },
        {
            title: 'Sales Contract Number',
            dataIndex: 'salesContractNumber',
            key: 'salesContractNumber',
            render: (text) => <span className=''>{text}</span>
        },
        {
            title: 'Document Type',
            dataIndex: 'documentType',
            key: 'documentType',
            render: (text) => <span>{text.split('_').join(' ')}</span>
        },
        {
            title: 'Type',
            dataIndex: 'createdType',
            key: 'createdType',
            render: (text) => <span className=''>{text}</span>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text) => (
                <span className=''>{getAnythingElseStatus(text)}</span>
            )
        },
        {
            title: '',
            render: (_, data) => (
                <div
                    className='cursor-pointer'
                    onClick={() => {
                        setCurrentDocData(data);
                    }}
                >
                    <Dropdown menu={{ items: docItems }} trigger={['click']}>
                        <img src='/img/vectors/more.svg' alt='more' />
                    </Dropdown>
                </div>
            )
        }
    ];

    const columns: ColumnsType<SingleBookingColumnsTypes> = [
        {
            title: 'ETA Loading',
            dataIndex: 'etaLoading',
            key: 'etaLoading',
            render: (_, { etaLoading }) => (
                <span>{moment(etaLoading).format('ll')}</span>
            )
        },
        {
            title: 'ETA Destination',
            dataIndex: 'etaDestination',
            key: 'etaDestination',
            render: (_, { etaDestination }) => (
                <span>{moment(etaDestination).format('ll')}</span>
            )
        },
        {
            title: 'Container No.',
            dataIndex: 'number',
            key: 'number',
            render: (text) => (
                <span className='text-appcolorprimary'>{text}</span>
            )
        },
        {
            title: 'Status',
            dataIndex: 'state',
            key: 'state	'
        },
        {
            title: '',
            render: (_, data) => (
                <div
                    className='cursor-pointer'
                    onClick={() => {
                        setCurrentData(data);
                    }}
                >
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <img src='/img/vectors/more.svg' alt='more' />
                    </Dropdown>
                </div>
            )
        }
    ];

    const [downloadLoading, setDownloadLoading] = useState(false);
    const myDiv = useRef<HTMLDivElement | null>(null);

    const handlePrint = useReactToPrint({
        content: () => myDiv.current
    });
    const closeCurrentDocModal = () => {
        setVisibleDocument(false);
    };
    return (
        <DocumentTableContainer className='rounded-[3px] bg-appcolorwhite'>
            {showContainers ? (
                <>
                    <div className='hidden lg:block'>
                        <Table
                            scroll={{ x: 1000 }}
                            loading={loading}
                            columns={columns}
                            dataSource={containers}
                            locale={{
                                emptyText: () => {
                                    return (
                                        <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                                            <div className='flex justify-center mb-4'>
                                                <img
                                                    src='/img/vectors/nodatacross.svg'
                                                    alt='nodata'
                                                />
                                            </div>
                                            <p className='text-center'>
                                                No booking container detail
                                            </p>
                                        </div>
                                    );
                                }
                            }}
                        />
                    </div>

                    <div className='lg:hidden'>
                        <Customtable
                            data={containers}
                            mobileComponent={<p></p>}
                            emptyText='No booking container detail'
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className='hidden lg:block'>
                        <Table
                            scroll={{ x: 1000 }}
                            loading={loading}
                            columns={columnsDocuments}
                            dataSource={documents}
                            locale={{
                                emptyText: () => {
                                    return (
                                        <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                                            <div className='flex justify-center mb-4'>
                                                <img
                                                    src='/img/vectors/nodatacross.svg'
                                                    alt='nodata'
                                                />
                                            </div>
                                            <p className='text-center'>
                                                No booking document detail
                                            </p>
                                        </div>
                                    );
                                }
                            }}
                        />
                    </div>
                    <div className='lg:hidden'>
                        <Customtable
                            data={documents}
                            mobileComponent={<p></p>}
                            emptyText='No booking document detail'
                        />
                    </div>
                </>
            )}
            {currentDocData?.createdType === 'CREATED' && (
                <CreatedDocumentPreview
                    currentData={currentDocData}
                    {...{
                        closeCurrentDocModal,
                        visibleDocument,
                        myDiv,
                        setDownloadLoading,
                        downloadLoading,
                        handlePrint
                    }}
                />
            )}
        </DocumentTableContainer>
    );
};

export default AssociatedDocumentTable;
