
import { GetTransactionsParams, Transactions } from '../pages/dashboard/payments/interfaces/Transactions';
import { useGetQuery } from './useGetQuery';
import { useQueryClient } from 'react-query';

const defaultParams: GetTransactionsParams = {
    page: 1,
    size: 10
};

export const useTransactions = (initialParams?: GetTransactionsParams) => {
    const queryClient = useQueryClient();

    const params = initialParams || defaultParams;

    const {
        isLoading,
        entries: transactionsList,
        isFetching
    } = useGetQuery<Transactions[]>({
        url: '/wallet/transaction',
        cacheKey: `transactions`,
        params: { ...params }
    });

    const invalidateTransactionsCache = () => {
        queryClient.invalidateQueries({ queryKey: ['transactions', params] });
    };

    return {
        isLoading,
        transactionsList,
        invalidateTransactionsCache,
        isFetching
    };
};
