import { ReactNode, useContext } from "react"
import { FeatureFlags } from "../context/feature-flags"

interface IProps {
    flag: string
    children: ReactNode
}

const EnablingFeature = (props: IProps) => {
    const { features } = useContext(FeatureFlags)
    return (
        <>
            { features[props.flag] && (props.children) }
        </>
    )
}

const isEnabled = (flag: string) => {
    const { features } = useContext(FeatureFlags)
    return features[flag]
}

export default EnablingFeature
export { isEnabled }
