export const TickSquareIcon = () => (
    <svg
        width='24'
        height='25'
        viewBox='0 0 24 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M9 22.085H15C20 22.085 22 20.085 22 15.085V9.08496C22 4.08496 20 2.08496 15 2.08496H9C4 2.08496 2 4.08496 2 9.08496V15.085C2 20.085 4 22.085 9 22.085Z'
            stroke='#A2E1C1'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.75 12.085L10.58 14.915L16.25 9.25497'
            stroke='#A2E1C1'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
