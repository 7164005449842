export const MoreVerticalIcon = () => {
    return (
        <svg
            width='16'
            height='15'
            viewBox='0 0 16 15'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M7.99995 8.11273C8.33836 8.11273 8.6127 7.83839 8.6127 7.49998C8.6127 7.16157 8.33836 6.88724 7.99995 6.88724C7.66154 6.88724 7.38721 7.16157 7.38721 7.49998C7.38721 7.83839 7.66154 8.11273 7.99995 8.11273Z'
                stroke='#98A2B3'
                strokeWidth='1.22549'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.99995 3.82351C8.33836 3.82351 8.6127 3.54918 8.6127 3.21077C8.6127 2.87236 8.33836 2.59802 7.99995 2.59802C7.66154 2.59802 7.38721 2.87236 7.38721 3.21077C7.38721 3.54918 7.66154 3.82351 7.99995 3.82351Z'
                stroke='#98A2B3'
                strokeWidth='1.22549'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M7.99995 12.4019C8.33836 12.4019 8.6127 12.1276 8.6127 11.7892C8.6127 11.4508 8.33836 11.1765 7.99995 11.1765C7.66154 11.1765 7.38721 11.4508 7.38721 11.7892C7.38721 12.1276 7.66154 12.4019 7.99995 12.4019Z'
                stroke='#98A2B3'
                strokeWidth='1.22549'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
