import { ReactElement, useState } from 'react';
import { Grid } from '@mui/material';
import Outletouterpage from '../../../components/dashboard/outletouterpage/outletouterpage';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { getBookingStatus } from '../../../utils/helper';
import { Mapping } from './mapping/mapping';
import Trackshipmentdrawer from './trackshipmentdrawer/trackshipmentdrawer';
import Custombutton from '../../../components/custombutton/custombutton';
import Existingbookingdrawer from './existingbookingdrawer/existingbookingdrawer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import useShippingLines from '../../../customhooks/shippinglines/userShippinglines';
import EnablingFeature from '../../../components/EnablingFeature';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';
import CustomCarousel from '../../../components/CustomCarousel';
import { DocumentTextIcon } from '../../../components/icons';
import { PlusIcon } from '../../../components/icons/PlusIcon';
import Customtable from '../../../components/customtable/customtable';
import BookingsTableMobileCard from './BookingsTableMobileCard';
import { useBookings } from '../../../customhooks/useBookings';
import { useBookingMetrics } from '../../../customhooks/useBookingsMetrics';
import { Booking } from './interfaces/Booking';
import { WalletBalancesSkeleton } from '../../../components/walletbalances/WalletBalancesSkeleton';

const paths = [
    {
        id: 1,
        efShipmentId: 12,
        longitude: 3.356251903616731,
        latitude: 6.434344148869343,
        shipmentDate: '03-20-2023',
        name: '🇳🇬 Apapa, Lagos'
    },
    {
        id: 1,
        efShipmentId: 12,
        longitude: -122.27376902311939,
        latitude: 37.800423077688826,
        shipmentDate: '03-20-2023',
        name: '🇺🇸 Oakland, USA'
    }
];

const Bookings = () => {
    const navigate = useNavigate();

    const { shippingLines, isLoadingShippingLines } = useShippingLines({
        forShipping: true
    });

    const [showExistingBookingDrawer, setShowExistingBookingDrawer] =
        useState(false);
    const [showTrackShipmentDrawer, setShowTrackShipmentDrawer] =
        useState(false);
    const [currentData, setCurrentData] = useState<Booking | null>(null);

    const state = useSelector((state: RootState) => ({
        user: state.user.user
    }));

    const { user } = state;

    const { isLoading: isBookingsLoading, bookings } = useBookings();

    const {
        isLoading: isBookingMetricsLoading,
        bookingMetrics,
        refetch: refetchMetrics
    } = useBookingMetrics(user?.corporate_id);

    const closeShowExisitingBookingDrawer = (refreshList?: boolean) => {
        setShowExistingBookingDrawer(false);
        if (refreshList) {
            refetchMetrics();
        }
    };

    const closeShowTrackShipmentDrawer = () => {
        setShowTrackShipmentDrawer(false);
    };
    const { permissions } = useUserProfile();

    const items = [
        {
            key: '1',
            label: (
                <button
                    className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base'
                    onClick={() => navigate(`/bookings/${currentData?.id}`)}
                >
                    View Booking Details
                </button>
            )
        },
        {
            key: '2',
            label: (
                <button
                    className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base'
                    onClick={() => {
                        navigate(
                            `/bookings/track-shipments/${currentData?.bookingReference}`
                        );
                    }}
                >
                    Track Shipment
                </button>
            )
        }
    ];

    const columns: ColumnsType<any> = [
        {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (_, { createdDate }) => (
                <span>{moment(createdDate).format('ll')}</span>
            )
        },
        {
            title: 'ETA Loading',
            dataIndex: 'departureDate',
            key: 'departureDate',
            render: (_, { departureDate }) => (
                <span>{moment(departureDate).format('ll')}</span>
            )
        },
        {
            title: 'ETA Destination',
            dataIndex: 'arrivalDate',
            key: 'arrivalDate',
            render: (_, { arrivalDate }) => (
                <span>{moment(arrivalDate).format('ll')}</span>
            )
        },
        {
            title: 'Booking No.',
            dataIndex: 'bookingReference',
            key: 'bookingReference',
            render: (text) => (
                <span className='text-appcolorprimary'>{text}</span>
            )
        },
        {
            title: 'Contract No',
            dataIndex: 'salesContractNumber',
            key: 'salesContractNumber'
        },
        {
            title: 'Shipping Line',
            dataIndex: 'shippingLineName',
            key: 'shippingLineName'
        },
        {
            title: 'Port of Loading',
            dataIndex: 'portOfLoading',
            key: 'portOfLoading'
        },
        {
            title: 'Port of Destination',
            dataIndex: 'portOfDischarge',
            key: 'portOfDischarge'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => (
                <span
                    className={`text-[12px] text-semibold p-1 pl-[0.3rem] rounded-[5px]
      ${
          status === 1
              ? 'text-appcolorgreen bg-appcolorgreenfaint'
              : status === 2
              ? 'text-appcolorgreen bg-appcolorgreenfaint'
              : status === 4
              ? 'text-appcolorred bg-appcolorfaintred'
              : status === 3
              ? 'text-appcolorblack bg-appcolorgreyfaint'
              : status === 5
              ? 'text-amber-600 bg-amber-100'
              : ''
      }
      `}
                >
                    {getBookingStatus(status)}
                </span>
            )
        },
        {
            title: '',
            render: (_, data) => (
                <div
                    className='cursor-pointer'
                    onClick={() => {
                        setCurrentData(data);
                    }}
                >
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <img src='/img/vectors/more.svg' alt='more' />
                    </Dropdown>
                </div>
            )
        }
    ];

    const summaryData: { [key: string]: string | ReactElement | number }[] = [
        {
            label: 'Bookings',
            value: bookingMetrics?.numberOfBookings,
            icon: <DocumentTextIcon />
        },
        {
            label: 'Confirmed Bookings',
            value: bookingMetrics?.numberOfActiveBookings,
            icon: <DocumentTextIcon color='#008F46' />
        },
        {
            label: 'Completed Bookings',
            value: bookingMetrics?.numberOfCompletedBookings,
            icon: <DocumentTextIcon color='#008F46' />
        },
        {
            label: 'Pending Bookings',
            value: bookingMetrics?.numberOfPendingBookings,
            icon: <DocumentTextIcon />
        }
    ];

    return (
        <EnablingFeature flag='BookingInttra'>
            <div>
                <Outletouterpage
                    breadcrumbs={[
                        {
                            title: 'Home',
                            link: '/dashboard'
                        },
                        {
                            title: 'Bookings',
                            link: '/bookings'
                        }
                    ]}
                >
                    <div className='flex justify-between'>
                        <h1 className='px-4 lg:px-0 text-lg text-black lg:text-[32px] lg:leading-[23px] lg:text-appcolorblacktwo lg:mt-[0.6rem]'>
                            Bookings
                        </h1>
                        {permissions &&
                            hasPermission({
                                userPermissions: permissions,
                                module: 'logistics',
                                permissionList: ['write', '*']
                            }) && (
                                <div className='hidden lg:flex gap-2 min-w-[35%]'>
                                    <Custombutton
                                        variant='contained'
                                        styles={{
                                            padding:
                                                '0.5rem 0.92rem 0.5rem 0.93rem',
                                            flex: 1
                                        }}
                                        disabled={false}
                                        buttonText='+ Create New Booking'
                                        onClickAction={() => {
                                            navigate(
                                                '/bookings/create-booking'
                                            );
                                        }}
                                        fullWidth
                                    />

                                    <Custombutton
                                        variant='contained'
                                        styles={{
                                            padding:
                                                '0.5rem 0.92rem 0.5rem 0.93rem',
                                            color: '#0088B2',
                                            border: '1px solid transparent',
                                            backgroundColor: '#FFFFFF',
                                            marginLeft: '0rem',
                                            flex: 1,
                                            '&:hover': {
                                                padding:
                                                    '0.5rem 0.92rem 0.5rem 0.93rem',
                                                color: '#0088B2',
                                                border: '1px solid transparent',
                                                backgroundColor: '#FFFFFF'
                                            }
                                        }}
                                        disabled={false}
                                        buttonText='+ Add existing booking'
                                        onClickAction={() => {
                                            setShowExistingBookingDrawer(true);
                                        }}
                                        fullWidth
                                    />
                                </div>
                            )}
                    </div>

                    <div className='mt-4 lg:mt-8'>
                        {isBookingMetricsLoading ? (
                            <div className='px-4 lg:px-0'>
                                <WalletBalancesSkeleton length={1} />
                            </div>
                        ) : (
                            <>
                                <div className='hidden lg:block rounded-[3px] bg-appcolorwhite mt-6'>
                                    <div className='p-[2.1rem]'>
                                        <Grid container alignItems='center'>
                                            <Grid item xs={12} md={12} lg={3}>
                                                <div className='pr-8 custom-piped view'>
                                                    <div className='flex items-center justify-between'>
                                                        <div>
                                                            <p className='mb-2 text-appcolorspacepruple text-[14px] leading-[18px]'>
                                                                Bookings
                                                            </p>
                                                            <p className='font-bold  text-[22px] leading-[28px]'>
                                                                {
                                                                    bookingMetrics?.numberOfBookings
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <img
                                                                src='/img/vectors/usercover.svg'
                                                                alt='usercover'
                                                                className='w-[3rem]'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={3}>
                                                <div className='px-8 custom-piped view'>
                                                    <div className='flex items-center justify-between'>
                                                        <div>
                                                            <p className='mb-2 text-appcolorspacepruple text-[14px] leading-[18px]'>
                                                                Confirmed
                                                                Bookings
                                                            </p>
                                                            <p className='font-bold  text-[22px] leading-[28px]'>
                                                                {
                                                                    bookingMetrics?.numberOfActiveBookings
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <img
                                                                src='/img/vectors/usercover.svg'
                                                                alt='usercover'
                                                                className='w-[3rem]'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={3}>
                                                <div className='px-8 custom-piped view'>
                                                    <div className='flex items-center justify-between'>
                                                        <div>
                                                            <p className='mb-2 text-appcolorspacepruple text-[14px] leading-[18px]'>
                                                                Completed
                                                                Bookings
                                                            </p>
                                                            <p className='font-bold  text-[22px] leading-[28px]'>
                                                                {
                                                                    bookingMetrics?.numberOfCompletedBookings
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <img
                                                                src='/img/vectors/doccover.svg'
                                                                alt='doccover'
                                                                className='w-[3rem]'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={3}>
                                                <div className='pl-8'>
                                                    <div className='flex items-center justify-between'>
                                                        <div>
                                                            <p className='mb-2 text-appcolorspacepruple text-[14px] leading-[18px]'>
                                                                Pending Bookings
                                                            </p>
                                                            <p className='font-bold  text-[22px] leading-[28px]'>
                                                                {
                                                                    bookingMetrics?.numberOfPendingBookings
                                                                }
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <img
                                                                src='/img/vectors/walletcover.svg'
                                                                alt='walletcover'
                                                                className='w-[3rem]'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>

                                <div className='px-4'>
                                    <CustomCarousel
                                        data={summaryData}
                                        actions={[
                                            {
                                                title: 'New Booking',
                                                icon: <PlusIcon />,
                                                onHandleClick: () =>
                                                    navigate(
                                                        '/bookings/create-booking'
                                                    )
                                            },
                                            {
                                                title: 'Add Existing Booking',
                                                icon: <PlusIcon />,
                                                onHandleClick: () =>
                                                    setShowExistingBookingDrawer(
                                                        true
                                                    )
                                            }
                                        ]}
                                    />
                                </div>
                            </>
                        )}

                        <div className='rounded-[3px] bg-appcolorwhite mt-8'>
                            <div className='p-4 lg:p-[1.8rem] h-[29rem]'>
                                <Grid container>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <div className='relative mr-4 map-container h-{25rem] w-full max-w-[100vw]'>
                                            {/* <Dottedmapview /> */}
                                            <Mapping paths={[]} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>

                        <div className='mt-8 hidden lg:block'>
                            <div className='relative'>
                                <div>
                                    <div className='flex items-center justify-between'>
                                        <div className='flex'>
                                            <div
                                                className={`bg-appcolorwhite trapezoid pt-[0.8rem] pb-[0.7rem] pl-[1.6rem] w-fit pr-[5.6rem] cursor-pointer text-[17px] leading-[24px] text-appcolorprimary`}
                                            >
                                                Shipments
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='rounded-[3px] bg-appcolorwhite px-[1rem]'>
                                <Table
                                    rowClassName={(record: any, index) =>
                                        index % 2 === 0 ? 'table-row-dark' : ''
                                    }
                                    bordered={false}
                                    scroll={{ x: 1800 }}
                                    // pagination={false}
                                    loading={isBookingsLoading}
                                    columns={columns}
                                    dataSource={bookings}
                                    rowKey={'id'}
                                    locale={{
                                        emptyText: () => {
                                            return (
                                                <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                                                    <div className='flex justify-center mb-4'>
                                                        <img
                                                            src='/img/vectors/nodatacross.svg'
                                                            alt='nodata'
                                                        />
                                                    </div>
                                                    <p className='text-center'>
                                                        You have no booking
                                                        done.
                                                        <br />
                                                        <span
                                                            className='cursor-pointer text-appcolorprimary'
                                                            onClick={() => {
                                                                navigate(
                                                                    '/bookings/create-booking'
                                                                );
                                                            }}
                                                        >
                                                            Make a booking now
                                                        </span>
                                                    </p>
                                                </div>
                                            );
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <div className='lg:hidden pb-[40px] bg-white max-w-[100vw]'>
                            <div className='flex items-center justify-between border-b border-appcolorfaintnine mx-4 pb-2'>
                                <h5>Shipments </h5>
                            </div>
                            <Customtable
                                data={bookings}
                                loadingData={isBookingsLoading}
                                mobileComponent={
                                    <BookingsTableMobileCard
                                        actions={items}
                                        setCurrentData={setCurrentData}
                                    />
                                }
                            />
                        </div>
                    </div>
                </Outletouterpage>

                {showTrackShipmentDrawer ? (
                    <Trackshipmentdrawer
                        paths={paths}
                        closeDrawer={closeShowTrackShipmentDrawer}
                        showDrawer={showTrackShipmentDrawer}
                    />
                ) : null}

                {showExistingBookingDrawer ? (
                    <Existingbookingdrawer
                        closeDrawer={closeShowExisitingBookingDrawer}
                        showDrawer={showExistingBookingDrawer}
                        shippingLines={shippingLines}
                        isLoadingShippingLines={isLoadingShippingLines}
                    />
                ) : null}
            </div>
        </EnablingFeature>
    );
};

export default Bookings;
