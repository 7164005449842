import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
// import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { NumberInput } from '../../../../components/paymentinput/paymentinput';
import NewCustomSelect from '../../../../components/newcustomselect/newcustomselect';
import { unitTypes } from '../../../../utils/appconstants';
import { MenuItem } from '@mui/material';
import CustomTextField from '../../../../components/CustomTextInput';

export interface quotefieldProps {
    id: string;
    updateQuoteField: (
        index: number,
        attributeName: string,
        attributeValue?: any
    ) => void;
    qty: number;
    price: number;
    index: number;
    removeQuoteField: (index: number) => void;
    item?: any;
    commoditiesList: any;
    isLoadingCommodities: boolean;
    errors?: any;
    setShowAddNewCommodity?: any;
}

const SingleCommodityFields = ({
    id,
    updateQuoteField,
    qty,
    price,
    index,
    removeQuoteField,
    item,
    isLoadingCommodities,
    commoditiesList,
    errors,
    setShowAddNewCommodity
}: quotefieldProps) => {
    return (
        <div className={`relative custom-quote-field px-[0rem]`}>
            <Grid container>
                <Grid item xs={12} md={12} lg={4}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='pt-[1rem]'>
                                    <NewCustomSelect
                                        hasError={errors?.commodity?.length > 0}
                                        helperText={
                                            errors?.commodity?.length > 0
                                                ? errors?.commodity
                                                : undefined
                                        }
                                        selectValue={item.commodityId}
                                        required
                                        selectOptions={commoditiesList}
                                        name={'commodity'}
                                        updateField={(_, value) => {
                                            updateQuoteField(
                                                index,
                                                'commodity',
                                                commoditiesList.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.name
                                            );
                                            updateQuoteField(
                                                index,
                                                'hsCode',
                                                commoditiesList.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.hsCode
                                            );
                                            updateQuoteField(
                                                index,
                                                'commodityType',
                                                commoditiesList.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.type
                                            );
                                            updateQuoteField(
                                                index,
                                                'quality',
                                                commoditiesList.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.quality
                                            );

                                            updateQuoteField(
                                                index,
                                                'metric',
                                                commoditiesList.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.unitType
                                            );

                                            updateQuoteField(
                                                index,
                                                'commodityId',
                                                commoditiesList.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.id
                                            );
                                        }}
                                        label={'Commodity'}
                                        selectOptionLabel='name'
                                        loading={isLoadingCommodities}
                                        dropdownRender={() => {
                                            return (
                                                <div className='px-1 border-t border-appcolorgreyfaint'>
                                                    <p
                                                        className='px-0 text-center py-2 text-[14px] cursor-pointer text-appcolorprimary'
                                                        onClick={() => {
                                                            setShowAddNewCommodity(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        + Add a New Commodity
                                                    </p>
                                                </div>
                                            );
                                        }}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={6} mt={2}>
                                <CustomTextField
                                    id={`quote-commodityType-${id}`}
                                    label='Commodity Type'
                                    name='commodityType'
                                    disabled
                                    value={item?.commodityType}
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'commodityType',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12} md={12} lg={4}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid item xs={12} md={12} lg={6} mt={2}>
                                <CustomTextField
                                    id={`quote-quality-${id}`}
                                    label='Quality'
                                    name='quality'
                                    disabled
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'quality',
                                            e.target.value
                                        );
                                    }}
                                    value={item?.quality}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} mt={2}>
                                <CustomTextField
                                    value={item?.hsCode}
                                    id={`quote-hsCode-${id}`}
                                    label='HS Code'
                                    name='hsCode'
                                    disabled
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'hsCode',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12} md={12} lg={4}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid item xs={12} md={12} lg={4} mt={2}>
                                <CustomTextField
                                    value={
                                        isNaN(parseFloat(item?.quantity))
                                            ? undefined
                                            : item?.quantity
                                    }
                                    id={`quote-quantity-${id}`}
                                    label='Quantity'
                                    name='quantity'
                                    numericInput
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'quantity',
                                            e.target.value
                                        );
                                    }}
                                    InputProps={{
                                        inputComponent: NumberInput,
                                        inputProps: {
                                            min: '0'
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={4} mt={2}>
                                <CustomTextField
                                    select
                                    value={item?.metric}
                                    disabled
                                    id={`commodity-type-${id}`}
                                    label='Unit Metric'
                                    name='metric'
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'metric',
                                            e.target.value
                                        );
                                    }}
                                >
                                    {unitTypes.map((item) => (
                                        <MenuItem
                                            value={item}
                                            key={`${id}-${item}`}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))}
                                </CustomTextField>
                            </Grid>
                            <Grid item xs={12} md={12} lg={4} mt={2}>
                                <CustomTextField
                                    value={
                                        isNaN(parseFloat(item?.quantityBuffer))
                                            ? undefined
                                            : item?.quantityBuffer
                                    }
                                    id={`quote-quantityBuffer-${id}`}
                                    label='Qty buffer (%)'
                                    name='quantityBuffer'
                                    numericInput
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'quantityBuffer',
                                            e.target.value
                                        );
                                    }}
                                    InputProps={{
                                        inputComponent: NumberInput,
                                        inputProps: {
                                            min: '0',
                                            max: '100'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {index !== 0 && (
                <div
                    className='absolute top-[1.2rem] cursor-pointer right-[-1rem] custom-quote-field-delete w-fit transition-all delay-75'
                    onClick={() => {
                        removeQuoteField(index);
                    }}
                >
                    <DeleteIcon />
                </div>
            )}
        </div>
    );
};

export default SingleCommodityFields;
