import { useNavigate } from 'react-router-dom';
import AddWarehouseForm from '../../../components/dashboard/stock/AddWarehouseForm';
import { ChevronLeftIcon } from '../../../components/icons';

const AddWarehouse = () => {
    const navigate = useNavigate();

    return (
        <div className='flex w-full max-w-[450px] flex-col px-4 py-[32px]'>
            <button
                className='hidden lg:flex items-center text-sm font-medium text-appcolorblacktwo'
                onClick={() => navigate('/stock')}
            >
                <img
                    src='/img/vectors/breadcrumbcaretright.svg'
                    alt='caret'
                    className='pr-3'
                />
                <span>Back to Stock Management</span>
            </button>

            <div className='lg:hidden '>
                <button
                    className='flex items-center gap-2 text-lg font-medium text-ebony cursor-pointer w-fit'
                    onClick={() => navigate('/stock')}
                >
                    <ChevronLeftIcon />
                    <span>Stock management</span>
                </button>
            </div>

            <h4 className='text-xl text-ebony lg:text-appcolorfaintsix lg:text-2xl lg:font-bold my-[30px]'>
                Add Warehouse
            </h4>

            <AddWarehouseForm />
        </div>
    );
};

export default AddWarehouse;
