import { http } from '..';
import { paginatedData } from '../../utils/helper-interface';

export interface getFXRatePayload {
    currencyFrom: string;
    currencyTo: string;
}

export interface createTransactionPayload {
    destinationCurrencyCode: string;
    expectedDestinationAmount: number;
    expectedRate: number;
    paymentPurposeId: string;
    paymentReference?: string;
    sourceAmount: number;
    sourceCurrencyCode: string;
    transactionDestination: number;
    transactionSource: number;
    transactionType: number;
    transactionDocumentsUrl: string[];
    notifyParties: string[];
}

export interface getTransactionPayload extends paginatedData {}

export interface getTransactioByReferencePayload {
    corporateId: number;
    transactionReference: string;
}

export interface getAdminWalletsPayload {
    type: 'PROVIDER' | 'POOL' | 'INTERNAL';
}

/**
 * Get FX rate
 * @param data Object
 * @returns http response
 */
export const getFXRate = (data: getFXRatePayload) => {
    return http.get(`/rate`, {
        params: {
            ...data
        }
    });
};

/**
 * Gets transaction based on reference
 * @param data Object
 * @returns ttp response
 */
export const getTransactioByReference = (
    data: getTransactioByReferencePayload
) => {
    return http.get(
        `/wallet/transaction/${data.transactionReference}/corporate/${data.corporateId}`
    );
};
