import { useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { useLocation, useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { canShow } from '../utils';
import { useUserProfile } from '../../../../customhooks/useUserProfile';

export interface menuitemsProps {
    itemDetails: any;
    isActive: boolean;
}

const Menuitem = ({ itemDetails, isActive }: menuitemsProps) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();

    const state = useSelector((state: RootState) => ({
        token: state.user.token,
        user: state.user.user
    }));

    const { user } = state;

    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    const { isLoading, userDetails, permissions } = useUserProfile();

    return (
        <>
            <div className='mb-2'>
                <ListItemButton
                    sx={{
                        borderRadius: !isActive ? 'initial' : '0 5px 5px 0',
                        backgroundColor: !isActive
                            ? 'initial'
                            : 'rgba(0, 136, 178, 0.1)',
                        marginRight: !isActive ? 'initial' : '0.5rem',
                        color: !isActive
                            ? 'initial'
                            : theme.palette.primary.main,
                        borderLeft: !isActive
                            ? 'initial'
                            : `4px solid ${theme.palette.primary.main}`,
                        marginLeft: '1rem',
                        // borderColor: theme.palette.primary.main,
                        transition: 'all 0.05s ease-in',
                        '&:hover': {
                            borderRadius: '0 5px 5px 0',
                            backgroundColor: 'rgba(0, 136, 178, 0.1)',
                            marginRight: '0.5rem',
                            color: theme.palette.primary.main,
                            borderLeft: `4px solid ${theme.palette.primary.main}`
                        }
                    }}
                    onClick={
                        itemDetails.child !== undefined
                            ? handleClick
                            : () => {
                                  if (itemDetails.title === 'Financing') {
                                      if (user?.user_level === 5) {
                                          navigate('/financing/overview');
                                      } else {
                                          navigate(itemDetails.route);
                                      }
                                  } else {
                                      navigate(
                                          itemDetails.route === '#'
                                              ? '/dashboard'
                                              : itemDetails.route
                                      );
                                  }
                              }
                    }
                >
                    <ListItemIcon
                        sx={{
                            minWidth: '41px'
                        }}
                    >
                        <img
                            src={
                                isActive
                                    ? itemDetails.iconActive
                                    : itemDetails.icon
                            }
                            alt={itemDetails.title}
                        />
                    </ListItemIcon>
                    <ListItemText primary={itemDetails.title} />
                    {itemDetails.child !== undefined && (
                        <>{open ? <ExpandLess /> : <ExpandMore />}</>
                    )}
                </ListItemButton>
                {itemDetails.child !== undefined && (
                    <>
                        <Collapse in={open} timeout='auto' unmountOnExit>
                            <List component='div' disablePadding>
                                {itemDetails.child
                                    .filter((item: any) => canShow(user, item))
                                    .map((item: any, index: number) => (
                                        <ListItemButton
                                            sx={{
                                                pl: 8,
                                                ml: 2,
                                                mr: 1,
                                                pt: 0.5,
                                                pb: 0.5,
                                                color:
                                                    location.pathname ===
                                                    item.route
                                                        ? '#0088b2'
                                                        : 'inherit'
                                            }}
                                            key={index}
                                            onClick={() => {
                                                if (item.route !== '#') {
                                                    navigate(item.route);
                                                }
                                            }}
                                        >
                                            <ListItemText
                                                primary={item.title}
                                                sx={{ pt: 0 }}
                                            />
                                        </ListItemButton>
                                    ))}
                            </List>
                        </Collapse>
                    </>
                )}
            </div>
        </>
    );
};

export default Menuitem;
