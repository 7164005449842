export const documentTypeOptions = [
  { value: 'BILL_OF_LADING', label: 'Bill of Lading' },
  { value: 'COMMERCIAL_INVOICE', label: 'Commercial Invoice' },
  { value: 'CONTRACT', label: 'Contract' },
  { value: 'DRAFT_BILL_OF_LADING', label: 'Draft Bill of Lading' },
  {
    value: 'DRAFT_CERTIFICATE_OF_ORIGIN',
    label: 'Draft Certificate of Origin',
  },
  { value: 'FUMIGATION_CERTIFICATE', label: 'Fumigation Service' },
  {
    value: 'DRAFT_GROSS_MASS_DECLARATION',
    label: 'Draft Gross Mass Declaration',
  },
  { value: 'GROSS_MASS_DECLARATION', label: 'Gross Mass Declaration' },
  { value: 'MARINE_INSURANCE', label: 'Marine Insurance' },
  { value: 'NXP_FORM', label: 'File' },
  {
    value: 'ORIGINAL_CERTIFICATE_OF_ORIGIN',
    label: 'Original Certificate of Origin',
  },
  { value: 'PACKING_LIST', label: 'Packing List' },
  { value: 'PHYTOSANITARY_CERTIFICATE', label: 'Phytosanitary Certificate' },
  { value: 'PRE_INSPECTION_CERTIFICATE', label: 'Pre-Inspection Certificate' },
  { value: 'PROFORMA_INVOICE', label: 'Proforma Invoice' },
  { value: 'PURCHASE_ORDER', label: 'Purchase Order' },
  { value: 'QUOTATION', label: 'Quotation' },
  { value: 'SALES_CONTRACT', label: 'Sales Contract' },
  { value: 'CERTIFICATE_ANALYSIS', label: 'Certificate Analysis' },

  { value: 'OTHER_DOCUMENTS', label: 'Other Documents' },
]
