import Dialog from '@mui/material/Dialog';
import { ReactNode } from 'react';
import { SxProps } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import { CloseIcon } from './icons';

export interface CustomModalProps {
    open?: boolean;
    children: ReactNode;
    onClose: (refreshList?: boolean) => void;
    title?: string;
    subTitle?: ReactNode;
    maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    hideCloseIcon?: boolean;
    styles?: SxProps;
}

const CustomModal = ({
    open,
    onClose,
    children,
    title,
    subTitle,
    maxWidth,
    hideCloseIcon,
    styles
}: CustomModalProps) => {
    const theme = useTheme();

    return (
        <div className=''>
            <Dialog
                open={Boolean(open)}
                onClose={() => {
                    onClose();
                }}
                maxWidth={maxWidth}
                sx={{
                    [theme.breakpoints.down('md')]: {
                        bottom: '0 !important',
                        width: '100% !important',
                        top: 'auto'
                    },
                    ...styles
                }}
            >
                <div className='relative py-4 lg:py-[1rem] lg:px-[1rem]'>
                    {title && (
                        <p className='px-4 mt-4 text-ebony mb-4 lg:font-bold lg:leading-5 lg:text-[20px] lg:mb-[0.8rem]'>
                            {title}
                        </p>
                    )}
                    {subTitle && <p className='mb-4 px-4'>{subTitle}</p>}

                    <div
                        style={{ zIndex: 3000 }}
                        className='w-[100vw] lg:w-full'
                    >
                        {children}
                    </div>
                </div>
                {!hideCloseIcon && (
                    <div
                        className='absolute top-[20px] cursor-pointer right-[20px] w-fit transition-all delay-75 close-btn z-50'
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <CloseIcon />
                    </div>
                )}
            </Dialog>
        </div>
    );
};

export default CustomModal;
