import { createContext, ReactNode } from 'react';
import { queryConfig } from '../api/queryBase';
import SuiteUIPageLoader from '../components/SuiteUIPageLoader';

export const FeatureFlags = createContext({
    features: {} as Record<string, boolean>
});

interface FeatureFlagsProviderProps {
    children: ReactNode;
}

export const FeatureFlagsProvider = (props: FeatureFlagsProviderProps) => {
    const query = queryConfig({ authed: false });
    const { isLoading, data } = query<Record<string, boolean>>({
        url: '/features',
        cacheKey: 'core-features'
    });
    const features: Record<string, boolean> = data ? data.payload : {};

    return (
        <FeatureFlags.Provider value={{ features }}>
            {isLoading ? <SuiteUIPageLoader /> : props.children}
        </FeatureFlags.Provider>
    );
};
