export const RefreshTableIcon = () => {
    return (
        <svg
            width='16'
            height='17'
            viewBox='0 0 16 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                opacity='0.01'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 16.5L0 0.5L16 0.5L16 16.5L0 16.5Z'
                fill='black'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.33333 9.16667C3.33333 8.79848 3.03486 8.5 2.66667 8.5C2.29848 8.5 2 8.79848 2 9.16667C2 11.744 4.08934 13.8333 6.66667 13.8333H11.7234L10.8616 14.6952L10.8062 14.758C10.6028 15.0195 10.6213 15.3977 10.8616 15.638C11.1219 15.8984 11.544 15.8984 11.8044 15.6381L13.7986 13.6441C13.9228 13.523 14 13.3539 14 13.1667C14 12.9795 13.9228 12.8103 13.7986 12.6892L11.8044 10.6952L11.7416 10.6398C11.48 10.4364 11.1019 10.455 10.8616 10.6953L10.8061 10.7581C10.6028 11.0196 10.6213 11.3978 10.8616 11.6381L11.7234 12.5H6.66667L6.52207 12.4969C4.7482 12.4212 3.33333 10.9592 3.33333 9.16667Z'
                fill='black'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5.1377 1.36195C4.87736 1.1016 4.45525 1.1016 4.1949 1.36195L2.1949 3.36195L2.19052 3.3669C2.17434 3.38342 2.15902 3.40077 2.14462 3.41889L2.13944 3.42475C1.93609 3.68628 1.95457 4.06444 2.1949 4.30476L4.1949 6.30476L4.2577 6.36022C4.51923 6.56357 4.89738 6.54508 5.1377 6.30476L5.19316 6.24195C5.39651 5.98043 5.37803 5.60227 5.1377 5.36195L4.27588 4.50006H9.33346L9.47805 4.50314C11.2519 4.57885 12.6668 6.04089 12.6668 7.83339C12.6668 8.20158 12.9653 8.50006 13.3335 8.50006C13.7016 8.50006 14.0001 8.20158 14.0001 7.83339C14.0001 5.25607 11.9108 3.16673 9.33346 3.16673H4.27579L5.1377 2.30476L5.19316 2.24195C5.39651 1.98043 5.37803 1.60227 5.1377 1.36195Z'
                fill='black'
            />
        </svg>
    );
};
