import React, { useState } from 'react';
import { Button } from '@mui/material';
import CustomModal from '../../../../components/CustomModal';
import PaymentReceipt from './PaymentReceipt';
import { ViewContainer } from '../../../../styles/PaymentStyles';
import DownloadPaymentReceipt from './DownloadPaymentReceipt';
import { Transactions } from '../interfaces/Transactions';
import AttachmentIcon from '../../../../components/icons/AttachmentIcon';

export const ViewPaymentReceipt = ({ data }: { data: Transactions }) => {
    const [visibleDocument, setVisibleDocument] = useState(false);
    const closeCurrentDocModal = () => {
        setVisibleDocument(false);
    };

    return (
        <ViewContainer>
            <div className='flex gap-2 mt-2 w-full lg:w-max'>
                <Button
                    variant='text'
                    className='flex-1 h-[40px] lg:h-[unset] lg:flex-none'
                    onClick={() => {
                        setVisibleDocument(true);
                    }}
                >
                    <AttachmentIcon />
                    View
                </Button>

                <DownloadPaymentReceipt {...{ data }} />
            </div>
            <CustomModal
                maxWidth='lg'
                open={visibleDocument}
                onClose={closeCurrentDocModal}
            >
                <div className='lg:w-[40rem] w-full'>
                    <PaymentReceipt {...{ data }} />
                </div>
            </CustomModal>
        </ViewContainer>
    );
};
