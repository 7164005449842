export const CopyIcon = () => (
    <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M10.6667 8.59992V11.3999C10.6667 13.7333 9.73337 14.6666 7.40004 14.6666H4.60004C2.26671 14.6666 1.33337 13.7333 1.33337 11.3999V8.59992C1.33337 6.26659 2.26671 5.33325 4.60004 5.33325H7.40004C9.73337 5.33325 10.6667 6.26659 10.6667 8.59992Z'
            stroke='#0088B2'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M14.6667 4.59992V7.39992C14.6667 9.73325 13.7334 10.6666 11.4 10.6666H10.6667V8.59992C10.6667 6.26659 9.73337 5.33325 7.40004 5.33325H5.33337V4.59992C5.33337 2.26659 6.26671 1.33325 8.60004 1.33325H11.4C13.7334 1.33325 14.6667 2.26659 14.6667 4.59992Z'
            stroke='#0088B2'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
