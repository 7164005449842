export const CloseIcon = () => (
    <svg
        width='44'
        height='44'
        viewBox='0 0 44 44'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M28 16L16 28M16 16L28 28'
            stroke='#667085'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
