import { yupToFormErrors } from 'formik';
import { BookingFormProps } from '../interfaces/BookingForm';
import { MainCarriage, MetaCarriage } from '../interfaces/Transport';
import { MainCarriageForm } from '../MainCarriageForm';
import { CarriageFormSection } from '../CarriageFormSection';
import { ContainerFormSection } from '../ContainerFormSection';
import { MetaCarriageForm } from '../MetaCarriageForm';
import { SuitUIButton } from '../../../../../components/SuitUIButton';
import { TransportSchema } from '../schemas/BookingFormValidationSchema';

interface CarriageProps extends BookingFormProps {
    activeStep: number;
    setActiveStep: (val: number) => void;
}

const Carriage = ({
    formData,
    setFormData,
    formik,
    activeStep,
    setActiveStep
}: CarriageProps) => {
    const handleNext = () => {
        try {
            TransportSchema.validateSync(formik.values, {
                abortEarly: false
            });

            setActiveStep(activeStep + 1);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } catch (err) {
            formik.setErrors(yupToFormErrors(err));
        }
    };
    return (
        <div>
            <CarriageFormSection<MainCarriage>
                {...{
                    title: 'Main Carriage',
                    addButtonText: '+ Add Carriage',
                    section: 'main',
                    hasDefault: true,
                    formData,
                    setFormData,
                    formik,
                    MetaComponent: MainCarriageForm
                }}
            />

            <CarriageFormSection<MetaCarriage>
                {...{
                    title: 'Pre Carriage (Optional)',
                    addButtonText: '+ Add Pre Carriage',
                    section: 'preCarriage',
                    formData,
                    setFormData,
                    formik,
                    MetaComponent: MetaCarriageForm
                }}
            />

            <CarriageFormSection<MetaCarriage>
                {...{
                    title: 'On Carriage (Optional',
                    addButtonText: '+ Add On Carriage',
                    section: 'onCarriage',
                    formData,
                    setFormData,
                    formik,
                    MetaComponent: MetaCarriageForm
                }}
            />

            <ContainerFormSection
                {...{
                    formData,
                    setFormData,
                    formik
                }}
            />

            <div className='flex gap-[16px] items-center justify-end mt-[40px] lg:mb-0'>
                <SuitUIButton
                    text='Back'
                    onClick={() => setActiveStep(activeStep - 1)}
                    invert
                    fullWidth
                    style='px-[0.92rem] border border-gray300 text-steelBlue font-medium text-sm leading-[20px] lg:border-none lg:text-appcolorprimary'
                />

                <SuitUIButton
                    text='Continue'
                    onClick={handleNext}
                    fullWidth
                    style='px-[0.92rem]'
                />
            </div>
        </div>
    );
};

export default Carriage;
