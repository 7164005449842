import { useNavigate } from 'react-router-dom';
import { DocumentTableContainer } from '../../../styles/TradeDocStyles';
import { Dropdown, MenuProps, Table } from 'antd';
import { formatNumber } from '../../../utils/helper';
import Customtable from '../../customtable/customtable';
import { MoreIcon } from '../../icons/MoreIcon';
import WarehouseTableMobileCard from './WarehouseTableMobileCard';
import { ExportTwoIcon } from '../../icons';
import { PlusIcon } from '../../icons/PlusIcon';
import FilterWarehouse from './FilterWarehouse';

const WarehouseTable = () => {
    const navigate = useNavigate();

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div className='flex items-center menu-otps'>
                    <span className='ml-2'>Edit Details</span>
                </div>
            )
        },
        {
            key: '2',
            label: (
                <div className='flex items-center menu-otps'>
                    <span className='ml-2'>View about Warehosue</span>
                </div>
            )
        },
        {
            key: '3',
            label: (
                <div className='flex items-center menu-otps'>
                    <span className='ml-2'>Extract to CSV</span>
                </div>
            )
        }
    ];

    return (
        <>
            <DocumentTableContainer className='bg-appcolorwhite hidden lg:block'>
                <Table
                    loading={false}
                    pagination={{
                        pageSize: 20,
                        showSizeChanger: true
                    }}
                    locale={{
                        emptyText: () => {
                            return (
                                <div className='flex flex-col justify-center mt-24 mb-24 align-center'>
                                    <div className='flex justify-center mb-4'>
                                        <img
                                            src='/img/vectors/nodatacross.svg'
                                            alt='nodata'
                                        />
                                    </div>

                                    <p className='text-center text-appcolorblackthree'>
                                        There are no items on this list. <br />
                                    </p>
                                </div>
                            );
                        }
                    }}
                    rowKey='id'
                    dataSource={[]}
                    columns={[
                        {
                            title: 'Warehouse name',
                            dataIndex: 'name',
                            key: 'name',
                            render: (text) => (
                                <span className=''>{text ?? '-'}</span>
                            )
                        },
                        {
                            title: 'Location',
                            dataIndex: 'location',
                            key: 'location',
                            render: (text) => (
                                <span className=''>{text ?? '-'}</span>
                            )
                        },
                        {
                            title: 'Size',
                            dataIndex: 'size',
                            key: 'size',
                            render: (text) => (
                                <span className=''>
                                    {formatNumber(text ?? 0)}
                                </span>
                            )
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                            render: (text) => (
                                <span className=''>{text ?? '-'}</span>
                            )
                        },
                        {
                            title: '',
                            render: (_, data) => (
                                <div
                                    className='cursor-pointer'
                                    // onClick={() => {
                                    //   props.setCurrentData(data)
                                    // }}
                                >
                                    <Dropdown
                                        menu={{ items }}
                                        trigger={['click']}
                                        placement='bottomRight'
                                    >
                                        <img
                                            src='/img/vectors/more.svg'
                                            alt='more'
                                        />
                                    </Dropdown>
                                </div>
                            )
                        }
                    ]}
                />
            </DocumentTableContainer>
            <div className='lg:hidden pb-[40px] pt-4 mt-[24px] bg-white max-w-[100vw]'>
                <div className='flex items-center justify-between border-b border-appcolorfaintnine py-4 mx-4'>
                    <h5>Warehouse </h5>
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    label: (
                                        <button
                                            className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base'
                                            onClick={() => {}}
                                        >
                                            <FilterWarehouse
                                            // {...{
                                            //     setTransactionFilterOptions,
                                            //     beneficiaries
                                            // }}
                                            />
                                        </button>
                                    )
                                },
                                {
                                    key: '2',
                                    label: (
                                        <button
                                            className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base'
                                            onClick={() => {}}
                                        >
                                            <ExportTwoIcon />
                                            <span>Extract</span>
                                        </button>
                                    )
                                },
                                {
                                    key: '3',
                                    label: (
                                        <button
                                            className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base'
                                            onClick={() =>
                                                navigate('/stock/add-warehouse')
                                            }
                                        >
                                            <PlusIcon />
                                            <span>Add warehouse</span>
                                        </button>
                                    )
                                }
                            ]
                        }}
                        trigger={['click']}
                    >
                        <div
                            style={{
                                cursor: 'pointer'
                            }}
                        >
                            <MoreIcon />
                        </div>
                    </Dropdown>
                </div>
                <Customtable
                    data={[]}
                    loadingData={false}
                    multiTableTiles={[]}
                    mobileComponent={<WarehouseTableMobileCard />}
                />
            </div>
        </>
    );
};

export default WarehouseTable;
