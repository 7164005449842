import { Dropdown } from 'antd';
import moment from 'moment';
import { BankIcon, ProfileCircleIcon } from '../../../components/icons';
import { DeleteIcon } from '../../../components/icons/DeleteIcon';
import { EditIcon } from '../../../components/icons/EditIcon';
import { MoreIcon } from '../../../components/icons/MoreIcon';
import { Beneficiary } from './interfaces/Beneficiary';

const UserBeneficiaryTableMobileCard = ({
    item,
    handleEdit,
    handleDelete
}: {
    item?: Beneficiary;
    handleEdit: (val: number) => void;
    handleDelete: (val: Beneficiary) => void;
}) => {
    return (
        <div className='bg-white border border-appcolorfaintnine border-b border-0 py-[20px]'>
            <div className='flex items-center justify-between mb-[16px]'>
                <div className='w-[32px] h-[32px] rounded-[8px] border border-gray300 flex justify-center items-center'>
                    {item?.beneficiaryEntityType === 'INDIVIDUAL' ? (
                        <ProfileCircleIcon />
                    ) : (
                        <BankIcon />
                    )}
                </div>

                <Dropdown
                    menu={{
                        items: [
                            {
                                key: '1',
                                label: (
                                    <button
                                        className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base'
                                        onClick={() =>
                                            item?.id && handleEdit(item?.id)
                                        }
                                    >
                                        <EditIcon />
                                        <span>Edit</span>
                                    </button>
                                )
                            },
                            {
                                key: '2',
                                label: (
                                    <button
                                        className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base'
                                        onClick={() =>
                                            item?.id && handleDelete(item)
                                        }
                                    >
                                        <DeleteIcon />
                                        <span>Delete</span>
                                    </button>
                                )
                            }
                        ]
                    }}
                    trigger={['click']}
                >
                    <div
                        style={{
                            cursor: 'pointer'
                        }}
                    >
                        <MoreIcon />
                    </div>
                </Dropdown>
            </div>

            <div>
                <p className='text-sm font-semibold leading-5 text-ebony mb-[8px]'>
                    {item?.beneficiaryCompanyName}
                </p>
                <div className='flex items-center gap-x-4 gap-y-1 flex-wrap mb-[4px]'>
                    <p className='flex gap-[4px]  text-xs font-normal leading-[18px] text-paleSky'>
                        Acct No:
                        <span className='font-semibold'>
                            {' '}
                            {item?.accountNumber}
                        </span>
                    </p>
                    <p className='flex gap-[4px] text-xs font-normal leading-[18px] text-paleSky'>
                        Bank name:
                        <span className='font-semibold'> {item?.bankName}</span>
                    </p>
                </div>
                <p className='text-xs font-normal leading-[18px] text-paleSky'>
                    Date:{' '}
                    <span className='font-semibold'>
                        {moment(item?.createdDate).format('ll')}
                    </span>
                </p>
            </div>
        </div>
    );
};

export default UserBeneficiaryTableMobileCard;
