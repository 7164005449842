import { Upload, UploadFile } from 'antd';
import { useRef } from 'react';
import { UploadCloudIcon } from './icons';

interface UploadItemProps {
    actionText: string;
    action: string;
    name: string;
    accept?: string;
    defaultFileList?: UploadFile[];
    onChange: (file: any) => void;
    maxCount: number;
    error?: string;
}

const UploadItem = ({
    action,
    actionText,
    accept = '.png, .jpg, .jpeg, .pdf',
    name,
    defaultFileList = [],
    onChange,
    error,
    maxCount
}: UploadItemProps) => {
    const uploadRef = useRef<HTMLInputElement>(null);
    return (
        <div>
            <div
                className={`flex flex-col bg-white rounded-[12px] px-[24px] py-[16px] justify-center items-center border border-${
                    error ? 'crimson' : 'appcolorfaintnine'
                }`}
            >
                <div className='h-[40px] w-[40px] rounded-[50%] bg-athensGray flex justify-center items-center'>
                    <div className='h-[32px] w-[32px] rounded-[50%] bg-titanium flex justify-center items-center'>
                        <UploadCloudIcon />
                    </div>
                </div>

                <Upload
                    action={action}
                    accept={accept}
                    name={name}
                    maxCount={maxCount}
                    headers={{
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }}
                    defaultFileList={defaultFileList}
                    onChange={({ file }) => onChange(file)}
                    method={'post'}
                    data={{
                        fileType: 'PAYMENT'
                    }}
                >
                    <span ref={uploadRef} className='opacity-0' />
                </Upload>

                <button
                    className='text-appcolorprimary text-sm font-semibold '
                    onClick={() => uploadRef?.current?.click()}
                >
                    {actionText}
                </button>

                <p className='text-xs font-normal text-midnightHaze mt-[4px]'>
                    SVG, PNG, JPG or GIF (max. 800x400px)
                </p>
            </div>
            {error && (
                <p className=' text-[13px] leading-[16px] text-crimson pt-[8px]'>
                    {error}
                </p>
            )}
        </div>
    );
};

export default UploadItem;
