import { CircularProgress, Grid, MenuItem, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import {
    medthodsOfDispatch,
    typesOfShipment
} from '../../../../../utils/appconstants';
import { ShipmentDetailsProps } from '../../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/parkinglistTypes';
import { Country } from '../../../../../interfaces/Country';

const ShippmentDetails = ({
    errors,
    payload,
    countries,
    updateField,
    isCountriesLoading
}: ShipmentDetailsProps) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: '0px'
                        }}
                    >
                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                select
                                error={errors?.methodOfDispatch.length > 0}
                                helperText={
                                    errors?.methodOfDispatch.length > 0
                                        ? errors?.methodOfDispatch
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='commercialinvoice-methodOfDispatch'
                                label='Method of Dispatch'
                                name='methodOfDispatch'
                                value={payload?.methodOfDispatch}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'methodOfDispatch',
                                        e.target.value
                                    );
                                }}
                            >
                                {medthodsOfDispatch.map((item, index) => (
                                    <MenuItem
                                        value={item}
                                        key={`medthodsOfDispatch-${item}-${index}`}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                select
                                error={errors?.typeOfShipment.length > 0}
                                helperText={
                                    errors?.typeOfShipment.length > 0
                                        ? errors?.typeOfShipment
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='commercialinvoice-typeOfShipment'
                                label='Type of Shipment'
                                name='typeOfShipment'
                                value={payload?.typeOfShipment}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'typeOfShipment',
                                        e.target.value
                                    );
                                }}
                            >
                                {typesOfShipment.map((item, index) => (
                                    <MenuItem
                                        value={item}
                                        key={`typesOfShipment-${item}-${index}`}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                    <TextField
                        select
                        error={errors?.countryOfOriginId.length > 0}
                        helperText={
                            errors?.countryOfOriginId.length > 0
                                ? errors?.countryOfOriginId
                                : undefined
                        }
                        margin='normal'
                        required
                        fullWidth
                        id='commercialinvoice-countryOfOriginId'
                        label='Country Of Origin of Goods'
                        name='countryOfOriginId'
                        value={payload?.countryOfOriginId}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            updateField('countryOfOriginId', e.target.value);
                            updateField(
                                'countryOfOriginName',
                                countries.filter(
                                    (item: { id: string | number }) =>
                                        item?.id === e.target.value
                                )[0]?.name
                            );
                        }}
                    >
                        {isCountriesLoading ? (
                            <MenuItem>
                                <CircularProgress size={20} />
                            </MenuItem>
                        ) : (
                            countries.map((item: Country) => (
                                <MenuItem
                                    value={item.id}
                                    key={`countries-${item.id}`}
                                >
                                    <div className='flex items-center'>
                                        <img
                                            src={item?.flagImageUrl}
                                            alt={`${item.name}-flag`}
                                            className='w-4 h-4'
                                        />
                                        <span className='ml-2'>
                                            {item.name}
                                        </span>
                                    </div>
                                </MenuItem>
                            ))
                        )}
                    </TextField>
                </div>
            </Grid>
        </Grid>
    );
};

export default ShippmentDetails;
