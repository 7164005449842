import React, { useEffect, useRef, useState } from 'react';
import { openNotification } from '../../../../utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import useShippingLines from '../../../../customhooks/shippinglines/userShippinglines';
import useCorporatePartners from '../../../../customhooks/useCorporatePartners';
import {
    createSalesContract,
    getSingleDocuments
} from '../../../../api/documents/documents';
import {
    TradeContainer,
    TradeContent
} from '../../../../styles/TradeDocStyles';
import FormHeader from '../../../../components/dashboard/tradeDocuments/common/FormHeader';
import SalesContractForm from '../../../../components/dashboard/tradeDocuments/formComponents/SalesContractForm';
import Addpartnerdrawer from '../addpartnerdrawer/addpartnerdrawer';
import CustomModal from '../../../../components/CustomModal';
import Savetradedocument from '../savetradedocument/savetradedocument';
import { nanoid } from '@reduxjs/toolkit';
import useCommodities from '../../../../customhooks/useCommodities';
import SalesContractPreview from '../../../../components/dashboard/tradeDocuments/previewComponents/SalesContractPreview';
import { Backdrop } from '@mui/material';
import useHsCode from '../../../../customhooks/useHsCode';

const SalesContractNew = () => {
    type commodityInterface = {
        id: string;
        commodity: string;
        commodityType: string;
        quality: string;
        quantity: number;
        hsCode: string;
        quantityBuffer: string;
        commodityId: string;
        metric: string;
    };

    const myDiv = useRef<HTMLDivElement | null>(null);
    const { corporatePartners, isLoading } = useCorporatePartners({ page: 1 });
    const { hsCodeList } = useHsCode({ page: 1, size: 0 });
    const { commoditiesList, isLoading: isLoadingCommodities } =
        useCommodities();

    const { shippingLines, isLoadingShippingLines } = useShippingLines({});
    const navigate = useNavigate();
    const pageTopRef = useRef<any>(null);

    const [fileId, setFileId] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [saveOnly, setSaveOnly] = useState(true);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showAddPartner, setShowAddPartner] = useState(false);
    const [error, setError] = useState<any>(null);
    const [showPreview, setShowPreview] = useState(false);
    const [signatureUrlBase64Exporter, setSignatureUrlBase64Exporter] =
        useState('');
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [commodityFields, setCommodityFields] = useState<
        commodityInterface[]
    >([
        {
            id: nanoid(9),
            commodityId: '',
            commodity: '',
            commodityType: '',
            quality: '',
            quantity: 0,
            hsCode: '',
            quantityBuffer: '',
            metric: ''
        }
    ]);
    const [payload, setPayload] = useState<any>({
        documentName: '',
        contractNumber: nanoid(9),
        contractType: '',
        commodities: [],
        shippingDeadline: Date.now(),
        containerType: '',
        portOfDischargeIds: [],
        portOfDischargeValue: undefined,
        portOfLoadingValue: undefined,
        portOfLoadingId: undefined,
        shippingLineIds: [],
        customerId: '',
        paymentTerms: '',
        shippingLineId: '',
        stamp: undefined,
        buyerAddress: '',
        exporterId: '',
        exporterAddress: '',
        additionalInfo: '',
        exporterSignature: ''
    });
    const [errors, setErrors] = useState({
        clientId: '',
        contractNumber: '',
        paymentTerms: '',
        stamp: '',
        contractType: '',
        commodity: '',
        commodityArr: '',
        commodityId: '',
        shippingDeadline: '',
        quantity: '',
        quantityUnit: '',
        containerType: '',
        contractStatus: '',
        portOfDischargeId: '',
        portOfDischargeValue: '',
        logoUrl: '',
        salesContractType: '',
        shippingLineId: '',
        location: '',
        buyerAddress: '',
        exporterId: '',
        exporterAddress: '',
        additionalInfo: '',
        exporterSignature: ''
    });

    const location = useLocation();
    const [params, setParams] = useState<any>();
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const param: any = Object.fromEntries(searchParams.entries());
        setParams(param);
    }, [location.search]);

    useEffect(() => {
        if (params?.edit) {
            fetchDocuments();
        }
    }, [params]);

    const fetchDocuments = async () => {
        setLoadingData(true);
        try {
            const res = await getSingleDocuments({
                corporateDocumentId: params?.edit
            });

            const { data } = res.data;
            setData(data);

            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            if (error?.response) {
                openNotification('error', `Documents, Something went wrong`);
            } else {
                openNotification('error', `Documents, Something went wrong`);
            }
        }
    };

    useEffect(() => {
        const transformArray = (array: any) => {
            const newArray: any = [];
            array.forEach((val: any) => {
                newArray.push({
                    hsCode: val.hsCode,
                    quantityBuffer: val.quantityBuffer,
                    quantity: val.quantity,
                    quality: val.quality,
                    commodityType: val.type,
                    commodityId: val.commodityId,
                    commodity: val.name,
                    metric: val.metric
                });
            });
            return newArray;
        };
        if (data) {
            setFileId(data?.id);
            setPayload({
                documentName: data?.documentName || '',
                contractNumber:
                    data?.documentData?.salesContractNumber || nanoid(9),
                contractType: data?.documentData?.contractType || '',
                shippingDeadline:
                    data?.documentData?.shippingDeadline || Date.now(),
                containerType: data?.documentData?.containerType || '',
                portOfDischargeIds:
                    [data?.documentData?.portsOfDestination[0].id] || [],
                portOfDischargeValue:
                    data?.documentData?.portsOfDestination[0] || '',
                portOfLoadingValue: data?.documentData?.portOfLoading || '',
                portOfLoadingId: data?.documentData?.portOfLoading.name || '',
                shippingLineIds:
                    [data?.documentData?.shippingLines[0].id] || [],
                paymentTerms: data?.documentData?.paymentTerms || '',
                shippingLineId: data?.documentData?.shippingLines[0].id || '',
                stamp: data?.documentData?.stamp || '',
                exporterSignature: data?.documentData?.exporterSignature || '',
                buyerAddress: data?.documentData?.buyerAddress || '',
                exporterId: data?.documentData?.exporterId || '',
                customerId: data?.documentData?.buyerId || '',
                exporterAddress: data?.documentData?.exporterAddress || '',
                additionalInfo: data?.documentData?.additionalInformation || ''
            });

            setCommodityFields(transformArray(data?.documentData?.commodities));
        }
    }, [data]);

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const closeShowAddParty = () => {
        setShowAddPartner(false);
    };

    /**
     * Update quotes input fields
     * @param id string
     * @param name string
     * @param value any
     */
    const updateCommodityField = (
        index: number,
        attributeName: string,
        attributeValue?: any
    ) => {
        const newContainers: any[] = [...commodityFields];
        newContainers[index][attributeName] = attributeValue;
        setCommodityFields(newContainers);
    };

    /**
     * Adds quotes input field line
     */
    const addCommodityField = () => {
        setCommodityFields([
            ...commodityFields,
            {
                id: nanoid(9),
                commodityId: '',
                commodity: '',
                commodityType: '',
                quality: '',
                quantity: 0,
                hsCode: '',
                quantityBuffer: '',
                metric: ''
            }
        ]);
    };

    /**
     * Remove quotes input field line
     */
    const removeCommodityField = (index: number) => {
        setCommodityFields(commodityFields.filter((item, i) => i !== index));
    };

    /**
     * Close save document
     */
    const closeSaveModal = () => {
        setShowSaveModal(false);
    };

    const onSubmit = async () => {
        setLoading(true);
        setError(null);
        setShowSaveModal(false);

        setErrors((prevState: any) => ({
            ...prevState,
            clientId: '',
            contractNumber: '',
            contractType: '',
            commodity: '',
            shippingDeadline: '',
            quantity: '',
            quantityUnit: '',
            containerType: '',
            contractStatus: '',
            portOfDischargeId: '',
            portOfDischargeValue: '',
            shippingLine: '',
            location: '',
            stamp: ''
        }));

        try {
            const items: any[] = [];

            const {
                contractNumber,
                contractType,
                shippingDeadline,
                containerType,
                portOfDischargeIds,
                portOfDischargeValue,
                portOfLoadingValue,
                portOfLoadingId,
                shippingLineIds,
                customerId,
                paymentTerms,
                documentName,
                shippingLineId,
                stamp,
                additionalInfo,
                buyerAddress,
                exporterAddress,
                exporterId,
                exporterSignature
            } = payload;

            const dischargeValue: any = portOfDischargeValue;
            const portOfLoading: any = portOfLoadingValue;

            commodityFields.forEach((item) => {
                items.push({
                    commodityId: item.commodityId,
                    quantity: item.quantity,
                    quantityBuffer: item.quantityBuffer,
                    metric: item.metric
                });
            });

            const res = await createSalesContract({
                createdType: 'CREATED',
                documentData: {
                    contractType,
                    buyerId: customerId,
                    salesContractNumber: contractNumber,
                    commodities: commodityFields,
                    shippingDeadline,
                    portOfLoadingId: portOfLoading?.id,
                    containerType,
                    paymentTerms,
                    portOfDischargeIds: [dischargeValue?.id],
                    shippingLineIds: [shippingLineId],
                    stamp,
                    additionalInfo,
                    exporterId,
                    exporterAddress,
                    exporterSignature,
                    buyerAddress
                },
                documentType: 'SALES_CONTRACT',
                documentName,
                id: fileId
            });

            const { id } = res.data.data;

            setFileId(id);

            openNotification('success', 'Sales Contract created successfully');

            setShowSaveModal(false);
            setLoading(false);

            if (saveOnly) {
                navigate('/trade-documents');
            }
        } catch (error) {
            window.scroll({
                top: pageTopRef?.current?.offsetTop,
                left: 0,
                behavior: 'smooth'
            });

            setShowSaveModal(false);
            setLoading(false);
            const otherErrors: any[] = [];

            if (error?.response) {
                if (error?.response?.data?.error) {
                    const resError = error?.response?.data?.error;

                    if (typeof resError === 'string') {
                        const errorList = (
                            <li key={`error-list-${1}`}>{resError}</li>
                        );
                        setError(errorList);
                    } else {
                        Object.entries(resError).forEach(([key, value]) => {
                            if (Object.keys(errors).includes(key)) {
                                setErrors((prevState) => ({
                                    ...prevState,
                                    [key]: value
                                }));
                            } else {
                                otherErrors.push(value);
                            }
                        });

                        if (otherErrors.length > 0) {
                            const errorList = otherErrors.map(
                                (element: any, index) => {
                                    return (
                                        <li key={`error-list-${index}`}>
                                            {element}
                                        </li>
                                    );
                                }
                            );
                            setError(errorList);
                        }
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    const breadcrumbs = [
        {
            title: 'Home',
            link: '/',
            active: false
        },
        {
            title: 'Documents',
            link: '/trade-documents',
            active: false
        },
        {
            title: 'Sales Contract',
            link: '/trade-documents/sales-contract',
            active: true
        }
    ];

    return (
        <TradeContainer>
            <FormHeader
                breadcrumbs={breadcrumbs}
                docTitle='Sales Contract'
                {...{
                    showPreview,
                    setShowPreview,
                    myDiv,
                    downloadLoading,
                    setDownloadLoading
                }}
                onSaveOnlyAction={() => {
                    setSaveOnly(true);
                    setShowSaveModal(true);
                }}
                onSaveAction={() => {
                    setSaveOnly(false);
                    setShowSaveModal(true);
                }}
                disabled={
                    Object.values(payload).some(
                        (item: any) => item === undefined
                    ) || loading
                }
            />

            <TradeContent>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                    open={loadingData}
                >
                    <img
                        src='/img/vectors/loadingcircles.svg'
                        alt='loading'
                        className='animate-spin'
                    />
                </Backdrop>{' '}
                {showPreview ? (
                    <div ref={myDiv}>
                        <SalesContractPreview
                            data={payload}
                            docTitle='Sales Contract'
                            quoteFields={commodityFields}
                            {...{
                                signatureUrlBase64Exporter
                            }}
                        />
                    </div>
                ) : (
                    <SalesContractForm
                        {...{
                            error,
                            updateField,
                            payload,
                            errors,
                            loading,
                            shippingLines,
                            corporatePartners,
                            loadingCorporatepartners: isLoading,
                            setShowAddPartner,
                            isLoadingShippingLines,
                            commodityFields,
                            updateCommodityField,
                            removeCommodityField,
                            addCommodityField,
                            commoditiesList,
                            isLoadingCommodities,
                            hsCodeList,
                            setSignatureUrlBase64Exporter,
                            signatureUrlBase64Exporter
                        }}
                    />
                )}
                <Addpartnerdrawer
                    closeDrawer={closeShowAddParty}
                    showDrawer={showAddPartner}
                />
                {showSaveModal && (
                    <CustomModal
                        open={showSaveModal}
                        onClose={closeSaveModal}
                        title='Saving document'
                        maxWidth='xs'
                        styles={{
                            overflowX: 'hidden',
                            '.MuiPaper-root': {
                                width: '100%'
                            }
                        }}
                    >
                        <Savetradedocument
                            updateField={updateField}
                            onSubmit={onSubmit}
                            loading={loading}
                            data={payload}
                        />
                    </CustomModal>
                )}
            </TradeContent>
        </TradeContainer>
    );
};

export default SalesContractNew;
