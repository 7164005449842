import { Grid, MenuItem, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import {
    medthodsOfDispatch,
    typesOfShipment
} from '../../../../../utils/appconstants';
import { CommercialTransportInterface } from '../../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/CommercialTypes';
import CountrySelector from '../../common/CountrySelector';

const MethodDetails = ({
    errors,
    payload,
    updateField,
    countries,
    isCountriesLoading
}: CommercialTransportInterface) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: '0px'
                        }}
                    >
                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                select
                                error={errors?.methodOfDispatch.length > 0}
                                helperText={
                                    errors?.methodOfDispatch.length > 0
                                        ? errors?.methodOfDispatch
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                value={payload?.methodOfDispatch || ''}
                                id='commercialinvoice-methodOfDispatch'
                                label='Method of Dispatch'
                                name='methodOfDispatch'
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'methodOfDispatch',
                                        e.target.value
                                    );
                                }}
                            >
                                {medthodsOfDispatch.map((item, index) => (
                                    <MenuItem
                                        value={item || ''}
                                        key={`medthodsOfDispatch-${item}-${index}`}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                select
                                error={errors?.typeOfShipment.length > 0}
                                helperText={
                                    errors?.typeOfShipment.length > 0
                                        ? errors?.typeOfShipment
                                        : undefined
                                }
                                margin='normal'
                                required
                                value={payload?.typeOfShipment || ''}
                                fullWidth
                                id='commercialinvoice-typeOfShipment'
                                label='Type of Shipment'
                                name='typeOfShipment'
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'typeOfShipment',
                                        e.target.value
                                    );
                                }}
                            >
                                {typesOfShipment.map((item, index) => (
                                    <MenuItem
                                        value={item || ''}
                                        key={`typesOfShipment-${item}-${index}`}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <CountrySelector
                error={errors?.countryOfOrigin.length > 0}
                helperText={
                    errors?.countryOfOrigin.length > 0
                        ? errors?.countryOfOrigin
                        : undefined
                }
                changeFunction={(e: ChangeEvent<HTMLInputElement>) => {
                    updateField('countryOfOrigin', e.target.value);
                    updateField(
                        'countryOfOriginName',
                        countries.filter(
                            (item: { id: string | number }) =>
                                item?.id === e.target.value
                        )[0]?.name
                    );
                }}
                value={payload?.countryOfOrigin || ''}
                id='commercialinvoice-countryOfOrigin'
                label='Country Of Origin of Goods'
                name='countryOfOrigin'
                {...{ isCountriesLoading, countries }}
            />
        </Grid>
    );
};

export default MethodDetails;
