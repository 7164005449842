import React, { Dispatch, SetStateAction, useState } from 'react';
import {
    Button,
    DatePicker,
    DatePickerProps,
    Divider,
    Dropdown,
    Input,
    MenuProps,
    Select,
    Space,
    Tooltip
} from 'antd';
import {
    contentStyle,
    headingStyle,
    menuStyle,
    subStyle
} from '../../../components/dashboard/tradeFilter/filterStyle';
import { Beneficiary } from './interfaces/Beneficiary';
import { GetTransactionsParams } from './interfaces/Transactions';
import { FilterIcon } from '../../../components/icons';
import CustomModal from '../../../components/CustomModal';
import { SuitUIButton } from '../../../components/SuitUIButton';

interface FilterProps {
    setTransactionFilterOptions: Dispatch<
        SetStateAction<GetTransactionsParams>
    >;
    beneficiaries: Beneficiary[];
}

const FilterTransactions = ({
    setTransactionFilterOptions,
    beneficiaries
}: FilterProps) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const [tempFilterOptions, setTempFilterOptions] =
        useState<GetTransactionsParams>({
            page: 1,
            size: 0
        });

    const onChangeFrom: DatePickerProps['onChange'] = (date) => {
        setTempFilterOptions({
            ...tempFilterOptions,
            createdDateFrom: date?.format('YYYY-MM-DD HH:mm:ss')
        });
    };

    const onChangeTo: DatePickerProps['onChange'] = (date) => {
        setTempFilterOptions({
            ...tempFilterOptions,
            createdDateTo: date?.format('YYYY-MM-DD HH:mm:ss')
        });
    };

    const statusOptions = [
        { label: 'COMPLETED', value: '1' },
        { label: 'INITIATED', value: '9' },
        { label: 'CANCELLED', value: '0' },
        { label: 'PROCESSING', value: '5,6,7,8' }
    ];

    const items = [
        {
            key: '1',
            label: (
                <div>
                    <label className='font-medium text-sm text-steelBlue leading-6'>
                        Amount Range
                    </label>
                    <div className='flex items-center gap-[12px]'>
                        <Input
                            type='number'
                            value={tempFilterOptions?.amountRangeStart}
                            style={{ height: '40px' }}
                            placeholder='Start amount'
                            onChange={(e) =>
                                setTempFilterOptions({
                                    ...tempFilterOptions,
                                    amountRangeStart: Number(e.target.value)
                                })
                            }
                        />
                        <Input
                            type='number'
                            value={tempFilterOptions?.amountRangeEnd}
                            style={{ height: '40px' }}
                            placeholder='End amount'
                            onChange={(e) =>
                                setTempFilterOptions({
                                    ...tempFilterOptions,
                                    amountRangeEnd: Number(e.target.value)
                                })
                            }
                        />
                    </div>
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '2',
            label: (
                <div className='mt-[16px] lg:mt-0'>
                    <label className='inline-block font-medium text-sm text-steelBlue leading-6'>
                        Beneficiary
                    </label>
                    <Select
                        style={{ width: '100%' }}
                        value={tempFilterOptions?.corporateBeneficiaryId}
                        onChange={(value: string) =>
                            setTempFilterOptions({
                                ...tempFilterOptions,
                                corporateBeneficiaryId: value
                            })
                        }
                        options={beneficiaries?.map((item: Beneficiary) => ({
                            value: item.id,
                            label: item.beneficiaryCompanyName
                        }))}
                        size='large'
                    />
                </div>
            )
        },

        {
            type: 'divider'
        },
        {
            key: '3',
            label: (
                <div className='mt-[16px] lg:mt-0'>
                    <label className='inline-block font-medium text-sm text-steelBlue leading-6'>
                        Created Date
                    </label>
                    <div className='flex items-center gap-[12px]'>
                        <DatePicker
                            placeholder='Start Date'
                            onChange={onChangeFrom}
                            showTime={{ format: 'HH:mm' }}
                            format='YYYY-MM-DD'
                            style={{ width: '100%', height: '40px' }}
                        />

                        <DatePicker
                            showTime={{ format: 'HH:mm' }}
                            format='YYYY-MM-DD'
                            placeholder='End Date'
                            onChange={onChangeTo}
                            style={{ width: '100%', height: '40px' }}
                        />
                    </div>
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '5',
            label: (
                <div className='mt-[16px] lg:mt-0'>
                    <label className='inline-block font-medium text-sm text-steelBlue leading-6'>
                        Status
                    </label>

                    <Select
                        className='h-[40px] w-full'
                        value={tempFilterOptions?.status}
                        onChange={(value: string) =>
                            setTempFilterOptions({
                                ...tempFilterOptions,
                                status: value
                            })
                        }
                        options={statusOptions}
                        size='large'
                    />
                </div>
            )
        }
    ];

    const handleMenuClick = () => {
        setTransactionFilterOptions(tempFilterOptions);
        setDropdownVisible(false);
        setModalVisible(false);
    };

    const handleButtonClick = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const dropdownItems = items as MenuProps['items'];

    return (
        <>
            <div className='hidden lg:block'>
                <Dropdown
                    menu={{ items: dropdownItems }}
                    trigger={['click']}
                    visible={dropdownVisible}
                    overlayStyle={{
                        pointerEvents: 'auto'
                    }}
                    dropdownRender={(menu) => (
                        <div style={contentStyle}>
                            <h2 style={headingStyle}>Filters</h2>
                            <p style={subStyle}>
                                Filters will be added to the table.
                            </p>
                            {React.cloneElement(menu as React.ReactElement, {
                                style: menuStyle
                            })}
                            <Divider style={{ margin: 0 }} />
                            <Space
                                style={{
                                    padding: '1rem',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}
                            >
                                <Button
                                    className='reset-btn'
                                    onClick={() => {
                                        setTransactionFilterOptions({
                                            page: 1,
                                            size: 0
                                        });
                                        setTempFilterOptions({
                                            page: 1,
                                            size: 0
                                        });
                                        setDropdownVisible(false);
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button
                                    className='bg-appcolorprimarydeep text-appcolorwhite border-0 hover:bg-appcolorwhite hover:border-appcolorprimarydeep hover:border  hover:text-appcolorprimary'
                                    onClick={handleMenuClick}
                                >
                                    Apply Filter
                                </Button>
                            </Space>
                        </div>
                    )}
                >
                    <Tooltip title='Apply filters' arrow>
                        <button
                            className='tab-buttons mr-2 flex'
                            onClick={handleButtonClick}
                        >
                            <div className='pr-2'>
                                <img src='/img/vectors/filter.svg' alt='' />
                            </div>
                            Filter
                        </button>
                    </Tooltip>
                </Dropdown>
            </div>

            <div className='lg:hidden w-[100vw]'>
                <CustomModal
                    maxWidth='lg'
                    open={modalVisible}
                    onClose={() => setModalVisible(false)}
                    hideCloseIcon
                >
                    <div className='w-full  px-[16px] pt-[40px]'>
                        <h2 className='text-ebony mb-[16px]'>Filters</h2>

                        {items?.map((item) => item?.label)}

                        <div className='flex justify-between pt-[40px] pb-[80px]'>
                            <SuitUIButton
                                text='Cancel'
                                onClick={() => setModalVisible(false)}
                                invert
                                style='h-[40px] border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                            />
                            <div className='flex gap-[24px]'>
                                <SuitUIButton
                                    text='Reset'
                                    onClick={() => {
                                        setTransactionFilterOptions({
                                            page: 1,
                                            size: 0
                                        });
                                        setTempFilterOptions({
                                            page: 1,
                                            size: 0
                                        });
                                    }}
                                    variant='secondary'
                                />
                                <SuitUIButton
                                    text='Apply Filter'
                                    onClick={handleMenuClick}
                                    style='h-[40px]'
                                />
                            </div>
                        </div>
                    </div>
                </CustomModal>

                <button
                    className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base lg:h-[unset] lg:w-[unset] lg:text-black lg:text-sm lg:gap-[unset] lg:mx-2'
                    onClick={() => setModalVisible(true)}
                >
                    <FilterIcon />
                    Filter
                </button>
            </div>
        </>
    );
};

export default FilterTransactions;
