import { useState } from 'react';
import { SalesContainer } from '../../../../styles/SalesStyles';
import SalesHeader from '../../../../components/dashboard/sales/SalesHeader';
import InvoiceSummary from '../../../../components/dashboard/sales/invoice/InvoiceSummary';
import InvoiceTable from '../../../../components/dashboard/sales/invoice/InvoiceTable';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { Backdrop } from '@mui/material';
import EnablingFeature from '../../../../components/EnablingFeature';
import { useInvoices } from '../../../../customhooks/useInvoices';
import useCorporatePartners from '../../../../customhooks/useCorporatePartners';
import { Invoice, InvoiceParams } from '../../payments/interfaces/Invoice';
import { LoadingCircles } from '../../../../components/icons/LoadingCircles';
import CreateInvoice from '../../../../components/dashboard/sales/invoice/CreateInvoice';

const Invoices = () => {
    const [filterInvoiceOptions, setFilterInvoiceOptions] =
        useState<InvoiceParams>({} as InvoiceParams);
    const [currentData, setCurrentData] = useState<Invoice>({} as Invoice);

    const { user } = useSelector((state: RootState) => state.user);

    const {
        entries,
        isFetching,
        isLoading,
        invalidateLogisticsInvoice: refetchInvoice,
        paging
    } = useInvoices({
        corporateId: user?.corporate_id
    });

    const {
        corporatePartners,
        isLoading: loadingCorporatePartners,
        invalidateCorporatePartners: fetchPartners
    } = useCorporatePartners({ page: 1 });

    return (
        <EnablingFeature flag='Invoicing'>
            <SalesContainer>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                    open={isFetching || isLoading}
                >
                    <div className='animate-spin'>
                        <LoadingCircles />
                    </div>
                </Backdrop>
                <div className='px-4 lg:px-0'>
                    <SalesHeader
                        title='Invoices'
                        {...{
                            filterInvoiceOptions,
                            setFilterInvoiceOptions
                        }}
                    />
                    <InvoiceSummary />
                </div>
                <div className='px-[16px] lg:hidden flex justify-between gap-[8px] pt-4'>
                    <CreateInvoice
                        {...{
                            corporatePartners,
                            loadingCorporatePartners,
                            fetchPartners
                        }}
                    />
                </div>
                <InvoiceTable
                    {...{
                        currentData,
                        setCurrentData,
                        refetchInvoice,
                        paging,
                        filterInvoiceOptions,
                        setFilterInvoiceOptions,
                        corporatePartners
                    }}
                    data={entries}
                    loadingData={isFetching || isLoading}
                />
            </SalesContainer>
        </EnablingFeature>
    );
};

export default Invoices;
