export const Cross = () => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M4.59388 4.59388C5.1634 4.02437 6.08676 4.02437 6.65628 4.59388L10.0001 7.93769L13.3439 4.59388C13.9134 4.02437 14.8368 4.02437 15.4063 4.59388C15.9758 5.1634 15.9758 6.08676 15.4063 6.65628L12.0625 10.0001L15.4063 13.3439C15.9758 13.9134 15.9758 14.8368 15.4063 15.4063C14.8368 15.9758 13.9134 15.9758 13.3439 15.4063L10.0001 12.0625L6.65628 15.4063C6.08676 15.9758 5.1634 15.9758 4.59388 15.4063C4.02437 14.8368 4.02437 13.9134 4.59388 13.3439L7.93769 10.0001L4.59388 6.65628C4.02437 6.08676 4.02437 5.1634 4.59388 4.59388Z'
                fill='#200E32'
            />
        </svg>
    );
};
