import { Box, Typography } from '@mui/material';

export const ReeferNonOperating = () => {
    return (
        <Box className='space-y-4 mt-4 mb-8'>
            <Typography variant='subtitle1' sx={{ fontWeight: 700 }}>
                Container Set to Non-Operating Reefer
            </Typography>
            <Typography>
                Temperature, air-flow, humidity, vent and additional settings
                will not be applied
            </Typography>
        </Box>
    );
};
