import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Drawer from '@mui/material/Drawer';
import Custombutton from '../../../../components/custombutton/custombutton';
import { bookingProfile } from '../../../../api/booking/booking';
import { openNotification } from '../../../../utils/helper';
import { useMediaQuery, useTheme } from '@mui/material';

export interface newcarrierDrawerProps {
    closeDrawer: (updateUser?: boolean) => void;
    showDrawer: boolean;
    corporateId: number;
}

const Bookingsetupdrawer = ({
    closeDrawer,
    showDrawer,
    corporateId
}: newcarrierDrawerProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [error, setError] = useState<string | any>(null);
    const [loading, setLoading] = useState(false);

    const [payload, setPayload] = useState<any>({
        address: undefined,
        jobFunction: undefined,
        postCode: undefined,
        state: undefined,
        taxId: undefined
    });
    const [errors, setErrors] = useState<any>({
        address: '',
        jobFunction: '',
        postCode: '',
        state: '',
        taxId: ''
    });

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrors((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));
    };

    /**
     * Submit form
     * @param e Object
     */
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        setErrors((prevState: any) => ({
            ...prevState,
            address: '',
            jobFunction: '',
            postCode: '',
            state: '',
            taxId: ''
        }));

        try {
            const res = await bookingProfile(corporateId, payload);

            const { data } = res.data;

            setLoading(false);

            openNotification('success', 'Booking profile created successfully');

            closeDrawer(true);
        } catch (error) {
            setLoading(false);
            if (error?.response) {
                if (error?.response?.data?.error) {
                    let hasFieldNameAttribute = false;

                    error?.response?.data?.error.map((item: any) => {
                        return Object.keys(item).map((itemInside: string) => {
                            if (itemInside === 'fieldName') {
                                hasFieldNameAttribute = true;

                                return;
                            }
                        });
                    });

                    if (hasFieldNameAttribute) {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>
                                    {element.messageError}
                                </li>
                            );
                        });
                        setError(errorList);
                    } else {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>{element}</li>
                            );
                        });
                        setError(errorList);
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    return (
        <div>
            <div
                className='slide-in-right active bg-appcolorwhite rounded-[50%] top-[1.2rem] right-[28.4rem] fixed p-4 z-[2000] cursor-pointer'
                onClick={() => {
                    closeDrawer();
                }}
            >
                <img src='/img/vectors/cross.svg' alt='cross' />
            </div>
            <Drawer
                anchor={isMobile ? 'bottom' : 'right'}
                open={showDrawer}
                onClose={() => {
                    closeDrawer();
                }}
                sx={{
                    '.MuiDrawer-paperAnchorRight': {
                        width: {
                            xs: '95%',
                            lg: '30rem'
                        }
                    }
                }}
            >
                <div className='px-[3.2rem] py-[2.1rem]'>
                    <h1 className='text-[24px] leading-[23px] text-appcolorblacktwo font-semibold'>
                        Set up Booking Profile
                    </h1>

                    {error !== null && (
                        <div className='mt-10'>
                            <Alert severity='error'>{error}</Alert>
                        </div>
                    )}

                    <div className='my-4'>
                        <form autoComplete='off' onSubmit={onSubmit} noValidate>
                            {/* <div className='mb-4'>
                <Customselect
                  required
                  showSearch={true}
                  selectOptionLabel="name"
                  loading={loadingShippingLines}
                  selectOptions={[
                    {
                      name: 'bello',
                      id: 10
                    }
                  ]}
                  name={'shippingLineId'}
                  updateField={(_, value) => {
                    updateField('shippingLineId', value)
                  }}
                  label={'Shipping Line'}
                />
              </div> */}

                            <TextField
                                value={payload?.jobFunction}
                                error={errors?.jobFunction.length > 0}
                                helperText={
                                    errors?.jobFunction.length > 0
                                        ? errors?.jobFunction
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='createnewcarrier-jobFunction'
                                label='Job Function'
                                name='jobFunction'
                                onChange={(e: any) => {
                                    updateField('jobFunction', e.target.value);
                                }}
                            />

                            <TextField
                                value={payload?.postCode}
                                error={errors?.postCode.length > 0}
                                helperText={
                                    errors?.postCode.length > 0
                                        ? errors?.postCode
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='createnewcarrier-postCode'
                                label='Postal Code'
                                name='postCode'
                                onChange={(e: any) => {
                                    updateField('postCode', e.target.value);
                                }}
                            />

                            {/* <TextField
                value={payload?.blNumber}
                error={errors?.blNumber.length > 0}
                helperText={errors?.blNumber.length > 0 ? errors?.blNumber : undefined}
                margin="normal"
                required
                fullWidth
                id="createnewcarrier-blNumber"
                label=" BL Number"
                name="blNumber"
                onChange={(e: any) => {
                  updateField('blNumber', e.target.value)
                }}
              /> */}

                            <TextField
                                value={payload?.state}
                                error={errors?.state.length > 0}
                                helperText={
                                    errors?.state.length > 0
                                        ? errors?.state
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='createnewcarrier-state'
                                label='State'
                                name='state'
                                onChange={(e: any) => {
                                    updateField('state', e.target.value);
                                }}
                            />

                            <TextField
                                value={payload?.taxId}
                                error={errors?.taxId.length > 0}
                                helperText={
                                    errors?.taxId.length > 0
                                        ? errors?.taxId
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='createnewcarrier-taxId'
                                label='Government Tax ID'
                                name='taxId'
                                onChange={(e: any) => {
                                    updateField('taxId', e.target.value);
                                }}
                            />

                            <TextField
                                multiline={true}
                                value={payload?.bookingNumber}
                                error={errors?.address.length > 0}
                                helperText={
                                    errors?.address.length > 0
                                        ? errors?.address
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='existingbooking-address'
                                label='Address'
                                name='address'
                                onChange={(e: any) => {
                                    updateField('address', e.target.value);
                                }}
                            />

                            <div className='mt-4'>
                                <Custombutton
                                    isLoadingButton
                                    loading={loading}
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    styles={{
                                        padding:
                                            '0.75rem 0.62rem 0.9rem 0.93rem'
                                    }}
                                    disabled={
                                        Object.values(payload).some(
                                            (item: any) => item === undefined
                                        ) || loading
                                    }
                                    buttonText='Submit'
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default Bookingsetupdrawer;
