import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Tile from './tile';
import { Box, Button, IconButton } from '@mui/material';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import { DocumentTableContainer } from '../../../../../styles/TradeDocStyles';
import { OceanFetcherResponse } from '../../ocean-schedules';
import { Table } from 'antd';
import React, { useState } from 'react';
import CustomModal from '../../../../../components/CustomModal';
import { underScoreToNormalString } from '../../../../../utils/helper';
import { SearchResult } from '../../interfaces/SearchResults';
import dayjs from 'dayjs';

interface ListResultsProps extends OceanFetcherResponse {
    handleBookButtonClick: (data: SearchResult) => void;
    isProfileExistsFn: (id: string) => boolean;
}

interface EmptinessProps {
    title?: string;
    subtitle?: string;
    icon?: React.ReactNode;
}

export const EmptinessPlaceholder = (props: EmptinessProps) => {
    return (
        <div className='flex flex-col justify-center mt-8 mb-4 align-center'>
            <div className='flex justify-center mb-4'>{props.icon}</div>

            <div className='text-center text-appcolorblackthree'>
                {props.title && (
                    <b style={{ fontSize: '2em' }}>{props.title}</b>
                )}
                {props.subtitle && (
                    <p
                        style={{
                            textTransform: 'capitalize',
                            fontSize: '1.3em'
                        }}
                    >
                        {props.subtitle}
                    </p>
                )}
                <br />
            </div>
        </div>
    );
};

const ListResults = (props: ListResultsProps) => {
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<SearchResult | null>(null);

    const tableColumns: (
        | ColumnGroupType<SearchResult>
        | ColumnType<SearchResult>
    )[] = [
        {
            title: 'Carrier',
            dataIndex: 'carrier',
            key: 'carrier',
            sorter: (a, b) => a?.carrier?.localeCompare(b?.carrier),
            defaultSortOrder: 'ascend'
        },
        {
            title: 'Vessel Voyage',
            dataIndex: 'vessel',
            key: 'vessel',
            render: (_, data) => (
                <span>
                    {data.vessel ? data.vessel + ' / ' + data.voyage : '--'}
                </span>
            )
        },
        {
            title: 'Service',
            dataIndex: 'service',
            key: 'service',
            render: (_, data) => (
                <span>{data.service ? data.service : '--'}</span>
            )
        },
        {
            title: 'Departs',
            dataIndex: 'departs',
            key: 'departs',
            render: (_, { departs }) => (
                <span>
                    {dayjs
                        .unix(Number(departs))
                        .format('DD-MMM-YYYY')
                        .toUpperCase()}
                </span>
            )
        },
        {
            title: 'Cut-Off',
            dataIndex: 'cutoff',
            key: 'cutoff',
            render: (_, { cutoff }) => (
                <span>
                    {dayjs
                        .unix(Number(cutoff))
                        .format('DD-MMM-YYYY')
                        .toUpperCase()}
                </span>
            )
        },
        {
            title: 'Arrives',
            dataIndex: 'arrives',
            key: 'arrives',
            render: (_, { arrives }) => (
                <span>
                    {dayjs
                        .unix(Number(arrives))
                        .format('DD-MMM-YYYY')
                        .toUpperCase()}
                </span>
            )
        }
    ];

    const transitColumn: ColumnType<SearchResult> = {
        title: 'Transit',
        dataIndex: 'transit',
        key: 'transit'
    };

    const InfoColumn: ColumnType<SearchResult> = {
        title: 'Info',
        render: (_, data) => (
            <div>
                <IconButton
                    onClick={() => {
                        setModalData(data);
                        setShowInfoModal(true);
                    }}
                >
                    <InsertDriveFileOutlinedIcon />
                </IconButton>
            </div>
        )
    };

    const bookColumn: ColumnType<SearchResult> = {
        title: 'Book',
        render: (_, data) => (
            <div className='cursor-pointer'>
                <Button
                    className='action-btn'
                    onClick={() => props.handleBookButtonClick(data)}
                >
                    {props.isProfileExistsFn(data.carrierId)
                        ? 'Book'
                        : 'Connect'}
                </Button>
            </div>
        )
    };

    if (props.source === 'by-points') {
        tableColumns.push(transitColumn);
        tableColumns.push(InfoColumn);
    }

    tableColumns.push(bookColumn);

    return (
        <>
            <DocumentTableContainer>
                <Table
                    loading={props.stillLoading}
                    dataSource={props?.results ?? []}
                    locale={{
                        emptyText: () => {
                            let title = 'Check vessel schedules online.';
                            let subtitle =
                                'At FrontEdge, we allow you to check shipping schedules by routing, by port, by vessel, or by carrier.';
                            let icon = (
                                <Box
                                    component='img'
                                    src='/img/vectors/osc-pre-search.svg'
                                    sx={{
                                        width: { md: 750, sm: 300 }
                                    }}
                                />
                            );
                            if (props.hasApiResponse) {
                                title = 'No schedules found.';
                                subtitle = props.errorMessage
                                    ? underScoreToNormalString(
                                          props.errorMessage
                                      )
                                    : 'We are unable to provide this schedule at this moment. Please do another search.';
                                icon = <ErrorOutlineOutlinedIcon />;
                            }
                            return (
                                <EmptinessPlaceholder
                                    title={title}
                                    subtitle={subtitle}
                                    icon={icon}
                                />
                            );
                        }
                    }}
                    pagination={{
                        pageSize: 10
                    }}
                    columns={tableColumns}
                />
            </DocumentTableContainer>

            <CustomModal
                open={showInfoModal}
                onClose={() => setShowInfoModal(false)}
                maxWidth='xl'
            >
                <Tile
                    data={modalData}
                    from=''
                    to=''
                    modal
                    handleBookButtonClick={props.handleBookButtonClick}
                    isProfileExistsFn={props.isProfileExistsFn}
                />
            </CustomModal>
        </>
    );
};

export default ListResults;
