import { SuitUIButton } from './SuitUIButton';

interface CustomModalActionsProps {
    onHandleSave: () => void;
    onHandleClear: () => void;
    disabled?: boolean;
}

export const CustomModalActions = (props: CustomModalActionsProps) => {
    const { onHandleSave, onHandleClear, disabled } = props;

    return (
        <div className='flex gap-[16px] items-center justify-end lg:mb-0'>
            <SuitUIButton
                text='Cancel'
                onClick={onHandleClear}
                invert
                fullWidth
                style='px-[0.92rem] border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
            />

            <SuitUIButton
                text='Save'
                onClick={onHandleSave}
                fullWidth
                style='px-[0.92rem]'
                disabled={disabled}
            />
        </div>
    );
};
