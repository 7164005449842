import validator from 'validator';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { toast } from 'react-toastify';
import currencyToSymbolMap from 'currency-symbol-map';
import { AxiosError } from 'axios';
import { Transactions } from '../pages/dashboard/payments/interfaces/Transactions';
import { User } from '../interfaces/User';

/**
 * Returns curecnyt symbol from cuurrenct code
 * @param currencyCode string
 * @returns string
 */
export const fetchCurrencySymbol = (currencyCode: string) => {
    if (currencyCode === '') return '';
    return currencyToSymbolMap(currencyCode);
};

/**
 * Parse function for commaNumber
 * @param string
 * @param separator
 * @returns string
 */
const parse = (string: any, separator: any) => {
    let i = ((string.length - 1) % 3) + 1;

    if (i === 1 && string[0] === '-') {
        i = 4;
    }

    const strings = [string.slice(0, i)];

    for (; i < string.length; i += 3) {
        strings.push(separator, string.substr(i, 3));
    }

    return strings;
};

/**
 * Return a string with the provided number formatted with commas.
 *  Can specify either a Number or a String.
 * @param number || string inputNumber
 * @param optionalSeparator
 * @param optionalDecimalChar
 * @returns  string
 */
export const commaNumber = (
    inputNumber: number | string,
    optionalSeparator?: any,
    optionalDecimalChar?: any
) => {
    const decimalChar = optionalDecimalChar || '.';

    let stringNumber;

    {
        let number;

        switch (typeof inputNumber) {
            case 'string':
                if (inputNumber.length < (inputNumber[0] === '-' ? 5 : 4)) {
                    return inputNumber;
                }

                stringNumber = inputNumber;

                number = Number(
                    decimalChar !== '.'
                        ? stringNumber.replace(decimalChar, '.')
                        : stringNumber
                );
                break;

            case 'number':
                stringNumber = String(inputNumber);
                number = inputNumber;

                if ('.' !== decimalChar && !Number.isInteger(inputNumber)) {
                    stringNumber = stringNumber.replace('.', decimalChar);
                }
                break;

            default:
                return inputNumber;
        }

        if (
            (-1000 < number && number < 1000) ||
            isNaN(number) ||
            !isFinite(number)
        ) {
            return stringNumber;
        }
    }

    {
        const decimalIndex = stringNumber.lastIndexOf(decimalChar);
        let decimal;
        if (decimalIndex > -1) {
            decimal = stringNumber.slice(decimalIndex);
            stringNumber = stringNumber.slice(0, decimalIndex);
        }

        const parts = parse(stringNumber, optionalSeparator || ',');

        if (decimal) {
            parts.push(decimal);
        }

        return parts.join('');
    }
};

/**
 * Validates password
 * @param  password string
 * @returns boolean
 */
export const validatePassword = (password: string | undefined) => {
    if (password === undefined) {
        return false;
    } else {
        if (
            validator.isStrongPassword(password.trim(), {
                minLength: 8,
                minLowercase: 1,
                minUppercase: 1,
                minNumbers: 1,
                minSymbols: 1
            })
        ) {
            return true;
        }
        return false;
    }
};

/**
 * Validates email address
 * @param email string
 * @returns boolean
 */
export const validateEmail = (email: string) => {
    if (email === undefined) {
        return false;
    } else {
        if (validator.isEmail(email)) {
            return true;
        }
        return false;
    }
};

/**
 * Validates phone number
 * @param  phoneNumber string
 * @returns boolean
 */
export const validatePhoneNumber = (phoneNumber: string | undefined) => {
    if (phoneNumber === undefined) {
        return false;
    } else {
        return isValidPhoneNumber(phoneNumber);
    }
};

/**
 * Removes the nth character from the string from the beginning
 * @param  value string
 * @param  n number
 * @returns
 */
export const removeChar = (value: string, n: number) => {
    const str = value.slice(n);
    return str;
};

/**
 * Manages notifications
 * @param type string
 * @param message string
 * @returns
 */
export const openNotification = (
    type: 'info' | 'success' | 'warning' | 'error' | 'default',
    message: string
) => {
    switch (type) {
        case 'info':
            return toast.info(message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
        case 'success':
            return toast.success(message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
        case 'warning':
            return toast.warning(message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
        case 'error':
            return toast.error(message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
        default:
            return toast(message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
    }
};

/**
 * Gets the union between two arrays based on name of attribute
 * @param array1 Object
 * @param array2 Object
 * @param name string
 * @returns Object
 */
export const getUnion = (array1: any[], array2: any[], name: string) => {
    return array1.filter((object1) => {
        return array2.some((object2) => {
            return object1[name] === object2[name];
        });
    });
};

/**
 * Returns the appropriate flag for the currency passed
 * @param currency string
 * @returns string
 */
export const getCountryFlag = (currency: string) => {
    switch (currency) {
        case 'Naira':
            return '🇳🇬';
        case 'US Dollar':
            return '🇺🇸';
        case 'GB Pound':
            return '🇬🇧';
        case 'Euro':
            return '🇪🇺';
        case 'Dirham':
            return '🇦🇪';
        case 'Australian Dollar':
            return '🇦🇺';
        case 'Chinese Yen':
            return '🇨🇳';
        case 'Canadian Dollar':
            return '🇨🇦';
        case 'NGN':
            return '🇳🇬';
        case 'USD':
            return '🇺🇸';
        case 'GBP':
            return '🇬🇧';
        case 'EUR':
            return '🇪🇺';
        case 'AED':
            return '🇦🇪';
        case 'AUD':
            return '🇦🇺';
        case 'CNY':
            return '🇨🇳';
        case 'CAD':
            return '🇨🇦';
        default:
            break;
    }
};

/**
 * Validate conversion (true or false)
 * @param value string
 * @returns boolean
 */
export const validateConversionInput = (value: string) => {
    const inputValue = value.split('-');
    const amoutValue = inputValue[0];
    const currency = inputValue[1];

    return amoutValue.length !== 0 && currency.length !== 0;
};

/**
 * Validate conversion currency
 * @param value string
 * @returns boolean
 */
export const validateConversionInputCurrency = (value: string) => {
    const inputValue = value.split('-');
    const currency = inputValue[1];

    return currency.length !== 0;
};

/**
 * Validate conversion amount
 * @param value string
 * @returns boolean
 */
export const validateConversionInputAmount = (value: string) => {
    const inputValue = value.split('-');
    const amount = inputValue[0];

    return amount.length !== 0;
};

/**
 * Validate conversion (string)
 * @param value string
 * @returns string
 */
export const validateConversionInputResponse = (value: string) => {
    const inputValue = value.split('-');
    const amoutValue = inputValue[0];
    const currency = inputValue[1];
    let errorResponse = '';
    if (amoutValue.length === 0 && currency.length === 0) {
        errorResponse = 'Amount and currency is required';
    }
    if (amoutValue.length === 0 && currency.length !== 0) {
        errorResponse = 'Amount is required';
    }
    if (amoutValue.length !== 0 && currency.length === 0) {
        errorResponse = 'Currency is required';
    }

    return errorResponse;
};

/**
 * Returns the appropriate transaction status
 * @param  value number
 * @returns string
 */
export const getTransactionStatus = (value: number | undefined) => {
    switch (value) {
        case 0:
            return 'CANCELED';
        case 9:
            return 'INITIATED';
        case 8:
            return 'PROCESSING';
        case 7:
            return 'PROCESSING';
        case 6:
            return 'PROCESSING';
        case 5:
            return 'PROCESSING';
        case 1:
            return 'COMPLETED';
        default:
            return '-';
    }
};

/**
 * Returns the appropriate transaction type
 * @param  value number
 * @returns string
 */
export const getTransactionType = (value: number | undefined) => {
    switch (value) {
        case 1:
            return 'FX CONVERSION';
        case 2:
            return 'PAYMENT';
        case 3:
            return 'PAYMENT';
        case 0:
            return 'CREDIT';
        default:
            return '-';
    }
};

/**
 * Capitalize first text of a string
 * @param  value string
 * @returns string
 */
export const capitalizeTextChar = (value?: string) => {
    if (value === undefined) {
        return '';
    } else {
        const capitalizedText = value.charAt(0).toUpperCase();
        return capitalizedText;
    }
};

/**
 * Returns the appropriate beneficiary status
 * @param  value number
 * @returns string
 */
export const getBeneficiaryStatus = (value: number) => {
    switch (value) {
        case 0:
            return 'DISABLED';
        case 1:
            return 'ENABLED';
        case 2:
            return 'PENDING';
        case 3:
            return 'PENDING';
        case 4:
            return 'PENDING';
        case 5:
            return 'PENDING';
        default:
            return '-';
    }
};

export const getCorpotateStatus = (value: number) => {
    switch (value) {
        case 0:
            return 'DISABLED';
        case 1:
            return 'ENABLED';
        case 2:
            return 'PENDING';
        case 3:
            return 'PENDING';
        case 4:
            return 'PENDING';
        case 5:
            return 'REJECTED';
        default:
            return '-';
    }
};

export const getBookingStatus = (value: number) => {
    switch (value) {
        case 0:
            return 'DISABLED';
        case 1:
            return 'COMPLETED';
        case 2:
            return 'ACTIVE';
        case 3:
            return 'INITIATED';
        case 4:
            return 'FAILED';
        case 5:
            return 'PENDING';
        default:
            return '-';
    }
};

export const getAnythingElseStatus = (value: number) => {
    switch (value) {
        case 0:
            return 'DISABLED';
        case 1:
            return 'ENABLED';
        case 2:
            return 'PENDING';
        case 3:
            return 'PENDING';
        case 4:
            return 'PENDING';
        case 5:
            return 'REJECTED';
        default:
            return '-';
    }
};

/**
 * Calculates file size
 * @param  bytes number
 * @param  decimals number
 * @returns string
 */
export const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

/**
 * Returns the appropriate file type img src
 * @param fileName string name of the file
 * @returns string
 */
export const getFileIcon = (fileName: string) => {
    const fileLength = fileName.split('.').length;
    const fileType = fileName.split('.')[fileLength - 1];

    switch (fileType) {
        case '.pdf':
            return '/img/vectors/pdficon.svg';
        case '.jpg':
            return '/img/vectors/jpgicon.svg';
        default:
            return '/img/vectors/jpgicon.svg';
    }
};

/**
 * Returns the appropriate currency format
 * @param value string
 * @param curr string
 * @returns string
 */
export function formatCurrency(value: number, curr: string) {
    if (curr) {
        return new Intl.NumberFormat(curr === 'NGN' ? 'en-NG' : 'en-US', {
            style: 'currency',
            currency: curr,
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
        }).format(value);
    }
    return new Intl.NumberFormat('en-NG', {
        style: 'currency',
        currency: 'NGN',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    }).format(value);
}

/**
 * Returns the appropriate number format
 * @param number number
 * @returns string
 */
export const formatNumber = (number: number) => {
    return new Intl.NumberFormat().format(number);
};

/**
 * Returns the appropriate number format
 * @param number number
 * @returns string
 */

export const formatDecimalNumber = (number: number) => {
    return new Intl.NumberFormat('en-NG', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    }).format(number);
};

export const pascalToHyphenString = (pascalCase: string): string => {
    return pascalCase.replace(/_/g, '-').toLowerCase();
};
export const underScoreToNormalString = (text: string): string => {
    return text.replace(/_/g, ' ').toLowerCase();
};
export const getContractStatus = (value: number) => {
    switch (value) {
        case 0:
            return 'Cancelled';
        case 1:
            return 'Completed';
        case 2:
            return 'Draft';
        case 3:
            return 'Issued';
        case 4:
            return 'Approved';
        case 5:
            return 'Booking Placed';
        case 6:
            return 'Expired';
        case 7:
            return 'Stock Allocated';
        default:
            return '-';
    }
};

export const getInvoiceStatus = (value: number) => {
    switch (value) {
        case 0:
            return 'Cancelled';
        case 1:
            return 'Paid';
        case 2:
            return 'Draft';
        case 3:
            return 'Partially Paid';
        case 4:
            return 'Overdue';
        case 5:
            return 'Write Off';
        case 6:
            return 'Issued';
        default:
            return '-';
    }
};
export const getDedicatedAccountStatus = (value: number) => {
    switch (value) {
        case 0:
            return 'Rejected';
        case 1:
            return 'Approved';
        case 2:
            return 'Pending';
        default:
            return '-';
    }
};
export const getFileName = (url: string, limit: number = 30) => {
    if (url.length > limit) {
        return url.substring(0, limit) + '...';
    }
    return url;
};

export const getErrorMessage = (error: AxiosError | any) => {
    const errorList = error?.response?.data?.error;

    if (!errorList) {
        return error?.response?.data?.message;
    }

    return typeof errorList === 'string'
        ? errorList
        : errorList[Object.keys(errorList)[0]];
};

export const getRecipientName = ({transactionDetails, user}: {transactionDetails?: Transactions; user: User | null}) => {
    const { extra, transactionAction } = transactionDetails || {};

    if (extra && transactionAction !== 'CREDIT') {
        const nameFound = extra.find((extra) => extra.key === 'destination')
            ?.value;
        return nameFound
            ? `${nameFound.split(' ')[0]} ${nameFound.split(' ')[1]}`
            : '';
    } else {
        return user?.name || '';
    }
};


export const hasSymbol = (str: string) => {
    return /[^A-Za-z0-9]/.test(str)
}

export const hasUpperCase = (str: string) => {
    return /[A-Z]/.test(str)
}

export const hasLowerCase = (str: string) => {
    return /[a-z]/.test(str)
}

export const hasDigit = (str: string) => {
    return /\d/.test(str)
}
