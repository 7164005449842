import { User } from '../../../../interfaces/User';
import { BookingForm } from './interfaces/BookingForm';
import { PartiesFormInput } from './PartiesFormInput';
import { FormikProps } from 'formik';

interface PartiesFormProps {
    user: User | null;
    formData: BookingForm;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
    setShowAddParty: React.Dispatch<React.SetStateAction<boolean>>;
    formik: FormikProps<BookingForm>;
}

export const PartiesForm = ({
    user,
    formData,
    setFormData,
    setShowAddParty,
    formik
}: PartiesFormProps) => {
    return (
        <div className='flex flex-col lg:flex-row gap-4'>
            <div className='w-full'>
                <PartiesFormInput
                    user={user}
                    required={true}
                    value={formData.parties?.shipper}
                    isDisabled={!!formData.parties?.forwarder}
                    label='Shipper'
                    setFormData={setFormData}
                    setShowAddParty={setShowAddParty}
                    error={!!formik.errors.parties}
                    helperText={
                        formik.errors.parties && String(formik.errors.parties)
                    }
                    onHandleChange={(_, value) => {
                        setFormData((prev) => {
                            const newObject = { ...prev };

                            delete newObject.parties?.shipper;

                            const update = {
                                ...newObject,
                                parties: {
                                    ...newObject.parties,
                                    ...(value
                                        ? { shipper: value?.corporateId }
                                        : null)
                                }
                            };

                            formik.setValues(update);

                            return update;
                        });
                    }}
                />
            </div>
            <div className='w-full'>
                <PartiesFormInput
                    user={user}
                    value={formData.parties?.forwarder}
                    isDisabled={!!formData.parties?.shipper}
                    required={true}
                    label='Forwarder'
                    setFormData={setFormData}
                    setShowAddParty={setShowAddParty}
                    error={!!formik.errors.parties}
                    helperText={
                        formik.errors.parties && String(formik.errors.parties)
                    }
                    onHandleChange={(_, value) => {
                        setFormData((prev) => {
                            const newObject = { ...prev };

                            delete newObject.parties?.forwarder;

                            const update = {
                                ...newObject,
                                parties: {
                                    ...newObject.parties,
                                    ...(value
                                        ? { forwarder: value?.corporateId }
                                        : null)
                                }
                            };

                            formik.setValues(update);

                            return update;
                        });
                    }}
                />
            </div>
            <div className='w-full'>
                <PartiesFormInput
                    user={user}
                    label='Consignee'
                    value={formData.parties?.consignee}
                    setFormData={setFormData}
                    setShowAddParty={setShowAddParty}
                    onHandleChange={(_, value) => {
                        if (value) {
                            setFormData((prev) => ({
                                ...prev,
                                parties: {
                                    ...prev.parties,
                                    consignee: value.corporateId
                                }
                            }));
                        }
                    }}
                />
            </div>
        </div>
    );
};
