import CustomTextField from '../../../../components/CustomTextInput';
import { BookingForm } from './interfaces/BookingForm';

interface ReferencesFormProps {
    formData: BookingForm;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
}

export const ReferencesForm = ({
    formData,
    setFormData
}: ReferencesFormProps) => {
    return (
        <div className='flex flex-col gap-4 pb-4 lg:pb-0 lg:grid lg:grid-cols-3'>
            <CustomTextField
                label="Shipper's Reference Number"
                onChange={(e) =>
                    setFormData((prev) => ({
                        ...prev,
                        references: {
                            ...formData.references,
                            shipperReferences: e.target.value
                        }
                    }))
                }
                value={formData.references.shipperReferences}
            />

            <CustomTextField
                label="Forwarder's Reference Number"
                onChange={(e) =>
                    setFormData((prev) => ({
                        ...prev,
                        references: {
                            ...formData.references,
                            forwarderReferences: e.target.value
                        }
                    }))
                }
                value={formData.references.forwarderReferences}
            />

            <CustomTextField
                label='Purchase Order Number'
                onChange={(e) =>
                    setFormData((prev) => ({
                        ...prev,
                        references: {
                            ...formData.references,
                            purchaseOrderNumbers: e.target.value
                        }
                    }))
                }
                value={formData.references.purchaseOrderNumbers}
            />
        </div>
    );
};
