import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import CustomTextField from './CustomTextInput';

interface AutoCompleteSelectProps {
    id: string;
    options: { label: string; value: string }[];
    onChange: (value: { label: string; value: string }) => void;
    value?: { label: string; value: string };
    label: string;
    name: string;
    required?: boolean;
    isLoading?: boolean;
    error?: boolean;
    helperText?: string;
}

const AutoCompleteSelect = ({
    id,
    options,
    onChange,
    value,
    required,
    isLoading,
    label,
    name,
    error,
    helperText
}: AutoCompleteSelectProps) => {
    return (
        <Autocomplete
            disableClearable
            id={id}
            options={options}
            value={value}
            renderInput={(params) => (
                <CustomTextField
                    {...params}
                    label={label}
                    required={required}
                    name={name}
                    error={error}
                    helperText={helperText}
                    isLoading={isLoading}
                    params={params}
                />
            )}
            noOptionsText={'No data available'}
            renderOption={(props, option: any) => (
                <MenuItem
                    {...props}
                    value={option.label}
                    key={`menu-${option.value}`}
                >
                    {option.label}
                </MenuItem>
            )}
            getOptionLabel={(option) => option.label}
            onChange={(e, value) => onChange(value)}
        />
    );
};

export default AutoCompleteSelect;
