import React, { Dispatch, FC, SetStateAction } from 'react';
import Signature from '../../../signature/signature';
import {
    Autocomplete,
    CircularProgress,
    Grid,
    MenuItem,
    TextField,
    Alert
} from '@mui/material';
import { formatDecimalNumber } from '../../../../utils/helper';
import Quotefield from '../../../../pages/dashboard/tradedocuments/quotation/quotefield/quotefield';
import NewCustomSelect from '../../../newcustomselect/newcustomselect';
import Customdatepicker from '../../../customdatepicker/customdatepicker';
import {
    medthodsOfDispatch,
    typesOfShipment
} from '../../../../utils/appconstants';
import ChooseDocUpload from '../../../custommodal/ChooseDocUpload';
import UploadStamp from '../common/UploadStamp';
import UploadLogo from '../common/UploadLogo';
import PortsAutocomplete from '../../../autocompletewithapi/PortsAutocomplete';

interface IProps {
    error: any;
    updateField: any;
    payload: any;
    corporatePartners: any;
    loadingCorporatepartners: boolean;
    setShowAddPartner: Dispatch<SetStateAction<boolean>>;
    errors: any;
    quoteFields: any[];
    updateQuoteField: any;
    removeQuoteField: any;
    currencies: any;
    totalAmount: number;
    addQuoteField: any;
    loadingCurrencies: boolean;
    setSignatureUrlBase64: (value: string) => void;
    signatureUrlBase64: any;
    loading: boolean;
    setShowSaveModal: Dispatch<SetStateAction<boolean>>;
    setSaveOnly: Dispatch<SetStateAction<boolean>>;
    setPartnerId?: any;
}

const ProformaForm: FC<IProps> = ({
    error,
    updateField,
    payload,
    corporatePartners,
    loadingCorporatepartners,
    setShowAddPartner,
    errors,
    quoteFields,
    updateQuoteField,
    removeQuoteField,
    currencies,
    addQuoteField,
    loading,
    loadingCurrencies,
    setSignatureUrlBase64,
    signatureUrlBase64,
    setSaveOnly,
    setShowSaveModal,
    totalAmount,
    setPartnerId
}: IProps): JSX.Element => {
    return (
        <div className='form-container'>
            <div className='border-0 border-appcolorgreyfaint'>
                {error !== null && (
                    <div className='mt-10 mb-4'>
                        <Alert severity='error'>{error}</Alert>
                    </div>
                )}

                <form>
                    <div className=''>
                        <div className='flex items-center justify-between w-full'>
                            <div className='flex gap-2 items-center'>
                                <UploadLogo {...{ payload, updateField }} />

                                <ChooseDocUpload {...{ updateField, payload }}>
                                    <p>Choose Logo</p>
                                </ChooseDocUpload>
                            </div>
                            <UploadStamp {...{ payload, updateField }} />
                        </div>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            marginTop: 0
                                        }}
                                    >
                                        <Grid item xs={12} md={12} lg={12}>
                                            <div className='mt-4'>
                                                <NewCustomSelect
                                                    selectValue={
                                                        payload.sellerId
                                                    }
                                                    // alternativeValue='name'
                                                    required
                                                    selectOptions={
                                                        corporatePartners
                                                    }
                                                    name={'sellerId'}
                                                    updateField={(_, value) => {
                                                        updateField(
                                                            'sellerId',
                                                            value
                                                        );
                                                        updateField(
                                                            'sellerAddress',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item.id ===
                                                                    value
                                                            )[0]?.address
                                                        );
                                                        updateField(
                                                            'sellerName',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item.id ===
                                                                    value
                                                            )[0]?.name
                                                        );
                                                        updateField(
                                                            'sellerBankName',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item.id ===
                                                                    value
                                                            )[0]?.bankName
                                                        );
                                                        updateField(
                                                            'sellerAccountNumber',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item.id ===
                                                                    value
                                                            )[0]
                                                                ?.bankAccountNumber
                                                        );
                                                        updateField(
                                                            'sellerSwiftCode',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item.id ===
                                                                    value
                                                            )[0]?.swiftCode
                                                        );
                                                        updateField(
                                                            'bankDetails',
                                                            `Bank Name: ${corporatePartners.filter(
                                                                (item: any) =>
                                                                    item.id ===
                                                                    value
                                                            )[0]
                                                                ?.bankName}\r\nAccount Number: ${corporatePartners.filter(
                                                                (item: any) =>
                                                                    item.id ===
                                                                    value
                                                            )[0]
                                                                ?.bankAccountNumber}\r\nSwift Code: ${corporatePartners.filter(
                                                                (item: any) =>
                                                                    item.id ===
                                                                    value
                                                            )[0]?.swiftCode}`
                                                        );
                                                    }}
                                                    label={'Invoice From'}
                                                    // alternativeValue='shippingLineId'
                                                    selectOptionLabel='name'
                                                    loading={
                                                        loadingCorporatepartners
                                                    }
                                                    dropdownRender={() => {
                                                        return (
                                                            <div className='px-4 border-t border-appcolorgreyfaint'>
                                                                <p
                                                                    className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                                    onClick={() => {
                                                                        setShowAddPartner(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    + Add a New
                                                                    Partner
                                                                </p>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                                    <TextField
                                        error={errors?.invoiceNumber.length > 0}
                                        helperText={
                                            errors?.invoiceNumber.length > 0
                                                ? errors?.invoiceNumber
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='proformainvoice-invoiceNumber'
                                        label='Invoice Number'
                                        name='invoiceNumber'
                                        value={payload?.invoiceNumber}
                                        onChange={(e: any) => {
                                            updateField(
                                                'invoiceNumber',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} lg={3}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[1.1rem] pb-2 h-full'>
                                    <Customdatepicker
                                        value={ payload?.invoiceDate || null }
                                        id='quotation-invoiceDate'
                                        label='Invoice Date'
                                        name='invoiceDate'
                                        updateField={updateField}
                                        error={errors?.invoiceDate.length > 0}
                                        helperText={
                                            errors?.invoiceDate.length > 0
                                                ? errors?.invoiceDate
                                                : undefined
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                    <div className='mt-4'>
                                        <NewCustomSelect
                                            selectValue={payload.receiverId}
                                            required
                                            selectOptions={corporatePartners}
                                            name={'receiverId'}
                                            updateField={(_, value) => {
                                                updateField(
                                                    'receiverId',
                                                    value
                                                );
                                                updateField(
                                                    'receiverAddress',
                                                    corporatePartners.filter(
                                                        (item: any) =>
                                                            item.id === value
                                                    )[0]?.address
                                                );
                                                updateField(
                                                    'receiverName',
                                                    corporatePartners.filter(
                                                        (item: any) =>
                                                            item.id === value
                                                    )[0]?.name
                                                );
                                            }}
                                            label={'Invoice  To'}
                                            // alternativeValue='shippingLineId'
                                            selectOptionLabel='name'
                                            loading={loadingCorporatepartners}
                                            dropdownRender={() => {
                                                return (
                                                    <div className='px-4 border-t border-appcolorgreyfaint'>
                                                        <p
                                                            className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                            onClick={() => {
                                                                setShowAddPartner(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            + Add a New Partner
                                                        </p>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                                    <TextField
                                        error={
                                            errors?.buyerReference.length > 0
                                        }
                                        helperText={
                                            errors?.buyerReference.length > 0
                                                ? errors?.buyerReference
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='proformainvoice-buyerReference'
                                        label='Buyer Reference'
                                        name='buyerReference'
                                        value={payload?.buyerReference}
                                        onChange={(e: any) => {
                                            updateField(
                                                'buyerReference',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    >
                                        <Grid item xs={12} md={12} lg={6}>
                                            <TextField
                                                select
                                                error={
                                                    errors?.methodOfDispatch
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.methodOfDispatch
                                                        .length > 0
                                                        ? errors?.methodOfDispatch
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='proformainvoice-methodOfDispatch'
                                                label='Method of Dispatch'
                                                name='methodOfDispatch'
                                                value={
                                                    payload?.methodOfDispatch
                                                }
                                                onChange={(e: any) => {
                                                    updateField(
                                                        'methodOfDispatch',
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                {medthodsOfDispatch.map(
                                                    (item, index) => (
                                                        <MenuItem
                                                            value={item}
                                                            key={`medthodsOfDispatch-${item}-${index}`}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={6}>
                                            <TextField
                                                select
                                                error={
                                                    errors?.typeOfShipment
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.typeOfShipment
                                                        .length > 0
                                                        ? errors?.typeOfShipment
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='proformainvoice-typeOfShipment'
                                                label='Type of Shipment'
                                                name='typeOfShipment'
                                                value={payload?.typeOfShipment}
                                                onChange={(e: any) => {
                                                    updateField(
                                                        'typeOfShipment',
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                {typesOfShipment.map(
                                                    (item, index) => (
                                                        <MenuItem
                                                            value={item}
                                                            key={`typesOfShipment-${item}-${index}`}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-4'>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    >
                                        <Grid item xs={12} md={12} lg={6}>
                                            <PortsAutocomplete
                                                errorCondition={
                                                    errors?.portOfLoadingId
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.portOfLoadingId
                                                        .length > 0
                                                        ? errors?.portOfLoadingId
                                                        : undefined
                                                }
                                                id='quotation-portOfLoading'
                                                label='Port of Loading'
                                                name='portOfLoadingId'
                                                updateField={updateField}
                                                nameValue='portOfLoadingValue'
                                                value={
                                                    payload.portOfLoadingValue
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={6}>
                                            <PortsAutocomplete
                                                errorCondition={
                                                    errors?.portOfDischargeId
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.portOfDischargeId
                                                        .length > 0
                                                        ? errors?.portOfDischargeId
                                                        : undefined
                                                }
                                                id='quotation-portOfDischargeId'
                                                label='Port of Discharge'
                                                name='portOfDischargeId'
                                                updateField={updateField}
                                                nameValue='portOfDischargeValue'
                                                value={
                                                    payload.portOfDischargeValue
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    error={
                                        errors?.salesContractNumber?.length > 0
                                    }
                                    helperText={
                                        errors?.salesContractNumber?.length > 0
                                            ? errors?.salesContractNumber
                                            : undefined
                                    }
                                    margin='normal'
                                    value={payload?.salesContractNumber}
                                    required
                                    fullWidth
                                    id='proformainvoice-salesContractNumber'
                                    label='Sales Contract Number'
                                    name='salesContractNumber'
                                    onChange={(e: any) => {
                                        updateField(
                                            'salesContractNumber',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    {quoteFields.map((item, index) => (
                        <Quotefield
                            item={item}
                            key={item.id}
                            index={index}
                            id={item.id}
                            updateQuoteField={updateQuoteField}
                            price={item.price}
                            qty={item.qty}
                            removeQuoteField={removeQuoteField}
                        />
                    ))}

                    <div className=''>
                        <div className='border border-appcolorgreyfaint px-[1rem] pt-8 pb-8'>
                            <div
                                className='flex items-center cursor-pointer w-fit'
                                onClick={() => {
                                    addQuoteField();
                                }}
                            >
                                <div>
                                    <img
                                        src='/img/vectors/primarydownload.svg'
                                        alt='primarydownload'
                                        className='w-6'
                                    />
                                </div>
                                <span className='pl-2 text-appcolorprimary'>
                                    Add Field
                                </span>
                            </div>
                        </div>

                        <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 flex justify-end gap-2'>
                            <div className='w-[20%]'>
                                <Autocomplete
                                    value={payload.currencyCodeValue}
                                    // inputValue={payload.currencyCode}
                                    placeholder='Currency'
                                    disableClearable
                                    id='send-currency'
                                    sx={{
                                        marginTop: '1rem',
                                        '.MuiInputBase-input': {
                                            height: '0.3rem'
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            value={payload.currencyCode}
                                            label='Select Currency'
                                            placeholder='Currency'
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment:
                                                    loadingCurrencies && (
                                                        <React.Fragment>
                                                            <CircularProgress
                                                                size={20}
                                                            />
                                                            {
                                                                params
                                                                    .InputProps
                                                                    .endAdornment
                                                            }
                                                        </React.Fragment>
                                                    )
                                            }}
                                        />
                                    )}
                                    noOptionsText={'No data available'}
                                    renderOption={(props, option) => (
                                        <MenuItem
                                            {...props}
                                            value={option.nameAndFlag}
                                            key={`countries-${option.id}`}
                                        >
                                            {option.nameAndFlag}
                                        </MenuItem>
                                    )}
                                    getOptionLabel={(option) =>
                                        option.nameAndFlag
                                    }
                                    onChange={(event: any, value: any) => {
                                        if (value !== null) {
                                            updateField(
                                                'currencyCode',
                                                value.currencyCode
                                            );
                                            updateField(
                                                'currencyCodeValue',
                                                value
                                            );
                                        }
                                    }}
                                    options={currencies}
                                />
                            </div>
                            <div className='w-[20%]'>
                                <TextField
                                    disabled
                                    margin='normal'
                                    fullWidth
                                    id='proformainvoice-totalAmount'
                                    label='Total Amount'
                                    name='totalAmount'
                                    value={formatDecimalNumber(totalAmount)}
                                />
                            </div>
                        </div>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full relative'>
                                    <div
                                        className='absolute right-8 top-6 cursor-pointer text-appcolorprimary z-50'
                                        onClick={
                                            payload?.bankDetails
                                                ? () => {
                                                      setShowAddPartner(true);
                                                      setPartnerId(
                                                          payload?.sellerId
                                                      );
                                                  }
                                                : undefined
                                        }
                                    >
                                        Edit partner
                                    </div>
                                    <TextField
                                        multiline
                                        rows={2.8}
                                        margin='normal'
                                        fullWidth
                                        disabled
                                        id='proformainvoice-bankDetails'
                                        label='Bank Details'
                                        name='bankDetails'
                                        value={payload?.bankDetails}
                                        onChange={(e: any) => {
                                            updateField(
                                                'bankDetails',
                                                e.target.value
                                            );
                                        }}
                                    />

                                    <TextField
                                        multiline
                                        rows={2.8}
                                        margin='normal'
                                        fullWidth
                                        id='proformainvoice-additionalInformation'
                                        label='Additional Information'
                                        name='additionalInformation'
                                        value={payload?.additionalInformation}
                                        onChange={(e: any) => {
                                            updateField(
                                                'additionalInformation',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    >
                                        <Grid item xs={12} md={12} lg={6}>
                                            <TextField
                                                error={
                                                    errors?.placeOfIssue
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.placeOfIssue
                                                        .length > 0
                                                        ? errors?.placeOfIssue
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                value={payload?.placeOfIssue}
                                                id='quotation-placeOfIssue'
                                                label='Place of Issue'
                                                name='placeOfIssue'
                                                onChange={(e: any) => {
                                                    updateField(
                                                        'placeOfIssue',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <div className='pt-4'>
                                                <Customdatepicker
                                                    value={
                                                        payload?.issuedDate !==
                                                        undefined
                                                            ? payload?.issuedDate
                                                            : null
                                                    }
                                                    id='quotation-issuedDate'
                                                    label='Issued Date'
                                                    name='issuedDate'
                                                    updateField={updateField}
                                                    error={
                                                        errors?.issuedDate
                                                            .length > 0
                                                    }
                                                    helperText={
                                                        errors?.issuedDate
                                                            .length > 0
                                                            ? errors?.issuedDate
                                                            : undefined
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <TextField
                                                error={
                                                    errors?.signatoryCompany
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.signatoryCompany
                                                        .length > 0
                                                        ? errors?.signatoryCompany
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                value={
                                                    payload?.signatoryCompany
                                                }
                                                id='proformainvoice-signatoryCompany'
                                                label='Signatory Company'
                                                name='signatoryCompany'
                                                onChange={(e: any) => {
                                                    updateField(
                                                        'signatoryCompany',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <TextField
                                                error={
                                                    errors
                                                        ?.nameOfAuthorisedSignatory
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors
                                                        ?.nameOfAuthorisedSignatory
                                                        .length > 0
                                                        ? errors?.nameOfAuthorisedSignatory
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='proformainvoice-nameOfAuthorisedSignatory'
                                                label='Name of Authorized Signature'
                                                name='nameOfAuthorisedSignatory'
                                                value={
                                                    payload?.nameOfAuthorisedSignatory
                                                }
                                                onChange={(e: any) => {
                                                    updateField(
                                                        'nameOfAuthorisedSignatory',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <div className='w-full'>
                                                <Signature
                                                    name='signatureUrl'
                                                    updateField={updateField}
                                                    setSignatureUrlBase64={
                                                        setSignatureUrlBase64
                                                    }
                                                    signatureUrlBase64={
                                                        signatureUrlBase64
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProformaForm;
