import { useState } from 'react';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Drawer from '@mui/material/Drawer';
import Custombutton from '../../../components/custombutton/custombutton';
import { addCorporateParties } from '../../../api/booking/booking';
import { openNotification } from '../../../utils/helper';
import useCountries from '../../../customhooks/useCountries';
import NewCustomSelect from '../../../components/newcustomselect/newcustomselect';
import { useMediaQuery, useTheme } from '@mui/material';

export interface newcarrierDrawerProps {
    closeDrawer: (refreshList?: boolean) => void;
    showDrawer: boolean;
    corporateId: number;
}

const AddPartyDrawer = ({
    closeDrawer,
    showDrawer,
    corporateId
}: newcarrierDrawerProps) => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { countries, isCountriesLoading } = useCountries();

    const [error, setError] = useState<string | any>(null);
    const [loading, setLoading] = useState(false);

    const [payload, setPayload] = useState<any>({
        address: undefined,
        countryCode: '',
        emailAddress: undefined,
        fax: '',
        phoneNumber: undefined,
        postalCode: undefined,
        name: undefined
    });
    const [errors, setErrors] = useState<any>({
        address: '',
        countryCode: '',
        emailAddress: '',
        fax: '',
        phoneNumber: '',
        postalCode: '',
        name: ''
    });

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrors((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));
    };

    /**
     * Submit form
     * @param e Object
     */
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        setErrors((prevState: any) => ({
            ...prevState,
            address: '',
            countryCode: '',
            emailAddress: '',
            fax: '',
            phoneNumber: '',
            postalCode: '',
            name: ''
        }));

        try {
            const res = await addCorporateParties(payload);

            const { data } = res.data;

            setLoading(false);

            openNotification('success', 'Booking party created successfully');

            closeDrawer(true);
        } catch (error) {
            setLoading(false);
            console.log(error);
            const otherErrors: any[] = [];

            if (error?.response) {
                if (error?.response?.data?.error) {
                    const resError = error?.response?.data?.error;

                    if (typeof resError === 'string') {
                        const errorList = (
                            <li key={`error-list-${1}`}>{resError}</li>
                        );
                        setError(errorList);
                    } else {
                        Object.entries(resError).forEach(([key, value]) => {
                            if (Object.keys(errors).includes(key)) {
                                setErrors((prevState: any) => ({
                                    ...prevState,
                                    [key]: value
                                }));
                            } else {
                                otherErrors.push(value);
                            }
                        });

                        if (otherErrors.length > 0) {
                            const errorList = otherErrors.map(
                                (element: any, index) => {
                                    return (
                                        <li key={`error-list-${index}`}>
                                            {element}
                                        </li>
                                    );
                                }
                            );
                            setError(errorList);
                        }
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    return (
        <div>
            <div
                className='slide-in-right active bg-appcolorwhite rounded-[50%] top-[1.2rem] right-[28.4rem] fixed p-4 z-[2000] cursor-pointer'
                onClick={() => {
                    closeDrawer();
                }}
            >
                <img src='/img/vectors/cross.svg' alt='cross' />
            </div>
            <Drawer
                anchor={isMobile ? 'bottom' : 'right'}
                open={showDrawer}
                onClose={() => {
                    closeDrawer();
                }}
                sx={{
                    '.MuiDrawer-paperAnchorRight': {
                        width: {
                            xs: '95%',
                            lg: '30rem'
                        }
                    }
                }}
            >
                <div className='px-[3.2rem] py-[2.1rem]'>
                    <h1 className='text-[24px] leading-[23px] text-appcolorblacktwo font-semibold'>
                        Create Booking Party
                    </h1>

                    {error !== null && (
                        <div className='mt-10'>
                            <Alert severity='error'>{error}</Alert>
                        </div>
                    )}

                    <div className='my-4'>
                        <form autoComplete='off' onSubmit={onSubmit} noValidate>
                            <TextField
                                value={payload?.name}
                                error={errors?.name.length > 0}
                                helperText={
                                    errors?.name.length > 0
                                        ? errors?.phoneNumber
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='createnewcarrier-name'
                                label='Name'
                                name='name'
                                onChange={(e: any) => {
                                    updateField('name', e.target.value);
                                }}
                            />

                            <TextField
                                value={payload?.emailAddress}
                                error={errors?.emailAddress.length > 0}
                                helperText={
                                    errors?.emailAddress.length > 0
                                        ? errors?.emailAddress
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='createnewcarrier-emailAddress'
                                label='Email Address'
                                name='emailAddress'
                                onChange={(e: any) => {
                                    updateField('emailAddress', e.target.value);
                                }}
                            />

                            <div className='mt-4'>
                                <NewCustomSelect
                                    selectValue={payload?.countryId}
                                    required
                                    selectOptions={
                                        countries as unknown as Record<
                                            string,
                                            string | number
                                        >[]
                                    }
                                    name={'countryId'}
                                    updateField={(_, value) => {
                                        updateField('countryId', value);
                                    }}
                                    label={'Country'}
                                    selectOptionLabel='name'
                                    loading={isCountriesLoading}
                                />
                            </div>

                            <TextField
                                value={payload?.phoneNumber}
                                error={errors?.phoneNumber.length > 0}
                                helperText={
                                    errors?.phoneNumber.length > 0
                                        ? errors?.phoneNumber
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='createnewcarrier-phoneNumber'
                                label='Phone Number'
                                name='phoneNumber'
                                onChange={(e: any) => {
                                    updateField('phoneNumber', e.target.value);
                                }}
                            />

                            <TextField
                                value={payload?.postalCode}
                                error={errors?.postalCode.length > 0}
                                helperText={
                                    errors?.postalCode.length > 0
                                        ? errors?.postalCode
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='createnewcarrier-postalCode'
                                label='Postal Code'
                                name='postalCode'
                                onChange={(e: any) => {
                                    updateField('postalCode', e.target.value);
                                }}
                            />

                            <TextField
                                value={payload?.fax}
                                error={errors?.fax.length > 0}
                                helperText={
                                    errors?.fax.length > 0
                                        ? errors?.fax
                                        : undefined
                                }
                                margin='normal'
                                fullWidth
                                id='createnewcarrier-fax'
                                label='FAX'
                                name='fax'
                                onChange={(e: any) => {
                                    updateField('fax', e.target.value);
                                }}
                            />

                            <TextField
                                multiline={true}
                                value={payload?.address}
                                error={errors?.address.length > 0}
                                helperText={
                                    errors?.address.length > 0
                                        ? errors?.address
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='existingbooking-address'
                                label='Address'
                                name='address'
                                onChange={(e: any) => {
                                    updateField('address', e.target.value);
                                }}
                            />

                            <div className='mt-4'>
                                <Custombutton
                                    isLoadingButton
                                    loading={loading}
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    styles={{
                                        padding: '0.5rem 0.62rem 0.9rem 0.93rem'
                                    }}
                                    disabled={
                                        Object.values(payload).some(
                                            (item: any) => item === undefined
                                        ) || loading
                                    }
                                    buttonText='Submit'
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default AddPartyDrawer;
