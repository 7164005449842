import React, { Dispatch, SetStateAction } from 'react';
import {
    DocumentTableContainer,
    TabContainerNew
} from '../../../../styles/TradeDocStyles';
import { Divider, Stack } from '@mui/material';
import { active } from '../contracts/ContractDetailsTable';
import InvoiceItemsTable from './InvoiceItemsTable';
import InvoiceBookingTable from './InvoiceBookingTable';
import InvoicePaymentTable from './InvoicePaymentTable';
import { SuitUIButton } from '../../../SuitUIButton';
import { SalesDocumentData } from '../../../../pages/dashboard/payments/interfaces/SalesDocuments';
import { Invoice } from '../../../../pages/dashboard/payments/interfaces/Invoice';
import { Booking } from '../../../../pages/dashboard/bookings/interfaces/Booking';

const InvoiceDetailsTable = ({
    setActiveView,
    activeView,
    data,
    loadingData,
    bookingData,
    paymentData
}: {
    setActiveView: Dispatch<SetStateAction<string>>;
    activeView?: string;
    data?: SalesDocumentData;
    loadingData?: boolean;
    bookingData?: { content: Booking[] };
    paymentData?: Invoice;
}) => {
    return (
        <>
            <div className='mt-[16px] mb-[10px] lg:block hidden'>
                <TabContainerNew>
                    <Stack direction='row' spacing={'5px'}>
                        <div
                            className='tab-buttons'
                            style={{
                                cursor: 'pointer',
                                background:
                                    activeView === 'Invoice Line Items'
                                        ? '#0088b21a'
                                        : 'unset',

                                color:
                                    activeView === 'Invoice Line Items'
                                        ? '#0088b2'
                                        : 'initial',

                                fontWeight:
                                    activeView === 'Invoice Line Items'
                                        ? '500'
                                        : '400'
                            }}
                            onClick={() => setActiveView('Invoice Line Items')}
                        >
                            {activeView === 'Invoice Line Items' ? active : doc}
                            <p
                                style={{
                                    paddingLeft: '5px'
                                }}
                            >
                                Invoice Line Items
                            </p>
                        </div>

                        <Divider orientation='vertical' flexItem />
                        <div
                            className='tab-buttons'
                            style={{
                                cursor: 'pointer',
                                background:
                                    activeView === 'Payment History'
                                        ? '#0088b21a'
                                        : 'unset',

                                color:
                                    activeView === 'Payment History'
                                        ? '#0088b2'
                                        : 'initial',

                                fontWeight:
                                    activeView === 'Payment History'
                                        ? '500'
                                        : '400'
                            }}
                            onClick={() => setActiveView('Payment History')}
                        >
                            {activeView === 'Payment History' ? active : doc}

                            <p style={{ paddingLeft: '5px' }}>
                                Payment History
                            </p>
                        </div>

                        <Divider orientation='vertical' flexItem />
                        <div
                            className='tab-buttons'
                            style={{
                                cursor: 'pointer',
                                background:
                                    activeView === 'Booking History'
                                        ? '#0088b21a'
                                        : 'unset',

                                color:
                                    activeView === 'Booking History'
                                        ? '#0088b2'
                                        : 'initial',

                                fontWeight:
                                    activeView === 'Booking History'
                                        ? '500'
                                        : '400'
                            }}
                            onClick={() => setActiveView('Booking History')}
                        >
                            {activeView === 'Booking History' ? active : doc}

                            <p style={{ paddingLeft: '5px' }}>
                                Booking History
                            </p>
                        </div>
                    </Stack>
                </TabContainerNew>
            </div>
            <div className='flex items-center gap-[24px] my-[16px] px-4 lg:hidden'>
                <SuitUIButton
                    text={` Line Items`}
                    onClick={() => setActiveView('Invoice Line Items')}
                    type='button'
                    variant={
                        activeView === 'Invoice Line Items'
                            ? 'primary'
                            : 'secondary'
                    }
                />

                <SuitUIButton
                    text={`Payment`}
                    onClick={() => setActiveView('Payment History')}
                    type='button'
                    variant={
                        activeView === 'Payment History'
                            ? 'primary'
                            : 'secondary'
                    }
                />

                <SuitUIButton
                    text={`Bookings`}
                    onClick={() => setActiveView('Booking History')}
                    type='button'
                    variant={
                        activeView === 'Booking History'
                            ? 'primary'
                            : 'secondary'
                    }
                />
            </div>
            <DocumentTableContainer className='bg-appcolorwhite'>
                {activeView === 'Invoice Line Items' && (
                    <InvoiceItemsTable {...{ data, loadingData }} />
                )}
                {activeView === 'Booking History' && (
                    <InvoiceBookingTable
                        {...{ loadingData }}
                        data={bookingData}
                    />
                )}
                {activeView === 'Payment History' && (
                    <InvoicePaymentTable
                        {...{ loadingData }}
                        data={paymentData}
                    />
                )}
            </DocumentTableContainer>
        </>
    );
};

export default InvoiceDetailsTable;
const doc = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
    >
        <path
            d='M12.5201 1.96747H5.85343C5.41141 1.96747 4.98748 2.14306 4.67492 2.45562C4.36236 2.76818 4.18677 3.19211 4.18677 3.63414V16.9675C4.18677 17.4095 4.36236 17.8334 4.67492 18.146C4.98748 18.4585 5.41141 18.6341 5.85343 18.6341H15.8534C16.2955 18.6341 16.7194 18.4585 17.0319 18.146C17.3445 17.8334 17.5201 17.4095 17.5201 16.9675V6.96747M12.5201 1.96747L17.5201 6.96747M12.5201 1.96747V6.96747H17.5201M14.1868 11.1341H7.5201M14.1868 14.4675H7.5201M9.18677 7.8008H7.5201'
            stroke='#101828'
            strokeWidth='1.2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
