import { Box, Grid, Stack } from '@mui/material';
import { TabContainerNew } from '../../../../styles/TradeDocStyles';
import { TabContext, TabPanel } from '@mui/lab';
import { useState } from 'react';
import ListResults from './search-results/list-results';
import TileResults from './search-results/tile-results';
import { OceanFetcherResponse } from '../ocean-schedules';
import React from 'react';
import { gridView, listView } from '../../../../components/customIcons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../store/store';
import useShippingLines from '../../../../customhooks/shippinglines/userShippinglines';
import { ShippingProfile } from '../../../../interfaces/User';
import { SearchResult } from '../interfaces/SearchResults';
import Newcarrierdrawer from '../../bookings/newcarrierdrawer/newcarrierdrawer';
import useShippingProfiles from '../../../../customhooks/useShippingProfiles';

interface SearchResultsProps extends OceanFetcherResponse {
    locationFrom: string;
    locationTo: string;
    results: SearchResult[];
    theTabIndex: string;
    theSelectedVessel: string;
    theResponseError: string;
}

const SearchResults = (props: SearchResultsProps) => {
    const [theSearchView, setTheSearchView] = useState('1');
    const [theCarrier, setTheCarrier] = useState<string>();
    const { shippingProfiles } = useShippingProfiles();

    const [showAddNewCarrierModal, setShowAddNewCarrierModal] = useState(false);
    const navigate = useNavigate();

    const { user } = useSelector((state: RootState) => state.user);
    const { shippingLines, isLoadingShippingLines } = useShippingLines({
        forBooking: true
    });

    const shippingLineOptions = shippingLines.map((line) => {
        return {
            label: line.name,
            ...line
        };
    });

    const isProfileExistsFn = (carrier: string): boolean => {
        return (
            shippingProfiles?.some(
                (item: ShippingProfile) =>
                    item.shippingLineId === Number(carrier)
            ) || false
        );
    };

    const handleBookButtonClick = (data: SearchResult) => {
        const carrierId = data.carrierId;
        setTheCarrier(carrierId);
        if (isProfileExistsFn(carrierId)) {
            navigate(`/bookings/create-booking?carrier=${carrierId}`);
        } else {
            setShowAddNewCarrierModal(true);
        }
    };

    const closeShowNewCarrier = () => {
        setShowAddNewCarrierModal(false);
    };

    return (
        <TabContext value={theSearchView}>
            <Grid
                display='flex'
                justifyContent={
                    props.locationFrom || props.theSelectedVessel
                        ? 'space-between'
                        : 'flex-end'
                }
                alignItems='center'
                container
            >
                {props.locationFrom && props?.theTabIndex === '1' ? (
                    <p>
                        Showing results from <b>{props.locationFrom}</b> to{' '}
                        <b>{props.locationTo}</b>
                    </p>
                ) : props.theSelectedVessel && props?.theTabIndex === '2' ? (
                    <p>
                        Showing results for <b>{props.theSelectedVessel}</b>
                    </p>
                ) : (
                    <div />
                )}

                <Grid item>
                    <Box display='flex' justifyContent='flex-end'>
                        <TabContainerNew>
                            <Stack direction='row' spacing={1}>
                                <div
                                    className={
                                        theSearchView === '1'
                                            ? 'tab-buttons actif'
                                            : 'tab-buttons'
                                    }
                                    onClick={() => setTheSearchView('1')}
                                >
                                    {listView}
                                </div>
                                <div
                                    className={
                                        theSearchView === '2'
                                            ? 'tab-buttons actif'
                                            : 'tab-buttons'
                                    }
                                    onClick={() => setTheSearchView('2')}
                                >
                                    {gridView}
                                </div>
                            </Stack>
                        </TabContainerNew>
                    </Box>
                </Grid>
            </Grid>
            <TabPanel sx={{ p: 0 }} value='1'>
                <ListResults
                    results={props.results}
                    hasApiResponse={props.hasApiResponse ?? false}
                    stillLoading={props.stillLoading ?? false}
                    source={
                        props?.theTabIndex === '1' ? 'by-points' : 'by-vessel'
                    }
                    errorMessage={props.theResponseError}
                    {...{ handleBookButtonClick, isProfileExistsFn }}
                />
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value='2'>
                <TileResults
                    from={props.locationFrom}
                    to={props.locationTo}
                    source={
                        props?.theTabIndex === '1' ? 'by-points' : 'by-vessel'
                    }
                    results={props.results}
                    hasApiResponse={props.hasApiResponse ?? false}
                    errorMessage={props.theResponseError}
                    {...{ handleBookButtonClick, isProfileExistsFn }}
                />
            </TabPanel>
            {showAddNewCarrierModal && user?.corporate_id && (
                <Newcarrierdrawer
                    corporateId={user.corporate_id}
                    closeDrawer={closeShowNewCarrier}
                    showDrawer={showAddNewCarrierModal}
                    shippingLines={shippingLineOptions}
                    isLoadingShippingLines={isLoadingShippingLines}
                    carrier={theCarrier}
                />
            )}
        </TabContext>
    );
};

export default SearchResults;
