import { Divider, Stack } from '@mui/material';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { TabContainerNew } from '../../../styles/TradeDocStyles';

interface breadcrumb {
    title: string;
    link?: string;
    active: boolean;
}

interface IProps {
    breadcrumbs?: breadcrumb[];
    setActiveView?: any;
    noHead?: boolean;
    activeView?: any;
}

const CorporatesHeader: FC<IProps> = ({
    breadcrumbs,
    setActiveView,
    activeView,
    noHead
}: IProps): JSX.Element => {
    const navigate = useNavigate();

    return (
        <div className='form-header'>
            <div className='breadcrumbs'>
                {breadcrumbs?.map((item, index) => (
                    <div className='flex gap-2'>
                        <p
                            key={index}
                            className={
                                item?.active
                                    ? 'breadcrumb-text active'
                                    : 'breadcrumb-text'
                            }
                            onClick={() => {
                                if (item.link !== undefined) {
                                    navigate(item?.link);
                                }
                            }}
                        >
                            {item.title}
                        </p>
                        {index !== breadcrumbs.length - 1 && (
                            <img
                                src='/img/vectors/breadcrumbcaret.svg'
                                alt='caret'
                            />
                        )}
                    </div>
                ))}
            </div>
            <div className='header-action'>
                <h1 className='text-appcolorblacktwo'>{'Corporates'}</h1>
            </div>
            {!noHead && (
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    className='header-action'
                >
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        width='100%'
                    >
                        <TabContainerNew>
                            <Stack direction='row' spacing={'5px'}>
                                <div
                                    className='tab-buttons'
                                    style={{
                                        cursor: 'pointer',
                                        background:
                                            activeView === undefined
                                                ? '#0088b21a'
                                                : 'unset',

                                        color:
                                            activeView === undefined
                                                ? '#0088b2'
                                                : 'initial',

                                        fontWeight:
                                            activeView === undefined
                                                ? '500'
                                                : '400'
                                    }}
                                    onClick={() => setActiveView(undefined)}
                                >
                                    <p
                                        style={{
                                            paddingRight: '5px'
                                        }}
                                    >
                                        All Registrations{' '}
                                    </p>
                                </div>

                                <Divider orientation='vertical' flexItem />
                                <div
                                    className='tab-buttons'
                                    style={{
                                        cursor: 'pointer',
                                        background:
                                            activeView === true
                                                ? '#0088b21a'
                                                : 'unset',

                                        color:
                                            activeView === true
                                                ? '#0088b2'
                                                : 'initial',

                                        fontWeight:
                                            activeView === true ? '500' : '400'
                                    }}
                                    onClick={() => setActiveView(true)}
                                >
                                    <p style={{ paddingRight: '5px' }}>
                                        Approved
                                    </p>
                                </div>

                                <Divider orientation='vertical' flexItem />
                                <div
                                    className='tab-buttons'
                                    style={{
                                        cursor: 'pointer',
                                        background:
                                            activeView === false
                                                ? '#0088b21a'
                                                : 'unset',

                                        color:
                                            activeView === false
                                                ? '#0088b2'
                                                : 'initial',

                                        fontWeight:
                                            activeView === false ? '500' : '400'
                                    }}
                                    onClick={() => setActiveView(false)}
                                >
                                    <p style={{ paddingRight: '5px' }}>
                                        Pending
                                    </p>
                                </div>
                            </Stack>
                        </TabContainerNew>

                        {/* <TabContainerNew>
              <div className="bg-appcolorwhite rounded-[3px] pb-0 px-4 absolute right-0 top-[-0.9rem] z-10">
                <div className="">
                  <InputBase
                    fullWidth
                    placeholder="Search Application"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                  />
                </div>
              </div>
            </TabContainerNew> */}
                    </Stack>
                </Stack>
            )}
        </div>
    );
};

export default CorporatesHeader;
