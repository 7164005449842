import styled from 'styled-components';

const idealViewportWidth: number = 1440;

export const scaleValue = (value: number) => {
    return value * (window.innerWidth / idealViewportWidth);
};

export const TradeContainer = styled.section`
    min-height: 500px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    .page-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h1 {
            font-size: 28px;
            font-weight: 500;
        }
    }
    .header-action {
        margin-top: 2rem;
        h1 {
            font-size: 28px;
            font-weight: 500;
        }
    }
    .form-header {
        .breadcrumbs {
            display: flex;
            gap: 8px;
        }
        .breadcrumb-text {
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #7d8398;
            cursor: pointer;
            :hover {
                text-decoration: underline;
            }
        }
        .active {
            color: #121212;
        }
    }
`;

export const TabContainerNew = styled.section`
    background: white;
    border-radius: 8px;
    overflow-x: hidden;
    padding: 8px 8px;
    border: 1px solid #e8e8e8;
    height: 46px;
    display: flex;
    overflow: hidden;
    .tab-buttons {
        min-width: 30px;
        height: 30px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 5px;
        padding-left: 5px;
        color: #121212;
        svg {
            color: #121212;
        }
        cursor: pointer;
        :hover {
            color: #0088b2;
            font-weight: 500;
            background: #0088b21a;
            svg {
                color: #0088b2;
                path {
                    stroke: #0088b2;
                }
            }
        }
        p {
            font-size: 14px;
        }
    }
    .actif {
        color: #0088b2;
        font-weight: 500;
        background: #0088b21a;
        svg {
            color: #0088b2;
            path {
                stroke: #0088b2;
            }
        }
    }
`;

export const DocumentTableContainer = styled.div`
    margin-top: 16px;
    border-radius: 8px;
    overflow-x: hidden;
    height: 100%;
    border: 1px solid #e8e8e8;

    table {
        thead {
            th {
                padding: 14px 16px !important;
                font-size: 14px;
                ::before {
                    content: none !important;
                }
            }
        }
        tbody {
            tr {
                cursor: pointer;
                :hover {
                    background: #0088b21a !important;
                }
                td {
                    padding: 12px 16px !important;
                    border-bottom: 0 !important;
                    font-size: 13px;
                    background-color: unset !important;
                }
                :last-of-type {
                    td {
                        border-bottom: 1px solid #f0f0f0 !important;
                    }
                }
                :nth-of-type(odd) {
                    background-color: #f9f9f9;
                }
            }
        }
    }
    .action-btn {
        :hover {
            background-color: unset;
            color: #0088b2;
            text-decoration: underline;
        }
    }
    .ant-pagination-item-link {
        display: flex !important;
        align-items: center;
        justify-content: center;
        svg {
            width: 10px;
        }
    }
    .ant-pagination {
        padding: 0 20px;
    }
`;

export const PreviewDocumentContainer = styled.div`
    width: 750px;
    min-height: 850px;
    position: relative;
    p {
        font-size: 10px;
    }
    .sales-info-preview-content {
        padding: 1rem;
        padding-top: 5px;
        opacity: 0.7;
        p,
        ul {
            font-size: 13px;
            margin-left: 20px;
            list-style-type: decimal;
        }
        ul {
            margin-left: 30px;
        }
        h2 {
            font-size: 16xpx;
        }
    }
    #image-renderer {
        background-image: unset !important;
        display: block;
        flex: unset;
    }
    .preview-container {
        max-width: 100% !important;
        height: 40px !important;
    }
    .top-preview-content,
    .mid-preview-content {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        > div {
            max-width: 27%;
            width: 100%;
            .title {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;

                flex: 1;
                letter-spacing: 0.02em;
                color: rgba(0, 0, 0, 0.5);
            }
            .value {
                text-align: right;
                font-weight: 500;
            }
        }

        .view-three {
            > div {
                :first-of-type {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .mid-preview-content {
        border-top: 0.4px solid #828282;
        padding-top: 8px;
        > div {
            .value {
                text-align: left;
                margin-bottom: 0.2rem;
            }
        }
    }
    .table-view {
        margin-top: 1.5rem;
        table {
            border-radius: 0;
            border: 0.4px solid #000000;

            thead {
                background: #0088b2 !important;
            }

            th {
                background-color: unset !important;
                border-radius: 0 !important;
                color: white;
                border-right: 0.4px solid #000000;
                font-size: 10px;
                padding: 5px;
                :last-of-type {
                    border-right: 0;
                }
                ::before {
                    content: unset !important;
                }
            }
            tbody {
                tr {
                    :last-of-type {
                        td {
                            border-bottom: none;
                        }
                    }
                }
                td {
                    background-color: unset !important;
                    border-radius: 0 !important;
                    border-right: 0.4px solid #000000;
                    font-size: 10px;
                    max-width: 150px;
                    padding: 5px;
                    :last-of-type {
                        border-right: 0;
                    }
                }
            }
        }
    }
    .preview-total {
        border: 0.4px solid #000000;
        margin-top: 0.5rem;
        padding: 4px 10px;
        font-size: 11px;
        span {
            font-size: 12px;
            font-weight: 600;
            margin-left: 10px;
        }
    }
    .bottom-preview-content {
        margin-top: 1rem;
        border-top: 0.4px solid #828282;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 00px;
        padding: 0.5rem;
        padding-bottom: 0;
        > div {
            max-width: 25%;
            width: 100%;
            .titl {
                display: flex;
                align-items: center;
                letter-spacing: 0.02em;
                color: #828282;
                padding-bottom: 0px;
            }
            p {
                padding-bottom: 5px;
                color: #000000;
                font-weight: 400;
            }
            .img {
                height: 30px;
                margin-top: 0.5rem;
            }
        }
    }
    .preview-container {
        max-width: 20%;
        height: 40px !important;
    }
    #image-renderer {
        background-image: unset !important;
    }
`;

export const TradeContent = styled.div`
    margin-top: 20px;
    #image-renderer {
        background-image: unset !important;
    }
    .form-container {
        background-color: white;
        background: #ffffff;
        border-radius: 5px;
        padding: 25px;
        .preview-container {
            width: auto;
            height: 80px !important;
        }
    }
`;
export const PreviewContent = styled.div`
    width: 100%;
    padding: 25px;
    max-width: 794px;
    min-height: 1200px;
    max-height: 1600px;
    height: 100%;
    background-color: white;
    position: relative;
    background: #ffffff;
    .totalView {
        #image-renderer {
            display: block;
            flex: unset;
        }
        .img {
            width: auto;
            height: 70px !important;
        }
    }
    #image-renderer {
        background-image: unset !important;
        display: block;
        flex: unset;
        margin-bottom: 0.5rem;
    }
    .preview-container {
        max-width: 20%;
        height: 40px !important;
        margin-top: 0.5rem;
    }
    p {
        font-size: 13px;
    }
    .top-preview-content,
    .mid-preview-content {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        .preview-container {
            max-width: 100%;
            height: 60px !important;
            margin-top: 1rem;
        }
        > div {
            max-width: 28%;
            width: 100%;
            .title {
                font-family: 'Outfit';
                font-style: normal;
                font-weight: 400;

                flex: 1;
                letter-spacing: 0.02em;
                color: rgba(0, 0, 0, 0.5);
            }
            .value {
                text-align: right;
                font-weight: 500;
            }
        }

        .view-three {
            > div {
                :first-of-type {
                    margin-bottom: 20px;
                }
            }
        }
    }
    .mid-preview-content {
        border-top: 0.4px solid #828282;
        padding-top: 15px;
        > div {
            .value {
                text-align: left;
                margin-bottom: 0.5rem;
            }
        }
    }
    .sales-info-preview-content {
        padding: 1rem;
        padding-top: 5px;

        opacity: 0.7;
        p,
        ul {
            font-size: 13px;
            margin-left: 20px;
            list-style-type: decimal;
        }
        ul {
            margin-left: 30px;
        }
        h2 {
            font-size: 16px;
        }
    }
    .table-view {
        margin-top: 4rem;

        table {
            border-radius: 0;
            border: 0.4px solid #000000;

            thead {
                background: #0088b2 !important;
            }
            th {
                background-color: unset !important;
                border-radius: 0 !important;
                color: white;
                border-right: 0.4px solid #000000;
                font-size: 13px;
                padding: 10px;
                :last-of-type {
                    border-right: 0;
                }
                ::before {
                    content: unset !important;
                }
            }
            tbody {
                tr {
                    :last-of-type {
                        td {
                            border-bottom: none;
                        }
                    }
                }
                td {
                    background-color: unset !important;
                    border-radius: 0 !important;
                    border-right: 0.4px solid #000000;
                    border-bottom: 0.4px solid #000;
                    font-size: 13px;
                    max-width: 300px;
                    :last-of-type {
                        border-right: 0;
                    }
                }
            }
        }
    }
    .preview-total {
        border: 0.4px solid #000000;
        margin-top: 1rem;
        padding: 8px 20px;
        font-size: 15px;
        span {
            font-size: 16px;
            font-weight: 600;
            margin-left: 20px;
        }
    }
    .bottom-preview-content {
        margin-top: 1.5rem;
        border-top: 0.4px solid #828282;
        margin-bottom: 1.5rem;
        position: absolute;
        bottom: 30px;
        left: 30px;
        right: 30px;
        padding: 1rem;
        > div {
            max-width: 25%;
            width: 100%;
            .titl {
                display: flex;
                align-items: center;
                letter-spacing: 0.02em;
                color: #828282;
                padding-bottom: 3px;
            }
            p {
                padding-bottom: 5px;
                color: #000000;
                font-weight: 400;
            }
            img {
                height: 50px;
            }
        }
    }
`;
