import React, { useEffect, useState, useRef } from 'react';
import {
    TradeContainer,
    TradeContent
} from '../../../../styles/TradeDocStyles';
import FormHeader from '../../../../components/dashboard/tradeDocuments/common/FormHeader';
import useCurrencies from '../../../../customhooks/useCurrencies';
import { nanoid } from '@reduxjs/toolkit';
import useCorporatePartners from '../../../../customhooks/useCorporatePartners';
import QuotationForm from '../../../../components/dashboard/tradeDocuments/formComponents/QuotationForm';
import Addpartnerdrawer from '../addpartnerdrawer/addpartnerdrawer';
import CustomModal from '../../../../components/CustomModal';
import Savetradedocument from '../savetradedocument/savetradedocument';
import QuotationPreview from '../../../../components/dashboard/tradeDocuments/previewComponents/QuotationPreview';
import { openNotification } from '../../../../utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    createQuotation,
    getSingleDocuments
} from '../../../../api/documents/documents';
import { Backdrop } from '@mui/material';

const QuotationNew = () => {
    // State variables
    const navigate = useNavigate();
    const location = useLocation();
    const [params, setParams] = useState<any>();
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const param: any = Object.fromEntries(searchParams.entries());
        setParams(param);
    }, [location.search]);

    useEffect(() => {
        if (params?.edit) {
            fetchDocuments();
        }
    }, [params]);

    const [fileId, setFileId] = useState(undefined);
    const [showPreview, setShowPreview] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [saveOnly, setSaveOnly] = useState(true);
    const [signatureUrlBase64, setSignatureUrlBase64] = useState('');
    const [showAddPartner, setShowAddPartner] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [quoteFields, setQuoteFields] = useState([
        {
            id: nanoid(9),
            productCode: nanoid(9),
            desc: undefined,
            qty: 0,
            type: undefined,
            price: 0
        }
    ]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>(null);
    const [payload, setPayload] = useState<any>({
        receiverName: undefined,
        sellerName: undefined,
        documentName: '',
        sellerId: undefined,
        quoteNumber: nanoid(9),
        issuedDate: Date.now(),
        receiverId: undefined,
        portOfLoading: '',
        portOfLoadingId: undefined,
        methodOfDispatch: undefined,
        portOfDischarge: '',
        portOfDischargeId: undefined,
        typeOfShipment: undefined,
        placeOfIssue: undefined,
        signatoryCompany: undefined,
        nameOfAuthorisedSignatory: undefined,
        signatureUrl: undefined,
        documentData: '',
        totalAmount: '',
        additionalInformation: '',
        salesContractNumber: '',
        documentUrl: '',
        dateOfIssue: Date.now(),
        currencyCode: undefined,
        currencyCodeValue: undefined,
        portOfLoadingValue: undefined,
        portOfDischargeValue: undefined,
        sellerAddress: '',
        receiverAddress: '',
        logoUrl: '',
        sellerCountry: '',
        receiverCountry: ''
    });
    const [errors, setErrors] = useState({
        receiverId: '',
        issuedDate: '',
        documentData: '',
        portOfLoadingId: '',
        methodOfDispatch: '',
        portOfDischargeId: '',
        typeOfShipment: '',
        totalAmount: '',
        additionalInformation: '',
        placeOfIssue: '',
        signatoryCompany: '',
        nameOfAuthorisedSignatory: '',
        signatureUrl: '',
        sellerId: '',
        quoteNumber: '',
        documentName: '',
        salesContractNumber: '',
        documentUrl: '',
        dateOfIssue: '',
        currencyCode: '',
        sellerAddress: '',
        receiverAddress: '',
        logoUrl: ''
        // billOfLadingNumber: "",
    });

    const fetchDocuments = async () => {
        setLoadingData(true);
        try {
            const res = await getSingleDocuments({
                corporateDocumentId: params?.edit
            });

            const { data } = res.data;
            setData(data);

            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            if (error?.response) {
                openNotification('error', `Documents, Something went wrong`);
            } else {
                openNotification('error', `Documents, Something went wrong`);
            }
        }
    };

    useEffect(() => {
        if (data) {
            setFileId(data?.id);
            setPayload({
                receiverName: data?.documentData?.receiver?.name || '',
                documentName: data?.documentName || '',
                sellerName: data?.documentData?.seller?.address || '',
                sellerId: data?.documentData?.sellerId,
                quoteNumber: data?.documentData?.quoteNumber || nanoid(9),
                issuedDate: data?.documentData?.issuedDate || Date.now(),
                receiverId: data?.documentData?.receiverId || '',
                methodOfDispatch: data?.documentData?.methodOfDispatch || '',
                typeOfShipment: data?.documentData?.typeOfShipment || '',
                placeOfIssue: data?.documentData?.placeOfIssue || '',
                signatoryCompany: data?.documentData?.signatoryCompany || '',
                additionalInformation:
                    data?.documentData?.additionalInformation || '',
                nameOfAuthorisedSignatory:
                    data?.documentData?.nameOfAuthorisedSignatory || '',
                signatureUrl: data?.documentData?.signatureUrl || '',
                totalAmount: data?.documentData?.totalAmount || '',
                salesContractNumber:
                    data?.documentData?.salesContractNumber || '',
                dateOfIssue:
                    data?.documentData?.documentData?.issuedDate || Date.now(),
                currencyCode: data?.documentData?.currencyCode || '',
                currencyCodeValue: data?.documentData?.currencyCode || '',
                portOfLoading: data?.documentData?.portOfLoading || '',
                portOfLoadingId: data?.documentData?.portOfLoading.name || '',
                portOfLoadingValue: data?.documentData?.portOfLoading || '',
                portOfDischarge: data?.documentData?.portOfDischarge || '',
                portOfDischargeId:
                    data?.documentData?.portOfDischarge.name || '',
                portOfDischargeValue: data?.documentData?.portOfDischarge || '',
                sellerAddress: data?.documentData?.seller?.address || '',
                receiverAddress: data?.documentData?.receiver?.address || '',
                logoUrl: data?.documentData?.logoUrl || '',
                sellerCountry: '',
                receiverCountry: ''
            });

            const transformArray = (array: any) => {
                const newArray: any = [];

                array.forEach((val: any) => {
                    newArray.push({
                        id: val.id,
                        productCode: val.productCode,
                        desc: val.descriptionOfGoods,
                        qty: val.quantity,
                        type: val.unitType,
                        price: val.unitPrice
                    });
                });
                return newArray;
            };
            setQuoteFields(transformArray(data?.documentData?.items));
        }
    }, [data]);

    // Custom hooks
    const { isLoading: isLoadingCurrencies, currencies } = useCurrencies();
    const { corporatePartners, isLoading } = useCorporatePartners({ page: 1 });

    // References
    const myDiv = useRef<HTMLDivElement | null>(null);
    const pageTopRef = useRef<any>(null);

    //Local methods
    const closeShowAddParty = () => {
        setShowAddPartner(false);
    };

    /**
     * Close save document
     */
    const closeSaveModal = () => {
        setShowSaveModal(false);
    };

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    /**
     * Update quotes input fields
     * @param id string
     * @param name string
     * @param value any
     */
    const updateQuoteField = (
        index: number,
        attributeName: string,
        attributeValue?: any
    ) => {
        const newContainers: any[] = [...quoteFields];
        newContainers[index][attributeName] = attributeValue;
        setQuoteFields(newContainers);
    };

    /**
     * Adds quotes input field line
     */
    const addQuoteField = () => {
        setQuoteFields([
            ...quoteFields,
            {
                id: nanoid(9),
                productCode: nanoid(9),
                desc: undefined,
                qty: 0,
                type: undefined,
                price: 0
            }
        ]);
    };

    /**
     * Remove quotes input field line
     */
    const removeQuoteField = (index: number) => {
        setQuoteFields(quoteFields.filter((item, i) => i !== index));
    };

    useEffect(() => {
        let totalNumber = 0;
        quoteFields.forEach((item) => {
            totalNumber +=
                (isNaN(item.price) ? 0 : Number(item.price)) *
                (isNaN(item.qty) ? 0 : Number(item.qty));
        });
        setTotalAmount(totalNumber);
    }, [quoteFields]);

    //local variables
    const breadcrumbs = [
        {
            title: 'Home',
            link: '/',
            active: false
        },
        {
            title: 'Documents',
            link: '/trade-documents',
            active: false
        },
        {
            title: 'Quotation',
            link: '/trade-documents/quotation',
            active: true
        }
    ];

    const onSubmit = async () => {
        setLoading(true);
        setError(null);
        setShowSaveModal(false);

        setErrors((prevState: any) => ({
            ...prevState,
            receiverId: '',
            issuedDate: '',
            documentData: '',
            portOfLoading: '',
            methodOfDispatch: '',
            portOfDischarge: '',
            typeOfShipment: '',
            totalAmount: '',
            additionalInformation: '',
            placeOfIssue: '',
            signatoryCompany: '',
            nameOfAuthorisedSignatory: '',
            signatureUrl: '',
            sellerId: '',
            quoteNumber: '',
            documentName: '',
            dateOfIssue: '',
            currencyCode: '',
            sellerAddress: '',
            receiverAddress: '',
            logoUrl: ''
        }));

        try {
            const items: any[] = [];

            const {
                issuedDate,
                sellerId,
                receiverId,
                portOfLoadingId,
                methodOfDispatch,
                portOfDischargeId,
                typeOfShipment,
                // totalAmount,
                additionalInformation,
                placeOfIssue,
                signatoryCompany,
                nameOfAuthorisedSignatory,
                signatureUrl,
                quoteNumber,
                documentName,
                dateOfIssue,
                currencyCode,
                logoUrl,
                portOfDischargeValue,
                portOfLoadingValue,
                salesContractNumber
                // billOfLadingNumber,
            } = payload;

            const dischargeValue: any = portOfDischargeValue;
            const portValue: any = portOfLoadingValue;

            quoteFields.forEach((item) => {
                items.push({
                    amount: item.price,
                    descriptionOfGoods: item.desc,
                    productCode: item.productCode,
                    quantity: item.qty,
                    unitPrice: item.price,
                    unitType: item.type
                });
            });

            const res = await createQuotation({
                createdType: 'CREATED',
                documentData: {
                    items,
                    documentDate: issuedDate,
                    sellerId,
                    receiverId,
                    methodOfDispatch,
                    portOfDischargeId: dischargeValue?.id,
                    portOfLoadingId: portValue?.id,
                    typeOfShipment,
                    totalAmount,
                    additionalInformation,
                    placeOfIssue,
                    signatoryCompany,
                    nameOfAuthorisedSignatory,
                    signatureUrl,
                    quoteNumber,
                    issuedDate: dateOfIssue,
                    currencyCode,
                    logoUrl: logoUrl !== '' ? logoUrl : undefined,
                    salesContractNumber:
                        salesContractNumber !== ''
                            ? salesContractNumber
                            : undefined
                    // billOfLadingNumber,
                },
                documentType: 'QUOTATION',
                documentName,
                id: fileId
            });

            const { id } = res.data.data;

            setFileId(id);

            openNotification(
                'success',
                'Quotation document created successfully'
            );

            setLoading(false);

            if (saveOnly) {
                navigate('/trade-documents');
            }
        } catch (error) {
            window.scroll({
                top: pageTopRef?.current?.offsetTop,
                left: 0,
                behavior: 'smooth'
            });

            setShowSaveModal(false);
            setLoading(false);
            const otherErrors: any[] = [];

            if (error?.response) {
                if (error?.response?.data?.error) {
                    const resError = error?.response?.data?.error;

                    if (typeof resError === 'string') {
                        const errorList = (
                            <li key={`error-list-${1}`}>{resError}</li>
                        );
                        setError(errorList);
                    } else {
                        Object.entries(resError).forEach(([key, value]) => {
                            if (Object.keys(errors).includes(key)) {
                                setErrors((prevState) => ({
                                    ...prevState,
                                    [key]: value
                                }));
                            } else {
                                otherErrors.push(value);
                            }
                        });

                        if (otherErrors.length > 0) {
                            const errorList = otherErrors.map(
                                (element: any, index) => {
                                    return (
                                        <li key={`error-list-${index}`}>
                                            {element}
                                        </li>
                                    );
                                }
                            );
                            setError(errorList);
                        }
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    return (
        <TradeContainer>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loadingData}
            >
                <img
                    src='/img/vectors/loadingcircles.svg'
                    alt='loading'
                    className='animate-spin'
                />
            </Backdrop>
            <FormHeader
                breadcrumbs={breadcrumbs}
                docTitle='Quotation'
                {...{
                    showPreview,
                    setShowPreview,
                    myDiv,
                    downloadLoading,
                    setDownloadLoading
                }}
                onSaveOnlyAction={() => {
                    setSaveOnly(true);
                    setShowSaveModal(true);
                }}
                onSaveAction={() => {
                    setSaveOnly(false);
                    setShowSaveModal(true);
                }}
                disabled={
                    Object.values(payload).some(
                        (item: any) => item === undefined
                    ) || loading
                }
            />

            <TradeContent>
                {' '}
                {showPreview ? (
                    <div ref={myDiv}>
                        <QuotationPreview
                            data={payload}
                            docTitle='Quotation'
                            quoteFields={quoteFields}
                            totalAmount={totalAmount}
                            signatureUrlBase64={signatureUrlBase64}
                        />
                    </div>
                ) : (
                    <QuotationForm
                        {...{
                            error,
                            updateField,
                            payload,
                            corporatePartners,
                            loadingCorporatepartners: isLoading,
                            setShowAddPartner,
                            errors,
                            quoteFields,
                            updateQuoteField,
                            removeQuoteField,
                            currencies,
                            addQuoteField,
                            loading,
                            loadingCurrencies: isLoadingCurrencies,
                            setSignatureUrlBase64,
                            signatureUrlBase64,
                            setSaveOnly,
                            setShowSaveModal,
                            totalAmount
                        }}
                    />
                )}
                {showAddPartner ? (
                    <Addpartnerdrawer
                        closeDrawer={closeShowAddParty}
                        showDrawer={showAddPartner}
                    />
                ) : null}
                {showSaveModal && (
                    <CustomModal
                        open={showSaveModal}
                        onClose={closeSaveModal}
                        title='Saving document'
                        maxWidth='xs'
                        styles={{
                            overflowX: 'hidden',
                            '.MuiPaper-root': {
                                width: '100%'
                            }
                        }}
                    >
                        <Savetradedocument
                            updateField={updateField}
                            onSubmit={onSubmit}
                            loading={loading}
                            data={payload}
                        />
                    </CustomModal>
                )}
            </TradeContent>
        </TradeContainer>
    );
};

export default QuotationNew;
