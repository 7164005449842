import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BookingForm } from './interfaces/BookingForm';
import { GeneralDetail } from './interfaces/GeneralDetail';
import { BookingConnection } from './interfaces/BookingConnection';
import { useGetQuery } from '../../../../customhooks/useGetQuery';
import { Party } from './interfaces/Party';
import { Container } from './interfaces/Container';
import { Cargo } from './interfaces/Cargo';
import { Reference } from './interfaces/Reference';
import { Payment } from './interfaces/Payment';
import { Transport } from './interfaces/Transport';
import Outletouterpage from '../../../../components/dashboard/outletouterpage/outletouterpage';
import Newcarrierdrawer from '../newcarrierdrawer/newcarrierdrawer';
import useShippingLines from '../../../../customhooks/shippinglines/userShippinglines';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { mutateConfig } from '../../../../api/queryBase';
import Bookingsetupdrawer from '../bookingsetupdrawer/bookingsetupdrawer';
import { updateUser } from '../../../../store/userSlice/userSlice';
import Setupbookingprofile from './setupbookingprofile';
import AddNewCommodity from '../../tradedocuments/addNewCommodity/AddNewCommodity';
import { CreateBookingForm } from './CreateBookingForm';
import AddPartyDrawer from '../AddPartyDrawer';
import { BookingFormValidationSchema } from './schemas/BookingFormValidationSchema';
import { sanitizePayload } from './utils/sanitizePayload';
import CreateBookingMobileForm from './CreateBookingMobileForm';
import { getErrorMessage } from '../../../../utils/helper';

const initialFormData = {
    cargo: [{ uid: uuidv4() } as Cargo],
    container: [{ uid: uuidv4() } as Container],
    general: {} as GeneralDetail,
    parties: {} as Party,
    payment: [{ uid: uuidv4() } as Payment],
    references: {} as Reference,
    salesContractNumber: '',
    transport: {
        main: [{ uid: uuidv4() }]
    } as Transport
};

const CreateBooking = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { shippingLines, isLoadingShippingLines } = useShippingLines({
        forBooking: true
    });
    const shippingLineOptions = shippingLines.map((line) => {
        return {
            label: line.name,
            ...line
        };
    });

    const state = useSelector((state: RootState) => ({
        user: state.user.user
    }));

    const { user } = state;

    const [showAddParty, setShowAddParty] = useState(false);

    const closeShowAddParty = () => {
        setShowAddParty(false);
    };
    const [showBookingSetup, setShowBookingSetup] = useState(false);
    const [showAddNewCarrierModal, setShowAddNewCarrierModal] = useState(false);

    const closeShowBookingSetup = (updateUserData?: boolean) => {
        setShowBookingSetup(false);
        if (updateUserData && user) {
            dispatch(
                updateUser({
                    user: {
                        ...user,
                        booking: true
                    }
                })
            );
        }
    };

    const closeShowNewCarrier = () => {
        setShowAddNewCarrierModal(false);
    };
    const [showAddNewCommodity, setShowAddNewCommodity] = useState(false);
    const closeShowAddCommodity = () => {
        setShowAddNewCommodity(false);
    };

    const {
        isLoading: isLoadingBookingConnections,
        entries: bookingConnections
    } = useGetQuery<BookingConnection[]>({
        params: { page: 1, size: 0 },
        url: '/booking/shipping/profiles',
        cacheKey: 'shipping-profiles'
    });
    const [formData, setFormData] = useState<BookingForm>(initialFormData);
    const [showAdditionalSection, setShowAdditionalSection] = useState({
        general: false,
        parties: false,
        references: false
    });
    const mutation = mutateConfig();
    const { mutate } = mutation({
        url: `/booking/create`
    });
    const formik = useFormik({
        initialValues: initialFormData,
        validationSchema: BookingFormValidationSchema,
        isInitialValid: false,
        onSubmit: () => {
            const sanitizedData = sanitizePayload(formData);

            mutate(sanitizedData, {
                onSettled: (_, error) => {
                    if (error) {
                        toast.error(
                            getErrorMessage(error) ||
                                'Booking creation failed. Please try again later'
                        );
                        return;
                    }

                    setFormData(initialFormData);
                    formik.resetForm();
                    toast.info('Booking created successfully');
                    navigate('/bookings');
                }
            });
        }
    });

    const onHandleSubmit = () => {
        formik.handleSubmit();
    };

    return (
        <>
            <div className='hidden lg:block mb-12 2xl:max-w-[70rem]'>
                <Outletouterpage
                    breadcrumbs={[
                        {
                            title: 'Home',
                            link: '/dashboard'
                        },
                        {
                            title: 'Bookings',
                            link: '/bookings'
                        },
                        {
                            title: user?.booking
                                ? 'Create Booking'
                                : 'New Booking Profile',
                            link: '/bookings/create-booking'
                        }
                    ]}
                >
                    <div>
                        {!user?.booking && user?.corporate_id && (
                            <Setupbookingprofile
                                corporateId={user?.corporate_id}
                                user={user}
                            />
                        )}

                        <CreateBookingForm
                            formik={formik}
                            formData={formData}
                            setFormData={setFormData}
                            user={user}
                            setShowAddParty={setShowAddParty}
                            setShowAddNewCarrierModal={
                                setShowAddNewCarrierModal
                            }
                            setShowAddNewCommodity={setShowAddNewCommodity}
                            isLoadingBookingConnections={
                                isLoadingBookingConnections
                            }
                            bookingConnections={bookingConnections}
                            showAdditionalSection={showAdditionalSection}
                            setShowAdditionalSection={setShowAdditionalSection}
                            onHandleSubmit={onHandleSubmit}
                        />
                    </div>
                </Outletouterpage>

                {showBookingSetup && user?.corporate_id && (
                    <Bookingsetupdrawer
                        corporateId={user.corporate_id}
                        closeDrawer={closeShowBookingSetup}
                        showDrawer={showBookingSetup}
                    />
                )}

                {showAddParty && user?.corporate_id && (
                    <AddPartyDrawer
                        corporateId={user.corporate_id}
                        closeDrawer={closeShowAddParty}
                        showDrawer={showAddParty}
                    />
                )}

                {showAddNewCarrierModal && user?.corporate_id && (
                    <Newcarrierdrawer
                        corporateId={user.corporate_id}
                        closeDrawer={closeShowNewCarrier}
                        showDrawer={showAddNewCarrierModal}
                        shippingLines={shippingLineOptions}
                        isLoadingShippingLines={isLoadingShippingLines}
                    />
                )}

                {showAddNewCommodity ? (
                    <AddNewCommodity
                        closeDrawer={closeShowAddCommodity}
                        showDrawer={showAddNewCommodity}
                    />
                ) : null}
            </div>

            <CreateBookingMobileForm
                formik={formik}
                formData={formData}
                setFormData={setFormData}
                user={user}
                setShowAddParty={setShowAddParty}
                setShowAddNewCarrierModal={setShowAddNewCarrierModal}
                setShowAddNewCommodity={setShowAddNewCommodity}
                isLoadingBookingConnections={isLoadingBookingConnections}
                bookingConnections={bookingConnections}
                showAdditionalSection={showAdditionalSection}
                setShowAdditionalSection={setShowAdditionalSection}
                onHandleSubmit={onHandleSubmit}
            />
        </>
    );
};

export default CreateBooking;
