import { http } from '..'
import { paginatedData } from '../../utils/helper-interface'

interface getDocumentsPayload extends paginatedData {
    corporateDocumentId?: number;
    name?: string;
    search?: string;
    id?: number;
}

export const getDocuments = (data: getDocumentsPayload) => {
    return http.get(`/sales/documents`, {
        params: {
            ...data
        }
    });
};

export const getSingleDocuments = (data: getDocumentsPayload) => {
    return http.get(`/sales/documents/${data?.corporateDocumentId}`);
};

export const getUploadedLogo = (data: getDocumentsPayload) => {
    return http.get(`/sales/documents/logo`, {
        params: {
            ...data
        }
    });
};

export const createSalesContract = (data: any) => {
    return http.post('/sales/documents/sales/contract', data);
};

export const createQuotation = (data: any) => {
    return http.post('/sales/documents/quotation', data);
};

export const createProformaInvoice = (data: any) => {
    return http.post('/sales/documents/proforma/invoice', data);
};

export const createCommercialInvoice = (data: any) => {
    return http.post('/sales/documents/commercial/invoice', data);
};

export const createHouseBillOfLading = (data: any) => {
    return http.post('/sales/documents/house/bill/of/lading', data);
};

export const createParkingList = (data: any) => {
    return http.post('/sales/documents/packing/list', data);
};

export const createGross = (data: any) => {
    return http.post('/sales/documents/verified/gross/mass/declaration', data);
};

export const createCertOfOrigin = (data: any) => {
    return http.post('/sales/documents/certificate/of/origin', data);
};

export const createPurchaseOrder = (data: any) => {
    return http.post('/sales/documents/purchase/order', data);
};

export const createDocumentGeneral = (data: any) => {
    return http.post('/sales/documents/general', data);
};

export const createPartner = (data: any) => {
  return http.post('/sales/documents/partner/', data)
}

export const updatePartner = (data: any, id: number) => {
  return http.put(`/sales/documents/partner/${id}`, data)
}

export const createCommodity = (data: any) => {
  return http.post('/commodity', data)
}
