import React, { useState, useEffect } from 'react';
import Outletouterpage from '../../../../components/dashboard/outletouterpage/outletouterpage';
import { Mapping } from '../mapping/mapping';
import Shipmentitem from './shipmentitem/shipmentitem';
import { openNotification } from '../../../../utils/helper';
import { createTracking, getTracking } from '../../../../api/booking/booking';
import Skeleton from '@mui/material/Skeleton';
import { Alert, Grid } from '@mui/material';
import NewCustomSelect from '../../../../components/newcustomselect/newcustomselect';
import useShippingLines from '../../../../customhooks/shippinglines/userShippinglines';
import Custombutton from '../../../../components/custombutton/custombutton';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import CustomTextField from '../../../../components/CustomTextInput';

const Trackshipment = () => {
    const { shippingLines, isLoadingShippingLines } = useShippingLines({
        forShipping: true
    });

    const state = useSelector((state: RootState) => ({
        user: state.user.user
    }));

    const { user } = state;

    const [error, setError] = useState<string | any>(null);
    const [creating, setCreating] = useState(false);
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [payload, setPayload] = useState({
        shippingLineId: undefined,
        billOfLadingNumber: undefined,
        trackingType: undefined
    });
    const [errors, setErrors] = useState({
        shippingLineId: '',
        billOfLadingNumber: '',
        trackingType: ''
    });

    const fetchTrackings = async () => {
        setLoadingData(true);
        try {
            const res = await getTracking({
                page: 1,
                size: 0
            });

            const { data } = res.data;

            setData(data.content);
            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            if (error?.response) {
                openNotification('error', `Tracking, Something went wrong`);
            } else {
                openNotification('error', `Tracking, Something went wrong`);
            }
        }
    };

    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrors((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));
    };

    const onSubmit = async () => {
        if (!user) {
            return;
        }

        setCreating(true);
        setError(null);

        setErrors((prevState: any) => ({
            ...prevState,
            shippingLineId: '',
            billOfLadingNumber: '',
            trackingType: ''
        }));

        try {
            await createTracking(user.user_id, payload);

            setCreating(false);

            openNotification('success', 'Tracking was created successfully');

            fetchTrackings();

            setPayload((prevState: any) => ({
                ...prevState,
                shippingLineId: undefined,
                billOfLadingNumber: undefined,
                trackingType: undefined
            }));
        } catch (error) {
            setCreating(false);
            if (error?.response) {
                if (error?.response?.data?.error) {
                    let hasFieldNameAttribute = false;

                    error?.response?.data?.error.map((item: any) => {
                        return Object.keys(item).map((itemInside: string) => {
                            if (itemInside === 'fieldName') {
                                hasFieldNameAttribute = true;

                                return;
                            }
                        });
                    });

                    if (hasFieldNameAttribute) {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>
                                    {element.messageError}
                                </li>
                            );
                        });
                        setError(errorList);
                    } else {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>{element}</li>
                            );
                        });
                        setError(errorList);
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    useEffect(() => {
        fetchTrackings();
    }, []);

    return (
        <div className='relative'>
            <div className='relative lg:block flex flex-col-reverse'>
                <div className='lg:w-[30%]'>
                    <Outletouterpage
                        breadcrumbs={[
                            {
                                title: 'Home',
                                link: '/dashboard'
                            },
                            {
                                title: 'Bookings',
                                link: '/bookings'
                            },
                            {
                                title: 'Track Shipment',
                                link: '/bookings/track-shipments/123'
                            }
                        ]}
                    >
                        <div className='lg:mt-0 mt-[9rem]  '>
                            <div className='flex justify-between lg:mb-8 lg:px-0 px-4 bg-white bg-[unset] pt-9 lg:pt-0'>
                                <h1 className='lg:text-[32px] text-[18px] leading-[23px] text-appcolorblacktwo border-b pb-3 w-full lg:border-b-0 lg:pb-0'>
                                    Track Shipments
                                </h1>
                            </div>

                            <div className='w-full px-4 p-4 bg-appcolorwhite'>
                                <Grid
                                    container
                                    spacing={4}
                                    sx={{
                                        marginTop: '0px'
                                    }}
                                >
                                    <Grid item xs={12} md={12} lg={12}>
                                        {error !== null && (
                                            <div className='mt-10 mb-4'>
                                                <Alert severity='error'>
                                                    {error}
                                                </Alert>
                                            </div>
                                        )}

                                        <div className='bg-appcolorwhite rounded-[3px] mb-4'>
                                            <Grid
                                                container
                                                spacing={2}
                                                sx={{
                                                    marginTop: 0
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={6}
                                                    lg={6}
                                                >
                                                    <CustomTextField
                                                        value={
                                                            payload?.billOfLadingNumber
                                                        }
                                                        error={
                                                            errors
                                                                ?.billOfLadingNumber
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors
                                                                ?.billOfLadingNumber
                                                                .length > 0
                                                                ? errors?.billOfLadingNumber
                                                                : undefined
                                                        }
                                                        id='createtraking-bookingNumber'
                                                        label='Container, Booking, Bill of Lading'
                                                        name='bookingNumber'
                                                        onChange={(e: any) => {
                                                            updateField(
                                                                'billOfLadingNumber',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <div className='lg:mt-0 mt-4'>
                                                        <NewCustomSelect
                                                            selectValue={
                                                                payload.trackingType
                                                            }
                                                            required
                                                            selectOptions={[
                                                                {
                                                                    name: 'Container',
                                                                    value: 'CN'
                                                                },
                                                                {
                                                                    name: 'Bill of lading',
                                                                    value: 'BL'
                                                                },
                                                                {
                                                                    name: 'Booking Number',
                                                                    value: 'BK'
                                                                }
                                                            ]}
                                                            name={
                                                                'shippingLineId'
                                                            }
                                                            updateField={(
                                                                _,
                                                                value
                                                            ) => {
                                                                updateField(
                                                                    'trackingType',
                                                                    value
                                                                );
                                                            }}
                                                            label={
                                                                'Tracking Type'
                                                            }
                                                            alternativeValue='value'
                                                            selectOptionLabel='name'
                                                            loading={false}
                                                        />
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} md={12} lg={12}>
                                        <NewCustomSelect
                                            selectValue={payload.shippingLineId}
                                            required
                                            selectOptions={
                                                shippingLines as unknown as Record<
                                                    string,
                                                    unknown
                                                >[]
                                            }
                                            name={'shippingLineId'}
                                            updateField={(_, value) => {
                                                updateField(
                                                    'shippingLineId',
                                                    value
                                                );
                                            }}
                                            label={'Shipping Line'}
                                            selectOptionLabel='name'
                                            loading={isLoadingShippingLines}
                                        />
                                    </Grid>
                                </Grid>

                                <div className='mt-6'>
                                    <Custombutton
                                        onClickAction={() => {
                                            onSubmit();
                                        }}
                                        isLoadingButton
                                        loading={creating}
                                        type='submit'
                                        fullWidth
                                        variant='contained'
                                        styles={{
                                            padding: '0.7rem',
                                            borderRadius: '10px'
                                        }}
                                        disabled={
                                            Object.values(payload).some(
                                                (item: any) =>
                                                    item === undefined
                                            ) ||
                                            Object.values(errors).some(
                                                (item: any) => item.length > 0
                                            ) ||
                                            creating
                                        }
                                        buttonText='Submit'
                                    />
                                </div>
                            </div>

                            <div className='mt-8 px-4 lg:px-0'>
                                <p className=' opacity-50 text-base uppercase pb-2 font-medium'>
                                    Recent
                                </p>
                                {loadingData ? (
                                    <div className='mt-[-3rem]'>
                                        <Skeleton
                                            variant='text'
                                            sx={{ fontSize: '10rem' }}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        {data.map((item: any) => (
                                            <Shipmentitem
                                                key={`tracking-${item.id}`}
                                                item={item}
                                            />
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </Outletouterpage>
                </div>

                <div className='lg:w-[55%] lg:h-screen h-60 lg:fixed right-0 lg:top-[0rem] relative'>
                    <h1 className='lg:hidden mt-8 mb-5 mx-5 text-[20px] leading-[20px] text-appcolorblacktwo'>
                        Tracking
                    </h1>
                    <Mapping isDrawer paths={[]} />
                </div>
            </div>
        </div>
    );
};

export default Trackshipment;
