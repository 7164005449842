export const BarChartIcon = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect x='3' y='3' width='34' height='34' rx='17' fill='#B3DBE8' />
            <path
                d='M24.25 25.6667V18.5833M20 25.6667V14.3333M15.75 25.6667V21.4167'
                stroke='#0088B2'
                strokeWidth='1.41667'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <rect
                x='3'
                y='3'
                width='34'
                height='34'
                rx='17'
                stroke='#E5F3F7'
                strokeWidth='5.66667'
            />
        </svg>
    );
};
