import React from 'react';
import OpsTransactionsTable from './OpsTransactionsTable';
import OpsBeneficiaryTable from './OpsBeneficiariesTable';
import { PaymentTablesProps } from './interfaces/PaymentTable';

const OpsPaymentTable = ({
    isBeneficiariesLoading,
    beneficiaries,
    transactionsList,
    activeView,
    isTransactionsLoading,
    setShowAddBeneficiary,
    reRunGetBeneficiaries,
    setBeneficiaryId
}: PaymentTablesProps) => {
    return (
        <>
            {activeView === 'transactions' ? (
                <OpsTransactionsTable
                    {...{ isTransactionsLoading, transactionsList }}
                />
            ) : (
                <OpsBeneficiaryTable
                    {...{
                        isBeneficiariesLoading,
                        beneficiaries,
                        setShowAddBeneficiary,
                        reRunGetBeneficiaries,
                        setBeneficiaryId
                    }}
                />
            )}
        </>
    );
};

export default OpsPaymentTable;
