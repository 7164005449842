import Autocomplete from '@mui/material/Autocomplete';
import { BookingEntryDivider } from './BookingEntryDivider';
import { BookingForm } from './interfaces/BookingForm';
import { Payment } from './interfaces/Payment';
import CustomMapInput from '../../../../components/CustomMapInput';
import CustomTextField from '../../../../components/CustomTextInput';

const chargeTypes = [
    'Additional Charges',
    'Basic Freight',
    'Destination Haulage Charges',
    'Destination Port Charge',
    'Origin Port Charges',
    'Origin Haulage Charges'
];

const paymentTerms = ['Pre-paid', 'Collect', 'Payable Elsewhere'];

const payers = [
    'Booker',
    'Consignee',
    'Contract Party',
    'Forwarder',
    'Main Notify Party',
    'First Additional Notify Party',
    'Second Additional Notify Party',
    'Shipper'
];
interface PaymentFormProps {
    content: Payment;
    index: number;
    isRemoveShown?: boolean;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
}

export const PaymentForm = (props: PaymentFormProps) => {
    const { content, setFormData, index, isRemoveShown } = props;

    const onHandleRemoveItem = () => {
        setFormData((prev) => {
            prev.payment.splice(index, 1);

            return {
                ...prev,
                payment: prev.payment
            };
        });
    };

    return (
        <>
            <BookingEntryDivider
                index={index}
                isRemoveShown={isRemoveShown}
                onHandleRemoveItem={onHandleRemoveItem}
            />

            <div className='flex flex-col gap-4 pb-4 lg:pb-0 lg:grid lg:grid-cols-4'>
                <div className='w-full'>
                    <Autocomplete
                        options={chargeTypes.map((label) => ({ label }))}
                        getOptionLabel={(option) => option.label}
                        value={
                            content.chargeType
                                ? { label: content.chargeType }
                                : null
                        }
                        renderInput={(params) => (
                            <CustomTextField
                                params={params}
                                label='Charge Type'
                            />
                        )}
                        onChange={(_, value) => {
                            if (value) {
                                const entry = {
                                    ...content,
                                    chargeType: value.label
                                };
                                setFormData((prev) => {
                                    prev.payment.splice(index, 1, entry);

                                    return {
                                        ...prev,
                                        payment: prev.payment
                                    };
                                });
                            }
                        }}
                    />
                </div>
                <div className='w-full'>
                    <Autocomplete
                        options={paymentTerms.map((label) => ({ label }))}
                        value={
                            content.paymentTerm
                                ? { label: content.paymentTerm }
                                : null
                        }
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <CustomTextField
                                params={params}
                                label='Payment Term'
                            />
                        )}
                        onChange={(_, value) => {
                            if (value) {
                                const entry = {
                                    ...content,
                                    paymentTerm: value.label
                                };
                                setFormData((prev) => {
                                    prev.payment.splice(index, 1, entry);

                                    return {
                                        ...prev,
                                        payment: prev.payment
                                    };
                                });
                            }
                        }}
                    />
                </div>

                <div className='w-full'>
                    <Autocomplete
                        options={payers.map((label) => ({ label }))}
                        value={content.payer ? { label: content.payer } : null}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <CustomTextField params={params} label='Payer' />
                        )}
                        onChange={(_, value) => {
                            if (value) {
                                const entry = {
                                    ...content,
                                    payer: value.label
                                };
                                setFormData((prev) => {
                                    prev.payment.splice(index, 1, entry);

                                    return {
                                        ...prev,
                                        payment: prev.payment
                                    };
                                });
                            }
                        }}
                    />
                </div>

                <div className='w-full'>
                    <CustomMapInput
                        name='paymentLocation'
                        value={content.paymentLocation}
                        updateField={(_, value) => {
                            const entry = {
                                ...content,
                                paymentLocation: value
                            };
                            setFormData((prev) => {
                                prev.payment.splice(index, 1, entry);

                                return {
                                    ...prev,
                                    paymentLocation: prev.payment
                                };
                            });
                        }}
                        label='Payment Location'
                    />
                </div>
            </div>
        </>
    );
};
