import React, { FC } from 'react';
import { REACT_APP_BASE_URL } from '../../../../config';
import { Stack } from '@mui/material';
import { PreviewContent } from '../../../../styles/TradeDocStyles';
import moment from 'moment';
import { Table } from 'antd';
import { formatCurrency, formatNumber } from '../../../../utils/helper';
import DocumentViewer from '../../../DocumentViewer/DocumentViewer';

interface IProps {
    data: any;
    docTitle: string;
    quoteFields: any;
    totalAmount?: number;
    signatureUrlBase64: any;
}

const QuotationPreview: FC<IProps> = ({
    data,
    docTitle,
    quoteFields,
    totalAmount,
    signatureUrlBase64
}: IProps) => {
    return (
        <PreviewContent>
            <div className='text-[14px]'>
                <div className='flex items-center justify-between pb-4 mb-4'>
                    <div className='w-[400px]'>
                        {data?.logoUrl ? (
                            <div className='h-[50px] w-[100px]'>
                                <DocumentViewer
                                    fileUrl={`${REACT_APP_BASE_URL}/${data?.logoUrl.split(
                                        '/v1/'
                                    )[1]}`}
                                />
                            </div>
                        ) : (
                            <>
                                <div>
                                    <img
                                        src='/img/vectors/powered.svg'
                                        alt=''
                                        className='h-[2.5rem]'
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <div>
                        <h1 className='text-appcolorprimary text-[26px] leading-[27px] font-bold capitalize'>
                            {docTitle}
                        </h1>
                    </div>
                </div>
            </div>

            <Stack
                direction='row'
                alignItems='start'
                className='top-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                {docTitle !== 'Draft Gross Mass Declaration' && (
                    <div className='view-one'>
                        <p className='font-semibold text-appcolorprimary'>
                            Invoice From
                        </p>
                        <p className='font-medium'>{data?.sellerName}</p>
                        <p>{data?.sellerAddress}</p>
                        <p>{data?.sellerCountry}</p>
                    </div>
                )}
                {docTitle !== 'Draft Gross Mass Declaration' && (
                    <div className='view-two'>
                        <p className='font-semibold text-appcolorprimary'>
                            Invoice To
                        </p>
                        <p className='font-medium'>{data?.receiverName}</p>
                        <p>{data?.receiverAddress}</p>
                        <p>{data?.receiverCountry}</p>
                    </div>
                )}
                {docTitle === 'Proforma Invoice' && (
                    <div className='view-three'>
                        <div></div>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Invoice No.</p>
                            <p className='value'>
                                {data?.invoiceNumber || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Date</p>
                            <p className='value'>
                                {moment(data.invoiceDate).format('ll') || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Buyer Reference</p>
                            <p className='value'>
                                {data.buyerReference || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Port of Loading</p>
                            <p className='value'>
                                {data.portOfLoadingValue?.name || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Port of Discharge</p>
                            <p className='value'>
                                {data.portOfDischargeValue?.name || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Method of Dispatch</p>
                            <p className='value'>
                                {data.methodOfDispatch || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Type of Shipment</p>
                            <p className='value'>
                                {data.typeOfShipment || '--'}
                            </p>
                        </Stack>
                    </div>
                )}
                {docTitle === 'Quotation' && (
                    <div className='view-three'>
                        <div></div>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Quote No.</p>
                            <p className='value'>{data?.quoteNumber || '--'}</p>
                        </Stack>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Issued Date</p>
                            <p className='value'>
                                {moment(data.issuedDate).format('ll') || '--'}
                            </p>
                        </Stack>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Port of Loading</p>
                            <p className='value'>
                                {data.portOfLoadingValue?.name || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Method of Dispatch</p>
                            <p className='value'>
                                {data.methodOfDispatch || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Port of Discharge</p>
                            <p className='value'>
                                {data.portOfDischargeValue?.name || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Type of Shipment</p>
                            <p className='value'>
                                {data.typeOfShipment || '--'}
                            </p>
                        </Stack>
                    </div>
                )}

                {docTitle === 'Purchase Order' && (
                    <div className='view-three'>
                        <div></div>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                            alignItems='flex-end'
                        >
                            <p className='title'>Purchase Order No.</p>
                            <p className='value'>
                                {data?.purchaseOrderNumber || '--'}
                            </p>
                        </Stack>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Issued Date</p>
                            <p className='value'>
                                {moment(data.issuedDate).format('ll') || '--'}
                            </p>
                        </Stack>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Supplier Reference</p>
                            <p className='value'>
                                {data.supplierReference || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Shipper Reference</p>
                            <p className='value'>
                                {data.shipperReference || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Method of Dispatch</p>
                            <p className='value'>
                                {data.methodOfDispatch || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Type of Shipment</p>
                            <p className='value'>
                                {data.typeOfShipment || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Port of Loading</p>
                            <p className='value'>
                                {data.portOfLoadingValue?.name || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Port of Discharge</p>
                            <p className='value'>
                                {data.portOfDischargeValue?.name || '--'}
                            </p>
                        </Stack>
                    </div>
                )}

                {docTitle === 'Draft Gross Mass Declaration' && (
                    <>
                        <div className='view-one'>
                            <p className='font-semibold text-appcolorprimary'>
                                Shipper
                            </p>
                            <p className='font-medium'>{data?.shipperName}</p>
                            <p>{data?.shipperAddress}</p>
                            <p>{data?.shipperCountry}</p>
                            <div className='mt-[5px] w-full'></div>
                        </div>
                        <div className='view-three'>
                            <div></div>
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                                alignItems='flex-end'
                            >
                                <p className='title'>Shipper Reference.</p>
                                <p className='value'>
                                    {data?.shipperReference || '--'}
                                </p>
                            </Stack>

                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                                alignItems='flex-end'
                            >
                                <p className='title'>Shipper Address.</p>
                                <p className='value'>
                                    {data?.shipperAddress || '--'}
                                </p>
                            </Stack>

                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                                alignItems='flex-end'
                            >
                                <p className='title'>Certificate Number.</p>
                                <p className='value'>
                                    {data?.certificateNumber || '--'}
                                </p>
                            </Stack>
                        </div>
                    </>
                )}
            </Stack>

            <div className='table-view'>
                <Table
                    rowKey={'id'}
                    columns={
                        docTitle === 'Draft Gross Mass Declaration'
                            ? [
                                  {
                                      title: 'Container No',
                                      dataIndex: 'containerNumber',
                                      key: 'containerNumber'
                                  },
                                  {
                                      title: 'Container Tare (Kg)',
                                      dataIndex: 'containerTare',
                                      key: 'containerTare',
                                      render: (text) => (
                                          <span>{formatNumber(text)} Kg</span>
                                      )
                                  },
                                  {
                                      title: 'Cargo Weight (KG)',
                                      dataIndex: 'cargoWeight',
                                      key: 'cargoWeight',
                                      render: (text) => (
                                          <span>{formatNumber(text)} Kg</span>
                                      )
                                  },
                                  {
                                      title: 'Gross Weight (KG)',
                                      dataIndex: 'grossWeight',
                                      key: 'grossWeight',
                                      render: (text) => (
                                          <span>{formatNumber(text)} Kg</span>
                                      )
                                  }
                              ]
                            : [
                                  {
                                      title: 'Product Code',
                                      dataIndex: 'id',
                                      key: 'id'
                                  },
                                  {
                                      title: 'Description of goods',
                                      dataIndex: 'desc',
                                      key: 'desc'
                                  },
                                  {
                                      title: 'Quantity',
                                      dataIndex: 'qty',
                                      key: 'qty',
                                      render: (text) => (
                                          <span>{formatNumber(text)}</span>
                                      )
                                  },
                                  {
                                      title: 'Unit type',
                                      dataIndex: 'type',
                                      key: 'type'
                                  },
                                  {
                                      title: 'Price',
                                      dataIndex: 'price',
                                      key: 'price',
                                      render: (text) => (
                                          <span>
                                              {formatCurrency(
                                                  text,
                                                  data?.currencyCode
                                              )}
                                          </span>
                                      )
                                  },
                                  {
                                      title: 'Total',
                                      dataIndex: '',
                                      key: '',
                                      render: (text, record: any) => (
                                          <span>
                                              {formatCurrency(
                                                  (isNaN(record.price)
                                                      ? 0
                                                      : record.price) *
                                                      (isNaN(record.qty)
                                                          ? 0
                                                          : record.qty),
                                                  data?.currencyCode
                                              )}
                                          </span>
                                      )
                                  }
                              ]
                    }
                    dataSource={quoteFields}
                    pagination={false}
                />
            </div>

            <Stack direction='row' justifyContent='flex-end'>
                {docTitle === 'Draft Gross Mass Declaration' ? (
                    <div className='preview-total'>
                        Total Weight :{' '}
                        <span className=''>
                            {formatNumber(data?.grossWeight || 0)} Kg
                        </span>
                    </div>
                ) : (
                    <div className='preview-total'>
                        Total amount :{' '}
                        <span className=''>
                            {formatCurrency(
                                totalAmount || 0,
                                data?.currencyCode
                            )}
                        </span>
                    </div>
                )}
            </Stack>

            <Stack
                direction='row'
                alignItems='start'
                className='bottom-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    {docTitle === 'Proforma Invoice' && (
                        <div className='w-[100%] mb-[15px]'>
                            <p className='titl'>Bank Details</p>
                            <Stack direction='row' gap='20px' width='100%'>
                                <p className='w-[6rem]'>Bank Name</p>
                                <p className='value'>
                                    {data.sellerBankName || '--'}
                                </p>
                            </Stack>

                            <Stack direction='row' gap='20px' width='100%'>
                                <p className='w-[6rem]'>Account No.</p>
                                <p className='value'>
                                    {data.sellerAccountNumber || '--'}
                                </p>
                            </Stack>

                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                // justifyContent="space-between"
                            >
                                <p className='w-[6rem]'>Swift Code</p>
                                <p className='value'>
                                    {data.sellerSwiftCode || '--'}
                                </p>
                            </Stack>
                        </div>
                    )}

                    <p className='titl'>Additional Information</p>
                    <p>{data?.additionalInformation || '--'}</p>
                </div>

                <div className='view-two'>
                    <p className='titl'>Place of Issue</p>
                    <p className=''>{data?.placeOfIssue || '--'}</p>
                    <p className='titl'>Signatory Company</p>
                    <p>{data?.signatoryCompany || '--'}</p>
                    {data?.stamp && (
                        <Stack direction='row' justifyContent='flex-end'>
                            <div className='totalView w-[250px]'>
                                <p className='titl'>Stamp</p>
                                <div className='h-50px] w-[80px]'>
                                    <DocumentViewer
                                        fileUrl={`${REACT_APP_BASE_URL}/${data?.stamp.split(
                                            '/v1/'
                                        )[1]}`}
                                    />
                                </div>
                            </div>
                        </Stack>
                    )}
                </div>

                <div className='view-three'>
                    <p className='titl'>Date of Issue</p>
                    <p className=''>
                        {moment(data.dateOfIssue).format('ll') || '--'}
                    </p>
                    <p className='titl'>Name of Authorized Signatory</p>
                    <p>{data?.nameOfAuthorisedSignatory || '--'}</p>
                    <p className='titl'>Signature</p>
                    <img src={`${signatureUrlBase64}`} alt='' />
                </div>
            </Stack>
        </PreviewContent>
    );
};

export default QuotationPreview;
