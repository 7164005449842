import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';

interface CustomTabProp {
    header: string;
    content: string | JSX.Element;
}

interface CustomTabProps {
    items: Array<CustomTabProp>;
}

export const CustomTab = ({ items }: CustomTabProps) => {
    const [selectedTab, setSelectedTab] = useState(0);
    const contents = items.map(({ content }) => content);

    if (!items.length) {
        return <></>;
    }

    return (
        <Box sx={{ width: '598px' }}>
            <Box sx={{ pb: '26px' }}>
                <Tabs
                    value={selectedTab}
                    onChange={(_: React.SyntheticEvent, tabIndex: number) =>
                        setSelectedTab(tabIndex)
                    }
                    aria-label=''
                >
                    {items.map(({ header }, index) => (
                        <Tab
                            key={index}
                            label={header}
                            sx={{
                                pl: 0,
                                mr: 4,
                                textTransform: 'none',
                                fontSize: '18px',
                                ...(selectedTab === index
                                    ? { fontWeight: 700 }
                                    : { color: '#425466' })
                            }}
                        />
                    ))}
                </Tabs>
            </Box>
            <Box>{contents[selectedTab]}</Box>
        </Box>
    );
};
