export const NotificationsBellIcon = () => (
    <svg
        width='24'
        height='25'
        viewBox='0 0 24 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M12.02 3.41016C8.70997 3.41016 6.01997 6.10016 6.01997 9.41016V12.3002C6.01997 12.9102 5.75997 13.8402 5.44997 14.3602L4.29997 16.2702C3.58997 17.4502 4.07997 18.7602 5.37997 19.2002C9.68997 20.6402 14.34 20.6402 18.65 19.2002C19.86 18.8002 20.39 17.3702 19.73 16.2702L18.58 14.3602C18.28 13.8402 18.02 12.9102 18.02 12.3002V9.41016C18.02 6.11016 15.32 3.41016 12.02 3.41016Z'
            stroke='#292D32'
            strokeWidth='1.5'
            strokeMiterlimit='10'
            strokeLinecap='round'
        />
        <path
            d='M13.87 3.70141C13.56 3.61141 13.24 3.54141 12.91 3.50141C11.95 3.38141 11.03 3.45141 10.17 3.70141C10.46 2.96141 11.18 2.44141 12.02 2.44141C12.86 2.44141 13.58 2.96141 13.87 3.70141Z'
            stroke='#292D32'
            strokeWidth='1.5'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M15.02 19.5586C15.02 21.2086 13.67 22.5586 12.02 22.5586C11.2 22.5586 10.44 22.2186 9.90002 21.6786C9.36002 21.1386 9.02002 20.3786 9.02002 19.5586'
            stroke='#292D32'
            strokeWidth='1.5'
            strokeMiterlimit='10'
        />
    </svg>
);
