import React from 'react'

const EmptyShipment = () => {
  return (
    <div className="w-full flex flex-col h-[400px] text-appcolorprimary gap-5 rounded-[3px] bg-appcolorwhite items-center justify-center text-xl font-semibold">
      <img src="/img/vectors/nodata.svg" alt="location" />
      No Tracking Details
    </div>
  )
}

export default EmptyShipment
