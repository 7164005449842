import { ChangeEvent, FocusEvent, Fragment, ReactNode } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useMediaQuery, useTheme } from '@mui/material';
import { AutocompleteRenderInputParams } from '@mui/material';

interface CustomTextFieldprops {
    id?: string;
    value?: string | number;
    type?: string;
    variant?: 'outlined' | 'standard' | 'filled';
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
    label?: string;
    name?: string;
    placeholder?: string;
    autoComplete?: 'on' | 'off';
    error?: boolean;
    helperText?: string;
    required?: boolean;
    isLoading?: boolean;
    params?: AutocompleteRenderInputParams | TextFieldProps;
    height?: number;
    multiline?: boolean;
    rows?: number;
    focused?: boolean;
    disabled?: boolean;
    InputProps?: any;
    numericInput?: boolean;
    children?: ReactNode;
    select?: boolean;
}

const CustomTextField = ({
    id,
    value,
    type,
    label,
    variant = 'outlined',
    name,
    placeholder,
    autoComplete,
    error,
    onChange,
    onBlur,
    onFocus,
    helperText,
    required,
    isLoading,
    params,
    height = 48,
    multiline,
    rows,
    focused,
    disabled,
    InputProps,
    numericInput,
    children,
    select
}: CustomTextFieldprops) => {
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('md'));

    return (
        <Box
            sx={{
                display: 'block',
                width: '100%',
                '.MuiOutlinedInput-root': {
                    height: `${height}px`,
                    width: '100%',
                    background: 'white',
                    padding: multiline ? '14px 0px' : '0 20px 0 0',
                    border: `1px solid #D0D5DD`,
                    borderRadius: '8px',
                    boxShadow: {
                        xs: '0px 1px 2px 0px #1018280D'
                    }
                },
                '& .MuiOutlinedInput-root': {
                    border: 'none'
                },

                '.MuiInputBase-input': {
                    padding: '0 14px !important',
                    height: '100% !important'
                }
            }}
        >
            <div>
                {isMobile && (
                    <label className='text-sm font-medium mb-[10px] inline-block text-steelBlue'>
                        {label}
                    </label>
                )}
            </div>
            <TextField
                {...params}
                id={id}
                label={isMobile ? '' : label}
                placeholder={placeholder}
                autoComplete={autoComplete}
                required={required}
                name={name}
                value={value}
                type={type}
                variant={variant}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                error={error}
                helperText={helperText}
                fullWidth
                multiline={multiline}
                rows={rows}
                focused={focused}
                disabled={disabled}
                InputProps={{
                    endAdornment: InputProps?.endAdornment
                        ? InputProps?.endAdornment
                        : isLoading && (
                              <Fragment>
                                  <CircularProgress size={20} />
                                  {params?.InputProps?.endAdornment}
                              </Fragment>
                          ),
                    inputMode: numericInput ? 'numeric' : 'none',
                    pattern: numericInput ? '[0-9]*' : '',
                    ...InputProps,
                    ...params?.InputProps
                }}
            >
                {' '}
                {children}
            </TextField>
        </Box>
    );
};

export default CustomTextField;
