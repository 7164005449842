import Box from '@mui/material/Box';
import { Dropdown } from 'antd';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { mutateConfig } from '../../../api/queryBase';
import {
    BoxSearchIcon,
    ChevronRightIcon,
    MoreVerticalIcon,
    EmptyWalletIcon
} from '../../../components/icons';
import { UserPermissions } from '../../../interfaces/User';
import { getErrorMessage } from '../../../utils/helper';
import DisableUser from './modals/DisableUser';
import UpdateRole from './modals/UpdateRole';

interface AdminUserProfileListProps {
    selected: UserPermissions | null;
    setSelected: (obj: UserPermissions) => void;
    usersPermissions: UserPermissions[];
    isAdmin: boolean;
    refetchUsers: () => void;
}

const AdminUserProfileList = ({
    selected,
    setSelected,
    usersPermissions,
    isAdmin,
    refetchUsers
}: AdminUserProfileListProps) => {
    const { mutate: mutatePermission } = mutateConfig({ method: 'post' })({
        url: `users/${selected?.id}/disable`,
        cacheKeys: 'users-permission'
    });

    const { mutate: mutateRole } = mutateConfig({ method: 'patch' })({
        url: `users/${selected?.id}/role`,
        cacheKeys: 'users-permission'
    });

    const [showModal, setShowModal] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const handleUpdateRole = () => {
        setSubmitting(true);
        mutateRole(
            { role: 'ADMIN' },
            {
                onSettled: (_, error: AxiosError | undefined | any) => {
                    setSubmitting(false);
                    if (error) {
                        toast.error(
                            getErrorMessage(error) || 'An error occurred.'
                        );
                    } else {
                        setShowModal('');
                        toast.success('User role updated successfully.');
                        refetchUsers();
                    }
                }
            }
        );
    };

    const handleDisableUser = () => {
        setSubmitting(true);
        mutatePermission(null, {
            onSettled: (_, error: AxiosError | undefined | any) => {
                setSubmitting(false);
                if (error) {
                    toast.error(getErrorMessage(error) || 'An error occurred.');
                } else {
                    setShowModal('');
                    toast.success('User disabled successfully.');
                    refetchUsers();
                }
            }
        });
    };

    const getMenuItems = (role: string) =>
        [
            role?.toLowerCase() !== 'admin' && (
                <Box
                    onClick={() => setShowModal('make-admin')}
                    className='flex items-center gap-[16px] h-[36px] text-steelBlue'
                >
                    <EmptyWalletIcon /> Make Admin
                </Box>
            ),
            <Box
                onClick={() => setShowModal('disable')}
                className='flex items-center gap-[16px] h-[36px] text-steelBlue'
            >
                <BoxSearchIcon /> Disable User
            </Box>
        ].map((comp, key) => ({
            key,
            label: comp
        }));

    return (
        <Box className='w-[211px]'>
            <Box className='rounded-t-sm bg-aliceBlue h-[37px] flex items-center px-[16px] font-bold text-sm text-ebony'>
                Users
            </Box>

            {usersPermissions?.map((item: UserPermissions) => (
                <Box
                    key={item.id}
                    className={`flex items-center py-[6px] bg-white border-0 border-b border-appcolorfaintnine cursor-pointer`}
                    onClick={() => setSelected(item)}
                >
                    <Box
                        className={`flex items-center mb-[1px] ml-[21px] mr-[8px] p-2 rounded-[4px]  w-full ${
                            item?.id === selected?.id
                                ? 'bg-athensGray'
                                : 'bg-white'
                        }`}
                    >
                        <Box className='font-bold text-sm text-steelBlue'>{`${item.firstName} ${item.lastName}`}</Box>
                        <Box className='ml-auto flex gap-[6px] items-center'>
                            {isAdmin && (
                                <Dropdown
                                    menu={{
                                        items: getMenuItems(item.role)
                                    }}
                                    trigger={['click']}
                                >
                                    <Box>
                                        <MoreVerticalIcon />
                                    </Box>
                                </Dropdown>
                            )}
                            {item?.id === selected?.id && <ChevronRightIcon />}
                        </Box>
                    </Box>
                </Box>
            ))}
            {showModal === 'disable' && selected && (
                <DisableUser
                    userEmail={selected?.email}
                    open={showModal === 'disable'}
                    onClose={() => setShowModal('')}
                    handleDisable={handleDisableUser}
                    loading={submitting}
                />
            )}

            {showModal === 'make-admin' && selected && (
                <UpdateRole
                    userName={`${selected.firstName} ${selected.lastName}`}
                    open={showModal === 'make-admin'}
                    onClose={() => setShowModal('')}
                    handleDisable={handleUpdateRole}
                    loading={submitting}
                />
            )}
        </Box>
    );
};

export default AdminUserProfileList;
