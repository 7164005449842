import { Grid, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import NewCustomSelect from '../../../../newcustomselect/newcustomselect';
import { PartnerDetailsInterface } from '../../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/CommercialTypes';
import Customdatepicker from '../../../../customdatepicker/customdatepicker';

const ExporterDetails = ({
    errors,
    payload,
    updateField,
    corporatePartners,
    setShowAddPartner,
    loadingCorporatepartners
}: PartnerDetailsInterface) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: 0
                        }}
                    >
                        <Grid item xs={12} md={12} lg={12}>
                            <div className='mt-4'>
                                <NewCustomSelect
                                    hasError={errors?.exporterId.length > 0}
                                    helperText={
                                        errors?.exporterId.length > 0
                                            ? errors?.exporterId
                                            : undefined
                                    }
                                    selectValue={String(payload.exporterId)}
                                    required
                                    selectOptions={
                                        corporatePartners as unknown as Record<
                                            string,
                                            unknown
                                        >[]
                                    }
                                    name={'exporterId'}
                                    updateField={(_, value) => {
                                        updateField('exporterId', value);
                                        updateField(
                                            'exporterAddress',
                                            corporatePartners.filter(
                                                (item: {
                                                    id: string | number;
                                                }) => item?.id === value
                                            )[0]?.address
                                        );
                                        updateField(
                                            'exporterName',
                                            corporatePartners.filter(
                                                (item: {
                                                    id: string | number;
                                                }) => item?.id === value
                                            )[0]?.name
                                        );
                                        updateField(
                                            'exporterBankName',
                                            corporatePartners.filter(
                                                (item: {
                                                    id: string | number;
                                                }) => item?.id === value
                                            )[0]?.bankName
                                        );
                                        updateField(
                                            'exporterAccountNumber',
                                            corporatePartners.filter(
                                                (item: {
                                                    id: string | number;
                                                }) => item?.id === value
                                            )[0]?.bankAccountNumber
                                        );
                                        updateField(
                                            'exporterCountry',
                                            corporatePartners.filter(
                                                (item: {
                                                    id: string | number;
                                                }) => item?.id === value
                                            )[0]?.bankCountry
                                        );
                                        updateField(
                                            'exporterSwiftCode',
                                            corporatePartners.filter(
                                                (item: {
                                                    id: string | number;
                                                }) => item?.id === value
                                            )[0]?.swiftCode
                                        );
                                        updateField(
                                            'bankDetails',
                                            `Bank Name: ${corporatePartners.filter(
                                                (item: {
                                                    id: string | number;
                                                }) => item.id === value
                                            )[0]
                                                ?.bankName}\r\nAccount Number: ${corporatePartners.filter(
                                                (item: {
                                                    id: string | number;
                                                }) => item.id === value
                                            )[0]
                                                ?.bankAccountNumber}\r\nSwift Code: ${corporatePartners.filter(
                                                (item: {
                                                    id: string | number;
                                                }) => item.id === value
                                            )[0]?.swiftCode}`
                                        );
                                    }}
                                    label={'Exporter'}
                                    selectOptionLabel='name'
                                    loading={loadingCorporatepartners}
                                    dropdownRender={() => {
                                        return (
                                            <div className='px-4 border-t border-appcolorgreyfaint'>
                                                <p
                                                    className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                    onClick={() => {
                                                        setShowAddPartner(true);
                                                    }}
                                                >
                                                    + Add a New Partner
                                                </p>
                                            </div>
                                        );
                                    }}
                                />
                            </div>

                            <TextField
                                InputLabelProps={{
                                    shrink: !!payload.exporterAddress
                                }}
                                error={errors?.exporterAddress.length > 0}
                                helperText={
                                    errors?.exporterAddress.length > 0
                                        ? errors?.exporterAddress
                                        : undefined
                                }
                                value={payload?.exporterAddress}
                                margin='normal'
                                required
                                fullWidth
                                id='quotation-exporterAddress'
                                label='Exporter Address'
                                name='exporterAddress'
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'exporterAddress',
                                        e.target.value
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <Grid item xs={12} md={12} lg={3}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                    <TextField
                        error={errors?.invoiceNumber.length > 0}
                        helperText={
                            errors?.invoiceNumber.length > 0
                                ? errors?.invoiceNumber
                                : undefined
                        }
                        margin='normal'
                        required
                        fullWidth
                        id='proformainvoice-invoiceNumber'
                        label='Invoice Number'
                        name='invoiceNumber'
                        value={payload?.invoiceNumber || ''}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            updateField('invoiceNumber', e.target.value);
                        }}
                    />
                </div>
            </Grid>

            <Grid item xs={12} md={12} lg={3}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[1.1rem] pb-2 h-full'>
                    <Customdatepicker
                        value={ payload?.invoiceDate || null }
                        id='quotation-invoiceDate'
                        label='Invoice Date'
                        name='invoiceDate'
                        updateField={updateField}
                        error={errors?.invoiceDate.length > 0}
                        helperText={
                            errors?.invoiceDate.length > 0
                                ? errors?.invoiceDate
                                : undefined
                        }
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default ExporterDetails;
