export const MoreCircleIcon = () => (
    <svg
        width='24'
        height='25'
        viewBox='0 0 24 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M12 22.085C17.5 22.085 22 17.585 22 12.085C22 6.58496 17.5 2.08496 12 2.08496C6.5 2.08496 2 6.58496 2 12.085C2 17.585 6.5 22.085 12 22.085Z'
            stroke='#DF835A'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M15.9965 12.085H16.0054'
            stroke='#DF835A'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M11.9955 12.085H12.0045'
            stroke='#DF835A'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M7.99451 12.085H8.00349'
            stroke='#DF835A'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
