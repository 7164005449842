import { useNavigate } from 'react-router-dom';
import { SuitUITabContainer } from '../../../components/SuiteUITabContainer';
import { Dispatch } from 'react';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';
import MakeInvoicePaymentIcon from '../../../components/icons/MakeIncoicePaymentIcon';
import { SuitUIButton } from '../../../components/SuitUIButton';
import { PlusIcon } from '../../../components/icons/PlusIcon';

export const LogisticsInvoiceActions = ({
    selected,
    setRaiseEmpty
}: {
    selected: (number | string)[];
    setRaiseEmpty: Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
    const navigate = useNavigate();
    const { permissions } = useUserProfile();

    const tabs = [
        {
            label: 'Create Invoice',
            onHandleClick: () => {
                navigate('/payments/logistics/create');
            },
            icon: <PlusIcon color='#0088B2' />,
            view:
                permissions &&
                hasPermission({
                    userPermissions: permissions,
                    module: 'payments',
                    permissionList: ['write', '*']
                })
        },
        {
            label: 'Make Payment',
            onHandleClick: () => {
                if (selected.length) {
                    navigate(`/payments/logistics/pay?invoices=${selected}`);
                    return;
                }
                setRaiseEmpty(true);
            },
            icon: <MakeInvoicePaymentIcon />,
            view:
                permissions &&
                hasPermission({
                    userPermissions: permissions,
                    module: 'payments',
                    permissionList: ['write', '*']
                })
        }
    ];
    return (
        <>
            <div className='hidden lg:block'>
                <SuitUITabContainer {...{ tabs }} />
            </div>
            <div className='lg:hidden py-4 flex gap-2'>
                {tabs.map(
                    ({ onHandleClick, icon, label, view }, key) =>
                        view && (
                            <SuitUIButton
                                key={key}
                                text={label}
                                onClick={onHandleClick}
                                invert
                                fullWidth
                                iconLeft={icon}
                                style='border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                            />
                        )
                )}
            </div>
        </>
    );
};
