export const BankIcon = () => (
    <svg
        width='16'
        height='16'
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M8.24658 1.43331L14.2466 3.83329C14.4799 3.92663 14.6666 4.20662 14.6666 4.45329V6.66663C14.6666 7.03329 14.3666 7.33329 13.9999 7.33329H1.99992C1.63325 7.33329 1.33325 7.03329 1.33325 6.66663V4.45329C1.33325 4.20662 1.51992 3.92663 1.75326 3.83329L7.75326 1.43331C7.88659 1.37998 8.11325 1.37998 8.24658 1.43331Z'
            stroke='#667085'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M14.6666 14.6667H1.33325V12.6667C1.33325 12.3 1.63325 12 1.99992 12H13.9999C14.3666 12 14.6666 12.3 14.6666 12.6667V14.6667Z'
            stroke='#667085'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M2.66675 11.9999V7.33325'
            stroke='#667085'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M5.33325 11.9999V7.33325'
            stroke='#667085'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M8 11.9999V7.33325'
            stroke='#667085'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M10.6667 11.9999V7.33325'
            stroke='#667085'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M13.3333 11.9999V7.33325'
            stroke='#667085'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M0.666748 14.6667H15.3334'
            stroke='#667085'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M8 5.66675C8.55228 5.66675 9 5.21903 9 4.66675C9 4.11446 8.55228 3.66675 8 3.66675C7.44772 3.66675 7 4.11446 7 4.66675C7 5.21903 7.44772 5.66675 8 5.66675Z'
            stroke='#667085'
            strokeMiterlimit='10'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
