import { Dropdown, MenuProps, Table } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { DedicatedAccount } from './interfaces/DedicatedAccounts';
import { getDedicatedAccountStatus } from '../../../../utils/helper';
import CustomModal from '../../../../components/CustomModal';
import ApproveAccountDialog from './ApproveAccountDialog';
import { Corporate } from '../../corporates/interface/DocDrawerProps';
import { useNavigate } from 'react-router-dom';

const DedicatedAccountsTable = ({
    accountsList,
    isAccountsLoading
}: {
    accountsList: DedicatedAccount[];
    isAccountsLoading: boolean;
}) => {
    const [showApproveAccountModal, setShowApproveAccountModal] = useState(
        false
    );

    const [currentAccount, setCurrentAccount] = useState<string>('');
    const navigate = useNavigate();

    const getMenu = (record: {
        status: number;
        corporate: Corporate;
    }): MenuProps['items'] => [
        {
            key: '1',
            label: (
                <div
                    className='flex items-center'
                    onClick={() =>
                        navigate(
                            `/payments/dedicated-accounts/${record.corporate.id}`
                        )
                    }
                >
                    <span className='ml-2'>View Account request</span>
                </div>
            )
        },
        record.status === 2
            ? {
                  key: '2',
                  label: (
                      <div
                          className='flex items-center'
                          onClick={() => {
                              setShowApproveAccountModal(true);
                              setCurrentAccount(record.corporate.id);
                          }}
                      >
                          <span className='ml-2'>Approve Account Request</span>
                      </div>
                  )
              }
            : null
    ];

    return (
        <>
            <Table
                loading={isAccountsLoading}
                pagination={{ pageSize: 20 }}
                dataSource={accountsList}
                columns={[
                    {
                        title: 'Date',
                        dataIndex: 'createdDate',
                        key: 'createdDate',
                        render: (_, { createdDate }) => (
                            <span>
                                {dayjs(Number(createdDate)).format('ll')}
                            </span>
                        )
                    },

                    {
                        title: 'Business Name',
                        dataIndex: 'businessName',
                        key: 'businessName',
                        render: (_, { corporate }) => (
                            <span>{corporate.businessName}</span>
                        )
                    },

                    {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        render: (_, { status }) => (
                            <span
                                className={`text-[12px] text-semibold px-[8px] py-[2px] rounded-[16px]
                        ${
                            status === 1
                                ? 'text-[#027A48] bg-[#ECFDF3]'
                                : status === 2
                                ? 'text-[#7A2E0E] bg-[#FFFAEB]'
                                : status === 0
                                ? 'text-[#344054] bg-[#F9FAFB]'
                                : ''
                        }
                        `}
                            >
                                {getDedicatedAccountStatus(status)}
                            </span>
                        )
                    },
                    {
                        title: '',
                        render: (_, record) => (
                            <Dropdown
                                menu={{
                                    items: getMenu(record)
                                }}
                                trigger={['click']}
                            >
                                <img
                                    src='/img/vectors/more.svg'
                                    alt='more'
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                />
                            </Dropdown>
                        )
                    }
                ]}
            />
            <CustomModal
                maxWidth='md'
                open={showApproveAccountModal}
                onClose={() => setShowApproveAccountModal(false)}
                title=''
            >
                <ApproveAccountDialog
                    {...{ currentAccount, setShowApproveAccountModal }}
                />
            </CustomModal>
        </>
    );
};

export default DedicatedAccountsTable;
