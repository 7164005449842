import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import Outletouterpage from '../../../components/dashboard/outletouterpage/outletouterpage';
import PointsSearch from './components/points-search';
import SearchResults from './components/search-results';
import VesselSearch from './components/vessel-search';
import { Backdrop, Box, Divider, Stack } from '@mui/material';
import { TabContainerNew } from '../../../styles/TradeDocStyles';
import { TabContext, TabPanel } from '@mui/lab';
import { Dispatch, SetStateAction, useState } from 'react';
import {
    getOceanSchedules,
    getOceanSchedulesPayload,
    getVesselSchedules,
    getVesselSchedulesPayload
} from '../../../api/booking/ocean-schedules';
import { AxiosResponse } from 'axios';
import React from 'react';
import {
    FullResponseData,
    PointResponse,
    PointRoutes,
    Port,
    Schedule,
    SearchResult,
    TransShipment,
    VesselResponse
} from './interfaces/SearchResults';
import EnablingFeature from '../../../components/EnablingFeature';

export interface SearchProps {
    role: string;
    fetcher: (
        how: string,
        data: getOceanSchedulesPayload | getVesselSchedulesPayload
    ) => void | Promise<AxiosResponse<unknown>>;
    onSearchComplete: (
        scheme: string,
        e: AxiosResponse<FullResponseData | any>,
        carrier?: string,
        carrierId?: string
    ) => void;
    indicateLoading: Dispatch<SetStateAction<boolean>>;
    loadingData: boolean;
}

export interface OceanFetcherResponse {
    hasApiResponse?: boolean;
    stillLoading?: boolean;
    source?: 'by-points' | 'by-vessel';
    results: SearchResult[];
    errorMessage?: string;
}

const fetchOceanSchedules = (
    how: string,
    data: getOceanSchedulesPayload | getVesselSchedulesPayload
) => {
    let results;
    const byPoint = (data: getOceanSchedulesPayload) => {
        return getOceanSchedules(data);
    };

    const byVessel = (data: getVesselSchedulesPayload) => {
        return getVesselSchedules(data);
    };

    switch (how) {
        case 'by-point':
            results = byPoint(data as getOceanSchedulesPayload);
            break;
        case 'by-vessel':
            results = byVessel(data as getVesselSchedulesPayload);
            break;
        default:
            console.warn('Unknown ocean-schedule search protocol');
    }

    return results;
};

const OceanSchedules = () => {
    const [theSearchResults, setTheSearchResults] = useState<SearchResult[]>(
        []
    );
    const [theApiSuccess, setTheApiSuccess] = useState<boolean | undefined>(
        undefined
    );

    const [theApiLoading, setTheApiLoading] = useState<boolean>(false);
    const [, setTheLocationFrom] = useState<string>('');
    const [, setTheLocationTo] = useState<string>('');
    const [theDisplayFrom, setTheDisplayFrom] = useState<string>('');
    const [theDisplayTo, setTheDisplayTo] = useState<string>('');
    const [theSelectedVessel, setTheSelectedVessel] = useState<string>('');
    const [theResponseError, setTheResponseError] = useState<string>('');

    const transformVesselResults = (data: VesselResponse): Port[] => {
        let results: Port[];
        if (data.succeeded && data?.schedules) {
            const { schedules } = data;
            const scheduleMapping = schedules.map(
                (schedule: Schedule, idx: number) => {
                    const portMapping: Port[] = [];
                    schedule.ports.forEach((port, port_idx: number) => {
                        portMapping.push({
                            key: `${idx}${port_idx}`,
                            carrier: schedule.carrier_name,
                            vessel: schedule.voyages.join(' / '),
                            service:
                                schedule.service_name ?? schedule.service_code,
                            departs: port.estimated_departure,
                            arrives: port.estimated_arrival
                        });
                    });
                    return portMapping;
                }
            );
            results = scheduleMapping.flat();
        } else {
            results = [];
        }
        return results;
    };

    const transformPointsResults = (
        data: PointResponse,
        carrier: string = '',
        carrierId: string = ''
    ): SearchResult[] => {
        let transformedData: SearchResult[] = [];

        if (data.succeeded && data?.routes) {
            data?.routes.forEach((item: PointRoutes) => {
                const { transShipments, cutOff, transitTime } = item;
                transShipments.forEach((transShipment: TransShipment) => {
                    const { vesselName, voyage, service, departure, arrival } =
                        transShipment;
                    const { date: departureDate } = departure;
                    const { date: arrivalDate } = arrival;

                    transformedData.push({
                        carrier: carrier,
                        carrierId: carrierId,
                        vessel: vesselName,
                        voyage: voyage,
                        service: service,
                        departs: departureDate,
                        arrives: arrivalDate,
                        transit: transitTime,
                        cutoff: cutOff
                    });
                });
            });
        } else {
            transformedData = [];
            (data.errorMessages && data.errorMessages?.length > 0) ??
                setTheResponseError(data?.errorMessages[0]);
        }
        return transformedData;
    };

    const handleSearchComplete = (
        scheme: string,
        results: AxiosResponse<FullResponseData>,
        carrier: string = '',
        carrierId: string = ''
    ) => {
        let incomingSearchResults: SearchResult[] | any[];

        switch (scheme) {
            case 'by-vessel':
                incomingSearchResults = transformVesselResults(
                    results.data.data
                );
                setTheSearchResults(incomingSearchResults);
                setTheApiSuccess(true);
                setTheApiLoading(false);
                break;
            case 'by-point':
                incomingSearchResults = transformPointsResults(
                    results.data.data,
                    carrier,
                    carrierId
                );
                setTheSearchResults(incomingSearchResults);
                setTheApiSuccess(true);
                setTheApiLoading(false);
                break;
            default:
                setTheSearchResults([]);
                setTheApiSuccess(false);
                setTheApiLoading(false);
        }
    };

    const [theTabIndex, setTheTabIndex] = useState('1');

    const changeTabIndex = (
        e: React.MouseEvent<HTMLDivElement>,
        newTabIndex: string
    ) => {
        const target = e.target as HTMLDivElement;
        document
            .querySelectorAll<HTMLDivElement>(
                '[role="osc--search-selector"] div'
            )
            .forEach((t) => t.classList.remove('actif'));

        target.closest('div')?.classList.add('actif');
        setTheTabIndex(newTabIndex);
        setTheSearchResults([]);
    };

    const theBreadcrumbs = [
        {
            title: 'Home',
            link: '/dashboard'
        },
        {
            title: 'Ocean Schedules',
            link: '/ocean-schedules'
        }
    ];

    return (
        <EnablingFeature flag="OceanSchedules">
        <Outletouterpage breadcrumbs={theBreadcrumbs}>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={theApiLoading}
            >
                <img
                    src='/img/vectors/loadingcircles.svg'
                    alt='loading'
                    className='animate-spin'
                />
            </Backdrop>
            <div className='flex justify-between mb-6'>
                <h1
                    role='the-heading'
                    className='text-[32px] leading-[23px] text-appcolorblacktwo mt-[0.6rem]'
                >
                    Welcome to Ocean Schedules!
                </h1>
            </div>
            <TabContext value={theTabIndex}>
                <Box
                    sx={{
                        width: 'fit-content',
                        backgroundColor: 'white',
                        borderRadius: 2
                    }}
                >
                    <TabContainerNew>
                        <Stack
                            direction='row'
                            divider={
                                <Divider orientation='vertical' flexItem />
                            }
                            spacing={1}
                            role='osc--search-selector'
                        >
                            <div
                                className='tab-buttons actif'
                                onClick={(e) => changeTabIndex(e, '1')}
                            >
                                <LocationOnOutlinedIcon
                                    sx={{ mr: 0.5 }}
                                    fontSize='small'
                                />
                                By Points
                            </div>
                        </Stack>
                    </TabContainerNew>
                </Box>
                <TabPanel sx={{ px: 0, pt: 1.5 }} value='1'>
                    <PointsSearch
                        fetcher={fetchOceanSchedules}
                        role='oceans-search'
                        onSearchComplete={handleSearchComplete}
                        indicateLoading={setTheApiLoading}
                        setFrom={setTheLocationFrom}
                        setTo={setTheLocationTo}
                        setFromDisplay={setTheDisplayFrom}
                        setToDisplay={setTheDisplayTo}
                        loadingData={theApiLoading}
                        {...{ setTheResponseError }}
                    />
                </TabPanel>
                <TabPanel sx={{ px: 0, pt: 1.5 }} value='2'>
                    <VesselSearch
                        fetcher={fetchOceanSchedules}
                        role='oceans-search'
                        onSearchComplete={handleSearchComplete}
                        indicateLoading={setTheApiLoading}
                        loadingData={theApiLoading}
                        setTheSelectedVessel={setTheSelectedVessel}
                    />
                </TabPanel>
            </TabContext>
            <SearchResults
                locationFrom={theDisplayFrom}
                locationTo={theDisplayTo}
                results={theSearchResults}
                hasApiResponse={theApiSuccess}
                stillLoading={theApiLoading}
                {...{ theTabIndex, theSelectedVessel, theResponseError }}
            />
        </Outletouterpage>
        </EnablingFeature>
    );
};

export default OceanSchedules;
