import { useState } from 'react';
import CustomTextField from '../CustomTextInput';

export interface PasswordInputProps {
    id: string;
    label?: string;
    name: string;
    updateField: (name: string, value: any) => void;
    error?: boolean;
    helperText?: string;
}

const Passwordinput = ({
    id,
    label,
    name,
    updateField,
    error,
    helperText
}: PasswordInputProps) => {
    const [showPassword] = useState(false);

    return (
        <div>
            <CustomTextField
                error={error}
                helperText={helperText}
                onChange={(e) => {
                    updateField(name, e.target.value);
                }}
                id={id}
                type={showPassword ? 'text' : 'password'}
                label={label}
                name={name}
                variant='outlined'
            />
        </div>
    );
};

Passwordinput.defaultProps = {
    label: 'Password'
};

export default Passwordinput;
