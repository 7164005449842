import { Button, Stack } from '@mui/material'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import Custombutton from '../../custombutton/custombutton'

interface IProps {
  type?: string
  children?: ReactNode
  currentData?: any
  approveFunction?: any
  loading?: boolean
  cancelFunction?: any
}
const ApprovalModalContent: FC<IProps> = ({
  type,
  children,
  currentData,
  cancelFunction,
  approveFunction,
  loading,
}) => {
  return (
    <Container>
      <img src="/img/vectors/acceptIcon.svg" alt="accept-icon" />

      <h2>Accept {type}</h2>
      <p>Are you sure you want to accept this {}?</p>
      {children}
      <Stack direction="row" className="buttons">
        <Button className="cancel" onClick={cancelFunction}>
          Cancel
        </Button>
        <Custombutton
          isLoadingButton={loading}
          buttonText="Accept"
          onClickAction={approveFunction}
        />
      </Stack>
    </Container>
  )
}

export default ApprovalModalContent

const Container = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  img {
    width: 70px;
    margin: auto;
    margin-bottom: 20px;
  }
  h2 {
    color: #101828;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
  p {
    color: #667085;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    margin-top: 12px;
  }
  .buttons {
    width: 100%;
    justify-content: center;
    margin-top: 32px;
    gap: 20px;
    .cancel {
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      border: 1px solid var(--gray-300, #d0d5dd) !important;
      background: var(--white, #fff) !important;
      color: black !important;
    }
    button {
      width: 170px;
      border-radius: 5px;

      :last-of-type {
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border: 1px solid var(--green-1, #219653);
        background: var(--green-1, #219653);
        color: white;
      }
    }
  }
`
