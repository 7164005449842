import { Dispatch, SetStateAction, useState } from 'react';
import { ServiceableInvoice } from './interfaces/Invoice';
import SingleLogisticsPayInvoiceForm from './SingleLogisticsPayInvoiceForm';

export const LogisticsInvoicePayDetailsForm = ({
    invoices,
    section,
    setSection,
    invoiceIds,
    setInvoices
}: {
    invoices: ServiceableInvoice[];
    section: number;
    setSection: Dispatch<SetStateAction<number>>;
    invoiceIds: string[];
    setInvoices: Dispatch<SetStateAction<ServiceableInvoice[]>>;
}) => {
    const [invoicePosition, setInvoicePosition] = useState(0);

    return (
        <>
            {invoiceIds.length > 0 && invoicePosition < invoiceIds.length && (
                <SingleLogisticsPayInvoiceForm
                    {...{
                        setInvoicePosition,
                        invoicePosition,
                        setInvoices,
                        section,
                        setSection,
                        invoices
                    }}
                    isLast={invoicePosition === invoiceIds.length - 1}
                    currentId={invoiceIds[invoicePosition]}
                />
            )}
        </>
    );
};
