import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import Custombutton from '../../../components/custombutton/custombutton';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { MutableRefObject, useEffect, useState } from 'react';
import { mutateConfig } from '../../../api/queryBase';
import { useFormik } from 'formik';
import { object, string, number } from 'yup';
import { toast } from 'react-toastify';
import { CustomDatepicker } from '../../../components/CustomDatepicker';
import { CurrencySelect } from '../../../components/CurrencySelect';
import { createPortal } from 'react-dom';
import CustomModal from '../../../components/CustomModal';
import UploadLogisticsInvoice from './UploadLogisticsInvoice';
import { FileSelector } from '../../../components/FileSelector';
import { UploadProgressInformation } from '../../../components/UploadProgressInformation';
import { FileIcon } from '../../../components/icons/FileIcon';
import { getErrorMessage, getFileName } from '../../../utils/helper';
import { REACT_APP_BASE_URL } from '../../../config';
import DocumentViewer from '../../../components/DocumentViewer/DocumentViewer';
import { ServiceableInvoice } from './interfaces/Invoice';
import dayjs from 'dayjs';
import { NumericFormat } from 'react-number-format';
import { InvoiceCarrierAgentsSelect } from '../../../components/InvoiceCarrierAgentsSelect';
import { useQueryClient } from 'react-query';

export const LogisticsInvoiceCreate = () => {
    const [viewUploadModal, setViewUploadModal] = useState(false);
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { backRef } = useOutletContext() as {
        backRef: MutableRefObject<HTMLDivElement>;
    };
    const mutation = mutateConfig();
    const { mutate, isLoading } = mutation({
        url: '/invoice/freight/create',
        cacheKeys: 'invoices'
    });
    const [invoiceForm, setInvoiceForm] = useState<ServiceableInvoice>(
        {} as ServiceableInvoice
    );
    const [file, setFile] = useState<File>();

    const validationSchema = object({
        invoiceNumber: string().required(),
        billOfLadingNumber: string().required(),
        customerName: string().required(),
        customerNumber: string().required(),
        currencyCode: string().required(),
        totalAmount: number().required(),
        beneficiaryId: number().required(),
        dueDate: string().required(),
        issuedDate: string().required(),
        documentUrl: string().required()
    });

    useEffect(() => {
        if (!file) {
            setViewUploadModal(true);
        }
    }, [file]);

    const formik = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnMount: true,
        onSubmit: () => {
            mutate(invoiceForm, {
                onSettled: (_, error) => {
                    if (error) {
                        toast.error(
                            getErrorMessage(error) || 'Invoice request failed'
                        );
                        return;
                    }

                    setInvoiceForm({} as ServiceableInvoice);
                    formik.resetForm();
                    queryClient.invalidateQueries('invoices');
                    toast.info('Invoice request submitted successfully');
                    navigate('/payments/logistics/list');
                }
            });
        }
    });

    const handleDateChange = (
        value: string | number | undefined | null,
        fieldName: string
    ) => {
        if (value) {
            const timeZoneOffset = new Date().getTimezoneOffset();
            const localTimeInMilliseconds =
                new Date(value).getTime() - timeZoneOffset * 60 * 1000;
            const localDate = new Date(localTimeInMilliseconds);

            const formattedDate = dayjs(localDate).format('YYYY-MM-DD');

            setInvoiceForm((prev) => ({
                ...prev,
                [fieldName]: formattedDate
            }));

            formik.setFieldValue(fieldName, formattedDate);
        }
    };

    return (
        <>
            {backRef.current &&
                createPortal(
                    <Box
                        onClick={() => {
                            navigate('/payments/logistics');
                        }}
                        className='text-[12px] mb-[5px] cursor-pointer'
                    >
                        {'<'} Back
                    </Box>,
                    backRef.current
                )}
            <Box className='lg:font-semibold lg:text-[24px] px-4 lg:px-4 pb-6 lg:pt-4 font-medium text-[18px]'>
                Create Invoice
            </Box>

            <Grid container spacing={1} className='lg:px-4 px-4 pb-12'>
                <Grid item lg={6} md={12} sm={12} className='w-full'>
                    <Box className='flex flex-col space-y-5'>
                        {invoiceForm['documentUrl'] && file && (
                            <Box className=' min-h-[70px] p-4 border-[1px] border-appcolorprimary bg-[#F9FAFB] rounded-md'>
                                <Box className='grid grid-cols-12 gap-2'>
                                    <FileIcon />
                                    <Box className='pl-4 col-span-10'>
                                        <Typography className='text-[#101828] text-[14px] font-[500]'>
                                            {getFileName(file.name)}
                                        </Typography>
                                        <Typography className='text-[#667085] text-[14px]'>
                                            {Math.round(file.size / 1000)} KB
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        <TextField
                            sx={{
                                background: 'white'
                            }}
                            margin='normal'
                            label='Invoice No.'
                            onChange={(e) =>
                                setInvoiceForm((prev) => {
                                    const update = {
                                        ...prev,
                                        invoiceNumber: e.target.value
                                    };

                                    formik.setValues(update);

                                    return update;
                                })
                            }
                            value={invoiceForm['invoiceNumber']}
                        />
                        <TextField
                            sx={{
                                background: 'white'
                            }}
                            margin='normal'
                            label='Bill of Lading No.'
                            onChange={(e) =>
                                setInvoiceForm((prev) => {
                                    const update = {
                                        ...prev,
                                        billOfLadingNumber: e.target.value
                                    };

                                    formik.setValues(update);

                                    return update;
                                })
                            }
                            value={invoiceForm['billOfLadingNumber']}
                        />
                        <TextField
                            sx={{
                                background: 'white'
                            }}
                            margin='normal'
                            label='Customer Number'
                            onChange={(e) =>
                                setInvoiceForm((prev) => {
                                    const update = {
                                        ...prev,
                                        customerNumber: e.target.value
                                    };

                                    formik.setValues(update);

                                    return update;
                                })
                            }
                            value={invoiceForm['customerNumber']}
                        />
                        <TextField
                            sx={{
                                background: 'white'
                            }}
                            margin='normal'
                            label='Customer Name'
                            onChange={(e) =>
                                setInvoiceForm((prev) => {
                                    const update = {
                                        ...prev,
                                        customerName: e.target.value
                                    };

                                    formik.setValues(update);

                                    return update;
                                })
                            }
                            value={invoiceForm['customerName']}
                        />
                        <CurrencySelect
                            label='Invoice currency'
                            onHandleChange={(value) => {
                                if (value !== null) {
                                    setInvoiceForm((prev) => {
                                        const update = {
                                            ...prev,
                                            currencyCode: value.currencyCode
                                        };

                                        formik.setValues(update);

                                        return update;
                                    });
                                }
                            }}
                        />
                        <FormControl sx={{ background: 'white' }}>
                            <InputLabel htmlFor='outlined-adornment-amount'>
                                Amount
                            </InputLabel>

                            <NumericFormat
                                id='outlined-adornment-amount'
                                customInput={OutlinedInput}
                                thousandSeparator={true}
                                prefix={
                                    invoiceForm['currencyCode'] + '  ' ??
                                    '- - -' + '  '
                                }
                                disabled={!invoiceForm['currencyCode']}
                                style={{ border: 'none' }}
                                label='Amount'
                                onValueChange={(values) => {
                                    const { value } = values;
                                    setInvoiceForm((prev) => {
                                        const update = {
                                            ...prev,
                                            totalAmount: Number(value)
                                        };
                                        formik.setValues(update);
                                        return update;
                                    });
                                }}
                                value={invoiceForm['totalAmount']}
                            />
                        </FormControl>
                        <InvoiceCarrierAgentsSelect
                            onHandleChange={(value) => {
                                if (value) {
                                    setInvoiceForm((prev) => {
                                        const update = {
                                            ...prev,
                                            beneficiaryId:
                                                value?.beneficiary?.id
                                        };

                                        formik.setValues(update);

                                        return update;
                                    });
                                }
                            }}
                            isError={false}
                            label='Carrier'
                        />
                        <CustomDatepicker
                            value={invoiceForm['issuedDate']}
                            label='Issue Date'
                            onHandleChange={(value) =>
                                handleDateChange(value, 'issuedDate')
                            }
                        />
                        <CustomDatepicker
                            value={invoiceForm['dueDate']}
                            label='Due Date'
                            onHandleChange={(value) =>
                                handleDateChange(value, 'dueDate')
                            }
                        />

                        {viewUploadModal && (
                            <CustomModal
                                maxWidth='lg'
                                open={viewUploadModal}
                                onClose={() => {
                                    setViewUploadModal(false);
                                    if (!file) {
                                        navigate('/payments/logistics/list');
                                    }
                                }}
                            >
                                <UploadLogisticsInvoice>
                                    <div className='mb-4 border border-dashed rounded-lg overflow-hidden'>
                                        <FileSelector
                                            {...{
                                                label: 'PDF, PNG, JPG or GIF',
                                                enabled: true,
                                                onFilesSelectedHandler: ({
                                                    files,
                                                    input
                                                }) => {
                                                    const [file] = files;
                                                    setFile(file);
                                                    input &&
                                                        document.body.removeChild(
                                                            input
                                                        );
                                                }
                                            }}
                                        />
                                    </div>
                                    {file && (
                                        <>
                                            <UploadProgressInformation
                                                file={file}
                                                reference={'fileUpload'}
                                                onHandleSettled={(params: {
                                                    uri: string;
                                                    reference: string;
                                                }) => {
                                                    const { uri } = params;
                                                    if (uri) {
                                                        setInvoiceForm(
                                                            (prev) => {
                                                                const update = {
                                                                    ...prev,
                                                                    documentUrl:
                                                                        uri
                                                                };

                                                                formik.setValues(
                                                                    update
                                                                );

                                                                return update;
                                                            }
                                                        );
                                                    }
                                                }}
                                                onDeleteHandler={() => {
                                                    setFile(undefined);
                                                    setInvoiceForm((prev) => {
                                                        const update = {
                                                            ...prev,
                                                            documentUrl: ''
                                                        };
                                                        formik.setValues(
                                                            update
                                                        );
                                                        return update;
                                                    });
                                                }}
                                            />
                                            <Stack
                                                direction='row'
                                                gap='20px'
                                                mt={6}
                                            >
                                                <Custombutton
                                                    type='button'
                                                    variant='outlined'
                                                    buttonText='Cancel'
                                                    styles={{
                                                        padding:
                                                            '0.75rem 0.62rem',
                                                        width: '100%'
                                                    }}
                                                    onClickAction={() => {
                                                        setFile(undefined);
                                                        setInvoiceForm(
                                                            (prev) => {
                                                                const update = {
                                                                    ...prev,
                                                                    documentUrl:
                                                                        ''
                                                                };
                                                                formik.setValues(
                                                                    update
                                                                );
                                                                return update;
                                                            }
                                                        );
                                                        setViewUploadModal(
                                                            false
                                                        );
                                                        navigate(
                                                            '/payments/logistics/list'
                                                        );
                                                    }}
                                                    disabled={
                                                        !invoiceForm[
                                                            'documentUrl'
                                                        ]
                                                    }
                                                />
                                                <Custombutton
                                                    isLoadingButton
                                                    type='button'
                                                    variant='contained'
                                                    buttonText='Save and Continue'
                                                    styles={{
                                                        padding:
                                                            '0.75rem 0.62rem',
                                                        width: '100%'
                                                    }}
                                                    onClickAction={() => {
                                                        setViewUploadModal(
                                                            false
                                                        );
                                                    }}
                                                    disabled={
                                                        !invoiceForm[
                                                            'documentUrl'
                                                        ]
                                                    }
                                                />
                                            </Stack>
                                        </>
                                    )}
                                </UploadLogisticsInvoice>
                            </CustomModal>
                        )}

                        <Custombutton
                            isLoadingButton
                            type='button'
                            loading={formik.isSubmitting || isLoading}
                            variant='contained'
                            buttonText='Create Invoice'
                            styles={{
                                padding: '0.75rem 0.62rem',
                                width: '100%'
                            }}
                            onClickAction={() => {
                                formik.handleSubmit();
                            }}
                            disabled={!formik.isValid}
                        />
                    </Box>
                </Grid>
                <Grid item lg={6} md={12} sm={12} className='hidden lg:block'>
                    {invoiceForm['documentUrl'] && (
                        <DocumentViewer
                            fileUrl={
                                REACT_APP_BASE_URL +
                                invoiceForm['documentUrl']?.replace('v1/', '')
                            }
                            loader={true}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};
