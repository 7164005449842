export const documentList = [
  {
    title: 'Quotation',
    route: '/trade-documents/quotation',
    documentType: 'QUOTATION',
  },
  {
    title: 'Proforma Invoice',
    route: '/trade-documents/proforma-invoice',
    documentType: 'PROFORMA_INVOICE',
  },
  {
    title: 'Commercial invoice',
    route: '/trade-documents/commercial-invoice',
    documentType: 'COMMERCIAL_INVOICE',
  },
  {
    title: 'Packing list',
    route: '/trade-documents/packing-list',
    documentType: 'PACKING_LIST',
  },
  {
    title: 'Sales Contract',
    route: '/trade-documents/sales-contract',
    documentType: 'SALES_CONTRACT',
  },

  {
    title: 'Draft bill of lading',
    route: '/trade-documents/house-bill-of-lading',
    documentType: 'DRAFT_BILL_OF_LADING',
  },
  {
    title: 'Bill of lading',
    route: '#',
    isUpload: true,
    documentType: 'BILL_OF_LADING',
  },
  {
    title: 'Purchase order',
    route: '/trade-documents/purchase-order',
    documentType: 'PURCHASE_ORDER',
  },
  {
    title: 'Draft Verified gross mass declaration',
    route: '/trade-documents/verified-gross-mass-declaration',
    documentType: 'DRAFT_GROSS_MASS_DECLARATION',
  },
  {
    title: 'Draft Certificate of origin',
    route: '/trade-documents/certificate-of-origin',
    documentType: 'DRAFT_CERTIFICATE_OF_ORIGIN',
  },
  {
    title: 'Original Certificate of origin',
    route: '#',
    isUpload: true,
    documentType: 'CERTIFICATE_OF_ORIGIN',
  },
  {
    title: 'Original Verified Gross Mass Declaration',
    route: '#',
    documentType: 'GROSS_MASS_DECLARATION',
    isUpload: true,
  },

  {
    title: 'Phytosanitary certificate',
    route: '#',
    documentType: 'PHYTOSANITARY_CERTIFICATE',
    isUpload: true,
  },
  {
    title: 'Fumigation certificate',
    route: '#',
    documentType: 'FUMIGATION_CERTIFICATE',
    isUpload: true,
  },
  {
    title: 'Marine Insurance',
    route: '#',
    documentType: 'MARINE_INSURANCE',
    isUpload: true,
  },
  {
    title: 'Original Certificate of Analysis',
    route: '#',
    documentType: 'ORIGINAL_CERTIFICATE_ANALYSIS',
    isUpload: true,
  },
  {
    title: 'Pre-inspection Report',
    route: '#',
    documentType: 'PRE_INSPECTION_CERTIFICATE',
    isUpload: true,
  },
  {
    title: 'Nigerian Export Proceeds (NXP)',
    route: '#',
    documentType: 'NXP_FORM',
    isUpload: true,
  },
  {
    title: 'Other Documents',
    route: '#',
    documentType: 'OTHER_DOCUMENTS',
    isUpload: true,
  },
]
