import React, { ForwardedRef, forwardRef } from 'react';
import { PreviewDocumentContainer } from '../../../../styles/TradeDocStyles';
import { REACT_APP_BASE_URL } from '../../../../config';
import QuotationTop from './QuotationTop';
import ProformaView from './ProformaView';
import CommercialView from './CommercialView';
import PackingListView from './PackingListView';
import CertificateOfOriginView from './CertificateOfOriginView';
import DraftBillOfLadenView from './DraftBillOfLadenView';
import SalesView from './SalesView';
import DocumentViewer from '../../../DocumentViewer/DocumentViewer';
interface IProps {
    data?: any;
    currentData: {
        createdType: string;
        documentType: string;
        id: any;
    };
}
const MainPreviewComponent = (
    { data, currentData }: IProps,
    ref: ForwardedRef<HTMLDivElement>
) => {
    return (
        <div
            ref={ref}
            className='px-4'
            style={{
                position: 'absolute',
                top: '0',
                left: '0',
                zIndex: '-200'
            }}
        >
            <PreviewDocumentContainer>
                <div className='text-[14px]'>
                    <div className='flex items-center justify-between mb-4 w-full'>
                        {data?.logoUrl ? (
                            <div className='w-[350px]'>
                                <DocumentViewer
                                    fileUrl={`${REACT_APP_BASE_URL}/${data?.logoUrl.split(
                                        '/v1/'
                                    )[1]}`}
                                />
                            </div>
                        ) : (
                            <>
                                <div>
                                    <img
                                        src='/img/vectors/powered.svg'
                                        alt=''
                                        className='h-[2.5rem]'
                                    />
                                </div>
                            </>
                        )}
                        <div>
                            <h1 className='text-appcolorprimary text-[26px] leading-[27px] font-bold capitalize'>
                                {currentData?.documentType
                                    .split('_')
                                    .filter((x) => x.length > 0)
                                    .map(
                                        (x) =>
                                            x.charAt(0).toUpperCase() +
                                            x.slice(1)
                                    )
                                    .join(' ')
                                    .toLowerCase()}
                            </h1>
                            <p className='text-right text-sm'>
                                Doc#: {data?.corporateDocumentId}
                            </p>
                        </div>
                    </div>
                </div>
                {(currentData?.documentType === 'QUOTATION' ||
                    currentData?.documentType === 'PURCHASE_ORDER' ||
                    currentData?.documentType ===
                        'DRAFT_GROSS_MASS_DECLARATION') &&
                    data && (
                        <QuotationTop
                            data={data}
                            docType={currentData?.documentType}
                        />
                    )}
                {currentData?.documentType === 'PROFORMA_INVOICE' && data && (
                    <ProformaView data={data} />
                )}
                {currentData?.documentType === 'COMMERCIAL_INVOICE' && data && (
                    <CommercialView data={data} />
                )}
                {currentData?.documentType === 'PACKING_LIST' && data && (
                    <PackingListView data={data} />
                )}
                {currentData?.documentType === 'DRAFT_CERTIFICATE_OF_ORIGIN' &&
                    data && <CertificateOfOriginView data={data} />}
                {currentData?.documentType === 'DRAFT_BILL_OF_LADING' &&
                    data && <DraftBillOfLadenView data={data} />}
                {currentData?.documentType === 'SALES_CONTRACT' && data && (
                    <SalesView
                        data={data}
                        docType={currentData?.documentType}
                    />
                )}
            </PreviewDocumentContainer>
        </div>
    );
};
export default forwardRef<HTMLDivElement, IProps>(MainPreviewComponent);
