import { Divider, Stack } from '@mui/material'
import React from 'react'

interface IProps {
  data?: any
}

const PartiesGeneral = ({ data }: IProps) => {
  return (
    <div className="details-content flex-1">
      <h3>Parties</h3>
      <Stack direction="row" className="flex-dets">
        <p>Shipper</p>
        <p>{data?.creationDetails?.parties?.shipperName || '--'}</p>
      </Stack>
      <Stack direction="row" className="flex-dets">
        <p>Forwarder</p>
        <p>{data?.creationDetails?.parties?.forwarderName || '--'}</p>
      </Stack>
      <Stack direction="row" className="flex-dets">
        <p>Consignee</p>
        <p>{data?.creationDetails?.parties?.consigneeName || '--'}</p>
      </Stack>
      <Stack direction="row" className="flex-dets">
        <p>Contract Party</p>
        <p>{data?.creationDetails?.parties?.contractPartyName || '--'}</p>
      </Stack>
      <Divider className=" bg-appcolormainblack" />
      <Stack direction="row" className="flex-dets">
        <p>Notify Party </p>
        <p>{data?.creationDetails?.parties?.notifyPartyName || '--'}</p>
      </Stack>{' '}
      <Divider className=" bg-appcolormainblack" />
      <Stack direction="row" className="flex-dets">
        <p>Add. Notify Party 1</p>
        <p>
          {data?.creationDetails?.parties?.additionalNotifyParty1Name || '--'}
        </p>
      </Stack>
      <Divider className=" bg-appcolormainblack" />
      <Stack direction="row" className="flex-dets">
        <p>Add. Notify Party 2</p>
        <p>
          {data?.creationDetails?.parties?.additionalNotifyParty2Name || '--'}
        </p>
      </Stack>
    </div>
  )
}

export default PartiesGeneral
