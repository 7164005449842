import React, { useState } from 'react'
import type { DatePickerProps, MenuProps } from 'antd'
import {
  Button,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Select,
  Space,
} from 'antd'
import { documentTypeOptions } from './filterOptions'
import { contentStyle, headingStyle, menuStyle, subStyle } from './filterStyle'

interface IProps {
  setFilterOptions: any
  filterOptions: any
  fetchDocuments: any
}

const FilterTradeDocuments: React.FC<IProps> = ({
  setFilterOptions,
  filterOptions,
  fetchDocuments,
}): JSX.Element => {
  const onChangeTo: DatePickerProps['onChange'] = (date, dateString) => {
    setFilterOptions({
      ...filterOptions,
      createdDateTo: date?.format('YYYY-MM-DD HH:mm:ss'),
    })
  }

  const onChangeFrom: DatePickerProps['onChange'] = (date, dateString) => {
    setFilterOptions({
      ...filterOptions,
      createdDateFrom: date?.format('YYYY-MM-DD HH:mm:ss'),
    })
  }

  const onOk = (value: DatePickerProps['value']) => {
    console.log('onOk:')
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div>
          <h2>Date Range</h2>
          <Space
            style={{
              padding: 0,
              marginTop: '.5rem',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <DatePicker
              placeholder="Start Date"
              onChange={onChangeFrom}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD"
              onOk={onOk}
            />

            <DatePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD"
              placeholder="End Date"
              onChange={onChangeTo}
              onOk={onOk}
            />
          </Space>
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: (
        <div>
          <h2>Document Name</h2>
          <Input
            type="text"
            value={filterOptions?.documentName}
            style={{
              marginTop: '.5rem',
            }}
            onChange={(e) =>
              setFilterOptions({
                ...filterOptions,
                documentName: e.target.value,
              })
            }
          />
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: (
        <div>
          <h2>Document Type</h2>

          <Select
            style={{
              marginTop: '.5rem',
              width: '100%',
            }}
            options={documentTypeOptions}
            value={filterOptions?.documentType}
            onChange={(value: string) =>
              setFilterOptions({ ...filterOptions, documentType: value })
            }
          />
        </div>
      ),
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: (
        <div>
          <h2>Data Type</h2>

          <Select
            style={{
              marginTop: '.5rem',
              width: '100%',
            }}
            value={filterOptions?.createdType}
            onChange={(value: string) =>
              setFilterOptions({ ...filterOptions, createdType: value })
            }
            options={[
              { value: 'CREATED', label: 'Form' },
              { value: 'UPLOADED', label: 'File' },
            ]}
          />
        </div>
      ),
    },
  ]

  const [dropdownVisible, setDropdownVisible] = useState(false)

  const handleMenuClick = () => {
    fetchDocuments(filterOptions)
    setDropdownVisible(false)
  }

  const handleButtonClick = () => {
    setDropdownVisible(!dropdownVisible)
  }
  return (
    <Dropdown
      menu={{ items }}
      trigger={['click']}
      visible={dropdownVisible}
      overlayStyle={{
        pointerEvents: 'auto',
      }}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          <h2 style={headingStyle}>Filters</h2>
          <p style={subStyle}>Filters will be added to the table.</p>
          {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
          <Divider style={{ margin: 0 }} />
          <Space
            style={{
              padding: '1rem',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Button
              className="reset-btn"
              onClick={() => {
                setFilterOptions({})
                fetchDocuments({})
                setDropdownVisible(false)
              }}
            >
              Reset
            </Button>
            <Button
              className="bg-appcolorprimarydeep text-appcolorwhite border-0 hover:bg-appcolorwhite hover:border-appcolorprimarydeep hover:border  hover:text-appcolorprimary"
              onClick={handleMenuClick}
            >
              Apply Filter
            </Button>
          </Space>
        </div>
      )}
    >
      <div className="tab-buttons" onClick={handleButtonClick}>
        <img src="/img/vectors/plainfilter.svg" alt="plainfilter" />
        <p style={{ paddingRight: '5px' }}>Filter</p>
      </div>
    </Dropdown>
  )
}

export default FilterTradeDocuments
