import React, { Dispatch, SetStateAction } from 'react';
import {
    Invoice,
    InvoiceParams
} from '../../../../pages/dashboard/payments/interfaces/Invoice';
import { Dropdown, MenuProps } from 'antd';
import { Box } from '@mui/material';
import { MoreIcon } from '../../../icons/MoreIcon';
import { RefreshTableIcon } from '../../../icons/RefreshTableIcon';
import { formatCurrency, getInvoiceStatus } from '../../../../utils/helper';
import dayjs from 'dayjs';
import FilterInvoice from './FilterInvoice';
import { CorporatePartner } from '../../../../interfaces/CorporatePartner';
import { getInvoiceContractStatusIcon } from '../../../icons/InvoiceStatusIcons';
import MobilePagination from '../../../MobilePagination';
import Customtable from '../../../customtable/customtable';

const MobileCard = ({
    item,
    setCurrentData,
    actions
}: {
    item?: Invoice;
    setCurrentData: (data: Invoice) => void;
    actions: MenuProps['items'];
}) => {
    return (
        <Box key={item?.id} borderBottom='1px solid#EAECF0' py={3} mx={2}>
            <Box width='full' justifyContent='space-between' display='flex'>
                <Box display='flex' gap={1}>
                    {getInvoiceContractStatusIcon(
                        getInvoiceStatus(Number(item?.status)).toUpperCase()
                    )}
                    <Box>
                        <h4 className=' text-ebony text-[14px] font-semibold'>
                            {item?.consigneeName}
                        </h4>
                        <p className=' text-[12px] text-paleSky'>
                            Total Amount:
                            <span className=' pl-1 pr-3 font-extrabold'>
                                {formatCurrency(
                                    item?.totalAmount ?? 0,
                                    item?.currencyCode ?? 'NGN'
                                )}{' '}
                            </span>
                            Invoice:
                            <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                {item?.invoiceNumber}
                            </span>
                        </p>
                        <p className=' text-[12px] text-paleSky'>
                            Balance Due:
                            <span className=' pl-1 pr-3 font-extrabold'>
                                {formatCurrency(
                                    item?.totalPaid ?? 0,
                                    item?.currencyCode ?? 'NGN'
                                )}{' '}
                            </span>
                            Status:
                            <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                {getInvoiceStatus(Number(item?.status))}
                            </span>
                        </p>
                        <p className=' text-[12px] text-paleSky'>
                            Due Date:
                            <span className=' pl-1 pr-3 font-extrabold'>
                                {dayjs(item?.dueDate).format('DD MMM, YYYY')}
                            </span>
                            Created Date:
                            <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                {dayjs(item?.createdDate).format(
                                    'DD MMM, YYYY'
                                )}{' '}
                            </span>
                        </p>
                        <p className=' text-[12px] text-paleSky'>
                            Payment Terms:
                            <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                {item?.termsOfPayment}
                            </span>
                        </p>
                    </Box>
                </Box>

                <div
                    className='cursor-pointer'
                    onClick={() => {
                        item && setCurrentData(item);
                    }}
                >
                    <Dropdown
                        menu={{ items: actions }}
                        trigger={['click']}
                        placement='bottomRight'
                    >
                        <Box className=' flex flex-row justify-center'>
                            <MoreIcon />
                        </Box>
                    </Dropdown>
                </div>
            </Box>
        </Box>
    );
};

const InvoiceTableMobileCards = ({
    loading,
    invoices,
    fetchInvoices,
    setCurrentData,
    items,
    filterOptions,
    setFilterOptions,
    corporatePartners,
    paging
}: {
    loading: boolean;
    invoices: Invoice[];
    fetchInvoices: () => void;
    setCurrentData: Dispatch<SetStateAction<Invoice>>;
    items: MenuProps['items'];
    filterOptions: InvoiceParams;
    setFilterOptions: Dispatch<SetStateAction<InvoiceParams>>;
    corporatePartners: CorporatePartner[];
    paging: { page: number; total: number };
}) => {
    const getContractMenuOptions = () =>
        [
            <FilterInvoice
                {...{
                    setFilterOptions,
                    filterOptions,
                    corporatePartners
                }}
            />,
            <div className='tab-buttons flex' onClick={fetchInvoices}>
                <div className='pr-2'>
                    <RefreshTableIcon />
                </div>
                <p style={{ paddingRight: '5px' }}>Refresh</p>
            </div>
        ].map((comp, key) => ({
            key,
            label: comp
        }));

    return (
        <>
            <Box
                display='flex'
                width='100%'
                justifyContent='space-between'
                alignItems='center'
                bgcolor='white'
                px={2}
                py={2}
                borderBottom='1px solid #EAECF0'
            >
                <div className='flex gap-2 items-center'>
                    <p className=' text-ebony text-[16px] font-medium'>
                        Invoices
                    </p>
                </div>
                <Dropdown
                    menu={{
                        items: getContractMenuOptions()
                    }}
                    trigger={['click']}
                >
                    <Box className=' flex flex-row justify-center'>
                        <MoreIcon />
                    </Box>
                </Dropdown>
            </Box>
            <div className='lg:hidden '>
                <Customtable
                    data={invoices}
                    loadingData={loading}
                    emptyText=' You have no Invoices.'
                    customMobilePagination={
                        <MobilePagination
                            page={paging.page === 0 ? 1 : paging.page}
                            setPage={(page) => {
                                setFilterOptions((props) => ({
                                    ...props,
                                    page
                                }));
                            }}
                            totalPages={Math.ceil(paging.total / 10)}
                        />
                    }
                    mobileComponent={
                        <MobileCard
                            setCurrentData={setCurrentData}
                            actions={items}
                        />
                    }
                />
            </div>
        </>
    );
};

export default InvoiceTableMobileCards;
