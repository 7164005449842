import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0088B2',
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          '&:hover': {
            backgroundColor: '#0088B2',
          },
        },
      },
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
    },
    MuiAutocomplete:{
      
      styleOverrides:{
        popper:{
          zIndex: '3000',

        },
       
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: '2000',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: '#0088B2',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#0088B2',
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: 'red',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#0088B2',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: '12px',
          color: '#2C2D2E',
          lineHeight: '14px'
        },
      },
    
    },
  },
})
