import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Backdrop from '@mui/material/Backdrop';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import {
    commaNumber,
    getRecipientName,
    getTransactionStatus,
    getTransactionType
} from '../../../utils/helper';
import Skeleton from '@mui/material/Skeleton';
import dayjs from 'dayjs';
import { REACT_APP_BASE_URL } from '../../../config';
import ViewTransactionDocument from './shared/ViewTransactionDocument';
import { ViewPaymentReceipt } from './shared/ViewPaymentReceipt';
import { useTransactionDetails } from '../../../customhooks/useTransactionDetails';
import { BreadcrumbCaretRight } from '../../../components/icons/BreadcrumbCaretRight';
import { LoadingCircles } from '../../../components/icons/LoadingCircles';
import ViewTransactionsSummary from './ViewTransactionsSummary';
import PaymentProgress from '../../../components/PaymentProgress';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';
import { CopyText } from '../../../components/CopyText';
import { ChevronLeftIcon } from '../../../components/icons';

const ViewTransaction = () => {
    const navigate = useNavigate();
    const { reference } = useParams();

    const { user } = useSelector((state: RootState) => state.user);

    const {
        isLoading: isTransactionDetailsLoading,
        entries: transactionDetails,
        isFetching
    } = useTransactionDetails(reference, user?.corporate_id);
    const { permissions } = useUserProfile();

    const topSection = [
        {
            label: 'Rate',
            value: `1 ${transactionDetails?.fromCurrencyCode} = ${transactionDetails?.rateValue} ${transactionDetails?.toCurrencyCode}`
        },
        {
            label: 'Amount',
            value: transactionDetails?.amountFrom
        },
        {
            label: 'Status',
            value: getTransactionStatus(transactionDetails?.status),
            isBold: true
        },
        {
            label: 'Tranx ID',
            value: transactionDetails?.transactionReference,
            isBold: true,
            canCopy: true
        }
    ];

    const midSection = [
        {
            label: 'Transaction Start Date',
            value: transactionDetails?.transactionStartDate
                ? dayjs(transactionDetails?.transactionStartDate).format('ll')
                : 'n/a'
        },
        {
            label: 'Beneficiary receives',
            value: `${transactionDetails?.toCurrencyCode} ${transactionDetails?.amountTo.toLocaleString()}`
        },
        {
            label: 'Transaction account',
            value: `${transactionDetails?.fromCurrencyCode} Account`
        },
        {
            label: 'Amount from',
            value: `${transactionDetails?.fromCurrencyCode} ${transactionDetails?.amountFrom.toLocaleString()}`
        },
        {
            label: 'Amount to',
            value: `${transactionDetails?.toCurrencyCode} ${transactionDetails?.amountTo.toLocaleString()}`
        },
        {
            label: 'Transaction End Date',
            value: transactionDetails?.transactionEndDate
                ? dayjs(transactionDetails?.transactionEndDate).format('ll')
                : 'n/a'
        }
    ];

    return (
        <div className='lg:h-screen'>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={isTransactionDetailsLoading || isFetching}
            >
                <div className='animate-spin'>
                    <LoadingCircles />
                </div>
            </Backdrop>

            <div className='hidden lg:block mb-[1.3rem]'>
                <button
                    className='flex items-center gap-2 font-medium cursor-pointer w-fit'
                    onClick={() => {
                        navigate('/payments/overview');
                    }}
                >
                    <BreadcrumbCaretRight />
                    <span>Back to All Transaction History</span>
                </button>
            </div>

            <div className='lg:hidden p-[16px] bg-white'>
                <button
                    className='flex items-center gap-2 text-lg font-normal text-ebony cursor-pointer w-fit'
                    onClick={() => {
                        navigate('/payments/overview');
                    }}
                >
                    <ChevronLeftIcon />
                    <span>All transactions</span>
                </button>
            </div>

            {isTransactionDetailsLoading || isFetching ? (
                <Skeleton variant='text' sx={{ fontSize: '8rem' }} />
            ) : (
                <ViewTransactionsSummary {...{ transactionDetails }} />
            )}

            {isTransactionDetailsLoading || isFetching ? (
                <div className='mt-[-5rem]'>
                    <Skeleton variant='text' sx={{ fontSize: '20rem' }} />
                </div>
            ) : (
                <>
                    <div className='hidden lg:block'>
                        <div className='bg-appcolorwhite pt-[0.8rem] pb-[0.6rem] pl-[1.5rem] w-fit pr-[3rem] text-[11px] leading-[14px] trapezoid'>
                            <span className='text-[16px] leading-6'>
                                Breakdown
                            </span>
                        </div>

                        <div className='p-[1.5rem] bg-appcolorwhite'>
                            <Grid container>
                                <Grid item xs={12} md={12} lg={6}>
                                    <div className='pr-4 custom-piped view'>
                                        <Grid container>
                                            <Grid item xs={12} md={12} lg={6}>
                                                <div className='mb-[1.6rem]'>
                                                    <p className='text-appcolorblacktwo opacity-[0.6]'>
                                                        Rate
                                                    </p>
                                                    <p className='font-medium'>
                                                        1 &nbsp;
                                                        {
                                                            transactionDetails?.fromCurrencyCode
                                                        }
                                                        &nbsp; ={' '}
                                                        {Number(
                                                            transactionDetails?.rateValue
                                                        )}
                                                        &nbsp;
                                                        {
                                                            transactionDetails?.toCurrencyCode
                                                        }
                                                    </p>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={6}>
                                                <div className='mb-[1.6rem]'>
                                                    <p className='text-appcolorblacktwo opacity-[0.6]'>
                                                        Beneficiary receives
                                                    </p>
                                                    <p className='font-medium'>
                                                        {
                                                            transactionDetails?.toCurrencyCode
                                                        }{' '}
                                                        {commaNumber(
                                                            Number(
                                                                transactionDetails?.amountTo
                                                            ).toFixed(2)
                                                        )}
                                                    </p>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={6}>
                                                <div className='mb-[1.6rem]'>
                                                    <p className='text-appcolorblacktwo opacity-[0.6]'>
                                                        Transaction Type
                                                    </p>
                                                    <p className='font-medium'>
                                                        {getTransactionType(
                                                            transactionDetails?.transactionType ??
                                                                0
                                                        )}
                                                    </p>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={6}>
                                                <div className='mb-[1.6rem]'>
                                                    <p className='text-appcolorblacktwo opacity-[0.6]'>
                                                        Transaction Account
                                                    </p>
                                                    <p className='font-medium'>
                                                        {
                                                            transactionDetails?.fromCurrencyCode
                                                        }{' '}
                                                        Account
                                                    </p>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={6}>
                                                <div className='mb-[1.6rem]'>
                                                    <p className='text-appcolorblacktwo opacity-[0.6]'>
                                                        Amount from
                                                    </p>
                                                    <p className='font-medium'>
                                                        {
                                                            transactionDetails?.fromCurrencyCode
                                                        }{' '}
                                                        {commaNumber(
                                                            Number(
                                                                transactionDetails?.amountFrom
                                                            ).toFixed(2)
                                                        )}
                                                    </p>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={6}>
                                                <div className='mb-[1.6rem]'>
                                                    <p className='text-appcolorblacktwo opacity-[0.6]'>
                                                        Amount to
                                                    </p>
                                                    <p className='font-medium'>
                                                        {
                                                            transactionDetails?.toCurrencyCode
                                                        }{' '}
                                                        {commaNumber(
                                                            Number(
                                                                transactionDetails?.amountTo
                                                            ).toFixed(2)
                                                        )}
                                                    </p>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={6}>
                                                <div className='mb-[1.6rem]'>
                                                    <p className='text-appcolorblacktwo opacity-[0.6]'>
                                                        Transaction End date
                                                    </p>
                                                    <p className='font-medium'>
                                                        {transactionDetails?.transactionEndDate
                                                            ? dayjs(
                                                                  transactionDetails?.transactionEndDate
                                                              ).format('ll')
                                                            : 'n/a'}
                                                    </p>
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={6}>
                                                {user &&
                                                    user.user_level === 5 && (
                                                        <div className='mb-[1.6rem]'>
                                                            <p className='text-appcolorblacktwo opacity-[0.6]'>
                                                                Transaction
                                                                Reference
                                                            </p>
                                                            <p className='font-medium'>
                                                                {
                                                                    transactionDetails?.extra.find(
                                                                        (
                                                                            extra
                                                                        ) =>
                                                                            extra.key ===
                                                                            'conversionReference'
                                                                    )?.value
                                                                }
                                                            </p>
                                                        </div>
                                                    )}
                                            </Grid>

                                            {transactionDetails?.documents &&
                                                transactionDetails?.documents
                                                    .length > 0 &&
                                                permissions &&
                                                hasPermission({
                                                    userPermissions:
                                                        permissions,
                                                    module: 'payments',
                                                    permissionList: [
                                                        'write',
                                                        '*',
                                                        'read'
                                                    ]
                                                }) && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={12}
                                                        lg={6}
                                                    >
                                                        <div className='mb-[1.6rem]'>
                                                            <p className='text-appcolorblacktwo opacity-[0.6]'>
                                                                Supporting
                                                                Document:
                                                            </p>

                                                            <ViewTransactionDocument
                                                                fileUrl={
                                                                    REACT_APP_BASE_URL +
                                                                    transactionDetails?.documents[0]?.documentUrl?.replace(
                                                                        'v1/',
                                                                        ''
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </Grid>
                                                )}

                                            {transactionDetails?.status === 1 &&
                                                permissions &&
                                                hasPermission({
                                                    userPermissions:
                                                        permissions,
                                                    module: 'payments',
                                                    permissionList: [
                                                        'write',
                                                        '*',
                                                        'read'
                                                    ]
                                                }) && (
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={12}
                                                        lg={6}
                                                    >
                                                        <div className='mb-[1.6rem]'>
                                                            <p className='text-appcolorblacktwo opacity-[0.6]'>
                                                                Payment Receipt:
                                                            </p>

                                                            <ViewPaymentReceipt
                                                                data={
                                                                    transactionDetails
                                                                }
                                                            />
                                                        </div>
                                                    </Grid>
                                                )}

                                            <Grid item xs={12} md={12} lg={12}>
                                                <div className='mb-[1.6rem]'>
                                                    <p className='text-appcolorblacktwo opacity-[0.6]'>
                                                        Narration
                                                    </p>
                                                    <p className='font-medium'>
                                                        {
                                                            transactionDetails?.narration
                                                        }
                                                    </p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>

                                {transactionDetails?.transactionAction !==
                                    'CREDIT' && (
                                    <Grid item xs={12} md={12} lg={6}>
                                        <div className='flex justify-center mx-auto'>
                                            <PaymentProgress
                                                fromAmount={`${transactionDetails?.fromCurrencyCode} ${commaNumber(
                                                    Number(
                                                        transactionDetails?.amountFrom
                                                    ).toFixed(2)
                                                )}`}
                                                toAmount={`${transactionDetails?.toCurrencyCode} ${commaNumber(
                                                    Number(
                                                        transactionDetails?.amountTo
                                                    ).toFixed(2)
                                                )}`}
                                                toName={
                                                    transactionDetails?.transactionAction ===
                                                    'CREDIT'
                                                        ? user?.name
                                                        : getRecipientName({
                                                              transactionDetails,
                                                              user
                                                          })
                                                }
                                                status={
                                                    transactionDetails?.status
                                                }
                                                transactionAction={
                                                    transactionDetails?.transactionAction ??
                                                    ''
                                                }
                                                fromName={
                                                    transactionDetails?.businessName
                                                }
                                            />
                                        </div>
                                        <div className='mt-4 bg-appcolorprimaryfaintthree p-[0.6rem] rounded-[3px] ml-8'>
                                            <p className='text-center'>
                                                <span>
                                                    You are Sending{' '}
                                                    {
                                                        transactionDetails?.fromCurrencyCode
                                                    }{' '}
                                                    {commaNumber(
                                                        Number(
                                                            transactionDetails?.amountFrom
                                                        ).toFixed(2)
                                                    )}{' '}
                                                    at 1 &nbsp;
                                                    {
                                                        transactionDetails?.fromCurrencyCode
                                                    }
                                                    &nbsp; ={' '}
                                                    {Number(
                                                        transactionDetails?.rateValue
                                                    )}
                                                    &nbsp;
                                                    {
                                                        transactionDetails?.toCurrencyCode
                                                    }
                                                    .
                                                </span>
                                                <br />
                                                <span>
                                                    Recipient will get{' '}
                                                    {
                                                        transactionDetails?.toCurrencyCode
                                                    }{' '}
                                                    {commaNumber(
                                                        Number(
                                                            transactionDetails?.amountTo
                                                        ).toFixed(2)
                                                    )}
                                                </span>
                                            </p>
                                        </div>
                                    </Grid>
                                )}
                            </Grid>
                        </div>
                    </div>

                    <div className='lg:hidden pt-[24px] pb-[80px] px-[16px]'>
                        <h5 className='text-base font-medium text-ebony border border-0 border-b border-appcolorfaintnine mb-[8px]'>
                            Transaction details
                        </h5>

                        <div className='pb-[16px]'>
                            {topSection?.map((item) => (
                                <div className='flex justify-between items-center leading-[24px] text-ebony text-sm mt-[16px] border border-0 border-b border-appcolorfaintnine leading-6'>
                                    <p>{item.label}</p>
                                    <p
                                        className={`items-center gap-[4px] flex ${
                                            item.isBold ? 'font-bold' : ''
                                        }`}
                                    >
                                        {item.value}{' '}
                                        {item.canCopy && (
                                            <CopyText text={item.value || ''} />
                                        )}
                                    </p>
                                </div>
                            ))}
                        </div>

                        <div className='flex justify-center my-[16px] h-[24px] items-center'>
                            <p className='w-[56px] border border-0 border-b border-silver'></p>
                        </div>

                        <div className='pb-[16px] mb-[16px]'>
                            {midSection?.map((item) => (
                                <div className='flex justify-between items-center leading-[24px] text-ebony text-sm font-normal mt-[16px] border border-0 border-b border-appcolorfaintnine leading-6'>
                                    <p>{item.label}</p>
                                    <p>{item.value} </p>
                                </div>
                            ))}
                        </div>

                        <div className='flex justify-center my-[16px] h-[24px] items-center'>
                            <p className='w-[56px] border border-0 border-b border-silver'></p>
                        </div>

                        <div className='text-ebony text-sm font-normal leading-6'>
                            <p>Narration</p>
                            <p>{transactionDetails?.narration}</p>
                        </div>
                        {transactionDetails && (
                            <>
                                <div className='mt-[16px]'>
                                    <p className='text-steelBlue text-sm font-normal'>
                                        Support Document
                                    </p>

                                    <ViewTransactionDocument
                                        fileUrl={
                                            REACT_APP_BASE_URL +
                                            transactionDetails?.documents[0]?.documentUrl?.replace(
                                                'v1/',
                                                ''
                                            )
                                        }
                                    />
                                </div>

                                <div className='mt-[16px]'>
                                    <p className='text-steelBlue text-sm font-normal'>
                                        Payment Receipt:
                                    </p>

                                    <ViewPaymentReceipt
                                        data={transactionDetails}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default ViewTransaction;
