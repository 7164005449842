import { RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from './store/store';
import { setAuthToken } from './api';
import { ToastContainer } from 'react-toastify';
import Custombutton from './components/custombutton/custombutton';
import { Router } from './Router';
import 'react-toastify/dist/ReactToastify.css';
import MfaPinModal from './components/MfaPinModal';

function App() {
    const state = useSelector((state: RootState) => ({
        token: state.user.token,
        user: state.user.user
    }));

    const { token } = state;
    setAuthToken(token);

    return (
        <div className='relative App'>
            <ToastContainer toastClassName='toast-z-index' />

            <MfaPinModal />

            {false && (
                <div className='bg-appcolorprimary pt-[0.6rem] justify-center flex items-center pb-[0.8rem] fixed w-full z-10'>
                    <p className='mr-[1.9rem] text-appcolorwhite text-[15px] leading-[19px] tracking-[0.01rem]'>
                        Ready to start using FrontEdge? Set up in minutes
                    </p>
                    <Custombutton
                        buttonText='Continue Setup'
                        styles={{
                            padding: '0.5rem',
                            fontSize: '15px',
                            lineHeight: '19px',
                            letterSpacing: '0.01rem',
                            color: '#0088B2',
                            backgroundColor: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#ffffff'
                            }
                        }}
                    />
                    <p className='absolute text-appcolorwhite top-[0.7rem] right-[1.6rem]'>
                        <span className='cursor-pointer'>x</span>
                    </p>
                </div>
            )}

            <RouterProvider router={Router} />
        </div>
    );
}

export default App;
