import React from 'react'
import { Input } from 'antd'
import Chatitem from './chatitem'
import Custombutton from '../../../../../components/custombutton/custombutton'

const Chats = () => {
  return (
    <div className="relative h-[23.5rem] overflow-y-hidden overflow-x-hidden">
      <div className="min-h-[19rem] max-h-[19rem] overflow-y-auto overflow-x-hidden relative">
        <div className="absolute w-[1px] h-screen bg-appcolorfaintblack left-[4.8rem]"></div>

        <Chatitem chatText="A dispute was raised on this document. Doesnt tally with document uploaded" />

        <Chatitem
          isAdmin
          chatText="Review the evidence then accept or decline this evidence."
        />
      </div>

      <div className="absolute bottom-0 left-[6.5rem] w-full pt-3 border-t border-appcolorgreyfaint">
        <Input.TextArea
          rows={2}
          className="w-full"
          bordered={false}
          placeholder="Your Response Here..."
        />

        <Custombutton
          isLoadingButton
          variant="contained"
          styles={{
            padding: '0.35rem 0.92rem 0.5rem 0.93rem',
          }}
          disabled={false}
          buttonText="Send"
          size="small"
          onClickAction={() => {}}
        />
      </div>
    </div>
  )
}

export default Chats
