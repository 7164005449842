import { ShippingProfile } from '../interfaces/User';
import { useGetQuery } from './useGetQuery';

const useShippingProfiles = () => {
    const { isLoading, entries: shippingProfiles } = useGetQuery<ShippingProfile[]>({
        url: '/booking/shipping/profiles',
        cacheKey: 'shippingProfiles',
        params: { page: 1, size: 0 }
    });

    return { isLoading, shippingProfiles };
};
export default useShippingProfiles;


