import { useQueryClient } from 'react-query';
import {
    DedicatedAccount,
    GetDedicatedAccountParams
} from '../pages/dashboard/payments/dedicatedAccounts/interfaces/DedicatedAccounts';
import { useGetQuery } from './useGetQuery';

const defaultParams: GetDedicatedAccountParams = {
    page: 1,
    size: 20
};

export const UseDedicatedAccounts = (
    initialParams?: GetDedicatedAccountParams
) => {
    const queryClient = useQueryClient();

    const params = initialParams || defaultParams;

    const {
        isLoading,
        entries: accountsList,
        isFetching
    } = useGetQuery<DedicatedAccount[]>({
        url: '/administrator/offshore/corporates',
        cacheKey: `dedicated-accounts`,
        params: { ...params }
    });

    const invalidateDedicatedAccountsCache = () => {
        queryClient.invalidateQueries({
            queryKey: ['dedicated-accounts', params]
        });
    };

    return {
        isLoading,
        accountsList,
        invalidateDedicatedAccountsCache,
        isFetching
    };
};
