import { Box, Typography } from '@mui/material';
import { UploadIcon } from './icons/UploadIcon';
import {
    FileSelectorParams,
    handleFilePicker
} from '../api/services/handleFileUpload';
import { DragEvent, useState } from 'react';

interface DocumentUploadProps {
    title?: string;
    label: string;
    enabled?: boolean;
    onFilesSelectedHandler: (entry: FileSelectorParams) => void;
}

export const FileSelector = (props: DocumentUploadProps) => {
    const [dragOver, setDragOver] = useState(false);
    const { title, label, enabled, onFilesSelectedHandler } = props;
    const handleOnDrop = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const { files } = event.dataTransfer as DataTransfer;
        const collection = [];

        for (const file of files || []) {
            collection.push(file);
        }

        onFilesSelectedHandler({ files: collection });
        setDragOver(false);
    };

    return (
        <Box>
            {title && <Box className='mb-[12px]'>{title}</Box>}
            <Box
                className={`px-[24px] py-[16px] text-center space-y-4 rounded ${
                    dragOver ? 'bg-slate-50' : 'bg-white'
                }`}
                onDrop={handleOnDrop}
                onDragOver={(event) => {
                    event.preventDefault();
                    setDragOver(true);
                }}
                onDragLeave={(event) => {
                    event.preventDefault();
                    setDragOver(false);
                }}
            >
                <Box className='mx-auto w-[40px] h-[40px]'>
                    <UploadIcon />
                </Box>
                <Typography>
                    <span
                        className='text-appcolorprimary cursor-pointer'
                        onClick={() =>
                            enabled &&
                            handleFilePicker({
                                onFilesSelectedHandler
                            })
                        }
                    >
                        Click to upload
                    </span>{' '}
                    or drag and drop
                </Typography>
                <Typography className='text-paleSky'>{label}</Typography>
            </Box>
        </Box>
    );
};
