import { InputAdornment, Stack, TextField } from '@mui/material';
import { Button } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ExportIcon } from '../../../icons/ExportIcon';
import MessageEnvelope from '../../../icons/MessageEnvelope';
interface SimpleDialogProps {
    closeShowAddModal?: () => void;
}
const ContractExport = (props: SimpleDialogProps) => {
    const [emailList, setEmailList] = useState<{ email: string }[]>([
        { email: '' }
    ]);

    const addEmail = () => {
        setEmailList([...emailList, { email: '' }]);
    };
    return (
        <ExportContainer>
            <ExportIcon />
            <h2>Export CSV</h2>
            <p>
                Please enter emails of users to which you would like to export
                as a CSV
            </p>
            <p className='lbl'>Email Address</p>

            <Stack>
                {emailList?.map((item, index) => (
                    <TextField
                        key={index}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <MessageEnvelope />
                                </InputAdornment>
                            )
                        }}
                        value={item?.email}
                        margin='dense'
                        fullWidth
                        placeholder='you@untitledui.com'
                    />
                ))}

                <div className='addAnother' onClick={addEmail}>
                    + Add another
                </div>
            </Stack>

            <Stack
                direction='row'
                width='100%'
                gap='12px'
                className='submitBtns'
            >
                <Button onClick={props.closeShowAddModal}>Cancel</Button>
                <Button>Send CSV</Button>
            </Stack>
        </ExportContainer>
    );
};

export default ContractExport;

export const ExportContainer = styled.div`
    max-width: 400px;
    margin: auto;
    .addAnother {
        margin-top: 12px;
        margin-bottom: 12px;
        cursor: pointer;
        color: #0088b2;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }
    .submitBtns {
        margin: 25px 0 10px;
        button {
            border-radius: 8px;
            height: 48px;
            width: 350px;

            color: #fff;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            :first-of-type {
                background-color: white !important;
                color: black !important;
                border: 1px solid #0088b2;
            }
        }
    }
    .MuiOutlinedInput-root {
        border-radius: 8px;
        background: #fff;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    p {
        color: #667085;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }
    .lbl {
        color: #344054;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
    }
    h2 {
        color: #101828;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-top: 20px;
    }
    .ant-radio-group {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        border: 1px solid #0088b2 !important;
        background: rgba(0, 136, 178, 0.1);
        color: #0088b2;
        font-weight: 500;
    }
    .ant-radio-button-wrapper {
        border-radius: 0 !important;
        margin-right: 10px;
        color: black;
        border: 1px solid #667085 !important;
        color: #667085;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        padding: 5px 20px;
        /* border-start-end-radius: 0;
        border-end-end-radius:0; */
        ::before {
            width: 0;
        }
        :hover {
            border: 1px solid #0088b2;
            background: rgba(0, 136, 178, 0.1);
            color: #0088b2;
        }
    }
`;
