import { Box, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { CustomTab } from '../../../components/CustomTab';
import { SuitUIButton } from '../../../components/SuitUIButton';
import { PageTitle } from '../../../components/PageTitle';
import { BarChartIcon } from '../../../components/icons/BarChartIcon';
import { CargoInsuranceHome } from './CargoInsuranceHome';
import { GoodsInTransitHome } from './GoodsInTransitHome';
import { InsuranceAdBox } from './InsuranceAdBox';
import Outletouterpage from '../../../components/dashboard/outletouterpage/outletouterpage';
import EnablingFeature from '../../../components/EnablingFeature';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';
import { useUserProfile } from '../../../customhooks/useUserProfile';

export const InsuranceOverview = () => {
    const navigate = useNavigate();
    const { permissions } = useUserProfile();

    return (
        <EnablingFeature flag='Insurance'>
            <Box className='mb-8 overflow-x-hidden'>
                <Outletouterpage
                    breadcrumbs={[
                        {
                            title: 'Cargo',
                            link: '/dashboard'
                        }
                    ]}
                >
                    <Box className='flex justify-between'>
                        <PageTitle
                            {...{
                                Icon: BarChartIcon,
                                title: 'Cargo and Goods in-transit insurances'
                            }}
                        />
                        {permissions &&
                            hasPermission({
                                userPermissions: permissions,
                                module: 'insurance',
                                permissionList: ['write', '*']
                            }) && (
                                <SuitUIButton
                                    text='Apply for Insurance'
                                    onClick={() => {
                                        navigate('/insurance/profile');
                                    }}
                                />
                            )}
                    </Box>

                    <Box className='mt-[22px] flex justify-between'>
                        <Box>
                            <Box className='w-[547px] mb-[45px]'>
                                Protect the entirety of your merchandise's
                                in-land transit or shipment journey and ensure
                                collection
                            </Box>
                            <CustomTab
                                items={[
                                    {
                                        header: 'Cargo Insurance ',
                                        content: <CargoInsuranceHome />
                                    },
                                    {
                                        header: 'Goods in-transit insurance',
                                        content: <GoodsInTransitHome />
                                    }
                                ]}
                            />
                            <Typography>
                                To learn more about the risks covered and apply,{' '}
                                <Link
                                    className='text-appcolorprimary'
                                    to='/insurance/profile'
                                >
                                    click to get started
                                </Link>
                            </Typography>
                        </Box>
                        <InsuranceAdBox />
                    </Box>
                </Outletouterpage>
            </Box>
        </EnablingFeature>
    );
};
