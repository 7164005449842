import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
// import { outletouterpageProps } from './outletouterpage-interface'

export interface breadcrumb {
    title: string;
    link?: string;
}

export interface outletouterpageProps {
    children: ReactNode;
    title?: ReactNode;
    breadcrumbs?: breadcrumb[];
    topRightAction?: ReactNode;
    isMaxWidth?: boolean;
}

const Outletouterpage = ({
    children,
    title,
    breadcrumbs,
    topRightAction,
    isMaxWidth
}: outletouterpageProps) => {
    const navigate = useNavigate();

    return (
        <div className={`${isMaxWidth ? 'lg:max-w-[60rem]' : ''}`}>
            {breadcrumbs !== undefined && breadcrumbs?.length > 0 && (
                <>
                    <div className='flex items-center justify-between mb-6'>
                        <div className='hidden lg:flex px-[16px] lg:px-0 pt-[24px] lg:pt-0'>
                            {breadcrumbs.map((item, index) => (
                                <p
                                    className={`text-[12px] flex items-center ${
                                        index === breadcrumbs.length - 1
                                            ? 'text-appcolorblackfour'
                                            : 'mr-[0.6rem] text-appcolorpurplefaint'
                                    }`}
                                    key={`breadcrumbs-${index}-${item.title}`}
                                >
                                    <span
                                        className='mr-[0.7rem] cursor-pointer'
                                        onClick={() => {
                                            if (item.link !== undefined) {
                                                navigate(item?.link);
                                            }
                                        }}
                                    >
                                        {item.title}
                                    </span>
                                    {index !== breadcrumbs.length - 1 && (
                                        <img
                                            src='/img/vectors/breadcrumbcaret.svg'
                                            alt='caret'
                                        />
                                    )}
                                </p>
                            ))}
                        </div>

                        <div>{topRightAction}</div>
                    </div>
                </>
            )}

            {title !== undefined && <div className='mb-6'>{title}</div>}

            <div>{children}</div>
        </div>
    );
};

Outletouterpage.defaultProps = {
    breadcrumbs: []
};

export default Outletouterpage;
