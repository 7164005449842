import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import Skeleton from '@mui/material/Skeleton';
import moment from 'moment';
import {
    capitalizeTextChar,
    getBookingStatus,
    openNotification,
    pascalToHyphenString
} from '../../../../utils/helper';
import type { MenuProps } from 'antd';
import { Divider } from '@mui/material';
import Custombutton from '../../../../components/custombutton/custombutton';
import { BookingContainer } from '../../../../components/dashboard/upsBooking/Styles';
import AssociatedDocumentTable from '../../../../components/dashboard/bookings/AssociatedDocumentTable';
import { TabContainerNew } from '../../../../styles/TradeDocStyles';
import { hasPermission } from '../../../../components/dashboard/dashboardpage/utils';
import { useUserProfile } from '../../../../customhooks/useUserProfile';
import { ChevronLeftIcon } from '../../../../components/icons';
import StatusIndicator from '../../../../components/StatusIndicator';
import { useBookingByID } from '../../../../customhooks/useBookings';
import { SuitUIButton } from '../../../../components/SuitUIButton';

const Viewbookings = () => {
    const { reference } = useParams();
    const navigate = useNavigate();

    const [showContainers, setShowContainers] = useState(true);
    const [documents, setDocuments] = useState<any[]>([]);
    const [containers, setContainers] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentData, setCurrentData] = useState<any>('');
    const [currentDocData, setCurrentDocData] = useState<any | undefined>();
    const [visibleDocument, setVisibleDocument] = useState(false);
    const { permissions } = useUserProfile();

    const state = useSelector((state: RootState) => ({
        user: state.user.user
    }));

    const { user } = state;

    const { isLoading, booking: data } = useBookingByID({
        bookingId: reference || '0',
        corporateId: user?.corporate_id
    });

    const getVesselName = (
        vesselId: number,
        vessels: {
            name: string;
            id: number;
        }[]
    ) => {
        if (vesselId === undefined || vessels.length === 0) {
            return '-';
        } else {
            const filteredVessel = vessels.filter(
                (item) => item.id === vesselId
            );
            if (filteredVessel.length > 0) {
                return filteredVessel[0].name;
            } else {
                return '-';
            }
        }
    };

    const summaryData = [
        {
            label: 'Date',
            value: moment(data?.createdDate || Date.now()).format('ll')
        },
        {
            label: 'Port of loading',
            value: data?.portOfLoading || '--'
        },
        {
            label: 'Port of Destination',
            value: data?.portOfDischarge || '--'
        },
        {
            label: 'Vessel(s)',
            value: data?.trackingDetails?.details?.vessels?.length
                ? data?.trackingDetails?.details?.vessels?.map(
                      (item: { name: string }) => (
                          <p className='font-bold text-[13px]'>
                              {item?.name + ', ' || ''}{' '}
                          </p>
                      )
                  )
                : '--'
        },
        {
            label: 'Status',
            value: getBookingStatus(data?.status || 0)
        }
    ];
    // const fetchBooking = async () => {
    //     if (!user) {
    //         return;
    //     }

    //     try {
    //         const res = await getBooking({
    //             bookingId: reference || '0',
    //             corporateId: user.corporate_id
    //         });

    //         const { data } = res.data;

    //         const newContainers: any[] = [];

    //         data?.trackingDetails?.details?.containers.forEach((item: any) => {
    //             newContainers.push({
    //                 ...item,
    //                 vesselName: getVesselName(
    //                     item.vesselId,
    //                     data?.trackingDetails?.details?.vessels || []
    //                 )
    //             });
    //         });

    //         setContainers(newContainers);

    //         setDocuments(data?.documents);
    //     } catch (error) {
    //         if (error?.response) {
    //             openNotification('error', `Booking, Something went wrong`);
    //         } else {
    //             openNotification('error', `Booking, Something went wrong`);
    //         }
    //     }
    // };

    // useEffect(() => {
    //     fetchBooking();
    // }, []);

    const items: MenuProps['items'] = [
        {
            key: '2',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        navigate(
                            `/bookings/track-shipments/${data?.bookingReference}/${currentData.number}`
                        );
                    }}
                >
                    <span className='ml-2'>Track Container</span>
                </div>
            )
        }
    ];

    const docItems: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        setVisibleDocument(true);
                    }}
                >
                    <span className='ml-2'>View </span>
                </div>
            )
        },
        {
            key: '2',
            label: (
                <div
                    className={`flex items-center ${
                        permissions &&
                        !hasPermission({
                            userPermissions: permissions,
                            module: 'logistics',
                            permissionList: ['write', '*']
                        }) &&
                        ` opacity-30 cursor-not-allowed`
                    }`}
                    onClick={() => {
                        if (
                            permissions &&
                            hasPermission({
                                userPermissions: permissions,
                                module: 'logistics',
                                permissionList: ['write', '*']
                            })
                        ) {
                            navigate(
                                `/trade-documents/${pascalToHyphenString(
                                    currentDocData?.documentType
                                )}?edit=${currentDocData?.id}`
                            );
                        } else {
                            openNotification(
                                'error',
                                'You do not have access to perform this action'
                            );
                        }
                    }}
                >
                    <span className='ml-2'>Edit </span>
                </div>
            )
        }
    ];

    return (
        <BookingContainer>
            <div className='max-w-[100vw]'>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                    open={isLoading}
                >
                    <img
                        src='/img/vectors/loadingcircles.svg'
                        alt='loading'
                        className='animate-spin'
                    />
                </Backdrop>

                <div className='hidden lg:block mb-[1.3rem]'>
                    <p
                        className='flex items-center font-medium cursor-pointer w-fit'
                        onClick={() => {
                            navigate('/bookings');
                        }}
                    >
                        <img
                            src='/img/vectors/breadcrumbcaretright.svg'
                            alt='caret'
                            className='pr-3'
                        />
                        <span>Back to All Bookings</span>
                    </p>
                </div>

                <div className='lg:hidden p-[16px] bg-white'>
                    <button
                        className='flex items-center gap-2 text-lg font-normal text-ebony cursor-pointer w-fit'
                        onClick={() => {
                            navigate('/bookings');
                        }}
                    >
                        <ChevronLeftIcon />
                        <span>Bookings</span>
                    </button>
                </div>

                <div className='hidden lg:block'>
                    {isLoading ? (
                        <Skeleton variant='text' sx={{ fontSize: '8rem' }} />
                    ) : (
                        <div className='bg-appcolorwhite rounded-[3px] gap-[50px] flex items-center py-[1.9rem] px-[1.5rem] mb-4'>
                            <div className='flex items-center '>
                                <div className='flex custom-piped view'>
                                    <div className='rounded-[50%] w-[3rem] h-[3rem] bg-appcolorprimaryfaintthree flex items-center justify-center'>
                                        <span className='font-bold text-appcolorprimary text-[16px] leading-[22px] opacity-[0.5]'>
                                            {capitalizeTextChar(
                                                data?.businessName?.split(
                                                    ' '
                                                )[0] || 'A'
                                            )}
                                            {capitalizeTextChar(
                                                data?.businessName?.split(
                                                    ' '
                                                )[1] || 'B'
                                            )}
                                        </span>
                                    </div>

                                    <div className='pl-[0.9rem] mr-5'>
                                        <p className='pb-[0.3rem] text-appcolorblacktwo opacity-[0.6]'>
                                            Booking Number
                                        </p>
                                        <p className='font-bold'>
                                            {data?.bookingReference || '--'}
                                        </p>
                                    </div>
                                </div>

                                {summaryData?.map((item) => (
                                    <div
                                        key={item.label}
                                        className='custom-piped view'
                                    >
                                        <div className='pl-[0.9rem] mr-5'>
                                            <p className='pb-[0.3rem] text-appcolorblacktwo opacity-[0.6]'>
                                                {item.label}
                                            </p>
                                            <p className='font-bold'>
                                                {item.value}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <Custombutton
                                buttonText='Track Shipment'
                                styles={{
                                    padding: '0.5rem',
                                    fontSize: '15px',
                                    lineHeight: '19px',
                                    letterSpacing: '0.01rem',
                                    color: '#0088B2',
                                    backgroundColor: '#ffffff',
                                    border: '1px solid #0088B2',

                                    '&:hover': {
                                        backgroundColor: '#0088B2',
                                        color: '#ffffff',
                                        border: '1px solid #ffffff'
                                    }
                                }}
                                onClickAction={() =>
                                    navigate(
                                        `/bookings/track-shipments/${data?.bookingReference}`
                                    )
                                }
                            />{' '}
                        </div>
                    )}
                </div>
                <div className='bg-white flex flex-col justify-center items-center px-4 pt-6 pb-8 lg:hidden'>
                    <StatusIndicator status={getBookingStatus(data?.status)} />
                    <p className='text-paleSky text-sm py-4'>
                        Booking no:{' '}
                        <span className='font-bold'>
                            {data?.bookingReference}
                        </span>
                    </p>
                    <SuitUIButton
                        text='Track shipment'
                        onClick={() =>
                            navigate(
                                `/bookings/track-shipments/${data?.bookingReference}`
                            )
                        }
                        fullWidth
                    />
                </div>

                <div className='lg:hidden px-4 py-8'>
                    <h5 className='text-base text-ebony font-medium border-b border-appcolorgreyfaintnine'>
                        Booking details
                    </h5>
                    {summaryData?.map((item) => (
                        <div
                            key={item.label}
                            className='flex justify-between align-center text-sm text-ebony border-b border-appcolorgreyfaintnine pt-[24px]'
                        >
                            <p>{item.label}</p>
                            <p className='font-medium'>{item.value}</p>
                        </div>
                    ))}
                </div>

                <div className='bg-white lg:bg-inherit py-6 lg:py-0 mt-8'>
                    <div className='relative'>
                        <div className='px-4 lg:px-0 hidden lg:flex items-center justify-between'>
                            <TabContainerNew className='gap-[5px]'>
                                <div
                                    className={`tab-buttons`}
                                    onClick={() => {
                                        setShowContainers(!showContainers);
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        background: showContainers
                                            ? '#0088b21a'
                                            : 'unset',

                                        color: showContainers
                                            ? '#0088b2'
                                            : 'initial',

                                        fontWeight: showContainers
                                            ? '500'
                                            : '400'
                                    }}
                                >
                                    <p
                                        style={{
                                            paddingRight: '5px',
                                            display: 'flex'
                                        }}
                                    >
                                        Containers ({containers.length})
                                    </p>
                                </div>
                                <Divider orientation='vertical' flexItem />

                                <div
                                    className={`tab-buttons`}
                                    onClick={() => {
                                        setShowContainers(!showContainers);
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        background: !showContainers
                                            ? '#0088b21a'
                                            : 'unset',

                                        color: !showContainers
                                            ? '#0088b2'
                                            : 'initial',

                                        fontWeight: !showContainers
                                            ? '500'
                                            : '400'
                                    }}
                                >
                                    <p
                                        style={{
                                            paddingRight: '5px',
                                            display: 'flex'
                                        }}
                                    >
                                        Associated Documents ({documents.length}
                                        )
                                    </p>
                                </div>
                            </TabContainerNew>
                        </div>
                        <div className='flex items-center gap-[24px] mb-[16px] px-4 lg:hidden'>
                            <SuitUIButton
                                text={`Containers (${containers.length}
                                                )`}
                                onClick={() =>
                                    setShowContainers(!showContainers)
                                }
                                type='button'
                                variant={
                                    showContainers ? 'primary' : 'secondary'
                                }
                            />

                            <SuitUIButton
                                text={`Associated Documents (${documents.length}
                                                    )`}
                                onClick={() =>
                                    setShowContainers(!showContainers)
                                }
                                type='button'
                                variant={
                                    !showContainers ? 'primary' : 'secondary'
                                }
                            />
                        </div>
                    </div>

                    <AssociatedDocumentTable
                        {...{
                            setCurrentData,
                            items,
                            showContainers,
                            loading,
                            containers,
                            documents,
                            docItems,
                            setCurrentDocData,
                            setVisibleDocument,
                            visibleDocument,
                            currentDocData
                        }}
                    />
                </div>
            </div>
        </BookingContainer>
    );
};

export default Viewbookings;
