import React, { useState, useEffect, ReactNode, ReactElement } from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { SuitUIButton } from '../SuitUIButton';
import MobilePagination from '../MobilePagination';

export interface tableTileProps {
    title: string;
    isNotTable?: boolean;
    noDataText?: () => ReactNode;
    columns?: ColumnsType<any>;
    rowKey?: string;
    actionText?: () => ReactNode;
    data?: { [key: string]: string }[];
    getData?: () => void;
}

export interface customtableProps {
    noFilter?: boolean;
    multiTableTiles?: tableTileProps[];
    noPaddingHorizontal?: boolean;
    data: any;
    loadingData?: boolean;
    isDashboardHomeTable?: boolean;
    updateCurrentData?: (value: any) => void;
    mobileComponent?: ReactElement<any>;
    viewMoreText?: string;
    viewMoreAction?: () => void;
    onMobileRowClick?: (data: { [key: string]: string }) => void;
    emptyText?: string;
    customMobilePagination?: ReactElement<any>;
}

const Customtable = ({
    noFilter,
    multiTableTiles,
    noPaddingHorizontal,
    data,
    loadingData,
    isDashboardHomeTable,
    updateCurrentData,
    mobileComponent,
    viewMoreText,
    viewMoreAction,
    onMobileRowClick = () => {},
    emptyText,
    customMobilePagination
}: customtableProps) => {
    const [currentTableIndex, setCurrentTableIndex] = useState(0);
    const [page, setPage] = useState(1);

    const [tableTiles, setTableTiles] = useState<tableTileProps[]>(
        multiTableTiles || []
    );

    const swithTileIndex = (index: number) => {
        const newTiles = [...tableTiles];
        if (index !== 0) {
            const itemToMove = newTiles[index];
            newTiles.splice(index, 1);
            newTiles.unshift(itemToMove);
            setTableTiles(newTiles);
        }
    };

    useEffect(() => {
        if (tableTiles[currentTableIndex]?.getData !== undefined) {
            tableTiles[currentTableIndex]?.getData?.();
        }
    }, [tableTiles[currentTableIndex]?.title]);

    const dataToView = loadingData
        ? []
        : isDashboardHomeTable
        ? data?.slice(0, 5)
        : data || [];

    return (
        <>
            <div className='relative mb-12 hidden lg:block'>
                <div className='flex items-center justify-between'>
                    <div className='flex'>
                        {tableTiles.map((item, index) => (
                            <div
                                className={`${
                                    index === currentTableIndex
                                        ? 'bg-appcolorwhite trapezoid'
                                        : ''
                                } pt-[0.8rem] pb-[1rem] pl-[1.6rem] w-fit pr-[5.6rem] cursor-pointer`}
                                key={item.title}
                                onClick={() => {
                                    swithTileIndex(index);
                                }}
                            >
                                {item.title}
                            </div>
                        ))}
                    </div>
                </div>

                <div
                    className={`bg-appcolorwhite py-4 mt-[-0.5rem] relative z-[2] ${
                        noPaddingHorizontal ? '' : 'px-[1.6rem]'
                    }`}
                >
                    {!noFilter && (
                        <div className='flex items-center justify-between text-[15px] leading-[19px]  border-b border-appcolorgreyfainttwo pb-3'>
                            <div className='flex items-center'></div>

                            {
                                <div className='flex items-center'>
                                    {tableTiles[currentTableIndex]
                                        ?.actionText !== undefined &&
                                        tableTiles[
                                            currentTableIndex
                                        ]?.actionText?.() !== null && (
                                            <div className='mr-4'>
                                                {tableTiles[
                                                    currentTableIndex
                                                ]?.actionText?.()}
                                            </div>
                                        )}

                                    {!isDashboardHomeTable && (
                                        <>
                                            <div className='flex items-center mr-[1.2rem]'>
                                                <div className='pr-2'>
                                                    <img
                                                        src='/img/vectors/filter.svg'
                                                        alt=''
                                                    />
                                                </div>
                                                <span className='text-appcolorfaintfour'>
                                                    Filter
                                                </span>
                                            </div>

                                            <div
                                                className='flex items-center mr-[1.2rem] cursor-pointer'
                                                onClick={() => {
                                                    if (
                                                        tableTiles[
                                                            currentTableIndex
                                                        ].getData !== undefined
                                                    ) {
                                                        tableTiles[
                                                            currentTableIndex
                                                        ].getData?.();
                                                    }
                                                }}
                                            >
                                                <div className='pr-2'>
                                                    <img
                                                        src='/img/vectors/refreshtable.svg'
                                                        alt=''
                                                    />
                                                </div>
                                                <span className='text-appcolorfaintfour'>
                                                    Refresh
                                                </span>
                                            </div>

                                            <div className='flex items-center'>
                                                <div className='pr-2'>
                                                    <img
                                                        src='/img/vectors/filter2.svg'
                                                        alt=''
                                                    />
                                                </div>
                                                <span className='text-appcolorfaintfour'>
                                                    Sort
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            }
                        </div>
                    )}

                    <div className='mt-1'>
                        {tableTiles[currentTableIndex]?.isNotTable !==
                        undefined ? (
                            <>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit. Possimus eligendi asperiores
                                facere quisquam molestiae unde repudiandae dolor
                                soluta illum ab voluptatem iste ad quae,
                                officiis consequatur obcaecati accusamus tenetur
                                earum?
                            </>
                        ) : (
                            <Table
                                onRow={(record: any, rowIndex) => {
                                    return {
                                        onClick: (event) => {
                                            if (
                                                updateCurrentData !== undefined
                                            ) {
                                                updateCurrentData(record);
                                            }
                                        }
                                    };
                                }}
                                pagination={
                                    isDashboardHomeTable ? false : undefined
                                }
                                loading={loadingData}
                                columns={tableTiles[currentTableIndex]?.columns}
                                dataSource={dataToView}
                                rowKey={tableTiles[currentTableIndex]?.rowKey}
                                locale={{
                                    emptyText: () => {
                                        return (
                                            <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                                                <div className='flex justify-center mb-4'>
                                                    <img
                                                        src='/img/vectors/nodatacross.svg'
                                                        alt='nodata'
                                                    />
                                                </div>

                                                {tableTiles[currentTableIndex]
                                                    ?.noDataText !==
                                                    undefined &&
                                                tableTiles[
                                                    currentTableIndex
                                                ]?.noDataText?.() !== null ? (
                                                    <>
                                                        {tableTiles[
                                                            currentTableIndex
                                                        ].noDataText?.()}
                                                    </>
                                                ) : (
                                                    <>
                                                        <p className='text-center'>
                                                            No data found
                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                        );
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
            {mobileComponent && (
                <div className='lg:hidden min-h-[50vh]'>
                    {!dataToView?.length ? (
                        <div className='flex flex-col justify-center mt-24 mb-24 align-center '>
                            <div className='flex justify-center mb-4'>
                                <img
                                    src='/img/vectors/nodatacross.svg'
                                    alt='nodata'
                                />
                            </div>

                            <p className='text-center text-appcolorblackthree'>
                                {emptyText ||
                                    'There are no items on this list.'}
                            </p>
                        </div>
                    ) : (
                        dataToView
                            .slice(10 * (page - 1), 10 * page)
                            ?.map((item: { [key: string]: string }) => (
                                <>
                                    {React.cloneElement(mobileComponent, {
                                        key: item.title,
                                        item,
                                        onClick: () => onMobileRowClick(item)
                                    })}
                                </>
                            ))
                    )}

                    {viewMoreAction && (
                        <div className='bg-white flex items-center justify-center pt-[24px] pb-[60px]'>
                            <SuitUIButton
                                variant='secondary'
                                text={viewMoreText || ''}
                                onClick={viewMoreAction}
                            />
                        </div>
                    )}

                    {customMobilePagination ? (
                        customMobilePagination
                    ) : !viewMoreAction && dataToView.length ? (
                        <MobilePagination
                            page={page}
                            setPage={setPage}
                            totalPages={Math.ceil(dataToView.length / 10)}
                        />
                    ) : null}
                </div>
            )}
        </>
    );
};

export default Customtable;
