import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { AccountTypesTabs } from './AccountTypesTabs';
import { SearchInput } from '../../../components/SearchInput';
import { ChangeEvent, useState } from 'react';
import { CurrencySelection } from './interfaces/Currency';
import useCurrencies from '../../../customhooks/useCurrencies';
import { mutateConfig } from '../../../api/queryBase';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../utils/helper';

const CurrencyAdministration = () => {
    const [search, setSearch] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selection, setSelection] = useState<CurrencySelection>({
        label: '',
        currencies: []
    });
    const { currencies } = useCurrencies();
    const mutation = mutateConfig({ method: 'put' });
    const { mutate: mutatePool } = mutation({
        url: `/currencies/pool/toggle`,
        cacheKeys: 'currencies-pool'
    });
    const { mutate: mutateOffshore } = mutation({
        url: `/currencies/offShore/toggle`,
        cacheKeys: 'currencies-offshore'
    });

    const mutateSelection = {
        pool: mutatePool,
        dedicated: mutateOffshore
    };
    const onHandleToggleClick = ({
        currencyCode,
        checked
    }: {
        currencyCode: string;
        checked: boolean;
    }) => {
        mutateSelection[selection.label as 'pool' | 'dedicated'](
            {
                currencyCode,
                status: checked ? 'ENABLED' : 'DISABLED'
            },
            {
                onSettled: (_, error) => {
                    if (error) {
                        toast.error(
                            getErrorMessage(error) ||
                                'Currency update failed. Please try again'
                        );
                        return;
                    }

                    toast.success('Currency update successful');
                }
            }
        );
    };
    const onHandleSearchEntry = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setSearch(e.target.value);
    };

    return (
        <>
            <Box className='text-[24px] font-semibold mb-6 text-appcolorblacktwo'>
                Currency Administration
            </Box>
            <AccountTypesTabs
                setIsLoading={setIsLoading}
                setSelection={setSelection}
            />
            <SearchInput onHandleChange={onHandleSearchEntry} />
            <Box className='bg-white px-10 pt-5 pb-20 rounded-md'>
                <Box className='text-[18px] font-semibold border-b-2 pb-5'>
                    Allowed Currencies
                </Box>
                {!isLoading && (
                    <FormGroup>
                        <Box className='grid grid-cols-6 gap-2 border-b-2 pb-5 mt-5'>
                            {(search
                                ? currencies.filter(({ currencyCode }) =>
                                      currencyCode
                                          .toLowerCase()
                                          .includes(search.toLowerCase())
                                  )
                                : currencies
                            ).map(({ currencyCode }) => (
                                <FormControlLabel
                                    key={currencyCode}
                                    control={
                                        <Checkbox
                                            checked={(
                                                selection.currencies || []
                                            ).some(
                                                ({ currencyCode: ccCode }) =>
                                                    ccCode === currencyCode
                                            )}
                                            onChange={(e) =>
                                                onHandleToggleClick({
                                                    currencyCode,
                                                    checked: e.target.checked
                                                })
                                            }
                                        />
                                    }
                                    label={currencyCode}
                                />
                            ))}
                        </Box>
                    </FormGroup>
                )}
            </Box>
        </>
    );
};

export default CurrencyAdministration;
