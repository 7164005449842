import styled from "styled-components";
import { scaleValue } from "../../../styles/TradeDocStyles";

export const BookingContainer = styled.div`
  width: 100%;
  min-width: ${scaleValue(1140)}px;
  overflow-x: hidden;
  .page-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 28px;
      font-weight: 500;
    }
  }
  .header-action {
    margin-top: 1rem;
    h1 {
      font-size: 28px;
      font-weight: 500;
    }
  }
  .form-header {
    .breadcrumbs {
      display: flex;
      gap: 8px;
    }
    .breadcrumb-text {
      font-weight: 400;
      font-size: 12px; 
      line-height: 150%;
      color: #7d8398;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
    .active {
      color: #121212;
    }
  }
  .buttons {
    justify-content: center;
    gap: 20px;
    .cancel {
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      border: 0;
      background: var(--white, #fff) !important;
      color: #0088b2 !important;
    }
    button {
      width: 100px;
      border-radius: 5px;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      border: 1px solid var(--green-1, #0088b2);
      background: var(--green-1, #0088b2);
      color: white;
    }
  }
`;

export const DetailsContainer = styled.div`
  border: 1px solid var(--gray-3, #828282);
  background: #fff;
  width: 100%;
  padding: 56px;
  font-size: 13px;
  font-family: "Outfit";
  line-height: 14px;
  letter-spacing: 0.5px;
  .details-content {
    border-top: 4px solid #211313;
    margin-bottom: 2rem;
    h3 {
      padding: 8px 0;
      border-bottom: 1px solid #211313;
    }
  }
  .flex-dets {
    width: 100%;
    justify-content: space-between;
    padding: 8px 0;
    p {
      width: 50%;

      :first-of-type {
      }
    }
  }
  table {
    thead {
      th {
        padding: 7px 7px !important;
        font-size: 12px;
        font-weight: 400 !important;
        border-bottom: 1px solid #211313 !important;

        ::before {
          content: none !important;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        :hover {
          background: #0088b21a !important;
        }
        td {
          padding: 7px !important;
          border-bottom: 0 !important;
          font-size: 12px;
          background-color: unset !important;
          font-weight: 700 !important;
        }
      }
    }
  }
`;
