export const CheckBoxCheckedIcon = () => (
    <svg
        width='20'
        height='21'
        viewBox='0 0 20 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect
            x='0.5'
            y='0.54248'
            width='19'
            height='19'
            rx='3.5'
            fill='#0088B2'
            fillOpacity='0.05'
        />
        <rect
            x='0.5'
            y='0.54248'
            width='19'
            height='19'
            rx='3.5'
            stroke='#0088B2'
        />
        <path
            d='M15 6.29248L8.125 13.1675L5 10.0425'
            stroke='#0088B2'
            strokeWidth='1.6666'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
