export const UploadIcon = () => {
    return (
        <svg
            width='41'
            height='41'
            viewBox='0 0 41 41'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect x='2' y='2' width='37' height='37' rx='18.5' fill='#F2F4F7' />
            <g clipPath='url(#clip0_1619_17485)'>
                <path
                    d='M23 23.1667L20.5 20.5M20.5 20.5L18 23.1667M20.5 20.5V26.5M25.7437 24.76C26.3533 24.4055 26.8349 23.8446 27.1124 23.1658C27.3899 22.4869 27.4476 21.7288 27.2764 21.0111C27.1051 20.2934 26.7147 19.657 26.1666 19.2023C25.6186 18.7476 24.9443 18.5005 24.25 18.5H23.4625C23.2733 17.7195 22.9207 16.9949 22.4312 16.3807C21.9417 15.7664 21.328 15.2786 20.6363 14.9537C19.9446 14.6289 19.1928 14.4756 18.4375 14.5053C17.6823 14.5349 16.9431 14.7469 16.2757 15.1251C15.6083 15.5034 15.0299 16.0381 14.5841 16.6891C14.1383 17.34 13.8367 18.0904 13.7018 18.8836C13.567 19.6768 13.6025 20.4924 13.8057 21.2688C14.0089 22.0453 14.3745 22.7626 14.875 23.3667'
                    stroke='#0088B2'
                    strokeWidth='1.66667'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <rect
                x='2'
                y='2'
                width='37'
                height='37'
                rx='18.5'
                stroke='#F9FAFB'
                strokeWidth='3'
            />
            <defs>
                <clipPath id='clip0_1619_17485'>
                    <rect
                        width='15'
                        height='16'
                        fill='white'
                        transform='translate(13 12.5)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
