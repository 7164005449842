import React, { Dispatch, SetStateAction } from 'react';
import {
    DocumentTableContainer,
    TabContainerNew
} from '../../../../styles/TradeDocStyles';
import { Divider, Stack } from '@mui/material';
import { filter } from './FilterContract';
import ContractITemsTable from './ContractITemsTable';
import ContractBookingsTable from './ContractBookingsTable';
import { SuitUIButton } from '../../../SuitUIButton';
import { SalesContracts } from '../../../../pages/dashboard/payments/interfaces/SalesContracts';
import { Booking } from '../../../../pages/dashboard/bookings/interfaces/Booking';

interface IProps {
    setActiveView: Dispatch<SetStateAction<string>>;
    activeView?: string;
    data?: SalesContracts;
    loadingData?: boolean;
    bookingData?: { content: Booking[] };
}

const ContractDetailsTable = ({
    setActiveView,
    activeView,
    data,
    loadingData,
    bookingData
}: IProps) => {
    return (
        <>
            <div className='mt-[16px] mb-[10px] lg:block hidden'>
                <TabContainerNew>
                    <Stack direction='row' spacing={'5px'}>
                        <div
                            className='tab-buttons'
                            style={{
                                cursor: 'pointer',
                                background:
                                    activeView === 'Contract Line Items'
                                        ? '#0088b21a'
                                        : 'unset',

                                color:
                                    activeView === 'Contract Line Items'
                                        ? '#0088b2'
                                        : 'initial',

                                fontWeight:
                                    activeView === 'Contract Line Items'
                                        ? '500'
                                        : '400'
                            }}
                            onClick={() => setActiveView('Contract Line Items')}
                        >
                            {activeView === 'Contract Line Items'
                                ? active
                                : filter}
                            <p
                                style={{
                                    paddingLeft: '5px'
                                }}
                            >
                                Contract Line Items
                            </p>
                        </div>

                        <Divider orientation='vertical' flexItem />
                        <div
                            className='tab-buttons'
                            style={{
                                cursor: 'pointer',
                                background:
                                    activeView === 'Bookings'
                                        ? '#0088b21a'
                                        : 'unset',

                                color:
                                    activeView === 'Bookings'
                                        ? '#0088b2'
                                        : 'initial',

                                fontWeight:
                                    activeView === 'Bookings' ? '500' : '400'
                            }}
                            onClick={() => setActiveView('Bookings')}
                        >
                            {activeView === 'Bookings' ? active : filter}

                            <p style={{ paddingLeft: '5px' }}>Bookings</p>
                        </div>
                    </Stack>
                </TabContainerNew>
            </div>
            <div className='flex items-center gap-[24px] my-[16px] px-4 lg:hidden'>
                <SuitUIButton
                    text={`Contract Line Items`}
                    onClick={() => setActiveView('Contract Line Items')}
                    type='button'
                    variant={
                        activeView === 'Contract Line Items'
                            ? 'primary'
                            : 'secondary'
                    }
                />

                <SuitUIButton
                    text={`Bookings`}
                    onClick={() => setActiveView('Bookings')}
                    type='button'
                    variant={
                        activeView === 'Bookings' ? 'primary' : 'secondary'
                    }
                />
            </div>
            <DocumentTableContainer className='bg-appcolorwhite'>
                {activeView === 'Contract Line Items' && (
                    <ContractITemsTable {...{ data, loadingData }} />
                )}
                {activeView === 'Bookings' && (
                    <ContractBookingsTable
                        {...{ loadingData }}
                        data={bookingData}
                    />
                )}
            </DocumentTableContainer>
        </>
    );
};

export default ContractDetailsTable;
export const active = (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M11 7.33333V14.6667M7.33333 11H14.6667M4.58333 2.75H17.4167C18.4292 2.75 19.25 3.57081 19.25 4.58333V17.4167C19.25 18.4292 18.4292 19.25 17.4167 19.25H4.58333C3.57081 19.25 2.75 18.4292 2.75 17.4167V4.58333C2.75 3.57081 3.57081 2.75 4.58333 2.75Z'
            stroke='#0088b2'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
