import { CircularProgress, Grid, MenuItem, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { Country } from '../../../../interfaces/Country';

interface SelectorInterface {
    error: boolean;
    id: string;
    value: string | number;
    label: string;
    name: string;
    changeFunction: (e: ChangeEvent<HTMLInputElement>) => void;
    countries: Country[];
    isCountriesLoading: boolean;
    helperText: string | undefined;
}

const CountrySelector = ({
    error,
    id,
    value,
    label,
    name,
    changeFunction,
    countries,
    isCountriesLoading,
    helperText
}: SelectorInterface) => {
    return (
        <Grid item xs={12} md={12} lg={6}>
            <TextField
                select
                error={error}
                margin='normal'
                helperText={helperText}
                required
                fullWidth
                id={id}
                label={label}
                name={name}
                value={value}
                onChange={changeFunction}
            >
                {isCountriesLoading ? (
                    <MenuItem>
                        <CircularProgress size={20} />
                    </MenuItem>
                ) : (
                    countries.map((item: Country) => (
                        <MenuItem
                            value={item.id || ''}
                            key={`countries-final-${item.id}`}
                        >
                            <div className='flex items-center'>
                                <img
                                    src={item?.flagImageUrl}
                                    alt={`${item.name}-flag`}
                                    className='w-4 h-4'
                                />
                                <span className='ml-2'>{item.name}</span>
                            </div>
                        </MenuItem>
                    ))
                )}
            </TextField>
        </Grid>
    );
};

export default CountrySelector;
