import { useReactToPrint } from 'react-to-print';
import React, { useRef } from 'react';
import { Button } from '@mui/material';
import PaymentReceipt from './PaymentReceipt';
import { Transactions } from '../interfaces/Transactions';

const DownloadPaymentReceipt = ({ data }: { data: Transactions }) => {
    const myDiv = useRef<HTMLDivElement | null>(null);

    const handlePrint = useReactToPrint({
        content: () => myDiv.current
    });
    return (
        <div className='flex-1 flex lg:flex-none'>
            <Button
                variant='text'
                onClick={() => handlePrint()}
                className='download-button h-[40px] lg:h-[unset] flex-1 lg:flex-none'
            >
                Download
            </Button>
            <div className='fixed w-[100%] top-0 px-8 -z-50' ref={myDiv}>
                <PaymentReceipt {...{ data }} />
            </div>
        </div>
    );
};

export default DownloadPaymentReceipt;
