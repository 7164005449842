import React, { ChangeEvent } from 'react';
import Signature from '../../../../signature/signature';
import UploadStamp from '../../common/UploadStamp';
import { Grid, TextField } from '@mui/material';
import Customdatepicker from '../../../../customdatepicker/customdatepicker';
import { CommercialSignatureInterface } from '../../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/CommercialTypes';

const SignatureFields = ({
    payload,
    setPartnerId,
    setShowAddPartner,
    updateField,
    errors,
    setSignatureUrlBase64,
    signatureUrlBase64
}: CommercialSignatureInterface) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full relative'>
                    <div
                        className='absolute right-8 top-6 cursor-pointer text-appcolorprimary z-50'
                        onClick={
                            payload?.bankDetails
                                ? () => {
                                      setShowAddPartner(true);
                                      setPartnerId(payload?.exporterId);
                                  }
                                : undefined
                        }
                    >
                        Edit partner
                    </div>
                    <TextField
                        multiline
                        rows={3.8}
                        margin='normal'
                        fullWidth
                        disabled
                        id='commercialinvoice-bankDetails'
                        value={payload?.bankDetails || ''}
                        label='Banks Details'
                        name='bankDetails'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            updateField('bankDetails', e.target.value);
                        }}
                    />

                    <TextField
                        multiline
                        rows={3.8}
                        margin='normal'
                        value={payload?.additionalInformation || ''}
                        fullWidth
                        id='commercialinvoice-additionalInformation'
                        label='Additional Information'
                        name='additionalInformation'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            updateField(
                                'additionalInformation',
                                e.target.value
                            );
                        }}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: '0px'
                        }}
                    >
                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                error={errors?.placeOfIssue.length > 0}
                                helperText={
                                    errors?.placeOfIssue.length > 0
                                        ? errors?.placeOfIssue
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='commercialinvoice-placeOfIssue'
                                label='Place of Issue'
                                name='placeOfIssue'
                                value={payload?.placeOfIssue || ''}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField('placeOfIssue', e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <div className='mt-4'>
                                <Customdatepicker
                                    value={ payload?.dueDate || null }
                                    id='proformainvoice-date'
                                    label='Due Date'
                                    name='dueDate'
                                    updateField={updateField}
                                    error={errors?.dueDate.length > 0}
                                    helperText={
                                        errors?.dueDate.length > 0
                                            ? errors?.dueDate
                                            : undefined
                                    }
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                error={errors?.signatoryCompany.length > 0}
                                helperText={
                                    errors?.signatoryCompany.length > 0
                                        ? errors?.signatoryCompany
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                value={payload?.signatoryCompany || ''}
                                id='commercialinvoice-signatoryCompany'
                                label='Signatory Company'
                                name='signatoryCompany'
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'signatoryCompany',
                                        e.target.value
                                    );
                                }}
                            />

                            <TextField
                                error={
                                    errors?.nameOfAuthorisedSignatory.length > 0
                                }
                                helperText={
                                    errors?.nameOfAuthorisedSignatory.length > 0
                                        ? errors?.nameOfAuthorisedSignatory
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                value={payload?.nameOfAuthorisedSignatory || ''}
                                id='commercialinvoice-nameOfAuthorisedSignatory'
                                label='Name of Authorized Signature'
                                name='nameOfAuthorisedSignatory'
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'nameOfAuthorisedSignatory',
                                        e.target.value
                                    );
                                }}
                            />

                            <div className='flex gap-2 items-center mt-3'>
                                <UploadStamp
                                    {...{
                                        payload,
                                        updateField
                                    }}
                                    name="Exporter's Stamp Image"
                                />
                            </div>
                            <div className='w-full'>
                                <Signature
                                    name='signatureUrl'
                                    updateField={updateField}
                                    setSignatureUrlBase64={
                                        setSignatureUrlBase64
                                    }
                                    signatureUrlBase64={signatureUrlBase64}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export default SignatureFields;
