import { Stack } from '@mui/material';
import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { formatCurrency, formatNumber } from '../../../../utils/helper';
import { REACT_APP_BASE_URL } from '../../../../config';
import DocumentViewer from '../../../DocumentViewer/DocumentViewer';
interface IProps {
    data: any;
}
const CommercialView = ({ data }: IProps) => {
    const getTotal = () => {
        let totalNumber = 0;

        data?.items?.forEach((item: any) => {
            totalNumber +=
                (isNaN(item.unitPrice) ? 0 : Number(item.unitPrice)) *
                (isNaN(item.quantity) ? 0 : Number(item.quantity));
        });
        return totalNumber;
    };

    return (
        <>
            <Stack
                direction='row'
                alignItems='start'
                className='top-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='font-semibold text-appcolorprimary'>
                        Exporter
                    </p>
                    <p className='font-medium'>
                        {data?.exporter?.name || '--'}
                    </p>
                    <p>{data?.exporterAddress}</p>
                </div>
                <div className='view-two'>
                    <p className='font-semibold text-appcolorprimary'>
                        Consignee
                    </p>
                    <p className='font-medium'>{data?.consignee?.name}</p>
                    <p>{data?.consigneeAddress}</p>
                </div>

                <div className='view-one'>
                    <p className='font-semibold text-appcolorprimary'>Buyer</p>
                    {data.buyer?.name && (
                        <p className='font-medium'>
                            {data.buyer?.name || '--'}
                        </p>
                    )}
                    {data.buyerAddress && <p>{data.buyerAddress || '--'}</p>}
                    {data.buyerReference && (
                        <Stack direction='row' gap='10px' width='100%'>
                            <p className=' text-appcolorblackthree opacity-70'>
                                Buyer Ref:{' '}
                            </p>
                            <p className=''>{data.buyerReference || '--'}</p>
                        </Stack>
                    )}
                </div>
                <div className='view-three'>
                    <div></div>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Invoice No.</p>
                        <p className='value'>{data?.invoiceNumber || '--'}</p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Date</p>
                        <p className='value'>
                            {moment(data.invoiceDate).format('ll') || '--'}
                        </p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Reference</p>
                        <p className='value'>{data.reference || '--'}</p>
                    </Stack>
                </div>
            </Stack>
            <Stack
                direction='row'
                alignItems='start'
                className='mid-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='title'>Method of Dispatch</p>
                    <p className='value'>{data.methodOfDispatch}</p>

                    <p className='title'>Type of Shipment</p>
                    <p className='value'>{data.typeOfShipment}</p>

                    <p className='title'>Country Of Origin of Goods</p>
                    <p className='value'>{data.countryOfOrigin?.name}</p>
                </div>

                <div className='view-one'>
                    <p className='title'>Transport</p>
                    <p className='value'>{data.transport}</p>

                    <p className='title'>Voyage Number</p>
                    <p className='value'>{data.voyageNumber}</p>

                    <p className='title'>Final Destination</p>
                    <p className='value'>{data.countryOfOrigin?.name}</p>

                    <p className='title'>Country of Final Destination</p>
                    <p className='value'>
                        {data.countryOfFinalDestination?.name}
                    </p>
                </div>

                <div className='view-one'>
                    <p className='title'>Port of Loading</p>
                    <p className='value'>{data.portOfLoading?.name}</p>

                    <p className='title'>Method of Discharge</p>
                    <p className='value'>{data.portOfDischarge?.name}</p>

                    <p className='title'>Method of Dispatch</p>
                    <p className='value'>{data.methodOfDispatch}</p>

                    <p className='title'>Place of Origin</p>
                    <p className='value'>{data.placeOfOrigin}</p>
                </div>

                <div className='view-one'>
                    <p className='title'>Date of Departure</p>
                    <p className='value'>
                        {moment(data?.dateOfDeparture).format('ll')}
                    </p>

                    <p className='title'>Terms/Method of Payment</p>
                    <p className='value'>{data.termsOfPayment}</p>

                    <p className='title'>Marine Cover Policy No</p>
                    <p className='value'>{data.marineCoverPolicyNo}</p>

                    <p className='title'>Letter Of Credit No</p>
                    <p className='value'>{data.letterOfCreditNo}</p>
                </div>
            </Stack>
            <div className='table-view'>
                <Table
                    rowKey={'id'}
                    columns={[
                        {
                            title: 'Product Code',
                            dataIndex: 'id',
                            key: 'id'
                        },
                        {
                            title: 'Description',
                            dataIndex: 'descriptionOfGoods',
                            key: 'descriptionOfGoods'
                        },
                        {
                            title: 'Unit Qty.',
                            dataIndex: 'quantity',
                            key: 'quantity',
                            render: (text) => <span>{formatNumber(text)}</span>
                        },
                        {
                            title: 'Unit Type',
                            dataIndex: 'unitType',
                            key: 'unitType'
                        },
                        {
                            title: 'Unit Price',
                            dataIndex: 'unitPrice',
                            key: 'unitPrice',
                            render: (text) => (
                                <span>
                                    {formatCurrency(text, data?.currencyCode)}
                                </span>
                            )
                        },
                        {
                            title: 'Total',
                            dataIndex: '',
                            key: '',
                            render: (text, record: any) => (
                                <span>
                                    {formatCurrency(
                                        (isNaN(record.unitPrice)
                                            ? 0
                                            : record.unitPrice) *
                                            (isNaN(record.quantity)
                                                ? 0
                                                : record.quantity),
                                        data?.currencyCode
                                    )}
                                </span>
                            )
                        }
                    ]}
                    dataSource={data?.items}
                    pagination={false}
                />
            </div>
            <Stack direction='row' justifyContent='flex-end'>
                <div className='preview-total'>
                    Total amount :{' '}
                    <span className=''>
                        {formatCurrency(getTotal(), data?.currencyCode)}
                    </span>
                </div>
            </Stack>
            <Stack
                direction='row'
                alignItems='start'
                className='bottom-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='titl'>Bank Details</p>
                    <p>{data?.bankDetails || '--'}</p>

                    <p className='titl'>Additional Information</p>
                    <p>{data?.additionalInformation}</p>
                </div>

                <div className='view-two'>
                    <p className='titl'>Place of Issue</p>
                    <p className=''>{data?.placeOfIssue || '--'}</p>
                    <p className='titl'>Signatory Company</p>
                    <p>{data?.signatoryCompany || '--'}</p>
                    <p className='titl'>Exporter's Stamp</p>

                    {data?.stampUrl && (
                        <div className='h-[50px] w-[100px] img'>
                            <DocumentViewer
                                fileUrl={`${REACT_APP_BASE_URL}/${data?.stampUrl?.split(
                                    '/v1/'
                                )[1]}`}
                            />
                        </div>
                    )}
                </div>

                <div className='view-three'>
                    <p className='titl'>Due Date</p>
                    <p className=''>
                        {moment(data.dueDate).format('ll') || '--'}
                    </p>
                    <p className='titl'>Name of Authorized Signatory</p>
                    <p>{data?.nameOfAuthorisedSignatory || '--'}</p>
                    <p className='titl'>Signature</p>
                    <div className='h-[50px] w-[100px] img'>
                        <DocumentViewer
                            fileUrl={`${REACT_APP_BASE_URL}/${data?.signatureUrl?.split(
                                '/v1/'
                            )[1]}`}
                        />
                    </div>
                </div>
            </Stack>
        </>
    );
};

export default CommercialView;
