import { formatCurrency, formatNumber } from "../../../../../utils/helper";

interface ColumnObject {
  title: string;
  dataIndex: string;
  key: string;
  render?: (text: any, record: any) => JSX.Element;
}

interface TableData {
  key: string;
  containerNumber?: string;
  containerTare?: number;
  cargoWeight?: number;
  grossWeight?: number;
  productCode?: string;
  descriptionOfGoods?: string;
  quantity?: any;
  unitType?: string;
  unitPrice?: any;
}

const dratf_certificate_columns: ColumnObject[] = [
  {
    title: "Container No",
    dataIndex: "containerNumber",
    key: "containerNumber",
  },
  {
    title: "Container Tare (Kg)",
    dataIndex: "containerTare",
    key: "containerTare",
    render: (text: number) => <span>{formatNumber(text)} Kg</span>,
  },
  {
    title: "Cargo Weight (KG)",
    dataIndex: "cargoWeight",
    key: "cargoWeight",
    render: (text: number) => <span>{formatNumber(text)} Kg</span>,
  },
  {
    title: "Gross Weight (KG)",
    dataIndex: "grossWeight",
    key: "grossWeight",
    render: (text: number) => <span>{formatNumber(text)} Kg</span>,
  },
];

export const getColumns = (docType: string, currency: string) => {
  switch (docType) {
    case "DRAFT_GROSS_MASS_DECLARATION":
      return dratf_certificate_columns;
    case "DRAFT_BILL_OF_LADING":
      return [
        {
          title: "Product Code",
          dataIndex: "itemMarker",
          key: "itemMarker",
        },
        {
          title: "Description",
          dataIndex: "descriptionOfGoods",
          key: "descriptionOfGoods",
        },
        {
          title: "Package Kind",
          dataIndex: "kindAndNumber",
          key: "kindAndNumber",
        },
        {
          title: "Package Qty",
          dataIndex: "unitQuantity",
          key: "unitQuantity",
          render: (text: number) => <span>{formatNumber(text || 0)} Kg</span>,
        },
        {
          title: "Chargeable",
          dataIndex: "chargeable",
          key: "chargeable",
        },
      ];
    default:
      return [
        {
          title: "Product Code",
          dataIndex: "productCode",
          key: "productCode",
        },
        {
          title: "Description of goods",
          dataIndex: "descriptionOfGoods",
          key: "descriptionOfGoods",
        },
        {
          title: "Quantity",
          dataIndex: "quantity",
          key: "quantity",
          render: (text: number) => <span>{formatNumber(text)}</span>,
        },
        {
          title: "Unit type",
          dataIndex: "unitType",
          key: "unitType",
        },
        {
          title: "Price",
          dataIndex: "unitPrice",
          key: "unitPrice",
          render: (text: number) => (
            <span>{formatCurrency(text, currency)}</span>
          ),
        },
        {
          title: "Total",
          dataIndex: "",
          key: "",
          render: (text: number, record: TableData) => (
            <span>
              {formatCurrency(
                (isNaN(record?.unitPrice) ? 0 : record?.unitPrice) *
                  (isNaN(record?.quantity) ? 0 : record?.quantity),
                currency
              )}
            </span>
          ),
        },
      ];
  }
};
