export const RefreshCircleIcon = () => (
    <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M18.3334 10.0001C18.3334 14.6001 14.6001 18.3334 10.0001 18.3334C5.40008 18.3334 2.59175 13.7001 2.59175 13.7001M2.59175 13.7001H6.35842M2.59175 13.7001V17.8667M1.66675 10.0001C1.66675 5.40008 5.36675 1.66675 10.0001 1.66675C15.5584 1.66675 18.3334 6.30008 18.3334 6.30008M18.3334 6.30008V2.13341M18.3334 6.30008H14.6334'
            stroke='#344054'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
