import React, { FC } from 'react';
import { PreviewContent } from '../../../../styles/TradeDocStyles';
// import DocViewer from '@cyntler/react-doc-viewer';
import { REACT_APP_BASE_URL } from '../../../../config';
import { Stack } from '@mui/material';
import moment from 'moment';
import { Table } from 'antd';
import { formatNumber } from '../../../../utils/helper';
import DocumentViewer from '../../../DocumentViewer/DocumentViewer';

interface IProps {
    data: any;
    docTitle: string;
    quoteFields: any;
    totalAmount?: number;
    signatureUrlBase64: any;
}

const BillOfLadenPreview: FC<IProps> = ({
    data,
    docTitle,
    quoteFields,
    totalAmount,
    signatureUrlBase64
}: IProps) => {
    return (
        <PreviewContent>
            <div className='text-[14px]'>
                <div className='flex items-center justify-between pb-4 mb-4'>
                    <div>
                        {data?.logoUrl ? (
                            <div className='h-[50px] w-[100px]'>
                                <DocumentViewer
                                    fileUrl={`${REACT_APP_BASE_URL}/${data?.logoUrl.split(
                                        '/v1/'
                                    )[1]}`}
                                />
                            </div>
                        ) : (
                            <>
                                <div>
                                    <img
                                        src='/img/vectors/powered.svg'
                                        alt=''
                                        className='h-[2.5rem]'
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <div>
                        <h1 className='text-appcolorprimary text-[26px] leading-[27px] font-bold capitalize'>
                            {docTitle}
                        </h1>
                    </div>
                </div>

                <Stack
                    direction='row'
                    alignItems='start'
                    className='top-preview-content'
                    width='full'
                    justifyContent={'space-between'}
                >
                    <div className='view-one'>
                        <p className='font-semibold text-appcolorprimary'>
                            Consigner/Shipper{' '}
                        </p>
                        <p className='font-medium'>
                            {data?.shipperName || '--'}
                        </p>
                        <p>{data?.shipperAddress}</p>
                        <p>{data?.shipperCountry}</p>

                        <div className='mt-[15px] w-full'>
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Bank Name</p>
                                <p className='value'>
                                    {data?.shipperBank || '--'}
                                </p>
                            </Stack>
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Account No.</p>
                                <p className='value'>
                                    {data?.shipperAccountNumber || '--'}
                                </p>
                            </Stack>
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Swift Code</p>
                                <p className='value'>
                                    {data?.shipperSwiftCode || '--'}
                                </p>
                            </Stack>
                        </div>
                    </div>

                    <div className='view-two'>
                        <p className='font-semibold text-appcolorprimary'>
                            Consignee
                        </p>
                        <p className='font-medium'>
                            {data?.consigneeName || '--'}
                        </p>
                        <p>{data?.consigneeAddress}</p>
                        <p>{data?.consigneeCountry}</p>

                        <div className='mt-[15px] w-full'>
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Bank Name</p>
                                <p className='value'>
                                    {data?.consigneeBank || '--'}
                                </p>
                            </Stack>
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Account No.</p>
                                <p className='value'>
                                    {data?.consigneeAccountNumber || '--'}
                                </p>
                            </Stack>
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Swift Code</p>
                                <p className='value'>
                                    {data?.consigneeSwiftCode || '--'}
                                </p>
                            </Stack>
                        </div>
                    </div>

                    <div className='view-three'>
                        <div></div>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>MTD No.</p>
                            <p className='value'>{data?.mtdNumber || '--'}</p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Date</p>
                            <p className='value'>
                                {moment(data?.issuedDate).format('ll') || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Place of Receipt</p>
                            <p className='value'>
                                {data?.placeOfReceipt || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Shipment Ref No.</p>
                            <p className='value'>
                                {data?.shipmentReferenceNumber || '--'}
                            </p>
                        </Stack>
                    </div>
                </Stack>

                <Stack
                    direction='row'
                    alignItems='start'
                    className='mid-preview-content'
                    width='full'
                    justifyContent={'space-between'}
                >
                    <div className='view-one'>
                        <p className='title'>Notify Party</p>
                        <p className='value'>{data?.notifyPartyName || '--'}</p>

                        <p className='title'>Port of Loading</p>
                        <p className='value'>
                            {data?.portOfLoadingValue?.name || '--'}
                        </p>

                        <p className='title'>Vessel & Voyage</p>
                        <p className='value'>{data?.vesselAndVoyage || '--'}</p>

                        <p className='title'>Port of Departure</p>
                        <p className='value'>
                            {data?.portOfDischargeValue?.name}
                        </p>
                    </div>

                    <div className='view-one'>
                        <p className='title'>Place of Delivery</p>
                        <p className='value'>{data?.placeOfDelivery || '--'}</p>

                        <p className='title'>End of Delivery Date</p>
                        <p className='value'>
                            {' '}
                            {moment(data?.endDateOfDelivery).format('ll')}
                        </p>

                        <p className='title'>Transport</p>
                        <p className='value'>{data?.transport || '--'}</p>

                        <p className='title'>Route/ Place of Transhipment</p>
                        <p className='value'>
                            {data?.placeOfTranshipment || '--'}
                        </p>
                    </div>
                </Stack>

                <div className='table-view'>
                    <Table
                        rowKey={'id'}
                        columns={[
                            {
                                title: 'Product Code',
                                dataIndex: 'productCode',
                                key: 'productCode'
                            },
                            {
                                title: 'Description',
                                dataIndex: 'desc',
                                key: 'desc'
                            },
                            {
                                title: 'Package Kind',
                                dataIndex: 'kindOfPackage',
                                key: 'kindOfPackage'
                            },
                            {
                                title: 'Package Qty',
                                dataIndex: 'qtyOfPackage',
                                key: 'qtyOfPackage',
                                render: (text: number) => (
                                    <span>{formatNumber(text || 0)} Kg</span>
                                )
                            },
                            {
                                title: 'Chargeable',
                                dataIndex: 'chargeable',
                                key: 'chargeable'
                            }
                        ]}
                        dataSource={quoteFields}
                        pagination={false}
                    />
                </div>

                <Stack direction='row' justifyContent='flex-end'>
                    <div className='preview-total'>
                        Total Weight :{' '}
                        <span className=''>
                            {formatNumber(data?.totalGrossWeight || 0)} Kg
                        </span>
                    </div>
                </Stack>

                <Stack
                    direction='row'
                    alignItems='start'
                    className='bottom-preview-content'
                    width='full'
                    justifyContent={'space-between'}
                >
                    <div className='view-one'>
                        <p className='titl'>Delivery Agent</p>
                        <p>{data?.deliveryAgent || '--'}</p>

                        <p className='titl'>Number of Originals</p>
                        <p>{data?.noOfOriginals || '--'}</p>
                    </div>

                    <div className='view-two'>
                        <p className='titl'>Freight Amount</p>
                        <p className=''>{data?.freightAmount || '--'}</p>
                        <p className='titl'>Freight Payable At</p>
                        <p>{data?.freightPayableAt || '--'}</p>
                    </div>

                    <div className='view-three'>
                        <p className='titl'>Place of Issue</p>
                        <p>{data?.placeOfIssue || '--'}</p>
                        <p className='titl'>Signature</p>
                        <img src={`${signatureUrlBase64}`} alt='' />
                    </div>
                </Stack>
            </div>
        </PreviewContent>
    );
};

export default BillOfLadenPreview;
