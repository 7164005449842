import { StockMenuTabs } from './StockMenuTabs';

const StockHeader = () => {
    return (
        <div className='mt-[24px] lg:mt-0 flex items-center justify-between'>
            <h1 className='mb-4 text-xl text-black lg:text-[28px] lg:font-medium lg:text-appcolorblacktwo'>
                Stock Management
            </h1>

            <div className='hidden lg:block'>
                <StockMenuTabs />
            </div>
        </div>
    );
};

export default StockHeader;
