import { useQuery, useMutation, useQueryClient } from 'react-query';
import { http, httpNoAuth } from '.';
import { AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';
import { store } from '../store/store';
import { setMfaLoading, setShowMfaModal } from '../store/appSlice/appSlice';
import { getErrorMessage } from '../utils/helper';


interface AuthenticationOption {
    authed?: boolean;
}

interface QueryAuthenticationOptions extends AuthenticationOption {
    isBlob?: boolean;
}

export interface UploadProgressParams {
    progress: number;
}

interface MutationAuthenticationOption extends AuthenticationOption {
    onUploadProgress?: (params: UploadProgressParams) => void;
    method?: 'post' | 'put' | 'patch' | 'delete';
}

interface QueryOptions {
    url: string;
    cacheKey?: string;
    params?: Record<string, string | number | boolean>;
    enabled?: boolean;
}

interface MutateOptions {
    url: string;
    cacheKeys?: string | [string, Record<string, string | number>];
}

export const queryConfig =
    ({ authed = true, isBlob = false }: QueryAuthenticationOptions = {}) =>
    <T>(options: QueryOptions) => {
        const requester = authed ? http : httpNoAuth;
        const { url, cacheKey, params, enabled = true } = options;

        return useQuery({
            queryFn: async (): Promise<{ status: number; payload: T }> => {
                const { status, data: result } = await requester.get(url, {
                    params: { ...params },
                    responseType: isBlob ? 'blob' : 'json'
                });

                if (isBlob) {
                    return { status, payload: result } as {
                        status: number;
                        payload: T;
                    };
                } else {
                    const { data: payload } = result;
                    return { status, payload };
                }
            },
            queryKey: [cacheKey, params],
            enabled
        });
    };

export const mutateConfig =
    ({
        authed = true,
        onUploadProgress,
        method = 'post'
    }: MutationAuthenticationOption = {}) =>
    <T>(options: MutateOptions) => {
        const queryClient = useQueryClient();
        const requester = authed ? http : httpNoAuth;
        const { url, cacheKeys } = options;

        const handlePost = async (
            body: unknown
        ): Promise<{ status: number; payload: T}> => {
            const { status, data: result} = await requester[method](
                url,
                body as AxiosRequestConfig<unknown> | undefined,
                {
                    onUploadProgress: (params) =>
                        onUploadProgress &&
                        onUploadProgress(params as UploadProgressParams)
                }
            );

            const { data: payload } = result;

            return { status, payload };
        }

        return useMutation({
            mutationFn: async (
                body: unknown
            ): Promise<{ status: number; payload: T }> => {
                
                const {status, payload} = await handlePost(body)

               if(status === 204){
                store.dispatch(
                    setShowMfaModal({
                        showMfaModal: true,

                        mfaAction: async (otp: string) => {
                            try{
                            store.dispatch(setMfaLoading(true))
                              const result = await handlePost({
                                    ...body as object,
                                    otp
                                })
                                
                                store.dispatch(
                                    setShowMfaModal({
                                        showMfaModal: false,
                                        mfaAction: () => {},
                                    })
                                )
                                store.dispatch(setMfaLoading(false))
                               
                                const { message } = result.payload as unknown as  { message: string }
                                toast.success(message || "Action completed successfully.")
                                return result;
                            }catch(error){
                                store.dispatch(setMfaLoading(false));
                                toast.error(
                                    getErrorMessage(error) ||
                                        'An error has occured. Please try again.'
                                );
                            }
                        }
                      
                         
                    })
                );
               }

                

                return { status, payload };
            },
            onSuccess: () => {
                cacheKeys &&
                    queryClient.invalidateQueries({
                        queryKey: cacheKeys,
                        refetchInactive: true
                    });
            }
        });
    };

export const deleteMutate = (options: MutateOptions) => {
    const queryClient = useQueryClient();
    const requester = http;
    const { url, cacheKeys } = options;

    return useMutation(
        (id?: string | number, params?: string) =>
            requester.delete(params ? `${url}${params}` : `${url}/${id}`),
        {
            onSuccess: () => {
                cacheKeys &&
                    queryClient.invalidateQueries({
                        queryKey: cacheKeys,
                        refetchInactive: true
                    });
            }
        }
    );
};
