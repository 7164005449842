import { ReactNode } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface ButtonProps {
    text: string;
    invert?: boolean;
    iconLeft?: ReactNode;
    fullWidth?: boolean;
    style?: string;
    onClick?: () => void;
    variant?: 'primary' | 'secondary' | 'danger';
    isLoading?: boolean;
    disabled?: boolean;
    type?: 'button' | 'reset' | 'submit';
    position?: string;
}

export const SuitUIButton = (props: ButtonProps) => {
    const {
        text,
        invert,
        onClick = () => {},
        iconLeft,
        fullWidth,
        style,
        variant,
        isLoading,
        disabled,
        type,
        position
    } = props;

    const background = invert
        ? 'bg-appcolorwhite'
        : variant === 'secondary'
        ? ''
        : variant === 'danger'
        ? 'bg-crimson'
        : 'bg-appcolorprimary';
    const color = invert
        ? 'text-appcolorprimary'
        : variant === 'secondary'
        ? 'text-appcolorprimary'
        : 'text-appcolorwhite';
    const width = fullWidth ? 'w-full' : '';

    const padding = variant === 'secondary' ? '' : 'py-[12px] px-[20px]';

    return (
        <button
            className={`flex gap-[16px] items-center ${
                position ?? 'justify-center'
            } cursor-pointer rounded-[10px]  font-semibold ${background} ${color} ${width} ${padding} ${
                disabled && 'bg-opacity-20'
            } ${style}`}
            onClick={onClick}
            disabled={disabled || isLoading}
            type={type}
        >
            {iconLeft} {text}{' '}
            {isLoading && <CircularProgress size={20} color='inherit' />}
        </button>
    );
};
