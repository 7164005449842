import React from 'react';
import { getBeneficiaryStatus } from '../../../utils/helper';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { NoDataCross } from '../../../components/icons/NoDataCross';
import { BeneficiaryTableProps } from './interfaces/PaymentTable';

const OpsBeneficiariesTable = ({
    isBeneficiariesLoading,
    beneficiaries
}: BeneficiaryTableProps) => {
    return (
        <Table
            loading={isBeneficiariesLoading}
            pagination={{ pageSize: 20 }}
            dataSource={beneficiaries}
            locale={{
                emptyText: () => {
                    return (
                        <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                            <div className='flex justify-center mb-4'>
                                <NoDataCross />
                            </div>

                            <p className='text-center'>No data found</p>
                        </div>
                    );
                }
            }}
            columns={[
                {
                    title: 'Date',
                    dataIndex: 'createdDate',
                    key: 'createdDate',
                    render: (_, { createdDate }) => (
                        <span>{dayjs(createdDate).format('ll')}</span>
                    )
                },
                {
                    title: 'Corporate',
                    dataIndex: 'businessName',
                    key: 'businessName'
                },
                {
                    title: 'Account Name',
                    dataIndex: 'beneficiaryCompanyName',
                    key: 'beneficiaryCompanyName'
                },
                {
                    title: 'Acc No',
                    dataIndex: 'accountNumber',
                    key: 'accountNumber'
                },
                {
                    title: 'Bank Name',
                    dataIndex: 'bankName',
                    key: 'bankName'
                },
                {
                    title: 'Currency',
                    dataIndex: 'currencyCode',
                    key: 'currencyCode'
                },
                {
                    title: 'Country',
                    dataIndex: 'beneficiaryCountryName',
                    key: 'beneficiaryCountryName'
                },
                {
                    title: 'Lables/Tags',
                    dataIndex: 'beneficiaryEntityType',
                    key: 'beneficiaryEntityType'
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    render: (_, { status }) => (
                        <span>{getBeneficiaryStatus(status)}</span>
                    )
                }
            ]}
        />
    );
};

export default OpsBeneficiariesTable;
