import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { TabContainerNew } from '../../../../styles/TradeDocStyles';
import { CircularProgress, Divider, Stack, Tooltip } from '@mui/material';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';

interface breadcrumb {
    title: string;
    link?: string;
    active: boolean;
}

interface IProps {
    breadcrumbs?: breadcrumb[];
    showPreview?: boolean;
    docTitle?: string;
    setShowPreview: Dispatch<SetStateAction<boolean>>;
    myDiv: MutableRefObject<HTMLDivElement | null>;
    downloadLoading: boolean;
    setDownloadLoading: Dispatch<SetStateAction<boolean>>;
    onSaveAction?: () => void;
    onSaveOnlyAction?: () => void;
    loading?: boolean;
    disabled?: boolean;
}

const FormHeader = ({
    breadcrumbs,
    showPreview,
    setShowPreview,
    myDiv,
    docTitle,
    setDownloadLoading,
    downloadLoading,
    onSaveOnlyAction,
    loading,
    disabled
}: IProps) => {
    const navigate = useNavigate();
    const handlePrint = useReactToPrint({
        content: () => myDiv.current
    });
    return (
        <div className='form-header'>
            <div className='back-btn mb-[1.3rem]'>
                <p
                    className='flex items-center font-medium cursor-pointer w-fit'
                    onClick={() => {
                        navigate('/trade-documents');
                    }}
                >
                    <img
                        src='/img/vectors/breadcrumbcaretright.svg'
                        alt='caret'
                        className='pr-3'
                    />
                    <span>Back to Trade Documents</span>
                </p>
            </div>
            <div className='breadcrumbs'>
                {breadcrumbs?.map((item, index) => (
                    <div className='flex gap-2' key={index}>
                        <p
                            key={index}
                            className={
                                item?.active
                                    ? 'breadcrumb-text active'
                                    : 'breadcrumb-text'
                            }
                            onClick={() => {
                                if (item.link !== undefined) {
                                    navigate(item?.link);
                                }
                            }}
                        >
                            {item.title}
                        </p>
                        {index !== breadcrumbs.length - 1 && (
                            <img
                                src='/img/vectors/breadcrumbcaret.svg'
                                alt='caret'
                            />
                        )}
                    </div>
                ))}
            </div>

            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                className='header-action'
            >
                <h1 className='text-appcolorblacktwo'>
                    {docTitle || 'Quotation'}
                </h1>
                <TabContainerNew>
                    <Stack direction='row' spacing={'5px'}>
                        {disabled ? (
                            <>
                                <Tooltip
                                    title='Complete all compulsory fields'
                                    arrow
                                >
                                    <div
                                        className='tab-buttons'
                                        style={{
                                            cursor: disabled
                                                ? 'not-allowed'
                                                : 'pointer'
                                        }}
                                    >
                                        {loading ? (
                                            <CircularProgress size={15} />
                                        ) : (
                                            <SaveOutlinedIcon className='h-[1.1rem] mr-[5px]' />
                                        )}
                                        <p style={{ paddingRight: '5px' }}>
                                            Save
                                        </p>
                                    </div>
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <div
                                    className='tab-buttons'
                                    onClick={() => {
                                        if (onSaveOnlyAction !== undefined) {
                                            onSaveOnlyAction();
                                        }
                                    }}
                                    style={{
                                        cursor: disabled
                                            ? 'not-allowed'
                                            : 'pointer'
                                    }}
                                >
                                    {loading ? (
                                        <CircularProgress size={15} />
                                    ) : (
                                        <SaveOutlinedIcon className='h-[1.1rem] mr-[5px]' />
                                    )}
                                    <p style={{ paddingRight: '5px' }}>Save</p>
                                </div>
                            </>
                        )}
                        <Divider orientation='vertical' flexItem />

                        {!showPreview ? (
                            <>
                                <div
                                    className='tab-buttons'
                                    onClick={() => {
                                        setShowPreview(!showPreview);
                                    }}
                                >
                                    <img
                                        src='/img/vectors/eye.svg'
                                        alt='eye'
                                        className='h-[1.1rem] mr-[5px]'
                                    />
                                    <p style={{ paddingRight: '5px' }}>
                                        Preview
                                    </p>
                                </div>
                            </>
                        ) : (
                            <>
                                <div
                                    className='tab-buttons'
                                    onClick={() => {
                                        setShowPreview(!showPreview);
                                    }}
                                >
                                    <ArrowLeftOutlined className='mr-2' />

                                    <p style={{ paddingRight: '5px' }}>Back</p>
                                </div>
                                <Divider orientation='vertical' flexItem />
                                <div
                                    className='tab-buttons'
                                    onClick={handlePrint}
                                >
                                    {downloadLoading ? (
                                        <CircularProgress
                                            size={15}
                                            sx={{
                                                marginRight: '0.3rem'
                                            }}
                                        />
                                    ) : (
                                        <img
                                            src='/img/vectors/plaindownload.svg'
                                            alt='plaindownload'
                                            className='h-[1.1rem] mr-2'
                                        />
                                    )}{' '}
                                    <p style={{ paddingRight: '5px' }}>
                                        Download
                                    </p>
                                </div>
                            </>
                        )}
                    </Stack>
                </TabContainerNew>
            </Stack>
        </div>
    );
};

export default FormHeader;
