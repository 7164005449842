import React, { useEffect, useRef, useState } from 'react';
import { TradeContainer } from '../../../styles/TradeDocStyles';
import DocumentPageHeader from '../../../components/dashboard/tradeDocuments/DocumentPageHeader';
import AllDocumentsTable from '../../../components/dashboard/tradeDocuments/AllDocumentsTable';
import { getDocuments } from '../../../api/documents/documents';
import { openNotification } from '../../../utils/helper';
import { REACT_APP_BASE_URL } from '../../../config';
import CustomModal from '../../../components/CustomModal';
import Addtradedocument from './addtradedocument/addtradedocument';
import CreatedDocumentPreview from '../../../components/dashboard/tradeDocuments/CreatedDocumentPreview';
import useCorporatePartners from '../../../customhooks/useCorporatePartners';
import Addpartnerdrawer from './addpartnerdrawer/addpartnerdrawer';
import { useReactToPrint } from 'react-to-print';
import DocumentViewer from '../../../components/DocumentViewer/DocumentViewer';

const TradeDocumentNew = () => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState<any>(null);
    const [currentData, setCurrentData] = useState<any>(null);
    const [visibleDocument, setVisibleDocument] = useState(false);

    const closeCurrentDocModal = () => {
        setVisibleDocument(false);
    };

    const closeShowAddModal = (refreshList?: boolean) => {
        setShowAddModal(false);
        if (refreshList) {
            fetchDocuments();
        }
    };

    const { corporatePartners, isLoading } = useCorporatePartners({ page: 1 });

    const [showAddPartner, setShowAddPartner] = useState(false);

    const closeShowAddParty = () => {
        setShowAddPartner(false);
    };

    const [filterOptions, setFilterOptions] = useState<any>({});

    const fetchDocuments = async (opts?: any) => {
        setLoadingData(true);
        try {
            const res = await getDocuments(
                opts
                    ? {
                          page: 1,
                          size: 0,
                          ...opts
                      }
                    : {
                          page: 1,
                          size: 0
                      }
            );

            const { data } = res.data;
            setData(data);
            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            if (error?.response) {
                openNotification('error', `Documents, Something went wrong`);
            } else {
                openNotification('error', `Documents, Something went wrong`);
            }
        }
    };

    useEffect(() => {
        fetchDocuments();
    }, []);

    const [downloadLoading, setDownloadLoading] = useState(false);
    const myDiv = useRef<HTMLDivElement | null>(null);
    const pageTopRef = useRef<any>(null);

    const handlePrint = useReactToPrint({
        content: () => myDiv.current
    });

    return (
        <TradeContainer>
            <DocumentPageHeader
                {...{
                    setShowAddModal,
                    setFilterOptions,
                    filterOptions,
                    fetchDocuments
                }}
            />

            <AllDocumentsTable
                {...{
                    loadingData,
                    setShowAddModal,
                    setCurrentData,
                    currentData,
                    setVisibleDocument
                }}
                data={data?.content}
            />

            {currentData?.documentUrl &&
                currentData?.createdType !== 'CREATED' && (
                    <CustomModal
                        maxWidth='xl'
                        open={visibleDocument}
                        onClose={closeCurrentDocModal}
                        title='View Document'
                        styles={{
                            overflowX: 'hidden'
                        }}
                    >
                        <div className='w-[60rem] h-[80vh]'>
                            <DocumentViewer
                                fileUrl={`${REACT_APP_BASE_URL}/${currentData?.documentUrl.split(
                                    '/v1/'
                                )[1]}`}
                            />
                        </div>
                    </CustomModal>
                )}

            {currentData?.createdType === 'CREATED' && (
                <CreatedDocumentPreview
                    {...{
                        currentData,
                        closeCurrentDocModal,
                        visibleDocument,
                        myDiv,
                        setDownloadLoading,
                        downloadLoading,
                        handlePrint
                    }}
                />
            )}

            <CustomModal
                maxWidth='xl'
                open={showAddModal}
                onClose={closeShowAddModal}
                title='Documents'
                subTitle='Please select a document type:'
                styles={{
                    overflowX: 'hidden'
                }}
            >
                <Addtradedocument
                    closeShowAddModal={closeShowAddModal}
                    {...{
                        loadingCorporatepartners: isLoading,
                        corporatePartners,
                        setShowAddPartner
                    }}
                />
            </CustomModal>

            {showAddPartner && (
                <Addpartnerdrawer
                    closeDrawer={closeShowAddParty}
                    showDrawer={showAddPartner}
                />
            )}
        </TradeContainer>
    );
};

export default TradeDocumentNew;
