import { BreadcrumbCaretRight } from '../../../components/icons/BreadcrumbCaretRight';
import { SalesSummaryContainer } from '../../../styles/SalesStyles';
import { Avatar, Backdrop, Button, Stack } from '@mui/material';
import { formatCurrency, getInvoiceStatus } from '../../../utils/helper';
import dayjs from 'dayjs';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { LoadingCircles } from '../../../components/icons/LoadingCircles';
import { SalesDocument } from './interfaces/SalesDocuments';
import { createPortal } from 'react-dom';
import { MutableRefObject } from 'react';
import { InvoiceStates } from './interfaces/Invoice';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';
import { useUserProfile } from '../../../customhooks/useUserProfile';

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: '#E5F3F7',
            color: '#0088B29a',
            width: '54px',
            height: '54px',
            fontSize: '18px',
            fontWeight: '600'
        },
        children: name
    };
}

const LogisticsInvoiceDetailsSummary = ({
    isPaymentLoading,
    isDetailsLoading,
    invoiceDetails
}: {
    isPaymentLoading: boolean;
    isDetailsLoading: boolean;
    invoiceDetails: SalesDocument;
}) => {
    const navigate = useNavigate();

    const { backRef } = useOutletContext() as {
        backRef: MutableRefObject<HTMLDivElement>;
    };
    const { permissions } = useUserProfile();

    return (
        <>
            {backRef.current &&
                createPortal(
                    <div
                        onClick={() => {
                            navigate('/payments/logistics/list');
                        }}
                    >
                        <p className='flex items-center gap-2 font-medium text-sm cursor-pointer w-fit'>
                            <BreadcrumbCaretRight />
                            <span>Back to Logistics Payments</span>
                        </p>
                    </div>,
                    backRef.current
                )}
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={isDetailsLoading || isPaymentLoading}
            >
                <div className='animate-spin'>
                    <LoadingCircles />
                </div>
            </Backdrop>

            <SalesSummaryContainer>
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    gap='60px'
                >
                    <Stack direction='row' gap='15px' className='first-details'>
                        <Avatar {...stringAvatar('#')} />
                        <div>
                            <p>Invoice Number</p>
                            <h4>
                                {invoiceDetails?.documentData?.invoiceNumber ??
                                    ''}
                            </h4>
                        </div>
                    </Stack>

                    <div className='other-details'>
                        <p>Carrier</p>
                        <h4>
                            {invoiceDetails?.documentData?.beneficiary
                                ?.beneficiaryCompanyName ?? ''}
                        </h4>
                    </div>
                    <div className='other-details'>
                        <p>Due Date</p>
                        <h4>
                            {dayjs(
                                invoiceDetails?.documentData?.dueDate
                            ).format('DD MMM, YYYY')}
                        </h4>
                    </div>

                    <div className='other-details'>
                        <p>Total invoice Amount</p>
                        <h4>
                            {formatCurrency(
                                invoiceDetails?.documentData?.totalAmount || 0,
                                invoiceDetails?.documentData?.currencyCode
                            )}
                        </h4>
                    </div>
                    <div className='other-details'>
                        <p>Status</p>
                        <h4 className='uppercase'>
                            {
                                InvoiceStates[
                                    getInvoiceStatus(
                                        invoiceDetails?.documentData?.status
                                    ).toUpperCase() as keyof typeof InvoiceStates
                                ]
                            }
                        </h4>
                    </div>
                </Stack>

                {(invoiceDetails?.documentData?.status === 6 ||
                    invoiceDetails?.documentData?.status === 4) &&
                    permissions &&
                    hasPermission({
                        userPermissions: permissions,
                        module: 'payments',
                        permissionList: ['write', '*']
                    }) && (
                        <Button
                            sx={{
                                backgroundColor: '#0088B2',
                                color: 'white',
                                borderRadius: '4px',
                                padding: '4px 8px',
                                height: 35,
                                width: 128
                            }}
                            onClick={() =>
                                navigate(
                                    `/payments/logistics/pay?invoices=${invoiceDetails.id}`
                                )
                            }
                        >
                            Make Payment
                        </Button>
                    )}
            </SalesSummaryContainer>
        </>
    );
};

export default LogisticsInvoiceDetailsSummary;
