import React, { FC, useState } from 'react';
import { Dropdown, MenuProps, Table } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../CustomModal';
import ApprovalModalContent from './ApprovalModalContent';
import { Alert, TextField } from '@mui/material';
import { approveBooking } from '../../../api/booking/booking';
import { getBookingStatus, openNotification } from '../../../utils/helper';

interface IProps {
    loadingData: boolean;
    data?: any;
    fetch?: any;
}
const BookingsTable: FC<IProps> = ({
    loadingData,
    data,
    fetch
}: IProps): JSX.Element => {
    const [currentData, setCurrentData] = useState<any>();
    const navigate = useNavigate();
    const [bookingNumber, setBookingNumber] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | any>(null);
    const [errors, setErrors] = useState<string | any>(null);
    const [showApproveModal, setShowApproveModal] = useState(false);

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        navigate(`/bookings/${currentData?.id}/details`);
                    }}
                >
                    <span className='ml-2'>Review Booking Details</span>
                </div>
            )
        },
        {
            key: '2',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        setShowApproveModal(true);
                    }}
                >
                    <span className='ml-2'>Approve Booking</span>
                </div>
            )
        }
    ];

    const approveFunction = async () => {
        setError(undefined);
        if (
            bookingNumber === '' ||
            bookingNumber === undefined ||
            bookingNumber === null
        ) {
            setError('Enter Booking Number');
        } else {
            setLoading(true);
            try {
                const res = await approveBooking(currentData?.id, {
                    bookingNumber: bookingNumber
                });
                setLoading(false);
                setShowApproveModal(false);
                openNotification(
                    'success',
                    'Booking was approved successfully'
                );
                fetch();
            } catch (error) {
                setLoading(false);
                openNotification(
                    'error',
                    'Booking approval, Something went wrong'
                );
                const otherErrors: any[] = [];

                if (error?.response) {
                    if (error?.response?.data?.error) {
                        const resError = error?.response?.data?.error;

                        if (typeof resError === 'string') {
                            const errorList = (
                                <li key={`error-list-${1}`}>{resError}</li>
                            );
                            setError(errorList);
                        } else {
                            Object.entries(resError).forEach(([key, value]) => {
                                if (Object.keys(errors).includes(key)) {
                                    setErrors((prevState: any) => ({
                                        ...prevState,
                                        [key]: value
                                    }));
                                } else {
                                    otherErrors.push(value);
                                }
                            });

                            if (otherErrors.length > 0) {
                                const errorList = otherErrors.map(
                                    (element: any, index) => {
                                        return (
                                            <li key={`error-list-${index}`}>
                                                {element}
                                            </li>
                                        );
                                    }
                                );
                                setError(errorList);
                            }
                        }
                    } else {
                        setError(
                            error?.response?.data?.message ||
                                'Something went wrong'
                        );
                    }
                } else {
                    setError('Something went wrong');
                }
            }
        }
    };

    return (
        <>
            <Table
                loading={loadingData}
                pagination={{ pageSize: 20 }}
                dataSource={data?.content}
                columns={[
                    {
                        title: 'Date',
                        dataIndex: 'createdDate',
                        key: 'createdDate',
                        render: (_, { createdDate }) => (
                            <span>
                                {createdDate
                                    ? moment(createdDate).format('LL')
                                    : '-'}
                            </span>
                        )
                    },
                    {
                        title: 'Business Name',
                        dataIndex: 'businessName',
                        key: 'businessName',
                        render: (text) => (
                            <span className='text-appcolorprimary'>{text}</span>
                        )
                    },
                    {
                        title: 'Shipping Line',
                        dataIndex: 'shippingLineName',
                        key: 'shippingLineName'
                    },
                    {
                        title: 'Port of Loading',
                        dataIndex: 'portOfLoading',
                        key: 'portOfLoading'
                    },
                    {
                        title: 'Departure Date',
                        dataIndex: 'departureDate',
                        key: 'departureDate',
                        render: (_, { departureDate }) => (
                            <span>
                                {departureDate
                                    ? moment(departureDate).format('LL')
                                    : '-'}
                            </span>
                        )
                    },
                    {
                        title: 'Port of Discharge',
                        dataIndex: 'portOfDischarge',
                        key: 'portOfDischarge'
                    },
                    {
                        title: 'Arrival Date',
                        dataIndex: 'arrivalDate',
                        key: 'arrivalDate',
                        render: (_, { arrivalDate }) => (
                            <span>
                                {arrivalDate
                                    ? moment(arrivalDate).format('LL')
                                    : '-'}
                            </span>
                        )
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        render: (_, { status }) => (
                            <span
                                className={`text-[12px] text-semibold p-1 pl-[0.3rem] rounded-[5px]
            ${
                status === 1
                    ? 'text-appcolorgreen bg-appcolorgreenfaint'
                    : status === 2
                    ? 'text-appcolorgreen bg-appcolorgreenfaint'
                    : status === 4
                    ? 'text-appcolorred bg-appcolorfaintred'
                    : status === 3
                    ? 'text-appcolorblack bg-appcolorgreyfaint'
                    : ''
            }
            `}
                            >
                                {getBookingStatus(status)}
                            </span>
                        )
                    },
                    {
                        title: '',
                        render: (_, data) => (
                            <div
                                className='cursor-pointer'
                                onClick={() => {
                                    setCurrentData(data);
                                }}
                            >
                                <Dropdown
                                    menu={{ items }}
                                    trigger={['click']}
                                    placement='bottomRight'
                                >
                                    <img
                                        src='/img/vectors/more.svg'
                                        alt='more'
                                    />
                                </Dropdown>
                            </div>
                        )
                    }
                ]}
            />

            {showApproveModal && (
                <CustomModal
                    maxWidth='md'
                    open={showApproveModal}
                    onClose={() => setShowApproveModal(false)}
                    title=''
                >
                    <ApprovalModalContent
                        type='Booking'
                        {...{ currentData }}
                        approveFunction={approveFunction}
                        cancelFunction={() => {
                            setBookingNumber('');
                            setShowApproveModal(false);
                        }}
                        loading={loading}
                    >
                        {error && (
                            <div className='mt-4'>
                                <Alert severity='error'>{error}</Alert>
                            </div>
                        )}
                        <TextField
                            value={bookingNumber}
                            margin='normal'
                            required
                            fullWidth
                            id='bookingNumber'
                            label='Booking/BL Number'
                            name='bookingNumber'
                            onChange={(e: any) => {
                                setBookingNumber(e.target.value);
                            }}
                        />
                    </ApprovalModalContent>
                </CustomModal>
            )}
        </>
    );
};

export default BookingsTable;
