import styled from 'styled-components';

export const ViewContainer = styled.div`
    @media (min-width: 1024px) {
        width: auto;
    }
    width: 100%;

    button {
        border: 1px solid #344054;
        display: flex;
        gap: 5px;
        padding: 3px 5px;
        color: #344054;

        :hover {
            background-color: unset;
        }
    }
    .download-button {
        background-color: #0088b2;
        color: white;
        border: none;
        :hover {
            background-color: #0088b2;
        }
    }
`;

export const PaymentReceiptContainer = styled.div`
    padding: 0.5rem;
    @media (min-width: 1024px) {
        padding: 1.5rem;
    }
    .foot-note {
        text-align: center;
        margin: 3rem auto 0rem;
        color: #0088b2;
        font-weight: 600;
        b {
            font-weight: 800;
        }
    }
    .payment-info {
        margin-top: 16px;
        color: #0088b2;
        font-size: 15px;
        font-weight: 500;
    }
    @media print {
        page-break-before: always;
        page-break-after: always;
    }
`;
