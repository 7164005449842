export const RadioCheckedIcon = () => (
    <svg
        width='14'
        height='15'
        viewBox='0 0 14 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.9085 7.58204C12.9085 10.8576 10.2531 13.513 6.97755 13.513C3.70199 13.513 1.04663 10.8576 1.04663 7.58204C1.04663 4.30648 3.70199 1.65112 6.97755 1.65112C10.2531 1.65112 12.9085 4.30648 12.9085 7.58204ZM11.8618 7.58204C11.8618 10.2796 9.67506 12.4663 6.97755 12.4663C4.28003 12.4663 2.09326 10.2796 2.09326 7.58204C2.09326 4.88452 4.28003 2.69776 6.97755 2.69776C9.67506 2.69776 11.8618 4.88452 11.8618 7.58204ZM6.97755 11.4197C9.09702 11.4197 10.8152 9.70152 10.8152 7.58204C10.8152 5.46256 9.09702 3.74439 6.97755 3.74439C4.85807 3.74439 3.1399 5.46256 3.1399 7.58204C3.1399 9.70152 4.85807 11.4197 6.97755 11.4197Z'
            fill='#1993B9'
        />
    </svg>
);
