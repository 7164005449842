export const PreviewIcon = ({ color }: { color?: string }) => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
        >
            <g clip-path='url(#clip0_305_46394)'>
                <path
                    d='M1.14583 10.6666C1.14583 10.6666 4.39583 4.16663 10.0833 4.16663C15.7708 4.16663 19.0208 10.6666 19.0208 10.6666C19.0208 10.6666 15.7708 17.1666 10.0833 17.1666C4.39583 17.1666 1.14583 10.6666 1.14583 10.6666Z'
                    stroke={color ?? '#1D2939'}
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M10.0833 13.1041C11.4295 13.1041 12.5208 12.0128 12.5208 10.6666C12.5208 9.32043 11.4295 8.22913 10.0833 8.22913C8.73713 8.22913 7.64583 9.32043 7.64583 10.6666C7.64583 12.0128 8.73713 13.1041 10.0833 13.1041Z'
                    stroke={color ?? '#1D2939'}
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <defs>
                <clipPath id='clip0_305_46394'>
                    <rect
                        width='19.5'
                        height='19.5'
                        fill='white'
                        transform='translate(0.333328 0.916626)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
