export const MenuIcon = () => (
    <svg
        width='24'
        height='25'
        viewBox='0 0 24 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect
            width='24'
            height='24'
            transform='translate(0 0.5)'
            fill='white'
        />
        <path
            d='M3 12.5H17M3 6.5H21M3 18.5H21'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
