export const InfoCircleIcon = () => {
    return (
        <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M9.06683 4.86683C9.06683 5.32905 8.72016 5.7335 8.20016 5.7335C7.68016 5.7335 7.3335 5.32905 7.3335 4.86683C7.3335 4.40461 7.73794 4.00016 8.20016 4.00016C8.66238 4.00016 9.06683 4.40461 9.06683 4.86683ZM7.22238 11.3335C6.88905 11.3335 6.66683 11.1238 6.66683 10.8092C6.66683 10.4945 6.88905 10.2848 7.22238 10.2848H7.66683C7.72238 10.2848 7.77794 10.2324 7.77794 10.1799V7.82039C7.77794 7.76795 7.72238 7.71552 7.66683 7.71552H7.22238C6.88905 7.71552 6.66683 7.50578 6.66683 7.19117C6.66683 6.87657 6.88905 6.66683 7.22238 6.66683H7.77794C8.38905 6.66683 8.88905 7.13874 8.88905 7.71552V10.2324C8.88905 10.2848 8.94461 10.3372 9.00016 10.3372H9.44461C9.77794 10.3372 10.0002 10.547 10.0002 10.8616C10.0002 11.1762 9.77794 11.3335 9.44461 11.3335H7.22238ZM8.00016 1.3335C4.3335 1.3335 1.3335 4.3335 1.3335 8.00016C1.3335 11.6668 4.3335 14.6668 8.00016 14.6668C11.6668 14.6668 14.6668 11.6668 14.6668 8.00016C14.6668 4.3335 11.6668 1.3335 8.00016 1.3335Z'
                fill='#4F5E71'
            />
        </svg>
    );
};
