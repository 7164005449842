import { useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

interface RoleAutocompleteProps {
    onHandleChange: (value: { value: string; label: string }) => void;
    initialValue?: string;
    error?: boolean;
    errorMessage?: string;
}

const roles = [
    {
        value: 'ADMIN',
        label: 'Admin'
    },
    {
        value: 'MEMBER',
        label: 'Member'
    }
];

const RolesDropdown = ({
    initialValue,
    onHandleChange,
    error,
    errorMessage
}: RoleAutocompleteProps) => {
    const defaultValue = useMemo(() => {
        return roles.find((item) => item.value === initialValue);
    }, [initialValue]);

    return (
        <Autocomplete
            disableClearable
            options={roles}
            value={defaultValue}
            sx={{
                marginTop: '12px',
                '.MuiInputBase-input': {
                    height: '0.3rem'
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label=''
                    error={error}
                    helperText={errorMessage}
                />
            )}
            noOptionsText={'No data available'}
            renderOption={(props, option) => (
                <MenuItem {...props} value={option.value} key={option.value}>
                    <div className='flex items-center'>
                        <span className='ml-2'>{option.label}</span>
                    </div>
                </MenuItem>
            )}
            getOptionLabel={(option) => option.label}
            onChange={(_, value) => onHandleChange(value)}
        />
    );
};

export default RolesDropdown;
