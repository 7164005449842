import React, { Fragment, MutableRefObject } from 'react';
import { Stack } from '@mui/material';
import { FrontEdgeLogo } from '../../../components/icons/FrontEdgeLogo';
import { PaymentReceiptContainer } from '../../../styles/PaymentStyles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Wallet } from './interfaces/Wallet';

const DedicatedAccountDetails = ({
    wallets,
    myDiv
}: {
    wallets: Wallet[];
    myDiv?: MutableRefObject<HTMLDivElement | null>;
}) => {
    const { user } = useSelector((state: RootState) => state.user);

    const filteredAccounts = wallets?.filter(
        (wallet) =>
            wallet.currencyCode !== 'NGN' && wallet.accountMode !== undefined
    );

    return (
        <div className='absolute w-[100%] top-0 z-[-8000]' ref={myDiv}>
            <PaymentReceiptContainer className='p-[0rem'>
                <Stack direction='row' justifyContent='space-between'>
                    <FrontEdgeLogo />
                    <p className=' text-[#1D2939] font-[600] text-[14px]'>
                        2 Onikoyi, Ikoyi , Lagos, Nigeria.
                    </p>
                </Stack>
                <h3 className=' font-[700] text-[25px] text-center my-[1.5rem]'>
                    Global Account Details - {user?.corporate_name}
                </h3>
                <Stack gap='22px'>
                    {filteredAccounts?.map((wallet) => (
                        <Fragment key={`account-${wallet.id}`}>
                            {wallet.accountMode === 'multi' && (
                                <div className='rounded-[4px] bg-[#F2F4F7] p-[12px]'>
                                    <h5 className=' mb-[.5rem] text-[16px] font-[700]'>
                                        Receive {wallet.currencyCode} from
                                        within {wallet.nameAndFlag}
                                    </h5>
                                    <Stack
                                        direction='row'
                                        justifyContent='space-between'
                                        mb='.5rem'
                                    >
                                        <p className=' text-[14px]'>
                                            Account Name
                                        </p>
                                        <p className=' text-[14px] text-right font-[600]'>
                                            {wallet.localAccountName}
                                        </p>
                                    </Stack>

                                    <Stack
                                        direction='row'
                                        justifyContent='space-between'
                                        mb='.5rem'
                                    >
                                        <p className=' text-[14px]'>
                                            Account Number
                                        </p>
                                        <p className=' text-[14px] text-right font-[600]'>
                                            {wallet.localAccountNumber}
                                        </p>
                                    </Stack>

                                    <Stack
                                        direction='row'
                                        justifyContent='space-between'
                                        mb='.5rem'
                                    >
                                        <p className=' text-[14px]'>
                                            Sort Code
                                        </p>
                                        <p className=' text-[14px] text-right font-[600]'>
                                            {wallet.localSortCode}
                                        </p>
                                    </Stack>

                                    <Stack
                                        direction='row'
                                        justifyContent='space-between'
                                        mb='.5rem'
                                    >
                                        <p className=' text-[14px]'>
                                            Bank Name
                                        </p>
                                        <p className=' text-[14px] text-right font-[600]'>
                                            {wallet.localBankName}
                                        </p>
                                    </Stack>
                                    <Stack
                                        direction='row'
                                        justifyContent='space-between'
                                        mb='.5rem'
                                    >
                                        <p className=' text-[14px] min-w-[20%]'>
                                            Bank Address
                                        </p>
                                        <p className=' text-[14px] text-right font-[600]'>
                                            {wallet.localBankAddress}
                                        </p>
                                    </Stack>
                                </div>
                            )}
                            <div className='rounded-[4px] bg-[#F2F4F7] p-[12px]'>
                                <h5 className=' mb-[.5rem] text-[16px] font-[700]'>
                                    Receive {wallet.currencyCode} from outside{' '}
                                    {wallet.nameAndFlag}
                                </h5>
                                <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    mb='.5rem'
                                >
                                    <p className=' text-[14px]'>Account Name</p>
                                    <p className=' text-[14px] text-right font-[600]'>
                                        {wallet.globalAccountName}
                                    </p>
                                </Stack>

                                <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    mb='.5rem'
                                >
                                    <p className=' text-[14px]'>
                                        Account Number
                                    </p>
                                    <p className=' text-[14px] text-right font-[600]'>
                                        {wallet.globalAccountNumber}
                                    </p>
                                </Stack>

                                <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    mb='.5rem'
                                >
                                    <p className=' text-[14px]'>Swift Code</p>
                                    <p className=' text-[14px] text-right font-[600]'>
                                        {wallet.globalSwiftCode}
                                    </p>
                                </Stack>

                                <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    mb='.5rem'
                                >
                                    <p className=' text-[14px]'>Bank Name</p>
                                    <p className=' text-[14px] text-right font-[600]'>
                                        {wallet.globalBankName}
                                    </p>
                                </Stack>
                                <Stack
                                    direction='row'
                                    justifyContent='space-between'
                                    mb='.5rem'
                                >
                                    <p className=' text-[14px] min-w-[20%]'>
                                        Bank Address
                                    </p>
                                    <p className=' text-[14px] text-right font-[600]'>
                                        {wallet.globalBankAddress}
                                    </p>
                                </Stack>
                            </div>{' '}
                        </Fragment>
                    ))}
                </Stack>
            </PaymentReceiptContainer>
        </div>
    );
};

export default DedicatedAccountDetails;
