import React, { ReactNode } from 'react';
import Documentupload from '../../../documentupload/documentupload';
import { REACT_APP_BASE_URL } from '../../../../config';
// import DocViewer from '@cyntler/react-doc-viewer';
import DocumentViewer from '../../../DocumentViewer/DocumentViewer';

export interface custommodalProps {
    children?: ReactNode;
    updateField?: any;
    payload?: any;
}

const UploadLogo = ({ updateField, payload, children }: custommodalProps) => {
    return (
        <div className='w-[250px]'>
            {payload?.logoUrl ? (
                <>
                    <p className=' text-appcolorprimary pb-[.5rem]'>
                        Uploaded Logo
                    </p>{' '}
                    <div className='h-[50px] w-[100px] preview-container mb-4 rounded-[3px] border-appcolorfaintgreyblue border border-dashed px-[.2rem] py-[.2rem]'>
                        <DocumentViewer
                            fileUrl={`${REACT_APP_BASE_URL}/${payload?.logoUrl.split(
                                '/v1/'
                            )[1]}`}
                        />
                    </div>
                </>
            ) : (
                <Documentupload
                    acceptText='.jpg and .png'
                    accept='.jpg, .png'
                    useAuthorization
                    returnURLOnly
                    title={'Upload Logo'}
                    afterUploadUpdate={(
                        itemId: number,
                        documentUrl: string
                    ) => {
                        updateField('logoUrl', documentUrl);
                    }}
                />
            )}
        </div>
    );
};

export default UploadLogo;
