export const FrontEdgeSmallLogo = () => {
    return (
        <svg
            width='36'
            height='44'
            viewBox='0 0 36 44'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M3.45537 14.5861C4.78139 15.7681 6.3897 16.5758 8.1195 16.9285L18.4258 19.0759L23.1833 8.87586L24.9364 5.11794L25.1286 4.70814L2.54439 0C-1.3744 4.51846 -0.52862 11.0214 3.45537 14.5861Z'
                fill='#FFCC00'
            />
            <path
                d='M18.4258 19.0706L23.2362 20.0709L33.271 22.1593L33.7661 22.2613L34.9133 19.8061C35.8137 17.8905 36.1065 15.7385 35.7514 13.6467C35.3964 11.555 34.4109 9.6267 32.9309 8.12792L32.9203 8.1136L32.7441 7.93465L32.5679 7.7557C32.5186 7.7056 32.464 7.65548 32.4111 7.60897L32.2225 7.44253C32.1081 7.34769 31.9882 7.25106 31.8702 7.15801C31.7909 7.09537 31.7098 7.03454 31.627 6.97906C30.4536 6.11445 29.1113 5.51525 27.6907 5.22179L25.125 4.68494L24.933 5.09473L23.1797 8.85265L18.4258 19.0706Z'
                fill='#0088B2'
            />
            <path
                d='M23.9168 43.3681L33.7649 22.2611L33.2698 22.1591L23.2349 20.0709L18.4245 19.0706L13.9437 28.6711C10.6081 35.8326 16.1392 43.9801 23.9168 43.3681Z'
                fill='#00BAFF'
            />
        </svg>
    );
};
