import React, { useState, useEffect } from 'react';
import moment from 'moment';
import useIndustries from '../../../customhooks/industries/useIndustries';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import { updateCorporate } from '../../../api/base/base';
import { openNotification } from '../../../utils/helper';
import Custombutton from '../../../components/custombutton/custombutton';

export interface corpdetailsProps {
    data: any;
    isDrawer?: boolean;
}

const Corpdetails = ({ data, isDrawer }: corpdetailsProps) => {
    const { isIndustriesLoading, industries } = useIndustries();

    const [updating, setUpdating] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [payload, setPayload] = useState<any>({
        businessName: data?.businessName,
        rcNumber: data?.rcNumber,
        registeredAddress: data?.registeredAddress,
        officeAddress: data?.officeAddress
            ? data?.officeAddress.trim().length > 0
                ? data?.officeAddress
                : undefined
            : undefined,
        businessPhoneNumber: data?.businessPhoneNumber,
        businessWebsite: data?.businessWebsite,
        annualTurnover: data?.annualTurnover,
        defaultCurrency: data?.defaultCurrency,
        businessEmail: data?.businessEmail
    });
    const [errors, setErrors] = useState({
        businessName: '',
        rcNumber: '',
        registeredAddress: '',
        officeAddress: '',
        businessPhoneNumber: '',
        businessWebsite: '',
        annualTurnover: ''
    });

    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const onSubmit = async () => {
        setUpdating(true);
        try {
            await updateCorporate(data?.id, {
                ...payload
            });
            setUpdating(false);

            openNotification(
                'success',
                'Update Business Details, Corporate Updated Successfully'
            );

            setEditMode(false);
        } catch (error) {
            setUpdating(false);
            if (error?.response?.data) {
                const errors = error?.response?.data?.error;
                Object.entries(errors).forEach(([key, value]) => {
                    setErrors((prevState) => ({
                        ...prevState,
                        [key]: value
                    }));
                });
            } else {
                openNotification(
                    'error',
                    'Update Business Details, Something went wrong'
                );
            }
        }
    };

    useEffect(() => {
        if (payload !== null) {
            setErrors((prevState) => ({
                ...prevState,
                businessName:
                    payload?.businessName === undefined
                        ? ''
                        : payload.businessName.length > 0
                        ? ''
                        : 'Business Name is required',
                rcNumber:
                    payload?.rcNumber === undefined
                        ? ''
                        : payload?.rcNumber.length > 0
                        ? ''
                        : 'CAC No. is required',
                registeredAddress:
                    payload?.registeredAddress === undefined
                        ? ''
                        : payload?.registeredAddress.length > 0
                        ? ''
                        : 'Registered Address is required',
                officeAddress:
                    payload?.officeAddress === undefined
                        ? ''
                        : payload?.officeAddress.length > 0
                        ? ''
                        : 'Office Address is required',
                businessPhoneNumber:
                    payload?.businessPhoneNumber === undefined
                        ? ''
                        : payload?.businessPhoneNumber.length > 0
                        ? ''
                        : 'Phone number is required',
                businessWebsite:
                    payload?.businessWebsite === undefined
                        ? ''
                        : payload?.businessWebsite.length > 0
                        ? ''
                        : 'Website is required',
                annualTurnover:
                    payload?.annualTurnover === undefined
                        ? ''
                        : payload?.annualTurnover.length > 0
                        ? ''
                        : 'Annual Turnover is required'
            }));
        }
    }, [payload]);

    useEffect(() => {
        if (isDrawer) {
            setEditMode(true);
        }
    }, [isDrawer]);

    return (
        <div className={`${isDrawer ? 'mt-[-4rem]' : ''}`}>
            {!isDrawer ? (
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                    open={updating}
                >
                    <img
                        src='/img/vectors/loadingcircles.svg'
                        alt='loading'
                        className='animate-spin'
                    />
                </Backdrop>
            ) : null}

            <div>
                <div>
                    {isDrawer ? (
                        <>
                            <div className='flex justify-end mt-8'>
                                {editMode ? (
                                    <Custombutton
                                        size='small'
                                        isLoadingButton
                                        loading={updating}
                                        type='submit'
                                        // fullWidth
                                        variant='contained'
                                        styles={{
                                            padding:
                                                '0.45rem 0.52rem 0.4rem 0.53rem'
                                        }}
                                        disabled={
                                            Object.values(payload).some(
                                                (item: any) =>
                                                    item === undefined
                                            ) ||
                                            Object.values(errors).some(
                                                (item) => item.length > 0
                                            ) ||
                                            updating
                                        }
                                        buttonText='Update'
                                        onClickAction={() => {
                                            onSubmit();
                                        }}
                                    />
                                ) : (
                                    <Custombutton
                                        size='small'
                                        isLoadingButton
                                        loading={updating}
                                        type='submit'
                                        // fullWidth
                                        variant='contained'
                                        styles={{
                                            padding:
                                                '0.45rem 0.52rem 0.4rem 0.53rem'
                                        }}
                                        disabled={
                                            Object.values(payload).some(
                                                (item: any) =>
                                                    item === undefined
                                            ) ||
                                            Object.values(errors).some(
                                                (item) => item.length > 0
                                            ) ||
                                            updating
                                        }
                                        buttonText='Edit'
                                        onClickAction={() => {
                                            setEditMode(true);
                                        }}
                                    />
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='flex items-center justify-between'>
                                <div className='flex'>
                                    <div
                                        className={`bg-appcolorwhite trapezoid pt-[0.8rem] pb-[0.7rem] pl-[1.6rem] w-fit pr-[5.6rem] cursor-pointer text-[16px] leading-[24px]`}
                                    >
                                        Breakdown
                                    </div>
                                </div>

                                <p
                                    className={` text-[14px] leading-[24px] cursor-pointer ${
                                        !editMode
                                            ? 'text-appcolorprimary'
                                            : Object.values(payload).some(
                                                  (item: any) =>
                                                      item === undefined
                                              ) ||
                                              Object.values(errors).some(
                                                  (item) => item.length > 0
                                              )
                                            ? ''
                                            : 'text-appcolorprimary'
                                    }`}
                                    onClick={() => {
                                        if (!editMode) {
                                            setEditMode(true);
                                        } else {
                                            onSubmit();
                                        }
                                    }}
                                >
                                    {editMode ? 'Update' : 'Edit'}
                                </p>
                            </div>
                        </>
                    )}
                </div>
                <div
                    className={`bg-appcolorwhite pt-[0.8rem] pb-[0.7rem] ${
                        isDrawer ? 'px-[0rem]' : 'pl-[1.6rem] pr-[1.6rem]'
                    }`}
                >
                    <div className='pb-[2rem] mb-[2rem] border-b border-appcolorblackfaint pt-8'>
                        <div className='flex justify-between mb-8'>
                            <p className='text-appcolorfaintsix font-semibold leading-[16px] text-[13px]'>
                                Business Name
                            </p>
                            {editMode ? (
                                <div className='w-[50%] text-roght'>
                                    <TextField
                                        value={payload?.businessName}
                                        error={errors?.businessName.length > 0}
                                        helperText={
                                            errors?.businessName.length > 0
                                                ? errors?.businessName
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='corp-businessName'
                                        // label="First Name"
                                        name='businessName'
                                        onChange={(e: any) => {
                                            updateField(
                                                'businessName',
                                                e.target.value
                                            );
                                        }}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    />
                                </div>
                            ) : (
                                <p className='text-appcolorgreydeep leading-[16px] text-[13px] w-[50%] text-right'>
                                    {payload?.businessName || '-'}
                                </p>
                            )}
                        </div>

                        <div className='flex justify-between mb-8'>
                            <p className='text-appcolorfaintsix font-semibold leading-[16px] text-[13px]'>
                                CAC No.
                            </p>

                            {editMode ? (
                                <div className='w-[50%] text-roght'>
                                    <TextField
                                        value={payload?.rcNumber}
                                        error={errors?.rcNumber.length > 0}
                                        helperText={
                                            errors?.rcNumber.length > 0
                                                ? errors?.rcNumber
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='corp-rcNumber'
                                        name='rcNumber'
                                        onChange={(e: any) => {
                                            updateField(
                                                'rcNumber',
                                                e.target.value
                                            );
                                        }}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    />
                                </div>
                            ) : (
                                <p className='text-appcolorgreydeep leading-[16px] text-[13px] w-[50%] text-right'>
                                    {payload?.rcNumber || '-'}
                                </p>
                            )}
                        </div>

                        <div className='flex justify-between mb-8'>
                            <p className='text-appcolorfaintsix font-semibold leading-[16px] text-[13px] '>
                                Registered Address
                            </p>
                            {editMode ? (
                                <div className='w-[50%] text-roght'>
                                    <TextField
                                        multiline
                                        value={payload?.registeredAddress}
                                        error={
                                            errors?.registeredAddress.length > 0
                                        }
                                        helperText={
                                            errors?.registeredAddress.length > 0
                                                ? errors?.registeredAddress
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='corp-registeredAddress'
                                        // label="First Name"
                                        name='rcNumber'
                                        onChange={(e: any) => {
                                            updateField(
                                                'registeredAddress',
                                                e.target.value
                                            );
                                        }}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    />
                                </div>
                            ) : (
                                <p className='text-appcolorgreydeep leading-[16px] text-[13px] w-[50%] text-right'>
                                    {payload?.registeredAddress || '-'}
                                </p>
                            )}
                        </div>
                        <div className='flex justify-between mb-8'>
                            <p className='text-appcolorfaintsix font-semibold leading-[16px] text-[13px]'>
                                Office Address
                            </p>

                            {editMode ? (
                                <div className='w-[50%] text-roght'>
                                    <TextField
                                        multiline
                                        value={payload?.officeAddress}
                                        error={errors?.officeAddress.length > 0}
                                        helperText={
                                            errors?.officeAddress.length > 0
                                                ? errors?.officeAddress
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='corp-officeAddress'
                                        name='rcNumber'
                                        onChange={(e: any) => {
                                            updateField(
                                                'officeAddress',
                                                e.target.value
                                            );
                                        }}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    />
                                </div>
                            ) : (
                                <p className='text-appcolorgreydeep leading-[16px] text-[13px] w-[50%] text-right'>
                                    {payload?.officeAddress || '-'}
                                </p>
                            )}
                        </div>

                        <div className='flex justify-between mb-8'>
                            <p className='text-appcolorfaintsix font-semibold leading-[16px] text-[13px]'>
                                Business Email
                            </p>
                            <p className='text-appcolorgreydeep leading-[16px] text-[13px] w-[50%] text-right'>
                                {payload?.businessEmail || '-'}
                            </p>
                        </div>

                        <div className='flex justify-between mb-8'>
                            <p className='text-appcolorfaintsix font-semibold leading-[16px] text-[13px]'>
                                Business Phone no
                            </p>

                            {editMode ? (
                                <div className='w-[50%] text-roght'>
                                    <TextField
                                        value={payload?.businessPhoneNumber}
                                        error={
                                            errors?.businessPhoneNumber.length >
                                            0
                                        }
                                        helperText={
                                            errors?.businessPhoneNumber.length >
                                            0
                                                ? errors?.businessPhoneNumber
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='corp-businessPhoneNumber'
                                        name='rcNumber'
                                        onChange={(e: any) => {
                                            updateField(
                                                'businessPhoneNumber',
                                                e.target.value
                                            );
                                        }}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    />
                                </div>
                            ) : (
                                <p className='text-appcolorgreydeep leading-[16px] text-[13px] w-[50%] text-right'>
                                    {payload?.businessPhoneNumber || '-'}
                                </p>
                            )}
                        </div>

                        <div className='flex justify-between mb-8'>
                            <p className='text-appcolorfaintsix font-semibold leading-[16px] text-[13px]'>
                                Website
                            </p>

                            {editMode ? (
                                <div className='w-[50%] text-roght'>
                                    <TextField
                                        value={payload?.businessWebsite}
                                        error={
                                            errors?.businessWebsite.length > 0
                                        }
                                        helperText={
                                            errors?.businessWebsite.length > 0
                                                ? errors?.businessWebsite
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='corp-businessWebsite'
                                        // label="First Name"
                                        name='rcNumber'
                                        onChange={(e: any) => {
                                            updateField(
                                                'businessWebsite',
                                                e.target.value
                                            );
                                        }}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    />
                                </div>
                            ) : (
                                <p className='text-appcolorgreydeep leading-[16px] text-[13px] w-[50%] text-right'>
                                    {payload?.businessWebsite || '-'}
                                </p>
                            )}
                        </div>

                        <div className='flex justify-between'>
                            <p className='text-appcolorfaintsix font-semibold leading-[16px] text-[13px]'>
                                Currency
                            </p>
                            <p className='text-appcolorgreydeep leading-[16px] text-[13px] w-[50%] text-right'>
                                {payload?.defaultCurrency || '-'}
                            </p>
                        </div>
                    </div>

                    <div>
                        <p className='leading-[16px] text-[13px] font-bold mb-8'>
                            Business Registration Document
                        </p>

                        <div className='flex justify-between mb-8'>
                            <p className='text-appcolorfaintsix font-semibold leading-[16px] text-[13px]'>
                                Annual Turnover
                            </p>

                            {editMode ? (
                                <div className='w-[50%] text-roght'>
                                    <TextField
                                        value={payload?.annualTurnover}
                                        error={
                                            errors?.annualTurnover.length > 0
                                        }
                                        helperText={
                                            errors?.annualTurnover.length > 0
                                                ? errors?.annualTurnover
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='corp-annualTurnover'
                                        // label="First Name"
                                        name='rcNumber'
                                        onChange={(e: any) => {
                                            updateField(
                                                'annualTurnover',
                                                e.target.value
                                            );
                                        }}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    />
                                </div>
                            ) : (
                                <p className='text-appcolorgreydeep leading-[16px] text-[13px] w-[50%] text-right'>
                                    {payload?.annualTurnover || '-'}
                                </p>
                            )}
                        </div>

                        <div className='flex justify-between mb-8'>
                            <p className='text-appcolorfaintsix font-semibold leading-[16px] text-[13px]'>
                                Date Created
                            </p>
                            <p className='text-appcolorgreydeep leading-[16px] text-[13px] w-[50%] text-right'>
                                {payload?.createdDate ? (
                                    <>
                                        {' '}
                                        {moment(
                                            parseInt(payload?.createdDate)
                                        ).format('ll')}
                                    </>
                                ) : (
                                    '-'
                                )}
                            </p>
                        </div>

                        <div className='flex justify-between mb-8'>
                            <p className='text-appcolorfaintsix font-semibold leading-[16px] text-[13px]'>
                                Industry
                            </p>
                            <p className='text-appcolorgreydeep leading-[16px] text-[13px] w-[50%] text-right'>
                                {payload?.corporateIndustryId ? (
                                    <>
                                        {' '}
                                        {industries.filter(
                                            (itemIndustry) =>
                                                itemIndustry.id ===
                                                payload?.corporateIndustryId
                                        )[0]?.name || '-'}
                                    </>
                                ) : (
                                    '-'
                                )}
                            </p>
                        </div>

                        <div className='flex justify-between mb-8'>
                            <p className='text-appcolorfaintsix font-semibold leading-[16px] text-[13px]'>
                                Country Code
                            </p>
                            <p className='text-appcolorgreydeep leading-[16px] text-[13px] w-[50%] text-right'>
                                {payload?.countryCode || '-'}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Corpdetails;
