import React, { useRef, useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useLocation, useNavigate } from 'react-router-dom';
import { menuList } from '../../../utils/routes';
import Menuitem from './menuitem/menuitem';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { logoutUser } from '../../../store/userSlice/userSlice';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import Grow from '@mui/material/Grow';
import CustomModal from '../../CustomModal';
import Onboardingdocs from './onboardingdocs/onboardingdocs';
import Updatebusinessdetails from './updatebusinessdetails/updatebusinessdetails';
import jwtDecode from 'jwt-decode';
import { refreshToken } from '../../../api/base/base';
import { isTokenExpired, setToken } from '../../../pages/auth';
import { useGetQuery } from '../../../customhooks/useGetQuery';
import { Notifications } from './Notifications';
import { Notification } from '../interfaces/Notification';
import EnablingFeature from '../../EnablingFeature';
import { canShow, hasPermission } from './utils';
import { User } from '../../../interfaces/User';
import { mutateConfig } from '../../../api/queryBase';
import { useQueryClient } from 'react-query';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import ProtectedDashboardPage from './ProtectedDashboardPage';
import ScrollToTop from '../../../customhooks/ScrollToTop';
import { FrontEdgeLogo } from '../../icons/FrontEdgeLogo';
import { LogoutIcon, MenuIcon, NotificationsBellIcon } from '../../icons';
import { useMediaQuery, useTheme } from '@mui/material';

const drawerWidth = 260;
interface Props {
    window?: () => Window;
    fullWidth?: boolean;
}

export default function ResponsiveDrawer(props: Props) {
    const { window } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const state = useSelector((state: RootState) => ({
        user: state.user.user,
        token: state.user.token
    }));

    const { user, token } = state;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [showSetupBusinessInfo, setShowSetupBusinessInfo] = useState(false);
    const [showDocModal, setShowDocModal] = useState(false);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [openNotification, setOpenNotification] = useState(false);
    const anchorRefNotification = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);
    const { entries: notifications = [] } = useGetQuery<Notification[]>({
        url: '/corporate/notification',
        cacheKey: 'notifications'
    });
    const mutation = mutateConfig({ method: 'put' });
    const { mutate } = mutation({
        url: `/corporate/notifications/read`,
        cacheKeys: 'notifications'
    });

    const closeShowSetupBusinessInfo = () => {
        setShowSetupBusinessInfo(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event?.target as Node)
        ) {
            return;
        }

        setOpen(false);
    };

    const handleListKeyDown = (
        event: React.KeyboardEvent<HTMLUListElement>
    ) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    };

    const handleLogout = () => {
        setOpen(false);
        queryClient.removeQueries();
        navigate('/login');
        dispatch(logoutUser());
    };

    const handleToggleNotification = () => {
        setOpenNotification((prevOpen) => !prevOpen);
    };

    const onHandleNotificationClick = (notificationIds: number[]) => {
        if (notificationIds.length) {
            mutate({ notificationIds });
        }
    };

    const handleCloseNotification = (event?: MouseEvent | TouchEvent) => {
        if (
            anchorRefNotification.current &&
            anchorRefNotification.current.contains(event?.target as Node)
        ) {
            return;
        }

        setOpenNotification(false);
    };

    const handleListKeyDownNotification = (
        event: React.KeyboardEvent<HTMLUListElement>
    ) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenNotification(false);
        }
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const { permissions } = useUserProfile();

    const drawer = (
        <div className='relative h-[100vh]'>
            <div className='px-[1.6rem] pt-[0.8rem]'>
                <div>
                    <img src='/img/logo/logo.svg' alt='logo' />
                </div>
            </div>
            {permissions && (
                <div className='mt-[2rem]'>
                    <List component='nav'>
                        {menuList
                            .filter(
                                (item) =>
                                    canShow(user, item) &&
                                    hasPermission({
                                        userPermissions: permissions,
                                        module: item.permissionTitle ?? '',
                                        permissionList: ['read', 'write', '*'],
                                        feature: item.feature
                                    })
                            )
                            .map((item, index) => (
                                <Menuitem
                                    key={index}
                                    itemDetails={item}
                                    isActive={location.pathname.includes(
                                        item?.route ?? ''
                                    )}
                                />
                            ))}
                    </List>
                </div>
            )}

            <div className='absolute bottom-0 h-[76px] py-4 pl-[32px] shadow-[#00103D0F_0px_0px_6px_0px] w-full'>
                <button
                    className='flex gap-4 items-center text-alizarinCrimson text-sm font-semibold'
                    onClick={handleLogout}
                >
                    <LogoutIcon />
                    Logout
                </button>
            </div>
        </div>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    useEffect(() => {
        if (user?.corporate_status === 3 || user?.corporate_status === 4) {
            setShowDocModal(true);
        }
    }, []);

    useEffect(() => {
        if (user?.user_level !== 5) {
            if (
                user?.has_business_type === false ||
                user?.has_business_industry === false
            ) {
                setShowSetupBusinessInfo(true);
            }
        }
    }, [user]);

    useEffect(() => {
        const refresh = async (): Promise<void> => {
            try {
                const res = await refreshToken();
                const { access_token, refresh_token } = res.data.data;
                const user: User = jwtDecode(access_token);
                setToken(access_token);
                localStorage.setItem('refreshToken', refresh_token);
                localStorage.setItem('exp', user.exp.toString());
            } catch (error) {
                handleLogout();
            }
        };

        if (token) {
            const interval = setInterval(() => {
                if (!isTokenExpired()) {
                    refresh();
                } else {
                    clearInterval(interval);
                    handleLogout();
                }
            }, 21540000);
        } else {
            handleLogout();
        }
    }, []);

    //handles logout on all tabs
    useEffect(() => {
        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === 'token' && event.newValue === null) {
                handleLogout();
                console.log('User logged out in another tab');
            }
        };

        addEventListener('storage', handleStorageChange);
        return () => {
            removeEventListener('storage', handleStorageChange);
        };
    }, []);

    return (
        <EnablingFeature flag='Accessible'>
            <Box
                sx={{
                    display: 'flex',
                    backgroundColor: '#F1F1F1',
                    overflowY: 'hidden'
                }}
            >
                <CssBaseline />
                <AppBar
                    position='fixed'
                    sx={{
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        height: '64px',
                        justifyContent: 'center',
                        ml: { sm: `${drawerWidth}px` },
                        backgroundColor: '#FFFFFF'
                    }}
                >
                    <Toolbar>
                        <div className='md:hidden mr-auto'>
                            <FrontEdgeLogo />
                        </div>
                        <div className='flex justify-end md:w-full w-fit bg-textcolorwhite  pr-[1.6rem] items-center bg-appcolorwhite py-1'>
                            <div className='flex'>
                                <div
                                    className='mr-[1.1rem] ml-[1.1rem] cursor-pointer'
                                    ref={anchorRefNotification}
                                    aria-controls={
                                        openNotification
                                            ? 'notification-list'
                                            : undefined
                                    }
                                    aria-haspopup='true'
                                    onClick={handleToggleNotification}
                                >
                                    <Badge
                                        badgeContent={
                                            notifications.filter(
                                                ({ status }) => status === 1
                                            ).length
                                        }
                                        color='error'
                                    >
                                        {isMobile ? (
                                            <NotificationsBellIcon />
                                        ) : (
                                            <img
                                                src='/img/vectors/bell.svg'
                                                alt='bell'
                                            />
                                        )}
                                    </Badge>
                                </div>

                                <Popper
                                    open={openNotification}
                                    anchorEl={anchorRefNotification.current}
                                    role={undefined}
                                    transition
                                    disablePortal
                                    placement='bottom'
                                    sx={{
                                        width: '400px'
                                    }}
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                    placement === 'bottom'
                                                        ? 'center top'
                                                        : 'center bottom'
                                            }}
                                        >
                                            <Paper>
                                                <ClickAwayListener
                                                    onClickAway={
                                                        handleCloseNotification
                                                    }
                                                >
                                                    <MenuList
                                                        autoFocusItem={open}
                                                        id='notification-list'
                                                        onKeyDown={
                                                            handleListKeyDownNotification
                                                        }
                                                    >
                                                        <Notifications
                                                            {...{
                                                                handleCloseNotification,
                                                                notifications,
                                                                onHandleNotificationClick
                                                            }}
                                                        />
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>

                                <div className='hidden md:block mr-[1.1rem] cursor-pointer'>
                                    <img
                                        src='/img/vectors/user.svg'
                                        alt='user'
                                    />
                                </div>

                                <div>
                                    <div
                                        className='hidden md:flex cursor-pointer'
                                        ref={anchorRef}
                                        aria-controls={
                                            open ? 'menu-list' : undefined
                                        }
                                        aria-haspopup='true'
                                        onClick={handleToggle}
                                    >
                                        <p className='text-appcolorprimary'>
                                            {user?.name}
                                        </p>
                                        <span>
                                            <KeyboardArrowDownIcon
                                                fontSize='small'
                                                color='primary'
                                            />
                                        </span>
                                    </div>
                                </div>

                                <Popper
                                    open={open}
                                    anchorEl={anchorRef.current}
                                    role={undefined}
                                    transition
                                    disablePortal
                                    placement='bottom-end'
                                >
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{
                                                transformOrigin:
                                                    placement === 'bottom'
                                                        ? 'center top'
                                                        : 'center bottom'
                                            }}
                                        >
                                            <Paper>
                                                <ClickAwayListener
                                                    onClickAway={handleClose}
                                                >
                                                    <MenuList
                                                        autoFocusItem={open}
                                                        id='menu-list'
                                                        onKeyDown={(e) =>
                                                            handleListKeyDown(e)
                                                        }
                                                    >
                                                        <MenuItem
                                                            onClick={
                                                                handleLogout
                                                            }
                                                        >
                                                            Logout
                                                        </MenuItem>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </div>
                        </div>
                        <IconButton
                            color='primary'
                            aria-label='open drawer'
                            edge='start'
                            onClick={handleDrawerToggle}
                            sx={{ display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box
                    component='nav'
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label='mailbox folders'
                >
                    <Drawer
                        container={container}
                        variant='temporary'
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth
                            }
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant='permanent'
                        sx={{
                            display: { xs: 'none', sm: 'block' },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth
                            }
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component='main'
                    sx={{
                        flexGrow: 1,
                        p: {
                            lg: '32px 16px',
                            xs: props.fullWidth ? '' : '32px 0'
                        },
                        width: {
                            sm: `calc(100% - ${drawerWidth}px)`
                        },
                        minHeight: '100vh',
                        maxHeight: '100%',
                        backgroundColor: '#F1F1F1',
                        position: 'relative'
                    }}
                >
                    <Toolbar />

                    <div>
                        <ProtectedDashboardPage fullWidth={props.fullWidth} />
                        <ScrollToTop />
                    </div>
                </Box>

                {showDocModal && (
                    <CustomModal
                        maxWidth='md'
                        open={showDocModal}
                        onClose={() => {}}
                        title='Upload Business Documents'
                    >
                        <Onboardingdocs handleLogout={handleLogout} />
                    </CustomModal>
                )}

                {showSetupBusinessInfo && (
                    <CustomModal
                        maxWidth='md'
                        open={showSetupBusinessInfo}
                        onClose={closeShowSetupBusinessInfo}
                        title='Update Business Details'
                        subTitle='Kindly update your business details'
                    >
                        <Updatebusinessdetails
                            user={user}
                            onClose={closeShowSetupBusinessInfo}
                        />
                    </CustomModal>
                )}
            </Box>
        </EnablingFeature>
    );
}
