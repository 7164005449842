export const MoneySendIcon = () => (
    <svg
        width='19'
        height='19'
        viewBox='0 0 19 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M10.5375 12.2724H9.12751C8.13001 12.2724 7.3125 11.4324 7.3125 10.3974C7.3125 10.0899 7.5675 9.83495 7.875 9.83495C8.1825 9.83495 8.4375 10.0899 8.4375 10.3974C8.4375 10.8099 8.74501 11.1474 9.12751 11.1474H10.5375C10.83 11.1474 11.0625 10.885 11.0625 10.5625C11.0625 10.1575 10.95 10.0974 10.695 10.0074L8.4375 9.21994C7.9575 9.04744 7.3125 8.70245 7.3125 7.59995C7.3125 6.66245 8.05501 5.88995 8.96251 5.88995H10.3725C11.37 5.88995 12.1875 6.72995 12.1875 7.76495C12.1875 8.07245 11.9325 8.32745 11.625 8.32745C11.3175 8.32745 11.0625 8.07245 11.0625 7.76495C11.0625 7.35245 10.755 7.01495 10.3725 7.01495H8.96251C8.67001 7.01495 8.4375 7.27745 8.4375 7.59995C8.4375 8.00495 8.54999 8.06495 8.80499 8.15495L11.0625 8.94245C11.5425 9.11495 12.1875 9.45995 12.1875 10.5625C12.1875 11.5075 11.445 12.2724 10.5375 12.2724Z'
            fill='#0088B2'
        />
        <path
            d='M9.75 13.0225C9.4425 13.0225 9.1875 12.7675 9.1875 12.46V5.70996C9.1875 5.40246 9.4425 5.14746 9.75 5.14746C10.0575 5.14746 10.3125 5.40246 10.3125 5.70996V12.46C10.3125 12.7675 10.0575 13.0225 9.75 13.0225Z'
            fill='#0088B2'
        />
        <path
            d='M9.75 17.1475C5.3025 17.1475 1.6875 13.5325 1.6875 9.08496C1.6875 4.63746 5.3025 1.02246 9.75 1.02246C10.0575 1.02246 10.3125 1.27746 10.3125 1.58496C10.3125 1.89246 10.0575 2.14746 9.75 2.14746C5.925 2.14746 2.8125 5.25996 2.8125 9.08496C2.8125 12.91 5.925 16.0225 9.75 16.0225C13.575 16.0225 16.6875 12.91 16.6875 9.08496C16.6875 8.77746 16.9425 8.52246 17.25 8.52246C17.5575 8.52246 17.8125 8.77746 17.8125 9.08496C17.8125 13.5325 14.1975 17.1475 9.75 17.1475Z'
            fill='#0088B2'
        />
        <path
            d='M17.25 5.14746C16.9425 5.14746 16.6875 4.89246 16.6875 4.58496V2.14746H14.25C13.9425 2.14746 13.6875 1.89246 13.6875 1.58496C13.6875 1.27746 13.9425 1.02246 14.25 1.02246H17.25C17.5575 1.02246 17.8125 1.27746 17.8125 1.58496V4.58496C17.8125 4.89246 17.5575 5.14746 17.25 5.14746Z'
            fill='#0088B2'
        />
        <path
            d='M13.5001 5.89746C13.3576 5.89746 13.2151 5.84496 13.1026 5.73246C12.8851 5.51496 12.8851 5.15496 13.1026 4.93746L16.8526 1.18746C17.0701 0.969963 17.4301 0.969963 17.6476 1.18746C17.8651 1.40496 17.8651 1.76496 17.6476 1.98246L13.8976 5.73246C13.7851 5.84496 13.6426 5.89746 13.5001 5.89746Z'
            fill='#0088B2'
        />
    </svg>
);
