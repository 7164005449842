import { Dispatch } from 'react';
import useCurrencies from '../../../customhooks/useCurrencies';
import { mutateConfig } from '../../../api/queryBase';
import { toast } from 'react-toastify';
import useWallets from '../../../customhooks/wallets/useWallets';
import { Box, Paper } from '@mui/material';
import { CurrencySelect } from '../../../components/CurrencySelect';
import { SuitUIButton } from '../../../components/SuitUIButton';
import { getErrorMessage } from '../../../utils/helper';

const G3Currencies = ['USD', 'EUR', 'GBP'];

const AddAccount = ({
    currencyCode,
    setShowG3Modal,
    setShowAddAccount,
    setCurrencyCode
}: {
    currencyCode: string;
    setShowG3Modal: Dispatch<React.SetStateAction<boolean>>;
    setShowAddAccount: Dispatch<React.SetStateAction<boolean>>;
    setCurrencyCode: Dispatch<React.SetStateAction<string>>;
}) => {
    const { currencies } = useCurrencies({
        all: false
    });
    const { wallets } = useWallets();
    const mutation = mutateConfig({});
    const { mutate } = mutation({
        url: `/wallet/currency/${currencyCode}`
    });

    const onHandleSubmit = () => {
        mutate(
            {},
            {
                onSettled: (_, error) => {
                    if (error) {
                        toast.error(
                            getErrorMessage(error) ||
                                'An error has occured while creating an account'
                        );
                        return;
                    }
                    setShowAddAccount(false);
                    toast.info('Account created successfully');
                }
            }
        );
    };

    return (
        <Paper elevation={0} className='h-full flex flex-col justify-between'>
            <Box>
                <h1 className='text-base text-ebony lg:text-[24px] lg:leading-[23px] lg:text-appcolorblacktwo lg:font-semibold'>
                    Add Wallet
                </h1>

                <p className='text-sm text-steelBlue mt-8 mb-[24px] font-medium'>
                    Please select the currency for new wallet
                </p>

                <CurrencySelect
                    onHandleChange={(value) => {
                        if (value !== null) {
                            setCurrencyCode(value.currencyCode);
                        }
                    }}
                    options={currencies.filter(({ currencyCode }) => {
                        return !wallets.some(
                            (wallet) => wallet.currencyCode === currencyCode
                        );
                    })}
                />
            </Box>

            <SuitUIButton
                text='+ Request Account'
                onClick={() => {
                    if (G3Currencies.includes(currencyCode)) {
                        setShowAddAccount(false);
                        setShowG3Modal(true);
                        return;
                    }

                    onHandleSubmit();
                }}
                disabled={currencyCode.length === 0}
                fullWidth
                style='mt-8'
            />
        </Paper>
    );
};

export default AddAccount;
