import React, { useEffect } from 'react';
import Authouterpage from '../components/authouterpage/authouterpage';
import { Form, Formik, FormikHelpers } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
    getErrorMessage,
    openNotification,
    validatePassword
} from '../utils/helper';
import Passwordinput from '../components/passwordinput/passwordinput';
import Custombutton from '../components/custombutton/custombutton';
import { logoutUser } from '../store/userSlice/userSlice';
import { useDispatch } from 'react-redux';
import { mutateConfig } from '../api/queryBase';
import { AxiosError } from 'axios';

const InvitedUserPassword = () => {
    interface InvitePasswordValues {
        email: string | null;
        password: string;
        confirmPassword: string;
        inviteCode: string | null;
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const mutation = mutateConfig();
    const { mutate: mutateAcceptInvite } = mutation({
        url: `users/invite/accept`
    });

    const initialValues: InvitePasswordValues = {
        email: query.get('email'),
        password: '',
        confirmPassword: '',
        inviteCode: query.get('code')
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required('Password is required')
            .test('password', 'Invalid password', (value) =>
                validatePassword(value)
            ),
        confirmPassword: Yup.string().oneOf(
            [Yup.ref('password'), ''],
            'Passwords do not match'
        )
    });

    const handleSubmit = async (
        values: InvitePasswordValues,
        { setSubmitting }: FormikHelpers<InvitePasswordValues>
    ) => {
        console.log(values);
        setSubmitting(true);
        const { email, inviteCode, password } = values;

        mutateAcceptInvite(
            { email, inviteCode, password },
            {
                onSettled: (_, error: AxiosError | undefined | any) => {
                    if (error) {
                        setSubmitting(false);

                        openNotification(
                            'error',
                            getErrorMessage(error) || 'Something went wrong'
                        );
                        return;
                    }
                    setSubmitting(false);
                },
                onSuccess: () => {
                    setSubmitting(false);

                    openNotification(
                        'success',
                        'Password was updated successfully was successful.'
                    );

                    navigate('/login');
                }
            }
        );
    };

    useEffect(() => {
        dispatch(logoutUser());
    }, []);

    return (
        <div>
            <Authouterpage
                title='ADD PASSWORD'
                info='Welcome'
                infoText='To get started with your new account, please update your password.'
            >
                <div>
                    <div className='bg-appcolorwhite py-[2.7rem] lg:py-[3.5rem] lg:px-[2.6rem] px-[1.6rem] mb-[1.75rem] rounded-[3px]'>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({
                                errors,
                                isValid,
                                values,
                                setFieldValue,
                                touched,
                                isSubmitting
                            }) => (
                                <Form autoComplete='off' noValidate>
                                    <Passwordinput
                                        id='password'
                                        label='Password'
                                        name='password'
                                        updateField={setFieldValue}
                                        error={
                                            touched?.password &&
                                            !!errors?.password
                                        }
                                        helperText={
                                            touched?.password
                                                ? errors?.password
                                                : undefined
                                        }
                                    />
                                    <Passwordinput
                                        id='confirmPassword'
                                        label='Re-enter Password'
                                        name='confirmPassword'
                                        updateField={setFieldValue}
                                        error={
                                            touched?.confirmPassword &&
                                            !!errors?.confirmPassword
                                        }
                                        helperText={
                                            touched?.confirmPassword
                                                ? errors?.confirmPassword
                                                : undefined
                                        }
                                    />
                                    <div className='mt-4'>
                                        <Custombutton
                                            isLoadingButton
                                            loading={isSubmitting}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            styles={{
                                                padding:
                                                    '0.75rem 0.62rem 1rem 0.93rem'
                                            }}
                                            disabled={!isValid || isSubmitting}
                                            buttonText='Continue'
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Authouterpage>
        </div>
    );
};

export default InvitedUserPassword;
