export const ExportTwoIcon = () => (
    <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M13.7 7.41675C16.7 7.67508 17.925 9.21675 17.925 12.5917V12.7001C17.925 16.4251 16.4333 17.9167 12.7083 17.9167H7.28332C3.55832 17.9167 2.06665 16.4251 2.06665 12.7001V12.5917C2.06665 9.24175 3.27498 7.70008 6.22498 7.42508'
            stroke='#344054'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M10 12.4999V3.0166'
            stroke='#344054'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M12.7917 4.87492L10 2.08325L7.20837 4.87492'
            stroke='#344054'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
