import { getCountryFlag } from '../../utils/helper';
import { Wallet } from '../../pages/dashboard/payments/interfaces/Wallet';
import { useEffect, useState } from 'react';
import { queryConfig } from '../../api/queryBase';

const useWallets = () => {
    const [wallets, setWallets] = useState<Wallet[]>([]);
    const query = queryConfig();
    const { isLoading, data } = query<Wallet[]>({
        url: '/wallet',
        cacheKey: 'wallets'
    });

    useEffect(() => {
        const unsorted = (data?.payload || []).map((wallet) => ({
            ...wallet,
            nameAndFlag: `${getCountryFlag(wallet.currencyCode)} ${
                wallet.currencyCode
            }`
        }));
        setWallets(unsorted.sort((a, b) => b.ledgerBalance - a.ledgerBalance));
    }, [isLoading]);

    return { isLoading, wallets };
};

export default useWallets;
