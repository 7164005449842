import { Stack } from '@mui/material';
import { Table } from 'antd';
import moment from 'moment';
import React, { FC } from 'react';
import { formatCurrency, formatNumber } from '../../../../utils/helper';
import { REACT_APP_BASE_URL } from '../../../../config';
import DocumentViewer from '../../../DocumentViewer/DocumentViewer';

interface IProps {
    data: any;
}

const ProformaView: FC<IProps> = ({ data }: IProps) => {
    const getTotal = () => {
        let totalNumber = 0;

        data?.items?.forEach((item: any) => {
            totalNumber +=
                (isNaN(item?.unitPrice) ? 0 : Number(item?.unitPrice)) *
                (isNaN(item?.quantity) ? 0 : Number(item?.quantity));
        });
        return totalNumber;
    };
    return (
        <>
            <Stack
                direction='row'
                alignItems='start'
                className='top-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='font-semibold text-appcolorprimary'>
                        Invoice From
                    </p>
                    <p className='font-medium'>{data?.seller?.name}</p>
                    <p>{data?.seller?.address}</p>
                    <p>{data?.seller?.bankCountry}</p>
                </div>

                <div className='view-two'>
                    <p className='font-semibold text-appcolorprimary'>
                        Invoice To
                    </p>
                    <p className='font-medium'>{data?.receiver?.name}</p>
                    <p>{data?.receiver?.address}</p>
                    <p>{data?.receiver?.bankCountry}</p>
                </div>

                <div className='view-three'>
                    <div></div>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Invoice No.</p>
                        <p className='value'>{data?.invoiceNumber || '--'}</p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Date</p>
                        <p className='value'>
                            {moment(data?.invoiceDate).format('ll') || '--'}
                        </p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Buyer Ref.</p>
                        <p className='value'>{data?.buyerReference || '--'}</p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Port of Loading</p>
                        <p className='value'>
                            {data?.portOfLoading?.name || '--'}
                        </p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Port of Discharge</p>
                        <p className='value'>
                            {data?.portOfDischarge?.name || '--'}
                        </p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Method of Dispatch</p>
                        <p className='value'>
                            {data?.methodOfDispatch || '--'}
                        </p>
                    </Stack>

                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Type of Shipment</p>
                        <p className='value'>{data?.typeOfShipment || '--'}</p>
                    </Stack>
                </div>
            </Stack>

            <div className='table-view'>
                <Table
                    rowKey={'id'}
                    columns={[
                        {
                            title: 'Product Code',
                            dataIndex: 'productCode',
                            key: 'productCode'
                        },
                        {
                            title: 'Description of goods',
                            dataIndex: 'descriptionOfGoods',
                            key: 'descriptionOfGoods'
                        },
                        {
                            title: 'Quantity',
                            dataIndex: 'quantity',
                            key: 'quantity',
                            render: (text) => <span>{formatNumber(text)}</span>
                        },
                        {
                            title: 'Unit type',
                            dataIndex: 'unitType',
                            key: 'unitType'
                        },
                        {
                            title: 'Price',
                            dataIndex: 'unitPrice',
                            key: 'unitPrice',
                            render: (text) => (
                                <span>
                                    {formatCurrency(text, data?.currencyCode)}
                                </span>
                            )
                        },
                        {
                            title: 'Total',
                            dataIndex: '',
                            key: '',
                            render: (text, record: any) => (
                                <span>
                                    {formatCurrency(
                                        (isNaN(record?.unitPrice)
                                            ? 0
                                            : record?.unitPrice) *
                                            (isNaN(record?.quantity)
                                                ? 0
                                                : record?.quantity),
                                        data?.currencyCode
                                    )}
                                </span>
                            )
                        }
                    ]}
                    dataSource={data?.items}
                    pagination={false}
                />
            </div>

            <Stack direction='row' justifyContent='flex-end'>
                <div className='preview-total'>
                    Total amount :{' '}
                    <span className=''>
                        {formatCurrency(getTotal(), data?.currencyCode)}
                    </span>
                </div>
            </Stack>

            <Stack
                direction='row'
                alignItems='start'
                className='bottom-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='titl'>Additional Information</p>
                    <p>{data?.additionalInformation}</p>

                    <div className='w-[100%] mt-[10px]'>
                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='titl'>Bank Name</p>
                            <p className='value'>
                                {data?.seller?.bankName || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='titl'>Account No.</p>
                            <p className='value'>
                                {data?.seller?.bankAccountNumber || '--'}
                            </p>
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='titl'>Swift Code</p>
                            <p className='value'>
                                {data?.seller?.swiftCode || '--'}
                            </p>
                        </Stack>
                    </div>
                </div>

                <div className='view-two'>
                    <p className='titl'>Place of Issue</p>
                    <p className=''>{data?.placeOfIssue || '--'}</p>
                    <p className='titl'>Signatory Company</p>
                    <p>{data?.signatoryCompany || '--'}</p>
                    {data?.stamp && (
                        <Stack direction='row' justifyContent='flex-end'>
                            <div className='totalView w-[250px]'>
                                <p className='titl'>Stamp</p>
                                <div className='h-[50px] w-[100px] img'>
                                    <DocumentViewer
                                        fileUrl={`${REACT_APP_BASE_URL}/${data?.stamp.split(
                                            '/v1/'
                                        )[1]}`}
                                    />
                                </div>
                            </div>
                        </Stack>
                    )}
                </div>

                <div className='view-three'>
                    <p className='titl'>Date of Issue</p>
                    <p className=''>
                        {moment(data?.dateOfIssue).format('ll') || '--'}
                    </p>

                    <p className='titl'>Name of Authorized Signatory</p>
                    <p>{data?.nameOfAuthorisedSignatory || '--'}</p>
                    <p className='titl'>Signature</p>
                    <div className='h-[50px] w-[100px] img'>
                        <DocumentViewer
                            fileUrl={`${REACT_APP_BASE_URL}/${data?.signatureUrl.split(
                                '/v1/'
                            )[1]}`}
                        />
                    </div>
                </div>
            </Stack>
        </>
    );
};

export default ProformaView;
