import { Dropdown, MenuProps, Table } from 'antd'
import React, { useState } from 'react'
import { getCorpotateStatus } from '../../../utils/helper'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
interface IProps {
  loadingData: boolean
  data?: any
  fetch?: any
}

const AllRegisteredTable = ({ data, loadingData }: IProps) => {
  const navigate = useNavigate()
  const [currentData, setCurrentData] = useState<any>()

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="flex items-center"
          onClick={() => {
            navigate(`/corporates/${currentData?.id}`)
          }}
        >
          <img src="/img/vectors/eyesicon.svg" alt="eyesicon" />
          <span className="ml-2">Review Application</span>
        </div>
      ),
    },
  ]
  return (
    <>
      <Table
        loading={loadingData}
        pagination={{ pageSize: 20 }}
        dataSource={data?.content}
        columns={[
          {
            title: 'Last Modified',
            dataIndex: 'lastModifiedDate',
            key: 'lastModifiedDate',
            render: (_, { lastModifiedDate }) => (
              <span>{moment(parseInt(lastModifiedDate)).format('ll')}</span>
            ),
          },
          {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (_, { createdDate }) => (
              <span>{moment(parseInt(createdDate)).format('ll')}</span>
            ),
          },

          {
            title: 'Business Name',
            dataIndex: 'businessName',
            key: 'businessName',
            render: (text) => <span>{text}</span>,
          },

          {
            title: 'Industry',
            dataIndex: 'industryName',
            key: 'industryName',
            render: (text) => <span>{text}</span>,
          },
          {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, { status }) => (
              <span>{getCorpotateStatus(status)}</span>
            ),
          },
          {
            title: '',
            render: (_, data) => (
              <div
                className="cursor-pointer"
                onClick={() => {
                  setCurrentData(data)
                }}
              >
                <Dropdown menu={{ items }} trigger={['click']}>
                  <img src="/img/vectors/more.svg" alt="more" />
                </Dropdown>
              </div>
            ),
          },
        ]}
      />
    </>
  )
}

export default AllRegisteredTable
