import { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Placerequest from './placerequest/placerequest';
import Selectbeneficiary from './selectbeneficiary/selectbeneficiary';
import Confirmation from './confirmation/confirmation';

import { openNotification } from '../../../../utils/helper';
import { getFXRate } from '../../../../api/wallets/wallets';
import { Beneficiary } from '../interfaces/Beneficiary';
import { PaymentRequest } from '../interfaces/PaymentRequest';
import useBeneficiaries from '../../../../customhooks/beneficiaries/useBeneficiaries';
import { usePaymentpurposes } from '../../../../customhooks/paymentpurpose/usePaymentpurposes';
import useWallets from '../../../../customhooks/wallets/useWallets';
import CustomStepper from '../../../../components/CustomStepper';

const Makepayment = () => {
    const [conversionType, setConversionType] = useState(1);
    const [recipientChoosen, setRecipientChoosen] = useState<Beneficiary>(
        {} as Beneficiary
    );
    const [rate, setRate] = useState(0);
    const [rateExpiry, setRateExpiry] = useState(null);
    const [activeStep, setActiveStep] = useState(0);
    const [requestObject, setRequestObject] = useState<PaymentRequest>({
        convertAmount: 0.0,
        convertCurrency: 'NGN',
        receiveAmount: 0.0,
        receiveCurrency: 'USD',
        calcConvert: 0,
        calcReceive: 0
    });
    const [, setRateReversed] = useState(0);
    const [loadRate, setLoadRate] = useState(false);
    const [, setVfxToken] = useState('');

    const {
        beneficiaries,
        isLoading: isBeneficiariesLoading,
        invalidateBeneficiaryCache: fetchBeneficiaries
    } = useBeneficiaries();

    const { paymentPurposes, loadingPaymentPurposes, fetchPaymentpurposes } =
        usePaymentpurposes();

    const { wallets, isLoading: isWalletsLoading } = useWallets();

    const { convertAmount, receiveAmount, convertCurrency, receiveCurrency } =
        requestObject;

    const steps = [
        {
            label: 'Enter amount to transfer'
        },
        {
            label: 'Select Benefiary'
        },
        {
            label: 'Confirmation'
        }
    ];

    const updateConversionType = (value: number) => {
        setConversionType(value);
    };

    /**
     * Updates the value of the recipientChoosen variable
     * @param value Object
     */
    const updateRecipientChoosen = (value: any) => {
        setRecipientChoosen(value);
    };

    /**
     * Update number of step
     * @param activeStep number
     */
    const updateStep = (activeStep: number) => {
        setActiveStep(activeStep);
    };

    const updateRequestObject = (value: any) => {
        setRequestObject(value);
    };

    /**
     * Ger rate and run multipliction
     * Rate changes only on change in currencies of convert and receive
     */
    const getRate = useCallback(async () => {
        try {
            setLoadRate(true);
            if (convertCurrency === receiveCurrency) {
                setRate(1);
                setRateReversed(1);
                setVfxToken('');
                setLoadRate(false);
            } else {
                const res = await getFXRate({
                    currencyFrom: convertCurrency,
                    currencyTo: receiveCurrency
                });
                const { rate, vfx_token } = res.data.data;

                setRate(rate);
                setVfxToken(vfx_token);
                setLoadRate(false);
            }
        } catch (error) {
            setLoadRate(false);
            if (error?.response) {
                openNotification('error', 'Get rate, Something went wrong');
            }
        }
    }, [convertCurrency, receiveCurrency]);

    useEffect(() => {
        if (rate === 0 || (!convertAmount && !receiveAmount)) return;

        const newRequest = { ...requestObject };

        newRequest.receiveAmount = rate * convertAmount;

        updateRequestObject({
            ...requestObject,
            ...newRequest
        });
    }, [rate]);

    const reRunRateCallAction = () => {
        getRate();
    };

    useEffect(() => {
        getRate();
    }, [getRate]);

    const [rerunRateCall, setRerunRateCall] = useState(false);

    const updateReRunRateCall = (value: boolean) => {
        setRerunRateCall(value);
    };

    return (
        <div className=''>
            <div>
                <Grid container>
                    <Grid item xs={12} md={12} lg={8}>
                        <div
                            className={`lg:bg-appcolorwhite lg:rounded-[3px] lg:py-[2.3rem] lg:px-[5.1rem]`}
                        >
                            <div className='px-4'>
                                <CustomStepper
                                    steps={steps}
                                    activeStep={activeStep}
                                />
                            </div>
                            {activeStep === 0 && (
                                <Placerequest
                                    activeStep={activeStep}
                                    updateStep={updateStep}
                                    requestObject={requestObject}
                                    updateRequestObject={updateRequestObject}
                                    rate={rate}
                                    loadRate={loadRate}
                                    reRunRateCallAction={reRunRateCallAction}
                                    conversionType={conversionType}
                                    wallets={wallets}
                                    isWalletsLoading={isWalletsLoading}
                                />
                            )}

                            {activeStep === 1 && (
                                <Selectbeneficiary
                                    activeStep={activeStep}
                                    updateStep={updateStep}
                                    requestObject={requestObject}
                                    updateRequestObject={updateRequestObject}
                                    conversionType={conversionType}
                                    updateRecipientChoosen={
                                        updateRecipientChoosen
                                    }
                                    updateConversionType={updateConversionType}
                                    beneficiaries={beneficiaries}
                                    isBeneficiariesLoading={
                                        isBeneficiariesLoading
                                    }
                                    fetchBeneficiaries={fetchBeneficiaries}
                                    paymentPurposes={paymentPurposes}
                                    loadingPaymentPurposes={
                                        loadingPaymentPurposes
                                    }
                                />
                            )}

                            {activeStep === 2 && (
                                <Confirmation
                                    updateStep={updateStep}
                                    requestObject={requestObject}
                                    conversionType={conversionType}
                                    rate={rate}
                                    recipientChoosen={recipientChoosen}
                                    sourceWallet={wallets.find(
                                        (item) =>
                                            item.currencyCode ===
                                            requestObject.convertCurrency
                                    )}
                                />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Makepayment;
