import { Checkbox, CheckboxProps } from '@mui/material';
import { CheckBoxCheckedIcon, CheckBoxIcon } from './icons';

const CustomCheckBox = ({ onChange, checked }: CheckboxProps) => {
    return (
        <Checkbox
            icon={<CheckBoxIcon />}
            checkedIcon={<CheckBoxCheckedIcon />}
            onChange={onChange}
            checked={checked}
        />
    );
};

export default CustomCheckBox;
