import { http } from '..';
import { paginatedData } from '../../utils/helper-interface';

export interface getTrackingPayload extends paginatedData {}

export interface getBookingsPayload extends paginatedData {
    bookingReference?: string;
}

export interface getShippingLinesPayload extends paginatedData {
    booking?: number;
    shipping?: number;
}

export interface carrierConnectionPayload {
    annualShipmentTeu: string;
    bookingNumber: string;
    contractNumber: string;
    customerNumber: string;
    requesterContractNumber: string;
    fullName: string;
    taxId: string;
}

export interface bookingProfilePayload {
    address: string;
    jobFunction: string;
    postCode: string;
    state: string;
    taxId: string;
}

export interface createBookingExistingPayload {
    agent: string;
    billOfLadingNumber: string;
    salesContractNumber: string;
    shippingLineId: 0;
}

/**
 * Get bookings
 * @param data Object
 * @returns http response
 */
export const getBookings = (data: getBookingsPayload) => {
    return http.get(`/booking`, {
        params: {
            ...data
        }
    });
};

export const getShippingLines = (data: getShippingLinesPayload) => {
    return http.get(`/booking/shipping/lines`, {
        params: {
            ...data
        }
    });
};

export const getBookingMetrics = (corporateId: number) => {
    return http.get(`/booking/corporate/${corporateId}/metrics`);
};

export const carrierConnection = (
    corporateId: number,
    shippingLineId: number,
    data: carrierConnectionPayload
) => {
    return http.post(
        `/booking/corporate/${corporateId}/shipping/${shippingLineId}/profile/create`,
        data
    );
};

export const bookingProfile = (corporateId: number, data: any) => {
    return http.post(`/booking/corporate/${corporateId}/profile/create`, data);
};

export const createBookingExisting = (data: createBookingExistingPayload) => {
    return http.post(`/booking/existing/create`, data);
};

export const getBooking = (data: {
    bookingId: string;
    corporateId: number;
}) => {
    return http.get(`/booking/${data.bookingId}/corporate/${data.corporateId}`);
};

export const getTracking = (data: getTrackingPayload) => {
    return http.get(`/tracking`, {
        params: {
            ...data
        }
    });
};

export const addCorporateParties = (data: any) => {
    return http.post(`/booking/corporate/parties/create`, data);
};

export const createTracking = (corporateId: string, data: any) => {
    return http.post(`/tracking/corporate/${corporateId}/create`, data);
};

export const getMoreTrackingReference = (trackingReference: string) => {
    return http.get(`/tracking/${trackingReference}`);
};

export const getBookingProfile = (data: getTrackingPayload) => {
    return http.get(`/booking/profiles`, {
        params: {
            ...data
        }
    });
};

export const getShippingProfile = (data: getTrackingPayload) => {
    return http.get(`/booking/shipping/profiles`, {
        params: {
            ...data
        }
    });
};

export const approveBooking = (
    bookingId: number,
    data: {
        bookingNumber: string;
    }
) => {
    return http.post(
        `/booking/${bookingId}/approve?bookingNumber=${data?.bookingNumber}`
    );
};

export const approveShippingProfile = (
  shippingProfileId: number|string,
  data: {
    contractNumber:string
    inttraAlias:string
  },
) => {
  return http.post(
    `/booking/corporate/profile/shipping/${shippingProfileId}/approve?contractNumber=${data?.contractNumber}&inttraAlias=${data?.inttraAlias}`,
  )
}

export const getShippingProfileDetails = (id: number) => {
    return http.get(`/booking/shipping/profiles/${id}`);
};

export const approveBookingProfile = (
    corporateId: number | string,
    data: {
        inttraCompanyId: string;
    }
) => {
    return http.post(
        `/booking/corporate/${corporateId}/profile/approve?inttraCompanyId=${data.inttraCompanyId}`
    );
};

export const getBookingProfileDetails = (id: number) => {
    return http.get(`/booking/corporate/${id}/profile`);
};
