import {
    Box,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { FileSelector } from '../../../components/FileSelector';
import { ChangeEvent, useState } from 'react';
import { UploadProgressInformation } from '../../../components/UploadProgressInformation';
import { SuitUIButton } from '../../../components/SuitUIButton';
import { PhoneInput } from '../../../components/PhoneInput';
import { z } from 'zod';
import { mutateConfig } from '../../../api/queryBase';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../utils/helper';

const Location = z.object({
    fullAddress: z.string().min(5).optional(),
    proofOfAddressUrl: z.string().min(5).optional()
});

const InsuranceProfile = z.object({
    corporateName: z.string().min(3),
    rcNumber: z.string().min(5),
    taxId: z.string().min(5),
    address: Location,
    phoneNumber: z.string().min(10),
    emailAddress: z.string().email()
});

const documents = [
    {
        key: 'proofOfAddressUrl',
        label: 'Proof of address eg Utility, Bank Statement'
    },
    { key: 'cacUrl', label: 'CAC' },
    { key: 'c02Url', label: 'CAC Form 02' },
    { key: 'c07Url', label: 'CAC Form 07' }
];

interface FileUri {
    uri: string;
    reference: string;
}

export const InsuranceProfileForm = () => {
    const [files, setFiles] = useState<{ reference: string; file: File }[]>([]);
    const [doc, setDoc] = useState('');
    const [fileUris, setFileUris] = useState<FileUri[]>([]);
    const onHandleSettled = (params: { uri: string; reference: string }) => {
        const { uri, reference } = params;
        if (reference === 'proofOfAddressUrl') {
            setProfile((v) => ({
                ...v,
                address: { ...v.address, proofOfAddressUrl: uri }
            }));
        }
        setFileUris((current) => [...current, params]);
    };
    const onDeleteHandler = (reference: string) => {
        setFileUris((current) =>
            current.filter((target) => target.reference !== reference)
        );
        setFiles((current) =>
            current.filter((entry) => !reference.includes(entry.reference))
        );
    };
    const [profile, setProfile] = useState<
        Partial<z.infer<typeof InsuranceProfile>>
    >({});
    const [errors, setErrors] = useState<Record<string, string>>({});
    const mutation = mutateConfig({});
    const { mutate } = mutation({
        url: `/insurance/profile`
    });

    return (
        <Box>
            <Typography sx={{ fontWeight: 600, fontSize: '28px', py: 4 }}>
                Profile creation form
            </Typography>

            <Box className='grid grid-cols-1 gap-6 w-[441px]'>
                <TextField
                    error={!!errors['corporateName']}
                    label='Corporate Name'
                    sx={{ background: 'white' }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const { value: corporateName } = event.target;
                        setProfile((v) => ({ ...v, corporateName }));
                    }}
                />
                <TextField
                    error={!!errors['rcNumber']}
                    label='Company Registration Number (RC Number)'
                    sx={{ background: 'white' }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const { value: rcNumber } = event.target;
                        setProfile((v) => ({ ...v, rcNumber }));
                    }}
                />
                <TextField
                    error={!!errors['taxId']}
                    label='Tax ID'
                    sx={{ background: 'white' }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const { value: taxId } = event.target;
                        setProfile((v) => ({ ...v, taxId }));
                    }}
                />
                <TextField
                    error={!!errors['address']}
                    label='Address'
                    sx={{ background: 'white' }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const { value: fullAddress } = event.target;
                        setProfile((v) => ({
                            ...v,
                            address: { ...v.address, fullAddress }
                        }));
                    }}
                />
                <PhoneInput
                    error={!!errors['phoneNumber']}
                    onChange={(phone: string) => {
                        const phoneNumber = phone.replace('+', '');
                        setProfile((v) => ({ ...v, phoneNumber }));
                    }}
                />
                <TextField
                    error={!!errors['emailAddress']}
                    type='email'
                    label='Email'
                    placeholder='hello@mail.com'
                    sx={{
                        background: 'white'
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <IconButton aria-label='mail'>
                                    <MailOutlineIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const { value: emailAddress } = event.target;
                        setProfile((v) => ({ ...v, emailAddress }));
                    }}
                />
                <Box>
                    <Typography className='!mb-[12px]'>Documents</Typography>
                    <InputLabel id='cac-selector'>Select a CAC Form</InputLabel>
                    <Select
                        sx={{
                            background: 'white',
                            color: 'black',
                            width: '100%'
                        }}
                        displayEmpty
                        labelId='cac-selector'
                        value={doc}
                        onChange={(event) => {
                            const { value } = event.target;
                            setDoc(value as string);
                        }}
                    >
                        <MenuItem value=''>None</MenuItem>
                        {documents.map(({ key, label }, index) => (
                            <MenuItem
                                key={index}
                                disabled={files.some(
                                    (entry) => key === entry.reference
                                )}
                                value={key}
                            >
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                {files?.map(({ file, reference }, index) => (
                    <UploadProgressInformation
                        key={index}
                        file={file}
                        reference={reference}
                        onHandleSettled={onHandleSettled}
                        onDeleteHandler={onDeleteHandler}
                    />
                ))}
                <FileSelector
                    {...{
                        label: 'SVG, PNG, JPG or GIF (max. 800x400px)',
                        enabled: Boolean(doc),
                        onFilesSelectedHandler: ({ files, input }) => {
                            const [file] = files;
                            setFiles((current) => [
                                ...current,
                                { reference: doc, file }
                            ]);
                            input && document.body.removeChild(input);
                        }
                    }}
                />
                <SuitUIButton
                    text='Create Profile'
                    onClick={() => {
                        const validate = InsuranceProfile.safeParse(profile);

                        if (!validate.success) {
                            setErrors({});

                            const { issues } = validate.error;
                            for (const { message, path } of issues) {
                                const [field] = path;
                                setErrors((error) => ({
                                    ...error,
                                    [field]: message
                                }));
                            }

                            return;
                        }

                        setErrors({});
                        const docs = fileUris.reduce((a, b) => {
                            const { reference, uri } = b;

                            a = { ...a, [reference]: uri };
                            return a;
                        }, {});
                        mutate(
                            { ...profile, ...docs },
                            {
                                onSettled: (_, error) => {
                                    if (error) {
                                        toast.error(
                                            getErrorMessage(error) ||
                                                'Upload failed and a report has been raised. Please try again later'
                                        );
                                        return;
                                    }

                                    toast.success(
                                        'Profile successfully created'
                                    );
                                }
                            }
                        );
                    }}
                />
            </Box>
        </Box>
    );
};
