export const HierachyIcon = () => {
    return (
        <svg
            width='56'
            height='56'
            viewBox='0 0 56 56'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                x='4'
                y='4'
                width='48'
                height='48'
                rx='24'
                fill='#0088B2'
                fillOpacity='0.1'
            />
            <rect
                x='4'
                y='4'
                width='48'
                height='48'
                rx='24'
                stroke='#0088B2'
                strokeOpacity='0.1'
                strokeWidth='8'
            />
            <path
                d='M21 31V24'
                stroke='#0088B2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M21.25 38C23.0449 38 24.5 36.5449 24.5 34.75C24.5 32.9551 23.0449 31.5 21.25 31.5C19.4551 31.5 18 32.9551 18 34.75C18 36.5449 19.4551 38 21.25 38Z'
                stroke='#0088B2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M21 24C22.6569 24 24 22.6569 24 21C24 19.3431 22.6569 18 21 18C19.3431 18 18 19.3431 18 21C18 22.6569 19.3431 24 21 24Z'
                stroke='#0088B2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M35 24C36.6569 24 38 22.6569 38 21C38 19.3431 36.6569 18 35 18C33.3431 18 32 19.3431 32 21C32 22.6569 33.3431 24 35 24Z'
                stroke='#0088B2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M21.1289 31C21.5789 29.25 23.1789 27.95 25.0689 27.96L28.4989 27.97C31.1189 27.98 33.3489 26.3 34.1689 23.96'
                stroke='#0088B2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
