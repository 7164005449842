import { useEffect, useState } from 'react';
import { queryConfig } from '../api/queryBase';
import { Currency } from '../pages/dashboard/payments/interfaces/Currency';

const useCurrencies = ({ all = true }: { all?: boolean } = {}) => {
    const [currencies, setCurrencies] = useState<Currency[]>([]);
    const query = queryConfig();
    const { isLoading, data } = query<Currency[]>({
        url: '/currencies',
        cacheKey: 'currencies',
        params: { all }
    });

    useEffect(() => {
        setCurrencies(data?.payload || []);
    }, [isLoading]);

    return { isLoading, currencies };
};

export default useCurrencies;
