import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Collapse, Timeline } from 'antd';
import { SuitUIButton } from '../../../../../components/SuitUIButton';

const { Panel } = Collapse;

export interface shipmentitemProps {
    item: any;
    isExtra?: boolean;
}

const Shipmentitem = ({ item, isExtra }: shipmentitemProps) => {
    const navigate = useNavigate();

    const [tabValue, setTabValue] = useState('Route');
    const [routesData, setRoutesData] = useState<any[]>([]);
    const [containerData, setContainerData] = useState<any[]>([]);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const getLocationName = (
        locationId: number,
        locations: {
            name: string;
            id: number;
            countryCode: string;
        }[]
    ) => {
        if (locationId === undefined || locations.length === 0) {
            return '-';
        } else {
            const filteredLocation = locations.filter(
                (item) => item.id === locationId
            );
            if (filteredLocation.length > 0) {
                return `${filteredLocation[0].name}, ${filteredLocation[0].countryCode}`;
            } else {
                return '-';
            }
        }
    };

    useEffect(() => {
        if (isExtra) {
            const { routes, locations, containers } = item.details;

            const newRouteData: any[] = [];

            routes
                .filter((item: any) => item.type === 2 || item.type === 3)
                .forEach((item: any) => {
                    newRouteData.push({
                        children: (
                            <div>
                                <p className='mb-1 font-semibold'>
                                    {getLocationName(
                                        item.locationId,
                                        locations
                                    )}
                                </p>
                                <div className='flex justify-between'>
                                    <span>
                                        {item.type === 3 && 'Transport Arrival'}
                                        {item.type === 2 &&
                                            'Transport Departure'}
                                    </span>
                                    <span>
                                        {' '}
                                        {moment(item.date).format('ll')}
                                    </span>
                                </div>
                            </div>
                        )
                    });
                });

            setRoutesData(newRouteData);

            const newContainers: any[] = [];

            containers.forEach((item: any) => {
                const panelData: any = [];
                const toRender: any = [];

                item.events.forEach((itemInside: any) => {
                    const toPush: any = {
                        title: getLocationName(
                            itemInside.locationId,
                            locations
                        ),
                        related: [],
                        locationId: itemInside.locationId
                    };

                    if (
                        panelData.filter(
                            (checkInside: any) =>
                                checkInside.locationId === itemInside.locationId
                        ).length > 0
                    ) {
                        const findIndexOf = panelData.findIndex(
                            (itemFind: any) =>
                                itemFind.locationId === toPush.locationId
                        );

                        panelData[findIndexOf].related.push({
                            description: itemInside.description,
                            date: moment(itemInside.date).format('ll')
                        });
                    } else {
                        toPush.related.push({
                            description: itemInside.description,
                            date: moment(itemInside.date).format('ll')
                        });
                        panelData.push(toPush);
                    }
                });

                panelData.forEach((panelDataItem: any) => {
                    const children = (
                        <>
                            <p className='mb-1 font-semibold'>
                                {panelDataItem.title}
                            </p>
                            {panelDataItem.related.map(
                                (renderText: any, i: number) => (
                                    <div
                                        className='flex justify-between mb-1'
                                        key={i}
                                    >
                                        <span className='w-[50%]'>
                                            {renderText.description}
                                        </span>
                                        <span className='w-[50%] text-right'>
                                            {renderText.date}
                                        </span>
                                    </div>
                                )
                            )}
                        </>
                    );

                    toRender.push({
                        children
                    });
                });

                newContainers.push({
                    ...item,
                    panelData,
                    toRender
                });
            });

            setContainerData(newContainers);
        }
    }, []);

    return (
        <div
            className='rounded-[3px] bg-appcolorwhite py-[1.2rem] px-[1.2rem] mb-8'
            style={{
                cursor: isExtra ? 'initial' : 'pointer'
            }}
            onClick={() => {
                if (!isExtra) {
                    navigate(
                        `/bookings/track-shipments/${item.trackingReference}`
                    );
                }
            }}
        >
            <div className='flex justify-between mb-2'>
                <div className='text-[14px] leading-[24px]'>
                    <span className='pr-3 text-appcolorgrefaint'>BL</span>
                    <span className='text-appcolorblacktwo'>
                        {item?.trackingReference}
                    </span>
                </div>
                <div>
                    <p className='w-fit bg-appcolorprimaryfaintthree py-[0.3rem] px-[0.6rem] text-appcolorprimary rounded-[3px] text-[12px]'>
                        IN TRANSIT
                    </p>
                </div>
            </div>

            <div className='flex justify-between text-[14px] leading-[24px] text-appcolorblacktwo mb-2'>
                <p>
                    {item?.portOfLoading}, {item?.countryOfLoading}
                </p>
                <p>
                    {item?.portOfDischarge}, {item?.countryOfDischarge}
                </p>
            </div>

            <div className='relative'>
                <div className='relative flex items-center mb-2'>
                    <div className='bg-appcolorprimary rounded-[50%] w-[0.7rem] h-[0.7rem] z-[2]'></div>
                    <div className='border border-dashed grow border-appcolorgrefaint'></div>
                    <div className='bg-appcolorgrefaint rounded-[50%] w-[0.7rem] h-[0.7rem] z-[2]'></div>
                </div>
                <div
                    className={`border-appcolorprimary border-[2px] w-[20%] absolute top-[0.3rem] left-0 z-[1] mx-[0.6rem]`}
                ></div>
            </div>

            <div className='flex'>
                <span>ETD: {moment(item?.departureDate).format('ll')}</span>

                <span className='pl-3'>
                    ETA: {moment(item?.arrivalDate).format('ll')}
                </span>
            </div>

            <span>Shipping line: {item?.shippingLineName}</span>

            {isExtra ? (
                <>
                    <div className='mt-4 hidden lg:block'>
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            textColor='primary'
                            indicatorColor='primary'
                            aria-label=''
                        >
                            <Tab
                                value='Route'
                                label='Route'
                                sx={{
                                    paddingBottom: '0px',
                                    textTransform: 'capitalize'
                                }}
                            />
                            <Tab
                                value='Vessel(s)'
                                label='Vessel'
                                sx={{
                                    paddingBottom: '0px',
                                    textTransform: 'capitalize'
                                }}
                            />
                            <Tab
                                value='Containers'
                                label='Containers'
                                sx={{
                                    paddingBottom: '0px',
                                    textTransform: 'capitalize'
                                }}
                            />
                        </Tabs>
                    </div>

                    <div className='lg:hidden flex mt-4 gap-6'>
                        <SuitUIButton
                            text='Route'
                            type='button'
                            style='py-2'
                            onClick={() => setTabValue('Route')}
                            variant={
                                tabValue === 'Route' ? 'primary' : 'secondary'
                            }
                        />
                        <SuitUIButton
                            text='Vessel'
                            type='button'
                            style='py-2'
                            onClick={() => setTabValue('Vessel(s)')}
                            variant={
                                tabValue === 'Vessel(s)'
                                    ? 'primary'
                                    : 'secondary'
                            }
                        />
                        <SuitUIButton
                            text='Containers'
                            type='button'
                            style='py-2'
                            onClick={() => setTabValue('Containers')}
                            variant={
                                tabValue === 'Containers'
                                    ? 'primary'
                                    : 'secondary'
                            }
                        />
                    </div>
                    <div>
                        {tabValue === 'Route' ? (
                            <div className='mt-4'>
                                {routesData.length > 0 ? (
                                    <Timeline items={routesData} />
                                ) : (
                                    <p>There is no route data available</p>
                                )}
                            </div>
                        ) : null}

                        {tabValue === 'Vessel(s)' ? (
                            <>
                                {item?.details?.vessels.length > 0 ? (
                                    <>
                                        <Collapse
                                            bordered={false}
                                            accordion
                                            className='mt-[1rem]'
                                        >
                                            {item?.details?.vessels?.map(
                                                (it: any) => (
                                                    <Panel
                                                        header={it?.name}
                                                        key={item?.name}
                                                    >
                                                        <div className='px-6 mt-4'>
                                                            <div className='flex w-[50%] mb-2'>
                                                                <span>
                                                                    Name:
                                                                </span>
                                                                <span className='pl-2 text-appcolorprimary'>
                                                                    {it?.name}
                                                                </span>
                                                            </div>

                                                            <div className='flex w-[50%] mb-2'>
                                                                <span>
                                                                    Call Sign:
                                                                </span>
                                                                <span className='pl-2 text-appcolorprimary'>
                                                                    {
                                                                        it?.callSign
                                                                    }
                                                                </span>
                                                            </div>

                                                            <div className='flex w-[50%] mb-2'>
                                                                <span>
                                                                    IMO:
                                                                </span>
                                                                <span className='pl-2 text-appcolorprimary'>
                                                                    {it?.imo}
                                                                </span>
                                                            </div>

                                                            <div className='flex w-[50%] mb-2'>
                                                                <span>
                                                                    MMSI:
                                                                </span>
                                                                <span className='pl-2 text-appcolorprimary'>
                                                                    {it?.mmsi}
                                                                </span>
                                                            </div>

                                                            <div className='flex w-[50%] mb-2'>
                                                                <span>
                                                                    Flag:
                                                                </span>
                                                                <span className='pl-2 text-appcolorprimary'>
                                                                    {it?.flag}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Panel>
                                                )
                                            )}
                                        </Collapse>
                                    </>
                                ) : (
                                    <p className='mt-4'>
                                        There is no vessel data available
                                    </p>
                                )}
                            </>
                        ) : null}

                        {tabValue === 'Containers' ? (
                            <>
                                {containerData.length > 0 ? (
                                    <>
                                        <div className='mt-4 '>
                                            <Collapse
                                                bordered={false}
                                                accordion
                                            >
                                                {containerData.map((item) => (
                                                    <Panel
                                                        header={item?.number}
                                                        key={item?.number}
                                                    >
                                                        <Timeline
                                                            items={
                                                                item.toRender
                                                            }
                                                        />
                                                    </Panel>
                                                ))}
                                            </Collapse>
                                        </div>
                                    </>
                                ) : (
                                    <p className='mt-4'>
                                        There is no container data available
                                    </p>
                                )}
                            </>
                        ) : null}
                    </div>
                </>
            ) : null}
        </div>
    );
};

export default Shipmentitem;
