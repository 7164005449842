import AnchorIcon from '@mui/icons-material/Anchor';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

interface AnchorLocationProps {
    location: string;
    date?: string;
}

const AnchorLocation = (props: AnchorLocationProps) => {
    return (
        <Box display='flex'>
            <AnchorIcon sx={{ mr: 2, color: 'black' }} />
            <Box display='block'>
                <b>{props.location}</b>
                {props.date && (
                    <p>
                        {dayjs
                            .unix(Number(props.date))
                            .format('DD-MMM-YYYY')
                            .toUpperCase()}
                    </p>
                )}
            </Box>
        </Box>
    );
};

export default AnchorLocation;
