import {
    Box,
    InputAdornment,
    Stack,
    TextField,
    Divider,
    Autocomplete,
    CircularProgress,
    MenuItem
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { EditIcon } from '../../../components/icons/EditIcon';
import { object, string } from 'yup';
import { useFormik } from 'formik';
import MessageEnvelope from '../../../components/icons/MessageEnvelope';
import Custombutton from '../../../components/custombutton/custombutton';
import { CurrentUserProfile } from '../../../interfaces/User';
import { Country } from '../../../interfaces/Country';
import { BusinessType } from '../../../customhooks/businesstypes/useBusinesstypes';
import { Industry } from '../../../customhooks/industries/useIndustries';
import { mutateConfig } from '../../../api/queryBase';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { getErrorMessage } from '../../../utils/helper';
import CustomTextField from '../../../components/CustomTextInput';

const ProfileAccountDetails = ({
    userDetails,
    countries,
    isCountriesLoading,
    isBusinessTypesLoading,
    businessTypes,
    industries,
    isIndustriesLoading
}: {
    userDetails: CurrentUserProfile;
    countries: Country[];
    isCountriesLoading: boolean;
    isBusinessTypesLoading: boolean;
    businessTypes: BusinessType[];
    industries: Industry[];
    isIndustriesLoading: boolean;
}) => {
    const queryClient = useQueryClient();
    const mutation = mutateConfig({ method: 'patch' });
    const { mutate } = mutation({
        url: `/me`
    });

    const formik = useFormik({
        initialValues: {
            firstName: userDetails.firstName ?? '',
            lastName: userDetails.lastName ?? '',
            corporateCountryCode: userDetails?.business?.country ?? '',
            industry: userDetails?.business?.industry ?? '',
            businessType: userDetails?.business?.businessTypes ?? [],
            phoneNumber: userDetails?.phoneNumber ?? '',
            email: userDetails?.email ?? '',
            corporateName: userDetails?.business?.name ?? '',
            corporateIndustryId:
                industries?.find(
                    (item) => item.name === userDetails?.business?.industry
                )?.id ?? ''
        },
        validationSchema: object({
            lastName: string().required(),
            firstName: string().required(),
            corporateName: string().required(),
            industry: string().required(),
            corporateCountryCode: string().required(),
            phoneNumber: string().required()
        }),
        validateOnMount: true,
        onSubmit: (values) => {
            formik.setSubmitting(true);
            const { businessType, industry, email, ...data } = values;
            mutate(data, {
                onSettled: (_, error: AxiosError | undefined | any) => {
                    formik.setSubmitting(false);
                    if (error) {
                        toast.error(
                            getErrorMessage(error) ||
                                'Profile Update failed. Please try again later'
                        );
                        return;
                    }
                    queryClient.invalidateQueries('user-profile');
                    toast.info('Profile Updated successfully');
                    setEditValues(false);
                }
            });
        }
    });

    const [editValues, setEditValues] = useState(false);
    useEffect(() => {
        if (userDetails) {
            formik.setValues({
                firstName: userDetails.firstName || '',
                lastName: userDetails.lastName || '',
                corporateCountryCode: userDetails?.business?.country || '',
                industry: userDetails?.business?.industry || '',
                businessType: userDetails?.business?.businessTypes || [],
                phoneNumber: userDetails?.phoneNumber || '',
                email: userDetails?.email || '',
                corporateName: userDetails?.business?.name || '',
                corporateIndustryId:
                    industries.find(
                        (item) => item.name === userDetails?.business?.industry
                    )?.id || ''
            });
        }
    }, [userDetails, industries]);

    return (
        <>
            <Stack
                direction='column'
                className=' bg-white max-w-[618px] lg:p-[16px] px-[16px] py-[8px] rounded-[8px] lg:border-[1px] border-appcolorfaintnine mt-3'
                gap='5px'
            >
                <Stack
                    justifyContent='space-between'
                    alignItems='center'
                    direction='row'
                >
                    <h3 className='color-[#1D2939] text-[16px] font-[500]'>
                        Account Details
                    </h3>
                    {!editValues && (
                        <Box
                            className='bg-white flex items-center justify-center rounded-[8px] border border-[#EAECF0] p-[12px] cursor-pointer hover:bg-gray-100'
                            onClick={() => setEditValues(!editValues)}
                        >
                            <EditIcon />
                        </Box>
                    )}
                </Stack>
                <Divider orientation='horizontal' className='py-1' />
                <Box
                    justifyContent='space-between'
                    alignItems='center'
                    className=' flex lg:flex-row flex-col lg:gap-4 gap-2 mt-0 lg:mt-4'
                >
                    <div className='flex-1 w-full lg:w-max'>
                        <CustomTextField
                            label='First Name'
                            disabled={!editValues}
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            name='firstName'
                        />
                    </div>
                    <div className='flex-1 w-full lg:w-max'>
                        <CustomTextField
                            label='Last Name'
                            disabled={!editValues}
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            name='lastName'
                        />
                    </div>
                </Box>

                <div className='lg:mt-4'>
                    <CustomTextField
                        label='Email'
                        name='email'
                        disabled
                        InputProps={{
                            startAdornment: (
                                <InputAdornment
                                    position='start'
                                    className='ml-4'
                                >
                                    <MessageEnvelope />
                                </InputAdornment>
                            )
                        }}
                        value={formik.values.email}
                    />
                </div>
                <div className='lg:mt-4'>
                    <CustomTextField
                        label='Business Name'
                        disabled={!editValues}
                        onChange={formik.handleChange}
                        value={formik.values.corporateName}
                        name='corporateName'
                    />
                </div>
                {editValues ? (
                    <div className='lg:mt-2'>
                        <Autocomplete
                            disableClearable
                            options={industries}
                            value={industries.find(
                                (item) => item.name === formik.values.industry
                            )}
                            sx={{
                                marginTop: '.5rem',
                                flex: 1
                            }}
                            renderInput={(params) => (
                                <CustomTextField
                                    params={params}
                                    label='Industry'
                                    name='industry'
                                    value={
                                        industries.find(
                                            (item) =>
                                                item.name ===
                                                formik.values.industry
                                        )?.name
                                    }
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: isIndustriesLoading && (
                                            <>
                                                <CircularProgress size={20} />
                                                {params.InputProps.endAdornment}
                                            </>
                                        )
                                    }}
                                />
                            )}
                            noOptionsText={'No data available'}
                            renderOption={(props, option) => (
                                <MenuItem
                                    {...props}
                                    value={option.name}
                                    key={`industries-${option.id}`}
                                >
                                    <div className='flex items-center'>
                                        <span className='ml-2'>
                                            {option.name}
                                        </span>
                                    </div>
                                </MenuItem>
                            )}
                            getOptionLabel={(option) => option.name}
                            onChange={(_, value) => {
                                if (value !== null) {
                                    formik.setValues((prev) => ({
                                        ...prev,
                                        industry: value.name,
                                        corporateIndustryId: value.id
                                    }));
                                }
                            }}
                        />
                    </div>
                ) : (
                    <div className='lg:mt-4'>
                        <CustomTextField
                            label='Industry'
                            name='industry'
                            disabled={true}
                            value={formik.values.industry}
                        />
                    </div>
                )}

                <Autocomplete
                    multiple
                    disableClearable
                    disabled={true}
                    id='signup-businessTypes'
                    options={businessTypes}
                    sx={{
                        marginTop: '.5rem'
                    }}
                    value={businessTypes?.filter(
                        (option) =>
                            formik.values.businessType?.includes(option.name)
                    )}
                    renderInput={(params) => (
                        <div className=' mt-2'>
                            <TextField
                                {...params}
                                label='Business Type'
                                name='businessType'
                                sx={{
                                    '.MuiInputBase-root': {
                                        borderRadius: '10px'
                                    }
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: isBusinessTypesLoading && (
                                        <React.Fragment>
                                            <CircularProgress size={20} />
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    )
                                }}
                            />
                        </div>
                    )}
                    noOptionsText={'No data available'}
                    renderOption={(props, option) => (
                        <MenuItem
                            {...props}
                            value={option.id}
                            key={`business-type-${option.id}`}
                        >
                            <div className='flex items-center'>
                                <span className='ml-2'>{option.name}</span>
                            </div>
                        </MenuItem>
                    )}
                    getOptionLabel={(option) => option.name}
                />

                <Box
                    justifyContent='space-between'
                    alignItems='center'
                    className=' flex lg:flex-row flex-col lg:gap-4 gap-2 mt-0 lg:mt-4'
                >
                    <div className='flex-1 w-full lg:w-max'>
                        {!editValues ? (
                            <CustomTextField
                                name='country'
                                disabled={!editValues}
                                label='Country'
                                value={
                                    countries.find(
                                        (item) =>
                                            item.countryCode ===
                                            formik.values.corporateCountryCode
                                    )?.name
                                }
                            />
                        ) : (
                            <Autocomplete
                                disableClearable
                                options={countries}
                                value={countries.find(
                                    (item) =>
                                        item.countryCode ===
                                        formik.values.corporateCountryCode
                                )}
                                sx={{
                                    flex: 1
                                }}
                                renderInput={(params) => (
                                    <CustomTextField
                                        params={params}
                                        name='country'
                                        disabled={!editValues}
                                        label='Country'
                                        value={
                                            countries.find(
                                                (item) =>
                                                    item.countryCode ===
                                                    formik.values
                                                        .corporateCountryCode
                                            )?.name
                                        }
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment:
                                                isCountriesLoading && (
                                                    <>
                                                        <CircularProgress
                                                            size={20}
                                                        />
                                                        {
                                                            params.InputProps
                                                                .endAdornment
                                                        }
                                                    </>
                                                )
                                        }}
                                    />
                                )}
                                noOptionsText={'No data available'}
                                renderOption={(props, option) => (
                                    <MenuItem
                                        {...props}
                                        value={option.countryCode2}
                                        key={`countries-${option.id}`}
                                    >
                                        <div className='flex items-center'>
                                            <img
                                                src={option?.flagImageUrl}
                                                alt={`${option.name}-flag`}
                                                className='w-4 h-4'
                                            />
                                            <span className='ml-2'>
                                                {option.name}
                                            </span>
                                        </div>
                                    </MenuItem>
                                )}
                                getOptionLabel={(option) => option.name}
                                onChange={(_, value) => {
                                    if (value !== null) {
                                        formik.setValues((prev) => ({
                                            ...prev,
                                            corporateCountryCode:
                                                value.countryCode
                                        }));
                                    }
                                }}
                            />
                        )}
                    </div>
                    <div className='flex-1 w-full lg:w-max'>
                        <CustomTextField
                            label='Phone Number'
                            disabled={!editValues}
                            onChange={formik.handleChange}
                            value={formik.values.phoneNumber}
                            name='phoneNumber'
                        />
                    </div>
                </Box>

                {editValues && (
                    <Stack
                        justifyContent='flex-end'
                        alignItems='center'
                        direction='row'
                        gap='15px'
                        mt='20px'
                    >
                        <Custombutton
                            type='button'
                            variant='outlined'
                            buttonText='Cancel'
                            styles={{
                                padding: '0.75rem 0.62rem',
                                width: '25%'
                            }}
                            onClickAction={() => {
                                setEditValues(false);
                            }}
                        />

                        <Custombutton
                            isLoadingButton
                            type='button'
                            variant='contained'
                            loading={formik.isSubmitting}
                            buttonText='Update'
                            styles={{
                                padding: '0.75rem 0.62rem',
                                width: '25%'
                            }}
                            onClickAction={() => {
                                formik.handleSubmit();
                            }}
                        />
                    </Stack>
                )}
            </Stack>
        </>
    );
};

export default ProfileAccountDetails;
