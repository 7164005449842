import { useGetQuery } from '../useGetQuery';

export interface shippingLinesInterface {
    booking: number;
    code: string;
    id: number;
    name: string;
    shipping: number;
}

export type shippingLinesInterfaceArray = shippingLinesInterface[];

const useShippingLines = ({
    forBooking,
    forShipping
}: {
    forBooking?: boolean;
    forShipping?: boolean;
}) => {
    const { isLoading: isLoadingShippingLines, entries: shippingLines } =
        useGetQuery<shippingLinesInterface[]>({
            url: '/booking/shipping/lines',
            cacheKey: 'shippingLines',
            params: {
                page: 1,
                size: 0,
                booking: forBooking ? 1 : undefined,
                shipping: forShipping ? 1 : undefined
            }
        });

    return { isLoadingShippingLines, shippingLines };
};

export default useShippingLines;
