import { v4 as uuidv4 } from 'uuid';
import { SuitUIButton } from '../../../../components/SuitUIButton';
import { BookingForm } from './interfaces/BookingForm';
import { Payment } from './interfaces/Payment';
import { PaymentForm } from './PaymentForm';
interface PaymentFormSectionProps {
    formData: BookingForm;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
}

export const PaymentFormSection = (props: PaymentFormSectionProps) => {
    const { formData, setFormData } = props;
    return (
        <>
            <div className='flex justify-between items-center pt-4 pb-[4px] border-b border-dashed lg:border-solid border-gray300 lg:border-appcolorgreyfaint  lg:mt-8'>
                <p className='text-steelBlue'>Payment</p>

                <SuitUIButton
                    text='+ Add Payment'
                    variant='secondary'
                    onClick={() => {
                        setFormData((prev) => {
                            return {
                                ...prev,
                                payment: [
                                    ...(prev.payment || []),
                                    { uid: uuidv4() } as Payment
                                ]
                            };
                        });
                    }}
                />
            </div>

            <div className='mt-1'>
                {formData.payment?.map((content, index) => (
                    <PaymentForm
                        {...{
                            content,
                            index,
                            setFormData,
                            isRemoveShown: formData.payment.length > 1
                        }}
                        key={content.uid}
                    />
                ))}
            </div>
        </>
    );
};
