import { CustomDatepicker } from '../../../../../components/CustomDatepicker';
import { ContainerFumigation } from '../interfaces/ContainerFumigation';
import { ModalProps } from '../interfaces/ModalProps';
import { useState } from 'react';
import { CustomModalActions } from '../../../../../components/CustomModalActions';
import CustomTextField from '../../../../../components/CustomTextInput';

export const FumigationModal = (props: ModalProps<ContainerFumigation>) => {
    const { payload, onHandleClose } = props;
    const [fumigation, setFumigation] = useState(payload);

    return (
        <div className='flex flex-col gap-4 p-4 lg:p-[40px] lg:w-[30vw]'>
            <CustomDatepicker
                value={fumigation?.date}
                label='Fumigation Date'
                onHandleChange={(v) => {
                    if (v) {
                        setFumigation((prev) => ({
                            ...prev,
                            date: new Date(v).toISOString()
                        }));
                    }
                }}
            />

            <CustomTextField
                type='number'
                value={fumigation?.amount}
                label='Fumigation Amount'
                onChange={(e) =>
                    setFumigation((prev) => ({
                        ...prev,
                        amount: e.target.value
                    }))
                }
            />

            <CustomTextField
                value={fumigation?.type}
                label='Fumigation Type'
                onChange={(e) =>
                    setFumigation((prev) => ({
                        ...prev,
                        type: e.target.value
                    }))
                }
            />

            <CustomModalActions
                onHandleSave={() => {
                    onHandleClose();
                }}
                onHandleClear={onHandleClose}
            />
        </div>
    );
};
