export const GhostIcon = () => {
    return (
        <svg
            height='120px'
            width='120px'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'
        >
            <path
                style={{ fill: '#DEDEE0' }}
                d='M256,0L256,0C157.617,0,77.576,80.041,77.576,178.424v258.586c0,41.349,33.642,74.989,74.991,74.989
    c20.038,0,38.25-7.916,51.717-20.764C217.75,504.086,235.961,512,255.998,512H256c20.038,0,38.251-7.916,51.717-20.764
    c13.467,12.85,31.68,20.764,51.718,20.764c41.349,0,74.989-33.64,74.989-74.989V178.424C434.424,80.041,354.383,0,256,0z'
            />
            <g>
                <path
                    style={{ fill: '#57565C' }}
                    d='M307.717,155.15c-6.128,0-12.117,2.482-16.462,6.827c-4.329,4.329-6.811,10.318-6.811,16.446
        s2.482,12.133,6.811,16.462c4.329,4.329,10.333,6.811,16.462,6.811s12.133-2.482,16.462-6.811
        c4.329-4.344,6.811-10.333,6.811-16.462s-2.482-12.117-6.811-16.446C319.836,157.634,313.845,155.15,307.717,155.15z'
                />
                <path
                    style={{ fill: '#57565C' }}
                    d='M204.293,155.15c-6.128,0-12.131,2.482-16.46,6.811c-4.329,4.344-6.827,10.333-6.827,16.462
        c0,6.128,2.498,12.133,6.827,16.462c4.329,4.329,10.332,6.811,16.46,6.811c6.113,0,12.119-2.482,16.446-6.811
        c4.329-4.344,6.811-10.333,6.811-16.462s-2.482-12.117-6.811-16.462C216.41,157.634,210.406,155.15,204.293,155.15z'
                />
            </g>
            <path
                style={{ fill: '#D6D5D8' }}
                d='M77.576,178.424v258.586c0,41.349,33.642,74.989,74.991,74.989c20.038,0,38.25-7.916,51.717-20.764
    C217.75,504.086,235.961,512,255.998,512V0C157.617,0.002,77.576,80.041,77.576,178.424z M220.739,194.884
    c-4.329,4.329-10.333,6.811-16.446,6.811c-6.128,0-12.131-2.482-16.46-6.811c-4.329-4.329-6.827-10.333-6.827-16.462
    s2.498-12.117,6.827-16.462c4.329-4.329,10.332-6.811,16.46-6.811c6.113,0,12.119,2.482,16.446,6.811
    c4.329,4.344,6.811,10.333,6.811,16.462C227.55,184.553,225.067,190.542,220.739,194.884z'
            />
        </svg>
    );
};
