import { Dropdown } from 'antd';
import { truncate } from 'lodash';
import dayjs from 'dayjs';
import { MoreIcon } from '../../../components/icons/MoreIcon';
import StatusIndicator from '../../../components/StatusIndicator';
import { getBookingStatus } from '../../../utils/helper';
import { Booking } from './interfaces/Booking';
import { ReactElement } from 'react';

interface BookingsTableMobileCardProps {
    item?: Booking;
    actions?: { key: string; label: ReactElement }[];
    setCurrentData: (data: Booking) => void;
}

const BookingsTableMobileCard = ({
    item,
    actions,
    setCurrentData
}: BookingsTableMobileCardProps) => {
    return (
        <div className='bg-white py-4 border-b border-appcolorfaintnine mx-4 flex justify-between relative'>
            <div className='flex justify-between mb-2'>
                <div className='flex gap-4'>
                    <div>
                        <StatusIndicator
                            status={getBookingStatus(item?.status || 0)}
                        />
                    </div>

                    <div>
                        <p className='text-sm font-semibold leading-5 text-ebony '>
                            {item?.bookingReference}
                        </p>

                        <div className='flex items-center gap-x-2 gap-y-1 mb-[4px]'>
                            <p className='flex gap-[4px]  text-xs font-normal leading-[18px] text-paleSky'>
                                Contract no:
                                <span className='font-semibold'>
                                    {item?.salesContractNumber}
                                </span>
                            </p>
                            <p className='flex gap-[4px] text-xs font-normal leading-[18px] text-paleSky'>
                                Shipping:
                                <span className='font-semibold'>
                                    {truncate(item?.shippingLineName, {
                                        length: 15,
                                        omission: '...'
                                    })}
                                </span>
                            </p>
                        </div>
                        <div className='flex items-center gap-x-4 gap-y-1 flex-wrap mb-[4px]'>
                            <p className='flex gap-[4px]  text-xs font-normal leading-[18px] text-paleSky'>
                                ETA Destination:
                                <span className='font-semibold'>
                                    {dayjs(item?.arrivalDate).format('ll')}
                                </span>
                            </p>
                            <p className='text-xs font-normal leading-[18px] text-paleSky'>
                                Status:
                                <span className='font-semibold'>
                                    &nbsp;{getBookingStatus(item?.status || 0)}
                                </span>
                            </p>
                        </div>
                        <div className='flex items-center gap-x-2 gap-y-1 flex-wrap'>
                            <p className='flex gap-[4px]  text-xs font-normal leading-[18px] text-paleSky'>
                                Port of Loading:
                                <span className='font-semibold'>
                                    {item?.portOfLoading}
                                </span>
                            </p>
                            <p className='flex gap-[4px] text-xs font-normal leading-[18px] text-paleSky'>
                                Port of Destination:
                                <span className='font-semibold'>
                                    {item?.portOfDischarge}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='absolute top-4 right-0'>
                <Dropdown
                    menu={{
                        items: actions
                    }}
                    trigger={['click']}
                >
                    <button onClick={() => item && setCurrentData(item)}>
                        <MoreIcon />
                    </button>
                </Dropdown>
            </div>
        </div>
    );
};

export default BookingsTableMobileCard;
