import React from 'react';
import { menuList } from '../../../utils/routes';
import { RouteItem } from '../../../interfaces/RouteItem';
import { Outlet, useNavigate } from 'react-router-dom';
import { hasPermission } from './utils';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { PermissionLockIcon } from '../../icons';
import Custombutton from '../../custombutton/custombutton';
import { Backdrop } from '@mui/material';
import { LoadingCircles } from '../../icons/LoadingCircles';

const ProtectedDashboardPage = ({ fullWidth }: { fullWidth?: boolean }) => {
    const { isLoading, permissions, isFetching } = useUserProfile();
    const navigate = useNavigate();

    const findRoute = (
        menu: RouteItem[],
        targetRoute: string
    ): RouteItem | undefined => {
        const targetSegments = targetRoute.split('/').slice(0, 2).join('/');

        for (const item of menu) {
            if (item.route && item.route.startsWith(targetSegments)) {
                return item;
            }
            if (item.child) {
                const foundInChildren = findRoute(item.child, targetRoute);
                if (foundInChildren) {
                    return foundInChildren;
                }
            }
        }
        return undefined;
    };

    return (
        <>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={isLoading || isFetching}
            >
                <div className='animate-spin'>
                    <LoadingCircles />
                </div>
            </Backdrop>

            {permissions && (
                <>
                    {hasPermission({
                        userPermissions: permissions,
                        module:
                            findRoute(menuList, location.pathname)
                                ?.permissionTitle ?? '',
                        permissionList: ['read', 'write', '*'],
                        feature: findRoute(menuList, location.pathname)?.feature
                    }) ? (
                        <div
                            className={`${
                                fullWidth ? '' : 'px-[16px] mb-[16px]'
                            } lg:px-[1rem] lg:mb-8`}
                        >
                            <Outlet />
                        </div>
                    ) : (
                        <div className=' w-[full] flex justify-center items-center h-[80vh] flex-col gap-4'>
                            <PermissionLockIcon />
                            <h3 className='text-[18px] font-bold text-center'>
                                Permission needed
                            </h3>
                            <p className='w-[30%] text-center text-midnightHaze text-[14px]'>
                                You currently do not have permission to view
                                this page. Please your admin for assistance
                            </p>

                            <Custombutton
                                variant='contained'
                                buttonText='Go to Home'
                                onClickAction={() => navigate('/dashboard')}
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default ProtectedDashboardPage;
