import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import {
    ContainerDimension,
    ContainerDimensionType
} from '../interfaces/ContainerDimension';
import { useState } from 'react';
import { ModalProps } from '../interfaces/ModalProps';
import { CustomModalActions } from '../../../../../components/CustomModalActions';

export const DimensionsModal = (props: ModalProps<ContainerDimension>) => {
    const { payload, onHandleClose } = props;
    const [dimensions, setDimensions] = useState(payload);
    const [isValidated, setIsValidated] = useState(false);
    const checkValidatated = (update: ContainerDimension) => {
        const validate = ContainerDimensionType.safeParse(update);
        setIsValidated(validate.success);
    };

    return (
        <Box>
            <Grid
                container
                spacing={2}
                sx={{
                    marginTop: '0px'
                }}
            >
                <Grid item xs={6}>
                    <TextField
                        value={dimensions?.length}
                        margin='normal'
                        fullWidth
                        label='Length'
                        type='number'
                        onChange={(e) => {
                            setDimensions((prev) => {
                                const update = {
                                    ...prev,
                                    length: Number(e.target.value)
                                };

                                checkValidatated(update);

                                return update;
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={dimensions?.width}
                        margin='normal'
                        fullWidth
                        label='Width'
                        type='number'
                        onChange={(e) => {
                            setDimensions((prev) => {
                                const update = {
                                    ...prev,
                                    width: Number(e.target.value)
                                };

                                checkValidatated(update);

                                return update;
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={dimensions?.height}
                        margin='normal'
                        fullWidth
                        label='Height'
                        type='number'
                        onChange={(e) => {
                            setDimensions((prev) => {
                                const update = {
                                    ...prev,
                                    height: Number(e.target.value)
                                };

                                checkValidatated(update);

                                return update;
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={6} sx={{ marginTop: '16px' }}>
                    <FormControl fullWidth>
                        <InputLabel id='unit-label'>UOM</InputLabel>
                        <Select
                            labelId='unit-label'
                            value={dimensions?.unit}
                            label='UOM'
                            onChange={(e) => {
                                setDimensions((prev) => {
                                    const update = {
                                        ...prev,
                                        unit: e.target.value
                                    };

                                    checkValidatated(update);

                                    return update;
                                });
                            }}
                        >
                            {[
                                { value: 'm', label: 'Meter' },
                                { value: 'ft', label: 'Feet' }
                            ].map(({ value, label }) => (
                                <MenuItem key={value} value={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <CustomModalActions
                onHandleSave={() => {
                    onHandleClose();
                }}
                onHandleClear={onHandleClose}
                disabled={!isValidated}
            />
        </Box>
    );
};
