import { Autocomplete, Box } from '@mui/material';
import { BookingConnection } from '../pages/dashboard/bookings/createbooking/interfaces/BookingConnection';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useEffect, useState } from 'react';
import useShippingProfiles from '../customhooks/useShippingProfiles';
import CustomTextField from './CustomTextInput';

export const CarrierBookingAgentsSelect = (props: {
    value?: number;
    setShowAddNewCarrierModal?: React.Dispatch<React.SetStateAction<boolean>>;
    onHandleChange?: (value: BookingConnection) => void;
    isError?: boolean;
    label?: string;
    helperText?: string;
}) => {
    const {
        onHandleChange,
        setShowAddNewCarrierModal,
        isError,
        value,
        label = 'Carrier/Booking Agent',
        helperText
    } = props;
    const { user } = useSelector((state: RootState) => ({
        user: state.user.user
    }));
    const {
        isLoading: isLoadingBookingConnections,
        shippingProfiles: bookingConnections
    } = useShippingProfiles();
    const [selectedConnection, setSelectedConnection] =
        useState<BookingConnection>({} as BookingConnection);
    const lastBooking = [...bookingConnections].pop();
    const carrier = new URLSearchParams(location.search).get('carrier');

    useEffect(() => {
        const connection = bookingConnections.find(
            (connection) =>
                connection.shippingLineId === Number(carrier) ||
                connection.id === value
        );

        if (connection) {
            setSelectedConnection(connection);
        }
    }, [value, bookingConnections]);

    return (
        <Autocomplete
            autoComplete
            disableClearable
            options={bookingConnections}
            onChange={(_, value) => {
                setSelectedConnection(value);

                if (onHandleChange) {
                    onHandleChange(value);
                }
            }}
            renderInput={(params) => (
                <CustomTextField
                    name='bookingAgent'
                    params={params}
                    label={label}
                    required
                    error={isError}
                    helperText={helperText}
                />
            )}
            key={selectedConnection?.shippingLineId}
            disabled={!!carrier}
            value={selectedConnection}
            getOptionLabel={(option) => option.shippingLineName || ''}
            loading={isLoadingBookingConnections}
            renderOption={(props, { shippingLineName }, state) => (
                <Box key={state.index}>
                    <Box component='li' {...props}>
                        {shippingLineName}
                    </Box>
                    {user?.booking &&
                        setShowAddNewCarrierModal &&
                        lastBooking?.shippingLineName === shippingLineName && (
                            <Box
                                className='cursor-pointer p-2 text-appcolorprimary'
                                onMouseDown={() =>
                                    setShowAddNewCarrierModal(true)
                                }
                            >
                                + Add a New Carrier
                            </Box>
                        )}
                </Box>
            )}
        />
    );
};
