export const BreadcrumbCaretRight = () => {
    return (
        <svg
            width='5'
            height='7'
            viewBox='0 0 5 7'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M4.94336 6.17457L1.8867 3.5L4.94336 0.825426L4.00005 0L4.86374e-05 3.5L4.00005 7L4.94336 6.17457Z'
                fill='#333333'
            />
        </svg>
    );
};
