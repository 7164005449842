import { User } from '../../../../interfaces/User';
import { BookingForm } from './interfaces/BookingForm';
import { PartiesFormInput } from './PartiesFormInput';

interface PartiesFormProps {
    user: User | null;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
    setShowAddParty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PartiesAdditionalForm = (props: PartiesFormProps) => {
    const { user, setFormData, setShowAddParty } = props;

    return (
        <div className='flex flex-col gap-4 pb-4 lg:pb-0 lg:grid lg:grid-cols-3'>
            <div className='w-full'>
                <PartiesFormInput
                    user={user}
                    label='Contract Party'
                    setFormData={setFormData}
                    setShowAddParty={setShowAddParty}
                    onHandleChange={(_, value) => {
                        if (value) {
                            setFormData((prev) => ({
                                ...prev,
                                parties: {
                                    ...prev.parties,
                                    contractParty: value.corporateId
                                }
                            }));
                        }
                    }}
                />
            </div>

            <div className='w-full'>
                <PartiesFormInput
                    user={user}
                    label='Notify Party'
                    setFormData={setFormData}
                    setShowAddParty={setShowAddParty}
                    onHandleChange={(_, value) => {
                        if (value) {
                            setFormData((prev) => ({
                                ...prev,
                                parties: {
                                    ...prev.parties,
                                    notifyParty: value.corporateId
                                }
                            }));
                        }
                    }}
                />
            </div>

            <div className='w-full'>
                <PartiesFormInput
                    user={user}
                    label='Additional Notify Party 1'
                    setFormData={setFormData}
                    setShowAddParty={setShowAddParty}
                    onHandleChange={(_, value) => {
                        if (value) {
                            setFormData((prev) => ({
                                ...prev,
                                parties: {
                                    ...prev.parties,
                                    additionalNotifyParty1: value.corporateId
                                }
                            }));
                        }
                    }}
                />
            </div>

            <div className='w-full'>
                <PartiesFormInput
                    user={user}
                    label='Additional Notify Party 2'
                    setFormData={setFormData}
                    setShowAddParty={setShowAddParty}
                    onHandleChange={(_, value) => {
                        if (value) {
                            setFormData((prev) => ({
                                ...prev,
                                parties: {
                                    ...prev.parties,
                                    additionalNotifyParty2: value.corporateId
                                }
                            }));
                        }
                    }}
                />
            </div>
        </div>
    );
};
