import { useGetQuery } from '../useGetQuery';

export interface PaymentPurposesInterface {
    id: number;
    title: string;
}


export const usePaymentpurposes = () => {
    const {
        isLoading,
        entries,
        refetch
    } = useGetQuery< PaymentPurposesInterface[] >({
        url: '/payment/purpose',
        cacheKey: 'payment-purposes',
    });

    return {paymentPurposes: entries, loadingPaymentPurposes: isLoading, fetchPaymentpurposes: refetch };
};
