import React from 'react';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { formatCurrency } from '../../../../utils/helper';
import Customtable from '../../../customtable/customtable';
import { Box } from '@mui/material';
import { NoDataCross } from '../../../icons/NoDataCross';

const MobileItemsCards = ({ item }: { item?: any }) => {
    return (
        <>
            <Box key={item?.id} borderBottom='1px solid#EAECF0' p={2}>
                <Box width='full' justifyContent='space-between' display='flex'>
                    <Box display='flex' gap={1}>
                        <Box>
                            <h4 className=' text-ebony text-[12px] font-normal'>
                                Amount:{' '}
                                <span className='text-[14px] text-ebony font-semibold'>
                                    {formatCurrency(
                                        item?.amount ?? 0,
                                        item.currencyCode
                                    )}{' '}
                                </span>
                            </h4>
                            <p className=' text-[12px] text-paleSky whitespace-pre-line max-w-[320px] overflow-hidden'>
                                Comment:
                                <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                    {item?.comment ?? '--'}
                                </span>
                            </p>

                            <p className=' text-[12px] text-paleSky'>
                                Created Date:
                                {dayjs(item?.createdDate).format('ll')}
                            </p>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const InvoicePaymentTable = ({
    loadingData,
    data
}: {
    loadingData?: boolean;
    data?: any;
}) => {
    return (
        <>
            <Table
                loading={loadingData}
                pagination={{ pageSize: 20 }}
                dataSource={data || []}
                locale={{
                    emptyText: () => {
                        return (
                            <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                                <div className='flex justify-center mb-4'>
                                    <NoDataCross />{' '}
                                </div>

                                <p className='text-center text-appcolorblackthree'>
                                    You have no Payment on this Invoice.
                                    <br />
                                </p>
                            </div>
                        );
                    }
                }}
                columns={[
                    {
                        title: 'Date',
                        dataIndex: 'createdDate',
                        key: 'createdDate',
                        render: (_, record: any) => (
                            <span>
                                {dayjs(record?.createdDate).format('ll')}
                            </span>
                        )
                    },
                    {
                        title: 'Amount',
                        dataIndex: 'amount',
                        key: 'amount',
                        render: (_, record: any) => (
                            <span>
                                {formatCurrency(
                                    record?.amount || 0,
                                    record?.currencyCode || 'NGN'
                                )}
                            </span>
                        )
                    },
                    {
                        title: 'Comment',
                        dataIndex: 'comment',
                        key: 'comment'
                    }
                ]}
                className='hidden lg:block'
            />
            <div className='lg:hidden'>
                <Customtable
                    data={data ?? []}
                    loadingData={loadingData}
                    multiTableTiles={[]}
                    emptyText='You have no Payment on this Invoice.'
                    mobileComponent={<MobileItemsCards />}
                />
            </div>
        </>
    );
};
export default InvoicePaymentTable;
