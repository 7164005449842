import React, { useState } from 'react';
import { Collapse, Timeline } from 'antd';
import EmptyShipment from '../EmptyShipment';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import moment from 'moment';

const { Panel } = Collapse;

export interface shipmentitemProps {
    containerDetails: any;
    containerNumber?: any;
}
const ContainerShipmentItem = ({
    containerDetails,
    containerNumber
}: shipmentitemProps) => {
    const [tabValue, setTabValue] = useState('Route');
    const [routesData, setRoutesData] = useState<any[]>([]);

    const vesselData = JSON.parse(containerDetails.tracking).vessels;

    /**
     * Handle change
     * @param event Event
     * @param newValue string
     */
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const getLocationName = (
        locationId: number,
        locations: {
            name: string;
            id: number;
            countryCode: string;
        }[]
    ) => {
        if (locationId === undefined || locations.length === 0) {
            return '-';
        } else {
            const filteredLocation = locations.filter(
                (item) => item.id === locationId
            );
            if (filteredLocation.length > 0) {
                return `${filteredLocation[0].name}, ${filteredLocation[0].countryCode}`;
            } else {
                return '-';
            }
        }
    };

    return (
        <div>
            {Object.keys(containerDetails).length === 0 ? (
                <EmptyShipment />
            ) : (
                <div className='rounded-[3px] bg-appcolorwhite py-[1.2rem] px-[1.2rem] mb-8'>
                    <div className='flex justify-between mb-2'>
                        <div className='text-[14px] leading-[24px]'>
                            <span className='pr-3 text-appcolorgrefaint'>
                                Container
                            </span>
                            <span className='text-appcolorblacktwo'>
                                {containerDetails?.number}
                            </span>
                        </div>
                        <div>
                            <p className='w-fit bg-appcolorprimaryfaintthree py-[0.3rem] px-[0.6rem] text-appcolorprimary rounded-[3px] text-[12px]'>
                                IN TRANSIT
                            </p>
                        </div>
                    </div>

                    <div className='flex justify-between text-[14px] leading-[24px] text-appcolorblacktwo mb-2'>
                        <p>
                            {containerDetails?.portOfLoading},{' '}
                            {containerDetails?.countryOfLoading}
                        </p>
                        <p>
                            {containerDetails?.portOfDischarge},{' '}
                            {containerDetails?.countryOfDischarge}
                        </p>
                    </div>

                    <div className='relative'>
                        <div className='relative flex items-center mb-2'>
                            <div className='bg-appcolorprimary rounded-[50%] w-[0.7rem] h-[0.7rem] z-[2]'></div>
                            <div className='border border-dashed grow border-appcolorgrefaint'></div>
                            <div className='bg-appcolorgrefaint rounded-[50%] w-[0.7rem] h-[0.7rem] z-[2]'></div>
                        </div>
                        <div
                            className={`border-appcolorprimary border-[2px] w-[20%] absolute top-[0.3rem] left-0 z-[1] mx-[0.6rem]`}
                        ></div>
                    </div>

                    <div className='flex'>
                        <span>
                            ETD:{' '}
                            {moment(containerDetails?.etaDestination).format(
                                'll'
                            )}
                        </span>

                        <span className='pl-3'>
                            ETA:{' '}
                            {moment(containerDetails?.etaLoading).format('ll')}
                        </span>
                    </div>

                    <div className='mt-4'>
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                            textColor='primary'
                            indicatorColor='primary'
                            aria-label=''
                        >
                            <Tab
                                value='Route'
                                label='Route'
                                sx={{
                                    paddingBottom: '0px',
                                    textTransform: 'capitalize'
                                }}
                            />
                            <Tab
                                value='Vessel(s)'
                                label='Vessel'
                                sx={{
                                    paddingBottom: '0px',
                                    textTransform: 'capitalize'
                                }}
                            />
                        </Tabs>
                    </div>

                    <div>
                        {tabValue === 'Route' ? (
                            <div className='mt-4'>
                                {routesData.length > 0 ? (
                                    <Timeline items={routesData} />
                                ) : (
                                    <p>There is no route data available</p>
                                )}
                            </div>
                        ) : null}

                        {tabValue === 'Vessel(s)' ? (
                            <>
                                {vesselData.length > 0 ? (
                                    <>
                                        <Collapse
                                            bordered={false}
                                            accordion
                                            className='mt-[1rem]'
                                        >
                                            {vesselData?.map((it: any) => (
                                                <Panel
                                                    header={it?.name}
                                                    key={it?.name}
                                                >
                                                    <div className='px-6 mt-4'>
                                                        <div className='flex w-[50%] mb-2'>
                                                            <span>Name:</span>
                                                            <span className='pl-2 text-appcolorprimary'>
                                                                {it?.name}
                                                            </span>
                                                        </div>

                                                        <div className='flex w-[50%] mb-2'>
                                                            <span>
                                                                Call Sign:
                                                            </span>
                                                            <span className='pl-2 text-appcolorprimary'>
                                                                {it?.callSign}
                                                            </span>
                                                        </div>

                                                        <div className='flex w-[50%] mb-2'>
                                                            <span>IMO:</span>
                                                            <span className='pl-2 text-appcolorprimary'>
                                                                {it?.imo}
                                                            </span>
                                                        </div>

                                                        <div className='flex w-[50%] mb-2'>
                                                            <span>MMSI:</span>
                                                            <span className='pl-2 text-appcolorprimary'>
                                                                {it?.mmsi}
                                                            </span>
                                                        </div>

                                                        <div className='flex w-[50%] mb-2'>
                                                            <span>Flag:</span>
                                                            <span className='pl-2 text-appcolorprimary'>
                                                                {it?.flag}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Panel>
                                            ))}
                                        </Collapse>
                                    </>
                                ) : (
                                    <p className='mt-4'>
                                        There is no vessel data available
                                    </p>
                                )}
                            </>
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ContainerShipmentItem;
