import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ThemeProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from 'react-query';
import './styles/styles.scss';
import './index.css';
import './App.css';
import { theme } from './components/theme/theme';
import { FeatureFlagsProvider } from './context/feature-flags';

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://edab3be85ed8955f740dff26d2951d8b@o4506129690460160.ingest.sentry.io/4506129702191104',
        environment: process.env.NODE_ENV,
        integrations: [new Sentry.Replay()],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0
    });
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
            refetchOnMount: false
        }
    }
});
const persistor = persistStore(store);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <StrictMode>
                        <FeatureFlagsProvider>
                            <App />
                        </FeatureFlagsProvider>
                    </StrictMode>
                </QueryClientProvider>
            </ThemeProvider>
        </PersistGate>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
