import { BookingForm } from '../interfaces/BookingForm';

export const sanitizePayload = (payload: BookingForm) => {
    const entries = Object.entries(payload);
    const output = {} as Record<string, unknown>;

    for (const [key, value] of entries) {
        if (typeof value === 'string' || typeof value === 'number') {
            output[key] = value;

            continue;
        }

        if (Array.isArray(value)) {
            output[key] = [];

            for (const a of value) {
                delete a.uid;

                if (Object.keys(a).length) {
                    (output[key] as unknown[]).push(a);
                } else {
                    delete output[key];
                }
            }

            continue;
        }

        if (typeof value === 'object') {
            if (Object.keys(value).length) {
                output[key] = sanitizePayload(value);
            }

            continue;
        }
    }

    return output;
};
