import GenericAutocomplete from "./GenericAutocomplete"
import { ReactNode } from "react"
import { getVessels } from "../../api/base/base"

interface VesselsAutocompleteProps {
  iconPrefix?: ReactNode
  id: string
  isRequired?: boolean
  label: string
  name: string
  nameValue?: string
  updateField: (name: string, value: any) => void
  value?: string
}

const VesselsAutocomplete = (props: VesselsAutocompleteProps) => {
  return (
    <GenericAutocomplete
      apiFunction={getVessels}
      labelFunction={(item: any) => item.toDisplay}
      displayFunction={(item: any) => {
        return {
          ...item,
          toDisplay: `${item.name} - ${item.imo}`,
        }
      }}
      error={false}
      helperText="Search by Vessel Name"
      iconPrefix={ props.iconPrefix }
      id={ props.id }
      isSnug={true}
      label={ props.label }
      name={ props.name }
      updateField={ props.updateField }
      nameValue={ props.nameValue }
      isRequired={props.isRequired}
      initialSearch=""
      updateFieldValue={ props.updateField }
      value={ props.value }
    />
  )
}

export default VesselsAutocomplete
