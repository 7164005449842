export const PlainFileIcon = () => {
    return (
        <svg
            width='21'
            height='21'
            viewBox='0 0 21 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M12.618 1.96742H5.95133C5.50931 1.96742 5.08538 2.14302 4.77282 2.45558C4.46026 2.76814 4.28467 3.19206 4.28467 3.63409V16.9674C4.28467 17.4095 4.46026 17.8334 4.77282 18.1459C5.08538 18.4585 5.50931 18.6341 5.95133 18.6341H15.9513C16.3934 18.6341 16.8173 18.4585 17.1298 18.1459C17.4424 17.8334 17.618 17.4095 17.618 16.9674V6.96742M12.618 1.96742L17.618 6.96742M12.618 1.96742V6.96742H17.618M14.2847 11.1341H7.618M14.2847 14.4674H7.618M9.28467 7.80076H7.618'
                stroke='#475467'
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
