import { z } from 'zod';

export const ContainerDimensionType = z.object({
    length: z.number(),
    width: z.number(),
    height: z.number(),
    unit: z.string().min(1)
});

export type ContainerDimension = z.infer<typeof ContainerDimensionType>;
