import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { WalletBalancesSkeleton } from './WalletBalancesSkeleton';
import { Wallet } from '../../pages/dashboard/payments/interfaces/Wallet';
import WalletBalanceItem from './WalletBalanceItem';
import { useUserProfile } from '../../customhooks/useUserProfile';
import { hasPermission } from '../dashboard/dashboardpage/utils';
import CustomCarousel from '../CustomCarousel';
import { ReactElement } from 'react';

interface WalletAction {
    component: JSX.Element;
    onHandleClick: () => void;
    display: boolean;
    title: string;
    icon: JSX.Element;
}

export const WalletBalances = (props: {
    wallets: Wallet[];
    isLoading: boolean;
    actions?: WalletAction[];
}) => {
    const { wallets, isLoading, actions } = props;

    const { permissions } = useUserProfile();

    const location = useLocation();

    const actionsToDisplay = actions?.filter((item) => item.display);

    return (
        <>
            {actions && (
                <Box className='hidden lg:flex items-center mb-2 justify-between'>
                    {location.pathname.includes('logistics/pay') ? (
                        <Box className='font-semibold text-[24px] '>
                            Pay for invoice
                        </Box>
                    ) : (
                        <div />
                    )}
                    <Box className='flex items-center justify-between'>
                        {actionsToDisplay?.map(
                            ({ component, onHandleClick }, index) => (
                                <Box
                                    key={index}
                                    className='flex px-3 cursor-pointer text-appcolorprimary text-[13px] border-r-2 last:border-r-0 border-appcolorprimary'
                                    onClick={onHandleClick}
                                >
                                    {component}
                                </Box>
                            )
                        )}
                    </Box>
                </Box>
            )}

            <Box className='hidden lg:flex justify-between bg-white p-5 rounded-lg overflow-x-scroll w-full'>
                {isLoading ? (
                    <WalletBalancesSkeleton length={4} />
                ) : (
                    [...wallets].map((item) => (
                        <Box
                            key={item.currencyCode}
                            className='flex items-center border-r grow mr-6 pr-4 last:border-r-0 last:mr-0'
                        >
                            <WalletBalanceItem item={item} />
                        </Box>
                    ))
                )}
            </Box>

            <Box className='lg:hidden max-w-[calc(100vw-32px)]'>
                <CustomCarousel
                    isLoading={isLoading}
                    data={
                        wallets as unknown as {
                            [key: string]: string | ReactElement | number;
                        }[]
                    }
                    component={<WalletBalanceItem />}
                    actions={
                        actionsToDisplay &&
                        permissions &&
                        hasPermission({
                            userPermissions: permissions,
                            module: 'payments',
                            permissionList: ['write', '*']
                        })
                            ? actionsToDisplay
                            : []
                    }
                />
            </Box>
        </>
    );
};
