import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Box, Button, Grid, Stack } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { getVesselSchedulesPayload } from '../../../../api/booking/ocean-schedules';
import VesselsAutocomplete from '../../../../components/autocompletewithapi/VesselsAutocomplete';
import { SearchProps } from '../ocean-schedules';
import React from 'react';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { FullResponseData } from '../interfaces/SearchResults';

const IDENTIFIER = 'by-vessel';

interface VesselSearchInterface extends SearchProps {
    setTheSelectedVessel: Dispatch<SetStateAction<string>>;
}

const VesselSearch = (props: VesselSearchInterface) => {
    const [theFormData, setTheFormData] =
        useState<getVesselSchedulesPayload | null>(null);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (theFormData === null) {
            return;
        }
        props.indicateLoading(true);
        try {
            const searchResults = await props.fetcher(IDENTIFIER, theFormData);
            if (!searchResults) {
                props.indicateLoading(false);
                return;
            }

            const response: AxiosResponse<FullResponseData> =
                searchResults as AxiosResponse<FullResponseData>;

            if (response?.data?.data?.succeeded) {
                props.indicateLoading(false);

                if (
                    response.data.data.errorMessages &&
                    response.data.data.errorMessages.length > 0
                ) {
                    toast.error(response.data.data.errorMessages[0]);
                } else {
                    props.onSearchComplete(IDENTIFIER, response);
                }
            } else {
                console.error(
                    'Search request was not successful:',
                    response?.data
                );
            }
        } catch (error) {
            props.indicateLoading(false);
        }
    };

    const getImoFromDisplay = (value: string) => {
        return Number(
            value
                .split('-')
                .map((v) => v.trim())
                .pop()
        );
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box
                role={props.role}
                sx={{
                    p: 2,
                    backgroundColor: 'white',
                    borderRadius: 2,
                    pb: 1
                }}
            >
                <Grid
                    container
                    alignItems='center'
                    spacing={1}
                    justifyContent='space-between'
                >
                    <Grid item md={11.3}>
                        <VesselsAutocomplete
                            id='vessel-name'
                            label='Vessel Name'
                            name='osc--vessel-name'
                            updateField={(_, value) => {
                                props.setTheSelectedVessel(value.split('-')[0]);
                                setTheFormData({
                                    ...theFormData,
                                    imo: getImoFromDisplay(value)
                                });
                            }}
                        />
                    </Grid>
                    <Grid item md={0.6}>
                        <Stack
                            direction='column'
                            width='100%'
                            alignItems='center'
                            justifyContent='center'
                        >
                            <Button
                                variant='contained'
                                sx={{
                                    width: '45px',
                                    height: '48px'
                                }}
                                type='submit'
                            >
                                <SearchOutlinedIcon sx={{ color: 'white' }} />
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

export default VesselSearch;
