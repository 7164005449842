import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import Adminfinancingoverview from './adminfinancingoverview/adminfinancingoverview';
import Financingoverview from './financingoverview/financingoverview';

export const FinanceOverview = () => {
    const state = useSelector((state: RootState) => ({
        token: state.user.token,
        user: state.user.user
    }));

    const { user } = state;

    return user?.user_level === 5 ? (
        <Adminfinancingoverview />
    ) : (
        <Financingoverview />
    );
};
