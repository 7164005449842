import Grid from '@mui/material/Grid'
import React, { useState } from 'react'
import Outletouterpage from '../../../../components/dashboard/outletouterpage/outletouterpage'
import Documentupload from '../../../../components/documentupload/documentupload'
import Chats from './chats/chats'


const documentToUpload = [
  {
    title: 'Upload Parking List'
  },
  {
    title: 'Upload Bill of lading'
  },
  {
    title: 'Upload Invoice'
  },
  {
    title: 'Upload Sales Contract'
  }
]


const Viewfinanceapplication = () => {
  const [tabValue, setTabValue] = useState('Disputes');
  const [tabs, setTabs] = useState(["Disputes", "Summary"])

  /**
 * Switches the clicked on tile to be the first
 * @param index number 
 */
  const swithTileIndex = (index: number) => {
    const newTiles = [...tabs]
    if (index !== 0) {
      const itemToMove = newTiles[index];
      newTiles.splice(index, 1)
      newTiles.unshift(itemToMove)
      setTabs(newTiles)
    }
  }

  return (
    <div>
      <Outletouterpage
        breadcrumbs={
          [
            {
              title: 'Home',
              link: '/dashboard'
            },
            {
              title: 'Financing',
              link: '/financing'
            }
          ]
        }
      >
        <div>
          <div>
            <div className="flex items-center justify-between">
              <div className='flex'>
                <div className={`bg-appcolorwhite trapezoid pt-[0.8rem] pb-[0.7rem] pl-[1.6rem] w-fit pr-[5.6rem] cursor-pointer text-[17px] leading-[24px]`}
                >
                  View Application
                </div>
              </div>
            </div>
            <div className='px-[1.3rem] bg-appcolorwhite mb-4 pt-8 pb-[1.5rem]'>
              <Grid container alignItems="center">
                <Grid item xs={12} md={12} lg={4}>
                  <div className='custom-piped view'>
                    <p className='text-appcolorblacktwo opacity-[0.6]'>
                      Transaction ID
                    </p>
                    <div className='flex items-center'>
                      <p className='font-bold text- [22px]  leading-[28px] mr-4'>
                        1414242424 </p>
                      <p className='rounded-[3px] shadow-[0px 1px 2px rgba(16, 24, 40, 0.05)] flex justify-center bg-appcolorgreenfaint w-fit px-[0.5rem] py-[0.3rem] items-center mr-4'>
                        <span className='text-[14px] leading-[20px] font-bold text-appcolorgreen'>
                          Successful
                        </span>
                      </p>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={2}>
                  <div className='pl-[2.5rem] custom-piped view'>
                    <p className='text-appcolorblacktwo opacity-[0.6]'>
                      Requested
                    </p>
                    <div className=''>
                      <p className='font-bold text-[22px]  leading-[28px] mr-4'>
                        $50,000 </p>
                      <span className='opacity-[0.5]'>Paid</span>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={2}>
                  <div className='pl-[2.5rem] custom-piped view'>
                    <p className='text-appcolorblacktwo opacity-[0.6]'>
                      Credit Line
                    </p>
                    <div className=''>
                      <p className='font-bold text-[22px]  leading-[28px] mr-4'>
                        $554,423.434</p>
                      <span className='opacity-[0.5]'>Paid</span>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={2}>
                  <div className='pl-[2.5rem] custom-piped view'>
                    <p className='text-appcolorblacktwo opacity-[0.6]'>
                      KYC Document
                    </p>
                    <div className=''>
                      <p className='font-bold text-[22px]  leading-[28px] mr-4'>
                        4</p>
                      <span className='opacity-[0.5]'>Documents</span>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={2}>
                  <div className='flex justify-center'>
                    <img src="/img/vectors/uploadicontwo.svg" alt="uploadicontwo" className='cursor-pointer w-[1.5rem]' />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

          <div className='mt-16'>
            <Grid container spacing={4}
              sx={{
                marginTop: '0px'
              }}
            >
              <Grid item xs={12} md={12} lg={5}>
                <div>
                  <div className='flex'>
                    {
                      tabs.map((item, index) => (
                        <div className={`${item === tabValue ? "bg-appcolorwhite trapezoid" : ""} pt-[0.8rem] pb-2 pl-[1.6rem] w-fit pr-[5.6rem] cursor-pointer`}
                          onClick={() => {
                            setTabValue(item)
                            swithTileIndex(index)
                          }}
                        >
                          {item}
                        </div>
                      ))
                    }
                  </div>
                  <div className='bg-appcolorwhite py-12 rounded-[3px] px-[2.4rem]'>
                    {
                      tabValue === 'Disputes' ?
                        <>
                          {
                            documentToUpload.map((item, index) => (
                              <Documentupload
                                uploaded
                                key={`upload-doc-${item.title
                                  }-${index}`}
                                title={item.title}
                              />
                            ))
                          }
                        </> :
                        <>
                          <div>
                            <div className='flex justify-between py-4 mb-4'>
                              <p className='text-[13px] leading-4 text-appcolorgreydeep'>Date Opened</p>
                              <div className='flex flex-col items-end'>
                                <p className='text-appcolorblack text-[13px] font-bold mb-1 leading-4 '>02/11/2021</p>
                                <span className='text-[10px] leading-[13px] text-appcolorfaintsix text-right'>
                                  10:41 AM
                                </span>
                              </div>
                            </div>

                            <div className='flex justify-between pb-6 mb-8 border-b border-appcolorlightgrey'>
                              <p className='text-[13px] leading-4 text-appcolorgreydeep'>Merchant</p>
                              <div className='flex flex-col items-end'>
                                <p className='text-appcolorblack text-[13px] font-bold leading-4'>FrontEdge</p>
                                <span className='text-[10px] leading-[13px] text-appcolorfaintsix text-right'>
                                  94_157718054
                                </span>
                              </div>
                            </div>


                            <div className='flex justify-between pb-6 mb-8 border-b border-appcolorlightgrey'>
                              <p className='text-[13px] leading-4 text-appcolorgreydeep'>Customer</p>
                              <div>
                                <p className='text-appcolorblack text-[13px] font-medium leading-4'>Moni Alli</p>
                              </div>
                            </div>


                            <div className='flex justify-between pb-6 mb-8 border-b border-appcolorlightgrey'>
                              <p className='text-[13px] leading-4 text-appcolorgreydeep'>Customer Email</p>
                              <div>
                                <p className='text-appcolorblack text-[13px] font-medium leading-4'>monialli@frontedge.io</p>
                              </div>
                            </div>

                            <div className='flex justify-between'>
                              <p className='text-[13px] leading-4 text-appcolorgreydeep'> Status</p>
                              <div>

                                <div className='flex items-center justify-end'>
                                  <span className='w-2 h-2 mr-3 bg-appcolorgreen rounded-[50%]'>
                                  </span>
                                  <p className='text-appcolorblack text-[13px] font-bold leading-4'>Accepted</p>
                                </div>

                              </div>
                            </div>
                          </div>
                        </>
                    }
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={12} lg={7}>
                <div>
                  <div>
                    <div className='flex justify-end'>
                      <p className='text-[14px] leading-[16px]'>Request made <span className='font-bold'>
                        March 17, 2023 4:04 PM</span></p>
                    </div>
                  </div>

                  <div className='bg-appcolorwhite rounded-[3px] p-[1.4rem] mt-8'>
                    {
                      tabValue === 'Disputes' ?
                        <div>
                          <img src="/img/vectors/docs.svg" alt="docs"
                            className='w-full'
                          />
                        </div>
                        :
                        <div>
                          <Chats />
                        </div>
                    }

                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Outletouterpage>
    </div>
  )
}

export default Viewfinanceapplication