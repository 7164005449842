import React, { useState, useEffect } from 'react';
import { Upload, UploadFile } from 'antd';
import type { UploadProps } from 'antd';
import { formatBytes, getFileIcon, openNotification } from '../../utils/helper';
import CircularProgress, {
    CircularProgressProps
} from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { REACT_APP_BASE_URL } from '../../config';
import { uploadDocumentUrl } from '../../api/base/base';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export interface documentuploadProps {
    useAuthorization?: boolean;
    title: string;
    uploaded?: boolean;
    runAfterUpload?: boolean;
    documentItemId?: number;
    documentUrl?: string;
    status?: number;
    afterUploadUpdate?: (itemId: number, documentUrl: string) => void;
    rejectedMessage?: string;
    returnURLOnly?: boolean;
    acceptText?: string;
    accept?: string;
    maxCount?: number;
    full?: boolean;
}

export const CircularProgressWithLabel = (
    props: CircularProgressProps & { value: number }
) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant='determinate' {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    variant='caption'
                    component='div'
                    color='#12B76A'
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
};

const Documentupload = ({
    useAuthorization,
    title,
    uploaded,
    runAfterUpload,
    documentItemId,
    documentUrl,
    status,
    afterUploadUpdate,
    rejectedMessage,
    returnURLOnly,
    acceptText,
    accept,
    maxCount,
    full
}: documentuploadProps) => {
    const [percent, setPercent] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [fileList, setFileList] = useState<UploadFile<any>[]>([]);
    const [error, setError] = useState<any>(null);

    /**
     * File upload size checker
     * @param file
     * @param fileList
     * @returns
     */
    const beforeUpload = (file: any, fileList: any) => {
        const isLt5M = file.size / 1024 / 1024 < 150;
        if (!isLt5M) {
            const newFileList = [...fileList];
            openNotification('error', 'File must be smaller than 150MB.');
            const index = newFileList.findIndex(
                (item: any) => item.uid === file.uid
            );
            newFileList[index].status = 'error';
            setFileList(newFileList);
        }
        return isLt5M;
    };

    const afterUpload = async (fileList: any[], filename: string) => {
        const url = fileList[0].response.data.fileUri;
        try {
            await uploadDocumentUrl({
                documentItemId: documentItemId || 0,
                documentUrl: url
            });
            setUploading(false);
            // openNotification('success', `${filename} file uploaded successfully`)
            if (afterUploadUpdate !== undefined) {
                afterUploadUpdate(documentItemId || 0, url);
            }
        } catch (error) {
            setUploading(false);
            if (error.response) {
                setError(
                    `${
                        error?.response?.data?.message || 'Something went wrong'
                    }`
                );
                // openNotification("error", `Upload Document, ${error?.response?.data?.message || 'Something went wrong'}`)
            }
        }
    };

    const props: UploadProps = {
        maxCount: maxCount,
        disabled: status === 1 ? true : false,
        beforeUpload,
        accept: accept,
        showUploadList: false,
        name: 'file',
        action: `${REACT_APP_BASE_URL}/files/upload`,
        headers: useAuthorization
            ? {
                  Authorization: `Bearer ${localStorage.getItem('token')}`
              }
            : undefined,
        onChange(info) {
            setPercent(info.file.percent || 0);
            setFileList(info.fileList);
            setError(null);

            if (info.file.status === 'uploading') {
                setUploading(true);
            }

            if (info.file.status === 'done') {
                setFileList(info.fileList);

                if (runAfterUpload) {
                    afterUpload(info.fileList, info.file.name);
                } else {
                    setUploading(false);
                    // openNotification('success', `${info.file.name} file uploaded successfully`)
                    const url = info.fileList[0].response.data.fileUri;
                    if (returnURLOnly) {
                        if (afterUploadUpdate !== undefined) {
                            afterUploadUpdate(documentItemId || 0, url);
                        }
                    }
                }
            } else if (info.file.status === 'error') {
                setFileList([]);
                setUploading(false);
                // openNotification('error', `${info.file.name} file upload failed.`)
                setError(`${info.file.name} file upload failed.`);
            }
        },
        method: 'POST',
        data: {
            fileType: 'OTHERS'
        }
    };

    useEffect(() => {
        if (uploaded) {
            let size: any = 0;
            let docName = 'file.png';

            if (documentUrl?.length) {
                docName = documentUrl?.split('?size=')[0];
                size = documentUrl?.split('?size=')[1];
            }

            setFileList([
                {
                    uid: 'rc-upload-1679585437822-5',
                    lastModified: 1679519998443,
                    name: title || '6133d4cc5a1e0f9897b8.png',
                    size: size,
                    type: 'image/png',
                    percent: 100,
                    // "originFileObj": {},
                    status: 'done',
                    response: {
                        name: title || '6133d4cc5a1e0f9897b8.png',
                        status: 'done',
                        url:
                            documentUrl ||
                            'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                        thumbUrl:
                            documentUrl ||
                            'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
                    },
                    xhr: {}
                }
            ]);
        }
    }, []);

    return (
        <div className='mb-4'>
            <Upload {...props}>
                <div
                    className={`rounded-[3px] border-appcolorfaintgreyblue border border-dashed px-[1rem] py-[1rem] ${
                        full ? '' : 'w-[250px]'
                    } relative`}
                >
                    {uploaded ? (
                        <div className='absolute top-[-0.7rem] right-[-0.7rem]'>
                            <CheckCircleOutlineIcon color='primary' />
                        </div>
                    ) : null}
                    {fileList.length === 0 ? (
                        <div className='flex'>
                            <div className='pr-[0.5rem]'>
                                <img
                                    src='/img/vectors/uploadicon.svg'
                                    alt='uploadicon'
                                />
                            </div>

                            <div>
                                <p
                                    className={`text-appcolorprimary text-[14px] leading-[20px]`}
                                >
                                    {title}
                                </p>
                                <span className='text-appcolorblackthree'>
                                    Accepts{' '}
                                    {acceptText ?? '.jpg, .png and .pdf'}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div className='flex items-center justify-between'>
                            <div className='flex'>
                                <div className='pr-[0.5rem]'>
                                    {fileList[0].fileName?.length !== 0 ? (
                                        <img
                                            src={getFileIcon(
                                                fileList[0].fileName || ''
                                            )}
                                            alt='infoicon'
                                        />
                                    ) : null}
                                </div>

                                <div>
                                    <p className='text-[14px] leading-[20px]'>
                                        {fileList[0].name}
                                    </p>
                                    <span className='text-appcolorblackthree'>
                                        {formatBytes(fileList[0].size || 0)}
                                    </span>
                                </div>
                            </div>

                            <div className='w-fit'>
                                {
                                    uploading ? (
                                        <CircularProgressWithLabel
                                            value={percent}
                                        />
                                    ) : null
                                    // <img src="/img/vectors/deleteicon.svg" alt="deleteicon" />
                                }
                            </div>
                        </div>
                    )}
                </div>
            </Upload>

            {error !== null ? (
                <p className='text-sm text-appcolorred'>{error}</p>
            ) : null}

            {rejectedMessage !== undefined ? (
                <p className='text-sm text-appcolorred'>{rejectedMessage}</p>
            ) : null}
        </div>
    );
};

Documentupload.defaultProps = {
    runAfterUpload: false,
    documentUrl: '',
    returnURLOnly: false,
    acceptText: undefined,
    accept: '.png, .jpg, .pdf',
    maxCount: 1
};

export default Documentupload;
