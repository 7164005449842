import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Authouterpage from '../../components/authouterpage/authouterpage'
import { openNotification, validatePassword } from '../../utils/helper';
import { resetPassword } from '../../api/base/base';
import Passwordinput from '../../components/passwordinput/passwordinput';
import Custombutton from '../../components/custombutton/custombutton';

const Resetpassword = () => {
  const navigate = useNavigate();
  const { token } = useParams()

  const [loading, setLoading] = useState(false)
  const [payload, setPayload] = useState<any>({
    cipher: undefined
  })
  const [errors, setErrors] = useState({
    cipher: '',
  })

  /**
 * Update input fields
 * @param name string
 * @param value any
 */
  const updateField = (name: string, value: any) => {
    setPayload((prevState: any) => ({
      ...prevState,
      [name]: value
    }))
  }

  /**
 * Submit form
 * @param e Object
 */
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    setErrors((prevState: any) => ({
      ...prevState,
      email: '',
      resetUrl: ''
    }))

    try {
      await resetPassword({
        ...payload,
        resetCode: token
      })
      setLoading(false)

      openNotification("success", "Reset password, Reset password was successful.")

      navigate('/login')
    } catch (error: any) {
      setLoading(false)
      if (error?.response?.data) {
        const errors = error?.response?.data?.error
        if (typeof errors === 'string') {
          setErrors(prevState => ({
            ...prevState,
            cipher: errors
          }))
        } else {
          Object.entries(errors).forEach(([key, value]) => {
            setErrors(prevState => ({
              ...prevState,
              [key]: value
            }))
          })
        }
      } else {
        openNotification("error", "Reset password, Something went wrong")
      }
    }
  }

  /**
 * Form validation on value change
 */
  useEffect(() => {
    if (payload !== null) {
      setErrors((prevState) => ({
        ...prevState,
        cipher: payload?.cipher === undefined ? '' : validatePassword(payload?.cipher) ? '' : 'Invalid password',
      }))
    }
  }, [payload])

  return (
      <div>
          <Authouterpage
              title='RESET PASSWORD'
              info='Reset your FrontEdge password?'
          >
              <div>
                  <div className='bg-appcolorwhite py-[2.7rem] lg:py-[3.5rem] lg:px-[2.6rem] px-[1.6rem] mb-[1.75rem] rounded-[3px]'>
                      <form autoComplete='off' onSubmit={onSubmit} noValidate>
                          <Passwordinput
                              id='reset-password'
                              label='Password'
                              name='cipher'
                              updateField={updateField}
                              error={errors?.cipher.length > 0}
                              helperText={
                                  errors?.cipher.length > 0
                                      ? errors?.cipher
                                      : undefined
                              }
                          />

                          <div className='mt-4'>
                              <Custombutton
                                  isLoadingButton
                                  loading={loading}
                                  type='submit'
                                  fullWidth
                                  variant='contained'
                                  styles={{
                                      padding: '0.75rem 0.62rem 1rem 0.93rem'
                                  }}
                                  disabled={
                                      Object.values(payload).some(
                                          (item: any) => item === undefined
                                      ) ||
                                      Object.values(errors).some(
                                          (item) => item.length > 0
                                      ) ||
                                      loading
                                  }
                                  buttonText='Continue'
                              />
                          </div>
                      </form>
                  </div>
              </div>
          </Authouterpage>
      </div>
  );
}

export default Resetpassword