import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material'
import React, { Dispatch, FC, SetStateAction } from 'react'
import NewCustomSelect from '../../../newcustomselect/newcustomselect';
import Customdatepicker from '../../../customdatepicker/customdatepicker'
import Signature from '../../../signature/signature'
import ChooseDocUpload from '../../../custommodal/ChooseDocUpload'
import UploadLogo from '../common/UploadLogo'

interface IProps {
  error: any
  updateField: any
  payload: any
  corporatePartners: any
  loadingCorporatepartners: boolean
  setShowAddPartner: Dispatch<SetStateAction<boolean>>
  errors: any
  quoteFields: any[]
  methodOne: boolean
  methodTwo: boolean
  updateQuoteField: any
  handleChangeOne: any
  handleChangeTwo: any
  setSignatureUrlBase64: (value: string) => void
  signatureUrlBase64: any
  loading: boolean
  setShowSaveModal: Dispatch<SetStateAction<boolean>>
  setSaveOnly: Dispatch<SetStateAction<boolean>>
}

const VerifiedGrossForm: FC<IProps> = ({
  error,
  updateField,
  payload,
  corporatePartners,
  methodOne,
  methodTwo,
  loadingCorporatepartners,
  setShowAddPartner,
  errors,
  quoteFields,
  updateQuoteField,
  handleChangeOne,
  handleChangeTwo,

  loading,
  setSignatureUrlBase64,
  signatureUrlBase64,
  setSaveOnly,
  setShowSaveModal,
}: IProps): JSX.Element => {
  return (
      <div className='form-container'>
          <div className='border-0 border-appcolorgreyfaint'>
              {error !== null && (
                  <div className='mb-4'>
                      <Alert severity='error'>{error}</Alert>
                  </div>
              )}
              <form>
                  <div>
                      <div className='flex gap-2 items-center'>
                          <UploadLogo {...{ payload, updateField }} />

                          <ChooseDocUpload {...{ updateField, payload }}>
                              <p>Choose Logo</p>
                          </ChooseDocUpload>
                      </div>

                      <Grid container>
                          <Grid item xs={12} md={12} lg={6}>
                              <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                  <div className='mt-4'>
                                      <NewCustomSelect
                                          hasError={
                                              errors?.shipperId.length > 0
                                          }
                                          helperText={
                                              errors?.shipperId.length > 0
                                                  ? errors?.shipperId
                                                  : undefined
                                          }
                                          selectValue={payload.shipperId}
                                          required
                                          selectOptions={corporatePartners}
                                          name={'shipperId'}
                                          updateField={(_, value) => {
                                              updateField('shipperId', value);
                                              updateField(
                                                  'shipperAddress',
                                                  corporatePartners.filter(
                                                      (item: any) =>
                                                          item?.id === value
                                                  )[0]?.address
                                              );
                                              updateField(
                                                  'shipperBank',
                                                  corporatePartners.filter(
                                                      (item: any) =>
                                                          item?.id === value
                                                  )[0]?.bankName
                                              );

                                              updateField(
                                                  'shipperSwiftCode',
                                                  corporatePartners.filter(
                                                      (item: any) =>
                                                          item?.id === value
                                                  )[0]?.swiftCode
                                              );
                                          }}
                                          label={'Shipper'}
                                          // alternativeValue='shippingLineId'
                                          selectOptionLabel='name'
                                          loading={loadingCorporatepartners}
                                          dropdownRender={() => {
                                              return (
                                                  <div className='px-4 border-t border-appcolorgreyfaint'>
                                                      <p
                                                          className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                          onClick={() => {
                                                              setShowAddPartner(
                                                                  true
                                                              );
                                                          }}
                                                      >
                                                          + Add a New Partner
                                                      </p>
                                                  </div>
                                              );
                                          }}
                                      />
                                  </div>

                                  <TextField
                                      InputLabelProps={{
                                          shrink:
                                              payload.shipperAddress !==
                                              undefined
                                                  ? true
                                                  : false
                                      }}
                                      error={errors?.shipperAddress.length > 0}
                                      helperText={
                                          errors?.shipperAddress.length > 0
                                              ? errors?.shipperAddress
                                              : undefined
                                      }
                                      value={payload?.shipperAddress}
                                      margin='normal'
                                      required
                                      fullWidth
                                      id='quotation-exporterAddress'
                                      label='Shipper Address'
                                      name='shipperAddress'
                                      onChange={(e: any) => {
                                          updateField(
                                              'shipperAddress',
                                              e.target.value
                                          );
                                      }}
                                  />
                              </div>
                          </Grid>
                          <Grid item xs={12} md={12} lg={6}>
                              <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                  <Grid
                                      container
                                      spacing={1}
                                      sx={{
                                          marginTop: '0px'
                                      }}
                                  >
                                      <Grid item xs={12} md={12} lg={12}>
                                          <TextField
                                              error={
                                                  errors?.shipperReference
                                                      .length > 0
                                              }
                                              helperText={
                                                  errors?.shipperReference
                                                      .length > 0
                                                      ? errors?.shipperReference
                                                      : undefined
                                              }
                                              margin='normal'
                                              required
                                              fullWidth
                                              id='quotation-shipperReference'
                                              label="Shipper's Reference"
                                              name='shippers'
                                              value={payload.shipperReference}
                                              onChange={(e: any) => {
                                                  updateField(
                                                      'shipperReference',
                                                      e.target.value
                                                  );
                                              }}
                                          />
                                      </Grid>
                                      <Grid item xs={12} md={12} lg={12}>
                                          <TextField
                                              error={
                                                  errors?.certificateNumber
                                                      .length > 0
                                              }
                                              helperText={
                                                  errors?.certificateNumber
                                                      .length > 0
                                                      ? errors?.certificateNumber
                                                      : undefined
                                              }
                                              margin='normal'
                                              required
                                              fullWidth
                                              value={payload.certificateNumber}
                                              id='quotation-certificateNumber'
                                              label='Certificate Number'
                                              name='certificateNumber'
                                              onChange={(e: any) => {
                                                  updateField(
                                                      'certificateNumber',
                                                      e.target.value
                                                  );
                                              }}
                                          />
                                      </Grid>
                                  </Grid>
                              </div>
                          </Grid>
                      </Grid>
                  </div>

                  <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                      <Grid
                          container
                          spacing={1}
                          sx={{
                              marginTop: '0px'
                          }}
                      >
                          <Grid item xs={12} md={12} lg={6}>
                              <TextField
                                  error={errors?.billOfLadingNumber.length > 0}
                                  helperText={
                                      errors?.billOfLadingNumber?.length > 0
                                          ? errors?.billOfLadingNumber
                                          : undefined
                                  }
                                  margin='normal'
                                  value={payload?.billOfLadingNumber}
                                  required
                                  fullWidth
                                  id='quotation-billOfLadingNumber'
                                  label='Bill of Lading Number'
                                  name='billOfLadingNumber'
                                  onChange={(e: any) => {
                                      updateField(
                                          'billOfLadingNumber',
                                          e.target.value
                                      );
                                  }}
                              />
                          </Grid>
                      </Grid>
                  </div>

                  <div>
                      <FormControl>
                          <div className='border border-appcolorgreyfaint px-[1rem] pt-[1rem] pb-2 flex items-center'>
                              <div className='pl-2'>
                                  <p className='font-bold text-appcolorblacktwo leading-[18px] opacity-[0.6] pb-2'>
                                      METHOD 1
                                  </p>
                                  <p className='text-appcolorblacktwo leading-[18px] opacity-[0.6] '>
                                      Weighing the packed container using
                                      calibrated and certified weighing
                                      wquipment (e.g. weighbridges, load cell
                                      sensing technologies etc).
                                  </p>
                              </div>
                              <FormControlLabel
                                  control={
                                      <Checkbox
                                          onChange={handleChangeOne}
                                          name='methodOne'
                                          checked={methodOne}
                                      />
                                  }
                                  label=''
                              />
                          </div>

                          <div className='border border-appcolorgreyfaint px-[1rem] pt-[1rem] pb-2 flex items-center'>
                              <div className='pl-2'>
                                  <p className='font-bold text-appcolorblacktwo leading-[18px] opacity-[0.6] pb-2'>
                                      METHOD 2
                                  </p>
                                  <p className='text-appcolorblacktwo leading-[18px] opacity-[0.6] '>
                                      Weighing all packages and cargo items,
                                      including the mass of pallets, dunnage and
                                      other securing material to be packed in
                                      the container and adding the tare mass of
                                      the container to the sum of the single
                                      masses, using a certified method approved
                                      by the Country competent Authority (Marine
                                      and Coastguard Agency (MCA) or its
                                      authorized body).
                                  </p>
                              </div>
                              <FormControlLabel
                                  control={
                                      <Checkbox
                                          onChange={handleChangeTwo}
                                          name='methodOne'
                                          checked={methodTwo}
                                      />
                                  }
                                  label=''
                              />
                          </div>
                      </FormControl>
                  </div>

                  <div>
                      <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                          <Grid
                              container
                              spacing={1}
                              sx={{
                                  marginTop: '0px'
                              }}
                          >
                              <Grid item xs={12} md={12} lg={3}>
                                  <TextField
                                      error={errors?.containerNumber.length > 0}
                                      helperText={
                                          errors?.containerNumber.length > 0
                                              ? errors?.containerNumber
                                              : undefined
                                      }
                                      margin='normal'
                                      fullWidth
                                      id='verifiedgrossmassdeclaration--containerNumber'
                                      label='Container Number'
                                      name='containerNumber'
                                      value={payload.containerNumber}
                                      onChange={(e: any) => {
                                          updateField(
                                              'containerNumber',
                                              e.target.value
                                          );
                                          updateQuoteField(
                                              0,
                                              'containerNumber',
                                              e.target.value
                                          );
                                      }}
                                  />
                              </Grid>

                              <Grid item xs={12} md={12} lg={3}>
                                  <TextField
                                      error={errors?.containerTare.length > 0}
                                      helperText={
                                          errors?.containerTare.length > 0
                                              ? errors?.containerTare
                                              : undefined
                                      }
                                      margin='normal'
                                      fullWidth
                                      id='verifiedgrossmassdeclaration--containerTare'
                                      label='Container Tare (kg)'
                                      name='containerTare'
                                      value={payload.containerTare}
                                      onChange={(e: any) => {
                                          updateField(
                                              'containerTare',
                                              e.target.value
                                          );
                                          updateQuoteField(
                                              0,
                                              'containerTare',
                                              e.target.value
                                          );
                                      }}
                                  />
                              </Grid>

                              <Grid item xs={12} md={12} lg={3}>
                                  <TextField
                                      error={errors?.cargoWeight.length > 0}
                                      helperText={
                                          errors?.cargoWeight.length > 0
                                              ? errors?.cargoWeight
                                              : undefined
                                      }
                                      margin='normal'
                                      fullWidth
                                      value={payload.cargoWeight}
                                      id='verifiedgrossmassdeclaration--cargoWeight'
                                      label='Cargo Weight (kg)'
                                      name='cargoWeight'
                                      onChange={(e: any) => {
                                          updateField(
                                              'cargoWeight',
                                              e.target.value
                                          );
                                          updateQuoteField(
                                              0,
                                              'cargoWeight',
                                              e.target.value
                                          );
                                      }}
                                  />
                              </Grid>

                              <Grid item xs={12} md={12} lg={3}>
                                  <TextField
                                      error={errors?.grossWeight.length > 0}
                                      helperText={
                                          errors?.grossWeight.length > 0
                                              ? errors?.grossWeight
                                              : undefined
                                      }
                                      margin='normal'
                                      fullWidth
                                      id='verifiedgrossmassdeclaration-grossWeight'
                                      label='Gross Weight (kg)'
                                      name='grossWeight'
                                      onChange={(e: any) => {
                                          updateField(
                                              'grossWeight',
                                              e.target.value
                                          );
                                          updateQuoteField(
                                              0,
                                              'grossWeight',
                                              e.target.value
                                          );
                                      }}
                                      value={payload.grossWeight}
                                  />
                              </Grid>
                          </Grid>
                      </div>

                      <Grid container>
                          <Grid item xs={12} md={12} lg={6}>
                              <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                  <TextField
                                      multiline
                                      rows={7.4}
                                      margin='normal'
                                      fullWidth
                                      id='quotation-additionalInformation'
                                      label='Additional Information'
                                      name='additionalInformation'
                                      onChange={(e: any) => {
                                          updateField(
                                              'additionalInformation',
                                              e.target.value
                                          );
                                      }}
                                  />
                              </div>
                          </Grid>
                          <Grid item xs={12} md={12} lg={6}>
                              <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                  <Grid
                                      container
                                      spacing={1}
                                      sx={{
                                          marginTop: '0px'
                                      }}
                                  >
                                      <Grid item xs={12} md={12} lg={6}>
                                          <TextField
                                              error={
                                                  errors?.placeOfIssue.length >
                                                  0
                                              }
                                              helperText={
                                                  errors?.placeOfIssue.length >
                                                  0
                                                      ? errors?.placeOfIssue
                                                      : undefined
                                              }
                                              margin='normal'
                                              required
                                              fullWidth
                                              id='quotation-placeOfIssue'
                                              label='Place of Issue'
                                              name='placeOfIssue'
                                              value={payload.placeOfIssue}
                                              onChange={(e: any) => {
                                                  updateField(
                                                      'placeOfIssue',
                                                      e.target.value
                                                  );
                                              }}
                                          />
                                      </Grid>
                                      <Grid item xs={12} md={12} lg={6}>
                                          <div className='pt-4'>
                                              <Customdatepicker
                                                  id='quotation-issuedDate'
                                                  label='Date of Issue'
                                                  name='issuedDate'
                                                  updateField={updateField}
                                                  error={
                                                      errors?.issuedDate
                                                          .length > 0
                                                  }
                                                  helperText={
                                                      errors?.issuedDate
                                                          .length > 0
                                                          ? errors?.issuedDate
                                                          : undefined
                                                  }
                                                  value={
                                                      payload?.issuedDate !==
                                                      undefined
                                                          ? payload?.issuedDate
                                                          : null
                                                  }
                                              />
                                          </div>
                                      </Grid>
                                      <Grid item xs={12} md={12} lg={12}>
                                          <TextField
                                              error={
                                                  errors?.signatoryCompany
                                                      .length > 0
                                              }
                                              helperText={
                                                  errors?.signatoryCompany
                                                      .length > 0
                                                      ? errors?.signatoryCompany
                                                      : undefined
                                              }
                                              margin='normal'
                                              required
                                              fullWidth
                                              value={payload.signatoryCompany}
                                              id='quotation-signatoryCompany'
                                              label='Signatory Company'
                                              name='signatoryCompany'
                                              onChange={(e: any) => {
                                                  updateField(
                                                      'signatoryCompany',
                                                      e.target.value
                                                  );
                                              }}
                                          />
                                      </Grid>
                                      <Grid item xs={12} md={12} lg={12}>
                                          <TextField
                                              error={
                                                  errors
                                                      ?.nameOfAuthorisedSignatory
                                                      .length > 0
                                              }
                                              helperText={
                                                  errors
                                                      ?.nameOfAuthorisedSignatory
                                                      .length > 0
                                                      ? errors?.nameOfAuthorisedSignatory
                                                      : undefined
                                              }
                                              margin='normal'
                                              required
                                              fullWidth
                                              id='quotation-nameOfAuthorisedSignatory'
                                              label='Name of Authorized Signature'
                                              name='nameOfAuthorisedSignatory'
                                              onChange={(e: any) => {
                                                  updateField(
                                                      'nameOfAuthorisedSignatory',
                                                      e.target.value
                                                  );
                                              }}
                                          />
                                      </Grid>
                                      <Grid item xs={12} md={12} lg={12}>
                                          <div className='w-full'>
                                              <Signature
                                                  name='signatureUrl'
                                                  updateField={updateField}
                                                  setSignatureUrlBase64={
                                                      setSignatureUrlBase64
                                                  }
                                                  signatureUrlBase64={
                                                      signatureUrlBase64
                                                  }
                                              />
                                          </div>
                                      </Grid>
                                  </Grid>
                              </div>
                          </Grid>
                      </Grid>
                  </div>
              </form>
          </div>
      </div>
  );
}

export default VerifiedGrossForm
