import { TextField, InputAdornment, Autocomplete, Box } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { queryConfig } from '../api/queryBase';

export const PhoneInput = ({
    onChange,
    error
}: {
    onChange: (phone: string) => void;
    error?: boolean;
}) => {
    const query = queryConfig({ authed: false });
    const { isLoading, data } = query<{ name: string; phoneCode: string }[]>({
        url: '/countries'
    });
    const [countryCode, setCountryCode] = useState({
        name: 'Nigeria',
        phoneCode: '+234'
    });
    const handleOnCountryCodeSelect = (
        _: unknown,
        value: { name: string; phoneCode: string }
    ) => {
        setCountryCode(value);
    };
    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value: phone } = event.target;
        onChange(`${countryCode.phoneCode}${phone}`);
    };

    return (
        <TextField
            error={error}
            type='number'
            label='Phone Number'
            sx={{
                background: 'white',
                '& .MuiInputBase-input .MuiOutlinedInput-input': {
                    pr: '0 !important'
                }
            }}
            disabled={isLoading}
            onChange={handleOnChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position='start'>
                        <Autocomplete
                            sx={{
                                '& .MuiAutocomplete-input': {
                                    p: '0 !important',
                                    width: '50px !important'
                                },
                                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline':
                                    {
                                        border: 0,
                                        p: 0
                                    }
                            }}
                            onChange={handleOnCountryCodeSelect}
                            disableClearable
                            value={countryCode}
                            autoSelect={true}
                            getOptionLabel={(option) => option.phoneCode}
                            disabled={isLoading}
                            options={data ? data.payload : []}
                            renderInput={(params) => (
                                <TextField
                                    sx={{ outline: 'none' }}
                                    {...params}
                                />
                            )}
                            renderOption={(props, { name }) => (
                                <Box
                                    sx={{ outline: 'none', p: 2 }}
                                    component='li'
                                    {...props}
                                    key={name}
                                >
                                    {name}
                                </Box>
                            )}
                        />
                    </InputAdornment>
                )
            }}
        />
    );
};
