import { useEffect, useState } from 'react';
import {
    Box,
    FormControlLabel,
    Radio,
    Button,
    CircularProgress
} from '@mui/material';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { mutateConfig } from '../../../api/queryBase';
import { RadioCheckedIcon, RadioIcon } from '../../../components/icons';
import { Domains } from '../../../interfaces/Domains';
import { AccessPermissions } from '../../../interfaces/User';
import { getErrorMessage } from '../../../utils/helper';

interface AdminUserPermissionsProps {
    userId: number;
    accessPermissions: {
        [key in keyof Domains]: 'read' | 'write' | 'none' | '*';
    };
    refetchUsers: () => void;
}

export const permissionTypes = [
    {
        label: 'No Access',
        value: 'none'
    },
    {
        label: 'View',
        value: 'read'
    },
    {
        label: 'View & Change',
        value: 'write'
    }
];

const AdminUserPermissions = ({
    userId,
    accessPermissions,
    refetchUsers
}: AdminUserPermissionsProps) => {
    const [isUpdating, setIsupdating] = useState(false);
    const [permissions, setPermissions] =
        useState<AccessPermissions>(accessPermissions);

    const mutation = mutateConfig({ method: 'patch' });
    const { mutate } = mutation({
        url: `/users/${userId}/permissions`
    });

    useEffect(() => {
        setPermissions(accessPermissions);
    }, [accessPermissions]);

    const handleUpdatePermissions = async () => {
        setIsupdating(true);

        mutate(permissions, {
            onSettled: (_, error: AxiosError | undefined | any) => {
                setIsupdating(false);
                if (error) {
                    toast.error(
                        getErrorMessage(error) ||
                            'An error has occured while updating permissions'
                    );
                } else {
                    toast.success('Permissions update successful');
                    refetchUsers();
                }
            }
        });
    };

    return (
        <Box className='grow'>
            <Box className='rounded-t-sm bg-aliceBlue h-[37px] flex items-center px-[16px] font-bold text-sm text-ebony'>
                Permissions
            </Box>

            <Box className='grid grid-cols-3 gap-1 mt-1'>
                {Object.keys(permissions)?.map((section) => (
                    <Box key={section}>
                        <Box className='rounded-t-sm bg-aliceBlue h-[37px] flex items-center px-[16px] border-0 border-b border-[#EAECF0] font-bold text-sm text-[#101828] capitalize'>
                            {section}
                        </Box>
                        <Box className='grid grid-cols-2 bg-white p-[16px]  pr-[0px]'>
                            {permissionTypes.map((item) => (
                                <FormControlLabel
                                    key={item.value}
                                    value={item.value}
                                    control={
                                        <Radio
                                            onChange={() => {
                                                setPermissions({
                                                    ...permissions,
                                                    [section]: item.value
                                                });
                                            }}
                                            size='small'
                                            color='info'
                                            icon={<RadioIcon />}
                                            checkedIcon={<RadioCheckedIcon />}
                                        />
                                    }
                                    label={item.label}
                                    checked={
                                        permissions[
                                            section as keyof Domains
                                        ] === item.value
                                    }
                                />
                            ))}
                        </Box>
                    </Box>
                ))}
            </Box>

            <div className='flex justify-end my-6'>
                <Button
                    variant='contained'
                    sx={{
                        padding: '8px 40px',
                        height: '40px'
                    }}
                    onClick={handleUpdatePermissions}
                >
                    {isUpdating ? (
                        <CircularProgress size={15} color='inherit' />
                    ) : (
                        'Save'
                    )}
                </Button>
            </div>
        </Box>
    );
};

export default AdminUserPermissions;
