import { useEffect } from 'react';
import { Autocomplete, Box } from '@mui/material';
import { SalesContract } from './../../tradedocuments/salescontract/interfaces/SalesContract';
import { queryConfig } from './../../../../api/queryBase';
import { BookingFormProps } from './interfaces/BookingForm';
import { useGetQuery } from '../../../../customhooks/useGetQuery';
import { BookingConnection } from './interfaces/BookingConnection';
import CustomMapInput from '../../../../components/CustomMapInput';
import { CarrierBookingAgentsSelect } from '../../../../components/CarrierBookingAgentsSelect';
import CustomTextField from '../../../../components/CustomTextInput';

export const GeneralDetailsForm = ({
    user,
    setShowAddNewCarrierModal,
    formData,
    setFormData,
    formik
}: BookingFormProps) => {
    const query = queryConfig();
    const { data } = query<{ content: SalesContract[] }>({
        url: `/sales/contract/${user?.corporate_id}`,
        enabled: Boolean(user?.corporate_id)
    });
    const { entries: bookingConnections } = useGetQuery<BookingConnection[]>({
        url: '/booking/shipping/profiles',
        cacheKey: 'shipping-profiles',
        params: { page: 1, size: 0 }
    });

    const carrier = new URLSearchParams(location.search).get('carrier');

    const value = bookingConnections.find(
        (connection) => connection.shippingLineId === Number(carrier)
    );

    useEffect(() => {
        if (value) {
            setFormData((prev) => {
                const update = {
                    ...prev,
                    general: {
                        ...prev.general,
                        contractNumber: value.contractNumber,
                        carrierBookingNumber: value.bookingNumber,
                        shippingLineId: value.shippingLineId
                    }
                };
                formik.setValues(update);
                return update;
            });
        }
    }, [value?.shippingLineId]);
    return (
        <div className='flex flex-col lg:flex-row gap-4'>
            <div className='w-full'>
                <Autocomplete
                    autoComplete
                    disableClearable
                    options={data?.payload.content || []}
                    value={
                        {
                            salesContractNumber: formData.salesContractNumber
                        } as unknown as SalesContract
                    }
                    renderInput={(params) => (
                        <CustomTextField
                            params={params}
                            name='salesContractNumber'
                            label='Sales Contract Number'
                            required
                            error={!!formik.errors.salesContractNumber}
                            helperText={formik.errors.salesContractNumber}
                        />
                    )}
                    getOptionLabel={(option) => option.salesContractNumber}
                    renderOption={(props, { salesContractNumber }, state) => (
                        <Box component='li' {...props} key={state.index}>
                            {salesContractNumber}
                        </Box>
                    )}
                    onChange={(_, v) => {
                        if (v) {
                            const {
                                salesContractNumber,
                                containerType,
                                hsCode,
                                hsCodeDescription,
                                commodityId
                            } = v;

                            setFormData((prev) => {
                                const [cargo, ...cargoes] = prev.cargo;
                                const newCargo = {
                                    ...cargo,
                                    hsCode,
                                    description: hsCodeDescription,
                                    commodityId
                                };

                                const [container, ...containers] =
                                    prev.container;
                                const newContainer = {
                                    ...container,
                                    containerType
                                };
                                const update = {
                                    ...prev,
                                    salesContractNumber,
                                    cargo: [newCargo, ...cargoes],
                                    container: [newContainer, ...containers]
                                };

                                formik.setValues(update);

                                return update;
                            });
                        }
                    }}
                />
            </div>

            <div className='w-full'>
                <CarrierBookingAgentsSelect
                    value={formData.general.id}
                    onHandleChange={(value) => {
                        if (value) {
                            setFormData((prev) => {
                                const update = {
                                    ...prev,
                                    general: {
                                        ...prev.general,
                                        id: value.id,
                                        contractNumber: value.contractNumber,
                                        carrierBookingNumber:
                                            value.bookingNumber,
                                        shippingLineId: value.shippingLineId
                                    }
                                };
                                formik.setValues(update);
                                return update;
                            });
                        }
                    }}
                    setShowAddNewCarrierModal={setShowAddNewCarrierModal}
                    isError={!!formik.errors.general?.contractNumber}
                    helperText={formik.errors.general?.contractNumber}
                />
            </div>
            <CustomTextField
                name='contractNumber'
                required
                label='Carrier Contract Number'
                disabled
                value={formData.general.contractNumber}
            />

            <CustomMapInput
                name='bookingOffice'
                updateField={(_, value) => {
                    setFormData((prev) => ({
                        ...prev,
                        general: {
                            ...prev.general,
                            bookingOffice: value
                        }
                    }));
                }}
                label='Booking Office'
                value={formData.general.bookingOffice}
            />
        </div>
    );
};
