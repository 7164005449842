import ContractTabs from './contracts/ContractTabs';
import useCorporatePartners from '../../../customhooks/useCorporatePartners';
import InvoiceTabs from './invoice/InvoiceTabs';
import { SalesContractParams } from '../../../pages/dashboard/payments/interfaces/SalesContracts';
import { Dispatch, SetStateAction } from 'react';
import { InvoiceParams } from '../../../pages/dashboard/payments/interfaces/Invoice';

interface IProps {
    title: string;
    setViewExport?: Dispatch<SetStateAction<boolean>>;
    viewExport?: boolean;
    setFilterOptions?: Dispatch<SetStateAction<SalesContractParams>>;
    filterOptions?: SalesContractParams;
    fetchContracts?: () => void;
    fetchInvoice?: () => void;
    filterInvoiceOptions?: InvoiceParams;
    setFilterInvoiceOptions?: Dispatch<SetStateAction<InvoiceParams>>;
}
const SalesHeader = ({
    title,
    setViewExport,
    viewExport,
    setFilterOptions,
    filterOptions,
    fetchContracts,
    fetchInvoice,
    filterInvoiceOptions,
    setFilterInvoiceOptions
}: IProps) => {
    const { corporatePartners, isLoading, invalidateCorporatePartners } =
        useCorporatePartners({ page: 1 });

    return (
        <div className='page-header flex'>
            <h1 className='text-appcolorblacktwo'>{title}</h1>
            <div className='lg:flex hidden'>
                {title === 'Contracts' ? (
                    <ContractTabs
                        {...{
                            setViewExport,
                            viewExport,
                            setFilterOptions,
                            filterOptions,
                            fetchContracts,
                            corporatePartners
                        }}
                    />
                ) : (
                    <InvoiceTabs
                        {...{
                            corporatePartners,
                            fetchInvoice,
                            filterInvoiceOptions,
                            setFilterInvoiceOptions,
                            loadingCorporatePartners: isLoading
                        }}
                        fetchPartners={invalidateCorporatePartners}
                    />
                )}
            </div>
        </div>
    );
};

export default SalesHeader;
