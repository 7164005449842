export const DeleteIcon = ({ color = '#475467' }: { color?: string }) => {
    return (
        <svg
            width='20'
            height='23'
            viewBox='0 0 20 23'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M1 5.5H3M3 5.5H19M3 5.5V19.5C3 20.0304 3.21071 20.5391 3.58579 20.9142C3.96086 21.2893 4.46957 21.5 5 21.5H15C15.5304 21.5 16.0391 21.2893 16.4142 20.9142C16.7893 20.5391 17 20.0304 17 19.5V5.5H3ZM6 5.5V3.5C6 2.96957 6.21071 2.46086 6.58579 2.08579C6.96086 1.71071 7.46957 1.5 8 1.5H12C12.5304 1.5 13.0391 1.71071 13.4142 2.08579C13.7893 2.46086 14 2.96957 14 3.5V5.5M8 10.5V16.5M12 10.5V16.5'
                stroke={color}
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
