import Box from '@mui/material/Box';
import { EmptySearchIcon } from '../../../components/icons';
import { PlusIcon } from '../../../components/icons/PlusIcon';
import { SuitUIButton } from '../../../components/SuitUIButton';

const UserEmpty = ({
    handleInvite,
    subText
}: {
    handleInvite: () => void;
    subText: string;
}) => {
    return (
        <Box className='flex flex-col justify-center items-center w-full h-[60vh]'>
            <EmptySearchIcon />
            <h5 className='font-semibold text-lg text-ebony my-[16px]'>
                No user found
            </h5>
            <p className='font-normal text-sm text-midnightHaze mb-[40px] max-w-[352px] text-center'>
                {subText}
            </p>

            <SuitUIButton
                text='Invite new user'
                onClick={handleInvite}
                iconLeft={<PlusIcon color='#FFFFFF' />}
            />
        </Box>
    );
};

export default UserEmpty;
