import { Alert, Grid, MenuItem, TextField } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'
import NewCustomSelect from '../../../newcustomselect/newcustomselect';
import Customdatepicker from '../../../customdatepicker/customdatepicker'
import {
  medthodsOfDispatch,
  transports,
  typesOfShipment,
} from '../../../../utils/appconstants'
import Certificateoforiginfield from '../../../../pages/dashboard/tradedocuments/housebilloflading/certificateoforigin/certificateoforigin/certificateoforiginfield'
import Signature from '../../../signature/signature'
import ChooseDocUpload from '../../../custommodal/ChooseDocUpload'
import UploadLogo from '../common/UploadLogo'
import PortsAutocomplete from '../../../autocompletewithapi/PortsAutocomplete'

interface IProps {
  error: any
  updateField: any
  payload: any
  corporatePartners: any
  loadingCorporatepartners?: boolean
  setShowAddPartner: Dispatch<SetStateAction<boolean>>
  errors?: any
  quoteFields: any[]
  addQuoteField?: any
  handleChangeOne?: any
  handleChangeTwo?: any
  updateQuoteField?: any
  removeQuoteField?: any
  setSignatureUrlBase64: (value: string) => void
  setSignatureUrlBase64Chamber: (value: string) => void
  signatureUrlBase64?: any
  signatureUrlBase64Chamber?: any
  loading?: boolean
  setShowSaveModal?: Dispatch<SetStateAction<boolean>>
  setSaveOnly?: Dispatch<SetStateAction<boolean>>
  totalGrossWeight?: number
}

const CertificateOriginForm: FC<IProps> = ({
  error,
  updateField,
  payload,
  totalGrossWeight,
  corporatePartners,
  loadingCorporatepartners,
  setShowAddPartner,
  errors,
  quoteFields,
  updateQuoteField,
  removeQuoteField,
  addQuoteField,
  loading,
  setSignatureUrlBase64Chamber,
  setSignatureUrlBase64,
  signatureUrlBase64,
  signatureUrlBase64Chamber,
}: IProps): JSX.Element => {
  return (
      <>
          <div className='form-container'>
              <div className='border-0 border-appcolorgreyfaint'>
                  {error !== null && (
                      <div className='mb-4 '>
                          <Alert severity='error'>{error}</Alert>
                      </div>
                  )}
                  <form>
                      <div className=''>
                          <div className='flex gap-2 items-center'>
                              <UploadLogo {...{ payload, updateField }} />

                              <ChooseDocUpload {...{ updateField, payload }}>
                                  <p>Choose Logo</p>
                              </ChooseDocUpload>
                          </div>

                          <Grid container>
                              <Grid item xs={12} md={12} lg={6}>
                                  <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                      <div className='mt-4'>
                                          <NewCustomSelect
                                              hasError={
                                                  errors?.exporterId.length > 0
                                              }
                                              helperText={
                                                  errors?.exporterId.length > 0
                                                      ? errors?.exporterId
                                                      : undefined
                                              }
                                              selectValue={payload.exporterId}
                                              required
                                              selectOptions={corporatePartners}
                                              name={'exporterId'}
                                              updateField={(_, value) => {
                                                  updateField(
                                                      'exporterId',
                                                      value
                                                  );
                                                  updateField(
                                                      'exporterAddress',
                                                      corporatePartners.filter(
                                                          (item: any) =>
                                                              item?.id === value
                                                      )[0]?.address
                                                  );
                                                  updateField(
                                                      'exporterName',
                                                      corporatePartners.filter(
                                                          (item: any) =>
                                                              item?.id === value
                                                      )[0]?.name
                                                  );
                                                  updateField(
                                                      'exporterBankName',
                                                      corporatePartners.filter(
                                                          (item: any) =>
                                                              item?.id === value
                                                      )[0]?.bankName
                                                  );
                                                  updateField(
                                                      'exporterAccountNumber',
                                                      corporatePartners.filter(
                                                          (item: any) =>
                                                              item?.id === value
                                                      )[0]?.bankAccountNumber
                                                  );
                                                  updateField(
                                                      'exporterSwiftCode',
                                                      corporatePartners.filter(
                                                          (item: any) =>
                                                              item?.id === value
                                                      )[0]?.swiftCode
                                                  );
                                              }}
                                              label={'Exporter'}
                                              selectOptionLabel='name'
                                              loading={loadingCorporatepartners}
                                              dropdownRender={() => {
                                                  return (
                                                      <div className='px-4 border-t border-appcolorgreyfaint'>
                                                          <p
                                                              className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                              onClick={() => {
                                                                  setShowAddPartner(
                                                                      true
                                                                  );
                                                              }}
                                                          >
                                                              + Add a New
                                                              Partner
                                                          </p>
                                                      </div>
                                                  );
                                              }}
                                          />
                                      </div>

                                      <TextField
                                          InputLabelProps={{
                                              shrink:
                                                  payload.exporterAddress !==
                                                  undefined
                                                      ? true
                                                      : false
                                          }}
                                          error={
                                              errors?.exporterAddress.length > 0
                                          }
                                          helperText={
                                              errors?.exporterAddress.length > 0
                                                  ? errors?.exporterAddress
                                                  : undefined
                                          }
                                          value={payload?.exporterAddress}
                                          margin='normal'
                                          required
                                          fullWidth
                                          id='quotation-exporterAddress'
                                          label='Exporter Address'
                                          name='exporterAddress'
                                          onChange={(e: any) => {
                                              updateField(
                                                  'exporterAddress',
                                                  e.target.value
                                              );
                                          }}
                                      />
                                  </div>
                              </Grid>

                              <Grid item xs={12} md={12} lg={6}>
                                  <Grid
                                      container
                                      sx={{
                                          marginTop: '0px'
                                      }}
                                  >
                                      <Grid item xs={12} md={12} lg={6}>
                                          <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 '>
                                              <TextField
                                                  error={
                                                      errors
                                                          ?.exportInvoiceNumber
                                                          .length > 0
                                                  }
                                                  helperText={
                                                      errors
                                                          ?.exportInvoiceNumber
                                                          .length > 0
                                                          ? errors?.exportInvoiceNumber
                                                          : undefined
                                                  }
                                                  margin='normal'
                                                  required
                                                  fullWidth
                                                  id='proformainvoice-exportInvoiceNumber'
                                                  label='Invoice Number'
                                                  name='exportInvoiceNumber'
                                                  value={
                                                      payload?.exportInvoiceNumber
                                                  }
                                                  onChange={(e: any) => {
                                                      updateField(
                                                          'exportInvoiceNumber',
                                                          e.target.value
                                                      );
                                                  }}
                                              />
                                          </div>
                                      </Grid>

                                      <Grid item xs={12} md={12} lg={6}>
                                          <div className='border border-appcolorgreyfaint px-[1rem] pt-[1.1rem] pb-2 h-full'>
                                              <div className=''>
                                                  <Customdatepicker
                                                      value={
                                                          payload?.exportInvoiceDate !==
                                                          undefined
                                                              ? payload?.exportInvoiceDate
                                                              : null
                                                      }
                                                      id='quotation-issuedDate'
                                                      label='Exporter Invoice Date'
                                                      name='exportInvoiceDate'
                                                      updateField={updateField}
                                                      error={
                                                          errors
                                                              ?.exportInvoiceDate
                                                              .length > 0
                                                      }
                                                      helperText={
                                                          errors
                                                              ?.exportInvoiceDate
                                                              .length > 0
                                                              ? errors?.exportInvoiceDate
                                                              : undefined
                                                      }
                                                  />
                                              </div>
                                          </div>
                                      </Grid>
                                  </Grid>

                                  <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                                      <TextField
                                          error={
                                              errors?.letterOfCreditNumber
                                                  .length > 0
                                          }
                                          helperText={
                                              errors?.letterOfCreditNumber
                                                  .length > 0
                                                  ? errors?.letterOfCreditNumber
                                                  : undefined
                                          }
                                          margin='normal'
                                          required
                                          fullWidth
                                          id='proformainvoice-letterOfCreditNumber'
                                          label='Letter of Credit Number'
                                          name='letterOfCreditNumber'
                                          value={payload?.letterOfCreditNumber}
                                          onChange={(e: any) => {
                                              updateField(
                                                  'letterOfCreditNumber',
                                                  e.target.value
                                              );
                                          }}
                                      />
                                  </div>
                              </Grid>
                          </Grid>

                          <Grid container>
                              <Grid item xs={12} md={12} lg={6}>
                                  <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 '>
                                      <div className='mt-4'>
                                          <NewCustomSelect
                                              hasError={
                                                  errors?.consigneeId.length > 0
                                              }
                                              helperText={
                                                  errors?.consigneeId.length > 0
                                                      ? errors?.consigneeId
                                                      : undefined
                                              }
                                              selectValue={payload.consigneeId}
                                              required
                                              selectOptions={corporatePartners}
                                              name={'consigneeId'}
                                              updateField={(_, value) => {
                                                  updateField(
                                                      'consigneeId',
                                                      value
                                                  );
                                                  updateField(
                                                      'consigneeAddress',
                                                      corporatePartners.filter(
                                                          (item: any) =>
                                                              item?.id === value
                                                      )[0]?.address
                                                  );
                                                  updateField(
                                                      'consigneeName',
                                                      corporatePartners.filter(
                                                          (item: any) =>
                                                              item?.id === value
                                                      )[0]?.name
                                                  );
                                                  updateField(
                                                      'consigneeBankName',
                                                      corporatePartners.filter(
                                                          (item: any) =>
                                                              item?.id === value
                                                      )[0]?.bankName
                                                  );
                                                  updateField(
                                                      'consigneeAccountNumber',
                                                      corporatePartners.filter(
                                                          (item: any) =>
                                                              item?.id === value
                                                      )[0]?.bankAccountNumber
                                                  );
                                                  updateField(
                                                      'consigneeSwiftCode',
                                                      corporatePartners.filter(
                                                          (item: any) =>
                                                              item?.id === value
                                                      )[0]?.swiftCode
                                                  );
                                              }}
                                              label={'Consignee'}
                                              selectOptionLabel='name'
                                              loading={loadingCorporatepartners}
                                              dropdownRender={() => {
                                                  return (
                                                      <div className='px-4 border-t border-appcolorgreyfaint'>
                                                          <p
                                                              className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                              onClick={() => {
                                                                  setShowAddPartner(
                                                                      true
                                                                  );
                                                              }}
                                                          >
                                                              + Add a New
                                                              Partner
                                                          </p>
                                                      </div>
                                                  );
                                              }}
                                          />
                                      </div>

                                      <TextField
                                          InputLabelProps={{
                                              shrink:
                                                  payload.consigneeAddress !==
                                                  undefined
                                                      ? true
                                                      : false
                                          }}
                                          error={
                                              errors?.consigneeAddress.length >
                                              0
                                          }
                                          helperText={
                                              errors?.consigneeAddress.length >
                                              0
                                                  ? errors?.consigneeAddress
                                                  : undefined
                                          }
                                          value={payload?.consigneeAddress}
                                          margin='normal'
                                          required
                                          fullWidth
                                          id='quotation-consigneeAddress'
                                          label='Consignee Address'
                                          name='consigneeAddress'
                                          onChange={(e: any) => {
                                              updateField(
                                                  'consigneeAddress',
                                                  e.target.value
                                              );
                                          }}
                                      />
                                  </div>
                              </Grid>
                              <Grid item xs={12} md={12} lg={6}>
                                  <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 '>
                                      <div className='mt-4'>
                                          <NewCustomSelect
                                              hasError={
                                                  errors?.buyerId.length > 0
                                              }
                                              helperText={
                                                  errors?.buyerId.length > 0
                                                      ? errors?.buyerId
                                                      : undefined
                                              }
                                              selectValue={payload.buyerId}
                                              required
                                              selectOptions={corporatePartners}
                                              name={'buyerId'}
                                              updateField={(_, value) => {
                                                  updateField('buyerId', value);
                                                  updateField(
                                                      'buyerName',
                                                      corporatePartners.filter(
                                                          (item: any) =>
                                                              item?.id === value
                                                      )[0]?.name
                                                  );
                                                  updateField(
                                                      'buyerAddress',
                                                      corporatePartners.filter(
                                                          (item: any) =>
                                                              item?.id === value
                                                      )[0]?.address
                                                  );
                                              }}
                                              label={'Buyer'}
                                              // alternativeValue='shippingLineId'
                                              selectOptionLabel='name'
                                              loading={loadingCorporatepartners}
                                              dropdownRender={() => {
                                                  return (
                                                      <div className='px-4 border-t border-appcolorgreyfaint'>
                                                          <p
                                                              className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                              onClick={() => {
                                                                  setShowAddPartner(
                                                                      true
                                                                  );
                                                              }}
                                                          >
                                                              + Add a New
                                                              Partner
                                                          </p>
                                                      </div>
                                                  );
                                              }}
                                          />
                                      </div>

                                      <TextField
                                          InputLabelProps={{
                                              shrink:
                                                  payload.buyerAddress !==
                                                  undefined
                                                      ? true
                                                      : false
                                          }}
                                          error={
                                              errors?.buyerAddress.length > 0
                                          }
                                          helperText={
                                              errors?.buyerAddress.length > 0
                                                  ? errors?.buyerAddress
                                                  : undefined
                                          }
                                          value={payload?.buyerAddress}
                                          margin='normal'
                                          required
                                          fullWidth
                                          id='quotation-buyerAddress'
                                          label='Buyer Address'
                                          name='buyerAddress'
                                          onChange={(e: any) => {
                                              updateField(
                                                  'buyerAddress',
                                                  e.target.value
                                              );
                                          }}
                                      />
                                  </div>
                              </Grid>
                          </Grid>

                          <Grid container>
                              <Grid item xs={12} md={12} lg={6}>
                                  <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                      <Grid
                                          container
                                          spacing={6}
                                          sx={{
                                              marginTop: '0px'
                                          }}
                                      >
                                          <Grid item xs={12} md={12} lg={6}>
                                              <TextField
                                                  select
                                                  error={
                                                      errors?.methodOfDispatch
                                                          .length > 0
                                                  }
                                                  helperText={
                                                      errors?.methodOfDispatch
                                                          .length > 0
                                                          ? errors?.methodOfDispatch
                                                          : undefined
                                                  }
                                                  margin='normal'
                                                  required
                                                  fullWidth
                                                  id='certificateoforgin-methodOfDispatch'
                                                  label='Method of Dispatch'
                                                  name='methodOfDispatch'
                                                  value={
                                                      payload?.methodOfDispatch
                                                  }
                                                  onChange={(e: any) => {
                                                      updateField(
                                                          'methodOfDispatch',
                                                          e.target.value
                                                      );
                                                  }}
                                              >
                                                  {medthodsOfDispatch.map(
                                                      (item, index) => (
                                                          <MenuItem
                                                              value={item}
                                                              key={`medthodsOfDispatch-${item}-${index}`}
                                                          >
                                                              {item}
                                                          </MenuItem>
                                                      )
                                                  )}
                                              </TextField>
                                          </Grid>

                                          <Grid item xs={12} md={12} lg={6}>
                                              <TextField
                                                  select
                                                  error={
                                                      errors?.typeOfShipment
                                                          .length > 0
                                                  }
                                                  helperText={
                                                      errors?.typeOfShipment
                                                          .length > 0
                                                          ? errors?.typeOfShipment
                                                          : undefined
                                                  }
                                                  margin='normal'
                                                  required
                                                  fullWidth
                                                  id='certificateoforgin-typeOfShipment'
                                                  label='Type of Shipment'
                                                  name='typeOfShipment'
                                                  value={
                                                      payload?.typeOfShipment
                                                  }
                                                  onChange={(e: any) => {
                                                      updateField(
                                                          'typeOfShipment',
                                                          e.target.value
                                                      );
                                                  }}
                                              >
                                                  {typesOfShipment.map(
                                                      (item, index) => (
                                                          <MenuItem
                                                              value={item}
                                                              key={`typesOfShipment-${item}-${index}`}
                                                          >
                                                              {item}
                                                          </MenuItem>
                                                      )
                                                  )}
                                              </TextField>
                                          </Grid>
                                      </Grid>

                                      <Grid
                                          container
                                          spacing={6}
                                          sx={{
                                              marginTop: '0px'
                                          }}
                                      >
                                          <Grid item xs={12} md={12} lg={6}>
                                              <TextField
                                                  select
                                                  error={
                                                      errors?.transport.length >
                                                      0
                                                  }
                                                  helperText={
                                                      errors?.transport.length >
                                                      0
                                                          ? errors?.transport
                                                          : undefined
                                                  }
                                                  margin='normal'
                                                  required
                                                  fullWidth
                                                  id='certificateoforgin-transport'
                                                  label='Transport'
                                                  name='transport'
                                                  value={payload?.transport}
                                                  onChange={(e: any) => {
                                                      updateField(
                                                          'transport',
                                                          e.target.value
                                                      );
                                                  }}
                                              >
                                                  {transports.map(
                                                      (item, index) => (
                                                          <MenuItem
                                                              value={item}
                                                              key={`transports-${item}-${index}`}
                                                          >
                                                              {item}
                                                          </MenuItem>
                                                      )
                                                  )}
                                              </TextField>
                                          </Grid>

                                          <Grid item xs={12} md={12} lg={6}>
                                              <TextField
                                                  error={
                                                      errors?.voyageNumber
                                                          .length > 0
                                                  }
                                                  helperText={
                                                      errors?.voyageNumber
                                                          .length > 0
                                                          ? errors?.voyageNumber
                                                          : undefined
                                                  }
                                                  margin='normal'
                                                  required
                                                  fullWidth
                                                  id='proformainvoice-voyageNumber'
                                                  label='Voyage Number'
                                                  name='voyageNumber'
                                                  value={payload?.voyageNumber}
                                                  onChange={(e: any) => {
                                                      updateField(
                                                          'voyageNumber',
                                                          e.target.value
                                                      );
                                                  }}
                                              />
                                          </Grid>
                                      </Grid>

                                      <Grid
                                          container
                                          spacing={6}
                                          sx={{
                                              marginTop: '0px'
                                          }}
                                      >
                                          <Grid item xs={12} md={12} lg={6}>
                                              <PortsAutocomplete
                                                  errorCondition={
                                                      errors?.portOfLoadingId
                                                          .length > 0
                                                  }
                                                  helperText={
                                                      errors?.portOfLoadingId
                                                          .length > 0
                                                          ? errors?.portOfLoadingId
                                                          : undefined
                                                  }
                                                  id='quotation-portOfLoading'
                                                  label='Port of Loading'
                                                  name='portOfLoadingId'
                                                  updateField={updateField}
                                                  nameValue='portOfLoadingValue'
                                                  value={
                                                      payload.portOfLoadingValue
                                                  }
                                              />
                                          </Grid>

                                          <Grid item xs={12} md={12} lg={6}>
                                              <PortsAutocomplete
                                                  errorCondition={
                                                      errors?.portOfDischargeId
                                                          .length > 0
                                                  }
                                                  helperText={
                                                      errors?.portOfDischargeId
                                                          .length > 0
                                                          ? errors?.portOfDischargeId
                                                          : undefined
                                                  }
                                                  id='quotation-portOfDischarge'
                                                  label='Port of Discharge'
                                                  name='portOfDischargeId'
                                                  updateField={updateField}
                                                  nameValue='portOfDischargeValue'
                                                  value={
                                                      payload.portOfDischargeValue
                                                  }
                                              />
                                          </Grid>
                                      </Grid>

                                      <Grid
                                          container
                                          spacing={6}
                                          sx={{
                                              marginTop: '0px'
                                          }}
                                      >
                                          <Grid item xs={12} md={12} lg={6}>
                                              <div className='pt-4'>
                                                  <Customdatepicker
                                                      value={
                                                          payload?.dateOfDeparture !==
                                                          undefined
                                                              ? payload?.dateOfDeparture
                                                              : null
                                                      }
                                                      id='quotation-dateOfDeparture'
                                                      label='Date of Departure'
                                                      name='dateOfDeparture'
                                                      updateField={updateField}
                                                      error={
                                                          errors
                                                              ?.dateOfDeparture
                                                              .length > 0
                                                      }
                                                      helperText={
                                                          errors
                                                              ?.dateOfDeparture
                                                              .length > 0
                                                              ? errors?.dateOfDeparture
                                                              : undefined
                                                      }
                                                  />
                                              </div>
                                          </Grid>
                                          <Grid item xs={12} md={12} lg={6}>
                                              <TextField
                                                  error={
                                                      errors?.finalDestination
                                                          .length > 0
                                                  }
                                                  helperText={
                                                      errors?.finalDestination
                                                          .length > 0
                                                          ? errors?.finalDestination
                                                          : undefined
                                                  }
                                                  margin='normal'
                                                  required
                                                  fullWidth
                                                  id='certificateoforgin-finalDestination'
                                                  label='Final Destination'
                                                  name='finalDestination'
                                                  value={
                                                      payload?.finalDestination
                                                  }
                                                  onChange={(e: any) => {
                                                      updateField(
                                                          'finalDestination',
                                                          e.target.value
                                                      );
                                                  }}
                                              />
                                          </Grid>
                                      </Grid>
                                  </div>
                              </Grid>

                              <Grid item xs={12} md={12} lg={6}>
                                  <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 '>
                                      <TextField
                                          multiline
                                          rows={4}
                                          error={
                                              errors?.packingDetails.length > 0
                                          }
                                          helperText={
                                              errors?.packingDetails.length > 0
                                                  ? errors?.packingDetails
                                                  : undefined
                                          }
                                          margin='normal'
                                          // required
                                          fullWidth
                                          id='certificateoforgin-packingDetails'
                                          label='Parking Details'
                                          name='packingDetails'
                                          value={payload?.packingDetails}
                                          onChange={(e: any) => {
                                              updateField(
                                                  'packingDetails',
                                                  e.target.value
                                              );
                                          }}
                                      />
                                  </div>
                              </Grid>
                          </Grid>
                      </div>
                      <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                          <Grid
                              container
                              spacing={1}
                              sx={{
                                  marginTop: '0px'
                              }}
                          >
                              <Grid item xs={12} md={12} lg={6}>
                                  <TextField
                                      error={
                                          errors?.billOfLadingNumber.length > 0
                                      }
                                      helperText={
                                          errors?.billOfLadingNumber?.length > 0
                                              ? errors?.billOfLadingNumber
                                              : undefined
                                      }
                                      margin='normal'
                                      value={payload?.billOfLadingNumber}
                                      required
                                      fullWidth
                                      id='quotation-billOfLadingNumber'
                                      label='Bill of Lading Number'
                                      name='billOfLadingNumber'
                                      onChange={(e: any) => {
                                          updateField(
                                              'billOfLadingNumber',
                                              e.target.value
                                          );
                                      }}
                                  />
                              </Grid>
                          </Grid>
                      </div>
                      <div className='relative w-full'>
                          {quoteFields.map((item, index) => (
                              <Certificateoforiginfield
                                  key={item.id}
                                  index={index}
                                  id={item.id}
                                  updateQuoteField={updateQuoteField}
                                  grossWeight={item.grossWeight}
                                  qtyOfPackage={item.qtyOfPackage}
                                  removeQuoteField={removeQuoteField}
                                  item={item}
                              />
                          ))}
                      </div>

                      <div className=''>
                          <div className='border border-appcolorgreyfaint px-[1rem] pt-8 pb-8 '>
                              <div
                                  className='flex items-center cursor-pointer w-fit'
                                  onClick={() => {
                                      addQuoteField();
                                  }}
                              >
                                  <div>
                                      <img
                                          src='/img/vectors/primarydownload.svg'
                                          alt='primarydownload'
                                          className='w-6'
                                      />
                                  </div>
                                  <span className='pl-2 text-appcolorprimary'>
                                      Add Field
                                  </span>
                              </div>
                          </div>

                          <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 flex justify-end '>
                              <div className='w-[250px]'>
                                  <TextField
                                      disabled
                                      margin='normal'
                                      fullWidth
                                      id='certificateoforgin-totalAmount'
                                      label='Total gross weight (kg)'
                                      name='totalAmount'
                                      value={totalGrossWeight}
                                  />
                              </div>
                          </div>

                          <div className='border border-appcolorgreyfaint px-[1rem] pt-[1rem] pb-2'>
                              <Grid container>
                                  <Grid item xs={12} md={12} lg={6} pr={'1rem'}>
                                      <div className='pl-2'>
                                          <p className='font-bold text-appcolorblacktwo leading-[18px] opacity-[0.6] pb-2'>
                                              Declaration By The Chamber
                                          </p>
                                          <p className='text-appcolorblacktwo leading-[18px] opacity-[0.6] '>
                                              The undersigned certifies on the
                                              basis of information provided by
                                              the exporter name that to the best
                                              of it’s knowledge and belief, the
                                              goods are of designated origin,
                                              production or manufacture.
                                          </p>
                                      </div>
                                  </Grid>
                                  <Grid item xs={12} md={12} lg={6}>
                                      <div className='pl-2'>
                                          <p className='font-bold text-appcolorblacktwo leading-[18px] opacity-[0.6] pb-2'>
                                              Declaration By The Exporter
                                          </p>
                                          <p className='text-appcolorblacktwo leading-[18px] opacity-[0.6] '>
                                              I, the undersigned, being duly
                                              authorized by the Consignor, and
                                              having made the necessary
                                              enquiries hereby certify that
                                              based on the rules of origin of
                                              the country of destination, all
                                              the goods listed originate in the
                                              country and place of designated. I
                                              further declare that I will
                                              furnish to the Customs authorities
                                              of the importing or their nominee,
                                              for inspection at any time, such
                                              as evidence as may be required for
                                              the purpose of verifying this
                                              certificate.
                                          </p>
                                      </div>
                                  </Grid>
                              </Grid>
                          </div>

                          <Grid container>
                              <Grid item xs={12} md={12} lg={6}>
                                  <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 '>
                                      <Grid
                                          container
                                          spacing={6}
                                          sx={{
                                              marginTop: '0px'
                                          }}
                                      >
                                          <Grid item xs={12} md={12} lg={6}>
                                              <TextField
                                                  error={
                                                      errors
                                                          ?.chamberPlaceOfIssue
                                                          .length > 0
                                                  }
                                                  helperText={
                                                      errors
                                                          ?.chamberPlaceOfIssue
                                                          .length > 0
                                                          ? errors?.chamberPlaceOfIssue
                                                          : undefined
                                                  }
                                                  margin='normal'
                                                  required
                                                  fullWidth
                                                  id='certificateoforgin-exporterPlaceOfIssue'
                                                  label='Chamber Place of Issue'
                                                  name='chamberPlaceOfIssue'
                                                  value={
                                                      payload?.chamberPlaceOfIssue
                                                  }
                                                  onChange={(e: any) => {
                                                      updateField(
                                                          'chamberPlaceOfIssue',
                                                          e.target.value
                                                      );
                                                  }}
                                              />
                                          </Grid>
                                          <Grid item xs={12} md={12} lg={6}>
                                              <div className='pt-4'>
                                                  <Customdatepicker
                                                      value={
                                                          payload?.chamberIssuedDate !==
                                                          undefined
                                                              ? payload?.chamberIssuedDate
                                                              : null
                                                      }
                                                      id='quotation-chamberIssuedDate'
                                                      label='Chamber Issued Date'
                                                      name='chamberIssuedDate'
                                                      updateField={updateField}
                                                      error={
                                                          errors
                                                              ?.chamberIssuedDate
                                                              .length > 0
                                                      }
                                                      helperText={
                                                          errors
                                                              ?.chamberIssuedDate
                                                              .length > 0
                                                              ? errors?.chamberIssuedDate
                                                              : undefined
                                                      }
                                                  />
                                              </div>
                                          </Grid>
                                          <Grid item xs={12} md={12} lg={12}>
                                              <TextField
                                                  error={
                                                      errors
                                                          ?.chamberSignatoryCompany
                                                          .length > 0
                                                  }
                                                  helperText={
                                                      errors
                                                          ?.chamberSignatoryCompany
                                                          .length > 0
                                                          ? errors?.chamberSignatoryCompany
                                                          : undefined
                                                  }
                                                  margin='normal'
                                                  required
                                                  fullWidth
                                                  id='certificateoforgin-chamberSignatoryCompany'
                                                  label='Chamber Signatory Company'
                                                  name='chamberSignatoryCompany'
                                                  value={
                                                      payload?.chamberSignatoryCompany
                                                  }
                                                  onChange={(e: any) => {
                                                      updateField(
                                                          'chamberSignatoryCompany',
                                                          e.target.value
                                                      );
                                                  }}
                                              />
                                          </Grid>
                                          <Grid item xs={12} md={12} lg={12}>
                                              <TextField
                                                  error={
                                                      errors
                                                          ?.chamberNameOfAuthorisedSignatory
                                                          .length > 0
                                                  }
                                                  helperText={
                                                      errors
                                                          ?.chamberNameOfAuthorisedSignatory
                                                          .length > 0
                                                          ? errors?.chamberNameOfAuthorisedSignatory
                                                          : undefined
                                                  }
                                                  margin='normal'
                                                  required
                                                  fullWidth
                                                  value={
                                                      payload?.chamberNameOfAuthorisedSignatory
                                                  }
                                                  id='certificateoforgin-chamberNameOfAuthorisedSignatory'
                                                  label='Chamber Name of Authorized Signature'
                                                  name='chamberNameOfAuthorisedSignatory'
                                                  onChange={(e: any) => {
                                                      updateField(
                                                          'chamberNameOfAuthorisedSignatory',
                                                          e.target.value
                                                      );
                                                  }}
                                              />
                                          </Grid>
                                          <Grid item xs={12} md={12} lg={12}>
                                              <div className='w-full'>
                                                  <Signature
                                                      label='Chamber Signature'
                                                      name='chamberSignatureUrl'
                                                      updateField={updateField}
                                                      setSignatureUrlBase64={
                                                          setSignatureUrlBase64Chamber
                                                      }
                                                      signatureUrlBase64={
                                                          signatureUrlBase64Chamber
                                                      }
                                                  />
                                              </div>
                                          </Grid>
                                      </Grid>
                                  </div>
                              </Grid>
                              <Grid item xs={12} md={12} lg={6}>
                                  <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 '>
                                      <Grid
                                          container
                                          spacing={6}
                                          sx={{
                                              marginTop: '0px'
                                          }}
                                      >
                                          <Grid item xs={12} md={12} lg={6}>
                                              <TextField
                                                  // select
                                                  error={
                                                      errors
                                                          ?.exporterPlaceOfIssue
                                                          .length > 0
                                                  }
                                                  helperText={
                                                      errors
                                                          ?.exporterPlaceOfIssue
                                                          .length > 0
                                                          ? errors?.exporterPlaceOfIssue
                                                          : undefined
                                                  }
                                                  margin='normal'
                                                  required
                                                  fullWidth
                                                  id='certificateoforgin-exporterPlaceOfIssue'
                                                  label='Place of Issue'
                                                  name='exporterPlaceOfIssue'
                                                  value={
                                                      payload?.exporterPlaceOfIssue
                                                  }
                                                  onChange={(e: any) => {
                                                      updateField(
                                                          'exporterPlaceOfIssue',
                                                          e.target.value
                                                      );
                                                  }}
                                              />
                                          </Grid>
                                          <Grid item xs={12} md={12} lg={6}>
                                              <div className='pt-4'>
                                                  <Customdatepicker
                                                      value={
                                                          payload?.exporterIssuedDate !==
                                                          undefined
                                                              ? payload?.exporterIssuedDate
                                                              : null
                                                      }
                                                      id='quotation-issuedDate'
                                                      label='Exporter Issued Date'
                                                      name='exporterIssuedDate'
                                                      updateField={updateField}
                                                      error={
                                                          errors
                                                              ?.exporterIssuedDate
                                                              .length > 0
                                                      }
                                                      helperText={
                                                          errors
                                                              ?.exporterIssuedDate
                                                              .length > 0
                                                              ? errors?.exporterIssuedDate
                                                              : undefined
                                                      }
                                                  />
                                              </div>
                                          </Grid>
                                          <Grid item xs={12} md={12} lg={12}>
                                              <TextField
                                                  error={
                                                      errors
                                                          ?.exporterSignatoryCompany
                                                          .length > 0
                                                  }
                                                  helperText={
                                                      errors
                                                          ?.exporterSignatoryCompany
                                                          .length > 0
                                                          ? errors?.exporterSignatoryCompany
                                                          : undefined
                                                  }
                                                  margin='normal'
                                                  required
                                                  fullWidth
                                                  id='certificateoforgin-exporterSignatoryCompany'
                                                  label='Signatory Company'
                                                  name='exporterSignatoryCompany'
                                                  value={
                                                      payload?.exporterSignatoryCompany
                                                  }
                                                  onChange={(e: any) => {
                                                      updateField(
                                                          'exporterSignatoryCompany',
                                                          e.target.value
                                                      );
                                                  }}
                                              />
                                          </Grid>
                                          <Grid item xs={12} md={12} lg={12}>
                                              <TextField
                                                  error={
                                                      errors
                                                          ?.exporterNameOfAuthorisedSignatory
                                                          .length > 0
                                                  }
                                                  helperText={
                                                      errors
                                                          ?.exporterNameOfAuthorisedSignatory
                                                          .length > 0
                                                          ? errors?.exporterNameOfAuthorisedSignatory
                                                          : undefined
                                                  }
                                                  margin='normal'
                                                  required
                                                  fullWidth
                                                  id='certificateoforgin-exporterNameOfAuthorisedSignatory'
                                                  label='Name of Authorized Signature'
                                                  name='exporterNameOfAuthorisedSignatory'
                                                  value={
                                                      payload?.exporterNameOfAuthorisedSignatory
                                                  }
                                                  onChange={(e: any) => {
                                                      updateField(
                                                          'exporterNameOfAuthorisedSignatory',
                                                          e.target.value
                                                      );
                                                  }}
                                              />
                                          </Grid>
                                          <Grid item xs={12} md={12} lg={12}>
                                              <div className='w-full'>
                                                  <Signature
                                                      name='exporterSignatureUrl'
                                                      updateField={updateField}
                                                      setSignatureUrlBase64={
                                                          setSignatureUrlBase64
                                                      }
                                                      signatureUrlBase64={
                                                          signatureUrlBase64
                                                      }
                                                  />
                                              </div>
                                          </Grid>
                                      </Grid>
                                  </div>
                              </Grid>
                          </Grid>
                      </div>
                  </form>
              </div>
          </div>
      </>
  );
}

export default CertificateOriginForm
