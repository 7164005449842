export const getFormattedAmount = ({
    amount,
    currency,
    compact
}: {
    amount: number;
    currency: string;
    compact?: boolean;
}) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
        ...(compact && amount > 999_999
            ? {
                  notation: 'compact'
              }
            : { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    }).format(amount);
};
