import { Autocomplete, Chip, TextField } from '@mui/material';

export const PartyNotificationEntry = ({
    onHandleChange,
    label = 'Notifying Party'
}: {
    onHandleChange?: (values: string[]) => void;
    label?: string;
}) => {
    return (
        <div>
            <Autocomplete
                freeSolo
                multiple
                disableClearable
                options={[]}
                sx={{
                    marginTop: '0rem',
                    '.MuiInputBase-input': {
                        height: '0.3rem'
                    }
                }}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant='outlined'
                            label={option}
                            {...getTagProps({
                                index
                            })}
                        />
                    ))
                }
                renderInput={(params) => (
                    <TextField {...params} label={label} required={false} />
                )}
                onChange={(_, value) => {
                    if (onHandleChange) {
                        onHandleChange(value);
                    }
                }}
            />
            <div className='text-appcolorprimary text-[13px] text-right mt-1'>
                This input supports multiple email entries, press enter when
                done to start a new entry.
            </div>
        </div>
    );
};
