import { Box, Divider, Stack, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode, useState } from 'react';

interface Tab {
    label: string;
    onHandleClick: () => void;
    view: boolean;
    icon?: ReactNode;
}

const Tab = styled(Box)(({ theme }) => ({
    ...theme.typography.body2,
    padding: '5px',
    textAlign: 'center',
    borderRadius: '5px',
    color: '#0088b2',
    fontWeight: 500,
    fontSize: '13px',
    cursor: 'pointer'
}));

export const SuitUITabContainer = (props: { tabs: Tab[] }) => {
    const theme = useTheme();
    const { tabs } = props;
    const [active, setActive] = useState(tabs[0].label);

    return (
        <Stack
            direction='row'
            divider={<Divider orientation='vertical' flexItem />}
            spacing={1}
            sx={{
                background: 'white',
                marginBottom: 1,
                width: 'fit-content',
                borderRadius: '5px',
                p: 1
            }}
        >
            {tabs.map(
                ({ label, onHandleClick, view }) =>
                    view && (
                        <Tab
                            key={label}
                            onClick={() => {
                                setActive(label);
                                onHandleClick();
                            }}
                            sx={{
                                backgroundColor:
                                    active === label ? '#0088b21a' : 'white',
                                color:
                                    active === label
                                        ? '#0088b2'
                                        : theme.palette.text.secondary
                            }}
                        >
                            {label}
                        </Tab>
                    )
            )}
        </Stack>
    );
};
