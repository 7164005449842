import { Box, IconButton } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import Custombutton from '../../../components/custombutton/custombutton';
import { ServiceableInvoice } from './interfaces/Invoice';
import { Dispatch, SetStateAction } from 'react';
import { ArrowBackIos } from '@mui/icons-material';
import { getFormattedAmount } from '../../../shared/getFormattedAmount';
import { mutateConfig } from '../../../api/queryBase';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { getErrorMessage } from '../../../utils/helper';

export const LogisticsInvoicePaySummary = ({
    invoices,
    section,
    setSection
}: {
    invoices: ServiceableInvoice[];
    section: number;
    setSection: Dispatch<SetStateAction<number>>;
}) => {
    const navigate = useNavigate();
    const mutation = mutateConfig();
    const queryClient = useQueryClient();
    const { isLoading, mutate } = mutation({
        url: '/invoice/freight/payment',
        cacheKeys: 'invoices'
    });

    const onHandlePayment = () => {
        mutate(
            {
                invoices: invoices.map((invoice) => ({
                    corporateDocumentId: invoice['corporateDocumentId'],
                    notifyParties: (invoice['notifyParties'] || '').split(','),
                    totalAmount: invoice['totalToPay'],
                    transactionCurrencyCode: invoice['transactionCurrency'],
                    transactionSource: invoice['transactionSource']
                }))
            },
            {
                onSettled: (_, error) => {
                    if (error) {
                        toast.error(getErrorMessage(error));
                        return;
                    }

                    toast.success('Invoice payment successful');

                    queryClient.invalidateQueries('invoices');

                    navigate('/payments/logistics/list');
                }
            }
        );
    };

    return (
        <Box className='space-y-5 lg:w-[50%] w-full'>
            <Box className='flex flex-col lg:space-y-5 lg:p-5 lg:bg-white rounded-lg'>
                <Box className=' hidden lg:inline-block'>
                    Review Details of your transfer
                </Box>
                <Box className='lg:bg-[#F6F6F6] lg:py-5 lg:space-y-4 gap-4 lg:gap-0 mt-0'>
                    {invoices.map((invoice, index) => (
                        <Accordion
                            key={index}
                            className='lg:bg-[#F6F6F6] bg-unset'
                            sx={{
                                '.MuiAccordionSummary-content': {
                                    m: 0
                                },
                                '.MuiAccordionSummary-root': {
                                    minHeight: '10px',
                                    mx: 0,
                                    py: 1,
                                    px: 1,
                                    backgroundColor: 'transparent',
                                    '@media (min-width: 1024px)': {
                                        backgroundColor: 'white',
                                        mx: 3,
                                        py: 1,
                                        px: 2
                                    }
                                },
                                '.MuiAccordion-root': {
                                    backgroundColor: 'unset',

                                    '@media (min-width: 1024px)': {
                                        backgroundColor: 'white'
                                    }
                                },
                                '&:before': {
                                    display: 'none'
                                }
                            }}
                            disableGutters
                            elevation={0}
                        >
                            <AccordionSummary
                                sx={{
                                    '@media (min-width: 1024px)': {
                                        backgroundColor: 'white'
                                    },
                                    backgroundColor: 'transparent'
                                }}
                                expandIcon={<ExpandMoreIcon />}
                            >
                                <Typography>Invoice {index + 1}</Typography>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    '@media (min-width: 1024px)': { px: 5 },
                                    px: 2,
                                    borderTop: '1px solid #CFD4E2',
                                    borderBottom: '1px solid #CFD4E2'
                                }}
                            >
                                <Box className='space-y-3'>
                                    <Box className='flex justify-between'>
                                        <Box>Invoice No*</Box>
                                        <Box>{invoice['invoiceNumber']}</Box>
                                    </Box>
                                    <Box className='flex justify-between'>
                                        <Box>Carrier Name</Box>
                                        <Box className='uppercase text-right'>
                                            {
                                                invoice.beneficiary
                                                    .beneficiaryCompanyName
                                            }
                                        </Box>
                                    </Box>
                                    <Box className='flex justify-between'>
                                        <Box>Carrier Bank</Box>
                                        <Box className='uppercase'>
                                            {invoice.beneficiary.bankName}
                                        </Box>
                                    </Box>

                                    <Box className='flex justify-between'>
                                        <Box>Carrier Account No.</Box>

                                        <Box className='uppercase'>
                                            {invoice.beneficiary.accountNumber}
                                        </Box>
                                    </Box>
                                    <Box className='flex justify-between'>
                                        <Box>Carrier Swift Code</Box>
                                        <Box className='uppercase'>
                                            {invoice.beneficiary.swiftCode}
                                        </Box>
                                    </Box>

                                    <Box className='flex justify-between'>
                                        <Box>Amount</Box>
                                        <Box>{`${getFormattedAmount({
                                            amount: invoice['totalToPay'] || 0,
                                            currency: invoice[
                                                'transactionCurrency'
                                            ] as string
                                        })}`}</Box>
                                    </Box>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Box>

            <Box className='flex flex-col space-y-5 px-4 py-2 lg:bg-white text-[14px] lg:border-l-black lg:border-l-2 rounded-sm'>
                Please note that final confirmation of this transaction is
                subject to approval by your organization and exchange rates my
                change.
            </Box>

            <Box className='flex lg:space-x-2 lg:flex-row flex-col mb-4 items-center '>
                <div className='lg:block hidden'>
                    <IconButton
                        aria-label='previous'
                        style={{ borderRadius: 0 }}
                        onClick={() => setSection(section - 1)}
                    >
                        <ArrowBackIos />
                    </IconButton>
                </div>
                <Custombutton
                    isLoadingButton
                    loading={isLoading}
                    type='button'
                    variant='contained'
                    buttonText={
                        isLoading ? 'Please wait' : 'Confirm and continue'
                    }
                    styles={{
                        padding: '0.75rem 0.62rem',
                        width: '100%'
                    }}
                    onClickAction={onHandlePayment}
                    disabled={isLoading}
                />
                <div
                    className='lg:hidden my-4 font-medium'
                    onClick={() => setSection(section - 1)}
                >
                    Go back
                </div>
            </Box>
        </Box>
    );
};
