import React from 'react';
import {
    commaNumber,
    formatCurrency,
    getTransactionStatus
} from '../../../utils/helper';
import { Dropdown, MenuProps, Table } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { NoDataCross } from '../../../components/icons/NoDataCross';
import { TransactionTableProps } from './interfaces/PaymentTable';

const OpsTransactionsTable = ({
    isTransactionsLoading,
    transactionsList
}: TransactionTableProps) => {
    const navigate = useNavigate();

    const getMenu = (record: {
        transactionReference: string;
    }): MenuProps['items'] => [
        {
            key: '1',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        navigate(
                            `/payments/transactions/${record.transactionReference}`
                        );
                    }}
                >
                    <span className='ml-2'>View Details</span>
                </div>
            )
        }
    ];

    return (
        <Table
            loading={isTransactionsLoading}
            pagination={{ pageSize: 20 }}
            dataSource={transactionsList}
            locale={{
                emptyText: () => {
                    return (
                        <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                            <div className='flex justify-center mb-4'>
                                <NoDataCross />
                            </div>

                            <p className='text-center'>No data found</p>
                        </div>
                    );
                }
            }}
            columns={[
                {
                    title: 'Date',
                    dataIndex: 'createdDate',
                    key: 'createdDate',
                    render: (_, { createdDate }) => (
                        <span>{dayjs(createdDate).format('ll')}</span>
                    )
                },
                {
                    title: 'Corporate',
                    dataIndex: 'businessName',
                    key: 'businessName'
                },
                {
                    title: 'Tranx ID',
                    dataIndex: 'transactionReference',
                    key: 'transactionReference'
                },
                {
                    title: 'From',
                    dataIndex: 'amountFrom',
                    key: 'amountFrom',
                    render: (text, { fromCurrencyCode }) => (
                        <span>
                            {formatCurrency(parseFloat(text), fromCurrencyCode)}
                        </span>
                    )
                },
                {
                    title: 'To',
                    dataIndex: 'amountTo',
                    key: 'amountTo',
                    render: (text, { toCurrencyCode }) => (
                        <span>
                            {formatCurrency(parseFloat(text), toCurrencyCode)}
                        </span>
                    )
                },
                {
                    title: 'Rate',
                    dataIndex: 'rateValue',
                    key: 'rateValue',
                    render: (text) => (
                        <span>{commaNumber(parseFloat(text).toFixed(5))}</span>
                    )
                },
                {
                    title: 'Type',
                    dataIndex: 'transactionAction',
                    key: 'transactionAction'
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    render: (_, { status }) => (
                        <span>{getTransactionStatus(status)}</span>
                    )
                },
                {
                    title: '',
                    render: (_, record) => (
                        <Dropdown
                            menu={{
                                items: getMenu(record)
                            }}
                            trigger={['click']}
                        >
                            <img
                                src='/img/vectors/more.svg'
                                alt='more'
                                style={{
                                    cursor: 'pointer'
                                }}
                            />
                        </Dropdown>
                    )
                }
            ]}
        />
    );
};

export default OpsTransactionsTable;
