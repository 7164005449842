export const NoDataCross = () => {
    return (
        <svg
            width='88'
            height='108'
            viewBox='0 0 88 108'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M44 100.12C67.888 100.12 87.253 80.7546 87.253 56.8666C87.253 32.9787 67.888 13.6136 44 13.6136C20.112 13.6136 0.747009 32.9787 0.747009 56.8666C0.747009 80.7546 20.112 100.12 44 100.12Z'
                fill='url(#paint0_linear_1191_10259)'
            />
            <g filter='url(#filter0_d_1191_10259)'>
                <path
                    d='M68.7984 38.412H19.2016C17.6091 38.412 16.3181 39.7031 16.3181 41.2956V101.85C16.3181 103.442 17.6091 104.733 19.2016 104.733H68.7984C70.391 104.733 71.682 103.442 71.682 101.85V41.2956C71.682 39.7031 70.391 38.412 68.7984 38.412Z'
                    fill='white'
                />
            </g>
            <path
                d='M38.233 47.0627H23.2386C22.2831 47.0627 21.5085 47.8373 21.5085 48.7928C21.5085 49.7483 22.2831 50.5229 23.2386 50.5229H38.233C39.1885 50.5229 39.9631 49.7483 39.9631 48.7928C39.9631 47.8373 39.1885 47.0627 38.233 47.0627Z'
                fill='#0088B2'
            />
            <path
                d='M48.6137 54.5599H23.2386C22.2831 54.5599 21.5085 55.3345 21.5085 56.29C21.5085 57.2455 22.2831 58.0201 23.2386 58.0201H48.6137C49.5692 58.0201 50.3438 57.2455 50.3438 56.29C50.3438 55.3345 49.5692 54.5599 48.6137 54.5599Z'
                fill='#DFEAFB'
            />
            <path
                d='M38.233 62.6337H23.2386C22.2831 62.6337 21.5085 63.4083 21.5085 64.3638C21.5085 65.3193 22.2831 66.0939 23.2386 66.0939H38.233C39.1885 66.0939 39.9631 65.3193 39.9631 64.3638C39.9631 63.4083 39.1885 62.6337 38.233 62.6337Z'
                fill='#0088B2'
            />
            <path
                d='M48.6137 70.1309H23.2386C22.2831 70.1309 21.5085 70.9055 21.5085 71.861C21.5085 72.8165 22.2831 73.5911 23.2386 73.5911H48.6137C49.5692 73.5911 50.3438 72.8165 50.3438 71.861C50.3438 70.9055 49.5692 70.1309 48.6137 70.1309Z'
                fill='#DFEAFB'
            />
            <path
                d='M38.233 78.2048H23.2386C22.2831 78.2048 21.5085 78.9794 21.5085 79.9349C21.5085 80.8904 22.2831 81.665 23.2386 81.665H38.233C39.1885 81.665 39.9631 80.8904 39.9631 79.9349C39.9631 78.9794 39.1885 78.2048 38.233 78.2048Z'
                fill='#0088B2'
            />
            <path
                d='M48.6137 85.702H23.2386C22.2831 85.702 21.5085 86.4766 21.5085 87.4321C21.5085 88.3876 22.2831 89.1622 23.2386 89.1622H48.6137C49.5692 89.1622 50.3438 88.3876 50.3438 87.4321C50.3438 86.4766 49.5692 85.702 48.6137 85.702Z'
                fill='#DFEAFB'
            />
            <g filter='url(#filter1_d_1191_10259)'>
                <path
                    d='M68.7984 9H19.2016C17.6091 9 16.3181 10.291 16.3181 11.8835V29.1847C16.3181 30.7773 17.6091 32.0683 19.2016 32.0683H68.7984C70.391 32.0683 71.682 30.7773 71.682 29.1847V11.8835C71.682 10.291 70.391 9 68.7984 9Z'
                    fill='#0088B2'
                />
            </g>
            <path
                d='M38.233 15.3438H23.2386C22.2831 15.3438 21.5085 16.1184 21.5085 17.0739C21.5085 18.0295 22.2831 18.8041 23.2386 18.8041H38.233C39.1885 18.8041 39.9631 18.0295 39.9631 17.0739C39.9631 16.1184 39.1885 15.3438 38.233 15.3438Z'
                fill='#0088B2'
            />
            <path
                d='M48.6137 22.841H23.2386C22.2831 22.841 21.5085 23.6156 21.5085 24.5712C21.5085 25.5267 22.2831 26.3013 23.2386 26.3013H48.6137C49.5692 26.3013 50.3438 25.5267 50.3438 24.5712C50.3438 23.6156 49.5692 22.841 48.6137 22.841Z'
                fill='white'
            />
            <defs>
                <filter
                    id='filter0_d_1191_10259'
                    x='10.3181'
                    y='29.412'
                    width='67.3638'
                    height='78.3213'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dy='-3' />
                    <feGaussianBlur stdDeviation='3' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='BackgroundImageFix'
                        result='effect1_dropShadow_1191_10259'
                    />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='effect1_dropShadow_1191_10259'
                        result='shape'
                    />
                </filter>
                <filter
                    id='filter1_d_1191_10259'
                    x='10.3181'
                    y='0'
                    width='67.3638'
                    height='35.0683'
                    filterUnits='userSpaceOnUse'
                    colorInterpolationFilters='sRGB'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        type='matrix'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                        result='hardAlpha'
                    />
                    <feOffset dy='-3' />
                    <feGaussianBlur stdDeviation='3' />
                    <feColorMatrix
                        type='matrix'
                        values='0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0'
                    />
                    <feBlend
                        mode='normal'
                        in2='BackgroundImageFix'
                        result='effect1_dropShadow_1191_10259'
                    />
                    <feBlend
                        mode='normal'
                        in='SourceGraphic'
                        in2='effect1_dropShadow_1191_10259'
                        result='shape'
                    />
                </filter>
                <linearGradient
                    id='paint0_linear_1191_10259'
                    x1='44'
                    y1='13.6136'
                    x2='44'
                    y2='100.12'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop stopColor='#0088B2' stopOpacity='0.58' />
                    <stop offset='1' stopColor='#0088B2' stopOpacity='0' />
                </linearGradient>
            </defs>
        </svg>
    );
};
