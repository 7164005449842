import React, { Dispatch, SetStateAction, useState } from 'react';
import { RegisterValues, ResendConfirmationPayload } from './SignUp';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import {
    Alert,
    Autocomplete,
    CircularProgress,
    FormControlLabel,
    InputAdornment,
    MenuItem
} from '@mui/material';
import useIndustries from '../../customhooks/industries/useIndustries';
import useBusinessTypes from '../../customhooks/businesstypes/useBusinesstypes';
import CountriesDropdown from '../../components/CountriesDropdown';
import { Country } from '../../interfaces/Country';
import Passwordinput from '../../components/passwordinput/passwordinput';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
    hasDigit,
    hasLowerCase,
    hasSymbol,
    hasUpperCase,
    openNotification,
    validatePassword,
    validatePhoneNumber
} from '../../utils/helper';
import { mutateConfig } from '../../api/queryBase';
import { AxiosError } from 'axios';
import CustomTextField from '../../components/CustomTextInput';
import { SuitUIButton } from '../../components/SuitUIButton';
import { Checkbox } from 'antd';

const SignUpForm = ({
    setShowConfirmation,
    setResentConfirmationPayload
}: {
    setShowConfirmation: Dispatch<SetStateAction<boolean>>;
    setResentConfirmationPayload: Dispatch<
        SetStateAction<ResendConfirmationPayload>
    >;
}) => {
    const initialValues: RegisterValues = {
        emailAddress: undefined,
        phoneNumber: undefined,
        cipherCode: undefined,
        rcNumber: '',
        businessName: '',
        countryId: '',
        firstName: '',
        lastName: '',
        corporateIndustryId: '',
        businessTypes: []
    };

    const [chosenCountry, setChosenCountry] = useState<Country>();
    const [genericErrorMessage, setGenericErrorMessage] = useState<string>('');
    const { industries, isIndustriesLoading } = useIndustries();
    const { businessTypes, isBusinessTypesLoading } = useBusinessTypes();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    const validationSchema = (chosenCountry: Country) =>
        Yup.object().shape({
            businessTypes: Yup.array().required('Business Type is required'),
            lastName: Yup.string().required('Last Name is required'),
            firstName: Yup.string().required('First Name is required'),
            businessName: Yup.string().required('Business Name is required'),
            emailAddress: Yup.string()
                .email('Invalid Email address')
                .required('Email is required'),
            corporateIndustryId: Yup.string().required('Industry  is required'),
            rcNumber: Yup.string().when([], {
                is: () => chosenCountry.countryCode === 'NGA',
                then: (schema) =>
                    schema
                        .matches(
                            /^(BN|RC|IT|LP|LLP)?\d{6}$/,
                            `${
                                chosenCountry?.businessIdentification ||
                                'RC Number'
                            } must be a valid RC number`
                        )
                        .required(
                            `${
                                chosenCountry?.businessIdentification ||
                                'RC Number'
                            } is required`
                        ),
                otherwise: (schema) =>
                    schema.required(
                        `${chosenCountry?.businessIdentification} is required`
                    )
            }),
            phoneNumber: Yup.string()
                .required('Phone Number is required')
                .test('phone-number', 'Invalid phone number', (value) =>
                    validatePhoneNumber(`${chosenCountry?.phoneCode}${value}`)
                ),
            cipherCode: Yup.string()
                .required('Invalid password')
                .test('password', 'Invalid password', (value) =>
                    validatePassword(value)
                )
        });

    const mutation = mutateConfig();
    const { mutate } = mutation({
        url: `/signup`
    });

    interface Payload {
        userId: number;
        corporateId: number;
        email: string;
    }

    const handleSubmit = async (
        values: RegisterValues,
        { setErrors, resetForm }: FormikHelpers<RegisterValues>
    ) => {
        setIsSubmitting(true);
        mutate(values, {
            onSettled: (_, error: AxiosError | undefined | any) => {
                setIsSubmitting(false);
                if (error) {
                    if (error.response?.data) {
                        const errors = error.response.data.error;
                        if (typeof errors === 'string') {
                            setGenericErrorMessage(errors);
                        } else {
                            setErrors(errors);
                        }
                    } else {
                        openNotification('error', 'Something went wrong');
                    }
                    return;
                }

                resetForm();
            },
            onSuccess: (data: { payload: unknown }) => {
                const payload = data.payload as Payload;

                setIsSubmitting(false);
                openNotification('success', 'Sign Up successful');
                setShowConfirmation(true);
                setResentConfirmationPayload({
                    userId: payload.userId,
                    corporateId: payload.corporateId,
                    email: values.emailAddress ?? ''
                });
            }
        });
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema(
                chosenCountry ?? ({} as Country)
            )}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={handleSubmit}
        >
            {({ errors, isValid, values, setFieldValue, touched }) => (
                <Form autoComplete='off' noValidate>
                    <div className='flex flex-col gap-4 lg:gap-[24px] bg-appcolorwhite py-[2.7rem] lg:py-[3.5rem] lg:px-[2.6rem] px-[1.6rem] mb-[1.75rem] rounded-[3px]'>
                        {genericErrorMessage &&
                            typeof genericErrorMessage === 'string' && (
                                <div className='mb-8'>
                                    <Alert severity='error'>
                                        {genericErrorMessage}
                                    </Alert>
                                </div>
                            )}
                        <div className='flex gap-4 flex-col sm:flex-row'>
                            <div className='w-full'>
                                <Field
                                    as={CustomTextField}
                                    error={
                                        touched?.firstName && errors?.firstName
                                    }
                                    helperText={
                                        touched?.firstName && errors?.firstName
                                    }
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='siginup-firstname'
                                    label='First Name'
                                    name='firstName'
                                    valid
                                />
                            </div>
                            <div className='w-full'>
                                <Field
                                    as={CustomTextField}
                                    error={
                                        touched?.lastName && errors?.lastName
                                    }
                                    helperText={
                                        touched?.lastName && errors?.lastName
                                    }
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='siginup-lastName'
                                    label='Last Name'
                                    name='lastName'
                                />
                            </div>
                        </div>

                        <Field
                            as={CustomTextField}
                            error={
                                touched?.emailAddress && errors?.emailAddress
                            }
                            helperText={
                                touched?.emailAddress && errors?.emailAddress
                            }
                            margin='normal'
                            required
                            fullWidth
                            id='siginup-emailAddress'
                            label='Business Email'
                            name='emailAddress'
                            type='email'
                        />

                        <Field
                            as={CustomTextField}
                            error={
                                touched?.businessName && errors?.businessName
                            }
                            helperText={
                                touched?.businessName && errors?.businessName
                            }
                            margin='normal'
                            required
                            fullWidth
                            id='siginup-businessName'
                            label='Business Name'
                            name='businessName'
                        />
                        <Autocomplete
                            disableClearable
                            id='signup-corporateIndustryId'
                            options={industries}
                            renderInput={(params) => (
                                <CustomTextField
                                    params={params}
                                    name='industry'
                                    label='Industry'
                                    required={true}
                                    error={
                                        touched?.corporateIndustryId &&
                                        !!errors?.corporateIndustryId
                                    }
                                    helperText={
                                        touched?.corporateIndustryId
                                            ? errors?.corporateIndustryId
                                            : ''
                                    }
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: isIndustriesLoading && (
                                            <React.Fragment>
                                                <CircularProgress size={20} />
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            )}
                            noOptionsText={'No data available'}
                            renderOption={(props, option) => (
                                <MenuItem
                                    {...props}
                                    value={option.id}
                                    key={`countries-${option.id}`}
                                >
                                    <div className='flex items-center'>
                                        <span className='ml-2'>
                                            {option.name}
                                        </span>
                                    </div>
                                </MenuItem>
                            )}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, value) => {
                                if (value !== null) {
                                    setFieldValue(
                                        'corporateIndustryId',
                                        value.id.toString()
                                    );
                                }
                            }}
                        />

                        <Autocomplete
                            multiple
                            disableClearable
                            id='signup-businessTypes'
                            options={businessTypes}
                            renderInput={(params) => (
                                <CustomTextField
                                    name='type'
                                    params={params}
                                    label='Business Type'
                                    required={true}
                                    error={
                                        touched?.businessTypes &&
                                        !!errors?.businessTypes
                                    }
                                    helperText={
                                        touched?.businessTypes
                                            ? errors?.businessTypes
                                            : ''
                                    }
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment:
                                            isBusinessTypesLoading && (
                                                <React.Fragment>
                                                    <CircularProgress
                                                        size={20}
                                                    />
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </React.Fragment>
                                            )
                                    }}
                                />
                            )}
                            noOptionsText={'No data available'}
                            renderOption={(props, option) => (
                                <MenuItem
                                    {...props}
                                    value={option.id}
                                    key={`countries-${option.id}`}
                                >
                                    <div className='flex items-center'>
                                        <span className='ml-2'>
                                            {option.name}
                                        </span>
                                    </div>
                                </MenuItem>
                            )}
                            getOptionLabel={(option) => option.name}
                            onChange={(e, value) => {
                                if (value !== null) {
                                    const newValue = value.map((item) => {
                                        return item.id;
                                    });
                                    setFieldValue('businessTypes', newValue);
                                }
                            }}
                        />

                        <div className='flex gap-4 flex-col sm:flex-row'>
                            <div className='w-full'>
                                <CountriesDropdown
                                    initialValue={values.countryId}
                                    error={
                                        touched?.countryId &&
                                        !!errors?.countryId
                                    }
                                    errorMessage={
                                        touched?.countryId
                                            ? errors?.countryId
                                            : undefined
                                    }
                                    onHandleChange={(value) => {
                                        if (value !== null) {
                                            setFieldValue(
                                                'countryId',
                                                value.id
                                            );
                                            setChosenCountry(value);
                                        }
                                    }}
                                    {...{
                                        errors,
                                        values
                                    }}
                                />
                            </div>
                            <div className='w-full'>
                                <Field
                                    as={CustomTextField}
                                    error={
                                        touched?.phoneNumber &&
                                        errors?.phoneNumber
                                    }
                                    helperText={
                                        touched?.phoneNumber &&
                                        errors?.phoneNumber
                                    }
                                    InputProps={
                                        chosenCountry
                                            ? {
                                                  startAdornment: (
                                                      <InputAdornment position='start'>
                                                          <span className='text-appcolorprimary pl-2'>
                                                              {
                                                                  chosenCountry?.phoneCode
                                                              }
                                                          </span>
                                                      </InputAdornment>
                                                  )
                                              }
                                            : undefined
                                    }
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='siginup-phoneNumber'
                                    label='Phone Number'
                                    name='phoneNumber'
                                />
                            </div>
                        </div>

                        {chosenCountry && (
                            <Field
                                as={CustomTextField}
                                error={touched?.rcNumber && errors?.rcNumber}
                                helperText={
                                    touched?.rcNumber && errors?.rcNumber
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='siginup-rcNumber'
                                name='rcNumber'
                                label={`${chosenCountry?.businessIdentification}`}
                            />
                        )}

                        <Passwordinput
                            id='signup-password'
                            label='Password'
                            name='cipherCode'
                            updateField={setFieldValue}
                            error={touched?.cipherCode && !!errors?.cipherCode}
                            helperText={
                                touched?.cipherCode ? errors?.cipherCode : ''
                            }
                        />

                        <div className='flex flex-wrap gap-1 px-4 -mt-4'>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        className='text-xs'
                                        checked={Boolean(
                                            values?.cipherCode &&
                                                values?.cipherCode?.length > 7
                                        )}
                                    />
                                }
                                label={
                                    <p className='text-xs ml-2'>
                                        Minimum 8 characters
                                    </p>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(
                                            values?.cipherCode &&
                                                hasSymbol(values?.cipherCode)
                                        )}
                                    />
                                }
                                label={
                                    <p className='text-xs ml-2'>
                                        At least 1 symbol
                                    </p>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(
                                            values?.cipherCode &&
                                                hasDigit(values?.cipherCode)
                                        )}
                                    />
                                }
                                label={
                                    <p className='text-xs ml-2'>
                                        At least 1 number
                                    </p>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(
                                            values?.cipherCode &&
                                                hasLowerCase(values?.cipherCode)
                                        )}
                                    />
                                }
                                label={
                                    <p className='text-xs ml-2'>
                                        At least 1 lower case letter
                                    </p>
                                }
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Boolean(
                                            values?.cipherCode &&
                                                hasUpperCase(values?.cipherCode)
                                        )}
                                    />
                                }
                                label={
                                    <p className='text-xs ml-2'>
                                        At least 1 upper case letter
                                    </p>
                                }
                            />
                        </div>

                        <div className='mt-4'>
                            <SuitUIButton
                                isLoading={isSubmitting}
                                type='submit'
                                fullWidth
                                disabled={
                                    Object.values(values).some(
                                        (item) => item === undefined
                                    ) ||
                                    !isValid ||
                                    isSubmitting
                                }
                                text='Create a Free Account'
                            />
                        </div>

                        <p className='mt-4 text-sm text-appcolorfaint leading[18px]'>
                            Already have an account?{' '}
                            <span
                                className='underline cursor-pointer text-appcolorprimary'
                                onClick={() => {
                                    navigate('/login');
                                }}
                            >
                                Log In
                            </span>
                        </p>

                        <p className='mt-4 text-sm text-appcolorfaint leading[18px]'>
                            By Signing up you agree to our{' '}
                            <span
                                className='underline cursor-pointer text-appcolorprimary'
                                onClick={() => {
                                    navigate('/signup');
                                }}
                            >
                                Privacy Policy & Terms
                            </span>
                        </p>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SignUpForm;
