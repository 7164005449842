import Alert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCACDocuments } from '../../../../api/base/base';
import { RootState } from '../../../../store/store';
import { openNotification } from '../../../../utils/helper';
import Custombutton from '../../../custombutton/custombutton';
import Documentupload from '../../../documentupload/documentupload';
import Skeleton from '@mui/material/Skeleton';

export interface onboardingdocsProps {
    handleLogout: () => void;
}

const Onboardingdocs = ({ handleLogout }: onboardingdocsProps) => {
    const [uploadingDocs, setUploadingDocs] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();

    const state = useSelector((state: RootState) => ({
        user: state.user.user
    }));

    const { user } = state;

    const fetchDocuments = async () => {
        if (!user) {
            return;
        }

        setLoading(true);
        try {
            const res = await getCACDocuments(user.corporate_id);
            const { data } = res.data;
            setUploadingDocs(data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error.response) {
                openNotification('error', 'Something went wrong');
            }
        }
    };

    const afterUploadUpdate = (itemId: number, documentUrl: string) => {
        const newUploadingDocs = [...uploadingDocs];
        const foundIndex = newUploadingDocs.findIndex(
            (item: any) => item.itemId === itemId
        );
        newUploadingDocs[foundIndex].uploaded = true;
        newUploadingDocs[foundIndex].documentUrl = documentUrl;
        setUploadingDocs(newUploadingDocs);
    };

    const validateUploads = () => {
        const checkFiles = uploadingDocs.every((item) => item.uploaded);
        return !checkFiles;
    };

    useEffect(() => {
        fetchDocuments();
    }, []);

    return (
        <div className='w-[30rem]'>
            {loading ? (
                <div className='my-[-6rem]'>
                    <Skeleton variant='text' sx={{ fontSize: '20rem' }} />
                </div>
            ) : (
                <>
                    <Alert
                        severity='info'
                        sx={{
                            background: 'rgba(0, 136, 178, 0.06)',
                            borderRadius: '3px',
                            border: '1px solid #0088B2',
                            color: theme.palette.primary.main,
                            marginBottom: '1rem'
                        }}
                    >
                        {validateUploads() ? (
                            <>
                                We were unable to automatically verify your
                                business registration. Please upload the
                                following registration documents for us to grant
                                you system access.
                            </>
                        ) : (
                            <>
                                Your business registration documents have been
                                submitted and is currently being reviewed by our
                                team. You will be able to continue your setup as
                                soon as the verification is complete.
                            </>
                        )}
                    </Alert>

                    <div>
                        {uploadingDocs.map((item, index) => (
                            <Documentupload
                                useAuthorization
                                key={`upload-doc-${item.title}-${index}`}
                                title={item.documentName}
                                runAfterUpload={true}
                                documentItemId={item.itemId}
                                uploaded={item.uploaded}
                                documentUrl={item.documentUrl}
                                status={item.status}
                                afterUploadUpdate={afterUploadUpdate}
                                rejectedMessage={item.rejectedMessage}
                            />
                        ))}
                    </div>

                    <div className='flex mt-4'>
                        <div className='grow'>
                            <Custombutton
                                variant='contained'
                                styles={{
                                    padding: '0.5rem 0.92rem 0.5rem 0.93rem',
                                    color: '#CB444A',
                                    border: '1px solid #CB444A',
                                    backgroundColor: '#FFFFFF',
                                    marginRight: '1rem',
                                    width: '100%',
                                    '&:hover': {
                                        padding:
                                            '0.5rem 0.92rem 0.5rem 0.93rem',
                                        color: '#CB444A',
                                        border: '1px solid #CB444A',
                                        backgroundColor: '#FFFFFF'
                                    }
                                }}
                                disabled={false}
                                buttonText='Logout'
                                // size="small"
                                onClickAction={() => {
                                    handleLogout();
                                }}
                                fullWidth
                            />
                        </div>

                        {/* <div className='grow'>
                <Custombutton
                  variant="contained"
                  styles={{
                    padding: "0.5rem 0.92rem 0.5rem 0.93rem",
                    width: '100%',
                    marginLeft: '1rem',
                  }}
                  disabled={validateUploads() || loading}
                  buttonText="Submit"
                  // size="small"
                  onClickAction={() => {
                    openNotification('success', 'Your documents has been successfully updated')
                    handleLogout()
                  }}
                />
              </div> */}
                    </div>
                </>
            )}
        </div>
    );
};

export default Onboardingdocs;
