import { RouteItem } from "../../../interfaces/RouteItem"
import { User } from "../../../interfaces/User"
import { isEnabled } from "../../EnablingFeature"

export const canShow = (user: User | null, item: RouteItem): boolean => {
  return item?.level.includes(user?.user_level || 0) && isEnabled(item?.feature) 
}


export const hasPermission = ({userPermissions,module,permissionList,feature}:{
  userPermissions: Record<string, string>,
  module: string,
  permissionList: string[],
  feature?:string}
): boolean => {
  const modulePermission = userPermissions[module];

  if(feature && feature === "Accessible"){return true}
  if (modulePermission === "*") {
    return true; 
  } else if (modulePermission === "none") {
    return false; 
  } else {
    return permissionList?.includes(modulePermission); 
  }
};