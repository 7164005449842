export interface FilePickerParams {
    onFilesSelectedHandler: (entry: FileSelectorParams) => void;
    multiple?: boolean;
}

export interface FileSelectorParams {
    files: File[];
    input?: HTMLInputElement;
}

export const handleFilePicker = (params: FilePickerParams) => {
    const { onFilesSelectedHandler, multiple } = params;
    const input = document.createElement('input');

    input.type = 'file';
    input.style.display = 'none';
    input.multiple = Boolean(multiple);

    input.addEventListener('change', (event: Event) => {
        const { files } = event.target as HTMLInputElement;
        const collection = [];

        for (const file of files || []) {
            collection.push(file);
        }

        onFilesSelectedHandler({ files: collection, input });
    });

    document.body.appendChild(input);

    input.click();
};
