import React, { useState, useEffect } from 'react';
import Outletouterpage from '../../../../components/dashboard/outletouterpage/outletouterpage';
import { Mapping } from '../mapping/mapping';
import Shipmentitem from './shipmentitem/shipmentitem';
import { openNotification } from '../../../../utils/helper';
import { getMoreTrackingReference } from '../../../../api/booking/booking';
import Skeleton from '@mui/material/Skeleton';
import { useParams } from 'react-router-dom';
import EmptyShipment from './EmptyShipment';
import ContainerShipmentItem from './shipmentitem/ContainerShipmentItem';

const TrackshipmentInternal = () => {
    const { reference, containerNumber } = useParams();

    const [containerDetails, setContainerDetails] = useState<any>();

    const [data, setData] = useState<any[]>([]);
    const [loadingData, setLoadingData] = useState(false);

    const fetchTrackings = async () => {
        setLoadingData(true);
        try {
            const res = await getMoreTrackingReference(reference || '');

            const { data } = res.data;

            const checkings: any[] = [];

            checkings.push({
                ...data
            });

            setData(checkings);
            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);

            if (error?.response) {
                console.log('error', error?.response);
            } else {
                openNotification('error', `Tracking, Something went wrong`);
            }
        }
    };

    useEffect(() => {
        if (containerNumber && data) {
            setContainerDetails(
                data[0]?.details?.containers?.filter(
                    (cont: any) => cont.number === containerNumber
                )[0]
            );
        }
    }, [containerNumber, reference, data]);

    useEffect(() => {
        fetchTrackings();
    }, []);

    return (
        <div className='relative'>
            <div className='relative lg:block flex flex-col-reverse'>
                <div className='lg:w-[50%]'>
                    <Outletouterpage
                        breadcrumbs={[
                            {
                                title: 'Home',
                                link: '/dashboard'
                            },
                            {
                                title: 'Bookings',
                                link: '/bookings'
                            },
                            {
                                title: 'Track Shipment',
                                link: '/bookings/track-shipments/123'
                            }
                        ]}
                    >
                        <div className='lg:mt-0 mt-[4rem]  '>
                            <div className='flex justify-between lg:mb-8 lg:px-0 px-4 pt-9 lg:pt-0'>
                                <h1 className='lg:text-[32px] text-[18px] leading-[23px] text-appcolorblacktwo border-b pb-3 w-full lg:border-b-0 lg:pb-0'>
                                    Track Shipments
                                </h1>
                            </div>

                            <div className='lg:mt-8 mt-4 px-4 lg:px-0'>
                                {loadingData ? (
                                    <div className='mt-[-3rem]'>
                                        <Skeleton
                                            variant='text'
                                            sx={{ fontSize: '10rem' }}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        {containerDetails &&
                                        Object.keys(containerDetails).length !==
                                            0 ? (
                                            <ContainerShipmentItem
                                                {...{
                                                    containerDetails,
                                                    containerNumber
                                                }}
                                            />
                                        ) : data?.length === 0 ? (
                                            <EmptyShipment />
                                        ) : (
                                            data.map((item: any) => (
                                                <Shipmentitem
                                                    key={`tracking-${item.id}`}
                                                    item={item}
                                                    isExtra
                                                />
                                            ))
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </Outletouterpage>
                </div>
                <div className='lg:w-[40%] lg:h-screen lg:fixed right-0 lg:top-[0rem] relative h-60'>
                    <div className=''>
                        <Mapping
                            isDrawer
                            paths={data[0]?.pins || []}
                            currentLat={data[0]?.currentLatitude}
                            currentLong={data[0]?.currentLongitude}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackshipmentInternal;
