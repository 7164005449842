import { Table } from 'antd'
import moment from 'moment'
import React from 'react'
interface IProps {
  data?: any
}
const DetailsContainerCargo = ({ data }: IProps) => {
  const contColumns = [
    {
      title: 'Container Type',
      dataIndex: 'containerType',
      key: 'containerType',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      key: 'comments',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Company Name',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Requested Pickup',
      dataIndex: 'pickUpDate',
      key: 'pickUpDate',
      render: (text: any) => (
        <span>{text ? moment(text || Date.now()).format('ll') : '--'}</span>
      ),
    },
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      key: 'contactName',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Contact Number',
      dataIndex: 'contactNumber',
      key: 'contactNumber',
      render: (text: any) => <span>{text || '--'}</span>,
    },
  ]

  const transformContainers = (array: any): any => {
    const newArr: any = []
    array.forEach((element: any) => {
      const val = {
        containerType: element.containerType || '--',
        comments: element.comments || '--',
        companyName: element?.haulage?.companyName || '--',
        address: element?.haulage?.address || '--',
        pickUpDate: element?.haulage?.pickUpDate || '--',
        contactName: element?.haulage?.contactName || '--',
        contactNumber: element?.haulage?.contactPhone || '--',
      }
      newArr.push(val)
    })
    return newArr
  }

  const cargoColumns = [
    {
      title: 'Commodity',
      dataIndex: 'commodityName',
      key: 'commodityName',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Commodity Type',
      dataIndex: 'commodityType',
      key: 'commodityType',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Commodity Quality',
      dataIndex: 'commodityQuality',
      key: 'commodityQuality',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'HSCode',
      dataIndex: 'hsCode',
      key: 'hsCode',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Cargo Description',
      dataIndex: 'description',
      key: 'description',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Cargo Weight',
      dataIndex: 'cargoWeight',
      key: 'cargoWeight',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Net Weight',
      dataIndex: 'netWeight',
      key: 'netWeight',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Weight Unit',
      dataIndex: 'weightUnit',
      key: 'weightUnit',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Gross Volume',
      dataIndex: 'grossVolume',
      key: 'grossVolume',
      render: (text: any) => <span>{text || '--'}</span>,
    },
    {
      title: 'Volume Unit',
      dataIndex: 'volumeUnit',
      key: 'volumeUnit',
      render: (text: any) => <span>{text || '--'}</span>,
    },
  ]

  return (
    <>
      <div className="details-content">
        <h3>Container</h3>
        <Table
          dataSource={transformContainers(data?.creationDetails?.container)}
          columns={contColumns}
          pagination={false}
        />
      </div>

      <div className="details-content">
        <h3>Cargo</h3>
        <Table
          dataSource={data?.creationDetails?.cargo}
          columns={cargoColumns}
          pagination={false}
        />
      </div>
    </>
  )
}

export default DetailsContainerCargo
