import { Grid, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import Signature from '../../../../signature/signature';
import { SignatureDetailsProps } from '../../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/parkinglistTypes';

const SignatureDetails = ({
    errors,
    payload,
    setSignatureUrlBase64,
    updateField,
    signatureUrlBase64
}: SignatureDetailsProps) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={6}>
                <div className='w-full'>
                    <Signature
                        name='signatureUrl'
                        updateField={updateField}
                        setSignatureUrlBase64={setSignatureUrlBase64}
                        signatureUrlBase64={signatureUrlBase64}
                    />
                </div>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] my-[10px] pt-[0rem] pb-0 h-full'>
                    <Grid
                        container
                        spacing={0}
                        sx={{
                            marginTop: '0px'
                        }}
                    >
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                error={errors?.placeOfIssue.length > 0}
                                helperText={
                                    errors?.placeOfIssue.length > 0
                                        ? errors?.placeOfIssue
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='commercialinvoice-placeOfIssue'
                                label='Place of Issue'
                                name='placeOfIssue'
                                value={payload?.placeOfIssue}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField('placeOfIssue', e.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                error={errors?.signatoryCompany.length > 0}
                                helperText={
                                    errors?.signatoryCompany.length > 0
                                        ? errors?.signatoryCompany
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='commercialinvoice-signatoryCompany'
                                label='Signatory Company'
                                name='signatoryCompany'
                                value={payload?.signatoryCompany}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'signatoryCompany',
                                        e.target.value
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TextField
                                error={
                                    errors?.nameOfAuthorisedSignatory.length > 0
                                }
                                helperText={
                                    errors?.nameOfAuthorisedSignatory.length > 0
                                        ? errors?.nameOfAuthorisedSignatory
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='commercialinvoice-nameOfAuthorisedSignatory'
                                label='Name of Authorized Signature'
                                name='nameOfAuthorisedSignatory'
                                value={payload?.nameOfAuthorisedSignatory}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'nameOfAuthorisedSignatory',
                                        e.target.value
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export default SignatureDetails;
