import { useGetQuery } from './useGetQuery';

interface GetFXRateParams {
    currencyFrom: string;
    currencyTo: string;
    enabled?: boolean;
}

export const useFXRate = (params: GetFXRateParams) => {
    const { currencyFrom, currencyTo, enabled = true } = params;

    const { isFetching, entries } = useGetQuery<{
        rate: number;
        vfx_token: string;
    }>({
        url: '/rate',
        cacheKey: `rate-${currencyTo}--${currencyFrom}`,
        params: { ...params },
        enabled
    });

    if (currencyFrom === currencyTo) {
        return { isFetching: false, rate: 1, vfxToken: '' };
    }

    const { rate = 0, vfx_token: vfxToken } = entries;

    return { isFetching, rate: Number(rate), vfxToken };
};
