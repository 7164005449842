export const BookingEntryDivider = (props: {
    index: number;
    isRemoveShown?: boolean;
    onHandleRemoveItem: () => void;
}) => {
    const { index, onHandleRemoveItem, isRemoveShown } = props;

    return (
        <div className='flex items-center my-5'>
            <div className='rounded-full bg-appcolorprimaryfainttwo px-[0.9rem] py-[0.5rem] text-appcolorprimary text-[12px]'>
                {index + 1}
            </div>
            <hr className='grow border-appcolorprimary' />
            {isRemoveShown && (
                <div
                    className='rounded-full bg-appcolorfaintred px-[0.9rem] py-[0.5rem] text-appcolorred text-[12px] cursor-pointer'
                    onClick={onHandleRemoveItem}
                >
                    X
                </div>
            )}
        </div>
    );
};
