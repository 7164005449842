import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

interface CustomStepperProps {
    steps: { label: string }[];
    activeStep: number;
    hideLabel?: boolean;
}
const CustomStepper = ({
    steps,
    activeStep,
    hideLabel
}: CustomStepperProps) => {
    return (
        <>
            <div className='hidden lg:block'>
                <Stepper activeStep={activeStep} orientation='horizontal'>
                    {steps.map((step) => (
                        <Step key={step.label}>
                            <StepLabel>{step.label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </div>

            <div className='lg:hidden'>
                <div className='flex items-center gap-[8px]'>
                    {steps?.map((_, index) =>
                        index <= activeStep ? (
                            <div className='w-[20px] h-[20px] rounded-[50%] flex justify-center items-center bg-appcolorprimary'>
                                <p className='text-sm font-medium text-white'>
                                    {index + 1}
                                </p>
                            </div>
                        ) : (
                            <div className='w-[8px] h-[8px] rounded-[50%] bg-lavender' />
                        )
                    )}
                </div>
                {!hideLabel && (
                    <div className=' border border-0 border-b  border-appcolorfainttwo pb-[16px] pt-[12px]'>
                        <p className='text-ebony text-base leading-6'>
                            {steps[activeStep].label}
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};

export default CustomStepper;
