import { deleteMutate } from '../../../api/queryBase';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { getErrorMessage, openNotification } from '../../../utils/helper';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';
import CustomModal from '../../../components/CustomModal';
import { SuitUIButton } from '../../../components/SuitUIButton';
import { Beneficiary } from './interfaces/Beneficiary';
import { DeleteIcon } from '../../../components/icons/DeleteIcon';

export const ConfirmDeleteBeneficiary = ({
    item,
    open,
    onClose
}: {
    item: Beneficiary | null;
    open: boolean;
    onClose: () => void;
}) => {
    const { permissions } = useUserProfile();

    const { user } = useSelector((state: RootState) => state.user);

    const { mutate, isLoading: isDeleting } = deleteMutate({
        url: `corporate/${user?.corporate_id}/beneficiary`
    });

    const deleteBeneficiary = () => {
        if (!item?.id) return;
        if (
            permissions &&
            hasPermission({
                userPermissions: permissions,
                module: 'payments',
                permissionList: ['write', '*']
            })
        ) {
            mutate(item.id, {
                onSettled: (_, error) => {
                    if (error) {
                        openNotification(
                            'error',
                            getErrorMessage(error) ||
                                'Error deleting beneficiary'
                        );
                        return;
                    }

                    openNotification(
                        'info',
                        'You have successfully deleted a beneficiary'
                    );
                    onClose();
                }
            });
        } else {
            openNotification(
                'error',
                'You do not have access to perform this action'
            );
        }
    };
    return (
        <CustomModal maxWidth='lg' open={open} onClose={onClose} hideCloseIcon>
            <div className='w-full  p-[40px]'>
                <div className='w-[48px] h-[48px] rounded-[50%] flex justify-center items-center bg-whiteSmoke'>
                    <div className='w-[40px] h-[40px] rounded-[50%] flex justify-center items-center bg-rose'>
                        <DeleteIcon color='#CB444A' />
                    </div>
                </div>

                <h5 className='text-base font-semibold leading-7 text-ebony mt-[24px] mb-[16px]'>
                    Delete beneficiary?
                </h5>
                <p className='text-sm  text-ebony mb-[40px]'>
                    Are you sure you want to delete{' '}
                    <span className='font-bold'>
                        {item?.beneficiaryCompanyName}.
                    </span>
                </p>

                <SuitUIButton
                    text='Delete'
                    onClick={deleteBeneficiary}
                    isLoading={isDeleting}
                    fullWidth
                    variant='danger'
                />
            </div>
        </CustomModal>
    );
};
