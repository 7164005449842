import {
    Alert,
    Autocomplete,
    CircularProgress,
    MenuItem,
    TextField
} from '@mui/material';
import React from 'react';
import Quotefield from '../../../../pages/dashboard/tradedocuments/quotation/quotefield/quotefield';

import { formatDecimalNumber } from '../../../../utils/helper';
import ChooseDocUpload from '../../../custommodal/ChooseDocUpload';
import UploadLogo from '../common/UploadLogo';
import { CommercialFormInterface } from '../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/CommercialTypes';
import SignatureFields from './CommercialFomComponents/SignatureFields';
import DepatureDetails from './CommercialFomComponents/DepatureDetails';
import PortsDetails from './CommercialFomComponents/PortsDetails';
import TransportDetails from './CommercialFomComponents/TransportDetails';
import ConsigneeDetails from './CommercialFomComponents/ConsigneeDetails';
import ExporterDetails from './CommercialFomComponents/ExporterDetails';
import MethodDetails from './CommercialFomComponents/MethodDetails';

const CommercialForm = ({
    error,
    updateField,
    payload,
    corporatePartners,
    countries,
    isCountriesLoading,
    loadingCorporatepartners,
    setShowAddPartner,
    errors,
    quoteFields,
    updateQuoteField,
    removeQuoteField,
    currencies,
    addQuoteField,
    loadingCurrencies,
    setSignatureUrlBase64,
    signatureUrlBase64,
    totalAmount,
    setPartnerId
}: CommercialFormInterface) => {
    return (
        <div className='form-container'>
            <div className='border-0 border-appcolorgreyfaint'>
                {error !== null && (
                    <div className='mb-4'>
                        <Alert severity='error'>{error}</Alert>
                    </div>
                )}

                <form>
                    <div className=''>
                        <div className='flex gap-2 items-center'>
                            <UploadLogo {...{ payload, updateField }} />

                            <ChooseDocUpload {...{ updateField, payload }}>
                                <p>Choose Logo</p>
                            </ChooseDocUpload>
                        </div>
                        <ExporterDetails
                            {...{
                                payload,
                                updateField,
                                errors,
                                corporatePartners,
                                loadingCorporatepartners,
                                setShowAddPartner
                            }}
                        />

                        <ConsigneeDetails
                            {...{
                                payload,
                                updateField,
                                errors,
                                corporatePartners,
                                loadingCorporatepartners,
                                setShowAddPartner
                            }}
                        />

                        <MethodDetails
                            {...{
                                errors,
                                payload,
                                updateField,
                                countries,
                                isCountriesLoading,
                                setShowAddPartner
                            }}
                        />

                        <TransportDetails
                            {...{
                                payload,
                                updateField,
                                errors,
                                countries,
                                isCountriesLoading,
                                setShowAddPartner
                            }}
                        />

                        <PortsDetails
                            {...{
                                payload,
                                updateField,
                                errors,
                                setShowAddPartner
                            }}
                        />

                        <DepatureDetails
                            {...{
                                payload,
                                updateField,
                                errors,
                                setShowAddPartner
                            }}
                        />
                    </div>

                    {quoteFields.map((item, index) => (
                        <Quotefield
                            item={item}
                            key={item.id}
                            index={index}
                            id={String(item.id)}
                            updateQuoteField={updateQuoteField}
                            price={item.price}
                            qty={item.qty}
                            removeQuoteField={removeQuoteField}
                        />
                    ))}

                    <div className='border border-appcolorgreyfaint px-[1rem] pt-8 pb-8'>
                        <div
                            className='flex items-center cursor-pointer w-fit'
                            onClick={() => {
                                addQuoteField();
                            }}
                        >
                            <div>
                                <img
                                    src='/img/vectors/primarydownload.svg'
                                    alt='primarydownload'
                                    className='w-6'
                                />
                            </div>
                            <span className='pl-2 text-appcolorprimary'>
                                Add Field
                            </span>
                        </div>
                    </div>

                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 flex justify-end gap-2'>
                        <div className='w-[20%]'>
                            <Autocomplete
                                placeholder='Currency'
                                disableClearable
                                id='send-currency'
                                sx={{
                                    marginTop: '1rem',
                                    '.MuiInputBase-input': {
                                        height: '0.3rem'
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        value={payload?.currencyCode || ''}
                                        label='Select Currency'
                                        placeholder='Currency'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: loadingCurrencies && (
                                                <React.Fragment>
                                                    <CircularProgress
                                                        size={20}
                                                    />
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </React.Fragment>
                                            )
                                        }}
                                    />
                                )}
                                noOptionsText={'No data available'}
                                renderOption={(props, option) => (
                                    <MenuItem
                                        {...props}
                                        value={option.nameAndFlag || ''}
                                        key={`countries-${option.id}`}
                                    >
                                        {option.nameAndFlag}
                                    </MenuItem>
                                )}
                                getOptionLabel={(option) => option.nameAndFlag}
                                onChange={(event: any, value: any) => {
                                    if (value !== null) {
                                        updateField(
                                            'currencyCode',
                                            value.currencyCode
                                        );
                                        updateField('currencyCodeValue', value);
                                    }
                                }}
                                options={currencies}
                            />
                        </div>
                        <div className='w-[20%]'>
                            <TextField
                                disabled
                                margin='normal'
                                fullWidth
                                id='commercialinvoice-totalAmount'
                                label='Total Amount'
                                name='totalAmount'
                                value={formatDecimalNumber(totalAmount || 0)}
                            />
                        </div>
                    </div>

                    <SignatureFields
                        {...{
                            payload,
                            setPartnerId,
                            setShowAddPartner,
                            updateField,
                            errors,
                            setSignatureUrlBase64,
                            signatureUrlBase64
                        }}
                    />
                </form>
            </div>
        </div>
    );
};

export default CommercialForm;
