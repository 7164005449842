import { ReactNode } from 'react';
import { getPorts } from '../../api/base/base';
import GenericAutocomplete from './GenericAutocomplete';

export interface PortsAutocompleteProps {
    errorCondition: boolean;
    helperText: string | undefined;
    iconPrefix?: ReactNode;
    id: string;
    isSnug?: boolean;
    isRequired?: boolean;
    label: string;
    name: string;
    nameValue?: string;
    updateField: (name: string, value: any) => void;
    value?: string;
}

const PortsAutocomplete = (props: PortsAutocompleteProps) => {
    return (
        <GenericAutocomplete
            apiFunction={getPorts}
            labelFunction={(item: any) => item.toDisplay}
            displayFunction={(item: any) => {
                return {
                    ...item,
                    toDisplay: `${item.name}, ${item.countryName}`
                };
            }}
            error={props.errorCondition}
            helperText={props.helperText}
            iconPrefix={props.iconPrefix}
            id={props.id}
            isSnug={props.isSnug}
            label={props.label}
            name={props.name}
            updateField={props.updateField}
            nameValue={props.nameValue}
            isRequired={props.isRequired}
            updateFieldValue={props.updateField}
            value={props.value}
        />
    );
};

export default PortsAutocomplete;
