import { useState } from 'react';
import PortsAutocomplete from '../../autocompletewithapi/PortsAutocomplete';
import CustomTextField from '../../CustomTextInput';
import NewCustomSelect from '../../newcustomselect/newcustomselect';
import { NumberInput } from '../../paymentinput/paymentinput';
import { SuitUIButton } from '../../SuitUIButton';

const AddWarehouseForm = () => {
    const [formValues, setFormValues] = useState({
        type: '',
        status: '',
        location: '',
        unit: 'm2'
    });

    return (
        <div className='flex flex-col gap-[16px]'>
            <CustomTextField label='Warehouse name' name='warehouseName' />

            <div className='w-full'>
                <PortsAutocomplete
                    errorCondition={false}
                    helperText={''}
                    id='warehouse-location'
                    label='Location'
                    name='location'
                    updateField={() => console.log('')}
                    nameValue='location'
                    value={formValues.location}
                />
            </div>

            <NewCustomSelect
                hasError={false}
                helperText=''
                selectValue={formValues?.type}
                required
                selectOptions={[
                    {
                        value: 'closed',
                        label: 'Closed'
                    },
                    {
                        value: 'open-yard',
                        label: 'Open Yard'
                    },
                    {
                        value: 'refrigerated',
                        label: 'Refrigerated'
                    }
                ]}
                name={'type'}
                updateField={(_, value) =>
                    setFormValues({ ...formValues, type: value })
                }
                label={'Select Type'}
                selectOptionLabel='label'
                loading={false}
            />

            <div className='grid grid-cols-[2fr_1fr] gap-2'>
                <CustomTextField
                    label='Size'
                    name='size'
                    InputProps={{
                        inputComponent: NumberInput,
                        inputProps: {
                            min: '0'
                        }
                    }}
                />
                <NewCustomSelect
                    hasError={false}
                    helperText=''
                    selectValue={formValues?.unit}
                    required
                    selectOptions={[
                        {
                            value: 'm2',
                            label: 'm2'
                        },
                        {
                            value: 'sq ft',
                            label: 'sq ft'
                        }
                    ]}
                    name={'unit'}
                    updateField={(_, value) =>
                        setFormValues({ ...formValues, unit: value })
                    }
                    label={'Unit'}
                    selectOptionLabel='label'
                    loading={false}
                />
            </div>

            <NewCustomSelect
                hasError={false}
                helperText=''
                selectValue={formValues?.type}
                required
                selectOptions={[
                    {
                        value: 'active',
                        label: 'Active'
                    },

                    {
                        value: 'inactive',
                        label: 'Inactive'
                    }
                ]}
                name='status'
                updateField={(_, value) =>
                    setFormValues({ ...formValues, type: value })
                }
                label='Status'
                selectOptionLabel='label'
                loading={false}
            />

            <SuitUIButton
                text='Save Warehouse'
                onClick={() => {}}
                fullWidth
                style='mt-[40px]'
            />
        </div>
    );
};

export default AddWarehouseForm;
