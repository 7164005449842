import CustomTextField from '../../../../components/CustomTextInput';
import { BookingForm } from './interfaces/BookingForm';
interface ReferencesFormProps {
    formData: BookingForm;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
}

export const ReferencesAdditionalForm = ({
    formData,
    setFormData
}: ReferencesFormProps) => {
    return (
        <div className='mt-4 flex flex-col gap-4 pb-4 lg:pb-0 lg:grid lg:grid-cols-3'>
            <CustomTextField
                label='Tariff Number'
                onChange={(e) =>
                    setFormData((prev) => ({
                        ...prev,
                        references: {
                            ...formData.references,
                            tariffNumber: e.target.value
                        }
                    }))
                }
            />

            <CustomTextField
                label='Contract Party Reference Number'
                onChange={(e) =>
                    setFormData((prev) => ({
                        ...prev,
                        references: {
                            ...formData.references,
                            contractPartyReferences: e.target.value
                        }
                    }))
                }
            />

            <CustomTextField
                label="Consignee's Reference Number"
                onChange={(e) =>
                    setFormData((prev) => ({
                        ...prev,
                        references: {
                            ...formData.references,
                            consigneeReferences: e.target.value
                        }
                    }))
                }
            />

            <CustomTextField
                label='B/L Reference Number'
                onChange={(e) =>
                    setFormData((prev) => ({
                        ...prev,
                        references: {
                            ...formData.references,
                            billOfLadingReferences: e.target.value
                        }
                    }))
                }
            />

            <CustomTextField
                label='Customer Shipment ID'
                onChange={(e) =>
                    setFormData((prev) => ({
                        ...prev,
                        references: {
                            ...formData.references,
                            customerShipmentId: e.target.value
                        }
                    }))
                }
            />
        </div>
    );
};
