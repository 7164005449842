export const ShipIcon = () => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M20.42 12.3697C21.29 12.7197 21.83 13.7497 21.63 14.6597L21.22 16.5197C20.51 19.7197 18 21.9997 14.38 21.9997H9.61998C5.99998 21.9997 3.48999 19.7197 2.77999 16.5197L2.36998 14.6597C2.16998 13.7497 2.70997 12.7197 3.57997 12.3697L4.99999 11.7997L10.51 9.58969C11.47 9.20969 12.53 9.20969 13.49 9.58969L19 11.7997L20.42 12.3697Z'
            stroke='#0088B2'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M12 22V10'
            stroke='#0088B2'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M19 8V11.8L13.49 9.59C12.53 9.21 11.47 9.21 10.51 9.59L5 11.8V8C5 6.35 6.35 5 8 5H16C17.65 5 19 6.35 19 8Z'
            stroke='#0088B2'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M14.5 5H9.5V3C9.5 2.45 9.95 2 10.5 2H13.5C14.05 2 14.5 2.45 14.5 3V5Z'
            stroke='#0088B2'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
