import React from 'react';
import { PlainFileIcon } from '../../../components/icons/PlainFileIcon';
import { Divider, Grid, Skeleton } from '@mui/material';
import {
    fetchCurrencySymbol,
    formatCurrency,
    formatNumber
} from '../../../utils/helper';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { ViewContainer } from '../../../styles/PaymentStyles';
import { REACT_APP_BASE_URL } from '../../../config';
import ViewTransactionDocument from './shared/ViewTransactionDocument';
import { ViewPaymentReceipt } from './shared/ViewPaymentReceipt';
import PaymentProgress from '../../../components/PaymentProgress';
import dayjs from 'dayjs';
import { InvoicePayment } from './interfaces/InvoicePayment';
import { useGetQuery } from '../../../customhooks/useGetQuery';
import { Transactions } from './interfaces/Transactions';
import useWallets from '../../../customhooks/wallets/useWallets';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';

const LogisticsInvoicePaymentDetails = ({
    paymentDetails
}: {
    paymentDetails: InvoicePayment[];
}) => {
    const { reference } = useParams();
    const { user } = useSelector((state: RootState) => state.user);
    const { wallets, isLoading: isWalletLoading } = useWallets();

    const { isLoading: isTransactionLoading, entries: transactionDetails } =
        useGetQuery<Transactions>({
            url: `/wallet/transaction/${paymentDetails[0]?.transactionReference}/corporate/${user?.corporate_id}`,
            cacheKey: `invoice-transaction-details-${reference}`,
            enabled: Boolean(paymentDetails[0]?.transactionReference)
        });

    const wallet = wallets.find(
        (wallet) => wallet.id === transactionDetails?.fromWalletId
    );
    const { permissions } = useUserProfile();

    return (
        <div>
            <div className='mt-[20px]'>
                <div className='flex gap-[5px] items-center py-[10px] px-[14px] leading-[22px] text-[15px] bg-white w-max'>
                    <PlainFileIcon />
                    Details
                </div>
            </div>

            {isWalletLoading || isTransactionLoading ? (
                <Skeleton variant='rectangular' height={'35rem'} />
            ) : (
                <Grid
                    className='p-[20px] bg-white rounded-[5px] gap-[40px] rounded-tl-[0]'
                    justifyContent='space-evenly'
                    direction='row'
                    alignItems='center'
                    container
                >
                    <Grid container item lg={5}>
                        <Grid item xs={12} md={12} lg={12}>
                            <div className=' flex justify-between py-[15px] border-b-[1px] border-[#eaecf0]'>
                                <p className='text-appcolorblacktwo opacity-[0.6]'>
                                    Rate
                                </p>

                                <p className='font-medium'>
                                    {formatCurrency(
                                        1,
                                        transactionDetails?.fromCurrencyCode
                                    )}
                                    &nbsp; ={' '}
                                    {formatCurrency(
                                        transactionDetails?.rateValue,
                                        transactionDetails?.toCurrencyCode
                                    )}
                                </p>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <div className=' flex justify-between py-[15px] border-b-[1px] border-[#eaecf0]'>
                                <p className='text-appcolorblacktwo opacity-[0.6]'>
                                    Carrier receives
                                </p>
                                <p className='font-medium'>
                                    {formatCurrency(
                                        transactionDetails?.amountTo,
                                        transactionDetails?.toCurrencyCode
                                    )}
                                </p>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <div className=' flex justify-between py-[15px] border-b-[1px] border-[#eaecf0]'>
                                <p className='text-appcolorblacktwo opacity-[0.6]'>
                                    Transaction Account / Wallet
                                </p>
                                <p className='font-medium'>
                                    {wallet?.walletName}
                                </p>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <div className=' flex justify-between py-[15px] border-b-[1px] border-[#eaecf0]'>
                                <p className='text-appcolorblacktwo opacity-[0.6]'>
                                    Amount from
                                </p>
                                <p className='font-medium'>
                                    {formatCurrency(
                                        transactionDetails?.amountFrom,
                                        transactionDetails?.fromCurrencyCode
                                    )}
                                </p>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <div className=' flex justify-between py-[15px] border-b-[1px] border-[#eaecf0]'>
                                <p className='text-appcolorblacktwo opacity-[0.6]'>
                                    Amount to
                                </p>
                                <p className='font-medium'>
                                    {formatCurrency(
                                        transactionDetails?.amountTo,
                                        transactionDetails?.toCurrencyCode
                                    )}
                                </p>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <div className=' flex justify-between py-[15px] border-b-[1px] border-[#eaecf0]'>
                                <p className='text-appcolorblacktwo opacity-[0.6]'>
                                    Transaction ID
                                </p>
                                <p className='font-medium'>
                                    {transactionDetails?.transactionReference}
                                </p>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <div className=' flex justify-between py-[15px] border-b-[1px] border-[#eaecf0]'>
                                <p className='text-appcolorblacktwo opacity-[0.6]'>
                                    Transaction date
                                </p>
                                <p className='font-medium'>
                                    {transactionDetails?.transactionEndDate
                                        ? dayjs(
                                              transactionDetails?.transactionEndDate
                                          ).format('DD MMM, YYYY')
                                        : 'n/a'}
                                </p>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <div className=' flex justify-between py-[15px] border-b-[1px] border-[#eaecf0]'>
                                <p className='text-appcolorblacktwo opacity-[0.6]'>
                                    Narration
                                </p>
                                <p className='font-medium text-right w-[70%]'>
                                    {transactionDetails?.narration}
                                </p>
                            </div>
                        </Grid>

                        {transactionDetails?.documents &&
                            transactionDetails?.documents.length > 0 &&
                            permissions &&
                            hasPermission({
                                userPermissions: permissions,
                                module: 'payments',
                                permissionList: ['write', '*', 'read']
                            }) && (
                                <Grid item xs={12} md={12} lg={12}>
                                    <div className=' flex justify-between py-[10px]'>
                                        <p className='text-appcolorblacktwo opacity-[0.6]'>
                                            Invoice
                                        </p>

                                        <ViewTransactionDocument
                                            fileUrl={
                                                REACT_APP_BASE_URL +
                                                transactionDetails?.documents[0]?.documentUrl?.replace(
                                                    'v1/',
                                                    ''
                                                )
                                            }
                                        />
                                    </div>
                                </Grid>
                            )}
                        {permissions &&
                            hasPermission({
                                userPermissions: permissions,
                                module: 'payments',
                                permissionList: ['write', '*', 'read']
                            }) && (
                                <Grid item xs={12} md={12} lg={12}>
                                    <ViewContainer>
                                        <div className=' flex justify-between py-[10px]'>
                                            <p className='text-appcolorblacktwo opacity-[0.6]'>
                                                Payment Receipt:
                                            </p>

                                            <ViewPaymentReceipt
                                                data={transactionDetails}
                                            />
                                        </div>
                                    </ViewContainer>
                                </Grid>
                            )}
                    </Grid>

                    <Divider orientation='vertical' flexItem />
                    <Grid item xs={12} md={12} lg={5}>
                        <div className='flex justify-center mx-auto'>
                            <PaymentProgress
                                fromAmount={`${transactionDetails?.fromCurrencyCode} ${formatNumber(
                                    Number(transactionDetails?.amountFrom)
                                )}`}
                                toAmount={`${transactionDetails?.toCurrencyCode} ${formatNumber(
                                    Number(transactionDetails?.amountTo)
                                )}`}
                                toName={user?.name}
                                status={transactionDetails.status}
                                transactionAction={
                                    transactionDetails?.transactionAction ?? ''
                                }
                                fromName={transactionDetails?.businessName}
                            />
                        </div>
                        <div className='mt-4 bg-[#f9f9f9] p-[0.6rem] rounded-[3px]'>
                            <p className='text-center text-[15px]'>
                                <span>
                                    You sent{' '}
                                    <b>
                                        {formatCurrency(
                                            transactionDetails?.amountFrom,
                                            transactionDetails.fromCurrencyCode
                                        )}
                                    </b>{' '}
                                    at{' '}
                                    <b>
                                        {formatCurrency(
                                            1,
                                            transactionDetails?.fromCurrencyCode
                                        )}
                                        &nbsp; = &nbsp;
                                        {fetchCurrencySymbol(
                                            transactionDetails.toCurrencyCode
                                        )}
                                        {transactionDetails.rateValue}
                                        .&nbsp;
                                    </b>
                                </span>

                                <span>
                                    Recipient will get <br />
                                    <b>
                                        {formatCurrency(
                                            transactionDetails?.amountTo,
                                            transactionDetails.toCurrencyCode
                                        )}
                                    </b>
                                </span>
                            </p>
                        </div>
                    </Grid>
                </Grid>
            )}
        </div>
    );
};

export default LogisticsInvoicePaymentDetails;
