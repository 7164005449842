import { Box, LinearProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { Typography } from 'antd';
import { DeleteIcon } from './icons/DeleteIcon';
import { FileIcon } from './icons/FileIcon';
import { UploadProgressParams, mutateConfig } from '../api/queryBase';
import { useEffect, useState } from 'react';
import { REACT_APP_BASE_URL } from '../config';
import { getErrorMessage, getFileName } from '../utils/helper';

interface UploadParams {
    reference: string;
    file: File;
    onHandleSettled: (params: { uri: string; reference: string }) => void;
    onDeleteHandler: (uri: string) => void;
}

export const UploadProgressInformation = (params: UploadParams) => {
    const { file, onHandleSettled, onDeleteHandler, reference } = params;
    const [progress, setProgress] = useState(0);
    const onUploadProgress = (params: UploadProgressParams) => {
        setProgress(Math.floor(params.progress * 100));
    };
    const mutation = mutateConfig({ onUploadProgress });
    const { mutate } = mutation<{ fileUri: string }>({
        url: `${REACT_APP_BASE_URL}/files/upload`
    });

    const form = new FormData();

    form.append('file', file);
    form.append('fileType', 'OTHERS');

    useEffect(() => {
        mutate(form, {
            onSettled: (data, error) => {
                if (error) {
                    toast.error(
                        getErrorMessage(error) ||
                            'Upload failed and a report has been raised. Please try again later'
                    );
                    return;
                }

                if (data) {
                    onHandleSettled({ uri: data.payload.fileUri, reference });
                }
            }
        });
    }, []);

    return (
        <Box className='lg:max-w-[450px] lg:min-w-[439px] max-w-full min-w-full h-[96px] p-4 border-[1px] border-appcolorprimary bg-white rounded-md'>
            <Box className='grid grid-cols-12 gap-2'>
                <FileIcon />
                <Box className='pl-4 col-span-10'>
                    <Typography className='uppercase'>
                        {getFileName(file.name)}
                    </Typography>
                    <Typography>{Math.round(file.size / 1000)} KB</Typography>
                    <Box className='flex items-center'>
                        <LinearProgress
                            className='w-full mr-[12px]'
                            variant='determinate'
                            value={progress}
                        />{' '}
                        <span>{progress}%</span>
                    </Box>
                </Box>
                <Box
                    className='cursor-pointer'
                    onClick={() => onDeleteHandler(reference)}
                >
                    <DeleteIcon />
                </Box>
            </Box>
        </Box>
    );
};
