export const ChevronRightIcon = () => {
    return (
        <svg
            width='6'
            height='11'
            viewBox='0 0 6 11'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.784796 10.3002C0.59556 10.3002 0.406325 10.2218 0.262181 10.0658C-0.0268467 9.75295 -0.0268467 9.24735 0.262181 8.93455L3.43557 5.50015L0.262181 2.06575C-0.0268467 1.75295 -0.0268467 1.24735 0.262181 0.934551C0.551208 0.621751 1.01838 0.621751 1.30741 0.934551L5.00341 4.93455C5.29244 5.24735 5.29244 5.75295 5.00341 6.06575L1.30741 10.0658C1.16327 10.2218 0.974031 10.3002 0.784796 10.3002Z'
                fill='#98A2B3'
            />
        </svg>
    );
};
