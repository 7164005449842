import { FormControl, OutlinedInput, InputAdornment } from '@mui/material';
import { SearchIcon } from './icons/SearchIcon';
import { ChangeEventHandler } from 'react';

export const SearchInput = ({
    onHandleChange
}: {
    onHandleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}) => {
    return (
        <FormControl
            sx={{
                background: 'white',
                borderRadius: '8px',
                boxShadow:
                    '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A',
                height: '40px'
            }}
            variant='outlined'
            className='w-full lg:w-[35ch]'
        >
            <OutlinedInput
                startAdornment={
                    <InputAdornment position='start'>
                        <SearchIcon />
                    </InputAdornment>
                }
                sx={{
                    borderRadius: '8px',
                    height: '40px'
                }}
                placeholder='Search'
                onChange={onHandleChange}
            />
        </FormControl>
    );
};
