export const DocumentTextIcon = ({ color = '#0088B2' }: { color?: string }) => (
    <svg
        width='56'
        height='57'
        viewBox='0 0 56 57'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect
            y='0.0214844'
            width='56'
            height='56'
            rx='28'
            fill={color}
            fillOpacity='0.05'
        />
        <path
            opacity='0.4'
            d='M36.5 26.19H33.61C31.24 26.19 29.31 24.26 29.31 21.89V19C29.31 18.45 28.86 18 28.31 18H24.07C20.99 18 18.5 20 18.5 23.57V32.43C18.5 36 20.99 38 24.07 38H31.93C35.01 38 37.5 36 37.5 32.43V27.19C37.5 26.64 37.05 26.19 36.5 26.19Z'
            fill={color}
        />
        <path
            d='M31.7999 18.2105C31.3899 17.8005 30.6799 18.0805 30.6799 18.6505V22.1405C30.6799 23.6005 31.9199 24.8105 33.4299 24.8105C34.3799 24.8205 35.6999 24.8205 36.8299 24.8205C37.3999 24.8205 37.6999 24.1505 37.2999 23.7505C35.8599 22.3005 33.2799 19.6905 31.7999 18.2105Z'
            fill={color}
        />
        <path
            d='M29.5 29.75H23.5C23.09 29.75 22.75 29.41 22.75 29C22.75 28.59 23.09 28.25 23.5 28.25H29.5C29.91 28.25 30.25 28.59 30.25 29C30.25 29.41 29.91 29.75 29.5 29.75Z'
            fill={color}
        />
        <path
            d='M27.5 33.75H23.5C23.09 33.75 22.75 33.41 22.75 33C22.75 32.59 23.09 32.25 23.5 32.25H27.5C27.91 32.25 28.25 32.59 28.25 33C28.25 33.41 27.91 33.75 27.5 33.75Z'
            fill={color}
        />
    </svg>
);
