export const UploadCloudIcon = () => (
    <svg
        width='21'
        height='21'
        viewBox='0 0 21 21'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M7.16699 13.4183L10.5003 10.085M10.5003 10.085L13.8337 13.4183M10.5003 10.085V17.585M17.167 14.0373C18.1849 13.1967 18.8337 11.9249 18.8337 10.5016C18.8337 7.97032 16.7816 5.91829 14.2503 5.91829C14.0682 5.91829 13.8979 5.82329 13.8054 5.66641C12.7187 3.82232 10.7124 2.58496 8.41699 2.58496C4.96521 2.58496 2.16699 5.38318 2.16699 8.83496C2.16699 10.5567 2.8632 12.1159 3.98945 13.2462'
            stroke='#475467'
            strokeWidth='1.66667'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
