import React, { useEffect, useState } from 'react'
import { BookingContainer } from '../../../../components/dashboard/upsBooking/Styles'
import BookingHeader from '../../../../components/dashboard/upsBooking/BookingHeader'
import {
  getBookingProfile,
  getBookings,
  getShippingProfile,
} from '../../../../api/booking/booking'
import { openNotification } from '../../../../utils/helper'

import { DocumentTableContainer } from '../../../../styles/TradeDocStyles'
import BookingProfileTable from '../../../../components/dashboard/upsBooking/BookingProfileTable'
import BookingsTable from '../../../../components/dashboard/upsBooking/BookingsTable'
import ShippingProfileTable from '../../../../components/dashboard/upsBooking/ShippingProfileTable'

const BookingOverviewNew = () => {
  const breadcrumbs = [
    {
      title: 'Home',
      link: '/dashboard',
      active: false,
    },
    {
      title: 'Booking',
      link: '/bookings/overview',
      active: true,
    },
  ]

  const [loadingData, setLoadingData] = useState(false)
  const [data, setData] = useState<any>(null)
  const [activeView, setActiveView] = useState('Booking Profiles')

  const fetchBookingProfile = async () => {
    setLoadingData(true)
    try {
      const res = await getBookingProfile({ page: 1, size: 0 })

      const { content } = res.data.data

      setData({ content: content })
      setLoadingData(false)
    } catch (error) {
      setLoadingData(false)
      if (error?.response) {
        openNotification('error', `Booking Profile, Something went wrong`)
      } else {
        openNotification('error', `Booking Profile, Something went wrong`)
      }
    }
  }

  const fetchShippingProfile = async () => {
    setLoadingData(true)
    try {
      const res = await getShippingProfile({ page: 1, size: 0 })

      const { content } = res.data.data

      setData({ content: content })
      setLoadingData(false)
    } catch (error) {
      setLoadingData(false)
      if (error?.response) {
        openNotification('error', `Shipping Profile, Something went wrong`)
      } else {
        openNotification('error', `Shipping Profile, Something went wrong`)
      }
    }
  }

  const fetchBookings = async () => {
    setLoadingData(true)
    try {
      const res = await getBookings({ page: 1, size: 0 })

      const { content } = res.data.data

      setData({ content: content })
      setLoadingData(false)
    } catch (error) {
      setLoadingData(false)
      if (error?.response) {
        openNotification('error', `Booking , Something went wrong`)
      } else {
        openNotification('error', `Booking , Something went wrong`)
      }
    }
  }

  useEffect(() => {
    activeView === 'Bookings'
      ? fetchBookings()
      : activeView === 'Shipping Profiles'
      ? fetchShippingProfile()
      : fetchBookingProfile()
  }, [activeView])

  return (
    <BookingContainer>
      <BookingHeader {...{ breadcrumbs, setActiveView, activeView }} />
      <DocumentTableContainer className="bg-appcolorwhite">
        {activeView === 'Bookings' && (
          <BookingsTable {...{ data, loadingData }} fetch={fetchBookings} />
        )}
        {activeView === 'Shipping Profiles' && (
          <ShippingProfileTable
            {...{ data, loadingData }}
            fetch={fetchShippingProfile}
          />
        )}
        {activeView === 'Booking Profiles' && (
          <BookingProfileTable
            {...{ data, loadingData }}
            fetch={fetchBookingProfile}
          />
        )}
      </DocumentTableContainer>
    </BookingContainer>
  )
}

export default BookingOverviewNew
