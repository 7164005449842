import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import AddBeneficiaryDrawer from './AddBeneficiaryDrawer';
import { useTheme } from '@mui/material/styles';
import { Divider, Stack } from '@mui/material';
import { TabContainerNew } from '../../../styles/TradeDocStyles';
import UserPaymentTable from './UserPaymentTable';
import OpsPaymentTable from './OpsPaymentTable';
import useBeneficiaries from '../../../customhooks/beneficiaries/useBeneficiaries';
import FilterTransactions from './FilterTransactions';
import { add } from '../../../components/customIcons';
import { RefreshTableIcon } from '../../../components/icons/RefreshTableIcon';
import { InfoSquareIcon } from '../../../components/icons/InfoSquareIcon';
import { GetTransactionsParams } from './interfaces/Transactions';
import { useTransactions } from '../../../customhooks/useTransactions';
import { ExportTransactions } from './ExportTransactions';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { Dropdown } from 'antd';
import { MoreIcon } from '../../../components/icons/MoreIcon';
import { ExportTwoIcon, RefreshCircleIcon } from '../../../components/icons';

const PaymentTable = () => {
    const theme = useTheme();
    const location = useLocation();

    const { user } = useSelector((state: RootState) => state.user);
    const [activeView, setActiveView] = useState('transactions');
    const [showAddBeneficiary, setShowAddBeneficiary] = useState(false);
    const [beneficiaryId, setBeneficiaryId] = useState<number | null>(null);
    const [viewExport, setViewExport] = useState(false);

    const { permissions } = useUserProfile();

    const [transactionFilterOptions, setTransactionFilterOptions] =
        useState<GetTransactionsParams>({
            page: 1,
            size: 0
        });

    const {
        isLoading: isTransactionsLoading,
        transactionsList,
        invalidateTransactionsCache: fetchTransactions,
        isFetching: isTransactionsFetching
    } = useTransactions(transactionFilterOptions);

    const {
        beneficiaries,
        isLoading: isBeneficiariesLoading,
        invalidateBeneficiaryCache: fetchBeneficiaries,
        isBeneficiariesFetching
    } = useBeneficiaries();

    const reRunGetBeneficiaries = () => {
        fetchBeneficiaries();
    };

    const closeAddBeneficiaryDrawer = () => {
        setShowAddBeneficiary(false);
    };

    return (
        <div className='pt-[16px]'>
            {location.state !== null && location.state?.newTransactionAlert && (
                <div className='mt-[-1rem] mb-8'>
                    <Alert
                        icon={<InfoSquareIcon />}
                        severity='info'
                        sx={{
                            background: 'rgba(0, 136, 178, 0.06)',
                            borderRadius: '3px',
                            border: '1px solid #0088B2',
                            color: theme.palette.primary.main
                        }}
                    >
                        One new payment made.
                    </Alert>
                </div>
            )}

            <div className='bg-white min-h-[50vh] lg:bg-[unset]'>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    className='header-action mb-[1rem] mt-[-2rem]'
                >
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        width='100%'
                    >
                        <div className='px-[16px] pt-[24px] lg:px-0 lg:pt-0'>
                            <TabContainerNew>
                                <Stack direction='row' spacing={'5px'}>
                                    <div
                                        className={`cursor-pointer rounded-[5px] px-[5px] text-sm flex items-center justify-center ${
                                            activeView === 'transactions'
                                                ? 'lg:bg-[#0088b21a] text-[#0088b2] font-medium'
                                                : ''
                                        }`}
                                        onClick={() =>
                                            setActiveView('transactions')
                                        }
                                    >
                                        <p
                                            style={{
                                                paddingRight: '5px'
                                            }}
                                        >
                                            Transactions{' '}
                                        </p>
                                    </div>

                                    <Divider orientation='vertical' flexItem />
                                    <div
                                        className={`cursor-pointer rounded-[5px] px-[5px] text-sm flex items-center justify-center ${
                                            activeView === 'beneficiaries'
                                                ? 'lg:bg-[#0088b21a] text-[#0088b2] font-medium'
                                                : ''
                                        }`}
                                        onClick={() =>
                                            setActiveView('beneficiaries')
                                        }
                                    >
                                        <p style={{ paddingRight: '5px' }}>
                                            Beneficiaries
                                        </p>
                                    </div>
                                </Stack>
                            </TabContainerNew>
                        </div>

                        <div className='hidden lg:block'>
                            <TabContainerNew>
                                {activeView === 'transactions' &&
                                    permissions &&
                                    hasPermission({
                                        userPermissions: permissions,
                                        module: 'payments',
                                        permissionList: ['write', '*', 'read']
                                    }) && (
                                        <>
                                            <ExportTransactions
                                                params={
                                                    transactionFilterOptions
                                                }
                                                viewExport={viewExport}
                                                setViewExport={setViewExport}
                                            />

                                            <Divider
                                                orientation='vertical'
                                                flexItem
                                            />
                                            <FilterTransactions
                                                {...{
                                                    setTransactionFilterOptions,
                                                    beneficiaries
                                                }}
                                            />

                                            <Divider
                                                orientation='vertical'
                                                flexItem
                                            />
                                        </>
                                    )}
                                {activeView === 'beneficiaries' &&
                                    user?.user_level === 1 &&
                                    permissions &&
                                    hasPermission({
                                        userPermissions: permissions,
                                        module: 'payments',
                                        permissionList: ['write', '*']
                                    }) && (
                                        <>
                                            <div
                                                className='tab-buttons ml-2'
                                                onClick={() =>
                                                    setShowAddBeneficiary(true)
                                                }
                                            >
                                                <div className='pr-2'>
                                                    {add}
                                                </div>
                                                <p
                                                    style={{
                                                        paddingRight: '5px'
                                                    }}
                                                >
                                                    Add Beneficiary
                                                </p>
                                            </div>

                                            <Divider
                                                orientation='vertical'
                                                flexItem
                                            />
                                        </>
                                    )}
                                <div
                                    className='tab-buttons ml-2'
                                    onClick={
                                        activeView === 'beneficiaries'
                                            ? () => fetchBeneficiaries()
                                            : () => fetchTransactions()
                                    }
                                >
                                    <div className='pr-2'>
                                        <RefreshTableIcon />
                                    </div>
                                    <p style={{ paddingRight: '5px' }}>
                                        Refresh
                                    </p>
                                </div>
                            </TabContainerNew>
                        </div>

                        <div className='lg:hidden px-[16px] pt-[24px] relative'>
                            <Dropdown
                                menu={{
                                    items: [
                                        {
                                            key: '1',
                                            label: (
                                                <button
                                                    className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base'
                                                    onClick={
                                                        activeView ===
                                                        'beneficiaries'
                                                            ? () =>
                                                                  fetchBeneficiaries()
                                                            : () =>
                                                                  fetchTransactions()
                                                    }
                                                >
                                                    <RefreshCircleIcon />
                                                    <span>Refresh</span>
                                                </button>
                                            )
                                        },
                                        {
                                            key: '2',
                                            label:
                                                activeView === 'transactions' &&
                                                user?.user_level === 1 &&
                                                permissions &&
                                                hasPermission({
                                                    userPermissions:
                                                        permissions,
                                                    module: 'payments',
                                                    permissionList: [
                                                        'write',
                                                        '*'
                                                    ]
                                                }) ? (
                                                    <button
                                                        className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base'
                                                        onClick={() => {}}
                                                    >
                                                        <FilterTransactions
                                                            {...{
                                                                setTransactionFilterOptions,
                                                                beneficiaries
                                                            }}
                                                        />
                                                    </button>
                                                ) : null
                                        },
                                        {
                                            key: '3',
                                            label:
                                                activeView === 'transactions' &&
                                                user?.user_level === 1 &&
                                                permissions &&
                                                hasPermission({
                                                    userPermissions:
                                                        permissions,
                                                    module: 'payments',
                                                    permissionList: [
                                                        'write',
                                                        '*'
                                                    ]
                                                }) ? (
                                                    <button
                                                        className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base'
                                                        onClick={() =>
                                                            setViewExport(true)
                                                        }
                                                    >
                                                        <ExportTwoIcon />
                                                        <span>Export</span>
                                                    </button>
                                                ) : null
                                        }
                                    ]
                                }}
                                trigger={['click']}
                            >
                                <div
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    <MoreIcon />
                                </div>
                            </Dropdown>
                        </div>
                    </Stack>
                </Stack>

                {user?.user_level === 1 && (
                    <UserPaymentTable
                        {...{
                            beneficiaries,
                            transactionsList,
                            activeView,
                            setShowAddBeneficiary,
                            setBeneficiaryId
                        }}
                        reRunGetBeneficiaries={reRunGetBeneficiaries}
                        isTransactionsLoading={
                            isTransactionsFetching || isTransactionsLoading
                        }
                        isBeneficiariesLoading={
                            isBeneficiariesFetching || isBeneficiariesLoading
                        }
                    />
                )}
                {user?.user_level === 5 && (
                    <OpsPaymentTable
                        {...{
                            beneficiaries,
                            transactionsList,
                            activeView,
                            setShowAddBeneficiary,
                            reRunGetBeneficiaries,
                            setBeneficiaryId
                        }}
                        isTransactionsLoading={
                            isTransactionsFetching || isTransactionsLoading
                        }
                        isBeneficiariesLoading={
                            isBeneficiariesFetching || isBeneficiariesLoading
                        }
                    />
                )}
            </div>
            {
                <AddBeneficiaryDrawer
                    closeDrawer={closeAddBeneficiaryDrawer}
                    showDrawer={showAddBeneficiary}
                    reRunGetBeneficiaries={reRunGetBeneficiaries}
                    beneficiaryId={beneficiaryId ?? undefined}
                    setBeneficiaryId={setBeneficiaryId ?? undefined}
                />
            }
        </div>
    );
};

export default PaymentTable;
