import { Box, Drawer, useMediaQuery, useTheme } from '@mui/material';
import useWallets from '../customhooks/wallets/useWallets';
import { WalletBalances } from './walletbalances/WalletBalances';
import { EyesIcon } from './icons/EyeIcon';
import AddAccount from '../pages/dashboard/payments/AddAccount';
import { useState } from 'react';
import ViewAccounts from '../pages/dashboard/payments/ViewAccounts';
import CustomModal from './CustomModal';
import { G3PromptDialog } from '../pages/dashboard/payments/G3PromptDialog';
import TopupAccount from '../pages/dashboard/payments/TopupAccount';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserProfile } from '../customhooks/useUserProfile';
import { hasPermission } from './dashboard/dashboardpage/utils';
import { PlusIcon } from './icons/PlusIcon';
import { MoneySendIcon, WalletAddIcon } from './icons';

export const CustomerWalletBalances = () => {
    const navigate = useNavigate();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { wallets, isLoading: isLoadingWallets } = useWallets();
    const { permissions } = useUserProfile();
    const [currencyCode, setCurrencyCode] = useState('');
    const [showG3Modal, setShowG3Modal] = useState(false);
    const [showAddAccount, setShowAddAccount] = useState(false);
    const [showViewAccounts, setShowViewAccounts] = useState(false);
    const [showTopUp, setShowTopUp] = useState(false);

    const location = useLocation();

    const isPayment = location.pathname.split('/')[2] !== 'overview';

    const actions = [
        {
            component: (
                <Box className='flex space-x-2 items-center'>+ Add Funds</Box>
            ),
            onHandleClick: () => {
                setShowTopUp(true);
            },
            display:
                permissions &&
                hasPermission({
                    userPermissions: permissions,
                    module: 'payments',
                    permissionList: ['write', '*']
                }),
            title: 'Add Funds',
            icon: <PlusIcon color='#0088B2' />
        },
        {
            component: (
                <Box className='flex space-x-2 items-center'>
                    {isPayment ? 'Transactions' : '+ Make Payment'}
                </Box>
            ),
            onHandleClick: () => {
                navigate(
                    isPayment ? '/payments/overview' : '/payments/make-payment'
                );
            },
            display:
                !location.pathname.includes('logistics') &&
                permissions &&
                hasPermission({
                    userPermissions: permissions,
                    module: 'payments',
                    permissionList: ['write', '*']
                }),
            title: isPayment ? 'Transactions' : 'Make payment',
            icon: <MoneySendIcon />
        },
        {
            component: <Box>+ Add Account</Box>,
            onHandleClick: () => {
                setShowAddAccount(true);
            },
            display:
                permissions &&
                hasPermission({
                    userPermissions: permissions,
                    module: 'payments',
                    permissionList: ['write', '*']
                }),
            title: 'Add account',
            icon: <WalletAddIcon />
        },

        {
            component: (
                <Box className='flex space-x-2 items-center'>
                    <EyesIcon />
                    <Box>View All Accounts</Box>
                </Box>
            ),
            onHandleClick: () => {
                setShowViewAccounts(true);
            },
            display:
                permissions &&
                hasPermission({
                    userPermissions: permissions,
                    module: 'payments',
                    permissionList: ['write', '*', 'read']
                }),
            title: 'View accounts',
            icon: <EyesIcon size={19} />
        }
    ];

    return (
        <>
            <WalletBalances
                wallets={wallets}
                isLoading={isLoadingWallets}
                actions={actions}
            />

            <Drawer
                anchor={isMobile ? 'bottom' : 'right'}
                open={showAddAccount}
                onClose={() => setShowAddAccount(false)}
                PaperProps={{
                    sx: {
                        '.MuiDrawer-paperAnchorRight': {
                            width: {
                                xs: '95%',
                                lg: '30rem'
                            }
                        },
                        py: 2,
                        px: 4
                    }
                }}
            >
                <AddAccount
                    currencyCode={currencyCode}
                    setCurrencyCode={setCurrencyCode}
                    setShowG3Modal={setShowG3Modal}
                    setShowAddAccount={setShowAddAccount}
                />
            </Drawer>

            <ViewAccounts
                wallets={wallets}
                closeDrawer={() => setShowViewAccounts(false)}
                showDrawer={showViewAccounts}
            />

            <CustomModal
                maxWidth='lg'
                open={showG3Modal}
                onClose={() => setShowG3Modal(false)}
                styles={{
                    overflowX: 'hidden'
                }}
            >
                <G3PromptDialog
                    currencyCode={currencyCode}
                    setShowG3Modal={setShowG3Modal}
                />
            </CustomModal>

            <TopupAccount
                wallets={wallets}
                closeDrawer={() => setShowTopUp(false)}
                showDrawer={showTopUp}
                loadingWallets={isLoadingWallets}
            />
        </>
    );
};
