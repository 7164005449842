import { UserPermissions } from '../interfaces/User';
import { useGetQuery } from './useGetQuery';

interface GetUserPermissionsParams {
    search?: string;
    page:number;
    size:number
}

export const useUserPermissions = (params: GetUserPermissionsParams) => {
    const {
        isLoading,
        entries: users,
        refetch
    } = useGetQuery<{ data: UserPermissions[] }>({
        url: '/users',
        cacheKey: 'users-permission',
        params: { ...params }
    });

    return { isLoading, usersPermissions: users.data || [], refetch };
};
