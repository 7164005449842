import { Box } from '@mui/material';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { LogisticsInvoicePayDetailsSections } from './LogisticsInvoicePayDetailsSections';
import { LogisticsInvoicePayDetailsForm } from './LogisticsInvoicePayDetailsForm';
import { LogisticsInvoicePaySummary } from './LogisticsInvoicePaySummary';
import { MutableRefObject, useState } from 'react';
import { createPortal } from 'react-dom';
import { ServiceableInvoice } from './interfaces/Invoice';
import { CustomerWalletBalances } from '../../../components/CustomerWalletBalances';

export const LogisticsInvoicePayDetails = () => {
    const navigate = useNavigate();
    const { backRef } = useOutletContext() as {
        backRef: MutableRefObject<HTMLDivElement>;
    };
    const invoiceIds = new URLSearchParams(location.search).get('invoices');
    const [section, setSection] = useState(1);
    const [invoices, setInvoices] = useState<ServiceableInvoice[]>(
        [] as ServiceableInvoice[]
    );

    return (
        <div className='px-4 lg:px-0'>
            {backRef.current &&
                createPortal(
                    <Box
                        onClick={() => {
                            navigate(-1);
                        }}
                        className='lg:text-[12px] text-[16px] font-medium lg:font-normal mb-[5px] cursor-pointer'
                    >
                        {'<'} Back to logistics payment
                    </Box>,
                    backRef.current
                )}

            <CustomerWalletBalances />

            <Box className='flex lg:space-x-5 lg:mt-8 mt-0 flex-col lg:flex-row'>
                <LogisticsInvoicePayDetailsSections section={section} />
                {invoiceIds && (
                    <Box className='flex-1'>
                        {section === 1 ? (
                            <LogisticsInvoicePayDetailsForm
                                invoices={invoices}
                                section={section}
                                setSection={setSection}
                                setInvoices={setInvoices}
                                invoiceIds={invoiceIds?.split(',').map(String)}
                            />
                        ) : (
                            <LogisticsInvoicePaySummary
                                invoices={invoices}
                                section={section}
                                setSection={setSection}
                            />
                        )}
                    </Box>
                )}
            </Box>
        </div>
    );
};
