import { yupToFormErrors } from 'formik';
import { GeneralDetailsForm } from '../GeneralDetailsForm';
import { GeneralAdditionalDetailsForm } from '../GeneralAdditionalDetailsForm';
import { ShowHideSection } from '../ShowHideSection';
import { PartiesForm } from '../PartiesForm';
import { PartiesAdditionalForm } from '../PartiesAdditionalForm';
import { AddtionalSections, BookingFormProps } from '../interfaces/BookingForm';
import { SuitUIButton } from '../../../../../components/SuitUIButton';
import { GeneralDetailsSchema } from '../schemas/BookingFormValidationSchema';

interface GeneralDetailsProps extends BookingFormProps {
    activeStep: number;
    setActiveStep: (val: number) => void;
    showAdditionalSection: AddtionalSections;
    setShowAdditionalSection: (val: AddtionalSections) => void;
    setShowAddParty: React.Dispatch<React.SetStateAction<boolean>>;
}

const GeneralDetails = ({
    user,
    setShowAddNewCarrierModal,
    formData,
    setFormData,
    activeStep,
    setActiveStep,
    formik,
    showAdditionalSection,
    setShowAdditionalSection,
    setShowAddParty
}: GeneralDetailsProps) => {
    const handleNext = () => {
        try {
            GeneralDetailsSchema.validateSync(formik.values, {
                abortEarly: false
            });

            setActiveStep(activeStep + 1);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } catch (err) {
            formik.setErrors(yupToFormErrors(err));
        }
    };

    return (
        <div>
            <div className='border-b border-dashed border-gray300'>
                <GeneralDetailsForm
                    {...{
                        user,
                        formData,
                        setFormData,
                        setShowAddNewCarrierModal,
                        formik
                    }}
                />

                <ShowHideSection
                    {...{
                        show: showAdditionalSection.general,
                        title: 'Additional General Details',
                        onHandleClick: () =>
                            setShowAdditionalSection({
                                ...showAdditionalSection,
                                general: !showAdditionalSection.general
                            })
                    }}
                />
                <div className='mt-4'>
                    {showAdditionalSection.general && (
                        <GeneralAdditionalDetailsForm
                            {...{
                                user,
                                formData,
                                setFormData
                            }}
                        />
                    )}
                </div>
            </div>

            <div className='mt-4 pb-1 mb-[8px] border-b border-dashed border-gray300'>
                <p className='max-w-[343px]'>
                    Parties{' '}
                    <span className='text-crimson font-medium'>
                        {' '}
                        ** Registered Shipper or Forwarder is required **
                    </span>
                </p>
            </div>

            <div className='mb-[40px] border-b border-dashed border-gray300'>
                <PartiesForm
                    {...{
                        user,
                        formData,
                        setFormData,
                        setShowAddParty,
                        formik
                    }}
                />

                <ShowHideSection
                    {...{
                        show: showAdditionalSection.parties,
                        title: 'Additional Parties',
                        onHandleClick: () =>
                            setShowAdditionalSection({
                                ...showAdditionalSection,
                                parties: !showAdditionalSection.parties
                            })
                    }}
                />

                <div className='mt-4'>
                    {showAdditionalSection.parties && (
                        <PartiesAdditionalForm
                            {...{
                                user,
                                setFormData,
                                setShowAddParty
                            }}
                        />
                    )}
                </div>
            </div>

            <SuitUIButton text='Continue' fullWidth onClick={handleNext} />
        </div>
    );
};

export default GeneralDetails;
