export const RadioIcon = () => (
    <svg
        width='14'
        height='15'
        viewBox='0 0 14 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            opacity='0.5'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.97755 13.3489C10.2531 13.3489 12.9085 10.6935 12.9085 7.41798C12.9085 4.14242 10.2531 1.48706 6.97755 1.48706C3.70199 1.48706 1.04663 4.14242 1.04663 7.41798C1.04663 10.6935 3.70199 13.3489 6.97755 13.3489ZM6.97755 12.3023C9.67506 12.3023 11.8618 10.1155 11.8618 7.41798C11.8618 4.72046 9.67506 2.53369 6.97755 2.53369C4.28003 2.53369 2.09326 4.72046 2.09326 7.41798C2.09326 10.1155 4.28003 12.3023 6.97755 12.3023Z'
            fill='#87898F'
        />
    </svg>
);
