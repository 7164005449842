import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Outletouterpage from '../../../../components/dashboard/outletouterpage/outletouterpage';
import { Dropdown, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import type { MenuProps } from 'antd';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { commaNumber } from '../../../../utils/helper';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Viewdocumentdrawer from '../viewdocumentdrawer/viewdocumentdrawer';

const dataSource = [
    {
        id: 'FEFINANCE123',
        createdDate: 'March 17, 2023 2:04 PM',
        exporterName: 'Moni Alli',
        email: 'moni@frontedge.oi',
        phoneNo: '090 443 443 4434',
        yearlyExports: 500000,
        amountRequested: 500000,
        fundaAvailable: 300,
        fundsInUse: 500,
        noOfInvoices: 54,
        status: 1
    },
    {
        id: 'FEFINANCE123',
        createdDate: 'March 15, 2023 2:04 PM',
        exporterName: 'Moni Alli',
        email: 'moni@frontedge.oi',
        phoneNo: '090 443 443 4434',
        yearlyExports: 500000,
        amountRequested: 500000,
        fundaAvailable: 300,
        fundsInUse: 500,
        noOfInvoices: 54,
        status: 1
    },
    {
        id: 'FEFINANCE123',
        createdDate: 'March 14, 2023 7:04 AM',
        exporterName: 'Moni Alli',
        email: 'moni@frontedge.oi',
        phoneNo: '090 443 443 4434',
        yearlyExports: 500000,
        amountRequested: 500000,
        fundaAvailable: 300,
        fundsInUse: 500,
        noOfInvoices: 54,
        status: 1
    },
    {
        id: 'FEFINANCE123',
        createdDate: 'March 12, 2023 2:03 PM',
        exporterName: 'Moni Alli',
        email: 'moni@frontedge.oi',
        phoneNo: '090 443 443 4434',
        yearlyExports: 500000,
        amountRequested: 500000,
        fundaAvailable: 300,
        fundsInUse: 500,
        noOfInvoices: 54,
        status: 1
    },
    {
        id: 'FEFINANCE123',
        createdDate: 'March 11, 2023 9:00 PM',
        exporterName: 'Moni Alli',
        email: 'moni@frontedge.oi',
        phoneNo: '090 443 443 4434',
        yearlyExports: 500000,
        amountRequested: 500000,
        fundaAvailable: 300,
        fundsInUse: 500,
        noOfInvoices: 54,
        status: 1
    },
    {
        id: 'FEFINANCE123',
        createdDate: 'March 10, 2023 11:50 AM',
        exporterName: 'Moni Alli',
        email: 'moni@frontedge.oi',
        phoneNo: '090 443 443 4434',
        yearlyExports: 500000,
        amountRequested: 500000,
        fundaAvailable: 300,
        fundsInUse: 500,
        noOfInvoices: 54,
        status: 1
    }
];

const Adminfinancingoverview = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [currentData, setCurrentData] = useState<any>('');
    const [visible, setVisible] = useState(false);
    const [showDocument, setShowDocument] = useState(false);

    const updateShowDocument = (value: boolean) => {
        setShowDocument(value);
    };

    const closeVisible = () => {
        setVisible(false);
        updateShowDocument(false);
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        setVisible(true);
                    }}
                >
                    <img src='/img/vectors/eyesicon.svg' alt='eyesicon' />
                    <span className='ml-2'>Review Application</span>
                </div>
            )
        }
    ];

    const columns: ColumnsType<any> = [
        {
            title: 'Date',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (_, { createdDate }) => (
                <span>
                    {createdDate === '' ? (
                        <>{moment(moment.now()).format('ll')}</>
                    ) : (
                        <>{createdDate}</>
                    )}
                </span>
            )
        },
        {
            title: 'Exporter Name',
            dataIndex: 'exporterName',
            key: 'exporterName',
            render: (text) => (
                <span className=' text-appcolorprimary'>{text}</span>
            )
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text) => <span>{text}</span>
        },
        {
            title: 'Phone No',
            dataIndex: 'phoneNo',
            key: 'phoneNo',
            render: (text) => <span>{text}</span>
        },
        {
            title: 'Yearly Exports',
            dataIndex: 'yearlyExports',
            key: 'yearlyExports',
            render: (text) => <span>${commaNumber(text)}</span>
        },
        {
            title: 'Amount Requested',
            dataIndex: 'amountRequested',
            key: 'amountRequested',
            render: (text) => <span>${commaNumber(text)}</span>
        },
        {
            title: 'Document',
            // dataIndex: 'noOfInvoices',
            // key: 'noOfInvoices',
            render: (text) => (
                <div className='flex items-center'>
                    <span className='mr-2 text-appcolorprimary'>
                        View Files
                    </span>{' '}
                    <img
                        src='/img/vectors/featuredicon.svg'
                        alt='featuredicon'
                    />
                </div>
            )
        },
        {
            title: 'Status',
            render: (_, data) => (
                <div
                    className='cursor-pointer'
                    onClick={() => {
                        setCurrentData(data);
                    }}
                >
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <img src='/img/vectors/more.svg' alt='more' />
                    </Dropdown>
                </div>
            )
        }
    ];

    return (
        <div>
            <Outletouterpage
                breadcrumbs={[
                    {
                        title: 'Home',
                        link: '/dashboard'
                    },
                    // {
                    //   title: 'Financing',
                    //   link: '/financing'
                    // },
                    {
                        title: 'Overview',
                        link: '/financing/overview'
                    }
                ]}
            >
                <div className='flex justify-between'>
                    <h1 className='text-[32px] leading-[23px] text-appcolorblacktwo'>
                        Financing
                    </h1>

                    {/* <p className='w-fit flex items-center cursor-pointer bg-appcolorprimary rounded-[10px] py-[0.7rem] px-[0.9rem] mr-2' onClick={() => {
            navigate('/financing/export-finance/request')
          }}>
            <img src="/img/vectors/brokenplus.svg" alt="plaindownload" className='h-[1.1rem]' />
          </p> */}
                </div>

                <div className='mt-8'>
                    <div className='bg-appcolorwhite py-[2.5rem] px-[3.1rem] rounded-[3px] shadow-[0px 14px 24px rgba(51, 51, 51, 0.01)] mb-8'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12} lg={3}>
                                <div>
                                    <p className='text-appcolorfaintsix text-[14px] leading-[18px] mb-3'>
                                        No of Applications
                                    </p>
                                    <p className='font-bold text-[22px] leading-[90%] text-appcolorprimary'>
                                        5,423
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={3}>
                                <div>
                                    <p className='text-appcolorfaintsix text-[14px] leading-[18px] mb-3'>
                                        No. of Clients
                                    </p>
                                    <p className='font-bold text-[22px] leading-[90%]'>
                                        66
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={3}>
                                <div>
                                    <p className='text-appcolorfaintsix text-[14px] leading-[18px] mb-3'>
                                        No. of Pending Applications
                                    </p>
                                    <p className='font-bold text-[22px] leading-[90%]'>
                                        76
                                    </p>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={3}>
                                <div>
                                    <p className='text-appcolorfaintsix text-[14px] leading-[18px] mb-3'>
                                        Amount Requested
                                    </p>
                                    <p className='font-bold text-[22px] leading-[90%]'>
                                        $17,250,000
                                    </p>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className='relative mt-8'>
                        <div className='bg-appcolorwhite rounded-[3px] pb-0 px-4 absolute right-0 top-[-0.5rem]'>
                            <div className=''>
                                <InputBase
                                    fullWidth
                                    placeholder='Search Application'
                                    startAdornment={
                                        <InputAdornment position='start'>
                                            <SearchIcon />
                                        </InputAdornment>
                                    }
                                />
                            </div>
                        </div>

                        <div>
                            <div className='flex items-center justify-between'>
                                <div className='flex justify-between'>
                                    <div
                                        className={`bg-appcolorwhite trapezoid pt-[0.8rem] pb-[0.7rem] pl-[1.6rem] w-fit pr-[5.6rem] cursor-pointer text-[17px] leading-[24px] flex`}
                                    >
                                        <p className='rounded-[3px] shadow-[0px 1px 2px rgba(16, 24, 40, 0.05)] flex justify-center hover:bg-appcolorprimaryfainttwo w-fit px-[0.5rem] py-[0.3rem] items-center mr-4'>
                                            <span className='w-2 h-2 mr-3 bg-appcolorprimary rounded-[50%]'></span>
                                            <span className='text-[14px] leading-[20px] font-bold text-appcolorblackfour'>
                                                Recent
                                            </span>
                                        </p>

                                        <p className='rounded-[3px] shadow-[0px 1px 2px rgba(16, 24, 40, 0.05)] flex justify-center hover:bg-appcolorgreenfaint w-fit px-[0.5rem] py-[0.3rem] items-center mr-4'>
                                            <span className='w-2 h-2 mr-3 bg-appcolorgreen rounded-[50%]'></span>
                                            <span className='text-[14px] leading-[20px] font-bold text-appcolorblackfour'>
                                                Accepted
                                            </span>
                                        </p>

                                        <p className='rounded-[3px]  shadow-[0px 1px 2px rgba(16, 24, 40, 0.05)] flex justify-center hover:bg-appcolorfaintyellow w-fit px-[0.5rem] py-[0.3rem] items-center'>
                                            <span className='w-2 h-2 mr-3 bg-appcoloryellow rounded-[50%]'></span>
                                            <span className='text-[14px] leading-[20px] font-bold text-appcolorblackfour'>
                                                Pending
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className='py-[0.5rem] px-[1.3rem] bg-appcolorwhite'>
                                <Table
                                    // pagination={false}
                                    loading={loading}
                                    columns={columns}
                                    dataSource={dataSource}
                                    rowKey={'id'}
                                    locale={{
                                        emptyText: () => {
                                            return (
                                                <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                                                    <div className='flex justify-center mb-4'>
                                                        <img
                                                            src='/img/vectors/nodatacross.svg'
                                                            alt='nodata'
                                                        />
                                                    </div>
                                                    <p className='text-center'>
                                                        No data found
                                                    </p>
                                                </div>
                                            );
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Outletouterpage>

            {visible ? (
                <Viewdocumentdrawer
                    closeDrawer={closeVisible}
                    showDrawer={visible}
                    updateShowDocument={updateShowDocument}
                />
            ) : null}

            {showDocument ? (
                <div className='bg-appcolorwhite rounded-[3px] absolute left-[20%] h-[40rem] w-[40%] top-[15%] drop-shadow-md z-[5000]'>
                    <img
                        src='/img/vectors/docs.svg'
                        alt='docs'
                        className='w-[100%] h-[100%]'
                    />
                </div>
            ) : null}
        </div>
    );
};

export default Adminfinancingoverview;
