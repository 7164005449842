export const WalletAddIcon = () => (
    <svg
        width='19'
        height='19'
        viewBox='0 0 19 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M4.89254 5.94254C4.71254 5.94254 4.54754 5.86005 4.43504 5.71005C4.30754 5.53755 4.29254 5.31252 4.39004 5.12502C4.51754 4.87002 4.69753 4.62255 4.93003 4.39755L7.36753 1.95254C8.61253 0.715039 10.6375 0.715039 11.8825 1.95254L13.195 3.28756C13.75 3.83506 14.0875 4.57006 14.125 5.35006C14.1325 5.52256 14.065 5.68754 13.9375 5.80004C13.81 5.91254 13.6375 5.96504 13.4725 5.93504C13.3225 5.91254 13.165 5.90505 13 5.90505H5.50004C5.32004 5.90505 5.14754 5.92004 4.97504 5.94254C4.95254 5.94254 4.92254 5.94254 4.89254 5.94254ZM6.14504 4.77255H12.865C12.7675 4.51755 12.61 4.28505 12.4 4.07505L11.08 2.74003C10.2775 1.94503 8.96503 1.94503 8.15503 2.74003L6.14504 4.77255Z'
            fill='#0088B2'
        />
        <path
            d='M4 17.8975C2.755 17.8975 1.585 17.2375 0.955 16.165C0.6175 15.625 0.4375 14.9875 0.4375 14.335C0.4375 12.37 2.035 10.7725 4 10.7725C5.965 10.7725 7.5625 12.37 7.5625 14.335C7.5625 14.9875 7.3825 15.625 7.045 16.1725C6.415 17.2375 5.245 17.8975 4 17.8975ZM4 11.8975C2.6575 11.8975 1.5625 12.9925 1.5625 14.335C1.5625 14.7775 1.6825 15.2124 1.915 15.5874C2.3575 16.3374 3.1375 16.7725 4 16.7725C4.8625 16.7725 5.6425 16.33 6.085 15.595C6.3175 15.2125 6.4375 14.785 6.4375 14.335C6.4375 12.9925 5.3425 11.8975 4 11.8975Z'
            fill='#0088B2'
        />
        <path
            d='M5.11732 14.8823H2.88232C2.57482 14.8823 2.31982 14.6273 2.31982 14.3198C2.31982 14.0123 2.57482 13.7573 2.88232 13.7573H5.12482C5.43232 13.7573 5.68732 14.0123 5.68732 14.3198C5.68732 14.6273 5.43232 14.8823 5.11732 14.8823Z'
            fill='#0088B2'
        />
        <path
            d='M4 16.0301C3.6925 16.0301 3.4375 15.7751 3.4375 15.4676V13.2251C3.4375 12.9176 3.6925 12.6626 4 12.6626C4.3075 12.6626 4.5625 12.9176 4.5625 13.2251V15.4676C4.5625 15.7826 4.3075 16.0301 4 16.0301Z'
            fill='#0088B2'
        />
        <path
            d='M13 17.1475H5.97249C5.73249 17.1475 5.52249 16.9975 5.43999 16.78C5.35749 16.555 5.42499 16.3075 5.60499 16.1575C5.78499 16.0075 5.94999 15.8125 6.06999 15.6025C6.30999 15.22 6.42999 14.785 6.42999 14.3425C6.42999 13 5.33499 11.905 3.99249 11.905C3.29499 11.905 2.62749 12.205 2.16249 12.7375C2.00499 12.91 1.75749 12.9775 1.53999 12.895C1.32249 12.8125 1.17249 12.6025 1.17249 12.37V9.08496C1.17249 6.77496 2.59749 5.10247 4.80999 4.82497C5.01249 4.79497 5.24499 4.77246 5.48499 4.77246H12.985C13.165 4.77246 13.3975 4.77996 13.6375 4.81746C15.85 5.07246 17.2975 6.75246 17.2975 9.08496V12.835C17.3125 15.415 15.58 17.1475 13 17.1475ZM7.13499 16.0225H13C14.935 16.0225 16.1875 14.77 16.1875 12.835V9.08496C16.1875 7.32996 15.16 6.12244 13.495 5.92744C13.315 5.89744 13.1575 5.89746 13 5.89746H5.49999C5.31999 5.89746 5.14749 5.91245 4.97499 5.93495C3.32499 6.14495 2.31249 7.34496 2.31249 9.08496V11.2C2.82249 10.9225 3.40749 10.7725 3.99999 10.7725C5.96499 10.7725 7.56249 12.37 7.56249 14.335C7.56249 14.9275 7.41249 15.5125 7.13499 16.0225Z'
            fill='#0088B2'
        />
        <path
            d='M16.75 13.0225H14.5C13.36 13.0225 12.4375 12.1 12.4375 10.96C12.4375 9.81996 13.36 8.89746 14.5 8.89746H16.75C17.0575 8.89746 17.3125 9.15246 17.3125 9.45996C17.3125 9.76746 17.0575 10.0225 16.75 10.0225H14.5C13.9825 10.0225 13.5625 10.4425 13.5625 10.96C13.5625 11.4775 13.9825 11.8975 14.5 11.8975H16.75C17.0575 11.8975 17.3125 12.1525 17.3125 12.46C17.3125 12.7675 17.0575 13.0225 16.75 13.0225Z'
            fill='#0088B2'
        />
    </svg>
);
