const AttachmentIcon = () => {
    return (
        <svg
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M7.36523 7.3461L5.98175 8.72958C5.21439 9.49693 5.21439 10.7348 5.98175 11.5021C6.7491 12.2695 7.98696 12.2695 8.75431 11.5021L10.9332 9.3233C12.4623 7.79419 12.4623 5.30729 10.9332 3.77818C9.40404 2.24907 6.91714 2.24907 5.38803 3.77818L3.01315 6.15306C1.70249 7.46372 1.70249 9.59215 3.01315 10.9084'
                stroke='#344054'
                strokeWidth='1.47874'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default AttachmentIcon;
