export const CheckBoxIcon = () => (
    <svg
        width='20'
        height='22'
        viewBox='0 0 20 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect x='0.5' y='2.5' width='19' height='19' rx='5.5' fill='white' />
        <rect
            x='0.5'
            y='2.5'
            width='19'
            height='19'
            rx='5.5'
            stroke='#D0D5DD'
        />
    </svg>
);
