import { Box } from '@mui/material';

export const WalletBalancesSkeleton = ({ length }: { length: number }) => {
    return (
        <>
            {Array(length)
                .fill(1)
                .map((_, index) => (
                    <Box
                        key={index}
                        className='animate-pulse flex space-x-4 grow mr-3'
                    >
                        <Box className='rounded-full bg-slate-400 h-10 w-10'></Box>
                        <Box className='grow space-y-6 py-1'>
                            <Box className='space-y-3'>
                                <Box className='grid grid-cols-3 gap-4'>
                                    <Box className='h-2 bg-slate-400 rounded col-span-2'></Box>
                                </Box>
                                <Box className='h-2 bg-slate-400 rounded'></Box>
                                <Box className='h-2 bg-slate-400 rounded'></Box>
                            </Box>
                        </Box>
                    </Box>
                ))}
        </>
    );
};
