import {
    Alert,
    CircularProgress,
    Grid,
    InputAdornment,
    TextField
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import NewCustomSelect from '../../../../components/newcustomselect/newcustomselect';
import {
    jobFunctions,
    jobLevels,
    shipperRole
} from '../../../../utils/appconstants';
import useCountries from '../../../../customhooks/useCountries';
import Custombutton from '../../../../components/custombutton/custombutton';
import { bookingProfile } from '../../../../api/booking/booking';
import { openNotification } from '../../../../utils/helper';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../../store/userSlice/userSlice';
import { FileDoneOutlined } from '@ant-design/icons';
import { useTheme } from '@mui/material/styles';
import {
    getCities,
    getLocationDetails,
    getStates
} from '../../../../api/base/base';
import CustomMapInput from '../../../../components/CustomMapInput';

export interface setupBookingProfileProps {
    corporateId: number;
    user: any;
}

const Setupbookingprofile = ({
    corporateId,
    user
}: setupBookingProfileProps) => {
    const { countries, isCountriesLoading } = useCountries();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [loadingCities, setLoadingCities] = useState(false);
    const [cities, setCities] = useState([]);
    const [loadingState, setLoadingState] = useState(false);
    const [states, setStates] = useState([]);
    const [loadinglocation, setLoadinglocation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | any>(null);
    const [payload, setPayload] = useState({
        name: user?.corporate_name,
        address: undefined,
        cityId: undefined,
        countryId: undefined,
        postCode: undefined,
        phoneNumber: user?.phone_number,
        firstName: user?.first_name,
        lastName: user?.last_name,
        jobLevel: undefined,
        jobFunction: undefined,
        stateId: undefined,
        companyRole: undefined,
        place_id: ''
    });

    const [errors, setErrors] = useState({
        name: '',
        address: '',
        cityId: '',
        countryId: '',
        postCode: '',
        phoneNumber: '',
        firstName: '',
        lastName: '',
        jobLevel: '',
        jobFunction: '',
        stateId: '',
        companyRole: ''
    });

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrors((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));
    };

    const onSubmit = async () => {
        setLoading(true);
        setError(null);

        setErrors((prevState: any) => ({
            ...prevState,
            name: '',
            address: '',
            cityId: '',
            countryId: '',
            postCode: '',
            phoneNumber: '',
            firstName: '',
            lastName: '',
            jobLevel: '',
            jobFunction: '',
            stateId: '',
            companyRole: ''
        }));

        try {
            const res = await bookingProfile(corporateId, payload);

            const { data } = res.data;

            setLoading(false);

            openNotification('success', 'Booking profile created successfully');

            dispatch(
                updateUser({
                    user: {
                        ...user,
                        booking: true
                    }
                })
            );
        } catch (error) {
            setLoading(false);
            if (error?.response) {
                if (error?.response?.data?.error) {
                    const errors = error?.response?.data?.error;
                    Object.entries(errors).forEach(([key, value]) => {
                        setErrors((prevState) => ({
                            ...prevState,
                            [key]: value
                        }));
                    });
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    const getPlaceDetails = async () => {
        try {
            setLoadinglocation(true);

            const res = await getLocationDetails(payload.place_id);

            const { countryId, stateId, cityId, postalCode } = res.data.data;

            setPayload((prevState: any) => ({
                ...prevState,
                cityId,
                stateId,
                countryId,
                postCode: postalCode
            }));

            setLoadinglocation(false);
        } catch (error) {
            setLoadinglocation(false);
        }
    };

    useEffect(() => {
        if (payload.place_id !== '') {
            getPlaceDetails();
        }
    }, [payload.place_id]);

    const fetchStates = async () => {
        try {
            setLoadingState(true);
            const res = await getStates(payload?.countryId || '');
            const { data } = res.data;

            setStates(data);

            setLoadingState(false);
        } catch (error) {
            setLoadingState(false);
        }
    };

    useEffect(() => {
        if (payload.countryId) {
            fetchStates();
        }
    }, [payload.countryId]);

    const fetchCities = async () => {
        try {
            setLoadingCities(true);
            const res = await getCities(payload?.stateId || '');
            const { data } = res.data;

            setCities(data);

            setLoadingCities(false);
        } catch (error) {
            setLoadingCities(false);
        }
    };

    useEffect(() => {
        if (payload.stateId) {
            fetchCities();
        }
    }, [payload.stateId]);

    return (
        <div>
            <div className='mb-8'>
                <Alert
                    icon={
                        <img
                            src='/img/vectors/infosquare.svg'
                            alt='infosquare'
                        />
                    }
                    severity='info'
                    sx={{
                        background: 'rgba(0, 136, 178, 0.06)',
                        borderRadius: '3px',
                        border: '1px solid #0088B2',
                        color: theme.palette.primary.main
                    }}
                >
                    <div className='flex items-center justify-between w-full'>
                        <span>
                            To continue with booking, you need to setup booking
                            profile
                        </span>
                    </div>
                </Alert>
            </div>

            {error !== null && (
                <div className='mt-10 mb-4'>
                    <Alert severity='error'>{error}</Alert>
                </div>
            )}

            <div>
                <div>
                    <div className='flex items-center justify-between'>
                        <div className='flex'>
                            <div
                                className={`bg-appcolorwhite trapezoid pt-[0.8rem] pb-[0.7rem] pl-[1.6rem] w-fit pr-[5.6rem] cursor-pointer text-[17px] leading-[24px] flex items-center`}
                            >
                                <FileDoneOutlined className='text-appcolorprimary' />

                                <span className='pl-2 text-appcolorprimary'>
                                    New Booking Profile
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        className={`py-[0.5rem] px-[1.5rem] bg-appcolorwhite pb-[1.2rem] mb-4 pt-4`}
                    >
                        <div className='pt-3 pb-1 mb-4 border-b border-appcolorgreyfaint opacity-[0.8]'>
                            Your company information
                        </div>

                        <Grid
                            container
                            spacing={2}
                            sx={{
                                marginTop: '0px'
                            }}
                            // alignItems="center"
                        >
                            <Grid item xs={12} md={12} lg={3}>
                                <TextField
                                    autoComplete='off'
                                    value={payload?.name}
                                    error={errors?.name.length > 0}
                                    helperText={
                                        errors?.name.length > 0
                                            ? errors?.name
                                            : undefined
                                    }
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='createbooking-name'
                                    label='Company Name'
                                    name='name'
                                    onChange={(e: any) => {
                                        updateField('name', e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={3}>
                                <div className='mt-4 '>
                                    <NewCustomSelect
                                        required
                                        selectOptions={shipperRole}
                                        name={'companyRole'}
                                        updateField={(_, value) => {
                                            updateField('companyRole', value);
                                        }}
                                        label={'Company Role'}
                                        optionHasValue={true}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <div className='mt-[1rem]'>
                                    <CustomMapInput
                                        name='address'
                                        updateField={(_, value) => {
                                            updateField('address', value);
                                        }}
                                        label='Street Address'
                                        updatePlaceIDField={(_, value) => {
                                            updateField('place_id', value);
                                        }}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={3}>
                                <div className='mt-4'>
                                    <NewCustomSelect
                                        selectValue={payload?.countryId}
                                        required
                                        selectOptions={
                                            countries as unknown as Record<
                                                string,
                                                unknown
                                            >[]
                                        }
                                        name={'countryId'}
                                        updateField={(_, value) => {
                                            updateField('countryId', value);
                                        }}
                                        label={'Country'}
                                        selectOptionLabel='name'
                                        loading={
                                            isCountriesLoading ||
                                            loadinglocation
                                        }
                                        // alternativeValue='name'
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={3}>
                                <div className='mt-4'>
                                    <NewCustomSelect
                                        selectValue={payload?.stateId}
                                        required
                                        selectOptions={states}
                                        name={'stateId'}
                                        updateField={(_, value) => {
                                            updateField('stateId', value);
                                        }}
                                        label={'State'}
                                        selectOptionLabel='name'
                                        loading={
                                            loadingState || loadinglocation
                                        }
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={3}>
                                <div className='mt-4'>
                                    <NewCustomSelect
                                        selectValue={payload?.cityId}
                                        required
                                        selectOptions={cities}
                                        name={'cityId'}
                                        updateField={(_, value) => {
                                            updateField('cityId', value);
                                        }}
                                        label={'City'}
                                        selectOptionLabel='name'
                                        loading={
                                            loadingCities || loadinglocation
                                        }
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={3}>
                                <TextField
                                    value={payload?.postCode}
                                    error={errors?.postCode.length > 0}
                                    helperText={
                                        errors?.postCode.length > 0
                                            ? errors?.postCode
                                            : undefined
                                    }
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='createbooking-postCode'
                                    label='Postal Code'
                                    name='postCode'
                                    onChange={(e: any) => {
                                        updateField('postCode', e.target.value);
                                    }}
                                    InputLabelProps={{
                                        shrink: payload?.postCode
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                {loadinglocation ? (
                                                    <CircularProgress
                                                        size={20}
                                                    />
                                                ) : null}
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <div className='mt-6 pt-3 pb-1 mb-4 border-b border-appcolorgreyfaint opacity-[0.8]'>
                            Personal details
                        </div>

                        <Grid
                            container
                            spacing={2}
                            sx={{
                                marginTop: '0px'
                            }}
                            alignItems='center'
                        >
                            <Grid item xs={12} md={12} lg={3}>
                                <TextField
                                    value={payload?.firstName}
                                    error={errors?.firstName.length > 0}
                                    helperText={
                                        errors?.firstName.length > 0
                                            ? errors?.firstName
                                            : undefined
                                    }
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='createbooking-firstName'
                                    label='Firstname'
                                    name='firstName'
                                    onChange={(e: any) => {
                                        updateField(
                                            'firstName',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={3}>
                                <TextField
                                    value={payload?.lastName}
                                    error={errors?.lastName.length > 0}
                                    helperText={
                                        errors?.lastName.length > 0
                                            ? errors?.lastName
                                            : undefined
                                    }
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='createbooking-lastName'
                                    label='Last Name'
                                    name='lastName'
                                    onChange={(e: any) => {
                                        updateField('lastName', e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={3}>
                                <TextField
                                    value={payload?.phoneNumber}
                                    error={errors?.phoneNumber.length > 0}
                                    helperText={
                                        errors?.phoneNumber.length > 0
                                            ? errors?.phoneNumber
                                            : undefined
                                    }
                                    margin='normal'
                                    required
                                    fullWidth
                                    id='createnewcarrier-phoneNumber'
                                    label='Phone Number'
                                    name='phoneNumber'
                                    onChange={(e: any) => {
                                        updateField(
                                            'phoneNumber',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={3}>
                                <div className='mt-[0.4rem]'>
                                    <NewCustomSelect
                                        required
                                        selectOptions={jobLevels}
                                        name={'jobLevel'}
                                        updateField={(_, value) => {
                                            updateField('jobLevel', value);
                                        }}
                                        label={'Job Level'}
                                        optionHasValue={true}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={3}>
                                <div className='mt-4'>
                                    <NewCustomSelect
                                        required
                                        selectOptions={jobFunctions}
                                        name={'jobFunction'}
                                        updateField={(_, value) => {
                                            updateField('jobFunction', value);
                                        }}
                                        label={'Job Function'}
                                        optionHasValue={true}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <div className='flex justify-end pt-4 mt-8 border-t border-appcolorgreyfaint'>
                            <div className='w-[23%]'>
                                <Custombutton
                                    isLoadingButton
                                    loading={loading}
                                    // type="submit"
                                    fullWidth
                                    variant='contained'
                                    styles={{
                                        padding:
                                            '0.75rem 0.62rem 0.9rem 0.93rem'
                                    }}
                                    disabled={
                                        Object.values(payload).some(
                                            (item: any) => item === undefined
                                        ) || loading
                                    }
                                    buttonText='Submit'
                                    onClickAction={() => {
                                        onSubmit();
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Setupbookingprofile;
