import { useQueryClient } from 'react-query';
import { Invoice } from '../pages/dashboard/payments/interfaces/Invoice';
import { useGetQuery } from './useGetQuery';

interface GetInvoicesParams {
    corporateId?: number;
    params?: Record<string, string | number | boolean | undefined>;
    enabled?:boolean
}

export const useInvoices = <T = Invoice>(opts: GetInvoicesParams) => {
    const queryClient = useQueryClient();

    const { corporateId, params, enabled = true  } = opts;
    const { isLoading, entries, isFetching, paging } = useGetQuery<T[]>({
        url: `/invoice/${corporateId}`,
        cacheKey: 'invoices',
        params: { ...params },
        enabled
    });

    const invalidateLogisticsInvoice = () => {
        queryClient.invalidateQueries('invoices');
    };

    return {
        isLoading,
        entries,
        isFetching,
        paging,
        invalidateLogisticsInvoice
    };
};
