import { Box } from '@mui/material';
import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import { MoreIcon } from '../../../icons/MoreIcon';
import { Dropdown } from 'antd';
import { RefreshTableIcon } from '../../../icons/RefreshTableIcon';
import { formatNumber, getContractStatus } from '../../../../utils/helper';
import dayjs from 'dayjs';
import {
    SalesContractParams,
    SalesContracts
} from '../../../../pages/dashboard/payments/interfaces/SalesContracts';
import FilterContract from './FilterContract';
import useCorporatePartners from '../../../../customhooks/useCorporatePartners';
import useCommodities from '../../../../customhooks/useCommodities';
import { download } from '../../../customIcons';
import { getInvoiceContractStatusIcon } from '../../../icons/InvoiceStatusIcons';
import Customtable from '../../../customtable/customtable';
import MobilePagination from '../../../MobilePagination';

const MobileCard = ({
    item,
    setCurrentData,
    actions
}: {
    item?: SalesContracts;
    setCurrentData: (data: SalesContracts) => void;
    actions: (
        data: SalesContracts
    ) => { key: number; label: false | JSX.Element }[];
}) => {
    return (
        <Box key={item?.id} borderBottom='1px solid#EAECF0' py={3} mx={2}>
            <Box width='full' justifyContent='space-between' display='flex'>
                <Box display='flex' gap={1}>
                    {getInvoiceContractStatusIcon(
                        getContractStatus(Number(item?.status)).toUpperCase()
                    )}
                    <Box>
                        <h4 className=' text-ebony text-[14px] font-semibold'>
                            {item?.buyerName}
                        </h4>
                        <p className=' text-[12px] text-paleSky'>
                            Total Quantity:
                            <span className=' pl-1 pr-3 font-extrabold'>
                                {formatNumber(item?.quantity || 0)}{' '}
                                {item?.metric}
                            </span>
                            Contract:
                            <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                {item?.salesContractNumber}
                            </span>
                        </p>
                        <p className=' text-[12px] text-paleSky'>
                            Commodity:
                            <span className=' pl-1 pr-3 font-extrabold'>
                                {item?.name}
                            </span>
                            Status:
                            <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                {getContractStatus(item?.status || 0)}
                            </span>
                        </p>
                        <p className=' text-[12px] text-paleSky'>
                            Shipping Deadline:
                            <span className=' pl-1 pr-3 font-extrabold'>
                                {dayjs(item?.shippingDeadline).format(
                                    'DD MMM, YYYY'
                                )}
                            </span>
                            P Qty:
                            <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                {formatNumber(item?.pendingQuantity ?? 0)}
                            </span>
                        </p>
                    </Box>
                </Box>

                <div
                    className='cursor-pointer'
                    onClick={() => {
                        item && setCurrentData(item);
                    }}
                >
                    <Dropdown
                        menu={{ items: item ? actions(item) : [] }}
                        trigger={['click']}
                        placement='bottomRight'
                    >
                        <Box className=' flex flex-row justify-center'>
                            <MoreIcon />
                        </Box>
                    </Dropdown>
                </div>
            </Box>
        </Box>
    );
};

const ContractsTableMobileCards = ({
    loading,
    contracts,
    emptyView,
    fetchContracts,
    filterOptions,
    setFilterOptions,
    setViewExport,
    setCurrentData,
    items,
    paging
}: {
    loading: boolean;
    contracts: SalesContracts[];
    emptyView: ReactNode;
    fetchContracts: () => void;
    filterOptions: SalesContractParams;
    setFilterOptions: Dispatch<SetStateAction<SalesContractParams>>;
    setViewExport: Dispatch<SetStateAction<boolean>>;
    setCurrentData: Dispatch<SetStateAction<SalesContracts>>;
    paging: { page: number; total: number };
    items: (
        data: SalesContracts
    ) => { key: number; label: false | JSX.Element }[];
}) => {
    const { commoditiesList } = useCommodities();
    const { corporatePartners } = useCorporatePartners({ page: 1 });

    const getContractMenuOptions = () =>
        [
            <FilterContract
                {...{
                    setFilterOptions,
                    filterOptions,
                    fetchContracts,
                    commoditiesList,
                    corporatePartners
                }}
            />,
            <div
                className='tab-buttons flex'
                onClick={() => setViewExport(true)}
            >
                {download}
                <p style={{ paddingLeft: '5px' }}>Download</p>
            </div>,
            <div className='tab-buttons flex' onClick={fetchContracts}>
                <div className='pr-2'>
                    <RefreshTableIcon />
                </div>
                <p style={{ paddingRight: '5px' }}>Refresh</p>
            </div>
        ].map((comp, key) => ({
            key,
            label: comp
        }));

    return (
        <>
            <Box
                display='flex'
                width='100%'
                justifyContent='space-between'
                alignItems='center'
                bgcolor='white'
                px={2}
                py={2}
                borderBottom='1px solid #EAECF0'
            >
                <div className='flex gap-2 items-center'>
                    <p className=' text-ebony text-[16px] font-medium'>
                        Contracts
                    </p>
                </div>

                <Dropdown
                    menu={{
                        items: getContractMenuOptions()
                    }}
                    trigger={['click']}
                >
                    <Box className=' flex flex-row justify-center'>
                        <MoreIcon />
                    </Box>
                </Dropdown>
            </Box>
            <div className='lg:hidden mt-4'>
                <Customtable
                    data={contracts}
                    loadingData={loading}
                    emptyText=' You have no Contracts.'
                    customMobilePagination={
                        <MobilePagination
                            page={paging.page === 0 ? 1 : paging.page}
                            setPage={(page) => {
                                setFilterOptions((props) => ({
                                    ...props,
                                    page
                                }));
                            }}
                            totalPages={Math.ceil(paging.total / 10)}
                        />
                    }
                    mobileComponent={
                        <MobileCard
                            setCurrentData={setCurrentData}
                            actions={items}
                        />
                    }
                />
            </div>
        </>
    );
};

export default ContractsTableMobileCards;
