import { BookingForm } from './interfaces/BookingForm';
import { CommentForm } from './CommentForm';
interface CommentFormSectionProps {
    formData: BookingForm;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
}

export const CommentFormSection = (props: CommentFormSectionProps) => {
    const { formData, setFormData } = props;
    return (
        <>
            <div className='flex justify-between items-center pt-4 pb-[4px] border-b border-dashed lg:border-solid border-gray300 lg:border-appcolorgreyfaint lg:mt-8'>
                <p className='text-steelBlue'>Comments & Notifications</p>
            </div>

            <CommentForm
                {...{
                    formData,
                    setFormData
                }}
            />
        </>
    );
};
