import { OffshoreAccount } from '../pages/dashboard/payments/dedicatedAccounts/interfaces/OffshoreAccount';
import { useGetQuery } from './useGetQuery';

export const useCorporateOffshoreDetails = (corporateId?: string) => {
    const { isLoading, entries, isFetching } = useGetQuery<OffshoreAccount[]>({
        url: `/administrator/corporates/${corporateId}/offshore/accounts`,
        cacheKey: `wallet-details-${corporateId}`
    });

    return {
        isLoading,
        entries,
        isFetching
    };
};
