import { Dispatch, SetStateAction, useState } from 'react';
import { TextField } from '@mui/material';
import { Button } from 'antd';
import * as Yup from 'yup';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import CustomModal from '../../../../components/CustomModal';
import { InviteIcon } from '../../../../components/icons/InviteIcon';
import { UpdateContainer } from '../../../../components/dashboard/sales/UpdateStatus';
import { mutateConfig } from '../../../../api/queryBase';
import RolesDropdown from '../../../../components/RolesDropdown';
import { getErrorMessage } from '../../../../utils/helper';
import { SuitUIButton } from '../../../../components/SuitUIButton';

interface InviteValues {
    firstName: string;
    lastName: string;
    email: string;
    role: string;
}

const InviteUserForm = ({
    viewInviteForm,
    setViewInviteForm,
    refetchUsers
}: {
    viewInviteForm: boolean;
    setViewInviteForm: Dispatch<SetStateAction<boolean>>;
    refetchUsers: () => void;
}) => {
    const closeModal = () => {
        setViewInviteForm(false);
    };

    const initialValues: InviteValues = {
        firstName: '',
        lastName: '',
        email: '',
        role: ''
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        email: Yup.string()
            .email('Invalid Email address')
            .required('Email is required'),
        role: Yup.string().required('Role is required')
    });

    const mutation = mutateConfig();
    const { mutate } = mutation({
        url: `/users/invite`
    });

    const [submitting, setSubmitting] = useState(false);

    const handleSubmit = async (
        values: InviteValues,
        { resetForm }: FormikHelpers<InviteValues>
    ) => {
        setSubmitting(true);
        mutate(values, {
            onSettled: (_, error: AxiosError | undefined | any) => {
                setSubmitting(false);
                if (error) {
                    toast.error(
                        getErrorMessage(error) ||
                            'An error has occured. Please try again.'
                    );
                } else {
                    resetForm();
                    closeModal();
                    refetchUsers();
                }
            }
        });
    };

    return (
        <CustomModal
            maxWidth='md'
            open={viewInviteForm}
            onClose={closeModal}
            title=''
            hideCloseIcon
        >
            <UpdateContainer className='px-4 lg:w-[400px] mx-auto py-4 max-w-[400px] w-full'>
                <InviteIcon />
                <h2 className='pt-6 mb-2 text-[18px] text-ebony'>
                    Invite New Users
                </h2>
                <h5 className=' leading-7 text-[18px] text-paleSky mb-[24px]'>
                    Enter the email addresses of the users you'd like to invite,
                    and choose the role they should have.
                </h5>

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={handleSubmit}
                >
                    {({ errors, isValid, values, setFieldValue, touched }) => (
                        <Form autoComplete='off' noValidate>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '12px'
                                }}
                            >
                                <div className=''>
                                    <h5 className='lbl'>First Name</h5>

                                    <Field
                                        as={TextField}
                                        autoFocus
                                        type='text'
                                        error={
                                            touched?.firstName &&
                                            errors?.firstName
                                        }
                                        helperText={
                                            touched?.firstName &&
                                            errors?.firstName
                                        }
                                        required
                                        fullWidth
                                        id='firstName'
                                        name='firstName'
                                    />
                                </div>

                                <div className=''>
                                    <h5 className='lbl'>Last Name</h5>
                                    <Field
                                        as={TextField}
                                        type='text'
                                        error={
                                            touched?.lastName &&
                                            errors?.lastName
                                        }
                                        helperText={
                                            touched?.lastName &&
                                            errors?.lastName
                                        }
                                        required
                                        fullWidth
                                        id='lastName'
                                        name='lastName'
                                    />
                                </div>

                                <div className=''>
                                    <h5 className='lbl'>Enter Email</h5>

                                    <Field
                                        as={TextField}
                                        type='email'
                                        placeholder='you@untitledui.com'
                                        error={touched?.email && errors?.email}
                                        helperText={
                                            touched?.email && errors?.email
                                        }
                                        required
                                        fullWidth
                                        id='email'
                                        name='email'
                                    />
                                </div>

                                <div className=''>
                                    <h5 className='lbl'>Select a role</h5>
                                    <RolesDropdown
                                        initialValue={values.role}
                                        error={touched?.role && !!errors?.role}
                                        errorMessage={
                                            touched?.role
                                                ? errors?.role
                                                : undefined
                                        }
                                        onHandleChange={({
                                            value
                                        }: {
                                            value: string;
                                        }) => {
                                            setFieldValue('role', value);
                                        }}
                                        {...{
                                            errors,
                                            values
                                        }}
                                    />
                                </div>
                            </div>

                            <div className='cta-buttons'>
                                <Button className='btn-1' onClick={closeModal}>
                                    Cancel
                                </Button>
                                <SuitUIButton
                                    text='Invite'
                                    type='submit'
                                    isLoading={submitting}
                                    fullWidth
                                    disabled={
                                        Object.values(values).some(
                                            (item) => item === ''
                                        ) ||
                                        !isValid ||
                                        submitting
                                    }
                                />
                            </div>
                        </Form>
                    )}
                </Formik>
            </UpdateContainer>
        </CustomModal>
    );
};

export default InviteUserForm;
