import { useMediaQuery, useTheme } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import React from 'react';
import { Mapping, ShippingPath } from '../mapping/mapping';

export interface trackshipmentdrawerProps {
    closeDrawer: () => void;
    showDrawer: boolean;
    paths: ShippingPath[];
}

const paths = [
    {
        id: 1,
        efShipmentId: 12,
        longitude: 3.356251903616731,
        latitude: 6.434344148869343,
        shipmentDate: '03-20-2023',
        name: '🇳🇬 Apapa'
    },
    {
        id: 1,
        efShipmentId: 12,
        longitude: -74.062882,
        latitude: 40.599435,
        shipmentDate: '03-20-2023',
        name: '🇺🇸 New York'
    },
    {
        id: 1,
        efShipmentId: 12,
        longitude: 23.81161,
        latitude: -32.632603,
        shipmentDate: '03-20-2023',
        name: '🇿🇦 Durban'
    }
];

const Trackshipmentdrawer = ({
    closeDrawer,
    showDrawer,
    paths
}: trackshipmentdrawerProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <div>
            <div
                className='slide-in-right active bg-appcolorwhite rounded-[50%] top-[1.2rem] right-[28.4rem] fixed p-4 z-[2000] cursor-pointer'
                onClick={() => {
                    closeDrawer();
                }}
            >
                <img src='/img/vectors/cross.svg' alt='cross' />
            </div>

            <Drawer
                anchor={isMobile ? 'bottom' : 'right'}
                open={showDrawer}
                onClose={() => {
                    closeDrawer();
                }}
                sx={{
                    '.MuiDrawer-paperAnchorRight': {
                        width: {
                            xs: '95%',
                            lg: '30rem'
                        }
                    }
                }}
            >
                <div>
                    <Mapping paths={paths} isDrawer />
                </div>
            </Drawer>
        </div>
    );
};

export default Trackshipmentdrawer;
