import React, { useCallback, useEffect, useRef, useState } from 'react';
import useCorporatePartners from '../../../../customhooks/useCorporatePartners';
import useCountries from '../../../../customhooks/useCountries';
import { nanoid } from '@reduxjs/toolkit';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    createParkingList,
    getSingleDocuments
} from '../../../../api/documents/documents';
import { openNotification } from '../../../../utils/helper';
import {
    TradeContainer,
    TradeContent
} from '../../../../styles/TradeDocStyles';
import FormHeader from '../../../../components/dashboard/tradeDocuments/common/FormHeader';
import PackingListForm from '../../../../components/dashboard/tradeDocuments/formComponents/PackingListForm';
import Addpartnerdrawer from '../addpartnerdrawer/addpartnerdrawer';
import CustomModal from '../../../../components/CustomModal';
import Savetradedocument from '../savetradedocument/savetradedocument';
import FormPreviewTemplate2 from '../../../../components/dashboard/tradeDocuments/previewComponents/FormPreviewTemplate2';
import { Backdrop } from '@mui/material';
import {
    PackingError,
    PackingPayload,
    PackingQuoteFiled
} from '../tradeDocumentTypes/parkinglistTypes';

const PackingList = () => {
    const { countries, isCountriesLoading } = useCountries();
    const navigate = useNavigate();
    const location = useLocation();
    const [params, setParams] = useState<any>();
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState<any>(null);

    const { corporatePartners, isLoading } = useCorporatePartners({ page: 1 });
    const pageTopRef = useRef<any>(null);
    const myDiv = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const param: any = Object.fromEntries(searchParams.entries());
        setParams(param);
    }, [location.search]);

    const fetchDocuments = useCallback(async () => {
        setLoadingData(true);
        try {
            const res = await getSingleDocuments({
                corporateDocumentId: params?.edit
            });

            const { data } = res.data;
            setData(data);

            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            openNotification('error', `Documents, Something went wrong`);
        }
    }, [params?.edit]);

    useEffect(() => {
        if (params?.edit) {
            fetchDocuments();
        }
    }, [params, fetchDocuments]);

    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
    const [signatureUrlBase64, setSignatureUrlBase64] = useState<string>('');
    const [saveOnly, setSaveOnly] = useState<boolean>(true);
    const [fileId, setFileId] = useState<string | number | unknown>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | any>(null);
    const [payload, setPayload] = useState<PackingPayload>({
        countryOfFinalDestinationName: '',
        countryOfOriginName: '',
        logoUrl: '',
        placeOfIssue: undefined,
        portOfDischargeId: undefined,
        portOfLoadingId: undefined,
        reference: undefined,
        signatoryCompany: undefined,
        signatureUrl: undefined,
        transport: undefined,
        typeOfShipment: undefined,
        voyageNumber: undefined,
        additionalInformation: '',
        billOfLadingNumber: undefined,
        buyerId: '',
        buyerReference: undefined,
        consigneeId: undefined,
        countryOfFinalDestinationId: undefined,
        countryOfOriginId: undefined,
        dateOfDeparture: Date.now(),
        exportInvoiceNumber: nanoid(9),
        exporterId: undefined,
        finalDestination: undefined,
        issuedDate: Date.now(),
        methodOfDispatch: undefined,
        nameOfAuthorisedSignatory: undefined,
        documentName: '',
        portOfLoadingValue: undefined,
        portOfDischargeValue: undefined,
        documentUrl: '',
        buyerAddress: ''
    });
    const [errors, setErrors] = useState<PackingError>({
        countryOfFinalDestinationName: '',
        countryOfOriginName: '',
        placeOfIssue: '',
        portOfDischargeId: '',
        portOfLoadingId: '',
        reference: '',
        signatoryCompany: '',
        signatureUrl: '',
        transport: '',
        typeOfShipment: '',
        voyageNumber: '',
        additionalInformation: '',
        billOfLadingNumber: '',
        buyerId: '',
        buyerReference: '',
        consigneeId: '',
        countryOfFinalDestinationId: '',
        countryOfOriginId: '',
        dateOfDeparture: '',
        exportInvoiceNumber: '',
        exporterId: '',
        finalDestination: '',
        issuedDate: '',
        methodOfDispatch: '',
        nameOfAuthorisedSignatory: '',
        documentName: '',
        methodOfPayment: '',
        placeOfOrigin: '',
        marineCover: '',
        letterOfCredit: '',
        documentUrl: '',
        buyerAddress: '',
        logoUrl: ''
    });

    const [totalUnitQuantity, setTotalUnitQuantity] = useState<number>(0);
    const [totalNetWeight, setTotalNetWeight] = useState<number>(0);
    const [totalGrossWeight, setTotalGrossWeight] = useState<number>(0);
    const [totalVolume, setTotalVolume] = useState<number>(0);

    const [quoteFields, setQuoteFields] = useState<PackingQuoteFiled[]>([
        {
            id: nanoid(9),
            productCode: nanoid(9),
            desc: undefined,
            qty: 0,
            kindOfPackage: undefined,
            qtyOfPackage: undefined,
            weightOfPackageKG: undefined,
            grossWeightOfPackageKG: undefined,
            measurementOfPackage: undefined
        }
    ]);

    useEffect(() => {
        if (data) {
            setFileId(data?.id);
            setPayload({
                countryOfFinalDestinationName:
                    data?.documentData?.countryOfFinalDestinationName || '',
                countryOfOriginName:
                    data?.documentData?.countryOfOrigin?.name || '',
                logoUrl: data?.documentData?.logoUrl || '',
                placeOfIssue: data?.documentData?.placeOfIssue || '',
                portOfDischargeId:
                    data?.documentData?.portOfDischarge.name || '',
                portOfDischargeValue: data?.documentData?.portOfDischarge || '',
                portOfLoadingId: data?.documentData?.portOfLoading.name || '',
                portOfLoadingValue: data?.documentData?.portOfLoading || '',
                reference: data?.documentData?.reference || '',
                signatureUrl: data?.documentData?.signatureUrl || '',
                transport: data?.documentData?.transport || '',
                typeOfShipment: data?.documentData?.typeOfShipment || '',
                buyerReference: data?.documentData?.buyerReference || '',
                voyageNumber: data?.documentData?.voyageNumber || '',
                additionalInformation:
                    data?.documentData?.additionalInformation || '',
                billOfLadingNumber:
                    data?.documentData?.billOfLadingNumber || '',
                buyerId: data?.documentData?.buyerId || '',
                consigneeId: data?.documentData?.consigneeId || '',
                consigneeName: data?.documentData?.consignee.name || '',
                consigneeAddress: data?.documentData?.consignee.address || '',
                countryOfFinalDestinationId:
                    data?.documentData?.countryOfFinalDestinationId || '',
                countryOfOrigin: data?.documentData?.countryOfOrigin || '',
                countryOfOriginId: data?.documentData?.countryOfOriginId || '',
                dateOfDeparture:
                    data?.documentData?.dateOfDeparture || Date.now(),
                exportInvoiceNumber:
                    data?.documentData?.exportInvoiceNumber || nanoid(9),
                exporterId: data?.documentData?.exporterId || '',
                exporterName: data?.documentData?.exporter.name || '',
                exporterAddress: data?.documentData?.exporter.address || '',
                finalDestination: data?.documentData?.finalDestination || '',
                issuedDate: data?.documentData?.issuedDate || Date.now(),
                methodOfDispatch: data?.documentData?.methodOfDispatch || '',
                documentName: data?.documentName || '',
                buyerAddress: data?.documentData?.buyer.address || '',
                buyer: data?.documentData?.buyer.name || '',
                signatoryCompany: data?.documentData?.signatoryCompany || '',
                nameOfAuthorisedSignatory:
                    data?.documentData?.nameOfAuthorisedSignatory || ''
            });

            const transformArray = (array: any) => {
                const newArray: any = [];
                array.forEach((val: any) => {
                    newArray.push({
                        id: val.id,
                        productCode: val.itemMarker,
                        desc: val.descriptionOfGoods,
                        qty: val.unitQuantity,
                        kindOfPackage: val.kindAndNumber,
                        qtyOfPackage: val.kindQuantity,
                        weightOfPackageKG: val.netWeight,
                        grossWeightOfPackageKG: val.grossWeight,
                        measurementOfPackage: val.volume
                    });
                });
                return newArray;
            };
            setQuoteFields(transformArray(data?.documentData?.packing));
            setTotalGrossWeight(data?.documentData?.totalGrossWeight);
            setTotalUnitQuantity(data?.documentData?.totalUnitQuantity);
            setTotalNetWeight(data?.documentData?.totalNetWeight);
            setTotalVolume(data?.documentData?.totalVolume);
        }
    }, [data]);

    const [showSaveModal, setShowSaveModal] = useState(false);

    const [showAddPartner, setShowAddPartner] = useState(false);
    const [partnerId, setPartnerId] = useState();

    const closeShowAddParty = () => {
        setShowAddPartner(false);
    };

    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const updateQuoteField = (index: number, name: string, value: any) => {
        const newContainers: any[] = [...quoteFields];
        newContainers[index][name] = value;
        setQuoteFields(newContainers);
    };

    const addQuoteField = () => {
        setQuoteFields([
            ...quoteFields,
            {
                id: nanoid(9),
                productCode: nanoid(9),
                desc: undefined,
                qty: 0,
                kindOfPackage: undefined,
                qtyOfPackage: undefined,
                weightOfPackageKG: undefined,
                grossWeightOfPackageKG: undefined,
                measurementOfPackage: undefined
            }
        ]);
    };

    const removeQuoteField = (index: number) => {
        setQuoteFields(quoteFields.filter((item, i) => i !== index));
    };

    const onSubmit = async () => {
        setLoading(true);
        setError(null);

        setErrors((prevState: any) => ({
            ...prevState,
            countryOfFinalDestinationName: '',
            countryOfOriginName: '',
            placeOfIssue: '',
            portOfDischargeId: '',
            portOfLoadingId: '',
            reference: '',
            signatoryCompany: '',
            signatureUrl: '',
            transport: '',
            typeOfShipment: '',
            voyageNumber: '',
            additionalInformation: '',
            billOfLadingNumber: '',
            buyerId: '',
            buyerReference: '',
            consigneeId: '',
            countryOfFinalDestinationId: '',
            countryOfOriginId: '',
            dateOfDeparture: '',
            exportInvoiceNumber: '',
            exporterId: '',
            finalDestination: '',
            issuedDate: '',
            methodOfDispatch: '',
            nameOfAuthorisedSignatory: '',
            documentName: '',
            buyerAddress: '',
            logoUrl: ''
        }));

        try {
            const items: any[] = [];

            const {
                documentName,
                placeOfIssue,
                reference,
                signatoryCompany,
                signatureUrl,
                transport,
                typeOfShipment,
                voyageNumber,
                additionalInformation,
                billOfLadingNumber,
                buyerId,
                buyerReference,
                consigneeId,
                countryOfFinalDestinationId,
                countryOfOriginId,
                dateOfDeparture,
                exportInvoiceNumber,
                exporterId,
                finalDestination,
                issuedDate,
                methodOfDispatch,
                nameOfAuthorisedSignatory,
                portOfDischargeValue,
                portOfLoadingValue,
                logoUrl
            } = payload;

            const dischargeValue: any = portOfDischargeValue;
            const portValue: any = portOfLoadingValue;

            quoteFields.forEach((item: any) => {
                items.push({
                    itemMarker: item?.productCode,
                    chargeable: item?.chargeable,
                    descriptionOfGoods: item?.desc,
                    kindAndNumber: item?.kindOfPackage,
                    unitQuantity: item?.qty,
                    kindQuantity: item?.qtyOfPackage,
                    grossWeight: item?.grossWeightOfPackageKG,
                    netWeight: item?.weightOfPackageKG,
                    volume: item?.measurementOfPackage
                });
            });

            const res = await createParkingList({
                createdType: 'CREATED',
                documentData: {
                    packing: items,
                    placeOfIssue,
                    portOfDischargeId: dischargeValue?.id,
                    portOfLoadingId: portValue?.id,
                    reference,
                    signatoryCompany,
                    signatureUrl,
                    totalVolume: totalVolume,
                    transport,
                    typeOfShipment,
                    voyageNumber,
                    additionalInformation,
                    billOfLadingNumber,
                    buyerId: buyerId === '' ? null : buyerId,
                    buyerReference,
                    consigneeId,
                    countryOfFinalDestinationId,
                    countryOfOriginId,
                    dateOfDeparture,
                    exportInvoiceNumber,
                    exporterId,
                    finalDestination,
                    issuedDate,
                    exportInvoiceDate: issuedDate,
                    logoUrl: logoUrl !== '' ? logoUrl : undefined,
                    methodOfDispatch,
                    nameOfAuthorisedSignatory,
                    totalGrossWeight,
                    totalUnitQuantity,
                    totalNetWeight
                },
                documentType: 'PACKING_LIST',
                documentName,
                id: fileId
            });

            const { id } = res.data.data;

            setFileId(id);

            openNotification('success', 'Parking List created successfully');

            setShowSaveModal(false);
            setLoading(false);

            if (saveOnly) {
                navigate('/trade-documents');
            }
        } catch (error) {
            window.scroll({
                top: pageTopRef?.current?.offsetTop,
                left: 0,
                behavior: 'smooth'
            });

            setShowSaveModal(false);
            setLoading(false);
            const otherErrors: any[] = [];

            if (error?.response) {
                if (error?.response?.data?.error) {
                    const resError = error?.response?.data?.error;

                    if (typeof resError === 'string') {
                        const errorList = (
                            <li key={`error-list-${1}`}>{resError}</li>
                        );
                        setError(errorList);
                    } else {
                        Object.entries(resError).forEach(([key, value]) => {
                            if (Object.keys(errors).includes(key)) {
                                setErrors((prevState: any) => ({
                                    ...prevState,
                                    [key]: value
                                }));
                            } else {
                                otherErrors.push(value);
                            }
                        });

                        if (otherErrors.length > 0) {
                            const errorList = otherErrors.map(
                                (element: any, index) => {
                                    return (
                                        <li key={`error-list-${index}`}>
                                            {element}
                                        </li>
                                    );
                                }
                            );
                            setError(errorList);
                        }
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    const closeSaveModal = () => {
        setShowSaveModal(false);
    };

    useEffect(() => {
        let totalUnitQuantity = 0;
        let totalNetWeight = 0;
        let totalGrossWeight = 0;
        let totalMeasurement = 0;

        quoteFields.forEach((item: any) => {
            totalUnitQuantity += isNaN(item?.qty) ? 0 : Number(item?.qty);

            totalNetWeight +=
                isNaN(item?.weightOfPackageKG) ||
                isNaN(item?.qtyOfPackage) ||
                isNaN(item?.qty)
                    ? 0
                    : Number(item?.weightOfPackageKG) *
                      Number(item?.qtyOfPackage) *
                      Number(item?.qty);

            totalGrossWeight +=
                isNaN(item?.grossWeightOfPackageKG) ||
                isNaN(item?.qtyOfPackage) ||
                isNaN(item?.qty)
                    ? 0
                    : Number(item?.grossWeightOfPackageKG) *
                      Number(item?.qtyOfPackage) *
                      Number(item?.qty);

            totalMeasurement +=
                isNaN(item?.measurementOfPackage) ||
                isNaN(item?.qtyOfPackage) ||
                isNaN(item?.qty)
                    ? 0
                    : Number(item?.measurementOfPackage) *
                      Number(item?.qtyOfPackage) *
                      Number(item?.qty);
        });

        setTotalUnitQuantity(totalUnitQuantity);
        setTotalNetWeight(totalNetWeight);
        setTotalGrossWeight(totalGrossWeight);
        setTotalVolume(totalMeasurement);
    }, [quoteFields]);

    const breadcrumbs = [
        {
            title: 'Home',
            link: '/',
            active: false
        },
        {
            title: 'Documents',
            link: '/trade-documents',
            active: false
        },
        {
            title: 'Packing List',
            link: '/trade-documents/packing-list',
            active: true
        }
    ];

    return (
        <TradeContainer>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loadingData}
            >
                <img
                    src='/img/vectors/loadingcircles.svg'
                    alt='loading'
                    className='animate-spin'
                />
            </Backdrop>
            <FormHeader
                breadcrumbs={breadcrumbs}
                docTitle='Packing List'
                {...{
                    showPreview,
                    setShowPreview,
                    myDiv,
                    downloadLoading,
                    setDownloadLoading
                }}
                onSaveOnlyAction={() => {
                    setSaveOnly(true);
                    setShowSaveModal(true);
                }}
                onSaveAction={() => {
                    setSaveOnly(false);
                    setShowSaveModal(true);
                }}
                disabled={
                    Object.values(payload).some(
                        (item: any) => item === undefined
                    ) || loading
                }
            />
            <TradeContent>
                {showPreview ? (
                    <div ref={myDiv}>
                        <FormPreviewTemplate2
                            data={payload}
                            docTitle='Packing List'
                            quoteFields={quoteFields}
                            signatureUrlBase64={signatureUrlBase64}
                            {...{
                                totalGrossWeight,
                                totalUnitQuantity,
                                totalNetWeight,
                                totalVolume
                            }}
                        />
                    </div>
                ) : (
                    <PackingListForm
                        {...{
                            error,
                            updateField,
                            payload,
                            corporatePartners,
                            countries,
                            isCountriesLoading,
                            loadingCorporatepartners: isLoading,
                            setShowAddPartner,
                            errors,
                            quoteFields,
                            updateQuoteField,
                            removeQuoteField,
                            addQuoteField,
                            setSignatureUrlBase64,
                            signatureUrlBase64,
                            totalGrossWeight,
                            totalUnitQuantity,
                            totalNetWeight,
                            totalVolume
                        }}
                    />
                )}

                {showAddPartner ? (
                    <Addpartnerdrawer
                        closeDrawer={closeShowAddParty}
                        showDrawer={showAddPartner}
                        partnerId={partnerId}
                        setPartnerId={setPartnerId}
                    />
                ) : null}

                {showSaveModal && (
                    <CustomModal
                        open={showSaveModal}
                        onClose={closeSaveModal}
                        title='Saving document'
                        maxWidth='xs'
                        styles={{
                            overflowX: 'hidden',
                            '.MuiPaper-root': {
                                width: '100%'
                            }
                        }}
                    >
                        <Savetradedocument
                            updateField={updateField}
                            onSubmit={onSubmit}
                            loading={loading}
                            data={payload}
                        />
                    </CustomModal>
                )}
            </TradeContent>
        </TradeContainer>
    );
};

export default PackingList;
