import React, { useMemo } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import useCountries from '../customhooks/useCountries';
import { Country } from '../interfaces/Country';
import CustomTextField from './CustomTextInput';

interface CountryAutocompleteProps {
    onHandleChange: (value: Country) => void;
    initialValue?: string;
    error?: boolean;
    errorMessage?: string;
}

const CountriesDropdown = ({
    initialValue,
    onHandleChange,
    error,
    errorMessage
}: CountryAutocompleteProps) => {
    const { countries, isCountriesLoading } = useCountries();

    const defaultValue = useMemo(() => {
        return countries.find((item) => item.countryCode2 === initialValue);
    }, [countries, initialValue]);

    return (
        <Autocomplete
            disableClearable
            options={countries}
            value={defaultValue}
            renderInput={(params) => (
                <CustomTextField
                    name='coutries'
                    params={params}
                    label='Select Country'
                    error={error}
                    helperText={errorMessage}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: isCountriesLoading && (
                            <>
                                <CircularProgress size={20} />
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
            noOptionsText={'No data available'}
            renderOption={(props, option) => (
                <MenuItem
                    {...props}
                    value={option.countryCode2}
                    key={`countries-${option.id}`}
                >
                    <div className='flex items-center'>
                        <img
                            src={option?.flagImageUrl}
                            alt={`${option.name}-flag`}
                            className='w-4 h-4'
                        />
                        <span className='ml-2'>{option.name}</span>
                    </div>
                </MenuItem>
            )}
            getOptionLabel={(option) => option.name}
            onChange={(_, value) => onHandleChange(value)}
        />
    );
};

export default CountriesDropdown;
