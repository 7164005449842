import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserProfile } from '../../../../customhooks/useUserProfile';
import { Dropdown, MenuProps } from 'antd';
import { hasPermission } from '../../dashboardpage/utils';
import { SuitUIButton } from '../../../SuitUIButton';
import { PlusIcon } from '../../../icons/PlusIcon';
import { Divider, Tooltip } from '@mui/material';
import { add } from '../../../customIcons';
import CustomModal from '../../../CustomModal';
import UploadInvoice from './UploadInvoice';
import Addpartnerdrawer from '../../../../pages/dashboard/tradedocuments/addpartnerdrawer/addpartnerdrawer';
import { nanoid } from '@reduxjs/toolkit';
import useCurrencies from '../../../../customhooks/useCurrencies';
import { CorporatePartner } from '../../../../interfaces/CorporatePartner';

const CreateInvoice = ({
    corporatePartners,
    loadingCorporatePartners,
    fetchPartners
}: {
    corporatePartners?: CorporatePartner[];
    fetchPartners: () => void;
    loadingCorporatePartners?: boolean;
}) => {
    const [visible, setVisible] = useState(false);
    const [error, setError] = useState<string | any>(null);
    const [totalAmount, setTotalAmount] = useState(0);
    const { isLoading: isLoadingCurrencies, currencies } = useCurrencies();

    const navigate = useNavigate();
    const { permissions } = useUserProfile();

    const closeVisibleModal = () => {
        setVisible(false);
    };

    const [quoteFields, setQuoteFields] = useState([
        {
            id: nanoid(9),
            desc: undefined,
            qty: 0,
            type: undefined,
            price: 0
        }
    ]);

    const [payloadCom, setPayloadCom] = useState<any>({
        // billOfLadingNumber: undefined,
        documentUrl: undefined,
        documentName: undefined,
        salesContractNumber: '',
        dueDate: undefined,
        consigneeName: '',
        billOfLadingNumber: undefined,
        invoiceNumber: nanoid(9),
        consigneeId: undefined
    });

    const [errorsCom, setErrorsCom] = useState<any>({
        // billOfLadingNumber: "",
        documentUrl: '',
        documentName: '',
        salesContractNumber: '',
        dueDate: '',
        consigneeName: '',
        billOfLadingNumber: '',
        invoiceNumber: '',
        consigneeId: ''
    });

    const [showAddPartner, setShowAddPartner] = useState(false);

    const closeShowAddParty = (refreshList?: boolean) => {
        setShowAddPartner(false);
        if (refreshList) {
            fetchPartners();
        }
    };

    const updateField = (name: string, value: any) => {
        setPayloadCom((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrorsCom((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));
    };

    const updateQuoteField = (
        index: number,
        attributeName: string,
        attributeValue?: any
    ) => {
        const newContainers: any[] = [...quoteFields];
        newContainers[index][attributeName] = attributeValue;
        setQuoteFields(newContainers);
    };

    useEffect(() => {
        let totalNumber = 0;
        quoteFields.forEach((item) => {
            totalNumber +=
                (isNaN(item.price) ? 0 : Number(item.price)) *
                (isNaN(item.qty) ? 0 : Number(item.qty));
        });
        setTotalAmount(totalNumber);
    }, [quoteFields]);

    const addQuoteField = () => {
        setQuoteFields([
            ...quoteFields,
            {
                id: nanoid(9),
                desc: undefined,
                qty: 0,
                type: undefined,
                price: 0
            }
        ]);
    };

    const removeQuoteField = (index: number) => {
        setQuoteFields(quoteFields.filter((item, i) => i !== index));
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <p
                    onClick={() => {
                        setVisible(true);
                    }}
                >
                    Upload
                </p>
            )
        },
        {
            key: '2',
            label: (
                <p
                    onClick={() => {
                        navigate('/trade-documents/commercial-invoice');
                    }}
                >
                    Create
                </p>
            )
        }
    ];

    return (
        <>
            {permissions &&
                hasPermission({
                    userPermissions: permissions,
                    module: 'sales',
                    permissionList: ['write', '*']
                }) && (
                    <>
                        <div className='lg:hidden w-full'>
                            <Dropdown
                                menu={{ items }}
                                placement='bottomLeft'
                                trigger={['click']}
                            >
                                <SuitUIButton
                                    text='Add Invoice'
                                    invert
                                    fullWidth
                                    iconLeft={<PlusIcon color='#0088B2' />}
                                    style='border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                                />
                            </Dropdown>
                        </div>
                        <div className='hidden lg:flex w-max'>
                            <Divider orientation='vertical' flexItem />
                            <Dropdown
                                menu={{ items }}
                                placement='bottomLeft'
                                trigger={['click']}
                                className='hidden'
                            >
                                <Tooltip title='Add Invoice' arrow>
                                    <div className='tab-buttons'>
                                        {add}
                                        <p style={{ paddingLeft: '5px' }}>
                                            Add Invoice
                                        </p>
                                    </div>
                                </Tooltip>
                            </Dropdown>
                        </div>
                    </>
                )}

            <CustomModal
                maxWidth='lg'
                open={visible}
                onClose={closeVisibleModal}
                title={`Upload Document`}
                subTitle={`Please upload Commercial Invoice document.`}
                styles={{
                    overflowX: 'hidden'
                }}
            >
                <div className='mt-4 max-h-[80vh] overflow-y-scroll lg:max-h-max '>
                    <UploadInvoice
                        {...{
                            corporatePartners,
                            currencies,
                            loadingCurrencies: isLoadingCurrencies,
                            updateField,
                            errorsCom,
                            error,
                            setError,
                            payloadCom,
                            quoteFields,
                            updateQuoteField,
                            addQuoteField,
                            removeQuoteField,
                            closeVisibleModal,
                            totalAmount,
                            setShowAddPartner,
                            loadingCorporatePartners
                        }}
                    />
                </div>
                {showAddPartner && (
                    <Addpartnerdrawer
                        closeDrawer={closeShowAddParty}
                        showDrawer={showAddPartner}
                    />
                )}
            </CustomModal>
        </>
    );
};

export default CreateInvoice;
