import { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

export interface authouterpageProps {
    children: ReactNode;
    title: string;
    info: string;
    infoText?: string;
}

/**
 * Auth Outerpage component
 * @param props authouterpageProps
 * @returns tsx
 */
const Authouterpage = ({
    children,
    title,
    info,
    infoText
}: authouterpageProps) => {
    const navigate = useNavigate();

    return (
        <div className='min-h-screen max-h-full bg-appcolorprimary pt-4 px-[1.7rem] lg:px-[9.1rem] relative'>
            <div className='flex justify-between'>
                <div>
                    <img
                        src='/img/logo/whitelogo.svg'
                        alt='logo'
                        onClick={() => {
                            navigate('/login');
                        }}
                        className='cursor-pointer'
                    />
                </div>

                <p>
                    <span className='cursor-pointer text-appcolorwhite text-[13px] leading-[23px]'>
                        Learn More
                    </span>
                </p>
            </div>

            <div className='absolute right-[10rem] top-[3rem] hidden lg:block'>
                <img src='/img/logo/logowatermark.svg' alt='logowatermark' />
            </div>

            <div
                className={`flex justify-start w-full my-auto mt-[5.0rem] ${
                    title === 'SIGN UP' ? 'lg:mt-[4.5rem]' : 'lg:mt-[9.5rem]'
                }`}
            >
                <div className='lg:w-[90%]'>
                    <Grid container alignItems='center'>
                        <Grid item xs={12} md={12} lg={6}>
                            <div className='lg:w-[70%] mb-8 lg:mb-0'>
                                <div className='flex mb-[1.3rem] items-center'>
                                    <div className='mr-4'>
                                        <img
                                            src='/img/vectors/authspiral.svg'
                                            alt='spiral'
                                        />
                                    </div>

                                    <p className='text-appcolorwhite tracking-[0.4rem] text-[13px] leading-[23px]'>
                                        {title}
                                    </p>
                                </div>

                                <h1 className='font-light text-appcolorwhite text-[40px] lg:text-[59px] leading-[93.5%] mb-[1.9rem]'>
                                    {info}
                                </h1>

                                {infoText !== undefined && (
                                    <p className='text-lg font-light text-appcolorwhite'>
                                        {infoText}
                                    </p>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <div className='relative'>{children}</div>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <div className='left-0 mb-8 lg:absolute bottom-8 lg:mb-0'>
                <p className='text-sm font-light text-appcolorwhite leading-[23px] lg:pl-[9.1rem] mt-4 lg:mt-0'>
                    <span>©FrontEdge {new Date().getFullYear()}</span>
                </p>
            </div>
        </div>
    );
};

Authouterpage.defaultProps = {
    infoText: undefined
};

export default Authouterpage;
