import { Alert, Grid, MenuItem, TextField } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import Signature from '../../../signature/signature';
import Housebillofladingfield from '../../../../pages/dashboard/tradedocuments/housebilloflading/housebillofladingfield/housebillofladingfield';
import { transports } from '../../../../utils/appconstants';
import Customdatepicker from '../../../customdatepicker/customdatepicker';
import NewCustomSelect from '../../../newcustomselect/newcustomselect';
import ChooseDocUpload from '../../../custommodal/ChooseDocUpload';
import UploadLogo from '../common/UploadLogo';
import PortsAutocomplete from '../../../autocompletewithapi/PortsAutocomplete';
import { NumberInput } from '../../../paymentinput/paymentinput';

interface IProps {
    error: any;
    updateField: any;
    payload: any;
    corporatePartners: any;
    loadingCorporatepartners: boolean;
    setShowAddPartner: Dispatch<SetStateAction<boolean>>;
    errors: any;
    quoteFields: any[];
    updateQuoteField: any;
    removeQuoteField: any;
    addQuoteField: any;
    setSignatureUrlBase64: (value: string) => void;
    signatureUrlBase64: any;
}

const BillOfLadenForm: FC<IProps> = ({
    error,
    updateField,
    payload,
    corporatePartners,
    loadingCorporatepartners,
    setShowAddPartner,
    errors,
    quoteFields,
    updateQuoteField,
    removeQuoteField,
    addQuoteField,
    setSignatureUrlBase64,
    signatureUrlBase64
}): JSX.Element => {
    return (
        <div className='form-container'>
            {' '}
            <div className='border-0 border-appcolorgreyfaint'>
                {error !== null && (
                    <div className='mb-4'>
                        <Alert severity='error'>{error}</Alert>
                    </div>
                )}

                <form>
                    <div className=''>
                        <div className='flex gap-2 items-center'>
                            <UploadLogo {...{ payload, updateField }} />

                            <ChooseDocUpload {...{ updateField, payload }}>
                                <p>Choose Logo</p>
                            </ChooseDocUpload>
                        </div>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            marginTop: 0
                                        }}
                                    >
                                        <Grid item xs={12} md={12} lg={12}>
                                            <div className='mt-4'>
                                                <NewCustomSelect
                                                    hasError={
                                                        errors?.shipperId
                                                            .length > 0
                                                    }
                                                    helperText={
                                                        errors?.shipperId
                                                            .length > 0
                                                            ? errors?.shipperId
                                                            : undefined
                                                    }
                                                    selectValue={
                                                        payload.shipperId
                                                    }
                                                    required
                                                    selectOptions={
                                                        corporatePartners
                                                    }
                                                    name={'consigneeId'}
                                                    updateField={(_, value) => {
                                                        updateField(
                                                            'shipperId',
                                                            value
                                                        );
                                                        updateField(
                                                            'shipperAddress',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]?.address
                                                        );

                                                        updateField(
                                                            'shipperName',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]?.name
                                                        );
                                                        updateField(
                                                            'shipperAccountNumber',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]
                                                                ?.bankAccountNumber
                                                        );
                                                        updateField(
                                                            'shipperBank',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]?.bankName
                                                        );
                                                        updateField(
                                                            'shipperCountry',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]?.bankCountry
                                                        );
                                                        updateField(
                                                            'shipperSwiftCode',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]?.swiftCode
                                                        );
                                                    }}
                                                    label={'Consigner/Shipper'}
                                                    selectOptionLabel='name'
                                                    loading={
                                                        loadingCorporatepartners
                                                    }
                                                    dropdownRender={() => {
                                                        return (
                                                            <div className='px-4 border-t border-appcolorgreyfaint'>
                                                                <p
                                                                    className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                                    onClick={() => {
                                                                        setShowAddPartner(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    + Add a New
                                                                    Partner
                                                                </p>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </div>

                                            <TextField
                                                InputLabelProps={{
                                                    shrink:
                                                        payload.shipperAddress !==
                                                        undefined
                                                            ? true
                                                            : false
                                                }}
                                                error={
                                                    errors?.shipperAddress
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.shipperAddress
                                                        .length > 0
                                                        ? errors?.shipperAddress
                                                        : undefined
                                                }
                                                value={payload?.shipperAddress}
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='quotation-documentName'
                                                label='Consigner/Shipper Address'
                                                name='shipperAddress'
                                                onChange={(e) => {
                                                    updateField(
                                                        'shipperAddress',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={3}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                    <TextField
                                        error={errors?.mtdNumber.length > 0}
                                        helperText={
                                            errors?.mtdNumber.length > 0
                                                ? errors?.mtdNumber
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='proformainvoice-mtdNumber'
                                        label='MTD No'
                                        name='mtdNumber'
                                        value={payload?.mtdNumber}
                                        onChange={(e) => {
                                            updateField(
                                                'mtdNumber',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={3}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[1.1rem] pb-2 h-full'>
                                    <Customdatepicker
                                        value={ payload?.issuedDate || null }
                                        id='quotation-issuedDate'
                                        label='Date of Issue'
                                        name='issuedDate'
                                        updateField={updateField}
                                        error={errors?.issuedDate.length > 0}
                                        helperText={
                                            errors?.issuedDate.length > 0
                                                ? errors?.issuedDate
                                                : undefined
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                    <div className='mt-4'>
                                        <NewCustomSelect
                                            hasError={
                                                errors?.consigneeId.length > 0
                                            }
                                            helperText={
                                                errors?.consigneeId.length > 0
                                                    ? errors?.consigneeId
                                                    : undefined
                                            }
                                            selectValue={payload.consigneeId}
                                            required
                                            selectOptions={corporatePartners}
                                            name={'consigneeId'}
                                            updateField={(_, value) => {
                                                updateField(
                                                    'consigneeId',
                                                    value
                                                );
                                                updateField(
                                                    'consigneeAddress',
                                                    corporatePartners.filter(
                                                        (item: any) =>
                                                            item?.id === value
                                                    )[0]?.address
                                                );
                                                updateField(
                                                    'consigneeName',
                                                    corporatePartners.filter(
                                                        (item: any) =>
                                                            item?.id === value
                                                    )[0]?.name
                                                );
                                                updateField(
                                                    'consigneeAccountNumber',
                                                    corporatePartners.filter(
                                                        (item: any) =>
                                                            item?.id === value
                                                    )[0]?.bankAccountNumber
                                                );
                                                updateField(
                                                    'consigneeBank',
                                                    corporatePartners.filter(
                                                        (item: any) =>
                                                            item?.id === value
                                                    )[0]?.bankName
                                                );
                                                updateField(
                                                    'consigneeCountry',
                                                    corporatePartners.filter(
                                                        (item: any) =>
                                                            item?.id === value
                                                    )[0]?.bankCountry
                                                );
                                                updateField(
                                                    'consigneeSwiftCode',
                                                    corporatePartners.filter(
                                                        (item: any) =>
                                                            item?.id === value
                                                    )[0]?.swiftCode
                                                );
                                            }}
                                            label={'Consignee'}
                                            // alternativeValue='shippingLineId'
                                            selectOptionLabel='name'
                                            loading={loadingCorporatepartners}
                                            dropdownRender={() => {
                                                return (
                                                    <div className='px-4 border-t border-appcolorgreyfaint'>
                                                        <p
                                                            className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                            onClick={() => {
                                                                setShowAddPartner(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            + Add a New Partner
                                                        </p>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>

                                    <TextField
                                        InputLabelProps={{
                                            shrink:
                                                payload.consigneeAddress !==
                                                undefined
                                                    ? true
                                                    : false
                                        }}
                                        error={
                                            errors?.consigneeAddress.length > 0
                                        }
                                        helperText={
                                            errors?.consigneeAddress.length > 0
                                                ? errors?.consigneeAddress
                                                : undefined
                                        }
                                        value={payload?.consigneeAddress}
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='quotation-documentName'
                                        label='Consignee Address'
                                        name='consigneeAddress'
                                        onChange={(e) => {
                                            updateField(
                                                'consigneeAddress',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    >
                                        <Grid item xs={12} md={12} lg={6}>
                                            <TextField
                                                error={
                                                    errors?.placeOfReceipt
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.placeOfReceipt
                                                        .length > 0
                                                        ? errors?.placeOfReceipt
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='proformainvoice-placeOfReceipt'
                                                label='Place of Receipt'
                                                name='placeOfReceipt'
                                                value={payload?.placeOfReceipt}
                                                onChange={(e) => {
                                                    updateField(
                                                        'placeOfReceipt',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={6}>
                                            <div className='pt-[1rem]'>
                                                <Customdatepicker
                                                    value={
                                                        payload?.dateOfReceipt !==
                                                        undefined
                                                            ? payload?.dateOfReceipt
                                                            : null
                                                    }
                                                    id='quotation-dateOfReceipt'
                                                    label='Date Of Receipt'
                                                    name='dateOfReceipt'
                                                    updateField={updateField}
                                                    error={
                                                        errors?.dateOfReceipt
                                                            .length > 0
                                                    }
                                                    helperText={
                                                        errors?.dateOfReceipt
                                                            .length > 0
                                                            ? errors?.dateOfReceipt
                                                            : undefined
                                                    }
                                                />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={6}>
                                            <TextField
                                                error={
                                                    errors
                                                        ?.shipmentReferenceNumber
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors
                                                        ?.shipmentReferenceNumber
                                                        .length > 0
                                                        ? errors?.shipmentReferenceNumber
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='proformainvoice-shipmentReferenceNumber'
                                                label='Shipment Reference Number'
                                                name='shipmentReferenceNumber'
                                                value={
                                                    payload?.shipmentReferenceNumber
                                                }
                                                onChange={(e) => {
                                                    updateField(
                                                        'shipmentReferenceNumber',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                    <div className='mt-4'>
                                        <NewCustomSelect
                                            hasError={
                                                errors?.notifyPartyId.length > 0
                                            }
                                            helperText={
                                                errors?.notifyPartyId.length > 0
                                                    ? errors?.notifyPartyId
                                                    : undefined
                                            }
                                            selectValue={payload.notifyPartyId}
                                            required
                                            selectOptions={corporatePartners}
                                            name={'notifyPartyId'}
                                            updateField={(_, value) => {
                                                updateField(
                                                    'notifyPartyId',
                                                    value
                                                );
                                                updateField(
                                                    'notifyPartyName',
                                                    corporatePartners.filter(
                                                        (item: any) =>
                                                            item?.id === value
                                                    )[0]?.name
                                                );
                                            }}
                                            label={'Notify Party'}
                                            // alternativeValue='shippingLineId'
                                            selectOptionLabel='name'
                                            loading={loadingCorporatepartners}
                                            dropdownRender={() => {
                                                return (
                                                    <div className='px-4 border-t border-appcolorgreyfaint'>
                                                        <p
                                                            className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                            onClick={() => {
                                                                setShowAddPartner(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            + Add a New Partner
                                                        </p>
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    >
                                        <Grid item xs={12} md={12} lg={6}>
                                            <PortsAutocomplete
                                                errorCondition={
                                                    errors?.portOfLoadingId
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.portOfLoadingId
                                                        .length > 0
                                                        ? errors?.portOfLoadingId
                                                        : undefined
                                                }
                                                id='quotation-portOfLoading'
                                                label='Port of Loading'
                                                name='portOfLoadingId'
                                                updateField={updateField}
                                                nameValue='portOfLoadingValue'
                                                value={
                                                    payload.portOfLoadingValue
                                                }
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={6}>
                                            <PortsAutocomplete
                                                errorCondition={
                                                    errors?.portOfDischargeId
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.portOfDischargeId
                                                        .length > 0
                                                        ? errors?.portOfDischargeId
                                                        : undefined
                                                }
                                                id='quotation-portOfDischarge'
                                                label='Port of Discharge'
                                                name='portOfDischargeId'
                                                updateField={updateField}
                                                nameValue='portOfDischargeValue'
                                                value={
                                                    payload.portOfDischargeValue
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    <TextField
                                        error={
                                            errors?.vesselAndVoyage.length > 0
                                        }
                                        helperText={
                                            errors?.vesselAndVoyage.length > 0
                                                ? errors?.vesselAndVoyage
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='proformainvoice-vesselAndVoyage'
                                        label='Vessel and Voyage'
                                        name='vesselAndVoyage'
                                        value={payload?.vesselAndVoyage}
                                        onChange={(e) => {
                                            updateField(
                                                'vesselAndVoyage',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    >
                                        <Grid item xs={12} md={12} lg={6}>
                                            <div className='pt-[1rem]'>
                                                <Customdatepicker
                                                    value={
                                                        payload?.startDateOfDelivery !==
                                                        undefined
                                                            ? payload?.startDateOfDelivery
                                                            : null
                                                    }
                                                    id='quotation-startDateOfDelivery'
                                                    label='Start Date Of Delivery'
                                                    name='startDateOfDelivery'
                                                    updateField={updateField}
                                                    error={
                                                        errors
                                                            ?.startDateOfDelivery
                                                            .length > 0
                                                    }
                                                    helperText={
                                                        errors
                                                            ?.startDateOfDelivery
                                                            .length > 0
                                                            ? errors?.startDateOfDelivery
                                                            : undefined
                                                    }
                                                />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={6}>
                                            <div className='pt-[1rem]'>
                                                <Customdatepicker
                                                    value={
                                                        payload?.endDateOfDelivery !==
                                                        undefined
                                                            ? payload?.endDateOfDelivery
                                                            : null
                                                    }
                                                    id='quotation-endDateOfDelivery'
                                                    label='End Date Of Delivery'
                                                    name='endDateOfDelivery'
                                                    updateField={updateField}
                                                    error={
                                                        errors
                                                            ?.endDateOfDelivery
                                                            .length > 0
                                                    }
                                                    helperText={
                                                        errors
                                                            ?.endDateOfDelivery
                                                            .length > 0
                                                            ? errors?.endDateOfDelivery
                                                            : undefined
                                                    }
                                                />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={6}>
                                            <TextField
                                                error={
                                                    errors?.placeOfDelivery
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.placeOfDelivery
                                                        .length > 0
                                                        ? errors?.placeOfDelivery
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='commercialinvoice-placeOfDelivery'
                                                label='Place of Delivery'
                                                name='placeOfDelivery'
                                                value={payload?.placeOfDelivery}
                                                onChange={(e) => {
                                                    updateField(
                                                        'placeOfDelivery',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                                    <Grid
                                        spacing={1}
                                        container
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    >
                                        <Grid item xs={12} md={12} lg={6}>
                                            <TextField
                                                select
                                                error={
                                                    errors?.transport.length > 0
                                                }
                                                helperText={
                                                    errors?.transport.length > 0
                                                        ? errors?.transport
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='commercialinvoice-transport'
                                                label='Transport'
                                                value={payload.transport}
                                                name='transport'
                                                onChange={(e) => {
                                                    updateField(
                                                        'transport',
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                {transports.map(
                                                    (item, index) => (
                                                        <MenuItem
                                                            value={item}
                                                            key={`transports-${item}-${index}`}
                                                        >
                                                            {item}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={6}>
                                            <TextField
                                                error={
                                                    errors?.placeOfTranshipment
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.placeOfTranshipment
                                                        .length > 0
                                                        ? errors?.placeOfTranshipment
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='commercialinvoice-placeOfTranshipment'
                                                label='Route / place of Transhipment'
                                                value={
                                                    payload.placeOfTranshipment
                                                }
                                                name='placeOfTranshipment'
                                                onChange={(e) => {
                                                    updateField(
                                                        'placeOfTranshipment',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    error={
                                        errors?.billOfLadingNumber.length > 0
                                    }
                                    helperText={
                                        errors?.billOfLadingNumber?.length > 0
                                            ? errors?.billOfLadingNumber
                                            : undefined
                                    }
                                    margin='normal'
                                    value={payload?.billOfLadingNumber}
                                    required
                                    fullWidth
                                    id='quotation-billOfLadingNumber'
                                    label='Bill of Lading Number'
                                    name='billOfLadingNumber'
                                    onChange={(e) => {
                                        updateField(
                                            'billOfLadingNumber',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    error={
                                        errors?.salesContractNumber?.length > 0
                                    }
                                    helperText={
                                        errors?.salesContractNumber?.length > 0
                                            ? errors?.salesContractNumber
                                            : undefined
                                    }
                                    margin='normal'
                                    value={payload?.salesContractNumber}
                                    required
                                    fullWidth
                                    id='quotation-salesContractNumber'
                                    label='Sales Contract Number'
                                    name='salesContractNumber'
                                    onChange={(e) => {
                                        updateField(
                                            'salesContractNumber',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>

                    {quoteFields.map((item, index) => (
                        <Housebillofladingfield
                            key={item.id}
                            index={index}
                            id={item.id}
                            updateQuoteField={updateQuoteField}
                            kindOfPackage={item.kindOfPackage}
                            qtyOfPackage={item.qtyOfPackage}
                            weightOfPackageKG={item.weightOfPackageKG}
                            grossWeightOfPackageKG={item.grossWeightOfPackageKG}
                            measurementOfPackage={item.measurementOfPackage}
                            qty={item.qty}
                            removeQuoteField={removeQuoteField}
                            item={item}
                        />
                    ))}

                    <div>
                        <div className='border border-appcolorgreyfaint px-[1rem] pt-8 pb-8'>
                            <div
                                className='flex items-center cursor-pointer w-fit'
                                onClick={() => {
                                    addQuoteField();
                                }}
                            >
                                <div>
                                    <img
                                        src='/img/vectors/primarydownload.svg'
                                        alt='primarydownload'
                                        className='w-6'
                                    />
                                </div>
                                <span className='pl-2 text-appcolorprimary'>
                                    Add Field
                                </span>
                            </div>
                        </div>

                        <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                            <Grid
                                container
                                spacing={1}
                                sx={{
                                    marginTop: '0px'
                                }}
                            >
                                <Grid item xs={12} md={12} lg={3}>
                                    <TextField
                                        required
                                        margin='normal'
                                        fullWidth
                                        id='housebilloflading-containerNo'
                                        label='Container No(s)'
                                        name='containerNo'
                                        value={payload?.containerNo}
                                        onChange={(e) => {
                                            updateField(
                                                'containerNo',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} lg={3}>
                                    <TextField
                                        required
                                        margin='normal'
                                        fullWidth
                                        id='housebilloflading-sealNumber'
                                        label='Seal No'
                                        name='sealNumber'
                                        value={payload.sealNumber}
                                        onChange={(e) => {
                                            updateField(
                                                'sealNumber',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} lg={3}>
                                    <TextField
                                        required
                                        margin='normal'
                                        fullWidth
                                        id='housebilloflading-kindOfPackages'
                                        label='Package Kind'
                                        name='kindOfPackages'
                                        value={payload.kindOfPackages}
                                        onChange={(e) => {
                                            updateField(
                                                'kindOfPackages',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} lg={3}>
                                    <TextField
                                        required
                                        margin='normal'
                                        fullWidth
                                        id='housebilloflading-totalGrossWeight'
                                        label='Gross Weight'
                                        name='totalGrossWeight'
                                        value={payload.totalGrossWeight}
                                        InputProps={{
                                            inputComponent: NumberInput,
                                            inputProps: {
                                                min: '0'
                                            }
                                        }}
                                        inputProps={{
                                            inputMode: 'numeric',
                                            pattern: '[0-9]*'
                                        }}
                                        onChange={(e) => {
                                            updateField(
                                                'totalGrossWeight',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} lg={3}>
                                    <TextField
                                        required
                                        margin='normal'
                                        fullWidth
                                        id='housebilloflading-measurement'
                                        label='Measurement'
                                        name='measurement'
                                        value={payload.measurement}
                                        onChange={(e) => {
                                            updateField(
                                                'measurement',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <Grid container>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                    <TextField
                                        error={errors?.deliveryAgent.length > 0}
                                        helperText={
                                            errors?.deliveryAgent.length > 0
                                                ? errors?.deliveryAgent
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='housebilloflading-deliveryAgent'
                                        label='Delivery Agent'
                                        name='deliveryAgent'
                                        value={payload.deliveryAgent}
                                        onChange={(e) => {
                                            updateField(
                                                'deliveryAgent',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    >
                                        <Grid item xs={12} md={12} lg={6}>
                                            <TextField
                                                error={
                                                    errors?.freightAmount
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.freightAmount
                                                        .length > 0
                                                        ? errors?.freightAmount
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='housebilloflading-freightAmount'
                                                label='Freight Amount'
                                                name='freightAmount'
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                    pattern: '[0-9]*'
                                                }}
                                                InputProps={{
                                                    inputComponent: NumberInput,
                                                    inputProps: {
                                                        min: '0'
                                                    }
                                                }}
                                                value={payload.freightAmount}
                                                onChange={(e) => {
                                                    updateField(
                                                        'freightAmount',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={6}>
                                            <TextField
                                                error={
                                                    errors?.noOfOriginals
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.noOfOriginals
                                                        .length > 0
                                                        ? errors?.noOfOriginals
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='housebilloflading-noOfOriginals'
                                                label='No of Originals'
                                                name='noOfOriginals'
                                                value={payload.noOfOriginals}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                    pattern: '[0-9]*'
                                                }}
                                                InputProps={{
                                                    inputComponent: NumberInput,
                                                    inputProps: {
                                                        min: '0'
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    updateField(
                                                        'noOfOriginals',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12}>
                                            <TextField
                                                error={
                                                    errors?.placeOfIssue
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.placeOfIssue
                                                        .length > 0
                                                        ? errors?.placeOfIssue
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='housebilloflading-placeOfIssue'
                                                label='Place of Issue'
                                                name='placeOfIssue'
                                                value={payload.placeOfIssue}
                                                onChange={(e) => {
                                                    updateField(
                                                        'placeOfIssue',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12}>
                                            <TextField
                                                error={
                                                    errors?.freightPayableAt
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.freightPayableAt
                                                        .length > 0
                                                        ? errors?.freightPayableAt
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='housebilloflading-freightPayableAt'
                                                label='Freight Payable At'
                                                name='freightPayableAt'
                                                value={
                                                    payload?.freightPayableAt
                                                }
                                                onChange={(e) => {
                                                    updateField(
                                                        'freightPayableAt',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <div className='w-full'>
                                                <Signature
                                                    name='signatureUrl'
                                                    updateField={updateField}
                                                    setSignatureUrlBase64={
                                                        setSignatureUrlBase64
                                                    }
                                                    signatureUrlBase64={
                                                        signatureUrlBase64
                                                    }
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default BillOfLadenForm;
