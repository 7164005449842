import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { TagIcon } from '../../../components/icons/TagIcon';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { useGetQuery } from '../../../customhooks/useGetQuery';
import { getFormattedAmount } from '../../../shared/getFormattedAmount';
import { LogisticsInvoiceSummarySkeleton } from './LogisticsInvoiceSummarySkeleton';
import { Carousel } from 'antd';
import { RoundedArrowIcon } from '../../../components/icons';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { CarouselRef } from '../../../interfaces/SliderInterfaces';

interface InvoiceSummary {
    totalAmount: number;
    totalPaid: number;
    totalDue: number;
    totalOverdue: number;
    currencyCode?: string;
}

const SummaryTitles = {
    totalAmount: 'total value of invoices',
    totalPaid: 'total paid',
    totalDue: 'total outstanding',
    totalOverdue: 'total overdue'
};

export const LogisticsInvoiceSummary = () => {
    const { user } = useSelector((state: RootState) => state.user);
    const { isLoading, entries } = useGetQuery<InvoiceSummary[]>({
        url: `/invoice/summary/${user?.corporate_id}`,
        cacheKey: 'invoice-summary',
        params: { serviceable: true },
        enabled: Boolean(user?.corporate_id)
    });
    const [summary, setSummary] = useState<InvoiceSummary>({
        totalAmount: 0,
        totalPaid: 0,
        totalDue: 0,
        totalOverdue: 0
    } as InvoiceSummary);
    const [selectedCurrency, setSelectedCurrency] = useState('NGN');

    useEffect(() => {
        const found = entries.find(
            ({ currencyCode }) => currencyCode === selectedCurrency
        );

        if (found) {
            const { currencyCode, totalAmount, ...rest } = found;

            setSummary({ totalAmount, ...rest });
        }
    }, [entries, selectedCurrency]);

    const slider = useRef<CarouselRef>(null);

    const [slideIndex, setSlideIndex] = useState(0);

    const CustomMobileInput = styled(InputBase)(({ theme }) => ({
        '& .MuiInputBase-input': {
            borderRadius: '8px',
            backgroundColor: 'white',
            border: '1px solid #D0D5DD',
            '&:focus': {
                borderRadius: 8,
                borderColor: '#80bdff',
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
            }
        }
    }));

    if (isLoading) {
        return <LogisticsInvoiceSummarySkeleton />;
    }

    return (
        <>
            <Box className='lg:flex hidden lg:justify-between justify-end lg:bg-white lg:px-7 lg:py-5 lg:rounded-lg gap-3 lg:gap-0 flex-wrap '>
                {Object.keys(summary).map((key, index) => (
                    <Box
                        key={index}
                        className='flex items-center border-r grow lg:mr-10 pr-2 last:border-r-0 last:mr-0 '
                    >
                        <Box className='grow'>
                            <Box className='capitalize lg:text-[14px] text-[13px]'>
                                {
                                    SummaryTitles[
                                        key as keyof typeof SummaryTitles
                                    ]
                                }
                            </Box>
                            <Box className='capitalize lg:text-[22px] text-[20px] font-semibold'>
                                {getFormattedAmount({
                                    amount: summary[
                                        key as keyof InvoiceSummary
                                    ] as number,
                                    currency: selectedCurrency,
                                    compact: true
                                })}
                            </Box>
                        </Box>
                        <Box className='lg:block hidden'>
                            <TagIcon />
                        </Box>
                    </Box>
                ))}
                <FormControl className='' sx={{ width: 80 }} size='small'>
                    <Select
                        value={selectedCurrency}
                        variant='standard'
                        disableUnderline
                    >
                        {entries.map(({ currencyCode }) => (
                            <MenuItem
                                key={currencyCode}
                                value={currencyCode}
                                onClick={() =>
                                    setSelectedCurrency(currencyCode as string)
                                }
                            >
                                {currencyCode}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Box className='lg:hidden space-y-2 max-w-[100vw] px-[1rem]'>
                <FormControl
                    className=' rounded-md'
                    sx={{ width: '100%', backgroundColor: 'white', border: 0 }}
                    size='medium'
                >
                    <Select
                        value={selectedCurrency}
                        input={<CustomMobileInput />}
                    >
                        {entries.map(({ currencyCode }) => (
                            <MenuItem
                                key={currencyCode}
                                value={currencyCode}
                                onClick={() =>
                                    setSelectedCurrency(currencyCode as string)
                                }
                            >
                                {currencyCode}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Carousel ref={slider}>
                    {Object.keys(summary).map((key, index) => (
                        <Box
                            key={index}
                            className='flex items-center grow
                        bg-white p-4 border flex-1 min-w-[48%] rounded-lg border-gray300 '
                        >
                            <div className='flex items-center'>
                                <Box className='grow'>
                                    <Box className='capitalize lg:text-[14px] text-[13px]'>
                                        {
                                            SummaryTitles[
                                                key as keyof typeof SummaryTitles
                                            ]
                                        }
                                    </Box>
                                    <Box className='capitalize lg:text-[22px] text-[20px] font-semibold'>
                                        {getFormattedAmount({
                                            amount: summary[
                                                key as keyof InvoiceSummary
                                            ] as number,
                                            currency: selectedCurrency,
                                            compact: true
                                        })}
                                    </Box>
                                </Box>
                                <TagIcon />
                            </div>
                        </Box>
                    ))}
                </Carousel>

                <Box className='flex justify-between items-center mt-[16px]'>
                    <Box className='flex gap-[8px] items-center'>
                        {Object.keys(summary)?.map((key, index) => (
                            <Box
                                key={key}
                                className={`w-[8px] h-[8px] rounded-[50%] ${
                                    slideIndex !== index
                                        ? 'bg-lavender'
                                        : 'bg-appcolorprimary'
                                }`}
                            />
                        ))}
                    </Box>
                    <Box className='flex gap-[20px] items-center'>
                        <button
                            onClick={() => {
                                slider.current?.prev();
                                setSlideIndex(
                                    slider.current?.innerSlider?.state
                                        ?.currentSlide || 0
                                );
                            }}
                            className='rotate-180'
                        >
                            <RoundedArrowIcon />
                        </button>
                        <button
                            onClick={() => {
                                slider.current?.next();
                                setSlideIndex(
                                    slider.current?.innerSlider?.state
                                        ?.currentSlide || 0
                                );
                            }}
                        >
                            <RoundedArrowIcon />
                        </button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
