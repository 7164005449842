import {
    Alert,
    Drawer,
    MenuItem,
    useMediaQuery,
    useTheme
} from '@mui/material';
import React, { useState } from 'react';
import Custombutton from '../../../../components/custombutton/custombutton';
import NewCustomSelect from '../../../../components/newcustomselect/newcustomselect';
import { createCommodity } from '../../../../api/documents/documents';
import { openNotification } from '../../../../utils/helper';
import { unitTypes } from '../../../../utils/appconstants';
import useHsCode from '../../../../customhooks/useHsCode';
import CustomTextField from '../../../../components/CustomTextInput';

export interface newcarrierDrawerProps {
    closeDrawer: (refreshList?: boolean) => void;
    showDrawer: boolean;
}

const AddNewCommodity = ({
    closeDrawer,
    showDrawer
}: newcarrierDrawerProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [error, setError] = useState<string | any>(null);
    const [loading, setLoading] = useState(false);
    const { isLoading, hsCodeList } = useHsCode({ page: 1, size: 0 });

    const [payload, setPayload] = useState<any>({
        hsCode: '',
        name: '',
        quality: '',
        type: '',
        unitType: '',
        hsCodeDescription: ''
    });
    const [errors, setErrors] = useState<any>({
        hsCode: '',
        name: '',
        quality: '',
        type: '',
        hsCodeDescription: '',
        unitType: ''
    });

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrors((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));
    };

    /**
     * Submit form
     * @param e Object
     */
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        setErrors((prevState: any) => ({
            ...prevState,
            hsCode: '',
            name: '',
            quality: '',
            type: '',
            hsCodeDescription: '',
            unitType: ''
        }));

        try {
            // TODO: Remove. These two are not used within this scope
            const res = await createCommodity(payload);
            const { data } = res.data;

            setLoading(false);

            openNotification('success', 'Commodity created successfully');

            closeDrawer(true);
        } catch (error) {
            setLoading(false);
            if (error?.response) {
                if (error?.response?.data?.error) {
                    let hasFieldNameAttribute = false;

                    error?.response?.data?.error.map((item: any) => {
                        return Object.keys(item).map((itemInside: string) => {
                            if (itemInside === 'fieldName') {
                                hasFieldNameAttribute = true;

                                return;
                            }
                        });
                    });

                    if (hasFieldNameAttribute) {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>
                                    {element.messageError}
                                </li>
                            );
                        });
                        setError(errorList);
                    } else {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>{element}</li>
                            );
                        });
                        setError(errorList);
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    return (
        <div>
            <div
                className='slide-in-right active bg-appcolorwhite rounded-[50%] top-[1.2rem] right-[28.4rem] fixed p-4 z-[2000] cursor-pointer'
                onClick={() => {
                    closeDrawer();
                }}
            >
                <img src='/img/vectors/cross.svg' alt='cross' />
            </div>
            <Drawer
                anchor={isMobile ? 'bottom' : 'right'}
                open={showDrawer}
                onClose={() => {
                    closeDrawer();
                }}
                sx={{
                    '.MuiDrawer-paperAnchorRight': {
                        width: {
                            xs: '95%',
                            lg: '30rem'
                        }
                    }
                }}
            >
                <div className='px-[3.2rem] py-[2.1rem]'>
                    <h1 className='text-[24px] leading-[23px] text-appcolorblacktwo font-semibold'>
                        Create Commodity
                    </h1>

                    {error !== null && (
                        <div className='mt-10'>
                            <Alert severity='error'>{error}</Alert>
                        </div>
                    )}

                    <div className='my-4'>
                        <form autoComplete='off' onSubmit={onSubmit} noValidate>
                            <CustomTextField
                                value={payload?.name}
                                error={errors?.name?.length > 0}
                                helperText={
                                    errors?.name?.length > 0
                                        ? errors?.name
                                        : undefined
                                }
                                required
                                id='createnewcommodity-name'
                                label='Commodity Name'
                                name='name'
                                onChange={(e: any) => {
                                    updateField('name', e.target.value);
                                }}
                            />

                            <CustomTextField
                                value={payload?.type}
                                error={errors?.type.length > 0}
                                helperText={
                                    errors?.type.length > 0
                                        ? errors?.type
                                        : undefined
                                }
                                required
                                id='type'
                                label='Commodity Type'
                                name='type'
                                onChange={(e: any) => {
                                    updateField('type', e.target.value);
                                }}
                            />
                            <div className='py-[1rem]'>
                                <NewCustomSelect
                                    hasError={errors?.hsCodeArr?.length > 0}
                                    helperText={
                                        errors?.hsCode?.length > 0
                                            ? errors?.hsCode
                                            : undefined
                                    }
                                    selectValue={payload.hsCode}
                                    required
                                    selectOptions={
                                        hsCodeList as unknown as Record<
                                            string,
                                            unknown
                                        >[]
                                    }
                                    name={'hsCode'}
                                    updateField={(_, value) => {
                                        updateField('hsCodeArr', value);

                                        updateField(
                                            'hsCode',
                                            hsCodeList.filter(
                                                (item: any) =>
                                                    item?.id === value
                                            )[0]?.hsCode
                                        );

                                        updateField(
                                            'hsCodeDescription',
                                            hsCodeList.filter(
                                                (item: any) =>
                                                    item?.id === value
                                            )[0]?.hsDescription
                                        );
                                    }}
                                    label={'HS Code'}
                                    selectOptionLabel='hsCode'
                                    loading={isLoading}
                                />
                            </div>
                            <CustomTextField
                                value={payload?.hsCodeDescription}
                                error={errors?.hsCodeDescription?.length > 0}
                                helperText={
                                    errors?.hsCodeDescription?.length > 0
                                        ? errors?.hsCodeDescription
                                        : undefined
                                }
                                disabled
                                required
                                id='createnewcommodity-name'
                                label='HS Code Description'
                                name='hsCodeDescription'
                                onChange={(e: any) => {
                                    updateField(
                                        'hsCodeDescription',
                                        e.target.value
                                    );
                                }}
                            />

                            <CustomTextField
                                value={payload?.quality}
                                error={errors?.quality.length > 0}
                                helperText={
                                    errors?.quality.length > 0
                                        ? errors?.quality
                                        : undefined
                                }
                                required
                                id='quality'
                                label='Quality'
                                name='quality'
                                onChange={(e: any) => {
                                    updateField('quality', e.target.value);
                                }}
                            />

                            <CustomTextField
                                select
                                value={payload?.unitType}
                                id={`commodity-unitType`}
                                label='Unit Type'
                                name='unitType'
                                onChange={(e: any) => {
                                    updateField('unitType', e.target.value);
                                }}
                            >
                                {unitTypes.map((item, i) => (
                                    <MenuItem value={item} key={`${i}-${item}`}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </CustomTextField>

                            <div className='mt-4'>
                                <Custombutton
                                    isLoadingButton
                                    loading={loading}
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    styles={{
                                        padding:
                                            '0.75rem 0.62rem 0.9rem 0.93rem'
                                    }}
                                    disabled={
                                        Object.values(payload).some(
                                            (item: any) => item === undefined
                                        ) || loading
                                    }
                                    buttonText='Submit'
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default AddNewCommodity;
