import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { REACT_APP_BASE_URL } from '../../config';
import Custombutton from '../custombutton/custombutton';

const dataURItoBlob = (dataURI: any) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
};

export interface signatureProps {
    updateField: (name: string, value: any) => void;
    name: string;
    setSignatureUrlBase64: (value: string) => void;
    signatureUrlBase64: string;
    label?: string;
}

const Signature = ({
    updateField,
    name,
    setSignatureUrlBase64,
    signatureUrlBase64,
    label
}: signatureProps) => {
    const signatureRef = useRef<SignatureCanvas>(null);

    const [loading, setLoading] = useState(false);

    const clear = () => {
        signatureRef?.current?.clear();
    };

    const save = async () => {
        const base64String =
            signatureRef.current?.getTrimmedCanvas().toDataURL('image/png') ||
            '';

        const img = new Image();

        img.src = base64String || '';

        img.onload = function () {
            // Create a canvas element
            const canvas = document.createElement('canvas');

            // Set the canvas dimensions to the image dimensions
            canvas.width = img.width;
            canvas.height = img.height;

            // Get the 2D context of the canvas
            const ctx: any = canvas.getContext('2d');

            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0);

            // Get the data URL of the canvas
            const dataUrl = canvas.toDataURL();

            // Convert the data URL to a Blob
            const blob = dataURItoBlob(dataUrl);

            // Create a new FormData object
            const formData = new FormData();

            // Append the Blob to the FormData object
            formData.append('file', blob, 'image.png');

            formData.append('fileType', 'CORPORATE_DOCUMENT');

            setLoading(true);

            axios
                .post(`${REACT_APP_BASE_URL}/files/upload`, formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                })
                .then((response) => {
                    const { fileUri } = response.data.data;
                    if (fileUri) {
                        updateField(name, fileUri);

                        setSignatureUrlBase64(base64String);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error uploading image:', error);
                    setLoading(false);
                });
        };
    };

    useEffect(() => {
        if (signatureUrlBase64 !== '') {
            signatureRef.current?.fromDataURL(signatureUrlBase64);
        }
    }, []);

    return (
        <div
            className='mt-2 border '
            style={{
                border: '1px solid rgba(0, 0, 0, 0.2)',
                borderRadius: '5px'
            }}
        >
            <p className='py-4 pl-4'>{label}</p>

            <SignatureCanvas
                canvasProps={{
                    width: 500,
                    height: 100,
                    className: 'sigCanvas'
                }}
                ref={signatureRef}
            />

            <div className='flex pb-4 pl-4'>
                <Custombutton
                    disabled={loading}
                    onClickAction={clear}
                    size='small'
                    isLoadingButton
                    variant='contained'
                    styles={{
                        padding: '0.5rem',
                        marginRight: '0.5rem'
                    }}
                    buttonText='Clear'
                />

                <Custombutton
                    disabled={loading}
                    loading={loading}
                    onClickAction={save}
                    size='small'
                    isLoadingButton
                    variant='contained'
                    styles={{
                        padding: '0.5rem'
                    }}
                    buttonText='Save'
                />
            </div>
        </div>
    );
};

Signature.defaultProps = {
    label: 'Signature'
};

export default Signature;
