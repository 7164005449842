import { Alert, Box } from '@mui/material';
import Parkinglistfield from '../../../../pages/dashboard/tradedocuments/parkinglist/parkinglistfield/parkinglistfield';
import ChooseDocUpload from '../../../custommodal/ChooseDocUpload';
import UploadLogo from '../common/UploadLogo';
import { PackingListInterface } from '../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/parkinglistTypes';
import ExporterDetails from './PackingListFormComponents/ExporterDetails';
import BuyerDetails from './PackingListFormComponents/BuyerDetails';
import ShippmentDetails from './PackingListFormComponents/ShippmentDetails';
import PortDetails from './PackingListFormComponents/PortDetails';
import TransportDetails from './PackingListFormComponents/TransportDetails';
import SignatureDetails from './PackingListFormComponents/SignatureDetails';
import Totals from './PackingListFormComponents/Totals';
import UploadStamp from '../common/UploadStamp';

const PackingListForm = ({
    error,
    updateField,
    payload,
    corporatePartners,
    countries,
    isCountriesLoading,
    loadingCorporatepartners,
    setShowAddPartner,
    errors,
    quoteFields,
    updateQuoteField,
    removeQuoteField,
    addQuoteField,
    setSignatureUrlBase64,
    signatureUrlBase64,
    totalNetWeight,
    totalUnitQuantity,
    totalGrossWeight,
    totalVolume
}: PackingListInterface) => {
    return (
        <div className='form-container'>
            <div className='border-0 border-appcolorgreyfaint'>
                {error !== null && (
                    <div className='mb-4'>
                        <Alert severity='error'>{error}</Alert>
                    </div>
                )}

                <form>
                    <div>
                        <div className='flex gap-2 items-center'>
                            <UploadLogo {...{ payload, updateField }} />

                            <ChooseDocUpload {...{ updateField, payload }}>
                                <p>Choose Logo</p>
                            </ChooseDocUpload>

                            <Box className='ml-auto'>
                                <UploadStamp {...{ payload, updateField }} />
                            </Box>
                        </div>

                        <ExporterDetails
                            {...{
                                errors,
                                payload,
                                corporatePartners,
                                updateField,
                                loadingCorporatepartners,
                                setShowAddPartner
                            }}
                        />

                        <BuyerDetails
                            {...{
                                errors,
                                payload,
                                corporatePartners,
                                updateField,
                                loadingCorporatepartners,
                                setShowAddPartner
                            }}
                        />

                        <ShippmentDetails
                            {...{
                                errors,
                                payload,
                                countries,
                                updateField,
                                isCountriesLoading,
                                setShowAddPartner
                            }}
                        />

                        <TransportDetails
                            {...{
                                errors,
                                payload,
                                countries,
                                updateField,
                                isCountriesLoading,
                                setShowAddPartner
                            }}
                        />

                        <PortDetails
                            {...{
                                errors,
                                payload,
                                updateField,
                                setShowAddPartner
                            }}
                        />
                    </div>

                    {quoteFields.map((item, index) => (
                        <Parkinglistfield
                            key={item.id}
                            index={index}
                            id={item.id}
                            updateQuoteField={updateQuoteField}
                            kindOfPackage={item.kindOfPackage}
                            qtyOfPackage={item.qtyOfPackage}
                            weightOfPackageKG={item.weightOfPackageKG}
                            grossWeightOfPackageKG={item.grossWeightOfPackageKG}
                            measurementOfPackage={item.measurementOfPackage}
                            qty={item.qty}
                            removeQuoteField={removeQuoteField}
                            item={item}
                        />
                    ))}

                    <div className='border border-appcolorgreyfaint px-[1rem] pt-8 pb-8'>
                        <div
                            className='flex items-center cursor-pointer w-fit'
                            onClick={() => {
                                addQuoteField();
                            }}
                        >
                            <img
                                src='/img/vectors/primarydownload.svg'
                                alt='primarydownload'
                                className='w-6'
                            />
                            <span className='pl-2 text-appcolorprimary'>
                                Add Field
                            </span>
                        </div>
                    </div>

                    <Totals
                        {...{
                            totalNetWeight,
                            totalUnitQuantity,
                            totalGrossWeight,
                            totalVolume
                        }}
                    />

                    <SignatureDetails
                        {...{
                            errors,
                            payload,
                            setSignatureUrlBase64,
                            updateField,
                            signatureUrlBase64,
                            setShowAddPartner
                        }}
                    />
                </form>
            </div>
        </div>
    );
};

export default PackingListForm;
