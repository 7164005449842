import React, { Dispatch, SetStateAction, useState } from 'react';
import { Dropdown, Table, Pagination } from 'antd';
import { DocumentTableContainer } from '../../../../styles/TradeDocStyles';
import { useNavigate } from 'react-router-dom';
import {
    formatNumber,
    getContractStatus,
    openNotification
} from '../../../../utils/helper';
import CustomModal from '../../../CustomModal';
import UpdateStatus from '../UpdateStatus';
import {
    deleteSalesContract,
    updateContractStatus
} from '../../../../api/sales/sales';
import { useUserProfile } from '../../../../customhooks/useUserProfile';
import { hasPermission } from '../../dashboardpage/utils';
import ContractsTableMobileCards from './ContractsTableMobileCards';
import { NoDataCross } from '../../../icons/NoDataCross';
import {
    SalesContractParams,
    SalesContracts
} from '../../../../pages/dashboard/payments/interfaces/SalesContracts';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { MoreIcon } from '../../../icons/MoreIcon';

const ContractTable = ({
    loadingData,
    data,
    fetchContracts,
    setCurrentData,
    currentData,
    paging,
    filterParams,
    setFilterParams,
    setViewExport
}: {
    loadingData: boolean;
    data: SalesContracts[];
    setCurrentData: Dispatch<SetStateAction<SalesContracts>>;
    fetchContracts: () => void;
    currentData: SalesContracts;
    paging: { page: number; total: number };
    filterParams: SalesContractParams;
    setFilterParams: Dispatch<SetStateAction<SalesContractParams>>;
    setViewExport: Dispatch<SetStateAction<boolean>>;
}) => {
    const navigate = useNavigate();
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [error, setError] = useState<string | any>(null);
    const [loading, setLoading] = useState(false);
    const { permissions } = useUserProfile();

    const updateFunction = async (val: any) => {
        setLoading(true);
        setError(null);

        try {
            const res = await updateContractStatus(
                currentData?.corporateDocumentId,
                val
            );

            setLoading(false);
            openNotification('success', 'Status updated successfully');
            setShowUpdateModal(false);
            fetchContracts();
        } catch (error) {
            setLoading(false);
            if (error?.response) {
                if (error?.response?.data?.error) {
                    let hasFieldNameAttribute = false;

                    error?.response?.data?.error.map((item: any) => {
                        return Object.keys(item).map((itemInside: string) => {
                            if (itemInside === 'fieldName') {
                                hasFieldNameAttribute = true;

                                return;
                            }
                        });
                    });

                    if (hasFieldNameAttribute) {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>
                                    {element.messageError}
                                </li>
                            );
                        });
                        setError(errorList);
                    } else {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>{element}</li>
                            );
                        });
                        setError(errorList);
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    const getMenuItems = (data: SalesContracts) =>
        [
            <div
                className='flex items-center menu-otps'
                onClick={() =>
                    navigate(
                        `/sales/contracts/details/${data?.corporateDocumentId}`
                    )
                }
            >
                <span className='ml-2'>View Contract Details</span>
            </div>,
            <div
                className={`flex items-center menu-otps ${
                    permissions &&
                    !hasPermission({
                        userPermissions: permissions,
                        module: 'sales',
                        permissionList: ['write', '*']
                    }) &&
                    ` opacity-30 cursor-not-allowed`
                }`}
                onClick={() => {
                    if (
                        permissions &&
                        hasPermission({
                            userPermissions: permissions,
                            module: 'sales',
                            permissionList: ['write', '*']
                        })
                    ) {
                        setShowUpdateModal(true);
                    } else {
                        openNotification(
                            'error',
                            'You do not have access to perform this action'
                        );
                    }
                }}
            >
                <span className='ml-2'>Update Status</span>
            </div>,
            <div
                className={`flex items-center menu-otps ${
                    permissions &&
                    !hasPermission({
                        userPermissions: permissions,
                        module: 'sales',
                        permissionList: ['write', '*']
                    }) &&
                    ` opacity-30 cursor-not-allowed`
                }`}
                onClick={() => {
                    if (
                        permissions &&
                        hasPermission({
                            userPermissions: permissions,
                            module: 'sales',
                            permissionList: ['write', '*']
                        })
                    ) {
                        navigate(
                            `/trade-documents/sales-contract?edit=${data?.corporateDocumentId}`
                        );
                    } else {
                        openNotification(
                            'error',
                            'You do not have access to perform this action'
                        );
                    }
                }}
            >
                <span className='ml-2'>Edit Contract</span>
            </div>,
            <div
                className={`flex items-center menu-otps ${
                    permissions &&
                    !hasPermission({
                        userPermissions: permissions,
                        module: 'sales',
                        permissionList: ['write', '*']
                    }) &&
                    ` opacity-30 cursor-not-allowed`
                }`}
                onClick={() => {
                    if (
                        permissions &&
                        hasPermission({
                            userPermissions: permissions,
                            module: 'sales',
                            permissionList: ['write', '*']
                        })
                    ) {
                        deleteSalesContract(data?.corporateDocumentId);
                        fetchContracts();
                    } else {
                        openNotification(
                            'error',
                            'You do not have access to perform this action'
                        );
                    }
                }}
            >
                <span className='ml-2'>Delete Contract</span>
            </div>
        ].map((comp, key) => ({
            key,
            label: comp
        }));

    const updateOptions = [
        { value: 'APPROVED', label: 'Approved', color: '#6DA75F' },
        { value: 'BOOKING_PLACED', label: 'Booking Placed', color: '#D6DA1C' },
        {
            value: 'STOCK_ALLOCATED',
            label: 'Stock Allocated',
            color: '#061292'
        },
        { value: 'CANCELED', label: 'Canceled', color: '#CB444A' },
        { value: 'COMPLETED', label: 'Completed', color: '#12B76A' },
        { value: 'DRAFT', label: 'Draft', color: '#A0A0A0' },
        { value: 'EXPIRED', label: 'Expired', color: '#000' },
        { value: 'ISSUED', label: 'Issued', color: '#42A5F5' }
    ];

    const EmptyTable = () => (
        <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
            <div className='flex justify-center mb-4'>
                <NoDataCross />
            </div>

            <p className='text-center text-appcolorblackthree'>
                You have no Contracts.
                <br />
            </p>
        </div>
    );

    return (
        <>
            <DocumentTableContainer className='bg-appcolorwhite rounded-none lg:rounded-[8px]'>
                <Table
                    loading={loadingData}
                    pagination={false}
                    locale={{
                        emptyText: () => {
                            return <EmptyTable />;
                        }
                    }}
                    dataSource={data}
                    columns={[
                        {
                            title: 'Shipping Deadline',
                            dataIndex: 'shippingDeadline',
                            key: 'shippingDeadline',
                            render: (_, record) => (
                                <span>
                                    {dayjs(record?.shippingDeadline).format(
                                        'll'
                                    )}
                                </span>
                            )
                        },
                        {
                            title: 'Customer',
                            dataIndex: 'buyerName',
                            key: 'buyerName',
                            render: (text) => (
                                <span className=''>{text ?? '-'}</span>
                            )
                        },
                        {
                            title: 'Contract #',
                            dataIndex: 'salesContractNumber',
                            key: 'salesContractNumber',
                            render: (text) => (
                                <span className=''>{text ?? '-'}</span>
                            )
                        },
                        {
                            title: 'Commodity',
                            dataIndex: 'name',
                            key: 'name',
                            render: (text) => (
                                <span className=''>{text ?? '-'}</span>
                            )
                        },
                        {
                            title: 'Total Quantity',
                            dataIndex: 'quantity',
                            key: 'quantity',
                            render: (_, record) => (
                                <span>
                                    {formatNumber(record?.quantity || 0) +
                                        ' ' +
                                        (record?.metric || '')}
                                </span>
                            )
                        },
                        {
                            title: 'Pending Quantity',
                            dataIndex: 'pendingQuantity',
                            key: 'pendingQuantity',
                            render: (text) => (
                                <span>{formatNumber(text || 0)}</span>
                            )
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                            render: (text) => (
                                <span className=''>
                                    {getContractStatus(text) ?? '-'}
                                </span>
                            )
                        },
                        {
                            title: '',
                            key: 'id',
                            dataIndex: 'id',
                            render: (_, data) => (
                                <div
                                    className='cursor-pointer'
                                    onClick={() => {
                                        setCurrentData(data);
                                    }}
                                >
                                    <Dropdown
                                        menu={{ items: getMenuItems(data) }}
                                        trigger={['click']}
                                        placement='bottomRight'
                                    >
                                        <Box className=' flex flex-row justify-center'>
                                            <MoreIcon />
                                        </Box>
                                    </Dropdown>
                                </div>
                            )
                        }
                    ]}
                    className='hidden lg:block'
                />

                <div className='lg:hidden mt-4'>
                    <ContractsTableMobileCards
                        loading={loadingData}
                        contracts={data}
                        emptyView={<EmptyTable />}
                        fetchContracts={fetchContracts}
                        filterOptions={filterParams}
                        setFilterOptions={setFilterParams}
                        setViewExport={setViewExport}
                        setCurrentData={setCurrentData}
                        items={getMenuItems}
                        paging={paging}
                    />
                </div>
                {data?.length > 0 && (
                    <Box className='lg:flex hidden lg:justify-end bg-white lg:py-5 pt-2 pb-10 flex-col lg:flex-row gap-4 justify-center items-center pr-2'>
                        <Pagination
                            total={paging.total}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            current={paging.page}
                            onChange={(page) => {
                                setFilterParams((props) => ({
                                    ...props,
                                    page
                                }));
                            }}
                        />
                    </Box>
                )}
                {showUpdateModal && (
                    <CustomModal
                        maxWidth='md'
                        open={showUpdateModal}
                        onClose={() => setShowUpdateModal(false)}
                        title=''
                    >
                        <UpdateStatus
                            {...{
                                updateOptions,
                                updateFunction,
                                loading,
                                error,
                                setSelectedStatus,
                                selectedStatus
                            }}
                        />
                    </CustomModal>
                )}
            </DocumentTableContainer>
        </>
    );
};

export default ContractTable;
