import { Box } from '@mui/material';

export const LogisticsInvoiceSummarySkeleton = () => {
    return (
        <Box className='flex justify-between bg-white px-7 py-5 rounded-lg'>
            {[1, 2, 3, 4].map((_, index) => (
                <Box
                    key={index}
                    className='animate-pulse flex space-x-4 grow mr-3'
                >
                    <Box className='grow space-y-6 py-1'>
                        <Box className='space-y-3'>
                            <Box className='h-2 bg-slate-400 rounded'></Box>
                            <Box className='h-2 bg-slate-400 rounded'></Box>
                        </Box>
                    </Box>
                    <Box className='rounded-full bg-slate-400 h-10 w-10'></Box>
                </Box>
            ))}
            <Box className='flex flex-col justify-center'>
                <Box className='h-2 w-10 bg-slate-400 rounded'></Box>
            </Box>
        </Box>
    );
};
