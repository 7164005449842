export const ThreeLayersIcon = () => {
    return (
        <svg
            width='42'
            height='42'
            viewBox='0 0 42 42'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect x='3' y='3' width='36' height='36' rx='18' fill='white' />
            <g clipPath='url(#clip0_3718_180591)'>
                <path
                    d='M13.5 24.75L21 28.5L28.5 24.75M13.5 21L21 24.75L28.5 21M21 13.5L13.5 17.25L21 21L28.5 17.25L21 13.5Z'
                    stroke='#0088B2'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </g>
            <rect
                x='3'
                y='3'
                width='36'
                height='36'
                rx='18'
                stroke='white'
                strokeOpacity='0.2'
                strokeWidth='5.4'
            />
            <defs>
                <clipPath id='clip0_3718_180591'>
                    <rect
                        width='18'
                        height='18'
                        fill='white'
                        transform='translate(12 12)'
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
