import { Grid, MenuItem } from '@mui/material';
import { useState } from 'react';
import SingleCommodityFields from '../salescontract/SingleCommodityFields';
import NewCustomSelect from '../../../../components/newcustomselect/newcustomselect';
import useCommodities from '../../../../customhooks/useCommodities';
import useShippingLines from '../../../../customhooks/shippinglines/userShippinglines';
import Customdatepicker from '../../../../components/customdatepicker/customdatepicker';
import {
    contractTypes,
    methodsOfPayments
} from '../../../../utils/appconstants';
import SalesAddCommodity from '../../../../components/dashboard/tradeDocuments/common/SalesAddCommodity';
import AddNewCommodity from '../addNewCommodity/AddNewCommodity';
import Documentupload from '../../../../components/documentupload/documentupload';
import PortsAutocomplete from '../../../../components/autocompletewithapi/PortsAutocomplete';
import { useGetContainerTypes } from '../../bookings/createbooking/hooks/useGetContainerTypes';
import useCorporatePartners from '../../../../customhooks/useCorporatePartners';
import CustomTextField from '../../../../components/CustomTextInput';

interface IProps {
    error?: any;
    updateField?: any;
    payload?: any;
    errors?: any;
    loading?: boolean;
    commodityFields?: any;
    updateCommodityField?: any;
    removeCommodityField?: any;
    addCommodityField?: any;
    setShowAddPartner?: any;
}

const SalesContractUpload = ({
    error,
    updateField,
    payload,
    loading,
    errors,
    commodityFields,
    updateCommodityField,
    removeCommodityField,
    addCommodityField,
    setShowAddPartner
}: IProps) => {
    const { corporatePartners, isLoading: loadingCorporatepartners } =
        useCorporatePartners({ page: 1 });

    const { commoditiesList, isLoading: isLoadingCommodities } =
        useCommodities();

    const { shippingLines, isLoadingShippingLines } = useShippingLines({});

    const [showAddNewCommodity, setShowAddNewCommodity] = useState(false);

    const closeShowAddParty = () => {
        setShowAddNewCommodity(false);
    };

    const { isLoading, containerTypes } = useGetContainerTypes();

    return (
        <div className=' mx-auto lg:w-full'>
            <Grid container>
                <div className='mt-2 lg:w-full w-[95%] mx-auto'>
                    <Documentupload
                        useAuthorization
                        returnURLOnly
                        title={'Document'}
                        afterUploadUpdate={(
                            itemId: number,
                            documentUrl: string
                        ) => {
                            updateField('documentUrl', documentUrl);
                        }}
                        full={true}
                    />
                </div>
                <Grid item xs={12} md={12} lg={6}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                marginTop: 0
                            }}
                        >
                            <Grid item xs={12} md={12} lg={12}>
                                <div className='mt-4'>
                                    <NewCustomSelect
                                        selectValue={payload.exporterId}
                                        required
                                        selectOptions={
                                            corporatePartners as unknown as Record<
                                                string,
                                                unknown
                                            >[]
                                        }
                                        name={'customerId'}
                                        updateField={(_, value) => {
                                            updateField('exporterId', value);
                                            updateField(
                                                'exporterAddress',
                                                corporatePartners.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.address
                                            );
                                            updateField(
                                                'exporterName',
                                                corporatePartners.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.name
                                            );

                                            updateField(
                                                'exporterCountry',
                                                corporatePartners.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.bankCountry
                                            );
                                        }}
                                        label={'Exporter'}
                                        selectOptionLabel='name'
                                        loading={loadingCorporatepartners}
                                        dropdownRender={() => {
                                            return (
                                                <div className='px-4 border-t border-appcolorgreyfaint'>
                                                    <p
                                                        className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                        onClick={() => {
                                                            setShowAddPartner(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        + Add a New Partner
                                                    </p>
                                                </div>
                                            );
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} mt={2}>
                                <CustomTextField
                                    value={payload?.exporterAddress}
                                    error={errors?.exporterAddress?.length > 0}
                                    helperText={
                                        errors?.exporterAddress.length > 0
                                            ? errors?.exporterAddress
                                            : undefined
                                    }
                                    id='quotation-exporterAddress'
                                    label='Exporter Address'
                                    name='exporterAddress'
                                    onChange={(e: any) => {
                                        updateField(
                                            'exporterAddress',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                        <Grid
                            container
                            spacing={1}
                            sx={{
                                marginTop: 0
                            }}
                        >
                            <Grid item xs={12} md={12} lg={12}>
                                <div className='mt-4'>
                                    <NewCustomSelect
                                        selectValue={payload.customerId}
                                        required
                                        selectOptions={
                                            corporatePartners as unknown as Record<
                                                string,
                                                unknown
                                            >[]
                                        }
                                        name={'customerId'}
                                        updateField={(_, value) => {
                                            updateField('customerId', value);
                                            updateField(
                                                'customerAddress',
                                                corporatePartners.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.address
                                            );
                                            updateField(
                                                'buyerAddress',
                                                corporatePartners.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.address
                                            );

                                            updateField(
                                                'customerName',
                                                corporatePartners.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.name
                                            );
                                            updateField(
                                                'customerBank',
                                                corporatePartners.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.bankName
                                            );
                                            updateField(
                                                'customerSwiftCode',
                                                corporatePartners.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.swiftCode
                                            );
                                            updateField(
                                                'customerCountry',
                                                corporatePartners.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.bankCountry
                                            );
                                            updateField(
                                                'customerBankAccount',
                                                corporatePartners.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.bankAccountNumber
                                            );
                                        }}
                                        label={'Buyer'}
                                        selectOptionLabel='name'
                                        loading={loadingCorporatepartners}
                                        dropdownRender={() => {
                                            return (
                                                <div className='px-4 border-t border-appcolorgreyfaint'>
                                                    <p
                                                        className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                        onClick={() => {
                                                            setShowAddPartner(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        + Add a New Partner
                                                    </p>
                                                </div>
                                            );
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12} mt={2}>
                            <CustomTextField
                                value={payload?.buyerAddress}
                                error={errors?.buyerAddress?.length > 0}
                                helperText={
                                    errors?.buyerAddress?.length > 0
                                        ? errors?.buyerAddress
                                        : undefined
                                }
                                id='quotation-buyerAddress'
                                label='Buyer Address'
                                name='buyerAddress'
                                onChange={(e: any) => {
                                    updateField('buyerAddress', e.target.value);
                                }}
                            />
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12} md={12} lg={4}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[1em] pb-2'>
                        <CustomTextField
                            value={payload?.contractNumber}
                            error={errors?.contractNumber.length > 0}
                            helperText={
                                errors?.contractNumber.length > 0
                                    ? errors?.contractNumber
                                    : undefined
                            }
                            required
                            id='quotation-contractNumber'
                            label='Contract Number'
                            name='contractNumber'
                            onChange={(e: any) => {
                                updateField('contractNumber', e.target.value);
                            }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[1rem] pb-2'>
                        <CustomTextField
                            value={payload?.contractType}
                            select
                            error={errors?.contractType.length > 0}
                            helperText={
                                errors?.contractType.length > 0
                                    ? errors?.contractType
                                    : undefined
                            }
                            required
                            id='quotation-methodOfDispatch'
                            label='Contract Type'
                            name='contractType'
                            onChange={(e: any) => {
                                updateField('contractType', e.target.value);
                                updateField(
                                    'salesContractType',
                                    e.target.value
                                );
                            }}
                        >
                            {contractTypes.map((item: any, index: any) => (
                                <MenuItem
                                    value={item}
                                    key={`medthodsOfDispatch-${item}-${index}`}
                                >
                                    {item}
                                </MenuItem>
                            ))}
                        </CustomTextField>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                        <div className='pt-4'>
                            <Customdatepicker
                                id='quotation-shippingDeadline'
                                label='Shipping Deadline'
                                name='shippingDeadline'
                                updateField={updateField}
                                error={errors?.shippingDeadline.length > 0}
                                helperText={
                                    errors?.shippingDeadline.length > 0
                                        ? errors?.shippingDeadline
                                        : undefined
                                }
                                value={payload?.shippingDeadline || null}
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[1rem] pb-3 h-full'>
                        <PortsAutocomplete
                            errorCondition={errors?.portOfLoadingId?.length > 0}
                            helperText={
                                errors?.portOfLoadingId?.length > 0
                                    ? errors?.portOfLoadingId
                                    : undefined
                            }
                            id='quotation-portOfLoading'
                            label='Port of Loading'
                            name='portOfLoadingId'
                            updateField={updateField}
                            nameValue='portOfLoadingValue'
                            value={payload.portOfLoadingValue}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={12} lg={4}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[1rem] pb-3 h-full'>
                        <NewCustomSelect
                            required
                            selectValue={payload.containerType}
                            selectOptions={
                                containerTypes as unknown as Record<
                                    string,
                                    string | number
                                >[]
                            }
                            name={'containerType'}
                            updateField={(_, value) => {
                                updateField('containerType', value);
                            }}
                            label='Container Type'
                            selectOptionLabel='shortDescription'
                            loading={isLoading}
                            alternativeValue='shortDescription'
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={12} lg={4}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pb-3 h-full'>
                        <CustomTextField
                            select
                            error={errors?.paymentTerms.length > 0}
                            helperText={
                                errors?.paymentTerms.length > 0
                                    ? errors?.paymentTerms
                                    : undefined
                            }
                            required
                            id='salesContract-termsOfPayment'
                            label='Payment Term'
                            name='paymentTerms'
                            value={payload?.paymentTerms}
                            onChange={(e: any) => {
                                updateField('paymentTerms', e.target.value);
                            }}
                        >
                            {methodsOfPayments.map((item, index) => (
                                <MenuItem
                                    value={item}
                                    key={`paymentTerms-${item}-${index}`}
                                >
                                    {item}
                                </MenuItem>
                            ))}
                        </CustomTextField>
                    </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[1rem] pb-3 h-full'>
                        <PortsAutocomplete
                            errorCondition={
                                errors?.portOfDischargeId.length > 0
                            }
                            helperText={
                                errors?.portOfDischargeId.length > 0
                                    ? errors?.portOfDischargeId
                                    : undefined
                            }
                            id='quotation-portOfDischargeId'
                            label='Port of Discharge'
                            name='portOfDischargeId'
                            updateField={updateField}
                            nameValue='portOfDischargeValue'
                            value={payload.portOfDischargeValue}
                        />
                    </div>
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[1rem] pb-3 h-full'>
                        <NewCustomSelect
                            selectValue={payload.shippingLineId}
                            required
                            selectOptions={
                                shippingLines as unknown as Record<
                                    string,
                                    unknown
                                >[]
                            }
                            name={'shippingLineId'}
                            updateField={(_, value) => {
                                updateField('shippingLineId', value);
                                updateField(
                                    'shippingLineValue',
                                    shippingLines.filter(
                                        (item: any) => item?.id === value
                                    )[0]?.name
                                );
                            }}
                            label={'Shipping Line'}
                            selectOptionLabel='name'
                            loading={isLoadingShippingLines}
                        />
                    </div>
                </Grid>

                {commodityFields.map((item: any, index: any) => (
                    <SingleCommodityFields
                        item={item}
                        key={item.id}
                        index={index}
                        id={item.id}
                        updateQuoteField={updateCommodityField}
                        price={item.price}
                        qty={item.qty}
                        errors={errors}
                        removeQuoteField={removeCommodityField}
                        {...{
                            commoditiesList,
                            isLoadingCommodities,
                            setShowAddNewCommodity
                        }}
                    />
                ))}

                <Grid item xs={12} md={12} lg={12}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[1rem] pb-3 h-full'>
                        <SalesAddCommodity
                            {...{
                                addCommodityField
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
            {showAddNewCommodity ? (
                <AddNewCommodity
                    closeDrawer={closeShowAddParty}
                    showDrawer={showAddNewCommodity}
                />
            ) : null}
        </div>
    );
};

export default SalesContractUpload;
