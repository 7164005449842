const MessageEnvelope = () => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M18.3334 4.99998C18.3334 4.08331 17.5834 3.33331 16.6667 3.33331H3.33335C2.41669 3.33331 1.66669 4.08331 1.66669 4.99998M18.3334 4.99998V15C18.3334 15.9166 17.5834 16.6666 16.6667 16.6666H3.33335C2.41669 16.6666 1.66669 15.9166 1.66669 15V4.99998M18.3334 4.99998L10 10.8333L1.66669 4.99998'
                stroke='#667085'
                strokeWidth='1.66667'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default MessageEnvelope;
