import React, { Dispatch, ReactNode, SetStateAction } from 'react';
import {
    InvoiceStates,
    ServiceableInvoice,
    ServiceableInvoiceParams
} from './interfaces/Invoice';
import { Box, Stack } from '@mui/material';
import { MoreIcon } from '../../../components/icons/MoreIcon';
import PaidInvoiceIcon from '../../../components/icons/PaidInvoiceIcon';
import { formatCurrency, getInvoiceStatus } from '../../../utils/helper';
import NotPaidInvoiceIcon from '../../../components/icons/NotPaidInvoiceIcon';
import dayjs from 'dayjs';
import OverdueInvoiceIcon from '../../../components/icons/OverdueInvoiceIcon';
import { LoadingCircles } from '../../../components/icons/LoadingCircles';
import { Checkbox, Dropdown } from 'antd';
import { ExportTransactions } from './ExportTransactions';
import LogisticsInvoiceListFilter from './LogisticsInvoiceListFilter';
import { RefreshTableIcon } from '../../../components/icons/RefreshTableIcon';

const LogisticsInvoicesMobileCards = ({
    invoices,
    loading,
    emptyView,
    selected,
    setSelected,
    getMenuItems,
    filterParams,
    viewExport,
    setViewExport,
    setFilterParams,
    refetchInvoices
}: {
    loading: boolean;
    invoices: ServiceableInvoice[];
    emptyView: ReactNode;
    selected: (number | string)[];
    filterParams: ServiceableInvoiceParams;
    setSelected: Dispatch<SetStateAction<(number | string)[]>>;
    getMenuItems: (
        docId: number,
        id: number,
        status: number
    ) => { key: number; label: false | JSX.Element }[];
    viewExport: boolean;
    setViewExport: (val: boolean) => void;
    setFilterParams: Dispatch<SetStateAction<ServiceableInvoiceParams>>;
    refetchInvoices: () => void;
}) => {
    const getInvoicesMenuOptions = () =>
        [
            <ExportTransactions
                serviceable
                params={filterParams}
                viewExport={viewExport}
                setViewExport={setViewExport}
            />,

            <LogisticsInvoiceListFilter
                {...{ setFilterParams, filterParams }}
            />,
            <div className='tab-buttons flex' onClick={() => refetchInvoices()}>
                <div className='pr-2'>
                    <RefreshTableIcon />
                </div>
                <p style={{ paddingRight: '5px' }}>Refresh</p>
            </div>
        ].map((comp, key) => ({
            key,
            label: comp
        }));
    return (
        <>
            <Box
                display='flex'
                width='100%'
                justifyContent='space-between'
                alignItems='center'
                bgcolor='white'
                px={2}
                py={2}
            >
                <div className='flex gap-2 items-center'>
                    <Checkbox
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSelected(
                                    invoices
                                        .filter(
                                            ({ status }) =>
                                                !['CANCELLED', 'PAID'].includes(
                                                    getInvoiceStatus(
                                                        status
                                                    ).toUpperCase()
                                                )
                                        )
                                        .map(
                                            ({ corporateDocumentId }) =>
                                                corporateDocumentId
                                        )
                                );

                                return;
                            }

                            setSelected([]);
                        }}
                        checked={
                            !!selected.length &&
                            selected.length ===
                                invoices.filter(({ status }) =>
                                    ['CANCELLED', 'PAID'].includes(
                                        getInvoiceStatus(status).toUpperCase()
                                    )
                                ).length
                        }
                    />
                    <p className=' text-ebony text-[17px] font-semibold'>
                        Invoices
                    </p>
                </div>

                <Dropdown
                    menu={{
                        items: getInvoicesMenuOptions()
                    }}
                    trigger={['click']}
                >
                    <Box className=' flex flex-row justify-center'>
                        <MoreIcon />
                    </Box>
                </Dropdown>
            </Box>
            <Stack
                direction='column'
                gap={4}
                className='lg:hidden bg-white p-4 pt-0'
            >
                {invoices?.length === 0 || loading ? (
                    <Box className='flex w-full justify-center items-center h-96 flex-col'>
                        <div className='animate-spin opacity-30  w-max'>
                            <LoadingCircles />
                        </div>
                        {emptyView}
                    </Box>
                ) : (
                    invoices.map((invoice) => (
                        <Box
                            key={invoice.id}
                            borderBottom='1px solid#EAECF0'
                            borderTop='1px solid#EAECF0'
                            pb={1}
                        >
                            <Box
                                display='flex'
                                width='100%'
                                justifyContent='space-between'
                                alignItems='center'
                                bgcolor='#FAFAFA'
                                p={0.5}
                            >
                                <Checkbox
                                    disabled={['CANCELLED', 'PAID'].includes(
                                        getInvoiceStatus(
                                            invoice.status
                                        ).toUpperCase()
                                    )}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelected((prev) => [
                                                ...prev,
                                                invoice.corporateDocumentId
                                            ]);

                                            return;
                                        }

                                        setSelected(
                                            selected
                                                .filter(
                                                    (ref) =>
                                                        ref !==
                                                        invoice.corporateDocumentId
                                                )
                                                .map(
                                                    (corporateDocumentId) =>
                                                        corporateDocumentId
                                                )
                                        );
                                    }}
                                    checked={
                                        selected.includes(
                                            invoice.corporateDocumentId
                                        ) &&
                                        !['CANCELLED', 'PAID'].includes(
                                            getInvoiceStatus(
                                                invoice.status
                                            ).toUpperCase()
                                        )
                                    }
                                />
                                <Dropdown
                                    menu={{
                                        items: getMenuItems(
                                            invoice.corporateDocumentId as number,
                                            invoice.id,
                                            invoice.status
                                        )
                                    }}
                                    trigger={['click']}
                                >
                                    <Box className=' flex flex-row justify-center'>
                                        <MoreIcon />
                                    </Box>
                                </Dropdown>
                            </Box>
                            <Box display='flex' gap={1} pt={2}>
                                {getInvoiceStatus(
                                    invoice.status
                                ).toUpperCase() === 'PAID' ? (
                                    <PaidInvoiceIcon />
                                ) : getInvoiceStatus(
                                      invoice.status
                                  ).toUpperCase() === 'OVERDUE' ? (
                                    <OverdueInvoiceIcon />
                                ) : (
                                    <NotPaidInvoiceIcon />
                                )}
                                <Box>
                                    <h4 className=' text-ebony text-[14px] font-semibold'>
                                        {invoice.beneficiaryName}
                                    </h4>
                                    <p className=' text-[12px] text-paleSky'>
                                        Amount:
                                        <span className=' pl-1 pr-3 font-extrabold'>
                                            {formatCurrency(
                                                invoice.totalAmount,
                                                invoice.currencyCode
                                            )}
                                        </span>
                                        Status:
                                        <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                            {
                                                InvoiceStates[
                                                    getInvoiceStatus(
                                                        invoice.status
                                                    ).toUpperCase() as keyof typeof InvoiceStates
                                                ]
                                            }
                                        </span>
                                    </p>
                                    <p className=' text-[12px] text-paleSky'>
                                        Invoice No:
                                        <span className=' pl-1 pr-3 font-extrabold'>
                                            {invoice.invoiceNumber}
                                        </span>
                                    </p>
                                    <p className=' text-[12px] text-paleSky'>
                                        Due Date:
                                        <span className=' pl-1 pr-3 font-extrabold'>
                                            {dayjs(invoice.dueDate).format(
                                                'DD MMM, YYYY'
                                            )}
                                        </span>
                                    </p>
                                </Box>
                            </Box>
                        </Box>
                    ))
                )}
            </Stack>
        </>
    );
};

export default LogisticsInvoicesMobileCards;
