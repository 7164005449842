import { useDispatch, useSelector } from 'react-redux';
import OtpInput from 'react-otp-input';
import {
    AppState,
    setMfaLoading,
    setShowMfaModal
} from '../store/appSlice/appSlice';
import CustomModal from './CustomModal';
import { useState } from 'react';
import { SuitUIButton } from './SuitUIButton';
import { RootState } from '../store/store';

const MfaPinModal = () => {
    const dispatch = useDispatch();
    const appDetails = useSelector((state: { app: AppState }) => state.app);

    const email = useSelector(
        (state: RootState) => state.user.user?.user_email
    );

    const [otp, setOtp] = useState('');

    const onClose = () => {
        dispatch(
            setShowMfaModal({
                showMfaModal: false,
                mfaAction: () => {}
            })
        );
        dispatch(setMfaLoading(true));
    };

    return (
        <CustomModal
            maxWidth='sm'
            open={appDetails.showMfaModal}
            onClose={onClose}
            title=''
            hideCloseIcon
        >
            <div className='px-4 py-[24px] lg:w-[414px]'>
                <p className='text-paleSky font-medium text-base '>
                    Please enter the 4 digit code sent to “{email}”
                </p>

                <div className='my-[24px]'>
                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderInput={(props) => <input {...props} />}
                        shouldAutoFocus
                        containerStyle={{ justifyContent: 'space-between' }}
                        inputStyle={{
                            width: '56px',
                            height: '56px',
                            marginRight: '24px',
                            border: '1px solid #EAECF0',
                            borderRadius: '8px'
                        }}
                    />
                </div>

                <SuitUIButton
                    text='Continue'
                    onClick={() => appDetails.mfaAction(otp)}
                    fullWidth
                    disabled={otp.length < 4 || appDetails.loading}
                    isLoading={appDetails.loading}
                />
            </div>
        </CustomModal>
    );
};

export default MfaPinModal;
