import { useState, useEffect } from 'react';
import Custombutton from '../../../../../components/custombutton/custombutton';
import useCurrencies from '../../../../../customhooks/useCurrencies';
import Paymentinput from '../../../../../components/paymentinput/paymentinput';
import Tooltip from '@mui/material/Tooltip';
import { getUnion } from '../../../../../utils/helper';
import Skeleton from '@mui/material/Skeleton';
import {
    NGNTradeFXLimit,
    USDTradeFXLimit
} from '../../../../../utils/appconstants';
import { PaymentRequest } from '../../interfaces/PaymentRequest';
import { Wallet } from '../../interfaces/Wallet';
import InfoDropdown from '../../shared/InfoDropdown';
import useTimer from 'easytimer-react-hook';

export interface placerequestProps {
    activeStep: number;
    updateStep: (activeStep: number) => void;
    requestObject: PaymentRequest;
    updateRequestObject: (value: PaymentRequest) => void;
    rate: number;
    loadRate: boolean;
    reRunRateCallAction: () => void;
    conversionType: number;
    wallets: Wallet[];
    isWalletsLoading: boolean;
}

const infoList = [
    {
        label: 'Guaranteed Rate',
        text: 'Exchange rates are constantly changing during trading hours, FrontEdge strives to convert your money at the best rate available.'
    },
    {
        label: 'No transaction Fees',
        text: 'FrontEdge does not charge any fees whilst processing your payments.'
    },
    {
        label: 'Est Delivery 24hrs',
        text: 'FrontEdge executes your transfers as fast as we can within this time frame, but the delivery date is not a guarantee.'
    }
];

const Placerequest = ({
    activeStep,
    updateStep,
    requestObject,
    updateRequestObject,
    rate,
    loadRate,
    reRunRateCallAction,
    conversionType,
    wallets,
    isWalletsLoading
}: placerequestProps) => {
    const [timer] = useTimer({
        countdown: true,

        startValues: { seconds: 30 }
    });
    const { isLoading: isLoadingCurrencies, currencies } = useCurrencies();

    const [errors, setErrors] = useState({
        deductFrom: '',
        convert: '',
        receive: ''
    });
    const [rerunRateCall, setRerunRateCall] = useState(false);
    const [finalUnion, setFinalUnion] = useState<any>([]);

    const handleConversionAmountChange = (value: number) => {
        if (rate === 0) return;

        updateRequestObject({
            ...requestObject,
            convertAmount: value,
            receiveAmount: value * rate
        });
    };

    const handleConversionCurrencyChange = (value: string) => {
        const selectedWallet = wallets?.find(
            (item) => item.currencyCode === value
        );

        if (!selectedWallet) {
            setErrors((prevState) => ({
                ...prevState,
                deductFrom: `You don't have a wallet for ${value}`
            }));
        } else {
            updateRequestObject({
                ...requestObject,
                convertCurrency: value
            });
        }
    };

    const handleRecipientAmountChange = (value: number) => {
        if (rate === 0) return;

        updateRequestObject({
            ...requestObject,
            receiveAmount: value,
            convertAmount: value / rate
        });
    };

    const validateToNextStep = () => {
        const newErrors = { ...errors };
        newErrors['convert'] = '';
        newErrors['receive'] = '';
        newErrors['deductFrom'] = '';

        const convertFromBalance =
            wallets.find(
                (item) => item.currencyCode === requestObject.convertCurrency
            )?.walletBalance || 0;

        if (
            requestObject.convertCurrency === 'NGN' &&
            requestObject.convertAmount < NGNTradeFXLimit
        ) {
            newErrors[
                'convert'
            ] = `The minimun conversion amount for NGN is ₦${NGNTradeFXLimit.toLocaleString()}`;
        }

        if (
            requestObject.convertCurrency === 'USD' &&
            requestObject.convertAmount < USDTradeFXLimit
        ) {
            newErrors[
                'convert'
            ] = `The minimun conversion amount for USD is $${USDTradeFXLimit.toLocaleString()}`;
        }

        if (requestObject.convertAmount > convertFromBalance) {
            newErrors['convert'] =
                'Insufficient funds. Click on "Add Funds" ↗ to fund account.';
        }

        if (
            conversionType === 2 &&
            requestObject.convertCurrency === requestObject.receiveCurrency
        ) {
            newErrors['receive'] = 'Cannot convert to the same currency';
        }

        if (Object.values(newErrors).every((item) => item === '')) {
            updateStep(activeStep + 1);
        } else {
            setErrors((prevState) => ({
                ...prevState,
                ...newErrors
            }));
        }
    };

    /**
     * start the timer after component loads
     * only if there is a valid rate provided
     */
    useEffect(() => {
        if (rate !== 0 && !rerunRateCall) {
            timer.stop();
            timer.start();
            setRerunRateCall(false);
        }
    }, [rate, rerunRateCall]);

    /**
     * triggers the timer watch
     * Countedown watch tower
     */
    useEffect(() => {
        if (timer.getTimeValues().toString() === '00:00:00') {
            setRerunRateCall(true);
            timer.stop();
        }
    }, [timer.getTimeValues().toString()]);

    useEffect(() => {
        setFinalUnion(getUnion(wallets, currencies, 'currencyCode'));
    }, [currencies, wallets]);

    return (
        <div>
            <p className='hidden lg:block text-[19px] leading-6 pt-6'>
                Place Request
            </p>

            <div className='px-[16px] lg:px-[0] py-[16px] lg:py-[2.8rem] ]'>
                <Paymentinput
                    currencies={finalUnion}
                    loadingCurrencies={isLoadingCurrencies}
                    label='I want to send'
                    name='convert'
                    error={errors.convert.length > 0}
                    helperText={
                        errors.convert.length > 0 ? errors.convert : undefined
                    }
                    amount={requestObject.convertAmount}
                    setAmount={handleConversionAmountChange}
                    currency={requestObject.convertCurrency}
                    setCurrency={handleConversionCurrencyChange}
                />

                <div className='hidden lg:block py-8'>
                    <div className='flex flex-col center lg:justify-between lg:flex-row'>
                        <div>
                            <div className='flex items-center'>
                                <div>
                                    <div className='custom-line-with-dots'></div>
                                </div>
                                <div className='ml-2'>
                                    <p
                                        className={`font-[15px] leading-6  ${
                                            loadRate ? '' : 'mb-2 '
                                        }`}
                                    >
                                        At FrontEdge Rate
                                    </p>

                                    {requestObject.convertAmount &&
                                    requestObject.convertCurrency &&
                                    requestObject.receiveAmount &&
                                    requestObject.receiveCurrency ? (
                                        <>
                                            {loadRate ? (
                                                <Skeleton
                                                    variant='text'
                                                    sx={{ fontSize: '2em' }}
                                                />
                                            ) : (
                                                <>
                                                    <p className='mb-2 text-[19px] leading-6 font-bold text-appcolorprimary'>
                                                        1 &nbsp;
                                                        {
                                                            requestObject.convertCurrency
                                                        }
                                                        &nbsp; ={' '}
                                                        {Number(rate).toFixed(
                                                            7
                                                        )}
                                                        &nbsp;
                                                        {
                                                            requestObject.receiveCurrency
                                                        }
                                                    </p>

                                                    <p className='mb-2 font-[15px] leading-6'>
                                                        {Number(
                                                            1 / rate
                                                        ).toFixed(7)}{' '}
                                                        {
                                                            requestObject.convertCurrency
                                                        }{' '}
                                                        = 1{' '}
                                                        {
                                                            requestObject.receiveCurrency
                                                        }
                                                    </p>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <p>Select currencies to view rate</p>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='lg:w-[31%] w-full'>
                            <div className='bg-appcolorprimaryfaintthree py-[1.6rem] px-[1.1rem] text-left'>
                                {infoList.map((item) => (
                                    <div className='flex justify-end mb-2'>
                                        <p className='pr-2'>{item.label} </p>
                                        <Tooltip
                                            arrow
                                            title={item.text}
                                            placement='right'
                                        >
                                            <img
                                                src='/img/vectors/infosquare.svg'
                                                alt='infosquare'
                                            />
                                        </Tooltip>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {!rate || loadRate ? (
                    <Skeleton variant='text' sx={{ fontSize: '2em' }} />
                ) : (
                    <div className='my-[16px] py-[8px] px-[16px] border border-dashed border-0 border-l border-[#7F7F7F] lg:hidden'>
                        <p className='text-base leading-6 text-appcolormainblack'>
                            1 &nbsp; {requestObject.convertCurrency}
                            &nbsp; = {rate}
                            &nbsp;
                            {requestObject.receiveCurrency}
                        </p>
                        <p className='text-lg leading-7 font-bold text-appcolorfaintsix'>
                            1 {requestObject.receiveCurrency} ={' '}
                            {Number(1 / rate).toFixed(7)}
                            {requestObject.convertCurrency}
                        </p>
                        <p className='text-xs leading-6 text-paleSky'>
                            live exchange rate, refreshes every 30 secs
                        </p>
                    </div>
                )}

                <Paymentinput
                    currencies={finalUnion}
                    label='Recipient gets'
                    loadingCurrencies={isLoadingCurrencies}
                    name='receive'
                    error={errors.receive.length > 0}
                    helperText={
                        errors.receive.length > 0 ? errors.receive : undefined
                    }
                    amount={requestObject.receiveAmount}
                    setAmount={handleRecipientAmountChange}
                    currency={requestObject.receiveCurrency}
                    setCurrency={(receiveCurrency) =>
                        updateRequestObject({
                            ...requestObject,
                            receiveCurrency
                        })
                    }
                />

                <div className='mt-4'>
                    {rerunRateCall ? (
                        <>
                            <Custombutton
                                type='submit'
                                fullWidth
                                variant='contained'
                                disabled={false}
                                buttonText='Refresh Rate'
                                styles={{
                                    padding: '0.75rem 0.62rem 1rem 0.93rem',
                                    backgroundColor: 'rgba(109, 167, 95, 1)',
                                    '&:hover': {
                                        backgroundColor: 'rgba(109, 167, 95, 1)'
                                    }
                                }}
                                onClickAction={() => {
                                    setRerunRateCall(false);
                                    reRunRateCallAction();
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <Custombutton
                                isLoadingButton
                                loading={false}
                                type='submit'
                                fullWidth
                                variant='contained'
                                disabled={
                                    isWalletsLoading ||
                                    isLoadingCurrencies ||
                                    loadRate
                                }
                                buttonText='Continue'
                                styles={{
                                    padding: '0.75rem 0.62rem 1rem 0.93rem'
                                }}
                                onClickAction={() => {
                                    validateToNextStep();
                                }}
                            />
                        </>
                    )}
                </div>

                {rerunRateCall && (
                    <p className='text-appcolorred pt-[0.8rem]'>
                        Click on refresh to get current FX-Rate
                    </p>
                )}
            </div>

            <div className='lg:hidden px-[16px] flex flex-col gap-[16px] mb-[80px]'>
                {infoList.map((item) => (
                    <InfoDropdown text={item.text} label={item.label} />
                ))}
            </div>
        </div>
    );
};

export default Placerequest;
