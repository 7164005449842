export const TruckIcon = () => {
    return (
        <svg
            width='23'
            height='20'
            viewBox='0 0 23 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M14.9012 13.9011V1.79395H0.931396V13.9011H14.9012ZM14.9012 13.9011H21.4204V9.24449L18.6265 6.45054H14.9012V13.9011ZM7.45063 16.2294C7.45063 17.5153 6.40821 18.5577 5.12233 18.5577C3.83645 18.5577 2.79403 17.5153 2.79403 16.2294C2.79403 14.9435 3.83645 13.9011 5.12233 13.9011C6.40821 13.9011 7.45063 14.9435 7.45063 16.2294ZM19.5578 16.2294C19.5578 17.5153 18.5154 18.5577 17.2295 18.5577C15.9436 18.5577 14.9012 17.5153 14.9012 16.2294C14.9012 14.9435 15.9436 13.9011 17.2295 13.9011C18.5154 13.9011 19.5578 14.9435 19.5578 16.2294Z'
                stroke='#101828'
                strokeWidth='1.86264'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
