import { ReactNode } from 'react';
import { SuitUIButton } from '../../../../components/SuitUIButton';

export interface dashboardcardsProps {
    title: ReactNode;
    subTitle: ReactNode;
    getStarted: () => void;
    imgIcon: string;
}

const Dashboardcards = ({
    title,
    subTitle,
    getStarted,
    imgIcon
}: dashboardcardsProps) => {
    return (
        <div className='py-[24px] px-[24px] min-w-[calc(100vw-64px)] border border-lightGray rounded-[8px] lg:rounded-[0] mr-[16px] sm:min-w-[50vw] lg:min-w-[unset] lg:border-none  lg:w-full lg:py-[0.5rem] lg:px-[1.3rem] bg-appcolorwhite lg:pb-[2.4rem] lg:mb-4 lg:mr-0'>
            <div className='lg:flex justify-end hidden'>
                <img
                    className='cursor-pointer'
                    src='/img/vectors/cross.svg'
                    alt='cross'
                />
            </div>

            <div className='flex items-center justify-between'>
                <div>
                    <div className='text-base leading-[22px] font-medium lg:font-normal mb-[8px] lg:mb-4 text-appcolorblackfour lg:text-[22px] lg:leading-[110%]'>
                        {title}
                    </div>

                    <div className='text-[15px] text-appcolorblackthree leading-[119.5%] font-light'>
                        {subTitle}
                    </div>

                    <div className='mt-[24px] lg:mt-[2.2rem]'>
                        <SuitUIButton
                            text='Get Started'
                            onClick={getStarted}
                            style='rounded-[4px] text-sm lg:bg-appcolorprimary lg:py-[12px] lg:px-[20px] lg:text-appcolorwhite'
                            variant='secondary'
                        />
                    </div>
                </div>

                <div className='hidden lg:block'>
                    <img src={imgIcon} alt='dashicon' />
                </div>
            </div>
        </div>
    );
};

export default Dashboardcards;
