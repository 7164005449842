import { Divider, Stack } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { TabContainerNew } from '../../../../styles/TradeDocStyles';
import { BreadcrumbCaret } from '../../../../components/icons/BreadcrumbCaret';

interface breadcrumb {
    title: string;
    link?: string;
    active: boolean;
}

interface HeaderProps {
    breadcrumbs: breadcrumb[];
    setActiveView: Dispatch<SetStateAction<string>>;
    activeView: string;
}

const DedicatedAccountHeader = ({
    breadcrumbs,
    setActiveView,
    activeView
}: HeaderProps) => {
    const navigate = useNavigate();

    return (
        <div className='form-header'>
            <div className='breadcrumbs'>
                {breadcrumbs?.map((item, index) => (
                    <div
                        className='flex gap-2 items-center'
                        key={`account-breadcrumb-${index}`}
                    >
                        <p
                            className={
                                item?.active
                                    ? 'breadcrumb-text active'
                                    : 'breadcrumb-text'
                            }
                            onClick={() => {
                                if (item.link !== undefined) {
                                    navigate(item?.link);
                                }
                            }}
                        >
                            {item.title}
                        </p>
                        {index !== breadcrumbs.length - 1 && (
                            <BreadcrumbCaret />
                        )}
                    </div>
                ))}
            </div>
            <div className='header-action'>
                <h1 className='text-appcolorblacktwo'>
                    Dedicated Account Requests
                </h1>
            </div>

            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                className='header-action'
            >
                <Stack
                    direction='row'
                    justifyContent='space-between'
                    width='100%'
                >
                    <TabContainerNew>
                        <Stack direction='row' spacing={'5px'}>
                            <div
                                className='tab-buttons'
                                style={{
                                    cursor: 'pointer',
                                    background:
                                        activeView === 'all'
                                            ? '#0088b21a'
                                            : 'unset',

                                    color:
                                        activeView === 'all'
                                            ? '#0088b2'
                                            : 'initial',

                                    fontWeight:
                                        activeView === 'all' ? '500' : '400'
                                }}
                                onClick={() => setActiveView('all')}
                            >
                                <p
                                    style={{
                                        paddingRight: '5px'
                                    }}
                                >
                                    All Requests
                                </p>
                            </div>

                            <Divider orientation='vertical' flexItem />
                            <div
                                className='tab-buttons'
                                style={{
                                    cursor: 'pointer',
                                    background:
                                        activeView === 'pending'
                                            ? '#0088b21a'
                                            : 'unset',

                                    color:
                                        activeView === 'pending'
                                            ? '#0088b2'
                                            : 'initial',

                                    fontWeight:
                                        activeView === 'pending' ? '500' : '400'
                                }}
                                onClick={() => setActiveView('pending')}
                            >
                                <p style={{ paddingRight: '5px' }}>Pending</p>
                            </div>

                            <Divider orientation='vertical' flexItem />
                            <div
                                className='tab-buttons'
                                style={{
                                    cursor: 'pointer',
                                    background:
                                        activeView === 'approved'
                                            ? '#0088b21a'
                                            : 'unset',

                                    color:
                                        activeView === 'approved'
                                            ? '#0088b2'
                                            : 'initial',

                                    fontWeight:
                                        activeView === 'approved'
                                            ? '500'
                                            : '400'
                                }}
                                onClick={() => setActiveView('approved')}
                            >
                                <p style={{ paddingRight: '5px' }}>Approved</p>
                            </div>
                        </Stack>
                    </TabContainerNew>
                </Stack>
            </Stack>
        </div>
    );
};

export default DedicatedAccountHeader;
