import React, { useEffect, useRef, useState } from 'react';
import { SalesContainer } from '../../../../styles/SalesStyles';
import { Backdrop, Skeleton, Stack } from '@mui/material';
import ContractDetailsSummary from '../../../../components/dashboard/sales/contracts/ContractDetailsSummary';
import { useNavigate, useParams } from 'react-router-dom';
import ContractDetailsTable from '../../../../components/dashboard/sales/contracts/ContractDetailsTable';
import ContractPreview from '../../../../components/dashboard/sales/contracts/ContractPreview';
import { hasPermission } from '../../../../components/dashboard/dashboardpage/utils';
import { useUserProfile } from '../../../../customhooks/useUserProfile';
import { useGetQuery } from '../../../../customhooks/useGetQuery';
import { getContractStatus, openNotification } from '../../../../utils/helper';
import { SalesContracts } from '../../payments/interfaces/SalesContracts';
import { EyesIcon } from '../../../../components/icons/EyeIcon';
import { EditIcon } from '../../../../components/icons/EditIcon';
import { SuitUITabContainer } from '../../../../components/SuiteUITabContainer';
import { SuitUIButton } from '../../../../components/SuitUIButton';
import { Booking } from '../../bookings/interfaces/Booking';

const ContractDetails = () => {
    const { contractNumber } = useParams();

    const {
        isLoading: isBookingLoading,
        entries: bookingData,
        isError: isBookingError
    } = useGetQuery<{ content: Booking[] }>({
        url: `/sales/contract/booking/${contractNumber}`,
        cacheKey: `contract-details-${contractNumber}`,
        enabled: Boolean(contractNumber)
    });

    const {
        isLoading: isDetailsLoading,
        entries: data,
        isError: isDetailsError
    } = useGetQuery<SalesContracts>({
        url: `/sales/documents/${contractNumber}`,
        cacheKey: `sales-document-details-${contractNumber}`,
        enabled: Boolean(contractNumber)
    });

    useEffect(() => {
        if (isDetailsError) {
            openNotification('error', `Contract Details, Something went wrong`);
        }
        if (isBookingError) {
            openNotification('error', `Contract Booking, Something went wrong`);
        }
    }, [isDetailsError, isBookingError]);

    const navigate = useNavigate();

    const [activeView, setActiveView] = useState('Contract Line Items');

    const [visibleDocument, setVisibleDocument] = useState(false);

    const closeCurrentDocModal = () => {
        setVisibleDocument(false);
    };
    const [downloadLoading, setDownloadLoading] = useState(false);
    const myDiv = useRef<HTMLDivElement | null>(null);
    const { permissions } = useUserProfile();

    const tabs = [
        {
            label: 'Preview Contract',
            onHandleClick: () => {
                setVisibleDocument(true);
            },
            icon: <EyesIcon size={20} color='#000' />,
            view:
                permissions &&
                hasPermission({
                    userPermissions: permissions,
                    module: 'sales',
                    permissionList: ['write', '*', 'read']
                })
        },
        {
            label: 'Edit Contract',
            onHandleClick: () => {
                navigate(
                    `/trade-documents/sales-contract?edit=${contractNumber}`
                );
            },
            icon: <EditIcon />,
            view:
                permissions &&
                hasPermission({
                    userPermissions: permissions,
                    module: 'sales',
                    permissionList: ['write', '*']
                })
        }
    ];

    return (
        <>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={isDetailsLoading || isBookingLoading}
            />
            <div className='min-h-screen bg-white lg:bg-transparent'>
                <SalesContainer>
                    <div className='page-header hidden lg:flex'>
                        <p
                            className='back-btn'
                            onClick={() => navigate('/sales/contracts')}
                        >
                            {backBtn} Back to All Contracts
                        </p>

                        <SuitUITabContainer {...{ tabs }} />
                    </div>
                    <div className='px-4 mt-4 lg:hidden'>
                        <p
                            className='back-btn flex gap-3 text-[20px] items-center'
                            onClick={() => navigate('/sales/contracts')}
                        >
                            {backBtn} Contracts
                        </p>
                    </div>

                    {data && <ContractDetailsSummary details={data} />}
                    <div className=' p-4 flex flex-wrap gap-2 lg:hidden '>
                        {tabs.map(
                            ({ onHandleClick, icon, label, view }, key) =>
                                view && (
                                    <SuitUIButton
                                        key={key}
                                        text={label}
                                        onClick={onHandleClick}
                                        invert
                                        fullWidth
                                        iconLeft={icon}
                                        style='flex-1 border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                                    />
                                )
                        )}
                    </div>
                    <div className='w-full lg:hidden'>
                        <div className='px-4 pt-8 pb-10 bg-appcolorgrey'>
                            <h4 className=' text-[16px] font-bold border-b-[1px] border-appcolorfaintnine pb-2'>
                                Contract Details
                            </h4>

                            <Stack
                                width='100%'
                                direction='row'
                                className='flex-row flex justify-between py-[10px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                            >
                                <p className='text-appcolorblacktwo'>
                                    Port of Loading{' '}
                                </p>

                                <p className='font-medium'>
                                    {data?.portOfLoading?.name || '--'}
                                </p>
                            </Stack>

                            <Stack
                                width='100%'
                                direction='row'
                                className='flex-row flex justify-between py-[10px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                            >
                                <p className='text-appcolorblacktwo'>
                                    Port of Discharge{' '}
                                </p>

                                <p className='font-medium'>
                                    {data?.portsOfDestination?.[0]?.name ||
                                        '--'}
                                </p>
                            </Stack>

                            <Stack
                                width='100%'
                                direction='row'
                                className='flex-row flex justify-between py-[10px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                            >
                                <p className='text-appcolorblacktwo'>
                                    Payment Terms{' '}
                                </p>

                                <p className='font-medium'>
                                    {data?.paymentTerms || '--'}
                                </p>
                            </Stack>

                            <Stack
                                width='100%'
                                direction='row'
                                className='flex-row flex justify-between py-[10px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                            >
                                <p className='text-appcolorblacktwo'>
                                    Shipping Line{' '}
                                </p>

                                <p className='font-medium'>
                                    {data?.shippingLines?.[0]?.name || '--'}
                                </p>
                            </Stack>

                            <Stack
                                width='100%'
                                direction='row'
                                className='flex-row flex justify-between pt-[10px] text-[14px]'
                            >
                                <p className='text-appcolorblacktwo'>Status </p>

                                <p className='font-medium'>
                                    {getContractStatus(data?.status) || '--'}
                                </p>
                            </Stack>
                        </div>
                    </div>
                    <ContractDetailsTable
                        {...{ setActiveView, activeView }}
                        data={data}
                        bookingData={bookingData}
                        loadingData={isDetailsLoading || isBookingLoading}
                    />

                    {data && (
                        <ContractPreview
                            {...{
                                closeCurrentDocModal,
                                visibleDocument,
                                myDiv,
                                setDownloadLoading,
                                downloadLoading
                            }}
                            currentData={data}
                        />
                    )}
                </SalesContainer>
            </div>

            <div className='hidden min-h-screen py-4 bg-white'>
                {isDetailsLoading ||
                    (isBookingLoading && (
                        <Skeleton
                            variant='rectangular'
                            height={'35rem'}
                            className='mx-4'
                        />
                    ))}
            </div>
        </>
    );
};

export default ContractDetails;
export const backBtn = (
    <svg
        width='5'
        height='8'
        viewBox='0 0 5 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M4.94336 6.97533L1.8867 4.30075L4.94336 1.62618L4.00005 0.800751L4.86374e-05 4.30075L4.00005 7.80075L4.94336 6.97533Z'
            fill='#333333'
        />
    </svg>
);
const eye = (
    <svg
        width='23'
        height='23'
        viewBox='0 0 23 23'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_4305_317043)'>
            <path
                d='M1.10547 11.3007C1.10547 11.3007 4.77214 3.96741 11.1888 3.96741C17.6055 3.96741 21.2721 11.3007 21.2721 11.3007C21.2721 11.3007 17.6055 18.6341 11.1888 18.6341C4.77214 18.6341 1.10547 11.3007 1.10547 11.3007Z'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M11.1888 14.0507C12.7076 14.0507 13.9388 12.8195 13.9388 11.3007C13.9388 9.78196 12.7076 8.55074 11.1888 8.55074C9.67002 8.55074 8.4388 9.78196 8.4388 11.3007C8.4388 12.8195 9.67002 14.0507 11.1888 14.0507Z'
                stroke='black'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_4305_317043'>
                <rect
                    width='22'
                    height='22'
                    fill='white'
                    transform='translate(0.188965 0.300751)'
                />
            </clipPath>
        </defs>
    </svg>
);
