import React, { useState } from 'react';
import { TabContainerNew } from '../../../../styles/TradeDocStyles';
import { Divider, Stack, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../CustomModal';
import ContractExport from './ContractExport';
import FilterContract from './FilterContract';
import { Dropdown, MenuProps } from 'antd';
import Custombutton from '../../../custombutton/custombutton';
import SalesContractUpload from '../../../../pages/dashboard/tradedocuments/addtradedocument/SalesContractUpload';
import { nanoid } from '@reduxjs/toolkit';
import { openNotification } from '../../../../utils/helper';
import { createSalesContract } from '../../../../api/documents/documents';
import Addpartnerdrawer from '../../../../pages/dashboard/tradedocuments/addpartnerdrawer/addpartnerdrawer';
import { add, download } from '../../../customIcons';
import { useUserProfile } from '../../../../customhooks/useUserProfile';
import { hasPermission } from '../../dashboardpage/utils';

interface IProps {
    setViewExport?: any;
    viewExport?: boolean;
    setFilterOptions?: any;
    filterOptions?: any;
    fetchContracts?: any;
    commoditiesList?: any;
    corporatePartners?: any;
    fetchPartners?: any;
}

type commodityInterface = {
    id: string;
    commodity: string;
    commodityType: string;
    quality: string;
    quantity: number;
    hsCode: string;
    quantityBuffer: string;
    commodityId: string;
    metric: string;
};

const ContractTabs = ({
    setViewExport,
    viewExport,
    setFilterOptions,
    filterOptions,
    fetchContracts,
    commoditiesList,
    corporatePartners,
    fetchPartners
}: IProps) => {
    const navigate = useNavigate();
    const closeShowAddModal = () => {
        setViewExport(false);
    };

    const closeVisibleModal = () => {
        setVisible(false);
    };
    const { permissions } = useUserProfile();

    const [visible, setVisible] = useState(false);
    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <p
                    onClick={() => {
                        setVisible(true);
                        // closeUploadOrCreate()
                    }}
                >
                    Upload
                </p>
            )
        },
        {
            key: '2',
            label: (
                <p
                    onClick={() => {
                        navigate('/trade-documents/sales-contract');
                    }}
                >
                    Create
                </p>
            )
        }
    ];

    const [payloadSales, setPayloadSales] = useState<any>({
        documentName: '',
        contractNumber: nanoid(9),
        contractType: '',
        commodities: [],
        shippingDeadline: Date.now(),
        containerType: '',
        portOfDischargeIds: [],
        portOfDischargeValue: undefined,
        portOfLoadingValue: undefined,
        portOfLoadingId: undefined,
        shippingLineIds: [],
        customerId: '',
        paymentTerms: '',
        shippingLineId: '',
        buyerAddress: '',
        exporterId: '',
        exporterAddress: '',
        additionalInfo: ''
    });

    const [errorsSales, setErrorsSales] = useState({
        clientId: '',
        contractNumber: '',
        paymentTerms: '',
        contractType: '',
        commodity: '',
        commodityArr: '',
        commodityId: '',
        shippingDeadline: '',
        quantity: '',
        quantityUnit: '',
        containerType: '',
        contractStatus: '',
        portOfDischargeId: '',
        portOfDischargeValue: '',
        logoUrl: '',
        salesContractType: '',
        shippingLineId: '',
        location: '',
        buyerAddress: '',
        exporterId: '',
        exporterAddress: '',
        additionalInfo: ''
    });
    const [error, setError] = useState<string | any>(null);
    const [loading, setLoading] = useState(false);

    const [signatureUrlBase64, setSignatureUrlBase64] = useState('');
    const [signatureUrlBase64Exporter, setSignatureUrlBase64Exporter] =
        useState('');
    const [commodityFields, setCommodityFields] = useState<
        commodityInterface[]
    >([
        {
            id: nanoid(9),
            commodityId: '',
            commodity: '',
            commodityType: '',
            quality: '',
            quantity: 0,
            hsCode: '',
            quantityBuffer: '',
            metric: ''
        }
    ]);

    const [showAddPartner, setShowAddPartner] = useState(false);

    const closeShowAddParty = (refreshList?: boolean) => {
        setShowAddPartner(false);
        if (refreshList) {
            fetchPartners();
        }
    };

    /**
     * Update quotes input fields
     * @param id string
     * @param name string
     * @param value any
     */
    const updateCommodityField = (
        index: number,
        attributeName: string,
        attributeValue?: any
    ) => {
        const newContainers: any[] = [...commodityFields];
        newContainers[index][attributeName] = attributeValue;
        setCommodityFields(newContainers);
    };

    /**
     * Adds quotes input field line
     */
    const addCommodityField = () => {
        setCommodityFields([
            ...commodityFields,
            {
                id: nanoid(9),
                commodityId: '',
                commodity: '',
                commodityType: '',
                quality: '',
                quantity: 0,
                hsCode: '',
                quantityBuffer: '',
                metric: ''
            }
        ]);
    };
    const updateField = (name: string, value: any) => {
        setPayloadSales((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrorsSales((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));
    };

    const removeCommodityField = (index: number) => {
        setCommodityFields(commodityFields.filter((item, i) => i !== index));
    };

    const generalSubmit = async () => {
        try {
            setLoading(true);
            setError(null);

            setErrorsSales((prevState: any) => ({
                ...prevState,
                documentUrl: '',
                documentName: '',
                salesContractNumber: ''
            }));

            const items: any[] = [];

            const {
                contractNumber,
                contractType,
                shippingDeadline,
                containerType,
                portOfDischargeIds,
                portOfDischargeValue,
                portOfLoadingValue,
                portOfLoadingId,
                shippingLineIds,
                customerId,
                paymentTerms,
                documentName,
                shippingLineId,
                stamp,
                additionalInfo,
                buyerAddress,
                buyerSignature,
                exporterAddress,
                exporterId,
                exporterSignature,
                documentUrl
            } = payloadSales;

            const dischargeValue: any = portOfDischargeValue;
            const portOfLoading: any = portOfLoadingValue;

            commodityFields.forEach((item) => {
                items.push({
                    commodityId: item.commodityId,
                    quantity: item.quantity,
                    quantityBuffer: item.quantityBuffer,
                    metric: item.metric
                });
            });

            const data = {
                createdType: 'UPLOADED',
                documentData: {
                    contractType,
                    buyerId: customerId,
                    salesContractNumber: contractNumber,
                    commodities: commodityFields,
                    shippingDeadline,
                    portOfLoadingId: portOfLoading?.id,
                    containerType,
                    paymentTerms,
                    portOfDischargeIds: [dischargeValue?.id],
                    shippingLineIds: [shippingLineId],
                    additionalInfo,
                    exporterId,
                    exporterAddress,
                    buyerAddress
                },
                documentType: 'SALES_CONTRACT',
                documentName,
                documentUrl: documentUrl
            };

            await createSalesContract(data);

            openNotification('success', 'Document created successfully');

            closeVisibleModal();
        } catch (error) {
            setLoading(false);
            if (error?.response) {
                if (error?.response?.data?.error) {
                    let hasFieldNameAttribute = false;

                    error?.response?.data?.error.map((item: any) => {
                        return Object.keys(item).map((itemInside: string) => {
                            if (itemInside === 'fieldName') {
                                hasFieldNameAttribute = true;

                                return;
                            }
                        });
                    });

                    if (hasFieldNameAttribute) {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>
                                    {element.messageError}
                                </li>
                            );
                        });
                        setError(errorList);
                    } else {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>{element}</li>
                            );
                        });
                        setError(errorList);
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };
    return (
        <TabContainerNew>
            <Stack direction='row' spacing={'10px'}>
                {permissions &&
                    hasPermission({
                        userPermissions: permissions,
                        module: 'sales',
                        permissionList: ['write', '*', 'read']
                    }) && (
                        <>
                            <FilterContract
                                {...{
                                    setFilterOptions,
                                    filterOptions,
                                    fetchContracts,
                                    commoditiesList,
                                    corporatePartners
                                }}
                            />
                            <Divider orientation='vertical' flexItem />
                            <Tooltip title='Download' arrow>
                                <div
                                    className='tab-buttons'
                                    onClick={() => setViewExport(true)}
                                >
                                    {download}
                                    <p style={{ paddingLeft: '5px' }}>
                                        Download
                                    </p>
                                </div>
                            </Tooltip>
                        </>
                    )}

                {permissions &&
                    hasPermission({
                        userPermissions: permissions,
                        module: 'sales',
                        permissionList: ['write', '*']
                    }) && (
                        <>
                            <Divider orientation='vertical' flexItem />
                            <Dropdown
                                menu={{ items }}
                                placement='bottomLeft'
                                trigger={['click']}
                            >
                                <Tooltip title='Add Contract' arrow>
                                    <div className='tab-buttons'>
                                        {add}
                                        <p style={{ paddingLeft: '5px' }}>
                                            Add Contract
                                        </p>
                                    </div>
                                </Tooltip>
                            </Dropdown>
                        </>
                    )}
            </Stack>
            {viewExport && (
                <CustomModal
                    maxWidth='md'
                    open={viewExport}
                    onClose={closeShowAddModal}
                >
                    <ContractExport {...{ closeShowAddModal }} />
                </CustomModal>
            )}

            <CustomModal
                maxWidth='lg'
                open={visible}
                onClose={closeVisibleModal}
                title={`Upload Document`}
                subTitle={`Please upload Sales Contract document.`}
                styles={{
                    overflowX: 'hidden'
                }}
            >
                <div className='mt-4'>
                    <SalesContractUpload
                        payload={payloadSales}
                        errors={errorsSales}
                        {...{
                            commodityFields,
                            signatureUrlBase64,
                            signatureUrlBase64Exporter,
                            setSignatureUrlBase64,
                            setSignatureUrlBase64Exporter,
                            updateCommodityField,
                            removeCommodityField,
                            addCommodityField,
                            updateField,
                            setShowAddPartner
                        }}
                    />

                    <Custombutton
                        onClickAction={generalSubmit}
                        isLoadingButton
                        loading={loading}
                        type='submit'
                        fullWidth
                        variant='contained'
                        styles={{
                            padding: '0.75rem 0.62rem 0.9rem 0.93rem',
                            marginTop: '2rem'
                        }}
                        disabled={
                            Object.values(payloadSales).some(
                                (item: any) => item === undefined
                            ) || loading
                        }
                        buttonText='Submit'
                    />
                </div>
                {showAddPartner && (
                    <Addpartnerdrawer
                        // corporateId={user?.corporate_id}
                        closeDrawer={closeShowAddParty}
                        showDrawer={showAddPartner}
                    />
                )}
            </CustomModal>
        </TabContainerNew>
    );
};

export default ContractTabs;
