import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import SearchIcon from '@mui/icons-material/Search';
import { Select } from 'antd';
import Grid from '@mui/material/Grid';
import Custombutton from '../../../../components/custombutton/custombutton';
import { useMediaQuery, useTheme } from '@mui/material';

export interface viewdocumentdrawerProps {
    closeDrawer: () => void;
    showDrawer: boolean;
    updateShowDocument: (value: boolean) => void;
}

const Viewdocumentdrawer = ({
    closeDrawer,
    showDrawer,
    updateShowDocument
}: viewdocumentdrawerProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [currentDocument, setCurrentDocument] = useState<any>(null);
    const [visible, setVisible] = useState(false);

    const closeVisible = () => {
        setVisible(false);
    };

    return (
        <div className='relative'>
            <div
                className='slide-in-right active bg-appcolorwhite rounded-[50%] top-[1.2rem] right-[28.4rem] fixed p-4 z-[2000] cursor-pointer'
                onClick={() => {
                    closeDrawer();
                }}
            >
                <img src='/img/vectors/cross.svg' alt='cross' />
            </div>

            <Drawer
                anchor={isMobile ? 'bottom' : 'right'}
                open={showDrawer}
                onClose={() => {
                    closeDrawer();
                }}
                sx={{
                    '.MuiDrawer-paperAnchorRight': {
                        width: {
                            xs: '95%',
                            lg: '30rem'
                        }
                    }
                }}
            >
                <div className='px-8 mt-4'>
                    <div className='bg-appcolorwhite rounded-[3px] py-[0.8rem] px-4 flex border items-center border-appcolorgreyfaintfive'>
                        <SearchIcon />
                        <div className='grow' id='selectContainer'>
                            <Select
                                bordered={false}
                                getPopupContainer={() =>
                                    document.getElementById(
                                        'selectContainer'
                                    ) as HTMLElement
                                }
                                showSearch
                                placeholder='Search Application'
                                optionFilterProp='children'
                                onChange={(value) => {
                                    setCurrentDocument(value);
                                    updateShowDocument(true);
                                }}
                                onSearch={(value) => {}}
                                filterOption={(input, option) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={[
                                    {
                                        value: 'Bill_of_lading.pdf',
                                        label: 'Bill_of_lading.pdf'
                                    }
                                ]}
                                style={{
                                    width: '100%'
                                }}
                            />
                        </div>
                        <div>
                            <img
                                src='/img/vectors/filterdrawer.svg'
                                alt='filterdrawer'
                            />
                        </div>
                    </div>

                    {currentDocument !== null ? (
                        <div className='mt-4'>
                            <div className='py-4 border-b border-appcolorgreyfaintfive'>
                                <p className='text-[16px] leading-[20px] text-appcolorblackfour'>
                                    {currentDocument}
                                </p>
                            </div>

                            <div className='py-4 border-b border-appcolorgreyfaintfive'>
                                <Grid container>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <p className='text-[16px] leading-[20px] text-appcolorblackfour'>
                                            Schema
                                        </p>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <p className='text-[16px] leading-[20px] text-appcolorblackfour'>
                                            Value
                                        </p>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className='py-4'>
                                <Grid container>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <p className='text-[16px] leading-[20px] text-appcolorblackfour opacity-[0.4]'>
                                            Bill of Lading Number
                                        </p>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <p className='text-[16px] leading-[20px] text-appcolorblackfour'>
                                            SS0F090406718
                                        </p>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className='py-4'>
                                <Grid container>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <p className='text-[16px] leading-[20px] text-appcolorblackfour opacity-[0.4]'>
                                            Vessel Name
                                        </p>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <p className='text-[16px] leading-[20px] text-appcolorblackfour'>
                                            MSC WAVE F
                                        </p>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className='py-4'>
                                <Grid container>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <p className='text-[16px] leading-[20px] text-appcolorblackfour opacity-[0.4]'>
                                            No of Original <br /> Bill of
                                            Landing
                                        </p>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <p className='text-[16px] leading-[20px] text-appcolorblackfour'>
                                            3
                                        </p>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className='py-4'>
                                <Grid container>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <p className='text-[16px] leading-[20px] text-appcolorblackfour opacity-[0.4]'>
                                            Parking List No
                                        </p>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <p className='text-[16px] leading-[20px] text-appcolorblackfour'>
                                            219618043-1
                                        </p>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className='py-4'>
                                <Grid container>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <p className='text-[16px] leading-[20px] text-appcolorblackfour opacity-[0.4]'>
                                            Port of Landing
                                        </p>
                                    </Grid>
                                    <Grid item xs={6} md={6} lg={6}>
                                        <p className='text-[16px] leading-[20px] text-appcolorblackfour'>
                                            Shangai /China
                                        </p>
                                    </Grid>
                                </Grid>
                            </div>

                            <div className='flex justify-end mt-4'>
                                <Custombutton
                                    variant='contained'
                                    styles={{
                                        padding:
                                            '0.5rem 0.92rem 0.5rem 0.93rem',
                                        color: '#CB444A',
                                        border: '1px solid #CB444A',
                                        backgroundColor: '#FFFFFF',
                                        marginRight: '0.5rem',
                                        '&:hover': {
                                            padding:
                                                '0.5rem 0.92rem 0.5rem 0.93rem',
                                            color: '#CB444A',
                                            border: '1px solid #CB444A',
                                            backgroundColor: '#FFFFFF'
                                        }
                                    }}
                                    disabled={false}
                                    buttonText='Reject'
                                    size='small'
                                    onClickAction={() => {}}
                                />

                                <Custombutton
                                    variant='contained'
                                    styles={{
                                        padding: '0.5rem 0.92rem 0.5rem 0.93rem'
                                    }}
                                    disabled={false}
                                    buttonText='Accept'
                                    size='small'
                                    onClickAction={() => {}}
                                />
                            </div>
                        </div>
                    ) : null}
                </div>
            </Drawer>
        </div>
    );
};

export default Viewdocumentdrawer;
