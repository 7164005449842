import React from 'react';
import { StockContainer } from '../../../styles/StockStyles';
import { Backdrop } from '@mui/material';
import StockHeader from '../../../components/dashboard/stock/StockHeader';
import WarehouseSummary from '../../../components/dashboard/stock/WarehouseSummary';
import WarehouseTable from '../../../components/dashboard/stock/WarehouseTable';
import EnablingFeature from '../../../components/EnablingFeature';

const StockManagement = () => {
    return (
        <EnablingFeature flag='Stocks'>
            <StockContainer>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                    open={false}
                >
                    <img
                        src='/img/vectors/loadingcircles.svg'
                        alt='loading'
                        className='animate-spin'
                    />
                </Backdrop>
                <div className='px-4 lg:px-0'>
                    <StockHeader />
                    <WarehouseSummary />
                </div>

                <WarehouseTable />
            </StockContainer>
        </EnablingFeature>
    );
};

export default StockManagement;
