import { Stack } from '@mui/material'
import React from 'react'

interface IProps {
  data?: any
}

const DetailsFooter = ({ data }: IProps) => {
  return (
    <Stack
      direction="row"
      width="100%"
      justifyContent="space-between"
      gap="30px"
    >
      <div className="details-content flex-1">
        <h3>Payment</h3>
        <Stack direction="row" className="flex-dets">
          <p>Charge Type</p>
          <p>{data?.creationDetails?.payment[0]?.chargeType || '--'}</p>
        </Stack>
        <Stack direction="row" className="flex-dets">
          <p>Payment Term </p>
          <p>{data?.creationDetails?.payment[0]?.paymentTerm || '--'}</p>
        </Stack>
        <Stack direction="row" className="flex-dets mt-6">
          <p>Payer</p>
          <p>{data?.creationDetails?.payment[0]?.payer || '--'}</p>
        </Stack>
        <Stack direction="row" className="flex-dets">
          <p>Payment Location </p>
          <p>{data?.creationDetails?.payment[0]?.paymentLocation || '--'}</p>
        </Stack>
      </div>

      <div className="details-content flex-1">
        <h3>Comment & Notifications</h3>
        <Stack direction="row" className="flex-dets">
          <p>{data?.creationDetails?.general?.comments || '--'}</p>
        </Stack>
      </div>

      <div className="details-content flex-1">
        <h3>Partner Email Notifications</h3>

        <Stack direction="row" className="flex-dets">
          <p>{data?.creationDetails?.general?.notificationParties || '--'}</p>
          {/* <p>Lagos, Nigeria</p> */}
        </Stack>
      </div>
    </Stack>
  )
}

export default DetailsFooter
