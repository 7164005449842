import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { capitalizeTextChar } from '../utils/helper';

export interface PaymentProgressProps {
    fromAmount: string;
    toAmount?: string;
    toName?: string;
    status?: number;
    transactionAction: string;
    fromName?: string;
}

const PaymentProgress = ({
    fromAmount,
    toAmount,
    toName,
    status,
    transactionAction,
    fromName
}: PaymentProgressProps) => {
    const { user } = useSelector((state: RootState) => state.user);

    return (
        <div>
            <div className='flex items-center mb-[4rem]'>
                <div className='rounded-[50%] w-20 h-20 border-appcolorprimarydeep border-[5px] custom-payment-progress-item show-below done flex justify-center items-center'>
                    <div className=' flex justify-center items-center bg-appcolorprimarydeep w-12 h-12 rounded-[50%] font-bold text-appcolorwhite'>
                        {capitalizeTextChar(fromName?.split(' ')[0])}
                        {capitalizeTextChar(fromName?.split(' ')[1])}
                    </div>
                </div>
                <div className='ml-2'>
                    <p className='mb-2 leading-6'>{fromName}</p>
                    <p className='font-bold leading-6'>
                        {fromAmount !== undefined && fromAmount}
                    </p>
                </div>
            </div>

            <div
                className={`rounded-[50%] p-[0.8rem]  border-[5px] ml-[0.6rem] mt-[-0.6rem] w-fit   ${
                    status === 1
                        ? 'border-appcolorprimarydeep'
                        : 'border-appcolordeepgrey'
                }`}
            >
                <div className='w-fit'>
                    <img
                        src='/img/logo/paymentprocesslogo.svg'
                        alt='processlogo'
                        className='w-[1.5rem] h-[1.5rem]'
                    />
                </div>
            </div>

            <div className='flex items-center mt-[3.2rem]'>
                <div
                    className={`rounded-[50%] w-20 h-20 border-[5px] custom-payment-progress-item show-top flex justify-center items-center ${
                        status === 1
                            ? 'border-appcolorprimarydeep done'
                            : 'border-appcolordeepgrey'
                    }`}
                >
                    <div className=' bg-appcolorgrey rounded-[50%] font-bold flex justify-center items-center w-12 h-12'>
                        {transactionAction === 'CREDIT' ? (
                            <>
                                {capitalizeTextChar(user?.name.split(' ')[0])}
                                {capitalizeTextChar(user?.name.split(' ')[1])}
                            </>
                        ) : toName === undefined ? (
                            'TR'
                        ) : (
                            `${capitalizeTextChar(toName.split(' ')[0])}
              ${capitalizeTextChar(toName.split(' ')[1])}`
                        )}
                    </div>
                </div>
                <div className='ml-2'>
                    <p className='mb-2 leading-6'>
                        {toName !== undefined && toName}
                    </p>
                    <p className='font-bold leading-6'>
                        {toAmount !== undefined && toAmount}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PaymentProgress;
