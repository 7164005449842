import {
    commaNumber,
    formatCurrency,
    getTransactionStatus
} from '../../../utils/helper';
import { Dropdown, MenuProps, Table } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { NoDataCross } from '../../../components/icons/NoDataCross';
import { TransactionTableProps } from './interfaces/PaymentTable';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';
import Customtable from '../../../components/customtable/customtable';
import DashboardTableMobileCard from '../dashboardmain/dashboardTableMobileCard';

const UserTransactionsTable = ({
    isTransactionsLoading,
    transactionsList
}: TransactionTableProps) => {
    const navigate = useNavigate();
    const { permissions } = useUserProfile();

    const getMenu = (record: {
        transactionReference: string;
    }): MenuProps['items'] => [
        {
            key: '1',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        navigate(
                            `/payments/transactions/${record.transactionReference}`
                        );
                    }}
                >
                    <span className='ml-2'>View Details</span>
                </div>
            )
        }
    ];

    return (
        <>
            <div className='hidden lg:block'>
                <Table
                    loading={isTransactionsLoading}
                    pagination={{ pageSize: 20 }}
                    dataSource={transactionsList}
                    locale={{
                        emptyText: () => {
                            return (
                                <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                                    <div className='flex justify-center mb-4'>
                                        <NoDataCross />
                                    </div>

                                    <p className='text-center text-appcolorblackthree'>
                                        You have no transactions done.
                                        <br />
                                        {permissions &&
                                            hasPermission({
                                                userPermissions: permissions,
                                                module: 'payments',
                                                permissionList: [
                                                    'write',
                                                    '*',
                                                    'read'
                                                ]
                                            }) && (
                                                <>
                                                    {' '}
                                                    <span
                                                        className='cursor-pointer text-appcolorprimary'
                                                        onClick={() => {
                                                            navigate(
                                                                '/payments/make-payment'
                                                            );
                                                        }}
                                                    >
                                                        Make a payment
                                                    </span>{' '}
                                                    to get started.
                                                </>
                                            )}
                                    </p>
                                </div>
                            );
                        }
                    }}
                    columns={[
                        {
                            title: 'Date',
                            dataIndex: 'createdDate',
                            key: 'createdDate',
                            render: (_, { createdDate }) => (
                                <span>{dayjs(createdDate).format('ll')}</span>
                            )
                        },
                        {
                            title: 'Tranx ID',
                            dataIndex: 'transactionReference',
                            key: 'transactionReference'
                        },
                        {
                            title: 'From',
                            dataIndex: 'amountFrom',
                            key: 'amountFrom',
                            render: (text, { fromCurrencyCode }) => (
                                <span>
                                    {formatCurrency(
                                        parseFloat(text),
                                        fromCurrencyCode
                                    )}
                                </span>
                            )
                        },
                        {
                            title: 'To',
                            dataIndex: 'amountTo',
                            key: 'amountTo',
                            render: (text, { toCurrencyCode }) => (
                                <span>
                                    {formatCurrency(
                                        parseFloat(text),
                                        toCurrencyCode
                                    )}
                                </span>
                            )
                        },
                        {
                            title: 'Rate',
                            dataIndex: 'rateValue',
                            key: 'rateValue',
                            render: (text) => (
                                <span>
                                    {commaNumber(parseFloat(text).toFixed(5))}
                                </span>
                            )
                        },
                        {
                            title: 'Type',
                            dataIndex: 'transactionAction',
                            key: 'transactionAction'
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                            render: (_, { status }) => (
                                <span>{getTransactionStatus(status)}</span>
                            )
                        },
                        {
                            title: '',
                            render: (_, record) => (
                                <Dropdown
                                    menu={{
                                        items: getMenu(record)
                                    }}
                                    trigger={['click']}
                                >
                                    <img
                                        src='/img/vectors/more.svg'
                                        alt='more'
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                    />
                                </Dropdown>
                            )
                        }
                    ]}
                />
            </div>
            <div className='lg:hidden'>
                <Customtable
                    data={transactionsList}
                    loadingData={isTransactionsLoading}
                    multiTableTiles={[]}
                    mobileComponent={<DashboardTableMobileCard />}
                    onMobileRowClick={(data) =>
                        navigate(
                            `/payments/transactions/${data.transactionReference}`
                        )
                    }
                />
            </div>
        </>
    );
};

export default UserTransactionsTable;
