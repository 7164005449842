import { ReactNode } from 'react';
import {
    CloseSquareicon,
    FlashStatusIcon,
    MoreCircleIcon,
    TickSquareIcon
} from './icons';

const StatusIndicator = ({ status }: { status: string }) => {
    const views: {
        [key: string]: {
            background: string;
            icon: ReactNode;
        };
    } = {
        PROCESSING: {
            background: 'bg-lightYellow',
            icon: <MoreCircleIcon />
        },
        COMPLETED: {
            background: 'bg-green',
            icon: <TickSquareIcon />
        },
        CANCELED: {
            background: 'bg-orange',
            icon: <CloseSquareicon />
        },
        INITIATED: {
            background: 'bg-paleSky',
            icon: <FlashStatusIcon />
        },
        DISABLED: {
            background: 'bg-paleSky',
            icon: <FlashStatusIcon />
        },
        ACTIVE: {
            background: 'bg-green',
            icon: <TickSquareIcon />
        },
        FAILED: {
            background: 'bg-orange',
            icon: <CloseSquareicon />
        },
        PENDING: {
            background: 'bg-lightYellow',
            icon: <MoreCircleIcon />
        }
    };

    return (
        <div
            className={`w-[44px] h-[44px] rounded-[8px] flex justify-center items-center ${views[status]?.background}`}
        >
            {views[status]?.icon}
        </div>
    );
};

export default StatusIndicator;
