import React from 'react';
import { Wallet } from './interfaces/Wallet';
import { CopyText } from '../../../components/CopyText';

export const NoneModeBankAccount = ({
    selectedWallet
}: {
    selectedWallet: Wallet;
}) => {
    return (
        <>
            <h3 className='font-bold text-[14px] mb-[.5rem]'>
                {selectedWallet.currencyCode} Account (Local)
            </h3>
            <p>
                Account Number:
                <span className='font-semibold'>
                    &nbsp; {selectedWallet.walletReference}
                </span>
                &nbsp;
                <CopyText text={selectedWallet?.walletReference || ''} />
            </p>
            <p className='text-paleSky text-sm mb-[8px]'>
                Account Name:
                <span className='font-semibold'>
                    &nbsp; {selectedWallet.walletName}
                </span>
            </p>
            {selectedWallet.walletRefProviderCode && (
                <p className='text-paleSky text-sm mb-[8px]'>
                    Bank Name:
                    <span className='font-semibold'>
                        &nbsp;{' '}
                        {selectedWallet.walletRefProviderCode === '101'
                            ? 'Providus'
                            : ''}
                    </span>
                </p>
            )}
        </>
    );
};
