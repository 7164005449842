import React, { ReactNode, useState } from "react";
import CustomModal from '../CustomModal';
import { Button, Divider, Stack } from "@mui/material";
import Custombutton from "../custombutton/custombutton";
import LogoOptions from "./LogoOptions";
import Documentupload from "../documentupload/documentupload";

export interface custommodalProps {
  children: ReactNode;
  updateField?: any;
}

const ChooseDocUpload = ({ children, updateField }: custommodalProps) => {
  const [openDoc, setOpenDoc] = useState(false);
  const [viewLogos, setViewLogos] = useState(false);
  const [selected, setSelected] = useState("");

  const closeUploadOrCreate = () => {
    setOpenDoc(false);
    setViewLogos(false);
  };

  return (
      <>
          <CustomModal
              maxWidth='md'
              open={openDoc}
              onClose={closeUploadOrCreate}
              title={viewLogos ? `Uploaded photos` : `Choose Logo`}
          >
              {!viewLogos ? (
                  <Stack spacing={1} marginTop='2rem'>
                      <Divider />
                      <Documentupload
                          acceptText='.jpg and .png'
                          accept='.jpg, .png'
                          useAuthorization
                          returnURLOnly
                          title={'Upload Logo'}
                          afterUploadUpdate={(
                              itemId: number,
                              documentUrl: string
                          ) => {
                              updateField('logoUrl', documentUrl);
                              closeUploadOrCreate();
                          }}
                      />
                      <Divider />
                      <Button
                          size='medium'
                          sx={{
                              '&:hover': {
                                  color: 'white'
                              }
                          }}
                          onClick={() => setViewLogos(true)}
                      >
                          Choose from uploaded Logo
                      </Button>
                      <Divider />

                      <Custombutton
                          styles={{
                              color: 'black',
                              width: '100%',
                              marginTop: '.5rem',
                              '&:hover': {
                                  color: 'white'
                              }
                          }}
                          buttonText='Cancel'
                          onClickAction={closeUploadOrCreate}
                      />
                  </Stack>
              ) : (
                  <>
                      <LogoOptions {...{ setSelected, selected }} />
                      <Button
                          variant='contained'
                          sx={{ width: '100%', height: '40px' }}
                          onClick={() => {
                              updateField('logoUrl', selected);
                              closeUploadOrCreate();
                              setViewLogos(false);
                          }}
                      >
                          Select
                      </Button>
                  </>
              )}
          </CustomModal>
          <div
              className='cursor-pointer text-appcolorprimary'
              onClick={() => setOpenDoc(true)}
          >
              {children}
          </div>
      </>
  );
};

export default ChooseDocUpload;
