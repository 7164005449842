import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Outletouterpage from '../../../components/dashboard/outletouterpage/outletouterpage';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import EnablingFeature from '../../../components/EnablingFeature';
import { CustomerWalletBalances } from '../../../components/CustomerWalletBalances';
import { AdminWalletBalances } from '../../../components/AdminWalletBalances';
import { ChevronLeftIcon } from '../../../components/icons';

const PaymentsOverview = () => {
    const state = useSelector((state: RootState) => ({
        user: state.user.user
    }));

    const { user } = state;

    const location = useLocation();
    const navigate = useNavigate();

    return (
        <EnablingFeature flag='Payments'>
            <div className='relative w-full max-w-[100vw] overflow-hidden'>
                <Outletouterpage
                    breadcrumbs={[
                        {
                            title: 'Home',
                            link: '/dashboard'
                        },
                        {
                            title: 'Payments',
                            link: '/payments/overview'
                        }
                    ]}
                >
                    <div className='w-full'>
                        {location.pathname.split('/')[2] === 'make-payment' ? (
                            <button
                                className='lg:hidden flex gap-[12px] items-center px-4 mb-4 font-medium text-lg text-ebony'
                                onClick={() => navigate('/payments/overview')}
                            >
                                <ChevronLeftIcon /> Make payment
                            </button>
                        ) : (
                            <h1 className='lg:hidden px-4 mb-4 text-xl text-black'>
                                Transactions
                            </h1>
                        )}

                        <div className='px-[16px] lg:pl-0'>
                            {
                                // if user is admin
                                user?.user_level === 5 ? (
                                    <AdminWalletBalances />
                                ) : (
                                    <CustomerWalletBalances />
                                )
                            }
                        </div>

                        <div className='mt-[40px] lg:mt-12 view-add-transactions'>
                            <Outlet />
                        </div>
                    </div>
                </Outletouterpage>
            </div>
        </EnablingFeature>
    );
};

export default PaymentsOverview;
