import { Divider, Stack, Tooltip } from '@mui/material';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from '../../../../utils/helper';
import { Dropdown, MenuProps } from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { InvoiceSummaryDetails } from '../../../../pages/dashboard/payments/interfaces/Invoice';
import { useGetQuery } from '../../../../customhooks/useGetQuery';
import { LogisticsInvoiceSummarySkeleton } from '../../../../pages/dashboard/payments/LogisticsInvoiceSummarySkeleton';
import { SuitUIButton } from '../../../SuitUIButton';
import { DocumentTextIcon } from '../../../icons';
import CustomCarousel from '../../../CustomCarousel';
import WarehouseSummaryItem from '../../stock/WarehouseSummaryItem';

const InvoiceSummary = () => {
    const [selectedCurrency, setSelectedCurrency] =
        useState<InvoiceSummaryDetails>();

    const [selCurr, setSelCurr] = useState('');

    const selectedData: InvoiceSummaryDetails =
        selectedCurrency || ({} as InvoiceSummaryDetails);

    const { user } = useSelector((state: RootState) => state.user);

    const { isLoading, entries } = useGetQuery<InvoiceSummaryDetails[]>({
        url: `/invoice/summary/${user?.corporate_id}`,
        cacheKey: 'sales-contract-invoice',
        enabled: Boolean(user?.corporate_id)
    });

    useEffect(() => {
        if (entries) {
            setSelectedCurrency(entries[0]);
        }
    }, [entries]);

    const currencies: string[] =
        entries?.map((item: InvoiceSummaryDetails) => item.currencyCode) || [];

    const mappedData: { key: number; label: ReactNode }[] = currencies?.map(
        (item, ind) => {
            return {
                key: ind,
                label: (
                    <div
                        className='flex items-center menu-otps'
                        onClick={() => setSelCurr(item)}
                    >
                        <span className='ml-2'>{item}</span>
                    </div>
                )
            };
        }
    );

    const items: MenuProps['items'] = mappedData || [];

    const summaryData: { [key: string]: string | ReactElement }[] = [
        {
            label: 'Total value of invoices',
            value: formatCurrency(
                selectedData?.totalAmount || 0,
                selectedData?.currencyCode
            ),
            icon: <DocumentTextIcon />
        },
        {
            label: 'Total Outstanding',
            value: formatCurrency(
                selectedData?.totalDue || 0,
                selectedData?.currencyCode
            ),
            icon: <DocumentTextIcon color='#DF835A' />
        },
        {
            label: 'Total Paid',
            value: formatCurrency(
                selectedData?.totalPaid || 0,
                selectedData?.currencyCode
            ),
            icon: <DocumentTextIcon color='#008F46' />
        },
        {
            label: 'Total Overdue',
            value: formatCurrency(
                selectedData?.totalOverdue || 0,
                selectedData?.currencyCode
            ),
            icon: <DocumentTextIcon color='#E44000' />
        }
    ];
    useEffect(() => {
        if (selCurr !== '') {
            const data = entries?.filter(
                (item: InvoiceSummaryDetails) => item.currencyCode === selCurr
            );
            setSelectedCurrency(data[0]);
        }
    }, [selCurr]);

    if (isLoading) {
        return <LogisticsInvoiceSummarySkeleton />;
    }

    return (
        <>
            <Main className='hidden lg:block'>
                <InvoiceSummaryContainer>
                    <div className='summary-details'>
                        <Stack
                            direction='row'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <div>
                                <p>Total value of invoices</p>
                                <h3>
                                    {formatCurrency(
                                        selectedData?.totalAmount || 0,
                                        selectedData?.currencyCode
                                    )}
                                </h3>
                            </div>
                            <div>{imgIcon}</div>
                        </Stack>
                    </div>
                    <Divider orientation='vertical' flexItem />

                    <div className='summary-details'>
                        <Stack
                            direction='row'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <div>
                                <p>Total Outstanding</p>
                                <h3>
                                    {formatCurrency(
                                        selectedData?.totalDue || 0,
                                        selectedData?.currencyCode
                                    )}
                                </h3>
                            </div>
                            <div className='red'>{imgIcon}</div>
                        </Stack>
                    </div>
                    <Divider orientation='vertical' flexItem />

                    <div className='summary-details'>
                        <Stack
                            direction='row'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <div>
                                <p>Total Paid</p>
                                <h3>
                                    {formatCurrency(
                                        selectedData?.totalPaid || 0,
                                        selectedData?.currencyCode
                                    )}
                                </h3>
                            </div>
                            <div className='green'>{imgIcon}</div>
                        </Stack>
                    </div>
                    <Divider orientation='vertical' flexItem />

                    <div className='summary-details'>
                        <Stack
                            direction='row'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <div>
                                <p>Total Overdue</p>
                                <h3>
                                    {formatCurrency(
                                        selectedData?.totalOverdue || 0,
                                        selectedData?.currencyCode
                                    )}
                                </h3>
                            </div>
                            <div className='yellow'>{imgIcon}</div>
                        </Stack>
                    </div>
                </InvoiceSummaryContainer>
                <Stack
                    width='100%'
                    justifyContent={'flex-end'}
                    direction='row'
                    borderTop='1px solid #EAECF0'
                    padding='10px 0'
                >
                    <Dropdown
                        menu={{ items }}
                        trigger={['click']}
                        placement='bottomRight'
                        className='menu'
                    >
                        <Tooltip title='Switch Currency' arrow>
                            <div className='tab-buttons'>
                                {getSymbolFromCurrency(
                                    selectedCurrency?.currencyCode ?? 'NGN'
                                )}
                                <p>Switch Currency</p>
                                <p>{caret}</p>
                            </div>
                        </Tooltip>
                    </Dropdown>
                </Stack>
            </Main>
            <div className='lg:hidden w-full mt-2 space-y-2'>
                <Dropdown
                    menu={{ items }}
                    placement='bottomLeft'
                    trigger={['click']}
                >
                    <SuitUIButton
                        text={
                            selectedCurrency?.currencyCode ?? 'Switch Currency'
                        }
                        invert
                        fullWidth
                        style='border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                        position='justify-start'
                    />
                </Dropdown>
                <CustomCarousel
                    data={summaryData}
                    component={<WarehouseSummaryItem />}
                />
            </div>
        </>
    );
};

export default InvoiceSummary;

export const InvoiceSummaryContainer = styled.div`
    display: flex;
    height: 120px;
    padding: 15px 30px 6px;
    margin-bottom: 6px;
    align-items: center;
    gap: 30px;
    align-self: stretch;
    width: 100%;
    margin-top: 1rem;
    .summary-details {
        /* max-width: 180px; */
        flex: 1;
        p {
            color: #667085;
            font-family: Outfit;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        h3 {
            color: #353542;
            font-family: Outfit;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
    .red {
        svg {
            rect {
                fill: #fae6e8;
                fill-opacity: 1;
            }
            path {
                fill: #691921;
            }
        }
    }
    .green {
        svg {
            rect {
                fill: #e5f3f7;
                fill-opacity: 1;
            }
            path {
                fill: #3c6e46;
            }
        }
    }

    .yellow {
        svg {
            rect {
                fill: #fff7e0;
                fill-opacity: 1;
            }
            path {
                fill: #7a5c02;
            }
        }
    }
`;
export const Main = styled.div`
    border-radius: 8px;
    background: #fff;
    .menu {
        width: max-content;
    }
    .tab-buttons {
        display: flex;
        align-items: center;
        gap: 8px;
        width: max-content;
        margin-right: 30px;
        color: #1993b9;
        cursor: pointer;
        font-size: 14.5px;
    }
`;
const caret = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='8'
        height='6'
        viewBox='0 0 8 6'
        fill='none'
    >
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1.2728 1.54329C1.41518 1.39865 1.64602 1.39865 1.7884 1.54329L4.08268 3.874L6.37697 1.54329C6.51934 1.39865 6.75019 1.39865 6.89256 1.54329C7.03494 1.68793 7.03494 1.92244 6.89256 2.06708L4.34048 4.65967C4.1981 4.80431 3.96726 4.80431 3.82488 4.65967L1.2728 2.06708C1.13042 1.92244 1.13042 1.68793 1.2728 1.54329Z'
            stroke='#1993B9'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
export const imgIcon = (
    <svg
        width='29'
        height='29'
        viewBox='0 0 29 29'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect
            x='0.200195'
            y='0.601562'
            width='28'
            height='28'
            rx='10.1818'
            fill='#0088B2'
            fillOpacity='0.05'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M12.407 17.1625H15.5489C15.7857 17.1625 15.982 16.9641 15.982 16.725C15.982 16.4858 15.7857 16.2933 15.5489 16.2933H12.407C12.1702 16.2933 11.9738 16.4858 11.9738 16.725C11.9738 16.9641 12.1702 17.1625 12.407 17.1625ZM14.3591 13.3766H12.407C12.1702 13.3766 11.9738 13.575 11.9738 13.8141C11.9738 14.0533 12.1702 14.2458 12.407 14.2458H14.3591C14.5959 14.2458 14.7923 14.0533 14.7923 13.8141C14.7923 13.575 14.5959 13.3766 14.3591 13.3766ZM18.4808 12.8666C18.6166 12.865 18.7644 12.8633 18.8987 12.8633C19.0431 12.8633 19.1586 12.98 19.1586 13.1258V17.8158C19.1586 19.2625 17.9977 20.435 16.5654 20.435H11.968C10.4664 20.435 9.24194 19.2041 9.24194 17.6875V11.3991C9.24194 9.95248 10.4086 8.76831 11.8467 8.76831H14.9309C15.0811 8.76831 15.1966 8.89081 15.1966 9.03664V10.915C15.1966 11.9825 16.0687 12.8575 17.1256 12.8633C17.3725 12.8633 17.5901 12.8652 17.7806 12.8668C17.9288 12.868 18.0605 12.8691 18.1768 12.8691C18.259 12.8691 18.3655 12.8679 18.4808 12.8666ZM18.6401 12.0151C18.1653 12.0169 17.6057 12.0151 17.2031 12.0111C16.5643 12.0111 16.0382 11.4796 16.0382 10.8345V9.29681C16.0382 9.04539 16.3402 8.92056 16.5129 9.10198L18.8382 11.5444C19.0068 11.7211 18.8832 12.0146 18.6401 12.0151Z'
            fill='#0088B2'
        />
    </svg>
);
