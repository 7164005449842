import { Commodity } from '../interfaces/Commodity';
import { useGetQuery } from './useGetQuery';

const useCommodities = (search?: string) => {
    const { isLoading, entries: commoditiesList } = useGetQuery<Commodity[]>({
        url: '/commodity',
        cacheKey: 'commodities',
        params: { page: 1, size: 0, name: search }
    });

    return { isLoading, commoditiesList };
};
export default useCommodities;
