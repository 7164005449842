import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Drawer from '@mui/material/Drawer';
import Custombutton from '../../../../components/custombutton/custombutton';
import { shippingLinesInterfaceArray } from '../../../../customhooks/shippinglines/userShippinglines';
import { carrierConnection } from '../../../../api/booking/booking';
import { openNotification } from '../../../../utils/helper';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress, Grid, useMediaQuery, useTheme } from '@mui/material';
import Documentupload from '../../../../components/documentupload/documentupload';
import DownloadIcon from '@mui/icons-material/Download';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';

export interface newcarrierDrawerProps {
    closeDrawer: (refreshList?: boolean) => void;
    showDrawer: boolean;
    isLoadingShippingLines: boolean;
    shippingLines: shippingLinesInterfaceArray;
    corporateId: number;
    carrier?: string;
}

const Newcarrierdrawer = ({
    closeDrawer,
    showDrawer,
    shippingLines,
    isLoadingShippingLines,
    corporateId,
    carrier
}: newcarrierDrawerProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [error, setError] = useState<string | any>(null);
    const [loading, setLoading] = useState(false);

    const state = useSelector((state: RootState) => ({
        user: state.user.user
    }));

    const { user } = state;

    const [uploadSample, setUploadSample] = useState<any>(undefined);
    const [payload, setPayload] = useState<any>({
        bookingNumber: '',
        customerNumber: '',
        contractNumber: '',
        annualShipmentTeu: '',
        requesterContactNumber: user?.phone_number,
        firstName: user?.first_name,
        lastName: user?.last_name,
        requesterContactEmail: user?.user_email,
        taxId: '',
        shippingLineId: carrier || undefined,
        blNumber: '',
        letterOfAuthorizationUrl: ''
    });
    const [errors, setErrors] = useState<any>({
        bookingNumber: '',
        customerNumber: '',
        contractNumber: '',
        annualShipmentTeu: '',
        requesterContactNumber: '',
        firstName: '',
        lastName: '',
        requesterContactEmail: '',
        taxId: '',
        shippingLineId: '',
        blNumber: '',
        letterOfAuthorizationUrl: ''
    });

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrors((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));
    };

    /**
     * Submit form
     * @param e Object
     */
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        setErrors((prevState: any) => ({
            ...prevState,
            bookingNumber: '',
            customerNumber: '',
            contractNumber: '',
            annualShipmentTeu: '',
            requesterContactNumber: '',
            firstName: '',
            lastName: '',
            requesterContactEmail: '',
            taxId: '',
            shippingLineId: '',
            blNumber: '',
            letterOfAuthorizationUrl: ''
        }));

        try {
            const { shippingLineId, ...rest } = payload;

            const res = await carrierConnection(
                corporateId,
                shippingLineId,
                rest
            );

            const { data } = res.data;

            setLoading(false);

            openNotification(
                'success',
                'Carrier connection created successfully'
            );

            closeDrawer(true);
        } catch (error) {
            setLoading(false);
            if (error?.response) {
                if (error?.response?.data?.error) {
                    let hasFieldNameAttribute = false;

                    error?.response?.data?.error.map((item: any) => {
                        return Object.keys(item).map((itemInside: string) => {
                            if (itemInside === 'fieldName') {
                                hasFieldNameAttribute = true;

                                return;
                            }
                        });
                    });

                    if (hasFieldNameAttribute) {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>
                                    {element.messageError}
                                </li>
                            );
                        });
                        setError(errorList);
                    } else {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>{element}</li>
                            );
                        });
                        setError(errorList);
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    return (
        <div>
            <div
                className='slide-in-right active bg-appcolorwhite rounded-[50%] top-[1.2rem] right-[28.4rem] fixed p-4 z-[2000] cursor-pointer'
                onClick={() => {
                    closeDrawer();
                }}
            >
                <img src='/img/vectors/cross.svg' alt='cross' />
            </div>
            <Drawer
                anchor={isMobile ? 'bottom' : 'right'}
                open={showDrawer}
                onClose={() => {
                    closeDrawer();
                }}
                sx={{
                    '.MuiDrawer-paperAnchorRight': {
                        width: {
                            xs: '95%',
                            lg: '30rem'
                        }
                    }
                }}
            >
                <div className='px-[3.2rem] py-[2.1rem]'>
                    <h1 className='text-[24px] leading-[23px] text-appcolorblacktwo font-semibold'>
                        Establish Connection with Carrier
                    </h1>

                    <div className='mt-4 mb-2'>
                        <Alert
                            icon={
                                <img
                                    src='/img/vectors/infosquare.svg'
                                    alt='infosquare'
                                />
                            }
                            severity='info'
                            sx={{
                                background: 'rgba(0, 136, 178, 0.06)',
                                borderRadius: '3px',
                                border: '1px solid #0088B2',
                                color: theme.palette.primary.main
                            }}
                        >
                            Please make sure to provide the most recent booking
                            confirmation number, B/L number, contract number or
                            customer number that can be used by the carrier to
                            identify your company.
                        </Alert>
                    </div>

                    {error !== null && (
                        <div className='mt-10'>
                            <Alert severity='error'>{error}</Alert>
                        </div>
                    )}

                    <div className='my-4'>
                        <form autoComplete='off' onSubmit={onSubmit} noValidate>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                openOnFocus
                                value={shippingLines.find(
                                    (connection) =>
                                        connection.id ===
                                        Number(payload.shippingLineId)
                                )}
                                id='signup-shippinglineid'
                                options={shippingLines}
                                sx={{
                                    marginTop: '1rem',
                                    '.MuiInputBase-input': {
                                        height: '0.3rem'
                                    }
                                }}
                                onChange={(_, value) =>
                                    updateField('shippingLineId', value.id)
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Shipping Lines'
                                        required
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment:
                                                isLoadingShippingLines && (
                                                    <React.Fragment>
                                                        <CircularProgress
                                                            size={20}
                                                        />
                                                        {
                                                            params.InputProps
                                                                .endAdornment
                                                        }
                                                    </React.Fragment>
                                                )
                                        }}
                                    />
                                )}
                            />

                            <Grid
                                container
                                sx={{
                                    marginTop: '0px'
                                }}
                                spacing={4}
                            >
                                <Grid item xs={12} md={12} lg={6}>
                                    <TextField
                                        value={payload?.bookingNumber}
                                        error={errors?.bookingNumber.length > 0}
                                        helperText={
                                            errors?.bookingNumber.length > 0
                                                ? errors?.bookingNumber
                                                : undefined
                                        }
                                        margin='normal'
                                        fullWidth
                                        id='existingbooking-bookingNumber'
                                        label='Booking Number'
                                        name='bookingNumber'
                                        onChange={(e: any) => {
                                            updateField(
                                                'bookingNumber',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={6}>
                                    <TextField
                                        value={payload?.customerNumber}
                                        error={
                                            errors?.customerNumber.length > 0
                                        }
                                        helperText={
                                            errors?.customerNumber.length > 0
                                                ? errors?.customerNumber
                                                : undefined
                                        }
                                        margin='normal'
                                        // required
                                        fullWidth
                                        id='createnewcarrier-customerNumber'
                                        label='Customer Number'
                                        name='customerNumber'
                                        onChange={(e: any) => {
                                            updateField(
                                                'customerNumber',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <TextField
                                value={payload?.contractNumber}
                                error={errors?.contractNumber.length > 0}
                                helperText={
                                    errors?.contractNumber.length > 0
                                        ? errors?.contractNumber
                                        : undefined
                                }
                                margin='normal'
                                // required
                                fullWidth
                                id='createnewcarrier-contractNumber'
                                label='Contract Number'
                                name='contractNumber'
                                onChange={(e: any) => {
                                    updateField(
                                        'contractNumber',
                                        e.target.value
                                    );
                                }}
                            />

                            <TextField
                                value={payload?.blNumber}
                                error={errors?.blNumber.length > 0}
                                helperText={
                                    errors?.blNumber.length > 0
                                        ? errors?.blNumber
                                        : undefined
                                }
                                margin='normal'
                                fullWidth
                                id='createnewcarrier-blNumber'
                                label='Recent BL Number'
                                name='blNumber'
                                onChange={(e: any) => {
                                    updateField('blNumber', e.target.value);
                                }}
                            />

                            <TextField
                                value={payload?.annualShipmentTeu}
                                error={errors?.annualShipmentTeu.length > 0}
                                helperText={
                                    errors?.annualShipmentTeu.length > 0
                                        ? errors?.annualShipmentTeu
                                        : undefined
                                }
                                margin='normal'
                                fullWidth
                                id='createnewcarrier-annualShipmentTeu'
                                label='Annual Shipments in TEU'
                                name='annualShipmentTeu'
                                onChange={(e: any) => {
                                    updateField(
                                        'annualShipmentTeu',
                                        e.target.value
                                    );
                                }}
                            />

                            <TextField
                                value={payload?.requesterContactNumber}
                                error={
                                    errors?.requesterContactNumber.length > 0
                                }
                                helperText={
                                    errors?.requesterContactNumber.length > 0
                                        ? errors?.requesterContactNumber
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='createnewcarrier-requesterContactNumber'
                                label='Requestor’s Contact Number'
                                name='requesterContactNumber'
                                onChange={(e: any) => {
                                    updateField(
                                        'requesterContactNumber',
                                        e.target.value
                                    );
                                }}
                            />

                            <Grid
                                container
                                sx={{
                                    marginTop: '0px'
                                }}
                                spacing={4}
                            >
                                <Grid item xs={12} md={12} lg={6}>
                                    <TextField
                                        value={payload?.firstName}
                                        error={errors?.firstName.length > 0}
                                        helperText={
                                            errors?.firstName.length > 0
                                                ? errors?.firstName
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='createnewcarrier-firstName'
                                        label='First Name'
                                        name='firstName'
                                        onChange={(e: any) => {
                                            updateField(
                                                'firstName',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} lg={6}>
                                    <TextField
                                        value={payload?.lastName}
                                        error={errors?.lastName.length > 0}
                                        helperText={
                                            errors?.lastName.length > 0
                                                ? errors?.lastName
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='createnewcarrier-lastName'
                                        label='Last Name'
                                        name='lastName'
                                        onChange={(e: any) => {
                                            updateField(
                                                'lastName',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <TextField
                                value={payload?.requesterContactEmail}
                                error={errors?.requesterContactEmail.length > 0}
                                helperText={
                                    errors?.requesterContactEmail.length > 0
                                        ? errors?.requesterContactEmail
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='createnewcarrier-requesterContactEmail'
                                label="Requester's Contact Email"
                                name='requesterContactEmail'
                                onChange={(e: any) => {
                                    updateField(
                                        'requesterContactEmail',
                                        e.target.value
                                    );
                                }}
                            />

                            <TextField
                                value={payload?.taxId}
                                error={errors?.taxId.length > 0}
                                helperText={
                                    errors?.taxId.length > 0
                                        ? errors?.taxId
                                        : undefined
                                }
                                margin='normal'
                                // required
                                fullWidth
                                id='createnewcarrier-taxId'
                                label='Government Tax ID'
                                name='taxId'
                                onChange={(e: any) => {
                                    updateField('taxId', e.target.value);
                                }}
                            />

                            {uploadSample !== undefined ? (
                                <>
                                    <div className='mt-4'>
                                        <p className='mt-3'>
                                            <a
                                                className='text-appcolorprimary'
                                                download
                                                href={uploadSample}
                                            >
                                                Download Template
                                                <DownloadIcon />
                                            </a>
                                        </p>
                                        <Documentupload
                                            useAuthorization
                                            returnURLOnly
                                            title={'Letter of Authorization'}
                                            afterUploadUpdate={(
                                                itemId: number,
                                                documentUrl: string
                                            ) => {
                                                updateField(
                                                    'letterOfAuthorizationUrl',
                                                    documentUrl
                                                );
                                            }}
                                        />
                                    </div>
                                </>
                            ) : null}

                            <div className='mt-4'>
                                <Custombutton
                                    isLoadingButton
                                    loading={loading}
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    styles={{
                                        padding:
                                            '0.75rem 0.62rem 0.9rem 0.93rem'
                                    }}
                                    disabled={
                                        Object.values(payload).some(
                                            (item: any) => item === undefined
                                        ) || loading
                                    }
                                    buttonText='Connect Carrier'
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};

export default Newcarrierdrawer;
