import { useEffect, useState } from 'react';
import { Country } from '../interfaces/Country';
import { useGetQuery } from './useGetQuery';

const useCountries = () => {
    const { isLoading: isCountriesLoading, entries } = useGetQuery<Country[]>({
        url: '/countries',
        cacheKey: 'countries'
    });    
    const [countries, setCountries] = useState<Country[]>([] as Country[]);
    
    useEffect(() => {
        setCountries((entries || []).filter((item) => item.phoneCode));
    }, [entries]);

    return { countries, isCountriesLoading };
};

export default useCountries;
