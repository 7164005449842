import { Button } from 'antd';
import CustomModal from '../../../../components/CustomModal';
import { UpdateContainer } from '../../../../components/dashboard/sales/UpdateStatus';
import { HierachyIcon } from '../../../../components/icons';

const UpdateRole = ({
    userName,
    open,
    onClose,
    loading,
    handleDisable
}: {
    userName: string;
    open: boolean;
    onClose: () => void;
    loading: boolean;
    handleDisable: () => void;
}) => {
    return (
        <CustomModal
            maxWidth='md'
            open={open}
            onClose={onClose}
            title=''
            hideCloseIcon
        >
            <UpdateContainer className=' mx-auto py-4 max-w-[400px] w-full'>
                <HierachyIcon />
                <h2 className='pt-6 mb-2 text-[18px] text-ebony'>Make Admin</h2>
                <h5 className=' leading-7 text-[18px] text-paleSky'>
                    Please confirm to grant{' '}
                    <span className='font-bold'>{userName}</span> full
                    administrative permissions, allowing access to all features
                    and functionalities.
                </h5>

                <div className='cta-buttons'>
                    <Button className='btn-1' onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        className='btn-2'
                        loading={loading}
                        onClick={handleDisable}
                    >
                        Confirm
                    </Button>
                </div>
            </UpdateContainer>
        </CustomModal>
    );
};

export default UpdateRole;
