import { Table } from 'antd';
import React from 'react';
import { formatNumber } from '../../../../utils/helper';
import Customtable from '../../../customtable/customtable';
import { Box } from '@mui/material';
import { SalesContract } from '../../../../pages/dashboard/tradedocuments/salescontract/interfaces/SalesContract';
import { Commodity } from '../../../../interfaces/Commodity';
import { NoDataCross } from '../../../icons/NoDataCross';

const MobileItemsCards = ({ item }: { item?: Commodity }) => {
    return (
        <>
            <Box key={item?.id} borderBottom='1px solid#EAECF0' pb={3}>
                <Box width='full' justifyContent='space-between' display='flex'>
                    <Box display='flex' gap={1}>
                        <Box>
                            <h4 className=' text-ebony text-[14px] font-semibold'>
                                {item?.name ?? '--'}
                            </h4>
                            <p className=' text-[12px] text-paleSky'>
                                Type:
                                <span className=' pl-1 pr-3 font-extrabold'>
                                    {item?.type ?? '--'}
                                </span>
                                Quality:
                                <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                    {item?.quality ?? '--'}
                                </span>
                            </p>
                            <p className=' text-[12px] text-paleSky'>
                                Total Qty:
                                <span className=' pl-1 pr-3 font-extrabold'>
                                    {formatNumber(item?.quantity ?? 0)}
                                </span>
                                Qty Buffer:
                                <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                    {formatNumber(item?.quantityBuffer ?? 0)}
                                    {' %'}
                                </span>
                            </p>
                            <p className=' text-[12px] text-paleSky'>
                                Pending Qty:
                                <span className=' pl-1 pr-3 font-extrabold capitalize'>
                                    {formatNumber(item?.pendingQuantity ?? 0)}
                                </span>
                            </p>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
const ContractITemsTable = ({
    loadingData,
    data
}: {
    loadingData?: boolean;
    data?: SalesContract;
}) => {
    return (
        <>
            <Table
                loading={loadingData}
                pagination={{ pageSize: 20 }}
                dataSource={data?.commodities || ([] as Commodity[])}
                locale={{
                    emptyText: () => {
                        return (
                            <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                                <div className='flex justify-center mb-4'>
                                    <NoDataCross />
                                </div>

                                <p className='text-center text-appcolorblackthree'>
                                    You have no Commodities on this Contract.
                                    <br />
                                </p>
                            </div>
                        );
                    }
                }}
                columns={[
                    {
                        title: 'Commodity',
                        dataIndex: 'name',
                        key: 'name',
                        render: (text) => <span>{text}</span>
                    },
                    {
                        title: 'Type',
                        dataIndex: 'type',
                        key: 'type',
                        render: (text) => <span>{text}</span>
                    },
                    {
                        title: 'Quality',
                        dataIndex: 'quality',
                        key: 'quality'
                    },
                    {
                        title: 'Total Quantity',
                        dataIndex: 'quantity',
                        key: 'quantity',
                        render: (_, record: tableRecord) => (
                            <span>
                                {formatNumber(record.quantity || 0)}{' '}
                                {record.metric}
                            </span>
                        )
                    },

                    {
                        title: 'Qty Buffer (%)',
                        dataIndex: 'quantityBuffer',
                        key: 'quantityBuffer',
                        render: (text) => <span>{formatNumber(text || 0)}</span>
                    },
                    {
                        title: 'Pending Quantity',
                        dataIndex: 'pendingQuantity',
                        key: 'pendingQuantity',
                        render: (text) => <span>{formatNumber(text || 0)}</span>
                    }
                ]}
                className='hidden lg:block'
            />

            <div className='lg:hidden'>
                <Customtable
                    data={data?.commodities ?? []}
                    loadingData={loadingData}
                    multiTableTiles={[]}
                    emptyText='You have no Commodities on this Contract.'
                    mobileComponent={<MobileItemsCards />}
                />
            </div>
        </>
    );
};

export default ContractITemsTable;

interface tableRecord {
    quantity?: number | any;
    metric?: string;
}
