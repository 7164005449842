import React from 'react'

export interface chatitemProps {
  isAdmin?: boolean,
  chatText: string,

}

const Chatitem = ({ isAdmin, chatText }: chatitemProps) => {
  return (
    <div className='flex bg-[transparent] pr-4'>
      <div className='flex flex-col items-end mt-3 mr-10'>
        <p className='text-appcolorfaintsix text-[10px] leading-[13px]'>
          10/04/2021
        </p>
        <p className='text-appcolorfaintsix text-[10px] leading-[13px] opacity-[0.5]'>
          10:13AM
        </p>
      </div>
      <div className='flex flex-col'>
        <div className='flex items-center'>
          <div className={`${isAdmin ? 'bg-appcolorfaintgreen' : 'bg-appcolorfaintpurple'} w-[3rem] h-[3rem] rounded-[50%] mr-2`}>
          </div>
          <div>
            <p className='font-bold text-[14px] leadiong-[16px] text-appcolorfaintsix'>
              {
                isAdmin ? 'Admin' : 'You'
              }
            </p>
          </div>
        </div>

        <div className='mt-3 ml-[2.5rem]'>
          <div className='custom-piped-left text-appcolorfaintsix text-[13px] leading-[13px] opacity-[0.7]'>
            {chatText}
          </div>
        </div>
      </div>
    </div>

  )
}

export default Chatitem