import React, { useState } from 'react';
import Outletouterpage from '../../../../components/dashboard/outletouterpage/outletouterpage';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Customdatepicker from '../../../../components/customdatepicker/customdatepicker';
import { nanoid } from '@reduxjs/toolkit';
import { transports } from '../../../../utils/appconstants';
import Parkinglistfield from '../parkinglist/parkinglistfield/parkinglistfield';

const Billoflading = () => {
    const [payload, setPayload] = useState<any>({
        shipper: undefined,
        quoteNumber: undefined,
        date: undefined,
        consignee: undefined,
        portOfLoading: undefined,
        methodOfDisphatch: undefined,
        portOfDischarge: undefined,
        typeOfShipment: undefined,
        additionalInformation: undefined,
        placeOfIssue: undefined,
        dateOfIssue: undefined,
        signatoryCompany: undefined,
        nameOfAuthorizedSignature: undefined,
        signature: undefined,
        carrierName: undefined,
        notify: undefined,
        additionalNotify: undefined,
        preCarriageBy: undefined,
        placeOfDelivery: undefined,
        placeOfReceipt: undefined,
        additonalInformation: undefined,
        voyageNumber: undefined,
        transport: undefined,
        uniqueConsignmentReference: undefined,
        shipperReference: undefined,
        carrierReference: undefined
    });
    const [errors, setErrors] = useState<any>({
        shipper: '',
        quoteNumber: '',
        date: '',
        consignee: '',
        portOfLoading: '',
        portOfDischarge: '',
        methodOfDisphatch: '',
        typeOfShipment: '',
        additionalInformation: '',
        placeOfIssue: '',
        dateOfIssue: '',
        signatoryCompany: '',
        nameOfAuthorizedSignature: '',
        signature: '',
        carrierName: '',
        notify: '',
        additionalNotify: '',
        preCarriageBy: '',
        placeOfDelivery: '',
        placeOfReceipt: '',
        additonalInformation: '',
        voyageNumber: '',
        transport: '',
        uniqueConsignmentReference: '',
        shipperReference: '',
        carrierReference: ''
    });
    const [totalAmount, setTotalAmount] = useState(0);
    const [quoteFields, setQuoteFields] = useState([
        {
            id: nanoid(9),
            desc: undefined,
            qty: 0,
            kindOfPackage: undefined,
            qtyOfPackage: undefined,
            weightOfPackageKG: undefined,
            grossWeightOfPackageKG: undefined,
            measurementOfPackage: undefined
        }
    ]);

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    /**
     * Update quotes input fields
     * @param id string
     * @param name string
     * @param value any
     */
    const updateQuoteField = (index: number, name: string, value: any) => {
        const newContainers: any[] = [...quoteFields];
        newContainers[index][name] = value;
        setQuoteFields(newContainers);
    };

    /**
     * Adds quotes input field line
     */
    const addQuoteField = () => {
        setQuoteFields([
            ...quoteFields,
            {
                id: nanoid(9),
                desc: undefined,
                qty: 0,
                kindOfPackage: undefined,
                qtyOfPackage: undefined,
                weightOfPackageKG: undefined,
                grossWeightOfPackageKG: undefined,
                measurementOfPackage: undefined
            }
        ]);
    };

    /**
     * Remove quotes input field line
     */
    const removeQuoteField = (index: number) => {
        setQuoteFields(quoteFields.filter((item, i) => i !== index));
    };

    return (
        <div className=''>
            <Outletouterpage
                title={
                    <div className='flex items-center justify-between'>
                        <h1 className='text-[32px] leading-[23px] text-appcolorblacktwo'>
                            Bill of Lading: WOVEH4H
                        </h1>

                        <div className='flex items-center'>
                            <p className='flex items-center cursor-pointer bg-appcolorwhite rounded-[10px] py-[0.7rem] px-[0.9rem] mr-2'>
                                <img
                                    src='/img/vectors/eye.svg'
                                    alt='eye'
                                    className='h-[1.1rem]'
                                />
                            </p>

                            <p className='flex items-center cursor-pointer bg-appcolorwhite rounded-[10px] py-[0.7rem] px-[0.9rem] mr-2'>
                                <img
                                    src='/img/vectors/plainupload.svg'
                                    alt='plainupload'
                                    className='h-[1.1rem]'
                                />
                            </p>

                            <p className='flex items-center cursor-pointer bg-appcolorwhite rounded-[10px] py-[0.7rem] px-[0.9rem] mr-2'>
                                <img
                                    src='/img/vectors/plaindownload.svg'
                                    alt='plaindownload'
                                    className='h-[1.1rem]'
                                />
                            </p>

                            <p className='flex items-center cursor-pointer bg-appcolorprimary rounded-[10px] py-[0.7rem] px-[0.9rem] mr-2'>
                                <img
                                    src='/img/vectors/plainsave.svg'
                                    alt='plainsave'
                                    className='h-[1.1rem]'
                                />
                            </p>
                        </div>
                    </div>
                }
                breadcrumbs={[
                    {
                        title: 'Home'
                    },
                    {
                        title: 'Documents'
                    },
                    {
                        title: 'Bill of Lading'
                    }
                ]}
            >
                <div className='rounded-[3px] bg-appcolorwhite py-[3rem] '>
                    <div className='border-0 border-appcolorgreyfaint'>
                        <form>
                            <div className='px-[3rem]'>
                                <Grid container>
                                    <Grid item xs={12} md={12} lg={6}>
                                        <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 h-full'>
                                            <TextField
                                                select
                                                error={
                                                    errors?.shipper.length > 0
                                                }
                                                helperText={
                                                    errors?.shipper.length > 0
                                                        ? errors?.shipper
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='quotation-shipper'
                                                label='Shipper'
                                                name='shipper'
                                                onChange={(e: any) => {
                                                    updateField(
                                                        'shipper',
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <MenuItem value={'FrontEdge'}>
                                                    FrontEdge
                                                </MenuItem>
                                            </TextField>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={6}>
                                        <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 h-full'>
                                            <Grid
                                                container
                                                spacing={4}
                                                sx={{
                                                    marginTop: '0px'
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={3}
                                                    lg={6}
                                                >
                                                    <TextField
                                                        error={
                                                            errors
                                                                ?.shipperReference
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors
                                                                ?.shipperReference
                                                                .length > 0
                                                                ? errors?.shipperReference
                                                                : undefined
                                                        }
                                                        margin='normal'
                                                        required
                                                        fullWidth
                                                        id='quotation-shipperReference'
                                                        label="Shipper's Reference"
                                                        name='shippers'
                                                        onChange={(e: any) => {
                                                            updateField(
                                                                'shipperReference',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={3}
                                                    lg={6}
                                                >
                                                    <TextField
                                                        error={
                                                            errors
                                                                ?.carrierReference
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors
                                                                ?.carrierReference
                                                                .length > 0
                                                                ? errors?.carrierReference
                                                                : undefined
                                                        }
                                                        margin='normal'
                                                        required
                                                        fullWidth
                                                        id='quotation-carrierReference'
                                                        label="Carrier's Reference"
                                                        name='carrierReference'
                                                        onChange={(e: any) => {
                                                            updateField(
                                                                'carrierReference',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <TextField
                                                error={
                                                    errors
                                                        ?.uniqueConsignmentReference
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors
                                                        ?.uniqueConsignmentReference
                                                        .length > 0
                                                        ? errors?.uniqueConsignmentReference
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='quotation-uniqueConsignmentReference'
                                                label='Unique Consignment Reference'
                                                name='uniqueConsignmentReference'
                                                onChange={(e: any) => {
                                                    updateField(
                                                        'uniqueConsignmentReference',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={12} md={12} lg={6}>
                                        <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 h-full'>
                                            <TextField
                                                select
                                                error={
                                                    errors?.consignee.length > 0
                                                }
                                                helperText={
                                                    errors?.consignee.length > 0
                                                        ? errors?.consignee
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='quotation-consignee'
                                                label='Consignee'
                                                name='consignee'
                                                onChange={(e: any) => {
                                                    updateField(
                                                        'consignee',
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <MenuItem value={'FrontEdge'}>
                                                    FrontEdge
                                                </MenuItem>
                                            </TextField>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={6}>
                                        <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 h-full'>
                                            <TextField
                                                select
                                                error={
                                                    errors?.carrierName.length >
                                                    0
                                                }
                                                helperText={
                                                    errors?.carrierName.length >
                                                    0
                                                        ? errors?.carrierName
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='quotation-carrierName'
                                                label='Carrier Name'
                                                name='carrierName'
                                                onChange={(e: any) => {
                                                    updateField(
                                                        'carrierName',
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <MenuItem value={'FrontEdge'}>
                                                    FrontEdge
                                                </MenuItem>
                                            </TextField>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={12} md={12} lg={6}>
                                        <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 h-full'>
                                            <TextField
                                                select
                                                error={
                                                    errors?.notify.length > 0
                                                }
                                                helperText={
                                                    errors?.notify.length > 0
                                                        ? errors?.notify
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='quotation-notify'
                                                label='Notify Party (If not Consignee)'
                                                name='notify'
                                                onChange={(e: any) => {
                                                    updateField(
                                                        'notify',
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <MenuItem value={'FrontEdge'}>
                                                    FrontEdge
                                                </MenuItem>
                                            </TextField>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={3} lg={6}>
                                        <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 h-full'>
                                            <TextField
                                                select
                                                error={
                                                    errors?.additionalNotify
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.additionalNotify
                                                        .length > 0
                                                        ? errors?.additionalNotify
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='quotation-additionalNotify'
                                                label='Additional Notify Party'
                                                name='additionalNotify'
                                                onChange={(e: any) => {
                                                    updateField(
                                                        'additionalNotify',
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <MenuItem value={'FrontEdge'}>
                                                    FrontEdge
                                                </MenuItem>
                                            </TextField>
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={12} md={12} lg={6}>
                                        <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 h-full'>
                                            <Grid
                                                container
                                                spacing={4}
                                                sx={{
                                                    marginTop: '0px'
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <TextField
                                                        error={
                                                            errors
                                                                ?.preCarriageBy
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors
                                                                ?.preCarriageBy
                                                                .length > 0
                                                                ? errors?.preCarriageBy
                                                                : undefined
                                                        }
                                                        margin='normal'
                                                        required
                                                        fullWidth
                                                        id='quotation-preCarriageBy'
                                                        label='Pre-Carriage By'
                                                        name='preCarriageBy'
                                                        onChange={(e: any) => {
                                                            updateField(
                                                                'preCarriageBy',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <TextField
                                                        select
                                                        error={
                                                            errors
                                                                ?.placeOfReceipt
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors
                                                                ?.placeOfReceipt
                                                                .length > 0
                                                                ? errors?.placeOfReceipt
                                                                : undefined
                                                        }
                                                        margin='normal'
                                                        required
                                                        fullWidth
                                                        id='proformainvoice-placeOfReceipt'
                                                        label='Place of Receipt'
                                                        name='placeOfReceipt'
                                                        onChange={(e: any) => {
                                                            updateField(
                                                                'placeOfReceipt',
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <MenuItem
                                                            value={'Lagos'}
                                                        >
                                                            Lagos
                                                        </MenuItem>
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6}>
                                        <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 h-full'>
                                            <TextField
                                                error={
                                                    errors?.additonalInformation
                                                        .length > 0
                                                }
                                                helperText={
                                                    errors?.additonalInformation
                                                        .length > 0
                                                        ? errors?.additonalInformation
                                                        : undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='quotation-additonalInformation'
                                                label='Additonal Information'
                                                name='additonalInformation'
                                                onChange={(e: any) => {
                                                    updateField(
                                                        'additonalInformation',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={12} md={12} lg={6}>
                                        <Grid container>
                                            <Grid item xs={12} md={12} lg={4}>
                                                <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 h-full'>
                                                    <TextField
                                                        select
                                                        error={
                                                            errors?.transport
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors?.transport
                                                                .length > 0
                                                                ? errors?.transport
                                                                : undefined
                                                        }
                                                        margin='normal'
                                                        required
                                                        fullWidth
                                                        id='commercialinvoice-transport'
                                                        label='Transport'
                                                        name='transport'
                                                        onChange={(e: any) => {
                                                            updateField(
                                                                'transport',
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        {transports.map(
                                                            (item, index) => (
                                                                <MenuItem
                                                                    value={item}
                                                                    key={`transports-${item}-${index}`}
                                                                >
                                                                    {item}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </TextField>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={12} lg={4}>
                                                <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 h-full'>
                                                    <TextField
                                                        error={
                                                            errors?.voyageNumber
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors?.voyageNumber
                                                                .length > 0
                                                                ? errors?.voyageNumber
                                                                : undefined
                                                        }
                                                        margin='normal'
                                                        required
                                                        fullWidth
                                                        id='proformainvoice-voyageNumber'
                                                        label='Voyage Number'
                                                        name='voyageNumber'
                                                        onChange={(e: any) => {
                                                            updateField(
                                                                'voyageNumber',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={12} lg={4}>
                                                <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 h-full'>
                                                    <TextField
                                                        select
                                                        error={
                                                            errors
                                                                ?.placeOfDelivery
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors
                                                                ?.placeOfDelivery
                                                                .length > 0
                                                                ? errors?.placeOfDelivery
                                                                : undefined
                                                        }
                                                        margin='normal'
                                                        required
                                                        fullWidth
                                                        id='commercialinvoice-placeOfDelivery'
                                                        label='Place of Delivery'
                                                        name='placeOfDelivery'
                                                        onChange={(e: any) => {
                                                            updateField(
                                                                'placeOfDelivery',
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <MenuItem
                                                            value={'Lagos'}
                                                        >
                                                            Lagos
                                                        </MenuItem>
                                                    </TextField>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6}>
                                        <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4'>
                                            <Grid
                                                container
                                                spacing={6}
                                                sx={{
                                                    marginTop: '0px'
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <TextField
                                                        select
                                                        error={
                                                            errors
                                                                ?.portOfLoading
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors
                                                                ?.portOfLoading
                                                                .length > 0
                                                                ? errors?.portOfLoading
                                                                : undefined
                                                        }
                                                        margin='normal'
                                                        required
                                                        fullWidth
                                                        id='commercialinvoice-portofloading'
                                                        label='Port of Loading'
                                                        name='portOfLoading'
                                                        onChange={(e: any) => {
                                                            updateField(
                                                                'portOfLoading',
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <MenuItem
                                                            value={'Lagos'}
                                                        >
                                                            Lagos
                                                        </MenuItem>
                                                    </TextField>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <TextField
                                                        select
                                                        error={
                                                            errors
                                                                ?.portOfDischarge
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors
                                                                ?.portOfDischarge
                                                                .length > 0
                                                                ? errors?.portOfDischarge
                                                                : undefined
                                                        }
                                                        margin='normal'
                                                        required
                                                        fullWidth
                                                        id='commercialinvoice-portOfDischarge'
                                                        label='Port of Discharge'
                                                        name='portOfDischarge'
                                                        onChange={(e: any) => {
                                                            updateField(
                                                                'portOfDischarge',
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <MenuItem
                                                            value={'Lagos'}
                                                        >
                                                            Lagos
                                                        </MenuItem>
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>

                            {quoteFields.map((item, index) => (
                                <Parkinglistfield
                                    key={item.id}
                                    index={index}
                                    id={item.id}
                                    updateQuoteField={updateQuoteField}
                                    kindOfPackage={item.kindOfPackage}
                                    qtyOfPackage={item.qtyOfPackage}
                                    weightOfPackageKG={item.weightOfPackageKG}
                                    grossWeightOfPackageKG={
                                        item.grossWeightOfPackageKG
                                    }
                                    measurementOfPackage={
                                        item.measurementOfPackage
                                    }
                                    qty={item.qty}
                                    removeQuoteField={removeQuoteField}
                                />
                            ))}

                            <div className='px-[3rem]'>
                                <div className='border border-appcolorgreyfaint px-[1.5rem] pt-8 pb-8'>
                                    <div
                                        className='flex items-center cursor-pointer w-fit'
                                        onClick={() => {
                                            addQuoteField();
                                        }}
                                    >
                                        <div>
                                            <img
                                                src='/img/vectors/primarydownload.svg'
                                                alt='primarydownload'
                                                className='w-6'
                                            />
                                        </div>
                                        <span className='pl-2 text-appcolorprimary'>
                                            Add Field
                                        </span>
                                    </div>
                                </div>

                                <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4'>
                                    <Grid
                                        container
                                        spacing={4}
                                        sx={{
                                            marginTop: '0px'
                                        }}
                                    >
                                        <Grid item xs={12} md={12} lg={4}>
                                            <TextField
                                                disabled
                                                margin='normal'
                                                fullWidth
                                                id='commercialinvoice-totalNetWeight'
                                                label='Total net weight (kg)'
                                                name='totalNetWeight'
                                                value={totalAmount}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={4}>
                                            <TextField
                                                disabled
                                                margin='normal'
                                                fullWidth
                                                id='commercialinvoice-totalGrossWeight'
                                                label='Total gross weight (kg)'
                                                name='totalGrossWeight'
                                                value={totalAmount}
                                            />
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={4}>
                                            <TextField
                                                disabled
                                                margin='normal'
                                                fullWidth
                                                id='commercialinvoice-totalVolume'
                                                label='Total volume (m³)'
                                                name='totalVolume'
                                                value={totalAmount}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>

                                <Grid container>
                                    <Grid item xs={12} md={12} lg={6}>
                                        <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 h-full'>
                                            <TextField
                                                multiline
                                                rows={4.5}
                                                // error={errors?.additionalInformation.length > 0}
                                                // helperText={errors?.additionalInformation.length > 0 ? errors?.additionalInformation : undefined}
                                                margin='normal'
                                                // required
                                                fullWidth
                                                id='quotation-additionalInformation'
                                                label='Additional Information'
                                                name='additionalInformation'
                                                onChange={(e: any) => {
                                                    updateField(
                                                        'additionalInformation',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={6}>
                                        <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 h-full'>
                                            <Grid
                                                container
                                                spacing={6}
                                                sx={{
                                                    marginTop: '0px'
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <TextField
                                                        select
                                                        error={
                                                            errors?.placeOfIssue
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors?.placeOfIssue
                                                                .length > 0
                                                                ? errors?.placeOfIssue
                                                                : undefined
                                                        }
                                                        margin='normal'
                                                        required
                                                        fullWidth
                                                        id='quotation-placeOfIssue'
                                                        label='Place of Issue'
                                                        name='placeOfIssue'
                                                        onChange={(e: any) => {
                                                            updateField(
                                                                'placeOfIssue',
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        <MenuItem
                                                            value={'Lagos'}
                                                        >
                                                            Lagos
                                                        </MenuItem>
                                                    </TextField>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={6}
                                                >
                                                    <div className='pt-4'>
                                                        <Customdatepicker
                                                            id='quotation-dateOfIssue'
                                                            label='Date of Issue'
                                                            name='dateOfIssue'
                                                            updateField={
                                                                updateField
                                                            }
                                                            error={
                                                                errors
                                                                    ?.dateOfIssue
                                                                    .length > 0
                                                            }
                                                            helperText={
                                                                errors
                                                                    ?.dateOfIssue
                                                                    .length > 0
                                                                    ? errors?.dateOfIssue
                                                                    : undefined
                                                            }
                                                        />
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={12}
                                                >
                                                    <TextField
                                                        error={
                                                            errors
                                                                ?.signatoryCompany
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors
                                                                ?.signatoryCompany
                                                                .length > 0
                                                                ? errors?.signatoryCompany
                                                                : undefined
                                                        }
                                                        margin='normal'
                                                        required
                                                        fullWidth
                                                        id='quotation-signatoryCompany'
                                                        label='Signatory Company'
                                                        name='signatoryCompany'
                                                        onChange={(e: any) => {
                                                            updateField(
                                                                'signatoryCompany',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={12}
                                                >
                                                    <TextField
                                                        error={
                                                            errors
                                                                ?.nameOfAuthorizedSignature
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors
                                                                ?.nameOfAuthorizedSignature
                                                                .length > 0
                                                                ? errors?.nameOfAuthorizedSignature
                                                                : undefined
                                                        }
                                                        margin='normal'
                                                        required
                                                        fullWidth
                                                        id='quotation-nameOfAuthorizedSignature'
                                                        label='Name of Authorized Signature'
                                                        name='nameOfAuthorizedSignature'
                                                        onChange={(e: any) => {
                                                            updateField(
                                                                'nameOfAuthorizedSignature',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    md={12}
                                                    lg={12}
                                                >
                                                    <TextField
                                                        error={
                                                            errors?.signature
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors?.signature
                                                                .length > 0
                                                                ? errors?.signature
                                                                : undefined
                                                        }
                                                        margin='normal'
                                                        required
                                                        fullWidth
                                                        id='quotation-signature'
                                                        label='Signature'
                                                        name='signature'
                                                        onChange={(e: any) => {
                                                            updateField(
                                                                'signature',
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </div>
                </div>
            </Outletouterpage>
        </div>
    );
};

export default Billoflading;
