import React from 'react';
import {
    capitalizeTextChar,
    commaNumber,
    getRecipientName,
    getTransactionStatus,
    getTransactionType
} from '../../../utils/helper';
import { Transactions } from './interfaces/Transactions';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import StatusIndicator from '../../../components/StatusIndicator';

const ViewTransactionsSummary = ({
    transactionDetails
}: {
    transactionDetails?: Transactions;
}) => {
    const { user } = useSelector((state: RootState) => state.user);

    return (
        <>
            <div className='bg-appcolorwhite rounded-[3px] hidden lg:flex items-center py-[1.9rem] px-[1.5rem] mb-4'>
                <div className='flex custom-piped view'>
                    <div className='rounded-[50%] w-[3.3rem] h-[3.3rem ] bg-appcolorprimaryfaintthree py-[0.9rem] px-[0.7rem]'>
                        <span className='font-bold text-appcolorprimary text-[18px] leading-[22p] opacity-[0.5]'>
                            {capitalizeTextChar(
                                transactionDetails?.businessName.split(' ')[0]
                            )}
                            {capitalizeTextChar(
                                transactionDetails?.businessName.split(' ')[1]
                            )}
                        </span>
                    </div>

                    <div className='pl-[0.9rem] mr-5'>
                        <p className='pb-[0.3rem] text-appcolorblacktwo opacity-[0.6]'>
                            Transaction ID
                        </p>
                        <p className='font-bold'>
                            {transactionDetails?.transactionReference}
                        </p>
                    </div>
                </div>

                <div className='custom-piped view'>
                    <div className='pl-[0.9rem] mr-5'>
                        <p className='pb-[0.3rem] text-appcolorblacktwo opacity-[0.6]'>
                            Date
                        </p>
                        <p className='font-bold'>
                            {dayjs(transactionDetails?.createdDate).format(
                                'll'
                            )}
                        </p>
                    </div>
                </div>

                <div className='custom-piped view'>
                    <div className='pl-[0.9rem] mr-5'>
                        <p className='pb-[0.3rem] text-appcolorblacktwo opacity-[0.6]'>
                            Transaction Amount
                        </p>
                        <p className='font-bold'>
                            {transactionDetails?.fromCurrencyCode}{' '}
                            {commaNumber(
                                Number(transactionDetails?.amountFrom).toFixed(
                                    2
                                )
                            )}
                        </p>
                    </div>
                </div>

                <div className='custom-piped view'>
                    <div className='pl-[0.9rem] mr-5'>
                        <p className='pb-[0.3rem] text-appcolorblacktwo opacity-[0.6]'>
                            Beneficiary
                        </p>
                        <p className='font-bold'>
                            {transactionDetails?.transactionAction ===
                            'CREDIT' ? (
                                <>{user?.name}</>
                            ) : (
                                <>
                                    {getRecipientName({
                                        transactionDetails,
                                        user
                                    })}
                                </>
                            )}
                        </p>
                    </div>
                </div>

                <div className=''>
                    <div className='pl-[0.9rem] mr-5'>
                        <p className='pb-[0.3rem] text-appcolorblacktwo opacity-[0.6]'>
                            Status
                        </p>
                        <p className='font-bold'>
                            {getTransactionStatus(
                                transactionDetails?.status ?? 0
                            )}
                        </p>
                    </div>
                </div>
            </div>

            <div className='block bg-white flex flex-col items-center pt-[24px] pb-[40px]  lg:hidden'>
                <StatusIndicator
                    status={getTransactionStatus(
                        transactionDetails?.status ?? 0
                    )}
                />

                <p className='mt-[16px] text-paleSky text-sm font-norma'>
                    {getTransactionType(transactionDetails?.transactionType)}
                </p>
                <p className='text-lg font-bold text-ebony'>
                    {`${transactionDetails?.fromCurrencyCode}${transactionDetails?.amountFrom?.toLocaleString()} - ${transactionDetails?.toCurrencyCode}${transactionDetails?.amountTo?.toLocaleString()}`}
                </p>
                <p className='mt-[8px] text-paleSky text-sm font-norma'>
                    {transactionDetails?.businessName}
                </p>
            </div>
        </>
    );
};

export default ViewTransactionsSummary;
