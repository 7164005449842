import { Grid, TextField } from '@mui/material';
import React from 'react';
import { TotalsProps } from '../../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/parkinglistTypes';

const Totals = ({
    totalUnitQuantity,
    totalNetWeight,
    totalGrossWeight,
    totalVolume
}: TotalsProps) => {
    return (
        <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
            <Grid
                container
                spacing={1}
                sx={{
                    marginTop: '0px'
                }}
            >
                <Grid item xs={12} md={12} lg={3}>
                    <TextField
                        disabled
                        margin='normal'
                        fullWidth
                        id='commercialinvoice-totalUnit'
                        label='Total Unit Qty'
                        name='totalUnit'
                        value={totalUnitQuantity}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={3}>
                    <TextField
                        disabled
                        margin='normal'
                        fullWidth
                        id='commercialinvoice-totalNetWeight'
                        label='Total net weight (kg)'
                        name='totalNetWeight'
                        value={totalNetWeight}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={3}>
                    <TextField
                        disabled
                        margin='normal'
                        fullWidth
                        id='commercialinvoice-totalGrossWeight'
                        label='Total gross weight (kg)'
                        name='totalGrossWeight'
                        value={totalGrossWeight}
                    />
                </Grid>

                <Grid item xs={12} md={12} lg={3}>
                    <TextField
                        disabled
                        margin='normal'
                        fullWidth
                        value={totalVolume}
                        id='commercialinvoice-totalVolume'
                        label='Total volume (m³)'
                        name='totalVolume'
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default Totals;
