import { CustomDatepicker } from '../../../../../components/CustomDatepicker';
import { ContainerHaulage } from '../interfaces/ContainerHaulage';
import { useState } from 'react';
import { ModalProps } from '../interfaces/ModalProps';
import { CustomModalActions } from '../../../../../components/CustomModalActions';
import CustomTextField from '../../../../../components/CustomTextInput';

export const HaulageModal = (props: ModalProps<ContainerHaulage>) => {
    const { payload, onHandleClose } = props;
    const [haulage, setHaulage] = useState(payload);

    return (
        <div className='flex flex-col gap-4 p-4 lg:p-[40px] lg:w-[30vw]'>
            <CustomTextField
                value={haulage?.companyName}
                label='Company Name'
                onChange={(e) => {
                    setHaulage((prev) => ({
                        ...prev,
                        companyName: e.target.value
                    }));
                }}
            />

            <CustomTextField
                multiline
                rows={5}
                height={150}
                value={haulage?.address}
                label='Address'
                onChange={(e) =>
                    setHaulage((prev) => ({
                        ...prev,
                        address: e.target.value
                    }))
                }
            />

            <CustomDatepicker
                required={true}
                value={haulage?.pickUpDate}
                label='Requested Empty Pick-Up Date'
                onHandleChange={(v) => {
                    if (v) {
                        setHaulage((prev) => ({
                            ...prev,
                            pickUpDate: new Date(v).toISOString()
                        }));
                    }
                }}
            />

            <CustomTextField
                value={haulage?.contactName}
                label='Contact Name'
                onChange={(e) =>
                    setHaulage((prev) => ({
                        ...prev,
                        contactName: e.target.value
                    }))
                }
            />

            <CustomTextField
                type='number'
                value={haulage?.contactPhone}
                label='Contact Phone Number'
                onChange={(e) =>
                    setHaulage((prev) => ({
                        ...prev,
                        contactPhone: e.target.value
                    }))
                }
            />

            <CustomModalActions
                onHandleSave={() => {
                    onHandleClose();
                }}
                onHandleClear={onHandleClose}
            />
        </div>
    );
};
