import React from 'react';
import { SalesSummaryContainer } from '../../../../styles/SalesStyles';
import { Avatar, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { getContractStatus } from '../../../../utils/helper';
import { getInvoiceContractStatusIcon } from '../../../icons/InvoiceStatusIcons';

interface IProps {
    details?: any;
}

const ContractDetailsSummary = ({ details }: IProps) => {
    function stringAvatar(name: string) {
        return {
            sx: {
                bgcolor: '#E5F3F7',
                color: '#0088B29a',
                width: '54px',
                height: '54px',
                fontSize: '18px',
                fontWeight: '600'
            },
            children: `${name}`
        };
    }

    return (
        <>
            <SalesSummaryContainer className='hidden lg:flex'>
                <Stack direction='row' gap='12px' className='first-details'>
                    <Avatar {...stringAvatar('#')} />
                    <div>
                        <p>Contract Number</p>
                        <h4>{details?.salesContractNumber || ''}</h4>
                    </div>
                </Stack>
                <div className='other-details'>
                    <p>Customer</p>
                    <h4>{details?.buyer?.name || ''}</h4>
                </div>
                <div className='other-details'>
                    <p>Deadline</p>
                    <h4>{dayjs(details?.shippingDeadline).format('ll')}</h4>
                </div>
                <div className='other-details'>
                    <p>Port of Loading</p>
                    <h4>{details?.portOfLoading?.name || ''}</h4>
                </div>
                <div className='other-details'>
                    <p>Port of Discharge</p>
                    <h4>{details?.portsOfDestination?.[0]?.name || ''}</h4>
                </div>
                <div className='other-details'>
                    <p>Payment Terms</p>
                    <h4>{details?.paymentTerms || ''}</h4>
                </div>
                <div className='other-details'>
                    <p>Shipping Line</p>
                    <h4>{details?.shippingLines?.[0]?.name || ''}</h4>
                </div>
            </SalesSummaryContainer>
            <div className='flex lg:hidden items-center justify-center p-[2rem] flex-col gap-1 text-paleSky'>
                <div>
                    {getInvoiceContractStatusIcon(
                        getContractStatus(Number(details?.status)).toUpperCase()
                    )}
                </div>

                <p className='text-[14px]'>
                    Contract No: <b>{details?.salesContractNumber ?? ''}</b>
                </p>
                <h4 className='text-ebony text-[18px] font-bold'>
                    {details?.buyerName || ''}
                </h4>
                <p className='text-[14px]'>
                    Deadline:
                    <b>{dayjs(details?.shippingDeadline).format('ll')}</b>
                </p>
            </div>
        </>
    );
};

export default ContractDetailsSummary;
