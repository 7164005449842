import { MenuItem, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Notification } from '../interfaces/Notification';

dayjs.extend(relativeTime);

interface NotificationParams {
    notifications: Notification[];
    handleCloseNotification: (event: MouseEvent | TouchEvent) => void;
    onHandleNotificationClick: (ids: number[]) => void;
}

export const Notifications = (props: NotificationParams) => {
    const {
        notifications,
        handleCloseNotification,
        onHandleNotificationClick
    } = props;
    const [tabValue, setTabValue] = useState('All');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    return (
        <div className=''>
            <div className='flex justify-between pt-[1.6rem] px-[1.9rem] pb-2'>
                <h1 className='leading-6 text-[19px] font-bold text-appcolorprimary'>
                    Notifications
                </h1>
                <div
                    onClick={() => {
                        const ids = notifications
                            .filter(({ status }) => status === 1)
                            .map(({ id }) => id);
                        onHandleNotificationClick(ids);
                    }}
                    className='leading-6 text-[14px] underline text-appcolorprimary cursor-pointer'
                >
                    Mark all as read
                </div>
            </div>

            <div className='border-b border-appcolorprimary px-[1.9rem] mb-3'>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    textColor='primary'
                    indicatorColor='primary'
                    aria-label=''
                >
                    <Tab
                        value='All'
                        label={
                            <div className='flex items-center'>
                                <p className='text-[17px] leading-6 text-appcolorprimary mr-[0.5rem]'>
                                    All
                                </p>
                                <p className='text-[12px] py-1 px-2 rounded-[3px] text-appcolorwhite bg-appcoloryellow'>
                                    {notifications.length}
                                </p>
                            </div>
                        }
                        sx={{
                            paddingBottom: '0px',
                            textTransform: 'capitalize',
                            fontSize: '17px',
                            fontWeight: '500'
                        }}
                    />
                </Tabs>
            </div>

            <div className='h-[400px] overflow-scroll'>
                {notifications.map(({ id, message, createdDate }) => (
                    <MenuItem
                        onClick={(e) => {
                            handleCloseNotification(e as unknown as MouseEvent);
                            onHandleNotificationClick([id]);
                        }}
                        key={`notification-${id}`}
                    >
                        <div className='flex items-center'>
                            <div className='bg-appcolorprimary/[.1] rounded-[50%] p-2 mr-[0.4rem]'>
                                <img
                                    src='/img/logo/notificationlogo.svg'
                                    alt='logo'
                                />
                            </div>
                            <div className='w-[320px]'>
                                <p className='truncate'>{message}</p>
                                <p className='text-appcolorblackfaint text-[14px] leading-[18px]'>
                                    {dayjs().to(dayjs(new Date(createdDate)))}
                                </p>
                            </div>
                        </div>
                    </MenuItem>
                ))}
            </div>
        </div>
    );
};
