import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { BookingEntryDivider } from './BookingEntryDivider';
import { BookingForm } from './interfaces/BookingForm';
import { Cargo } from './interfaces/Cargo';
import useCommodities from '../../../../customhooks/useCommodities';
import { FormikProps } from 'formik';
import CustomTextField from '../../../../components/CustomTextInput';

interface CargoFormProps {
    content: Cargo;
    index: number;
    isRemoveShown?: boolean;
    formData: BookingForm;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
    setShowAddNewCommodity: React.Dispatch<React.SetStateAction<boolean>>;
    formik: FormikProps<BookingForm>;
}

export const CargoForm = ({
    content,
    formData,
    setFormData,
    setShowAddNewCommodity,
    index,
    isRemoveShown,
    formik
}: CargoFormProps) => {
    const { commoditiesList } = useCommodities();

    const onHandleRemoveItem = () => {
        setFormData((prev) => {
            prev.cargo.splice(index, 1);

            return {
                ...prev,
                cargo: prev.cargo
            };
        });
    };

    const lastCommodity = [...commoditiesList].pop();

    return (
        <>
            <BookingEntryDivider
                index={index}
                isRemoveShown={isRemoveShown}
                onHandleRemoveItem={onHandleRemoveItem}
            />

            <div className='flex flex-col gap-4 pb-4 lg:pb-0 lg:grid lg:grid-cols-2'>
                <div className='w-full'>
                    <Autocomplete
                        options={commoditiesList}
                        getOptionLabel={(option) => option.name}
                        value={commoditiesList.find(
                            (item) => item.id === content.commodityId
                        )}
                        renderInput={(params) => (
                            <CustomTextField
                                params={params}
                                label='Commodity'
                                required
                                error={
                                    !!(
                                        formik.errors.cargo &&
                                        (
                                            formik.errors.cargo[
                                                index
                                            ] as unknown as Cargo
                                        ).commodityId
                                    )
                                }
                                helperText={
                                    formik.errors.cargo &&
                                    String(
                                        (
                                            formik.errors.cargo[
                                                index
                                            ] as unknown as Cargo
                                        ).commodityId
                                    )
                                }
                            />
                        )}
                        renderOption={(props, { name }, state) => (
                            <Box key={state.index}>
                                <Box component='li' {...props}>
                                    {name}
                                </Box>
                                {lastCommodity?.name === name && (
                                    <Box
                                        className='cursor-pointer p-2 text-appcolorprimary'
                                        onMouseDown={() =>
                                            setShowAddNewCommodity(true)
                                        }
                                    >
                                        + Add a New Commodity
                                    </Box>
                                )}
                            </Box>
                        )}
                        onChange={(_, value) => {
                            if (value) {
                                const entry = {
                                    ...(content || {}),
                                    commodityId: value.id
                                };
                                setFormData((prev) => {
                                    prev.cargo.splice(index, 1, entry);

                                    const update = {
                                        ...prev,
                                        cargo: prev.cargo
                                    };

                                    formik.setValues(update);

                                    return update;
                                });
                            }
                        }}
                    />
                </div>
                <div className='w-full'>
                    <CustomTextField
                        required
                        value={content?.hsCode || ''}
                        label='HS Code'
                        disabled
                        error={
                            !!(
                                formik.errors.cargo &&
                                (formik.errors.cargo[index] as unknown as Cargo)
                                    .hsCode
                            )
                        }
                        helperText={
                            formik.errors.cargo &&
                            (formik.errors.cargo[index] as unknown as Cargo)
                                .hsCode
                        }
                        onChange={(e) => {
                            const entry = {
                                ...(content || {}),
                                hasCode: e.target.value
                            };
                            setFormData((prev) => {
                                prev.cargo.splice(index, 1, entry);

                                const update = {
                                    ...prev,
                                    cargo: prev.cargo
                                };

                                formik.setValues(update);

                                return update;
                            });
                        }}
                    />
                </div>

                <div className='w-full'>
                    <CustomTextField
                        value={content?.description || ''}
                        label='Description'
                        error={
                            !!(
                                formik.errors.cargo &&
                                (formik.errors.cargo[index] as unknown as Cargo)
                                    .description
                            )
                        }
                        helperText={
                            formik.errors.cargo &&
                            (formik.errors.cargo[index] as unknown as Cargo)
                                .description
                        }
                        onChange={(e) => {
                            const entry = {
                                ...(content || {}),
                                description: e.target.value
                            };
                            setFormData((prev) => {
                                prev.cargo.splice(index, 1, entry);

                                const update = {
                                    ...prev,
                                    cargo: prev.cargo
                                };

                                formik.setValues(update);

                                return update;
                            });
                        }}
                    />
                </div>

                <div className='flex flex-col gap-4 lg:grid lg:grid-cols-2'>
                    <CustomTextField
                        label='Cargo Weight (Excl. Tare)'
                        value={content.cargoWeight}
                        error={
                            !!(
                                formik.errors.cargo &&
                                (formik.errors.cargo[index] as unknown as Cargo)
                                    .cargoWeight
                            )
                        }
                        helperText={
                            formik.errors.cargo &&
                            (formik.errors.cargo[index] as unknown as Cargo)
                                .cargoWeight
                        }
                        onChange={(e) => {
                            const entry = {
                                ...(content || {}),
                                cargoWeight: e.target.value
                            };
                            setFormData((prev) => {
                                prev.cargo.splice(index, 1, entry);

                                const update = {
                                    ...prev,
                                    cargo: prev.cargo
                                };

                                formik.setValues(update);

                                return update;
                            });
                        }}
                    />

                    <CustomTextField
                        label='Net Weight'
                        type='number'
                        value={content.netWeight}
                        onChange={(e) => {
                            const entry = {
                                ...(content || {}),
                                netWeight: e.target.value
                            };
                            setFormData((prev) => {
                                prev.cargo.splice(index, 1, entry);

                                return {
                                    ...prev,
                                    cargo: prev.cargo
                                };
                            });
                        }}
                    />
                </div>
                <div className='flex flex-col gap-4 lg:grid lg:grid-cols-2'>
                    <div>
                        <Autocomplete
                            options={['KG', 'LBS'].map((label) => ({ label }))}
                            value={
                                content.weightUnit
                                    ? { label: content.weightUnit }
                                    : null
                            }
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <CustomTextField
                                    params={params}
                                    label='Weight Unit'
                                    required
                                    error={
                                        !!(
                                            formik.errors.cargo &&
                                            (
                                                formik.errors.cargo[
                                                    index
                                                ] as unknown as Cargo
                                            ).weightUnit
                                        )
                                    }
                                    helperText={
                                        formik.errors.cargo &&
                                        (
                                            formik.errors.cargo[
                                                index
                                            ] as unknown as Cargo
                                        ).weightUnit
                                    }
                                />
                            )}
                            onChange={(_, value) => {
                                if (value) {
                                    const entry = {
                                        ...(content || {}),
                                        weightUnit: value.label
                                    };
                                    setFormData((prev) => {
                                        prev.cargo.splice(index, 1, entry);

                                        const update = {
                                            ...prev,
                                            cargo: prev.cargo
                                        };

                                        formik.setValues(update);

                                        return update;
                                    });
                                }
                            }}
                        />
                    </div>
                    <CustomTextField
                        type='number'
                        label='Gross Volume'
                        value={content.grossVolume}
                        onChange={(e) => {
                            const entry = {
                                ...(content || {}),
                                grossVolume: e.target.value
                            };
                            setFormData((prev) => {
                                prev.cargo.splice(index, 1, entry);

                                return {
                                    ...prev,
                                    cargo: prev.cargo
                                };
                            });
                        }}
                    />
                </div>

                <div className='lg:flex lg:flex-col lg:gap-4 lg:pb-4 lg:pb-0 lg:grid lg:grid-cols-2'>
                    <div className='w-full'>
                        <Autocomplete
                            options={['CBM', 'CFT'].map((label) => ({ label }))}
                            value={
                                content.volumeUnit
                                    ? { label: content.volumeUnit }
                                    : null
                            }
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                                <CustomTextField
                                    params={params}
                                    label='Volume Unit'
                                    required
                                    error={
                                        !!(
                                            formik.errors.cargo &&
                                            (
                                                formik.errors.cargo[
                                                    index
                                                ] as unknown as Cargo
                                            ).volumeUnit
                                        )
                                    }
                                    helperText={
                                        formik.errors.cargo &&
                                        (
                                            formik.errors.cargo[
                                                index
                                            ] as unknown as Cargo
                                        ).volumeUnit
                                    }
                                />
                            )}
                            onChange={(_, value) => {
                                if (value) {
                                    const entry = {
                                        ...(content || {}),
                                        volumeUnit: value.label
                                    };
                                    setFormData((prev) => {
                                        prev.cargo.splice(index, 1, entry);

                                        const update = {
                                            ...prev,
                                            cargo: prev.cargo
                                        };

                                        formik.setValues(update);

                                        return update;
                                    });
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
