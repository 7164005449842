import React, { useEffect, useState } from 'react';
import { getCorporates, getIndustries } from '../../../api/base/base';
import { openNotification } from '../../../utils/helper';
import { Industry } from '../../../customhooks/industries/useIndustries';
import { BookingContainer } from '../../../components/dashboard/upsBooking/Styles';
import CorporatesHeader from '../../../components/dashboard/corporates/CorporatesHeader';
import { DocumentTableContainer } from '../../../styles/TradeDocStyles';
import AllRegisteredTable from '../../../components/dashboard/corporates/AllRegisteredTable';
import PendingTable from '../../../components/dashboard/corporates/PendingTable';
import ApprovedTable from '../../../components/dashboard/corporates/ApprovedTable';

const CorporatesNew = () => {
    const breadcrumbs = [
        {
            title: 'Home',
            link: '/dashboard',
            active: false
        },
        {
            title: 'Corportates',
            link: '/corporates',
            active: true
        }
    ];
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState<any>(null);
    const [activeView, setActiveView] = useState(undefined);

    const fetchCorporates = async (approvedOnly?: boolean) => {
        setLoadingData(true);
        try {
            const details = await getIndustries();

            const industries: Industry[] = details.data.data;

            const res = await getCorporates({});

            const { data } = res.data;

            let newContent;

            if (approvedOnly === true) {
                newContent = data.content
                    .filter((item: any) => item.status === 1)
                    .map((item: any) => {
                        return {
                            ...item,
                            industryName:
                                industries.filter(
                                    (itemIndustry) =>
                                        itemIndustry.id ===
                                        item.corporateIndustryId
                                )[0]?.name || '-'
                        };
                    });
            }

            if (approvedOnly === false) {
                newContent = data.content
                    .filter((item: any) => item.status !== 1)
                    .map((item: any) => {
                        return {
                            ...item,
                            industryName:
                                industries.filter(
                                    (itemIndustry) =>
                                        itemIndustry.id ===
                                        item.corporateIndustryId
                                )[0]?.name || '-'
                        };
                    });
            }

            if (approvedOnly === undefined) {
                newContent = data.content.map((item: any) => {
                    return {
                        ...item,
                        industryName:
                            industries.filter(
                                (itemIndustry) =>
                                    itemIndustry.id === item.corporateIndustryId
                            )[0]?.name || '-'
                    };
                });
            }

            setData({
                ...data,
                content: newContent
            });

            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            if (error?.response) {
                openNotification('error', `Corporates, Something went wrong`);
            } else {
                openNotification('error', `Corporates, Something went wrong`);
            }
        }
    };

    useEffect(() => {
        fetchCorporates(activeView);
    }, [activeView]);

    return (
        <BookingContainer>
            {' '}
            <CorporatesHeader {...{ breadcrumbs, setActiveView, activeView }} />
            <DocumentTableContainer className='bg-appcolorwhite'>
                {activeView === undefined && (
                    <AllRegisteredTable {...{ data, loadingData }} />
                )}
                {activeView === false && (
                    <PendingTable {...{ data, loadingData }} />
                )}
                {activeView === true && (
                    <ApprovedTable {...{ data, loadingData }} />
                )}
            </DocumentTableContainer>
        </BookingContainer>
    );
};

export default CorporatesNew;
