import { Button, Divider, Stack } from '@mui/material';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import {
    getDedicatedAccountStatus,
    getErrorMessage,
    openNotification
} from '../../../../utils/helper';
import CustomModal from '../../../../components/CustomModal';
import ApproveAccountDialog from './ApproveAccountDialog';
import { useNavigate } from 'react-router-dom';
import {
    ApproveRequestValues,
    DedicatedAccount
} from './interfaces/DedicatedAccounts';
import { mutateConfig } from '../../../../api/queryBase';

const RequestDetailsBreakdown = ({
    accountDetails
}: {
    accountDetails: DedicatedAccount | undefined;
}) => {
    const [showApproveAccountModal, setShowApproveAccountModal] =
        useState(false);
    const navigate = useNavigate();

    const mutation = mutateConfig();
    const { mutate, isLoading: isLoadingApproval } = mutation({
        url: `/administrator/corporates/${accountDetails?.corporate.id}/offshore/account/approve`
    });

    const handleDecline = async (values: ApproveRequestValues) => {
        mutate(values, {
            onSettled: (_, error) => {
                if (error) {
                    openNotification(
                        'error',
                        getErrorMessage(error) ||
                            'Decline Account, Something went wrong'
                    );
                    return;
                }
                navigate('/payments/dedicated-accounts');
            }
        });
    };

    return (
        <>
            <div className=' py-[15px] px-[30px] rounded-[10px] bg-white flex justify-between items-center'>
                <div className=' flex items-center gap-[15px]'>
                    <Stack>
                        <h4 className='color-[#475467] text-[15px] leading-[20px]'>
                            Business Name
                        </h4>
                        <p className='color-[#475467] text-[16px] font-bold tracking-[0.34px]  leading-[36px]'>
                            {accountDetails?.corporate.businessName}
                        </p>
                    </Stack>
                    <Divider
                        orientation='vertical'
                        sx={{ minHeight: '80px' }}
                    />
                    <Stack>
                        <h4 className='color-[#353542] text-[15px] leading-[20px] opacity-[0.7]'>
                            Date
                        </h4>
                        <p className='color-[#353542] text-[16px] font-bold tracking-[0.34px]  leading-[36px]'>
                            {dayjs(Number(accountDetails?.createdDate)).format(
                                'LL'
                            )}
                        </p>
                    </Stack>
                    <Divider
                        orientation='vertical'
                        sx={{ minHeight: '80px' }}
                    />

                    <Stack>
                        <h4 className='color-[#353542] text-[15px] leading-[20px] opacity-[0.7]'>
                            Status
                        </h4>
                        <p className='color-[#353542] text-[16px] font-bold tracking-[0.34px]  leading-[36px]'>
                            {getDedicatedAccountStatus(
                                accountDetails?.status ?? 0
                            )}
                        </p>
                    </Stack>
                </div>

                {accountDetails?.status === 2 && (
                    <div className='flex gap-[7px]'>
                        <Button
                            sx={{
                                background: '#B42318',
                                color: 'white',
                                borderRadius: '4px',
                                padding: '4px 8px',
                                height: '35px',
                                width: '128px',
                                '&hover': {
                                    backgroundColor: '#871912 '
                                }
                            }}
                            onClick={() => handleDecline({ approved: true })}
                        >
                            Decline
                        </Button>

                        <Button
                            sx={{
                                background: '#0088B2',
                                color: 'white',
                                borderRadius: '4px',
                                padding: '4px 8px',
                                height: '35px',
                                width: '128px'
                            }}
                            onClick={() => {
                                setShowApproveAccountModal(true);
                            }}
                        >
                            Approve
                        </Button>
                    </div>
                )}
            </div>
            <CustomModal
                maxWidth='md'
                open={showApproveAccountModal}
                onClose={() => setShowApproveAccountModal(false)}
                title=''
            >
                <ApproveAccountDialog
                    currentAccount={String(accountDetails?.corporate.id)}
                    {...{ setShowApproveAccountModal }}
                />
            </CustomModal>
        </>
    );
};

export default RequestDetailsBreakdown;
