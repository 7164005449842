import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import { updateCorporate } from '../../../../api/base/base';
import useBusinessTypes from '../../../../customhooks/businesstypes/useBusinesstypes';
import useIndustries from '../../../../customhooks/industries/useIndustries';
import { openNotification } from '../../../../utils/helper';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Custombutton from '../../../custombutton/custombutton';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../../store/userSlice/userSlice';

export interface updatebusinessdetailsProps {
    user: any;
    onClose: (refreshList?: boolean) => void;
}

const Updatebusinessdetails = ({
    user,
    onClose
}: updatebusinessdetailsProps) => {
    const { industries, isIndustriesLoading } = useIndustries();
    const { businessTypes, isBusinessTypesLoading } = useBusinessTypes();
    const disptch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState<any>({
        corporateIndustryId: undefined,
        businessTypes: undefined
    });
    const [error, setError] = useState<string | any>(null);
    const [errors, setErrors] = useState({
        corporateIndustryId: '',
        businessTypes: ''
    });

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    /**
     * Submit form
     * @param e Object
     */
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        // setErrors((prevState: any) => ({
        //   ...prevState,
        //   emailAddress: '',
        //   phoneNumber: '',
        //   cipherCode: '',
        //   rcNumber: '',
        //   businessName: '',
        //   countryId: '',
        // }))

        try {
            await updateCorporate(user?.corporate_id, {
                ...payload
            });

            setLoading(false);

            openNotification(
                'success',
                'Update Business Details, Details updated successfully'
            );

            disptch(
                updateUser({
                    user: {
                        ...user,
                        has_business_type: true,
                        has_business_industry: true
                    }
                })
            );

            onClose();
        } catch (error) {
            setLoading(false);
            if (error?.response?.data) {
                const errors = error?.response?.data?.error;
                Object.entries(errors).forEach(([key, value]) => {
                    setErrors((prevState) => ({
                        ...prevState,
                        [key]: value
                    }));
                });
            } else {
                openNotification('error', 'Something went wrong');
            }
        }
    };

    return (
        <div className='w-[25rem]'>
            {error !== null && (
                <Alert variant='outlined' severity='error'>
                    {error}
                </Alert>
            )}

            <form autoComplete='off' onSubmit={onSubmit} noValidate>
                <Autocomplete
                    disableClearable
                    id='signup-corporateIndustryId'
                    options={industries}
                    sx={{
                        marginTop: '1rem',
                        '.MuiInputBase-input': {
                            height: '0.3rem'
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Industry'
                            required={true}
                            error={errors?.corporateIndustryId.length > 0}
                            helperText={
                                errors?.corporateIndustryId.length > 0
                                    ? errors?.corporateIndustryId
                                    : undefined
                            }
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: isIndustriesLoading && (
                                    <React.Fragment>
                                        <CircularProgress size={20} />
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }}
                        />
                    )}
                    noOptionsText={'No data available'}
                    renderOption={(props, option: any) => (
                        <MenuItem
                            {...props}
                            value={option.id}
                            key={`countries-${option.id}`}
                        >
                            <div className='flex items-center'>
                                <span className='ml-2'>{option.name}</span>
                            </div>
                        </MenuItem>
                    )}
                    getOptionLabel={(option) => option.name}
                    onChange={(e: any, value: any) => {
                        if (value !== null) {
                            updateField(
                                'corporateIndustryId',
                                value.id.toString()
                            );
                        }
                    }}
                />

                <Autocomplete
                    multiple
                    disableClearable
                    id='signup-businessTypes'
                    options={businessTypes}
                    sx={{
                        marginTop: '1rem',
                        '.MuiInputBase-input': {
                            height: '0.3rem'
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label='Business Type'
                            required={true}
                            error={errors?.businessTypes.length > 0}
                            helperText={
                                errors?.businessTypes.length > 0
                                    ? errors?.businessTypes
                                    : undefined
                            }
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: isBusinessTypesLoading && (
                                    <React.Fragment>
                                        <CircularProgress size={20} />
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                )
                            }}
                        />
                    )}
                    noOptionsText={'No data available'}
                    renderOption={(props, option: any) => (
                        <MenuItem
                            {...props}
                            value={option.id}
                            key={`countries-${option.id}`}
                        >
                            <div className='flex items-center'>
                                <span className='ml-2'>{option.name}</span>
                            </div>
                        </MenuItem>
                    )}
                    getOptionLabel={(option) => option.name}
                    onChange={(e: any, value: any) => {
                        if (value !== null) {
                            const newValue = value.map((item: any) => {
                                return item.id;
                            });
                            updateField('businessTypes', newValue);
                        }
                    }}
                />

                <div className='mt-4'>
                    <Custombutton
                        isLoadingButton
                        loading={loading}
                        type='submit'
                        fullWidth
                        variant='contained'
                        styles={{
                            padding: '0.75rem 0.62rem 1rem 0.93rem'
                        }}
                        disabled={
                            Object.values(payload).some(
                                (item: any) => item === undefined
                            ) ||
                            Object.values(errors).some(
                                (item) => item.length > 0
                            ) ||
                            loading
                        }
                        buttonText='Update'
                    />
                </div>
            </form>
        </div>
    );
};

export default Updatebusinessdetails;
