import React, { ReactElement } from 'react';
import {
    InvoiceSummaryContainer,
    Main,
    imgIcon
} from '../invoice/InvoiceSummary';
import { Divider, Stack } from '@mui/material';
import { formatNumber } from '../../../../utils/helper';
import { DocumentTextIcon } from '../../../icons';
import CustomCarousel from '../../../CustomCarousel';
import WarehouseSummaryItem from '../../stock/WarehouseSummaryItem';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { useGetQuery } from '../../../../customhooks/useGetQuery';
import {
    SalesContractParams,
    SalesContractSummary
} from '../../../../pages/dashboard/payments/interfaces/SalesContracts';

const ContractSummary = ({
    filterOptions
}: {
    filterOptions: SalesContractParams;
}) => {
    const { user } = useSelector((state: RootState) => state.user);
    const { isLoading, entries } = useGetQuery<SalesContractSummary>({
        url: `/sales/contract/summary/${user?.corporate_id}`,
        cacheKey: 'sales-contract-summary',
        enabled: Boolean(user?.corporate_id)
    });

    const summaryData: { [key: string]: string | ReactElement }[] = [
        {
            label: 'No of Completed Contracts',
            value: formatNumber(entries?.completed || 0),
            icon: <DocumentTextIcon />
        },
        {
            label: 'No of Pending Contracts',
            value: formatNumber(entries?.pending || 0),
            icon: <DocumentTextIcon color='#DF835A' />
        },
        {
            label: 'Quantity of Completed Contract',
            value: entries?.totalPaid
                ? formatNumber(entries?.totalPaid)
                : 'N/A',
            icon: <DocumentTextIcon color='#008F46' />
        },
        {
            label: 'Quantity of Pending Contract',
            value: entries?.totalPaid
                ? formatNumber(entries?.totalPaid)
                : 'N/A',
            icon: <DocumentTextIcon color='#E44000' />
        }
    ];
    return (
        <>
            <Main className='hidden lg:block'>
                <InvoiceSummaryContainer>
                    <div className='summary-details'>
                        <Stack
                            direction='row'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <div>
                                <p>No of Completed Contracts</p>
                                <h3>{formatNumber(entries?.completed || 0)}</h3>
                            </div>
                            <div>{imgIcon}</div>
                        </Stack>
                    </div>
                    <Divider orientation='vertical' flexItem />

                    <div className='summary-details'>
                        <Stack
                            direction='row'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <div>
                                <p>No of Pending Contracts</p>
                                <h3>{formatNumber(entries?.pending || 0)}</h3>
                            </div>
                            <div className='red'>{imgIcon}</div>
                        </Stack>
                    </div>
                    <Divider orientation='vertical' flexItem />

                    <div className='summary-details'>
                        <Stack
                            direction='row'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <div>
                                <p>Quantity of Completed Contract</p>
                                <h3>
                                    {entries?.totalPaid
                                        ? formatNumber(entries?.totalPaid)
                                        : 'N/A'}
                                </h3>
                            </div>
                            <div className='green'>{imgIcon}</div>
                        </Stack>
                    </div>
                    <Divider orientation='vertical' flexItem />

                    <div className='summary-details'>
                        <Stack
                            direction='row'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <div>
                                <p>Quantity of pending contracts</p>
                                <h3>
                                    {entries?.totalPaid
                                        ? formatNumber(entries?.totalPaid)
                                        : 'N/A'}
                                </h3>
                            </div>
                            <div className='yellow'>{imgIcon}</div>
                        </Stack>
                    </div>
                </InvoiceSummaryContainer>
            </Main>
            <div className='lg:hidden mt-2 '>
                <CustomCarousel
                    data={summaryData}
                    component={<WarehouseSummaryItem />}
                />
            </div>
        </>
    );
};

export default ContractSummary;
