import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomStepper from '../../../../../components/CustomStepper';
import { ChevronLeftIcon } from '../../../../../components/icons';
import { CreateBookingProps } from '../interfaces/BookingForm';
import Carriage from './Carriage';
import GeneralDetails from './GeneralDetails';
import Payment from './Payment';
import References from './References';

const steps = [
    {
        label: 'General details'
    },
    {
        label: 'References'
    },
    {
        label: 'Confirmation'
    },
    {
        label: 'Confirmation'
    }
];

const CreateBookingMobile = ({
    formik,
    formData,
    setFormData,
    user,
    setShowAddNewCarrierModal,
    setShowAddParty,
    setShowAddNewCommodity,
    isLoadingBookingConnections,
    bookingConnections,
    showAdditionalSection,
    setShowAdditionalSection,
    onHandleSubmit
}: CreateBookingProps) => {
    const navigate = useNavigate();

    const [activeStep, setActiveStep] = useState(0);

    const views: {
        [key: number]: {
            label: string;
            component: ReactNode;
            hideLabel?: boolean;
        };
    } = {
        0: {
            label: 'General details',
            component: (
                <GeneralDetails
                    {...{
                        user,
                        formData,
                        setFormData,
                        activeStep,
                        setActiveStep,
                        setShowAddNewCarrierModal,
                        formik,
                        showAdditionalSection,
                        setShowAdditionalSection,
                        setShowAddParty
                    }}
                />
            )
        },
        1: {
            label: 'References',
            component: (
                <References
                    {...{
                        user,
                        formData,
                        setFormData,
                        activeStep,
                        setActiveStep,
                        setShowAddNewCarrierModal,
                        formik,
                        showAdditionalSection,
                        setShowAdditionalSection,
                        setShowAddParty
                    }}
                />
            )
        },
        2: {
            label: 'Main carriage',
            component: (
                <Carriage
                    {...{
                        user,
                        formData,
                        setFormData,
                        activeStep,
                        setActiveStep,
                        setShowAddNewCarrierModal,
                        formik,
                        showAdditionalSection,
                        setShowAdditionalSection,
                        setShowAddParty
                    }}
                />
            ),
            hideLabel: true
        },
        3: {
            label: 'Payment',
            component: (
                <Payment
                    {...{
                        user,
                        formData,
                        setFormData,
                        activeStep,
                        setActiveStep,
                        setShowAddNewCarrierModal,
                        formik,
                        setShowAddNewCommodity,
                        onHandleSubmit
                    }}
                />
            ),
            hideLabel: true
        }
    };

    return (
        <div className='lg:hidden p-[16px] pb-[80px] bg-white'>
            <button
                className='flex items-center gap-2 text-lg font-medium text-ebony cursor-pointer w-fit'
                onClick={() => {
                    navigate('/bookings');
                }}
            >
                <ChevronLeftIcon />
                <span>Bookings</span>
            </button>

            <h5 className='text-xl text-ebony py-4'>Create new booking</h5>

            <CustomStepper steps={steps} activeStep={activeStep} hideLabel />

            {!views[activeStep]?.hideLabel && (
                <div className='pt-[24px] pb-[12px] border-b border-dashed border-gray300 mb-[10px]'>
                    <p className='text-steelBlue'>{views[activeStep].label}</p>
                </div>
            )}

            {views[activeStep].component}
        </div>
    );
};

export default CreateBookingMobile;
