import React, { useEffect, useState } from 'react';

import { GoogleMap, useJsApiLoader, Polyline } from '@react-google-maps/api';
import { REACT_APP_GOOGLEAPIKEY } from '../../../../config';
import CustomModal from '../../../../components/CustomModal';
import { Box } from '@mui/material';

export interface ShippingPath {
    id: number;
    efShipmentId: number;
    longitude: number;
    latitude: number;
    shipmentDate: string;
    name: string;
}

export interface MappingProps {
    paths: ShippingPath[];
    isDrawer?: boolean;
    currentLong?: any;
    currentLat?: any;
}

const Marker = (_: { lat: number; lng: number }): JSX.Element => {
    return <></>;
};

export const Mapping = ({
    paths,
    isDrawer,
    currentLong,
    currentLat
}: MappingProps) => {
    const [visible, setVisible] = useState(false);
    const [pathDetail, setPathDetail] = useState<ShippingPath | null>(null);
    const { isLoaded } = useJsApiLoader({
        id: 'gms',
        googleMapsApiKey: REACT_APP_GOOGLEAPIKEY as string
    });

    const closeVisible = () => {
        setVisible(false);
        setPathDetail(null);
    };

    const center = {
        lat: currentLat
            ? currentLat
            : paths.length === 0
            ? 6.5244
            : paths[0].latitude,
        lng: currentLong
            ? currentLong
            : paths.length === 0
            ? 3.3792
            : paths[0].longitude
    };

    const [mapHeight, setMapHeight] = useState('25rem');

    useEffect(() => {
        const handleResize = () => {
            if (!isDrawer) {
                setMapHeight('100%');
            } else {
                if (window.innerWidth < 1024) {
                    setMapHeight('20rem');
                } else {
                    setMapHeight('100vh');
                }
            }
        };

        handleResize(); // Set the initial size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isDrawer]);

    return isLoaded ? (
        <Box>
            {
                <GoogleMap
                    mapContainerStyle={{
                        position: 'relative',
                        height: mapHeight,
                        width: '100%'
                    }}
                    center={center}
                    zoom={4}
                >
                    {currentLat && (
                        <Marker lat={currentLat} lng={currentLong} />
                    )}

                    {paths.length > 0 && (
                        <Polyline
                            options={{
                                strokeColor: '#0088B2',
                                strokeOpacity: 1,
                                strokeWeight: 5
                            }}
                            path={paths.map((item) => {
                                return {
                                    lat: item.latitude,
                                    lng: item.longitude
                                };
                            })}
                        />
                    )}
                </GoogleMap>
            }

            {visible ? (
                <CustomModal
                    maxWidth='xs'
                    open={visible}
                    onClose={closeVisible}
                    styles={{
                        overflowX: 'hidden'
                    }}
                >
                    <div>
                        <p>
                            <b>Location</b>: {pathDetail?.name}
                        </p>
                        <p>
                            <b>Shipment Date</b>: {pathDetail?.shipmentDate}
                        </p>
                    </div>
                </CustomModal>
            ) : null}
        </Box>
    ) : (
        <></>
    );
};
