import { Button } from '@mui/material';
import { SuiteUIAlert } from '../../../../components/SuiteUIAlert';
import Custombutton from '../../../../components/custombutton/custombutton';
import { GeneralDetailsForm } from './GeneralDetailsForm';
import { GeneralAdditionalDetailsForm } from './GeneralAdditionalDetailsForm';
import { PartiesForm } from './PartiesForm';
import { PartiesAdditionalForm } from './PartiesAdditionalForm';
import { ReferencesForm } from './ReferencesForm';
import { ReferencesAdditionalForm } from './ReferencesAdditionalForm';
import { MainCarriage, MetaCarriage } from './interfaces/Transport';
import { TransportForm } from './TransportForm';
import { CarriageFormSection } from './CarriageFormSection';
import { ContainerFormSection } from './ContainerFormSection';
import { CargoFormSection } from './CargoFormSection';
import { PaymentFormSection } from './PaymentFormSection';
import { CommentFormSection } from './CommentFormSection';
import { ShowHideSection } from './ShowHideSection';
import { MainCarriageForm } from './MainCarriageForm';
import { MetaCarriageForm } from './MetaCarriageForm';
import { CreateBookingProps } from './interfaces/BookingForm';

export const CreateBookingForm = ({
    formik,
    formData,
    setFormData,
    user,
    setShowAddNewCarrierModal,
    setShowAddParty,
    setShowAddNewCommodity,
    isLoadingBookingConnections,
    bookingConnections,
    showAdditionalSection,
    setShowAdditionalSection,
    onHandleSubmit
}: CreateBookingProps) => {
    return (
        <>
            {!isLoadingBookingConnections &&
                !bookingConnections.length &&
                user?.booking && (
                    <SuiteUIAlert
                        severity='info'
                        message='To book instantly going forward, you will need to first establish a connection with your carrier.'
                        actionComponent={
                            <Custombutton
                                onClickAction={() => {
                                    setShowAddNewCarrierModal(true);
                                }}
                                fullWidth
                                variant='contained'
                                styles={{
                                    padding: '0.2rem 0.92rem 0.2rem 0.93rem',
                                    width: '100%'
                                }}
                                buttonText='Establish Connection'
                            />
                        }
                    />
                )}

            <div>
                <div className='flex items-center justify-between'>
                    <div className='flex'>
                        <div
                            className={`bg-appcolorwhite trapezoid pt-[0.8rem] pb-[0.7rem] pl-[1.6rem] w-fit pr-[5.6rem] cursor-pointer text-[17px] leading-[24px] flex items-center`}
                        >
                            <span className='pl-2 text-appcolorprimary'>
                                Create New Booking
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    className={`py-[0.5rem] px-[2rem] bg-appcolorwhite pb-[2.4rem] mb-4 pt-4`}
                >
                    <section className='mt-3'>
                        <div className='pb-1 mb-8 border-b border-appcolorgreyfaint'>
                            <span className='opacity-[0.8]'>
                                General Details
                            </span>
                        </div>

                        <GeneralDetailsForm
                            {...{
                                user,
                                formData,
                                setFormData,
                                setShowAddNewCarrierModal,
                                formik
                            }}
                        />
                        <div className='mt-4'>
                            {showAdditionalSection.general && (
                                <GeneralAdditionalDetailsForm
                                    {...{
                                        user,
                                        formData,
                                        setFormData
                                    }}
                                />
                            )}
                            <ShowHideSection
                                {...{
                                    show: showAdditionalSection.general,
                                    title: 'Additional General Details',
                                    onHandleClick: () =>
                                        setShowAdditionalSection({
                                            ...showAdditionalSection,
                                            general:
                                                !showAdditionalSection.general
                                        })
                                }}
                            />
                        </div>
                    </section>

                    <section>
                        <div className='mt-8 pb-1 mb-8 border-b border-appcolorgreyfaint'>
                            <span className='opacity-[0.8]'>Parties</span>{' '}
                            <span className='text-appcolorred opacity-100 font-semibold text-[13px]'>
                                {' '}
                                ** Registered Shipper or Forwarder is required
                                **
                            </span>
                        </div>

                        <PartiesForm
                            {...{
                                user,
                                formData,
                                setFormData,
                                setShowAddParty,
                                formik
                            }}
                        />

                        <div className='mt-4'>
                            {showAdditionalSection.parties && (
                                <PartiesAdditionalForm
                                    {...{
                                        user,
                                        setFormData,
                                        setShowAddParty
                                    }}
                                />
                            )}
                            <ShowHideSection
                                {...{
                                    show: showAdditionalSection.parties,
                                    title: 'Additional Parties',
                                    onHandleClick: () =>
                                        setShowAdditionalSection({
                                            ...showAdditionalSection,
                                            parties:
                                                !showAdditionalSection.parties
                                        })
                                }}
                            />
                        </div>
                    </section>

                    <section>
                        <div className='mt-10 pb-1 mb-8 border-b border-appcolorgreyfaint'>
                            <span className='opacity-[0.8]'>References</span>
                        </div>

                        <ReferencesForm
                            {...{
                                formData,
                                setFormData
                            }}
                        />

                        <div className='mt-1'>
                            {showAdditionalSection.references && (
                                <ReferencesAdditionalForm
                                    {...{
                                        formData,
                                        setFormData
                                    }}
                                />
                            )}
                            <ShowHideSection
                                {...{
                                    show: showAdditionalSection.references,
                                    title: 'Additional References',
                                    onHandleClick: () =>
                                        setShowAdditionalSection({
                                            ...showAdditionalSection,
                                            references:
                                                !showAdditionalSection.references
                                        })
                                }}
                            />
                        </div>
                    </section>

                    <section>
                        <div className='mt-10 pb-1 mb-8 border-b border-appcolorgreyfaint'>
                            <span className='opacity-[0.8]'>Transport</span>
                        </div>

                        <div className='mt-1'>
                            <TransportForm
                                {...{
                                    formData,
                                    setFormData,
                                    formik
                                }}
                            />
                        </div>
                    </section>

                    <section>
                        <CarriageFormSection<MainCarriage>
                            {...{
                                title: 'Main Carriage',
                                addButtonText: '+ Add Carriage',
                                section: 'main',
                                hasDefault: true,
                                formData: {
                                    transport: formData.transport
                                },
                                setFormData,
                                formik,
                                MetaComponent: MainCarriageForm
                            }}
                        />
                    </section>

                    <section>
                        <CarriageFormSection<MetaCarriage>
                            {...{
                                title: 'Pre Carriage (Optional)',
                                addButtonText: '+ Add Pre Carriage',
                                section: 'preCarriage',
                                formData: {
                                    transport: formData.transport
                                },
                                setFormData,
                                formik,
                                MetaComponent: MetaCarriageForm
                            }}
                        />
                    </section>

                    <section>
                        <CarriageFormSection<MetaCarriage>
                            {...{
                                title: 'On Carriage (Optional',
                                addButtonText: '+ Add On Carriage',
                                section: 'onCarriage',
                                formData: {
                                    transport: formData.transport
                                },
                                setFormData,
                                formik,
                                MetaComponent: MetaCarriageForm
                            }}
                        />
                    </section>

                    <section>
                        <ContainerFormSection
                            {...{
                                formData,
                                setFormData,
                                formik
                            }}
                        />
                    </section>

                    <section className='mt-10'>
                        <SuiteUIAlert
                            severity='info'
                            message='NOTE: The sum of all Cargo Weights reflects the Gross Weight of the Cargo (excluding Tare)
                            for the entire Booking. Inaccurate declaration of cargo weight may endanger crew, port
                            worker, and vessel safety. Please verify the reasonable accuracy of this information
                            prior to submission.'
                        />
                    </section>

                    <section>
                        <CargoFormSection
                            {...{
                                formData,
                                setFormData,
                                setShowAddNewCommodity,
                                formik
                            }}
                        />
                    </section>

                    <section>
                        <PaymentFormSection
                            {...{
                                formData,
                                setFormData
                            }}
                        />
                    </section>

                    <section>
                        <CommentFormSection
                            {...{
                                formData,
                                setFormData
                            }}
                        />
                    </section>

                    <section className='flex justify-end mt-[20px] pt-[20px] border-t border-appcolorgreyfaint'>
                        <Button
                            variant='contained'
                            disabled={!user?.booking || !formik.isValid}
                            sx={{
                                width: '200px',
                                padding: '15px 16px'
                            }}
                            onClick={onHandleSubmit}
                        >
                            Submit
                        </Button>
                    </section>
                </div>
            </div>
        </>
    );
};
