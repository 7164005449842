import { Button } from 'antd';
import CustomModal from '../../../../components/CustomModal';
import { InviteIcon } from '../../../../components/icons/InviteIcon';
import { UpdateContainer } from '../../../../components/dashboard/sales/UpdateStatus';

const DisableUserModal = ({
    userEmail,
    open,
    onClose,
    loading,
    handleDisable
}: {
    userEmail: string;
    open: boolean;
    onClose: () => void;
    loading: boolean;
    handleDisable: () => void;
}) => {
    return (
        <CustomModal
            maxWidth='md'
            open={open}
            onClose={onClose}
            title=''
            hideCloseIcon
        >
            <UpdateContainer className=' mx-auto py-4 max-w-[400px] w-full'>
                <InviteIcon />
                <h2 className='pt-6 mb-2 text-[18px] text-ebony'>
                    Disable Team Member
                </h2>
                <h5 className=' leading-7 text-[18px] text-paleSky'>
                    By doing this,{' '}
                    <span className='font-bold'>{userEmail}</span> will no
                    longer have a role in your organization. Do you want to
                    continue?
                </h5>

                <div className='cta-buttons'>
                    <Button className='btn-1' onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        className='btn-3'
                        loading={loading}
                        onClick={handleDisable}
                    >
                        Disable
                    </Button>
                </div>
            </UpdateContainer>
        </CustomModal>
    );
};

export default DisableUserModal;
