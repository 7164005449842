import { Button, Select, Space } from 'antd';
import styled from 'styled-components';

interface IProps {
    updateOptions?: any;
    updateFunction?: any;
    loading?: boolean;
    error?: any;
    setSelectedStatus?: any;
    selectedStatus?: any;
}

const UpdateStatus = ({
    updateOptions,
    updateFunction,
    loading,
    selectedStatus,
    setSelectedStatus,
    error
}: IProps) => {
    const { Option } = Select;

    const handleChange = (value: string | string[]) => {
        setSelectedStatus(value);
    };
    return (
        <UpdateContainer>
            <div>{tag}</div>
            <h3>Change Status</h3>
            <p>You are about to manually change a status</p>
            <div className='relative z-[4000]'>
                <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Search to Select'
                    optionFilterProp='children'
                    size='large'
                    onChange={handleChange}
                >
                    {updateOptions?.map((opt: any, ind: any) => (
                        <Option value={opt.value} label={opt.label} key={ind}>
                            <Space style={{ padding: '0px 10px 5px 5px' }}>
                                <div
                                    style={{
                                        background: opt.color,
                                        width: '10px',
                                        height: '10px',
                                        borderRadius: '50%'
                                    }}
                                />
                                {opt.label}
                            </Space>
                        </Option>
                    ))}
                </Select>

                <div className='cta-buttons'>
                    <Button className='btn-1'>Cancel</Button>
                    <Button
                        className='btn-2'
                        loading={loading}
                        onClick={() => updateFunction(selectedStatus)}
                    >
                        Change Status
                    </Button>
                </div>
            </div>
        </UpdateContainer>
    );
};

export default UpdateStatus;

export const UpdateContainer = styled.div<{ maxWidth?: string }>`
    width: 100%;
    max-width: ${(props) => props.maxWidth || '400px'};

    h3 {
        color: #101828;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-top: 20px;
    }
    .ant-input-number-group-addon {
        background-color: unset;
    }
    .ant-input-number:hover {
        border-color: #d9d9d9;
    }
    .ant-input-number:not(:first-child) {
        border-left: 0;
    }
    .ant-input-number-wrapper {
        input {
            border-left: 0;
            height: 40px;
            :focus {
                border-left-width: 1px;
                box-shadow: none;
                border-color: #d9d9d9;
            }
        }
    }
    .left-addon {
        color: #667085;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
    }
    .form-label {
        color: #344054;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 10px;
    }
    .tag-line {
        border-radius: 16px;
        background: #f0f9ff;
        display: flex;
        padding: 2px 8px 2px 10px;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 0;
        margin-top: 0;
        color: #026aa2;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        align-self: stretch;
    }
    p {
        margin-bottom: 20px;
        color: #667085;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin-top: 5px;
        line-height: 20px; /* 142.857% */
    }
    .cta-buttons {
        margin-top: 32px;
        width: 100%;
        gap: 12px;
        display: flex;
        button {
            flex: 1;
            border-radius: 8px;
            height: 48px;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
        .btn-1 {
            border: 1px solid #d0d5dd !important;
            color: #344054 !important;
            background-color: white !important;
        }
        .btn-3 {
            border: 1px solid #b42318 !important;
            color: #ffffff !important;
            background-color: #b42318 !important;
        }
    }

    .lbl {
        color: #344054;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 10px;
    }
    @media (max-width: 1024px) {
        margin: auto;
        max-width: 90%;
        padding: 2rem 0 2rem 0;
    }
`;

const tag = (
    <svg
        width='56'
        height='56'
        viewBox='0 0 56 56'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect
            x='4'
            y='4'
            width='48'
            height='48'
            rx='24'
            fill='#0088B2'
            fillOpacity='0.1'
        />
        <path
            d='M23.5 23.5H23.51M37.09 29.91L29.92 37.08C29.7343 37.266 29.5137 37.4135 29.2709 37.5141C29.0281 37.6148 28.7678 37.6666 28.505 37.6666C28.2422 37.6666 27.9819 37.6148 27.7391 37.5141C27.4963 37.4135 27.2757 37.266 27.09 37.08L18.5 28.5V18.5H28.5L37.09 27.09C37.4625 27.4647 37.6716 27.9716 37.6716 28.5C37.6716 29.0284 37.4625 29.5353 37.09 29.91Z'
            stroke='#0088B2'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <rect
            x='4'
            y='4'
            width='48'
            height='48'
            rx='24'
            stroke='#0088B2'
            strokeOpacity='0.05'
            strokeWidth='8'
        />
    </svg>
);
