import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
// import { parkinglistfieldProps } from './parkinglistfield-interface'

export interface parkinglistfieldProps {
    id: string;
    updateQuoteField: (index: number, name: string, value: any) => void;
    qty: number;
    kindOfPackage?: string;
    qtyOfPackage?: number;
    weightOfPackageKG?: string;
    grossWeightOfPackageKG?: string;
    measurementOfPackage?: string;
    index: number;
    removeQuoteField: (index: number) => void;
    item?: any;
}

const Parkinglistfield = ({
    id,
    updateQuoteField,
    qty,
    kindOfPackage,
    qtyOfPackage,
    weightOfPackageKG,
    grossWeightOfPackageKG,
    measurementOfPackage,
    index,
    removeQuoteField,
    item
}: parkinglistfieldProps) => {
    return (
        <div className='relative custom-quote-field'>
            <Grid container>
                <Grid item xs={12} md={12} lg={6}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-4 h-full'>
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid item xs={12} md={12} lg={4}>
                                <TextField
                                    margin='normal'
                                    fullWidth
                                    id={`quote-productCode-${id}`}
                                    label='Product Code'
                                    name='productCode'
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'productCode',
                                            e.target.value
                                        );
                                    }}
                                    value={item?.productCode}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={5}>
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    id={`quote-desc-${id}`}
                                    label='Description of Goods'
                                    name='desc'
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'desc',
                                            e.target.value
                                        );
                                    }}
                                    value={item.desc}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={3}>
                                <TextField
                                    margin='normal'
                                    value={item.qty}
                                    fullWidth
                                    id={`quote-qty-${id}`}
                                    label='Unit Qty'
                                    name='qty'
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'qty',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-4'>
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    margin='normal'
                                    value={kindOfPackage}
                                    fullWidth
                                    id={`quote-kindOfPackage-${id}`}
                                    label='Package Kind'
                                    name='kindOfPackage'
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'kindOfPackage',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    margin='normal'
                                    fullWidth
                                    id={`quote-qtyOfPackage-${id}`}
                                    label='Package Qty'
                                    name='qtyOfPackage'
                                    value={qtyOfPackage}
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'qtyOfPackage',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    margin='normal'
                                    fullWidth
                                    id={`quote-weightOfPackageKG-${id}`}
                                    label='Net Weight of Package (Kg)'
                                    name='weightOfPackageKG'
                                    value={weightOfPackageKG}
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'weightOfPackageKG',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    margin='normal'
                                    fullWidth
                                    id={`quote-grossWeightOfPackageKG-${id}`}
                                    label='Gross Weight of Package (Kg)'
                                    name='grossWeightOfPackageKG'
                                    value={grossWeightOfPackageKG}
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'grossWeightOfPackageKG',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    margin='normal'
                                    fullWidth
                                    id={`quote-measurementOfPackage-${id}`}
                                    label='Measurements of Package (m3)'
                                    name='measurementOfPackage'
                                    value={measurementOfPackage}
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'measurementOfPackage',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {index !== 0 && (
                <div
                    className='absolute top-[1.5rem] cursor-pointer right-[0.8rem] custom-quote-field-delete w-fit transition-all delay-75'
                    onClick={() => {
                        removeQuoteField(index);
                    }}
                >
                    <DeleteIcon />
                </div>
            )}
        </div>
    );
};

export default Parkinglistfield;
