import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomTextField from './CustomTextInput';

interface CustomDatepickerProps {
    onHandleChange: (value: string | number | null | undefined) => void;
    label: string;
    error?: boolean;
    helperText?: string;
    value?: string | number;
    required?: boolean;
}

export const CustomDatepicker = ({
    onHandleChange,
    label,
    error,
    helperText,
    value,
    required
}: CustomDatepickerProps) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                className='w-full'
                label={label}
                value={value || null}
                onChange={onHandleChange}
                inputFormat='MMM DD, YYYY'
                renderInput={(params) => (
                    <CustomTextField
                        required={required}
                        error={error}
                        params={params}
                        label={label}
                        helperText={helperText}
                    />
                )}
            />
        </LocalizationProvider>
    );
};
