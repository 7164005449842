
import { useGetQuery } from './useGetQuery';
import { BookingMetrics } from '../pages/dashboard/bookings/interfaces/Booking';

export const useBookingMetrics = (corporateId?: number) => {
    const {
        isLoading,
        entries: bookingMetrics,
        isFetching,
        refetch,
    } = useGetQuery<BookingMetrics>({
        url: `/booking/corporate/${corporateId}/metrics`,
        cacheKey: `bookingMetrics`,
    });

    return {
        isLoading,
        bookingMetrics,
        refetch,
        isFetching
    };
};
