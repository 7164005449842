import React, { useState } from 'react';
import { SalesContainer } from '../../../../styles/SalesStyles';
import SalesHeader from '../../../../components/dashboard/sales/SalesHeader';
import ContractTable from '../../../../components/dashboard/sales/contracts/ContractTable';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { Backdrop } from '@mui/material';
import ContractSummary from '../../../../components/dashboard/sales/contracts/ContractSummary';
import EnablingFeature from '../../../../components/EnablingFeature';
import { useSalesContracts } from '../../../../customhooks/useContracts';
import {
    SalesContractParams,
    SalesContracts
} from '../../payments/interfaces/SalesContracts';
import { LoadingCircles } from '../../../../components/icons/LoadingCircles';
import CreateContractMobile from '../../../../components/dashboard/sales/contracts/CreateContractMobile';

const Contracts = () => {
    const [currentData, setCurrentData] = useState<SalesContracts>(
        {} as SalesContracts
    );
    const [viewExport, setViewExport] = useState<boolean>(false);
    const [filterOptions, setFilterOptions] = useState<SalesContractParams>(
        {} as SalesContractParams
    );

    const { user } = useSelector((state: RootState) => state.user);

    const {
        entries = [],
        invalidateContracts: refetchContracts,
        isFetching,
        isLoading,
        paging
    } = useSalesContracts<SalesContracts>({
        corporateId: user?.corporate_id,
        params: { size: 10, ...filterOptions }
    });

    return (
        <EnablingFeature flag='Sales'>
            <SalesContainer>
                <Backdrop
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.drawer + 1
                    }}
                    open={isFetching || isLoading}
                >
                    <div className='animate-spin'>
                        <LoadingCircles />
                    </div>
                </Backdrop>
                <div className='px-4 lg:px-0'>
                    <SalesHeader
                        title='Contracts'
                        fetchContracts={refetchContracts}
                        {...{
                            setViewExport,
                            viewExport,
                            setFilterOptions,
                            filterOptions
                        }}
                    />
                    <ContractSummary {...{ filterOptions }} />
                </div>
                <div className='px-[16px] lg:hidden flex justify-between gap-[8px] pt-4'>
                    <CreateContractMobile />
                </div>
                <ContractTable
                    {...{
                        currentData,
                        setCurrentData,
                        paging,
                        setViewExport
                    }}
                    filterParams={filterOptions}
                    setFilterParams={setFilterOptions}
                    loadingData={isFetching || isLoading}
                    data={entries}
                    fetchContracts={refetchContracts}
                />
            </SalesContainer>
        </EnablingFeature>
    );
};

export default Contracts;
