export const download = (
    <svg
        width='21'
        height='22'
        viewBox='0 0 21 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M18.1972 13.7397V17.15C18.1972 17.6022 18.0175 18.0359 17.6977 18.3557C17.378 18.6754 16.9443 18.8551 16.492 18.8551H4.55606C4.10383 18.8551 3.67012 18.6754 3.35034 18.3557C3.03057 18.0359 2.85092 17.6022 2.85092 17.15V13.7397M6.2612 9.47683L10.524 13.7397M10.524 13.7397L14.7869 9.47683M10.524 13.7397V3.50885'
            stroke='black'
            strokeWidth='1.86015'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export const add = (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M11 7.33333V14.6667M7.33333 11H14.6667M4.58333 2.75H17.4167C18.4292 2.75 19.25 3.57081 19.25 4.58333V17.4167C19.25 18.4292 18.4292 19.25 17.4167 19.25H4.58333C3.57081 19.25 2.75 18.4292 2.75 17.4167V4.58333C2.75 3.57081 3.57081 2.75 4.58333 2.75Z'
            stroke='black'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export const fileimg = (
    <svg
        width='29'
        height='29'
        viewBox='0 0 29 29'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect
            x='0.333344'
            y='0.902252'
            width='28'
            height='28'
            rx='10.1818'
            fill='#0088B2'
            fill-opacity='0.05'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M17.4215 12.1554C17.4215 13.8686 16.0478 15.2424 14.3334 15.2424C12.6195 15.2424 11.2452 13.8686 11.2452 12.1554C11.2452 10.4421 12.6195 9.06892 14.3334 9.06892C16.0478 9.06892 17.4215 10.4421 17.4215 12.1554ZM14.3334 20.7356C11.8031 20.7356 9.66669 20.3243 9.66669 18.7376C9.66669 17.1504 11.8165 16.7537 14.3334 16.7537C16.8642 16.7537 19 17.165 19 18.7516C19 20.3389 16.8502 20.7356 14.3334 20.7356Z'
            fill='#0088B2'
        />
    </svg>
);

export const cloudDownload = (
    <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clipPath='url(#clip0_6722_3398)'>
            <path
                d='M6.66649 14.1666L9.99982 17.4999M9.99982 17.4999L13.3332 14.1666M9.99982 17.4999V9.99994M17.3998 15.0749C18.1243 14.5655 18.6676 13.8384 18.9509 12.9992C19.2342 12.1601 19.2427 11.2525 18.9752 10.4081C18.7076 9.56381 18.178 8.82669 17.4632 8.30375C16.7484 7.78081 15.8855 7.49925 14.9998 7.49994H13.9498C13.6992 6.52317 13.2302 5.61598 12.5783 4.84668C11.9263 4.07737 11.1083 3.46599 10.1858 3.05857C9.26338 2.65115 8.26053 2.4583 7.25276 2.49454C6.245 2.53078 5.25858 2.79517 4.36778 3.2678C3.47698 3.74043 2.70501 4.40898 2.10998 5.22314C1.51495 6.03729 1.11237 6.97582 0.93254 7.96807C0.752714 8.96032 0.800331 9.98044 1.07181 10.9516C1.34328 11.9228 1.83154 12.8198 2.49982 13.5749'
                stroke='#344054'
                strokeWidth='1.67'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </g>
        <defs>
            <clipPath id='clip0_6722_3398'>
                <rect width='20' height='20' fill='white' />
            </clipPath>
        </defs>
    </svg>
);

export const gridView = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
    >
        <path
            d='M5.83321 1.75024H1.75V5.83345H5.83321V1.75024Z'
            stroke='#101828'
            strokeWidth='1.39996'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M12.2497 1.75024H8.16647V5.83345H12.2497V1.75024Z'
            stroke='#101828'
            strokeWidth='1.39996'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M12.2497 8.16671H8.16647V12.2499H12.2497V8.16671Z'
            stroke='#101828'
            strokeWidth='1.39996'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M5.83321 8.16671H1.75V12.2499H5.83321V8.16671Z'
            stroke='#101828'
            strokeWidth='1.39996'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export const listView = (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
    >
        <path
            d='M4.66667 3.5H12.25M4.66667 7H12.25M4.66667 10.5H12.25M1.75 3.5H1.75583M1.75 7H1.75583M1.75 10.5H1.75583'
            stroke='#101828'
            strokeWidth='1.16667'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
