import { Grid, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import Customdatepicker from '../../../../customdatepicker/customdatepicker';
import { CommercialFormBasicInterface } from '../../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/CommercialTypes';

const DepatureDetails = ({
    payload,
    updateField,
    errors
}: CommercialFormBasicInterface) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: '0px'
                        }}
                    >
                        <Grid item xs={12} md={12} lg={12}>
                            <div className='pt-4'>
                                <Customdatepicker
                                    value={ payload?.dateOfDeparture || null }
                                    id='commercialinvoice-dateOfDeparture'
                                    label='Date of Departure'
                                    name='dateOfDeparture'
                                    updateField={updateField}
                                    error={errors?.dateOfDeparture.length > 0}
                                    helperText={
                                        errors?.dateOfDeparture.length > 0
                                            ? errors?.dateOfDeparture
                                            : undefined
                                    }
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: '0px'
                        }}
                    >
                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                error={errors?.marineCoverPolicyNo.length > 0}
                                helperText={
                                    errors?.marineCoverPolicyNo.length > 0
                                        ? errors?.marineCoverPolicyNo
                                        : undefined
                                }
                                margin='normal'
                                fullWidth
                                id='commercialinvoice-marineCoverPolicyNo'
                                label='Marine Cover Policy No (Optional)'
                                name='marineCoverPolicyNo'
                                value={payload?.marineCoverPolicyNo || ''}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'marineCoverPolicyNo',
                                        e.target.value
                                    );
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                error={errors?.letterOfCreditNo.length > 0}
                                helperText={
                                    errors?.letterOfCreditNo.length > 0
                                        ? errors?.letterOfCreditNo
                                        : undefined
                                }
                                margin='normal'
                                fullWidth
                                id='commercialinvoice-letterOfCreditNo'
                                label='Letter Of Credit No (Optional)'
                                name='letterOfCreditNo'
                                value={payload?.letterOfCreditNo || ''}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField(
                                        'letterOfCreditNo',
                                        e.target.value
                                    );
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export default DepatureDetails;
