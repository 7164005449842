import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { openNotification } from '../../../../utils/helper';
import { approveBooking, getBooking } from '../../../../api/booking/booking';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import {
    BookingContainer,
    DetailsContainer
} from '../../../../components/dashboard/upsBooking/Styles';
import BookingHeader from '../../../../components/dashboard/upsBooking/BookingHeader';
import { Alert, Backdrop, Button, Stack, TextField } from '@mui/material';
import DetailsGeneral from '../../../../components/dashboard/upsBooking/BookingDetails/DetailsGeneral';
import PartiesGeneral from '../../../../components/dashboard/upsBooking/BookingDetails/PartiesGeneral';
import DetailsReference from '../../../../components/dashboard/upsBooking/BookingDetails/DetailsReference';
import DetailsContainerCargo from '../../../../components/dashboard/upsBooking/BookingDetails/DetailsContainerCargo';
import DetailsFooter from '../../../../components/dashboard/upsBooking/BookingDetails/DetailsFooter';
import CustomModal from '../../../../components/CustomModal';
import ApprovalModalContent from '../../../../components/dashboard/upsBooking/ApprovalModalContent';

const BookingDetails = () => {
    const { reference } = useParams();

    const breadcrumbs = [
        {
            title: 'Home',
            link: '/',
            active: false
        },
        {
            title: 'Bookings',
            link: '/bookings/overview',
            active: false
        },
        {
            title: 'Booking details',
            link: `/bookings/${reference}/details`,
            active: true
        }
    ];

    const state = useSelector((state: RootState) => ({
        user: state.user.user
    }));

    const { user } = state;

    const getVesselName = (
        vesselId: number,
        vessels: {
            name: string;
            id: number;
        }[]
    ) => {
        if (vesselId === undefined || vessels.length === 0) {
            return '-';
        } else {
            const filteredVessel = vessels.filter(
                (item) => item.id === vesselId
            );
            if (filteredVessel.length > 0) {
                return filteredVessel[0].name;
            } else {
                return '-';
            }
        }
    };
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>(null);
    const [loadingData, setLoadingData] = useState(false);
    const [bookingNumber, setBookingNumber] = useState<any>();
    const [error, setError] = useState<string | any>(null);
    const [errors, setErrors] = useState<string | any>(null);
    const [showApproveModal, setShowApproveModal] = useState(false);

    const fetchBooking = async () => {
        if (!user) {
            return;
        }

        setLoadingData(true);
        try {
            const res = await getBooking({
                bookingId: reference || '0',
                corporateId: user.corporate_id
            });

            const { data } = res.data;
            setData(data);
            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            if (error?.response) {
                openNotification('error', `Booking, Something went wrong`);
            } else {
                openNotification('error', `Booking, Something went wrong`);
            }
        }
    };

    const approveFunction = async () => {
        setError(undefined);
        if (
            bookingNumber === '' ||
            bookingNumber === undefined ||
            bookingNumber === null
        ) {
            setError('Enter Booking Number');
        } else {
            setLoading(true);
            try {
                const res = await approveBooking(Number(reference), {
                    bookingNumber: bookingNumber
                });
                setLoading(false);
                setShowApproveModal(false);
                openNotification(
                    'success',
                    'Booking was approved successfully'
                );
                navigate('/bookings/overview');
            } catch (error) {
                setLoading(false);
                openNotification(
                    'error',
                    'Booking approval, Something went wrong'
                );
                const otherErrors: any[] = [];

                if (error?.response) {
                    if (error?.response?.data?.error) {
                        const resError = error?.response?.data?.error;

                        if (typeof resError === 'string') {
                            const errorList = (
                                <li key={`error-list-${1}`}>{resError}</li>
                            );
                            setError(errorList);
                        } else {
                            Object.entries(resError).forEach(([key, value]) => {
                                if (Object.keys(errors).includes(key)) {
                                    setErrors((prevState: any) => ({
                                        ...prevState,
                                        [key]: value
                                    }));
                                } else {
                                    otherErrors.push(value);
                                }
                            });

                            if (otherErrors.length > 0) {
                                const errorList = otherErrors.map(
                                    (element: any, index) => {
                                        return (
                                            <li key={`error-list-${index}`}>
                                                {element}
                                            </li>
                                        );
                                    }
                                );
                                setError(errorList);
                            }
                        }
                    } else {
                        setError(
                            error?.response?.data?.message ||
                                'Something went wrong'
                        );
                    }
                } else {
                    setError('Something went wrong');
                }
            }
        }
    };

    useEffect(() => {
        fetchBooking();
    }, []);

    console.log(data);

    return (
        <BookingContainer>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loadingData}
            >
                <img
                    src='/img/vectors/loadingcircles.svg'
                    alt='loading'
                    className='animate-spin'
                />
            </Backdrop>
            <Stack
                direction='row'
                alignItems='center'
                width='100%'
                justifyContent='space-between'
            >
                <BookingHeader {...{ breadcrumbs }} noHead />
                <Stack
                    direction='row'
                    alignItems='center'
                    className='buttons'
                    mb='1rem'
                >
                    <Button
                        className='cancel'
                        onClick={() => navigate(`/bookings/overview`)}
                    >
                        Back
                    </Button>
                    <Button onClick={() => setShowApproveModal(true)}>
                        Approve
                    </Button>
                </Stack>
            </Stack>
            {!loadingData && data !== null && (
                <DetailsContainer>
                    <Stack
                        direction='row'
                        width='100%'
                        justifyContent='space-between'
                        gap='140px'
                    >
                        <DetailsGeneral {...{ data }} />
                        <PartiesGeneral {...{ data }} />
                    </Stack>

                    <DetailsReference {...{ data }} />

                    <DetailsContainerCargo {...{ data }} />

                    <DetailsFooter {...{ data }} />
                </DetailsContainer>
            )}
            {showApproveModal && (
                <CustomModal
                    maxWidth='md'
                    open={showApproveModal}
                    onClose={() => setShowApproveModal(false)}
                    title=''
                >
                    <ApprovalModalContent
                        type='Booking'
                        approveFunction={approveFunction}
                        cancelFunction={() => {
                            setBookingNumber('');
                            setShowApproveModal(false);
                        }}
                        loading={loading}
                    >
                        {error && (
                            <div className='mt-4'>
                                <Alert severity='error'>{error}</Alert>
                            </div>
                        )}
                        <TextField
                            value={bookingNumber}
                            margin='normal'
                            required
                            fullWidth
                            id='bookingNumber'
                            label='Booking/BL Number'
                            name='bookingNumber'
                            onChange={(e: any) => {
                                setBookingNumber(e.target.value);
                            }}
                        />
                    </ApprovalModalContent>
                </CustomModal>
            )}
        </BookingContainer>
    );
};

export default BookingDetails;
