import React, { FC } from 'react';
import { PreviewContent } from '../../../../styles/TradeDocStyles';
import { Stack } from '@mui/material';
import moment from 'moment';
import { Table } from 'antd';
import { REACT_APP_BASE_URL } from '../../../../config';
import DocumentViewer from '../../../DocumentViewer/DocumentViewer';

interface IProps {
    data: any;
    docTitle: string;
    quoteFields: any;
    signatureUrlBase64Exporter?: any;
}

const SalesContractPreview: FC<IProps> = ({
    data,
    docTitle,
    quoteFields,
    signatureUrlBase64Exporter
}: IProps) => {
    return (
        <PreviewContent>
            {' '}
            <div className='text-[14px]'>
                <div className='flex items-center justify-between pb-4 mb-4'>
                    <div>
                        <img
                            src='/img/vectors/powered.svg'
                            alt=''
                            className='h-[2.5rem]'
                        />
                    </div>

                    <div>
                        <h1 className='text-appcolorprimary text-[26px] leading-[27px] font-bold capitalize'>
                            {docTitle}
                        </h1>
                    </div>
                </div>
            </div>
            <Stack
                direction='row'
                alignItems='start'
                className='top-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='font-semibold text-appcolorprimary'>Buyer</p>
                    <p className='font-medium'>{data?.customerName}</p>
                    <p>{data?.buyerAddress}</p>
                </div>

                <div className='view-two'>
                    <p className='font-semibold text-appcolorprimary'>
                        Exporter
                    </p>
                    <p className='font-medium'>{data?.exporterName}</p>
                    <p>{data?.exporterAddress}</p>
                </div>

                <div className='view-three'>
                    <div></div>
                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Contract No.</p>
                        <p className='value'>{data?.contractNumber || '--'}</p>
                    </Stack>
                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Contract Type.</p>
                        <p className='value'>{data?.contractType || '--'}</p>
                    </Stack>
                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Payment Terms</p>
                        <p className='value'>{data?.paymentTerms || '--'}</p>
                    </Stack>
                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Shipping Deadline</p>
                        <p className='value'>
                            {moment(data.shippingDeadline).format('ll') || '--'}
                        </p>
                    </Stack>
                </div>
            </Stack>
            <Stack
                direction='row'
                alignItems='start'
                className='mid-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='title'>Container Type</p>
                    <p className='value'>{data?.containerType || '--'}</p>

                    <p className='title'>Port of Dispatch</p>
                    <p className='value'>
                        {data?.portOfDischargeValue?.toDisplay}
                    </p>
                </div>

                <div className='view-one'>
                    <p className='title'>Port of Loading</p>
                    <p className='value'>
                        {data?.portOfLoadingValue?.toDisplay || '--'}
                    </p>

                    <p className='title'>Shipping Lines</p>
                    <p className='value'>{data?.shippingLineValue || '--'}</p>
                </div>
            </Stack>
            <div className='table-view'>
                <Table
                    rowKey={'id'}
                    columns={[
                        {
                            title: 'Commodity',
                            dataIndex: 'commodity',
                            key: 'commodity'
                        },
                        {
                            title: 'Commodity Type',
                            dataIndex: 'commodityType',
                            key: 'commodityType'
                        },
                        {
                            title: 'Quality',
                            dataIndex: 'quality',
                            key: 'quality'
                        },

                        {
                            title: 'Quantity',
                            dataIndex: 'quantity',
                            key: 'quantity'
                        },
                        {
                            title: 'Unit Metric',
                            dataIndex: 'metric',
                            key: 'metric'
                        },
                        {
                            title: 'Quantity Buffer',
                            dataIndex: 'quantityBuffer',
                            key: 'quantityBuffer',
                            render: (text) => <span>{text}%</span>
                        }
                    ]}
                    dataSource={quoteFields}
                    pagination={false}
                />
            </div>
            <Stack
                direction='row'
                alignItems='start'
                className='sales-info-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                {data?.additionalInfo && (
                    <div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data?.additionalInfo
                            }}
                        />
                    </div>
                )}
            </Stack>
            <Stack
                direction='row'
                alignItems='start'
                className='bottom-preview-content'
                width='full'
                justifyContent={'space-between'}
                style={{ position: 'relative', left: 0, right: 0 }}
            >
                <div className='view-one'>
                    {data?.stamp && (
                        <Stack direction='row' justifyContent='flex-end'>
                            <div className='totalView w-[250px]'>
                                <p className='titl'>Exporter Stamp</p>
                                <div className='h-[50px] w-[100px] img'>
                                    <DocumentViewer
                                        fileUrl={`${REACT_APP_BASE_URL}/${data?.stamp.split(
                                            '/v1/'
                                        )[1]}`}
                                    />
                                </div>
                            </div>
                        </Stack>
                    )}
                </div>

                <div className='view-two'>
                    <p className='titl'>Exporter Signature</p>
                    <img src={`${signatureUrlBase64Exporter}`} alt='' />
                </div>
            </Stack>
        </PreviewContent>
    );
};

export default SalesContractPreview;
