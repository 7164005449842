interface PageTitleProps {
    Icon: () => JSX.Element;
    title: string;
}

export const PageTitle = (props: PageTitleProps) => {
    const { Icon, title } = props;

    return (
        <div className='flex space-x-[22px]'>
            <Icon />
            <div className='text-[28px] font-bold tracking-[-.56px]'>
                {title}
            </div>
        </div>
    );
};
