import { v4 as uuidv4 } from 'uuid';
import { CarriageFormSectionProps } from './interfaces/CarriageFormSectionProps';
import { SuitUIButton } from '../../../../components/SuitUIButton';

export const CarriageFormSection = <T,>({
    formData,
    setFormData,
    section,
    title,
    hasDefault,
    addButtonText,
    formik,
    MetaComponent
}: CarriageFormSectionProps<T>) => {
    return (
        <>
            <div className='flex justify-between items-center pt-4 pb-[4px] border-b border-dashed lg:border-solid border-gray300 lg:border-appcolorgreyfaint lg:mt-8'>
                <p className='text-steelBlue'>{title}</p>

                <SuitUIButton
                    text={addButtonText}
                    variant='secondary'
                    onClick={() => {
                        setFormData((prev) => {
                            const update = {
                                ...prev,
                                transport: {
                                    ...prev.transport,
                                    [section]: [
                                        ...(prev.transport[section] || []),
                                        { uid: uuidv4() }
                                    ]
                                }
                            };

                            formik.setValues(update);

                            return update;
                        });
                    }}
                />
            </div>

            <div className='mt-1'>
                {formData.transport[section]?.map(
                    ({ uid, ...carriage }, index) => (
                        <MetaComponent
                            {...{
                                section,
                                carriage,
                                index,
                                setFormData,
                                isRemoveShown: hasDefault
                                    ? formData.transport[section].length > 1
                                    : true,
                                formik
                            }}
                            key={uid}
                        />
                    )
                )}
            </div>
        </>
    );
};
