import React from 'react';
import { Wallet } from './interfaces/Wallet';
import { CopyText } from '../../../components/CopyText';

export const GlobalBankAccount = ({
    selectedWallet
}: {
    selectedWallet: Wallet;
}) => {
    return (
        <>
            <h3 className='font-bold text-[14px] mb-[.5rem]'>
                {selectedWallet.currencyCode} Account (Global)
            </h3>
            <p className='flex items-center text-paleSky text-sm mb-[8px]'>
                Account Number:
                <span className='font-semibold'>
                    &nbsp; {selectedWallet?.globalAccountNumber}
                </span>
                &nbsp;
                <CopyText text={selectedWallet?.globalAccountNumber || ''} />
            </p>
            <p className='text-paleSky text-sm mb-[8px]'>
                Account Name:
                <span className='font-semibold'>
                    &nbsp; &nbsp; {selectedWallet.globalAccountName}
                </span>
            </p>
            <p className='text-paleSky text-sm mb-[8px]'>
                Swift Code:
                <span className='font-semibold'>
                    &nbsp; {selectedWallet.globalSwiftCode}
                </span>
            </p>
            <p className='text-paleSky text-sm mb-[8px]'>
                Bank Name:
                <span className='font-semibold'>
                    &nbsp; {selectedWallet.globalBankName}
                </span>
            </p>
            <p className='text-paleSky text-sm mb-8'>
                Bank Address:
                <span className='font-semibold'>
                    &nbsp; {selectedWallet.globalBankAddress}
                </span>
            </p>
        </>
    );
};
