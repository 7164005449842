export const PDFIcon = () => {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='46'
            height='46'
            viewBox='0 0 46 46'
            fill='none'
        >
            <path
                d='M38.9587 12.75H29.7503V3.54171C29.7503 3.35385 29.6757 3.17368 29.5429 3.04084C29.41 2.908 29.2299 2.83337 29.042 2.83337H9.20866C9.0208 2.83337 8.84063 2.908 8.70779 3.04084C8.57496 3.17368 8.50033 3.35385 8.50033 3.54171V23.375C8.50033 23.5629 8.57496 23.7431 8.70779 23.8759C8.84063 24.0087 9.0208 24.0834 9.20866 24.0834H28.3337V35.4167H9.20866C9.0208 35.4167 8.84063 35.4913 8.70779 35.6242C8.57496 35.757 8.50033 35.9372 8.50033 36.125V41.7917C8.50033 41.9796 8.57496 42.1597 8.70779 42.2926C8.84063 42.4254 9.0208 42.5 9.20866 42.5H38.9587C39.1465 42.5 39.3267 42.4254 39.4595 42.2926C39.5924 42.1597 39.667 41.9796 39.667 41.7917V13.4584C39.667 13.2705 39.5924 13.0903 39.4595 12.9575C39.3267 12.8247 39.1465 12.75 38.9587 12.75Z'
                fill='#EAECF0'
            />
            <path
                d='M29.042 22.6666H6.37533C5.98412 22.6666 5.66699 22.9838 5.66699 23.375V36.125C5.66699 36.5162 5.98412 36.8333 6.37533 36.8333H29.042C29.4332 36.8333 29.7503 36.5162 29.7503 36.125V23.375C29.7503 22.9838 29.4332 22.6666 29.042 22.6666Z'
                fill='#EF5350'
            />
            <path
                d='M39.4616 12.9554L29.5449 3.03876C29.4458 2.93886 29.3193 2.87074 29.1813 2.84306C29.0434 2.81539 28.9003 2.82942 28.7704 2.88337C28.6404 2.93732 28.5295 3.02875 28.4517 3.14599C28.3739 3.26324 28.3329 3.40098 28.3337 3.54168V13.4583C28.3337 13.6462 28.4083 13.8264 28.5411 13.9592C28.674 14.092 28.8541 14.1667 29.042 14.1667H38.9587C39.0994 14.1675 39.2371 14.1264 39.3544 14.0486C39.4716 13.9708 39.563 13.8599 39.617 13.73C39.6709 13.6 39.685 13.457 39.6573 13.319C39.6296 13.1811 39.5615 13.0545 39.4616 12.9554Z'
                fill='#D0D5DD'
            />
            <path
                d='M10.6253 32.5834V26.9167H12.5095C12.9932 26.8846 13.472 27.0307 13.8553 27.3276C14.0488 27.4958 14.2021 27.7054 14.3038 27.9408C14.4056 28.1761 14.4532 28.4313 14.4432 28.6876C14.439 29.0187 14.3434 29.3423 14.167 29.6226C14.0013 29.8988 13.7538 30.1167 13.4587 30.2459C13.2026 30.3599 12.9243 30.4155 12.6441 30.4088H11.7941V32.5834H10.6253ZM11.8224 29.3817H12.4882C12.698 29.3928 12.9038 29.3216 13.062 29.1834C13.132 29.1131 13.1865 29.0288 13.2219 28.9361C13.2573 28.8433 13.2728 28.7442 13.2674 28.6451C13.2674 28.2106 13.0077 27.9934 12.4882 27.9934H11.8224V29.3817ZM15.5482 32.5834V26.9167H17.6732C18.2059 26.8979 18.7279 27.0686 19.1466 27.3984C19.4933 27.6805 19.7684 28.0406 19.9494 28.4493C20.1304 28.858 20.2121 29.3037 20.1878 29.7501C20.1939 30.2697 20.0723 30.783 19.8337 31.2446C19.6103 31.6763 19.2656 32.0333 18.842 32.2717C18.3525 32.5067 17.8116 32.6139 17.2695 32.5834H15.5482ZM16.7453 31.5067H17.5103C17.7824 31.5167 18.0512 31.4451 18.2824 31.3013C18.5275 31.1173 18.7213 30.8735 18.8453 30.5933C18.9694 30.313 19.0194 30.0056 18.9907 29.7005C19.0149 29.4137 18.9658 29.1254 18.8481 28.8628C18.7304 28.6001 18.5479 28.3717 18.3178 28.1988C18.0897 28.0539 17.8228 27.9822 17.5528 27.9934H16.7453V31.5067ZM21.4203 32.5834V26.9167H24.6645V27.9934H22.6174V29.148H24.4945V30.2246H22.6174V32.5834H21.4203Z'
                fill='white'
            />
        </svg>
    );
};
