import React from 'react';
import { PlainFileIcon } from '../../../../components/icons/PlainFileIcon';
import { Divider, Stack } from '@mui/material';
import { EyesIcon } from '../../../../components/icons/EyeIcon';
import { DedicatedAccount } from './interfaces/DedicatedAccounts';

const RequestCorporateBreakdown = ({
    accountDetails
}: {
    accountDetails?: DedicatedAccount;
}) => {
    return (
        <div className='mt-[20px]'>
            <div className='flex gap-[5px] items-center py-[10px] px-[14px] leading-[22px] text-[15px] bg-white w-max'>
                <PlainFileIcon />
                Breakdown
            </div>
            <Stack className='p-[20px] gap-[10px] bg-white rounded-[5px] rounded-tl-[0]'>
                <div className='flex'>
                    <p className='flex gap-[5px] w-[300px] items-center text-[14px] text-[#475467]'>
                        <EyesIcon color='#475467' /> Business Name
                    </p>
                    <p className='text-[14px]'>
                        {accountDetails?.corporate?.businessName ?? '--'}
                    </p>
                </div>
                <Divider />

                <div className='flex'>
                    <p className='flex gap-[5px] w-[300px] items-center text-[14px] text-[#475467]'>
                        <EyesIcon color='#475467' /> CAC No.
                    </p>
                    <p className='text-[14px]'>
                        {accountDetails?.corporate?.rcNumber ?? '--'}
                    </p>
                </div>
                <Divider />

                <div className='flex'>
                    <p className='flex gap-[5px] w-[300px] items-center text-[14px] text-[#475467]'>
                        <EyesIcon color='#475467' /> Business Email
                    </p>
                    <p className='text-[14px]'>
                        {accountDetails?.corporate?.businessEmail ?? '--'}
                    </p>
                </div>
                <Divider />

                <div className='flex'>
                    <p className='flex gap-[5px] w-[300px] items-center text-[14px] text-[#475467]'>
                        <EyesIcon color='#475467' /> Office Address
                    </p>
                    <p className='text-[14px]'>
                        {accountDetails?.corporate?.officeAddress ?? '--'}
                    </p>
                </div>
                <Divider />

                <div className='flex'>
                    <p className='flex gap-[5px] w-[300px] items-center text-[14px] text-[#475467]'>
                        <EyesIcon color='#475467' /> Currency
                    </p>
                    <p className='text-[14px]'>
                        {accountDetails?.corporate?.defaultCurrency ?? '--'}
                    </p>
                </div>
            </Stack>
        </div>
    );
};

export default RequestCorporateBreakdown;
