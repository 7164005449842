const OverdueInvoiceIcon = () => {
    return (
        <svg
            width='40'
            height='41'
            viewBox='0 0 40 41'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect y='0.0424805' width='40' height='40' rx='8' fill='#FF692E' />
            <path
                d='M20 15.7925V21.0425'
                stroke='#E44000'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M29.08 16.6225V23.4625C29.08 24.5825 28.4799 25.6225 27.5099 26.1925L21.5699 29.6225C20.5999 30.1825 19.3999 30.1825 18.4199 29.6225L12.4799 26.1925C11.5099 25.6325 10.9099 24.5925 10.9099 23.4625V16.6225C10.9099 15.5025 11.5099 14.4625 12.4799 13.8925L18.4199 10.4625C19.3899 9.90248 20.5899 9.90248 21.5699 10.4625L27.5099 13.8925C28.4799 14.4625 29.08 15.4925 29.08 16.6225Z'
                stroke='#E44000'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M20 24.2427V24.3427'
                stroke='#E44000'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default OverdueInvoiceIcon;
