import { CircularProgress, Grid, MenuItem, TextField } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { ShipmentDetailsProps } from '../../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/parkinglistTypes';
import { transports } from '../../../../../utils/appconstants';

const TransportDetails = ({
    errors,
    payload,
    countries,
    updateField,
    isCountriesLoading
}: ShipmentDetailsProps) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            marginTop: '0px'
                        }}
                    >
                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                select
                                error={errors?.transport.length > 0}
                                helperText={
                                    errors?.transport.length > 0
                                        ? errors?.transport
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                value={payload.transport}
                                id='commercialinvoice-transport'
                                label='Transport'
                                name='transport'
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField('transport', e.target.value);
                                }}
                            >
                                {transports.map((item, index) => (
                                    <MenuItem
                                        value={item}
                                        key={`transports-${item}-${index}`}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6}>
                            <TextField
                                error={errors?.voyageNumber.length > 0}
                                helperText={
                                    errors?.voyageNumber.length > 0
                                        ? errors?.voyageNumber
                                        : undefined
                                }
                                margin='normal'
                                required
                                fullWidth
                                id='proformainvoice-voyageNumber'
                                label='Voyage Number'
                                name='voyageNumber'
                                value={payload?.voyageNumber}
                                onChange={(
                                    e: ChangeEvent<HTMLInputElement>
                                ) => {
                                    updateField('voyageNumber', e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            </Grid>

            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                    <TextField
                        select
                        error={errors?.countryOfFinalDestinationId.length > 0}
                        helperText={
                            errors?.countryOfFinalDestinationId.length > 0
                                ? errors?.countryOfFinalDestinationId
                                : undefined
                        }
                        margin='normal'
                        required
                        fullWidth
                        id='commercialinvoice-countryOfFinalDestinationId'
                        label='Country of Final Destination'
                        name='countryOfFinalDestinationId'
                        value={payload.countryOfFinalDestinationId}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            updateField(
                                'countryOfFinalDestinationId',
                                e.target.value
                            );
                            updateField(
                                'countryOfFinalDestinationName',
                                countries.filter(
                                    (item: { id: string | number }) =>
                                        item?.id === e.target.value
                                )[0]?.name
                            );
                        }}
                    >
                        {isCountriesLoading ? (
                            <MenuItem>
                                <CircularProgress size={20} />
                            </MenuItem>
                        ) : (
                            countries.map((item) => (
                                <MenuItem
                                    value={item.id}
                                    key={`countries-${item.id}`}
                                >
                                    <div className='flex items-center'>
                                        <img
                                            src={item?.flagImageUrl}
                                            alt={`${item.name}-flag`}
                                            className='w-4 h-4'
                                        />
                                        <span className='ml-2'>
                                            {item.name}
                                        </span>
                                    </div>
                                </MenuItem>
                            ))
                        )}
                    </TextField>
                </div>
            </Grid>
        </Grid>
    );
};

export default TransportDetails;
