import { memo } from 'react';
import { currencyFlags } from '../utils/currencyFlags';
import { Box } from '@mui/material';

type CurrencyFlag = keyof typeof currencyFlags;

export const CountryFlagFromCode = memo(({ code }: { code: string }) => {
    if (!code) {
        return <></>;
    }
    return (
        <Box className='w-10 h-10'>
            <img
                className='w-10 h-10 rounded-full border border-b-50'
                src={`${
                    currencyFlags[code.toLocaleLowerCase() as CurrencyFlag]
                }`}
                alt={code}
            />
        </Box>
    );
});
