import { useEffect, useState } from 'react';
import { useGetQuery } from '../useGetQuery';

export interface BusinessType {
    id: number;
    name: string;
}

const useBusinessTypes = () => {
    const { isLoading: isBusinessTypesLoading, entries } = useGetQuery<
        BusinessType[]
    >({
        url: '/business/type',
        cacheKey: 'businessTypes'
    });
    const [businessTypes, setBusinessTypes] = useState<BusinessType[]>(
        [] as BusinessType[]
    );

    useEffect(() => {
        setBusinessTypes(entries || []);
    }, [entries]);

    return { businessTypes, isBusinessTypesLoading };
};

export default useBusinessTypes;
