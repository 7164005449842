import styled from 'styled-components'
import { scaleValue } from './TradeDocStyles'

export const StockContainer = styled.section`
  min-width: ${scaleValue(1140)}px;
  min-height: 500px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .page-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    h1 {
      font-size: 28px;
      font-weight: 500;
    }
  }
`
export const SummaryMain = styled.div`
  border-radius: 8px;
  background: #fff;
  display: flex;
  height: 120px;
  padding: 15px 30px 6px;
  margin-bottom: 6px;
  align-items: center;
  gap: 30px;
  align-self: stretch;
  width: 100%;
  margin-top: 1rem;
  .summary-details {
    /* max-width: 180px; */
    flex: 1;
    p {
      color: #667085;
      font-family: Outfit;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    h3 {
      color: #353542;
      font-family: Outfit;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
`

export const AddNewContainer = styled.div`
  display: flex;
  width: 450px;
  /* width: ${scaleValue(450)}px; */
  flex-direction: column;
  align-items: flex-start;
  .back-btn{
    display: flex;
    padding: 5px 10px;
    align-items: center;
    gap: 3px;
    span{
        color:  #333;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
  }

  .title{
    color: #353542;
    font-family: "Outfit";
    font-size: 24.26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 30px;
  }

`

export const StockFormContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  .submit-btn {
    width: 100%;
    button {
      border-radius: 8px;
      border: 1px solid #0088b2;
      background: #0088b2;
      width: 100%;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      height: 46px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }
  }
  .form-field {
    width: 100%;
    h2 {
      color: #344054;
      font-family: 'Outfit';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      margin-bottom: 0.5rem;
    }
    input {
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      background: #fff;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      padding: 8px 10px;
      font-size: 14px;
    }

    .MuiAutocomplete-inputRoot {
      padding: 0;
      padding-right: 0 !important;
    }
    .MuiAutocomplete-root {
      margin-top: 0;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }

    .MuiInputBase-input {
      padding: 18px 10px !important;
    }
    .ant-select-selector {
      padding: 14px 10px !important;
    }
    .ant-input-number-group-wrapper {
      input {
        min-height: 100%;
        height: 39px;
        border: none;
      }
      width: 100%;
      border: none;
      height: 39px;
    }
    .ant-input-number-group-addon {
      background-color: white;
      border: none;
    }
    .ant-input-number-wrapper {
      border: 1px solid #d0d5dd;
      border-radius: 8px;

      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  }
`
