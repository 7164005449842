import React, { ChangeEvent } from 'react';
import Newcustomselect from '../../../../newcustomselect/newcustomselect';
import { Grid, TextField } from '@mui/material';
import Customdatepicker from '../../../../customdatepicker/customdatepicker';
import { ExporterDetailsProps } from '../../../../../pages/dashboard/tradedocuments/tradeDocumentTypes/parkinglistTypes';

const ExporterDetails = ({
    errors,
    payload,
    corporatePartners,
    updateField,
    loadingCorporatepartners,
    setShowAddPartner
}: ExporterDetailsProps) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12} lg={6}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-4'>
                    <div className='mt-4'>
                        <Newcustomselect
                            hasError={errors?.exporterId.length > 0}
                            helperText={
                                errors?.exporterId.length > 0
                                    ? errors?.exporterId
                                    : undefined
                            }
                            selectValue={payload.exporterId}
                            required
                            selectOptions={
                                corporatePartners as unknown as Record<
                                    string,
                                    unknown
                                >[]
                            }
                            name={'exporterId'}
                            updateField={(_, value: string) => {
                                updateField('exporterId', value);
                                updateField(
                                    'exporterAddress',
                                    corporatePartners.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === value
                                    )[0]?.address
                                );
                                updateField(
                                    'exporterName',
                                    corporatePartners.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === value
                                    )[0]?.name
                                );
                                updateField(
                                    'exporterBankName',
                                    corporatePartners.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === value
                                    )[0]?.bankName
                                );
                                updateField(
                                    'exporterSwiftCode',
                                    corporatePartners.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === value
                                    )[0]?.swiftCode
                                );
                                updateField(
                                    'exporterCountry',
                                    corporatePartners.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === value
                                    )[0]?.bankCountry
                                );
                                updateField(
                                    'exporterAccountNumber',
                                    corporatePartners.filter(
                                        (item: { id: string | number }) =>
                                            item?.id === value
                                    )[0]?.bankAccountNumber
                                );
                            }}
                            label={'Exporter'}
                            selectOptionLabel='name'
                            loading={loadingCorporatepartners}
                            dropdownRender={() => {
                                return (
                                    <div className='px-4 border-t border-appcolorgreyfaint'>
                                        <p
                                            className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                            onClick={() => {
                                                setShowAddPartner(true);
                                            }}
                                        >
                                            + Add a New Partner
                                        </p>
                                    </div>
                                );
                            }}
                        />
                    </div>
                </div>
            </Grid>

            <Grid item xs={12} md={12} lg={3}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                    <TextField
                        error={errors?.exportInvoiceNumber.length > 0}
                        helperText={
                            errors?.exportInvoiceNumber.length > 0
                                ? errors?.exportInvoiceNumber
                                : undefined
                        }
                        margin='normal'
                        required
                        fullWidth
                        value={payload?.exportInvoiceNumber}
                        id='proformainvoice-exportInvoiceNumber'
                        label='Export Invoice Number'
                        name='exportInvoiceNumber'
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            updateField('exportInvoiceNumber', e.target.value);
                        }}
                    />
                </div>
            </Grid>

            <Grid item xs={12} md={12} lg={3}>
                <div className='border border-appcolorgreyfaint px-[1rem] pt-[1.1rem] pb-2 h-full'>
                    <Customdatepicker
                        value={ payload?.issuedDate || null }
                        id='quotation-issuedDate'
                        label='Invoice Date'
                        name='issuedDate'
                        updateField={updateField}
                        error={errors?.issuedDate.length > 0}
                        helperText={
                            errors?.issuedDate.length > 0
                                ? errors?.issuedDate
                                : undefined
                        }
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default ExporterDetails;
