import React from 'react';
import RequestDetailsBreakdown from './RequestDetailsBreakdown';
import { BreadcrumbCaretRight } from '../../../../components/icons/BreadcrumbCaretRight';
import { useNavigate, useParams } from 'react-router-dom';
import RequestCorporateBreakdown from './RequestCorporateBreakdown';
import RequestAccountDetails from './RequestAccountDetails';
import DedicatedAccountCredentials from './DedicatedAccountCredentials';
import { UseDedicatedAccounts } from '../../../../customhooks/UseDedicatedAccounts';
import { Backdrop } from '@mui/material';
import { LoadingCircles } from '../../../../components/icons/LoadingCircles';

const DedicatedAccountDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const {
        isLoading: isAccountsLoading,
        accountsList,
        isFetching
    } = UseDedicatedAccounts({
        corporateId: id
    });

    const accountDetails = accountsList.find(
        (account) => account.corporate.id == id
    );

    return (
        <div className='min-h-screen'>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={isAccountsLoading || isFetching}
            >
                <div className='animate-spin'>
                    <LoadingCircles />
                </div>
            </Backdrop>

            <div className='my-[1.3rem]'>
                <p
                    className='flex items-center gap-2 font-medium cursor-pointer w-fit'
                    onClick={() => {
                        navigate('/payments/dedicated-accounts');
                    }}
                >
                    <BreadcrumbCaretRight />
                    <span>Back </span>
                </p>
            </div>
            <RequestDetailsBreakdown accountDetails={accountDetails} />

            <RequestCorporateBreakdown accountDetails={accountDetails} />

            {accountDetails?.status !== 2 && (
                <RequestAccountDetails {...{ id }} />
            )}

            {accountDetails?.status !== 2 && (
                <DedicatedAccountCredentials {...{ id }} />
            )}
        </div>
    );
};

export default DedicatedAccountDetails;
