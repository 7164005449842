import React from 'react';
import { SalesDocument } from './interfaces/SalesDocuments';
import { InvoicePayment } from './interfaces/InvoicePayment';
import { formatCurrency, getInvoiceStatus } from '../../../utils/helper';
import PaidInvoiceIcon from '../../../components/icons/PaidInvoiceIcon';
import OverdueInvoiceIcon from '../../../components/icons/OverdueInvoiceIcon';
import NotPaidInvoiceIcon from '../../../components/icons/NotPaidInvoiceIcon';
import { Box, Skeleton, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { Divider } from 'antd';
import ViewTransactionDocument from './shared/ViewTransactionDocument';
import { REACT_APP_BASE_URL } from '../../../config';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';
import { Transactions } from './interfaces/Transactions';
import useWallets from '../../../customhooks/wallets/useWallets';
import { useParams } from 'react-router-dom';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';
import { useGetQuery } from '../../../customhooks/useGetQuery';
import { ViewPaymentReceipt } from './shared/ViewPaymentReceipt';

const LogisticsInvoiceDetailsMobileView = ({
    invoiceDetails,
    isDetailsLoading,
    paymentDetails,
    isPaymentLoading
}: {
    invoiceDetails: SalesDocument;
    isDetailsLoading: boolean;
    paymentDetails: InvoicePayment[];
    isPaymentLoading: boolean;
}) => {
    const { permissions } = useUserProfile();
    const { reference } = useParams();
    const { user } = useSelector((state: RootState) => state.user);
    const { wallets, isLoading: isWalletLoading } = useWallets();

    const { isLoading: isTransactionLoading, entries: transactionDetails } =
        useGetQuery<Transactions>({
            url: `/wallet/transaction/${paymentDetails[0]?.transactionReference}/corporate/${user?.corporate_id}`,
            cacheKey: `invoice-transaction-details-${reference}`,
            enabled: paymentDetails?.length > 0
        });

    const wallet = wallets.find(
        (wallet) => wallet.id === transactionDetails?.fromWalletId
    );

    const getStatusIcon = () => {
        const status = getInvoiceStatus(
            invoiceDetails?.documentData?.status
        ).toUpperCase();
        switch (status) {
            case 'PAID':
                return <PaidInvoiceIcon />;
            case 'OVERDUE':
                return <OverdueInvoiceIcon />;
            default:
                return <NotPaidInvoiceIcon />;
        }
    };

    const RenderPaymentDetails = () => {
        return (
            <Box>
                <h4 className=' text-[16px] font-bold border-b-[1px] border-appcolorfaintnine mb-2'>
                    Payment Details
                </h4>
                <Stack
                    width='100%'
                    direction='row'
                    className='flex-row flex justify-between pt-[24px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                >
                    <p className='text-appcolorblacktwo'>Rate </p>

                    <p className='font-medium'>
                        {formatCurrency(
                            1,
                            transactionDetails?.fromCurrencyCode
                        )}
                        &nbsp; ={' '}
                        {formatCurrency(
                            transactionDetails?.rateValue,
                            transactionDetails?.toCurrencyCode
                        )}
                    </p>
                </Stack>

                <Stack
                    width='100%'
                    direction='row'
                    className='flex-row flex justify-between pt-[24px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                >
                    <p className='text-appcolorblacktwo'>Carrier receives</p>

                    <p className='font-medium'>
                        {formatCurrency(
                            transactionDetails?.amountTo,
                            transactionDetails?.toCurrencyCode
                        )}{' '}
                    </p>
                </Stack>

                <Stack
                    width='100%'
                    direction='row'
                    className='flex-row flex justify-between pt-[24px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                >
                    <p className='text-appcolorblacktwo '>
                        Tranx Account / Wallet
                    </p>

                    <p className='font-medium text-right'>
                        {wallet?.walletName}
                    </p>
                </Stack>

                <Stack
                    width='100%'
                    direction='row'
                    className='flex-row flex justify-between pt-[24px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                >
                    <p className='text-appcolorblacktwo'> Amount from</p>

                    <p className='font-medium'>
                        {formatCurrency(
                            transactionDetails?.amountFrom,
                            transactionDetails?.fromCurrencyCode
                        )}
                    </p>
                </Stack>

                <Stack
                    width='100%'
                    direction='row'
                    className='flex-row flex justify-between pt-[24px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                >
                    <p className='text-appcolorblacktwo'> Amount to</p>

                    <p className='font-medium'>
                        {formatCurrency(
                            transactionDetails?.amountTo,
                            transactionDetails?.toCurrencyCode
                        )}
                    </p>
                </Stack>

                <Stack
                    width='100%'
                    direction='row'
                    className='flex-row flex justify-between pt-[24px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                >
                    <p className='text-appcolorblacktwo'> Tranx ID</p>

                    <p className='font-medium'>
                        {transactionDetails?.transactionReference}
                    </p>
                </Stack>
                <Divider className='mt-[2rem] mb-[1rem] mx-auto min-w-[20%] max-w-[20%] border-[#C5C5C5]' />
                <Stack
                    width='100%'
                    direction='row'
                    className='flex-row flex justify-between border-b-[1px] border-appcolorfaintnine text-[14px]'
                >
                    <p className='text-appcolorblacktwo'> Transaction date</p>

                    <p className='font-medium'>
                        {transactionDetails?.transactionEndDate
                            ? dayjs(
                                  transactionDetails?.transactionEndDate
                              ).format('DD MMM, YYYY')
                            : 'N/A'}
                    </p>
                </Stack>
                <Stack
                    width='100%'
                    direction='row'
                    className='flex-row flex justify-between pt-[24px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                >
                    <p className='text-appcolorblacktwo'> Status</p>

                    <p className='font-medium'>
                        {getInvoiceStatus(invoiceDetails?.documentData?.status)}
                    </p>
                </Stack>
                <Stack
                    width='100%'
                    className=' flex justify-between pt-[24px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                >
                    <p className='text-appcolorblacktwo'> Narration</p>

                    <p className='font-medium'>
                        {transactionDetails?.narration}
                    </p>
                </Stack>
                <Divider className='my-[1.5rem] mx-auto min-w-[20%] max-w-[20%] border-[#C5C5C5]' />

                {permissions &&
                    hasPermission({
                        userPermissions: permissions,
                        module: 'payments',
                        permissionList: ['write', '*', 'read']
                    }) && (
                        <div className=' w-[100%] flex flex-col '>
                            <h4 className='text-appcolorblacktwo text-[16px] font-semibold'>
                                Invoice
                            </h4>
                            <ViewTransactionDocument
                                fileUrl={
                                    REACT_APP_BASE_URL +
                                    invoiceDetails?.documentUrl?.replace(
                                        'v1/',
                                        ''
                                    )
                                }
                            />
                        </div>
                    )}

                {permissions &&
                    hasPermission({
                        userPermissions: permissions,
                        module: 'payments',
                        permissionList: ['write', '*', 'read']
                    }) && (
                        <div className=' w-[100%] flex flex-col mt-4'>
                            <h4 className='text-appcolorblacktwo text-[16px] font-semibold'>
                                Payment Receipt{' '}
                            </h4>
                            <ViewPaymentReceipt data={transactionDetails} />
                        </div>
                    )}
            </Box>
        );
    };

    return (
        <>
            {isWalletLoading ||
            isTransactionLoading ||
            isDetailsLoading ||
            isPaymentLoading ? (
                <Skeleton
                    variant='rectangular'
                    height={'35rem'}
                    className='mx-4'
                />
            ) : (
                <div className='lg:hidden'>
                    <div className='flex items-center justify-center p-[2rem] flex-col gap-1 text-paleSky'>
                        <div>{getStatusIcon()}</div>

                        <p className='text-[14px]'>
                            Invoice No:{' '}
                            <b>
                                {' '}
                                {invoiceDetails?.documentData?.invoiceNumber ??
                                    ''}
                            </b>
                        </p>
                        <h4 className='text-ebony text-[18px] font-bold'>
                            {formatCurrency(
                                invoiceDetails?.documentData?.totalAmount || 0,
                                invoiceDetails?.documentData?.currencyCode
                            )}
                        </h4>
                        <p className=' font-semibold text-[14px]'>
                            {invoiceDetails?.documentData?.beneficiary
                                ?.beneficiaryCompanyName ?? ''}
                        </p>
                    </div>
                    <div className=' bg-white px-5 py-5'>
                        {paymentDetails?.length > 0 ? (
                            <RenderPaymentDetails />
                        ) : (
                            <Box>
                                <h4 className=' text-[16px] font-bold border-b-[1px] border-appcolorfaintnine mb-2'>
                                    Details
                                </h4>
                                <Stack
                                    width='100%'
                                    direction='row'
                                    className='flex-row flex justify-between pt-[24px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                                >
                                    <p className='text-appcolorblacktwo'>
                                        Bill of lading Number
                                    </p>

                                    <p className='font-medium'>
                                        {
                                            invoiceDetails?.documentData
                                                ?.billOfLadingNumber
                                        }
                                    </p>
                                </Stack>

                                <Stack
                                    width='100%'
                                    direction='row'
                                    className='flex-row flex justify-between pt-[24px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                                >
                                    <p className='text-appcolorblacktwo'>
                                        Customer Name
                                    </p>

                                    <p className='font-medium'>
                                        {
                                            invoiceDetails?.documentData
                                                ?.customerName
                                        }
                                    </p>
                                </Stack>

                                <Stack
                                    width='100%'
                                    direction='row'
                                    className='flex-row flex justify-between pt-[24px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                                >
                                    <p className='text-appcolorblacktwo'>
                                        Carrier Name
                                    </p>

                                    <p className='font-medium'>
                                        {
                                            invoiceDetails?.documentData
                                                ?.beneficiary
                                                .beneficiaryCompanyName
                                        }
                                    </p>
                                </Stack>

                                <Stack
                                    width='100%'
                                    direction='row'
                                    className='flex-row flex justify-between pt-[24px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                                >
                                    <p className='text-appcolorblacktwo'>
                                        Issued Date
                                    </p>

                                    <p className='font-medium'>
                                        {dayjs(
                                            invoiceDetails?.documentData
                                                ?.issuedDate
                                        ).format('DD MMM, YYYY')}{' '}
                                    </p>
                                </Stack>

                                <Stack
                                    width='100%'
                                    direction='row'
                                    className='flex-row flex justify-between pt-[24px] border-b-[1px] border-appcolorfaintnine text-[14px]'
                                >
                                    <p className='text-appcolorblacktwo'>
                                        Due Date{' '}
                                    </p>

                                    <p className='font-medium'>
                                        {dayjs(
                                            invoiceDetails?.documentData
                                                ?.dueDate
                                        ).format('DD MMM, YYYY')}{' '}
                                    </p>
                                </Stack>

                                <Divider className='my-[1.5rem] mx-auto min-w-[20%] max-w-[20%] border-[#C5C5C5]' />

                                {permissions &&
                                    hasPermission({
                                        userPermissions: permissions,
                                        module: 'payments',
                                        permissionList: ['write', '*', 'read']
                                    }) && (
                                        <div className=' w-[100%] flex flex-col '>
                                            <h4 className='text-appcolorblacktwo text-[16px] font-semibold'>
                                                Invoice
                                            </h4>
                                            <ViewTransactionDocument
                                                fileUrl={
                                                    REACT_APP_BASE_URL +
                                                    invoiceDetails?.documentUrl?.replace(
                                                        'v1/',
                                                        ''
                                                    )
                                                }
                                            />
                                        </div>
                                    )}
                            </Box>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default LogisticsInvoiceDetailsMobileView;
