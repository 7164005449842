import jwtDecode from "jwt-decode";

export const getToken = (): string | null => {
  return localStorage.getItem("token");
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem("refreshToken");
};

export const setToken = (token: string): void => {
  localStorage.setItem("token", token);
};

export const removeToken = (): void => {
  localStorage.removeItem("token");
};

export const isTokenExpired = (): boolean => {
  const token = getToken();
  if (token) {
    const decodedToken: { exp: number } = jwtDecode(token);
    const currentTime: number = Math.floor(Date.now() / 1000); // Convert to seconds
    return decodedToken.exp < currentTime;
  }
  return true; // Token is not present or expired
};
