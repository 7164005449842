import { Divider, Stack } from '@mui/material'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { TabContainerNew } from '../../../styles/TradeDocStyles'

interface breadcrumb {
  title: string
  link?: string
  active: boolean
}

interface IProps {
  breadcrumbs?: breadcrumb[]
  setActiveView?: any
  noHead?: boolean
  activeView?: string
}

const BookingHeader: FC<IProps> = ({
  breadcrumbs,
  setActiveView,
  activeView,
  noHead,
}: IProps): JSX.Element => {
  const navigate = useNavigate()

  return (
    <div className="form-header">
      <div className="breadcrumbs">
        {breadcrumbs?.map((item, index) => (
          <div className="flex gap-2">
            <p
              key={index}
              className={
                item?.active ? 'breadcrumb-text active' : 'breadcrumb-text'
              }
              onClick={() => {
                if (item.link !== undefined) {
                  navigate(item?.link)
                }
              }}
            >
              {item.title}
            </p>
            {index !== breadcrumbs.length - 1 && (
              <img src="/img/vectors/breadcrumbcaret.svg" alt="caret" />
            )}
          </div>
        ))}
      </div>
      <div className="header-action">
        <h1 className="text-appcolorblacktwo">{activeView || ''}</h1>
      </div>
      {!noHead && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="header-action"
        >
          <Stack direction="row" justifyContent="space-between" width="100%">
            <TabContainerNew>
              <Stack direction="row" spacing={'5px'}>
                <div
                  className="tab-buttons"
                  style={{
                    cursor: 'pointer',
                    background:
                      activeView === 'Booking Profiles' ? '#0088b21a' : 'unset',

                    color:
                      activeView === 'Booking Profiles' ? '#0088b2' : 'initial',

                    fontWeight:
                      activeView === 'Booking Profiles' ? '500' : '400',
                  }}
                  onClick={() => setActiveView('Booking Profiles')}
                >
                  <p
                    style={{
                      paddingRight: '5px',
                    }}
                  >
                    Booking Profiles
                  </p>
                </div>

                <Divider orientation="vertical" flexItem />
                <div
                  className="tab-buttons"
                  style={{
                    cursor: 'pointer',
                    background:
                      activeView === 'Shipping Profiles'
                        ? '#0088b21a'
                        : 'unset',

                    color:
                      activeView === 'Shipping Profiles'
                        ? '#0088b2'
                        : 'initial',

                    fontWeight:
                      activeView === 'Shipping Profiles' ? '500' : '400',
                  }}
                  onClick={() => setActiveView('Shipping Profiles')}
                >
                  <p style={{ paddingRight: '5px' }}>Shipping Profiles</p>
                </div>

                <Divider orientation="vertical" flexItem />
                <div
                  className="tab-buttons"
                  style={{
                    cursor: 'pointer',
                    background:
                      activeView === 'Bookings' ? '#0088b21a' : 'unset',

                    color: activeView === 'Bookings' ? '#0088b2' : 'initial',

                    fontWeight: activeView === 'Bookings' ? '500' : '400',
                  }}
                  onClick={() => setActiveView('Bookings')}
                >
                  <p style={{ paddingRight: '5px' }}>Bookings</p>
                </div>
              </Stack>
            </TabContainerNew>

            <TabContainerNew>
              <Stack direction="row" spacing={'10px'}>
                <div className="tab-buttons">
                  <img src="/img/vectors/plainfilter.svg" alt="plainfilter" />
                  <p style={{ paddingRight: '5px' }}>Filter</p>
                </div>
                <Divider orientation="vertical" flexItem />
                <div className="tab-buttons">
                  <img src="/img/vectors/refreshtable.svg" alt="" />

                  <p style={{ paddingLeft: '5px' }}>Refresh</p>
                </div>
                <Divider orientation="vertical" flexItem />
                <div className="tab-buttons">
                  <img src="/img/vectors/filter2.svg" alt="" />

                  <p style={{ paddingLeft: '5px' }}>Sort</p>
                </div>
              </Stack>
            </TabContainerNew>
          </Stack>
        </Stack>
      )}
    </div>
  )
}

export default BookingHeader
