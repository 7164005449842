import {
    Autocomplete,
    Box,
    CircularProgress,
    MenuItem,
    TextField
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { Wallet } from '../pages/dashboard/payments/interfaces/Wallet';
import useWallets from '../customhooks/wallets/useWallets';
import { getFormattedAmount } from '../shared/getFormattedAmount';
import { InfoCircleIcon } from './icons/InfoCircleIcon';

export const WalletSelect = (props: {
    value?: number;
    onHandleChange: (value: Wallet) => void;
    options?: Wallet[];
}) => {
    const [selected, setSelected] = useState<Wallet>({} as Wallet);
    const { isLoading, wallets } = useWallets();
    const { value, onHandleChange, options = wallets } = props;

    useEffect(() => {
        if (value) {
            const found = options.find(({ id }) => id === value);

            if (found) {
                setSelected(found);
            }
        }
    });

    return (
        <Box className='space-y-1'>
            <Autocomplete
                placeholder='0.00'
                disableClearable
                sx={{
                    '.MuiInputBase-input': {
                        height: '0.3rem'
                    },
                    backgroundColor: 'white'
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        focused
                        label='Select Wallet'
                        placeholder='Wallets'
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: isLoading && (
                                <Fragment>
                                    <CircularProgress size={20} />
                                    {params.InputProps.endAdornment}
                                </Fragment>
                            )
                        }}
                    />
                )}
                noOptionsText={'No data available'}
                renderOption={(props, option) => (
                    <MenuItem
                        {...props}
                        value={option.id}
                        key={`wallet-${option.id}`}
                    >
                        {`${option.currencyCode} Wallet`}
                    </MenuItem>
                )}
                getOptionLabel={(option) =>
                    option.currencyCode ? `${option.currencyCode} Wallet` : ''
                }
                onChange={(_, value) => onHandleChange(value)}
                options={options}
                value={selected}
            />
            {selected && (
                <Box className='flex items-center space-x-2 p-3 pl-4 bg-gray-200'>
                    <InfoCircleIcon />
                    <Box>
                        Available balance is{' '}
                        <span className='font-semibold'>
                            {getFormattedAmount({
                                amount: selected.walletBalance || 0,
                                currency: selected.currencyCode ?? 'NGN'
                            })}
                        </span>
                    </Box>
                </Box>
            )}
        </Box>
    );
};
