const PaidInvoiceIcon = () => {
    return (
        <svg
            width='40'
            height='41'
            viewBox='0 0 40 41'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect y='0.0424805' width='40' height='40' rx='8' fill='#16B364' />
            <path
                d='M17.5 21.7927C17.5 22.7627 18.25 23.5427 19.17 23.5427H21.05C21.85 23.5427 22.5 22.8627 22.5 22.0127C22.5 21.1027 22.1 20.7727 21.51 20.5627L18.5 19.5127C17.91 19.3027 17.51 18.9827 17.51 18.0627C17.51 17.2227 18.16 16.5327 18.96 16.5327H20.84C21.76 16.5327 22.51 17.3127 22.51 18.2827'
                stroke='#A2E1C1'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M20 15.5425V24.5425'
                stroke='#A2E1C1'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M30 20.0425C30 25.5625 25.52 30.0425 20 30.0425C14.48 30.0425 10 25.5625 10 20.0425C10 14.5225 14.48 10.0425 20 10.0425'
                stroke='#A2E1C1'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M30 14.0425V10.0425H26'
                stroke='#A2E1C1'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M25 15.0425L30 10.0425'
                stroke='#A2E1C1'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default PaidInvoiceIcon;
