import React, { FC } from 'react';
import { PreviewContent } from '../../../../styles/TradeDocStyles';
import { REACT_APP_BASE_URL } from '../../../../config';
import { Stack } from '@mui/material';
import moment from 'moment';
import { formatCurrency, formatNumber } from '../../../../utils/helper';
import { Table } from 'antd';
import DocumentViewer from '../../../DocumentViewer/DocumentViewer';

interface IProps {
    data: any;
    docTitle: string;
    quoteFields: any;
    totalAmount?: number;
    signatureUrlBase64: any;
    totalGrossWeight?: any;
    totalUnitQuantity?: any;
    totalNetWeight?: any;
    signatureUrlBase64Chamber?: any;
    totalVolume?: number;
}

const FormPreviewTemplate2: FC<IProps> = ({
    data,
    docTitle,
    quoteFields,
    totalAmount,
    signatureUrlBase64,
    totalGrossWeight,
    totalUnitQuantity,
    totalNetWeight,
    signatureUrlBase64Chamber,
    totalVolume
}: IProps) => {
    return (
        <PreviewContent>
            <div className='text-[14px]'>
                <div className='flex items-center justify-between pb-4 mb-4'>
                    {data?.logoUrl ? (
                        <div className='h-[50px] w-[100px]'>
                            <DocumentViewer
                                fileUrl={`${REACT_APP_BASE_URL}/${data?.logoUrl.split(
                                    '/v1/'
                                )[1]}`}
                            />
                        </div>
                    ) : (
                        <>
                            <div>
                                <img
                                    src='/img/vectors/powered.svg'
                                    alt=''
                                    className='h-[2.5rem]'
                                />
                            </div>
                        </>
                    )}

                    <div>
                        <h1 className='text-appcolorprimary text-[26px] leading-[27px] font-bold capitalize'>
                            {docTitle}
                        </h1>
                    </div>
                </div>
            </div>

            {(docTitle === 'Commercial Invoice' ||
                docTitle === 'Packing List' ||
                docTitle === 'Draft Certificate of Origin') && (
                <Stack
                    direction='row'
                    alignItems='start'
                    className='top-preview-content'
                    width='full'
                    justifyContent={'space-between'}
                >
                    <div className='view-one'>
                        <p className='font-semibold text-appcolorprimary'>
                            Exporter
                        </p>
                        <p className='font-medium'>
                            {data?.exporterName || '--'}
                        </p>
                        <p>{data?.exporterAddress}</p>
                        <p>{data?.exporterCountry}</p>
                    </div>
                    <div className='view-two'>
                        <p className='font-semibold text-appcolorprimary'>
                            Consignee
                        </p>
                        <p className='font-medium'>{data?.consigneeName}</p>
                        <p>{data?.consigneeAddress}</p>
                        <p>{data?.consigneeCountry}</p>
                    </div>

                    <div className='view-three'>
                        <div></div>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>Invoice No.</p>
                            {docTitle === 'Draft Certificate of Origin' ||
                            docTitle === 'Packing List' ? (
                                <p className='value'>
                                    {data?.exportInvoiceNumber || '--'}
                                </p>
                            ) : (
                                <p className='value'>
                                    {data?.invoiceNumber || '--'}
                                </p>
                            )}
                        </Stack>

                        <Stack
                            direction='row'
                            gap='20px'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <p className='title'>
                                {docTitle === 'Draft Certificate of Origin'
                                    ? 'Exporter Invoice Date'
                                    : 'Date'}
                            </p>
                            <p className='value'>
                                {moment(
                                    docTitle === 'Draft Certificate of Origin'
                                        ? data?.exportInvoiceDate
                                        : data?.exportInvoiceDate
                                ).format('ll') || '--'}
                            </p>
                        </Stack>

                        {docTitle !== 'Draft Certificate of Origin' && (
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Reference</p>
                                <p className='value'>
                                    {data?.reference || '--'}
                                </p>
                            </Stack>
                        )}
                        {docTitle === 'Draft Certificate of Origin' && (
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Letter of Credit Number</p>
                                <p className='value'>
                                    {data?.letterOfCreditNumber || '--'}
                                </p>
                            </Stack>
                        )}
                        {data?.buyerName && (
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Buyer Name</p>
                                <p className='value'>
                                    {docTitle === 'Draft Certificate of Origin'
                                        ? data?.buyerName
                                        : data?.buyer || '--'}
                                </p>
                            </Stack>
                        )}
                        {data?.buyerAddress && (
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Buyer Address</p>
                                <p className='value'>
                                    {data?.buyerAddress || '--'}
                                </p>
                            </Stack>
                        )}
                        {docTitle === 'Commercial Invoice' &&
                            data?.buyerReference && (
                                <Stack
                                    direction='row'
                                    gap='20px'
                                    width='100%'
                                    justifyContent='space-between'
                                >
                                    <p className='title'>Buyer Reference</p>
                                    <p className='value'>
                                        {data?.buyerReference || '--'}
                                    </p>
                                </Stack>
                            )}
                        {docTitle === 'Packing List' && (
                            <Stack
                                direction='row'
                                gap='20px'
                                width='100%'
                                justifyContent='space-between'
                            >
                                <p className='title'>Bill of Lading Number</p>
                                <p className='value'>
                                    {data?.billOfLadingNumber || '--'}
                                </p>
                            </Stack>
                        )}
                    </div>
                </Stack>
            )}

            {docTitle === 'Commercial Invoice' && (
                <Stack
                    direction='row'
                    alignItems='start'
                    className='mid-preview-content'
                    width='full'
                    justifyContent={'space-between'}
                >
                    <div className='view-one'>
                        <p className='title'>Method of Dispatch</p>
                        <p className='value'>{data?.methodOfDispatch}</p>

                        <p className='title'>Type of Shipment</p>
                        <p className='value'>{data?.typeOfShipment}</p>

                        <p className='title'>Country Of Origin of Goods</p>
                        <p className='value'>{data?.countryOfOriginName}</p>
                    </div>

                    <div className='view-one'>
                        <p className='title'>Transport</p>
                        <p className='value'>{data?.transport}</p>

                        <p className='title'>Voyage Number</p>
                        <p className='value'>{data?.voyageNumber}</p>

                        <p className='title'>Final Destination</p>
                        <p className='value'>{data?.countryOfOriginName}</p>

                        <p className='title'>Country of Final Destination</p>
                        <p className='value'>
                            {data?.countryOfFinalDestinationName}
                        </p>
                    </div>

                    <div className='view-one'>
                        <p className='title'>Port of Loading</p>
                        <p className='value'>
                            {data?.portOfLoadingValue?.name}
                        </p>

                        <p className='title'>Method of Discharge</p>
                        <p className='value'>
                            {data?.portOfDischargeValue?.name}
                        </p>

                        <p className='title'>Method of Dispatch</p>
                        <p className='value'>{data?.methodOfDispatch}</p>

                        <p className='title'>Place of Origin</p>
                        <p className='value'>{data?.placeOfOrigin}</p>
                    </div>

                    <div className='view-one'>
                        <p className='title'>Date of Departure</p>
                        <p className='value'>
                            {moment(data?.dateOfDeparture).format('ll')}
                        </p>

                        <p className='title'>Terms/Method of Payment</p>
                        <p className='value'>{data?.termsOfPayment}</p>

                        <p className='title'>Marine Cover Policy No</p>
                        <p className='value'>{data?.marineCoverPolicyNo}</p>

                        <p className='title'>Letter Of Credit No</p>
                        <p className='value'>{data?.letterOfCreditNo}</p>
                    </div>
                </Stack>
            )}

            {docTitle === 'Packing List' && (
                <Stack
                    direction='row'
                    alignItems='start'
                    className='mid-preview-content'
                    width='full'
                    justifyContent={'space-between'}
                >
                    <div className='view-one'>
                        <p className='title'>Method of Dispatch</p>
                        <p className='value'>
                            {data?.methodOfDispatch || '--'}
                        </p>

                        <p className='title'>Type of Shipment</p>
                        <p className='value'>{data?.typeOfShipment || '--'}</p>

                        <p className='title'>Country Of Origin of Goods</p>
                        <p className='value'>
                            {data?.countryOfOriginName || '--'}
                        </p>
                    </div>

                    <div className='view-one'>
                        <p className='title'>Transport</p>
                        <p className='value'>{data?.transport || '--'}</p>

                        <p className='title'>Voyage Number</p>
                        <p className='value'>{data?.voyageNumber || '--'}</p>

                        <p className='title'>Country of Final Destination</p>
                        <p className='value'>
                            {data?.countryOfFinalDestinationName || '--'}
                        </p>
                    </div>

                    <div className='view-one'>
                        <p className='title'>Place of Origin</p>
                        <p className='value'>{data?.placeOfOrigin || '--'}</p>

                        <p className='title'>Method of Discharge</p>
                        <p className='value'>
                            {data?.portOfDischargeValue?.name || '--'}
                        </p>

                        <p className='title'>Final Destination</p>
                        <p className='value'>
                            {data?.finalDestination || '--'}
                        </p>
                    </div>

                    <div className='view-one'>
                        <p className='title'>Port of Loading</p>
                        <p className='value'>
                            {data?.portOfLoadingValue?.name || '--'}
                        </p>

                        <p className='title'>Date of Departure</p>
                        <p className='value'>
                            {moment(data?.dateOfDeparture).format('ll')}
                        </p>
                    </div>
                </Stack>
            )}

            {docTitle === 'Draft Certificate of Origin' && (
                <Stack
                    direction='row'
                    alignItems='start'
                    className='mid-preview-content'
                    width='full'
                    justifyContent={'space-between'}
                >
                    <div className='view-one'>
                        <p className='title'>Method of Dispatch</p>
                        <p className='value'>
                            {data?.methodOfDispatch || '--'}
                        </p>

                        <p className='title'>Type of Shipment</p>
                        <p className='value'>{data?.typeOfShipment || '--'}</p>

                        <p className='title'>Port of Loading</p>
                        <p className='value'>
                            {data?.portOfLoadingValue?.name || '--'}
                        </p>

                        <p className='title'>Date of Departure</p>
                        <p className='value'>
                            {moment(data?.dateOfDeparture).format('ll')}
                        </p>
                    </div>

                    <div className='view-one'>
                        <p className='title'>Transport</p>
                        <p className='value'>{data?.transport || '--'}</p>

                        <p className='title'>Voyage Number</p>
                        <p className='value'>{data?.voyageNumber || '--'}</p>

                        <p className='title'>Method of Discharge</p>
                        <p className='value'>
                            {data?.portOfDischargeValue?.name || '--'}
                        </p>

                        <p className='title'>Final Destination</p>
                        <p className='value'>
                            {data?.finalDestination || '--'}
                        </p>
                    </div>

                    <div className='view-one'>
                        <p className='title'>Parking Details</p>
                        <p className='value'>{data?.packingDetails || '--'}</p>
                    </div>
                </Stack>
            )}

            {docTitle === 'Commercial Invoice' && (
                <div className='table-view'>
                    <Table
                        rowKey={'id'}
                        columns={[
                            {
                                title: 'Product Code',
                                dataIndex: 'id',
                                key: 'id'
                            },
                            {
                                title: 'Description',
                                dataIndex: 'desc',
                                key: 'desc'
                            },
                            {
                                title: 'Unit Qty.',
                                dataIndex: 'qty',
                                key: 'qty',
                                render: (text) => (
                                    <span>{formatNumber(text)}</span>
                                )
                            },
                            {
                                title: 'Unit Type',
                                dataIndex: 'type',
                                key: 'type'
                            },
                            {
                                title: 'Unit Price',
                                dataIndex: 'price',
                                key: 'price',
                                render: (text) => (
                                    <span>
                                        {formatCurrency(
                                            text,
                                            data?.currencyCode
                                        )}
                                    </span>
                                )
                            },
                            {
                                title: 'Total',
                                dataIndex: '',
                                key: '',
                                render: (text, record: any) => (
                                    <span>
                                        {formatCurrency(
                                            (isNaN(record.price)
                                                ? 0
                                                : record.price) *
                                                (isNaN(record.qty)
                                                    ? 0
                                                    : record.qty),
                                            data?.currencyCode
                                        )}
                                    </span>
                                )
                            }
                        ]}
                        dataSource={quoteFields}
                        pagination={false}
                    />
                </div>
            )}

            {docTitle === 'Packing List' && (
                <div className='table-view'>
                    <Table
                        rowKey={'id'}
                        columns={[
                            {
                                title: 'Product Code',
                                dataIndex: 'id',
                                key: 'id'
                            },
                            {
                                title: 'Description',
                                dataIndex: 'desc',
                                key: 'desc'
                            },
                            {
                                title: 'Quantity',
                                dataIndex: 'qty',
                                key: 'qty',
                                render: (text) => (
                                    <span>{formatNumber(text)}</span>
                                )
                            },
                            {
                                title: 'Package Kind',
                                dataIndex: 'kindOfPackage',
                                key: 'kindOfPackage'
                            },
                            {
                                title: 'Package Qty',
                                dataIndex: 'qtyOfPackage',
                                key: 'qtyOfPackage'
                            },
                            {
                                title: 'Net Weight of Package (Kg)',
                                dataIndex: 'weightOfPackageKG',
                                key: 'weightOfPackageKG',
                                render: (text) => (
                                    <span>{formatNumber(text)}</span>
                                )
                            },
                            {
                                title: 'Gross Weight of Package (Kg)',
                                dataIndex: 'grossWeightOfPackageKG',
                                key: 'grossWeightOfPackageKG',
                                render: (text) => (
                                    <span>{formatNumber(text)}</span>
                                )
                            },
                            {
                                title: 'Measurements of Package (m3)',
                                dataIndex: 'measurementOfPackage',
                                key: 'measurementOfPackage',
                                render: (text) => (
                                    <span>{formatNumber(text)}</span>
                                )
                            }
                        ]}
                        dataSource={quoteFields}
                        pagination={false}
                    />
                </div>
            )}

            {docTitle === 'Draft Certificate of Origin' && (
                <div className='table-view'>
                    <Table
                        rowKey={'id'}
                        columns={[
                            {
                                title: 'Marks & Numbers*',
                                dataIndex: 'id',
                                key: 'id'
                            },
                            {
                                title: 'Description',
                                dataIndex: 'desc',
                                key: 'desc'
                            },
                            {
                                title: 'Package Kind',
                                dataIndex: 'kindOfPackage',
                                key: 'kindOfPackage'
                            },
                            {
                                title: 'Package Qty',
                                dataIndex: 'qtyOfPackage',
                                key: 'qtyOfPackage'
                            },
                            {
                                title: 'Tariff Code',
                                dataIndex: 'tariffCode',
                                key: 'tariffCode'
                            },
                            {
                                title: 'Gross Weight',
                                dataIndex: 'grossWeight',
                                key: 'grossWeight',
                                render: (text) => (
                                    <span>{formatNumber(text)} Kg</span>
                                )
                            }
                        ]}
                        dataSource={quoteFields}
                        pagination={false}
                    />
                </div>
            )}

            {docTitle === 'Draft Certificate of Origin' && (
                <Stack direction='row' justifyContent='flex-end'>
                    <div className='preview-total'>
                        Total Weight :{' '}
                        <span className=''>
                            {formatNumber(totalGrossWeight)} Kg
                        </span>
                    </div>
                </Stack>
            )}

            {docTitle === 'Commercial Invoice' && (
                <Stack direction='row' justifyContent='flex-end'>
                    <div className='preview-total'>
                        Total amount :{' '}
                        <span className=''>
                            {formatCurrency(
                                totalAmount || 0,
                                data?.currencyCode
                            )}
                        </span>
                    </div>
                </Stack>
            )}

            {docTitle === 'Packing List' && (
                <div className='flex justify-end mt-8 '>
                    <Stack direction='row' width='100%'>
                        <div
                            className='preview-total flex items-center flex-1'
                            style={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                marginTop: '0'
                            }}
                        >
                            Total Unit Qty:{' '}
                            <span>{formatNumber(totalUnitQuantity)}</span>
                        </div>

                        <div
                            className='preview-total flex items-center flex-1'
                            style={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                marginTop: '0'
                            }}
                        >
                            Total net weight (kg):{' '}
                            <span>{formatNumber(totalNetWeight)}</span>
                        </div>

                        <div
                            className='preview-total flex items-center flex-1'
                            style={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                marginTop: '0'
                            }}
                        >
                            Total gross weight (kg):{' '}
                            <span>{formatNumber(totalGrossWeight)}</span>
                        </div>

                        <div
                            className='preview-total flex items-center flex-1'
                            style={{
                                paddingLeft: '5px',
                                paddingRight: '5px',
                                marginTop: '0'
                            }}
                        >
                            Total volume (m³):{' '}
                            <span>{formatNumber(totalVolume || 0)}</span>
                        </div>
                    </Stack>
                </div>
            )}

            {docTitle === 'Draft Certificate of Origin' ? (
                <Stack
                    direction='row'
                    alignItems='start'
                    className='bottom-preview-content'
                    width='full'
                    justifyContent={'space-between'}
                >
                    <div className='view-one'>
                        <p className='titl'>Chamber Issue Date</p>
                        <p>
                            {moment(data?.chamberIssuedDate).format('ll') ||
                                '--'}
                        </p>

                        <p className='titl'>
                            Chamber Name Of Authorized Signatory
                        </p>
                        <p>{data?.chamberNameOfAuthorisedSignatory || '--'}</p>

                        <p className='titl'>Chamber Signature</p>
                        <img src={`${signatureUrlBase64Chamber}`} alt='' />
                    </div>

                    <div className='view-two'>
                        <p className='titl'>Chamber Place of Issue</p>
                        <p>{data?.chamberPlaceOfIssue || '--'}</p>

                        <p className='titl'>Place of Issue</p>
                        <p className=''>{data?.exporterPlaceOfIssue || '--'}</p>
                    </div>

                    <div className='view-three'>
                        <p className='titl'>Issued Date</p>
                        <p className=''>
                            {moment(data?.exporterIssuedDate).format('ll') ||
                                '--'}
                        </p>
                        <p className='titl'>Name of Authorized Signatory</p>
                        <p>{data?.exporterNameOfAuthorisedSignatory || '--'}</p>
                        <p className='titl'>Signature</p>
                        <img src={`${signatureUrlBase64}`} alt='' />
                    </div>
                </Stack>
            ) : (
                <Stack
                    direction='row'
                    alignItems='start'
                    className='bottom-preview-content'
                    width='full'
                    justifyContent={'space-between'}
                >
                    <div className='view-one'>
                        <p className='titl'>Bank Details</p>
                        <p>{data?.bankDetails || '--'}</p>

                        <p className='titl'>Additional Information</p>
                        <p>{data?.additionalInformation}</p>
                    </div>

                    <div className='view-two'>
                        <p className='titl'>Place of Issue</p>
                        <p className=''>{data?.placeOfIssue || '--'}</p>
                        <p className='titl'>Signatory Company</p>
                        <p>{data?.signatoryCompany || '--'}</p>
                        {data?.stamp && (
                            <Stack direction='row' justifyContent='flex-end'>
                                <div className='totalView w-[250px]'>
                                    <p className='titl'>Exporter's Stamp</p>
                                    <div className='h-[50px] w-[100px] img'>
                                        <DocumentViewer
                                            fileUrl={`${REACT_APP_BASE_URL}/${data?.stamp.split(
                                                '/v1/'
                                            )[1]}`}
                                        />
                                    </div>
                                </div>
                            </Stack>
                        )}
                    </div>

                    <div className='view-three'>
                        <p className='titl'>Due Date</p>
                        <p className=''>
                            {moment(data?.dueDate).format('ll') || '--'}
                        </p>
                        <p className='titl'>Name of Authorized Signatory</p>
                        <p>{data?.nameOfAuthorisedSignatory || '--'}</p>
                        <p className='titl'>Signature</p>
                        <img src={`${signatureUrlBase64}`} alt='' />
                    </div>
                </Stack>
            )}
        </PreviewContent>
    );
};

export default FormPreviewTemplate2;
