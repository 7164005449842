import Tile from './tile';
import { Grid } from '@mui/material';
import { OceanFetcherResponse } from '../../ocean-schedules';
import React from 'react';
import { EmptinessPlaceholder } from './list-results';
import { underScoreToNormalString } from '../../../../../utils/helper';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { SearchResult } from '../../interfaces/SearchResults';

interface TileResultsProps extends OceanFetcherResponse {
    from: string;
    to: string;
    handleBookButtonClick: (data: SearchResult) => void;
    isProfileExistsFn: (id: string) => boolean;
}

const TileResults = (props: TileResultsProps) => {
    return (
        <>
            {!props.hasApiResponse || props.results?.length === 0 ? (
                <div className=' bg-white h-[20rem] mt-8 rounded-md flex justify-center items-center'>
                    <EmptinessPlaceholder
                        title={'No schedules found.'}
                        subtitle={
                            props.errorMessage
                                ? underScoreToNormalString(props.errorMessage)
                                : 'We are unable to provide this schedule at this moment. Please do another search.'
                        }
                        icon={<ErrorOutlineOutlinedIcon fontSize='large' />}
                    />
                </div>
            ) : (
                <Grid container spacing={2} sx={{ mt: 2.5 }}>
                    <Grid item xs={12} md={12} lg={10}>
                        {props.results.map((result) => (
                            <Tile
                                from={props.from}
                                to={props.to}
                                data={result}
                                handleBookButtonClick={
                                    props.handleBookButtonClick
                                }
                                isProfileExistsFn={props.isProfileExistsFn}
                            />
                        ))}
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default TileResults;
