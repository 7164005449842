import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Outletouterpage from '../../../components/dashboard/outletouterpage/outletouterpage';
import { RootState } from '../../../store/store';
import Financingcards from './financingcards/financingcards';
import EnablingFeature from '../../../components/EnablingFeature';
import FinanceInfoModal from './FinanceInfoModal';
import { ShipIcon, WalletIcon } from '../../../components/icons';

const Financing = () => {
    const navigate = useNavigate();

    const state = useSelector((state: RootState) => ({
        user: state.user.user
    }));

    const { user } = state;
    const [viewInfoModal, setViewInfoModal] = useState(false);
    return (
        <EnablingFeature flag='Financing'>
            <div className='mb-8 overflow-x-hidden'>
                <Outletouterpage
                    breadcrumbs={[
                        {
                            title: 'Home',
                            link: '/dashboard'
                        },
                        {
                            title: 'Financing',
                            link: '/financing'
                        }
                    ]}
                >
                    <div className='flex justify-between'>
                        <h1 className='text-xl lg:text-[32px] leading-[23px] text-ebony lg:text-appcolorblacktwo'>
                            Financing
                        </h1>

                        <button
                            className='w-fit hidden lg:flex items-center cursor-pointer bg-appcolorprimary rounded-[10px] py-[0.7rem] px-[0.9rem] mr-2'
                            onClick={() => {
                                setViewInfoModal(true);
                            }}
                        >
                            <img
                                src='/img/vectors/brokenplus.svg'
                                alt='plaindownload'
                                className='h-[1.1rem]'
                            />
                        </button>
                    </div>

                    <div className='mt-[16px] lg:mt-8'>
                        <div>
                            <Grid
                                container
                                spacing={4}
                                alignItems='flex-end'
                                sx={{
                                    marginTop: '0'
                                }}
                            >
                                <Grid item xs={12} lg={6}>
                                    <Financingcards
                                        imgIcon='/img/vectors/exportfinancecolored.svg'
                                        hasTop
                                        topText='Export factoring'
                                        title={
                                            <p>
                                                Get paid upfront on all our
                                                shipments
                                            </p>
                                        }
                                        subTitle={
                                            <span>
                                                You can now get an advance on
                                                all exports and offer payment
                                                terms to your customers.
                                            </span>
                                        }
                                        getStarted={() => {
                                            setViewInfoModal(true);
                                        }}
                                        buttonText='Apply for Export Factoring'
                                        icon={<WalletIcon />}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Financingcards
                                        imgIcon='/img/vectors/logisticsolored.svg'
                                        hasTop
                                        topText='Freight Financing'
                                        title={
                                            <p>
                                                Ship your goods and pay us later
                                            </p>
                                        }
                                        subTitle={
                                            <span>
                                                We settle all your freight and
                                                logistics expenses and you pay
                                                us back later.
                                            </span>
                                        }
                                        getStarted={() => {
                                            setViewInfoModal(true);
                                        }}
                                        buttonText='Apply for Freight Financing'
                                        icon={<ShipIcon />}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Outletouterpage>
            </div>
            <FinanceInfoModal view={viewInfoModal} setView={setViewInfoModal} />
        </EnablingFeature>
    );
};

export default Financing;
