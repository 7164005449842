import React, { useState } from 'react';
import { PlusIcon } from '../../../icons/PlusIcon';
import { SuitUIButton } from '../../../SuitUIButton';
import { Dropdown, MenuProps, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useUserProfile } from '../../../../customhooks/useUserProfile';
import { hasPermission } from '../../dashboardpage/utils';
import { add } from '../../../customIcons';
import Addpartnerdrawer from '../../../../pages/dashboard/tradedocuments/addpartnerdrawer/addpartnerdrawer';
import Custombutton from '../../../custombutton/custombutton';
import CustomModal from '../../../CustomModal';
import useCorporatePartners from '../../../../customhooks/useCorporatePartners';
import SalesContractUpload from '../../../../pages/dashboard/tradedocuments/addtradedocument/SalesContractUpload';
import { useFormik } from 'formik';
import { nanoid } from '@reduxjs/toolkit';
import { object, string } from 'yup';
import { createSalesContract } from '../../../../api/documents/documents';
import { openNotification } from '../../../../utils/helper';
import { Divider } from '@mui/material';

type commodityInterface = {
    id: string;
    commodity: string;
    commodityType: string;
    quality: string;
    quantity: number;
    hsCode: string;
    quantityBuffer: string;
    commodityId: string;
    metric: string;
};

const CreateContractMobile = () => {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();
    const { permissions } = useUserProfile();

    const closeVisibleModal = () => {
        setVisible(false);
    };

    const [showAddPartner, setShowAddPartner] = useState(false);
    const { invalidateCorporatePartners } = useCorporatePartners({ page: 1 });

    const closeShowAddParty = (refreshList?: boolean) => {
        setShowAddPartner(false);
        if (refreshList) {
            invalidateCorporatePartners();
        }
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <p
                    onClick={() => {
                        setVisible(true);
                        // closeUploadOrCreate()
                    }}
                >
                    Upload
                </p>
            )
        },
        {
            key: '2',
            label: (
                <p
                    onClick={() => {
                        navigate('/trade-documents/sales-contract');
                    }}
                >
                    Create
                </p>
            )
        }
    ];

    const formik = useFormik({
        initialValues: {
            documentName: '',
            contractNumber: nanoid(9),
            contractType: '',
            commodities: [],
            shippingDeadline: Date.now(),
            containerType: '',
            portOfDischargeIds: [],
            portOfDischargeValue: undefined,
            portOfLoadingValue: undefined,
            portOfLoadingId: undefined,
            shippingLineIds: [],
            customerId: '',
            paymentTerms: '',
            shippingLineId: '',
            buyerAddress: '',
            exporterId: '',
            exporterAddress: '',
            additionalInfo: ''
        },
        validationSchema: object({
            lastName: string().required(),
            firstName: string().required(),
            corporateName: string().required(),
            industry: string().required(),
            corporateCountryCode: string().required(),
            phoneNumber: string().required()
        }),
        onSubmit: (values) => {
            console.log(values);
        }
    });

    const [payloadSales, setPayloadSales] = useState<any>({
        documentName: '',
        contractNumber: nanoid(9),
        contractType: '',
        commodities: [],
        shippingDeadline: Date.now(),
        containerType: '',
        portOfDischargeIds: [],
        portOfDischargeValue: undefined,
        portOfLoadingValue: undefined,
        portOfLoadingId: undefined,
        shippingLineIds: [],
        customerId: '',
        paymentTerms: '',
        shippingLineId: '',
        buyerAddress: '',
        exporterId: '',
        exporterAddress: '',
        additionalInfo: ''
    });

    const [errorsSales, setErrorsSales] = useState({
        clientId: '',
        contractNumber: '',
        paymentTerms: '',
        contractType: '',
        commodity: '',
        commodityArr: '',
        commodityId: '',
        shippingDeadline: '',
        quantity: '',
        quantityUnit: '',
        containerType: '',
        contractStatus: '',
        portOfDischargeId: '',
        portOfDischargeValue: '',
        logoUrl: '',
        salesContractType: '',
        shippingLineId: '',
        location: '',
        buyerAddress: '',
        exporterId: '',
        exporterAddress: '',
        additionalInfo: ''
    });
    const [error, setError] = useState<string | any>(null);
    const [loading, setLoading] = useState(false);

    const [signatureUrlBase64, setSignatureUrlBase64] = useState('');
    const [signatureUrlBase64Exporter, setSignatureUrlBase64Exporter] =
        useState('');
    const [commodityFields, setCommodityFields] = useState<
        commodityInterface[]
    >([
        {
            id: nanoid(9),
            commodityId: '',
            commodity: '',
            commodityType: '',
            quality: '',
            quantity: 0,
            hsCode: '',
            quantityBuffer: '',
            metric: ''
        }
    ]);

    /**
     * Update quotes input fields
     * @param id string
     * @param name string
     * @param value any
     */
    const updateCommodityField = (
        index: number,
        attributeName: string,
        attributeValue?: any
    ) => {
        const newContainers: any[] = [...commodityFields];
        newContainers[index][attributeName] = attributeValue;
        setCommodityFields(newContainers);
    };

    /**
     * Adds quotes input field line
     */
    const addCommodityField = () => {
        setCommodityFields([
            ...commodityFields,
            {
                id: nanoid(9),
                commodityId: '',
                commodity: '',
                commodityType: '',
                quality: '',
                quantity: 0,
                hsCode: '',
                quantityBuffer: '',
                metric: ''
            }
        ]);
    };
    const updateField = (name: string, value: any) => {
        setPayloadSales((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrorsSales((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));
    };

    const removeCommodityField = (index: number) => {
        setCommodityFields(commodityFields.filter((item, i) => i !== index));
    };

    const generalSubmit = async () => {
        try {
            setLoading(true);
            setError(null);

            setErrorsSales((prevState: any) => ({
                ...prevState,
                documentUrl: '',
                documentName: '',
                salesContractNumber: ''
            }));

            const items: any[] = [];

            const {
                contractNumber,
                contractType,
                shippingDeadline,
                containerType,
                portOfDischargeIds,
                portOfDischargeValue,
                portOfLoadingValue,
                portOfLoadingId,
                shippingLineIds,
                customerId,
                paymentTerms,
                documentName,
                shippingLineId,
                stamp,
                additionalInfo,
                buyerAddress,
                buyerSignature,
                exporterAddress,
                exporterId,
                exporterSignature,
                documentUrl
            } = payloadSales;

            const dischargeValue: any = portOfDischargeValue;
            const portOfLoading: any = portOfLoadingValue;

            commodityFields.forEach((item) => {
                items.push({
                    commodityId: item.commodityId,
                    quantity: item.quantity,
                    quantityBuffer: item.quantityBuffer,
                    metric: item.metric
                });
            });

            const data = {
                createdType: 'UPLOADED',
                documentData: {
                    contractType,
                    buyerId: customerId,
                    salesContractNumber: contractNumber,
                    commodities: commodityFields,
                    shippingDeadline,
                    portOfLoadingId: portOfLoading?.id,
                    containerType,
                    paymentTerms,
                    portOfDischargeIds: [dischargeValue?.id],
                    shippingLineIds: [shippingLineId],
                    additionalInfo,
                    exporterId,
                    exporterAddress,
                    buyerAddress
                },
                documentType: 'SALES_CONTRACT',
                documentName,
                documentUrl: documentUrl
            };

            await createSalesContract(data);

            openNotification('success', 'Document created successfully');

            closeVisibleModal();
        } catch (error) {
            setLoading(false);
            if (error?.response) {
                if (error?.response?.data?.error) {
                    let hasFieldNameAttribute = false;

                    error?.response?.data?.error.map((item: any) => {
                        return Object.keys(item).map((itemInside: string) => {
                            if (itemInside === 'fieldName') {
                                hasFieldNameAttribute = true;

                                return;
                            }
                        });
                    });

                    if (hasFieldNameAttribute) {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>
                                    {element.messageError}
                                </li>
                            );
                        });
                        setError(errorList);
                    } else {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>{element}</li>
                            );
                        });
                        setError(errorList);
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    return (
        <>
            {permissions &&
                hasPermission({
                    userPermissions: permissions,
                    module: 'sales',
                    permissionList: ['write', '*']
                }) && (
                    <>
                        <div className='lg:hidden w-full'>
                            <Dropdown
                                menu={{ items }}
                                placement='bottomLeft'
                                trigger={['click']}
                            >
                                <SuitUIButton
                                    text='Add Contracts'
                                    invert
                                    fullWidth
                                    iconLeft={<PlusIcon color='#0088B2' />}
                                    style='border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                                />
                            </Dropdown>
                        </div>
                        <div className='hidden lg:flex w-max'>
                            <Divider orientation='vertical' flexItem />
                            <Dropdown
                                menu={{ items }}
                                placement='bottomLeft'
                                trigger={['click']}
                                className='hidden'
                            >
                                <Tooltip title='Add Contract' arrow>
                                    <div className='tab-buttons'>
                                        {add}
                                        <p style={{ paddingLeft: '5px' }}>
                                            Add Contract
                                        </p>
                                    </div>
                                </Tooltip>
                            </Dropdown>
                        </div>
                    </>
                )}

            <CustomModal
                maxWidth='xl'
                open={visible}
                onClose={closeVisibleModal}
                title={`Upload Document`}
                subTitle={`Please upload Sales Contract document.`}
                styles={{
                    overflowX: 'hidden'
                }}
            >
                <div className='mt-4 max-h-[80vh] overflow-y-scroll lg:max-h-max '>
                    <SalesContractUpload
                        payload={payloadSales}
                        errors={errorsSales}
                        {...{
                            commodityFields,
                            signatureUrlBase64,
                            signatureUrlBase64Exporter,
                            setSignatureUrlBase64,
                            setSignatureUrlBase64Exporter,
                            updateCommodityField,
                            removeCommodityField,
                            addCommodityField,
                            updateField,
                            setShowAddPartner
                        }}
                    />

                    <div className='px-4 lg:px-0 mb-6 lg:mb-0'>
                        <Custombutton
                            onClickAction={generalSubmit}
                            isLoadingButton
                            loading={loading}
                            type='submit'
                            fullWidth
                            variant='contained'
                            styles={{
                                padding: '0.75rem 0.62rem 0.9rem 0.93rem',
                                marginTop: '2rem'
                            }}
                            disabled={
                                Object.values(payloadSales).some(
                                    (item: any) => item === undefined
                                ) || loading
                            }
                            buttonText='Submit'
                        />
                    </div>
                </div>
                {showAddPartner && (
                    <Addpartnerdrawer
                        // corporateId={user?.corporate_id}
                        closeDrawer={closeShowAddParty}
                        showDrawer={showAddPartner}
                    />
                )}
            </CustomModal>
        </>
    );
};

export default CreateContractMobile;
