import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Authouterpage from '../components/authouterpage/authouterpage';
import { TextField, Alert } from '@mui/material';
import { getErrorMessage, openNotification } from '../utils/helper';
import { useTheme } from '@mui/material/styles';
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { mutateConfig } from '../api/queryBase';
import Custombutton from '../components/custombutton/custombutton';
import { AxiosError } from 'axios';

interface ForgotPasswordValues {
    email: string;
    resetUrl: string;
}

const ForgotPassword = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const initialValues: ForgotPasswordValues = {
        email: '',
        resetUrl: `${window.location.href.split('/p')[0]}/password/reset/`
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required')
    });

    const mutation = mutateConfig();
    const { mutate } = mutation({
        url: `/password/reset`
    });

    const handleSubmit = async (
        values: ForgotPasswordValues,
        {
            setSubmitting,
            setErrors,
            resetForm
        }: FormikHelpers<ForgotPasswordValues>
    ) => {
        mutate(values, {
            onSettled: (_, error: AxiosError | undefined | any) => {
                setSubmitting(true);
                setSuccessMessage(null);

                if (error) {
                    setSubmitting(false);
                    if (error.response?.data) {
                        const errors = error.response.data.error;
                        setErrors(errors);
                    } else {
                        openNotification(
                            'error',
                            getErrorMessage(error) ||
                                'Forgot password, Something went wrong'
                        );
                    }
                    return;
                }

                setSubmitting(false);
                setSuccessMessage(
                    'Forgot password, Check your email for the reset link.'
                );
                resetForm();
            }
        });
    };

    return (
        <div>
            <Authouterpage
                title='FORGOT PASSWORD'
                info='Forgot your FrontEdge password?'
            >
                <div>
                    <div className='bg-appcolorwhite py-[2.7rem] lg:py-[3.5rem] lg:px-[2.6rem] px-[1.6rem] mb-[1.75rem] rounded-[3px]'>
                        {successMessage && (
                            <Alert
                                icon={
                                    <img
                                        src='/img/vectors/infosquare.svg'
                                        alt='infosquare'
                                    />
                                }
                                severity='info'
                                sx={{
                                    background: 'rgba(0, 136, 178, 0.06)',
                                    borderRadius: '5px',
                                    border: '1px solid #0088B2',
                                    marginBottom: '1rem',
                                    color: theme.palette.primary.main
                                }}
                            >
                                <div className='flex items-center justify-between w-full'>
                                    <span>{successMessage}</span>
                                </div>
                            </Alert>
                        )}
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({ isSubmitting, errors, isValid }) => (
                                <Form autoComplete='off'>
                                    <div>
                                        <Field
                                            as={TextField}
                                            error={!!errors.email}
                                            helperText={
                                                <ErrorMessage name='email' />
                                            }
                                            fullWidth
                                            name='email'
                                            type='email'
                                            label='Email'
                                        />
                                    </div>

                                    <div className='mt-4'>
                                        <Custombutton
                                            isLoadingButton
                                            loading={isSubmitting}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            styles={{
                                                padding:
                                                    '0.75rem 0.62rem 1rem 0.93rem'
                                            }}
                                            disabled={!isValid || isSubmitting}
                                            buttonText=' Reset Password'
                                        />
                                    </div>
                                </Form>
                            )}
                        </Formik>

                        <p className='mt-4 text-sm text-appcolorfaint leading-[18px]'>
                            Remember Password?{' '}
                            <span
                                className='underline cursor-pointer text-appcolorblack'
                                onClick={() => {
                                    navigate('/login');
                                }}
                            >
                                Log in
                            </span>
                        </p>
                    </div>
                </div>
            </Authouterpage>
        </div>
    );
};

export default ForgotPassword;
