import { ArrowDown } from '../../../../components/icons/ArrowDown';
import { ArrowUp } from '../../../../components/icons/ArrowUp';

interface ShowHideSectionProps {
    show: boolean;
    title: string;
    onHandleClick: () => void;
}

export const ShowHideSection = ({
    show,
    title,
    onHandleClick
}: ShowHideSectionProps) => {
    return (
        <div className='flex mt-4 text-[16px] leading-[24px] text-appcolorprimary font-semibold lg:font-normal'>
            <div className='pt-[2px]'>
                {show ? <ArrowUp /> : <ArrowDown />}{' '}
            </div>
            <span
                onClick={() => onHandleClick()}
                className='ml-2 cursor-pointer'
            >
                {title}
            </span>
        </div>
    );
};
