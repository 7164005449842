import { Box } from '@mui/material';
import cma from './brands/cma.png';
import mearsk from './brands/maersk.png';
import msc from './brands/msc.png';
import one from './brands/one.png';
import zim from './brands/zim.png';
import { TruckIcon } from '../../../components/icons/TruckIcon';
import { useNavigate } from 'react-router-dom';

const contents = [
    {
        title: 'Pay in any currency of choice and receive your cargo in hours, not days',
        content:
            'With FrontEdge, your payment is instantly sent to your logistics provider and your cargo is released on the same day, sometimes within a few hours'
    },
    {
        title: 'Ship now and pay us later',
        content:
            'We pay your logistics provider on your behalf, allowing you to manage your cash flow'
    },
    {
        title: 'Pay all your logistics providers in one place',
        content:
            'You can now pay all of your providers instantly from one platform without having to worry about managing various provider payment details'
    },
    {
        title: 'Reduce time spent reconciling your freight payments',
        content:
            'Using our integrations with carriers, you no longer have to spend days reconciling invoices – we automate the entire process for you'
    }
];

export const LogisticsInvoiceHome = () => {
    const navigate = useNavigate();

    return (
        <Box className='space-y-5 px-[1rem] lg:px-0'>
            <Box className='pb-5 border-b'>
                All-in-one logistics payment solution for faster cargo release
            </Box>
            <Box className='lg:space-x-6 rounded-sm justify-start'>
                {[cma, mearsk, msc, one, zim].map((icon, index) => (
                    <Box
                        key={index}
                        className='lg:w-[125px] w-[30%] h-[70px] justify-start inline-block rounded-sm'
                    >
                        <img
                            src={icon}
                            className='relative top-[50%] translate-y-[-50%] mx-auto'
                        />
                    </Box>
                ))}
            </Box>
            <Box className='grid lg:grid-cols-[1fr,2.5fr] gap-7 '>
                {contents.map(({ title, content }, index) => (
                    <Box
                        key={index}
                        className='flex space-x-5 lg:w-[356px] w-full bg-white p-5 rounded-lg'
                    >
                        <Box className='pt-1'>
                            <TruckIcon />
                        </Box>
                        <Box className='flex flex-col justify-between space-y-3'>
                            <Box className='font-semibold'>{title}</Box>
                            <Box className='leading-7 opacity-70 text-[15px]'>
                                {content}
                            </Box>
                            <Box
                                className='text-appcolorprimary cursor-pointer'
                                onClick={() => {
                                    navigate('/payments/logistics/list');
                                }}
                            >
                                Get Started
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};
