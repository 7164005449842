export const ArrowUp = () => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M16.6 12.5415L11.1667 7.1082C10.525 6.46654 9.47503 6.46654 8.83336 7.1082L3.40002 12.5415'
                stroke='#0088B2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
