import React, { Fragment } from 'react';
import { useCorporateOffshoreDetails } from '../../../../customhooks/useCorporateOffshoreDetails';
import { PlainFileIcon } from '../../../../components/icons/PlainFileIcon';
import { Grid, Skeleton, Stack } from '@mui/material';
import { OffshoreAccount } from './interfaces/OffshoreAccount';

const RequestAccountDetails = ({ id }: { id?: string }) => {
    const {
        isLoading,
        entries: accountDetails,
        isFetching
    } = useCorporateOffshoreDetails(id ?? '');

    const filteredAccounts:
        | OffshoreAccount[]
        | undefined = accountDetails?.filter(
        (account) => account.currencyCode !== 'NGN'
    );

    return (
        <>
            {isLoading || isFetching ? (
                <div className=''>
                    <Skeleton variant='text' sx={{ fontSize: '25rem' }} />
                </div>
            ) : (
                <div className='my-[20px]'>
                    <div className='flex gap-[5px] items-center py-[10px] px-[14px] leading-[22px] text-[15px] bg-white w-max'>
                        <PlainFileIcon />
                        Account Details{' '}
                    </div>

                    <div className='pt-[30px] pb-[20px] px-[20px] bg-white rounded-[5px] rounded-tl-[0]'>
                        <Grid container spacing={2} rowGap={2}>
                            {filteredAccounts?.map((account) => (
                                <Fragment key={`account-${account.id}`}>
                                    {account.accountMode === 'multi' && (
                                        <Grid item xs={12} md={6} lg={4}>
                                            <div className=' rounded-[5px] border-[#f2f4f7] border-2 p-[15px] '>
                                                <h3 className='font-bold text-[14px] mb-[.5rem]'>
                                                    {account.currencyCode}{' '}
                                                    Account (Local)
                                                </h3>
                                                <Stack
                                                    direction='row'
                                                    justifyContent='space-between'
                                                    mb='.5rem'
                                                >
                                                    <p className=' text-[14px]'>
                                                        Bank Name
                                                    </p>
                                                    <p className=' text-[14px]'>
                                                        {account.localBankName}
                                                    </p>
                                                </Stack>
                                                <Stack
                                                    direction='row'
                                                    justifyContent='space-between'
                                                    mb='.5rem'
                                                >
                                                    <p className=' text-[14px] min-w-[30%]'>
                                                        Bank Address
                                                    </p>
                                                    <p className=' text-[14px] text-right'>
                                                        {
                                                            account.localBankAddress
                                                        }
                                                    </p>
                                                </Stack>

                                                <Stack
                                                    direction='row'
                                                    justifyContent='space-between'
                                                    mb='.5rem'
                                                >
                                                    <p className=' text-[14px]'>
                                                        Account Name
                                                    </p>
                                                    <p className=' text-[14px] text-right'>
                                                        {
                                                            account.localAccountName
                                                        }
                                                    </p>
                                                </Stack>

                                                <Stack
                                                    direction='row'
                                                    justifyContent='space-between'
                                                    mb='.5rem'
                                                >
                                                    <p className=' text-[14px]'>
                                                        Sort Code
                                                    </p>
                                                    <p className=' text-[14px]'>
                                                        {account.localSortCode}
                                                    </p>
                                                </Stack>
                                                <Stack
                                                    direction='row'
                                                    justifyContent='space-between'
                                                    mb='.5rem'
                                                >
                                                    <p className=' text-[14px]'>
                                                        Account Number
                                                    </p>
                                                    <p className=' text-[14px]'>
                                                        {
                                                            account.localAccountNumber
                                                        }
                                                    </p>
                                                </Stack>
                                            </div>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} md={6} lg={4}>
                                        <div className=' rounded-[5px] border-[#f2f4f7] border-2 p-[15px] '>
                                            <h3 className='font-bold text-[14px] mb-[.5rem]'>
                                                {account.currencyCode} Account
                                                (Global)
                                            </h3>
                                            <Stack
                                                direction='row'
                                                justifyContent='space-between'
                                                mb='.5rem'
                                            >
                                                <p className=' text-[14px] min-w-[30%]'>
                                                    Bank Name
                                                </p>
                                                <p className=' text-[14px]'>
                                                    {account.globalBankName}
                                                </p>
                                            </Stack>
                                            <Stack
                                                direction='row'
                                                justifyContent='space-between'
                                                mb='.5rem'
                                            >
                                                <p className=' text-[14px] min-w-[30%]'>
                                                    Bank Address
                                                </p>
                                                <p className=' text-[14px] text-right'>
                                                    {account.globalBankAddress}
                                                </p>
                                            </Stack>

                                            <Stack
                                                direction='row'
                                                justifyContent='space-between'
                                                mb='.5rem'
                                            >
                                                <p className=' text-[14px] min-w-[30%] '>
                                                    Account Name
                                                </p>
                                                <p className=' text-[14px] text-right'>
                                                    {account.globalAccountName}
                                                </p>
                                            </Stack>

                                            <Stack
                                                direction='row'
                                                justifyContent='space-between'
                                                mb='.5rem'
                                            >
                                                <p className=' text-[14px] min-w-[30%]'>
                                                    Swift Code
                                                </p>
                                                <p className=' text-[14px]'>
                                                    {account.globalSwiftCode}
                                                </p>
                                            </Stack>
                                            <Stack
                                                direction='row'
                                                justifyContent='space-between'
                                                mb='.5rem'
                                            >
                                                <p className=' text-[14px]  min-w-[30%]'>
                                                    Account Number
                                                </p>
                                                <p className=' text-[14px]'>
                                                    {
                                                        account.globalAccountNumber
                                                    }
                                                </p>
                                            </Stack>
                                        </div>
                                    </Grid>
                                </Fragment>
                            ))}
                        </Grid>
                    </div>
                </div>
            )}
        </>
    );
};

export default RequestAccountDetails;
