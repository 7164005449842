import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCorporatePartners from '../../../../customhooks/useCorporatePartners';
import { nanoid } from '@reduxjs/toolkit';
import { openNotification } from '../../../../utils/helper';
import {
    createPurchaseOrder,
    getSingleDocuments
} from '../../../../api/documents/documents';
import FormHeader from '../../../../components/dashboard/tradeDocuments/common/FormHeader';
import {
    TradeContainer,
    TradeContent
} from '../../../../styles/TradeDocStyles';
import Addpartnerdrawer from '../addpartnerdrawer/addpartnerdrawer';
import CustomModal from '../../../../components/CustomModal';
import Savetradedocument from '../savetradedocument/savetradedocument';
import PurchaseForms from '../../../../components/dashboard/tradeDocuments/formComponents/PurchaseForms';
import useCurrencies from '../../../../customhooks/useCurrencies';
import QuotationPreview from '../../../../components/dashboard/tradeDocuments/previewComponents/QuotationPreview';

const PurchaseOrderNew = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [params, setParams] = useState<any>();
    const [_, setLoadingData] = useState(false);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const param: any = Object.fromEntries(searchParams.entries());
        setParams(param);
    }, [location.search]);

    useEffect(() => {
        if (params?.edit) {
            fetchDocuments();
        }
    }, [params]);

    const fetchDocuments = async () => {
        setLoadingData(true);
        try {
            const res = await getSingleDocuments({
                corporateDocumentId: params?.edit
            });

            const { data } = res.data;
            setData(data);

            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            if (error?.response) {
                openNotification('error', `Documents, Something went wrong`);
            } else {
                openNotification('error', `Documents, Something went wrong`);
            }
        }
    };

    const { corporatePartners, isLoading } = useCorporatePartners({ page: 1 });
    const pageTopRef = useRef<any>(null);
    const myDiv = useRef<HTMLDivElement | null>(null);

    const [showPreview, setShowPreview] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [signatureUrlBase64, setSignatureUrlBase64] = useState('');
    const [saveOnly, setSaveOnly] = useState(true);
    const [fileId, setFileId] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | any>(null);
    const [payload, setPayload] = useState<any>({
        sellerId: undefined,
        purchaseOrderNumber: nanoid(9),
        documentDate: Date.now(),
        issuedDate: Date.now(),
        receiverId: undefined,
        supplierReference: undefined,
        shipperReference: undefined,
        methodOfDispatch: undefined,
        typeOfShipment: undefined,
        portOfLoadingId: undefined,
        portOfLoadingValue: undefined,
        portOfDischargeId: undefined,
        portOfDischargeValue: undefined,
        placeOfIssue: undefined,
        signatoryCompany: undefined,
        nameOfAuthorisedSignatory: undefined,
        signatureUrl: undefined,
        documentName: '',
        logoUrl: '',
        sellerAddress: '',
        receiverAddress: '',
        sellerBank: '',
        sellerBankAccount: '',
        sellerSwiftCode: '',
        sellerPlace: '',
        receiverPlace: '',
        receiverBank: '',
        receiverBankAccount: '',
        receiverSwiftCode: '',
        additionalInformation: '',
        salesContractNumber: '',
        billOfLadingNumber: '',
        currencyCode: '',
        currencyCodeValue: ''
    });

    useEffect(() => {
        if (data) {
            setFileId(data?.id);
            setPayload({
                sellerId: data?.documentData?.sellerId,
                purchaseOrderNumber:
                    data?.documentData?.purchaseOrderNumber || nanoid(9),
                documentDate: data?.documentData?.documentDate || Date.now(),
                issuedDate: data?.documentData?.issuedDate || Date.now(),
                receiverId: data?.documentData?.receiverId || '',
                receiverName: data?.documentData?.receiver.name || '',
                supplierReference: data?.documentData?.supplierReference || '',
                shipperReference: data?.documentData?.shipperReference || '',
                methodOfDispatch: data?.documentData?.methodOfDispatch || '',
                typeOfShipment: data?.documentData?.typeOfShipment || '',
                portOfLoadingId: data?.documentData?.portOfLoading.name || '',
                portOfLoadingValue: data?.documentData?.portOfLoading || '',
                portOfDischargeId:
                    data?.documentData?.portOfDischarge.name || '',
                portOfDischargeValue: data?.documentData?.portOfDischarge || '',
                placeOfIssue: data?.documentData?.placeOfIssue || '',
                signatoryCompany: data?.documentData?.signatoryCompany || '',
                nameOfAuthorisedSignatory:
                    data?.documentData?.nameOfAuthorisedSignatory || '',
                signatureUrl: data?.documentData?.signatureUrl || '',
                documentName: data?.documentName || '',
                logoUrl: data?.documentData?.logoUrl || '',
                sellerAddress: data?.documentData?.seller?.address || '',
                sellerName: data?.documentData?.seller?.name || '',
                receiverAddress: data?.documentData?.receiver?.address || '',
                sellerBank: '',
                sellerBankAccount: '',
                sellerSwiftCode: '',
                sellerPlace: '',
                receiverPlace: '',
                receiverBank: '',
                receiverBankAccount: '',
                receiverSwiftCode: '',
                additionalInformation:
                    data?.documentData?.additionalInformation || '',
                currencyCode: data?.documentData?.currencyCode || '',
                salesContractNumber:
                    data?.documentData?.salesContractNumber || '',
                billOfLadingNumber:
                    data?.documentData?.billOfLadingNumber || '',
                currencyCodeValue: data?.documentData?.currencyCode || ''
            });

            const transformArray = (array: any) => {
                const newArray: any = [];

                array.forEach((val: any) => {
                    newArray.push({
                        id: val.id,
                        productCode: val.productCode,
                        desc: val.descriptionOfGoods,
                        qty: val.quantity,
                        type: val.unitType,
                        price: val.unitPrice
                    });
                });
                return newArray;
            };
            setQuoteFields(transformArray(data?.documentData?.items));
        }
    }, [data]);

    const [errors, setErrors] = useState({
        sellerId: '',
        purchaseOrderNumber: '',
        documentDate: '',
        issuedDate: '',
        receiverId: '',
        supplierReference: '',
        shipperReference: '',
        methodOfDispatch: '',
        typeOfShipment: '',
        portOfLoadingId: '',
        portOfLoadingValue: '',
        portOfDischargeId: '',
        portOfDischargeValue: '',
        placeOfIssue: '',
        signatoryCompany: '',
        nameOfAuthorisedSignatory: '',
        signatureUrl: '',
        documentName: '',
        logoUrl: '',
        salesContractNumber: '',
        billOfLadingNumber: '',
        currencyCode: ''
    });
    const [totalAmount, setTotalAmount] = useState(0);
    const [quoteFields, setQuoteFields] = useState([
        {
            id: nanoid(9),
            productCode: nanoid(9),
            desc: undefined,
            qty: 0,
            type: undefined,
            price: 0
        }
    ]);
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showAddPartner, setShowAddPartner] = useState(false);

    const closeShowAddParty = () => {
        setShowAddPartner(false);
    };

    /**
     * Close save document
     */
    const closeSaveModal = () => {
        setShowSaveModal(false);
    };

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    /**
     * Update quotes input fields
     * @param id string
     * @param name string
     * @param value any
     */
    const updateQuoteField = (
        index: number,
        attributeName: string,
        attributeValue?: any
    ) => {
        const newContainers: any[] = [...quoteFields];
        newContainers[index][attributeName] = attributeValue;
        setQuoteFields(newContainers);
    };

    useEffect(() => {
        let totalNumber = 0;
        quoteFields.forEach((item) => {
            totalNumber +=
                (isNaN(item.price) ? 0 : Number(item.price)) *
                (isNaN(item.qty) ? 0 : Number(item.qty));
        });
        setTotalAmount(totalNumber);
    }, [quoteFields]);

    /**
     * Adds quotes input field line
     */
    const addQuoteField = () => {
        setQuoteFields([
            ...quoteFields,
            {
                id: nanoid(9),
                productCode: nanoid(9),
                desc: undefined,
                qty: 0,
                type: undefined,
                price: 0
            }
        ]);
    };

    /**
     * Remove quotes input field line
     */
    const removeQuoteField = (index: number) => {
        setQuoteFields(quoteFields.filter((item, i) => i !== index));
    };

    const onSubmit = async () => {
        setLoading(true);
        setError(null);

        setErrors((prevState: any) => ({
            ...prevState,
            sellerId: '',
            purchaseOrderNumber: '',
            documentDate: '',
            issuedDate: '',
            receiverId: '',
            supplierReference: '',
            shipperReference: '',
            methodOfDispatch: '',
            typeOfShipment: '',
            portOfLoadingId: '',
            portOfLoadingValue: '',
            portOfDischargeId: '',
            portOfDischargeValue: '',
            placeOfIssue: '',
            signatoryCompany: '',
            nameOfAuthorisedSignatory: '',
            signatureUrl: '',
            documentName: '',
            logoUrl: '',
            currencyCode: ''
        }));

        try {
            const items: any[] = [];

            const {
                sellerId,
                purchaseOrderNumber,
                documentDate,
                issuedDate,
                receiverId,
                supplierReference,
                shipperReference,
                methodOfDispatch,
                typeOfShipment,
                portOfLoadingId,
                portOfLoadingValue,
                portOfDischargeId,
                portOfDischargeValue,
                placeOfIssue,
                signatoryCompany,
                nameOfAuthorisedSignatory,
                signatureUrl,
                documentName,
                logoUrl,
                additionalInformation,
                currencyCode,

                billOfLadingNumber,
                salesContractNumber
            } = payload;

            const dischargeValue: any = portOfDischargeValue;
            const portValue: any = portOfLoadingValue;

            quoteFields.forEach((item) => {
                items.push({
                    amount: item.price,
                    descriptionOfGoods: item.desc,
                    productCode: item.productCode,
                    quantity: item.qty,
                    unitPrice: item.price,
                    unitType: item.type
                });
            });

            const res = await createPurchaseOrder({
                createdType: 'CREATED',
                documentData: {
                    items,
                    sellerId,
                    purchaseOrderNumber,
                    documentDate,
                    issuedDate,
                    receiverId,
                    supplierReference,
                    shipperReference,
                    methodOfDispatch,
                    typeOfShipment,
                    portOfDischargeId: dischargeValue?.id,
                    portOfLoadingId: portValue?.id,
                    placeOfIssue,
                    signatoryCompany,
                    nameOfAuthorisedSignatory,
                    signatureUrl,
                    logoUrl: logoUrl !== '' ? logoUrl : undefined,
                    totalAmount,
                    salesContractNumber,
                    billOfLadingNumber,
                    currencyCode,
                    additionalInformation
                },
                documentType: 'PURCHASE_ORDER',
                documentName
            });

            const { id } = res.data.data;

            setFileId(id);

            openNotification(
                'success',
                'Purchase Order document created successfully'
            );

            setLoading(false);

            if (saveOnly) {
                navigate('/trade-documents');
            }
        } catch (error) {
            window.scroll({
                top: pageTopRef?.current?.offsetTop,
                left: 0,
                behavior: 'smooth'
            });

            setShowSaveModal(false);
            setLoading(false);
            const otherErrors: any[] = [];

            if (error?.response) {
                if (error?.response?.data?.error) {
                    const resError = error?.response?.data?.error;

                    if (typeof resError === 'string') {
                        const errorList = (
                            <li key={`error-list-${1}`}>{resError}</li>
                        );
                        setError(errorList);
                    } else {
                        Object.entries(resError).forEach(([key, value]) => {
                            if (Object.keys(errors).includes(key)) {
                                setErrors((prevState) => ({
                                    ...prevState,
                                    [key]: value
                                }));
                            } else {
                                otherErrors.push(value);
                            }
                        });

                        if (otherErrors.length > 0) {
                            const errorList = otherErrors.map(
                                (element: any, index) => {
                                    return (
                                        <li key={`error-list-${index}`}>
                                            {element}
                                        </li>
                                    );
                                }
                            );
                            setError(errorList);
                        }
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    const { isLoading: isLoadingCurrencies, currencies } = useCurrencies();

    const breadcrumbs = [
        {
            title: 'Home',
            link: '/',
            active: false
        },
        {
            title: 'Documents',
            link: '/trade-documents',
            active: false
        },
        {
            title: 'Purchase Order',
            link: '/trade-documents/purchase-order',
            active: true
        }
    ];

    return (
        <TradeContainer>
            <FormHeader
                breadcrumbs={breadcrumbs}
                docTitle='Purchase Order'
                {...{
                    showPreview,
                    setShowPreview,
                    myDiv,
                    downloadLoading,
                    setDownloadLoading
                }}
                onSaveOnlyAction={() => {
                    setSaveOnly(true);
                    setShowSaveModal(true);
                }}
                onSaveAction={() => {
                    setSaveOnly(false);
                    setShowSaveModal(true);
                }}
                disabled={
                    Object.values(payload).some(
                        (item: any) => item === undefined
                    ) || loading
                }
            />

            <TradeContent>
                {showPreview ? (
                    <div ref={myDiv}>
                        <QuotationPreview
                            data={payload}
                            docTitle='Purchase Order'
                            quoteFields={quoteFields}
                            totalAmount={totalAmount}
                            signatureUrlBase64={signatureUrlBase64}
                        />
                    </div>
                ) : (
                    <PurchaseForms
                        {...{
                            error,
                            updateField,
                            payload,
                            corporatePartners,
                            loadingCorporatepartners: isLoading,
                            setShowAddPartner,
                            errors,
                            quoteFields,
                            updateQuoteField,
                            removeQuoteField,
                            currencies,
                            addQuoteField,
                            loading,
                            loadingCurrencies: isLoadingCurrencies,
                            setSignatureUrlBase64,
                            signatureUrlBase64,
                            setSaveOnly,
                            setShowSaveModal,
                            totalAmount
                        }}
                    />
                )}

                {showAddPartner ? (
                    <Addpartnerdrawer
                        closeDrawer={closeShowAddParty}
                        showDrawer={showAddPartner}
                    />
                ) : null}

                {showSaveModal && (
                    <CustomModal
                        open={showSaveModal}
                        onClose={closeSaveModal}
                        title='Saving document'
                        maxWidth='xs'
                        styles={{
                            overflowX: 'hidden',
                            '.MuiPaper-root': {
                                width: '100%'
                            }
                        }}
                    >
                        <Savetradedocument
                            updateField={updateField}
                            onSubmit={onSubmit}
                            loading={loading}
                            data={payload}
                        />
                    </CustomModal>
                )}
            </TradeContent>
        </TradeContainer>
    );
};

export default PurchaseOrderNew;
