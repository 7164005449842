export const FileIcon = () => {
    return (
        <svg
            width='36'
            height='36'
            viewBox='0 0 36 36'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect
                x='2'
                y='2'
                width='32'
                height='32'
                rx='16'
                fill='#0088B2'
                fillOpacity='0.1'
            />
            <path
                d='M18.6666 11.3334H14C13.6463 11.3334 13.3072 11.4738 13.0572 11.7239C12.8071 11.9739 12.6666 12.3131 12.6666 12.6667V23.3334C12.6666 23.687 12.8071 24.0261 13.0572 24.2762C13.3072 24.5262 13.6463 24.6667 14 24.6667H22C22.3536 24.6667 22.6927 24.5262 22.9428 24.2762C23.1928 24.0261 23.3333 23.687 23.3333 23.3334V16M18.6666 11.3334L23.3333 16M18.6666 11.3334V16H23.3333'
                stroke='#0088B2'
                strokeWidth='1.33333'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <rect
                x='2'
                y='2'
                width='32'
                height='32'
                rx='16'
                stroke='#0088B2'
                strokeOpacity='0.1'
                strokeWidth='4'
            />
        </svg>
    );
};
