import { LogisticsInvoiceSummary } from './LogisticsInvoiceSummary';
import { LogisticsInvoicesListTable } from './LogisticsInvoicesListTable';
import { Box, Divider } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { LogisticsInvoiceActions } from './LogisticsInvoiceActions';
import { MutableRefObject, useEffect, useState } from 'react';
import {
    ServiceableInvoice,
    ServiceableInvoiceParams
} from './interfaces/Invoice';
import LogisticsInvoiceListFilter from './LogisticsInvoiceListFilter';
import { TabContainerNew } from '../../../styles/TradeDocStyles';
import { ExportTransactions } from './ExportTransactions';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';
import { RefreshTableIcon } from '../../../components/icons/RefreshTableIcon';
import { useInvoices } from '../../../customhooks/useInvoices';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

export const LogisticsInvoiceList = () => {
    const { actionsRef } = useOutletContext() as {
        actionsRef: MutableRefObject<HTMLDivElement>;
    };
    const [portalContent, setPortalContent] = useState<React.ReactNode | null>(
        null
    );

    const [selected, setSelected] = useState<(number | string)[]>([]);
    const [raiseEmpty, setRaiseEmpty] = useState(false);
    const [filterParams, setFilterParams] = useState<ServiceableInvoiceParams>(
        {} as ServiceableInvoiceParams
    );
    const [viewExport, setViewExport] = useState(false);
    const { permissions } = useUserProfile();
    const { user } = useSelector((state: RootState) => state.user);

    const { invalidateLogisticsInvoice: refetchInvoices } =
        useInvoices<ServiceableInvoice>({
            corporateId: user?.corporate_id,
            params: { serviceable: true, size: 10, ...filterParams },
            enabled: false
        });

    useEffect(() => {
        if (actionsRef.current) {
            const portalContent = (
                <LogisticsInvoiceActions
                    selected={selected}
                    setRaiseEmpty={setRaiseEmpty}
                />
            );

            setPortalContent(portalContent);
        }
    }, [actionsRef.current, selected, setRaiseEmpty]);

    return (
        <Box>
            {portalContent &&
                permissions &&
                hasPermission({
                    userPermissions: permissions,
                    module: 'payments',
                    permissionList: ['write', '*']
                }) &&
                createPortal(portalContent, actionsRef.current)}

            <Box className='lg:space-y-5 '>
                <LogisticsInvoiceSummary />
                <div className='lg:hidden px-[1rem]'>
                    <LogisticsInvoiceActions
                        selected={selected}
                        setRaiseEmpty={setRaiseEmpty}
                    />
                </div>
                {permissions &&
                    hasPermission({
                        userPermissions: permissions,
                        module: 'payments',
                        permissionList: ['write', '*', 'read']
                    }) && (
                        <Box className='hidden lg:flex w-[100%] mb-4 justify-end items-center'>
                            <TabContainerNew>
                                <ExportTransactions
                                    params={filterParams}
                                    serviceable
                                    viewExport={viewExport}
                                    setViewExport={setViewExport}
                                />
                                <Divider orientation='vertical' flexItem />

                                <LogisticsInvoiceListFilter
                                    {...{ setFilterParams, filterParams }}
                                />

                                <Divider orientation='vertical' flexItem />

                                <div
                                    className='tab-buttons ml-2'
                                    onClick={() => refetchInvoices()}
                                >
                                    <div className='pr-2'>
                                        <RefreshTableIcon />
                                    </div>
                                    <p style={{ paddingRight: '5px' }}>
                                        Refresh
                                    </p>
                                </div>
                            </TabContainerNew>
                        </Box>
                    )}
            </Box>
            <Box className='lg:mt-5'>
                <LogisticsInvoicesListTable
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    selected={selected}
                    setSelected={setSelected}
                    raiseEmpty={raiseEmpty}
                    setRaiseEmpty={setRaiseEmpty}
                    refetchInvoices={refetchInvoices}
                />
            </Box>
        </Box>
    );
};
