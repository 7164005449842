import React, {
    useState,
    useEffect,
    useRef,
    Dispatch,
    SetStateAction
} from 'react';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../../../../components/CustomModal';
import Documentupload from '../../../../components/documentupload/documentupload';
import {
    Alert,
    Autocomplete,
    CircularProgress,
    Grid,
    MenuItem,
    TextField
} from '@mui/material';
import Custombutton from '../../../../components/custombutton/custombutton';
import {
    createCommercialInvoice,
    createDocumentGeneral,
    createSalesContract
} from '../../../../api/documents/documents';
import {
    openNotification,
    formatDecimalNumber
} from '../../../../utils/helper';
import Customdatepicker from '../../../../components/customdatepicker/customdatepicker';
import Quotefield from '../quotation/quotefield/quotefield';
import { nanoid } from '@reduxjs/toolkit';
import { Dropdown, MenuProps } from 'antd';
import { documentList } from './documentList';
import NewCustomSelect from '../../../../components/newcustomselect/newcustomselect';
import useCurrencies from '../../../../customhooks/useCurrencies';
import SalesContractUpload from './SalesContractUpload';

export interface addtradedocumentProps {
    closeShowAddModal: (refreshList?: boolean) => void;
    corporatePartners: any;
    loadingCorporatepartners: boolean;
    setShowAddPartner: Dispatch<SetStateAction<boolean>>;
}

const DocItem = ({ item, setCurrentDocument, setVisible }: any) => {
    const selectContainer = useRef<any>(null);
    const navigate = useNavigate();

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        setVisible(true);
                    }}
                >
                    <div className='text-appcolorprimary'>
                        <img
                            src='/img/vectors/documentupload.svg'
                            alt='eyesicon'
                            className='h-[0.8rem] w-[0.8rem] text-appcolorprimary'
                        />
                    </div>
                    <span className='ml-2'>Upload</span>
                </div>
            )
        },
        {
            key: '2',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        navigate(item?.route);
                    }}
                >
                    <div>
                        <img
                            src='/img/vectors/documentcreate.svg'
                            alt='eyesicon'
                            className='h-[0.8rem] w-[0.8rem]'
                        />
                    </div>
                    <span className='ml-2'>Create</span>
                </div>
            ),
            style: {
                display: item.isUpload ? 'none' : 'block'
            }
        }
    ];

    return (
        <div className='flex mb-4 rounded-[8px] bg-appcolorwhite border border-appcolorfaintnine items-center py-[0.9rem] px-[1rem] justify-between'>
            <div className='flex items-center'>
                <div className='pr-[1rem]'>
                    <img src='/img/vectors/files.svg' alt='' />
                </div>

                <div>
                    <h1 className='font-medium text-[16px] leading-[18px]'>
                        {item.title}
                    </h1>
                </div>
            </div>

            <div
                ref={selectContainer}
                onClick={() => {
                    setCurrentDocument(item);
                }}
            >
                <Dropdown
                    className='docitem-dropdown'
                    getPopupContainer={() => selectContainer.current}
                    menu={{ items }}
                    trigger={['click']}
                    placement='bottomRight'
                >
                    <img
                        src='/img/vectors/ham.svg'
                        alt=''
                        className='cursor-pointer'
                    />
                </Dropdown>
            </div>
        </div>
    );
};

const Addtradedocument = ({
    closeShowAddModal,
    loadingCorporatepartners,
    corporatePartners,
    setShowAddPartner
}: addtradedocumentProps) => {
    const navigate = useNavigate();

    const { isLoading: isLoadingCurrencies, currencies } = useCurrencies();

    const [totalAmount, setTotalAmount] = useState(0);
    const [quoteFields, setQuoteFields] = useState([
        {
            id: nanoid(9),
            desc: undefined,
            qty: 0,
            type: undefined,
            price: 0
        }
    ]);
    const [error, setError] = useState<string | any>(null);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [currentDocument, setCurrentDocument] = useState<any>(null);
    const [uploadOrCreate, setUploadOrCreate] = useState(false);
    type commodityInterface = {
        id: string;
        commodity: string;
        commodityType: string;
        quality: string;
        quantity: number;
        hsCode: string;
        quantityBuffer: string;
        commodityId: string;
        metric: string;
    };

    const [payload, setPayload] = useState<any>({
        // billOfLadingNumber: undefined,
        documentUrl: undefined,
        documentName: '',
        salesContractNumber: '',
        dueDate: '',
        consigneeName: '',
        billOfLadingNumber: '',
        doctypeName: '',
        consigneeId: ''
    });

    const [errors, setErrors] = useState<any>({
        // billOfLadingNumber: "",
        documentUrl: undefined,
        documentName: '',
        salesContractNumber: '',
        dueDate: '',
        consigneeName: '',
        billOfLadingNumber: '',
        doctypeName: '',
        consigneeId: ''
    });

    const [payloadCom, setPayloadCom] = useState<any>({
        // billOfLadingNumber: undefined,
        documentUrl: undefined,
        documentName: undefined,
        salesContractNumber: '',
        dueDate: undefined,
        consigneeName: '',
        billOfLadingNumber: undefined,
        invoiceNumber: nanoid(9),
        consigneeId: undefined
    });

    const [payloadSales, setPayloadSales] = useState<any>({
        documentName: '',
        contractNumber: nanoid(9),
        contractType: '',
        commodities: [],
        shippingDeadline: Date.now(),
        containerType: '',
        portOfDischargeIds: [],
        portOfDischargeValue: undefined,
        portOfLoadingValue: undefined,
        portOfLoadingId: undefined,
        shippingLineIds: [],
        customerId: '',
        paymentTerms: '',
        shippingLineId: '',
        buyerAddress: '',
        exporterId: '',
        exporterAddress: '',
        additionalInfo: ''
    });

    const [errorsSales, setErrorsSales] = useState({
        clientId: '',
        contractNumber: '',
        paymentTerms: '',
        contractType: '',
        commodity: '',
        commodityArr: '',
        commodityId: '',
        shippingDeadline: '',
        quantity: '',
        quantityUnit: '',
        containerType: '',
        contractStatus: '',
        portOfDischargeId: '',
        portOfDischargeValue: '',
        logoUrl: '',
        salesContractType: '',
        shippingLineId: '',
        location: '',
        buyerAddress: '',
        exporterId: '',
        exporterAddress: '',
        additionalInfo: ''
    });

    const [errorsCom, setErrorsCom] = useState<any>({
        // billOfLadingNumber: "",
        documentUrl: '',
        documentName: '',
        salesContractNumber: '',
        dueDate: '',
        consigneeName: '',
        billOfLadingNumber: '',
        invoiceNumber: '',
        consigneeId: ''
    });

    const closeVisible = () => {
        setVisible(false);
        setCurrentDocument(null);
    };

    const closeUploadOrCreate = () => {
        setUploadOrCreate(false);
    };

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrors((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));
        setPayloadCom((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrorsCom((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));

        setPayloadSales((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrorsSales((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));
    };

    const generalSubmit = async () => {
        try {
            setLoading(true);
            setError(null);

            setErrors((prevState: any) => ({
                ...prevState,
                documentUrl: '',
                documentName: '',
                salesContractNumber: ''
            }));

            const { dueDate, billOfLadingNumber, consigneeName, ...rest } =
                payloadCom;

            if (currentDocument?.documentType === 'COMMERCIAL_INVOICE') {
                const items: any[] = [];

                quoteFields.forEach((item) => {
                    items.push({
                        amount: item.price,
                        descriptionOfGoods: item.desc,
                        productCode: '',
                        quantity: item.qty,
                        unitPrice: item.price,
                        unitType: item.type
                    });
                });

                await createCommercialInvoice({
                    createdType: 'UPLOADED',
                    documentData: {
                        items,
                        consigneeName,
                        billOfLadingNumber,
                        totalAmount,
                        dueDate,
                        salesContractNumber: rest.salesContractNumber,
                        invoiceNumber: rest.invoiceNumber,
                        consigneeId: rest.consigneeId,
                        currencyCode: rest.currencyCode
                    },
                    documentType: 'COMMERCIAL_INVOICE',
                    documentName: rest.documentName,
                    documentUrl: rest.documentUrl
                });
            } else if (currentDocument?.documentType === 'SALES_CONTRACT') {
                const items: any[] = [];

                const {
                    contractNumber,
                    contractType,
                    shippingDeadline,
                    containerType,
                    portOfDischargeIds,
                    portOfDischargeValue,
                    portOfLoadingValue,
                    portOfLoadingId,
                    shippingLineIds,
                    customerId,
                    paymentTerms,
                    documentName,
                    shippingLineId,
                    stamp,
                    additionalInfo,
                    buyerAddress,
                    buyerSignature,
                    exporterAddress,
                    exporterId,
                    exporterSignature,
                    documentUrl
                } = payloadSales;

                const dischargeValue: any = portOfDischargeValue;
                const portOfLoading: any = portOfLoadingValue;

                commodityFields.forEach((item) => {
                    items.push({
                        commodityId: item.commodityId,
                        quantity: item.quantity,
                        quantityBuffer: item.quantityBuffer,
                        metric: item.metric
                    });
                });

                const data = {
                    createdType: 'UPLOADED',
                    documentData: {
                        contractType,
                        buyerId: customerId,
                        salesContractNumber: contractNumber,
                        commodities: commodityFields,
                        shippingDeadline,
                        portOfLoadingId: portOfLoading?.id,
                        containerType,
                        paymentTerms,
                        portOfDischargeIds: [dischargeValue?.id],
                        shippingLineIds: [shippingLineId],
                        additionalInfo,
                        exporterId,
                        exporterAddress,
                        buyerAddress
                    },
                    documentType: 'SALES_CONTRACT',
                    documentName,
                    documentUrl: documentUrl
                };

                await createSalesContract(data);
            } else {
                await createDocumentGeneral({
                    documentType: currentDocument?.documentType,
                    documentTypeName: payload?.doctypeName
                        ? payload.doctypeName
                        : undefined,
                    billOfLadingNumber: payload?.billOfLadingNumber
                        ? payload?.billOfLadingNumber
                        : undefined,
                    documentName: payload?.documentName,
                    documentUrl: payload?.documentUrl,
                    salesContractNumber: payload?.salesContractNumber
                        ? payload?.salesContractNumber
                        : undefined,
                    createdType: 'UPLOADED'
                });
            }

            openNotification('success', 'Document created successfully');

            closeVisible();

            closeShowAddModal(true);
        } catch (error) {
            setLoading(false);
            if (error?.response) {
                if (error?.response?.data?.error) {
                    let hasFieldNameAttribute = false;

                    error?.response?.data?.error.map((item: any) => {
                        return Object.keys(item).map((itemInside: string) => {
                            if (itemInside === 'fieldName') {
                                hasFieldNameAttribute = true;

                                return;
                            }
                        });
                    });

                    if (hasFieldNameAttribute) {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>
                                    {element.messageError}
                                </li>
                            );
                        });
                        setError(errorList);
                    } else {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>{element}</li>
                            );
                        });
                        setError(errorList);
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    /**
     * Update quotes input fields
     * @param id string
     * @param name string
     * @param value any
     */
    const updateQuoteField = (
        index: number,
        attributeName: string,
        attributeValue?: any
    ) => {
        const newContainers: any[] = [...quoteFields];
        newContainers[index][attributeName] = attributeValue;
        setQuoteFields(newContainers);
    };

    useEffect(() => {
        let totalNumber = 0;
        quoteFields.forEach((item) => {
            totalNumber +=
                (isNaN(item.price) ? 0 : Number(item.price)) *
                (isNaN(item.qty) ? 0 : Number(item.qty));
        });
        setTotalAmount(totalNumber);
    }, [quoteFields]);

    /**
     * Adds quotes input field line
     */
    const addQuoteField = () => {
        setQuoteFields([
            ...quoteFields,
            {
                id: nanoid(9),
                desc: undefined,
                qty: 0,
                type: undefined,
                price: 0
            }
        ]);
    };

    /**
     * Remove quotes input field line
     */
    const removeQuoteField = (index: number) => {
        setQuoteFields(quoteFields.filter((item, i) => i !== index));
    };
    const [signatureUrlBase64, setSignatureUrlBase64] = useState('');
    const [signatureUrlBase64Exporter, setSignatureUrlBase64Exporter] =
        useState('');
    const [commodityFields, setCommodityFields] = useState<
        commodityInterface[]
    >([
        {
            id: nanoid(9),
            commodityId: '',
            commodity: '',
            commodityType: '',
            quality: '',
            quantity: 0,
            hsCode: '',
            quantityBuffer: '',
            metric: ''
        }
    ]);

    /**
     * Update quotes input fields
     * @param id string
     * @param name string
     * @param value any
     */
    const updateCommodityField = (
        index: number,
        attributeName: string,
        attributeValue?: any
    ) => {
        const newContainers: any[] = [...commodityFields];
        newContainers[index][attributeName] = attributeValue;
        setCommodityFields(newContainers);
    };

    /**
     * Adds quotes input field line
     */
    const addCommodityField = () => {
        setCommodityFields([
            ...commodityFields,
            {
                id: nanoid(9),
                commodityId: '',
                commodity: '',
                commodityType: '',
                quality: '',
                quantity: 0,
                hsCode: '',
                quantityBuffer: '',
                metric: ''
            }
        ]);
    };

    /**
     * Remove quotes input field line
     */
    const removeCommodityField = (index: number) => {
        setCommodityFields(commodityFields.filter((item, i) => i !== index));
    };
    return (
        <div className=''>
            <div>
                <Grid
                    container
                    spacing={4}
                    sx={{
                        marginTop: '0'
                    }}
                >
                    {documentList.map((item) => (
                        <Grid
                            item
                            xs={12}
                            md={12}
                            lg={4}
                            key={`new-doc-${item.title}`}
                        >
                            <DocItem
                                item={item}
                                setCurrentDocument={setCurrentDocument}
                                setVisible={setVisible}
                            />
                        </Grid>
                    ))}
                </Grid>
            </div>

            {visible && (
                <CustomModal
                    maxWidth='lg'
                    open={true}
                    onClose={closeVisible}
                    title={`Upload Document`}
                    subTitle={`Please upload ${currentDocument?.title} document.`}
                    styles={{
                        overflowX: 'hidden'
                    }}
                >
                    <div>
                        {error !== null && (
                            <div className='mt-10'>
                                <Alert severity='error'>{error}</Alert>
                            </div>
                        )}

                        <TextField
                            value={payload?.documentName}
                            error={errors?.documentName.length > 0}
                            helperText={
                                errors?.documentName.length > 0
                                    ? errors?.documentName
                                    : undefined
                            }
                            margin='normal'
                            required
                            fullWidth
                            id='existingbooking-documentName'
                            label='Name Tag'
                            name='documentName'
                            onChange={(e: any) => {
                                updateField('documentName', e.target.value);
                            }}
                        />
                        {currentDocument?.documentType === 'SALES_CONTRACT' ? (
                            <SalesContractUpload
                                payload={payloadSales}
                                errors={errorsSales}
                                {...{
                                    commodityFields,
                                    signatureUrlBase64,
                                    signatureUrlBase64Exporter,
                                    setSignatureUrlBase64,
                                    setSignatureUrlBase64Exporter,
                                    updateCommodityField,
                                    removeCommodityField,
                                    addCommodityField,
                                    updateField,
                                    setShowAddPartner
                                }}
                            />
                        ) : (
                            <>
                                {currentDocument?.documentType ===
                                    'OTHER_DOCUMENTS' && (
                                    <TextField
                                        value={payload?.doctypeName}
                                        error={errors?.doctypeName.length > 0}
                                        helperText={
                                            errors?.doctypeName.length > 0
                                                ? errors?.doctypeName
                                                : undefined
                                        }
                                        margin='normal'
                                        fullWidth
                                        id='existingbooking-doctypeName'
                                        label={'Document Type (Optional)'}
                                        name='doctypeName'
                                        onChange={(e: any) => {
                                            updateField(
                                                'doctypeName',
                                                e.target.value
                                            );
                                        }}
                                    />
                                )}
                                <TextField
                                    value={payload?.salesContractNumber}
                                    error={
                                        errors?.salesContractNumber.length > 0
                                    }
                                    helperText={
                                        errors?.salesContractNumber.length > 0
                                            ? errors?.salesContractNumber
                                            : undefined
                                    }
                                    margin='normal'
                                    // required
                                    fullWidth
                                    id='existingbooking-salesContractNumber'
                                    label={
                                        currentDocument?.documentType ===
                                        'SALES_CONTRACT'
                                            ? 'Sales Contract Number'
                                            : 'Sales Contract Number (Optional)'
                                    }
                                    name='salesContractNumber'
                                    onChange={(e: any) => {
                                        updateField(
                                            'salesContractNumber',
                                            e.target.value
                                        );
                                    }}
                                />

                                {currentDocument?.documentType !==
                                    'SALES_CONTRACT' && (
                                    <TextField
                                        value={payload?.billOfLadingNumber}
                                        error={
                                            errors?.billOfLadingNumber.length >
                                            0
                                        }
                                        helperText={
                                            errors?.billOfLadingNumber.length >
                                            0
                                                ? errors?.billOfLadingNumber
                                                : undefined
                                        }
                                        margin='normal'
                                        // required
                                        fullWidth
                                        id='existingbooking-salesContractNumber'
                                        label='BL Number (Optional)'
                                        name='billOfLadingNumber'
                                        onChange={(e: any) => {
                                            updateField(
                                                'billOfLadingNumber',
                                                e.target.value
                                            );
                                        }}
                                    />
                                )}
                                <div className='mt-2' style={{ width: '100%' }}>
                                    <Documentupload
                                        useAuthorization
                                        returnURLOnly
                                        title={'Document'}
                                        afterUploadUpdate={(
                                            itemId: number,
                                            documentUrl: string
                                        ) => {
                                            updateField(
                                                'documentUrl',
                                                documentUrl
                                            );
                                        }}
                                        full={true}
                                    />
                                </div>

                                {currentDocument?.documentType ===
                                'COMMERCIAL_INVOICE' ? (
                                    <div>
                                        <Grid
                                            container
                                            spacing={1}
                                            sx={{
                                                marginTop: 0,
                                                marginBottom: '1rem'
                                            }}
                                        >
                                            <Grid item xs={12} md={12} lg={4}>
                                                <div className='mt-4'>
                                                    <Customdatepicker
                                                        value={
                                                            payload?.dueDate !==
                                                            undefined
                                                                ? payload?.dueDate
                                                                : null
                                                        }
                                                        id='proformainvoice-date'
                                                        label='Due Date'
                                                        name='dueDate'
                                                        required
                                                        updateField={
                                                            updateField
                                                        }
                                                        error={
                                                            errors?.dueDate
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors?.dueDate
                                                                .length > 0
                                                                ? errors?.dueDate
                                                                : undefined
                                                        }
                                                    />
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={4}>
                                                <div className='mt-4'>
                                                    <NewCustomSelect
                                                        hasError={
                                                            errors?.consigneeId
                                                                .length > 0
                                                        }
                                                        helperText={
                                                            errors?.consigneeId
                                                                .length > 0
                                                                ? errors?.consigneeId
                                                                : undefined
                                                        }
                                                        selectValue={
                                                            payload.consigneeId
                                                        }
                                                        required
                                                        selectOptions={
                                                            corporatePartners
                                                        }
                                                        name={'consigneeId'}
                                                        updateField={(
                                                            _,
                                                            value
                                                        ) => {
                                                            updateField(
                                                                'consigneeId',
                                                                value
                                                            );
                                                            updateField(
                                                                'consigneeName',
                                                                corporatePartners.filter(
                                                                    (
                                                                        item: any
                                                                    ) =>
                                                                        item?.id ===
                                                                        value
                                                                )[0]?.name
                                                            );
                                                        }}
                                                        label={'Customer'}
                                                        selectOptionLabel='name'
                                                        loading={
                                                            loadingCorporatepartners
                                                        }
                                                        dropdownRender={() => {
                                                            return (
                                                                <div className='px-4 border-t border-appcolorgreyfaint'>
                                                                    <p
                                                                        className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                                        onClick={() => {
                                                                            setShowAddPartner(
                                                                                true
                                                                            );
                                                                        }}
                                                                    >
                                                                        + Add a
                                                                        New
                                                                        Partner
                                                                    </p>
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </Grid>

                                            <Grid item xs={12} md={12} lg={4}>
                                                <TextField
                                                    error={
                                                        errors?.invoiceNumber
                                                            ?.length > 0
                                                    }
                                                    helperText={
                                                        errors?.invoiceNumber
                                                            ?.length > 0
                                                            ? errors?.invoiceNumber
                                                            : undefined
                                                    }
                                                    margin='normal'
                                                    // required
                                                    fullWidth
                                                    id='proformainvoice-invoiceNumber'
                                                    label='Invoice Number'
                                                    name='invoiceNumber'
                                                    onChange={(e: any) => {
                                                        updateField(
                                                            'invoiceNumber',
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        {quoteFields.map((item, index) => (
                                            <Quotefield
                                                fromUpload
                                                key={item.id}
                                                index={index}
                                                id={item.id}
                                                updateQuoteField={
                                                    updateQuoteField
                                                }
                                                price={item.price}
                                                qty={item.qty}
                                                removeQuoteField={
                                                    removeQuoteField
                                                }
                                            />
                                        ))}

                                        <div className='border border-appcolorgreyfaint px-[1.5rem] pt-4 pb-4'>
                                            <div
                                                className='flex items-center cursor-pointer w-fit'
                                                onClick={() => {
                                                    addQuoteField();
                                                }}
                                            >
                                                <div>
                                                    <img
                                                        src='/img/vectors/primarydownload.svg'
                                                        alt='primarydownload'
                                                        className='w-6'
                                                    />
                                                </div>
                                                <span className='pl-2 text-appcolorprimary'>
                                                    Add Field
                                                </span>
                                            </div>
                                        </div>

                                        <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 flex justify-end gap-2'>
                                            <div className='w-[20%]'>
                                                <Autocomplete
                                                    value={
                                                        payload.currencyCodeValue
                                                    }
                                                    placeholder='Currency'
                                                    disableClearable
                                                    id='send-currency'
                                                    sx={{
                                                        marginTop: '1rem',
                                                        '.MuiInputBase-input': {
                                                            height: '0.3rem'
                                                        }
                                                    }}
                                                    renderInput={(
                                                        params: any
                                                    ) => (
                                                        <TextField
                                                            {...params}
                                                            value={
                                                                payload.currencyCode
                                                            }
                                                            label='Select Currency'
                                                            placeholder='Currency'
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                endAdornment:
                                                                    isLoadingCurrencies && (
                                                                        <React.Fragment>
                                                                            <CircularProgress
                                                                                size={
                                                                                    20
                                                                                }
                                                                            />
                                                                            {
                                                                                params
                                                                                    .InputProps
                                                                                    .endAdornment
                                                                            }
                                                                        </React.Fragment>
                                                                    )
                                                            }}
                                                        />
                                                    )}
                                                    noOptionsText={
                                                        'No data available'
                                                    }
                                                    renderOption={(
                                                        props,
                                                        option
                                                    ) => (
                                                        <MenuItem
                                                            {...props}
                                                            value={
                                                                option.nameAndFlag
                                                            }
                                                            key={`countries-${option.id}`}
                                                        >
                                                            {option.nameAndFlag}
                                                        </MenuItem>
                                                    )}
                                                    getOptionLabel={(option) =>
                                                        option.nameAndFlag
                                                    }
                                                    onChange={(
                                                        event: any,
                                                        value: any
                                                    ) => {
                                                        if (value !== null) {
                                                            updateField(
                                                                'currencyCode',
                                                                value.currencyCode
                                                            );
                                                            updateField(
                                                                'currencyCodeValue',
                                                                value
                                                            );
                                                        }
                                                    }}
                                                    options={currencies}
                                                />
                                            </div>
                                            <div className='w-[21%]'>
                                                <TextField
                                                    disabled
                                                    margin='normal'
                                                    fullWidth
                                                    id='commercialinvoice-totalAmount'
                                                    label='Total Amount'
                                                    name='totalAmount'
                                                    value={formatDecimalNumber(
                                                        totalAmount
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        )}
                        <div className='mt-4'>
                            {currentDocument?.documentType ===
                            'COMMERCIAL_INVOICE' ? (
                                <Custombutton
                                    onClickAction={generalSubmit}
                                    isLoadingButton
                                    loading={loading}
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    styles={{
                                        padding:
                                            '0.75rem 0.62rem 0.9rem 0.93rem'
                                    }}
                                    disabled={
                                        Object.values(payloadCom).some(
                                            (item: any) => item === undefined
                                        ) ||
                                        Object.values(errorsCom).some(
                                            (item: any) => item.length > 0
                                        ) ||
                                        loading
                                    }
                                    buttonText='Submit Invoice'
                                />
                            ) : (
                                <Custombutton
                                    onClickAction={generalSubmit}
                                    isLoadingButton
                                    loading={loading}
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    styles={{
                                        padding:
                                            '0.75rem 0.62rem 0.9rem 0.93rem'
                                    }}
                                    disabled={
                                        Object.values(payload).some(
                                            (item: any) => item === undefined
                                        ) || loading
                                    }
                                    buttonText='Submit'
                                />
                            )}
                        </div>
                    </div>
                </CustomModal>
            )}

            {uploadOrCreate && (
                <CustomModal
                    maxWidth='md'
                    open={true}
                    onClose={closeUploadOrCreate}
                    title={`Upload or Create Document`}
                    subTitle={`Please choose if you want to upload or create ${currentDocument?.title} document.`}
                    styles={{
                        overflowX: 'hidden'
                    }}
                >
                    <div className='flex justify-between'>
                        <div className='w-full mr-4'>
                            <Custombutton
                                onClickAction={() => {
                                    setVisible(true);
                                    closeUploadOrCreate();
                                }}
                                isLoadingButton
                                loading={loading}
                                type='submit'
                                fullWidth
                                variant='contained'
                                styles={{
                                    padding: '0.75rem 0.62rem 0.9rem 0.93rem'
                                }}
                                buttonText='Upload'
                            />
                        </div>
                        <div className='w-full'>
                            <Custombutton
                                onClickAction={() => {
                                    navigate(currentDocument?.route);
                                }}
                                isLoadingButton
                                loading={loading}
                                type='submit'
                                fullWidth
                                variant='contained'
                                styles={{
                                    padding: '0.75rem 0.62rem 0.9rem 0.93rem'
                                }}
                                buttonText='Create'
                            />
                        </div>
                    </div>
                </CustomModal>
            )}
        </div>
    );
};

export default Addtradedocument;
