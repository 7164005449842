import styled from 'styled-components';
import { scaleValue } from './TradeDocStyles';

export const SalesContainer = styled.section`
    min-width: ${scaleValue(1140)}px;
    min-height: 500px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    .page-header {
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        h1 {
            font-size: 28px;
            font-weight: 500;
        }
        .back-btn {
            display: flex;
            gap: 8px;
            color: #333;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            align-items: center;
            cursor: pointer;
            :hover {
                border-bottom: 1px solid #333;
            }
        }
    }
    @media (max-width: 1024px) {
        min-width: 100%;
        .page-header {
            h1 {
                font-size: 19px;
                font-weight: 500;
            }
        }
    }
`;

export const SalesSummaryContainer = styled.div`
    width: 100%;
    max-height: 120px;
    min-height: 117px;
    padding: 15px 25px;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    flex-shrink: 0;
    border-radius: 3px;
    background: #fff;
    margin-top: 16px;
    .first-details {
        align-items: center;
        p {
            color: #333;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding-bottom: 6px;
        }
        h4 {
            color: #333;
            font-size: 19px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
    .other-details {
        p {
            color: #333;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            opacity: 0.6;
            margin-bottom: 4px;
        }
        h4 {
            color: #333;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            text-transform: capitalize;
        }
    }
`;
