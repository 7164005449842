import React, { useState } from 'react';
import CustomModal from '../../../../components/CustomModal';
import DocumentViewer from '../../../../components/DocumentViewer/DocumentViewer';
import { Button, CircularProgress } from '@mui/material';
import { queryConfig } from '../../../../api/queryBase';
import { ViewContainer } from '../../../../styles/PaymentStyles';
import AttachmentIcon from '../../../../components/icons/AttachmentIcon';

const ViewTransactionDocument = ({
    fileUrl,
    downloadOnly = false
}: {
    fileUrl: string;
    downloadOnly?: boolean;
}) => {
    const [visibleDocument, setVisibleDocument] = useState(false);
    const query = queryConfig({ isBlob: true });
    const { isLoading, data, refetch } = query<Blob>({
        url: fileUrl,
        cacheKey: fileUrl,
        enabled: false
    });

    const closeCurrentDocModal = () => {
        setVisibleDocument(false);
    };

    const getFileName = (url: string) => {
        const parts = url.split('/');
        const fileNameWithQuery = parts[parts.length - 1];
        return fileNameWithQuery.split('?')[0];
    };

    const handleDownload = () => {
        if (data) {
            const newFileUrl = window.URL.createObjectURL(data.payload);
            const link = document.createElement('a');
            link.href = newFileUrl;
            link.setAttribute('download', getFileName(fileUrl));
            document.body.appendChild(link);
            link.click();
        }
    };

    return (
        <ViewContainer>
            <div className='flex gap-2 mt-2 w-full lg:w-max'>
                {!downloadOnly && (
                    <Button
                        variant='text'
                        onClick={() => {
                            setVisibleDocument(true);
                        }}
                        className='flex-1 h-[40px] lg:h-[unset] lg:flex-none'
                    >
                        <AttachmentIcon />
                        View
                    </Button>
                )}
                <Button
                    variant='text'
                    className='download-button h-[40px] lg:h-[unset] flex-1 lg:flex-none'
                    onClick={() => {
                        refetch();
                        handleDownload();
                    }}
                >
                    {isLoading && <CircularProgress size='sm' />}
                    Download
                </Button>
            </div>
            <CustomModal
                maxWidth='lg'
                open={visibleDocument}
                onClose={closeCurrentDocModal}
                title='View Document'
            >
                <div className='lg:w-[60rem] w-full h-[85vh]'>
                    <DocumentViewer fileUrl={fileUrl} loader={true} />
                </div>
            </CustomModal>
        </ViewContainer>
    );
};

export default ViewTransactionDocument;
