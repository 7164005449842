import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { setAuthToken } from '../../api';
import { ShippingProfile, User } from '../../interfaces/User';

export interface UserState {
    user: User | null;
    token: string | null;
    isAuthenticated: boolean;
    newTransactionAlert: boolean;
    refresh?: string | null;
    shippingProfile?: ShippingProfile[];
}

export interface updateUserInterface {
    user: User;
}

export interface loginUserInterface extends updateUserInterface {
    token: string | null;
    refresh: string | null;
}

export interface ShippingInterface  {
    shippingProfile: ShippingProfile[];
}

const initialState: UserState = {
    user: null,
    token: null,
    refresh: null,
    isAuthenticated: false,
    newTransactionAlert: false,
    shippingProfile: []
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loginUser: (state, action: PayloadAction<loginUserInterface>) => {
            state.user = action.payload.user;
            state.isAuthenticated = true;
            state.token = action.payload.token;
            state.refresh = action.payload.refresh;
        },
        logoutUser: (state) => {
            state.user = null;
            state.isAuthenticated = false;
            state.token = null;
            state.refresh = null;
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('exp');
            setAuthToken(null);
        },
        updateUser: (state, action: PayloadAction<updateUserInterface>) => {
            state.user = action.payload.user;
        },
        userShippingProfiles: (state, action: PayloadAction<ShippingInterface>) => {
            state.shippingProfile = action.payload.shippingProfile;
        }
    }
});

export const { loginUser, logoutUser, updateUser, userShippingProfiles } =
    userSlice.actions;

const userReducer = userSlice.reducer;

export default userReducer;
