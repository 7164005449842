import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormHeader from '../../../../components/dashboard/tradeDocuments/common/FormHeader';
import {
    TradeContainer,
    TradeContent
} from '../../../../styles/TradeDocStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import useCountries from '../../../../customhooks/useCountries';
import { nanoid } from '@reduxjs/toolkit';
import useCorporatePartners from '../../../../customhooks/useCorporatePartners';
import {
    createCommercialInvoice,
    getSingleDocuments
} from '../../../../api/documents/documents';
import { openNotification } from '../../../../utils/helper';
import Addpartnerdrawer from '../addpartnerdrawer/addpartnerdrawer';
import CustomModal from '../../../../components/CustomModal';
import Savetradedocument from '../savetradedocument/savetradedocument';
import CommercialForm from '../../../../components/dashboard/tradeDocuments/formComponents/CommercialForm';
import useCurrencies from '../../../../customhooks/useCurrencies';
import FormPreviewTemplate2 from '../../../../components/dashboard/tradeDocuments/previewComponents/FormPreviewTemplate2';
import { Backdrop } from '@mui/material';
import {
    CommercialError,
    CommercialPayload,
    OriginalItems,
    ParamsInterface,
    TransformedItems,
    generateInitialErrors
} from '../tradeDocumentTypes/CommercialTypes';

const CommercialInvoiceNew = () => {
    const breadcrumbs = [
        {
            title: 'Home',
            link: '/',
            active: false
        },
        {
            title: 'Documents',
            link: '/trade-documents',
            active: false
        },
        {
            title: 'Commercial Invoice',
            link: '/trade-documents/commercial-invoice',
            active: true
        }
    ];

    const navigate = useNavigate();
    const location = useLocation();
    const [params, setParams] = useState<ParamsInterface>();
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState<any>(null);
    const { isLoading: isLoadingCurrencies, currencies } = useCurrencies();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const param: any = Object.fromEntries(searchParams.entries());
        setParams(param);
    }, [location.search]);

    const fetchDocuments = useCallback(async () => {
        setLoadingData(true);
        try {
            const res = await getSingleDocuments({
                corporateDocumentId: params?.edit
            });

            const { data } = res.data;
            setData(data);

            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            openNotification('error', `Documents, Something went wrong`);
        }
    }, [params?.edit]);

    useEffect(() => {
        if (params?.edit) {
            fetchDocuments();
        }
    }, [params, fetchDocuments]);

    const { isCountriesLoading, countries } = useCountries();
    const [partnerId, setPartnerId] = useState<number | string | undefined>();
    const pageTopRef = useRef<HTMLDivElement | null>(null);
    const myDiv = useRef<HTMLDivElement | null>(null);
    const { corporatePartners, isLoading } = useCorporatePartners({ page: 1 });

    const [showPreview, setShowPreview] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [signatureUrlBase64, setSignatureUrlBase64] = useState<string>('');
    const [saveOnly, setSaveOnly] = useState(true);
    const [fileId, setFileId] = useState<string | number | unknown>(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | any>(null);
    const [payload, setPayload] = useState<CommercialPayload>({
        countryOfFinalDestinationName: '',
        countryOfOriginName: '',
        logoUrl: '',
        currencyCodeValue: undefined,
        portOfLoadingValue: undefined,
        portOfDischargeValue: undefined,
        buyerAddress: '',
        consigneeAddress: undefined,
        invoiceDate: Date.now(),
        exporterAddress: undefined,
        exporterId: undefined,
        invoiceNumber: nanoid(9),
        consigneeId: undefined,
        billOfLadingNumber: undefined,
        buyerReference: undefined,
        buyerId: '',
        methodOfDispatch: undefined,
        typeOfShipment: undefined,
        countryOfOrigin: undefined,
        transport: undefined,
        voyageNumber: '',
        countryOfFinalDestinationId: '',
        portOfLoadingId: undefined,
        portOfDischargeId: undefined,
        methodOfPayment: '',
        dateOfDeparture: Date.now(),
        countryOfFinalDestination: '',
        marineCoverPolicyNo: '',
        letterOfCreditNo: '',
        additionalInformation: '',
        bankDetails: '',
        placeOfIssue: undefined,
        signatoryCompany: undefined,
        nameOfAuthorisedSignatory: undefined,
        signatureUrl: undefined,
        placeOfOrigin: undefined,
        finalDestination: undefined,
        termsOfPayment: undefined,
        documentName: '',
        dueDate: Date.now(),
        buyer: '',
        exporterName: '',
        exporterBankName: '',
        exporterAccountNumber: '',
        exporterCountry: '',
        consigneeAccountNumber: '',
        consigneeName: '',
        consigneeBankName: '',
        consigneeCountry: '',
        salesContractNumber: '',
        stamp: ''
    });
    const [errors, setErrors] = useState<CommercialError>({
        countryOfFinalDestinationName: '',
        countryOfOriginName: '',
        portOfLoadingValue: '',
        portOfDischargeValue: '',
        buyerAddress: '',
        consigneeAddress: '',
        invoiceDate: '',
        exporterAddress: '',
        exporterId: '',
        invoiceNumber: '',
        consigneeId: '',
        billOfLadingNumber: '',
        buyerReference: '',
        buyerId: '',
        methodOfDispatch: '',
        typeOfShipment: '',
        countryOfOrigin: '',
        transport: '',
        voyageNumber: '',
        countryOfFinalDestinationId: '',
        portOfLoadingId: '',
        portOfDischargeId: '',
        methodOfPayment: '',
        dateOfDeparture: '',
        countryOfFinalDestination: '',
        marineCoverPolicyNo: '',
        letterOfCreditNo: '',
        additionalInformation: '',
        bankDetails: '',
        placeOfIssue: '',
        signatoryCompany: '',
        nameOfAuthorisedSignatory: '',
        signatureUrl: '',
        placeOfOrigin: '',
        finalDestination: '',
        termsOfPayment: '',
        documentName: '',
        salesContractNumber: '',
        dueDate: '',
        buyer: '',
        logoUrl: '',
        exporterName: '',
        consigneeName: '',
        stamp: '',
        documentUrl: ''
    });
    const [totalAmount, setTotalAmount] = useState(0);
    const [quoteFields, setQuoteFields] = useState<TransformedItems[]>([
        {
            id: nanoid(9),
            productCode: nanoid(9),
            desc: undefined,
            qty: 0,
            type: undefined,
            price: 0
        }
    ]);

    useEffect(() => {
        if (data) {
            setFileId(data?.id);
            setPayload({
                ...payload,
                buyerReference: data?.documentData?.buyerReference || '',
                invoiceNumber: data?.documentData?.invoiceNumber || nanoid(9),
                methodOfDispatch: data?.documentData?.methodOfDispatch || '',
                typeOfShipment: data?.documentData?.typeOfShipment || '',
                portOfLoadingId: data?.documentData?.portOfLoading?.name || '',
                portOfLoadingValue: data?.documentData?.portOfLoading || '',
                portOfDischargeValue: data?.documentData?.portOfDischarge || '',
                portOfDischargeId:
                    data?.documentData?.portOfDischarge?.name || '',
                additionalInformation:
                    data?.documentData?.additionalInformation || '',
                bankDetails: data?.documentData?.bankDetails || '',
                signatoryCompany: data?.documentData?.signatoryCompany || '',
                nameOfAuthorisedSignatory:
                    data?.documentData?.nameOfAuthorisedSignatory || '',
                documentName: data?.documentName || '',
                logoUrl: data?.documentData?.logoUrl || '',
                signatureUrl: data?.documentData?.signatureUrl || '',
                placeOfIssue: data?.documentData?.placeOfIssue || '',
                salesContractNumber:
                    data?.documentData?.salesContractNumber || '',
                countryOfFinalDestinationName: '',
                countryOfOriginName:
                    data?.documentData?.countryOfOrigin?.name || '',
                currencyCodeValue: data?.documentData?.currencyCode || '',
                buyerAddress: data?.documentData?.buyerAddress || '',
                consigneeAddress: data?.documentData?.consigneeAddress || '',
                invoiceDate: data?.documentData?.invoiceDate || Date.now(),
                exporterId: data?.documentData?.exporterId || '',
                exporterAddress: data?.documentData?.exporterAddress || '',
                consigneeId: data?.documentData?.consigneeId || '',
                billOfLadingNumber:
                    data?.documentData?.billOfLadingNumber || '',
                buyerId: data?.documentData?.buyerId || '',
                countryOfOrigin: data?.documentData?.countryOfOrigin || '',
                transport: data?.documentData?.transport || '',
                voyageNumber: data?.documentData?.voyageNumber || '',
                countryOfFinalDestinationId:
                    data?.documentData?.countryOfFinalDestinationId || '',
                methodOfPayment: '',
                dateOfDeparture:
                    data?.documentData?.dateOfDeparture || Date.now(),
                countryOfFinalDestination:
                    data?.documentData?.countryOfFinalDestination || '',
                marineCoverPolicyNo:
                    data?.documentData?.marineCoverPolicyNo || '',
                letterOfCreditNo: data?.documentData?.letterOfCreditNo || '',
                placeOfOrigin: data?.documentData?.placeOfOrigin || '',
                finalDestination: data?.documentData?.finalDestination || '',
                termsOfPayment: data?.documentData?.termsOfPayment || '',
                dueDate: data?.documentData?.dueDate || Date.now(),
                buyer: data?.documentData?.buyer?.name || '',
                exporterName: data?.documentData?.exporter?.name || '',
                consigneeAccountNumber: '',
                consigneeName: data?.documentData?.consignee?.name || '',
                consigneeBankName: '',
                consigneeCountry: '',
                countryOfOriginId: data?.documentData?.countryOfOriginId || '',
                stamp: data?.stampUrl
            });

            const transformArray = (
                array: OriginalItems[]
            ): TransformedItems[] => {
                const newArray: TransformedItems[] = [];
                array.forEach((val: OriginalItems) => {
                    newArray.push({
                        id: val.id,
                        productCode: val.productCode,
                        desc: val.descriptionOfGoods,
                        qty: val.quantity,
                        type: val.unitType,
                        price: val.unitPrice
                    });
                });
                return newArray;
            };
            setQuoteFields(transformArray(data?.documentData?.items));
        }
        // eslint-disable-next-line
    }, [data]);

    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showAddPartner, setShowAddPartner] = useState(false);

    const closeShowAddParty = () => {
        setShowAddPartner(false);
    };

    const closeSaveModal = () => {
        setShowSaveModal(false);
    };

    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    const updateQuoteField = (
        index: number,
        attributeName: keyof TransformedItems,
        attributeValue: TransformedItems[keyof TransformedItems]
    ) => {
        const newContainers: any[] = [...quoteFields];
        newContainers[index][attributeName] = attributeValue;
        setQuoteFields(newContainers);
    };

    useEffect(() => {
        let totalNumber = 0;
        quoteFields.forEach((item) => {
            totalNumber +=
                (isNaN(item.price) ? 0 : Number(item.price)) *
                (isNaN(item.qty) ? 0 : Number(item.qty));
        });
        setTotalAmount(totalNumber);
    }, [quoteFields]);

    const addQuoteField = () => {
        setQuoteFields([
            ...quoteFields,
            {
                id: nanoid(9),
                productCode: nanoid(9),
                desc: undefined,
                qty: 0,
                type: undefined,
                price: 0
            }
        ]);
    };

    const removeQuoteField = (index: number) => {
        setQuoteFields(quoteFields.filter((item, i) => i !== index));
    };

    const onSubmit = async () => {
        setLoading(true);
        setError(null);

        setErrors(generateInitialErrors());

        try {
            const items: unknown[] = [];

            const {
                portOfLoadingValue,
                portOfDischargeValue,
                buyerAddress,
                consigneeAddress,
                invoiceDate,
                exporterAddress,
                exporterId,
                invoiceNumber,
                consigneeId,
                billOfLadingNumber,
                buyerReference,
                buyerId,
                methodOfDispatch,
                typeOfShipment,
                countryOfOrigin,
                transport,
                voyageNumber,
                countryOfFinalDestinationId,
                methodOfPayment,
                dateOfDeparture,
                salesContractNumber,
                marineCoverPolicyNo,
                letterOfCreditNo,
                additionalInformation,
                bankDetails,
                placeOfIssue,
                signatoryCompany,
                nameOfAuthorisedSignatory,
                signatureUrl,
                placeOfOrigin,
                finalDestination,
                termsOfPayment,
                documentName,
                dueDate,
                buyer,
                logoUrl,
                currencyCodeValue,
                stamp
            } = payload;

            const dischargeValue: any = portOfDischargeValue;
            const portValue: any = portOfLoadingValue;
            const currencyCode: any = currencyCodeValue;

            quoteFields.forEach((item) => {
                items.push({
                    amount: item.price,
                    descriptionOfGoods: item.desc,
                    productCode: item.productCode,
                    quantity: item.qty,
                    unitPrice: item.price,
                    unitType: item.type
                });
            });

            const res = await createCommercialInvoice({
                createdType: 'CREATED',
                documentData: {
                    items,
                    buyerAddress,
                    consigneeAddress,
                    invoiceDate,
                    exporterAddress,
                    exporterId,
                    invoiceNumber,
                    consigneeId,
                    billOfLadingNumber,
                    buyerReference,
                    buyerId,
                    methodOfDispatch,
                    typeOfShipment,
                    countryOfOriginId: countryOfOrigin,
                    transport,
                    voyageNumber,
                    countryOfFinalDestinationId,
                    methodOfPayment,
                    dateOfDeparture,
                    marineCoverPolicyNo,
                    letterOfCreditNo,
                    additionalInformation,
                    bankDetails,
                    placeOfIssue,
                    signatoryCompany,
                    nameOfAuthorisedSignatory,
                    signatureUrl,
                    placeOfOrigin,
                    finalDestination,
                    termsOfPayment,
                    dueDate,
                    buyer,
                    currencyCode: currencyCode?.currencyCode,
                    portOfDischargeId: dischargeValue?.id,
                    portOfLoadingId: portValue?.id,
                    totalAmount,
                    logoUrl: logoUrl !== '' ? logoUrl : undefined,
                    stampUrl: stamp !== '' ? stamp : undefined,
                    salesContractNumber
                },
                documentType: 'COMMERCIAL_INVOICE',
                documentName,
                id: fileId
            });

            const { id } = res.data.data;

            setFileId(id);

            openNotification(
                'success',
                'Commercial Invoice document created successfully'
            );

            setLoading(false);

            if (saveOnly) {
                navigate('/trade-documents');
            }
        } catch (error) {
            window.scroll({
                top: pageTopRef?.current?.offsetTop,
                left: 0,
                behavior: 'smooth'
            });

            setShowSaveModal(false);
            setLoading(false);
            const otherErrors: any[] = [];

            if (error?.response) {
                if (error?.response?.data?.error) {
                    const resError = error?.response?.data?.error;

                    if (typeof resError === 'string') {
                        const errorList = (
                            <li key={`error-list-${1}`}>{resError}</li>
                        );
                        setError(errorList);
                    } else {
                        Object.entries(resError).forEach(([key, value]) => {
                            if (Object.keys(errors).includes(key)) {
                                setErrors((prevState) => ({
                                    ...prevState,
                                    [key]: value
                                }));
                            } else {
                                otherErrors.push(value);
                            }
                        });

                        if (otherErrors.length > 0) {
                            const errorList = otherErrors.map(
                                (element: any, index) => {
                                    return (
                                        <li key={`error-list-${index}`}>
                                            {element}
                                        </li>
                                    );
                                }
                            );
                            setError(errorList);
                        }
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    return (
        <TradeContainer>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loadingData}
            >
                <img
                    src='/img/vectors/loadingcircles.svg'
                    alt='loading'
                    className='animate-spin'
                />
            </Backdrop>
            <FormHeader
                breadcrumbs={breadcrumbs}
                docTitle='Commercial Invoice'
                {...{
                    showPreview,
                    setShowPreview,
                    myDiv,
                    downloadLoading,
                    setDownloadLoading
                }}
                onSaveOnlyAction={() => {
                    setSaveOnly(true);
                    setShowSaveModal(true);
                }}
                onSaveAction={() => {
                    setSaveOnly(false);
                    setShowSaveModal(true);
                }}
                disabled={
                    Object.values(payload).some(
                        (item: any) => item === undefined
                    ) || loading
                }
            />

            <TradeContent>
                {showPreview ? (
                    <div ref={myDiv}>
                        <FormPreviewTemplate2
                            data={payload}
                            docTitle='Commercial Invoice'
                            quoteFields={quoteFields}
                            totalAmount={totalAmount}
                            signatureUrlBase64={signatureUrlBase64}
                        />
                    </div>
                ) : (
                    <CommercialForm
                        {...{
                            error,
                            updateField,
                            payload,
                            corporatePartners,
                            countries,
                            isCountriesLoading,
                            loadingCorporatepartners: isLoading,
                            setShowAddPartner,
                            errors,
                            quoteFields,
                            updateQuoteField,
                            removeQuoteField,
                            currencies,
                            addQuoteField,
                            loadingCurrencies: isLoadingCurrencies,
                            setSignatureUrlBase64,
                            signatureUrlBase64,

                            totalAmount,
                            setPartnerId
                        }}
                    />
                )}
                {showAddPartner ? (
                    <Addpartnerdrawer
                        closeDrawer={closeShowAddParty}
                        showDrawer={showAddPartner}
                        partnerId={Number(partnerId)}
                        setPartnerId={setPartnerId}
                    />
                ) : null}
                {showSaveModal && (
                    <CustomModal
                        open={showSaveModal}
                        onClose={closeSaveModal}
                        title='Saving document'
                        maxWidth='xs'
                        styles={{
                            overflowX: 'hidden',
                            '.MuiPaper-root': {
                                width: '100%'
                            }
                        }}
                    >
                        <Savetradedocument
                            updateField={updateField}
                            onSubmit={onSubmit}
                            loading={loading}
                            data={payload}
                        />
                    </CustomModal>
                )}
            </TradeContent>
        </TradeContainer>
    );
};

export default CommercialInvoiceNew;
