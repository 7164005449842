import { Box, Divider, InputAdornment, Stack } from '@mui/material';
import React, { useState } from 'react';
import { EditIcon } from '../../../components/icons/EditIcon';
import { PreviewIcon } from '../../../components/icons/PreviewIcon';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import Custombutton from '../../../components/custombutton/custombutton';
import CustomTextField from '../../../components/CustomTextInput';
import { mutateConfig } from '../../../api/queryBase';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../../utils/helper';
import { HideIcon } from '../../../components/icons/HideIcon';

interface ChangePasswordForm {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const ChangePassword = () => {
    const [editValues, setEditValues] = useState(false);
    const [show, setShow] = useState(false);
    const mutation = mutateConfig({ method: 'patch' });
    const { user } = useSelector((state: RootState) => ({
        user: state.user.user
    }));

    const { mutate } = mutation({
        url: `/users/${user?.user_id}/password`
    });

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: object({
            currentPassword: string().required(),
            confirmPassword: string().required(),
            newPassword: string().required()
        }),
        onSubmit: (values) => {
            formik.setSubmitting(true);
            const { confirmPassword, ...data } = values;
            mutate(data, {
                onSettled: (_, error: AxiosError | undefined | any) => {
                    formik.setSubmitting(false);
                    if (error) {
                        toast.error(
                            getErrorMessage(error) ||
                                'Profile Update failed. Please try again later'
                        );
                        return;
                    }
                    toast.info('Password Updated successfully');
                    setEditValues(false);
                    setShow(false);
                }
            });
        }
    });

    return (
        <Stack
            direction='column'
            className=' bg-white max-w-[618px] lg:p-[16px] px-[16px] mt-12 lg:mt-3 py-[0px] rounded-[8px] lg:border-[1px] border-appcolorfaintnine '
            gap='5px'
        >
            <Stack
                justifyContent='space-between'
                alignItems='center'
                direction='row'
            >
                <h3 className='text-deepMidnightBlue text-[16px] font-[500]'>
                    Password
                </h3>
                {!editValues && (
                    <Box
                        className='bg-white flex items-center justify-center rounded-[8px] border border-[#EAECF0] p-[12px] cursor-pointer hover:bg-gray-100'
                        onClick={() => setEditValues(!editValues)}
                    >
                        <EditIcon />
                    </Box>
                )}
            </Stack>
            <Divider orientation='horizontal' className='py-1' />
            <Box
                justifyContent='space-between'
                alignItems='center'
                className=' flex lg:flex-row flex-col lg:gap-4 gap-2 mt-0 lg:mt-4'
            >
                <div className='flex-1 w-full lg:w-max'>
                    <CustomTextField
                        type={show ? 'text' : 'password'}
                        label='Current Password'
                        name='currentPassword'
                        disabled={!editValues}
                        onChange={formik.handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position='end'
                                    sx={{ paddingRight: '10px' }}
                                    onClick={() => setShow(!show)}
                                >
                                    {show ? <HideIcon /> : <PreviewIcon />}
                                </InputAdornment>
                            )
                        }}
                        value={formik.values.currentPassword}
                    />
                </div>
                <div className='flex-1 w-full lg:w-max'>
                    <CustomTextField
                        label='New Password'
                        type={show ? 'text' : 'password'}
                        disabled={!editValues}
                        onChange={formik.handleChange}
                        name='newPassword'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position='end'
                                    sx={{ paddingRight: '10px' }}
                                    onClick={() => setShow(!show)}
                                >
                                    {show ? <HideIcon /> : <PreviewIcon />}
                                </InputAdornment>
                            )
                        }}
                        value={formik.values.newPassword}
                    />
                </div>
            </Box>

            <Box
                justifyContent='space-between'
                alignItems='center'
                className=' flex lg:flex-row flex-col lg:gap-4 gap-2 mt-0 lg:mt-4'
            >
                <div className='flex-1 w-full lg:w-max'>
                    <CustomTextField
                        label='Confirm New Password'
                        type={show ? 'text' : 'password'}
                        disabled={!editValues}
                        name={'confirmPassword'}
                        onChange={formik.handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position='end'
                                    sx={{ paddingRight: '10px' }}
                                    onClick={() => setShow(!show)}
                                >
                                    {show ? <HideIcon /> : <PreviewIcon />}
                                </InputAdornment>
                            )
                        }}
                        value={formik.values.confirmPassword}
                    />
                </div>
                <div className=' flex-1' />
            </Box>

            {editValues && (
                <Stack
                    justifyContent='flex-end'
                    alignItems='center'
                    direction='row'
                    gap='15px'
                    mt='30px'
                >
                    <Custombutton
                        isLoadingButton
                        type='button'
                        variant='outlined'
                        buttonText='Cancel'
                        styles={{
                            padding: '0.75rem 0.62rem',
                            width: '25%'
                        }}
                        onClickAction={() => {
                            setEditValues(false);
                        }}
                    />

                    <Custombutton
                        isLoadingButton
                        type='button'
                        variant='contained'
                        buttonText='Save new password'
                        styles={{
                            padding: '0.75rem 0.62rem',
                            width: 'max-content'
                        }}
                        loading={formik.isSubmitting}
                        onClickAction={() => {
                            formik.handleSubmit();
                        }}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default ChangePassword;
