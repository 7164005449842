import { HsCommodity } from '../interfaces/HsCommodity';
import { useGetQuery } from './useGetQuery';

interface GetHsParams {
    page: number;
    size: number;
    search?: number;
}

const useHsCode = (params: GetHsParams) => {
    const { isLoading, entries: hsCodeList } = useGetQuery<HsCommodity[]>({
        url: '/hs/code',
        cacheKey: 'hs-code',
        params: { ...params }
    });

    return { isLoading, hsCodeList };
};
export default useHsCode;
