import { httpNoAuth, http } from "../index";
import { paginatedData } from "../../utils/helper-interface";
import { getRefreshToken } from "../../pages/auth";

export interface signupPayload {
  businessName: string;
  cipherCode: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  rcNumber: string;
  countryId?: string;
}

export interface signinPayload {
  userSecret: string;
  username: string;
  twoFactorToken?: string;
  twoFactorType?: string;
  signature?: string;
}

export interface forgotPasswordPayload {
  resetUrl: string;
  email: string;
}

export interface resetPasswordPayload {
  cipher: string;
  resetCode: string;
}

export interface resendverificationPayload {
  userId: number;
  corporateId: number;
}

export interface verifyEmailPayload {
  verificationCode: string;
}

export interface uploadDocUrl {
  documentItemId: number;
  documentUrl: string;
}

export interface updateCorporatePayload {
  corporateIndustryId: number;
  businessTypes: number[];
}

export interface updateDocumentByAdminProps {
  approved: boolean;
  documentId: number;
  message?: string;
}

export interface getCorporatesPayload extends paginatedData {}

/**
 * User signup
 * @param data Object
 * @returns axios http response
 */
export const signup = (data: signupPayload) => {
  return httpNoAuth.post("/signup", data);
};

/**
 * User signin
 * @param data Object
 * @returns axios http response
 */
export const signin = (data: signinPayload) => {
  return httpNoAuth.post("/login", data);
};

/**
 * Resend email verification
 * @param  data Object
 * @returns axios http response
 */
export const resendVerification = (data: resendverificationPayload) => {
  return httpNoAuth.post("/signup/email/verify/resend", data);
};

//refresh token
export const refreshToken = () => {
  return httpNoAuth.post("/refresh", {
    refreshToken: getRefreshToken(),
  });
};
/**
 * Get list of countries
 * @returns axios http response
 */
export const getCountries = () => {
  return httpNoAuth.get("/countries");
};

export const getStates = (countryId: string) => {
  return http.get(`/countries/${countryId}/state`);
};

export const getCities = (stateId: string) => {
  return http.get(`countries/state/${stateId}/city`);
};

export const getCitiesSearch = (city: string) => {
  return http.get(`/countries/state/city/search/${city}`);
};

export const getIndustries = () => {
  return httpNoAuth.get("/business/industry");
};

export const getBusinessTypes = () => {
  return httpNoAuth.get("/business/type");
};

export const getPorts = (search: string) => {
  return http.get("/ports", {
    params: {
      search,
    },
  });
};

/**
 * Get list of countries
 * @returns axios http response
 */
export const getCountriesExtras = () => {
  return httpNoAuth.get("/all/countries");
};

/**
 * Initiate forgot password
 * @param  data Object
 * @returns axios http response
 */
export const forgotPassword = (data: forgotPasswordPayload) => {
  return httpNoAuth.post("/password/reset", data);
};

/**
 * Reset password
 * @param  data Object
 * @returns axios http response
 */
export const resetPassword = (data: resetPasswordPayload) => {
  return httpNoAuth.put("/password/reset", data);
};

/**
 * Get list of payment purposes
 * @returns axios http response
 */
export const getPaymentPurposes = () => {
  return http.get("/payment/purpose");
};

/**
 * Verify user email
 * @param  data Object
 * @returns axios http response
 */
export const verifyEmail = (data: verifyEmailPayload) => {
  return httpNoAuth.post("/signup/email/verify", data);
};

export const getCACDocuments = (corporateId: number) => {
  return http.get(`/corporate/${corporateId}/onboarding/documents`);
};

export const getVessels = (search: string) => {
  return http.get(`/vessels`, {
    params: {
      search,
    }
  })
}

export const getDocuments = (corporateId: number) => {
  return http.get(`/corporate/${corporateId}/documents`);
};

export const uploadDocumentUrl = (data: uploadDocUrl) => {
  return http.post(`/corporate/onboarding/documents/upload`, data);
};

export const updateCorporate = (
  corporateId: number,
  data: updateCorporatePayload
) => {
  return http.put(`/corporate/${corporateId}/update`, data);
};

export const getCorporates = (data: getCorporatesPayload) => {
  return http.get(`/corporate`, {
    params: {
      ...data,
    },
  });
};

export const getCorporateById = (corporateId: string) => {
  return http.get(`/corporate/${corporateId}`);
};

export const updateDocumentByAdmin = (
  corporateId: string,
  data: updateDocumentByAdminProps
) => {
  return http.put(
    `/administrator/corporates/${corporateId}/documents/approve`,
    data
  );
};

export const getLocationDetails = (placeId: string) => {
  return http.get(`/place/search/${placeId}`);
};
