import { Autocomplete, Box } from '@mui/material';
import { User } from '../../../../interfaces/User';
import { BookingForm } from './interfaces/BookingForm';
import { useGetQuery } from '../../../../customhooks/useGetQuery';
import { CorporateParty } from './interfaces/CorporateParty';
import CustomTextField from '../../../../components/CustomTextInput';

interface PartiesFormProps {
    user: User | null;
    value?: number;
    label: string;
    required?: boolean;
    isDisabled?: boolean;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
    setShowAddParty: React.Dispatch<React.SetStateAction<boolean>>;
    onHandleChange: (
        event: React.SyntheticEvent<Element, Event>,
        value: CorporateParty | null
    ) => void;
    error?: boolean;
    helperText?: string;
}

export const PartiesFormInput = ({
    user,
    value,
    label,
    onHandleChange,
    setShowAddParty,
    required,
    isDisabled,
    error,
    helperText
}: PartiesFormProps) => {
    const { isLoading: isLoadingCorporateparties, entries: corporateParties } =
        useGetQuery<CorporateParty[]>({
            url: '/booking/corporate/parties',
            cacheKey: 'corporate-parties',
            params: {
                page: 1,
                size: 0,
                corporateId: user?.corporate_id
            }
        });
    const lastEntry = [...corporateParties].pop();

    return (
        <Autocomplete
            autoComplete
            disableClearable
            disabled={isDisabled}
            options={corporateParties}
            onChange={onHandleChange}
            value={corporateParties.find((item) => item.corporateId === value)}
            renderInput={(params) => (
                <CustomTextField
                    params={params}
                    label={label}
                    required={required}
                    error={error}
                    helperText={helperText}
                />
            )}
            getOptionLabel={(option) => option.name}
            loading={isLoadingCorporateparties}
            renderOption={(props, { name }, state) => (
                <Box key={state.index}>
                    <Box component='li' {...props}>
                        {name}
                    </Box>
                    {user?.booking && lastEntry?.name === name && (
                        <Box
                            className='cursor-pointer p-2 text-appcolorprimary'
                            onMouseDown={() => setShowAddParty(true)}
                        >
                            + Add a New Party
                        </Box>
                    )}
                </Box>
            )}
        />
    );
};
