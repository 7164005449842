import React from 'react';
import { TabContainerNew } from '../../../styles/TradeDocStyles';
import { Divider, Stack, Tooltip } from '@mui/material';
import { add, download } from '../../customIcons';
import FilterWarehouse from './FilterWarehouse';
import { useNavigate } from 'react-router-dom';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { hasPermission } from '../dashboardpage/utils';

export const StockMenuTabs = () => {
    const navigate = useNavigate();
    const { permissions } = useUserProfile();

    return (
        <TabContainerNew>
            <Stack direction='row' spacing={'10px'}>
                {permissions &&
                    hasPermission({
                        userPermissions: permissions,
                        module: 'stocks',
                        permissionList: ['write', '*', 'read']
                    }) && (
                        <>
                            <FilterWarehouse />
                            <Divider orientation='vertical' flexItem />
                            <Tooltip title='Download' arrow>
                                <div className='tab-buttons'>
                                    {download}
                                    <p style={{ paddingLeft: '5px' }}>
                                        Extract
                                    </p>
                                </div>
                            </Tooltip>
                        </>
                    )}
                {permissions &&
                    hasPermission({
                        userPermissions: permissions,
                        module: 'stocks',
                        permissionList: ['write', '*']
                    }) && (
                        <>
                            <Divider orientation='vertical' flexItem />
                            <Tooltip title='Add Warehouse' arrow>
                                <div
                                    className='tab-buttons'
                                    onClick={() =>
                                        navigate('/stock/add-warehouse')
                                    }
                                >
                                    {add}
                                    <p style={{ paddingLeft: '5px' }}>
                                        Add Warehouse
                                    </p>
                                </div>
                            </Tooltip>
                        </>
                    )}
            </Stack>
        </TabContainerNew>
    );
};
