import React, { Dispatch, SetStateAction, useState } from 'react';
import { ServiceableInvoiceParams } from './interfaces/Invoice';
import { filter } from '../../../components/dashboard/sales/contracts/FilterContract';
import { Tooltip } from '@mui/material';
import {
    Divider,
    Button,
    DatePicker,
    DatePickerProps,
    Dropdown,
    MenuProps,
    Select,
    Space,
    Input
} from 'antd';
import {
    contentStyle,
    headingStyle,
    menuStyle,
    subStyle
} from '../../../components/dashboard/tradeFilter/filterStyle';
import { CurrencySelect } from '../../../components/CurrencySelect';
import { SuitUIButton } from '../../../components/SuitUIButton';
import CustomModal from '../../../components/CustomModal';

const LogisticsInvoiceListFilter = ({
    setFilterParams,
    filterParams
}: {
    filterParams: ServiceableInvoiceParams;
    setFilterParams: Dispatch<SetStateAction<ServiceableInvoiceParams>>;
}) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const [tempFilterOptions, setTempFilterOptions] =
        useState<ServiceableInvoiceParams>(filterParams);

    const handleMenuClick = () => {
        setFilterParams(tempFilterOptions);
        setDropdownVisible(false);
    };

    const handleButtonClick = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const onChangeFrom: DatePickerProps['onChange'] = (date) => {
        setTempFilterOptions({
            ...tempFilterOptions,
            dueDateStart: date?.format('YYYY-MM-DD')
        });
    };
    const onChangeTo: DatePickerProps['onChange'] = (date) => {
        setTempFilterOptions({
            ...tempFilterOptions,
            dueDateEnd: date?.format('YYYY-MM-DD')
        });
    };

    const onChangeIssuedFrom: DatePickerProps['onChange'] = (date) => {
        setTempFilterOptions({
            ...tempFilterOptions,
            issuedDateStart: date?.format('YYYY-MM-DD')
        });
    };
    const onChangeIssuedTo: DatePickerProps['onChange'] = (date) => {
        setTempFilterOptions({
            ...tempFilterOptions,
            issuedDateEnd: date?.format('YYYY-MM-DD')
        });
    };

    const modalItems = [
        {
            key: '0',
            label: (
                <div className=' lg:mb-0 lg:border-0 border-b pb-4 mb-2 border-gray-300'>
                    <h2>Invoice Number</h2>
                    <Input
                        type='text'
                        value={tempFilterOptions?.invoiceNumber}
                        placeholder='Invoice Number'
                        style={{
                            marginTop: '.5rem'
                        }}
                        onChange={(e) =>
                            setTempFilterOptions({
                                ...tempFilterOptions,
                                invoiceNumber: e.target.value
                            })
                        }
                    />
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '1',
            label: (
                <div className=' lg:mb-0 lg:border-0 border-b pb-4 mb-2 border-gray-300'>
                    {' '}
                    <h2>Issued Date Range</h2>
                    <Space
                        style={{
                            padding: 0,
                            marginTop: '.5rem',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <DatePicker
                            placeholder='Start Date'
                            onChange={onChangeIssuedFrom}
                            format='YYYY-MM-DD'
                        />

                        <DatePicker
                            format='YYYY-MM-DD'
                            placeholder='End Date'
                            onChange={onChangeIssuedTo}
                        />
                    </Space>
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '2',
            label: (
                <div className=' lg:mb-0 lg:border-0 border-b pb-4 mb-2 border-gray-300'>
                    <h2>Due Date Range</h2>
                    <Space
                        style={{
                            padding: 0,
                            marginTop: '.5rem',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <DatePicker
                            placeholder='Start Date'
                            onChange={onChangeFrom}
                            format='YYYY-MM-DD'
                        />

                        <DatePicker
                            format='YYYY-MM-DD'
                            placeholder='End Date'
                            onChange={onChangeTo}
                        />
                    </Space>
                </div>
            )
        },

        {
            type: 'divider'
        },
        {
            key: '3',
            label: (
                <div className=' lg:mb-0 lg:border-0 border-b pb-4 mb-2 border-gray-300'>
                    <h2>Status</h2>
                    <Select
                        style={{
                            marginTop: '.5rem',
                            width: '100%'
                        }}
                        value={tempFilterOptions?.status}
                        onChange={(value: string) =>
                            setTempFilterOptions({
                                ...tempFilterOptions,
                                status: value
                            })
                        }
                        options={[
                            { value: 1, label: 'Paid' },
                            { value: 6, label: 'Issued' },
                            { value: 4, label: 'Overdue' },
                            { value: 5, label: 'Write Off' }
                        ]}
                    />
                </div>
            )
        },
        {
            type: 'divider'
        },

        {
            key: '4',
            label: (
                <div
                    style={{ marginTop: '10px' }}
                    className=' lg:mb-0 lg:border-0 border-b pb-4 mb-2 border-gray-300'
                >
                    <CurrencySelect
                        allOptions={true}
                        onHandleChange={(value) => {
                            if (value !== null) {
                                setTempFilterOptions({
                                    ...tempFilterOptions,
                                    currencyCode: value.currencyCode
                                });
                            }
                        }}
                    />
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '5',
            label: (
                <div className=' lg:mb-0 lg:border-0 border-b pb-4 mb-2 border-gray-300'>
                    {' '}
                    <h2>Amount Range</h2>
                    <Space
                        style={{
                            padding: 0,
                            marginTop: '.5rem',
                            justifyContent: 'space-between',
                            width: '100%'
                        }}
                    >
                        <Input
                            type='number'
                            value={tempFilterOptions?.amountRangeStart}
                            placeholder='Start amount'
                            style={{
                                marginTop: '.5rem'
                            }}
                            onChange={(e) =>
                                setTempFilterOptions({
                                    ...tempFilterOptions,
                                    amountRangeStart: Number(e.target.value)
                                })
                            }
                        />
                        <Input
                            type='number'
                            value={tempFilterOptions?.amountRangeEnd}
                            placeholder='End amount'
                            style={{
                                marginTop: '.5rem'
                            }}
                            onChange={(e) =>
                                setTempFilterOptions({
                                    ...tempFilterOptions,
                                    amountRangeEnd: Number(e.target.value)
                                })
                            }
                        />
                    </Space>
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '6',
            label: (
                <div>
                    {' '}
                    <h2>Carrier Name</h2>
                    <Input
                        type='text'
                        value={tempFilterOptions?.carrierName}
                        placeholder=''
                        style={{
                            marginTop: '.5rem'
                        }}
                        onChange={(e) =>
                            setTempFilterOptions({
                                ...tempFilterOptions,
                                carrierName: e.target.value
                            })
                        }
                    />
                </div>
            )
        }
    ];

    const items = modalItems as MenuProps['items'];

    return (
        <>
            <div className='hidden lg:block'>
                <Dropdown
                    menu={{ items }}
                    trigger={['click']}
                    open={dropdownVisible}
                    overlayStyle={{
                        pointerEvents: 'auto'
                    }}
                    dropdownRender={(menu) => (
                        <div style={contentStyle}>
                            <h2 style={headingStyle}>Filters</h2>
                            <p style={subStyle}>
                                Filters will be added to the table.
                            </p>
                            {React.cloneElement(menu as React.ReactElement, {
                                style: menuStyle
                            })}
                            <Divider style={{ margin: 0 }} />
                            <Space
                                style={{
                                    padding: '1rem',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}
                            >
                                <Button
                                    className='reset-btn'
                                    onClick={() => {
                                        setFilterParams({});
                                        setTempFilterOptions({});
                                        setDropdownVisible(false);
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button
                                    className='bg-appcolorprimarydeep text-appcolorwhite border-0 hover:bg-appcolorwhite hover:border-appcolorprimarydeep hover:border  hover:text-appcolorprimary'
                                    onClick={handleMenuClick}
                                >
                                    Apply Filter
                                </Button>
                            </Space>
                        </div>
                    )}
                >
                    <Tooltip title='Apply filters' arrow>
                        <div
                            className='tab-buttons flex'
                            onClick={handleButtonClick}
                        >
                            {filter}
                            <p style={{ paddingLeft: '8px' }}>Filter</p>
                        </div>
                    </Tooltip>
                </Dropdown>
            </div>

            <div className='lg:hidden '>
                <CustomModal
                    maxWidth='lg'
                    open={modalVisible}
                    onClose={() => setModalVisible(false)}
                    hideCloseIcon
                >
                    <div className='w-full px-[16px] pt-[40px]'>
                        <h2 className='text-ebony mb-[16px]'>Filters</h2>

                        {modalItems?.map((item) => item?.label)}

                        <div className='flex justify-between pt-[40px] pb-[80px]'>
                            <SuitUIButton
                                text='Cancel'
                                onClick={() => setModalVisible(false)}
                                invert
                                style='h-[40px] border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                            />
                            <div className='flex gap-[24px]'>
                                <SuitUIButton
                                    text='Reset'
                                    onClick={() => {
                                        setFilterParams({});
                                        setTempFilterOptions({});
                                        setModalVisible(false);
                                    }}
                                    variant='secondary'
                                />
                                <SuitUIButton
                                    text='Apply Filter'
                                    onClick={handleMenuClick}
                                    style='h-[40px]'
                                />
                            </div>
                        </div>
                    </div>
                </CustomModal>

                {/* <button
                    className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base lg:h-[unset] lg:w-[unset] lg:text-black lg:text-sm lg:gap-[unset] lg:mx-2'
                    onClick={() => setModalVisible(true)}
                >
                    {filter}
                    Filter
                </button> */}
                <Tooltip title='Apply filters' arrow>
                    <div
                        className='tab-buttons flex'
                        onClick={() => setModalVisible(true)}
                    >
                        {filter}
                        <p style={{ paddingLeft: '8px' }}>Filter</p>
                    </div>
                </Tooltip>
            </div>
        </>
    );
};

export default LogisticsInvoiceListFilter;
