export const HideIcon = ({ color }: { color?: string }) => {
    return (
        <svg
            width='20'
            height='21'
            viewBox='0 0 20 21'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M9.68661 6.31328L6.31328 9.68661C5.87995 9.25328 5.61328 8.65995 5.61328 7.99995C5.61328 6.67995 6.67995 5.61328 7.99995 5.61328C8.65995 5.61328 9.25328 5.87995 9.68661 6.31328Z'
                stroke={color ?? '#1D2939'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M11.88 3.84657C10.7134 2.96657 9.38002 2.48657 8.00002 2.48657C5.64668 2.48657 3.45335 3.87324 1.92668 6.27324C1.32668 7.21324 1.32668 8.79324 1.92668 9.73324C2.45335 10.5599 3.06668 11.2732 3.73335 11.8466'
                stroke={color ?? '#1D2939'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M5.61328 13.02C6.37328 13.34 7.17995 13.5133 7.99995 13.5133C10.3533 13.5133 12.5466 12.1266 14.0733 9.72662C14.6733 8.78662 14.6733 7.20662 14.0733 6.26662C13.8533 5.91995 13.6133 5.59329 13.3666 5.28662'
                stroke={color ?? '#1D2939'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.34 8.46655C10.1666 9.40655 9.39996 10.1732 8.45996 10.3466'
                stroke={color ?? '#1D2939'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M6.31337 9.68677L1.33337 14.6668'
                stroke={color ?? '#1D2939'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.6666 1.33325L9.68665 6.31325'
                stroke={color ?? '#1D2939'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
