import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { CopyIcon } from './icons';

export const CopyText = ({ text }: { text: string }) => {
    return (
        <CopyToClipboard
            text={text}
            onCopy={() => toast.success('Text copied to clipboard')}
        >
            <button>
                <CopyIcon />
            </button>
        </CopyToClipboard>
    );
};
