import { CorporatePartner } from '../interfaces/CorporatePartner';
import { useGetQuery } from './useGetQuery';
import { useQueryClient } from 'react-query';

const useCorporatePartners = (params: { page: number; id?: number }) => {
    const queryClient = useQueryClient();

    const { isLoading, entries: corporatePartners } = useGetQuery<
        CorporatePartner[]
    >({
        url: '/sales/documents/partner',
        cacheKey: 'partners',
        params: { size: 0, ...params }
    });

    const invalidateCorporatePartners = () => {
        queryClient.invalidateQueries('partners');
    };

    return { corporatePartners, isLoading, invalidateCorporatePartners };
};

export default useCorporatePartners;
