export const EyesIcon = ({
    color,
    size = 11
}: {
    color?: string;
    size?: number;
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox='0 0 19 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M11.935 9.0849C11.935 10.5699 10.735 11.7699 9.25 11.7699C7.765 11.7699 6.565 10.5699 6.565 9.0849C6.565 7.5999 7.765 6.3999 9.25 6.3999C10.735 6.3999 11.935 7.5999 11.935 9.0849Z'
                stroke='#0088B2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M9.25 15.2875C11.8975 15.2875 14.365 13.7275 16.0825 11.0275C16.7575 9.97 16.7575 8.1925 16.0825 7.135C14.365 4.435 11.8975 2.875 9.25 2.875C6.6025 2.875 4.135 4.435 2.4175 7.135C1.7425 8.1925 1.7425 9.97 2.4175 11.0275C4.135 13.7275 6.6025 15.2875 9.25 15.2875Z'
                stroke='#0088B2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};
