import { Beneficiary } from '../../pages/dashboard/payments/interfaces/Beneficiary';
import { useGetQuery } from '../useGetQuery';
import { useQueryClient } from 'react-query';

const useBeneficiaries = () => {
    const queryClient = useQueryClient();

    const {
        isLoading,
        entries: beneficiaries,
        isFetching: isBeneficiariesFetching
    } = useGetQuery<Beneficiary[]>({
        url: '/corporate/beneficiary',
        cacheKey: 'beneficiaries'
    });

    const invalidateBeneficiaryCache = () => {
        queryClient.invalidateQueries('beneficiaries');
    };

    return {
        beneficiaries,
        isLoading,
        invalidateBeneficiaryCache,
        isBeneficiariesFetching
    };
};

export default useBeneficiaries;
