import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Outletouterpage from '../../../components/dashboard/outletouterpage/outletouterpage';
import { RootState } from '../../../store/store';

const Inspection = () => {
    const navigate = useNavigate();

    const state = useSelector((state: RootState) => ({
        user: state.user.user
    }));

    const { user } = state;

    return (
        <div className='mb-8' id='inspection_module'>
            <Outletouterpage
                breadcrumbs={[
                    {
                        title: 'Home',
                        link: '/dashboard'
                    },
                    {
                        title: 'Inspection',
                        link: '/inspection/Inspection'
                    }
                ]}
            >
                <div className='flex justify-between'>
                    <h1 className='text-[32px] leading-[23px] text-appcolorblacktwo'>
                        Inspection
                    </h1>
                </div>
            </Outletouterpage>
        </div>
    );
};

export default Inspection;
