import { yupToFormErrors } from 'formik';
import { ShowHideSection } from '../ShowHideSection';
import { AddtionalSections, BookingFormProps } from '../interfaces/BookingForm';
import { ReferencesForm } from '../ReferencesForm';
import { ReferencesAdditionalForm } from '../ReferencesAdditionalForm';
import { TransportForm } from '../TransportForm';
import { SuitUIButton } from '../../../../../components/SuitUIButton';
import { ReferencesSchema } from '../schemas/BookingFormValidationSchema';

interface ReferencesProps extends BookingFormProps {
    activeStep: number;
    setActiveStep: (val: number) => void;
    showAdditionalSection: AddtionalSections;
    setShowAdditionalSection: (val: AddtionalSections) => void;
    setShowAddParty: React.Dispatch<React.SetStateAction<boolean>>;
}

const References = ({
    formData,
    setFormData,
    activeStep,
    setActiveStep,
    formik,
    showAdditionalSection,
    setShowAdditionalSection
}: ReferencesProps) => {
    const handleNext = () => {
        try {
            ReferencesSchema.validateSync(formik.values, {
                abortEarly: false
            });

            setActiveStep(activeStep + 1);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } catch (err) {
            formik.setErrors(yupToFormErrors(err));
        }
    };
    return (
        <div>
            <div className='border-b border-dashed border-gray300 pb-4'>
                <ReferencesForm
                    {...{
                        formData,
                        setFormData
                    }}
                />

                <ShowHideSection
                    {...{
                        show: showAdditionalSection.references,
                        title: 'Additional References',
                        onHandleClick: () =>
                            setShowAdditionalSection({
                                ...showAdditionalSection,
                                references: !showAdditionalSection.references
                            })
                    }}
                />

                <div className='mt-1'>
                    {showAdditionalSection.references && (
                        <ReferencesAdditionalForm
                            {...{
                                formData,
                                setFormData
                            }}
                        />
                    )}
                </div>
            </div>

            <div className='mt-4 pb-1 mb-[8px] border-b border-dashed border-gray300'>
                <p>Transport</p>
            </div>

            <div className='mt-1'>
                <TransportForm
                    {...{
                        formData,
                        setFormData,
                        formik
                    }}
                />
            </div>

            <div className='flex gap-[16px] items-center justify-end mt-8 lg:mb-0'>
                <SuitUIButton
                    text='Back'
                    onClick={() => setActiveStep(activeStep - 1)}
                    invert
                    fullWidth
                    style='px-[0.92rem] border border-gray300 text-steelBlue font-medium text-sm leading-[20px] lg:border-none lg:text-appcolorprimary'
                />

                <SuitUIButton
                    text='Continue'
                    onClick={handleNext}
                    fullWidth
                    style='px-[0.92rem]'
                />
            </div>
        </div>
    );
};

export default References;
