import { Box, Divider, Stack } from '@mui/material';
import { PDFIcon } from '../../../components/icons/PDFIcon';
import { CurrentUserProfile } from '../../../interfaces/User';
import BusinessDocumentViewer from './BusinessDocumentViewer';
import { FileIcon } from '../../../components/icons/FileIcon';

const ProfileDocuments = ({
    userDetails
}: {
    userDetails: CurrentUserProfile;
}) => {
    const { business } = userDetails;

    const getDocumentNameAndExtension = (url: string) => {
        const parts = url.split('/');
        const lastPart = parts[parts.length - 1];
        const [filename, query] = lastPart.split('?');
        const [name, extension] = filename.split('.');
        return { name, extension };
    };

    return (
        <Stack
            direction='column'
            className=' bg-white max-w-[618px] lg:p-[16px] px-[16px] mt-12 lg:mt-3 py-[0px] rounded-[8px] lg:border-[1px] border-appcolorfaintnine '
            gap='5px'
        >
            <Stack
                justifyContent='space-between'
                alignItems='center'
                direction='row'
            >
                <h3 className='text-deepMidnightBlue text-[16px] font-[500]'>
                    Documents & Photos
                </h3>
            </Stack>
            <Divider orientation='horizontal' className='py-1' />

            <h3 className='text-steelBlue text-[14px] py-2 font-[500]'>
                Form of Identification
            </h3>

            {business && business?.documents?.length > 0 && (
                <Stack gap='10px' className=' lg:flex-row flex-col'>
                    {business?.documents.map((document) => (
                        <div key={document.id}>
                            <p className='text-paleSky text-[14px] py-2 font-[400]'>
                                {document.name}
                            </p>
                            <Box className=' lg:min-h-[70px] min-h-[50px] p-2 flex items-center justify-between border-[1px] border-appcolorgreyfaintfive bg-aliceBlue rounded-md'>
                                <Box className='flex w-full gap-2 items-center justify-between pr-2'>
                                    <Box className='flex items-center'>
                                        {' '}
                                        {getDocumentNameAndExtension(
                                            document.url
                                        ).extension === 'pdf' ? (
                                            <PDFIcon />
                                        ) : (
                                            <FileIcon />
                                        )}
                                        <Box className='pl-8 col-span-9'>
                                            <p className='text-ebony text-[12.5px] font-[500]'>
                                                {getDocumentNameAndExtension(
                                                    document.url
                                                ).name.substring(0, 20) + '...'}
                                            </p>
                                        </Box>
                                    </Box>
                                    <BusinessDocumentViewer
                                        url={document.url}
                                        ext={
                                            getDocumentNameAndExtension(
                                                document.url
                                            ).extension
                                        }
                                    />
                                </Box>
                            </Box>
                        </div>
                    ))}
                </Stack>
            )}
        </Stack>
    );
};

export default ProfileDocuments;
