import { Box } from '@mui/material';

export const GoodsInTransitHome = () => {
    return (
        <Box
            className='text-[#425466]'
            sx={{ mb: '26px', fontWeight: 400, fontSize: '18px' }}
        >
            Insure the in-land transit of your goods online at 100%.
        </Box>
    );
};
