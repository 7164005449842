import { useState } from 'react';
import dayjs from 'dayjs';
import { Dropdown, MenuProps, Table } from 'antd';
import { NoDataCross } from '../../../components/icons/NoDataCross';
import { BeneficiaryTableProps } from './interfaces/PaymentTable';
import { openNotification } from '../../../utils/helper';
import { MoreIcon } from '../../../components/icons/MoreIcon';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import { hasPermission } from '../../../components/dashboard/dashboardpage/utils';
import Customtable from '../../../components/customtable/customtable';
import UserBeneficiaryTableMobileCard from './UserBeneficiaryTableMobileCard';
import { SuitUIButton } from '../../../components/SuitUIButton';
import { PlusIcon } from '../../../components/icons/PlusIcon';
import { ConfirmDeleteBeneficiary } from './ConfirmDeleteBeneficiary';
import { Beneficiary } from './interfaces/Beneficiary';

const UserBeneficiariesTable = ({
    isBeneficiariesLoading,
    beneficiaries,
    setShowAddBeneficiary,
    setBeneficiaryId
}: BeneficiaryTableProps) => {
    const { permissions } = useUserProfile();

    const [selected, setSelected] = useState<Beneficiary | null>(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleEdit = (id: number) => {
        setBeneficiaryId(id);
        if (
            permissions &&
            hasPermission({
                userPermissions: permissions,
                module: 'payments',
                permissionList: ['write', '*']
            })
        ) {
            setShowAddBeneficiary(true);
        } else {
            openNotification(
                'error',
                'You do not have access to perform this action'
            );
        }
    };

    const handleDelete = (item: Beneficiary) => {
        setSelected(item);
        setOpenDeleteModal(true);
    };

    const getMenu = (record: Beneficiary): MenuProps['items'] => [
        {
            key: '1',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => handleEdit(record.id)}
                >
                    <span
                        className={`ml-2 ${
                            permissions &&
                            !hasPermission({
                                userPermissions: permissions,
                                module: 'payments',
                                permissionList: ['write', '*']
                            }) &&
                            ` opacity-30 cursor-not-allowed`
                        }`}
                    >
                        Edit
                    </span>
                </div>
            )
        },
        {
            key: '2',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => handleDelete(record)}
                >
                    <span
                        className={`ml-2 ${
                            permissions &&
                            !hasPermission({
                                userPermissions: permissions,
                                module: 'payments',
                                permissionList: ['write', '*']
                            }) &&
                            ` opacity-30 cursor-not-allowed`
                        }`}
                    >
                        Delete
                    </span>
                </div>
            )
        }
    ];

    return (
        <>
            <ConfirmDeleteBeneficiary
                item={selected}
                open={openDeleteModal}
                onClose={() => setOpenDeleteModal(false)}
            />

            <div className='hidden lg:block'>
                <Table
                    loading={isBeneficiariesLoading}
                    pagination={{ pageSize: 20 }}
                    dataSource={beneficiaries}
                    locale={{
                        emptyText: () => {
                            return (
                                <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                                    <div className='flex justify-center mb-4'>
                                        <NoDataCross />
                                    </div>

                                    <p className='text-center text-appcolorblackthree'>
                                        You have no saved beneficiary.
                                        <br />
                                        <span
                                            className='cursor-pointer text-appcolorprimary'
                                            onClick={() => {
                                                setShowAddBeneficiary(true);
                                            }}
                                        >
                                            Add a new beneficiary
                                        </span>{' '}
                                        to get started.
                                    </p>
                                </div>
                            );
                        }
                    }}
                    columns={[
                        {
                            title: 'Date',
                            dataIndex: 'createdDate',
                            key: 'createdDate',
                            render: (_, { createdDate }) => (
                                <span>{dayjs(createdDate).format('ll')}</span>
                            )
                        },
                        {
                            title: 'Account Name',
                            dataIndex: 'beneficiaryCompanyName',
                            key: 'beneficiaryCompanyName'
                        },
                        {
                            title: 'Acc No',
                            dataIndex: 'accountNumber',
                            key: 'accountNumber'
                        },
                        {
                            title: 'Bank Name',
                            dataIndex: 'bankName',
                            key: 'bankName'
                        },
                        {
                            title: 'Currency',
                            dataIndex: 'currencyCode',
                            key: 'currencyCode'
                        },
                        {
                            title: 'Country',
                            dataIndex: 'beneficiaryCountryName',
                            key: 'beneficiaryCountryName'
                        },
                        {
                            title: 'Labels/Tags',
                            dataIndex: 'beneficiaryEntityType',
                            key: 'beneficiaryEntityType'
                        },
                        {
                            title: '',
                            render: (_, record) => (
                                <Dropdown
                                    menu={{
                                        items: getMenu(record)
                                    }}
                                    trigger={['click']}
                                >
                                    <div
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <MoreIcon />
                                    </div>
                                </Dropdown>
                            )
                        }
                    ]}
                />
            </div>

            <div className='lg:hidden pb-[40px] px-[16px] '>
                <SuitUIButton
                    text='Add beneficiary'
                    onClick={() => setShowAddBeneficiary(true)}
                    invert
                    fullWidth
                    iconLeft={<PlusIcon color='#0088B2' />}
                    style='border border-gray300 text-steelBlue font-medium text-sm leading-[20px] my-[16px]'
                />

                <Customtable
                    data={beneficiaries}
                    loadingData={isBeneficiariesLoading}
                    multiTableTiles={[]}
                    mobileComponent={
                        <UserBeneficiaryTableMobileCard
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                        />
                    }
                />
            </div>
        </>
    );
};

export default UserBeneficiariesTable;
