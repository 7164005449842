import React, { FC } from 'react';
import { Stack } from '@mui/material';
import moment from 'moment';
import { Table } from 'antd';
import { REACT_APP_BASE_URL } from '../../../../config';
import DocumentViewer from '../../../DocumentViewer/DocumentViewer';

interface CustomTableProps {
    data: any;
    docType: string;
    tableData?: any;
}

const SalesView: FC<CustomTableProps> = ({ data, docType }) => {
    return (
        <>
            <Stack
                direction='row'
                alignItems='start'
                className='top-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='font-semibold text-appcolorprimary'>Buyer</p>
                    <p className='font-medium'>{data?.buyer?.name || '--'}</p>
                    <p>{data?.buyerAddress || '--'}</p>
                </div>

                <div className='view-two'>
                    <p className='font-semibold text-appcolorprimary'>
                        Exporter
                    </p>
                    <p className='font-medium'>
                        {data?.exporter?.name || '--'}
                    </p>
                    <p>{data?.exporterAddress || '--'}</p>
                </div>

                <div className='view-three'>
                    <div></div>
                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Contract No.</p>
                        <p className='value'>
                            {data?.salesContractNumber || '--'}
                        </p>
                    </Stack>
                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Contract Type.</p>
                        <p className='value'>{data?.contractType || '--'}</p>
                    </Stack>
                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Payment Terms</p>
                        <p className='value'>{data?.paymentTerms || '--'}</p>
                    </Stack>
                    <Stack
                        direction='row'
                        gap='20px'
                        width='100%'
                        justifyContent='space-between'
                    >
                        <p className='title'>Shipping Deadline</p>
                        <p className='value'>
                            {moment(data.shippingDeadline).format('LL') || '--'}
                        </p>
                    </Stack>
                </div>
            </Stack>

            <Stack
                direction='row'
                alignItems='start'
                className='mid-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                <div className='view-one'>
                    <p className='title'>Container Type</p>
                    <p className='value'>{data?.containerType || '--'}</p>

                    <p className='title'>Port of Dispatch</p>
                    {data?.portsOfDestination?.map((item: any) => (
                        <p className='value'>{item?.name || '--'}</p>
                    ))}
                </div>

                <div className='view-one'>
                    <p className='title'>Port of Loading</p>
                    <p className='value'>{data?.portOfLoading?.name || '--'}</p>

                    <p className='title'>Shipping Lines</p>
                    {data?.shippingLines?.map((item: any) => (
                        <p className='value'>{item?.name || '--'}</p>
                    ))}
                </div>
            </Stack>

            <div className='table-view'>
                <Table
                    rowKey={'id'}
                    columns={[
                        {
                            title: 'Commodity',
                            dataIndex: 'name',
                            key: 'name'
                        },
                        {
                            title: 'Commodity Type',
                            dataIndex: 'type',
                            key: 'type'
                        },
                        {
                            title: 'Quality',
                            dataIndex: 'quality',
                            key: 'quality'
                        },

                        {
                            title: 'Quantity',
                            dataIndex: 'quantity',
                            key: 'quantity'
                        },
                        {
                            title: 'Unit Metric',
                            dataIndex: 'metric',
                            key: 'metric'
                        },
                        {
                            title: 'Quantity Buffer',
                            dataIndex: 'quantityBuffer',
                            key: 'quantityBuffer',
                            render: (text) => <span>{text}%</span>
                        }
                    ]}
                    dataSource={data?.commodities}
                    pagination={false}
                />
            </div>

            <Stack
                direction='row'
                alignItems='start'
                className='sales-info-preview-content'
                width='full'
                justifyContent={'space-between'}
            >
                {data?.additionalInfo && (
                    <div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data?.additionalInfo
                            }}
                        />
                    </div>
                )}
            </Stack>

            <Stack
                direction='row'
                alignItems='start'
                className='bottom-preview-content'
                width='full'
                justifyContent={'space-between'}
                style={{ position: 'relative', left: 0, right: 0 }}
            >
                <div className='view-one'>
                    {data?.stamp && (
                        <Stack direction='row' justifyContent='flex-end'>
                            <div className='totalView w-[250px]'>
                                <p className='titl'>Exporter Stamp</p>
                                <div className='h-[50px] w-[100px] img'>
                                    <DocumentViewer
                                        fileUrl={`${REACT_APP_BASE_URL}/${data?.stamp.split(
                                            '/v1/'
                                        )[1]}`}
                                    />
                                </div>
                            </div>
                        </Stack>
                    )}
                </div>

                <div className='view-two'>
                    <p className='titl'>Exporter Signature</p>
                    {data?.exporterSignature && (
                        <div className='h-[50px] w-[100px] img'>
                            <DocumentViewer
                                fileUrl={`${REACT_APP_BASE_URL}/${data?.exporterSignature.split(
                                    '/v1/'
                                )[1]}`}
                            />
                        </div>
                    )}
                </div>

                <div className='view-three'>
                    <p className='titl'>Buyer Signature</p>
                    {data?.buyerSignature && (
                        <div className='h-[50px] w-[100px] img'>
                            <DocumentViewer
                                fileUrl={`${REACT_APP_BASE_URL}/${data?.buyerSignature.split(
                                    '/v1/'
                                )[1]}`}
                            />
                        </div>
                    )}
                </div>
            </Stack>
        </>
    );
};

export default SalesView;
