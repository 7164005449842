import { ArrowLeftIcon } from './icons';

interface MobilePaginationProps {
    page: number;
    setPage: (val: number) => void;
    totalPages: number;
}

const MobilePagination = ({
    page,
    setPage,
    totalPages
}: MobilePaginationProps) => {
    return (
        <div className='flex justify-between items-center px-[16px] pt-[40px] pb-[80px]'>
            <button
                className='w-[36px] h-[36px] rounded-[8px] flex justify-center items-center border border-gray300 shadow-[0px 1px 2px 0px #1018280D]'
                onClick={() => setPage(page - 1)}
                disabled={page === 1}
            >
                <ArrowLeftIcon />
            </button>
            <p className='text-steelBlue text-sm'>
                Page <span className='font-semibold'>{page}</span> of{' '}
                <span className='font-semibold'>{totalPages}</span>
            </p>

            <button
                className='w-[36px] h-[36px] rounded-[8px] flex justify-center items-center border border-gray300 shadow-[0px 1px 2px 0px #1018280D] rotate-180'
                onClick={() => setPage(page + 1)}
                disabled={page === totalPages}
            >
                <ArrowLeftIcon />
            </button>
        </div>
    );
};

export default MobilePagination;
