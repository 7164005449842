
import { useGetQuery } from './useGetQuery';
import { Booking } from '../pages/dashboard/bookings/interfaces/Booking';

interface GetBookingsParams{
    bookingId: string;
    corporateId?: number;
}

export const useBookings = () => {
    const {
        isLoading,
        entries: bookings,
        isFetching,
        refetch,
    } = useGetQuery<Booking[]>({
        url: '/booking',
        cacheKey: `bookings`,
    });

    return {
        isLoading,
        bookings,
        isFetching,
        refetch
    };
};

export const useBookingByID = (params: GetBookingsParams) => {
    const {
        isLoading,
        entries: booking,
        isFetching,
        refetch,
    } = useGetQuery<Booking>({
        url: `/booking/${params.bookingId}/corporate/${params.corporateId}`,
        cacheKey: `booking`,
    });

    return {
        isLoading,
        booking,
        isFetching,
        refetch
    };
};

