import { Autocomplete, TextField, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useEffect, useState } from 'react';

import useBeneficiaryShippingLines from '../customhooks/useBeneficiaryShippingLines';
import { BeneficiaryShippingLines } from '../interfaces/User';

export const InvoiceCarrierAgentsSelect = (props: {
    value?: number;
    setShowAddNewCarrierModal?: React.Dispatch<React.SetStateAction<boolean>>;
    onHandleChange?: (value: BeneficiaryShippingLines) => void;
    isError?: boolean;
    label?: string;
}) => {
    const {
        onHandleChange,
        setShowAddNewCarrierModal,
        isError,
        value,
        label = 'Carrier/Booking Agent'
    } = props;
    const { user } = useSelector((state: RootState) => ({
        user: state.user.user
    }));

    const {
        isLoading: isLoadingBeneficiaryShippingLines,
        beneficiaryShippingLines
    } = useBeneficiaryShippingLines();

    const [selectedConnection, setSelectedConnection] =
        useState<BeneficiaryShippingLines>({} as BeneficiaryShippingLines);
    const lastBooking = [...beneficiaryShippingLines].pop();
    const carrier = new URLSearchParams(location.search).get('carrier');

    useEffect(() => {
        const connection = beneficiaryShippingLines.find(
            (connection) =>
                connection?.beneficiary?.id === Number(carrier) ||
                connection?.beneficiary?.id === value
        );

        if (connection) {
            setSelectedConnection(connection);
        }
    }, [value, beneficiaryShippingLines]);

    return (
        <Autocomplete
            autoComplete
            disableClearable
            sx={{
                '.MuiInputBase-input': {
                    height: '0.3rem'
                },
                background: 'white'
            }}
            options={beneficiaryShippingLines}
            onChange={(_, value) => {
                setSelectedConnection(value);

                if (onHandleChange) {
                    onHandleChange(value);
                }
            }}
            renderInput={(params) => (
                <TextField {...params} label={label} required error={isError} />
            )}
            key={selectedConnection?.beneficiary?.id}
            disabled={!!carrier}
            value={selectedConnection}
            getOptionLabel={(option) => option.name || ''}
            loading={isLoadingBeneficiaryShippingLines}
            renderOption={(props, { name }, state) => (
                <Box key={state.index}>
                    <Box component='li' {...props}>
                        {name}
                    </Box>
                    {user?.booking &&
                        setShowAddNewCarrierModal &&
                        lastBooking?.name === name && (
                            <Box
                                className='cursor-pointer p-2 text-appcolorprimary'
                                onMouseDown={() =>
                                    setShowAddNewCarrierModal(true)
                                }
                            >
                                + Add a New Carrier
                            </Box>
                        )}
                </Box>
            )}
        />
    );
};
