import React, { useState } from 'react';
import {
    contentStyle,
    headingStyle,
    menuStyle,
    subStyle
} from '../tradeFilter/filterStyle';
import { Button, Divider, Dropdown, MenuProps, Select, Space } from 'antd';
import { Tooltip } from '@mui/material';
import { filter } from '../sales/contracts/FilterContract';
import CustomModal from '../../CustomModal';
import { SuitUIButton } from '../../SuitUIButton';
import { FilterIcon } from '../../icons';

const FilterWarehouse = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const handleMenuClick = () => {
        setDropdownVisible(false);
        setModalVisible(false);
    };

    const items = [
        {
            key: '1',
            label: (
                <div className='mt-[16px] lg:mt-0'>
                    <label className='inline-block font-medium text-sm text-steelBlue leading-6'>
                        Commodity
                    </label>

                    <Select
                        style={{
                            width: '100%'
                        }}
                        size='large'
                    />
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '2',
            label: (
                <div className='mt-[16px] lg:mt-0'>
                    <label className='inline-block font-medium text-sm text-steelBlue leading-6'>
                        Commodity Type
                    </label>

                    <Select
                        style={{
                            width: '100%'
                        }}
                        size='large'
                    />
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '3',
            label: (
                <div className='mt-[16px] lg:mt-0'>
                    <label className='inline-block font-medium text-sm text-steelBlue leading-6'>
                        Quality
                    </label>

                    <Select
                        style={{
                            width: '100%'
                        }}
                        size='large'
                    />
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '4',
            label: (
                <div className='mt-[16px] lg:mt-0'>
                    <label className='inline-block font-medium text-sm text-steelBlue leading-6'>
                        Status
                    </label>

                    <Select
                        style={{
                            width: '100%'
                        }}
                        size='large'
                    />
                </div>
            )
        }
    ];

    const dropdownItems = items as MenuProps['items'];

    return (
        <>
            <div className='hidden lg:block'>
                <Dropdown
                    menu={{ items: dropdownItems }}
                    trigger={['click']}
                    visible={dropdownVisible}
                    overlayStyle={{
                        pointerEvents: 'auto'
                    }}
                    dropdownRender={(menu) => (
                        <div style={contentStyle}>
                            <h2 style={headingStyle}>Filters</h2>
                            <p style={subStyle}>
                                Filters will be added to the table.
                            </p>
                            {React.cloneElement(menu as React.ReactElement, {
                                style: menuStyle
                            })}
                            <Divider style={{ margin: 0 }} />
                            <Space
                                style={{
                                    padding: '1rem',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}
                            >
                                <Button className='reset-btn'>Reset</Button>
                                <Button className='bg-appcolorprimarydeep text-appcolorwhite border-0 hover:bg-appcolorwhite hover:border-appcolorprimarydeep hover:border  hover:text-appcolorprimary'>
                                    Apply Filter
                                </Button>
                            </Space>
                        </div>
                    )}
                >
                    <Tooltip title='Apply filters' arrow>
                        <button
                            className='tab-buttons gap-2 flex'
                            onClick={() => setDropdownVisible(!dropdownVisible)}
                        >
                            {filter}
                            Filter
                        </button>
                    </Tooltip>
                </Dropdown>
            </div>

            <div className='lg:hidden w-[100vw]'>
                <CustomModal
                    maxWidth='lg'
                    open={modalVisible}
                    onClose={() => setModalVisible(false)}
                    hideCloseIcon
                >
                    <div className='w-full  px-[16px] pt-[40px]'>
                        <h2 className='text-ebony mb-[16px]'>Filters</h2>

                        {items?.map((item) => item?.label)}

                        <div className='flex justify-between pt-[40px] pb-[80px]'>
                            <SuitUIButton
                                text='Cancel'
                                onClick={() => setModalVisible(false)}
                                invert
                                style='h-[40px] border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                            />
                            <div className='flex gap-[24px]'>
                                <SuitUIButton
                                    text='Reset'
                                    onClick={() => {}}
                                    variant='secondary'
                                />
                                <SuitUIButton
                                    text='Apply Filter'
                                    onClick={handleMenuClick}
                                    style='h-[40px]'
                                />
                            </div>
                        </div>
                    </div>
                </CustomModal>

                <button
                    className='flex items-center h-[52px] w-[150px] gap-[16px] text-steelBlue text-base lg:h-[unset] lg:w-[unset] lg:text-black lg:text-sm lg:gap-[unset] lg:mx-2'
                    onClick={() => setModalVisible(true)}
                >
                    <FilterIcon />
                    Filter
                </button>
            </div>
        </>
    );
};

export default FilterWarehouse;
