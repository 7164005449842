import { Dropdown, Table } from 'antd';
import React, { useState } from 'react';
import { getBookingStatus } from '../../../../utils/helper';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { NoDataCross } from '../../../icons/NoDataCross';
import { MoreIcon } from '../../../icons/MoreIcon';
import Customtable from '../../../customtable/customtable';
import BookingsTableMobileCard from '../../../../pages/dashboard/bookings/BookingsTableMobileCard';
import { Booking } from '../../../../pages/dashboard/bookings/interfaces/Booking';

const ContractBookingsTable = ({
    loadingData,
    data
}: {
    loadingData?: boolean;
    data?: { content: Booking[] };
}) => {
    const [currentData, setCurrentData] = useState<Booking | null>(null);
    const navigate = useNavigate();

    const items = [
        {
            key: '1',
            label: (
                <div
                    className='flex items-center'
                    onClick={() => {
                        navigate(`/bookings/${currentData?.id}`);
                    }}
                >
                    <span className='ml-2'>Review Booking Details</span>
                </div>
            )
        }
    ];

    return (
        <>
            <Table
                loading={loadingData}
                pagination={{ pageSize: 20 }}
                dataSource={data?.content || []}
                locale={{
                    emptyText: () => {
                        return (
                            <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                                <div className='flex justify-center mb-4'>
                                    <NoDataCross />
                                </div>

                                <p className='text-center text-appcolorblackthree'>
                                    You have no Bookings on this Contract.
                                    <br />
                                </p>
                            </div>
                        );
                    }
                }}
                columns={[
                    {
                        title: 'Date',
                        dataIndex: 'createdDate',
                        key: 'createdDate',
                        render: (_, record: any) => (
                            <span>
                                {moment(record?.createdDate).format('ll')}
                            </span>
                        )
                    },
                    {
                        title: 'Date of Departure',
                        dataIndex: 'departureDate',
                        key: 'departureDate',
                        render: (_, record: any) => (
                            <span>
                                {moment(record?.dateOfDeparture).format('ll')}
                            </span>
                        )
                    },
                    {
                        title: 'Estimated DOA',
                        dataIndex: 'arrivalDate',
                        key: 'arrivalDate',
                        render: (_, record: any) => (
                            <span>
                                {moment(record?.arrivalDate).format('ll')}
                            </span>
                        )
                    },
                    {
                        title: 'Booking #',
                        dataIndex: 'bookingReference',
                        key: 'bookingReference',
                        render: (text) => (
                            <span className=' text-appcolorprimary'>
                                {text ?? '-'}
                            </span>
                        )
                    },
                    {
                        title: 'Shipping Line',
                        dataIndex: 'shippingLineName',
                        key: 'shippingLineName'
                    },
                    {
                        title: 'Port of Loading',
                        dataIndex: 'portOfLoading',
                        key: 'portOfLoading'
                    },
                    {
                        title: 'Port of Destination',
                        dataIndex: 'portOfDischarge',
                        key: 'portOfDischarge'
                    },

                    {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        render: (_, { status }) => (
                            <span
                                className={`text-[12px] text-semibold p-1 pl-[0.3rem] rounded-[5px]
            ${
                status === 1
                    ? 'text-appcolorgreen bg-appcolorgreenfaint'
                    : status === 2
                    ? 'text-appcolorgreen bg-appcolorgreenfaint'
                    : status === 4
                    ? 'text-appcolorred bg-appcolorfaintred'
                    : status === 3
                    ? 'text-appcolorblack bg-appcolorgreyfaint'
                    : ''
            }
            `}
                            >
                                {getBookingStatus(status)}
                            </span>
                        )
                    },
                    {
                        title: '',
                        render: (_, data) => (
                            <div
                                className='cursor-pointer'
                                onClick={() => {
                                    setCurrentData(data);
                                }}
                            >
                                <Dropdown
                                    menu={{ items }}
                                    trigger={['click']}
                                    placement='bottomRight'
                                >
                                    <MoreIcon />
                                </Dropdown>
                            </div>
                        )
                    }
                ]}
                className='hidden lg:block'
            />
            <div className='lg:hidden'>
                <Customtable
                    data={data?.content ?? []}
                    loadingData={loadingData}
                    multiTableTiles={[]}
                    emptyText='You have no Bookings on this Contract.'
                    mobileComponent={
                        <BookingsTableMobileCard
                            actions={items}
                            setCurrentData={setCurrentData}
                        />
                    }
                />
            </div>
        </>
    );
};

export default ContractBookingsTable;
