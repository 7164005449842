import React, { Dispatch, SetStateAction, useState } from 'react';
import type { DatePickerProps, MenuProps } from 'antd';
import {
    Button,
    DatePicker,
    Divider,
    Dropdown,
    Input,
    Select,
    Space
} from 'antd';
import {
    contentStyle,
    headingStyle,
    menuStyle,
    subStyle
} from '../../tradeFilter/filterStyle';
import { Stack, Tooltip } from '@mui/material';
import { SalesContractParams } from '../../../../pages/dashboard/payments/interfaces/SalesContracts';
import { Commodity } from '../../../../interfaces/Commodity';
import { CorporatePartner } from '../../../../interfaces/CorporatePartner';
import CustomModal from '../../../CustomModal';
import { SuitUIButton } from '../../../SuitUIButton';

interface IProps {
    setFilterOptions?: Dispatch<SetStateAction<SalesContractParams>>;
    filterOptions?: SalesContractParams;
    commoditiesList?: Commodity[];
    corporatePartners?: CorporatePartner[];
}

const FilterContract = ({
    setFilterOptions,
    filterOptions,
    commoditiesList,
    corporatePartners
}: IProps) => {
    const [tempFilterOptions, setTempFilterOptions] =
        useState<SalesContractParams>(filterOptions ?? {});

    const onChangeFrom: DatePickerProps['onChange'] = (date) => {
        setTempFilterOptions({
            ...tempFilterOptions,
            shippingDeadlineStart: date?.format('YYYY-MM-DD HH:mm:ss')
        });
    };
    const onChangeTo: DatePickerProps['onChange'] = (date) => {
        setTempFilterOptions({
            ...tempFilterOptions,
            shippingDeadlineEnd: date?.format('YYYY-MM-DD HH:mm:ss')
        });
    };
    const onOk = (value: DatePickerProps['value']) => {
        console.log('onOk:');
    };

    const items = [
        {
            key: '1',
            label: (
                <div>
                    <h2>Contract Number</h2>
                    <Input
                        type='text'
                        value={tempFilterOptions?.salesContractNumber}
                        style={{
                            marginTop: '.5rem'
                        }}
                        onChange={(e) =>
                            setTempFilterOptions({
                                ...tempFilterOptions,
                                salesContractNumber: e.target.value
                            })
                        }
                    />
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '2',
            label: (
                <div>
                    <h2>Buyer Name</h2>

                    <Select
                        style={{
                            marginTop: '.5rem',
                            width: '100%'
                        }}
                        value={tempFilterOptions?.buyerId}
                        onChange={(value: string) =>
                            setTempFilterOptions({
                                ...tempFilterOptions,
                                buyerId: value
                            })
                        }
                        options={corporatePartners?.map(
                            (item: CorporatePartner) => ({
                                value: item.id,
                                label: item.name
                            })
                        )}
                    />
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '3',
            label: (
                <div>
                    <h2>Commodity</h2>

                    <Select
                        style={{
                            marginTop: '.5rem',
                            width: '100%'
                        }}
                        value={tempFilterOptions?.commodityId}
                        onChange={(value: string) =>
                            setTempFilterOptions({
                                ...tempFilterOptions,
                                commodityId: value
                            })
                        }
                        options={commoditiesList?.map((item: Commodity) => ({
                            value: item.id,
                            label: item.name
                        }))}
                    />
                </div>
            )
        },
        {
            type: 'divider'
        },
        {
            key: '4',
            label: (
                <div>
                    <h2>Shipping Deadline</h2>
                    <Stack
                        direction='row'
                        style={{
                            padding: 0,
                            marginTop: '.5rem',
                            justifyContent: 'space-between',
                            width: '100%',
                            gap: '10px'
                        }}
                    >
                        <DatePicker
                            placeholder='Start Date'
                            onChange={onChangeFrom}
                            showTime={{ format: 'HH:mm' }}
                            format='YYYY-MM-DD'
                            onOk={onOk}
                            className='flex-1'
                        />

                        <DatePicker
                            showTime={{ format: 'HH:mm' }}
                            format='YYYY-MM-DD'
                            placeholder='End Date'
                            onChange={onChangeTo}
                            onOk={onOk}
                            className='flex-1'
                        />
                    </Stack>
                </div>
            )
        }
    ];

    const modalItems = items as MenuProps['items'];

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const handleMenuClick = () => {
        setFilterOptions && setFilterOptions(tempFilterOptions);
        setDropdownVisible(false);
    };

    const handleButtonClick = () => {
        setDropdownVisible(!dropdownVisible);
    };

    return (
        <>
            <div className='hidden lg:block'>
                <Dropdown
                    menu={{ items: modalItems }}
                    trigger={['click']}
                    visible={dropdownVisible}
                    overlayStyle={{
                        pointerEvents: 'auto'
                    }}
                    dropdownRender={(menu) => (
                        <div style={contentStyle}>
                            <h2 style={headingStyle}>Filters</h2>
                            <p style={subStyle}>
                                Filters will be added to the table.
                            </p>
                            {React.cloneElement(menu as React.ReactElement, {
                                style: menuStyle
                            })}
                            <Divider style={{ margin: 0 }} />
                            <Space
                                style={{
                                    padding: '1rem',
                                    justifyContent: 'space-between',
                                    width: '100%'
                                }}
                            >
                                <Button
                                    className='reset-btn'
                                    onClick={() => {
                                        setFilterOptions &&
                                            setFilterOptions({});
                                        setTempFilterOptions({});
                                        setDropdownVisible(false);
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button
                                    className='bg-appcolorprimarydeep text-appcolorwhite border-0 hover:bg-appcolorwhite hover:border-appcolorprimarydeep hover:border  hover:text-appcolorprimary'
                                    onClick={handleMenuClick}
                                >
                                    Apply Filter
                                </Button>
                            </Space>
                        </div>
                    )}
                >
                    <Tooltip title='Apply filters' arrow>
                        <div
                            className='tab-buttons'
                            onClick={handleButtonClick}
                        >
                            {filter}
                            <p style={{ paddingRight: '5px' }}>Filter</p>
                        </div>
                    </Tooltip>
                </Dropdown>
            </div>

            <div className='lg:hidden '>
                <CustomModal
                    maxWidth='lg'
                    open={modalVisible}
                    onClose={() => setModalVisible(false)}
                    hideCloseIcon
                >
                    <div className='w-full px-[16px] pt-[40px]'>
                        <h2 className='text-ebony mb-[16px]'>Filters</h2>

                        <Stack gap={2} direction='column'>
                            {items?.map((item) => item?.label)}
                        </Stack>
                        <div className='flex justify-between pt-[40px] pb-[80px]'>
                            <SuitUIButton
                                text='Cancel'
                                onClick={() => setModalVisible(false)}
                                invert
                                style='h-[40px] border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                            />
                            <div className='flex gap-[24px]'>
                                <SuitUIButton
                                    text='Reset'
                                    onClick={() => {
                                        setFilterOptions &&
                                            setFilterOptions({});
                                        setTempFilterOptions({});
                                        setModalVisible(false);
                                    }}
                                    variant='secondary'
                                />
                                <SuitUIButton
                                    text='Apply Filter'
                                    onClick={() => {
                                        handleMenuClick();
                                        setModalVisible(false);
                                    }}
                                    style='h-[40px]'
                                />
                            </div>
                        </div>
                    </div>
                </CustomModal>

                <Tooltip title='Apply filters' arrow>
                    <div
                        className='tab-buttons flex'
                        onClick={() => setModalVisible(true)}
                    >
                        {filter}
                        <p style={{ paddingLeft: '8px' }}>Filter</p>
                    </div>
                </Tooltip>
            </div>
        </>
    );
};

export default FilterContract;
export const filter = (
    <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M5.76501 11.182H15.9958M3.20731 6.06656H18.5535M8.32272 16.2974H13.4381'
            stroke='black'
            strokeWidth='1.86015'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
