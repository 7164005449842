import { Alert, Grid, MenuItem, TextField } from '@mui/material';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import NewCustomSelect from '../../../newcustomselect/newcustomselect';
import Customdatepicker from '../../../customdatepicker/customdatepicker';
import {
    contractTypes,
    methodsOfPayments
} from '../../../../utils/appconstants';
import SalesAddCommodity from '../common/SalesAddCommodity';
import SingleCommodityFields from '../../../../pages/dashboard/tradedocuments/salescontract/SingleCommodityFields';
import AddNewCommodity from '../../../../pages/dashboard/tradedocuments/addNewCommodity/AddNewCommodity';
import UploadStamp from '../common/UploadStamp';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { textValue } from '../../../../pages/dashboard/tradedocuments/salescontract/textVallue';
import Signature from '../../../signature/signature';
import PortsAutocomplete from '../../../autocompletewithapi/PortsAutocomplete';
import { useGetContainerTypes } from '../../../../pages/dashboard/bookings/createbooking/hooks/useGetContainerTypes';

interface IProps {
    error: any;
    updateField: any;
    payload: any;
    corporatePartners: any;
    loadingCorporatepartners: boolean;
    setShowAddPartner: Dispatch<SetStateAction<boolean>>;
    errors: any;
    shippingLines: any;
    loading: boolean;
    isLoadingShippingLines: boolean;
    commodityFields: any;
    updateCommodityField: any;
    removeCommodityField: any;
    addCommodityField: any;
    commoditiesList: any;
    isLoadingCommodities: boolean;
    signatureUrlBase64Exporter?: any;
    setSignatureUrlBase64Exporter: (value: string) => void;
}

const SalesContractForm: FC<IProps> = ({
    error,
    updateField,
    payload,
    loading,
    errors,
    shippingLines,
    corporatePartners,
    loadingCorporatepartners,
    setShowAddPartner,
    isLoadingShippingLines,
    commodityFields,
    updateCommodityField,
    removeCommodityField,
    addCommodityField,
    commoditiesList,
    isLoadingCommodities,
    setSignatureUrlBase64Exporter,
    signatureUrlBase64Exporter
}: IProps): JSX.Element => {
    const [showAddNewCommodity, setShowAddNewCommodity] = useState(false);

    const closeShowAddParty = () => {
        setShowAddNewCommodity(false);
    };

    const handleTextChange = (html?: any) => {
        updateField('additionalInfo', html);
    };

    const { isLoading, containerTypes } = useGetContainerTypes();

    return (
        <div className='form-container'>
            <div className='border-0 border-appcolorgreyfaint'>
                {error !== null && (
                    <div className='mt-10 mb-4'>
                        <Alert severity='error'>{error}</Alert>
                    </div>
                )}

                <form>
                    <div>
                        <Grid container>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            marginTop: 0
                                        }}
                                    >
                                        <Grid item xs={12} md={12} lg={12}>
                                            <div className='mt-4'>
                                                <NewCustomSelect
                                                    selectValue={
                                                        payload.exporterId
                                                    }
                                                    required
                                                    selectOptions={
                                                        corporatePartners
                                                    }
                                                    name={'customerId'}
                                                    updateField={(_, value) => {
                                                        updateField(
                                                            'exporterId',
                                                            value
                                                        );
                                                        updateField(
                                                            'exporterAddress',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]?.address
                                                        );
                                                        updateField(
                                                            'exporterName',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]?.name
                                                        );

                                                        updateField(
                                                            'exporterCountry',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]?.bankCountry
                                                        );
                                                    }}
                                                    label={'Exporter'}
                                                    selectOptionLabel='name'
                                                    loading={
                                                        loadingCorporatepartners
                                                    }
                                                    dropdownRender={() => {
                                                        return (
                                                            <div className='px-4 border-t border-appcolorgreyfaint'>
                                                                <p
                                                                    className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                                    onClick={() => {
                                                                        setShowAddPartner(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    + Add a New
                                                                    Partner
                                                                </p>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <TextField
                                                value={payload?.exporterAddress}
                                                error={
                                                    errors?.exporterAddress
                                                        ?.length > 0
                                                }
                                                helperText={
                                                    errors?.exporterAddress
                                                        .length > 0
                                                        ? errors?.exporterAddress
                                                        : undefined
                                                }
                                                margin='normal'
                                                fullWidth
                                                id='quotation-exporterAddress'
                                                label='Exporter Address'
                                                name='exporterAddress'
                                                onChange={(e: any) => {
                                                    updateField(
                                                        'exporterAddress',
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            marginTop: 0
                                        }}
                                    >
                                        <Grid item xs={12} md={12} lg={12}>
                                            <div className='mt-4'>
                                                <NewCustomSelect
                                                    selectValue={
                                                        payload.customerId
                                                    }
                                                    required
                                                    selectOptions={
                                                        corporatePartners
                                                    }
                                                    name={'customerId'}
                                                    updateField={(_, value) => {
                                                        updateField(
                                                            'customerId',
                                                            value
                                                        );
                                                        updateField(
                                                            'customerAddress',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]?.address
                                                        );
                                                        updateField(
                                                            'buyerAddress',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]?.address
                                                        );

                                                        updateField(
                                                            'customerName',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]?.name
                                                        );
                                                        updateField(
                                                            'customerBank',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]?.bankName
                                                        );
                                                        updateField(
                                                            'customerSwiftCode',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]?.swiftCode
                                                        );
                                                        updateField(
                                                            'customerCountry',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]?.bankCountry
                                                        );
                                                        updateField(
                                                            'customerBankAccount',
                                                            corporatePartners.filter(
                                                                (item: any) =>
                                                                    item?.id ===
                                                                    value
                                                            )[0]
                                                                ?.bankAccountNumber
                                                        );
                                                    }}
                                                    label={'Buyer'}
                                                    selectOptionLabel='name'
                                                    loading={
                                                        loadingCorporatepartners
                                                    }
                                                    dropdownRender={() => {
                                                        return (
                                                            <div className='px-4 border-t border-appcolorgreyfaint'>
                                                                <p
                                                                    className='px-4 py-2 cursor-pointer text-appcolorprimary'
                                                                    onClick={() => {
                                                                        setShowAddPartner(
                                                                            true
                                                                        );
                                                                    }}
                                                                >
                                                                    + Add a New
                                                                    Partner
                                                                </p>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                            value={payload?.buyerAddress}
                                            error={
                                                errors?.buyerAddress?.length > 0
                                            }
                                            helperText={
                                                errors?.buyerAddress?.length > 0
                                                    ? errors?.buyerAddress
                                                    : undefined
                                            }
                                            margin='normal'
                                            fullWidth
                                            id='quotation-buyerAddress'
                                            label='Buyer Address'
                                            name='buyerAddress'
                                            onChange={(e: any) => {
                                                updateField(
                                                    'buyerAddress',
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={4}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                                    <TextField
                                        value={payload?.contractNumber}
                                        error={
                                            errors?.contractNumber.length > 0
                                        }
                                        helperText={
                                            errors?.contractNumber.length > 0
                                                ? errors?.contractNumber
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='quotation-contractNumber'
                                        label='Contract Number'
                                        name='contractNumber'
                                        onChange={(e: any) => {
                                            updateField(
                                                'contractNumber',
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={4}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2'>
                                    <TextField
                                        value={payload?.contractType}
                                        select
                                        error={errors?.contractType.length > 0}
                                        helperText={
                                            errors?.contractType.length > 0
                                                ? errors?.contractType
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='quotation-methodOfDispatch'
                                        label='Contract Type'
                                        name='contractType'
                                        onChange={(e: any) => {
                                            updateField(
                                                'contractType',
                                                e.target.value
                                            );
                                            updateField(
                                                'salesContractType',
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {contractTypes.map((item, index) => (
                                            <MenuItem
                                                value={item}
                                                key={`medthodsOfDispatch-${item}-${index}`}
                                            >
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={4}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-2 h-full'>
                                    <div className='pt-4'>
                                        <Customdatepicker
                                            id='quotation-shippingDeadline'
                                            label='Shipping Deadline'
                                            name='shippingDeadline'
                                            updateField={updateField}
                                            error={
                                                errors?.shippingDeadline
                                                    .length > 0
                                            }
                                            helperText={
                                                errors?.shippingDeadline
                                                    .length > 0
                                                    ? errors?.shippingDeadline
                                                    : undefined
                                            }
                                            value={
                                                payload?.shippingDeadline !==
                                                undefined
                                                    ? payload?.shippingDeadline
                                                    : null
                                            }
                                        />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={4}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[0.1rem] pb-3 h-full'>
                                    <PortsAutocomplete
                                        errorCondition={
                                            errors?.portOfLoadingId?.length > 0
                                        }
                                        helperText={
                                            errors?.portOfLoadingId?.length > 0
                                                ? errors?.portOfLoadingId
                                                : undefined
                                        }
                                        id='quotation-portOfLoading'
                                        label='Port of Loading'
                                        name='portOfLoadingId'
                                        updateField={updateField}
                                        nameValue='portOfLoadingValue'
                                        value={payload.portOfLoadingValue}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={4}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[1rem] pb-3 h-full'>
                                    <NewCustomSelect
                                        required
                                        selectValue={payload.containerType}
                                        selectOptions={
                                            containerTypes as unknown as Record<
                                                string,
                                                string | number
                                            >[]
                                        }
                                        name={'containerType'}
                                        updateField={(_, value) => {
                                            updateField('containerType', value);
                                        }}
                                        label='Container Type'
                                        selectOptionLabel='shortDescription'
                                        loading={isLoading}
                                        alternativeValue='shortDescription'
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={4}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pb-3 h-full'>
                                    <TextField
                                        select
                                        error={errors?.paymentTerms.length > 0}
                                        helperText={
                                            errors?.paymentTerms.length > 0
                                                ? errors?.paymentTerms
                                                : undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='salesContract-termsOfPayment'
                                        label='Payment Term'
                                        name='paymentTerms'
                                        value={payload?.paymentTerms}
                                        onChange={(e: any) => {
                                            updateField(
                                                'paymentTerms',
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {methodsOfPayments.map(
                                            (item, index) => (
                                                <MenuItem
                                                    value={item}
                                                    key={`paymentTerms-${item}-${index}`}
                                                >
                                                    {item}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem]  pb-3 h-full'>
                                    <PortsAutocomplete
                                        errorCondition={
                                            errors?.portOfDischargeId.length > 0
                                        }
                                        helperText={
                                            errors?.portOfDischargeId.length > 0
                                                ? errors?.portOfDischargeId
                                                : undefined
                                        }
                                        id='quotation-portOfDischargeId'
                                        label='Port of Discharge'
                                        name='portOfDischargeId'
                                        updateField={updateField}
                                        nameValue='portOfDischargeValue'
                                        value={payload.portOfDischargeValue}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[1rem] pb-3 h-full'>
                                    <NewCustomSelect
                                        selectValue={payload.shippingLineId}
                                        required
                                        selectOptions={shippingLines}
                                        name={'shippingLineId'}
                                        updateField={(_, value) => {
                                            updateField(
                                                'shippingLineId',
                                                value
                                            );
                                            updateField(
                                                'shippingLineValue',
                                                shippingLines.filter(
                                                    (item: any) =>
                                                        item?.id === value
                                                )[0]?.name
                                            );
                                        }}
                                        label={'Shipping Line'}
                                        selectOptionLabel='name'
                                        loading={isLoadingShippingLines}
                                    />
                                </div>
                            </Grid>

                            {commodityFields.map((item: any, index: any) => (
                                <SingleCommodityFields
                                    item={item}
                                    key={item.id}
                                    index={index}
                                    id={item.id}
                                    updateQuoteField={updateCommodityField}
                                    price={item.price}
                                    qty={item.qty}
                                    errors={errors}
                                    removeQuoteField={removeCommodityField}
                                    {...{
                                        commoditiesList,
                                        isLoadingCommodities,
                                        setShowAddNewCommodity
                                    }}
                                />
                            ))}

                            <Grid item xs={12} md={12} lg={12}>
                                <div className='border border-appcolorgreyfaint px-[1rem] pt-[1rem] pb-3 h-full'>
                                    <SalesAddCommodity
                                        {...{
                                            addCommodityField
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} className='mt-2'>
                                <p className='py-4 text-appcolorblackthree'>
                                    Additional Information
                                </p>
                                <ReactQuill
                                    theme='snow'
                                    value={payload?.additionalInfo || textValue}
                                    onChange={handleTextChange}
                                    style={{
                                        width: '100%',
                                        minHeight: '300px',
                                        opacity: 0.8
                                    }}
                                    formats={[
                                        'header',
                                        'font',
                                        'size',
                                        'bold',
                                        'italic',
                                        'underline',
                                        'strike',
                                        'blockquote',
                                        'list',
                                        'bullet',
                                        'indent',
                                        'link',
                                        'image',
                                        'video'
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <div className='flex gap-2 items-center mt-4'>
                                    <UploadStamp
                                        {...{ payload, updateField }}
                                        name="Exporter's Stamp Image"
                                    />
                                </div>
                            </Grid>
                            <Grid container spacing={2} className='py-3'>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div className='w-full'>
                                        <Signature
                                            label='Exporter Signature'
                                            name='exporterSignature'
                                            updateField={updateField}
                                            setSignatureUrlBase64={
                                                setSignatureUrlBase64Exporter
                                            }
                                            signatureUrlBase64={
                                                signatureUrlBase64Exporter
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </div>
            {showAddNewCommodity ? (
                <AddNewCommodity
                    closeDrawer={closeShowAddParty}
                    showDrawer={showAddNewCommodity}
                />
            ) : null}
        </div>
    );
};

export default SalesContractForm;
