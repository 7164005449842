import { v4 as uuidv4 } from 'uuid';
import { FormikProps } from 'formik';
import { BookingForm } from './interfaces/BookingForm';
import { Container } from './interfaces/Container';
import { ContainerForm } from './ContainerForm';
import { SuitUIButton } from '../../../../components/SuitUIButton';
export interface ContainerFormSection {
    formData: BookingForm;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
    formik: FormikProps<BookingForm>;
}

export const ContainerFormSection = ({
    formData,
    setFormData,
    formik
}: ContainerFormSection) => {
    return (
        <>
            <div className='flex justify-between items-center pt-4 pb-[4px] border-b border-dashed lg:border-solid border-gray300 lg:border-appcolorgreyfaint lg:mt-8'>
                <p className='text-steelBlue'>Container</p>

                <SuitUIButton
                    text='+ Add Container'
                    variant='secondary'
                    onClick={() => {
                        setFormData((prev) => {
                            return {
                                ...prev,
                                container: [
                                    ...(prev.container || []),
                                    { uid: uuidv4() } as Container
                                ]
                            };
                        });
                    }}
                />
            </div>

            <div className='mt-1'>
                {formData.container?.map((content, index) => (
                    <ContainerForm
                        {...{
                            content,
                            index,
                            setFormData,
                            isRemoveShown: formData.container.length > 1,
                            formik
                        }}
                        key={content.uid}
                    />
                ))}
            </div>
        </>
    );
};
