import { ReactNode, useState, useEffect } from 'react';
import {
    CircularProgress,
    ClickAwayListener,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    TextField,
    useMediaQuery,
    useTheme,
    Box
} from '@mui/material';

import { Input } from 'antd';
import { ChevronDownIcon } from '../icons';

export interface NewCustomSelectProps {
    required?: boolean;
    hasError?: boolean;
    helperText?: string;
    label?: string;
    placeHolder?: string;
    loading?: boolean;
    showSearch?: boolean;
    optionHasValue?: boolean;
    hasGroup?: boolean;
    selectOptions: (string | Record<string, string | number | unknown>)[];
    name: string;
    alternativeValue?: string;
    selectOptionLabel?: string;
    updateField: (name: string, value: string) => void;
    selectValue?: string;
    dropdownRender?: () => ReactNode;
    highLighted?: boolean;
    height?: number;
}

const NewCustomSelect = ({
    loading,
    optionHasValue,
    selectOptions,
    name,
    alternativeValue,
    selectOptionLabel,
    updateField,
    selectValue,
    label,
    required,
    dropdownRender,
    placeHolder,
    height = 48
}: NewCustomSelectProps) => {
    const { breakpoints } = useTheme();
    const isMobile = useMediaQuery(breakpoints.down('md'));

    const [newSelectOption, setNewSelectOption] = useState<
        (string | Record<string, unknown>)[]
    >([]);
    const [keyUp, setKeyUp] = useState(false);
    const [selectedValue, setSelectedValue] = useState<string | undefined>(
        undefined
    );
    const [open, setOpen] = useState(false);
    const [_, setFocused] = useState(true);
    const [inputValue, setInputValue] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (selectOptions !== undefined) {
            setNewSelectOption(selectOptions);
        }
    }, [selectOptions]);

    useEffect(() => {
        if (inputValue !== undefined && inputValue?.trim().length > 0) {
            if (optionHasValue) {
                const searchOptions = (selectOptions as string[]).filter(
                    (item) =>
                        item
                            .toLowerCase()
                            .includes(inputValue.trim().toLowerCase())
                );
                setNewSelectOption(searchOptions);
            } else {
                const filteredArray = selectOptions.filter((obj) =>
                    Object.values(obj).some((val) =>
                        (val as string)
                            .toString()
                            .toLowerCase()
                            .includes(inputValue.trim().toLowerCase())
                    )
                );
                setNewSelectOption(filteredArray);
            }
        } else {
            selectOptions && setNewSelectOption(selectOptions);
        }
    }, [inputValue]);

    useEffect(() => {
        if (optionHasValue) {
            const searchOptions: any = (selectOptions as string[])?.filter(
                (item) =>
                    selectValue &&
                    item
                        .toLowerCase()
                        .includes(selectValue?.trim().toLowerCase())
            );

            if (searchOptions.length > 0) {
                setSelectedValue(searchOptions[0]);
                setInputValue('');
            }
        } else {
            if (alternativeValue) {
                const getChoosen = (
                    selectOptions as Record<string, string>[]
                )?.filter((item) => item[alternativeValue] === selectValue);

                if (getChoosen.length > 0 && selectOptionLabel !== undefined) {
                    setSelectedValue(getChoosen[0][selectOptionLabel]);
                    setInputValue('');
                }
            } else {
                const getChoosen: any[] = selectOptions?.filter(
                    (item: any) => item.id === selectValue
                );

                if (getChoosen.length > 0 && selectOptionLabel !== undefined) {
                    setSelectedValue(getChoosen[0][selectOptionLabel]);
                    setInputValue('');
                }
            }
        }
    }, [loading, selectValue, selectOptions?.length]);

    return (
        <Box
            sx={{
                display: 'block',
                width: '100%',
                '.MuiOutlinedInput-root': {
                    height: `${height}px`,
                    width: '100%',

                    padding: 0,
                    border: `1px solid #D0D5DD`,
                    borderRadius: '8px',
                    color: 'black',
                    boxShadow: {
                        xs: '0px 1px 2px 0px #1018280D'
                    }
                },
                '& .MuiOutlinedInput-root': {
                    border: 'none',
                    width: '100%'
                }
            }}
            className='relative'
        >
            <div>
                {isMobile && (
                    <label className='text-sm font-medium mb-[10px] inline-block text-steelBlue'>
                        {label}
                    </label>
                )}
            </div>
            <ClickAwayListener
                onClickAway={() => {
                    setOpen(false);
                    setInputValue('');
                    setFocused(false);
                }}
            >
                <div className='relative'>
                    {!keyUp || inputValue !== undefined ? (
                        <div
                            className='absolute h-[2.9rem] top-[0.15rem] w-[100%] bg-white'
                            style={{
                                zIndex: 0
                            }}
                        >
                            <Input
                                value={selectedValue}
                                className='w-full h-full'
                                bordered={false}
                            />
                        </div>
                    ) : null}

                    <TextField
                        fullWidth
                        required={required}
                        onKeyDown={() => {
                            setKeyUp(false);
                        }}
                        onKeyUp={() => {
                            setKeyUp(true);
                        }}
                        id={`${name}-select-input`}
                        onChange={(e: any) => {
                            setInputValue(e.target.value);
                        }}
                        value={inputValue}
                        autoComplete='off'
                        onFocus={() => {
                            setOpen(true);
                        }}
                        focused={
                            open
                                ? inputValue !== undefined &&
                                  selectedValue !== undefined
                                : false
                        }
                        InputLabelProps={{
                            shrink: selectedValue !== undefined ? true : false
                        }}
                        label={isMobile ? '' : label}
                        placeholder={placeHolder}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    {loading ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <IconButton
                                            aria-label='delete'
                                            onClick={() => {
                                                setOpen(!open);
                                            }}
                                            className={`${
                                                open ? 'rotate-180' : ''
                                            } pr-[4px]`}
                                        >
                                            <ChevronDownIcon />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            )
                        }}
                    />

                    {open ? (
                        <div
                            className='absolute w-full bg-appcolorwhite left-[0] overflow-hidden
              outline-0 z-[5050]
              '
                            style={{
                                boxShadow:
                                    '15px 30px 40px rgba(64, 25, 109, 0.08)',
                                maxHeight: '200px',
                                overflowY: 'scroll',
                                border: '1px solid rgba(0, 0, 0, 0.1)',
                                marginTop: '0.5rem',
                                borderRadius: '5px'
                            }}
                        >
                            <List>
                                {newSelectOption?.length === 0 ? (
                                    <ListItem>
                                        <ListItemButton>
                                            There are no options available
                                        </ListItemButton>
                                    </ListItem>
                                ) : null}
                                {newSelectOption?.map(
                                    (item: any, index: number) => (
                                        <ListItem
                                            key={`select-id-${index}-${name}`}
                                            onClick={() => {
                                                const choosen = optionHasValue
                                                    ? item
                                                    : alternativeValue
                                                    ? item[alternativeValue]
                                                    : item.id;

                                                updateField(name, choosen);

                                                setInputValue('');

                                                setSelectedValue(
                                                    selectOptionLabel !==
                                                        undefined
                                                        ? item[
                                                              selectOptionLabel
                                                          ]
                                                        : item
                                                );

                                                setNewSelectOption(
                                                    selectOptions
                                                );

                                                setKeyUp(false);

                                                setOpen(false);
                                            }}
                                            sx={{
                                                padding: '0'
                                            }}
                                        >
                                            <ListItemButton>
                                                {selectOptionLabel !== undefined
                                                    ? item[selectOptionLabel]
                                                    : item}
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                )}
                                {dropdownRender !== undefined
                                    ? dropdownRender?.()
                                    : null}
                            </List>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </ClickAwayListener>
        </Box>
    );
};

NewCustomSelect.defaultProps = {
    required: false,
    selectOptions: [],
    showSearch: false,
    optionHasValue: false,
    loading: false,
    hasGroup: false,
    highLighted: false
};

export default NewCustomSelect;
