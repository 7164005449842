export const RoundedArrowIcon = () => (
    <svg
        width='24'
        height='25'
        viewBox='0 0 24 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            opacity='0.4'
            d='M12 22.085C17.5228 22.085 22 17.6078 22 12.085C22 6.56211 17.5228 2.08496 12 2.08496C6.47715 2.08496 2 6.56211 2 12.085C2 17.6078 6.47715 22.085 12 22.085Z'
            fill='#0088B2'
        />
        <path
            d='M16.03 11.555L13.03 8.55496C12.74 8.26496 12.26 8.26496 11.97 8.55496C11.68 8.84496 11.68 9.32496 11.97 9.61496L13.69 11.335H8.5C8.09 11.335 7.75 11.675 7.75 12.085C7.75 12.495 8.09 12.835 8.5 12.835H13.69L11.97 14.555C11.68 14.845 11.68 15.325 11.97 15.615C12.12 15.765 12.31 15.835 12.5 15.835C12.69 15.835 12.88 15.765 13.03 15.615L16.03 12.615C16.32 12.325 16.32 11.845 16.03 11.555Z'
            fill='#0088B2'
        />
    </svg>
);
