const MakeInvoicePaymentIcon = () => {
    return (
        <svg
            width='19'
            height='19'
            viewBox='0 0 19 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M10.5375 12.2302H9.12751C8.13001 12.2302 7.3125 11.3902 7.3125 10.3552C7.3125 10.0477 7.5675 9.79265 7.875 9.79265C8.1825 9.79265 8.4375 10.0477 8.4375 10.3552C8.4375 10.7677 8.74501 11.1052 9.12751 11.1052H10.5375C10.83 11.1052 11.0625 10.8427 11.0625 10.5202C11.0625 10.1152 10.95 10.0551 10.695 9.96515L8.4375 9.17765C7.9575 9.00515 7.3125 8.66016 7.3125 7.55766C7.3125 6.62016 8.05501 5.84766 8.96251 5.84766H10.3725C11.37 5.84766 12.1875 6.68766 12.1875 7.72266C12.1875 8.03016 11.9325 8.28516 11.625 8.28516C11.3175 8.28516 11.0625 8.03016 11.0625 7.72266C11.0625 7.31016 10.755 6.97266 10.3725 6.97266H8.96251C8.67001 6.97266 8.4375 7.23516 8.4375 7.55766C8.4375 7.96266 8.54999 8.02265 8.80499 8.11265L11.0625 8.90015C11.5425 9.07265 12.1875 9.41765 12.1875 10.5202C12.1875 11.4652 11.445 12.2302 10.5375 12.2302Z'
                fill='#0088B2'
            />
            <path
                d='M9.75 12.98C9.4425 12.98 9.1875 12.725 9.1875 12.4175V5.66748C9.1875 5.35998 9.4425 5.10498 9.75 5.10498C10.0575 5.10498 10.3125 5.35998 10.3125 5.66748V12.4175C10.3125 12.725 10.0575 12.98 9.75 12.98Z'
                fill='#0088B2'
            />
            <path
                d='M9.75 17.105C5.3025 17.105 1.6875 13.49 1.6875 9.04248C1.6875 4.59498 5.3025 0.97998 9.75 0.97998C10.0575 0.97998 10.3125 1.23498 10.3125 1.54248C10.3125 1.84998 10.0575 2.10498 9.75 2.10498C5.925 2.10498 2.8125 5.21748 2.8125 9.04248C2.8125 12.8675 5.925 15.98 9.75 15.98C13.575 15.98 16.6875 12.8675 16.6875 9.04248C16.6875 8.73498 16.9425 8.47998 17.25 8.47998C17.5575 8.47998 17.8125 8.73498 17.8125 9.04248C17.8125 13.49 14.1975 17.105 9.75 17.105Z'
                fill='#0088B2'
            />
            <path
                d='M17.25 5.10498C16.9425 5.10498 16.6875 4.84998 16.6875 4.54248V2.10498H14.25C13.9425 2.10498 13.6875 1.84998 13.6875 1.54248C13.6875 1.23498 13.9425 0.97998 14.25 0.97998H17.25C17.5575 0.97998 17.8125 1.23498 17.8125 1.54248V4.54248C17.8125 4.84998 17.5575 5.10498 17.25 5.10498Z'
                fill='#0088B2'
            />
            <path
                d='M13.5 5.85506C13.3575 5.85506 13.215 5.80256 13.1025 5.69006C12.885 5.47256 12.885 5.11256 13.1025 4.89506L16.8525 1.14506C17.07 0.927559 17.43 0.927559 17.6475 1.14506C17.865 1.36256 17.865 1.72256 17.6475 1.94006L13.8975 5.69006C13.785 5.80256 13.6425 5.85506 13.5 5.85506Z'
                fill='#0088B2'
            />
        </svg>
    );
};

export default MakeInvoicePaymentIcon;
