import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { getFormattedAmount } from '../../../shared/getFormattedAmount';
import { WalletSelect } from '../../../components/WalletSelect';
import { PartyNotificationEntry } from '../../../components/PartyNotificationEntry';
import { ArrowBackIos } from '@mui/icons-material';
import { Backdrop, Box, Grid, IconButton, TextField } from '@mui/material';
import Custombutton from '../../../components/custombutton/custombutton';
import { ServiceableInvoice } from './interfaces/Invoice';
import { useGetQuery } from '../../../customhooks/useGetQuery';
import { SalesDocument } from './interfaces/SalesDocuments';
import { useFXRate } from '../../../customhooks/useFXRate';
import { REACT_APP_BASE_URL } from '../../../config';
import DocumentViewer from '../../../components/DocumentViewer/DocumentViewer';
import { LoadingCircles } from '../../../components/icons/LoadingCircles';

const SingleLogisticsPayInvoiceForm = ({
    setInvoicePosition,
    invoicePosition,
    currentId,
    isLast,
    section,
    setSection,
    setInvoices,
    invoices
}: {
    invoicePosition: number;
    setInvoicePosition: Dispatch<SetStateAction<number>>;
    isLast: boolean;
    currentId: string;
    section: number;
    setSection: Dispatch<SetStateAction<number>>;
    setInvoices: Dispatch<SetStateAction<ServiceableInvoice[]>>;
    invoices: ServiceableInvoice[];
}) => {
    const { isLoading: isDetailsLoading, entries: invoiceDetails } =
        useGetQuery<SalesDocument>({
            url: `/sales/documents/${currentId}`,
            cacheKey: `invoice-details-${currentId}`
        });

    const { isFetching, rate } = useFXRate({
        currencyFrom: invoices[invoicePosition]?.transactionCurrency || 'USD',
        currencyTo: invoices[invoicePosition]?.currencyCode,
        enabled: Boolean(invoices[invoicePosition]?.transactionCurrency)
    });

    const { isFetching: isChargeRateFetching, rate: chargeRate } = useFXRate({
        currencyFrom: 'USD',
        currencyTo: invoices[invoicePosition]?.currencyCode || 'USD',
        enabled: Boolean(invoices[invoicePosition]?.currencyCode)
    });

    const { rate: chargeCalculationRate } = useFXRate({
        currencyFrom: invoices[invoicePosition]?.transactionCurrency || 'USD',
        currencyTo: 'USD',
        enabled: Boolean(invoices[invoicePosition]?.currencyCode)
    });

    const updateInvoices = (valueObj: Partial<ServiceableInvoice>) => {
        setInvoices(
            invoices.map((obj) =>
                obj.corporateDocumentId === Number(currentId)
                    ? { ...obj, ...valueObj }
                    : obj
            )
        );
    };

    useEffect(() => {
        const isDuplicate = invoices.some(
            (invoice) => invoice.corporateDocumentId === Number(currentId)
        );

        if (
            invoiceDetails &&
            !Array.isArray(invoiceDetails) &&
            !isDuplicate &&
            !isDetailsLoading
        ) {
            const newInvoiceData: ServiceableInvoice = {
                ...invoiceDetails?.documentData,
                corporateDocumentId: Number(currentId),
                documentUrl: invoiceDetails.documentUrl,
                customerName: invoiceDetails.documentData?.customerName ?? '',
                transactionSource: invoices[invoicePosition]?.transactionSource
            };

            if (isDuplicate) {
                setInvoices((prevInvoices) =>
                    prevInvoices.map((invoice) =>
                        invoice.corporateDocumentId ===
                        newInvoiceData.corporateDocumentId
                            ? newInvoiceData
                            : invoice
                    )
                );
            } else {
                setInvoices((prevInvoices) => [
                    ...prevInvoices,
                    newInvoiceData
                ]);
            }
        }
    }, [invoiceDetails, currentId]);

    useEffect(() => {
        if (rate && invoices[invoicePosition]?.totalAmount) {
            const totalToPay =
                Number(invoices[invoicePosition]?.totalAmount) / rate +
                15 * chargeRate;
            updateInvoices({ totalToPay });
        }
    }, [currentId, rate]);

    const calculateTotalAmountToPay = () => {
        const totalAmountToPay =
            Number(invoices[invoicePosition]?.totalAmount) / rate +
            15 / chargeCalculationRate;
        const walletBalance = Number(invoices[invoicePosition]?.walletBalance);
        const isInsufficientFunds =
            (walletBalance && totalAmountToPay > walletBalance) ||
            walletBalance === 0;

        let helperText = '';
        if (isInsufficientFunds) {
            helperText = `Insufficient funds. Click on "Add Funds" ↗ to fund account.`;
        }

        return {
            totalAmountToPay,
            helperText
        };
    };

    const { totalAmountToPay, helperText } = calculateTotalAmountToPay();

    return (
        <Grid container spacing={1}>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={isDetailsLoading || isFetching}
            >
                <div className='animate-spin'>
                    <LoadingCircles />
                </div>
            </Backdrop>
            <Grid item lg={6} md={12} sm={12}>
                {!isDetailsLoading && invoiceDetails && (
                    <Box className='flex flex-col space-y-5 lg:p-5 mb-10 lg:mb-0 lg:bg-white rounded-lg'>
                        <TextField
                            sx={{
                                background: 'white'
                            }}
                            margin='normal'
                            label='Invoice No.'
                            disabled
                            value={
                                invoices[invoicePosition]?.invoiceNumber || ''
                            }
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            sx={{
                                background: 'white'
                            }}
                            margin='normal'
                            label='Bill of Lading No.'
                            disabled
                            value={
                                invoices[invoicePosition]?.billOfLadingNumber
                            }
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            sx={{
                                background: 'white'
                            }}
                            margin='normal'
                            label='Customer Name'
                            disabled
                            value={invoices[invoicePosition]?.customerName}
                            InputLabelProps={{ shrink: true }}
                        />
                        <WalletSelect
                            value={invoices[invoicePosition]?.transactionSource}
                            onHandleChange={({
                                id,
                                currencyCode,
                                walletBalance
                            }) => {
                                updateInvoices({
                                    transactionSource: id,
                                    transactionCurrency: currencyCode,
                                    walletBalance
                                });
                            }}
                        />
                        {invoices[invoicePosition]?.currencyCode && (
                            <TextField
                                sx={{
                                    background: 'white'
                                }}
                                margin='normal'
                                label='Amount'
                                disabled
                                value={getFormattedAmount({
                                    amount: invoices[invoicePosition][
                                        'totalAmount'
                                    ],
                                    currency:
                                        invoices[invoicePosition]?.currencyCode
                                })}
                                InputLabelProps={{ shrink: true }}
                            />
                        )}

                        <TextField
                            sx={{
                                background: 'white'
                            }}
                            margin='normal'
                            label='Carrier'
                            disabled
                            value={
                                invoices[invoicePosition]?.beneficiary
                                    ?.beneficiaryCompanyName
                            }
                            InputLabelProps={{ shrink: true }}
                        />
                        {invoices[invoicePosition]?.transactionCurrency && (
                            <>
                                <TextField
                                    sx={{
                                        background: 'white'
                                    }}
                                    margin='normal'
                                    label='Booking & processing charges'
                                    disabled
                                    value={`${getFormattedAmount({
                                        amount: 15 * chargeRate,
                                        currency:
                                            invoices[invoicePosition]
                                                .currencyCode ?? 'NGN'
                                    })}`}
                                />

                                <TextField
                                    sx={{
                                        background: 'white'
                                    }}
                                    margin='normal'
                                    label='Exchange rate'
                                    value={`1 ${invoices[invoicePosition]
                                        ?.transactionCurrency} = ${Number(
                                        rate
                                    )} ${invoices[invoicePosition]
                                        ?.currencyCode} <----> ${Number(
                                        1 / Number(rate)
                                    )} ${invoices[invoicePosition]
                                        ?.currencyCode} = 1 ${invoices[
                                        invoicePosition
                                    ]?.transactionCurrency}`}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                />

                                <TextField
                                    sx={{
                                        background: 'white'
                                    }}
                                    error={helperText !== ''}
                                    helperText={helperText}
                                    margin='normal'
                                    label='Total amount to pay'
                                    value={`${getFormattedAmount({
                                        amount: totalAmountToPay,
                                        currency:
                                            invoices[invoicePosition]
                                                ?.transactionCurrency ?? 'NGN'
                                    })}`}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                />
                            </>
                        )}
                        <PartyNotificationEntry
                            onHandleChange={(values) => {
                                updateInvoices({
                                    notifyParties: values.join(',')
                                });
                                invoices[invoicePosition].notifyParties =
                                    values.join(',');
                            }}
                        />
                        <Box className='flex lg:space-x-2 lg:flex-row flex-col mb-4 items-center '>
                            <div className='lg:block hidden'>
                                {invoicePosition > 0 && (
                                    <IconButton
                                        aria-label='previous'
                                        style={{ borderRadius: 0 }}
                                        onClick={() =>
                                            setInvoicePosition(
                                                invoicePosition - 1
                                            )
                                        }
                                    >
                                        <ArrowBackIos />
                                    </IconButton>
                                )}
                            </div>
                            <Custombutton
                                isLoadingButton
                                type='button'
                                variant='contained'
                                buttonText={
                                    isLast
                                        ? 'Proceed to make payment'
                                        : 'Proceed to Next Invoice Detail'
                                }
                                styles={{
                                    padding: '0.75rem 0.62rem',
                                    width: '100%'
                                }}
                                onClickAction={() => {
                                    if (!isLast) {
                                        setInvoicePosition(invoicePosition + 1);
                                        return;
                                    }
                                    setSection(section + 1);
                                }}
                                disabled={
                                    isFetching ||
                                    !invoices[invoicePosition]?.currencyCode ||
                                    !invoices[invoicePosition]
                                        ?.transactionSource
                                }
                            />
                            {invoicePosition > 0 && (
                                <div
                                    className='lg:hidden my-4 font-medium'
                                    onClick={() =>
                                        setInvoicePosition(invoicePosition - 1)
                                    }
                                >
                                    Go back
                                </div>
                            )}
                        </Box>
                    </Box>
                )}
            </Grid>
            <Grid item lg={6} md={12} sm={12} className='hidden lg:block'>
                {invoices[invoicePosition]?.documentUrl && (
                    <DocumentViewer
                        fileUrl={
                            REACT_APP_BASE_URL +
                            invoices[invoicePosition]?.documentUrl?.replace(
                                'v1/',
                                ''
                            )
                        }
                        loader={true}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default SingleLogisticsPayInvoiceForm;
