import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Custombutton from '../../../components/custombutton/custombutton';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { getErrorMessage, openNotification } from '../../../utils/helper';
import { useTheme } from '@mui/material/styles';
import { Cross } from '../../../components/icons/Cross';
import * as Yup from 'yup';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { mutateConfig } from '../../../api/queryBase';
import useBeneficiaries from '../../../customhooks/beneficiaries/useBeneficiaries';
import CountriesDropdown from '../../../components/CountriesDropdown';
import { CurrencySelect } from '../../../components/CurrencySelect';
import CustomTextField from '../../../components/CustomTextInput';
import { SuitUIButton } from '../../../components/SuitUIButton';

interface AddBeneficiaryProps {
    closeDrawer: () => void;
    showDrawer: boolean;
    reRunGetBeneficiaries: () => void;
    beneficiaryId?: number;
    setBeneficiaryId: Dispatch<SetStateAction<number | null>>;
}

export interface AddBeneficiariesValues {
    beneficiaryFirstName: string;
    beneficiaryLastName: string;
    accountNumber: string;
    beneficiaryCompanyName: string;
    country: string;
    currency: string;
    swiftCode: string;
    beneficiaryEmailAddress: string;
    beneficiaryCountryCode: string;
}

const validationSchema = Yup.object().shape({
    beneficiaryFirstName: Yup.string(),
    beneficiaryLastName: Yup.string()
});

const AddBeneficiaryDrawer = ({
    closeDrawer,
    showDrawer,
    beneficiaryId,
    setBeneficiaryId
}: AddBeneficiaryProps) => {
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { beneficiaries } = useBeneficiaries();

    const beneficiaryDetails = beneficiaries?.filter(
        (item) => item.id === beneficiaryId
    )[0];

    const { user } = useSelector((state: RootState) => state.user);
    const [tabValue, setTabValue] = useState('company');

    const mutation = mutateConfig({ method: beneficiaryId ? 'put' : 'post' });
    const { mutate, isLoading } = mutation({
        url: beneficiaryId
            ? `/corporate/${user?.corporate_id}/beneficiary/${beneficiaryId}`
            : `/corporate/${user?.corporate_id}/beneficiary/create`
    });

    const handleSubmit = async (
        values: AddBeneficiariesValues,
        { resetForm }: FormikHelpers<AddBeneficiariesValues>
    ) => {
        if (!user) {
            return;
        }

        mutate(
            { ...values, type: tabValue?.toLowerCase() },
            {
                onSettled: (_, error) => {
                    if (error) {
                        openNotification(
                            'error',
                            getErrorMessage(error) || 'Something went wrong'
                        );
                        return;
                    }
                    openNotification('success', 'Updated successfully');

                    closeDrawer();
                    setBeneficiaryId(null);
                    resetForm();
                }
            }
        );
    };

    const initialValues: AddBeneficiariesValues = {
        beneficiaryFirstName: beneficiaryDetails?.beneficiaryFirstName ?? '',
        beneficiaryLastName: beneficiaryDetails?.beneficiaryLastName ?? '',
        accountNumber: beneficiaryDetails?.accountNumber ?? '',
        beneficiaryCompanyName:
            beneficiaryDetails?.beneficiaryCompanyName ?? '',
        country: beneficiaryDetails?.beneficiaryCountryName ?? '',
        currency: beneficiaryDetails?.currencyCode ?? '',
        swiftCode: beneficiaryDetails?.swiftCode ?? '',
        beneficiaryEmailAddress: '',
        beneficiaryCountryCode: beneficiaryDetails?.beneficiaryCountryCode ?? ''
    };
    useEffect(() => {
        if (beneficiaryDetails) {
            setTabValue(
                beneficiaryDetails?.beneficiaryEntityType.toLowerCase()
            );
        }
    }, [beneficiaryDetails]);

    return (
        <div>
            {showDrawer ? (
                <div
                    className='active bg-appcolorwhite rounded-[50%] top-[1.2rem] right-[32rem] fixed p-4 z-[4000] cursor-pointer'
                    onClick={() => {
                        closeDrawer();
                        setBeneficiaryId(null);
                    }}
                >
                    <Cross />
                </div>
            ) : null}

            <Drawer
                anchor={isMobile ? 'bottom' : 'right'}
                open={showDrawer}
                onClose={() => {
                    closeDrawer();
                    setBeneficiaryId(null);
                }}
                sx={{
                    '.MuiDrawer-paperAnchorRight': {
                        width: {
                            xs: '95%',
                            lg: '30rem'
                        }
                    }
                }}
            >
                <div className='px-[16px] pt-[24px] pb-[80px]  lg:px-[3.2rem] lg:py-[2.1rem]'>
                    <h1 className='text-base text-ebony lg:text-[24px] lg:leading-[23px] lg:text-appcolorblacktwo lg:font-semibold'>
                        {beneficiaryId
                            ? 'Update Beneficiary'
                            : 'Add Beneficiary'}
                    </h1>

                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, isValid, values, setFieldValue }) => (
                            <Form autoComplete='off' noValidate>
                                <div className='flex flex-col w-full gap-[16px]'>
                                    <div className='my-4 hidden lg:block'>
                                        <Tabs
                                            value={tabValue}
                                            onChange={(event, newValue) => {
                                                setTabValue(newValue);
                                                setFieldValue(
                                                    'beneficiaryCompanyName',
                                                    ''
                                                );
                                            }}
                                            textColor='primary'
                                            indicatorColor='primary'
                                            aria-label=''
                                        >
                                            <Tab
                                                value='company'
                                                label='company'
                                                sx={{
                                                    paddingBottom: '0px',
                                                    textTransform: 'capitalize',
                                                    fontSize: '17px',
                                                    fontWeight: '500'
                                                }}
                                            />
                                            <Tab
                                                value='individual'
                                                label='individual'
                                                sx={{
                                                    paddingBottom: '0px',
                                                    textTransform: 'capitalize',
                                                    fontSize: '17px',
                                                    fontWeight: '500'
                                                }}
                                            />
                                        </Tabs>
                                    </div>

                                    <div className='lg:hidden'>
                                        <p className='text-steelBlue text-sm font-medium mt-[40px] mb-[8px]'>
                                            Select beneficiary type
                                        </p>
                                        <div className='flex items-center gap-[24px] mb-[16px]'>
                                            <SuitUIButton
                                                text='Company'
                                                onClick={() => {
                                                    setTabValue('company');
                                                    setFieldValue(
                                                        'beneficiaryCompanyName',
                                                        ''
                                                    );
                                                }}
                                                type='button'
                                                variant={
                                                    tabValue === 'company'
                                                        ? 'primary'
                                                        : 'secondary'
                                                }
                                            />

                                            <SuitUIButton
                                                text='Individual'
                                                onClick={() => {
                                                    setTabValue('individual');
                                                    setFieldValue(
                                                        'beneficiaryCompanyName',
                                                        ''
                                                    );
                                                }}
                                                type='button'
                                                variant={
                                                    tabValue === 'individual'
                                                        ? 'primary'
                                                        : 'secondary'
                                                }
                                            />
                                        </div>
                                    </div>

                                    {tabValue === 'individual' && (
                                        <>
                                            <div>
                                                <Field
                                                    as={CustomTextField}
                                                    error={
                                                        !!errors.beneficiaryFirstName
                                                    }
                                                    helperText={
                                                        errors.beneficiaryFirstName ??
                                                        undefined
                                                    }
                                                    margin='normal'
                                                    required
                                                    fullWidth
                                                    id='addbeneficiary-beneficiaryFirstName'
                                                    label='First Name'
                                                    name='beneficiaryFirstName'
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        setFieldValue(
                                                            'beneficiaryFirstName',
                                                            e.target.value
                                                        );
                                                        setFieldValue(
                                                            'beneficiaryCompanyName',
                                                            `${
                                                                values?.beneficiaryLastName ||
                                                                ''
                                                            } ${e.target.value}`
                                                        );
                                                    }}
                                                />
                                            </div>

                                            <Field
                                                as={CustomTextField}
                                                error={
                                                    !!errors?.beneficiaryLastName
                                                }
                                                helperText={
                                                    errors?.beneficiaryLastName ??
                                                    undefined
                                                }
                                                margin='normal'
                                                required
                                                fullWidth
                                                id='addbeneficiary-beneficiaryLastName'
                                                label='Last Name'
                                                name='beneficiaryLastName'
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    setFieldValue(
                                                        'beneficiaryLastName',
                                                        e.target.value
                                                    );
                                                    setFieldValue(
                                                        'beneficiaryCompanyName',
                                                        `${
                                                            e.target.value || ''
                                                        } ${
                                                            values.beneficiaryFirstName
                                                        }`
                                                    );
                                                }}
                                            />
                                        </>
                                    )}

                                    {tabValue === 'company' && (
                                        <Field
                                            as={CustomTextField}
                                            error={
                                                !!errors.beneficiaryCompanyName
                                            }
                                            helperText={
                                                errors.beneficiaryCompanyName ??
                                                undefined
                                            }
                                            margin='normal'
                                            required
                                            fullWidth
                                            id='addbeneficiary-beneficiaryCompanyName'
                                            label='Company Name'
                                            name='beneficiaryCompanyName'
                                        />
                                    )}

                                    <Field
                                        as={CustomTextField}
                                        error={!!errors.beneficiaryEmailAddress}
                                        helperText={
                                            errors?.beneficiaryEmailAddress ??
                                            undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='addbeneficiary-beneficiaryEmailAddress'
                                        label='Email'
                                        name='beneficiaryEmailAddress'
                                        type='email'
                                    />

                                    <div className='flex flex-col w-full gap-[16px] lg:flex-row'>
                                        <div className='w-full'>
                                            <CountriesDropdown
                                                initialValue={
                                                    values.beneficiaryCountryCode
                                                }
                                                error={!!errors.country}
                                                errorMessage={
                                                    errors?.country ?? undefined
                                                }
                                                onHandleChange={(value) => {
                                                    if (value !== null) {
                                                        setFieldValue(
                                                            'country',
                                                            value.name
                                                        );
                                                        setFieldValue(
                                                            'beneficiaryCountryCode',
                                                            value.countryCode2
                                                        );
                                                    }
                                                }}
                                                {...{
                                                    errors,
                                                    values
                                                }}
                                            />
                                        </div>
                                        <div className='w-full'>
                                            <CurrencySelect
                                                allOptions={true}
                                                onHandleChange={(value) => {
                                                    if (value !== null) {
                                                        setFieldValue(
                                                            'currency',
                                                            value.currencyCode
                                                        );
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <Field
                                        as={CustomTextField}
                                        error={!!errors?.swiftCode}
                                        helperText={
                                            errors?.swiftCode ?? undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='addbeneficiary-swiftCode'
                                        label='SWIFT Code/Sort Code'
                                        name='swiftCode'
                                    />
                                    <Field
                                        as={CustomTextField}
                                        error={!!errors.accountNumber}
                                        helperText={
                                            errors.accountNumber ?? undefined
                                        }
                                        margin='normal'
                                        required
                                        fullWidth
                                        id='addbeneficiary-accountNumber'
                                        label='IBAN/Account Number'
                                        name='accountNumber'
                                    />

                                    <div className='hidden lg:block mt-4'>
                                        <Custombutton
                                            isLoadingButton
                                            loading={isLoading}
                                            type='submit'
                                            fullWidth
                                            variant='contained'
                                            styles={{
                                                padding:
                                                    '0.75rem 0.62rem 1rem 0.93rem'
                                            }}
                                            disabled={!isValid || isLoading}
                                            buttonText={
                                                beneficiaryId
                                                    ? 'Update'
                                                    : 'Add Recipient'
                                            }
                                        />

                                        <div className='mt-4'>
                                            <Custombutton
                                                type='button'
                                                fullWidth
                                                variant='text'
                                                styles={{
                                                    borderColor:
                                                        theme.palette.primary
                                                            .main,
                                                    borderWidth: '1px',
                                                    borderStyle: 'solid',
                                                    padding:
                                                        '0.65rem 0.92rem 0.6rem 0.93rem',
                                                    backgroundColor:
                                                        'transparent',
                                                    color: theme.palette.primary
                                                        .main,
                                                    '&:hover': {
                                                        backgroundColor:
                                                            'transparent',
                                                        color: theme.palette
                                                            .primary.main
                                                    }
                                                }}
                                                buttonText='Cancel'
                                                onClickAction={() => {
                                                    closeDrawer();
                                                    setBeneficiaryId(null);
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className='flex items-center gap-[16px] lg:hidden'>
                                        <SuitUIButton
                                            text='Cancel'
                                            onClick={() => {
                                                closeDrawer();
                                                setBeneficiaryId(null);
                                            }}
                                            type='button'
                                            invert
                                            fullWidth
                                            style='border border-gray300 text-steelBlue font-medium text-sm leading-[20px]'
                                        />

                                        <SuitUIButton
                                            text={
                                                beneficiaryId
                                                    ? 'Update'
                                                    : 'Add Beneficiary'
                                            }
                                            type='submit'
                                            fullWidth
                                            isLoading={isLoading}
                                        />
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Drawer>
        </div>
    );
};

export default AddBeneficiaryDrawer;
