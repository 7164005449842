import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { REACT_APP_BASE_URL } from '../../../config';
import CorporateDetails from './CorporateDetails';
import Custombutton from '../../../components/custombutton/custombutton';
import { updateDocumentByAdmin } from '../../../api/base/base';
import { openNotification } from '../../../utils/helper';
import CustomModal from '../../../components/CustomModal';
import TextField from '@mui/material/TextField';
import { DocumentDrawerProps } from './interface/DocDrawerProps';
import DocumentViewer from '../../../components/DocumentViewer/DocumentViewer';
import { useMediaQuery, useTheme } from '@mui/material';

const DocumentDrawer = ({
    closeDrawer,
    showDrawer,
    documentDetails,
    data
}: DocumentDrawerProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const docs = [
        {
            uri: `${REACT_APP_BASE_URL}/${documentDetails?.documentUrl.split(
                '/v1/'
            )[1]}`
        }
    ];

    const [visible, setVisible] = useState(false);
    const [submiting, setSubmiting] = useState(false);
    const [comment, setComment] = useState('');
    const [activeDocument] = useState(docs[0]);

    const closeVisible = () => {
        setVisible(false);
    };

    const onUpdateDocument = async (approved: boolean) => {
        setSubmiting(true);
        try {
            await updateDocumentByAdmin(data.id, {
                approved,
                message: comment,
                documentId: documentDetails?.documentId
            });
            setSubmiting(false);
            closeDrawer(true);
            setVisible(false);
            openNotification(
                'success',
                `Update Document, Document ${
                    approved ? 'approved' : 'rejected'
                } successfully`
            );
        } catch (error) {
            setSubmiting(false);
            if (error?.response) {
                openNotification(
                    'error',
                    `Update Document, ${
                        error?.response?.data?.message || 'Something went wrong'
                    }`
                );
            } else {
                openNotification(
                    'error',
                    'Update Document, Something went wrong'
                );
            }
        }
    };

    return (
        <div>
            <div
                className='slide-in-right active bg-appcolorwhite rounded-[50%] top-[1.2rem] right-[28.4rem] fixed p-4 z-[3000] cursor-pointer'
                onClick={() => {
                    closeDrawer();
                }}
            >
                <img src='/img/vectors/cross.svg' alt='cross' />
            </div>

            <div className='fixed top-[3rem] left-[10rem] z-[3000] '>
                <div className='flex justify-end mt-4'>
                    <Custombutton
                        variant='contained'
                        styles={{
                            padding: '0.5rem 0.92rem 0.5rem 0.93rem',
                            color: '#CB444A',
                            border: '1px solid #CB444A',
                            backgroundColor: '#FFFFFF',
                            marginRight: '0.5rem',
                            '&:hover': {
                                padding: '0.5rem 0.92rem 0.5rem 0.93rem',
                                color: '#CB444A',
                                border: '1px solid #CB444A',
                                backgroundColor: '#FFFFFF'
                            }
                        }}
                        isLoadingButton
                        disabled={submiting}
                        buttonText='Reject'
                        onClickAction={() => {
                            setVisible(true);
                        }}
                    />

                    <Custombutton
                        variant='contained'
                        styles={{
                            padding: '0.5rem 0.92rem 0.5rem 0.93rem'
                        }}
                        buttonText='Accept'
                        isLoadingButton
                        loading={submiting}
                        disabled={submiting}
                        // size="small"
                        onClickAction={() => {
                            onUpdateDocument(true);
                        }}
                    />
                </div>
            </div>

            <div className='fixed bg-appcolorwhite w-[50%] top-[8rem] left-[10rem] z-[3000] rounded-[3px] pb-4 px-4 pt-2 min-h-[65%] max-h-[65%] overflow-y-scroll'>
                <div className='h-[60vh]'>
                    <DocumentViewer
                        fileUrl={`${REACT_APP_BASE_URL}/${documentDetails?.documentUrl.split(
                            '/v1/'
                        )[1]}`}
                    />
                </div>
            </div>

            <Drawer
                anchor={isMobile ? 'bottom' : 'right'}
                open={showDrawer}
                onClose={() => {}}
                sx={{
                    '.MuiDrawer-paperAnchorRight': {
                        width: {
                            xs: '95%',
                            lg: '30rem'
                        }
                    }
                }}
            >
                <div className='px-[2.2rem] py-[2.1rem]'>
                    <h1 className='text-[24px] leading-[23px] text-appcolorblacktwo font-semibold w-[80%]'>
                        {documentDetails?.documentName}
                    </h1>

                    <div>
                        <CorporateDetails isDrawer={true} data={data} />
                    </div>
                </div>
            </Drawer>

            {visible ? (
                <CustomModal
                    maxWidth='xs'
                    open={visible}
                    onClose={closeVisible}
                    styles={{
                        overflowX: 'hidden',
                        zIndex: 3000
                    }}
                    title='Reject Document'
                    subTitle='Please input the reason for rejecting this document'
                >
                    <div>
                        <TextField
                            multiline
                            rows={2.5}
                            margin='normal'
                            required
                            fullWidth
                            id='quotation-message'
                            label='Comment'
                            name='message'
                            onChange={(e) => {
                                setComment(e.target.value);
                            }}
                        />

                        <div className='flex mt-4'>
                            <div className='grow'>
                                <Custombutton
                                    variant='contained'
                                    styles={{
                                        padding:
                                            '0.5rem 0.92rem 0.5rem 0.93rem',
                                        color: '#CB444A',
                                        border: '1px solid #CB444A',
                                        backgroundColor: '#FFFFFF',
                                        marginRight: '1rem',
                                        width: '100%',
                                        '&:hover': {
                                            padding:
                                                '0.5rem 0.92rem 0.5rem 0.93rem',
                                            color: '#CB444A',
                                            border: '1px solid #CB444A',
                                            backgroundColor: '#FFFFFF'
                                        }
                                    }}
                                    disabled={submiting}
                                    buttonText='Cancel'
                                    onClickAction={() => {
                                        setVisible(false);
                                    }}
                                />
                            </div>

                            <div className='grow'>
                                <Custombutton
                                    isLoadingButton
                                    loading={submiting}
                                    variant='contained'
                                    styles={{
                                        padding:
                                            '0.5rem 0.92rem 0.5rem 0.93rem',
                                        width: '100%',
                                        marginLeft: '1rem'
                                    }}
                                    disabled={submiting || comment.length === 0}
                                    buttonText='Submit'
                                    onClickAction={() => {
                                        onUpdateDocument(false);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </CustomModal>
            ) : null}
        </div>
    );
};

export default DocumentDrawer;
