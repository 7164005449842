import React, { useState } from 'react';
import { BookingContainer } from '../../../../components/dashboard/upsBooking/Styles';
import DedicatedAccountHeader from './DedicatedAccountHeader';
import { DocumentTableContainer } from '../../../../styles/TradeDocStyles';
import DedicatedAccountsTable from './DedicatedAccountsTable';
import { UseDedicatedAccounts } from '../../../../customhooks/UseDedicatedAccounts';

const DedicatedAccounts = () => {
    const breadcrumbs = [
        {
            title: 'Home',
            link: '/dashboard',
            active: false
        },
        {
            title: 'Dedicated Accounts',
            link: '/payments/dedicated-accounts',
            active: true
        }
    ];
    const [activeView, setActiveView] = useState<string>('all');

    const { isLoading: isAccountsLoading, accountsList } = UseDedicatedAccounts(
        {
            page: 1,
            size: 20,
            status:
                activeView === 'pending'
                    ? '2'
                    : activeView === 'approved'
                    ? '1'
                    : undefined
        }
    );

    return (
        <BookingContainer>
            <DedicatedAccountHeader
                {...{ breadcrumbs, setActiveView, activeView }}
            />
            <DocumentTableContainer className='bg-appcolorwhite'>
                <DedicatedAccountsTable
                    {...{ accountsList, isAccountsLoading }}
                />
            </DocumentTableContainer>
        </BookingContainer>
    );
};

export default DedicatedAccounts;
