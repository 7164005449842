import { Box } from '@mui/material';
import { useGetQuery } from '../../../customhooks/useGetQuery';
import { Currency, CurrencySelection } from './interfaces/Currency';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const AccountTypesTabs = ({
    setIsLoading,
    setSelection
}: {
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    setSelection: Dispatch<SetStateAction<CurrencySelection>>;
}) => {
    const { entries: offShoreCurrencies, isLoading: isOffshoreLoading } =
        useGetQuery<Currency[]>({
            url: '/currencies/offShore',
            cacheKey: 'currencies-offshore'
        });
    const { entries: poolCurrencies, isLoading: isPoolLoading } = useGetQuery<
        Currency[]
    >({
        url: '/currencies/pool',
        cacheKey: 'currencies-pool'
    });
    const [selected, setSelected] = useState('pool');
    const [tabs, setTabs] = useState<
        { label: string; currencies: Currency[] }[]
    >([
        { label: 'pool', currencies: poolCurrencies },
        { label: 'dedicated', currencies: offShoreCurrencies }
    ]);

    useEffect(() => {
        const updated = [
            { label: 'pool', currencies: poolCurrencies },
            { label: 'dedicated', currencies: offShoreCurrencies }
        ];
        setTabs(updated);
        setIsLoading(isOffshoreLoading || isPoolLoading);

        const current = updated.find(({ label }) => label === selected);

        if (current) {
            setSelection(current);
        }
    }, [poolCurrencies.length, offShoreCurrencies.length]);

    return (
        <Box className='flex space-x-10'>
            {tabs.map(({ label, currencies }) => (
                <Box
                    key={label}
                    onClick={() => {
                        setSelected(label);
                        setSelection({ label, currencies });
                    }}
                    className={`pb-3 mb-3 cursor-pointer ${
                        label === selected ? 'border-b-2 border-b-black' : ''
                    }`}
                >
                    <span className='capitalize'>{label}</span> Account
                </Box>
            ))}
        </Box>
    );
};
