import { Autocomplete, CircularProgress, MenuItem } from '@mui/material';
import useCurrencies from '../customhooks/useCurrencies';
import { Fragment } from 'react';
import { Currency } from '../pages/dashboard/payments/interfaces/Currency';
import CustomTextField from './CustomTextInput';

export const CurrencySelect = (props: {
    onHandleChange: (value: Currency) => void;
    options?: Currency[];
    allOptions?: boolean;
    label?: string;
}) => {
    const { isLoading: isLoadingCurrencies, currencies } = useCurrencies({
        all: props.allOptions || false
    });
    const { onHandleChange, options = currencies } = props;

    return (
        <Autocomplete
            placeholder='Currency'
            disableClearable
            renderInput={(params) => (
                <CustomTextField
                    name='currency'
                    params={params}
                    label={props?.label ?? 'Select Currency'}
                    placeholder='Currency'
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: isLoadingCurrencies && (
                            <Fragment>
                                <CircularProgress size={20} />
                                {params.InputProps.endAdornment}
                            </Fragment>
                        )
                    }}
                />
            )}
            noOptionsText={'No data available'}
            renderOption={(props, option) => (
                <MenuItem
                    {...props}
                    value={option.currencyCode}
                    key={`countries-${option.id}`}
                >
                    {option.currencyCode}
                </MenuItem>
            )}
            getOptionLabel={(option) => option.currencyCode || ''}
            onChange={(_, value) => onHandleChange(value)}
            options={options}
        />
    );
};
