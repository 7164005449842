import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { customdatepickerProps } from './customdatepicker-inteface';

export interface customdatepickerProps {
    id: string;
    label?: string;
    name: string;
    updateField: (name: string, value: any) => void;
    error?: boolean;
    helperText?: string;
    value?: string | null | number;
    required?: boolean;
}

const Customdatepicker = ({
    id,
    label,
    name,
    updateField,
    error,
    helperText,
    value,
    required
}: customdatepickerProps) => {
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    className='w-full'
                    label={label}
                    value={value === '' ? null : value}
                    onChange={(newValue) => {
                        updateField(name, newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            required={required}
                            id={id}
                            name={name}
                            error={error}
                            helperText={helperText}
                            {...params}
                            sx={{
                                '.MuiSvgIcon-root': {
                                    '.MuiInputAdornment-root': {
                                        color: '#0088B2 !important'
                                    }
                                }
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
        </div>
    );
};

Customdatepicker.defaultProps = {
    label: 'Date',
    required: false
};

export default Customdatepicker;
