import { useQueryClient } from 'react-query';
import { useGetQuery } from './useGetQuery';
import { SalesContracts } from '../pages/dashboard/payments/interfaces/SalesContracts';

interface GetContractsParams {
    corporateId?: number;
    params?: Record<string, string | number | boolean | undefined>;
    enabled?: boolean;
}

export const useSalesContracts = <T = SalesContracts>(
    opts: GetContractsParams
) => {
    const queryClient = useQueryClient();

    const { corporateId, params, enabled = true } = opts;
    const { isLoading, entries, isFetching, paging } = useGetQuery<T[]>({
        url: `/sales/contract/${corporateId}`,
        cacheKey: 'sales-contracts',
        params: { ...params },
        enabled
    });

    const invalidateContracts = () => {
        queryClient.invalidateQueries('sales-contracts');
    };

    return {
        isLoading,
        entries,
        isFetching,
        paging,
        invalidateContracts
    };
};
