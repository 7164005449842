import { http } from '..';
import {
    PointResponse,
    VesselResponse
} from '../../pages/dashboard/ocean-schedules/interfaces/SearchResults';

export interface getOceanSchedulesPayload {
    departureDate?: string;
    direction?: string;
    portFrom?: string;
    portTo?: string;
    portFromName?: string;
    portToName?: string;
    searchWindow?: string;
    vessel?: string;
    carrierId?: string;
}

export interface getVesselSchedulesPayload {
    imo?: number;
}

export const getOceanSchedules = (data: getOceanSchedulesPayload) => {
    return http.get(`/booking/schedules`, {
        params: {
            ...data
        }
    });
};

export const getVesselSchedules = (data: getVesselSchedulesPayload) => {
    return http.get(`/booking/schedules/vessel`, {
        params: {
            imo: data.imo
        }
    });
};

export interface SearchResultsData {
    data: {
        succeeded: boolean;
        errorMessages?: string[];
        data: VesselResponse | PointResponse;
    };
}
