import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import Skeleton from '@mui/material/Skeleton';
import {
    capitalizeTextChar,
    commaNumber,
    fetchCurrencySymbol,
    getCorpotateStatus,
    getCountryFlag,
    openNotification
} from '../../../utils/helper';
import {
    getCACDocuments,
    getCorporateById,
    getDocuments
} from '../../../api/base/base';
import { Tooltip } from 'antd';
import Grid from '@mui/material/Grid';
import DocumentDrawer from './DocumentDrawer';
import CorporateDetails from './CorporateDetails';
import ViewAccounts from '../payments/ViewAccounts';
import { Corporate, UploadedDocuments } from './interface/DocDrawerProps';

const ViewCorporates = () => {
    const navigate = useNavigate();
    const { corporateId } = useParams();
    const [showViewAccounts, setShowViewAccounts] = useState(false);
    const [currentDocumentDetails, setCurrentDocumentDetails] = useState<
        UploadedDocuments
    >();
    const [showDocumentDrawer, setShowDocumentDrawer] = useState(false);
    const [data, setData] = useState<Corporate>();
    const [loadingData, setLoadingData] = useState(false);
    const [uploadingDocs, setUploadingDocs] = useState<UploadedDocuments[]>([]);
    const [loading, setLoading] = useState(false);

    const closeViewAccounts = () => {
        setShowViewAccounts(false);
    };

    const fetchDocuments = async (dataUser: { status: number }) => {
        setLoading(true);
        try {
            let res;
            if (dataUser.status === 1) {
                res = await getDocuments(Number(corporateId) || 0);
            } else {
                res = await getCACDocuments(Number(corporateId) || 0);
            }
            const { data } = res.data;
            setUploadingDocs(data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            if (error?.response) {
                openNotification(
                    'error',
                    `Get Documents, ${
                        error?.response?.data?.message || 'Something went wrong'
                    }`
                );
            } else {
                openNotification(
                    'error',
                    'Get Documents, Something went wrong'
                );
            }
        }
    };

    const fetchCorporateById = async () => {
        try {
            setLoadingData(true);

            const res = await getCorporateById(corporateId || '');

            const { data } = res.data;

            setData(data);

            setLoadingData(false);

            fetchDocuments(data);
        } catch (error) {
            setLoadingData(false);
            if (error?.response) {
                openNotification('error', `Corporates, Something went wrong`);
            } else {
                openNotification('error', `Corporates, Something went wrong`);
            }
        }
    };

    const closeShowDocumentDrawer = (refreshList?: boolean) => {
        setShowDocumentDrawer(false);
        if (refreshList) {
            fetchCorporateById();
        }
    };

    useEffect(() => {
        fetchCorporateById();
    }, []);

    return (
        <div>
            <div className='mb-[1.3rem]'>
                <p
                    className='flex items-center font-medium cursor-pointer w-fit'
                    onClick={() => {
                        navigate('/corporates');
                    }}
                >
                    <img
                        src='/img/vectors/breadcrumbcaretright.svg'
                        alt='caret'
                        className='pr-3'
                    />
                    <span>Back to All Corporates</span>
                </p>
            </div>

            {loadingData ? (
                <Skeleton variant='text' sx={{ fontSize: '8rem' }} />
            ) : (
                <div className='bg-appcolorwhite rounded-[3px] flex items-center py-[1.9rem] px-[1.5rem] mb-8 justify-between'>
                    <div className='flex'>
                        <div className='flex custom-piped view'>
                            <div className='rounded-[50%] w-[3.3rem] h-[3.3rem ] bg-appcolorprimaryfaintthree py-[0.9rem] px-[0.7rem]'>
                                <span className='font-bold text-appcolorprimary text-[18px] leading-[22p] opacity-[0.5]'>
                                    {capitalizeTextChar(
                                        data?.businessName.split(' ')[0]
                                    )}
                                    {capitalizeTextChar(
                                        data?.businessName.split(' ')[1]
                                    )}
                                </span>
                            </div>

                            <div className='pl-[0.9rem] mr-5'>
                                <p className='pb-[0.3rem] text-appcolorblacktwo opacity-[0.6]'>
                                    Corporate Name
                                </p>
                                <p className='font-bold'>
                                    {data?.businessName || '-'}
                                </p>
                            </div>
                        </div>

                        <div className='custom-piped view'>
                            <div className='pl-[0.9rem] mr-5'>
                                <p className='pb-[0.3rem] text-appcolorblacktwo opacity-[0.6]'>
                                    Date Registered
                                </p>
                                <p className='font-bold'>
                                    {moment(
                                        parseInt(data?.createdDate || '')
                                    ).format('ll')}
                                </p>
                            </div>
                        </div>

                        <div className=''>
                            <div className='pl-[0.9rem] mr-5'>
                                <p className='pb-[0.3rem] text-appcolorblacktwo opacity-[0.6]'>
                                    Status
                                </p>
                                <p className='font-bold'>
                                    {getCorpotateStatus(data?.status || 0)}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='flex'>
                        <div className='cursor-pointer w-fit custom-piped view'>
                            <Tooltip
                                title={data?.businessPhoneNumber || 'test'}
                            >
                                <a href={`tel:${data?.businessPhoneNumber}`}>
                                    <img
                                        src='/img/vectors/callview.svg'
                                        alt='svg'
                                        className='w-[1.9rem] h-[1.9rem]'
                                    />
                                </a>
                            </Tooltip>
                        </div>

                        <div className='ml-3 cursor-pointer w-fit custom-piped view'>
                            <Tooltip title={data?.businessEmail || 'test'}>
                                <a href={`mailto:${data?.businessEmail}`}>
                                    <img
                                        src='/img/vectors/mailview.svg'
                                        alt='svg'
                                        className='w-[1.9rem] h-[1.9rem]'
                                    />
                                </a>
                            </Tooltip>
                        </div>

                        <div className='ml-3 cursor-pointer w-fit view'>
                            <img
                                src='/img/vectors/cancelview.svg'
                                alt='svg'
                                className='w-[1.9rem] h-[1.9rem]'
                            />
                        </div>
                    </div>
                </div>
            )}

            <div className={`${loadingData ? 'mt-[0rem]' : 'mt-[3.5rem]'}`}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={5}>
                        {loadingData ? (
                            <div className='mt-[-16rem]'>
                                <Skeleton
                                    variant='text'
                                    sx={{ fontSize: '58rem' }}
                                />
                            </div>
                        ) : (
                            <CorporateDetails data={data} />
                        )}
                    </Grid>
                    <Grid item xs={12} md={12} lg={7}>
                        {loadingData ? (
                            <div>
                                <Skeleton
                                    variant='text'
                                    sx={{ fontSize: '8rem' }}
                                />
                            </div>
                        ) : (
                            <>
                                <div>
                                    <div className='flex items-center justify-between'>
                                        <div className='flex'>
                                            <div
                                                className={`bg-appcolorwhite trapezoid pt-[0.8rem] pb-[0.7rem] pl-[1.6rem] w-fit pr-[5.6rem] cursor-pointer text-[16px] leading-[24px]`}
                                            >
                                                Wallets
                                            </div>
                                        </div>
                                    </div>
                                    <div className='rounded-[3px] bg-appcolorwhite py-[1.5rem] px-[1.8rem] flex items-center justify-between'>
                                        <div className='flex items-center grow custom-piped primary-piped'>
                                            {data !== null &&
                                                data?.summary !== undefined &&
                                                [...data.summary.wallets]
                                                    .splice(0, 2)
                                                    .map((item, index) => (
                                                        <div
                                                            className={`pr-4  ${
                                                                index === 1
                                                                    ? ''
                                                                    : 'payment-currency custom-piped'
                                                            }  ${
                                                                index === 0
                                                                    ? ''
                                                                    : 'pl-8'
                                                            }`}
                                                            key={`user-wallet-${item?.id}`}
                                                        >
                                                            <p className='text-[11px] leading-[14px] mb-2'>
                                                                {getCountryFlag(
                                                                    item?.currencyCode
                                                                )}{' '}
                                                                {
                                                                    item?.currencyCode
                                                                }
                                                            </p>

                                                            <p className='text-[22px] leading-[28px] font-medium text-appcolorblacktwo mb-2'>
                                                                {fetchCurrencySymbol(
                                                                    item?.currencyCode
                                                                )}{' '}
                                                                {commaNumber(
                                                                    item.walletBalance.toFixed(
                                                                        2
                                                                    )
                                                                )}
                                                            </p>
                                                            <p className='text-[10px] leading-[13px]'>
                                                                <span className='text-appcolorblacktwo opacity-[0.45]'>
                                                                    LEDGER
                                                                    BALANCE:{' '}
                                                                </span>{' '}
                                                                {fetchCurrencySymbol(
                                                                    item?.currencyCode
                                                                )}{' '}
                                                                {commaNumber(
                                                                    item.ledgerBalance.toFixed(
                                                                        2
                                                                    )
                                                                )}
                                                            </p>
                                                        </div>
                                                    ))}
                                        </div>

                                        {data !== null &&
                                            data?.summary !== undefined &&
                                            data?.summary.wallets.length >
                                                0 && (
                                                <p
                                                    className='flex items-center cursor-pointer text-appcolorprimary w-fit text-[13px] lleading-[150%]'
                                                    onClick={() => {
                                                        setShowViewAccounts(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    <img
                                                        src='/img/vectors/eyes.svg'
                                                        alt=''
                                                        className='w-[1.8rem] px-3 pr-1'
                                                    />{' '}
                                                    <span>
                                                        View All Wallets
                                                    </span>
                                                </p>
                                            )}
                                    </div>
                                </div>
                            </>
                        )}

                        {loadingData || loading ? (
                            <div className='mt-[-0.5rem]'>
                                <Skeleton
                                    variant='text'
                                    sx={{ fontSize: '8rem' }}
                                />
                            </div>
                        ) : (
                            <>
                                <div className='mt-[1.6rem]'>
                                    <div>
                                        <div className='flex items-center justify-between'>
                                            <div className='flex'>
                                                <div
                                                    className={`bg-appcolorwhite trapezoid pt-[0.8rem] pb-[0.7rem] pl-[1.6rem] w-fit pr-[5.6rem] cursor-pointer text-[16px] leading-[24px]`}
                                                >
                                                    Documents
                                                </div>
                                            </div>
                                        </div>
                                        <div className='bg-appcolorwhite pt-[1.8rem] pb-[1.7rem] pl-[1.6rem] pr-[1.6rem]'>
                                            {loading ? (
                                                <div className='my-[-6rem]'>
                                                    <Skeleton
                                                        variant='text'
                                                        sx={{
                                                            fontSize: '4rem'
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <>
                                                    <div className='flex flex-wrap'>
                                                        {uploadingDocs.map(
                                                            (item) => (
                                                                <div
                                                                    key={`check-doc-${item.itemId}`}
                                                                    className='mb-8 ml-4'
                                                                >
                                                                    <div className='flex'>
                                                                        <div className='mr-[0.6rem]'>
                                                                            {item.uploaded ? (
                                                                                <>
                                                                                    {item.status ===
                                                                                    1 ? (
                                                                                        <img
                                                                                            src='/img/vectors/cdoc.svg'
                                                                                            alt='marked'
                                                                                            className='w-[1.1rem h-[1.1rem]'
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            src='/img/vectors/edoc.svg'
                                                                                            alt='marked'
                                                                                            className='w-[1.1rem h-[1.1rem]'
                                                                                        />
                                                                                    )}
                                                                                </>
                                                                            ) : null}
                                                                        </div>

                                                                        <div>
                                                                            <p className='font-bold text-appcolorfaintsix text-[13px] leading-[16px]  border-b border-appcolorlightgrey pb-[0.5rem]'>
                                                                                {
                                                                                    item.documentName
                                                                                }
                                                                            </p>
                                                                            <div className='flex items-center justify-between pt-4'>
                                                                                <p className='text-appcolorgreydeep  text-[13px] leading-[16px] '>
                                                                                    Document
                                                                                </p>
                                                                                <div
                                                                                    className='w-fit'
                                                                                    onClick={() => {
                                                                                        if (
                                                                                            item.status ===
                                                                                            1
                                                                                        ) {
                                                                                            openNotification(
                                                                                                'warning',
                                                                                                'Corporate document has already being approved'
                                                                                            );
                                                                                        } else {
                                                                                            if (
                                                                                                item.uploaded
                                                                                            ) {
                                                                                                setCurrentDocumentDetails(
                                                                                                    item
                                                                                                );
                                                                                                setShowDocumentDrawer(
                                                                                                    true
                                                                                                );
                                                                                            } else {
                                                                                                openNotification(
                                                                                                    'warning',
                                                                                                    'Corporate document not yet uploaded.'
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src='/img/vectors/vieweyes.svg'
                                                                                        alt=''
                                                                                        className='w-[1.2rem] h-[1.2rem] cursor-pointer'
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Grid>
                </Grid>
            </div>

            {showDocumentDrawer && data && currentDocumentDetails ? (
                <DocumentDrawer
                    closeDrawer={closeShowDocumentDrawer}
                    showDrawer={showDocumentDrawer}
                    documentDetails={currentDocumentDetails}
                    data={data}
                />
            ) : null}

            {showViewAccounts && data ? (
                <ViewAccounts
                    wallets={data?.summary?.wallets}
                    closeDrawer={closeViewAccounts}
                    showDrawer={showViewAccounts}
                />
            ) : null}
        </div>
    );
};

export default ViewCorporates;
