import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Backdrop, Box, Button } from '@mui/material';
import { SearchInput } from '../../../components/SearchInput';
import AdminUserProfileList from './AdminUserProfileList';
import AdminUserPermissions from './AdminUserPermissions';
import InviteUserForm from './modals/InviteUserForm';
import { PlusIcon } from '../../../components/icons/PlusIcon';
import { useUserPermissions } from '../../../customhooks/useUserPermissions';
import { LoadingCircles } from '../../../components/icons/LoadingCircles';
import { CurrentUserProfile, UserPermissions } from '../../../interfaces/User';
import UserEmpty from './UserEmpty';
import { useGetQuery } from '../../../customhooks/useGetQuery';
import { SuitUIButton } from '../../../components/SuitUIButton';
import MobileAdminUserProfileList from './MobileAdminUserProfileList';

const AdminProfileContainer = ({ isAdmin }: { isAdmin: boolean }) => {
    const [viewInviteForm, setViewInviteForm] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [permissionsView, setPermissionsView] = useState(false);

    const { isLoading, usersPermissions, refetch } = useUserPermissions({
        search: searchTerm,
        page: 1,
        size: 20
    });

    const { entries: userDetails } = useGetQuery<CurrentUserProfile>({
        url: '/me',
        cacheKey: 'user-profile'
    });

    const [filteredUsersPermissions, setFilteredUserPermissions] = useState<
        UserPermissions[]
    >([]);
    const [selected, setSelected] = useState<UserPermissions>(
        usersPermissions?.[0]
    );

    useEffect(() => {
        const filtered = usersPermissions?.filter(
            (item) => item.id !== userDetails.id
        );
        setFilteredUserPermissions(filtered);
        setSelected(filtered?.[0]);
    }, [usersPermissions]);

    const debouncedSearch = debounce((value: string) => {
        if (value.length > 2 || !value.length) {
            setSearchTerm(value);
        }
    }, 300);

    const handleSearchInputChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        debouncedSearch(e.target.value);
    };

    return (
        <Box className='space-y-4 bg-white lg:bg-transparent mt-4 lg:mt-0 px-[1rem] py-2'>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={isLoading}
            >
                <div className='animate-spin'>
                    <LoadingCircles />
                </div>
            </Backdrop>
            {}
            <Box
                className={`gap-2 lg:flex items-center justify-between lg:flex-row flex-col ${
                    permissionsView ? 'hidden' : 'flex'
                }`}
            >
                <SearchInput onHandleChange={handleSearchInputChange} />
                {isAdmin && (
                    <>
                        <div className=' hidden lg:block'>
                            <Button
                                variant='outlined'
                                onClick={() => {
                                    setViewInviteForm(true);
                                }}
                                size='medium'
                                sx={{
                                    background: 'white',
                                    minHeight: '40px',
                                    height: '40px',
                                    padding: '10px 16px',
                                    fontSize: '14px',
                                    color: '#344054',
                                    borderRadius: '8px',
                                    border: '1px solid #D0D5DD',
                                    boxShadow:
                                        '0px 1px 2px 0px #1018280F, 0px 1px 3px 0px #1018281A'
                                }}
                                endIcon={<PlusIcon />}
                            >
                                Invite User
                            </Button>
                        </div>
                        <SuitUIButton
                            text='Invite User'
                            onClick={() => {
                                setViewInviteForm(true);
                            }}
                            style='h-[40px] w-full lg:hidden'
                            iconLeft={<PlusIcon color='white' />}
                        />
                    </>
                )}
            </Box>
            {!filteredUsersPermissions?.length && !isLoading ? (
                <UserEmpty
                    subText={
                        searchTerm
                            ? "We couldn't locate the user you're searching for. Please ensure the spelling is correct or invite the user to join your organization."
                            : "We couldn't fetch users. Please invite new users to join your organization."
                    }
                    handleInvite={() => setViewInviteForm(true)}
                />
            ) : (
                <>
                    <Box className='lg:flex hidden gap-[4px]'>
                        <Box className='w-[211px]'>
                            <AdminUserProfileList
                                selected={selected}
                                usersPermissions={filteredUsersPermissions}
                                setSelected={setSelected}
                                refetchUsers={refetch}
                                isAdmin={isAdmin}
                            />
                        </Box>

                        <Box className='w-full'>
                            {selected ? (
                                <AdminUserPermissions
                                    userId={selected?.id}
                                    accessPermissions={
                                        selected?.accessPermissions
                                    }
                                    refetchUsers={refetch}
                                />
                            ) : null}
                        </Box>
                    </Box>
                    <MobileAdminUserProfileList
                        selected={selected}
                        usersPermissions={filteredUsersPermissions}
                        setSelected={setSelected}
                        refetchUsers={refetch}
                        isAdmin={isAdmin}
                        {...{ permissionsView, setPermissionsView }}
                    />
                </>
            )}
            <InviteUserForm
                {...{
                    viewInviteForm,
                    setViewInviteForm,
                    refetchUsers: refetch
                }}
            />
        </Box>
    );
};

export default AdminProfileContainer;
