import React, { useState } from 'react';
import { UpdateContainer } from '../UpdateStatus';
import { Stack } from '@mui/material';
import { Button, DatePicker, Input, InputNumber, Select } from 'antd';
import type { DatePickerProps } from 'antd';
import { formatNumber, openNotification } from '../../../../utils/helper';
import { updateInvoicePayment } from '../../../../api/sales/sales';
import getSymbolFromCurrency from 'currency-symbol-map';

const { TextArea } = Input;
const { Option } = Select;

interface IProps {
    fetchContracts: any;
    currentData?: any;
    setShowUpdatePaymentModal?: any;
}

const UpdatePayment = ({
    fetchContracts,
    currentData,
    setShowUpdatePaymentModal
}: IProps) => {
    const [paymentData, setPaymentData] = useState<any>({
        amount: 0,
        comment: '',
        paymentDate: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>();
    const selectAfter = (
        <Select defaultValue={currentData?.currencyCode}>
            {/* <Option value="NGN">NGN</Option> */}
            {/* <Option value="USD">USD</Option> */}
        </Select>
    );
    const onChangeFrom: DatePickerProps['onChange'] = (date, dateString) => {
        setPaymentData({
            ...paymentData,
            paymentDate: date?.format('YYYY-MM-DD')
        });
    };

    const onOk = (value: DatePickerProps['value']) => {
        console.log('onOk:');
    };

    const updateAmount = (value: any) => {
        setPaymentData({
            ...paymentData,
            amount: Number(value)
        });
    };

    const submit = async () => {
        setLoading(true);
        setError(null);

        try {
            const res = await updateInvoicePayment(
                currentData?.corporateDocumentId,
                paymentData
            );

            setLoading(false);
            openNotification('success', 'Payment updated successfully');
            setShowUpdatePaymentModal(false);
            fetchContracts();
        } catch (error) {
            setLoading(false);
            if (error?.response) {
                if (error?.response?.data?.error) {
                    let hasFieldNameAttribute = false;

                    error?.response?.data?.error.map((item: any) => {
                        return Object.keys(item).map((itemInside: string) => {
                            if (itemInside === 'fieldName') {
                                hasFieldNameAttribute = true;

                                return;
                            }
                        });
                    });

                    if (hasFieldNameAttribute) {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>
                                    {element.messageError}
                                </li>
                            );
                        });
                        setError(errorList);
                    } else {
                        const errorList = Object.values(
                            error?.response?.data?.error
                        ).map((element: any, index) => {
                            return (
                                <li key={`error-list-${index}`}>{element}</li>
                            );
                        });
                        setError(errorList);
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    return (
        <UpdateContainer style={{ width: '450px', padding: '10px' }}>
            <div>{tag}</div>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                width='100%'
                marginTop='20px'
            >
                <h3 style={{ marginTop: 0 }}>Update Partial Payment</h3>
                <div className='tag-line'>
                    <span>Partial Payment | {currentData?.invoiceNumber}</span>
                </div>
            </Stack>
            <p>Update payment received on behalf of this Invoice</p>

            <Stack width='100%' gap='16px'>
                <div>
                    <p className='form-label'>Date</p>
                    <DatePicker
                        placeholder='Select Date'
                        onChange={onChangeFrom}
                        format='YYYY-MM-DD'
                        onOk={onOk}
                        style={{ width: '100%', height: '40px' }}
                    />
                </div>

                <Stack direction='row' gap='10px' width='100%'>
                    <div>
                        <p className='form-label'>Received Amount</p>
                        <InputNumber
                            addonBefore={
                                <span className='left-addon'>
                                    {getSymbolFromCurrency(
                                        currentData?.currencyCode
                                    )}
                                </span>
                            }
                            addonAfter={selectAfter}
                            // defaultValue="0"
                            width={'65%'}
                            height='40px'
                            onChange={updateAmount}
                        />
                    </div>
                    <div>
                        <p className='form-label'>Balance Due</p>
                        <InputNumber
                            addonBefore={
                                <span className='left-addon'>
                                    {getSymbolFromCurrency(
                                        currentData?.currencyCode
                                    )}
                                </span>
                            }
                            defaultValue={formatNumber(
                                currentData?.totalAmount ||
                                    0 - currentData?.totalPaid ||
                                    0
                            )}
                            width={'30%'}
                            height='40px'
                            disabled
                            // type="number"
                        />
                    </div>
                </Stack>

                <div>
                    <p className='form-label'>Notes</p>
                    <TextArea
                        rows={5}
                        placeholder='Optional Notes here...'
                        onChange={(e: any) =>
                            setPaymentData({
                                ...paymentData,
                                comment: e.target.value
                            })
                        }
                    />
                </div>
            </Stack>

            <div className='cta-buttons'>
                <Button className='btn-1'>Cancel</Button>
                <Button className='btn-2' loading={loading} onClick={submit}>
                    Update
                </Button>
            </div>
        </UpdateContainer>
    );
};

export default UpdatePayment;

const tag = (
    <svg
        width='56'
        height='56'
        viewBox='0 0 56 56'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <rect
            x='4'
            y='4'
            width='48'
            height='48'
            rx='24'
            fill='#0088B2'
            fill-opacity='0.1'
        />
        <path
            d='M17 26H39M19 20H37C38.1046 20 39 20.8954 39 22V34C39 35.1046 38.1046 36 37 36H19C17.8954 36 17 35.1046 17 34V22C17 20.8954 17.8954 20 19 20Z'
            stroke='#0088B2'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <rect
            x='4'
            y='4'
            width='48'
            height='48'
            rx='24'
            stroke='#0088B2'
            strokeOpacity='0.05'
            strokeWidth='8'
        />
    </svg>
);
