import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
// import { certificateoforiginfieldProps } from './certificateoforiginfield-interface';

export interface certificateoforiginfieldProps {
    id: string;
    updateQuoteField: (id: number, name: string, value: any) => void;
    grossWeight: number;
    index: number;
    qtyOfPackage: number;
    removeQuoteField: (index: number) => void;
    item: any;
}

const Certificateoforiginfield = ({
    id,
    updateQuoteField,
    grossWeight,
    qtyOfPackage,
    index,
    removeQuoteField,
    item
}: certificateoforiginfieldProps) => {
    return (
        <div className='relative custom-certificateoforiginfield-field'>
            <Grid container>
                <Grid item xs={12} md={12} lg={6}>
                    <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4 h-full'>
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid item xs={12} md={12} lg={4}>
                                <TextField
                                    // error={errors?.signatoryCompany.length > 0}
                                    // helperText={errors?.signatoryCompany.length > 0 ? errors?.signatoryCompany : undefined}
                                    margin='normal'
                                    // required
                                    fullWidth
                                    id={`certificateoforiginfield-productCode-${id}`}
                                    label='Marks & Numbers'
                                    name='productCode'
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'productCode',
                                            e.target.value
                                        );
                                    }}
                                    value={item?.productCode}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={8}>
                                <TextField
                                    // error={errors?.signatoryCompany.length > 0}
                                    // helperText={errors?.signatoryCompany.length > 0 ? errors?.signatoryCompany : undefined}
                                    margin='normal'
                                    required
                                    fullWidth
                                    id={`certificateoforiginfield-desc-${id}`}
                                    label='Description of Goods'
                                    name='desc'
                                    value={item?.desc}
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'desc',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                    <div className='border border-appcolorgreyfaint px-[1.5rem] pt-[0.1rem] pb-4'>
                        <Grid
                            container
                            spacing={3}
                            sx={{
                                marginTop: '0px'
                            }}
                        >
                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    margin='normal'
                                    value={item?.kindOfPackage}
                                    fullWidth
                                    id={`certificateoforiginfield-kindOfPackage-${id}`}
                                    label='Package Kind'
                                    name='kindOfPackage'
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'kindOfPackage',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    margin='normal'
                                    // required
                                    fullWidth
                                    id={`certificateoforiginfield-qtyOfPackage-${id}`}
                                    label='Package Qty'
                                    name='qtyOfPackage'
                                    value={item?.qtyOfPackage}
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'qtyOfPackage',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    margin='normal'
                                    fullWidth
                                    id={`certificateoforiginfield-tariffCode-${id}`}
                                    label='Tariff Code'
                                    name='tariffCode'
                                    value={item?.tariffCode}
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'tariffCode',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6}>
                                <TextField
                                    margin='normal'
                                    fullWidth
                                    id={`certificateoforiginfield-grossWeight-${id}`}
                                    label='Gross Weight (kg)'
                                    name='grossWeight'
                                    value={item?.grossWeight}
                                    onChange={(e: any) => {
                                        updateQuoteField(
                                            index,
                                            'grossWeight',
                                            e.target.value
                                        );
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {index !== 0 && (
                <div
                    className='absolute top-[1.5rem] cursor-pointer right-[0.8rem] custom-certificateoforiginfield-field-delete w-fit transition-all delay-75'
                    onClick={() => {
                        removeQuoteField(index);
                    }}
                >
                    <DeleteIcon />
                </div>
            )}
        </div>
    );
};

export default Certificateoforiginfield;
