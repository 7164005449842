import React from 'react';
import EnablingFeature from '../../../components/EnablingFeature';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

const SettingsContainer = () => {
    return (
        <EnablingFeature flag='Accessible'>
            <Box className='mt-[15px] space-y-[15px] '>
                <Box className='lg:text-[24px] lg:font-semibold font-medium flex justify-between text-[18px] px-4 lg:px-0'>
                    <Box>
                        <Box>My Settings</Box>
                    </Box>
                </Box>
                <Outlet />
            </Box>
        </EnablingFeature>
    );
};

export default SettingsContainer;
