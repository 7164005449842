import React, { useRef, useState } from 'react';
import { PreviewIcon } from '../../../components/icons/PreviewIcon';
import CustomModal from '../../../components/CustomModal';
import { FrameContainer } from '../../../components/DocumentViewer/DocumentViewer';

const BusinessDocumentViewer = ({ url, ext }: { url: string; ext: string }) => {
    const [visibleDocument, setVisibleDocument] = useState(false);
    const iframeRef = useRef(null);

    return (
        <div>
            <div
                className='cursor-pointer'
                onClick={() => {
                    setVisibleDocument(true);
                }}
            >
                <PreviewIcon />
            </div>
            <CustomModal
                maxWidth='xl'
                open={visibleDocument}
                onClose={() => setVisibleDocument(false)}
                title='View Document'
            >
                <div className='lg:w-[60rem] w-full h-[85vh]'>
                    <FrameContainer>
                        {ext === 'pdf' ? (
                            <iframe
                                title='PDF File Viewer'
                                ref={iframeRef}
                                src={url}
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            />
                        ) : (
                            <img src={url} alt={ext} />
                        )}
                    </FrameContainer>
                </div>
            </CustomModal>
        </div>
    );
};

export default BusinessDocumentViewer;
