import { v4 as uuidv4 } from 'uuid';
import { BookingForm } from './interfaces/BookingForm';
import { Cargo } from './interfaces/Cargo';
import { CargoForm } from './CargoForm';
import { FormikProps } from 'formik';
import { SuitUIButton } from '../../../../components/SuitUIButton';
interface CargoFormSectionProps {
    formData: BookingForm;
    setFormData: React.Dispatch<React.SetStateAction<BookingForm>>;
    setShowAddNewCommodity: React.Dispatch<React.SetStateAction<boolean>>;
    formik: FormikProps<BookingForm>;
}

export const CargoFormSection = (props: CargoFormSectionProps) => {
    const { formData, setFormData, setShowAddNewCommodity, formik } = props;
    return (
        <>
            <div className='flex justify-between items-center pt-4 pb-[4px] border-b border-dashed lg:border-solid border-gray300 lg:border-appcolorgreyfaint lg:mt-8'>
                <p className='text-steelBlue'>Cargo</p>

                <SuitUIButton
                    text=' + Add Cargo'
                    variant='secondary'
                    onClick={() => {
                        setFormData((prev) => {
                            return {
                                ...prev,
                                cargo: [
                                    ...(prev.cargo || []),
                                    { uid: uuidv4() } as Cargo
                                ]
                            };
                        });
                    }}
                />
            </div>

            <div className='mt-1'>
                {formData.cargo?.map((content, index) => (
                    <CargoForm
                        {...{
                            content,
                            index,
                            formData,
                            setFormData,
                            isRemoveShown: formData.cargo.length > 1,
                            setShowAddNewCommodity,
                            formik
                        }}
                        key={content.uid}
                    />
                ))}
            </div>
        </>
    );
};
