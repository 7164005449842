import { yupToFormErrors } from 'formik';
import { BookingFormProps } from '../interfaces/BookingForm';
import { CargoFormSection } from '../CargoFormSection';
import { PaymentFormSection } from '../PaymentFormSection';
import { CommentFormSection } from '../CommentFormSection';
import { SuitUIButton } from '../../../../../components/SuitUIButton';
import { FlagTwoIcon } from '../../../../../components/icons';
import { BookingFormValidationSchema } from '../schemas/BookingFormValidationSchema';

interface PaymentProps extends BookingFormProps {
    activeStep: number;
    setActiveStep: (val: number) => void;
    setShowAddNewCommodity: React.Dispatch<React.SetStateAction<boolean>>;
    onHandleSubmit: () => void;
}

const Payment = ({
    user,
    formData,
    setFormData,
    activeStep,
    setActiveStep,
    formik,
    onHandleSubmit,
    setShowAddNewCommodity
}: PaymentProps) => {
    const handleSubmit = () => {
        try {
            BookingFormValidationSchema.validateSync(formik.values, {
                abortEarly: false
            });

            onHandleSubmit();
        } catch (err) {
            formik.setErrors(yupToFormErrors(err));
        }
    };
    return (
        <div className='mt-4'>
            <div className='flex bg-appcolorprimary border border-appcolorprimary border-2 py-[12px] px-4 rounded-[8px] bg-opacity-[0.05] gap-4'>
                <div>
                    <FlagTwoIcon />
                </div>
                <p className='text-sm font-medium text-appcolorprimary'>
                    NOTE: The sum of all Cargo Weights reflects the Gross Weight
                    of the Cargo (excluding Tare) for the entire Booking.
                    Inaccurate declaration of cargo weight may endanger crew,
                    port worker, and vessel safety. Please verify the reasonable
                    accuracy of this information prior to submission.
                </p>
            </div>

            <CargoFormSection
                {...{
                    formData,
                    setFormData,
                    setShowAddNewCommodity,
                    formik
                }}
            />

            <PaymentFormSection
                {...{
                    formData,
                    setFormData
                }}
            />

            <CommentFormSection
                {...{
                    formData,
                    setFormData
                }}
            />

            <div className='flex gap-[16px] items-center justify-end mt-8 lg:mb-0'>
                <SuitUIButton
                    text='Back'
                    onClick={() => setActiveStep(activeStep - 1)}
                    invert
                    fullWidth
                    style='px-[0.92rem] border border-gray300 text-steelBlue font-medium text-sm leading-[20px] lg:border-none lg:text-appcolorprimary'
                />

                <SuitUIButton
                    text='Submit'
                    onClick={handleSubmit}
                    disabled={!user?.booking}
                    fullWidth
                    style='px-[0.92rem]'
                />
            </div>
        </div>
    );
};

export default Payment;
