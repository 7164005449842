import Custombutton from '../../../components/custombutton/custombutton';
import { mutateConfig } from '../../../api/queryBase';
import { toast } from 'react-toastify';
import { Box, Typography } from '@mui/material';
import { FeaturedIcon } from '../../../components/icons/FeaturedIcon';
import { Dispatch } from 'react';
import { getErrorMessage } from '../../../utils/helper';

export const G3PromptDialog = ({
    currencyCode,
    setShowG3Modal
}: {
    currencyCode: string;
    setShowG3Modal: Dispatch<React.SetStateAction<boolean>>;
}) => {
    const mutation = mutateConfig({});
    const { mutate } = mutation({
        url: `/wallet/currency/${currencyCode}`
    });

    const onHandleSubmit = () => {
        mutate(
            {},
            {
                onSettled: (_, error) => {
                    if (error) {
                        toast.error(
                            getErrorMessage(error) ||
                                'An error has occured while creating an account'
                        );
                        return;
                    }

                    toast.info('Your request has been submitted successfully');
                    setShowG3Modal(false);
                }
            }
        );
    };

    return (
        <div className='w-full  p-[40px]'>
            <Box className='flex justify-center mb-[20px]'>
                <FeaturedIcon />
            </Box>
            <Box className='mb-[20px]'>
                <Typography>
                    <span className='font-bold'>Note:</span> You will be
                    considered for all these{' '}
                    <span className='font-bold'>3 currencies</span>. Would you
                    like to proceed
                </Typography>
            </Box>
            <Box className='flex justify-center space-x-2 uppercase mb-[20px]'>
                <Typography className='bg-purple-100 py-[5px] px-[10px] rounded-2xl text-purple-800'>
                    usd
                </Typography>
                <Typography className='bg-blue-100 py-[5px] px-[10px] rounded-2xl text-blue-800'>
                    eur
                </Typography>
                <Typography className='bg-indigo-100 py-[5px] px-[10px] rounded-2xl text-indigo-800'>
                    gbp
                </Typography>
            </Box>
            <Box className='flex space-x-3'>
                <Custombutton
                    isLoadingButton
                    type='button'
                    variant='contained'
                    fullWidth
                    buttonText='Cancel'
                    styles={{
                        padding: '0.75rem 0.62rem',
                        backgroundColor: 'white',
                        color: 'black',
                        border: 'solid 1px #D0D5DD',
                        '&:hover': {
                            backgroundColor: 'white'
                        },
                        width: '100%'
                    }}
                    onClickAction={() => setShowG3Modal(false)}
                />
                <Custombutton
                    isLoadingButton
                    type='button'
                    fullWidth
                    variant='contained'
                    buttonText='Yes Please!'
                    styles={{
                        padding: '0.75rem 0.62rem',
                        width: '100%'
                    }}
                    onClickAction={() => {
                        onHandleSubmit();
                    }}
                />
            </Box>
        </div>
    );
};
