import { useEffect, useState } from 'react';
import { queryConfig } from '../../../../../api/queryBase';
import { ContainerType } from '../interfaces/ContainerType';

export const useGetContainerTypes = () => {
    const [containerTypes, setContainerTypes] = useState<ContainerType[]>([]);
    const query = queryConfig();
    const { isLoading, data } = query<{ content: ContainerType[] }>({
        url: '/booking/container/types',
        cacheKey: 'container-types'
    });

    useEffect(() => {
        setContainerTypes(data?.payload.content || []);
    });

    return { containerTypes, isLoading };
};
