export const contentStyle = {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '15px 30px 40px rgba(64, 25, 109, 0.08)',
    marginLeft: '-.5rem',
    marginTop: '.5rem',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    minWidth: '300px',
    maxWidth: '300px',
};

export const headingStyle = {
    fontSize: '15px',
    fontWeight: '500',
    padding: '.5rem 1rem 0',
    fontFamily: 'Outfit'
};

export const subStyle = {
    color: '#667085',
    fontSize: '13px',
    fontFamily: 'Outfit',
    padding: '0rem 1rem 0'
};
export const menuStyle = {
    boxShadow: 'none',
    input: {
        padding: 0
    }
};

export const buttonStyle = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '3px',
    border: '1.5px solid #EAECF0',
    background: '#FFF',
    padding:"14px",
    color:"#667085",
    fontSize:"14px"

};
