import { useEffect, useState } from 'react';
import { queryConfig } from '../api/queryBase';
import { QueryParameters } from '../pages/dashboard/bookings/createbooking/interfaces/QueryParameters';

interface QueryParams<U> {
    params?: U;
    url: string;
    cacheKey?: string;
    enabled?: boolean;
}

export const useGetQuery = <T, U = QueryParameters>(args: QueryParams<U>) => {
    const { url, params, cacheKey, enabled } = args;
    const [entries, setEntries] = useState<T>([] as T);
    const [paging, setPaging] = useState<{
        total: number;
        page: number;
    }>({} as { total: number; page: number });

    const query = queryConfig();
    const { isLoading, data, isFetching, refetch, isError, error } = query<{
        content: T;
        totalElements?: number;
        number?: number;
    }>({
        url,
        cacheKey,
        params: { ...params },
        enabled
    });

    useEffect(() => {
        setEntries((data?.payload.content || data?.payload || data || []) as T);
        setPaging((props) => ({
            ...props,
            total: data?.payload?.totalElements || 0,
            page: data?.payload?.number ? data?.payload?.number + 1 : 0
        }));
    }, [isFetching, isLoading]);

    return {
        isLoading,
        entries,
        isFetching,
        refetch,
        paging,
        isError, 
        error 
    };
};
