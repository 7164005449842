import { useState } from 'react';
import { UserProfileTabs } from './UserProfileTabs';
import ProfileAccountDetails from './ProfileAccountDetails';
import { Backdrop, Stack } from '@mui/material';
import ProfileDocuments from './ProfileDocuments';
import AdminProfileContainer from './AdminProfileContainer';
import { LoadingCircles } from '../../../components/icons/LoadingCircles';
import useIndustries from '../../../customhooks/industries/useIndustries';
import useBusinessTypes from '../../../customhooks/businesstypes/useBusinesstypes';
import useCountries from '../../../customhooks/useCountries';
import { useUserProfile } from '../../../customhooks/useUserProfile';
import ChangePassword from './ChangePassword';

const UserProfileSettings = () => {
    const { industries, isIndustriesLoading } = useIndustries();
    const { businessTypes, isBusinessTypesLoading } = useBusinessTypes();
    const { countries, isCountriesLoading } = useCountries();
    const { isLoading, userDetails } = useUserProfile();

    const ProfileTabs = [
        { key: 'profile', label: 'profile page', allowView: true },
        {
            key: 'manage',
            label: 'manage users',
            allowView: userDetails?.role?.toLowerCase() !== 'member'
        }
    ];

    const [selected, setSelected] = useState<
        (typeof ProfileTabs)[number]['key']
    >(ProfileTabs[0]['key']);

    return (
        <div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={isLoading}
            >
                <div className='animate-spin'>
                    <LoadingCircles />
                </div>
            </Backdrop>

            <UserProfileTabs
                {...{ tabs: ProfileTabs, selected, setSelected }}
            />

            {selected === 'profile' &&
                userDetails &&
                !isLoading &&
                !isCountriesLoading &&
                !isBusinessTypesLoading && (
                    <Stack
                        direction='column'
                        gap='10px'
                        className='bg-white lg:bg-transparent pb-10 mt-4 lg:mt-0'
                    >
                        <ProfileAccountDetails
                            {...{
                                userDetails,
                                countries,
                                isCountriesLoading,
                                isBusinessTypesLoading,
                                businessTypes,
                                industries,
                                isIndustriesLoading
                            }}
                        />
                        {userDetails?.role?.toLowerCase() === 'admin' ||
                            (userDetails?.role?.toLowerCase() === 'owner' && (
                                <ProfileDocuments {...{ userDetails }} />
                            ))}

                        <ChangePassword />
                    </Stack>
                )}

            {selected === 'manage' && (
                <AdminProfileContainer
                    isAdmin={
                        userDetails?.role?.toLowerCase() === 'admin' ||
                        userDetails?.role?.toLowerCase() === 'owner'
                    }
                />
            )}
        </div>
    );
};

export default UserProfileSettings;
