import { Divider, Stack } from '@mui/material'
import React from 'react'

interface IProps {
  data?: any
}

const DetailsGeneral = ({ data }: IProps) => {
  return (
    <div className="details-content flex-1">
      <h3>General</h3>
      <Stack direction="row" className="flex-dets">
        <p>Sales Contract No*</p>
        <p>{data?.salesContractNumber || '--'}</p>
      </Stack>
      <Stack direction="row" className="flex-dets">
        <p>Carrier Booking</p>
        <p>{data?.creationDetails?.general?.shipperName || '--'}</p>
      </Stack>
      <Stack direction="row" className="flex-dets">
        <p>Booking Office</p>
        <p>{data?.creationDetails?.general?.bookingOffice || '--'}</p>
      </Stack>
      <Divider className=" bg-appcolormainblack" />
      <Stack direction="row" className="flex-dets">
        <p>Carrier Booking No </p>
        <p>{data?.creationDetails?.general?.carrierBookingNumber}</p>
      </Stack>{' '}
      <Divider className=" bg-appcolormainblack" />
      <Stack direction="row" className="flex-dets">
        <p>Filer ID/SDAC</p>
        <p>{data?.creationDetails?.general?.filterId}</p>
      </Stack>
    </div>
  )
}

export default DetailsGeneral
