export const BoxSearchIcon = () => (
    <svg
        width='16'
        height='17'
        viewBox='0 0 16 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M2.60938 5.63977L8.2858 8.92476L13.9236 5.65904'
            stroke='#292D32'
            strokeWidth='1.02857'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M8.28589 14.7489V8.91821'
            stroke='#292D32'
            strokeWidth='1.02857'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M14.4638 9.10523V6.75235C14.4638 5.86521 13.8274 4.78522 13.0496 4.35451L9.61672 2.45164C8.88386 2.04021 7.68814 2.04021 6.95528 2.45164L3.52243 4.35451C2.74457 4.78522 2.10815 5.86521 2.10815 6.75235V10.3909C2.10815 11.2781 2.74457 12.3581 3.52243 12.7888L6.95528 14.6917C7.32171 14.8974 7.80386 15.0002 8.286 15.0002C8.76814 15.0002 9.25029 14.8974 9.61672 14.6917'
            stroke='#292D32'
            strokeWidth='1.02857'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M12.9146 14.6143C14.0507 14.6143 14.9717 13.6933 14.9717 12.5572C14.9717 11.421 14.0507 10.5 12.9146 10.5C11.7784 10.5 10.8574 11.421 10.8574 12.5572C10.8574 13.6933 11.7784 14.6143 12.9146 14.6143Z'
            stroke='#292D32'
            strokeWidth='1.02857'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M15.3575 14.9999L14.7146 14.3571'
            stroke='#292D32'
            strokeWidth='1.02857'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);
