import React, { useEffect, useRef, useState } from 'react';
import {
    TradeContainer,
    TradeContent
} from '../../../../styles/TradeDocStyles';
import FormHeader from '../../../../components/dashboard/tradeDocuments/common/FormHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';
import {
    createHouseBillOfLading,
    getSingleDocuments
} from '../../../../api/documents/documents';
import { openNotification } from '../../../../utils/helper';
import BillOfLadenForm from '../../../../components/dashboard/tradeDocuments/formComponents/BillOfLAdenForm';
import BillOfLadenPreview from '../../../../components/dashboard/tradeDocuments/previewComponents/BillOfLadenPreview';
import Addpartnerdrawer from '../addpartnerdrawer/addpartnerdrawer';
import CustomModal from '../../../../components/CustomModal';
import Savetradedocument from '../savetradedocument/savetradedocument';
import { Backdrop } from '@mui/material';
import useCorporatePartners from '../../../../customhooks/useCorporatePartners';

const HouseBillOfLadingNew = () => {
    const breadcrumbs = [
        {
            title: 'Home',
            link: '/',
            active: false
        },
        {
            title: 'Documents',
            link: '/trade-documents',
            active: false
        },
        {
            title: 'Draft Bill of Lading',
            link: '/trade-documents/house-bill-of-lading',
            active: true
        }
    ];

    const location = useLocation();
    const [params, setParams] = useState<any>();
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const param: any = Object.fromEntries(searchParams.entries());
        setParams(param);
    }, [location.search]);

    useEffect(() => {
        if (params?.edit) {
            fetchDocuments();
        }
    }, [params]);

    const fetchDocuments = async () => {
        setLoadingData(true);
        try {
            const res = await getSingleDocuments({
                corporateDocumentId: params?.edit
            });

            const { data } = res.data;
            setData(data);

            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            if (error?.response) {
                openNotification('error', `Documents, Something went wrong`);
            } else {
                openNotification('error', `Documents, Something went wrong`);
            }
        }
    };

    const navigate = useNavigate();
    const pageTopRef = useRef<any>(null);
    const { corporatePartners, isLoading } = useCorporatePartners({ page: 1 });

    const myDiv = useRef<HTMLDivElement | null>(null);
    const [showPreview, setShowPreview] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [signatureUrlBase64, setSignatureUrlBase64] = useState('');
    const [saveOnly, setSaveOnly] = useState(true);
    const [fileId, setFileId] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | any>(null);
    const [payload, setPayload] = useState<any>({
        documentName: '',
        portOfLoadingValue: undefined,
        portOfDischargeValue: undefined,
        portOfLoadingId: undefined,
        portOfDischargeId: undefined,
        shipperId: undefined,
        mtdNumber: undefined,
        issuedDate: Date.now(),
        consigneeId: undefined,
        placeOfReceipt: undefined,
        shipmentReferenceNumber: undefined,
        vesselAndVoyage: undefined,
        placeOfDelivery: undefined,
        endDateOfDelivery: Date.now(),
        transport: undefined,
        placeOfTranshipment: undefined,
        deliveryAgent: undefined,
        freightAmount: undefined,
        noOfOriginals: undefined,
        placeOfIssue: undefined,
        freightPayableAt: undefined,
        signatureUrl: undefined,
        notifyPartyId: undefined,
        containerNo: undefined,
        kindOfPackages: undefined,
        totalGrossWeight: undefined,
        measurement: undefined,
        sealNumber: undefined,
        shipperAddress: undefined,
        consigneeAddress: undefined,
        billOfLadingNumber: '',
        salesContractNumber: '',
        logoUrl: '',
        dateOfReceipt: Date.now(),
        startDateOfDelivery: Date.now()
    });

    const [errors, setErrors] = useState({
        documentName: '',
        shipperId: '',
        mtdNumber: '',
        issuedDate: '',
        consigneeId: '',
        placeOfReceipt: '',
        shipmentReferenceNumber: '',
        portOfLoadingId: '',
        portOfDischargeId: '',
        vesselAndVoyage: '',
        placeOfDelivery: '',
        endDateOfDelivery: '',
        transport: '',
        placeOfTranshipment: '',
        deliveryAgent: '',
        freightAmount: '',
        noOfOriginals: '',
        placeOfIssue: '',
        freightPayableAt: '',
        signatureUrl: '',
        notifyPartyId: '',
        containerNo: '',
        kindOfPackages: '',
        totalGrossWeight: '',
        measurement: '',
        sealNumber: '',
        shipperAddress: '',
        consigneeAddress: '',
        billOfLadingNumber: '',
        salesContractNumber: '',
        logoUrl: '',
        dateOfReceipt: '',
        startDateOfDelivery: ''
    });

    const [totalAmount, setTotalAmount] = useState(0);
    const [quoteFields, setQuoteFields] = useState([
        {
            id: nanoid(9),
            productCode: nanoid(9),
            desc: undefined,
            qty: 0,
            kindOfPackage: undefined,
            qtyOfPackage: undefined,
            weightOfPackageKG: undefined,
            grossWeightOfPackageKG: undefined,
            measurementOfPackage: undefined
        }
    ]);
    const [showSaveModal, setShowSaveModal] = useState(false);

    const [showAddPartner, setShowAddPartner] = useState(false);

    const closeShowAddParty = () => {
        setShowAddPartner(false);
    };

    useEffect(() => {
        if (data) {
            setFileId(data?.id);
            setPayload({
                documentName: data?.documentName || '',
                portOfLoadingValue: data?.documentData?.portOfLoading || '',
                portOfDischargeValue: data?.documentData?.portOfDischarge || '',
                portOfLoadingId: data?.documentData?.portOfLoading.name || '',
                portOfDischargeId:
                    data?.documentData?.portOfDischarge.name || '',
                shipperId: data?.documentData?.shipperId || '',
                mtdNumber: data?.documentData?.mtdNumber || '',
                issuedDate: data?.documentData?.issuedDate || Date.now(),
                consigneeId: data?.documentData?.consigneeId || '',
                placeOfReceipt: data?.documentData?.placeOfReceipt || '',
                shipmentReferenceNumber:
                    data?.documentData?.shipmentReferenceNumber || '',
                vesselAndVoyage: data?.documentData?.vesselAndVoyage || '',
                placeOfDelivery: data?.documentData?.placeOfDelivery || '',
                endDateOfDelivery:
                    data?.documentData?.endDateOfDelivery || Date.now(),
                transport: data?.documentData?.transport || '',
                placeOfTranshipment:
                    data?.documentData?.placeOfTranshipment || '',
                deliveryAgent: data?.documentData?.deliveryAgent || '',
                freightAmount: data?.documentData?.freightAmount || '',
                noOfOriginals: data?.documentData?.noOfOriginals || '',
                placeOfIssue: data?.documentData?.placeOfIssue || '',
                freightPayableAt: data?.documentData?.freightPayableAt || '',
                signatureUrl: data?.documentData?.signatureUrl || '',
                notifyPartyId: data?.documentData?.notifyPartyId || '',
                containerNo: data?.documentData?.containerNo || '',
                kindOfPackages: data?.documentData?.kindOfPackages || '',
                totalGrossWeight: data?.documentData?.totalGrossWeight || '',
                measurement: data?.documentData?.measurement || '',
                sealNumber: data?.documentData?.sealNumber || '',
                shipperAddress: data?.documentData?.shipperAddress || '',
                consigneeAddress: data?.documentData?.consigneeAddress || '',
                billOfLadingNumber:
                    data?.documentData?.billOfLadingNumber || '',
                salesContractNumber:
                    data?.documentData?.salesContractNumber || '',
                logoUrl: data?.documentData?.logoUrl || '',
                dateOfReceipt: data?.documentData?.dateOfReceipt || '',
                startDateOfDelivery:
                    data?.documentData?.startDateOfDelivery || ''
            });

            const transformArray = (array: any) => {
                const newArray: any = [];

                array.forEach((val: any) => {
                    newArray.push({
                        id: val.id,
                        productCode: val.itemMarker,
                        desc: val.descriptionOfGoods,
                        qtyOfPackage: val.unitQuantity,
                        chargeable: val.chargeable,
                        kindOfPackage: val.kindAndNumber
                    });
                });
                return newArray;
            };
            setQuoteFields(transformArray(data?.documentData?.packing));
        }
    }, [data]);

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    /**
     * Update quotes input fields
     * @param id string
     * @param name string
     * @param value any
     */
    const updateQuoteField = (
        index: number,
        attributeName: string,
        attributeValue?: any
    ) => {
        const newContainers: any[] = [...quoteFields];
        newContainers[index][attributeName] = attributeValue;
        setQuoteFields(newContainers);
    };

    /**
     * Adds quotes input field line
     */
    const addQuoteField = () => {
        setQuoteFields([
            ...quoteFields,
            {
                id: nanoid(9),
                productCode: nanoid(9),
                desc: undefined,
                qty: 0,
                kindOfPackage: undefined,
                qtyOfPackage: undefined,
                weightOfPackageKG: undefined,
                grossWeightOfPackageKG: undefined,
                measurementOfPackage: undefined
            }
        ]);
    };

    /**
     * Remove quotes input field line
     */
    const removeQuoteField = (index: number) => {
        setQuoteFields(quoteFields.filter((item, i) => i !== index));
    };

    const onSubmit = async () => {
        setLoading(true);
        setError(null);

        setErrors((prevState: any) => ({
            ...prevState,
            documentName: '',
            shipperId: '',
            mtdNumber: '',
            issuedDate: '',
            consigneeId: '',
            placeOfReceipt: '',
            shipmentReferenceNumber: '',
            portOfLoading: '',
            portOfDischarge: '',
            vesselAndVoyage: '',
            placeOfDelivery: '',
            endDateOfDelivery: '',
            transport: '',
            placeOfTranshipment: '',
            deliveryAgent: '',
            freightAmount: '',
            noOfOriginals: '',
            placeOfIssue: '',
            freightPayableAt: '',
            signatureUrl: '',
            notifyPartyId: '',
            consigneeAddress: ''
        }));

        try {
            const items: any[] = [];

            const {
                documentName,
                shipperId,
                mtdNumber,
                issuedDate,
                consigneeId,
                placeOfReceipt,
                shipmentReferenceNumber,
                portOfLoading,
                portOfDischarge,
                vesselAndVoyage,
                placeOfDelivery,
                endDateOfDelivery,
                transport,
                placeOfTranshipment,
                deliveryAgent,
                freightAmount,
                noOfOriginals,
                placeOfIssue,
                freightPayableAt,
                signatureUrl,
                containerNo,
                kindOfPackages,
                totalGrossWeight,
                measurement,
                sealNumber,
                shipperAddress,
                consigneeAddress,
                notifyPartyId,
                portOfDischargeValue,
                portOfLoadingValue,
                billOfLadingNumber,
                salesContractNumber,
                logoUrl,
                dateOfReceipt,
                startDateOfDelivery
            } = payload;

            const dischargeValue: any = portOfDischargeValue;
            const portValue: any = portOfLoadingValue;

            quoteFields.forEach((item: any, index) => {
                items.push({
                    itemMarker: item?.productCode,
                    chargeable: item?.chargeable,
                    descriptionOfGoods: item?.desc,
                    kindAndNumber: item?.kindOfPackage,
                    unitQuantity: item?.qtyOfPackage
                });
            });

            const res = await createHouseBillOfLading({
                createdType: 'CREATED',
                documentData: {
                    consigneeAddress,
                    shipperAddress,
                    packing: items,
                    documentName,
                    shipperId,
                    mtdNumber,
                    issuedDate,
                    consigneeId,
                    placeOfReceipt,
                    shipmentReferenceNumber,
                    portOfDischargeId: dischargeValue?.id,
                    portOfLoadingId: portValue?.id,
                    vesselAndVoyage,
                    placeOfDelivery,
                    endDateOfDelivery,
                    transport,
                    placeOfTranshipment,
                    deliveryAgent,
                    freightAmount,
                    noOfOriginals,
                    placeOfIssue,
                    freightPayableAt,
                    signatureUrl,
                    notifyPartyId,
                    containerNo,
                    kindOfPackages,
                    totalGrossWeight,
                    measurement,
                    sealNumber,
                    billOfLadingNumber,
                    salesContractNumber:
                        salesContractNumber !== ''
                            ? salesContractNumber
                            : undefined,
                    logoUrl: logoUrl !== '' ? logoUrl : undefined,
                    dateOfReceipt,
                    startDateOfDelivery
                },
                documentType: 'DRAFT_BILL_OF_LADING',
                documentName,
                id: fileId
            });

            const { id } = res.data.data;

            setFileId(id);

            openNotification('success', 'Parking List created successfully');

            setShowSaveModal(false);
            setLoading(false);

            if (saveOnly) {
                navigate('/trade-documents');
            }
        } catch (error) {
            window.scroll({
                top: pageTopRef?.current?.offsetTop,
                left: 0,
                behavior: 'smooth'
            });

            setShowSaveModal(false);
            setLoading(false);
            const otherErrors: any[] = [];

            if (error?.response) {
                if (error?.response?.data?.error) {
                    const resError = error?.response?.data?.error;

                    if (typeof resError === 'string') {
                        const errorList = (
                            <li key={`error-list-${1}`}>{resError}</li>
                        );
                        setError(errorList);
                    } else {
                        Object.entries(resError).forEach(([key, value]) => {
                            if (Object.keys(errors).includes(key)) {
                                setErrors((prevState) => ({
                                    ...prevState,
                                    [key]: value
                                }));
                            } else {
                                otherErrors.push(value);
                            }
                        });

                        if (otherErrors.length > 0) {
                            const errorList = otherErrors.map(
                                (element: any, index) => {
                                    return (
                                        <li key={`error-list-${index}`}>
                                            {element}
                                        </li>
                                    );
                                }
                            );
                            setError(errorList);
                        }
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    const closeSaveModal = () => {
        setShowSaveModal(false);
    };

    return (
        <TradeContainer>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loadingData}
            >
                <img
                    src='/img/vectors/loadingcircles.svg'
                    alt='loading'
                    className='animate-spin'
                />
            </Backdrop>
            <FormHeader
                breadcrumbs={breadcrumbs}
                docTitle='Draft Bill of Lading'
                {...{
                    showPreview,
                    setShowPreview,
                    myDiv,
                    downloadLoading,
                    setDownloadLoading
                }}
                onSaveOnlyAction={() => {
                    setSaveOnly(true);
                    setShowSaveModal(true);
                }}
                onSaveAction={() => {
                    setSaveOnly(false);
                    setShowSaveModal(true);
                }}
                disabled={
                    Object.values(payload).some(
                        (item: any) => item === undefined
                    ) || loading
                }
            />

            <TradeContent>
                {showPreview ? (
                    <div ref={myDiv}>
                        <BillOfLadenPreview
                            data={payload}
                            docTitle='Draft Bill of Lading'
                            quoteFields={quoteFields}
                            totalAmount={totalAmount}
                            signatureUrlBase64={signatureUrlBase64}
                        />
                    </div>
                ) : (
                    <BillOfLadenForm
                        {...{
                            error,
                            updateField,
                            payload,
                            corporatePartners,
                            loadingCorporatepartners: isLoading,
                            setShowAddPartner,
                            errors,
                            quoteFields,
                            updateQuoteField,
                            removeQuoteField,
                            addQuoteField,
                            loading,
                            setSignatureUrlBase64,
                            signatureUrlBase64,
                            setSaveOnly,
                            setShowSaveModal,
                            totalAmount
                        }}
                    />
                )}

                {showAddPartner ? (
                    <Addpartnerdrawer
                        closeDrawer={closeShowAddParty}
                        showDrawer={showAddPartner}
                    />
                ) : null}

                {showSaveModal && (
                    <CustomModal
                        open={showSaveModal}
                        onClose={closeSaveModal}
                        title='Saving document'
                        maxWidth='xs'
                        styles={{
                            overflowX: 'hidden',
                            '.MuiPaper-root': {
                                width: '100%'
                            }
                        }}
                    >
                        <Savetradedocument
                            updateField={updateField}
                            onSubmit={onSubmit}
                            loading={loading}
                            data={payload}
                        />
                    </CustomModal>
                )}
            </TradeContent>
        </TradeContainer>
    );
};

export default HouseBillOfLadingNew;
