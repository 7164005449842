import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { CountryFlagFromCode } from '../CountryFlagFromCode';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';
import { getFormattedAmount } from '../../shared/getFormattedAmount';
import { Wallet } from '../../pages/dashboard/payments/interfaces/Wallet';

const WalletBalanceItem = ({ item }: { item?: Wallet }) => {
    return (
        <Box className='flex grow space-x-3 items-center bg-white py-[17px] px-[14px] border-lightGray border rounded-[5px] lg:p-0 lg:border-none'>
            {item?.currencyCode && (
                <CountryFlagFromCode code={item.currencyCode} />
            )}
            <Box>
                <Box className='uppercase text-[11px] text-appcolorblackfive'>
                    {item?.currencyCode}
                </Box>
                <Box className='capitalize text-[22px] font-semibold'>
                    {item?.currencyCode &&
                        getFormattedAmount({
                            amount: item?.balance || item?.ledgerBalance || 0,
                            currency: item.currencyCode,
                            compact: true
                        })}
                </Box>
                <Box className='hidden lg:block uppercase text-[11px] text-appcolorblackfive whitespace-nowrap'>
                    {item?.currencyCode && (
                        <Tooltip
                            title={getFormattedAmount({
                                amount:
                                    item?.balance || item?.ledgerBalance || 0,
                                currency: item.currencyCode
                            })}
                        >
                            <Box className='flex items-center space-x-2'>
                                <Box>Ledger Balance</Box>
                                <InfoIcon fontSize='small' />
                            </Box>
                        </Tooltip>
                    )}
                </Box>
                <Box className='lg:hidden uppercase text-xs text-charcoal whitespace-nowrap'>
                    <p>
                        Ledger Balance:{' '}
                        {item?.currencyCode &&
                            getFormattedAmount({
                                amount:
                                    item?.balance || item?.ledgerBalance || 0,
                                currency: item.currencyCode
                            })}
                    </p>
                </Box>
            </Box>
        </Box>
    );
};

export default WalletBalanceItem;
