import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { NumberInput } from '../../../../components/paymentinput/paymentinput';
import Documentupload from '../../../../components/documentupload/documentupload';
import Custombutton from '../../../../components/custombutton/custombutton';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import Outletouterpage from '../../../../components/dashboard/outletouterpage/outletouterpage';
import Backdrop from '@mui/material/Backdrop';

const documentToUpload = [
    {
        title: 'Upload Parking List'
    },
    {
        title: 'Upload Bill of lading'
    },
    {
        title: 'Upload Invoice'
    },
    {
        title: 'Upload Sales Contract'
    }
];

const Requestexportfinance = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState<any>({
        annualSales: undefined,
        creditLine: undefined
    });
    const [errors, setErrors] = useState({
        annualSales: '',
        creditLine: ''
    });
    const [error, setError] = useState<string | any>(null);

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        setErrors((prevState: any) => ({
            ...prevState,
            [name]: ''
        }));
    };

    /**
     * Submit form
     * @param e Object
     */
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        setTimeout(() => {
            setLoading(false);

            navigate('/financing/overview', {
                state: {
                    newFinanceRequestAlert: true
                }
            });
        }, 2000);
    };

    return (
        <div>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loading}
            >
                <img
                    src='/img/vectors/loadingcircles.svg'
                    alt='loading'
                    className='animate-spin'
                />
            </Backdrop>

            <Outletouterpage
                breadcrumbs={[
                    {
                        title: 'Home',
                        link: '/dashboard'
                    },
                    {
                        title: 'Financing',
                        link: '/financing'
                    }
                ]}
            >
                <h1 className='text-[32px] leading-[23px] text-appcolorblacktwo'>
                    Financing
                </h1>

                <div className='mt-8'>
                    <Grid container>
                        <Grid item xs={12} md={9} lg={6}>
                            <div>
                                <div className='flex items-center justify-between'>
                                    <div className='flex'>
                                        <div
                                            className={`bg-appcolorwhite trapezoid pt-[0.8rem] pb-[0.7rem] pl-[1.6rem] w-fit pr-[5.6rem] cursor-pointer text-[17px] leading-[24px]`}
                                        >
                                            Create New Application
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`py-[0.5rem] px-[1.3rem] bg-appcolorwhite pb-[2.4rem] mb-4 pt-4`}
                                >
                                    {error !== null && (
                                        <div className='mt-10'>
                                            <Alert severity='error'>
                                                {error}
                                            </Alert>
                                        </div>
                                    )}

                                    <form className='pt-8' onSubmit={onSubmit}>
                                        <div className='pb-4 mb-4 border-b-2 border-dashed border-appcolorfaintgreyblue'>
                                            <div className='mb-8'>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    label='What are your annual sales in USD?'
                                                    error={
                                                        errors?.annualSales
                                                            .length > 0
                                                    }
                                                    helperText={
                                                        errors?.annualSales
                                                            .length > 0
                                                            ? errors?.annualSales
                                                            : undefined
                                                    }
                                                    variant='outlined'
                                                    placeholder='0.00'
                                                    value={payload.annualSales}
                                                    focused
                                                    onChange={(e: any) => {
                                                        updateField(
                                                            'annualSales',
                                                            e.target.value
                                                        );
                                                    }}
                                                    InputProps={{
                                                        inputComponent:
                                                            NumberInput,
                                                        inputProps: {
                                                            min: '0'
                                                        }
                                                    }}
                                                />
                                            </div>

                                            <div>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    label='What size of credit line in USD would address your financial needs?'
                                                    error={
                                                        errors?.creditLine
                                                            .length > 0
                                                    }
                                                    helperText={
                                                        errors?.creditLine
                                                            .length > 0
                                                            ? errors?.creditLine
                                                            : undefined
                                                    }
                                                    variant='outlined'
                                                    placeholder='0.00'
                                                    value={payload.creditLine}
                                                    focused
                                                    onChange={(e: any) => {
                                                        updateField(
                                                            'creditLine',
                                                            e.target.value
                                                        );
                                                    }}
                                                    InputProps={{
                                                        inputComponent:
                                                            NumberInput,
                                                        inputProps: {
                                                            min: '0'
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <p className='text-[15px] text-appcolorblacktwo leading-[19px]'>
                                            Upload Documents
                                        </p>

                                        <span className='opacity-[0.3] text-[12px] text-appcolorblacktwo leading-[111.5%] pb-[2.4rem]'>
                                            Scan and upload a copy of each of
                                            the documents listed below. We
                                            accept .jpg , .png and .pdf files
                                        </span>

                                        <br />

                                        <div className='mt-8'>
                                            {documentToUpload.map(
                                                (item, index) => (
                                                    <Documentupload
                                                        useAuthorization
                                                        key={`upload-doc-${item.title}-${index}`}
                                                        title={item.title}
                                                    />
                                                )
                                            )}
                                        </div>

                                        <div>
                                            <Custombutton
                                                isLoadingButton
                                                loading={loading}
                                                type='submit'
                                                fullWidth
                                                variant='contained'
                                                styles={{
                                                    padding:
                                                        '0.75rem 0.62rem 1rem 0.93rem'
                                                }}
                                                disabled={
                                                    Object.values(payload).some(
                                                        (item: any) =>
                                                            item === undefined
                                                    ) || loading
                                                }
                                                buttonText='Apply for Financing'
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Outletouterpage>
        </div>
    );
};

export default Requestexportfinance;
