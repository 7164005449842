import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AccessPermissions, UserPermissions } from '../../../interfaces/User';
import { Domains } from '../../../interfaces/Domains';
import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    Radio,
    Stack
} from '@mui/material';
import { mutateConfig } from '../../../api/queryBase';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import {
    BoxSearchIcon,
    EmptyWalletIcon,
    RadioCheckedIcon,
    RadioIcon
} from '../../../components/icons';
import { MoreIcon } from '../../../components/icons/MoreIcon';
import { Dropdown } from 'antd';
import DisableUserModal from './modals/DisableUser';
import UpdateRole from './modals/UpdateRole';
import { backBtn } from '../sales/contracts/ContractDetails';
import { permissionTypes } from './AdminUserPermissions';
import { getErrorMessage } from '../../../utils/helper';

interface MobileAdminUserProfileListProps {
    selected: UserPermissions;
    setSelected: (obj: UserPermissions) => void;
    usersPermissions: UserPermissions[];
    isAdmin: boolean;
    refetchUsers: () => void;
    permissionsView: boolean;
    setPermissionsView: Dispatch<SetStateAction<boolean>>;
}
const MobileAdminUserProfileList = ({
    selected,
    setSelected,
    usersPermissions,
    isAdmin,
    refetchUsers,
    permissionsView,
    setPermissionsView
}: MobileAdminUserProfileListProps) => {
    const { mutate: mutatePermission } = mutateConfig({ method: 'post' })({
        url: `users/${selected?.id}/disable`,
        cacheKeys: 'users-permission'
    });

    const { mutate: mutateRole } = mutateConfig({ method: 'patch' })({
        url: `users/${selected?.id}/role`,
        cacheKeys: 'users-permission'
    });

    const [showModal, setShowModal] = useState('');
    const [submitting, setSubmitting] = useState(false);

    const handleUpdateRole = () => {
        setSubmitting(true);
        mutateRole(
            { role: 'ADMIN' },
            {
                onSettled: (_, error: AxiosError | undefined | any) => {
                    setSubmitting(false);
                    if (error) {
                        toast.error(
                            getErrorMessage(error) || 'An error occurred.'
                        );
                    } else {
                        setShowModal('');
                        toast.success('User role updated successfully.');
                        refetchUsers();
                    }
                }
            }
        );
    };

    const handleDisableUser = () => {
        setSubmitting(true);
        mutatePermission(null, {
            onSettled: (_, error: AxiosError | undefined | any) => {
                setSubmitting(false);
                if (error) {
                    toast.error(getErrorMessage(error) || 'An error occurred.');
                } else {
                    setShowModal('');
                    toast.success('User disabled successfully.');
                    refetchUsers();
                }
            }
        });
    };

    const getMenuItems = (role: string) =>
        [
            role?.toLowerCase() !== 'admin' ? (
                <Box
                    onClick={() => setShowModal('make-admin')}
                    className='flex items-center gap-[16px] h-[25px] text-steelBlue'
                >
                    <EmptyWalletIcon /> Make Admin
                </Box>
            ) : null,
            <Box
                onClick={() => setShowModal('disable')}
                className='flex items-center gap-[16px] h-[25px] text-steelBlue'
            >
                <BoxSearchIcon /> Disable User
            </Box>
        ].map((comp, key) => ({
            key,
            label: comp
        }));
    const [permissions, setPermissions] = useState<
        AccessPermissions | undefined
    >(selected?.accessPermissions);

    useEffect(() => {
        setPermissions(selected?.accessPermissions);
    }, [selected]);

    const mutation = mutateConfig({ method: 'patch' });
    const { mutate, isLoading } = mutation({
        url: `/users/${selected?.id}/permissions`
    });

    const handleUpdatePermissions = async () => {
        mutate(permissions, {
            onSettled: (_, error: AxiosError | undefined | any) => {
                if (error) {
                    toast.error(
                        getErrorMessage(error) ||
                            'An error has occured while updating permissions'
                    );
                } else {
                    toast.success('Permissions update successful');
                    refetchUsers();
                }
            }
        });
    };

    return (
        <Box className={`w-full lg:hidden ${!permissionsView && 'py-3'}`}>
            {selected && permissionsView ? (
                <Box>
                    <p
                        className='flex items-center gap-2 text-ebony font-semibold text-sm'
                        onClick={() => setPermissionsView(false)}
                    >
                        {backBtn} Back
                    </p>
                    <p className=' py-6 text-[16px] text-paleSky font-medium'>{`${selected?.firstName} ${selected?.lastName}'s Permissions`}</p>

                    <Stack direction='column' gap='16px' mb='16px'>
                        {permissions &&
                            Object.keys(permissions)?.map((section) => (
                                <Box
                                    key={section}
                                    className='p-[16px] border border-gray-300 border-1 rounded-[10px]'
                                >
                                    <Box className='font-bold text-sm text-[#101828] capitalize'>
                                        {section}
                                    </Box>
                                    <Box className='grid grid-cols-2 bg-white py-[8px]'>
                                        {permissionTypes.map((item) => (
                                            <FormControlLabel
                                                key={item.value}
                                                value={item.value}
                                                control={
                                                    <Radio
                                                        onChange={() => {
                                                            setPermissions({
                                                                ...permissions,
                                                                [section]:
                                                                    item.value
                                                            });
                                                        }}
                                                        size='small'
                                                        color='info'
                                                        icon={<RadioIcon />}
                                                        checkedIcon={
                                                            <RadioCheckedIcon />
                                                        }
                                                    />
                                                }
                                                label={item.label}
                                                checked={
                                                    permissions[
                                                        section as keyof Domains
                                                    ] === item.value
                                                }
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                    </Stack>
                    <div className='flex justify-end my-6'>
                        <Button
                            variant='contained'
                            fullWidth
                            sx={{
                                padding: '8px',
                                height: '40px',
                                borderRadius: '8px'
                            }}
                            onClick={handleUpdatePermissions}
                        >
                            {isLoading ? (
                                <CircularProgress size={15} color='inherit' />
                            ) : (
                                'Save Changes'
                            )}
                        </Button>
                    </div>
                </Box>
            ) : (
                <>
                    <Box className='text-[14px] font-medium text-sm text-paleSky'>
                        All Users
                    </Box>
                    <Stack direction='column' gap='16px' my='16px'>
                        {usersPermissions?.map((item: UserPermissions) => (
                            <Box
                                className='flex px-[12px] py-[16px] border border-gray-300 border-1 rounded-[6px] items-center'
                                key={item.id}
                                onClick={() => setSelected(item)}
                            >
                                <Box
                                    className=' font-semibold text-[14px] flex-1 text-steelBlue'
                                    onClick={() => setPermissionsView(true)}
                                >{`${item.firstName} ${item.lastName}`}</Box>

                                <Box className='ml-auto flex gap-[6px] items-center'>
                                    {isAdmin && (
                                        <Dropdown
                                            menu={{
                                                items: getMenuItems(item.role)
                                            }}
                                            trigger={['click']}
                                        >
                                            <Box>
                                                <MoreIcon />
                                            </Box>
                                        </Dropdown>
                                    )}
                                </Box>
                            </Box>
                        ))}
                    </Stack>
                </>
            )}
            {showModal === 'disable' && selected && (
                <DisableUserModal
                    userEmail={selected?.email}
                    open={showModal === 'disable'}
                    onClose={() => setShowModal('')}
                    handleDisable={handleDisableUser}
                    loading={submitting}
                />
            )}

            {showModal === 'make-admin' && selected && (
                <UpdateRole
                    userName={`${selected?.firstName} ${selected?.lastName}`}
                    open={showModal === 'make-admin'}
                    onClose={() => setShowModal('')}
                    handleDisable={handleUpdateRole}
                    loading={submitting}
                />
            )}
        </Box>
    );
};

export default MobileAdminUserProfileList;
