import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';
import {
    ContainerReefer,
    ContainerReeferType
} from '../interfaces/ContainerReefer';

const temperatureUnits = [
    { unit: '°C', value: 'Celsius' },
    { unit: '°F', value: 'Fahrenheit' }
];

export const ReeferActive = (props: {
    reefer: ContainerReefer;
    setReefer: React.Dispatch<React.SetStateAction<ContainerReefer>>;
    setIsValidated: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { reefer, setReefer, setIsValidated } = props;
    const checkValidatated = (update: ContainerReefer) => {
        const validate = ContainerReeferType.safeParse(update);
        setIsValidated(validate.success);
    };

    return (
        <Box>
            <Grid
                container
                spacing={2}
                sx={{
                    marginTop: '0px'
                }}
            >
                <Grid item xs={9}>
                    <TextField
                        value={reefer?.temperature}
                        margin='normal'
                        fullWidth
                        label='Temparature'
                        type='number'
                        onChange={(e) => {
                            setReefer((prev) => {
                                const update = {
                                    ...prev,
                                    temperature: Number(e.target.value)
                                };

                                checkValidatated(update);

                                return update;
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={3} sx={{ marginTop: '16px' }}>
                    <FormControl fullWidth>
                        <InputLabel id='temperature-label'>Unit</InputLabel>
                        <Select
                            labelId='temperature-label'
                            value={reefer?.temperatureUnit}
                            label='Unit'
                            onChange={(e) => {
                                setReefer((prev) => {
                                    const update = {
                                        ...prev,
                                        temperatureUnit: e.target.value
                                    };

                                    checkValidatated(update);

                                    return update;
                                });
                            }}
                        >
                            {temperatureUnits.map(({ unit, value }) => (
                                <MenuItem key={value} value={value}>
                                    {unit}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={reefer?.humidity}
                        margin='normal'
                        fullWidth
                        label='Humidity'
                        type='number'
                        onChange={(e) => {
                            setReefer((prev) => {
                                const update = {
                                    ...prev,
                                    humidity: Number(e.target.value)
                                };

                                checkValidatated(update);

                                return update;
                            });
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    %
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: '14px' }}>
                    <FormControl fullWidth>
                        <InputLabel id='vent-label'>Vent Settings</InputLabel>
                        <Select
                            labelId='vent-label'
                            value={reefer?.ventSettings}
                            label='Vent Settings'
                            onChange={(e) => {
                                setReefer((prev) => {
                                    const update = {
                                        ...prev,
                                        ventSettings: e.target.value
                                    };

                                    checkValidatated(update);

                                    return update;
                                });
                            }}
                        >
                            {['open', 'closed'].map((value) => (
                                <MenuItem
                                    key={value}
                                    className='capitalize'
                                    value={value}
                                >
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        value={reefer?.airflow}
                        margin='normal'
                        fullWidth
                        label='Airflow'
                        type='number'
                        onChange={(e) => {
                            setReefer((prev) => {
                                const update = {
                                    ...prev,
                                    airflow: Number(e.target.value)
                                };

                                checkValidatated(update);

                                return update;
                            });
                        }}
                    />
                </Grid>
                <Grid item xs={4} sx={{ marginTop: '16px' }}>
                    <FormControl fullWidth>
                        <InputLabel id='airflow-label'>Unit</InputLabel>
                        <Select
                            labelId='airflow-label'
                            value={reefer?.airFlowUnit}
                            label='Unit'
                            onChange={(e) => {
                                setReefer((prev) => {
                                    const update = {
                                        ...prev,
                                        airFlowUnit: e.target.value
                                    };

                                    checkValidatated(update);

                                    return update;
                                });
                            }}
                        >
                            {['CBM/hr', 'CFT/hr'].map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={reefer?.oxygenLevel}
                        margin='normal'
                        fullWidth
                        label='Oxygen Level'
                        type='number'
                        onChange={(e) => {
                            setReefer((prev) => {
                                const update = {
                                    ...prev,
                                    oxygenLevel: Number(e.target.value)
                                };

                                checkValidatated(update);

                                return update;
                            });
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    %
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={reefer?.carbonDioxideLevel}
                        margin='normal'
                        fullWidth
                        label='Carbon Dioxide Level'
                        type='number'
                        onChange={(e) => {
                            setReefer((prev) => {
                                const update = {
                                    ...prev,
                                    carbonDioxideLevel: Number(e.target.value)
                                };

                                checkValidatated(update);

                                return update;
                            });
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    %
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={reefer?.nitrogenLevel}
                        margin='normal'
                        fullWidth
                        label='Nitrogen Level'
                        type='number'
                        onChange={(e) => {
                            setReefer((prev) => {
                                const update = {
                                    ...prev,
                                    nitrogenLevel: Number(e.target.value)
                                };

                                checkValidatated(update);

                                return update;
                            });
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    %
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        sx={{
                            marginTop: '30px'
                        }}
                        value={reefer?.genSetRequested}
                        control={
                            <Checkbox
                                onChange={(_, v) => {
                                    setReefer((prev) => {
                                        const update = {
                                            ...prev,
                                            genSetRequested: v
                                        };

                                        checkValidatated(update);

                                        return update;
                                    });
                                }}
                            />
                        }
                        label='Genset Requested'
                        labelPlacement='end'
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        value={reefer?.temperatureControlInstructions}
                        onChange={(e) => {
                            setReefer((prev) => {
                                const update = {
                                    ...prev,
                                    temperatureControlInstructions:
                                        e.target.value
                                };

                                checkValidatated(update);

                                return update;
                            });
                        }}
                        label='Temperature Control Instructions'
                        multiline
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
