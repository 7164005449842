import { useState } from 'react';
import { ChevronDownIcon } from '../../../../components/icons';

const InfoDropdown = ({ label, text }: { label: string; text: string }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div
            className='border-gray300 border rounded-[6px] p-[12px]'
            onClick={() => setIsOpen(!isOpen)}
        >
            <div className='flex items-center justify-between'>
                {label}
                <div className={isOpen ? 'rotate-180' : ''}>
                    <ChevronDownIcon />
                </div>
            </div>

            {isOpen && (
                <p className='text-xs font-normal text-midnightHaze'>{text}</p>
            )}
        </div>
    );
};

export default InfoDropdown;
