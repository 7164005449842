const NotPaidInvoiceIcon = () => {
    return (
        <svg
            width='40'
            height='41'
            viewBox='0 0 40 41'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect y='0.0424805' width='40' height='40' rx='8' fill='#667085' />
            <path
                d='M28.5 19.3425V15.0825C28.5 11.0525 27.56 10.0425 23.78 10.0425H16.22C12.44 10.0425 11.5 11.0525 11.5 15.0825V26.3425C11.5 29.0025 12.96 29.6325 14.73 27.7325L14.74 27.7225C15.56 26.8525 16.81 26.9225 17.52 27.8725L18.53 29.2225'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M16 15.0425H24'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M17 19.0425H23'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M26.211 22.8128L22.671 26.3528C22.531 26.4928 22.401 26.7528 22.371 26.9428L22.181 28.2928C22.111 28.7828 22.451 29.1228 22.941 29.0528L24.291 28.8628C24.481 28.8328 24.751 28.7028 24.881 28.5628L28.421 25.0228C29.031 24.4128 29.321 23.7028 28.421 22.8028C27.531 21.9128 26.821 22.2028 26.211 22.8128Z'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                stroke-miterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M25.6992 23.3228C25.9992 24.4028 26.8392 25.2428 27.9192 25.5428'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                stroke-miterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export default NotPaidInvoiceIcon;
