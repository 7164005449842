import { Box, Typography } from '@mui/material';
import { ThreeLayersIcon } from '../../../components/icons/ThreeLayersIcon';
import { SuitUIButton } from '../../../components/SuitUIButton';
import { useNavigate } from 'react-router-dom';

export const InsuranceAdBox = () => {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                width: '447px',
                height: '430px',
                borderRadius: '10px',
                color: 'white',
                p: '32px'
            }}
            className='bg-appcolorprimary'
        >
            <Box sx={{ display: 'flex', mb: '24px' }}>
                <ThreeLayersIcon />
                <Typography
                    sx={{
                        pl: '10px',
                        pt: '5px',
                        fontWeight: 700,
                        fontSize: '22px'
                    }}
                >
                    Why our Insurance?
                </Typography>
            </Box>
            <Box sx={{ fontSize: '17px', fontWeight: 300, mb: '20px' }}>
                <p>
                    FrontEdge can help you insure your cargo against different
                    risks.
                </p>
                {'\u00A0'}
                <div>
                    With FrontEdge, you are automatically connected to several
                    insurance partners which offers you the following benefits:
                    <ul>
                        <li>- Transparency on pricing of premiums</li>
                        <li>- Instant provision of insurance policies</li>
                        <li>- Automated claims process</li>
                    </ul>
                </div>
            </Box>
            <SuitUIButton
                text='Discover Our Insurance'
                onClick={() => {
                    navigate('/insurance/profile');
                }}
                invert
            />
        </Box>
    );
};
