import React, { useState, useEffect, useRef } from 'react';
import {
    TradeContainer,
    TradeContent
} from '../../../../../styles/TradeDocStyles';
import FormHeader from '../../../../../components/dashboard/tradeDocuments/common/FormHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import useCorporatePartners from '../../../../../customhooks/useCorporatePartners';
import { nanoid } from '@reduxjs/toolkit';
import useCountries from '../../../../../customhooks/useCountries';
import {
    createCertOfOrigin,
    getSingleDocuments
} from '../../../../../api/documents/documents';
import { openNotification } from '../../../../../utils/helper';
import Addpartnerdrawer from '../../addpartnerdrawer/addpartnerdrawer';
import CustomModal from '../../../../../components/CustomModal';
import Savetradedocument from '../../savetradedocument/savetradedocument';
import CertificateOriginForm from '../../../../../components/dashboard/tradeDocuments/formComponents/CertificateOriginForm';
import FormPreviewTemplate2 from '../../../../../components/dashboard/tradeDocuments/previewComponents/FormPreviewTemplate2';
import { Backdrop } from '@mui/material';

const CertificateOfOriginNew = () => {
    const breadcrumbs = [
        {
            title: 'Home',
            link: '/',
            active: false
        },
        {
            title: 'Documents',
            link: '/trade-documents',
            active: false
        },
        {
            title: 'Draft Certificate of Origin',
            link: '/trade-documents/certificate-of-origin',
            active: true
        }
    ];

    const navigate = useNavigate();
    const location = useLocation();
    const [params, setParams] = useState<any>();
    const [loadingData, setLoadingData] = useState(false);
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const param: any = Object.fromEntries(searchParams.entries());
        setParams(param);
    }, [location.search]);

    useEffect(() => {
        if (params?.edit) {
            fetchDocuments();
        }
    }, [params]);

    const { countries, isCountriesLoading } = useCountries();
    const { corporatePartners, isLoading } = useCorporatePartners({ page: 1 });

    const pageTopRef = useRef<any>(null);
    const myDiv = useRef<HTMLDivElement | null>(null);

    const [showPreview, setShowPreview] = useState(false);
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [signatureUrlBase64, setSignatureUrlBase64] = useState('');
    const [signatureUrlBase64Chamber, setSignatureUrlBase64Chamber] =
        useState('');
    const [saveOnly, setSaveOnly] = useState(true);
    const [fileId, setFileId] = useState(undefined);
    const [error, setError] = useState<string | any>(null);
    const [totalGrossWeight, setTotalGrossWeight] = useState(0);
    const [loading, setLoading] = useState(false);
    const [payload, setPayload] = useState<any>({
        buyerAddress: undefined,
        buyerName: '',
        buyerId: undefined,
        chamberIssuedDate: Date.now(),
        chamberNameOfAuthorisedSignatory: undefined,
        chamberPlaceOfIssue: undefined,
        chamberSignatoryCompany: undefined,
        chamberSignatureUrl: undefined,
        consigneeAddress: undefined,
        consigneeId: undefined,
        dateOfDeparture: undefined,
        exportInvoiceDate: Date.now(),
        exportInvoiceNumber: nanoid(9),
        exporterAddress: undefined,
        exporterAccountNumber: '',
        exporterBankName: '',
        exporterSwiftCode: '',
        consigneeAccountNumber: '',
        consigneeBankName: '',
        consigneeSwiftCode: '',
        exporterId: undefined,
        exporterIssuedDate: Date.now(),
        exporterNameOfAuthorisedSignatory: undefined,
        exporterPlaceOfIssue: undefined,
        exporterSignatoryCompany: undefined,
        exporterSignatureUrl: undefined,
        finalDestination: undefined,
        letterOfCreditNumber: undefined,
        logoUrl: '',
        methodOfDispatch: undefined,
        packingDetails: undefined,
        portOfLoadingId: undefined,
        transport: undefined,
        typeOfShipment: undefined,
        voyageNumber: undefined,
        documentName: '',
        portOfLoadingValue: undefined,
        portOfDischargeValue: undefined,
        billOfLadingNumber: ''
    });
    const [errors, setErrors] = useState({
        buyerAddress: '',
        buyerId: '',
        chamberIssuedDate: '',
        chamberNameOfAuthorisedSignatory: '',
        chamberPlaceOfIssue: '',
        chamberSignatoryCompany: '',
        chamberSignatureUrl: '',
        consigneeAddress: '',
        consigneeId: '',
        dateOfDeparture: '',
        exportInvoiceDate: '',
        exportInvoiceNumber: '',
        exporterAddress: '',
        exporterId: '',
        exporterIssuedDate: '',
        exporterNameOfAuthorisedSignatory: '',
        exporterPlaceOfIssue: '',
        exporterSignatoryCompany: '',
        exporterSignatureUrl: '',
        finalDestination: '',
        letterOfCreditNumber: '',
        logoUrl: '',
        methodOfDispatch: '',
        packingDetails: '',
        portOfLoadingId: '',
        transport: '',
        typeOfShipment: '',
        voyageNumber: '',
        documentName: '',
        portOfDischargeId: '',
        billOfLadingNumber: ''
    });
    const [totalAmount] = useState(0);
    const [quoteFields, setQuoteFields] = useState([
        {
            id: nanoid(9),
            productCode: nanoid(9),
            desc: undefined,
            type: undefined,
            grossWeight: 0,
            qtyOfPackage: 0
        }
    ]);

    const [showAddPartner, setShowAddPartner] = useState(false);

    const fetchDocuments = async () => {
        setLoadingData(true);
        try {
            const res = await getSingleDocuments({
                corporateDocumentId: params?.edit
            });

            const { data } = res.data;
            setData(data);

            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            if (error?.response) {
                openNotification('error', `Documents, Something went wrong`);
            } else {
                openNotification('error', `Documents, Something went wrong`);
            }
        }
    };

    useEffect(() => {
        if (data) {
            setFileId(data?.id);
            setPayload({
                documentName: data?.documentName || '',
                buyerAddress: data?.documentData?.buyer.address || '',
                buyerName: data?.documentData?.buyer.name || '',
                buyerId: data?.documentData?.buyerId || '',
                chamberIssuedDate:
                    data?.documentData?.chamberIssuedDate || Date.now(),
                chamberNameOfAuthorisedSignatory:
                    data?.documentData?.chamberNameOfAuthorisedSignatory || '',
                chamberPlaceOfIssue:
                    data?.documentData?.chamberPlaceOfIssue || '',
                chamberSignatoryCompany:
                    data?.documentData?.chamberSignatoryCompany || '',
                chamberSignatureUrl:
                    data?.documentData?.chamberSignatureUrl || '',
                consigneeAddress: data?.documentData?.consigneeAddress || '',
                consigneeId: data?.documentData?.consigneeId || '',
                dateOfDeparture:
                    data?.documentData?.dateOfDeparture || Date.now(),
                exportInvoiceDate:
                    data?.documentData?.exportInvoiceDate || Date.now(),
                exportInvoiceNumber:
                    data?.documentData?.exportInvoiceNumber || nanoid(9),
                exporterAddress: data?.documentData?.exporter.address || '',
                exporterAccountNumber: '',
                exporterBankName: '',
                exporterSwiftCode: '',
                consigneeAccountNumber: '',
                consigneeBankName: '',
                consigneeSwiftCode: '',
                exporterId: data?.documentData?.exporter.exporterId || '',
                exporterIssuedDate:
                    data?.documentData?.exporter.exporterIssuedDate ||
                    Date.now(),
                exporterNameOfAuthorisedSignatory:
                    data?.documentData?.exporter
                        .exporterNameOfAuthorisedSignatory || '',
                exporterPlaceOfIssue:
                    data?.documentData?.exporter.exporterPlaceOfIssue || '',
                exporterSignatoryCompany:
                    data?.documentData?.exporter.exporterSignatoryCompany || '',
                exporterSignatureUrl:
                    data?.documentData?.exporter.exporterSignatureUrl || '',
                finalDestination:
                    data?.documentData?.exporter.finalDestination || '',
                letterOfCreditNumber:
                    data?.documentData?.exporter.letterOfCreditNumber || '',
                logoUrl: data?.documentData?.logoUrl || '',
                methodOfDispatch: data?.documentData?.methodOfDispatch || '',
                packingDetails: data?.documentData?.packingDetails || '',
                portOfLoadingId: data?.documentData?.portOfLoading.name || '',
                transport: data?.documentData?.transport || '',
                typeOfShipment: data?.documentData?.typeOfShipment || '',
                voyageNumber: data?.documentData?.voyageNumber || '',
                portOfLoadingValue: data?.documentData?.portOfLoading || '',
                portOfDischargeValue: data?.documentData?.portOfDischarge || '',
                billOfLadingNumber: data?.documentData?.billOfLadingNumber || ''
            });

            const transformArray = (array: any) => {
                const newArray: any = [];

                array.forEach((val: any) => {
                    newArray.push({
                        id: val.id,
                        productCode: val.itemMarker,
                        desc: val.descriptionOfGoods,
                        qtyOfPackage: val.unitQuantity,
                        kindOfPackage: val.kindAndNumber,
                        grossWeight: val.grossWeight,
                        tariffCode: val.tariffCode
                    });
                });
                return newArray;
            };
            setQuoteFields(transformArray(data?.documentData?.packing));
        }
    }, [data]);
    const closeShowAddParty = () => {
        setShowAddPartner(false);
    };

    /**
     * Update input fields
     * @param name string
     * @param value any
     */
    const updateField = (name: string, value: any) => {
        setPayload((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
    };

    /**
     * Update quotes input fields
     * @param id string
     * @param name string
     * @param value any
     */
    const updateQuoteField = (index: number, name: string, value: any) => {
        const newContainers: any[] = [...quoteFields];
        newContainers[index][name] = value;
        setQuoteFields(newContainers);
    };

    /**
     * Adds quotes input field line
     */
    const addQuoteField = () => {
        setQuoteFields([
            ...quoteFields,
            {
                id: nanoid(9),
                productCode: nanoid(9),
                desc: undefined,
                type: undefined,
                grossWeight: 0,
                qtyOfPackage: 0
            }
        ]);
    };

    /**
     * Remove quotes input field line
     */
    const removeQuoteField = (index: number) => {
        setQuoteFields(quoteFields.filter((item, i) => i !== index));
    };

    /**
     * Close save document
     */
    const [showSaveModal, setShowSaveModal] = useState(false);
    const closeSaveModal = () => {
        setShowSaveModal(false);
    };

    useEffect(() => {
        let totalGrossWeight = 0;

        quoteFields.forEach((item: any) => {
            totalGrossWeight += isNaN(item?.grossWeight)
                ? 0
                : Number(item?.grossWeight);
        });

        setTotalGrossWeight(totalGrossWeight);
    }, [quoteFields]);

    const onSubmit = async () => {
        setLoading(true);
        setError(null);

        setErrors((prevState: any) => ({
            ...prevState,
            buyerAddress: '',
            buyerId: '',
            chamberIssuedDate: '',
            chamberNameOfAuthorisedSignatory: '',
            chamberPlaceOfIssue: '',
            chamberSignatoryCompany: '',
            chamberSignatureUrl: '',
            consigneeAddress: '',
            consigneeId: '',
            dateOfDeparture: '',
            exportInvoiceDate: '',
            exportInvoiceNumber: '',
            exporterAddress: '',
            exporterId: '',
            exporterIssuedDate: '',
            exporterNameOfAuthorisedSignatory: '',
            exporterPlaceOfIssue: '',
            exporterSignatoryCompany: '',
            exporterSignatureUrl: '',
            finalDestination: '',
            letterOfCreditNumber: '',
            logoUrl: '',
            methodOfDispatch: '',
            packingDetails: '',
            portOfLoadingId: '',
            transport: '',
            typeOfShipment: '',
            voyageNumber: '',
            documentName: '',
            portOfDischargeId: ''
        }));

        try {
            const items: any[] = [];

            const {
                buyerAddress,
                buyerId,
                chamberIssuedDate,
                chamberNameOfAuthorisedSignatory,
                chamberPlaceOfIssue,
                chamberSignatoryCompany,
                chamberSignatureUrl,
                consigneeAddress,
                consigneeId,
                dateOfDeparture,
                exportInvoiceDate,
                exportInvoiceNumber,
                exporterAddress,
                exporterId,
                exporterIssuedDate,
                exporterNameOfAuthorisedSignatory,
                exporterPlaceOfIssue,
                exporterSignatoryCompany,
                exporterSignatureUrl,
                finalDestination,
                letterOfCreditNumber,
                logoUrl,
                methodOfDispatch,
                packingDetails,
                portOfLoadingId,
                transport,
                typeOfShipment,
                voyageNumber,
                documentName,
                portOfDischargeValue,
                portOfLoadingValue,
                billOfLadingNumber
            } = payload;

            const dischargeValue: any = portOfDischargeValue;
            const portValue: any = portOfLoadingValue;

            quoteFields.forEach((item: any, index) => {
                items.push({
                    chargeable: item?.chargeable,
                    descriptionOfGoods: item?.desc,
                    kindAndNumber: item?.kindOfPackage,
                    unitQuantity: item?.qtyOfPackage,
                    grossWeight: item?.grossWeight,
                    tariffCode: item?.tariffCode,
                    netWeight: item?.netWeight,
                    itemMarker: item?.productCode
                });
            });

            const res = await createCertOfOrigin({
                createdType: 'CREATED',
                documentData: {
                    packing: items,
                    buyerAddress,
                    buyerId,
                    chamberIssuedDate,
                    chamberNameOfAuthorisedSignatory,
                    chamberPlaceOfIssue,
                    chamberSignatoryCompany,
                    chamberSignatureUrl,
                    consigneeAddress,
                    consigneeId,
                    dateOfDeparture,
                    exportInvoiceDate,
                    exportInvoiceNumber,
                    exporterAddress,
                    exporterId,
                    exporterIssuedDate,
                    exporterNameOfAuthorisedSignatory,
                    exporterPlaceOfIssue,
                    exporterSignatoryCompany,
                    exporterSignatureUrl,
                    finalDestination,
                    letterOfCreditNumber,
                    logoUrl: logoUrl !== '' ? logoUrl : undefined,
                    methodOfDispatch,
                    packingDetails,
                    totalGrossWeight,
                    transport,
                    typeOfShipment,
                    voyageNumber,
                    portOfDischargeId: dischargeValue?.id,
                    portOfLoadingId: portValue?.id,
                    billOfLadingNumber
                },
                documentType: 'DRAFT_CERTIFICATE_OF_ORIGIN',
                documentName,
                id: fileId
            });

            const { id } = res.data.data;

            setFileId(id);

            openNotification(
                'success',
                'Draft Certificate of Origin  created successfully'
            );

            setShowSaveModal(false);
            setLoading(false);

            if (saveOnly) {
                navigate('/trade-documents');
            }
        } catch (error) {
            window.scroll({
                top: pageTopRef?.current?.offsetTop,
                left: 0,
                behavior: 'smooth'
            });

            setShowSaveModal(false);
            setLoading(false);
            const otherErrors: any[] = [];

            if (error?.response) {
                if (error?.response?.data?.error) {
                    const resError = error?.response?.data?.error;

                    if (typeof resError === 'string') {
                        const errorList = (
                            <li key={`error-list-${1}`}>{resError}</li>
                        );
                        setError(errorList);
                    } else {
                        Object.entries(resError).forEach(([key, value]) => {
                            if (Object.keys(errors).includes(key)) {
                                setErrors((prevState) => ({
                                    ...prevState,
                                    [key]: value
                                }));
                            } else {
                                otherErrors.push(value);
                            }
                        });

                        if (otherErrors.length > 0) {
                            const errorList = otherErrors.map(
                                (element: any, index) => {
                                    return (
                                        <li key={`error-list-${index}`}>
                                            {element}
                                        </li>
                                    );
                                }
                            );
                            setError(errorList);
                        }
                    }
                } else {
                    setError(
                        error?.response?.data?.message || 'Something went wrong'
                    );
                }
            } else {
                setError('Something went wrong');
            }
        }
    };

    return (
        <TradeContainer>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loadingData}
            >
                <img
                    src='/img/vectors/loadingcircles.svg'
                    alt='loading'
                    className='animate-spin'
                />
            </Backdrop>
            <FormHeader
                breadcrumbs={breadcrumbs}
                docTitle='Draft Certificate of Origin'
                {...{
                    showPreview,
                    setShowPreview,
                    myDiv,
                    downloadLoading,
                    setDownloadLoading
                }}
                onSaveOnlyAction={() => {
                    setSaveOnly(true);
                    setShowSaveModal(true);
                }}
                onSaveAction={() => {
                    setSaveOnly(false);
                    setShowSaveModal(true);
                }}
                disabled={
                    Object.values(payload).some(
                        (item: any) => item === undefined
                    ) || loading
                }
            />

            <TradeContent>
                {showPreview ? (
                    <div ref={myDiv}>
                        <FormPreviewTemplate2
                            data={payload}
                            docTitle='Draft Certificate of Origin'
                            quoteFields={quoteFields}
                            totalAmount={totalAmount}
                            signatureUrlBase64={signatureUrlBase64}
                            signatureUrlBase64Chamber={
                                signatureUrlBase64Chamber
                            }
                            {...{
                                totalGrossWeight
                            }}
                        />
                    </div>
                ) : (
                    <CertificateOriginForm
                        {...{
                            error,
                            updateField,
                            payload,
                            totalGrossWeight,
                            corporatePartners,
                            loadingCorporatepartners: isLoading,
                            setShowAddPartner,
                            errors,
                            quoteFields,
                            updateQuoteField,
                            removeQuoteField,
                            addQuoteField,
                            loading,
                            setSignatureUrlBase64Chamber,
                            setSignatureUrlBase64,
                            signatureUrlBase64,
                            signatureUrlBase64Chamber
                        }}
                    />
                )}
            </TradeContent>

            {showAddPartner ? (
                <Addpartnerdrawer
                    closeDrawer={closeShowAddParty}
                    showDrawer={showAddPartner}
                />
            ) : null}

            {showSaveModal && (
                <CustomModal
                    open={showSaveModal}
                    onClose={closeSaveModal}
                    title='Saving document'
                    maxWidth='xs'
                    styles={{
                        overflowX: 'hidden',
                        '.MuiPaper-root': {
                            width: '100%'
                        }
                    }}
                >
                    <Savetradedocument
                        updateField={updateField}
                        onSubmit={onSubmit}
                        loading={loading}
                        data={payload}
                    />
                </CustomModal>
            )}
        </TradeContainer>
    );
};

export default CertificateOfOriginNew;
