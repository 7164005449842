export const CancelledInvoiceIcon = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='8' fill='#FF692E' />
            <path
                d='M17.1699 22.83L22.8299 17.17'
                stroke='#E44000'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M22.8299 22.83L17.1699 17.17'
                stroke='#E44000'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M17 30H23C28 30 30 28 30 23V17C30 12 28 10 23 10H17C12 10 10 12 10 17V23C10 28 12 30 17 30Z'
                stroke='#E44000'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export const CompletedInvoiceIcon = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='8' fill='#16B364' />
            <path
                d='M17 30H23C28 30 30 28 30 23V17C30 12 28 10 23 10H17C12 10 10 12 10 17V23C10 28 12 30 17 30Z'
                stroke='#A2E1C1'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15.75 20L18.58 22.83L24.25 17.17'
                stroke='#A2E1C1'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export const DraftInvoiceIcon = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='8' fill='#1B346A' />
            <path
                d='M21.2603 11.6L13.0503 20.29C12.7403 20.62 12.4403 21.27 12.3803 21.72L12.0103 24.96C11.8803 26.13 12.7203 26.93 13.8803 26.73L17.1003 26.18C17.5503 26.1 18.1803 25.77 18.4903 25.43L26.7003 16.74C28.1203 15.24 28.7603 13.53 26.5503 11.44C24.3503 9.37 22.6803 10.1 21.2603 11.6Z'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M19.8896 13.05C20.3196 15.81 22.5596 17.92 25.3396 18.2'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M11 30H29'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export const IssuedInvoiceIcon = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='8' fill='#FDE272' />
            <path
                d='M28 16.25V26C28 29 26.21 30 24 30H16C13.79 30 12 29 12 26V16.25C12 13 13.79 12.25 16 12.25C16 12.87 16.25 13.43 16.66 13.84C17.07 14.25 17.63 14.5 18.25 14.5H21.75C22.99 14.5 24 13.49 24 12.25C26.21 12.25 28 13 28 16.25Z'
                stroke='#DF835A'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M24 12.25C24 13.49 22.99 14.5 21.75 14.5H18.25C17.63 14.5 17.07 14.25 16.66 13.84C16.25 13.43 16 12.87 16 12.25C16 11.01 17.01 10 18.25 10H21.75C22.37 10 22.93 10.25 23.34 10.66C23.75 11.07 24 11.63 24 12.25Z'
                stroke='#DF835A'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M16 21H20'
                stroke='#DF835A'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M16 25H24'
                stroke='#DF835A'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export const ApprovedInvoiceIcon = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='8' fill='#9DB316' />
            <path
                d='M17.3096 22.7L18.8096 24.2L22.8096 20.2'
                stroke='#D8E1A2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M18 14H22C24 14 24 13 24 12C24 10 23 10 22 10H18C17 10 16 10 16 12C16 14 17 14 18 14Z'
                stroke='#D8E1A2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M24 12.02C27.33 12.2 29 13.43 29 18V24C29 28 28 30 23 30H17C12 30 11 28 11 24V18C11 13.44 12.67 12.2 16 12.02'
                stroke='#D8E1A2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export const BookingPlacedInvoiceIcon = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='8' fill='#305075' />
            <path
                d='M17.5596 26V21'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M20 23.5H15'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M16 10V13'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M24 10V13'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M23.8098 11.42C27.1498 11.54 28.8398 12.77 28.9398 17.47L29.0698 23.64C29.1498 27.76 28.1998 29.83 23.1998 29.94L17.1998 30.06C12.1998 30.16 11.1598 28.12 11.0798 24.01L10.9398 17.83C10.8398 13.13 12.4898 11.83 15.8098 11.58L23.8098 11.42Z'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export const ExpiredInvoiceIcon = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='8' fill='#9F2D00' />
            <path
                d='M15 22H20'
                stroke='#E2C0B2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15 13.96L11.25 10.21'
                stroke='#E2C0B2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.96 10.25L11.21 14'
                stroke='#E2C0B2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15 18H23'
                stroke='#E2C0B2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M18 10H24C27.33 10.18 29 11.41 29 15.99V24'
                stroke='#E2C0B2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M11 17.01V23.98C11 27.99 12 30 17 30H20C20.17 30 22.84 30 23 30'
                stroke='#E2C0B2'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M29 24L23 30V27C23 25 24 24 26 24H29Z'
                stroke='#E2C0B2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export const StockInvoiceIcon = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='8' fill='#667085' />
            <path
                d='M13 23V16'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M13.25 30C15.0449 30 16.5 28.5449 16.5 26.75C16.5 24.9551 15.0449 23.5 13.25 23.5C11.4551 23.5 10 24.9551 10 26.75C10 28.5449 11.4551 30 13.25 30Z'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M13 16C14.6569 16 16 14.6569 16 13C16 11.3431 14.6569 10 13 10C11.3431 10 10 11.3431 10 13C10 14.6569 11.3431 16 13 16Z'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M27 16C28.6569 16 30 14.6569 30 13C30 11.3431 28.6569 10 27 10C25.3431 10 24 11.3431 24 13C24 14.6569 25.3431 16 27 16Z'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M13.1299 23C13.5799 21.25 15.1799 19.95 17.0699 19.96L20.4999 19.97C23.1199 19.98 25.3499 18.3 26.1699 15.96'
                stroke='#CFD4E2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export const PaidInvoiceIcon = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='8' fill='#16B364' />
            <path
                d='M17.5 21.75C17.5 22.72 18.25 23.5 19.17 23.5H21.05C21.85 23.5 22.5 22.82 22.5 21.97C22.5 21.06 22.1 20.73 21.51 20.52L18.5 19.47C17.91 19.26 17.51 18.94 17.51 18.02C17.51 17.18 18.16 16.49 18.96 16.49H20.84C21.76 16.49 22.51 17.27 22.51 18.24'
                stroke='#A2E1C1'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M20 15.5V24.5'
                stroke='#A2E1C1'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M30 20C30 25.52 25.52 30 20 30C14.48 30 10 25.52 10 20C10 14.48 14.48 10 20 10'
                stroke='#A2E1C1'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M30 14V10H26'
                stroke='#A2E1C1'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M25 15L30 10'
                stroke='#A2E1C1'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export const PartiallyPaidInvoiceIcon = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='8' fill='#9DB316' />
            <path
                d='M20.66 10.5181L20.63 10.5881L17.73 17.3181H14.88C14.2 17.3181 13.55 17.4581 12.96 17.7081L14.71 13.5281L14.75 13.4281L14.82 13.2681C14.84 13.2081 14.86 13.1481 14.89 13.0981C16.2 10.0681 17.68 9.37814 20.66 10.5181Z'
                stroke='#D8E1A2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M26.0505 17.5181C25.6005 17.3781 25.1205 17.3181 24.6405 17.3181H17.7305L20.6305 10.5881L20.6605 10.5181C20.8105 10.5681 20.9505 10.6381 21.1005 10.6981L23.3105 11.6281C24.5405 12.1381 25.4005 12.6681 25.9205 13.3081C26.0205 13.4281 26.1005 13.5381 26.1705 13.6681C26.2605 13.8081 26.3305 13.9481 26.3705 14.0981C26.4105 14.1881 26.4405 14.2781 26.4605 14.3581C26.7305 15.1981 26.5705 16.2281 26.0505 17.5181Z'
                stroke='#D8E1A2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M29.5217 22.1984V24.1484C29.5217 24.3484 29.5117 24.5484 29.5017 24.7484C29.3117 28.2384 27.3617 29.9984 23.6617 29.9984H15.8617C15.6217 29.9984 15.3817 29.9784 15.1517 29.9484C11.9717 29.7384 10.2717 28.0384 10.0617 24.8584C10.0317 24.6284 10.0117 24.3884 10.0117 24.1484V22.1984C10.0117 20.1884 11.2317 18.4584 12.9717 17.7084C13.5717 17.4584 14.2117 17.3184 14.8917 17.3184H24.6517C25.1417 17.3184 25.6217 17.3884 26.0617 17.5184C28.0517 18.1284 29.5217 19.9884 29.5217 22.1984Z'
                stroke='#D8E1A2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.71 13.5281L12.96 17.7081C11.22 18.4581 10 20.1881 10 22.1981V19.2681C10 16.4281 12.02 14.0581 14.71 13.5281Z'
                stroke='#D8E1A2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M29.5186 19.2677V22.1977C29.5186 19.9977 28.0586 18.1277 26.0586 17.5277C26.5786 16.2277 26.7286 15.2077 26.4786 14.3577C26.4586 14.2677 26.4286 14.1777 26.3886 14.0977C28.2486 15.0577 29.5186 17.0277 29.5186 19.2677Z'
                stroke='#D8E1A2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export const OverdueIcon = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='8' fill='#9F2D00' />
            <path
                d='M20 15.75V21'
                stroke='#E2C0B2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M29.0802 16.58V23.42C29.0802 24.54 28.4802 25.58 27.5102 26.15L21.5702 29.58C20.6002 30.14 19.4002 30.14 18.4202 29.58L12.4802 26.15C11.5102 25.59 10.9102 24.55 10.9102 23.42V16.58C10.9102 15.46 11.5102 14.42 12.4802 13.85L18.4202 10.42C19.3902 9.86 20.5902 9.86 21.5702 10.42L27.5102 13.85C28.4802 14.42 29.0802 15.45 29.0802 16.58Z'
                stroke='#E2C0B2'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M20 24.2V24.3'
                stroke='#E2C0B2'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export const WriteOffIcon = () => {
    return (
        <svg
            width='40'
            height='40'
            viewBox='0 0 40 40'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <rect width='40' height='40' rx='8' fill='#D61111' />
            <path
                d='M26.04 21.55C25.62 21.96 25.38 22.55 25.44 23.18C25.53 24.26 26.52 25.05 27.6 25.05H29.5V26.24C29.5 28.31 27.81 30 25.74 30H15.63C15.94 29.74 16.21 29.42 16.42 29.06C16.79 28.46 17 27.75 17 27C17 24.79 15.21 23 13 23C12.06 23 11.19 23.33 10.5 23.88V19.51C10.5 17.44 12.19 15.75 14.26 15.75H25.74C27.81 15.75 29.5 17.44 29.5 19.51V20.95H27.48C26.92 20.95 26.41 21.17 26.04 21.55Z'
                stroke='#EFA0A0'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M10.5 20.41V15.84C10.5 14.65 11.23 13.59 12.34 13.17L20.28 10.17C21.52 9.7 22.85 10.62 22.85 11.95V15.75'
                stroke='#EFA0A0'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M30.5588 21.9702V24.0302C30.5588 24.5802 30.1188 25.0302 29.5588 25.0502H27.5988C26.5188 25.0502 25.5288 24.2602 25.4388 23.1802C25.3788 22.5502 25.6188 21.9602 26.0388 21.5502C26.4088 21.1702 26.9188 20.9502 27.4788 20.9502H29.5588C30.1188 20.9702 30.5588 21.4202 30.5588 21.9702Z'
                stroke='#EFA0A0'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M15 20H22'
                stroke='#EFA0A0'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M17 27C17 27.75 16.79 28.46 16.42 29.06C16.21 29.42 15.94 29.74 15.63 30C14.93 30.63 14.01 31 13 31C11.54 31 10.27 30.22 9.58 29.06C9.21 28.46 9 27.75 9 27C9 25.74 9.58 24.61 10.5 23.88C11.19 23.33 12.06 23 13 23C15.21 23 17 24.79 17 27Z'
                stroke='#EFA0A0'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.0692 28.04L11.9492 25.93'
                stroke='#EFA0A0'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path
                d='M14.0497 25.96L11.9297 28.07'
                stroke='#EFA0A0'
                strokeWidth='1.5'
                strokeMiterlimit='10'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
};

export const getInvoiceContractStatusIcon = (status: string) => {
    switch (status) {
        case 'COMPLETED':
            return <CompletedInvoiceIcon />;
        case 'DRAFT':
            return <DraftInvoiceIcon />;
        case 'CANCELLED':
            return <CancelledInvoiceIcon />;
        case 'ISSUED':
            return <IssuedInvoiceIcon />;
        case 'APPROVED':
            return <ApprovedInvoiceIcon />;
        case 'BOOKING PLACED':
            return <BookingPlacedInvoiceIcon />;
        case 'EXPIRED':
            return <ExpiredInvoiceIcon />;
        case 'STOCK ALLOCATED':
            return <StockInvoiceIcon />;

        case 'PAID':
            return <PaidInvoiceIcon />;

        case 'PARTIALLY PAID':
            return <PartiallyPaidInvoiceIcon />;

        case 'OVERDUE':
            return <OverdueIcon />;

        case 'WRITE OFF':
            return <WriteOffIcon />;

        default:
            return <IssuedInvoiceIcon />;
    }
};
