import { Box } from '@mui/material';
import * as Sentry from '@sentry/react';
import { FrontEdgeLogo } from './icons/FrontEdgeLogo';
import { Link, useRouteError } from 'react-router-dom';
import { useEffect } from 'react';
import { GhostIcon } from './icons/GhostIcon';

export const ErrorBoundary = () => {
    const error = useRouteError();

    useEffect(() => {
        Sentry.captureException(error);
    });

    return (
        <Box className='flex items-center flex-col w-[300px] mx-auto mt-40'>
            <Box>
                <FrontEdgeLogo />
            </Box>
            <Box className='mt-20'>
                <GhostIcon />
            </Box>
            <Box className='mt-20'>An error has occured and been reported.</Box>
            <Link className='text-appcolorprimary mt-10' to='/dashboard'>
                Go Home
            </Link>
        </Box>
    );
};
