import { ReactElement } from 'react';

interface WarehouseSummaryItemProps {
    label: string;
    value: string;
    bg: string;
    icon: ReactElement;
}

const WarehouseSummaryItem = ({
    item
}: {
    item?: WarehouseSummaryItemProps;
}) => {
    return (
        <div className='flex grow h-[107px] justify-between items-center bg-white py-[17px] px-[14px] border-lightGray border rounded-[5px] lg:p-0 lg:border-none'>
            <div>
                <p className='text-sm text-appcolorfaintgreyblue'>
                    {item?.label}
                </p>
                <h3 className='text-2xl text-charcoal font-medium'>
                    {item?.value}
                </h3>
            </div>

            {item?.icon}
        </div>
    );
};

export default WarehouseSummaryItem;
