import { Dropdown, MenuProps, Table } from 'antd';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { DocumentTableContainer } from '../../../styles/TradeDocStyles';
import { useNavigate } from 'react-router-dom';
import { openNotification, pascalToHyphenString } from '../../../utils/helper';
import { useReactToPrint } from 'react-to-print';
import { getSingleDocuments } from '../../../api/documents/documents';
import MainPreviewComponent from './previewComponents/MainPreviewComponent';
import { hasPermission } from '../dashboardpage/utils';
import { useUserProfile } from '../../../customhooks/useUserProfile';

interface IProps {
    setShowAddModal: Dispatch<SetStateAction<boolean>>;
    setVisibleDocument: Dispatch<SetStateAction<boolean>>;
    loadingData: boolean;
    data?: any[];
    setCurrentData: Dispatch<SetStateAction<any>>;
    currentData: {
        createdType: string;
        documentType: string;
        id: number | undefined;
    };
}

const AllDocumentsTable = (props: IProps) => {
    const navigate = useNavigate();
    const printContentRef = useRef<HTMLDivElement | null | any>(null);

    const handlePrint = useReactToPrint({
        content: () => printContentRef.current
    });

    const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
    const [singleData, setSingleData] = useState<any>(null);
    const [previewId, setPreviewId] = useState<any>(null);
    const { permissions } = useUserProfile();

    const fetchApiData = async () => {
        try {
            const res = await getSingleDocuments({
                corporateDocumentId: props.currentData?.id
            });
            const { data } = res.data;
            setSingleData(data?.documentData);
            setPreviewId(data?.id);
            setDownloadLoading(false);
        } catch (error) {
            setDownloadLoading(false);
            if (error?.response) {
                openNotification(
                    'error',
                    `Document Details, Something went wrong`
                );
            } else {
                openNotification(
                    'error',
                    `Documents Details, Something went wrong`
                );
            }
        }
    };

    const handlePrintDownloadClick = async () => {
        await fetchApiData();
        if (!downloadLoading) {
            handlePrint();
        }
    };

    const items: MenuProps['items'] =
        props?.currentData?.createdType === 'UPLOADED'
            ? [
                  {
                      key: '1',
                      label: (
                          <div
                              className='flex items-center'
                              onClick={() => {
                                  props.setVisibleDocument(true);
                              }}
                          >
                              <span className='ml-2'>Preview</span>
                          </div>
                      )
                  }
              ]
            : [
                  {
                      key: '0',
                      label: (
                          <div
                              className='flex items-center'
                              onClick={() => {
                                  props.setVisibleDocument(true);
                              }}
                          >
                              <span className='ml-2'>Preview</span>
                          </div>
                      )
                  },

                  {
                      key: '1',
                      label: (
                          <div
                              className='flex items-center '
                              onClick={() => {
                                  handlePrintDownloadClick();
                              }}
                          >
                              <span className='ml-2'>Download</span>
                          </div>
                      )
                  },
                  {
                      key: '2',
                      label: (
                          <div
                              className={`flex items-center ${
                                  permissions &&
                                  !hasPermission({
                                      userPermissions: permissions,
                                      module: 'tradeDocuments',
                                      permissionList: ['write', '*']
                                  }) &&
                                  ` opacity-30 cursor-not-allowed`
                              }`}
                              onClick={() => {
                                  if (
                                      permissions &&
                                      hasPermission({
                                          userPermissions: permissions,
                                          module: 'tradeDocuments',
                                          permissionList: ['write', '*']
                                      })
                                  ) {
                                      props.currentData.documentType ===
                                      'DRAFT_BILL_OF_LADING'
                                          ? navigate(
                                                `/trade-documents/house-bill-of-lading?edit=${props?.currentData?.id}`
                                            )
                                          : props.currentData.documentType ===
                                            'DRAFT_GROSS_MASS_DECLARATION'
                                          ? navigate(
                                                `/trade-documents/verified-gross-mass-declaration?edit=${props?.currentData?.id}`
                                            )
                                          : props.currentData.documentType ===
                                            'DRAFT_CERTIFICATE_OF_ORIGIN'
                                          ? navigate(
                                                `/trade-documents/certificate-of-origin?edit=${props?.currentData?.id}`
                                            )
                                          : navigate(
                                                `/trade-documents/${pascalToHyphenString(
                                                    props?.currentData
                                                        ?.documentType
                                                )}?edit=${props?.currentData
                                                    ?.id}`
                                            );
                                  } else {
                                      openNotification(
                                          'error',
                                          'You do not have access to perform this action'
                                      );
                                  }
                              }}
                          >
                              <span className='ml-2'>Edit</span>
                          </div>
                      )
                  }
              ];
    return (
        <DocumentTableContainer className='bg-appcolorwhite'>
            <Table
                loading={props.loadingData}
                pagination={{ pageSize: 20 }}
                locale={{
                    emptyText: () => {
                        return (
                            <div className='flex flex-col justify-center mt-6 mb-4 align-center'>
                                <div className='flex justify-center mb-4'>
                                    <img
                                        src='/img/vectors/nodatacross.svg'
                                        alt='nodata'
                                    />
                                </div>

                                <p className='text-center text-appcolorblackthree'>
                                    You have no documents.
                                    <br />
                                    <span
                                        className='cursor-pointer text-appcolorprimary'
                                        onClick={() => {
                                            props.setShowAddModal(true);
                                        }}
                                    >
                                        Create or Upload a document
                                    </span>{' '}
                                    to get started.
                                </p>
                            </div>
                        );
                    }
                }}
                rowKey='id'
                dataSource={props?.data || []}
                columns={[
                    {
                        title: 'Last Modified',
                        dataIndex: 'lastModified',
                        key: 'lastModified',
                        render: (_, record: any) => (
                            <span>
                                {moment(
                                    parseInt(record?.lastModifiedDate)
                                ).format('ll')}
                            </span>
                        )
                    },
                    {
                        title: 'Date Added',
                        dataIndex: 'createdDate',
                        key: 'createdDate',
                        render: (_, record: any) => (
                            <span>
                                {moment(parseInt(record?.createdDate)).format(
                                    'll'
                                )}
                            </span>
                        )
                    },
                    {
                        title: 'Name Tag',
                        dataIndex: 'documentName',
                        key: 'documentName',
                        render: (text) => (
                            <span className=''>{text ?? '-'}</span>
                        )
                    },
                    {
                        title: 'Document Type',
                        dataIndex: 'documentType',
                        key: 'documentType',
                        render: (text) => (
                            <span className='capitalize'>
                                {text.toLowerCase().split('_').join(' ')}
                            </span>
                        )
                    },
                    {
                        title: 'Data Type',
                        dataIndex: 'createdType',
                        key: 'createdType',
                        render: (text) => (
                            <span>{text === 'UPLOADED' ? 'File' : 'Form'}</span>
                        )
                    },
                    {
                        title: '',
                        render: (_, data) => (
                            <div
                                className='cursor-pointer'
                                onClick={() => {
                                    props.setCurrentData(data);
                                }}
                            >
                                <Dropdown
                                    menu={{ items }}
                                    trigger={['click']}
                                    placement='bottomRight'
                                >
                                    <img
                                        src='/img/vectors/more.svg'
                                        alt='more'
                                    />
                                </Dropdown>
                            </div>
                        )
                    }
                ]}
            />
            {singleData && !downloadLoading && (
                <MainPreviewComponent
                    data={singleData}
                    currentData={props.currentData}
                    ref={printContentRef}
                />
            )}
        </DocumentTableContainer>
    );
};

export default AllDocumentsTable;
