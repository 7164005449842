import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import CustomModal from '../../../CustomModal';
import { CircularProgress, Stack } from '@mui/material';
import {
    PreviewDocumentContainer,
    TabContainerNew
} from '../../../../styles/TradeDocStyles';
import Pdf from 'react-to-pdf';
import { REACT_APP_BASE_URL } from '../../../../config';
import SalesView from '../../tradeDocuments/previewComponents/SalesView';
import DocumentViewer from '../../../DocumentViewer/DocumentViewer';

interface IProps {
    currentData?: any;
    visibleDocument: boolean;
    closeCurrentDocModal: any;
    myDiv?: MutableRefObject<HTMLDivElement | null>;
    setDownloadLoading: Dispatch<SetStateAction<boolean>>;
    downloadLoading: boolean;
}

const ContractPreview = ({
    visibleDocument,
    closeCurrentDocModal,
    currentData,
    myDiv,
    setDownloadLoading,
    downloadLoading
}: IProps) => {
    return (
        <CustomModal
            maxWidth='xl'
            open={visibleDocument}
            onClose={closeCurrentDocModal}
            styles={{
                overflowX: 'hidden'
            }}
        >
            <Stack width='100%' direction='row' mb='1rem'>
                <TabContainerNew>
                    <Pdf
                        y={0.5}
                        targetRef={myDiv}
                        filename={`${'SALES_CONTRACT'?.toLowerCase()}.pdf`}
                        options={{
                            orientation: 'portrait',
                            unit: 'in',
                            format: [8.27, 12.5]
                        }}
                        onComplete={() => {
                            setDownloadLoading(false);
                        }}
                    >
                        {({ toPdf }: any) => (
                            <div
                                className='tab-buttons'
                                onClick={() => {
                                    if (!downloadLoading) {
                                        toPdf();
                                        setDownloadLoading(true);
                                    }
                                }}
                            >
                                {downloadLoading ? (
                                    <CircularProgress
                                        size={15}
                                        sx={{
                                            marginRight: '0.3rem'
                                        }}
                                    />
                                ) : (
                                    <img
                                        src='/img/vectors/plaindownload.svg'
                                        alt='plaindownload'
                                        className='h-[1.1rem] mr-2'
                                    />
                                )}
                                <p style={{ paddingRight: '5px' }}>Download</p>
                            </div>
                        )}
                    </Pdf>
                </TabContainerNew>
            </Stack>
            <div ref={myDiv} className='px-4'>
                <PreviewDocumentContainer>
                    <div className='text-[14px]'>
                        <div className='flex items-center justify-between mb-4 w-full'>
                            {currentData?.logoUrl ? (
                                <div className='w-[350px]'>
                                    <div className='preview-container'>
                                        <DocumentViewer
                                            fileUrl={`${REACT_APP_BASE_URL}/${currentData?.logoUrl.split(
                                                '/v1/'
                                            )[1]}`}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div>
                                        <img
                                            src='/img/vectors/powered.svg'
                                            alt=''
                                            className='h-[2.5rem]'
                                        />
                                    </div>
                                </>
                            )}

                            <div>
                                <h1 className='text-appcolorprimary text-[26px] leading-[27px] font-bold capitalize'>
                                    {'SALES_CONTRACT'
                                        .split('_')
                                        .filter((x) => x.length > 0)
                                        .map(
                                            (x) =>
                                                x.charAt(0).toUpperCase() +
                                                x.slice(1)
                                        )
                                        .join(' ')
                                        .toLowerCase()}
                                </h1>
                                <p className='text-right text-sm'>
                                    Doc#: {currentData?.corporateDocumentId}
                                </p>
                            </div>
                        </div>
                    </div>

                    <SalesView data={currentData} docType={'SALES_CONTRACT'} />
                </PreviewDocumentContainer>
            </div>
        </CustomModal>
    );
};

export default ContractPreview;
