import React, { Dispatch, SetStateAction } from 'react';
import { Stack } from '@mui/material';
import FilterInvoice from './FilterInvoice';
import { TabContainerNew } from '../../../../styles/TradeDocStyles';
import { useUserProfile } from '../../../../customhooks/useUserProfile';
import { hasPermission } from '../../dashboardpage/utils';
import { InvoiceParams } from '../../../../pages/dashboard/payments/interfaces/Invoice';
import { CorporatePartner } from '../../../../interfaces/CorporatePartner';
import CreateInvoice from './CreateInvoice';

interface IProps {
    filterInvoiceOptions?: InvoiceParams;
    setFilterInvoiceOptions?: Dispatch<SetStateAction<InvoiceParams>>;
    fetchInvoice?: () => void;
    corporatePartners?: CorporatePartner[];
    fetchPartners: () => void;
    loadingCorporatePartners?: boolean;
}
const InvoiceTabs = ({
    loadingCorporatePartners,
    corporatePartners,
    fetchPartners,
    fetchInvoice,
    filterInvoiceOptions,
    setFilterInvoiceOptions
}: IProps) => {
    const { permissions } = useUserProfile();

    return (
        <TabContainerNew>
            <Stack direction='row' spacing={'10px'}>
                {permissions &&
                    hasPermission({
                        userPermissions: permissions,
                        module: 'sales',
                        permissionList: ['write', '*', 'read']
                    }) && (
                        <FilterInvoice
                            {...{
                                corporatePartners,
                                fetchInvoice,
                                loadingCorporatePartners
                            }}
                            filterOptions={filterInvoiceOptions}
                            setFilterOptions={setFilterInvoiceOptions}
                        />
                    )}

                <CreateInvoice
                    {...{
                        corporatePartners,
                        loadingCorporatePartners,
                        fetchPartners
                    }}
                />
            </Stack>
        </TabContainerNew>
    );
};

export default InvoiceTabs;
