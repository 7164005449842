import moment from 'moment';
import { Transactions } from '../payments/interfaces/Transactions';
import {
    getRecipientName,
    getTransactionStatus,
    getTransactionType
} from '../../../utils/helper';
import StatusIndicator from '../../../components/StatusIndicator';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';

interface DashboardTableMobileCardProps {
    item?: Transactions;
    onClick?: () => void;
}

const DashboardTableMobileCard = ({
    item,
    onClick = () => {}
}: DashboardTableMobileCardProps) => {
    const { user } = useSelector((state: RootState) => state.user);
    return (
        <div className='bg-white px-[16px]' onClick={onClick}>
            <div className='flex  gap-[12px] border border-appcolorfaintnine border-b border-0 w-full py-[20px]'>
                <StatusIndicator status={getTransactionStatus(item?.status)} />

                <div>
                    <p className='text-sm font-semibold leading-5 text-ebony mb-[8px]'>
                        {item?.transactionAction === 'CREDIT' ? (
                            <>{user?.name}</>
                        ) : (
                            <>
                                {getRecipientName({
                                    transactionDetails: item,
                                    user
                                })}
                            </>
                        )}
                    </p>
                    <p className='text-xs font-normal leading-[18px] text-paleSky mb-[4px]'>
                        Tranx:{' '}
                        <span className='font-bold'>
                            {`${item?.fromCurrencyCode}${item?.amountFrom?.toLocaleString()} - ${item?.toCurrencyCode}${item?.amountTo?.toLocaleString()}`}
                        </span>
                    </p>
                    <div className='flex items-center gap-x-4  gap-y-1 flex-wrap mb-[4px] '>
                        <p className='text-xs font-normal leading-[18px] text-paleSky'>
                            Rate:{' '}
                            <span className='font-semibold'>
                                {item?.rateValue}
                            </span>
                        </p>
                        <p className='text-xs font-normal leading-[18px] text-paleSky'>
                            Type:{' '}
                            <span className='font-semibold'>
                                {getTransactionType(item?.transactionType)}
                            </span>
                        </p>
                        <p className='text-xs font-normal leading-[18px] text-paleSky'>
                            Status:{' '}
                            <span className='font-semibold'>
                                {getTransactionStatus(item?.status)}
                            </span>
                        </p>
                    </div>
                    <p className='text-xs font-normal leading-[18px] text-paleSky'>
                        Date:{' '}
                        <span className='font-semibold'>
                            {moment(item?.createdDate).format('ll')}
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default DashboardTableMobileCard;
