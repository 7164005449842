import { createBrowserRouter } from 'react-router-dom';
import Bookings from './pages/dashboard/bookings/bookings';
import CreateBooking from './pages/dashboard/bookings/createbooking/CreateBooking';
import BookingDetails from './pages/dashboard/bookings/overview/BookingDetails';
import BookingOverviewNew from './pages/dashboard/bookings/overview/BookingOverviewNew';
import BookingProfileDetails from './pages/dashboard/bookings/overview/BookingProfileDetails';
import ShippingProfileDetails from './pages/dashboard/bookings/overview/ShippingProfileDetails';
import Trackshipment from './pages/dashboard/bookings/trackshipment/trackshipment';
import TrackshipmentInternal from './pages/dashboard/bookings/trackshipment/trackshipmentinternal';
import Viewbookings from './pages/dashboard/bookings/viewbookings/viewbookings';
import CorporatesNew from './pages/dashboard/corporates/CorporatesNew';
import ViewCorporates from './pages/dashboard/corporates/ViewCorporates';
import Dashboardmain from './pages/dashboard/dashboardmain/dashboardmain';
import Financing from './pages/dashboard/financing/financing';
import Requestexportfinance from './pages/dashboard/financing/requestexportfinance/requestexportfinance';
import Viewfinanceapplication from './pages/dashboard/financing/viewfinanceapplication/viewfinanceapplication';
import Inspection from './pages/dashboard/inspection/Inspection';
import OceanSchedules from './pages/dashboard/ocean-schedules/ocean-schedules';
import Makepayment from './pages/dashboard/payments/makepayment/makepayment';
import PaymentsOverview from './pages/dashboard/payments/PaymentsOverview';
import ViewTransaction from './pages/dashboard/payments/ViewTransaction';
import ContractDetails from './pages/dashboard/sales/contracts/ContractDetails';
import Contracts from './pages/dashboard/sales/contracts/Contracts';
import InvoiceDetails from './pages/dashboard/sales/invoices/InvoiceDetails';
import Invoices from './pages/dashboard/sales/invoices/Invoices';
import TradeDocumentNew from './pages/dashboard/tradedocuments/TradeDocumentNew';
import Billoflading from './pages/dashboard/tradedocuments/billoflading/billoflading';
import CommercialInvoiceNew from './pages/dashboard/tradedocuments/commercialinvoice/CommercialInvoiceNew';
import HouseBillOfLadingNew from './pages/dashboard/tradedocuments/housebilloflading/HouseBillOfLadingNew';
import CertificateOfOriginNew from './pages/dashboard/tradedocuments/housebilloflading/certificateoforigin/CertificateOfOriginNew';
import PackingList from './pages/dashboard/tradedocuments/parkinglist/PackingList';
import ProformaInvoiceNew from './pages/dashboard/tradedocuments/proformainvoice/ProfomaInvoiceNew';
import PurchaseOrderNew from './pages/dashboard/tradedocuments/purchaseorder/PurchaseOrderNew';
import QuotationNew from './pages/dashboard/tradedocuments/quotation/QuotationNew';
import SalesContractNew from './pages/dashboard/tradedocuments/salescontract/SalesContractNew';
import VerifiedGrossMassDeclarationNew from './pages/dashboard/tradedocuments/verifiedgrossmassdeclaration/VerifiedGrossMassDeclarationNew';
import ForgotPassword from './pages/ForgotPassword';
import Resetpassword from './pages/resetpassword/resetpassword';
import SignUp from './pages/signUp/SignUp';
import Signin from './pages/signin/signin';
import Dashboardpage from './components/dashboard/dashboardpage/dashboardpage';
import Verifyaccount from './pages/verifyaccount/verifyaccount';
import { FinanceOverview } from './pages/dashboard/financing/FinanceOverview';
import { InsuranceOverview } from './pages/dashboard/insurance/InsuranceOverview';
import StockManagement from './pages/dashboard/stock';
import AddWarehouse from './pages/dashboard/stock/AddWarehouse';
import { InsuranceProfileForm } from './pages/dashboard/insurance/InsuranceProfileForm';
import { ErrorBoundary } from './components/ErrorBoundary';
import CurrencyAdministration from './pages/dashboard/payments/CurrencyAdministration';
import PaymentTable from './pages/dashboard/payments/PaymentTable';
import DedicatedAccounts from './pages/dashboard/payments/dedicatedAccounts/DedicatedAccounts';
import DedicatedAccountDetails from './pages/dashboard/payments/dedicatedAccounts/DedicatedAccountDetails';
import LogisticsInvoiceDetails from './pages/dashboard/payments/LogisticsInvoiceDetails';
import { LogisticsInvoiceContainer } from './pages/dashboard/payments/LogisticsInvoiceContainer';
import { LogisticsInvoiceHome } from './pages/dashboard/payments/LogisticsInvoiceHome';
import { LogisticsInvoiceList } from './pages/dashboard/payments/LogisticsInvoiceList';
import { LogisticsInvoiceCreate } from './pages/dashboard/payments/LogisticsInvoiceCreate';
import { LogisticsInvoicePayDetails } from './pages/dashboard/payments/LogisticsInvoicePayDetails';
import SettingsContainer from './pages/dashboard/settings/SettingsContainer';
import UserProfileSettings from './pages/dashboard/settings/UserProfileSettings';
import InvitedUserPassword from './pages/InvitedUserPassword';

export const Router = createBrowserRouter([
    {
        path: '/',
        element: <Signin />
    },
    {
        path: '/login',
        element: <Signin />
    },
    {
        path: '/signup',
        element: <SignUp />
    },
    {
        path: '/password/forgot',
        element: <ForgotPassword />
    },
    {
        path: '/password/reset/:token',
        element: <Resetpassword />
    },
    {
        path: '/verify/account/:verificationCode',
        element: <Verifyaccount />
    },
    {
        path: '/invited-user/password',
        element: <InvitedUserPassword />
    },
    {
        path: '/dashboard',
        element: <Dashboardpage fullWidth />,
        children: [
            {
                path: '/dashboard',
                element: <Dashboardmain />
            }
        ]
    },
    {
        path: '/bookings',
        element: <Dashboardpage fullWidth />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '/bookings',
                element: <Bookings />
            },
            {
                path: '/bookings/create-booking',
                element: <CreateBooking />
            },
            {
                path: '/bookings/:reference',
                element: <Viewbookings />
            },
            {
                path: '/bookings/track-shipments',
                element: <Trackshipment />
            },
            {
                path: '/bookings/track-shipments/:reference',
                element: <TrackshipmentInternal />
            },
            {
                path: '/bookings/track-shipments/:reference/:containerNumber',
                element: <TrackshipmentInternal />
            },
            {
                path: '/bookings/:reference/details',
                element: <BookingDetails />
            },
            {
                path: '/bookings/booking-profile/:reference/details',
                element: <BookingProfileDetails />
            },
            {
                path: '/bookings/shipping-profile/:reference/details',
                element: <ShippingProfileDetails />
            },
            {
                path: '/bookings/overview',
                element: <BookingOverviewNew />
            }
        ]
    },
    {
        path: '/corporates',
        element: <Dashboardpage />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '/corporates',
                element: <CorporatesNew />
            },

            {
                path: '/corporates/:corporateId',
                element: <ViewCorporates />
            }
        ]
    },
    {
        path: '/financing',
        element: <Dashboardpage />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '/financing',
                element: <Financing />
            },
            {
                path: '/financing/overview',
                element: <FinanceOverview />
            },
            {
                path: '/financing/export-finance/request',
                element: <Requestexportfinance />
            },
            {
                path: '/financing/application/:reference',
                element: <Viewfinanceapplication />
            }
        ]
    },
    {
        path: '/insurance',
        element: <Dashboardpage />,
        errorElement: <ErrorBoundary />,
        children: [
            { path: '/insurance', element: <InsuranceOverview /> },
            { path: '/insurance/profile', element: <InsuranceProfileForm /> }
        ]
    },
    {
        path: '/payments',
        element: <Dashboardpage fullWidth />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '/payments',
                element: <PaymentsOverview />,
                children: [
                    {
                        path: '/payments/overview',
                        element: <PaymentTable />
                    },
                    {
                        path: '/payments/make-payment',
                        element: <Makepayment />
                    }
                ]
            },
            {
                path: '/payments/currency-administration',
                element: <CurrencyAdministration />
            },
            {
                path: '/payments/dedicated-accounts',
                element: <DedicatedAccounts />
            },

            {
                path: '/payments/dedicated-accounts/:id',
                element: <DedicatedAccountDetails />
            },

            {
                path: '/payments/transactions/:reference',
                element: <ViewTransaction />
            },
            {
                path: '/payments/logistics',
                element: <LogisticsInvoiceContainer />,
                children: [
                    {
                        path: '/payments/logistics',
                        element: <LogisticsInvoiceHome />
                    },
                    {
                        path: '/payments/logistics/create',
                        element: <LogisticsInvoiceCreate />
                    },
                    {
                        path: '/payments/logistics/list',
                        element: <LogisticsInvoiceList />
                    },
                    {
                        path: '/payments/logistics/details/:reference',
                        element: <LogisticsInvoiceDetails />
                    },
                    {
                        path: '/payments/logistics/pay',
                        element: <LogisticsInvoicePayDetails />
                    }
                ]
            }
        ]
    },
    {
        path: '/trade-documents',
        element: <Dashboardpage />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '/trade-documents',
                element: <TradeDocumentNew />
            },
            {
                path: '/trade-documents/sales-contract',
                element: <SalesContractNew />
            },
            {
                path: '/trade-documents/quotation',
                element: <QuotationNew />
            },
            {
                path: '/trade-documents/proforma-invoice',
                element: <ProformaInvoiceNew />
            },
            {
                path: '/trade-documents/commercial-invoice',
                element: <CommercialInvoiceNew />
            },
            {
                path: '/trade-documents/packing-list',
                element: <PackingList />
            },
            {
                path: '/trade-documents/bill-of-lading',
                element: <Billoflading />
            },
            {
                path: '/trade-documents/purchase-order',
                element: <PurchaseOrderNew />
            },
            {
                path: '/trade-documents/verified-gross-mass-declaration',
                element: <VerifiedGrossMassDeclarationNew />
            },
            {
                path: '/trade-documents/certificate-of-origin',
                element: <CertificateOfOriginNew />
            },
            {
                path: '/trade-documents/house-bill-of-lading',
                element: <HouseBillOfLadingNew />
            }
        ]
    },
    {
        path: '/sales',
        element: <Dashboardpage fullWidth />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '/sales/contracts',
                element: <Contracts />
            },
            {
                path: '/sales/contracts/details/:contractNumber',
                element: <ContractDetails />
            },
            {
                path: '/sales/invoices',
                element: <Invoices />
            },
            {
                path: '/sales/invoices/details/:reference',
                element: <InvoiceDetails />
            }
        ]
    },
    {
        path: '/stock',
        element: <Dashboardpage fullWidth />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '/stock',
                element: <StockManagement />
            },
            {
                path: '/stock/add-warehouse',
                element: <AddWarehouse />
            }
        ]
    },
    {
        path: '/inspection',
        element: <Dashboardpage />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '/inspection',
                element: <Inspection />
            }
        ]
    },
    {
        path: '/ocean-schedules',
        element: <Dashboardpage />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '/ocean-schedules',
                element: <OceanSchedules />
            }
        ]
    },
    {
        path: '/settings',
        element: <Dashboardpage fullWidth />,
        errorElement: <ErrorBoundary />,
        children: [
            {
                path: '/settings',
                element: <SettingsContainer />,
                children: [
                    {
                        path: '/settings/profile',
                        element: <UserProfileSettings />
                    }
                ]
            }
        ]
    },
    {
        path: '*',
        element: <Signin />
    }
]);
