import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { openNotification } from '../../../../utils/helper';
import {
    approveBookingProfile,
    getBookingProfileDetails
} from '../../../../api/booking/booking';
import {
    BookingContainer,
    DetailsContainer
} from '../../../../components/dashboard/upsBooking/Styles';
import { Alert, Backdrop, Stack, TextField } from '@mui/material';
import BookingHeader from '../../../../components/dashboard/upsBooking/BookingHeader';
import { Button } from 'antd';
import CustomModal from '../../../../components/CustomModal';
import ApprovalModalContent from '../../../../components/dashboard/upsBooking/ApprovalModalContent';
import { BookingProfileTableData } from '../../../../components/dashboard/upsBooking/BookingPrfileTypes';

const BookingProfileDetails = () => {
    const { reference } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<BookingProfileTableData | null>(null);
    const [loadingData, setLoadingData] = useState(false);
    const [inttraCompanyId, setInttraCompanyId] = useState('');
    const [error, setError] = useState<
        string | JSX.Element[] | JSX.Element | null | string[]
    >(null);
    const [errors, setErrors] = useState<Record<string, string>>({});
    const [showApproveModal, setShowApproveModal] = useState(false);

    const breadcrumbs = [
        {
            title: 'Home',
            link: '/',
            active: false
        },
        {
            title: 'Bookings',
            link: '/bookings/overview',
            active: false
        },
        {
            title: 'Booking Profile details',
            link: `/bookings/booking-profile/${reference}/details`,
            active: true
        }
    ];

    const fetchBooking = async () => {
        setLoadingData(true);
        try {
            const res = await getBookingProfileDetails(Number(reference));
            const { data } = res.data;
            setData(data);
            setLoadingData(false);
        } catch (error) {
            setLoadingData(false);
            if (error?.response) {
                openNotification('error', `Booking, Something went wrong`);
            } else {
                openNotification('error', `Booking, Something went wrong`);
            }
        }
    };
    useEffect(() => {
        fetchBooking();
    }, []);

    const approveFunction = async () => {
        setError(null);
        if (!inttraCompanyId) {
            setError('Enter Intra Company ID');
        } else {
            setLoading(true);
            try {
                const res = await approveBookingProfile(Number(reference), {
                    inttraCompanyId
                });
                setLoading(false);
                setShowApproveModal(false);
                openNotification(
                    'success',
                    'Booking Profile was approved successfully'
                );
            } catch (error) {
                setLoading(false);
                openNotification(
                    'error',
                    'Booking Profile approval, Something went wrong'
                );
                navigate('/bookings/overview');
                const otherErrors: string[] = [];

                if (error?.response) {
                    if (error?.response?.data?.error) {
                        const resError = error?.response?.data?.error;

                        if (typeof resError === 'string') {
                            const errorList = (
                                <li key={`error-list-${1}`}>{resError}</li>
                            );
                            setError(errorList);
                        } else {
                            Object.entries(resError).forEach(([key, value]) => {
                                if (Object.keys(errors).includes(key)) {
                                    setErrors((prevState) => ({
                                        ...prevState,
                                        [key]: value as string
                                    }));
                                } else {
                                    otherErrors.push(value as string);
                                }
                            });

                            if (otherErrors.length > 0) {
                                const errorList = otherErrors.map(
                                    (element, index) => {
                                        return (
                                            <li key={`error-list-${index}`}>
                                                {element}
                                            </li>
                                        );
                                    }
                                );
                                setError(errorList);
                            }
                        }
                    } else {
                        setError(
                            error?.response?.data?.message ||
                                'Something went wrong'
                        );
                    }
                } else {
                    setError('Something went wrong');
                }
            }
        }
    };
    return (
        <BookingContainer>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={loadingData}
            >
                <img
                    src='/img/vectors/loadingcircles.svg'
                    alt='loading'
                    className='animate-spin'
                />
            </Backdrop>
            <Stack
                direction='row'
                alignItems='center'
                width='100%'
                justifyContent='space-between'
            >
                <BookingHeader {...{ breadcrumbs }} noHead />
                <Stack
                    direction='row'
                    alignItems='center'
                    className='buttons'
                    mb='1rem'
                >
                    <Button
                        className='cancel'
                        onClick={() => navigate(`/bookings/overview`)}
                    >
                        Back
                    </Button>{' '}
                    <Button onClick={() => setShowApproveModal(true)}>
                        Approve
                    </Button>
                </Stack>
            </Stack>
            {!loadingData && data !== null && (
                <DetailsContainer>
                    <div className='details-content flex-1'>
                        <h3>Company Details</h3>

                        <Stack direction='row' className='flex-dets'>
                            <p>Company Name</p>
                            <p>{data?.name || '--'}</p>
                        </Stack>

                        <Stack direction='row' className='flex-dets'>
                            <p>Company Role</p>
                            <p>{data?.companyRole || '--'}</p>
                        </Stack>
                        <Stack direction='row' className='flex-dets'>
                            <p>Address</p>
                            <p>{data?.address || '--'}</p>
                        </Stack>
                        <Stack direction='row' className='flex-dets'>
                            <p>Postal Code</p>
                            <p>{data?.postCode || '--'}</p>
                        </Stack>
                        <Stack direction='row' className='flex-dets'>
                            <p>Preferred Language</p>
                            <p>{data?.preferredLanguage || '--'}</p>
                        </Stack>
                    </div>

                    <div className='details-content flex-1'>
                        <h3>Personal Details</h3>

                        <Stack direction='row' className='flex-dets'>
                            <p>Full Name</p>
                            <p>
                                {data?.firstName + ' ' + data?.lastName || '--'}
                            </p>
                        </Stack>

                        <Stack direction='row' className='flex-dets'>
                            <p>Email Address</p>
                            <p>{data?.emailAddress || '--'}</p>
                        </Stack>
                        <Stack direction='row' className='flex-dets'>
                            <p>Phone Number</p>
                            <p>{data?.phoneNumber || '--'}</p>
                        </Stack>
                        <Stack direction='row' className='flex-dets'>
                            <p>Job Function</p>
                            <p>{data?.jobFunction || '--'}</p>
                        </Stack>
                        <Stack direction='row' className='flex-dets'>
                            <p>Job Level</p>
                            <p>{data?.jobLevel || '--'}</p>
                        </Stack>
                    </div>
                </DetailsContainer>
            )}
            {showApproveModal && (
                <CustomModal
                    maxWidth='md'
                    open={showApproveModal}
                    onClose={() => setShowApproveModal(false)}
                    title=''
                >
                    <ApprovalModalContent
                        type='Booking Profile'
                        approveFunction={approveFunction}
                        cancelFunction={() => {
                            setShowApproveModal(false);
                        }}
                        loading={loading}
                    >
                        {error && (
                            <div className='mt-4'>
                                <Alert severity='error'>{error}</Alert>
                            </div>
                        )}
                        <TextField
                            value={inttraCompanyId}
                            margin='normal'
                            required
                            fullWidth
                            id='inttraCompanyId'
                            label='Intra Company Id'
                            name='inttraCompanyId'
                            onChange={(e) => {
                                setInttraCompanyId(e.target.value);
                            }}
                        />
                    </ApprovalModalContent>
                </CustomModal>
            )}
        </BookingContainer>
    );
};

export default BookingProfileDetails;
