import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { CustomDatepicker } from '../../../../components/CustomDatepicker';
import { useGetQuery } from '../../../../customhooks/useGetQuery';
import { Port } from './interfaces/Port';
import { MetaCarriage } from './interfaces/Transport';
import { BookingEntryDivider } from './BookingEntryDivider';
import { CarriageFormProps } from './interfaces/CarriageFormProps';
import CustomTextField from '../../../../components/CustomTextInput';

const transportModes = [
    'Marine Transport',
    'Rail Transport',
    'Road Transport',
    'Inland Water Transport',
    'Rail/Water Combined',
    'Rail/Road'
];

export const MetaCarriageForm = <T extends MetaCarriage>(
    props: CarriageFormProps<T> & { index: number }
) => {
    const { carriage, setFormData, index, section, isRemoveShown, formik } =
        props;
    const { isLoading: isLoadingPorts, entries: ports } = useGetQuery<Port[]>({
        url: '/ports',
        cacheKey: 'ports',
        params: {
            search: 'Nigeria'
        }
    });
    const onHandleRemoveItem = () => {
        setFormData((prev) => {
            prev.transport[section].splice(index, 1);

            const update = {
                ...prev,
                transport: {
                    ...prev.transport,
                    [section]: prev.transport[section]
                }
            };

            formik.setValues(update);

            return update;
        });
    };

    return (
        <>
            <BookingEntryDivider
                index={index}
                isRemoveShown={isRemoveShown}
                onHandleRemoveItem={onHandleRemoveItem}
            />
            <div className='flex flex-col gap-4 pb-4 lg:pb-0 lg:grid lg:grid-cols-3'>
                <div className='w-full'>
                    <Autocomplete
                        options={ports}
                        onChange={(_, value) => {
                            if (value) {
                                setFormData((prev) => {
                                    const entry: MetaCarriage = {
                                        ...carriage,
                                        startId: value.id
                                    };

                                    (
                                        prev.transport[
                                            section
                                        ] as unknown as MetaCarriage[]
                                    ).splice(index, 1, entry);
                                    const update = {
                                        ...prev,
                                        transport: {
                                            ...prev.transport,
                                            [section]: prev.transport[section]
                                        }
                                    };

                                    formik.setValues(update);

                                    return update;
                                });
                            }
                        }}
                        renderInput={(params) => {
                            const error =
                                formik.errors.transport &&
                                formik.errors.transport[section] &&
                                (
                                    formik.errors.transport[
                                        section
                                    ] as unknown as T[]
                                )[index].startId;
                            return (
                                <CustomTextField
                                    params={params}
                                    label='Start'
                                    required
                                    error={!!error}
                                    helperText={error ? String(error) : ''}
                                />
                            );
                        }}
                        getOptionLabel={(option) => option.name}
                        loading={isLoadingPorts}
                        renderOption={(props, { name, countryName }, state) => (
                            <Box component='li' {...props} key={state.index}>
                                {name}, {countryName}
                            </Box>
                        )}
                    />
                </div>

                <div className='w-full'>
                    <CustomDatepicker
                        required={true}
                        value={carriage.etd}
                        label='ETD'
                        error={
                            !!(
                                formik.errors.transport &&
                                formik.errors.transport[section] &&
                                (
                                    formik.errors.transport[
                                        section
                                    ] as unknown as MetaCarriage[]
                                )[index].etd
                            )
                        }
                        helperText={
                            formik.errors.transport &&
                            formik.errors.transport[section] &&
                            (
                                formik.errors.transport[
                                    section
                                ] as unknown as MetaCarriage[]
                            )[index].etd
                        }
                        onHandleChange={(value) => {
                            if (value) {
                                setFormData((prev) => {
                                    const entry = {
                                        ...prev.transport[section][index],
                                        etd: new Date(value).toISOString()
                                    };
                                    prev.transport[section].splice(
                                        index,
                                        1,
                                        entry
                                    );

                                    const update = {
                                        ...prev,
                                        transport: {
                                            ...prev.transport,
                                            [section]: prev.transport[section]
                                        }
                                    };

                                    formik.setValues(update);

                                    return update;
                                });
                            }
                        }}
                    />
                </div>

                <div className='w-full'>
                    <Autocomplete
                        options={transportModes.map((label) => ({ label }))}
                        onChange={(_, value) => {
                            if (value) {
                                setFormData((prev) => {
                                    const entry = {
                                        ...prev.transport[section][index],
                                        mode: value.label
                                    };
                                    prev.transport[section].splice(
                                        index,
                                        1,
                                        entry
                                    );
                                    return {
                                        ...prev,
                                        transport: {
                                            ...prev.transport,
                                            [section]: prev.transport[section]
                                        }
                                    };
                                });
                            }
                        }}
                        renderInput={(params) => (
                            <CustomTextField params={params} label='Mode' />
                        )}
                    />
                </div>

                <div className='w-full'>
                    <Autocomplete
                        options={ports}
                        onChange={(_, value) => {
                            if (value) {
                                setFormData((prev) => {
                                    const entry = {
                                        ...prev.transport[section][index],
                                        endId: value.id
                                    };
                                    prev.transport[section].splice(
                                        index,
                                        1,
                                        entry
                                    );
                                    const update = {
                                        ...prev,
                                        transport: {
                                            ...prev.transport,
                                            [section]: prev.transport[section]
                                        }
                                    };

                                    formik.setValues(update);

                                    return update;
                                });
                            }
                        }}
                        renderInput={(params) => {
                            const error =
                                formik.errors.transport &&
                                formik.errors.transport[section] &&
                                (
                                    formik.errors.transport[
                                        section
                                    ] as unknown as MetaCarriage[]
                                )[index].endId;

                            return (
                                <CustomTextField
                                    params={params}
                                    label='End'
                                    required
                                    error={!!error}
                                    helperText={error ? String(error) : ''}
                                />
                            );
                        }}
                        getOptionLabel={(option) => option.name}
                        loading={isLoadingPorts}
                        renderOption={(props, { name, countryName }, state) => (
                            <Box component='li' {...props} key={state.index}>
                                {name}, {countryName}
                            </Box>
                        )}
                    />
                </div>

                <div className='w-full'>
                    <CustomDatepicker
                        required={true}
                        value={carriage.eta}
                        label='ETA'
                        error={
                            !!(
                                formik.errors.transport &&
                                formik.errors.transport[section] &&
                                (
                                    formik.errors.transport[
                                        section
                                    ] as unknown as MetaCarriage[]
                                )[index].eta
                            )
                        }
                        helperText={
                            formik.errors.transport &&
                            formik.errors.transport[section] &&
                            (
                                formik.errors.transport[
                                    section
                                ] as unknown as MetaCarriage[]
                            )[index].eta
                        }
                        onHandleChange={(value) => {
                            if (value) {
                                setFormData((prev) => {
                                    const entry = {
                                        ...prev.transport[section][index],
                                        eta: new Date(value).toISOString()
                                    };
                                    prev.transport[section].splice(
                                        index,
                                        1,
                                        entry
                                    );

                                    const update = {
                                        ...prev,
                                        transport: {
                                            ...prev.transport,
                                            [section]: prev.transport[section]
                                        }
                                    };

                                    formik.setValues(update);

                                    return update;
                                });
                            }
                        }}
                    />
                </div>
            </div>
        </>
    );
};
