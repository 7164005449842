import { ReactElement } from 'react';
import { SummaryMain } from '../../../styles/StockStyles';
import { Divider, Stack } from '@mui/material';
import { formatCurrency, formatNumber } from '../../../utils/helper';
import { imgIcon } from '../sales/invoice/InvoiceSummary';
import { fileimg } from '../../customIcons';
import CustomCarousel from '../../CustomCarousel';
import WarehouseSummaryItem from './WarehouseSummaryItem';
import { DocumentTextIcon } from '../../icons';

const summaryData: { [key: string]: string | ReactElement }[] = [
    {
        label: 'Total Quantity in Stock',
        value: `${formatNumber(0)} MT`,
        icon: <DocumentTextIcon />
    },
    {
        label: 'Quantity of pending contract',
        value: `${formatNumber(9550)} MT`,
        icon: <DocumentTextIcon color='#DF835A' />
    },
    {
        label: 'Avg Price of pending contracts',
        value: `${formatNumber(0)} MT`,
        icon: <DocumentTextIcon color='#008F46' />
    }
];

const WarehouseSummary = () => {
    return (
        <>
            <div className='hidden lg:block'>
                <SummaryMain>
                    <div className='summary-details'>
                        <Stack
                            direction='row'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <div>
                                <p>Total Quantity in Stock</p>
                                <h3>{formatNumber(0)} MT</h3>
                            </div>
                            <div>{fileimg}</div>
                        </Stack>
                    </div>
                    <Divider orientation='vertical' flexItem />
                    <div className='summary-details'>
                        <Stack
                            direction='row'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <div>
                                <p>Quantity of pending contracts</p>
                                <h3>{formatNumber(9550)} MT</h3>
                            </div>
                            <div>{fileimg}</div>
                        </Stack>
                    </div>
                    <Divider orientation='vertical' flexItem />
                    <div className='summary-details'>
                        <Stack
                            direction='row'
                            width='100%'
                            justifyContent='space-between'
                        >
                            <div>
                                <p>Avg Price of pending contracts</p>
                                <h3>{formatCurrency(0, 'NGN')}</h3>
                            </div>
                            <div className='green'>{imgIcon}</div>
                        </Stack>
                    </div>
                </SummaryMain>
            </div>
            <div className='lg:hidden'>
                <CustomCarousel
                    data={summaryData}
                    component={<WarehouseSummaryItem />}
                />
            </div>
        </>
    );
};

export default WarehouseSummary;
